
// PropTypes
import PropTypes from 'prop-types';


export const NotificationShape = { 
    id: PropTypes.string, 
    action: PropTypes.oneOf(['Follow', 'Comment', 'Clap', 'Validate',]),
    readed: PropTypes.bool,
}
