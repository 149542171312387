// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-groups__container{
    display: grid;
    grid-template-areas: "left left left left left left right right right right right right";
    grid-template-columns: repeat(12, 1fr);
    /* gap: var(--space-and-half);  */
    height: 600px;
    /* margin-bottom: 24px; */

    margin: var(--space);
    border-radius: 8px;
    padding: 12px;
    background: var(--new-background-color);
}

.tournament-groups__container.full_screen{
    height: 770px;
    margin: var(--space);
    border-radius: 8px;
    padding: 12px;
    background: var(--new-background-color);
}

.tournament-groups__title_section{
    margin-left: var(--space-and-half);
    display: flex;
    flex-direction: column;
    gap: var(--space-half);
}

.tournament-groups__title_section .baloa-names{
    font-weight: var(--font-regular);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-groups/TournamentGroupsComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wFAAwF;IACxF,sCAAsC;IACtC,iCAAiC;IACjC,aAAa;IACb,yBAAyB;;IAEzB,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".tournament-groups__container{\n    display: grid;\n    grid-template-areas: \"left left left left left left right right right right right right\";\n    grid-template-columns: repeat(12, 1fr);\n    /* gap: var(--space-and-half);  */\n    height: 600px;\n    /* margin-bottom: 24px; */\n\n    margin: var(--space);\n    border-radius: 8px;\n    padding: 12px;\n    background: var(--new-background-color);\n}\n\n.tournament-groups__container.full_screen{\n    height: 770px;\n    margin: var(--space);\n    border-radius: 8px;\n    padding: 12px;\n    background: var(--new-background-color);\n}\n\n.tournament-groups__title_section{\n    margin-left: var(--space-and-half);\n    display: flex;\n    flex-direction: column;\n    gap: var(--space-half);\n}\n\n.tournament-groups__title_section .baloa-names{\n    font-weight: var(--font-regular);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
