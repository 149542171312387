// React
import React from 'react'

// Styles
import './ProfileInformationComponent.style.css'

// Components
// import SeparatorComponent from 'shared/components/separator/SeparatorComponent';
// import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Assets
import add_icon from 'assets/icons/add.svg';
//import edit_icon from 'assets/icons/edit.svg';


// i18n
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


function ProfileInformationComponent( props ) {
    return (                                        
        <div className={ "profile-info " + ( props.className ? props.className : '' )}>
            <div className={"profile-info__photo " + ( props.profile?.user?.photo ? 'width-photo' : '' )}>
                {props.profile?.user?.photo &&
                    <img src={ props.profile?.user?.photo } alt=""/>
                }
                {!props.profile?.user?.photo &&
                    <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                } 
                { props.is_owner && 
                    <div className="profile-info__photo__new-button" onClick={ props.onNewPhoto }>
                        <img src={ add_icon } alt=""/>
                    </div>
                }
            </div>
            { /* props.is_owner && 
                <div className="profile-info__photo__edit-button" onClick={ props.onEditDescription }>
                    <img src={ edit_icon } alt=""/>
                </div> */
            }        
            <p className="profile-info__fullname baloa-headline-2 fullname_color">{ props.profile?.user?.first_name } { props.profile?.user?.last_name }</p>
            <p className="profile-info__username baloa-normal-medium username_color">@{ props.profile?.user?.username } • {props.profile?.profile_type === 'professional'? props.t('professional_types.'+props.profile?.profile_profession_type) : props.t('role.'+props.profile?.profile_type)}</p>
            
            <div className="profile-info__content">

                <div className="profile-info__content__follows__text" onClick={() => props.onFollowers()}>
                    <p className="baloa-subtitle-2 count-text">{props.profile?.followers_count}</p>
                    <p className="profile-info__content__follows__followers baloa-table follows-text">{`${props.t('followers.tab.label1')}`}</p>
                </div>
                <div className="profile-info__content__follows__text" onClick={() => props.onFollowers()}>
                    <p className="baloa-subtitle-2 count-text">{props.profile?.following_count}</p>
                    <p className="profile-info__content__follows__followings baloa-table follows-text">{`${props.t('followers.tab.label2')}`}</p>
                </div>
                <div className="profile-info__content__follows__text" onClick={() => props.onFollowers()}>
                    <p className="baloa-subtitle-2 count-text">{props.profile?.post_count}</p>
                    <p className="profile-info__content__follows__followings baloa-table follows-text">{`${props.t('posts.postTitle')}`}</p>
                </div>
            </div>
            <div  className="profile-info__content-buttons">
                {/* edit button */}
                {props.is_owner && 
                    <div className="profile-info__content__botton">
                        <SecondaryButtonComponent
                            type="button"
                            disabled={false} 
                            onClick={ props.onEditDescription } 
                        // className="profile-info__action__button"
                        >
                            <span>{props.t('btnEditProfile')}</span>
                        </SecondaryButtonComponent>
                    </div> 
                }
                {/* Follow Button */}
                {!props.profile?.is_owner && !props.profile?.is_following && 
                    <div className="profile-info__content__botton">
                        <SecondaryButtonComponent
                            type="button"
                            disabled={false} 
                            onClick={ () => props.onFollow(props.profile) } 
                            // className="profile-info__action__button"
                        >
                            <span>{props.t('followers.tab.buttonFollow')}</span>
                        </SecondaryButtonComponent>
                    </div> 
                }

                {/* Unfollow Button */}
                {!props.profile?.is_owner && props.profile?.is_following && 
                    <div className="profile-info__content__botton">
                        <SecondaryButtonComponent
                            type="button"
                            disabled={false} 
                            onClick={ () => props.onUnfollow(props.profile) } 
                            // className="profile-info__action__button"
                        >
                            <span>{props.t('followers.tab.buttonStopFollowing')}</span>
                        </SecondaryButtonComponent>
                    </div>
                } 
                
                
                {/* Share Button */}
                {props.is_owner &&
                    <div className="profile-info__content__botton">
                        <SecondaryButtonComponent
                            onClick = {() => { props.handleOnShareProfile() }} 
                        >
                            <span>{props.t("btnShareProfile")}</span>
                        </SecondaryButtonComponent> 
                    </div>
                }
                

            </div>
            
            {/* {props.is_owner && 
                <p className="social-networks baloa-hyperlink link-color">
                   {props.t('socialNetworks')}
                </p>
            } */}

            { props.profile?.attributes?.description?.value && !props.is_owner && 
                <p className="profile-info__description baloa-table">
                    { 
                        props.profile?.attributes?.description?.value
                    }
                </p> 
            }
            { props.profile?.attributes?.description?.value && props.is_owner &&
                <p onClick={props.onEditDescription} className="profile-info__description baloa-table link">
                    { 
                        props.profile?.attributes?.description?.value
                    }
                </p> 
            }
            { //!props.profile?.attributes?.description?.value && props.is_owner && 
                /* <p className="profile-info__description baloa-hyperlink" onClick={props.onEditDescription}>
                    {props.t('bio')}
                </p> */
                // <TextButtonComponent 
                //     className="profile-info__description"
                //     onClick={props.onEditDescription}
                // >

                //     <span>{props.t('Añadir descripción')}</span>
                    
                // </TextButtonComponent> 
            }
            {/* <SeparatorComponent className="profile-info__separator"/> */}

        </div>        
    )
}

export default withTranslation('profile')(ProfileInformationComponent);