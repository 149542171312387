// Axios
import Axios from "config/axiosConfig";
// Globals
import {api_url} from "config/GlobalData";


export const GetSearchApi = (keyword) => {
  return Axios.instance.get(`${api_url}/search/?search=${keyword}&version=4`,
    {
      headers: {
        'Authorization': localStorage.getItem('token')
      },
      search: keyword
    }
  );

}

export const GetSearchFilterApi = (keyword, filter, next_page) => {
  return Axios.instance.get(
    next_page ? next_page :`${api_url}/search-${filter}s/?search=${keyword}&version=2`,
    { 
      headers: {
        'Authorization': localStorage.getItem('token')
      },
      search: keyword, 
      filter: filter      
    }
  );
}
