// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.text-button {
    mix-blend-mode: normal;
    box-sizing: border-box;
    color: var(--new-green-color);
}
.text-button:focus {
    background-color: var(--white-color);
}
.text-button:hover {
    background-color: var(--white-color);
}
.text-button:active {
    background-color: var(--white-color);
}
.text-button:disabled {
    color: var(--disabled-color);
    background-color: var(--white-color);
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/text-button/TextButtonComponent.styles.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,6BAA6B;AACjC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,4BAA4B;IAC5B,oCAAoC;AACxC","sourcesContent":["\n.text-button {\n    mix-blend-mode: normal;\n    box-sizing: border-box;\n    color: var(--new-green-color);\n}\n.text-button:focus {\n    background-color: var(--white-color);\n}\n.text-button:hover {\n    background-color: var(--white-color);\n}\n.text-button:active {\n    background-color: var(--white-color);\n}\n.text-button:disabled {\n    color: var(--disabled-color);\n    background-color: var(--white-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
