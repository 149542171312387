//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentSaveTournamentModalComponent.style.css';


function TournamentSaveTournamentModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}
            onClick={()=>{}}
        
        >
            <p className='baloa-headline-5'>Torneo creado</p> 
            <p className='baloa-body-1'>Podrás seguir creando fechas y partidos cuando abras el torneo de nuevo.</p>
            
            <div className="login-modal-actions"> 

                <SecondaryButtonComponent onClick={ props.onClose }>
                    <span>CONTINUAR</span>
                </SecondaryButtonComponent>
                
                <SecondaryButtonComponent onClick={ props.onClose }>
                    <span>GUARDAR</span>
                </SecondaryButtonComponent>
                
            </div>
        </ModalComponent>
    )
}

export default TournamentSaveTournamentModalComponent;