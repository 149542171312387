// React
import React from 'react'

// Styles
import './ProfileAptitudesComponent.style.css'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import ProfileAptitudeComponent from 'app/profiles/components/profile-aptitude/ProfileAptitudeComponent';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileAptitudesComponent( props ) {

    return (

        <Card1Component 
            className={ "profile-aptitudes " + ( props.className ? props.className : '' ) } 
            title={props.t('apt.title')}
            button={ props.is_owner && ( 
                props.profile?.attributes?.aptitudes?.length === 0?
                <span class="hubicon-add_option"></span>
                :
                <span class="hubicon-edit"></span>
            ) }
            onButtonClick={ props.onNewButton }
        >

            <div className="profile-aptitudes__aptitudes">
                {
                    props.profile?.attributes?.aptitudes.map( aptitude => 
                        <ProfileAptitudeComponent 
                            className="profile-aptitudes__aptitudes__aptitude"
                            key = { aptitude.value }
                            aptitude = { aptitude }
                            is_owner = { props.is_owner }
                            onValidateAptitude = { props.onValidateAptitude }
                            onInviteToValidateAptitude = { props.onInviteToValidateAptitude }
                        />
                    )
                }
                {props.profile?.attributes?.aptitudes?.length === 0 &&(
                    props.is_owner? 
                        <div className='baloa-normal'>{props.t('apt.emptyOwnerMessage')}</div> 
                        :
                        <div className='baloa-normal'>{props.t('apt.emptyMessage')}</div> 
                )}
            </div>


        </Card1Component>
        
    )

}

export default withTranslation('profile')(ProfileAptitudesComponent);
