// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-aptitudes {
    border-radius: 14px;
}

.profile-aptitudes .hubicon-add_option{
    font-size: 30px;
    color: var(--basic-gray-color);
}

.profile-aptitudes .hubicon-edit {
    font-size: 24px;
    color: var(--basic-gray-color);
}

.profile-aptitudes__new-button {
    background: var(--white-color);
    border-radius: 2px;
}

.profile-aptitudes__new-button:hover {
    /* background: var(--hover-pb-bg-color); */
    cursor: pointer;
}

.profile-aptitudes__aptitudes {
    margin-top: var(--space);
}

.profile-aptitudes__aptitudes .baloa-normal {
    color: var(--basic-gray-color);
    padding: 40px 0;
    text-align: center;
    margin: 0 auto;
}

.profile-aptitudes__aptitudes__aptitude {
    margin-bottom: var(--space-eighth);
}

.profile__aptitudes .baloa-card-1__title.baloa-normal-medium{
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-aptitudes/ProfileAptitudesComponent.style.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.profile-aptitudes {\n    border-radius: 14px;\n}\n\n.profile-aptitudes .hubicon-add_option{\n    font-size: 30px;\n    color: var(--basic-gray-color);\n}\n\n.profile-aptitudes .hubicon-edit {\n    font-size: 24px;\n    color: var(--basic-gray-color);\n}\n\n.profile-aptitudes__new-button {\n    background: var(--white-color);\n    border-radius: 2px;\n}\n\n.profile-aptitudes__new-button:hover {\n    /* background: var(--hover-pb-bg-color); */\n    cursor: pointer;\n}\n\n.profile-aptitudes__aptitudes {\n    margin-top: var(--space);\n}\n\n.profile-aptitudes__aptitudes .baloa-normal {\n    color: var(--basic-gray-color);\n    padding: 40px 0;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.profile-aptitudes__aptitudes__aptitude {\n    margin-bottom: var(--space-eighth);\n}\n\n.profile__aptitudes .baloa-card-1__title.baloa-normal-medium{\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
