// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament_general_filter_container{
    width: 40rem;
}

.tournament_general_filter_container_box{
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.tournament_general_filter_section_title{
    margin-top: var(--space);
}

.tournament_general_filter_section_title .baloa-table{
    font-weight: var(--font-medium);
}

.tournament_general_filter_container_box_bottom{
    display: flex;
    gap: var(--space-x2);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .tournament_filter.tournament_ubication {
        margin: 0;
    }

    .tournament_general_filter_container{
        width: auto;
    }

    .tournament_general_filter_container_box{
        margin: 0;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/tournament-general-filter/TournamentGeneralFilterComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,SAAS;IACb;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,SAAS;IACb;;AAEJ","sourcesContent":[".tournament_general_filter_container{\n    width: 40rem;\n}\n\n.tournament_general_filter_container_box{\n    display: flex;\n    justify-content: space-between;\n    margin: 20px;\n}\n\n.tournament_general_filter_section_title{\n    margin-top: var(--space);\n}\n\n.tournament_general_filter_section_title .baloa-table{\n    font-weight: var(--font-medium);\n}\n\n.tournament_general_filter_container_box_bottom{\n    display: flex;\n    gap: var(--space-x2);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .tournament_filter.tournament_ubication {\n        margin: 0;\n    }\n\n    .tournament_general_filter_container{\n        width: auto;\n    }\n\n    .tournament_general_filter_container_box{\n        margin: 0;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
