// React
import React from 'react';

// Components
import TableComponent from 'shared/components/dynamic-table/TableComponent';
import PlayersActionComponent from '../league-team-players-actions/PlayersActionComponent';

// Material UI
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Skeleton from '@mui/material/Skeleton';

// Styles
import './LeagueListTeamMembersComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function LeagueListTeamMembersComponent( props ) {

    /* const handleOnAddButtonClick = () => {
        props.handleOnAddButtonClick(true);
    } */

    const handleOnEditActionClick = (info, row) => {
        props.handleOnEditMemberActionClick(row);        
    }

    const handleOnDeleteActionClick = (info, row) => {
        props.handleOnDeleteMemberActionClick(row);        
    }

    return(
        <div className='league-list-team-members__container'>            
            <TableComponent                   
                rows = { props.members }
                columns = { [
                    "photo",
                    "fullname",                         
                    //"username",
                    "team_role",
                    "table_state",
                    //"age", //TODO: no lo trae el api
                ] }
                columns_headers = { [
                    "",
                    props.t('team.tabs.members.nameLabel'),
                    //props.t('team.tabs.members.usernameLabel'),  
                    props.t('team.tabs.members.rolLabel'),
                    props.t('team.tabs.members.stateLabel'),                             
                    //props.t('teams.tabs.members.ageLabel'),//TODO: no lo trae el api
                ] }
                has_image_column = { true }
                image_column = { 0 }
                rows_per_page = { 15 }
                pagination = { true }
                handleOnActionClick = { (v)=>{ props.handleOnMemberActionClick(v) }}
                action_link={false}
                action_column = { true }
                placeholder_search_bar={props.t('team.tabs.members.searchPlaceholder')}
                search_by = "team_role"
                searchbar_by = "fullname"
                order_by = "fullname"
                has_searchbar = { true }
                has_button_filter = { true }
                filter_buttons_captions = { [ props.t("team.tabs.members.player"), props.t("team.tabs.members.coach"), props.t("team.tabs.members.staff") ] }
                filter_buttons_states = { [props.t("team.tabs.members.player"), props.t("team.tabs.members.coach"), props.t("team.tabs.members.staff") ] }
                has_add_button = { false }  
                //add_button_caption = {props.t('team.tabs.members.addMemberButtonLabel')}                                   
                //handleOnAddButtonClick = { handleOnAddButtonClick } 
                team = { false }
                msg_empty_table_title={props.t('team.tabs.members.emptyMemberTableTitle')}
                msg_empty_table_text={props.t('team.tabs.members.emptyMemberTableText')}
                itemClicLabel={ props.t('team.tabs.members.teamsViewMemberLabel') }
                has_optional_button={ false } 
                //optional_button_caption={ props.t('newTeam.forms.players.addMemberWithoutAccountBtn') }
                //handleOnOptionalButtonClick={ props.handleAddBulkMembers }
                actions_component = { 
                    <PlayersActionComponent                        
                        handleOnEditActionClick = {  handleOnEditActionClick }
                        handleOnDeleteActionClick = { handleOnDeleteActionClick }
                        editLabel={props.t('team.tabs.members.editLabel')} 
                        deleteLabel={props.t('team.tabs.members.deleteLabel')}
                    ></PlayersActionComponent>                         
                }
                has_html= { true }
                action_modal_buttons={ true }
                link_column={1}
            >
            </TableComponent>
        </div>
    )
}

export default withTranslation('team')(LeagueListTeamMembersComponent);

