// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-dynamic-form__container {
    width: 100%;
}

.input-dynamic-form__container .input-field {
    /* margin: var(--space-x2) 0; */
    margin: 20px 0;
}

.input-dynamic-form__container._input_date .input-field-input{
    width:40%;
    text-transform: uppercase;
}

.input-dynamic-form__container.invalid_data .input-field-label{
    color: var(--error-color);
    font-weight: var(--font-bold);
}

.input-dynamic-form__container.invalid_data .input-field-input{
    border: 1px solid var(--error-color);
}

.input-dynamic-form__container.invalid_data .input-field{
    margin-bottom: 0px;
}

.input-dynamic-form__error{
    color: var(--error-color);
    margin: 4px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/dynamic-fields-tournament-form/input-dynamic-form/InputDynamicFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".input-dynamic-form__container {\n    width: 100%;\n}\n\n.input-dynamic-form__container .input-field {\n    /* margin: var(--space-x2) 0; */\n    margin: 20px 0;\n}\n\n.input-dynamic-form__container._input_date .input-field-input{\n    width:40%;\n    text-transform: uppercase;\n}\n\n.input-dynamic-form__container.invalid_data .input-field-label{\n    color: var(--error-color);\n    font-weight: var(--font-bold);\n}\n\n.input-dynamic-form__container.invalid_data .input-field-input{\n    border: 1px solid var(--error-color);\n}\n\n.input-dynamic-form__container.invalid_data .input-field{\n    margin-bottom: 0px;\n}\n\n.input-dynamic-form__error{\n    color: var(--error-color);\n    margin: 4px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
