// React
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

// Routes
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';

//Components
import CreatePostModalComponent from 'app/posts/components/create-post-modal/CreatePostModalComponent';
import CreateFormationModalComponent from 'app/posts/components/create-formation-modal/CreateFormationModalComponent';

// Actions
import { 
  changeHomeNewPostModal,
  changePostNewFormationModal,
} from 'app/home/actions/HomeActionsCreators';

class CreatePostLayout extends Component {
  
  render() {
    
    return (
  
      <React.Fragment>
        
        <CreatePostModalComponent 
          onClick={() => {}} 
          isOpen={ this.props.state.home.modals.new_post_is_open } 
          onClose={() => { this.props.changeHomeNewPostModal( false ) }} 
        />
        <CreateFormationModalComponent 
          onClick={() => {}} 
          isOpen={ this.props.state.home.modals.new_formation_is_open } 
          onClose={() => { this.props.changePostNewFormationModal( false ) }} 
        />
        
        <Switch>
          {this.props.routes.map(route => (
            <RouteWithSubRoutes {...route} key={route.key} />
          ))}
        </Switch>
        
      </React.Fragment>

    );

  }
}

// Redux connection
const mapStateToProps = state => {
  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeHomeNewPostModal: ( new_modal_state ) => {
      return dispatch(changeHomeNewPostModal( new_modal_state ))
    },
    changePostNewFormationModal: ( new_modal_state ) => {
      return dispatch(changePostNewFormationModal( new_modal_state ))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePostLayout);