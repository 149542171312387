// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.technics-form-2 {}
.technics-form-2__form {
}
.technics-form-2__form__fields {
    padding: var(--space-x2-and-half) var(--space);
    display: flex;
    flex-direction: column;
}
.technics-form-2__form__fields__group {
    margin: var(--space-x3) var(--space-x3) 0 var(--space-x3);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-and-half);
}
.technics-form-2__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--new-input-background-color);
}
.technics-form-2__form__input {
    margin: 0 !important;
}
.technics-form-2__form__select {
    margin: 0 !important;
}
.technics-form-2__form__submit {}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-technics-meassures-modal/ProfileTechnicsMeassuresModalComponent.styles.css"],"names":[],"mappings":";AACA,kBAAkB;AAClB;AACA;AACA;IACI,8CAA8C;IAC9C,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,yDAAyD;IACzD,aAAa;IACb,8BAA8B;IAC9B,+BAA+B;AACnC;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,uDAAuD;AAC3D;AACA;IACI,oBAAoB;AACxB;AACA;IACI,oBAAoB;AACxB;AACA,gCAAgC","sourcesContent":["\n.technics-form-2 {}\n.technics-form-2__form {\n}\n.technics-form-2__form__fields {\n    padding: var(--space-x2-and-half) var(--space);\n    display: flex;\n    flex-direction: column;\n}\n.technics-form-2__form__fields__group {\n    margin: var(--space-x3) var(--space-x3) 0 var(--space-x3);\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: var(--space-and-half);\n}\n.technics-form-2__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    border-top: 1px solid var(--new-input-background-color);\n}\n.technics-form-2__form__input {\n    margin: 0 !important;\n}\n.technics-form-2__form__select {\n    margin: 0 !important;\n}\n.technics-form-2__form__submit {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
