/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Shapes
import { NotificationShape } from 'app/notifications/shapes/NotificationShape';

// Styles
import './NotificationPopoverItemComponent.styles.css';

// Functions
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


// Notification Popover Item Component:
// Renders Notification items and handles their actions.

function NotificationPopoverItemComponent(props) {

    const handleClickNotification = (notification) => {
        props.setReadNotification(notification.id);
    };

    return (
        <div className={"notifications-popover-item " + (props.className ? props.className : '')}>
            <div className="notifications-popover-item__image">
                <div className={"notifications-popover-item__image__photo"}>
                    {props.notification.author.photo &&
                        <img className="notifications-popover-item__image__photo__image" src={props.notification.author.photo} alt={`${props.notification.author.username} img`} />
                    }
                    {!props.notification.author.photo &&
                        <span className={"notifications-popover-item__image__photo__image icon-ball"} color={getRandonIconBallColor()}></span>
                    }

                </div>
                <div className="notifications-popover-item__image__icon">
                    {/* <span className={ "notifications-popover-item__image__icon__photo " + 
                        ( props.notification.action == 'Follow' && ' icon-user ' ) +
                        ( props.notification.action == 'Comment' && ' icon-message ' ) +
                        ( props.notification.action == 'Validate' && ' icon-check-single-72 ' ) +
                        ( props.notification.action == 'Clap' && ' icon-claps-48 ' )
                    }></span> */}
                    {props.notification.action == 'Clap' &&
                        <span class="iconr-clap">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                        </span>
                    }

                    {props.notification.action === 'Support' &&
                        <span class="iconr-support">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4" />
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8" />
                        </span>
                    }
                    {props.notification.action === 'Its_Funny' &&
                        <span class="iconr-funny">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    }
                    {props.notification.action === 'Red_Card' &&
                        <span class="iconr-red-card">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                        </span>
                    }
                    {props.notification.action === 'Celebrate' &&
                        <span class="iconr-celebrate">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    }
                    {props.notification.action === 'Sad' &&
                        <span class="iconr-sad">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    }
                </div>
            </div>
            <div className="notifications-popover-item__content"
            >
                {((props.notification.action === 'Follow'                    
                    || props.notification.action === 'Add'
                    || props.notification.action === 'Update'
                    || props.notification.action === 'Create')
                    &&
                    <Link to={`/${props.notification.author.is_type}/${props.notification.author.is_type === "page"? props.notification.author.pagename : props.notification.author.username}`} onClick={() => { handleClickNotification(props.notification) }}>
                        <div className="notifications-popover-item__content__caption">
                            <p className="notifications-popover-item__content__caption__text baloa-body-2">{props.notification.content}</p>
                            <p className="notifications-popover-item__content__caption__date baloa-body-2">{convertDateToMoment(props.notification.created)}</p>
                        </div>
                    </Link>)
                    ||
                    (props.notification.action === 'Validate' &&
                        <Link to={`/profile/${props.me}`} onClick={() => { handleClickNotification(props.notification) }}>
                            <div className="notifications-popover-item__content__caption">
                                <p className="notifications-popover-item__content__caption__text baloa-body-2">{props.notification.content}</p>
                                <p className="notifications-popover-item__content__caption__date baloa-body-2">{convertDateToMoment(props.notification.created)}</p>
                            </div>
                        </Link>
                    )
                    ||
                    (props.notification.action === 'Invitations_Player' &&
                        <Link to={`/tournament/inscriptions`} onClick={() => { handleClickNotification(props.notification) }}>
                            <div className="notifications-popover-item__content__caption">
                                <p className="notifications-popover-item__content__caption__text baloa-body-2">{props.notification.content}</p>
                                <p className="notifications-popover-item__content__caption__date baloa-body-2">{convertDateToMoment(props.notification.created)}</p>
                            </div>
                        </Link>
                    )
                    ||
                    (props.notification.action === 'Invitations_Team' &&
                        <Link to={`/tournament/invitations`} onClick={() => { handleClickNotification(props.notification) }}>
                            <div className="notifications-popover-item__content__caption">
                                <p className="notifications-popover-item__content__caption__text baloa-body-2">{props.notification.content}</p>
                                <p className="notifications-popover-item__content__caption__date baloa-body-2">{convertDateToMoment(props.notification.created)}</p>
                            </div>
                        </Link>
                    )
                    ||
                    <Link to={`/posts/${props.notification.obj_id}`} onClick={() => { handleClickNotification(props.notification) }}>
                        <div className="notifications-popover-item__content__caption">
                            <p className="notifications-popover-item__content__caption__text baloa-body-2">{props.notification.content}</p>
                            <p className="notifications-popover-item__content__caption__date baloa-body-2">{convertDateToMoment(props.notification.created)}</p>
                        </div>
                    </Link>
                }
                
            </div>

        </div>
    )
}

// Types
NotificationPopoverItemComponent.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape(NotificationShape)
    ),
}

// Exports
export default NotificationPopoverItemComponent;
