// React
import React from 'react';
import { Link } from 'react-router-dom';

//formik
import { useFormik } from 'formik';

//Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SearchResultBoxComponent from 'app/search/components/search-result-box/SearchResultBoxComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './SearchInputComponent.styles.css';

// Material UI
import SearchIcon from '@mui/icons-material/Search';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function SearchInputComponent(props) {
    const { children, value, index } = props; // eslint-disable-line
    const formikvalues = useFormik({
        initialValues: {
            searchfield: props.searchfield ? props.searchfield : "",
        },
    });
    const [cleanValue, setCleanValue] = React.useState(null);
    const formRef = React.createRef();

    

    React.useEffect( () => {
        if( props.show_sbox) { 
            setCleanValue(null);
        }
    }, [ props.show_sbox ] );// eslint-disable-line

    return(
        <React.Fragment>
            {isMobile &&
                <span className="icon-arrow-left" onClick={() => {props.handleMobileSearch()}}/>
            }   
            <FormComponent
                formInitialValues={ formikvalues.initialValues }
                onSubmit={( values, actions ) => {
                    props.onSearch( values );
                }}
                className="search-form__form form"
                autocomplete="off"
                onBlur={ ()=> {props.onBlur(); /* formRef.current?.setFieldValue("searchfield", ''); setCleanValue('') */}  }
                innerRef={formRef}
            >                     
                <InputFieldComponent
                    label={props.t('search')}
                    input_id="searchfield"
                    field_name="searchfield"
                    disabled={false}
                    className="search-form__form__input"
                    input_type="text"
                    resetError={ () => props.onResetError( { 'searchfield': "" } ) }
                    error={ props.errors ? props.errors : ""}
                    message=""
                    placeholder={props.t('search')} 
                    onChange={ props.onChange } 
                    onFocus={ props.onFocus }
                    value={cleanValue}
                />
                <SearchIcon />
                
                <div class="clearfix"></div>
                <SearchResultBoxComponent
                    show_sbox= {props.show_sbox}
                    results = {props.results? props.results : []}
                    keyword = {props.keyword}
                    handleMobileSearch={props.handleMobileSearch}
                    handleSearchLinkTo={props.handleSearchLinkTo}
                />
            </FormComponent>
            {isMobile &&
                <Link to={`/search/search=${props.keyword}`}>
                    <TextButtonComponent
                        className="search-form__mobile-button"
                        input_type={'submit'}
                        //onClick={()=>{formRef.current.handleSubmit()}}
                    >
                        <span>{props.t('search')}</span>
                    </TextButtonComponent>
                </Link>
            }
        </React.Fragment>
    )
};

export default withTranslation('common')(SearchInputComponent);
