import React from 'react'
import TournamentFilterItemComponent from '../tournament-filter-state/TournamentFilterItemComponent';

//i18
import { withTranslation } from 'react-i18next';

import './TournamentGeneralFilterComponent.styles.css'

// React device detect
import { isMobile } from 'react-device-detect';

function TournamentGeneralFliterComponent(props) {
    return (
        <React.Fragment>
        {!isMobile &&
            <div className='tournament_general_filter_container'>
                <div className='tournament_general_filter_container_box'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelState')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_state}
                            field_id={'tournament_state'}
                            filterType={'tournament_state'}
                            selectedFilter={props.selected_filter_state}
                            tournament_subsection={props.tournament_subsection}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                        />
                    </div>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{ props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelGenre')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_gender}
                            field_id={'tournament_gender'}
                            filterType={'tournament_gender'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedFilter={props.selected_filter_gender}
                        />
                    </div>
                </div>
                <div className='tournament_general_filter_section_title'>
                    <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelCatergory')}</p>
                    <TournamentFilterItemComponent
                        tournament_options={props.tournament_options_tag}
                        field_id={'tournament_category'}
                        filterType={'tournament_category'}
                        handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                        selectedFilter={props.selected_filter_category}
                    />
                </div>
                <div className='tournament_general_filter_container_box_bottom'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelFootballType')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_FootballType}
                            field_id={'tournament_type_soccer'}
                            filterType={'tournament_type_soccer'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedFilter={props.selected_filter_footballType}
                        />
                    </div>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelUbication')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={[]}
                            field_id={'tournament_ubication'}
                            filterType={'tournament_ubication'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedCities={props.selected_filter_ubication}
                            handleSearchWorldCities={props.handleSearchWorldCities}
                            world_cities={props.world_cities}
                        />
                    </div>

                </div>
            </div>
        }
        {isMobile &&
            <div className='tournament_general_filter_container'>
                <div className='tournament_general_filter_container_box'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelState')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_state}
                            field_id={'tournament_state'}
                            filterType={'tournament_state'}
                            selectedFilter={props.selected_filter_state}
                            tournament_subsection={props.tournament_subsection}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                        />
                    </div>
                </div>
                <div className='tournament_general_filter_container_box'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{ props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelGenre')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_gender}
                            field_id={'tournament_gender'}
                            filterType={'tournament_gender'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedFilter={props.selected_filter_gender}
                        />
                    </div>
                </div>
                <div className='tournament_general_filter_section_title'>
                    <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelCatergory')}</p>
                    <TournamentFilterItemComponent
                        tournament_options={props.tournament_options_tag}
                        field_id={'tournament_category'}
                        filterType={'tournament_category'}
                        handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                        selectedFilter={props.selected_filter_category}
                    />
                </div>
                <div className='tournament_general_filter_container_box'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelFootballType')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={props.tournament_options_FootballType}
                            field_id={'tournament_type_soccer'}
                            filterType={'tournament_type_soccer'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedFilter={props.selected_filter_footballType}
                        />
                    </div>
                </div>
                <div className='tournament_general_filter_container_box'>
                    <div className='tournament_general_filter_section_title'>
                        <p className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelUbication')}</p>
                        <TournamentFilterItemComponent
                            tournament_options={[]}
                            field_id={'tournament_ubication'}
                            filterType={'tournament_ubication'}
                            handleFilterButtonsFormat={props.handleFilterButtonsFormat}
                            selectedCities={props.selected_filter_ubication}
                            handleSearchWorldCities={props.handleSearchWorldCities}
                            world_cities={props.world_cities}
                        />
                    </div>

                </div>
            </div>
        }
        </React.Fragment>
    )
}

export default withTranslation('league')(TournamentGeneralFliterComponent)