// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HubTournamentMatchesWidgetComponent.styles.css';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';


function HubTournamentMatchesWidgetComponent( props ) {

    const history = useHistory();
    const clickTournament = (tournament_id) => {
        history.push(`/tournament/hub/${tournament_id}`);
    }

    const matchClick = (tournament_id, phase_id, match_date_id, match_id, participate) => {
        history.push(`/tournament/hub/${tournament_id}&phase=${phase_id}&match_date=${match_date_id}&match=${match_id}&type=match&participate=${participate}`);
    }

    return (
        <div className='hub-tournament-matches-widget__container'>
            <div className='hub-tournament-section-card__section' >
                <div className='baloa-subtitle-1'>{props.t('tournamentHubSections.dateMatches')}</div>
                <div className='baloa-normal-medium' onClick={() => { props.handleOnSubsectionViewMore('matches') }}>{props.t(`tournamentHubSections.viewMore`)}</div>
            </div>
            <div className='hub-tournament-matches-widget__matches-container'>
                {props.date_matches?.slice(0, 3).map(matches => (
                    (!isMobile && 
                        <div className='hub-tournament-matches-widget__matches__match' key={matches.match_id}>
                            <div className='hub-tournament-matches-widget__tournament_info' onClick={ ()=>{ clickTournament(matches.tournament_id) } }>
                                <div className='hub-tournament-matches-widget__tournament_info__img'>
                                    { matches.tournament_photo
                                        ? <img src={matches.tournament_photo} alt={`${matches.tournament_name} logo`} />
                                        : <span className='icon-ball' color={getRandonIconBallColor()} />
                                    }
                                </div>
                                <div className='baloa-table-column'>{matches.tournament_name}</div>                                                                         
                            </div>
                            <div className='hub-tournament-matches-widget__matches__match-date baloa-username' onClick={()=>{ matchClick(matches.tournament_id, matches.tournament_phase_id, matches.match_date_id,matches.match_id,matches.participate) }}>
                                    {`${moment(matches.match_date + ' ' + matches.match_time).format('MMM DD, YYYY - h:mm A')}`}
                            </div>                                                                       

                            <div className='hub-tournament-matches-widget__matches__match-team baloa-names' onClick={()=>{ matchClick(matches.tournament_id, matches.tournament_phase_id, matches.match_date_id,matches.match_id,matches.participate) }}>
                                    { matches.local_team_photo
                                        ? <img src={matches.local_team_photo} alt={`${matches.local_tournament_team_name} logo`} />
                                        : <span className='icon-ball' color={getRandonIconBallColor()} />
                                    }
                                    {matches.local_tournament_team_name? matches.local_tournament_team_name : matches.local_team_name}
                                    {(matches.match_state === 'Finalized' || matches.state === 'Finalized') &&
                                        <div className='hub-tournament-matches-widget__matches__match-team-score' >{`${matches.local_team_score}`}</div>
                                    }
                            </div>
                            <div className='hub-tournament-matches-widget__matches__match-team baloa-names' onClick={()=>{ matchClick(matches.tournament_id, matches.tournament_phase_id, matches.match_date_id,matches.match_id,matches.participate) }}>
                                    { matches.visitant_team_photo
                                        ? <img src={matches.visitant_team_photo} alt={`${matches.visitant_tournament_team_name} logo`} />
                                        : <span className='icon-ball' color={getRandonIconBallColor()} />
                                    }
                                    {matches.visitant_tournament_team_name? matches.visitant_tournament_team_name : matches.visitant_team_name}
                                    {(matches.match_state === 'Finalized' || matches.state === 'Finalized') &&
                                        <div className='hub-tournament-matches-widget__matches__match-team-score' >{`${matches.visitant_team_score}`}</div>
                                    }
                            </div>
                        </div>
                    )
                    ||
                    (isMobile &&
                        <div className='hub-tournament-matches-widget__matches__match' key={matches.match_id}>
                            <div className='hub-tournament-matches-widget__matches__match-info' >
                                <div className='hub-tournament-matches-widget__tournament_info' onClick={ ()=>{ clickTournament(matches.tournament_id) } }>
                                    <div className='hub-tournament-matches-widget__tournament_info__img'>
                                        { matches.tournament_photo
                                            ? <img src={matches.tournament_photo} alt={`${matches.tournament_name} logo`} />
                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                        }
                                    </div>
                                    <div className='baloa-table-column'>{matches.tournament_name}</div>                                                                         
                                </div>
                                {matches.match_state === 'Scheduled' || matches.state === 'Scheduled'?
                                    <div className='hub-tournament-matches-widget__matches__match-date baloa-table-column'>
                                            {`${moment(matches.match_date + ' ' + matches.match_time).format('MMM DD, YYYY - h:mm A')}`}
                                    </div>
                                    :
                                    <div className='hub-tournament-matches-widget__matches__match-state baloa-table-column'>
                                        {props.t(`league:matchAdminFilter.filterLabels.${matches.match_state? matches.match_state : matches.state}`)}
                                    </div>
                                }
                            </div>
                            <div className='hub-tournament-matches-widget__matches__match-teams-info' onClick={()=>{ matchClick(matches.tournament_id, matches.tournament_phase_id, matches.match_date_id,matches.match_id,matches.participate) }}>
                                <div className='hub-tournament-matches-widget__matches__match-team baloa-names'>                                                        
                                        {matches.local_tournament_team_name? matches.local_tournament_team_name : matches.local_team_name}
                                        { matches.local_team_photo
                                            ? <img src={matches.local_team_photo} alt={`${matches.local_tournament_team_name} logo`} />
                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                        }
                                        {(matches.match_state === 'Finalized' || matches.state === 'Finalized')?
                                            <div>{`${matches.local_team_score} -`}&nbsp;</div>
                                            :
                                            <div>&nbsp;{'V'}</div>
                                        }
                                </div>
                                <div className='hub-tournament-matches-widget__matches__match-team baloa-names'>
                                        {(matches.match_state === 'Finalized' || matches.state === 'Finalized')?
                                            <div>{`${matches.visitant_team_score}`}</div>
                                            :
                                            <div>{'S'}&nbsp;</div>
                                        }
                                        { matches.visitant_team_photo
                                            ? <img src={matches.visitant_team_photo} alt={`${matches.visitant_tournament_team_name} logo`} />
                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                        }
                                        {matches.visitant_tournament_team_name? matches.visitant_tournament_team_name : matches.visitant_team_name}
                                </div>
                            </div>
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentMatchesWidgetComponent);