
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './ProfileDescriptionModalComponent.styles.css';

// Validators
import { AnyValidator } from 'shared/validators/SignUpValidator';

// i18n
import { withTranslation } from 'react-i18next';



function ProfileDescriptionModalComponent( props ) {
    
    const formInitialValues = {
        description: props.model.attributes?.description?.value,
    }

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('data.modal.title2')}
        >
            <div className="technics-form">

                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        let new_attribute_data = { ...props.model.attributes?.description, value: values['description'] };
                        props.onNewData( new_attribute_data )
                    }}
                    className="technics-form__form form"
                    functionDisabled={()=>{}}
                >
                    <div className="technics-form__form__fields form__fields">
                        <InputFieldMultilineComponent 
                            label={props.t('data.modal.input')}
                            input_id="profile-description" 
                            field_name="description"
                            validationFunction={ AnyValidator }
                            disabled={false}
                            className="technics-form__form__input"
                            input_type="text"
                            resetError={ () => props.onResetError( { 'description': "" } )}
                            error={ props.errors }
                            message=""
                            placeholder=""
                            rows={5}
                        />
                    </div>

                    <div className="technics-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="technics-form__form__submit"
                        >
                            <span>{props.t('data.modal.btnSave')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileDescriptionModalComponent);