// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-post-video-options-modal .modal__container{
    padding: 0px;
}

.profile-post-video-options_list{
    width: 450px;
}

.profile-post-video-option_delete_subtitle,
.profile-post-video-options_list_button,
.profile-post-video-options_list_delete_button{
    width: 100%;
    padding: var(--space);
    text-align: center;
    border-bottom: 1px solid var(--new-gray-background-color);
}

.profile-post-video-options_list_cancel_button{
    width: 100%;
    padding: var(--space);
    text-align: center;
}

.profile-post-video-options_list_cancel_button.baloa-names{
    font-weight: var(--font-regular);
}

.profile-post-video-options_list_delete_button.baloa-names{
    color: var(--error-color);
}

.profile-post-video-options_list_button:hover,
.profile-post-video-options_list_delete_button:hover,
.profile-post-video-options_list_cancel_button:hover{
    cursor: pointer;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .profile-post-video-options-modal .modal__container{
        width: 80%;
    }

    .profile-post-video-options_list{
        width: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-post-video-clips-options-modal/ProfilePostVideoClipsOptionsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;;;IAGI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,yDAAyD;AAC7D;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;IAGI,eAAe;AACnB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".profile-post-video-options-modal .modal__container{\n    padding: 0px;\n}\n\n.profile-post-video-options_list{\n    width: 450px;\n}\n\n.profile-post-video-option_delete_subtitle,\n.profile-post-video-options_list_button,\n.profile-post-video-options_list_delete_button{\n    width: 100%;\n    padding: var(--space);\n    text-align: center;\n    border-bottom: 1px solid var(--new-gray-background-color);\n}\n\n.profile-post-video-options_list_cancel_button{\n    width: 100%;\n    padding: var(--space);\n    text-align: center;\n}\n\n.profile-post-video-options_list_cancel_button.baloa-names{\n    font-weight: var(--font-regular);\n}\n\n.profile-post-video-options_list_delete_button.baloa-names{\n    color: var(--error-color);\n}\n\n.profile-post-video-options_list_button:hover,\n.profile-post-video-options_list_delete_button:hover,\n.profile-post-video-options_list_cancel_button:hover{\n    cursor: pointer;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .profile-post-video-options-modal .modal__container{\n        width: 80%;\n    }\n\n    .profile-post-video-options_list{\n        width: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
