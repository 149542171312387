// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-confirm-dialog__title {
    max-width: 397px;
    margin: var(--space-and-half) 0;    
}

.modal-confirm-dialog__text{
    max-width: 280px;
    margin-top: calc(var(--space-half) + 2px);
}

.modal-confirm-dialog__actions {

    margin-top: var(--space-x2);
    border-top: 1px solid #ECECEC;
    padding-top: var(--space-half);
}

.modal-confirm-dialog__actions {
    display: flex;
    justify-content: flex-end;
}

.modal-confirm-dialog__actions .button {    
    padding: var(--space-half) var(--space-and-half);
}

.modal-confirm-dialog__actions .button:last-of-type {    
    background-color: var(--error-color);
    color: var(--white-color);
    font-size: 15px;
    line-height: 18px;
}

.modal-confirm-dialog__actions .button:first-of-type {    
    color: var(--basic-gray-color);
    border-color: var(--basic-gray-color);
    font-size: 15px;
    line-height: 18px;
    margin-right: var(--space);
}

.modal-confirm-dialog__actions .button:first-of-type:hover {    
    background: none;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/modal-confirm-dialog/ModalConfirmDialogComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;IACI,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;;IAEI,2BAA2B;IAC3B,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,qCAAqC;IACrC,eAAe;IACf,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".modal-confirm-dialog__title {\n    max-width: 397px;\n    margin: var(--space-and-half) 0;    \n}\n\n.modal-confirm-dialog__text{\n    max-width: 280px;\n    margin-top: calc(var(--space-half) + 2px);\n}\n\n.modal-confirm-dialog__actions {\n\n    margin-top: var(--space-x2);\n    border-top: 1px solid #ECECEC;\n    padding-top: var(--space-half);\n}\n\n.modal-confirm-dialog__actions {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.modal-confirm-dialog__actions .button {    \n    padding: var(--space-half) var(--space-and-half);\n}\n\n.modal-confirm-dialog__actions .button:last-of-type {    \n    background-color: var(--error-color);\n    color: var(--white-color);\n    font-size: 15px;\n    line-height: 18px;\n}\n\n.modal-confirm-dialog__actions .button:first-of-type {    \n    color: var(--basic-gray-color);\n    border-color: var(--basic-gray-color);\n    font-size: 15px;\n    line-height: 18px;\n    margin-right: var(--space);\n}\n\n.modal-confirm-dialog__actions .button:first-of-type:hover {    \n    background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
