// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import scriptjs from 'scriptjs';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Assets
//import google_logo_icon from 'assets/icons/google-logo-icon.svg';
//import facebook_logo_icon from 'assets/icons/facebook-logo-icon.svg';
//import apple_logo_icon from 'assets/icons/apple-logo-icon.svg';
//import {  ReactComponent as AppleLogoIcon   } from 'assets/icons/apple-logo-icon.svg';
//import phone_icon from 'assets/icons/phone-icon.svg';
import baloa_logo from 'assets/images/logo_baloa.svg';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TermsAndConditionsModalComponent from 'app/authentication/components/terms-and-conditions-modal/TermsAndConditionsModalComponent';

// Styles
import './SignUpLoginPanelComponent.styles.css';

// Actions
import { ChangeErrorLogin } from 'app/authentication/actions/ErrosLogin';
import { changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { signUpWithSocialAction, getTermsAndConditionsAction, loadCountryInLogin, loadProfessionTypes } from 'app/authentication/actions/LoginActions';
import {changeTermsModal} from 'app/authentication/actions/LoginActionsCreators';

// Facebook Social Auth Button
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//Google Social Auth Button
//import GoogleLogin from 'react-google-login';

//Apple Social Auth Button
//import AppleLogin from 'react-apple-login'

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

class SignUpStartPanelComponent extends Component {

    componentDidMount () {
        scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', async () => {
            const params = {
                clientId: 'com.baloa.singinservice',
                redirectURI: 'https://dev.baloasports.com/callbacks/sign_in_with_apple',
                //redirectURI: 'com.baloa.singinservice',                
                //redirectURI: 'https://dev.baloasports.com',
                scope: 'name email', 
                //state : "initial",
                usePopup: true,
            };
            const data = await window.AppleID.auth.init(params); //eslint-disable-line
            //console.log("Respuesta AppleID", data);
        });
        this.props.getTermsAndConditionsAction();
        this.props.loadCountryInLogin();
        this.props.loadProfessionTypes();
    }

    

    render = () => {
        return (
            <React.Fragment>
                {/* Modals */}
                <TermsAndConditionsModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.signup.terms_modal } 
                    onClose={() => { this.props.changeTermsModal( false ) }} 
                    terms={this.props.state.signup.terms_conditions}
                    checkTerms = { () => {} }
                />
                
                {/* <div className="new-account"> */}
                <div className="signup-login-panel__container">
                    <div className="signup-login-panel__left-col">
                        <Link to="/">
                            <img className="signup-header__image" src={baloa_logo} alt=""/>
                        </Link>
                    </div>
                    <div className="signup-login-panel__right-col">
                        <div className="signup-login-panel__title baloa-headline-1">{this.props.t('loginEmail.title')}</div>
                        <div className='signup-login-panel__subtitle baloa-subtitle-2' >{this.props.t('login.loginSubtitle')}</div>
                        <div className="new-account__methods">
                            <SecondaryButtonComponent
                                onClick={() => { this.props.changeSignUpStatus(SIGNUP_STATUS['PHONE']) }}
                                className="large signup-login-panel__methods__method phone"
                            >
                                <span className="hubicon-phone" />
                                {/* <img src={phone_icon} alt="" /> */}
                                <span className="signup-login-panel__methods__method__label">{this.props.t('page.button')}</span>

                                {/* <img src={phone_icon} alt="" /><span>{this.props.t('page.button')}</span> */}
                            </SecondaryButtonComponent>
                            <SecondaryButtonComponent
                                onClick={() => { this.props.changeSignUpStatus(SIGNUP_STATUS['LOGIN_EMAIL']) }} 
                                className="large signup-login-panel__methods__method"
                            >
                                <span className="icon-email" />
                                <span className="signup-login-panel__methods__method__label">{this.props.t('email.btnLabel')}</span>
                            </SecondaryButtonComponent>
                            <SecondaryButtonComponent
                                onClick={() => { this.props.changeSignUpStatus(SIGNUP_STATUS['CLAIM_ACCOUNT']) }} 
                                className="large signup-login-panel__methods__method claim"
                            >
                                <span className="signup-login-panel__methods__method__label">{this.props.t('claimAccount.cAccClaimBtnLbl')}</span>
                            </SecondaryButtonComponent>
                            <Link to="/" className='signup-login-panel__guest-mode'>
                                <div className='baloa-normal-medium'>{this.props.t('login.loginGuest')}</div>
                            </Link>

                            <div className='signup-login-panel__terms-conditions baloa-normal' onClick={() => {this.props.changeTermsModal(true)}} >
                                {this.props.t('login.loginTermsConditionsLabel')} <span>{this.props.t('login.loginTermsConditions')}</span>
                            </div>


                            {/* <SeparatorComponent className="new-account__separator" /> */}
                            {/* <div className="signup-login-panel__separator__container">
                                <div className="signup-login-panel__separator" />
                                <span>o</span>
                                <div className="signup-login-panel__separator" />
                            </div> */}
                                
                            {/* <GoogleLogin
                                autoLoad={false}
                                //clientId="86128099741-0qbt1o8gqtukuspavks1aleertm1t3h0.apps.googleusercontent.com"
                                clientId="773972066940-o9m3fp4s258f90fua21aj9jglocu50d4.apps.googleusercontent.com" //Google localhost
                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={renderProps.onClick}
                                        className="large signup-login-panel__methods__method">
                                        <img src={google_logo_icon}  alt="" /><span>Google</span>
                                    </SecondaryButtonComponent>
                                )}
                                onSuccess={(data) => {                            
                                    this.props.signUpWithSocialAction(data.accessToken, 'google-oauth2')
                                }}
                                cookiePolicy={'single_host_origin'}
                                icon={false}
                            /> */}

                            {/* <FacebookLogin
                                appId="1203616303336440"   
                                callback={(data) => {
                                    this.props.signUpWithSocialAction(data.accessToken, 'facebook')
                                }}

                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={() => { renderProps.onClick() }}
                                        className="large signup-login-panel__methods__method">
                                        <img src={facebook_logo_icon} alt="" /><span>Facebook</span>
                                    </SecondaryButtonComponent>
                                )}
                            /> */}

                            {/* <AppleLogin 
                                callback={(data) => {

                                    if(!data.error){
                                        this.props.signUpWithSocialAction(data.authorization.id_token, 'apple-id')                                
                                    }
                                    
                                }}
                                clientId="com.baloa.singinservice" 
                                redirectURI="https://dev.baloasports.com/callbacks/sign_in_with_apple" 
                                usePopup = {true}
                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={() => { renderProps.onClick() }}
                                        className="large signup-login-panel__methods__method signup-login-panel__methods__method__apple">
                                        <AppleLogoIcon className="signup-start-panel__apple-logo" alt="" /><span>Apple</span>
                                    </SecondaryButtonComponent>
                                )}
                            /> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChangeErrorLogin: (values) => {
            return dispatch(ChangeErrorLogin(values))
        },
        changeSignUpStatus: (value) => {
            return dispatch(changeSignUpStatus(value))
        },
        signUpWithSocialAction: (value, social_network) => {
            return dispatch(signUpWithSocialAction(value, social_network));
        },
        changeTermsModal: ( new_modal_state ) => {
            return dispatch( changeTermsModal( new_modal_state ))
        },
        getTermsAndConditionsAction:( ) => {
            return dispatch( getTermsAndConditionsAction())
        },
        loadCountryInLogin: () => {
            return dispatch( loadCountryInLogin())
        },
        loadProfessionTypes: () => {
            return dispatch( loadProfessionTypes())
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpStartPanelComponent);
