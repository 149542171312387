// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './RecoverPasswordPanelComponent.styles.css';

// Actions
//import { ChangeErrorLogin } from 'app/authentication/actions/ErrosLogin';
import { signUpSendEmailRecoverPasswordAction } from 'app/authentication/actions/LoginActions';
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Validators
//import { PhoneValidator } from 'shared/validators/PhoneValidator';
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';


class RecoverPasswordPanelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            disabled: false,
        };
    };

    
    

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    // resetErrorVerifyPassword(){
    //     const dataVerifyPassword = {
    //         'field': 'confirm_password',
    //         'error': ''
    //     }
    //     this.props.changeSignUpError( dataVerifyPassword);
    // }
    
    
    render = () => {
        
        const formInitialValues = {
            email: "",
        }
        
        return (
            <div className="recover-password-panel__container">
                <div className="recover-password-panel__left-col"></div>
                <div className="recover-password-panel__right-col">
                    <h2 className="recover-password-panel__title">Recuperar contraseña</h2>
                    {/* <h3 className="recover-password-panel__title">{this.props.t('page.title')}</h3>
                    <p className="recover-password-panel__text">{this.props.t('email.text')}</p> */}
                    <text className="recover-password-panel__text">Ingresa el correo electrónico con el que te registraste, te enviaremos un codigo para recuperar tu contraseña</text>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { 
                            this.props.signUpSendEmailRecoverPasswordAction(values.email);
                            }
                        }
                        className = "recover-password-panel__form"
                        functionDisabled = { this.disabled.bind( this ) }
                    >
                        <div className="login-email-form__fields">
                            
                            <label for="email">Correo Electrónico</label>
                            <InputFieldComponent
                                input_id="recover-password-panel__email"
                                field_name="email"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""
                                type="text"
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                            />
                            
                                                      
                        </div>
                        <div className="recover-password-panel__form__submit">
                            <PrimaryButtonComponent  disabled={false} onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN_EMAIL'] )} className="recover-password-panel__form__login">
                                <span>Iniciar sesión</span>
                            </PrimaryButtonComponent>
                            <PrimaryButtonComponent type="submit" disabled={!this.state.disabled} onClick={() => { }} className="recover-password-panel__form__submit">
                                <span>{this.props.t('code.button')}</span>
                            </PrimaryButtonComponent>                            
                        </div>                        
                    </FormComponent>
                    
                    
                </div>
            </div>
        )
    }
}




// HomeView.propTypes = {
//     appTitle: PropTypes.string,
// }

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpSendEmailRecoverPasswordAction: (email_values) => {
            return dispatch(signUpSendEmailRecoverPasswordAction(email_values));
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch(  changeSignUpStatus( new_status ) )
        },
        
        
        
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(RecoverPasswordPanelComponent)

//export default connect(mapStateToProps, mapDispatchToProps)(SignUpPhonePanelComponent);
