/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import Switch from '@mui/material/Switch';
import CurrencyFormat from 'react-currency-format';
import SelectTournamentTeamComponent from 'shared/components/select-tournament-team/SelectTournamentTeamComponent';
import SelectActionBtnComponent from 'shared/components/select-action-btn/SelectActionBtnComponent';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './TournamentNewMatchModal.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

function TournamentNewMatchModal( props ) {

    const formInitialValues = {
        local_team: '',
        visitant_team: '',
        match_date: '',
        match_time: '',
        field: '',
        persons: '',
        city: props.tournament_city,
        official_referee: '',
        second_assistant_referee: '',
        third_assistant_referee: '',
        fourth_official_referee: '',
        match_assigner:'',
        match_field_id: '',
        day: ''
    } 

    const [visitor_teams, setVisitorTeams] = React.useState( props.tournament_teams );
    const [referee_fields, setRefereeFields] = React.useState(false); // eslint-disable-line
    const [checkedTeams, setCheckedTeams] = React.useState(false);
    const [localTeam, setLocalTeam] =  React.useState({});
    const [visitorTeam, setVisitorTeam] = React.useState({});
    const [selectedPerson, setSelectedPerson] = React.useState({
        official_referee: null,
        second_assistant_referee: null,
        third_assistant_referee: null,
        fourth_official_referee: null,
        match_assigner: null,
    });
    const [tournamentDates, setTournamentDates] = React.useState([]);
    const [matchDateTime, setMatchDateTime] = React.useState('');
    const [tournamentDateId, setTournamentDateId] = React.useState('');
    const [actionType, setActionType] = React.useState('');
    const formRef = React.useRef();
    const [field, setField] = React.useState('');
    const [newField, setNewField] = React.useState(null);
    const [defaultField, setDefaultField] = React.useState(null);

    React.useEffect( () => {
        setVisitorTeams( props.tournament_teams )
    }, [ props ] )

    React.useEffect( () => {
        let dates_array = [];
        if(props.tournament_dates && props.tournament_dates.length > 0) {
            props.tournament_dates.forEach( (date, index) => {
                dates_array.push({
                    "value": date.id,
                    "caption": index + 1
                });                
            });
            setTournamentDates(dates_array);
        }
    }, [props.tournament_dates]);

    React.useEffect( () => {    
        if(props.new_page_field) {
            if(actionType === 'create-tournament-field') {
                let field = [ { field:  props.new_page_field.id } ]
                props.handleTournamentFieldActions('create-tournament-field', field);
                setActionType('');
                setNewField(props.new_page_field.id);
                props.cleanNewPageField();
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.new_page_field]);

    React.useEffect( ()=> {
        if(newField){
            let selectedField = props.tournament_fields?.filter(fild => fild.value === newField);
            setDefaultField(selectedField[0].value);
            setField(selectedField[0].value);
            formRef.current?.setFieldValue("field",selectedField[0].value)
            setNewField(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_fields]);


    const filterVisitor = (localTeam)=>{
        if(localTeam){
            // console.log("visitantes sin el local");
            setLocalTeam(props.tournament_teams?.filter(team => team.id === localTeam.id)[0]);
            setVisitorTeams(props.tournament_teams?.filter(team => team.id != localTeam.id));
        }else{
            // console.log("todos los visitantes");
            setLocalTeam(localTeam);
            setVisitorTeams( props.tournament_teams );
        }
        
    }

    /*function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }
       
        return today;
    }*/

    const handleOnCloseModal = () => {
        setRefereeFields(false);
        setCheckedTeams(false);
        setLocalTeam({});
        setVisitorTeam({});
        setDefaultField(null);
        setField('');
        setSelectedPerson({
            official_referee: null,
            second_assistant_referee: null,
            third_assistant_referee: null,
            fourth_official_referee: null,
            match_assigner: null,
        });
        props.onClose();
    }

    const handleChangeTeams=(event)=>{
        setCheckedTeams(event.target.checked);
        props.handleChangesSelectedTeams(event.target.checked);
    };

    const handleVisitorTeam=(team)=>{
        setVisitorTeam(team);
    };

    React.useEffect(()=>{
        setLocalTeam({});
        setVisitorTeam({});
    },[checkedTeams]);

    const handleSelectedStaff=(item, inputType)=>{
        setSelectedPerson((oldArray) => ({
            ...oldArray, [inputType]: item
        }));
    };

    const handleCreateField = (action_type) => {
        props.handleFieldsAdminActions(action_type, {}); 
        setActionType(action_type);
    }

    const handleMatchDateTime = (value) => {
        setMatchDateTime(value);
    }

    const clearDateTime = () => {
        setMatchDateTime('');
        formRef.current?.setFieldValue("match_date",'')
        
    }

    const handleField = (value) => {
        setField(value);
    }

    /* const clearField = () => {
        setField('');
        formRef.current?.setFieldValue("field",'')
    } */

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={() => handleOnCloseModal()}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelTitle')}
            className=""
        >
            <div class="new-match scroll-parent">
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => {                        
                        let match_date_time = matchDateTime.split('T');
                        let new_match_data = {
                            "local_team": localTeam.team,   //"local_team": values.local_team.team,
                            "visitant_team": visitorTeam.team, //"local_team": values.visitant_team.team,
                            "match_date": Array.isArray(match_date_time)? match_date_time[0] !== ''? match_date_time[0] : null : null,
                            "field": values.field !== '' ? values.field : null,
                            "match_time": Array.isArray(match_date_time)? match_date_time[1] !== ''? match_date_time[1] : null : null,                 
                            "match_city": props.cities?.filter((city) => city.name === values.match_city)[0].id,
                            "official_referee": values.official_referee ? values.official_referee?.profile : null,
                            "second_assistant_referee": values.second_assistant_referee ? values.second_assistant_referee.profile : null,
                            "third_assistant_referee": values.third_assistant_referee ? values.third_assistant_referee.profile : null,
                            "fourth_official_referee": values.fourth_official_referee ? values.fourth_official_referee.profile : null,
                            "intergroup_match": checkedTeams,
                            "referee_assigner": values.match_assigner ? values.match_assigner.profile : null,                            
                        };
                     
                        props.newMatch(tournamentDateId, new_match_data);
                        // setCheckedTeams(false);
                        handleOnCloseModal();
                        
                    }}
                    className="new-match__form form scroll-parent"
                    functionDisabled={()=>{}}
                    enableReinitialize={true}
                    innerRef={formRef}

                >
                    <div className="new-match__form__elements scroll">
                        <div className="new-match__form__elements_switch">
                            <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelTeamsSwitch')}</div>
                            <Switch
                                checked={ checkedTeams }
                                onChange={  (e) => {
                                    handleChangeTeams(e); 
                                    formRef.current?.setFieldValue('local_team',{});
                                    formRef.current?.setFieldValue('visitant_team',{})
                                }}
                                name="teams_select_match"
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="secondary"
                            />
                        </div>
                        <div className="new-match__form__elements__content grid-modal">
                            <SelectTournamentTeamComponent
                                id='local_team'
                                label={ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelHome') }
                                field_name="local_team"
                                optionLabelField="team"
                                optionSelectedField="id"
                                className="new-match__selector__team"
                                disabled={false}
                                resetError={ () => props.onResetError( { 'local_team': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam') }
                                options={props.tournament_teams}
                                defaultValue={null}
                                onChange={(team) => filterVisitor(team) }
                                validationFunction={RequiredValidator} 
                                value={localTeam}
                            />
                            <SelectTournamentTeamComponent
                                id='visitant_team'
                                label={ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelAway') }
                                field_name="visitant_team"
                                optionLabelField="team"
                                optionSelectedField="id"
                                className="new-match__selector__team"
                                disabled={false}
                                resetError={ () => props.onResetError( { 'visitant_team': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam') }
                                options={ visitor_teams}
                                defaultValue={null}
                                onChange={(team) => handleVisitorTeam(team) }
                                validationFunction={RequiredValidator}  
                                value={visitorTeam}
                            />                        
                            <div className={`new-match__form__elements__date grid-modal ${matchDateTime !== ''? 'clear' :''}`} >
                                <InputFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.columns.labelDateTime')} 
                                    input_id="match-date" 
                                    field_name="match_date"
                                    //validationFunction={ DateFormatValidator }
                                    disabled={false}
                                    className={`new-match__form__elements__date__input ${matchDateTime !== ''? 'clear' :''}`}
                                    input_type="datetime-local"
                                    resetError={ () => {}}
                                    error={ props.errors }
                                    message=""
                                    onChange={ (val) => { handleMatchDateTime(val) } }
                                    value={matchDateTime !== ''? matchDateTime :''}
                                />
                                
                                {matchDateTime !== '' &&
                                    <div className="new-match__form__elements__date__input_selected_date"><div className='baloa-table'>{moment(matchDateTime).format("DD/MM/YYYY HH:mm")}</div><div className="new-match__form__elements__date__input_selected_date__icon" onClick={ ()=> { clearDateTime() }}><span className='hubicon-clear_option'/></div></div>
                                }

                                {/* TODO cambiar como se captura la ciudad en creacion partido
                                    Se deja componente con estilo display: none
                                    el api recibe la ciudad que esta configurada en
                                    la creación de torneo 
                                */}
                                <InputFieldAutocompleteWithSearchComponent
                                    field_name={ "match_city" }
                                    options={ props.cities ? props.cities: null }
                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                    optionLabelField={ "name" }
                                    optionSelectedField={ "id" }
                                    className="new-match__form__elements__content__input city_input"
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelCity')}
                                    placeholder=""
                                    validationFunction={ RequiredValidator }
                                    resetError={ () => {}}
                                    error={ props.errors }
                                    lettercount={ "2" }
                                    handleSearchWord = { ( match_city ) => props.handleSearchCityNewMatchModal( match_city ) }
                                    id="city_autocomplete_new_match_modal"
                                    value={ props.cities[0] }                                
                                />
                            </div>
                            <div className='new-match__form__elements__date grid-modal'>
                                <SelectActionBtnComponent
                                    id='field'
                                    label={ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelCourt') }
                                    field_name="field"
                                    className={`new-match__selector__add ${field !== ''? 'clear' : ''}`}
                                    disabled={false}
                                    resetError={ () => props.onResetError( { 'field': "" } ) }
                                    error={ props.errors }
                                    message=""
                                    placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectField') }
                                    btn_add_label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewField') }
                                    options={ props.tournament_fields }
                                    defaultValue={defaultField}
                                    onChange={ (field) => { handleField() } }
                                    validationFunction={()=>{}}  
                                    //select_type='text'  
                                    select_type='fields'  
                                    addItem={ () =>{ handleCreateField('create-tournament-field')} }
                                    optionLabelField={"caption"}
                                    optionSelectedField={"value"}
                                />
                                {/* field !== '' &&
                                    <div className="new-match__form__elements__input_field"><div className='baloa-table'></div><div className="new-match__form__elements__date__input_selected_date__icon" onClick={ ()=> { clearField() }}><span className='hubicon-clear_option'/></div></div>
                                 */}
                            </div>
                            
                        </div>
                        <div className="new-match__form__elements__field grid-modal">
                            <div>
                                <SelectActionBtnComponent
                                    id='day'
                                    label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelDay') }
                                    field_name="day"
                                    className="new-match__selector__add day"
                                    disabled={false}
                                    resetError={ () => props.onResetError( { 'day': "" } ) }
                                    error={ props.errors }
                                    message=""
                                    placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectDay') }
                                    btn_add_label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewDay') }
                                    options={ tournamentDates }
                                    defaultValue={''}
                                    onChange={ (tournament_date_id) => setTournamentDateId(tournament_date_id) }
                                    validationFunction={RequiredValidator}  
                                    //select_type='text'
                                    select_type='fields'  
                                    addItem={ () => props.handleOnNewEditTournamentDate() }
                                    optionLabelField={"caption"}
                                    optionSelectedField={"value"}
                                />                                
                            </div>
                        </div>
                        { props.referee_payments &&
                            <React.Fragment>
                                <div className='new-match__content_payment_title baloa-table'>
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelReferee')}
                                </div>
                                <div className='new-match__content__payment_settings'>
                                    <div className='new-match__content__payment_settings__container'>
                                        <div className="new-match__content_registration baloa-names">
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.refereePaymenSettingTitle')}
                                        </div>
                                    </div>
                                        <div className='new-match__content__payment_settings__saved-config'>
                                            <div className='new-match__content__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localOptionLabel')}</div>
                                                <div className='baloa-username'>{props.tournament_referee_payment_config?.refereeing_value_local_team} {props.currency_payment}</div>
                                            </div>
                                            <div className='new-match__content__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.visitantOptionLabel')}</div>
                                                <div className='baloa-username'>{props.tournament_referee_payment_config?.refereeing_value_visitant_team} {props.currency_payment}</div>
                                            </div>
                                            <div className='new-match__content__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.arbitrageTotalAmountLabel')}</div>
                                                <div className='baloa-username'>
                                                    {`${Number(props.tournament_referee_payment_config?.refereeing_value_local_team)+Number(props.tournament_referee_payment_config?.refereeing_value_visitant_team)} 
                                                    ${props.currency_payment}`}</div>
                                            </div>
                                        </div>
                                </div>
                                <div className='new-match__content_payment_subtitle baloa-table'>
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelRefereeSubtitlePayment')}
                                </div>
                            </React.Fragment>
                        }
                        <div className="new-match__form__elements__referee">
                            <div className='new-match__form__elements__referee__input-fields'>
                                    <div className='new-match__form__elements__referee__input-fields__title baloa-normal-medium'>{ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelReferee') }</div>
                                    <div className='new-match__form__elements__referee__input-fields__column'>
                                        <div className="tournament-new-match__input-member">
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOfficialReferee')}</div>
                                            <div className='new-match__form_elements_input_value'>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className={`official_referee_input ${props.referee_payments? 'with-payment' : ''}`}
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'official_referee') }
                                                    formRef={formRef}
                                                    value={ selectedPerson ? selectedPerson.official_referee : null}
                                                    id="official_referee_autocomplete_new_match_modal" 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.official_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.official_referee !== '' ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                            
                                            {/* <label className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOptional')}</label> */}
                                        </div>
                                        <div className="tournament-new-match__input-member">
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelSecondAssistantReferee')}</div>
                                            <div className='new-match__form_elements_input_value'>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'second_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className={`second_assistant_referee_input ${props.referee_payments? 'with-payment' : ''}`}
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'second_assistant_referee') }
                                                    formRef={formRef}
                                                    value={ selectedPerson ? selectedPerson.second_assistant_referee : null}
                                                    id="second_assistant_referee_autocomplete_new_match_modal" 
                                                />
                                                { props.referee_payments &&
                                                        <CurrencyFormat
                                                            value={props.tournament_referee_payment_config?.second_assistant_referee_value} 
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={'$ '} 
                                                            suffix={` ${props.currency_payment}`}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className={`baloa-username ${selectedPerson?.second_assistant_referee !== '' ? 'selected' : ''}`}>{value}</div>}
                                                        />
                                                }
                                            </div>
                                            {/* <label className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOptional')}</label> */}
                                        </div>
                                    </div>
                                    <div className='new-match__form__elements__referee__input-fields__column'>
                                        <div className="tournament-new-match__input-member">
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelThirdAssistantReferee')}</div>
                                            <div className='new-match__form_elements_input_value'>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'third_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className={`tournament-third_assistant_referee_input ${props.referee_payments? 'with-payment' : ''}`}
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'third_assistant_referee') }
                                                    formRef={formRef}
                                                    value={ selectedPerson ? selectedPerson.third_assistant_referee : null}
                                                    id="third_assistant_referee_autocomplete_new_match_modal" 
                                                />
                                                { props.referee_payments &&
                                                            <CurrencyFormat
                                                                value={props.tournament_referee_payment_config?.third_assistant_referee_value} 
                                                                displayType={'text'} 
                                                                thousandSeparator={','} 
                                                                decimalSeparator={"."} 
                                                                prefix={'$ '} 
                                                                suffix={` ${props.currency_payment}`}
                                                                onValueChange ={()=>{}}
                                                                renderText={value => <div className={`baloa-username ${selectedPerson?.third_assistant_referee !== '' ? 'selected' : ''}`}>{value}</div>}
                                                            />
                                                    }
                                            </div>
                                            
                                            {/* <label className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOptional')}</label> */}
                                        </div>
                                        <div className="tournament-new-match__input-member">
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}</div>
                                            <div className='new-match__form_elements_input_value'>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'fourth_official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className={`tournament-fourth_official_referee_input ${props.referee_payments? 'with-payment' : ''}`}
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'fourth_official_referee') }
                                                    formRef={formRef}
                                                    value={ selectedPerson ? selectedPerson.fourth_official_referee : null}
                                                    id="fourth_official_referee_autocomplete_new_match_modal" 
                                                />
                                                { props.referee_payments &&
                                                            <CurrencyFormat
                                                                value={props.tournament_referee_payment_config?.fourth_official_referee_value} 
                                                                displayType={'text'} 
                                                                thousandSeparator={','} 
                                                                decimalSeparator={"."} 
                                                                prefix={'$ '} 
                                                                suffix={` ${props.currency_payment}`}
                                                                onValueChange ={()=>{}}
                                                                renderText={value => <div className={`baloa-username ${selectedPerson?.fourth_official_referee !== '' ? 'selected' : ''}`}>{value}</div>}
                                                            />
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='new-match__form__elements__referee__input-fields__column'>
                                        <div className="tournament-new-match__input-match_assigner">
                                        <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelInputAssigner')}</div>
                                        <div className='new-match__form_elements_input_value'>
                                            <InputFieldAutocompleteComponent
                                                    name= { 'match_assigner' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className={`tournament-match_assigner_referee_input ${props.referee_payments? 'with-payment' : ''}`}
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'match_assigner') }
                                                    formRef={formRef}
                                                    value={ selectedPerson ? selectedPerson.match_assigner : null}
                                                    id="match_assigner_referee_autocomplete_new_match_modal" 
                                                />
                                                { props.referee_payments &&
                                                            <CurrencyFormat
                                                                value={props.tournament_referee_payment_config?.referee_assigner_value} 
                                                                displayType={'text'} 
                                                                thousandSeparator={','} 
                                                                decimalSeparator={"."} 
                                                                prefix={'$ '} 
                                                                suffix={` ${props.currency_payment}`}
                                                                onValueChange ={()=>{}}
                                                                renderText={value => <div className={`baloa-username ${selectedPerson?.match_assigner !== '' ? 'selected' : ''}`}>{value}</div>}
                                                            />
                                                    }
                                        </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="new-match__form__actions form__actions">
                        <PrimaryButtonComponent
                            type="submit"
                            disabled={false} 
                            onClick={() => { setRefereeFields(false) } }
                            className="new-match__form__submit"
                        >
                            <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelAction')}</span>
                        </PrimaryButtonComponent>
                        {/* <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="new-match__form__submit"
                        >
                            <span>Guardar y crear otro</span>
                        </PrimaryButtonComponent> */}
                    </div>
                    
                </FormComponent>
            </div>

        </Modal2Component>
        /*  README: Use of the component with its properties
            each property is important to differentiate its functionality whether it is a local search or an api search.

                <LeagueMemberSearchIconComponent
                    fieldName={'fourth_official_referee'}
                    formRef={formRef}
                    options={props.staff}
                    // options={ props.baloaMembers ? props.baloaMembers : null } Search of baloaMembers out tournament
                    searchType={'member'}
                    tournamentId={props.id_tournament}
                    modalSearchPlaceholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                    labelInput={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}
                    searchApiMember={false}
                    labelOptional={false}
                    removeIconSearch={true}
                    removeWordLenghtValidation={true}
                    // handleSearchbBaloaMember = {(member)=>props.handleSearchbBaloaMember(member)} Search of baloaMembers out tournament
                /> 
            */
    )
}

export default withTranslation('league')(TournamentNewMatchModal);