// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.primary-button {
    /* box-shadow: var(--shadow-pb); */
    background-color: var(--new-green-color);
    mix-blend-mode: normal;
}
.primary-button:focus {
    background-color: var(--new-green-dark-color);
    
}
.primary-button:hover {
    background-color: var(--new-green-dark-color);
    /* box-shadow: var(--shadow-hover-pb); */
}
.primary-button:active {
    background-color: var(--new-green-dark-color);
}
.primary-button:disabled {
    /* background-color: var(--disabled-pb-bg-color); */
    opacity: 0.64;
    /* box-shadow: none; */
    color: var(--white-color);    
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/primary-button/PrimaryButtonComponent.styles.css"],"names":[],"mappings":";AACA;IACI,kCAAkC;IAClC,wCAAwC;IACxC,sBAAsB;AAC1B;AACA;IACI,6CAA6C;;AAEjD;AACA;IACI,6CAA6C;IAC7C,wCAAwC;AAC5C;AACA;IACI,6CAA6C;AACjD;AACA;IACI,mDAAmD;IACnD,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":["\n.primary-button {\n    /* box-shadow: var(--shadow-pb); */\n    background-color: var(--new-green-color);\n    mix-blend-mode: normal;\n}\n.primary-button:focus {\n    background-color: var(--new-green-dark-color);\n    \n}\n.primary-button:hover {\n    background-color: var(--new-green-dark-color);\n    /* box-shadow: var(--shadow-hover-pb); */\n}\n.primary-button:active {\n    background-color: var(--new-green-dark-color);\n}\n.primary-button:disabled {\n    /* background-color: var(--disabled-pb-bg-color); */\n    opacity: 0.64;\n    /* box-shadow: none; */\n    color: var(--white-color);    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
