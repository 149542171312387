//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './LeagueBulkDeleteMemberModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueBulkDeleteMemberModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            //onClose={props.onClose}       
        >
            <p className="modal-title">                
                {props.modalTitle}
            </p>
            <p className="modal-text">
                {props.modalContent}                    
            </p>
            
            <div className="league-bulk-delete-member-modal-actions"> 
                
                <SecondaryButtonComponent 
                    onClick={ props.onClose }
                >
                    <span>{props.cancelButtonText}</span>
                </SecondaryButtonComponent>
                <SecondaryButtonComponent
                    className="red" 
                    onClick={ props.deleteButtonAction }
                >
                    <span>{props.deleteButtonText}</span>
                </SecondaryButtonComponent>
                
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(LeagueBulkDeleteMemberModalComponent);