//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentRemoveMultiplePhasesModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentRemoveMultiplePhasesModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}
            onClick={()=>{}}
        
        >
            <p className="modal-title">                
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningRemove.labelTitle')}
            </p>
            <p className="modal-text">
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningRemove.labelInfo')}                    
            </p>
            
            <div className="login-modal-actions"> 

                <TextButtonComponent 
                    onClick={ ()=>{
                        props.handleDeleteTournamentPhaseModal();
                        props.onClose();
                    }}
                >
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningRemove.labelAction')}</span>
                </TextButtonComponent>
                
                <SecondaryButtonComponent 
                    onClick={ props.onClose }
                >
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningRemove.labelBackAction')}</span>
                </SecondaryButtonComponent>
                
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentRemoveMultiplePhasesModalComponent);