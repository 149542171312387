/*eslint eqeqeq: "off"*/
// Action creators
import { 
    changePageLoadingAction, 
    loadPageAction, 
    changePagePostsLoadingAction,
    loadPagePostsAction,
    loadPageErrorsAction,
    changePageExperiencesModalStateAction,
    loadPageFollowersAction,
    searchMembersPageAction,
    loadPageMembersAction,
    addPageNationalityAction,
    setStaffMemeberPermissionsAction,
    updatePagePostsAction,
    loadPageInscriptionTeamsAction,
} from './PageActionsCreators';
import { removeFromPageSuggestions, changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { loadLogguedinProfile} from 'app/profiles/actions/ProfileActions';
import { updateActiveMemberRolesAction } from 'app/leagues/actions/LeagueActionsCreators';
import { updateTournamentTeamPlayersAction } from 'app/leagues/actions/LeagueTournamentActionsCreators';
// Apis
import { 
    CreatePageApi,
    GetPageApi, 
    FollowPageApi, 
    UnfollowPageApi,
    GetPagePostsApi,
    CreateAttributeOnPageApi,
    EditAttributeOnPageApi,
    GetPageFollowersApi,
    AddMemberToPageApi,
    GetSearchMembersApi,
    GetMembersApi,
    AddAdminToPageApi,
    RemoveMembersApi,
    RemoveAdminApi,
    AddPageNationalityApi,
    BlockPageApi,
    SetStaffMemeberPermissionsApi,
    GetTeamsToJoinApi,
} from 'app/pages/apis/PageApi';
import { FollowProfileApi, UnfollowProfileApi } from 'app/profiles/apis/ProfileApi';

/*import {       
  switchProfileToPage,
} from 'app/profiles/actions/ProfileActions';*/

// i18next 
import i18n from 'i18next';

export const createPage = ( page_creation_data ) => {

    return async ( dispatch ) => {
        await CreatePageApi( page_creation_data )
            .then( response => {
                dispatch( loadPageAction( response ) );
                dispatch ( loadLogguedinProfile(response.data.admins[0].username) )
            })
            .catch( err => {
                const error_data = {
                    'pagename': i18n.t('page:snackBarMessages.pageExist'),
                }
                dispatch( loadPageErrorsAction( error_data, 'creation' ) );
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.pageExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPage = ( pagename ) => {

    return async ( dispatch ) => {
        
        dispatch( changePageLoadingAction( true ) );
        await GetPageApi( pagename )
            .then( response => {
                dispatch( loadPageAction( response ) );

                // if(response.data.is_admin){                  
                //   dispatch( switchProfileToPage( response.data.name ) );
                // }
                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log(err);
            })
    }
}

export const followPage = ( page ) => {
    
    return ( dispatch ) => {
        
        FollowPageApi( page.pagename )
            .then( response => {
                dispatch( removeFromPageSuggestions( page ) )
                dispatch( loadPage( page.pagename ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const followPageByPagename = ( page_pagename, pagename ) => {

    return ( dispatch ) => {
        
        FollowPageApi( page_pagename )
            .then( response => {
                dispatch( loadPageFollowers( pagename ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const followProfileByPagename = ( profile_username, pagename ) => {
    return ( dispatch) => {

        FollowProfileApi( profile_username )
            .then( response => {
                
                dispatch( loadPageFollowers( pagename ) )
                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )
    }
}

export const unfollowPage = ( page ) => {

    return ( dispatch ) => {
        
        UnfollowPageApi( page.pagename )
            .then( response => {
                dispatch( loadPage( page.pagename ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const unfollowPageByPagename = ( page_pagename, pagename ) => {

    return ( dispatch ) => {
        
        UnfollowPageApi( page_pagename )
            .then( response => {
                dispatch( loadPageFollowers( pagename ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const unfollowProfileByPagename = ( page_pagename, pagename ) => {

    return ( dispatch ) => {
        
        UnfollowProfileApi( page_pagename )
            .then( response => {
                dispatch( loadPageFollowers( pagename ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const createOrEditAttributeOnPage = ( page, new_attribute_data ) => {

    return ( dispatch ) => {

        if ( 'id' in new_attribute_data ) {
            dispatch( editAttributeOnPage( page, new_attribute_data ) )
        }
        else {
            dispatch( createAttributeOnPage( page, new_attribute_data ) );
        }

    }

}

export const createAttributeOnPage = ( page, new_attribute_data ) => {

    return ( dispatch ) => {

        CreateAttributeOnPageApi( page, new_attribute_data )
            .then( response => {
                dispatch( loadPage( page.pagename) );
                dispatch( moveThroughPageAttributeForms( new_attribute_data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }

}

export const editAttributeOnPage = ( page, new_attribute_data ) => {

    return ( dispatch ) => {

        EditAttributeOnPageApi( new_attribute_data )
            .then( response => {
                dispatch( loadPage( page.pagename ) );
                dispatch( moveThroughPageAttributeForms( new_attribute_data ) );
            } )
            .catch( error => {
                // let attribute_type = new_attribute_data['atype'].replace('-','_');
                // dispatch( loadPageErrors( { [attribute_type]: "Prueba de error" }, 'technics' ) )
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }

}

export const moveThroughPageAttributeForms = ( new_attribute_data ) => {

    return ( dispatch ) => {

        if ( new_attribute_data['atype'] == 'experience' ) {
            dispatch( changePageExperiencesModalStateAction( false ) );
        }

    }

}

export const loadPagePosts = ( username ) => {

    return ( dispatch ) => {

        dispatch( changePagePostsLoadingAction( true ) );
        GetPagePostsApi( username )
            .then( response => {
                dispatch( loadPagePostsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log(error.response)
            })
            .then( () => {
                dispatch( changePagePostsLoadingAction( false ) );
            } );
    }
}

export const loadPageFollowers = ( pagename ) => ( dispatch ) => {

    GetPageFollowersApi( pagename )
        .then( response => {
            dispatch( loadPageFollowersAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:snackBarMessages.problemExist'),
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const addMemberToPage = ( page_name, member_username ) => {

    return ( dispatch ) => {
        
        AddMemberToPageApi( page_name, member_username )
            .then( response => {    
                dispatch( loadPageMembers( page_name ) );                  
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('page:snackBarMessages.userAdded', {username: `${member_username}` }),
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addAdminToPage = ( page_name, member_username ) => {

    return ( dispatch ) => {
        
        AddAdminToPageApi( page_name, member_username )
            .then( response => {     
                dispatch( loadPage( page_name ) );                
                dispatch(changeErrorMessageSnackBar( {                    
                    message: i18n.t('page:snackBarMessages.userAdded', {username: `${member_username}` }),
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchMembersPage = ( searched_profile ) => {

    return ( dispatch ) => {
        
        GetSearchMembersApi( searched_profile )
            .then( response => {
                console.log("response");
                console.log(response);
                dispatch( searchMembersPageAction( response ) );
            })
            .catch( err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const loadPageMembers = ( pagename ) => {

    return ( dispatch ) => {
        
        GetMembersApi( pagename )
            .then( response => {
                dispatch( loadPageMembersAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const removeMemberPage = ( page_name, id_member ) => ( dispatch ) => {

    RemoveMembersApi( page_name, id_member )
        .then( response => {
            dispatch( loadPageMembers( page_name ) );
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:snackBarMessages.pageRemoved'),
                error_message_is_open: true
                } )
            );
        } )
        .catch( err => {            
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:snackBarMessages.problemExist'),
                    error_message_is_open: true
                } ) )
            }
        } )

}

export const removeAdminPage = ( page_name, id_member ) => ( dispatch ) => {

    RemoveAdminApi( page_name, id_member )
        .then( response => {            
            dispatch( loadPage( page_name ) );
            dispatch( loadPageMembers( page_name ) );
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:snackBarMessages.pageAdminRemoved'),
                error_message_is_open: true
                } )
            );
        } )
        .catch( err => {            
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'),
                    error_message_is_open: true
                } ) )
            }
        } )

}

export const addPageNationality = ( page, new_nationality_data ) => ( dispatch ) => {
    
    AddPageNationalityApi( page, new_nationality_data )
        .then( response => {
            dispatch( addPageNationalityAction( response.data ) );
            dispatch( loadPage( page.pagename ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'),
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const blockPage= ( pagename ) => {

    return ( dispatch ) => {
        
        BlockPageApi( pagename )
            .then( response => {
                // console.log('r', response);
                dispatch( changeErrorMessageSnackBar( {
                    message: `@${pagename} ` + i18n.t('profile:blockedMsg'),
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            } )
    }
}


export const setStaffMemeberPermissions = ( member_username, pagename, roles ) => {

    return ( dispatch ) => {
        
        SetStaffMemeberPermissionsApi( member_username, pagename, roles )
            .then( response => {    
                dispatch( setStaffMemeberPermissionsAction( response ) );    
                dispatch( updateActiveMemberRolesAction( response ) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const updatePagePosts = ( pagename ) => {

    return ( dispatch ) => {

        GetPagePostsApi( pagename )
            .then( response => {
                dispatch( updatePagePostsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log(error.response)
            })
    }
}

export const followPlayerTeam = (player) => {
    return ( dispatch ) => {
        FollowProfileApi(player.username)
            .then( response => {
                dispatch( updateTournamentTeamPlayersAction( player ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const unFollowPlayerTeam = (player) => {
    return ( dispatch ) => {
        UnfollowProfileApi(player.username)
            .then( response => {
                dispatch( updateTournamentTeamPlayersAction( player ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPageInscriptionTeams = (pagename) => {
    return ( dispatch ) => {
        GetTeamsToJoinApi(pagename)
            .then( response => {
                dispatch( loadPageInscriptionTeamsAction( response ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}