// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';


// Styles
import './MatchDetailModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function MatchDetailModalComponent ( props ){

    return(
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title=""            
        >         
            <div className="match-detail-modal__container">
                <LeagueTournamentInfoComponent 
                    tournament_info = {props.match_detail}
                />
            </div>
        </Modal2Component>
    )

}

export default withTranslation('post')(MatchDetailModalComponent);
