// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './HomeCreateProfilePaymentAccountModal.styles.css';

// i18n
import { withTranslation } from 'react-i18next';



function HomeCreateProfilePaymentAccountModal( props ) {

    const { children, value, index } = props; // eslint-disable-line

    return (
        
        <ModalComponent 
            isOpen={props.isOpen} 
            className="home-create-profile-account"        
        >
            
            <div className="home-create-profile-account__container">  
                <div className="home-create-profile-account__icon">
                    <span className='hubicon-info'></span>
                </div>
                <div className="home-create-profile-account__title baloa-subtitle-1">  
                    <span>{props.t('createPaymentAccountModal.title')}</span>
                </div>
                <div className="home-create-profile-account__subtitle baloa-table">  
                    <span>{props.t('createPaymentAccountModal.message')}</span>
                </div>
                <div className="home-create-profile-account__buttons">           
                    
                    <PrimaryButtonComponent  className="small" onClick={ props.onHandleAccept }>
                        <span>{props.t('createPaymentAccountModal.acceptBtn')}</span>
                    </PrimaryButtonComponent>
                    <TextButtonComponent className="small" onClick={ props.onClose }>
                        <span>{props.t('createPaymentAccountModal.cancelBtn')}</span>
                    </TextButtonComponent>
                </div>
            </div>

        </ModalComponent>

        )
    }
    
    export default withTranslation('home')(HomeCreateProfilePaymentAccountModal);