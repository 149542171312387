// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-fixture-accept-modal .modal__container {
    padding: 0;
    padding-top: 71px;
}

.tournament-fixture-accept-modal__container .baloa-normal-medium {
    color: var(--primary-very-dark-color);
    padding: 0 18px;
    text-align: center;
}

.tournament-fixture-accept-modal__action-btns {
    padding: var(--space-half) var(--space);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--new-input-background-color);
    margin-top: 38px;
}

.tournament-fixture-accept-modal__action-btns .secondary-button {
    border: none;
    color: var(--basic-gray-color);
    padding: 8px 20px;
}

.tournament-fixture-accept-modal__action-btns .secondary-button:hover {
    color: var(--white-color);
}
.tournament-fixture-accept-modal__action-btns .primary-button {
    padding: 8px 20px;
    margin-left: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-fixture-accept-modal/TournamentFixtureAcceptModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uDAAuD;IACvD,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".tournament-fixture-accept-modal .modal__container {\n    padding: 0;\n    padding-top: 71px;\n}\n\n.tournament-fixture-accept-modal__container .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    padding: 0 18px;\n    text-align: center;\n}\n\n.tournament-fixture-accept-modal__action-btns {\n    padding: var(--space-half) var(--space);\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    border-top: 1px solid var(--new-input-background-color);\n    margin-top: 38px;\n}\n\n.tournament-fixture-accept-modal__action-btns .secondary-button {\n    border: none;\n    color: var(--basic-gray-color);\n    padding: 8px 20px;\n}\n\n.tournament-fixture-accept-modal__action-btns .secondary-button:hover {\n    color: var(--white-color);\n}\n.tournament-fixture-accept-modal__action-btns .primary-button {\n    padding: 8px 20px;\n    margin-left: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
