
// Attributes state
const attributes_state = {
    constants:{
        aptitudes: [],
        specialties: [],
    },
}

export default function AttributesReducer( state = attributes_state, action ) {
    
    switch ( action.type ) {

        case "ATTRIBUTES:LOAD_CONSTANTS": {
            return { ...state, constants:{aptitudes: action.constants.attributes.aptitude.options, specialties: action.constants.attributes.specialty.options} }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt
        // no default

    }
    
    return state;
}