/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';

// Formik
import { Formik } from 'formik';

// Actions
import { changePostNewFormationModal } from 'app/home/actions/HomeActionsCreators';
import { changeHomeNewPostModal } from 'app/home/actions/HomeActionsCreators';
import { addUserFormation } from 'app/home/actions/HomeActionsCreators';
import { searchMembersPage } from 'app/pages/actions/PageActions';


// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormationFieldComponent from 'shared/components/formation/FormationFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';


// Styles
import './CreateFormationModalComponent.styles.css';


// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';


class CreateFormationModalComponent extends React.Component  {
    
    formInitialValues = {
        caption: "",
    }

    state = { 
        caption: null,
        formation: null,
        lineUp: '4-4-2',
        complete: false,
        clear_fields: "false"
    };

    callBackFormationDataFunction = (formationData) => {
        this.setPlayers(formationData);   
        this.setState({complete: true});
    }

    callBackLineDataFunction = (lineData) => { 
        this.setState({lineUp: lineData.lineUp});  
    }

    setPlayers = (formationData) => {       

        if(this.state.lineUp == "4-4-2")
            this.set4_4_2(formationData);                   

        if(this.state.lineUp == "5-3-2")
            this.set5_3_2(formationData);
            
        if(this.state.lineUp == "4-3-3")
            this.set4_3_3(formationData);

        if(this.state.lineUp == "3-4-3")
            this.set3_4_3(formationData);

        if(this.state.lineUp == "5-4-1")
            this.set5_4_1(formationData);
    }

    set4_4_2 = (formationData) => {
        
        let portero = formationData.final_positions_table.find( (el) => el[0] === "por");
        let defensa1 = formationData.final_positions_table.find( (el) => el[0] === "def1");
        let defensa2 = formationData.final_positions_table.find( (el) => el[0] === "def2");
        let defensa3 = formationData.final_positions_table.find( (el) => el[0] === "def3");
        let defensa4 = formationData.final_positions_table.find( (el) => el[0] === "def4");
        let medio1 = formationData.final_positions_table.find( (el) => el[0] === "med1");
        let medio2 = formationData.final_positions_table.find( (el) => el[0] === "med2");
        let medio3 = formationData.final_positions_table.find( (el) => el[0] === "med3");
        let medio4 = formationData.final_positions_table.find( (el) => el[0] === "med4");
        let delantero1 = formationData.final_positions_table.find( (el) => el[0] === "del1");
        let delantero2 = formationData.final_positions_table.find( (el) => el[0] === "del2");        

        let formation = {  
            players: {
                port: {"name": portero[2]},
                defe: [{"name": defensa1[2]}, {"name": defensa2[2]}, {"name": defensa3[2]}, {"name": defensa4[2]}],
                medi: [{"name": medio1[2]}, {"name": medio2[2]}, {"name": medio3[2]}, {"name": medio4[2]}],
                dela: [{"name": delantero1[2]}, {"name": delantero2[2]}],
                supl: [{"name": ""}, {"name": ""}, {"name": ""}, {"name": ""}]
            },
            lineup: this.state.lineUp
        };
        this.setState({formation: formation});
    }

    set5_3_2 = (formationData) => {

        let portero = formationData.final_positions_table.find((el)=>el[0] === "por");
        let defensa1 = formationData.final_positions_table.find((el)=>el[0] === "def1");
        let defensa2 = formationData.final_positions_table.find((el)=>el[0] === "def2");
        let defensa3 = formationData.final_positions_table.find((el)=>el[0] === "def3");
        let defensa4 = formationData.final_positions_table.find((el)=>el[0] === "def4");
        let defensa5 = formationData.final_positions_table.find((el)=>el[0] === "def5");
        let medio1 = formationData.final_positions_table.find((el)=>el[0] === "med1");
        let medio2 = formationData.final_positions_table.find((el)=>el[0] === "med2");
        let medio3 = formationData.final_positions_table.find((el)=>el[0] === "med3");
        let delantero1 = formationData.final_positions_table.find((el)=>el[0] === "del1");
        let delantero2 = formationData.final_positions_table.find((el)=>el[0] === "del2");
        
        let formation = {  
            players: {
                port: {"name": portero[2]},
                defe: [{"name": defensa1[2]}, {"name": defensa2[2]}, {"name": defensa3[2]}, {"name": defensa4[2]}, {"name": defensa5[2]}],
                medi: [{"name": medio1[2]}, {"name": medio2[2]}, {"name": medio3[2]}],
                dela: [{"name": delantero1[2]}, {"name": delantero2[2]}],
                supl: [{"name": ""}, {"name": ""}, {"name": ""}, {"name": ""}]
            },
            lineup: this.state.lineUp          
        };

        this.setState({formation: formation});
    }

    set4_3_3 = (formationData) => {

        let portero = formationData.final_positions_table.find((el)=>el[0] === "por");
        let defensa1 = formationData.final_positions_table.find((el)=>el[0] === "def1");
        let defensa2 = formationData.final_positions_table.find((el)=>el[0] === "def2");
        let defensa3 = formationData.final_positions_table.find((el)=>el[0] === "def3");
        let defensa4 = formationData.final_positions_table.find((el)=>el[0] === "def4");
        let medio1 = formationData.final_positions_table.find((el)=>el[0] === "med1");
        let medio2 = formationData.final_positions_table.find((el)=>el[0] === "med2");
        let medio3 = formationData.final_positions_table.find((el)=>el[0] === "med3");
        let delantero1 = formationData.final_positions_table.find((el)=>el[0] === "del1");
        let delantero2 = formationData.final_positions_table.find((el)=>el[0] === "del2");
        let delantero3 = formationData.final_positions_table.find((el)=>el[0] === "del3");
        
        let formation = {  
            players: {
                port: {"name": portero[2]},
                defe: [{"name": defensa1[2]}, {"name": defensa2[2]}, {"name": defensa3[2]}, {"name": defensa4[2]}],
                medi: [{"name": medio1[2]}, {"name": medio2[2]}, {"name": medio3[2]}],
                dela: [{"name": delantero1[2]}, {"name": delantero2[2]}, {"name": delantero3[2]}],
                supl: [{"name": ""}, {"name": ""}, {"name": ""}, {"name": ""}]
            },
            lineup: this.state.lineUp          
        };
        this.setState({formation: formation});
    }

    set3_4_3 = (formationData) => {

        let portero = formationData.final_positions_table.find((el)=>el[0] === "por");
        let defensa1 = formationData.final_positions_table.find((el)=>el[0] === "def1");
        let defensa2 = formationData.final_positions_table.find((el)=>el[0] === "def2");
        let defensa3 = formationData.final_positions_table.find((el)=>el[0] === "def3");
        let medio1 = formationData.final_positions_table.find((el)=>el[0] === "med1");
        let medio2 = formationData.final_positions_table.find((el)=>el[0] === "med2");
        let medio3 = formationData.final_positions_table.find((el)=>el[0] === "med3");
        let medio4 = formationData.final_positions_table.find((el)=>el[0] === "med4");
        let delantero1 = formationData.final_positions_table.find((el)=>el[0] === "del1");
        let delantero2 = formationData.final_positions_table.find((el)=>el[0] === "del2");
        let delantero3 = formationData.final_positions_table.find((el)=>el[0] === "del3");
        
        let formation = {  
            players: {
                port: {"name": portero[2]},
                defe: [{"name": defensa1[2]}, {"name": defensa2[2]}, {"name": defensa3[2]}],
                medi: [{"name": medio1[2]}, {"name": medio2[2]}, {"name": medio3[2]}, {"name": medio4[2]}],
                dela: [{"name": delantero1[2]}, {"name": delantero2[2]}, {"name": delantero3[2]}],
                supl: [{"name": ""}, {"name": ""}, {"name": ""}, {"name":""}]
            },
            lineup: this.state.lineUp          
        };

        this.setState({formation: formation});        
    }

    set5_4_1 = (formationData) => {

        let portero = formationData.final_positions_table.find((el)=>el[0] === "por");
        let defensa1 = formationData.final_positions_table.find((el)=>el[0] === "def1");
        let defensa2 = formationData.final_positions_table.find((el)=>el[0] === "def2");
        let defensa3 = formationData.final_positions_table.find((el)=>el[0] === "def3");
        let defensa4 = formationData.final_positions_table.find((el)=>el[0] === "def4");
        let defensa5 = formationData.final_positions_table.find((el)=>el[0] === "def5");
        let medio1 = formationData.final_positions_table.find((el)=>el[0] === "med1");
        let medio2 = formationData.final_positions_table.find((el)=>el[0] === "med2");
        let medio3 = formationData.final_positions_table.find((el)=>el[0] === "med3");
        let medio4 = formationData.final_positions_table.find((el)=>el[0] === "med4");
        let delantero1 = formationData.final_positions_table.find((el)=>el[0] === "del1");
        
        let formation = {  
            players: {
                port: {"name": portero[2]},
                defe: [{"name": defensa1[2]}, {"name": defensa2[2]}, {"name": defensa3[2]}, {"name": defensa4[2]}, {"name": defensa5[2]}],
                medi: [{"name": medio1[2]}, {"name": medio2[2]}, {"name": medio3[2]}, {"name": medio4[2]}],
                dela: [{"name": delantero1[2]}],
                supl: [{"name": ""}, {"name": ""}, {"name": ""}, {"name": ""}]
            },
            lineup: this.state.lineUp
        };
        this.setState({formation: formation});
    }

    onClickClear = () => { 
        if(this.state.clear_fields === "false"){
            this.setState({clear_fields: "true"});
        }  else {
            this.setState({clear_fields: "false"});
        }     
                
        this.setState({complete: false});
        this.setState({formation: null});
    }; 

   componentDidMount() {
        
    }

    clearInput1() {
        this.input1.clear();
    }

    handleOnFormationBackBtn(){
        this.props.changePostNewFormationModal( false );
        this.props.changeHomeNewPostModal( true );
        this.setState({formation: null});
    }
    
    render() {
        return (
            <Modal2Component 
                isOpen={this.props.isOpen} 
                onClose={()=>this.handleOnFormationBackBtn()}
                title={this.props.t('formation.title')} 
                className="medium create-formation-modal"
                btn_modal2_header={true}
                handleOnClearModalHeader={() => this.props.onClose}                      
            >
                <div className="technics-form">
                    <div className="profile-technics__data__positions-graph">
                        <Formik>
                            <FormationFieldComponent
                                returnFormationData = {this.callBackFormationDataFunction}
                                returnLineData = {this.callBackLineDataFunction}
                                display = "form"
                                position = "secondary"
                                lineUpSelector = "true"                                
                                lineUp = "4-4-2"
                                lineUps = { this.props.state.lineups.constants } 
                                clear = { this.state.clear_fields }                               
                                handleSearchMember = {( member_username ) => this.props.searchMembersPage( member_username )}
                                profiles = {this.props.state.page?.searched_members}
                            />
                        </Formik>                        
                    </div>
                    <div className="create-formation-modal__form">
                        <div className="create-formation-modal__form__group">
                            <SecondaryButtonComponent
                                type='button'
                                id='create-formation-modal__clear-formation-btn'
                                disabled={false}
                                onClick={ () => this.onClickClear() }
                            >
                                <span>{this.props.t('formation.btnClean')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent 
                                type="submit"
                                disabled={ !this.state.complete  }
                                onClick={ ()=>{ 

                                        this.props.changePostNewFormationModal( false );
                                        this.props.changeHomeNewPostModal( true );
                                        this.props.addUserFormation( this.state.formation );
                                        this.setState({formation: null});
                                    } 
                                } 
                                className="button primary-button create-formation-modal__form__submit"
                            >
                                <span>{this.props.t('formation.btnContinue')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                </div>                
            </Modal2Component>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePostNewFormationModal: ( new_modal_state ) => {
            return dispatch( changePostNewFormationModal( new_modal_state ) );
        },
        changeHomeNewPostModal: ( new_modal_state ) => {
            return dispatch( changeHomeNewPostModal( new_modal_state ) );
        },
        addUserFormation: ( formation ) => {
            return dispatch( addUserFormation( formation ) );
        },
        searchMembersPage: ( searched_profile ) => {
            dispatch( searchMembersPage( searched_profile ) );
        },
    }
}

export default compose(
    withTranslation("post"),
    connect(mapStateToProps, mapDispatchToProps)
)(CreateFormationModalComponent);

