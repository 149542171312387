// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentDynamicFormMakerComponent from 'app/leagues/components/tournament-dynamic-form-maker/TournamentDynamicFormMakerComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './TournamentDynamicFormMakerModalComponent.styles.css';


function TournamentDynamicFormMakerModalComponent( props ) {
    const [submitBtnLbl, setSubmitBtnLbl] = React.useState(props.formData !== undefined? props.saveBtnLbl : props.createBtnLbl);  //eslint-disable-line
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
            className={'tournament-dynamic-form-maker-modal'}
        >
            <TournamentDynamicFormMakerComponent 
                onNewFormValues={ props.onNewFormValues }
                form_title={ props.form_title }
                form_description={ props.form_description }
                submitTeamPlayerForm={ props.submitTeamPlayerForm }
                resetButton={ props.resetButton }
                formData={ props.formData }
                handlePreviewForm={ props.handlePreviewForm }
                formName={ props.formName }
            />
            <div className='tournament-dynamic-form-maker-modal__buttons'>
                <PrimaryButtonComponent
                    type="button"
                    disabled={false}
                    onClick={ props.handleCreate }
                    //className="tournament-create-new-tournament__submit-btn"                
                >
                    <span>{submitBtnLbl}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default TournamentDynamicFormMakerModalComponent;        