// React
import React from 'react';

// Components
import TableComponent from 'shared/components/dynamic-table/TableComponent';

// Styles
import './LeagueStaffComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import StaffActionComponent from '../tournament-staff-actions/StaffActionComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


// Assets

function LeagueStaffComponent ( props ) {

    const [ boxPosition, setBoxPosition ] = React.useState(false);
    const [ isBoxOpen, setBoxOpen ] = React.useState(false);
    const [ editRow, setEditRow ] = React.useState(false);
    const [ editRowId, setEditRowId ] = React.useState(undefined);
    const [roleType, setRoleType ] = React.useState(undefined);

    const handleBoxOpen = (index) => {
        if (!isBoxOpen){
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    }

    const prepareStaff = (staff) => {
        return staff?.map((member) => ({
            ...member, 
            full_name: member.first_name +' '+member.last_name,
            role_translate: (member.role_state == 'Pending'? (props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffRoles.'+member.role)+' <div class="column-tag tooltip '+member.role_state+' baloa-username">'+props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffStates.'+member.role_state)+'</div>') : props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffRoles.'+member.role)),
        }));
    }

    const handleEditRow = (id) => {
        setEditRow(true);
        setEditRowId(id);
    }

    const handleCancelEditRow = (id) => {
        setEditRow(false);
        setEditRowId(undefined);
    }

    const handleRolChange = (event) => {
        setRoleType(event.target.value);
    }

    const handleCheckEditRow = (id) => {
        props.handleOnEditStaffClick(id, roleType)
        setEditRow(false);
        setEditRowId(undefined);
    }

    return(
        <div className={"league-staf__container " + (editRow? 'nohover':'')}>
            <div className='league-staf__header'>
                <div className='baloa-table-column '>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.labelTitle')}</div>
                {props.selected_tournament.state !== 'Finalized' &&
                    <div className='league-staf__header__button-options'>
                        <PrimaryButtonComponent
                            onClick={()=>{handleBoxOpen(true)}}
                            className={'league-staf__header__button-options__button small'}>
                            <span>
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffButtons.addStaff')}                                
                            </span>
                            <span className="hubicon-person_add"></span>
                        </PrimaryButtonComponent>
                        <div className={`league-staf__header__button-options__box ${ boxPosition ? "active" : ""}`} >
                            
                            <div className='league-staf__header__button-options__box__box-option' 
                                onClick={()=> {
                                    props.handleAddStaf('admin');
                                    handleBoxOpen(false);
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.addAdmin')}</div>
                                <div className={"baloa-username"} >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.addAdminText')}</div>
                            </div>
                            
                            <div className='league-staf__header__button-options__box__box-option' 
                                onClick={()=> {
                                    props.handleAddStaf('referee');
                                    handleBoxOpen(false);
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.AddReferee')}</div>
                                <div className={"baloa-username"}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.AddRefereeText')}</div>
                            </div>

                            <div className='league-staf__header__button-options__box__box-option' 
                                onClick={()=> {
                                    props.handleAddStaf('assigner');
                                    handleBoxOpen(false);
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.AddAssigner')}</div>
                                <div className={"baloa-username"}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.addStaffOptions.AddAssignerText')}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <TableComponent
                rows = { prepareStaff(props.staff) }
                columns = { [
                    "full_name",
                    "role_translate"                       
                ] }
                columns_headers = { [                    
                    props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.columns.labelName'),
                    props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.columns.labelRole'),                      
                ] }
                has_image_column = { false }
                image_column = { -1 }
                rows_per_page = { 5 }
                pagination = { true }
                //handleOnActionClick = { (v)=>{ props.handleOnTeamActionClick(v) }}
                action_link={false}
                action_column = { true }
                action_modal_buttons = {false}
                search_by = "full_name"
                searchbar_by = "full_name"
                order_by = "full_name"
                placeholder_search_bar = {props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.labelSearch')}
                has_searchbar = { true }
                has_add_button = { false }  
                has_html={true}
                has_player_column={true}
                player_column={0}
                // has_button_filter = { false }  
                //add_button_caption = {props.t('añadir staff .....')}                                   
                // filter_buttons_captions = { [ ] }   
                // handleOnAddButtonClick = { props.handleOnAddButtonClick } 
                team = { false }
                msg_empty_table_title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.emptyMemberTableTitle')}
                msg_empty_table_text={props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.emptyMemberTableText')}
                //itemClicLabel={ props.t('') }
                action_buttons={ props.selected_tournament.state !== 'Finalized' }
                actions_component = { 
                    <StaffActionComponent               
                        handleOnAcceptClick = { props.handleOnAcceptStaffClick }
                        handleOnRejectClick = { props.handleOnRejectStaffClick }
                        handleOnEditActionClick = { handleEditRow }
                        handleOnCheckEditActionClick = { handleCheckEditRow }
                        handleOnCancelEditActionClick = { handleCancelEditRow}
                        handleOnDeleteActionClick = {props.handleOnDeleteStaffClick }
                        acceptLabel={props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffButtons.acceptStaff')} 
                        ignoreLabel={props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffButtons.ignoreStaff')}
                    ></StaffActionComponent>                                     
                }
                edit_row = {editRow}
                edit_row_column = {1}
                edit_row_id = {editRowId}
                handleRolChange={handleRolChange}                
            >
            </TableComponent>
        </div>
        )
    }
    
    export default withTranslation(['league'])(LeagueStaffComponent)