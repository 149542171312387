/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
//import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import InputFieldAutocompleteWithValidationComponent from 'shared/components/input-field-autocomplete-with-validation/InputFieldAutocompleteWithValidationComponent';
import TournamentTeamPlayerComponent from 'app/leagues/components/tournament-team-player/TournamentTeamPlayerComponent';

// Styles
import './EndTournamentMatchModal.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';


function EndTournamentMatchModal( props ) {
    const [runnerUpfinalistsTeams, setRunnerUpFinalistsTeams] = React.useState( props.tournament_teams );
    const [thirdPlacefinalistsTeams, setThirdPlaceFinalistsTeams] = React.useState( props.tournament_teams );

    const [champion, setChampion] = React.useState( props.classified_teams[0] ? props.classified_teams[0] : null);
    const [championCoach, setChampionCoach] = React.useState( null);
    const [runner_up, setRunnerUp] = React.useState( props.classified_teams[1] ? props.classified_teams[1] : null);
    const [runnerUpCoach, setRunnerUpCoach] = React.useState( null);
    const [third_place, setThirdPlace] = React.useState( props.classified_teams[2] ? props.classified_teams[2] : null);
    const [thirdPlaceCoach, setThirdPlaceCoach] = React.useState( null);

    const [scorer, setScorer] = React.useState(null);
    const [teamScorer, setTeamScorer] = React.useState(null);
    const [leastBeatenFence, setLeastBeatenFence] = React.useState(null);
    const [teamLeastBeatenFence, setTeamLeastBeatenFence] = React.useState(null);
    const [tournamentPlayer, setTournamentPlayer] = React.useState(null);
    const [teamTournamentPlayer, setTeamTournamentPlayer] = React.useState(null);
    const [revelationPlayer, setRevelationPlayer] = React.useState(null);
    const [teamRevelationPlayer, setTeamRevelationPlayer] = React.useState(null);
    const [flagChanges, setFlagChanges] = React.useState(false);
    const [flagScorer, setFlagScorer] = React.useState(false);
    const formRef = React.createRef();

    React.useEffect( () => {

        if(champion === null && !flagChanges  && props.isOpen){
            setChampion(props.classified_teams[0] ? props.classified_teams[0] : null);
            formRef.current?.setFieldValue("champion",  props.classified_teams[0]);
            setRunnerUpFinalistsTeams( props.tournament_teams?.filter(team => (team.teamname !== props.classified_teams[0]?.teamname)));
            if(props.classified_teams[0]) {
                props.handleSearchChampionTeamPlayers(props.classified_teams[0]?.teamname);
            }
        }
        if(runner_up === null && !flagChanges && props.isOpen){
            setRunnerUp(props.classified_teams[1] ? props.classified_teams[1] : null);
            formRef.current?.setFieldValue("runner_up",  props.classified_teams[1]);
            setThirdPlaceFinalistsTeams( runnerUpfinalistsTeams?.filter(team => (team.teamname != props.classified_teams[0]?.teamname) && (team.teamname != props.classified_teams[1]?.teamname)));
            // setChampionFinalistsTeams(props.tournament_teams.filter(team => (team.teamname !== props.classified_teams[1]?.teamname)));
            if(props.classified_teams[1]) {
                props.handleSearchRunnerUpTeamPlayers(props.classified_teams[1]?.teamname);
            }
        }
        if(third_place === null && !flagChanges && props.isOpen){
            setThirdPlace(props.classified_teams[2] ? props.classified_teams[2] : null);
            formRef.current?.setFieldValue("third_place",  props.classified_teams[2]);
            if(props.classified_teams[2])  {
                props.handleSearchThirdPlaceTeamPlayers(props.classified_teams[2]?.teamname);
            }
            // setChampionFinalistsTeams(championFinalistsTeams.filter(team => (team.teamname !== props.classified_teams[1]?.teamname && (team.teamname != props.classified_teams[1]?.teamname))));
        }

        if(props.scorer !== undefined && props.isOpen && !flagScorer){
            let newScorer = props.scorerTeamPlayers.find(player => player.id === props.scorer.id);
            setScorer(newScorer);

            let newScorerTeam = props.tournament_teams.find(team => team.teamname === props?.scorer?.team);
            setTeamScorer(newScorerTeam);
            if(newScorerTeam !== undefined && props.scorerTeamPlayers.length === 0 ){
                props.handleSearchScorerTeamPlayers(newScorerTeam?.teamname);
            }
        }

    }, [ props ] )  // eslint-disable-line

    /* eslint-disable */
    handleChangeChampion = handleChangeChampion.bind(this);
    handleChangeRunnerUp = handleChangeRunnerUp.bind(this);
    handleChangeThirdPlace = handleChangeThirdPlace.bind(this);
    /* eslint-enable */

    const formInitialValues = {
        champion:  props?.classified_teams[0] ? props?.classified_teams[0] : '',
        champion_coach: '',
        runner_up:  props?.classified_teams[1] ? props?.classified_teams[1] : '',
        runner_up_coach: '',
        third_place:  props?.classified_teams[2] ? props?.classified_teams[2] : '',
        scorer: scorer,
        least_beaten_fence: '',
        team_scorer: teamScorer,
        team_least_beaten_fence: '',
        tournamentPlayer: '',
        revelationPlayer: '', 
    }

    async function sendData( values ){
        let tournament_data;
        let least_beaten_fence = values.least_beaten_fence == undefined || values.least_beaten_fence === "" ? null : values.least_beaten_fence.username;
        let scorer = values.scorer == undefined || values.scorer === "" ? null : values.scorer.username;
        let team_least_beaten_fence = values.team_least_beaten_fence == undefined || values.team_least_beaten_fence === "" ? null : values.team_least_beaten_fence.teamname;
        let team_scorer = values.team_scorer == undefined || values.team_scorer === "" ? null : values.team_scorer.teamname;
        let champion_coach = values.champion_coach == undefined || values.champion_coach === "" ? null : values.champion_coach.username;
        let revelationPlayer = values.revelationPlayer === undefined || values.revelationPlayer === "" ? null : values.revelationPlayer.username;
        let tournamentPlayer = values.tournamentPlayer === undefined || values.tournamentPlayer === "" ? null : values.tournamentPlayer.username;
        let team_revelationPlayer = values.team_revelationPlayer === undefined || values.team_revelationPlayer === "" ? null : values.team_revelationPlayer.teamname;
        let team_tournamentPlayer = values.team_tournamentPlayer === undefined || values.team_tournamentPlayer === "" ? null : values.team_tournamentPlayer.teamname;
        let runner_up_coach = values.runner_up_coach === undefined || values.runner_up_coach === "" ? null : values.runner_up_coach.username;
        let third_place_coach = values.third_place_coach === undefined || values.third_place_coach === "" ? null : values.third_place_coach.username;

        if(values.third_place !== ''){
            tournament_data = {
                "champion": values.champion?.teamname ?? null,
                "champion_coach": champion_coach,
                "runner_up": values.runner_up?.teamname ?? null,
                "runner_up_coach": runner_up_coach,
                "third_place": values.third_place?.teamname ?? null,
                "third_place_coach": third_place_coach,
                "scorer": scorer,
                "team_scorer": team_scorer,
                "least_beaten_fence": least_beaten_fence,
                "team_least_beaten_fence": team_least_beaten_fence,
                "tournament_player": tournamentPlayer,
                "team_tournament_player": team_tournamentPlayer,
                "revelation_player": revelationPlayer,
                "team_revelation_player": team_revelationPlayer,
            }
        }else{
            tournament_data = {
                "champion": values.champion?.teamname ?? null,
                "champion_coach": champion_coach,
                "runner_up": values.runner_up?.teamname ?? null,
                "runner_up_coach": runner_up_coach,
                "third_place": third_place?.teamname ?? null,
                "third_place_coach": third_place_coach,
                "scorer": scorer,
                "team_scorer": team_scorer,
                "least_beaten_fence": least_beaten_fence,
                "team_least_beaten_fence": team_least_beaten_fence,
                "tournament_player": tournamentPlayer,
                "team_tournament_player": team_tournamentPlayer,
                "revelation_player": revelationPlayer,
                "team_revelation_player": team_revelationPlayer,
            }
        }
        await props.finishTournament(props.id_tournament,tournament_data);
    }

    function handleChangeChampion(selectedteam){
        setChampion(selectedteam);
        setFlagChanges(true)
        if(selectedteam !== null){
            setRunnerUpFinalistsTeams( props.tournament_teams?.filter(team => (team.teamname !== selectedteam?.teamname)));
            setThirdPlaceFinalistsTeams( runnerUpfinalistsTeams?.filter(team => (team.teamname !== selectedteam?.teamname)));
            props.handleSearchChampionTeamPlayers(selectedteam?.teamname);
            setRunnerUp(null);
            setThirdPlace(null);
            formRef.current?.setFieldValue("champion_coach", '');
            setChampionCoach(null);
            formRef.current?.setFieldValue("runner_up",  '');
            formRef.current?.setFieldValue("runner_up_coach", '');
            setRunnerUpCoach(null);
            formRef.current?.setFieldValue("third_place",  '');
            formRef.current?.setFieldValue("third_place_coach", '');
            setThirdPlaceCoach(null)
        }
    }

    function handleChangeRunnerUp(selectedteam){
        setRunnerUp(selectedteam);
        setFlagChanges(true)
        if(selectedteam !== null){
            setThirdPlaceFinalistsTeams( runnerUpfinalistsTeams?.filter(team => (team.teamname !== selectedteam?.teamname)));
            props.handleSearchRunnerUpTeamPlayers(selectedteam?.teamname);
            setThirdPlace(null);
            formRef.current?.setFieldValue("runner_up_coach",  '');
            setRunnerUpCoach(null);
            formRef.current?.setFieldValue("third_place",  '');
            formRef.current?.setFieldValue("third_place_coach",  '');
            setThirdPlaceCoach(null);
        }
    }

    function handleChangeThirdPlace(selectedteam){
        setThirdPlace(selectedteam);
        if(selectedteam !== null){
            props.handleSearchThirdPlaceTeamPlayers(selectedteam?.teamname);
            formRef.current?.setFieldValue("third_place_coach",  '');
            setThirdPlaceCoach(null);
        }
    }

    const handleChangeScorer = (selectedplayer) =>{
        setScorer(selectedplayer);
    }

    const handleChangeBeatenFence= (selectedplayer) =>{
        setLeastBeatenFence(selectedplayer);
    }

    const handleChangeTournamentPlayer = (selectedPlayer) =>{
        setTournamentPlayer(selectedPlayer);
    }

    const handleChangeRevelationPlayer = (selectedPlayer)=>{
        setRevelationPlayer(selectedPlayer);
    }

    const handleOnClose = () => {
        setChampion(null);
        setChampionCoach(null);
        setRunnerUp(null);
        setThirdPlace(null);
        setTournamentPlayer(null);
        setRevelationPlayer(null);
        setScorer(null);
        setTeamScorer(null);
        setLeastBeatenFence(null);
        setTeamLeastBeatenFence(null);
        setTeamRevelationPlayer(null);
        setTeamTournamentPlayer(null);
        setFlagScorer(false);
        setFlagChanges(false);
        props.onClose();
    }
    /* eslint-disable */
    function handlePodium(){
        let teams = [];
        props.classified_teams.map((team,index)=>{
            if(index < 2){
                teams.push(team);
            }
        })
        return teams;
    }
    /* eslint-enable */

    const hableSelectedTeamScorer =(team)=>{
        if(team !== null){
            setFlagScorer(true);
            setTeamScorer(team);  
            if(team !== teamScorer){
                props.handleSearchScorerTeamPlayers(team?.teamname);
                formRef.current?.setFieldValue("scorer",  ''); 
                setScorer(null);
            }
        }else{
            setTeamScorer(null);
            setScorer(null);
            formRef.current?.setFieldValue("team_scorer",  '');
            formRef.current?.setFieldValue("scorer",  '');
        }
    }

    const handleSelectedBeatenFenceTeam = (beatenFenceTeam) =>{
        if(beatenFenceTeam !== null){
            setTeamLeastBeatenFence(beatenFenceTeam);  
            if(beatenFenceTeam !== teamLeastBeatenFence){
                props.handleSearchBeatenFenceTeamPlayers(beatenFenceTeam?.teamname);
                formRef.current?.setFieldValue("least_beaten_fence",  ''); 
                setLeastBeatenFence(null);
            }
        }else{
            setTeamLeastBeatenFence(null);
            setLeastBeatenFence(null);
            formRef.current?.setFieldValue("team_least_beaten_fence",  '');
            formRef.current?.setFieldValue("least_beaten_fence",  '');
        }
    }

    const handleSelectedTeamTournametPlayers = (teamTournament) =>{
        if(teamTournament !== null){
            setTeamTournamentPlayer(teamTournament);  
            if(teamTournament !== teamTournamentPlayer){
                props.handleSearchtournamentTeamPlayers(teamTournament?.teamname);
                formRef.current?.setFieldValue("tournamentPlayer",  ''); 
                setTournamentPlayer(null);
            }
        }else{
            setTeamTournamentPlayer(null);
            setTournamentPlayer(null);
            formRef.current?.setFieldValue("team_tournamentPlayer",  '');
            formRef.current?.setFieldValue("tournamentPlayer",  '');
        }
    }

    const handleSelectedTeamRevelationPlayer = (teamRevelation)=>{
        if(teamRevelation !== null){
            setTeamRevelationPlayer(teamRevelation);  
            if(teamRevelation !== teamRevelationPlayer){
                props.handleSearchRevelationTeamPlayers(teamRevelation?.teamname);
                formRef.current?.setFieldValue("revelationPlayer",  ''); 
                setRevelationPlayer(null);
            }
        }else{
            setTeamRevelationPlayer(null);
            setRevelationPlayer(null);
            formRef.current?.setFieldValue("team_revelationPlayer",  '');
            formRef.current?.setFieldValue("revelationPlayer",  '');
        }
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={()=>{ handleOnClose() }}
            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTitle')}
            className="end-tournament-match-modal"
        >
            <div className="end-tournament scroll-parent">
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={( values, actions ) => {
                        sendData(values);
                        handleOnClose();
                    }}
                    className="end-tournament__form form scroll-parent"
                    functionDisabled={()=>{}}
                    innerRef={formRef}
                >
                    <div className="end-tournament__form__elements scroll">
                        <div className='end-tournament__form__element-title_section baloa-table'>
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelSubTitle')}
                        </div>
                        <div className="end-tournament__form__elements__content grid-modal">
                                <div className="end-tournament-titles__form__submit baloa-table">
                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label1stPlace')}
                                </div>
                                <InputFieldAutocompleteWithValidationComponent
                                    field_name="champion"
                                    options={ props.tournament_teams }
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "teams_tournament" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTeam')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam')}
                                    onChange = { handleChangeChampion }
                                    value = { champion }
                                    validationFunction={ RequiredValidator }
                                    resetError={ () => {}}
                                    error={ props.errors }
                                />
                                <InputFieldAutocompleteWithValidationComponent
                                    field_name="champion_coach"
                                    options={ props.championTeamPlayers? props.championTeamPlayers : null }
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "players" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label1stPlaceCoach')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderName')}
                                    // validationFunction={ RequiredValidator }
                                    validationFunction={()=>{}}
                                    resetError={ () => {}}
                                    //error={ props.errors }
                                    onChange={(value)=>setChampionCoach(value)}
                                    error={{champion_coach: ''}}
                                    value={championCoach}
                                />

                        </div>
                        <div className="end-tournament__form__elements__content grid-modal">
                                <div className="end-tournament-titles__form__submit baloa-table">
                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label2ndPlace')}
                                </div>
                                <InputFieldAutocompleteWithValidationComponent
                                    field_name="runner_up"
                                    options={ runnerUpfinalistsTeams}
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "teams_tournament" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTeam')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam')}
                                    onChange = { handleChangeRunnerUp }
                                    value = { runner_up }
                                    validationFunction={ RequiredValidator }
                                    resetError={ () => {}}
                                    error={ props.errors }
                                />

                                <InputFieldAutocompleteWithValidationComponent
                                    field_name="runner_up_coach"
                                    options={  props.runnerUpPlayersList ?  props.runnerUpPlayersList : null }
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "players" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label1stPlaceCoach')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderName')}
                                    validationFunction={ ()=>{} }
                                    resetError={ () => {}}
                                    onChange={(value)=>setRunnerUpCoach(value)}
                                    error={ {runner_up_coach: ''} }
                                    value={runnerUpCoach}
                                />


                        </div>
                        <div className="end-tournament__form__elements__field grid-modal">
                                <div className="end-tournament-titles__form__submit baloa-table">
                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label3rdPlace')}
                                </div>
                                <InputFieldAutocompleteWithValidationComponent
                                    name="third_place"
                                    field_name={"third_place"}
                                    // options={ props.tournament_teams? props.tournament_teams.filter(team => (team.teamname != props.classified_teams[0]?.teamname) && (team.teamname != props.classified_teams[1]?.teamname)) : null }
                                    options={ thirdPlacefinalistsTeams}
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "teams_tournament" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTeam')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam')}
                                    onChange = { handleChangeThirdPlace }
                                    value = { third_place }
                                    resetError={ () => {}}
                                    error={ {third_place: ''} }
                                    />
                                <InputFieldAutocompleteWithValidationComponent
                                    field_name="third_place_coach"
                                    options={   props.thirdPlacesPlayersList ?   props.thirdPlacesPlayersList : null }
                                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                                    optionLabelField={ "players" }
                                    optionSelectedField={ "id" }
                                    className="end-tournament__form__elements__content__input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label1stPlaceCoach')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderName')}
                                    validationFunction={ ()=>{} }
                                    resetError={ () => {}}
                                    onChange={(value)=> setThirdPlaceCoach(value)}
                                    error={ {third_place_coach: ''} }
                                    value={thirdPlaceCoach}
                                />

                        </div>
                        <div className="end-tournament__form__elements__field grid-modal">
                            <div className="end-tournament-titles__form__submit baloa-table">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelStriker')}
                            </div>
                            <TournamentTeamPlayerComponent
                                teamFieldName = {"team_scorer"}
                                playerFieldName = {"scorer"}
                                tournament_teams = { props.tournament_teams }
                                tournament_players = { teamScorer !== null ? props.scorerTeamPlayers : null }
                                handleSearchTeamPlayers = { (teamName)=> hableSelectedTeamScorer(teamName) }
                                scorer={ scorer }
                                teamScorer={ teamScorer }
                                handleChangeScorer = { (scorer)=>handleChangeScorer(scorer) }
                            />
                        </div>
                        <div className="end-tournament__form__elements__field grid-modal">
                            <div className="end-tournament-titles__form__submit baloa-table">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelLeastAllowedGoals')}
                            </div>
                            <TournamentTeamPlayerComponent
                                teamFieldName = {"team_least_beaten_fence"}
                                playerFieldName = {"least_beaten_fence"}
                                tournament_teams = { props.tournament_teams }
                                tournament_players = { teamLeastBeatenFence !== null ? props.beatenFenceTeamPlayers : null}
                                handleSearchTeamPlayers = { (teamBeaten) => handleSelectedBeatenFenceTeam(teamBeaten) }
                                scorer = { leastBeatenFence }
                                teamScorer={ teamLeastBeatenFence }
                                handleChangeScorer = { (player)=> handleChangeBeatenFence(player) }
                            />
                        </div>
                        <div className="end-tournament__form__elements__field grid-modal">
                            <div className="end-tournament-titles__form__submit baloa-table">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTournamentPlayer')}
                            </div>
                            <TournamentTeamPlayerComponent
                                teamFieldName = {"team_tournamentPlayer"}
                                playerFieldName = {"tournamentPlayer"}
                                tournament_teams = { props.tournament_teams }
                                tournament_players = { teamTournamentPlayer !== null ? props.tournamentPlayersList : null }
                                handleSearchTeamPlayers = { (teamTournament)=> handleSelectedTeamTournametPlayers(teamTournament)}
                                scorer = { tournamentPlayer }
                                teamScorer={teamTournamentPlayer }
                                handleChangeScorer = { (player)=> handleChangeTournamentPlayer(player) }
                            />
                        </div>
                        <div className="end-tournament__form__elements__field grid-modal">
                            <div className="end-tournament-titles__form__submit baloa-table">
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelRevelationPlayer')}
                            </div>
                            <TournamentTeamPlayerComponent
                                teamFieldName = {"team_revelationPlayer"}
                                playerFieldName = {"revelationPlayer"}
                                tournament_teams = { props.tournament_teams }
                                tournament_players = { props.revelationPlayersList !== null ? props.revelationPlayersList : null}
                                handleSearchTeamPlayers = { (teamRevelation)=> handleSelectedTeamRevelationPlayer(teamRevelation)}
                                scorer = { revelationPlayer }
                                teamScorer = {teamRevelationPlayer }
                                handleChangeScorer = { (player)=>handleChangeRevelationPlayer(player) }
                            />
                        </div>

                    </div>
                    <div className="end-tournament__form__actions form__actions">
                        <PrimaryButtonComponent
                            type="submit"
                            disabled={false}
                            onClick={()=>{}}
                            className="end-tournament__form__submit"
                        >
                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelEnd')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>
            </div>

        </Modal2Component>
    )

}

export default withTranslation('league')(EndTournamentMatchModal);
