// React
import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

//Material
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import HubTournamentMatchesDatesComponent from 'app/tournaments/components/hub-tournament-matches-dates/HubTournamentMatchesDatesComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//Assets
import empty_hub_tournament_matches from 'assets/images/empty_hub_tournament_matches.png'

// Styles
import './HubTournamentMatchesComponent.styles.css';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';


function HubTournamentMatchesComponent( props ) {

    let new_grouped_tournaments = _.groupBy(props.matches, 'tournament_name');

    const [accordionArray, setAccordionArray] = React.useState([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,]);

    const history = useHistory();
    /* const clickTournament = (tournament_id) => {
        history.push(`/tournament/hub/${tournament_id}`);
    } */

    const matchClick = (tournament_id, phase_id, match_date_id, match_id, participate) => {
        history.push(`/tournament/hub/${tournament_id}&phase=${phase_id}&match_date=${match_date_id}&match=${match_id}&type=match&participate=${participate}`);
    }

    const handleExpanded= (index) => {
        console.log('cambio de estado', index);
        let tempArray = [].concat(accordionArray);
        tempArray[index] = !tempArray[index];
        setAccordionArray(tempArray);
    }

    return (       
        <div className='hub-tournament-matches__container'>
            {!isMobile &&
                <div className='hub-tournament-matches__title baloa-headline-3'>
                    {props.t('menu.matchesLabelTitle')}
                </div>
            }
            {props.tournaments_dates?.length === 0 &&
                <div className='hub-tournament-matches__empty'>
                    <img src={empty_hub_tournament_matches} alt="empty hub tournament matches" />
                    <div className='baloa-subtitle-2'>{props.t('emptyMessages.emptyHubTournamentsMatchesDates')}</div>
                </div>
            }
            {props.tournaments_dates?.length > 0 &&
                <React.Fragment>
                    <HubTournamentMatchesDatesComponent 
                        tournaments_dates={props.tournaments_dates}
                        loadDateMatches={props.loadDateMatches}
                    />
                    {Object.keys(new_grouped_tournaments).map((tournament,index) => (
                        <div className='hub-tournament-matches__accordion' key={index}>
                            <Accordion className='hub-tournament-matches__tournament-matches' expanded={ accordionArray[index]} onChange={()=>{ handleExpanded(index) } }>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className='hub-tournament-matches__matches baloa-mini'
                                >                                    
                                    <div className='hub-tournament-matches__tournament_info'>
                                        <div className='hub-tournament-matches__tournament_info__img'>
                                            { new_grouped_tournaments[tournament][0].tournament_photo
                                                ? <img src={new_grouped_tournaments[tournament][0].tournament_photo} alt={`${new_grouped_tournaments[tournament][0].tournament_name} logo`} />
                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                            }
                                        </div>
                                        <div className='baloa-names'>{new_grouped_tournaments[tournament][0].tournament_name}</div>                                                                         
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='hub-tournament-matches__matches__container'>
                                    {(new_grouped_tournaments[tournament][0].matches? new_grouped_tournaments[tournament][0].matches : new_grouped_tournaments[tournament])?.map( tournament_matches => (
                                        (!isMobile && 
                                            <div className='hub-tournament-matches__matches__match' 
                                                key={tournament_matches.id}
                                                onClick={()=>{ matchClick(new_grouped_tournaments[tournament][0].tournament_id, tournament_matches.tournament_phase_id, tournament_matches.match_date_id,tournament_matches.id,new_grouped_tournaments[tournament][0].participate) }}
                                            >
                                                <div className='hub-tournament-matches__matches__match-info' >
                                                    <div className='hub-tournament-matches__matches__match-date baloa-username'>
                                                            {`${moment(tournament_matches.match_date + ' ' + tournament_matches.match_time).format('MMM DD, YYYY - h:mm A')}`}
                                                    </div>
                                                    {!(tournament_matches.match_state === 'Scheduled' || tournament_matches.state === 'Scheduled') &&
                                                        <div className='hub-tournament-matches__matches__match-state baloa-username'>
                                                            {props.t(`league:matchAdminFilter.filterLabels.${tournament_matches.match_state? tournament_matches.match_state : tournament_matches.state}`)}
                                                        </div>
                                                    }                                                    
                                                </div>
                                                <div className='hub-tournament-matches__matches__match-team baloa-names'>
                                                        { tournament_matches.local_team_photo
                                                            ? <img src={tournament_matches.local_team_photo} alt={`${tournament_matches.local_tournament_team_name} logo`} />
                                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        }
                                                        {tournament_matches.local_tournament_team_name? tournament_matches.local_tournament_team_name : tournament_matches.local_team_name}
                                                        {(tournament_matches.match_state === 'Finalized' || tournament_matches.state === 'Finalized') &&
                                                            <div className='hub-tournament-matches__matches__match-team-score' >{`${tournament_matches.local_team_score}`}</div>
                                                        }
                                                </div>
                                                <div className='hub-tournament-matches__matches__match-team baloa-names'>
                                                        { tournament_matches.visitant_team_photo
                                                            ? <img src={tournament_matches.visitant_team_photo} alt={`${tournament_matches.visitant_tournament_team_name} logo`} />
                                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        }
                                                        {tournament_matches.visitant_tournament_team_name? tournament_matches.visitant_tournament_team_name : tournament_matches.visitant_team_name}
                                                        {(tournament_matches.match_state === 'Finalized' || tournament_matches.state === 'Finalized') &&
                                                            <div className='hub-tournament-matches__matches__match-team-score' >{`${tournament_matches.visitant_team_score}`}</div>
                                                        }
                                                </div>
                                            </div>
                                        )
                                        ||
                                        (isMobile &&
                                            <div className='hub-tournament-matches__matches__match'
                                                onClick={()=>{ matchClick(new_grouped_tournaments[tournament][0].tournament_id, tournament_matches.tournament_phase_id, tournament_matches.match_date_id,tournament_matches.id,new_grouped_tournaments[tournament][0].participate) }}
                                            >
                                                {tournament_matches.match_state === 'Scheduled' || tournament_matches.state === 'Scheduled'?
                                                    <div className='hub-tournament-matches__matches__match-date baloa-username'>
                                                            {`${moment(tournament_matches.match_date + ' ' + tournament_matches.match_time).format('MMM DD, YYYY - h:mm A')}`}
                                                    </div>
                                                    :
                                                    <div className='hub-tournament-matches__matches__match-state baloa-username'>
                                                        {props.t(`league:matchAdminFilter.filterLabels.${tournament_matches.match_state? tournament_matches.match_state : tournament_matches.state}`)}
                                                    </div>
                                                }
                                                <div className='hub-tournament-matches__matches__match-teams-info'>
                                                    <div className='hub-tournament-matches__matches__match-team baloa-names'>                                                        
                                                            {tournament_matches.local_tournament_team_name? tournament_matches.local_tournament_team_name : tournament_matches.local_team_name}
                                                            { tournament_matches.local_team_photo
                                                                ? <img src={tournament_matches.local_team_photo} alt={`${tournament_matches.local_tournament_team_name} logo`} />
                                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                            }
                                                            {(tournament_matches.match_state === 'Finalized' || tournament_matches.state === 'Finalized')?
                                                                <div>{`${tournament_matches.local_team_score} -`}&nbsp;</div>
                                                                :
                                                                <div>&nbsp;{'V'}</div>
                                                            }
                                                    </div>
                                                    <div className='hub-tournament-matches__matches__match-team baloa-names'>
                                                            {(tournament_matches.match_state === 'Finalized' || tournament_matches.state === 'Finalized')?
                                                                <div>{`${tournament_matches.visitant_team_score}`}</div>
                                                                :
                                                                <div>{'S'}&nbsp;</div>
                                                            }
                                                            { tournament_matches.visitant_team_photo
                                                                ? <img src={tournament_matches.visitant_team_photo} alt={`${tournament_matches.visitant_tournament_team_name} logo`} />
                                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                            }
                                                            {tournament_matches.visitant_tournament_team_name? tournament_matches.visitant_tournament_team_name : tournament_matches.visitant_team_name}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        
                                    ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>                   
                        </div>
                    )) }
                </React.Fragment>
            }
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentMatchesComponent);
