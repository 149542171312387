// React
import React from 'react';

// Components

// Styles
import './TournamentRecognitionPodiumComponent.styles.css';

// Functions
import {getRandonIconBallColor} from "../../../../shared/functions/GetRandomIconColor";

//i18
import { withTranslation } from 'react-i18next';

function TournamentRecognitionPodiumComponent ( props ){

    return(
        <div className="podium-content__recognition__boxes">
            <div className="podium-content__recognition__boxes__box left">
                <div className="podium-content__recognition__boxes__box__image">
                    <div className="podium-content__recognition__boxes__box__image__content">
                        {props.selected_tournament?.scorer_photo && <img className='has_img' src={ props.selected_tournament.scorer_photo } alt=""/>}
                        {!props.selected_tournament?.scorer_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}                        
                    </div>
                </div>
                <div className="podium-content__recognition__boxes__box__text"
                    onClick = {
                        ()=>{ props.handleOnProfileClick( props.selected_tournament.scorer ) }
                    }
                >
                    <p className="podium-content__recognition__boxes__box__text__title baloa-table-column">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelScorer')}
                    </p>
                    <p className="baloa-subtitle-2">
                        {props.selected_tournament.scorer_name}
                    </p>
                </div>  
                <div className="podium-content__recognition__boxes__box__team"
                    onClick = {
                        ()=>{ props.handleOnPageClick( props.selected_tournament.team_scorer_page_owner, props.selected_tournament.team_scorer ) }
                    }
                >
                    <div className="podium-content__recognition__boxes__box__team__image">
                        {props.selected_tournament?.team_scorer_photo && <img className='has_img' src={ props.selected_tournament.team_scorer_photo } alt=""/>}
                        {!props.selected_tournament?.team_scorer_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}    
                    </div>
                    <p className="baloa-caption">{ props.selected_tournament?.team_scorer }</p>
                </div>                
            </div>
            <div className="podium-content__recognition__boxes__box right">
                <div className="podium-content__recognition__boxes__box__image">
                    <div className="podium-content__recognition__boxes__box__image__content">
                        {props.selected_tournament?.least_beaten_fence_photo && <img className='has_img' src={ props.selected_tournament.least_beaten_fence_photo } alt=""/>}
                        {!props.selected_tournament?.least_beaten_fence_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}
                    </div>
                </div>
                <div className="podium-content__recognition__boxes__box__text"
                    onClick = {
                        ()=>{ props.handleOnProfileClick( props.selected_tournament.least_beaten_fence ) }
                    }
                >
                    <p className="podium-content__recognition__boxes__box__text__title baloa-table-column">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelLeastBeatenFence')}
                    </p>
                    <p className="baloa-subtitle-2">
                        {props.selected_tournament.least_beaten_fence_name}
                    </p>
                </div>
                <div className="podium-content__recognition__boxes__box__team"
                    onClick = {
                        ()=>{ props.handleOnPageClick( props.selected_tournament.team_least_beaten_fence_page_owner, props.selected_tournament.team_least_beaten_fence ) }
                    }
                >
                    <div className="podium-content__recognition__boxes__box__team__image">
                        {props.selected_tournament?.team_least_beaten_fence_photo && <img className='has_img' src={ props.selected_tournament.team_least_beaten_fence_photo } alt=""/>}
                        {!props.selected_tournament?.team_least_beaten_fence_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>} 
                    </div>
                    <p className="baloa-caption ">{ props.selected_tournament?.team_least_beaten_fence }</p>
                </div>
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentRecognitionPodiumComponent);