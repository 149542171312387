// React
import React from 'react'

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './ProfileTournamentsTeamsTableComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileTournamentsTeamsTableComponent( props ) {

   return (
        <div className="profile-tournaments-table__container">
            
            <div className='profile-tournaments-table__table' >
                <div className='profile-tournaments-table__table-header'>
                    <div className='profile-tournaments-table__table-header-col baloa-normal-medium'>{props.summary_type === 'tournaments_summary' ? props.t('tournamentSummary.labelTournament') : props.t('tournamentSummary.labelTeam')}</div>
                    <div className='profile-tournaments-table__table-header-col'>
                        <div className='baloa-normal-medium played_games'>{props.t('tournamentSummary.labelPlayedGames')}<span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.playedGamesTooltip')}</span></div>
                        <span class="hubicon-titular"><span class="path1"></span><span class="path2"></span><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.titularTooltip')}</span></span>
                        <span className='hubicon-timer'><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.timerTooltip')}</span></span>
                        <span className='icon-ball'><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.goalsTooltip')}</span></span>
                        <span class="hubicon-assists">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>
                            <span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.assistancesTooltip')}</span>
                        </span>
                        <span className='hubicon-goalkeeper'><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.undefeatedFencesTooltip')}</span></span>                        
                        {/* <div className='baloa-normal-medium goals'>{props.t('tournamentSummary.labelGoalPerGame')}<span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.goalsPerGameTooltip')}</span></div> */}
                        <div className='profile-tournaments-table__table-header-col__yellow-card'><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.yellowCardTooltip')}</span></div>
                        <div className='profile-tournaments-table__table-header-col__red-card'><span class="profile-tournaments-table__tooltip">{props.t('tournamentSummary.redCardTooltip')}</span></div>
                    </div>
                </div>
                {props.table_data?.length > 0 
                    ? <div className='profile-tournaments-table__table-body'>
                        { props.table_data?.map((element, index) => (
                        <div key={index} className='profile-tournaments-table__table-body__container'>
                            <div className='profile-tournaments-table__table-body-col summary-info'>
                                <div className='profile-tournaments-table__table-body-col__img'>
                                    { props.summary_type === 'tournaments_summary' &&
                                        <div className='profile-tournaments-table__table-body-col__img-container'>
                                            { element.tournament_logo
                                                ? <img src={element.tournament_logo} alt={`${element.tournament_name} logo`} />
                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                            }
                                        </div>
                                    }
                                    { props.summary_type === 'teams_summary' &&
                                        <div className='profile-tournaments-table__table-body-col__img-container'>
                                            { element.team_logo
                                                ? <img src={element.team_logo} alt={`${element.teamname} logo`} />
                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                            }
                                        </div>
                                    }                                            
                                </div>
                                { props.summary_type === 'tournaments_summary' &&
                                    <div className='profile-tournaments-table__table-body-col__info'>
                                        <div className='baloa-normal-medium' >{element.tournament_name}</div>
                                        <div className='profile-tournaments-table__table-body-col__info__labels'>
                                            <div className='baloa-table-column'>{props.t(`tournamentSummary.footballType.${element.tournament_football_type}`)}</div>
                                            <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${element.tournament_gender}`)}</div>
                                            <div className='baloa-table-column'>{element.tournament_category}</div>
                                        </div>
                                    </div>
                                }
                                { props.summary_type === 'teams_summary' &&
                                    <div className='profile-tournaments-table__table-body-col__info'>
                                        <div className='baloa-normal-medium' >{element.team}</div>
                                        <div className='profile-tournaments-table__table-body-col__info__labels'>
                                            <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${element.team_gender}`)}</div>
                                            <div className='baloa-table-column'>{element.team_category}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='profile-tournaments-table__table-body-col summary-values'>
                                <div className={`baloa-normal-medium c1 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.games_played}</div>
                                <div className={`baloa-normal-medium c2 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.played_as_titular}</div>
                                <div className={`baloa-normal-medium c3 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.minutes_played}</div>
                                <div className={`baloa-normal-medium c4 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.goals}</div>
                                <div className={`baloa-normal-medium c5 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.assistance}</div>
                                <div className={`baloa-normal-medium c6 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.unbeaten_games}</div>
                                {/* <div className={`baloa-normal-medium ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.goals_match}</div> */}
                                <div className={`baloa-normal-medium c7 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.yellow_cards}</div>
                                <div className={`baloa-normal-medium c8 ${!element.player_has_paid ? 'locked_values' : ''}`} >{element.red_cards}</div>
                            </div>
                        </div>
                    ))}
                </div>
                :''
                }
            </div>
           
        </div>
    )
}

export default withTranslation('profile')(ProfileTournamentsTeamsTableComponent);

