
// React
import React from 'react';
import { connect } from 'react-redux';

// Axios
import axios from 'axios'; 

// Globals
import { api_url } from "config/GlobalData";

// Actions

import { loadPage } from 'app/pages/actions/PageActions';

// Cropper
import Cropper from 'react-easy-crop';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './PageUploadPhotoModalComponent.styles.css';

// Assets


import blankProfile from 'assets/icons/blankProfile.svg';


// Utilities
import getCroppedImg from 'shared/functions/CropImage';
import Loader from "react-loader-spinner";

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';



class PageUploadPhotoModalComponent extends React.Component  {
    
    formInitialValues = {
        caption: "",
    }

    state = { 
        selected_image: null,
        selected_image_url: null,
        image_input_ref: null,
        upload_progress: 0,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
        cropped_area_pixels: { },
        cropped_image: null,
        cropped_image_url: null,
    }; 

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (cropped_area, cropped_area_pixels) => {
        this.setState({ cropped_area_pixels: cropped_area_pixels })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    
    onImageChange = event => { 
        this.setState( { selected_image: event.target.files[0] } ); 
        this.setState( { selected_image_url: URL.createObjectURL( event.target.files[0] ) } );
    }; 

    onUploadProgress = ( progressEvent ) => {
        this.setState( { upload_progress: Math.round( progressEvent.loaded / progressEvent.total * 100 ) } );
    }

    executeImageAction = () =>  {
      if (this.state.selected_image) {
         this.setState({loading: true});
         this.showCroppedImage()
            .then(()=> {
               this.onFileUpload()
            }
            )
            .catch(err => console.log(err));
      }
    }
       
    onFileUpload = async () => { 
        const formData = new FormData(); 
        if (this.state.cropped_image) {
            const image = new File([this.state.cropped_image], 'page-image.jpg', {
                type: 'image/png'
            });
            formData.append( 
                "photo", 
                image, 
                this.state.cropped_image.name,
            ); 

        }
        axios.patch(
            `${ api_url }/pages/${this.props.state.page.page.pagename}/edit_page/`, 
            formData, 
            {
                headers: {
                    'Authorization': localStorage.getItem('token')
                },
                onUploadProgress: this.onUploadProgress,
            }
        ).then(() => {
            this.onRemoveImage();
            this.props.loadPage( this.props.state.page.page.pagename );
            this.props.onClose();
        }
        ).catch(
            err => console.log(err),
            console.log('Error uploading image'),
            this.onRemoveImage(), // TODO: revisar error cuando se prueba pagina testlioga
            this.props.loadPage( this.props.state.page.page.pagename ), // TODO: revisar error cuando se prueba pagina testlioga
            this.props.onClose(), // TODO: revisar error cuando se prueba pagina testlioga
        )
    }; 
       
    showCroppedImage = async () => {
        try {
            const cropped_image = await getCroppedImg(
                this.state.selected_image_url,
                this.state.cropped_area_pixels,
                0
            )
            this.setState({ cropped_image: cropped_image });
            this.setState( { cropped_image_url: URL.createObjectURL( cropped_image ) } );
        } catch (e) {
            console.error(e)
        }
    }

    onRemoveImage = () => {
      this.setState({ cropped_image: null, cropped_image_url: null, selected_image_url:null, selected_image:null });
    }
    
    render() {
        
        return (
            <Modal2Component 
            isOpen={this.props.isOpen} 
            onClose={this.props.onClose}
            title={this.props.t('uploadPhotoPage.title')} 
            className="medium"
            >
                <div className="profile-new-photo">

                    {/* Content */}
                    <div className="profile-new-photo__content">
                        
                        {/* Crop Image */}
                        { this.state.selected_image_url && !this.state.cropped_image && <div className="profile-new-photo__content__media"> 
                            <Cropper
                                image={this.state.selected_image_url}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                            />
                        </div> }
                        
                        {/* Display Image */}
                        {this.state.cropped_image && <div className="profile-new-photo__content__result">
                     <div className="profile-new-photo__content__result__container">
                        <div className="profile-new-photo__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="profile-new-photo__content__result"/>                           
                        </div>                        
                        <img src={ this.state.cropped_image_url} alt=""/>                        
                     </div>
                  </div>}

                     {/* Blank Image */}
                     {!this.state.cropped_image && !this.state.selected_image_url && <div className="profile-new-photo__content__result">
                        <div className="profile-new-photo__content__result__container">
                           <img src={blankProfile} alt="" />
                        </div>
                     </div>}                        
                    </div>

                  {/* Load image button */}
                  {!this.state.cropped_image && !this.state.selected_image_url && <div className="profile-new-photo__action">
                     <SecondaryButtonComponent
                        onClick={() => { this.image_input_ref.click() }}
                        className="profile-new-photo__action__button"
                        disabled={this.state.cropped_image ? true : false}
                     >
                        <span>{this.props.t('uploadPhotoPage.buttonImg')}</span>
                     </SecondaryButtonComponent>
                     <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={this.onImageChange}
                        ref={image_input => this.image_input_ref = image_input}
                        accept="image/*"
                     />
                  </div>}                    

                </div>

                <div className="profile-new-photo__form">
                    <FormComponent 
                        formInitialValues={this.formInitialValues} 
                        onSubmit={( values, actions ) => { 
                            
                        }}
                        className="profile-new-photo__form__form"
                        functionDisabled={()=>{}}
                    >
                        <div className="profile-new-photo__form__group">
                           <SecondaryButtonComponent
                              type="button"
                              onClick={ this.onRemoveImage }
                              disabled={(!this.state.selected_image) ? true : false}
                              className="profile-new-photo__form__submit">
                              <span>{this.props.t('common:actions.delete')}</span>
                           </SecondaryButtonComponent>                        
                            <PrimaryButtonComponent 
                                type="button"
                                disabled={ (!this.state.selected_image) ? true : false } 
                                onClick={ this.executeImageAction }
                                className={(this.state.cropped_image) ? "profile-new-photo__form__submit_invisible" : "profile-new-photo__form__submit"}
                            >
                                <span>{this.props.t('uploadPhotoPage.buttonOk')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </FormComponent>
                </div>
            </Modal2Component>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: ( pagename ) => {
            return dispatch( loadPage( pagename ) )
        },
    }
}

export default compose(
   withTranslation(["page","common"]),   
    connect(mapStateToProps, mapDispatchToProps)
)(PageUploadPhotoModalComponent);

//export default connect(mapStateToProps, mapDispatchToProps)(PageUploadPhotoModalComponent);