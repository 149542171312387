// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.existing-form__content {
    padding: var(--space-x2-and-half) calc(var(--space-x3)*2.4);
 }

.existing-form__content__messages{
    padding-bottom: var(--space);
    color: var(--primary-very-dark-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.0025em;
}

.existing-form__content__item{
    border: 1px solid var(--new-grey-border-color);
    display: flex;
    padding: var(--space);
    margin-bottom: var(--space-half-quarter);
    border-radius: 5px;
    align-items: center;
}

.existing-form__content__item__texts{
    padding-left: var(--space);
}

.existing-form__content__item__texts__name.baloa-table-column{
    color: var(--primary-very-dark-color);
}

.existing-form__content__item__texts__creator.baloa-mini{
    color: var(--primary-dark-color);
}

.existing-form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.existing-form__content__items-list .MuiFormControlLabel-root {
    width: 100%;
}

.existing-form__content__items-list .MuiIconButton-root {
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournaments-modal-form-existing/TournamentsModalFormExistingComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;CAC9D;;AAED;IACI,4BAA4B;IAC5B,qCAAqC;IACrC,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,8CAA8C;IAC9C,aAAa;IACb,qBAAqB;IACrB,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".existing-form__content {\n    padding: var(--space-x2-and-half) calc(var(--space-x3)*2.4);\n }\n\n.existing-form__content__messages{\n    padding-bottom: var(--space);\n    color: var(--primary-very-dark-color);\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 18px;\n    letter-spacing: 0.0025em;\n}\n\n.existing-form__content__item{\n    border: 1px solid var(--new-grey-border-color);\n    display: flex;\n    padding: var(--space);\n    margin-bottom: var(--space-half-quarter);\n    border-radius: 5px;\n    align-items: center;\n}\n\n.existing-form__content__item__texts{\n    padding-left: var(--space);\n}\n\n.existing-form__content__item__texts__name.baloa-table-column{\n    color: var(--primary-very-dark-color);\n}\n\n.existing-form__content__item__texts__creator.baloa-mini{\n    color: var(--primary-dark-color);\n}\n\n.existing-form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n\n.existing-form__content__items-list .MuiFormControlLabel-root {\n    width: 100%;\n}\n\n.existing-form__content__items-list .MuiIconButton-root {\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
