// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-interests__container{
    padding-top: 50px;
}
.profile-interests-sugestions.baloa-card-1 {
    background-color: transparent !important;
    border: none;
    padding: 0px;
}
.profile-interests__list-sugestions {
    
    display: flex;
    flex-direction: column;

}
.profile-interests__list__content-sugestions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: var(--space-and-half);
    column-gap: var(--space-and-half);
    grid-row-gap: var(--space-and-half);
    row-gap: var(--space-and-half);
    margin-top: var(--space-x2);
    margin-bottom: var(--space-x2);
    /* height: 95vh;    */
}

.profile-interests__list__content-sugestions.eight-width{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .profile-interests__list__content-sugestions{
        display: block;
        margin-top: 0;
    }

    .profile-interests__container.home-pages__sugestions.explore {
        padding-top: 108px;
        z-index: 4;
        width: -webkit-fill-available;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/components/page-groups-suggestions/SugestionsComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,wCAAwC;IACxC,YAAY;IACZ,YAAY;AAChB;AACA;;IAEI,aAAa;IACb,sBAAsB;;AAE1B;AACA;IACI,aAAa;IACb,kCAAkC;IAClC,uBAAuB;IACvB,sCAAsC;IACtC,iCAAiC;IACjC,mCAAmC;IACnC,8BAA8B;IAC9B,2BAA2B;IAC3B,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,cAAc;QACd,aAAa;IACjB;;IAEA;QACI,kBAAkB;QAClB,UAAU;QACV,6BAA6B;IACjC;AACJ","sourcesContent":[".profile-interests__container{\n    padding-top: 50px;\n}\n.profile-interests-sugestions.baloa-card-1 {\n    background-color: transparent !important;\n    border: none;\n    padding: 0px;\n}\n.profile-interests__list-sugestions {\n    \n    display: flex;\n    flex-direction: column;\n\n}\n.profile-interests__list__content-sugestions {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 1fr;\n    grid-column-gap: var(--space-and-half);\n    column-gap: var(--space-and-half);\n    grid-row-gap: var(--space-and-half);\n    row-gap: var(--space-and-half);\n    margin-top: var(--space-x2);\n    margin-bottom: var(--space-x2);\n    /* height: 95vh;    */\n}\n\n.profile-interests__list__content-sugestions.eight-width{\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .profile-interests__list__content-sugestions{\n        display: block;\n        margin-top: 0;\n    }\n\n    .profile-interests__container.home-pages__sugestions.explore {\n        padding-top: 108px;\n        z-index: 4;\n        width: -webkit-fill-available;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
