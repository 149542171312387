// React
import React from 'react';
//import InfiniteScroll from "react-infinite-scroll-component";

// Styles
import './SugestionsComponent.styles.css';

// Components
import SugestionComponent from 'app/pages/components/page-groups-suggestions/SugestionComponent';

// i18n
import { withTranslation } from 'react-i18next';

//Assets
//import loading_ball from 'assets/images/loading_ball.gif';

function SugestionsComponent ( props ) {

    /* const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.pages_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.pages_next_page]); // eslint-disable-line */

    const [activePage, setActivePage]= React.useState(undefined);

    React.useEffect( () => {
        // Need more pages?
        if( activePage == props.pages[ props.pages?.length -1 ] || activePage == props.pages[ props.pages?.length -2 ] || activePage == props.pages[ props.pages?.length -3 ] ) { 
            props.fetchMoreData();
        }
    }, [ activePage ] );// eslint-disable-line

    return (
        <div className={`profile-interests__container ${props.className}`}>
            <div className="profile-interests__title baloa-subtitle-1">{props.title}</div>
            <div className="profile-interests__list-sugestions">
                <div className={"profile-interests__list__content-sugestions " + props.addClassName}>
                    {/* <InfiniteScroll
                        className={"profile-interests__list__content-sugestions " + props.addClassName}
                        dataLength={props.pages.length}
                        next={props.fetchMoreData}
                        hasMore={loadMore}
                        loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                        height={props.scroll_height? props.scroll_height : '95vh'}
                        endMessage={''}
                    > */}
                        {props.pages && props?.pages.map( page => 
                            <SugestionComponent 
                                key = { page.id }
                                page = { page }
                                handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                handleFollow = { props.handleFollow }
                                handleUnFollow = { props.handleUnFollow }
                                followCaption = { props.followCaption }
                                followingCaption = { props.followingCaption }
                                page_type = { props.page_type }
                                onEnterViewport = { () => { 
                                    setActivePage( page );
                                } }
                                onExitViewport = { () => {} }
                            />
                        )}
                    {/* </InfiniteScroll> */}
                </div>
            </div>  
        </div>
    )
}

export default withTranslation('profile')(SugestionsComponent);