// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './SelectFieldMoreOptionsComponent.styles.css';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// i18n
import { withTranslation } from 'react-i18next';


class SelectFieldMoreOptionsComponent extends React.Component {

    render() {

        const field_name = this.props.field_name;

        return (

            <Field name={field_name} validate={this.props.validationFunction} >
                {(data) => {
                    // Backend Errors

                    if (this.props.error[field_name] !== "") {
                        data.form.errors[field_name] = this.props.error[field_name];
                    }
                    return (
                        <div className={"select-field-more-options " + (this.props.className ? this.props.className : '')}>
                            {this.props.label && <label className="select-field-more-options-label" htmlFor={this.props.input_id}>{this.props.label}</label>}
                            <Select
                                id={this.props.input_id}
                                disabled={this.props.disabled}
                                onClick={this.props.onClick}
                                onChange={this.props.onChange ? this.props.onChange(data.field.value) : undefined}
                                className={
                                    'select-field-more-options-input ' + ((data.form.errors[field_name] && data.form.touched[field_name]) ? 'error' : '')
                                }
                                {...data.field}
                                label={this.props.placeholder}
                                placeholder={(this.props.placeholder ? this.props.placeholder : '')}
                                displayEmpty
                                defaultValue={this.props.defaultValue}
                                variant='standard'
                            >
                                <MenuItem 
                                    onClick={ () => data.field.value = '' }
                                    key="" 
                                    value="">
                                        {(this.props.placeholder ? this.props.placeholder : '')}
                                </MenuItem>
                                {this.props.options.map(option => {
                                    return (
                                        <MenuItem
                                            onClick={() => data.field.value = option.value}
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.flag_icon &&
                                                <img src={option.flag_icon} alt="" className="select-field-more-options-icon" />
                                            }
                                            {option.content}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <p className="select-field-more-options__message">{data.form.errors[field_name] ? this.props.t('requiredValidator') : this.props.message}</p>
                        </div>
                    )

                }}
            </Field>

        );
    }
}

// Types
SelectFieldMoreOptionsComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
}
export default withTranslation('validators')(SelectFieldMoreOptionsComponent);
