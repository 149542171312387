
// Material
import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const baloa_theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#94A89F',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: '#0066ff',
            //main: '#00E063',
            main: '#30B36B',
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    breakpoints:{
        values:{
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
                flexContainer : {
                    borderBottom: '2px solid #D8E7E0',
                },
                indicator: {
                    //backgroundColor: '#00E063',
                    backgroundColor: '#30B36B',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 54,
                    '&:hover': {
                        [`& .${outlinedInputClasses.wrapper}`]: {
                            //color: '#00E063',
                            color: '#30B36B',
                        },
                    },
                    '& .Mui-selected': {
                        '& *': {
                            //color: '#00E063',
                            color: '#30B36B',
                        },
                    },
                },
                selected: {},
                textColorInherit: {
                    opacity: 1,
                },
                wrapper: {
                    textTransform: 'uppercase',
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#4D5451',
                },
            }
        }
    }
   
});