export const changeNewTeamError = ( data ) => {
    return { type: "TEAM:CHANGE_NEW_TEAM_ERROR", data }
}

export const loadTeamDataAction = ( data ) => {
    return { type: "TEAM:LOAD_TEAM", data }
}

export const updateTeamExtraDataAction = ( data ) => {
    return { type: "TEAM:UPDATE_TEAM_EXTRA", data }
}

export const searchMembersWithValidationAction = ( data ) => {
    return { type: "TEAM:SET_SEARCHED_MEMBERS", data }
}

export const deletePlayerFromTeamAction= ( data ) => {
    return { type: "TEAM:DELETE_PLAYER_FROM_TEAM", data }
}
    
export const addPlayerToTeamAction= ( data ) => {
    return { type: "TEAM:ADD_PLAYER_TO_TEAM", data }
}
    
export const getTeamPlayersAction= ( data ) => {
    return { type: "TEAM:SET_TEAM_PLAYERS", data }
}

export const cleanSearchedMembersAction= ( data ) => {
    return { type: "TEAM:CLEAN_SEARCHED_MEMBERS", data }
}

export const loadPageTeamsAction= ( data ) => {
    return { type: "TEAM:LOAD_PAGE_TEAMS", data }
}

export const updateTeamDataAction= ( data ) => {
    return { type: "TEAM:UPDATE_TEAM_DATA", data }
}

export const cleanPlayersAction= ( data ) => {
    return { type: "TEAM:CLEAN_PLAYERS", data }
}

export const setBulkMembersAction= ( data ) => {
    return { type: "TEAM:SET_BULK_MEMBERS", data }
}

export const changeConfirmationUploadBulkModalStateAction = ( new_state ) => {
    return { type: "TEAM:CONFIRM_UPLOAD_BULK_MODAL_STATE", new_state }
}

export const cleanBulkMembersAction = ( data ) => {
    return { type: "TEAM:CLEAN_BULK_MEMBERS", data }
}

export const loadMemberRolesDataAction = ( data ) => {
    return { type: "TEAM:LOAD_MEMBER_ROLES", data }
}

export const loadMemberPositionsDataAction = ( data ) => {

    return { type: "TEAM:LOAD_MEMBER_POSITIONS", data }
}

export const changeTeamMembersRolModalStateAction = ( new_state ) => {
    return { type: "TEAM:CHANGE_TEAM_MEMBERS_ROL_MODAL_STATE", new_state }
}
export const updateTeamMemberAction= ( data ) => {
    return { type: "TEAM:UPDATE_TEAM_MEMBER", data }
}

export const changeShowBulkStateAction = ( new_state ) => {
    return { type: "TEAM:SHOW_BULK", new_state }
}

export const changeLoadingBulkFileStateAction = ( new_state ) => {
    return { type: "TEAM:LOADING_BULK_FILE", new_state }
}

export const changeAddingBulkMemberStateAction = ( new_state ) => {
    return { type: "TEAM:ADDING_BULK_MEMBER", new_state }
}

export const changeBulkDeleteMemberModalStateAction = ( new_state ) => {
    return { type: "TEAM:CHANGE_BULK_DELETE_MEMBER_MODAL_STATE", new_state }
}

export const changeEditMemberModalState = ( new_state ) => {
    return { type: "TEAM:CHANGE_EDIT_MEMBER_MODAL_STATE", new_state }
}

export const changeDeleteMemberModalState = ( new_state ) => {
    return { type: "TEAM:CHANGE_DELETE_MEMBER_MODAL_STATE", new_state }
}

export const cleanTeamDataAction = ( data ) => {
    return { type: "TEAM:CLEAN_TEAM", data }
}