export const changeSearchBoxStateAction = ( new_state ) => {
    return { type: "SEARCH:CHANGE_SEARCH_BOX_STATE", new_state }
}

export const changeSearchFilteredStateAction = ( new_state ) => {
    return { type: "SEARCH:CHANGE_SEARCH_FILTERED_STATE", new_state }
}

export const loadSearchBoxAction = ( data ) => {
    return { type: "SEARCH:LOAD_SEARCH_BOX", data }
}
export const cleanSearchKeyword= ( new_value ) => {
    return { type: "SEARCH:CLEAN_SEARCH_KEYWORD", new_value }
}

export const loadSearchAction = ( data ) => {
    return { type: "SEARCH:LOAD_SEARCH", data }
}

export const loadSearchFilterAction = ( data ) => {
    return { type: "SEARCH:LOAD_SEARCH_FILTER", data }
}

export const loadSearchErrorsAction = ( errors, section ) => {
    return { type: "SEARCH:LOAD_SEARCH_ERRORS", errors, section }
}

export const updateSearchResultFollowAction = (data ) => {
    return { type: "SEARCH:UPDATE_SEARCH_RESULT_FOLLOW", data }
}

export const changeSearchOnStateAction = ( new_state ) => {
    return { type: "SEARCH:CHANGE_SEARCH_ON_STATE", new_state }
}