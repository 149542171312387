// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentMatchesToCloseModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentMatchesToCloseModalComponent ( props ){

    return(
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <p className="modal-title">                
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarning.labelTitle')}
            </p>
            <p className="modal-text">
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarning.labelInfo')}                 
                <p className="baloa-body-bold">
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarning.labelAdvice')}                                                     
                </p>
            </p>
            
            <div className="login-modal-actions"> 
                
                <SecondaryButtonComponent onClick={ props.onClose }>
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarning.labelAction')}</span>
                </SecondaryButtonComponent>
                
            </div>
        </ModalComponent>
    )

}

export default withTranslation('league')(TournamentMatchesToCloseModalComponent);
