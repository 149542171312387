// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carnet-information{}

.carnet-information__title{
    margin-top: var(--space-and-half);
    margin-left: var(--space-x3);
    margin-right: var(--space-x3);
    border: 1px solid var(--new-gray-background-color);
    border-radius: 8px;
    display: flex;
    padding: var(--space);
    align-items: center;
}

.carnet-information__title__text{
   padding-left: var(--space-half);
}

.carnet-information__item{
    padding-top: var(--space-and-half);
    padding-left: calc(var(--space-x3) + var(--space-and-half));
    padding-right: var(--space-x3);
    padding-bottom: var(--space-and-half);
    height: calc(100vh - 200px);
}

.carnet-information__item__text{
    padding-bottom: var(--space);
    font-size: var(--space);
}

.carnet-information__item__list{
    display: inline-grid;
}

.carnet-information__item__list__container{
    display: flex;
    padding-bottom: var(--space-half);
    font-size: var(--space);
}

.carnet-information__button{
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/create-carnet/carnet-information/CarnetInformationComponent.styles.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,iCAAiC;IACjC,4BAA4B;IAC5B,6BAA6B;IAC7B,kDAAkD;IAClD,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;GACG,+BAA+B;AAClC;;AAEA;IACI,kCAAkC;IAClC,2DAA2D;IAC3D,8BAA8B;IAC9B,qCAAqC;IACrC,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;IAC5B,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".carnet-information{}\n\n.carnet-information__title{\n    margin-top: var(--space-and-half);\n    margin-left: var(--space-x3);\n    margin-right: var(--space-x3);\n    border: 1px solid var(--new-gray-background-color);\n    border-radius: 8px;\n    display: flex;\n    padding: var(--space);\n    align-items: center;\n}\n\n.carnet-information__title__text{\n   padding-left: var(--space-half);\n}\n\n.carnet-information__item{\n    padding-top: var(--space-and-half);\n    padding-left: calc(var(--space-x3) + var(--space-and-half));\n    padding-right: var(--space-x3);\n    padding-bottom: var(--space-and-half);\n    height: calc(100vh - 200px);\n}\n\n.carnet-information__item__text{\n    padding-bottom: var(--space);\n    font-size: var(--space);\n}\n\n.carnet-information__item__list{\n    display: inline-grid;\n}\n\n.carnet-information__item__list__container{\n    display: flex;\n    padding-bottom: var(--space-half);\n    font-size: var(--space);\n}\n\n.carnet-information__button{\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
