// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './LeagueTeamBulkUploadMemberConfirmationModalComponent.styles.css';

function LeagueTeamBulkUploadMemberConfirmationModalComponent ( props ){

    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            //onClose={props.onClose}
            className={'team-bulk-upload-confirmation'}
        >
            <p className="team-bulk-upload-confirmation__title baloa-subtitle-1">                
                { props.modalTitle }
            </p>

            <div className="team-bulk-upload-confirmation__text baloa-table"
                dangerouslySetInnerHTML={{
                    __html: props.modalContent
                }}></div>
            
            <div className="team-bulk-upload-confirmation__actions"> 
                <PrimaryButtonComponent 
                    disabled={ false } 
                    onClick={()=>{ props.onClose() }} 
                    className="bulk-add-members__buttons__button"
                    type="submit"
                >
                    <span>{props.acceptButtonText}</span>
                </PrimaryButtonComponent>

            </div>
        </ModalComponent>
    )

}

export default (LeagueTeamBulkUploadMemberConfirmationModalComponent);
