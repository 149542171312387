
// React
import React from 'react';

// Components
import ButtonComponent from 'shared/components/button/ButtonComponent';

// Styles
import './RoundedButtonComponent.styles.css';

// Assets


function RoundedButtonComponent( props ) {
    return (
        <ButtonComponent 
            id={props.id}
            type={props.type}
            onClick={props.onClick} 
            disabled={props.disabled}
            className={ `rounded-button ${props.inverse? 'inverse': ''}  ${props.className? props.className : '' }` }
        >
            {props.children}
        </ButtonComponent>
    )
}

export default RoundedButtonComponent;