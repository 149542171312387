// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentInvitationInscriptionActionsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentInvitationInscriptionActionsModal( props ){

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}            
            className="tournament-invitation-inscription-actions-modal"
        >
            <div className="tournament-invitation-inscription-actions-modal__container" >
                <div className='baloa-hyperlink' >{props.mainLabel}</div>
                <div className='baloa-table' >{props.secondaryLabel}</div>
            </div>

            <div className="tournament-invitation-inscription-actions-modal__action-btns">
                <SecondaryButtonComponent
                    onClick={ () => props.onClose() }
                >{props.cancelBtn}</SecondaryButtonComponent>
                <PrimaryButtonComponent
                    type='button'
                    onClick={ () => props.handleOnInvitationInscriptionModalActions() }
                    className={`tournament-invitation-inscription-actions-modal ${props.accept_team_inscription_btn? 'accept_inscription': ''}`}
                >{props.acceptBtn}</PrimaryButtonComponent>
            </div>
            
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentInvitationInscriptionActionsModal);