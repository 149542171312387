// React
import React from 'react'

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ProfileTournamentsTeamsTableComponent from '../profile-tournaments-teams-table/ProfileTournamentsTeamsTableComponent';

// Styles
import './ProfileTournamentsTeamsSummaryComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileTournamentsTeamsSummaryComponent( props ) {

    function validateLockedData () {
        for(let key in props.profile_data) {
            if(props.profile_data[key] === 'ND') {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="profile-tournaments-teams-summary__container">
            <div className="profile-tournaments-teams-summary__title">
                <div className="baloa-subtitle-2">{props.summary_type === 'tournaments_summary' ? props.t('tournamentSummary.labelTournamentsSummary') : props.t('tournamentSummary.labelTeamsSummary')}</div>
                <div className='profile-tournaments-teams-summary__tooltip'>
                    <div className='baloa-table-column'>{props.t('tournamentSummary.toottip')}</div>
                    <span className='hubicon-help_fill'/>
                </div>
            </div>

            <ProfileTournamentsTeamsTableComponent 
                table_data={props.table_data}
                summary_type={props.summary_type}
            />

            { props.table_data?.length > 0
                ?  props.profile_tournament_paid 
                    ? <div className='profile-tournaments-teams-summary__unlock-values__action'>
                        <div className='profile-tournaments-teams-summary__unlock-values__text'>
                            <span className='hubicon-info' />
                            { props.is_owner && props.summary_type !== 'teams_summary' && 
                                <div className='baloa-normal-medium'>
                                    {props.t('tournamentSummary.blockedText')}
                                </div> }
                            { props.is_owner && props.summary_type === 'teams_summary' && 
                                <div className='baloa-normal-medium'>
                                    { validateLockedData()
                                        ? props.t('tournamentSummary.blockedText') 
                                        : props.t('tournamentSummary.alternativeblockedText') }
                                </div>
                            }
                            { !props.is_owner  && props.summary_type !== 'teams_summary' && 
                                <div className='baloa-normal-medium'>
                                    {props.t('tournamentSummary.visitantBlockedText', {user_name: props.username})}
                                </div>
                            }
                            { !props.is_owner  && props.summary_type === 'teams_summary' && 
                                <div className='baloa-normal-medium'>
                                    { validateLockedData()
                                        ? props.t('tournamentSummary.visitantBlockedText', {user_name: props.username}) 
                                        : props.t('tournamentSummary.alternativeVisitantBlockedTex', {user_name: props.username})}
                                </div> }
                        </div>
                        { props.is_owner && props.table_data?.length > 0 &&
                            <PrimaryButtonComponent onClick={() => {props.handleModalUnlockData()}} >
                                {props.t('tournamentSummary.unlockBtn')}
                            </PrimaryButtonComponent> 
                        }
                        </div>
                    : ''
                : <div className='profile-tournaments-teams-summary__unlock-values__action'>
                    <div className='profile-tournaments-teams-summary__unlock-values__text'>
                        <span className='hubicon-info' />
                            <div className='baloa-normal-medium'>
                                { props.is_owner 
                                    ? props.summary_type === 'tournaments_summary' ? props.t('tournamentSummary.blockedEmptyDataTournament') : props.t('tournamentSummary.blockedEmptyDataMatch')
                                    : props.t('tournamentSummary.visitantEmptyDataTournament', {user_name: props.username})
                                }
                            </div>
                    </div>
                </div>
            }
            {props.table_data?.length > 0
                ? <div className='profile-tournaments-teams-summary__view-all baloa-normal-medium' onClick={() => {props.handleOnSummaryViewAll(props.summary_type)}}>{props.t('tournamentSummary.labelViewAll')}</div>
                :''
            }
        </div>
    )
}

export default withTranslation('profile')(ProfileTournamentsTeamsSummaryComponent);

