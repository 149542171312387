import React from "react";

//Styles
import "./TournamentPlayerInfoComponent.styles.css"

function TournamentPlayerInfoComponent (props) {
    return(
        <div className="tournament-player-info__container">
            <div className="baloa-hyperlink" >{props.full_name}</div>
            <div className="baloa-table" >{props.team_name}</div>
        </div>
    )
}

export default TournamentPlayerInfoComponent;