/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Styles
import './PageCategoryModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function PageTypeModalComponent( props ) {
    
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('newPage.button')} 
        >
            <div className="page-type-form scroll-parent">

                <div className="page-type-form__form__fields form__fields scroll">
                   
                    <React.Fragment>

                        <div 
                            onClick = { () => { props.onSelectCategory('organizations') } } 
                            className={ "page-type-form__form__fields__field " + ( props.selectedCategory == 'organizations' ? 'active' : '' ) }
                        >
                            <div className="page-type-form__form__fields__field__icon">
                                <span className="icon-ball"></span>
                            </div>
                            <div className="page-type-form__form__fields__field__info">
                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.footballOrganizations.title')}</p>
                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                    {props.t('newPage.footballOrganizations.text')}
                                    <span className="icon-chevron-roght"></span>
                                </p>
                            </div>
                        </div>

                        <div 
                            onClick = { () => { props.onSelectCategory('institutions') } } 
                            className={ "page-type-form__form__fields__field " + ( props.selectedCategory == 'institutions' ? 'active' : '' ) }
                        >
                            <div className="page-type-form__form__fields__field__icon">
                                <span className="icon-company"></span>
                            </div>
                            <div className="page-type-form__form__fields__field__info">
                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.institutionsCorporations.title')}</p>
                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                    {props.t('newPage.institutionsCorporations.text')}
                                    <span className="icon-chevron-roght"></span>
                                </p>
                            </div>
                        </div>

                        <div 
                            onClick = { () => { props.onSelectCategory('content') } } 
                            className={ "page-type-form__form__fields__field " + ( props.selectedCategory == 'content' ? 'active' : '' ) }
                        >
                            <div className="page-type-form__form__fields__field__icon">
                                <span className="icon-pages-88"></span>
                            </div>
                            <div className="page-type-form__form__fields__field__info">
                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.contenGeneration.title')}</p>
                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                    {props.t('newPage.contenGeneration.text')}
                                    <span className="icon-chevron-roght"></span>
                                </p>
                            </div>
                        </div>

                        
                    </React.Fragment>
                            
                </div>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('page')(PageTypeModalComponent);

//export default PageTypeModalComponent;