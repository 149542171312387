// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-new-phase__container{
    background-color: var( --white-color );
    border-radius: var( --space );
    margin: var(--space);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-new-phase/TournamentNewPhaseComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,6BAA6B;IAC7B,oBAAoB;AACxB","sourcesContent":[".tournament-new-phase__container{\n    background-color: var( --white-color );\n    border-radius: var( --space );\n    margin: var(--space);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
