// React
import React from 'react'

// Styles
import './ProfileAptitudeComponent.style.css'

// Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';


// i18n
import { withTranslation } from 'react-i18next';


function ProfileAptitudeComponent( props ) {

    return (

        <div className={ "profile-aptitude " + ( props.className ? props.className : '' ) }>
            <div className="profile-aptitude__info">
                <p className="profile-aptitude__info__name baloa-subtitle-2 color-aptitudes">{ props.t(`apt.aptCode.${props.aptitude.value}`) }</p>
                <p className="profile-aptitude__info__validations-count baloa-names">
                    { props.aptitude.validations_count === 0 && props.t('apt.text2') }
                    { props.aptitude.validations_count === 1 &&  `${props.t('apt.text3',{validation: props.aptitude.validations_count})}` }
                    { props.aptitude.validations_count > 1 && `${props.t('apt.text4',{validation: props.aptitude.validations_count})}` }
                </p>
            </div>
            <div className="profile-aptitude__actions">
                {
                    props.is_owner ?
                    
                    <TextButtonComponent 
                        onClick={()=>{
                            props.onInviteToValidateAptitude( props.aptitude );
                        }} 
                        className="small"
                    >
                        <span>{props.t('apt.btnInvite')}</span>
                    </TextButtonComponent> 
                    
                    : props.aptitude.is_validated_by_user ?
                    
                    <TextButtonComponent 
                        onClick={()=>{
                            props.onValidateAptitude( props.aptitude );
                        }} 
                        className="validated small"
                    >
                        <span className='hubicon-check icon'/><span>{props.t('apt.btnValidated')}</span>
                    </TextButtonComponent>
                    
                    :
                    
                    <TextButtonComponent 
                        onClick={()=>{
                            props.onValidateAptitude( props.aptitude );
                        }} 
                        className="small"
                    >
                       <span>{props.t('apt.btnValidate')}</span>                       
                    </TextButtonComponent>

                }
            </div>
        </div>
        
    )

}

export default withTranslation('profile')(ProfileAptitudeComponent);

//export default ProfileAptitudeComponent;
