// React
import React from 'react';

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FollowerComponent from 'shared/components/follower/FollowerComponent';

// Styles
import './PageFollowersModalComponent.styles.css';

// i18n
import { withTranslation, useTranslation } from 'react-i18next';

function TabPanel( props ) {

    const { children, value, index, ...other } = props;
    const { t } = useTranslation('page');

   

    return (
        <div
            role="tabpanel"
            className="scroll-parent"
            hidden={ value !== index }
            id={ `simple-tabpanel-${index}` }
            aria-labelledby={ `simple-tab-${index}` }
            { ...other }
             
        >
            {/* followers */}
            { value === 0 && (
                <React.Fragment>
                    <div className="form scroll-parent">
                        <div className=" scroll">
                            {props.followers?.map(follower =>
                                <FollowerComponent
                                    follower={follower}
                                    onFollowProfile = { ( profile_username, pagename ) => { props.onFollowProfile( profile_username, pagename ) } }
                                    current_username= { props.current_username }
                                    onUnfollowProfile={ ( profile_username, pagename ) => { props.onUnfollowProfile( profile_username, pagename ) } }
                                    onFollowPage = { ( page_pagename, pagename ) => { props.onFollowPage( page_pagename, pagename ) } }
                                    onUnfollowPage = { ( page_pagename, pagename ) => { props.onUnfollowPage( page_pagename, pagename ) } }
                                    handleOnProfileClick={( profile ) => props.handleOnProfileClick( profile )}
                                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                >
                                </FollowerComponent>
                            )}
                        </div>                
                            
                        <div className="page-followers-form__form__actions form__actions">
                            <PrimaryButtonComponent 
                                type="button"
                                disabled={ false } 
                                onClick={ props.onClose }
                                className={"small"}
                            >
                                <span>{ t('profileModal.button')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                </React.Fragment>
            )}

            {/* following */}
            { value === 1 && (
                <React.Fragment>
                    <div className="form scroll-parent">
                        {/* <div className="scroll">                      
                            {props.following?.map( following =>
                                <FollowerComponent
                                    follower={following}
                                    onFollowProfile = { ( profile_username, pagename ) => { props.onFollowProfile( profile_username, pagename ) } }
                                    current_username= { props.current_username }
                                    onUnfollowProfile={ ( profile_username, pagename ) => { props.onUnfollowProfile( profile_username, pagename ) } }
                                    onFollowPage = { ( page_pagename, pagename ) => { props.onFollowPage( page_pagename, pagename ) } }
                                    onUnfollowPage = { ( page_pagename, pagename ) => { props.onUnfollowPage( page_pagename, pagename ) } }
                                    handleOnProfileClick={( profile ) => props.handleOnProfileClick( profile )}
                                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                >
                                </FollowerComponent>
                            )}
                        </div> */}
                        <div className="page-followers-form__form__actions form__actions">
                            <PrimaryButtonComponent 
                                type="button"
                                disabled={ false }
                                onClick={props.onClose} 
                                className={"small"}
                            >
                                <span>{ t('profileModal.button')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                </React.Fragment> 
            )}
        </div>
    );
}

function PageFollowersModalComponent( props ) {

    let [ state, setState ] = React.useState( 
        { 
            tab_selected: 0,
        } 
    )

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('profileModal.title')} 
        >
        
            <Tabs
                variant="fullWidth"
                className="page-followers-modal-tabs"
                value={ state.tab_selected }
                indicatorColor="primary"
                textColor="primary"
                onChange={ ( ev, new_value ) => { setState( { tab_selected: new_value } ) } }
                aria-label="Selection between "
            >
                <Tab label={props.t('profileModal.tabs.tabFollowers')} />  
                <Tab label={props.t('profileModal.tabs.tabFollowing')} /> 
            </Tabs>

            <div className="scroll-parent">
                {/* followers */}
                <TabPanel
                    index={ 0 } 
                    value={ state.tab_selected } 
                    errors = { props.errors }
                    followers={ props.followers }
                    onFollowProfile = { ( profile_username, pagename ) => { props.onFollowProfile( profile_username, pagename ) } }
                    current_username= { props.current_username }
                    onUnfollowProfile={ ( profile_username, pagename ) => { props.onUnfollowProfile( profile_username, pagename ) } }
                    onFollowPage = { ( page_pagename, pagename ) => { props.onFollowPage( page_pagename, pagename ) } }
                    onUnfollowPage = { ( page_pagename, pagename ) => { props.onUnfollowPage( page_pagename, pagename ) } }
                    onClose={props.onClose}
                    handleOnProfileClick={( profile ) => props.handleOnProfileClick( profile )}
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                />

                {/* following */}
                <TabPanel
                    index={ 1 } 
                    value={ state.tab_selected } 
                    errors = { props.errors }
                    // following= { props.following }
                    // onFollowProfile = { ( profile_username, pagename ) => { props.onFollowProfile( profile_username, pagename ) } }
                    // current_username= { props.current_username }
                    // onUnfollowProfile={ ( profile_username, pagename ) => { props.onUnfollowProfile( profile_username, pagename ) } }
                    // onFollowPage = { ( page_pagename, pagename ) => { props.onFollowPage( page_pagename, pagename ) } }
                    // onUnfollowPage = { ( page_pagename, pagename ) => { props.onUnfollowPage( page_pagename, pagename ) } }
                    onClose={props.onClose}
                    handleOnProfileClick={( profile ) => props.handleOnProfileClick( profile )}
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                />
            </div>
            

        </Modal2Component>
    )
}

export default withTranslation('page')(PageFollowersModalComponent);

//export default PageFollowersModalComponent;
