// React
import React from 'react';

// Material UI
import Box from '@mui/material/Box';

// Formik
import { Field, useFormik } from 'formik';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import LeagueSearchTeamListComponent from 'app/leagues/components/league-search-team-list/LeagueSearchTeamListComponent.jsx'

//i18
import { useTranslation } from 'react-i18next'; 

// Styles
import './LeagueAddTeamModalComponent.styles.css';


function LeagueAddTeamModalComponent( props ) {
    
    
    const formikvalues = useFormik({
        initialValues: {
            team: "",
        },
    });
    
    const { t } = useTranslation('league');    
    const [searchedValue, setSearchedValue] = React.useState(null);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
    const [isInputFocus, setIsInputFocus] = React.useState('');
    const [isEmptySearch, setIsEmptySearch] = React.useState(true);

    return (
        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
        >
            {console.log(formikvalues.initialValues)}
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => { 
                    
                    let new_team_data = values['team'];
                    props.onNewData( new_team_data );
                    setIsBtnDisabled(true);                    
                    setIsEmptySearch(true);
                    //formikvalues.initialValues('team', '' );
                }}
               
                onKeyDown={(event) => {                    
                    
                    if ((event.charCode || event.keyCode) === 13 ) {
                        if(searchedValue === null){
                            event.preventDefault();    
                        } else {
                            setSearchedValue(null)
                        }
                    }
                }}

                className="league-add-team-form__form form"
                autocomplete="off"
            >
                <Box className="league-add-team-form__box">
               
                    <InputSearchComponent
                      placeholder={t('options.teamsAndMembers.tabs.tabTeams.modals.modalAddTeam.labelSearch')}
                      handleSearch={(v) => {                            
                            props.handleSearchTeam(v);
                            if(v !== ''){setIsEmptySearch(false)}
                        }}

                      autoFocus = {isInputFocus}
                    />
                
                    <Field name="team">
                        {
                          ({
                              form: { setFieldValue }, 
                          }) => (
                              <React.Fragment>
                                { props.teams.length !== 0 && !isEmptySearch &&

                                    <LeagueSearchTeamListComponent 
                                        items={props.teams}
                                        onSelect = { (selected_team) => { 
                                            setFieldValue('team', selected_team );
                                            setSearchedValue(selected_team); 
                                            setIsInputFocus('true');
                                            setIsBtnDisabled(false);  
                                    } }
                                    />                                
                                }
                                
                                <div className="league-add-team-form__box__without-results">
                                    { props.teams.length === 0 && !isEmptySearch &&
                                        <p className="baloa-subtitle-2">{t('options.teamsAndMembers.tabs.tabTeams.modals.modalAddTeam.labelResults')}</p>
                                    }
                                </div>
                                
                              </React.Fragment>
                            )
                        }
                    </Field>
                </Box>
                <div className="league-add-team__footer">
                  <PrimaryButtonComponent 
                      type="submit"
                      disabled={isBtnDisabled} 
                      onClick={()=>{}} 
                      className="league-add-team__actions__submit"
                  >
                      <span>{t('options.teamsAndMembers.tabs.tabTeams.labelButtonAdd')}</span>
                  </PrimaryButtonComponent>
                </div>
            </FormComponent>

        </Modal2Component>

    )
}

export default LeagueAddTeamModalComponent;
