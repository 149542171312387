import React from "react";

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import "./ShareProfileLinkModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function ShareProfileLinkModalComponent(props) {
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                props.t('shareProfileLinkModal.modalTitle')               
            }
            className='share-profile-link-modal__container'
        >
            <div className={"share-profile-link-modal__content"}>
                
                    <React.Fragment>
                        <div className="share-profile-link-modal__description baloa-table-column">
                            <span className='icon-player' />
                            {props.t('shareProfileLinkModal.modalMessage')}
                        </div>
                        <div className="share-profile-link-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                            <span className="baloa-table">{props.share_url}</span>
                            <span className="icon-copy-86"></span>
                        </div>
                    </React.Fragment>
                
            </div>
            <div className="share-profile-link-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='share-profile-link-modal__buttons-button'
                    onClick={props.onClose}
                >
                    <span>{props.t('shareProfileLinkModal.acceptButton')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ShareProfileLinkModalComponent);