// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Styles
import './ProfileDataSheetModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

import ProfileTechnicsPrimaryTeamModalComponent from '../profile-technics-primary-team-modal/ProfileTechnicsPrimaryTeamModalComponent';
import ProfileTechnicsPrimaryPositionModalComponent from '../profile-technics-primary-position-modal/ProfileTechnicsPrimaryPositionModalComponent';
import ProfileTechnicsSecondaryPositionModalComponent from 'app/profiles/components/profile-technics-secondary-position-modal/ProfileTechnicsSecondaryPositionModalComponent';
import ProfileTechnicsMeassuresModalComponent from '../profile-technics-meassures-modal/ProfileTechnicsMeassuresModalComponent';
import ProfileTechnicsLateralityModalComponent from '../profile-technics-laterality-modal/ProfileTechnicsLateralityModalComponent';


function ProfileDataSheetModalComponent( props ) {
    
    const [step, setStep] = useState(0);

    // const formInitialValues = {
    //     primary_team: props.model.attributes?.primary_team?.value?.name,
    // }

    function stepCompleted ( index ){

        if(index === 1){
            setStep(1);//position1 -> scorers
        }else if(index === 2){
            setStep(2);//position2 -> changes 
        }else if (index === 3) {
            setStep(3);//height -> cards
        }else if (index === 4) {
            setStep(4);//dominant -> comments
        }else if(index === 0){
            setStep(0);//team
        }
        
    }

    const closeModal=()=>{
        stepCompleted(0);
        props.onClose();
    }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={props.t('playerCard.title')}
            className="medium"
        >
            <div className="technics-form scroll-parent">
                {step === 0 &&(
                    <ProfileTechnicsPrimaryTeamModalComponent
                        errors = { props.errors } 
                        model = { props.model }
                        onNewData = { props.onNewData }
                        onResetError = { props.onResetError}
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                    />
                )}
                {step === 1 &&(
                    <ProfileTechnicsPrimaryPositionModalComponent
                        errors = { props.errors } 
                        model = { props.model }
                        onNewData = { props.onNewData }
                        onResetError = { props.onResetError}
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                    />
                )}
                {step === 2 &&(
                    <ProfileTechnicsSecondaryPositionModalComponent
                        errors = { props.errors } 
                        model = { props.model }
                        onNewData = { props.onNewData }
                        onResetError = { props.onResetError}
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                    />
                )}
                {step === 3 &&(
                    <ProfileTechnicsMeassuresModalComponent
                        errors = { props.errors } 
                        model = { props.model }
                        onNewData = { props.onNewData }
                        onResetError = { props.onResetError}
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }  
                    />
                )}
                {step === 4 &&(
                    <ProfileTechnicsLateralityModalComponent
                        errors = { props.errors } 
                        model = { props.model }
                        onNewData = { props.onNewData }
                        onResetError = { props.onResetError}
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step } 
                        onClose={closeModal}
                    />
                )}
            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileDataSheetModalComponent);