
// React
import React from 'react';

// Components
import ButtonComponent from 'shared/components/button/ButtonComponent';

// Styles
import './TextButtonComponent.styles.css';

// Assets


function TextButtonComponent(props) {
    return (
        <ButtonComponent
            id={props.id}
            onClick={props.onClick}
            disabled={props.disabled}
            className={"text-button " + props.className}
            type={props.type}
        >
            {props.children}
        </ButtonComponent>
    )
}

export default TextButtonComponent;