/*eslint eqeqeq: "off"*/
// React
import React from "react";

// Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import LeagueSearchMemberToTeamComponent from "app/leagues/components/league-search-member-to-team/LeagueSearchMemberToTeamComponent";
import LeagueAddedPlayersListComponent from "app/leagues/components/league-added-players-list/LeagueAddedPlayersListComponent";
import SecondaryButtonComponent from "shared/components/secondary-button/SecondaryButtonComponent";

// Styles
import "./LeagueAddPlayersToTeamComponent.styles.css";
import "../league-new-team-form-component/LeagueNewTeamFormComponent.styles.css";

// i18n
import { withTranslation } from "react-i18next";

function LeagueAddPlayersToTeamComponent(props) {
	return (
		<div className="add-players-to-team__container">
			<div className="add-players-to-team__form__content form__fields">
				<LeagueSearchMemberToTeamComponent
					className="add-players-to-team__search"
					addPlayerHome={props.addPlayerHome}
					handleSearchMember={props.handleSearchMember}
					profiles={props.profiles}
					onSelectTeamPlayer={props.onSelectTeamPlayer}
					handleCleanMemberSearch={props.handleCleanMemberSearch}
				/>
				{!props.addPlayerHome &&
					<div className="add-players-to-team__bulk-add-members-button">
						<SecondaryButtonComponent
							onClick={() => {
								props.handleOnAddBulk(true);
							}}
							className=""
						>
							<span>
								{props.t(
									"newTeam.forms.players.addMemberWithoutAccountBtn"
								)}
							</span>
						</SecondaryButtonComponent>
					</div>
				}
			</div>

			<LeagueAddedPlayersListComponent
				className="add-players-to-team__members-list"
				players={props.team.team_players}
				onDeletePlayerButton={props.onDeletePlayerButton}
				roles={props.roles}
				positions={props.positions}
				errors_member_rol={props.errors_member_rol}
				onSelectRolMember={props.onSelectRolMember}
			/>
			{!props.addPlayerHome &&
				<React.Fragment>
					<div className="new-team-form__horizontal-line" />
					<PrimaryButtonComponent
						disabled={props.team.team_players.length == 0}
						onClick={() => {
							props.handleOnSaveButton();
						}}
						className="add-players-to-team__submit"
						type="submit"
					>
						<span>{props.t("newTeam.forms.players.button")}</span>
					</PrimaryButtonComponent>
				</React.Fragment>
			}
		</div>
	);
}

export default withTranslation(["team", "league"])(
	LeagueAddPlayersToTeamComponent
);
