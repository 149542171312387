// React
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';


// Material
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// Google Analytics
import ReactGA from 'react-ga4';

// Store
import setStore from './config/ConfigureStore';

// Router
import Router from './config/routes/Router';
import history from './config/history';

// Global Styles
import 'shared/styles/main.css';

// Themes
import { baloa_theme } from './config/BaloaMaterialTheme';

//Assets
import loading_baloa from 'assets/images/loading-baloa.gif';

// Global data
import { /* debug, */ ga_tracking_id } from './config/GlobalData';
import Interceptor from "config/axiosConfig";

export const store = setStore()

Interceptor.interceptor( store )

// Initialize google analytics page view tracking
history.listen(location => {
  //ReactGA.set({ page: location.pathname }); // Update the user's current page
  //ReactGA.pageview(location.pathname); // Record a pageview for the given page
  ReactGA.send({ hitType: "pageview", page: location.pathname });
});

function App() {

  /* ReactGA.initialize(
    ga_tracking_id,
    {
      debug: debug,
    },
  ); */
  ReactGA.initialize(ga_tracking_id);
  
  return (
    <Suspense fallback={<div><center><img src={loading_baloa} alt={'loading'}/></center></div>}>
      <Provider store={store}>
        <ThemeProvider theme={baloa_theme}>
          <CssBaseline />
          <Router history={history}/>
        </ThemeProvider>
      </Provider>        
    </Suspense>
  );
}

export default App;