/*eslint eqeqeq: "off"*/
// React
import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './EditRoleComponent.styles.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ListItemText } from '@mui/material';

function EditRoleComponent(props) {
    const role_options = [
        {name: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffRoles.Administrator'), description: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.rolesDescription.Administrator'), value: 'Administrator'},
        {name: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffRoles.Referee'), description: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.rolesDescription.Referee'), value: 'Referee'},
        {name: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffRoles.Assigner'), description: props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.rolesDescription.Assigner'), value: 'Assigner'}
    ]

    return (

            <div class="edit-role">
                <Select 
                    id={ 'role' }
                    name={ 'role' }
                    disabled={ false }
                    onChange={ props.handleRolChange  } 
                    className={ 
                        'select-field-input baloa-table'
                    }

                    placeholder={  ''  }
                    //value={}
                    defaultValue={props.row.role}
                    variant='standard'
                >
                
                    { role_options.map( option => {
                        return (
                            <MenuItem
                                key={ option.value } 
                                value={ option.value }
                                className={'edit-role__menu'}
                            >
                                <ListItemText primary={option.name} secondary={option.description} />
                            </MenuItem>
                        )
                    } )} 
                </Select>                  
            </div>

    )
    
}

export default withTranslation(['league'])(EditRoleComponent)