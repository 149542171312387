// React
import React from 'react';

// Styles
import './CardIdViewerStepperComponent.styles.css';

//Material
import MobileStepper from '@mui/material/MobileStepper';

// Components
import CardIdViewerComponent from '../card-id-viewer/CardIdViewerComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function CardIdViewerStepperComponent ( props ) {

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.downloadCardIdData.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return(

        <div className="card-id-viewer-stepper__content baloa-table-column"> 
            <CardIdViewerComponent 
                downloadCardIdData={ props.downloadCardIdData[activeStep] }
                card_id_form_data={ props.card_id_form_data }
                orientation={ props.orientation }
                template_image={ props.template_image }
            />
            {props.downloadCardIdData?.length > 1 &&            
                <MobileStepper
                    steps={maxSteps}
                    //position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <TextButtonComponent 
                            onClick={handleNext} 
                            disabled={activeStep === maxSteps - 1}
                            className='card-id-viewer-stepper__buttons__button'
                        >
                            <span className='icon-arrow-right'/>
                        </TextButtonComponent>
                    }
                    backButton={
                        <TextButtonComponent  
                            onClick={handleBack} 
                            disabled={activeStep === 0}
                            className='card-id-viewer-stepper__buttons__button'
                        >
                            <span className='icon-arrow-left'/>
                        </TextButtonComponent>
                    }
                />
            }
        </div> 
    )
}

export default CardIdViewerStepperComponent;