import React from 'react';
import Modal2Component from 'shared/components/modal2/Modal2Component';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
//i18
import { withTranslation } from 'react-i18next';
import './LeagueMatchPlayerShowAssistantModalComponent.styles.css';

function LeagueMatchPlayerShowAssistantModalComponent(props) {

    const listPlayersByTeams = (team) => {
        return (
            <div className='league_match_player__show_assistance-general-box'>
                <div className='league_match_player__show_assistance-team'>
                    <div className="league_match_team_assistance_team-ball-photo">
                        {team?.team_logo
                            ? <img className="league_match_team_assistance_section-team_photo" src={team?.team_logo} alt={'photo_team'} />
                            : <span className="icon-ball" color={getRandonIconBallColor()} />}
                    </div>
                    <div className="league_match_team_assistance_section-team_names">
                        <div className="baloa-table">{team?.name}</div>
                        <div className="baloa-mini">@{team?.teamname}</div>
                    </div>
                </div>
                <div className='league_match_player__show_assistance-players'>
                    <div className='league_match_player__show_assistance_players-titular'>
                        <div className='league_match_player__show_assistance-players-title-section baloa-username'>
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTitular')}
                            &nbsp;({team?.players?.filter((member) => member.rol_in_match === 'Titular')?.length })
                        </div>
                        {listPlayerBySection(team?.players, 'Titular')}
                    </div>
                    <div className='league_match_player__show_assistance_players-substitute'>
                        <div className='league_match_player__show_assistance-players-title-section baloa-username'>
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelSustitute')}
                            &nbsp;({team?.players?.filter((member) => member.rol_in_match === 'Substitute')?.length })
                        </div>
                        {listPlayerBySection(team?.players, 'Substitute')}
                    </div>
                    <div className='league_match_player__show_assistance_players-others'>
                        <div className='league_match_player__show_assistance-players-title-section baloa-username'>
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelNotAttendance')}
                            &nbsp;({team?.players?.filter((member) => member.rol_in_match === null)?.length })
                        </div>
                        {listPlayerBySection(team?.players, null)}
                    </div>
                </div>
            </div>
        )
    };

    const listPlayerBySection = (players, sectionFilter) => {

        return (
            <React.Fragment>
                {players?.filter((member) => member.rol_in_match === sectionFilter).map((member2) => (
                    <div key={member2.player_id} className='league_match_player__show_assistance_player-information'>
                        <div className='league_match_player__show_section--photo'>
                            {member2?.photo
                                ? <img className="league_match_player__show-member_photo" src={member2?.photo} alt={'memberPhoto'} />
                                : <span className="icon-ball" color={getRandonIconBallColor()} />}
                        </div>
                        <div className='league_match_player__assistance_section-player_name'>
                            <div className="baloa-table">{`${member2.number ? `${member2.number}.` : ''} ${member2.player_name ?? ''}`}</div>
                            <div className="baloa-mini">@{member2.username ?? ''}</div>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.matchInfo.labelAssistanceAndAligment')}
            className="league_match_player_show_assistance_general small scroll-parent"
        >
            <div className="league_match_player__show_assistance-container">
                <div className='league_match_player__show_assistance-section'>
                    {listPlayersByTeams(props.teams_match_assistance?.local_team)}
                </div>
                <div className='league_match_player__show_assistance-section'>
                    {listPlayersByTeams(props.teams_match_assistance?.visitant_team)}
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(LeagueMatchPlayerShowAssistantModalComponent)