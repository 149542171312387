
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './ProfileAptitudesModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileAptitudesModalComponent( props ) {

    
    return (

        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('apt.title')}
            className={'aptitudes_modal-container'}
        >

            <div className="aptitudes form scroll-parent">
                <div className='baloa-names'>{props.t('apt.subtitle')}</div>
                <div className="aptitudes__list">
                    { props.profileAptitudes.map( aptitude => {
                        return (
                            <div key={ aptitude } className="aptitudes__list__aptitude baloa-names selected"
                                onClick={ () => {
                                    let delete_attribute_data = { 
                                        atype: "aptitude",
                                        value: aptitude, 
                                    }
                                    props.onDeleteData( { ...aptitude, ...delete_attribute_data } );
                                } }
                            >
                                { props.t(`apt.aptCode.${aptitude.value}`) }
                                <span className='hubicon-clear_option' />
                            </div>
                        )
                    }) }
                    {props.profileAptitudes.length === 0 &&
                        <div className='baloa-table'>{props.t('apt.addMessage')}</div>
                    }
                </div> 
                <div className='baloa-names'>{props.t('apt.text1')}</div>
                <div className="aptitudes__list scroll">
                    { props.aptitudes.map( aptitude => {
                        return (
                            <div key={ aptitude } className="aptitudes__list__aptitude baloa-names"
                                onClick={ () => {
                                    let new_attribute_data = { 
                                        atype: "aptitude",
                                        value: aptitude, 
                                    }
                                    props.onNewData( new_attribute_data );
                                } }
                            >
                                { props.t(`apt.aptCode.${aptitude}`) }
                                <span className='hubicon-add_option' />
                            </div>
                        )
                    }) }

                </div>                 
            </div>
            <div className="aptitudes__actions form__actions form__actions">
                {/* <TextButtonComponent 
                    type="button"
                    disabled={false} 
                    onClick={ props.onAdminButton } 
                    className="aptitudes__actions__admin"
                >
                    <span>{props.t('apt.btnAdminSkill')}</span>
                </TextButtonComponent> */}
                <PrimaryButtonComponent 
                    type="button"
                    disabled={false} 
                    onClick={ props.onClose } 
                    className="aptitudes__actions__submit"
                >
                    <span>{props.t('apt.btnContinue')}</span>
                </PrimaryButtonComponent>
            </div>
            
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileAptitudesModalComponent)