import React from 'react';

// Material components
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Styles
import './RadioButtonDynamicFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function RadioButtonDynamicFormComponent(props) { 
    
    function handleDefaultValue() {
        let defaultValue = props.form_question.answers?.filter( answer => answer.is_default === true );
        if(defaultValue.length > 0){
            return defaultValue[0].id;
        }else{
            return null;
        }
    }

    return (
        <FormControl className="radioButton-dynamic-form">
            <FormLabel id="radioButton-dynamic-form__label" className='radioButton-dynamic-form__label'>{props.form_question.caption}</FormLabel>
            <RadioGroup defaultValue={`${handleDefaultValue()}`} 
                aria-labelledby="radioButton-dynamic-form__label"
                name={props.name} 
                id={props.id} 
            >
                { props.form_question.answers.length > 0 && props.form_question.answers.map( answer => 
                    <FormControlLabel 
                        value={`${answer.id}`} 
                        control={<Radio color='secondary'/>} 
                        label={answer.caption}
                    />
                    )
                }
                { (props.form_question.question_type?.caption === 'boolean' || props.form_question.input_type === 'boolean') &&  
                    <div className='radioButton-dynamic-form__boolean-question'>
                        <FormControlLabel 
                            value='TRUE' 
                            control={<Radio color='secondary'/>} 
                            label={props.t('registerPlayer.viewForm.trueAnswer')}
                        />
                        <FormControlLabel 
                            value='FALSE' 
                            control={<Radio color='secondary'/>} 
                            label={props.t('registerPlayer.viewForm.falseAnswer')}
                        />
                    </div>
                }
            </RadioGroup>            
        </FormControl>
    );
}

export default withTranslation('tournament')(RadioButtonDynamicFormComponent)