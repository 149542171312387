// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-external-stat-info-modal .modal__container {
    width: 420px;
    padding: 20px 0 0 0;
}

.edit-external-stat-info-modal__icon {
    text-align: center;
    margin-top: 25px;
}

.edit-external-stat-info-modal__icon .hubicon-verified {
    font-size: 35px;
}

.edit-external-stat-info-modal__info {
    padding: 13px 31px 31px 31px;
    text-align: center;    
}

.edit-external-stat-info-modal__info.baloa-normal-medium {
    color: var(--primary-very-dark-color);
}

.edit-external-stat-info-modal__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    padding: 8px;
    border-top: 1px solid var(--new-input-background-color);
}

.edit-external-stat-info-modal__actions .text-button {
    color: var(--basic-gray-color)
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-edit-external-stats-info-modal/ProfileEditExternalStatsInfoModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,QAAQ;IACR,YAAY;IACZ,uDAAuD;AAC3D;;AAEA;IACI;AACJ","sourcesContent":[".edit-external-stat-info-modal .modal__container {\n    width: 420px;\n    padding: 20px 0 0 0;\n}\n\n.edit-external-stat-info-modal__icon {\n    text-align: center;\n    margin-top: 25px;\n}\n\n.edit-external-stat-info-modal__icon .hubicon-verified {\n    font-size: 35px;\n}\n\n.edit-external-stat-info-modal__info {\n    padding: 13px 31px 31px 31px;\n    text-align: center;    \n}\n\n.edit-external-stat-info-modal__info.baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n}\n\n.edit-external-stat-info-modal__actions {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 6px;\n    padding: 8px;\n    border-top: 1px solid var(--new-input-background-color);\n}\n\n.edit-external-stat-info-modal__actions .text-button {\n    color: var(--basic-gray-color)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
