// React
import React from 'react';
import { Route } from 'react-router-dom';


const RouteWithSubRoutes = ({ path, exact, routes, component: Component }) => {
  return (
    <Route path={path} exact={exact} render={props => <Component {...props} routes={routes} />} />
  );
};

export default RouteWithSubRoutes;
