// React
import React from 'react';
import { connect } from 'react-redux';
import {compose} from 'redux';

// Material Ui
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Button from '@mui/material/Button';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// i18n
import { withTranslation } from 'react-i18next';

class ErrorMessageComponent extends React.Component  {

  handleClose = event => { 
    this.props.changeErrorMessageSnackBar( {
        message: '',
        error_message_is_open: false
    } );
  }; 
  
  render() {
      
      return (
        <Snackbar
        bodystyle={{ backgroundColor: 'teal', color: 'coral' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.props.isOpen}
        autoHideDuration={6000}
        onClose={this.handleClose}       
      >
          <SnackbarContent style={{
                backgroundColor:'#4D5451',
              }}
              message={<span id="client-snackbar">{this.props.message}</span>}  
              action={
                <React.Fragment>
                  <Button color="secondary" size="small" onClick={this.handleClose}>
                    { this.props.t('league:snackBarMessages.labelClose') }
                  </Button>
                  {/*<IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>*/}
                </React.Fragment>
              }            
          />
      </Snackbar>
      )
  }
}

const mapStateToProps = state => {
  return {
      state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeErrorMessageSnackBar: ( new_state ) => {
        return dispatch( changeErrorMessageSnackBar( new_state ) );
    }, 
  }
}

export default compose(
  withTranslation("signUp"),
  connect(mapStateToProps, mapDispatchToProps)
)(ErrorMessageComponent)