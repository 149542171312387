// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-add-members__buttons__back{
    display: flex;
    justify-content: end;
   
}
.bulk-add-members__buttons__back__button{
    font-size: var(--space);
    padding: calc((var(--space-half))) calc((var(--space-and-half)));
    margin-right: var(--space); 
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/add_members_without_account_modal/AddMembersWithoutAccountModal.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;;AAExB;AACA;IACI,uBAAuB;IACvB,gEAAgE;IAChE,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".bulk-add-members__buttons__back{\n    display: flex;\n    justify-content: end;\n   \n}\n.bulk-add-members__buttons__back__button{\n    font-size: var(--space);\n    padding: calc((var(--space-half))) calc((var(--space-and-half)));\n    margin-right: var(--space); \n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
