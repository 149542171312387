// React
import React from 'react';

// Components
import FeedComponentV2 from 'shared/components/feed/FeedComponentV2';

// Styles
import './ProfileFeedComponent.styles.css';


// Profile Feed Component:
//   Load and render Posts and handles their actions.

// i18n
import { withTranslation } from 'react-i18next';

function ProfileFeedComponent( props ) {

    return (
        <div 
        className = { 
            "profile-feed " + ( props.className ? props.className : '' ) 
        }
        >
            
            {/* No Posts. Message no Posts on this Profile */}
            { props.posts?.length === 0 ? 
                <React.Fragment>
                    <p className="profile-feed__suggestions-title baloa-headline-4">
                        {props.t('posts.text')}</p>
                    <p className="profile-feed__suggestions-title-sm baloa-subtitle-1">
                        {props.t('posts.text')}</p>
                    {/* <p className="profile-feed__suggestions-title-sm baloa-body-2">
                        Empieza a seguir cuentas para alimentar tu feed</p> */}
                </React.Fragment>
                : 
                <FeedComponentV2 
                    className="home-feed__feed"

                    posts = { props.posts }
                    me_photo = { props.me_photo }

                    show_group = { false }

                    handleOnLoadMorePosts = { () => { props.handleOnLoadMorePosts() } }

                    //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                    //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                    
                    handleClap = { ( post ) => props.handleClap( post ) }
                    onCloseClapsModal={() => { props.onCloseClapsModal( true ) }}
                    loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}
                    
                    handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment ) => props.handleOnComment( post, comment) }
                    
                    openReportPostModal={() => {props.openReportPostModal( true )}}
                    handleReportPost={(post_id) => {props.handleReportPost(post_id)}}
                    
                    openBlockPostModal={() => {props. openBlockPostModal( true )}}  // eslint-disable-line
                    handleBlockProfile={(username) => {props.handleBlockProfile(username)}}
                    
                    handleOnDeletePostClick = { ( post ) => props.handleOnDeletePostClick( post ) }
                    
                    handleOnCopyLinkClick = {(post) => {props.handleOnCopyLinkClick(post)}}
                    
                    handleOnAuthorClick = { props.handleOnAuthorClick }

                    menu_is_open = {props.menu_is_open}
                    profile = { props.profile }
                    user = { props.user }
                    page = { props.page }
                    sendReaction= { props.sendReaction }
                    overrideReaction= { props.overrideReaction }
                    handleReactionsDetails = { props.handleReactionsDetails }
                    loadPostReactionsById = { props.loadPostReactionsById }
                    deleteReaction = { props.deleteReaction }
                    followPostProfile = { props.followPostProfile }                            
                    unfollowPostProfile = { props.unfollowPostProfile }                            
                    followPostPage = { props.followPostPage }                            
                    unfollowPostPage = { props.unfollowPostPage }

                    handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                    handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                    handleOnQuoteIconClick = { props.handleOnQuoteIconClick }
                    feedView = {"profile"}
                    handleProfilePostModal={ props.handleProfilePostModal }
                />
            }

        </div>
    )
}

// Exports
export default withTranslation('profile')(ProfileFeedComponent);
