import React from 'react';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';
import filterIcon from '../../../../assets/icons/filter-tournament-icon.svg';
import Popover from '@mui/material/Popover';
import { withTranslation } from 'react-i18next';
import './LeagueTournamentFilterComponent.styles.css';

const LeagueTournamentFilterComponent = (props) => {

    const { filterType, league_page } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickEditPostButton = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseModal = () => {
        setAnchorEl(null);
    };

    /* Filter type to render components
        tournamentList -Tipo de torneo, Cateforia, Genero, Año inicio, Año fin
        tournamentAll - Tipo de Futbol, Modalidad, Consola, videoJuego, Modo de juego, Ubicacion
        tournamentVideoGame - Tipo de torneo, Modalidad, Consola, VideoJuego, Modelo de Juego, Ubicacion
        tournamentSport - Tipo de Fultol, Categoria, Genero, Tipo de Futbol, Ubicacion
    */

    const formRef = React.useRef();

    const tournament_gender = [
        { content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.All'), value: "All" },
        { content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Female'), value: "Female" },
        { content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Male'), value: "Male" },
        { content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Free'), value: "Free" }
    ];

    const tournament_type = [
        { value: 'All', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.All') },
        { value: 'Sport', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Sport') },
        { value: 'Video_Game', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Video_Game') },
    ];

    const football_type = [
        { value: 'All', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.All') },
        { value: 'Football_11', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_11') },
        { value: 'Football_8', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_8') },
        { value: 'Football_7', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_7') },
        { value: 'Football_5', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_5') },
        { value: 'Football_6', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_6') },
        { value: 'Football_9', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_9') },
        { value: 'Football_10', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Football_10') },
    ];

    const formInitialValues = {
        football_type: '',
        tournament_type: '',
        tournament_category: '',
        tournament_gender: '',
        start_date: '',
        end_date: '',
        modality: '',
        console: '',
        video_game: '',
        game_mode: '',
        main_city: '',
        main_country: ''
    };

    const validationParamsToApi = valuesJson => {
        let queryParams = '';
        for (var key in valuesJson) {
            if (valuesJson[key].length > 0 && valuesJson[key] !== 'All') {
                queryParams += `&${key}=${valuesJson[key]}`;
            }
        }
        return queryParams;
    }

    const handleFilter = values => {
        if (values.country_autocomplete_new_filter) {
            delete values['country_autocomplete_new_filter'];
            delete values['city_autocomplete_new_filter'];
        }
        props.handleFilterTournament({ league_pagename: league_page, type_filter: validationParamsToApi(values) });
        handleCloseModal();
    };

    const handleCleanFields = () => {
        // handleCloseModal();
        props.handleBackButton();
    };

    return (
        <>
            <TextButtonComponent onClick={handleClickEditPostButton}
                className="filter-button small">
                <img src={filterIcon} alt={"filter_icon"} />
                <span>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelFilter')}</span>
            </TextButtonComponent>
            {open &&
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 250, left: 760 }}
                    onClose={handleCloseModal}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>

                    <div className="container_filter_option_list">
                        <div className="filter_option_list_card_title">
                            <p className="baloa-names">{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelFilter')}</p>
                            <TextButtonComponent disabled={false}
                                onClick={() => handleCleanFields()}
                                className="filter-button_clean small">
                                <span>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelClean')}</span>
                            </TextButtonComponent>
                        </div>
                        <div className='filter_option_list_option_body_form'>
                            <FormComponent
                                formInitialValues={formInitialValues}
                                functionDisabled={() => { }}
                                onSubmit={(values) => handleFilter(values)}
                                enableReinitialize={true}
                                innerRef={formRef}
                            >
                                <SelectFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTournamentType')}
                                    field_name="tournament_type"
                                    className="filter_option_list_body_select"
                                    validationFunction={() => { }}
                                    disabled={false}
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={tournament_type}
                                />
                                <SelectFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCategory')}
                                    className="filter_option_list_body_select"
                                    field_name="tournament_category"
                                    validationFunction={() => { }}
                                    disabled={false}
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={props.tournament_tag}
                                />
                                <SelectFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGender')}
                                    className="filter_option_list_body_select"
                                    field_name="tournament_gender"
                                    validationFunction={() => { }}
                                    disabled={false}
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={tournament_gender}
                                />

                                {(filterType === 'tournamentSport' || filterType === 'tournamentAll') && (
                                    <React.Fragment>
                                        <SelectFieldComponent
                                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelFootballType')}
                                            className="filter_option_list_body_select"
                                            field_name="football_type"
                                            validationFunction={() => { }}
                                            disabled={false}
                                            resetError={() => { }}
                                            error={props.errors}
                                            message=""
                                            placeholder=""
                                            options={football_type}
                                        />
                                        <InputFieldAutocompleteWithSearchComponent
                                            options={props.countries ? props.countries : null}
                                            noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                                            optionLabelField={"name"}
                                            optionSelectedField={"id"}
                                            lettercount={"2"}
                                            handleSearchWord={(newCountryValue) => props.handleSearchNationality(newCountryValue)}
                                            className="filter_option_list_body_input_search"
                                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCountry')}
                                            placeholder=""
                                            id="country_autocomplete_new_filter"
                                            field_name="main_country"
                                            validationFunction={() => { }}
                                            resetError={() => { }}
                                            error={props.errors}
                                            // value={props.countries ? props.countries[0] : null}
                                        />
                                        <InputFieldAutocompleteWithSearchComponent
                                            options={props.cities ? props.cities : null}
                                            noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                                            optionLabelField={"name"}
                                            optionSelectedField={"id"}
                                            lettercount={"2"}
                                            handleSearchWord={(newCityValue) => props.handleSearchWorldCities(newCityValue)}
                                            className="filter_option_list_body_input_search"
                                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCity')}
                                            placeholder=""
                                            id="city_autocomplete_new_filter"
                                            field_name="main_city"
                                            validationFunction={() => { }}
                                            resetError={() => { }}
                                            error={props.errors}
                                            disabled={props.disableCities}
                                            // value={props.cities ? props.cities[0] : null}
                                        />
                                    </React.Fragment>
                                )}
                                {/* TODO: This section is not yet available in back for filters.*/}
                                {/*
                                {filterType !== 'tournamentList' && (
                                    <>
                                        <SelectFieldComponent
                                            label={'Modalidad'}
                                            className="filter_option_list_body_select"
                                            field_name="modality"
                                            validationFunction={() => { }}
                                            disabled={false}
                                            resetError={() => { }}
                                            error={props.errors}
                                            message=""
                                            placeholder=""
                                            options={''}
                                        />
                                        <SelectFieldComponent
                                            label={'Video Juego'}
                                            className="filter_option_list_body_select"
                                            field_name="video_game"
                                            validationFunction={() => { }}
                                            disabled={false}
                                            resetError={() => { }}
                                            error={props.errors}
                                            message=""
                                            placeholder=""
                                            options={''}
                                        />
                                        <SelectFieldComponent
                                            label={'Modo de juego'}
                                            className="filter_option_list_body_select"
                                            field_name="game_mode"
                                            validationFunction={() => { }}
                                            disabled={false}
                                            resetError={() => { }}
                                            error={props.errors}
                                            message=""
                                            placeholder=""
                                            options={tournament_gender}
                                        />
                                        <InputFieldComponent
                                            label={'Ubicacion'}
                                            input_id="ubication"
                                            field_name="ubication"
                                            className="edit-basic-data__form__content__date__input-start"
                                            input_type="text"
                                            resetError={() => { }}
                                            error={''}
                                        />
                                    </>
                                )} */}
                                {filterType === 'tournamentList' && (
                                    <div className='filter_option_list_option_body_form_date'>
                                        <InputFieldComponent
                                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.start_date')}
                                            className="filter_option_list_body_input input-field"
                                            input_id="start_date"
                                            field_name="start_date"
                                            input_type="date"
                                            // resetError={ () => props.onResetError( { 'start_date': "" } )}
                                            resetError={() => { }}
                                            error={props.errors}
                                        />
                                        <InputFieldComponent
                                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.end_start')}
                                            input_id="end_date"
                                            className="filter_option_list_body_input input-field"
                                            field_name="end_date"
                                            input_type="date"
                                            // resetError={ () => props.onResetError( { 'start_date': "" } )}
                                            resetError={() => { }}
                                            error={props.errors}
                                        />
                                    </div>
                                )}
                                <div className='filter_option_list_option_button'>
                                    <SecondaryButtonComponent
                                        type={'button'}
                                        className="small"
                                        onClick={() => handleCloseModal()}>
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCancel')}</span>
                                    </SecondaryButtonComponent>&nbsp;
                                    <PrimaryButtonComponent
                                        className="small"
                                        type='submit'
                                        onClick={() => { }}
                                    >
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelApply')}</span>
                                    </PrimaryButtonComponent>
                                </div>
                            </FormComponent>

                        </div>
                    </div>
                </Popover>
            }
        </>

    )
};

export default withTranslation('league')(LeagueTournamentFilterComponent);