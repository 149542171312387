// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-payment-info-modal__title.baloa-normal-medium,
.tournament-payment-info-modal__value.baloa-normal-medium {
    font-size: calc( var(--space) + var(--space-eighth) );
    line-height: calc( var(--space) + var(--space-quarter) + 1px );
    color: var( --primary-very-dark-color );
}

.tournament-payment-info-modal__container {
    padding: var( --space-and-half ) calc( var(--space-x3) + var(--space) );
}

.tournament-payment-info-modal__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var( --space );
}

.tournament-payment-info-modal__value.baloa-normal-medium {
    color: var(--basic-gray-color);
}

`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-payment-info-modal/TournamentPaymentInfoModalComponent.styles.css"],"names":[],"mappings":"AAAA;;IAEI,qDAAqD;IACrD,8DAA8D;IAC9D,uCAAuC;AAC3C;;AAEA;IACI,uEAAuE;AAC3E;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".tournament-payment-info-modal__title.baloa-normal-medium,\n.tournament-payment-info-modal__value.baloa-normal-medium {\n    font-size: calc( var(--space) + var(--space-eighth) );\n    line-height: calc( var(--space) + var(--space-quarter) + 1px );\n    color: var( --primary-very-dark-color );\n}\n\n.tournament-payment-info-modal__container {\n    padding: var( --space-and-half ) calc( var(--space-x3) + var(--space) );\n}\n\n.tournament-payment-info-modal__row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: var( --space );\n}\n\n.tournament-payment-info-modal__value.baloa-normal-medium {\n    color: var(--basic-gray-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
