/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueMatchScorerComponent from 'app/leagues/components/league-match-scorer/LeagueMatchScorerComponent';
import TournamentEndMatchStepsComponent from '../tournament-end-match-steps/TournamentEndMatchStepsComponent';
import { MinuteValidator } from 'shared/validators/MinuteValidation';

// Styles
import './LeagueMatchScorerFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueMatchScorerFormComponent( props ) {

    /* eslint-disable */
    const [localScorer, setLocalScorer ] = React.useState([]);
    const [localAssistant, setLocalAssistant] = React.useState([]);
    const [localGoalMinute, setLocalGoalMinute ]= React.useState([]);
    const [localOwnGoal, setLocalOwnGoal] = React.useState([]);
    const [localOwnGoalStatus, setLocalOwnGoalStatus] = React.useState([]);
    const [visitorScorer, setVisitorScorer ] = React.useState([]);
    const [visitorAssistant, setVisitorAssistant] = React.useState([]);
    const [visitorGoalMinute, setVisitorGoalMinute ] = React.useState([]);
    const [visitorOwnGoal, setVisitorOwnGoal] = React.useState([]);
    const [visitorOwnGoalStatus , setVisitorOwnGoalStatus ] = React.useState([]);
    /* eslint-enable */

    const [initialValues, setInitialValues] = useState({});
    /* eslint-disable */
    React.useEffect( () => {

        if(props.endGameState.scorers){
            
            let fval = setFormValues(props.endGameState.scorers);
            setInitialValues(fval);
        }
        
    }, [ props.endGameState ] )
    /* eslint-enable */

    function setFormValues ( form_data ){
        if(form_data.local.length >0){
            /* eslint-disable */
            form_data.local.map((lscore, lindex) =>{
                let localPlayerFound = {};
                let localPlayerAssistanrFound = {};
                if(lscore.profile !=null){
                    if(lscore.own_goal === true || lscore.own_goal === 'ownGoal'){
                        localPlayerFound = props.visitor_team_members.find(player => player.username === lscore.profile?.username) ?? null;
                    }else{
                        localPlayerFound = props.local_team_members.find(player => player.username === lscore.profile?.username) ?? null;
                        if(lscore.assistant?.username !== null){
                            localPlayerAssistanrFound = props.local_team_members.find(player => player.username === lscore.assistant?.username) ?? null;
                        }
                    }
                    if(lscore.assistant?.username != null && (lscore.own_goal === false || lscore.own_goal === 'goal')){
                        localAssistant[lindex]={
                            id: localPlayerAssistanrFound.id ?? localPlayerAssistanrFound.player_id,
                            is_assigned : localPlayerAssistanrFound.is_assigned,
                            number: localPlayerAssistanrFound.number,
                            photo: localPlayerAssistanrFound.photo,
                            player_id: localPlayerAssistanrFound.id ?? localPlayerAssistanrFound.player_id,
                            player_name:localPlayerAssistanrFound.player_name,
                            player_position: localPlayerAssistanrFound.player_position,
                            rol_in_match:localPlayerAssistanrFound.rol_in_match,
                            username: lscore.assistant.username
                        }
                    }else{
                        localAssistant[lindex]= null;
                    }
                    if(lscore.profile != null){
                        localScorer[lindex]={
                            id: localPlayerFound.id ?? localPlayerFound.player_id,
                            is_assigned : localPlayerFound.is_assigned,
                            number: localPlayerFound.number,
                            photo: localPlayerFound.photo,
                            player_id: localPlayerFound.id ?? localPlayerFound.player_id,
                            player_name:localPlayerFound.player_name,
                            player_position: localPlayerFound.player_position,
                            rol_in_match:localPlayerFound.rol_in_match,
                            username: lscore.profile.username
                        };
                    }else{
                        localScorer[lindex]= null;
                    }
                    localGoalMinute[lindex]= lscore.minute;
                    localOwnGoal[lindex] = (lscore.own_goal === true || lscore.own_goal === 'ownGoal') ;
                    localOwnGoalStatus[lindex] = (lscore.own_goal === true || lscore.own_goal === 'ownGoal') ? 'ownGoal': 'goal';
                }
            })
            /* eslint-enable */                                
        }
        if(form_data.visitant.length >0){
            /* eslint-disable */
            form_data.visitant.map((vscore, vindex) =>{
                if(vscore.profile){
                    let visitorPlayerFound = {};
                    let visitorPlayerAssistanrFound = {};
                    if(vscore.profile != null || vscore.assistant != null){
                        if(vscore.own_goal === true || vscore.own_goal === 'ownGoal'){
                            visitorPlayerFound = props.local_team_members.find(player => player.username === vscore.profile?.username) ?? null;
                        }else{
                            visitorPlayerFound = props.visitor_team_members.find(player => player.username === vscore.profile?.username) ?? null;
                            if(vscore.assistant?.username !== null){
                                visitorPlayerAssistanrFound= props.visitor_team_members.find(player => player.username === vscore.assistant?.username) ?? null;
                            }
                        }
                    }
                    if(vscore.assistant !== null  && (vscore.own_goal === false || vscore.own_goal === 'goal')){
                        visitorAssistant[vindex]={
                            id: visitorPlayerAssistanrFound.id ?? visitorPlayerAssistanrFound.player_id,
                            is_assigned : visitorPlayerAssistanrFound.is_assigned,
                            number: visitorPlayerAssistanrFound.number,
                            photo: visitorPlayerAssistanrFound.photo,
                            player_id: visitorPlayerAssistanrFound.id ?? visitorPlayerAssistanrFound.player_id,
                            player_name:visitorPlayerAssistanrFound.player_name,
                            player_position: visitorPlayerAssistanrFound.player_position,
                            rol_in_match:visitorPlayerAssistanrFound.rol_in_match,
                            username: vscore.assistant.username
                        }
                    }else{
                        visitorAssistant[vindex] = null;
                    }
                    if(vscore.profile != null){
                        visitorScorer[vindex]={
                            id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                            is_assigned : visitorPlayerFound.is_assigned,
                            number: visitorPlayerFound.number,
                            photo: visitorPlayerFound.photo,
                            player_id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                            player_name:visitorPlayerFound.player_name,
                            player_position: visitorPlayerFound.player_position,
                            rol_in_match:visitorPlayerFound.rol_in_match,
                            username: vscore.profile.username
                        };
                    }else{
                        visitorScorer[vindex]= null;
                    }
                    visitorGoalMinute[vindex]= vscore.minute;
                    visitorOwnGoal[vindex] = (vscore.own_goal === true || vscore.own_goal === 'ownGoal'); 
                    visitorOwnGoalStatus[vindex] = (vscore.own_goal === true || vscore.own_goal === 'ownGoal') ? 'ownGoal': 'goal';
                }
            })
            /* eslint-enable */                                
        }

        let formValues = {
            local_scorer: localScorer,
            local_goal_minute: localGoalMinute,            
            local_own_goal: localOwnGoal,
            local_assistant: localAssistant,
            local_own_goal_status: localOwnGoalStatus,
            visitor_scorer: visitorScorer,
            visitor_goal_minute: visitorGoalMinute,
            visitor_own_goal: visitorOwnGoal,
            visitor_assistant: visitorAssistant,
            visitor_own_goal_status: visitorOwnGoalStatus
        }
        return formValues;
    }

    const { children, index, ...other } = props; // eslint-disable-line

    const scorers = {
        local: [],
        visitant: [],
    }
    const scorersfull = {
        local: [],
        visitant: [],
    }

    const goalsValidations = {
        local: [],
        visitant: [],
    }

    //Create arrays 
    const prepareData = (input_values) => {
        if((input_values.local_scorer || input_values.local_assistant) && props.local_score > 0){
            /* eslint-disable */
            input_values.local_scorer.map((localscorer, index) => {
                if(localscorer){
                    scorers.local.push({ 
                        profile: localscorer.username? localscorer.username : '', 
                        minute: input_values.local_goal_minute? input_values.local_goal_minute[index]? input_values.local_goal_minute[index] : null : null,
                        own_goal: input_values.local_own_goal 
                            ? input_values.local_own_goal[index] !== undefined 
                                ? (input_values.local_own_goal[index] === 'ownGoal' || input_values.local_own_goal[index] === true)
                                : false 
                            : false,
                        assistant : input_values.local_assistant 
                            ?  input_values.local_assistant[index] != undefined && (input_values.local_own_goal[index] !== 'ownGoal' || input_values.local_own_goal[index] === false)
                                ? input_values.local_assistant[index].username 
                                : null 
                            : null,
                    });
                    scorersfull.local.push({
                        profile: localscorer,
                        minute: input_values.local_goal_minute? input_values.local_goal_minute[index]? input_values.local_goal_minute[index] : null : null,
                        own_goal: input_values.local_own_goal 
                            ? input_values.local_own_goal[index] !== undefined 
                                ? (input_values.local_own_goal[index] === 'ownGoal' || input_values.local_own_goal[index] === true)
                                : false 
                            : false,
                        assistant : input_values.local_assistant 
                            ? input_values.local_assistant[index] != undefined && (input_values.local_own_goal[index] !== 'ownGoal' || input_values.local_own_goal[index] !== true)
                                ? input_values.local_assistant[index] 
                                : null 
                            : null,
                    });
                }else{
                    scorers.local.push({ 
                        profile: null,
                        minute: null,
                        own_goal: null,
                        
                    });
                    scorersfull.local.push({
                        profile: null,
                        minute: null,
                        own_goal: false,
                        
                    });
                }
                
            })
            /* eslint-enable */
        }
        if((input_values.visitor_scorer || input_values.visitor_assistant) && props.visitor_score > 0){
            /* eslint-disable */    
            input_values.visitor_scorer.map((visitorscorer, index) => {
              
                if(visitorscorer){
                    scorers.visitant.push( { 
                        profile: visitorscorer.username? visitorscorer.username : '',
                        minute: input_values.visitor_goal_minute? input_values.visitor_goal_minute[index]? input_values.visitor_goal_minute[index] : null : null,
                        own_goal: input_values.visitor_own_goal 
                            ? input_values.visitor_own_goal[index] !== undefined 
                                ? (input_values.visitor_own_goal[index]  === 'ownGoal' || input_values.visitor_own_goal[index] === true ) 
                                : false 
                            : false,
                        assistant: input_values.visitor_assistant 
                            ? input_values.visitor_assistant[index] != undefined && (input_values.visitor_own_goal[index]  !== 'ownGoal' || input_values.visitor_own_goal[index] === false ) 
                                ? input_values.visitor_assistant[index].username 
                                : null 
                            : null,
                    })
                    scorersfull.visitant.push( { 
                        profile: visitorscorer,
                        minute: input_values.visitor_goal_minute? input_values.visitor_goal_minute[index]? input_values.visitor_goal_minute[index] : null : null,
                        own_goal: input_values.visitor_own_goal 
                            ? input_values.visitor_own_goal[index] !== undefined 
                                ? (input_values.visitor_own_goal[index]  === 'ownGoal' || input_values.visitor_own_goal[index] === true)
                                : false 
                            : false,
                        assistant: input_values.visitor_assistant 
                            ? input_values.visitor_assistant[index] != undefined && (input_values.visitor_own_goal[index]  !== 'ownGoal' || input_values.visitor_own_goal[index] === false ) 
                                ? input_values.visitor_assistant[index] 
                                : null 
                            : null,
                    })
                }else{
                    scorers.visitant.push({
                        profile: null,
                        minute: null,
                        own_goal: null,
                        assistant : null
                    });
                    scorersfull.visitant.push({
                        profile: null,
                        minute: null,
                        own_goal: false,
                        assistant : null
                    });
                }
            })
            /* eslint-enable */
        }

        if(input_values.local_scorer != undefined){
            if(input_values.local_scorer.length < props.local_score){
                for(var i = 1; i <= props.local_score - input_values.local_scorer.length; i++){
                    scorers.local.push({ 
                        profile: null, 
                        minute: null, 
                        own_goal: null,
                        assistant : null
                    });
                    scorersfull.local.push({ 
                        profile: null,
                        minute: null,
                        own_goal: false,
                        assistant : null
                    });
                }    
            }
        }

        if(input_values.visitor_scorer != undefined){
            if(input_values.visitor_scorer.length < props.visitor_score){
                for(var j = 1; j <= props.visitor_score - input_values.visitor_scorer.length; j++){
                    scorers.visitant.push({ 
                        profile: null,
                        minute: null,
                        own_goal: null,
                        assistant : null
                    });
                    scorersfull.visitant.push({
                        profile: null,
                        minute: null,
                        own_goal: false,
                        assistant : null
                    });
                }    
            }
        }
        if(input_values.local_scorer === undefined){
            for(var k = 1; k <= props.local_score; k++){
                scorers.local.push({ 
                    profile: null,
                    minute: null,
                    own_goal: null,
                    assistant : null
                });
                scorersfull.local.push({
                    profile: null,
                    minute: null,
                    own_goal: false,
                    assistant : null
                });
            }                
        }

        if(input_values.visitor_scorer === undefined){            
            for(var l = 1; l <= props.visitor_score; l++){
                scorers.visitant.push({ 
                    profile: null,
                    minute: null,
                    own_goal: null,
                    assistant : null
                });  
                scorersfull.visitant.push({ 
                    profile: null,
                    minute: null,
                    own_goal: false,
                    assistant : null
                }); 
            }                
        }
    }
    
    return (
        
        <FormComponent 
            formInitialValues={ initialValues } 
            enableReinitialize= {true}
            onSubmit={( values, actions ) => { 
                // if(validateData(values)){
                    props.stepCompleted(3);
                    if(Object.keys(values).length !== 0){
                        prepareData(values);
                        props.onNewData( scorers );
                        props.setScorersGameState( scorersfull ); 
                    } 
                    props.setEndGameStepState(true);
                    
                // }else{
                //     props.stepCompleted(1);
                // }

                
            }}
            className="league-match-scorer-form__form form scroll-parent"
            autocomplete="off"
        >
            <TournamentEndMatchStepsComponent
                step = { props.step }
                stepCompleted = {(i)=>props.stepCompleted(i)}
                endGameStepState = { props.endGameStepState }
            />
            <LeagueMatchScorerComponent
                local_score= { props.local_score }
                local_team = { props.local_team }
                local_team_members={ props.local_team_members }
                visitor_score= { props.visitor_score }
                visitor_team={ props.visitor_team }
                visitor_team_members={ props.visitor_team_members }
                endGameState = { props.endGameState }
                initialValues = { initialValues }
                goalsValidations = { goalsValidations }
                validationFunction={ (scorer,minute) => MinuteValidator(scorer,minute) }
            />
            
            <div className="league-match-scorer-form__form__actions">
                <SecondaryButtonComponent 
                    disabled={false} 
                    onClick={()=>{props.stepCompleted(1);}} 
                    className="league-match-scorer-form__actions__back-btn"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonBack')}</span>
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    type="submit"
                    disabled={false} 
                    onClick={()=>{}} 
                    className="league-match-scorer-form__actions__submit"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonContinue')}</span>
                </PrimaryButtonComponent>
            </div>
        </FormComponent>

    )
}

export default withTranslation('league')(LeagueMatchScorerFormComponent);