// Axios
import Axios from "config/axiosConfig";

// Globals
import { clickstream_api_url } from "config/GlobalData";



export const SendEventsApi = ( clickstream_data ) => {

    return Axios.instance.post( `${ clickstream_api_url }/ingestevent/`, 
        {
            UserId: clickstream_data.UserId,
            Channel: clickstream_data.Channel,
            Events: clickstream_data.Events
        },
        {}
    );
    
}