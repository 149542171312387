// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-aptitude {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-eighth) 0;
    border-bottom: 1px solid var(--primary-light-color);
}

p.profile-aptitude__info__validations-count {
    color: var(--basic-gray-color);
    margin-top: 6px;
}

.profile-aptitude__info__validations-count.baloa-names{
    font-weight: var(--font-regular);
}

.color-actitude{
    color: var(--primary-very-dark-color) !important;
}

.button.validated span.icon {
    grid-area: icon;
    text-decoration: none;
}

.profile-aptitude__info__name.baloa-subtitle-2{
    color: var(--primary-very-dark-color);
}

.profile-aptitude__actions .button.small{
    font-weight: var(--font-medium);
    font-size: calc(var(--space) + var(--space-eighth) + 1px);
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-aptitude/ProfileAptitudeComponent.style.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,8BAA8B;IAC9B,mDAAmD;AACvD;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,+BAA+B;IAC/B,yDAAyD;AAC7D","sourcesContent":["\n.profile-aptitude {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: var(--space-eighth) 0;\n    border-bottom: 1px solid var(--primary-light-color);\n}\n\np.profile-aptitude__info__validations-count {\n    color: var(--basic-gray-color);\n    margin-top: 6px;\n}\n\n.profile-aptitude__info__validations-count.baloa-names{\n    font-weight: var(--font-regular);\n}\n\n.color-actitude{\n    color: var(--primary-very-dark-color) !important;\n}\n\n.button.validated span.icon {\n    grid-area: icon;\n    text-decoration: none;\n}\n\n.profile-aptitude__info__name.baloa-subtitle-2{\n    color: var(--primary-very-dark-color);\n}\n\n.profile-aptitude__actions .button.small{\n    font-weight: var(--font-medium);\n    font-size: calc(var(--space) + var(--space-eighth) + 1px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
