/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './PlayersActionComponent.styles.css';

// Assets
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';

export default function PlayersActionComponent(props) {

    const { row, handleOnEditActionClick, handleOnDeleteActionClick, editLabel, deleteLabel } = props;

    return (
        <React.Fragment>

            <div class="player-actions">                
                <div class="player-actions__action baloa-names" onClick={ (event) => { handleOnEditActionClick( event, row ); props.onHandleMenuOptions(); } }>
                    <IconEdit className="player-actions__action__icon-edit" />
                    {editLabel}
                </div>
                <div class="player-actions__action baloa-names" onClick={ (event) => { handleOnDeleteActionClick( event, row ); props.onHandleMenuOptions(); } }>
                    <span class="icon-trash player-actions-icon"></span>
                    {deleteLabel}
                </div>     
                     
            </div>

        </React.Fragment>
    )

}