/*eslint eqeqeq: "off"*/
// React
import React from 'react'

// Styles
import './ProfileTechnicsComponent.style.css'

// Formik
import { Formik } from 'formik';

// Assets
//import plus_icon from 'assets/icons/edit.svg';

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import PositionFieldComponent from 'shared/components/positions-field/PositionsFieldComponent';

// Functions
import convertFieldPositionCode from 'shared/functions/ConvertFieldPositionCode';
import convertLateralityCode from 'shared/functions/ConvertLateralityCode';
//import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';



function ProfileTechnicsComponent( props ) {

    return (

        <Card1Component 
            className={ "profile-technics " + ( props.className ? props.className : '' ) } 
            title={props.t('playerCard.title')}
            /* button={ props.is_owner && ( 
                <img className="profile-technics__new-button" 
                    src={ plus_icon }
                    alt=""
                />
            ) }
            onButtonClick={ props.onNewButton } */
        >

            {/* Primary Team */}
            {/* <div className="profile-technics__team">
                { 
                    props.profile?.attributes?.primary_team?.value?.name
                    ? 
                    <React.Fragment>
                        <div className="profile-technics__team__icon">
                            <spam className={"icon-ball"} color={getRandonIconBallColor()}></spam>
                        </div>
                        <p className="baloa-normal-text">{ props.profile?.attributes?.primary_team?.value?.name }</p>
                    </React.Fragment>
                    : 
                    <React.Fragment>
                        <div className="profile-technics__team__icon">
                        <spam className={"icon-ball"} color={getRandonIconBallColor()}></spam>
                        </div>
                        <p className="baloa-normal-text color-text">{props.t('playerCard.team')}</p>
                    </React.Fragment>
                }
            </div> */}

            {/* Technics Data */}
            <div className="profile-technics__data">
                <div className="profile-technics__data__positions-graph">
                    <Formik>
                        <PositionFieldComponent 
                            display = "simple"
                            primary_position = { props.profile?.attributes?.primary_position?.value }
                            secondary_positions = { props.profile?.attributes?.secondary_position?.value }
                        />
                    </Formik>
                </div>
                <div className="profile-technics__data__info">
                    <div className="profile-technics__data__info__statistics"></div>
                    { !isMobile 
                        ?
                        <div className="profile-technics__data__info__attributes">

                            {/* Primary Position */}
                            <div className="profile-technics__data__info__attributes__attribute">
                                <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!props.profile?.attributes?.primary_position?.value? '-medium':''} color-text-title`}>
                                    {props.t('playerCard.position')}
                                    {(props.profile?.attributes?.primary_position?.value && props.is_owner)? 
                                        <span onClick={()=>{ props.handleOnPrimaryPosition() }} className='hubicon-edit'></span>
                                        :''
                                    }
                                </p>
                                {props.profile?.attributes?.primary_position?.value 
                                ?
                                    <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">{convertFieldPositionCode( props.profile?.attributes?.primary_position?.value )}</p>
                                    :
                                    props.is_owner?
                                        <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnPrimaryPosition() }}>{props.t('playerCard.add')}</div>
                                        : ''
                                }
                            </div>
                                    
                            {/* Secondary Positions */}
                            <div className="profile-technics__data__info__attributes__attribute">
                                <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!(props.profile?.attributes?.secondary_position?.value?.length > 0)? '-medium':''} color-text-title`}>
                                    {props.t('playerCard.altenativePosition')}
                                    {(props.profile?.attributes?.secondary_position?.value?.length > 0  && props.is_owner)?<span onClick={()=>{ props.handleOnSecondaryPosition() }} className='hubicon-edit'></span>:''}
                                </p>                            
                                {props.profile?.attributes?.secondary_position?.value?.length  > 0
                                    ?
                                    props.profile?.attributes?.secondary_position?.value.map( secondary_position => {
                                        return <p key={ secondary_position } className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">
                                            { convertFieldPositionCode( secondary_position ) }
                                        </p>
                                    } ) 
                                    :
                                    /* <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium">{props.t('playerCard.record')}</p> */
                                    props.is_owner?
                                        <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnSecondaryPosition() }}>{props.t('playerCard.add')}</div>
                                    : ''
                                }
                            </div>

                            {/* Height */}
                            {/* <div className="profile-technics__data__info__attributes__attribute">
                                <p className="profile-technics__data__info__attributes__attribute__label baloa-hyperlink color-text-title">{props.t('playerCard.height')}</p>
                                <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-text color-text-subtitle">
                                    { 
                                        props.profile?.attributes?.height?.value 
                                        ?
                                        `${ props.profile?.attributes?.height?.value } ${ props.profile?.attributes?.height?.units }`
                                        :
                                        props.t('playerCard.record')
                                    }
                                </p>
                            </div> */}

                            {/* Weight */}
                            {/* <div className="profile-technics__data__info__attributes__attribute">
                                <p className="profile-technics__data__info__attributes__attribute__label baloa-hyperlink color-text-title">{props.t('playerCard.weight')}</p>
                                <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-text color-text-subtitle">
                                    { 
                                        props.profile?.attributes?.weight?.value
                                        ?
                                        `${ props.profile?.attributes?.weight?.value } ${ props.profile?.attributes?.weight?.units }`
                                        :
                                        props.t('playerCard.record')
                                    }
                                </p>
                            </div> */}
                        
                            {/* Laterality */}
                            <div className="profile-technics__data__info__attributes__attribute">
                                <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!props.profile?.attributes?.laterality?.value? '-medium':''} color-text-title`}>
                                    {props.t('playerCard.foot')}
                                    {( props.profile?.attributes?.laterality?.value && props.is_owner )? <span onClick={()=>{ props.handleOnLaterality() }} className='hubicon-edit'></span>:''}
                                </p>                                
                                {props.profile?.attributes?.laterality?.value
                                    ?
                                    <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">{convertLateralityCode( props.profile?.attributes?.laterality?.value )}</p>
                                    :
                                    props.is_owner?
                                        <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnLaterality() }}>{props.t('playerCard.add')}</div>
                                    : ''
                                }                                
                            </div>
                        </div>
                        :
                        <div className="profile-technics__data__info__attributes">

                            <div className="profile-technics__data__info__attributes__content">
                                    {/* Primary Position */}
                                    <div className="profile-technics__data__info__attributes__attribute">
                                        <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!props.profile?.attributes?.primary_position?.value? '-medium':''} color-text-title`}>
                                            {props.t('playerCard.position')}
                                            {(props.profile?.attributes?.primary_position?.value && props.is_owner)? <span onClick={()=>{ props.handleOnPrimaryPosition() }} className='hubicon-edit'></span>:''}
                                        </p>
                                        {props.profile?.attributes?.primary_position?.value 
                                        ?
                                            <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">{convertFieldPositionCode( props.profile?.attributes?.primary_position?.value )}</p>
                                            :
                                            props.is_owner?
                                                <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnPrimaryPosition() }}>{props.t('playerCard.add')}</div>
                                            : ''
                                        }
                                    </div>
                                    {/* Height */}
                                    {/* <div className="profile-technics__data__info__attributes__attribute">
                                        <p className="profile-technics__data__info__attributes__attribute__label baloa-hyperlink color-text-title">{props.t('playerCard.height')}</p>
                                        <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-text color-text-subtitle">
                                            { 
                                                props.profile?.attributes?.height?.value 
                                                ?
                                                `${ props.profile?.attributes?.height?.value } ${ props.profile?.attributes?.height?.units }`
                                                :
                                                props.t('playerCard.record')
                                            }
                                        </p>
                                    </div> */}

                                    {/* Weight */}
                                    {/* <div className="profile-technics__data__info__attributes__attribute">
                                        <p className="profile-technics__data__info__attributes__attribute__label baloa-hyperlink color-text-title">{props.t('playerCard.weight')}</p>
                                        <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-text color-text-subtitle">
                                            { 
                                                props.profile?.attributes?.weight?.value
                                                ?
                                                `${ props.profile?.attributes?.weight?.value } ${ props.profile?.attributes?.weight?.units }`
                                                :
                                                props.t('playerCard.record')
                                            }
                                        </p>
                                    </div> */}
                                    
                                    {/* Secondary Positions */}
                                    <div className="profile-technics__data__info__attributes__attribute">
                                        <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!(props.profile?.attributes?.secondary_position?.value?.length > 0)? '-medium':''} color-text-title`}>
                                            {props.t('playerCard.altenativePosition')}
                                            {(props.profile?.attributes?.secondary_position?.value?.length > 0 && props.is_owner)? <span onClick={()=>{ props.handleOnSecondaryPosition() }} className='hubicon-edit'></span>:''}
                                        </p>                            
                                        {props.profile?.attributes?.secondary_position?.value?.length  > 0
                                            ?
                                            props.profile?.attributes?.secondary_position?.value.map( secondary_position => {
                                                return <p key={ secondary_position } className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">
                                                    { convertFieldPositionCode( secondary_position ) }
                                                </p>
                                            } ) 
                                            :
                                            /* <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium">{props.t('playerCard.record')}</p> */
                                            props.is_owner?
                                                <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnSecondaryPosition() }}>{props.t('playerCard.add')}</div>
                                            : ''
                                        }
                                    </div>

                                    {/* Laterality */}
                                    <div className="profile-technics__data__info__attributes__attribute">
                                        <p className={`profile-technics__data__info__attributes__attribute__label baloa-normal${!props.profile?.attributes?.laterality?.value? '-medium':''} color-text-title`}>
                                            {props.t('playerCard.foot')}
                                            {(props.profile?.attributes?.laterality?.value && props.is_owner)? <span onClick={()=>{ props.handleOnLaterality() }} className='hubicon-edit'></span>:''}
                                        </p>                                
                                        {props.profile?.attributes?.laterality?.value
                                            ?
                                            <p className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium color-text-subtitle">{convertLateralityCode( props.profile?.attributes?.laterality?.value )}</p>
                                            :
                                            props.is_owner?
                                                <div className="profile-technics__data__info__attributes__attribute__value baloa-normal-medium" onClick={()=>{ props.handleOnLaterality() }}>{props.t('playerCard.add')}</div>
                                            : ''
                                        }                                
                                    </div>
                            </div>

                            

                            
                        </div>
                    }
                </div>
            </div>

        </Card1Component>
        
    )

}

export default withTranslation('profile')(ProfileTechnicsComponent);
