/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ComposeInscriptionFormComponent from 'app/tournaments/components/compose-inscription-form/ComposeInscriptionFormComponent'

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HubFormsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function HubFormsModalComponent ( props ) {

    const [submitButton, setSubmitButton] = useState(false);

    const closeModal=()=>{
        setSubmitButton(false);
        props.onClose();
    }
    
    const sendForm =(data)=>{
        props.onNewData(data);
    }
    
    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={
                (!props.selected_admin_player?.full_name && props.profile_type === 'player') 
                ? props.t('inscriptionModal.labelStaffForm') 
                :(props.profile_type === 'player') ? props.t('inscriptionModal.labelPlayerForm') : props.t('inscriptionModal.subtitle') 
                }
            className="hub_form_modal_component"
        >
            <div className="hub-forms-modal scroll-parent">

                {/** Player data*/}
                {props.profile_type === 'player' &&
                    <div className='hub-forms-modal__team-info'> 
                        { (props.selected_admin_player?.profile_photo || props.selected_admin_player?.photo)
                            ? props.selected_admin_player?.profile_photo ? 
                                <img src={props.selected_admin_player?.profile_photo} alt={`${props.selected_admin_player?.username} img`} />
                                : <img src={props.selected_admin_player?.photo} alt={`${props.selected_admin_player?.username} img`} />
                            :
                            <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                        }
                        <div className='hub-forms-modal__team-info__name-username'>
                            {
                                props.selected_admin_player?.full_name  ? <div className='baloa-names'>{props.selected_admin_player?.full_name}</div> : <div className='baloa-names'>{props.selected_admin_player?.first_name} {props.selected_admin_player?.last_name}</div>
                            }
                            <div className='baloa-username'>@{props.selected_admin_player?.username}</div>
                        </div>
                    </div>
                }
                {/** Team data*/}
                {props.profile_type === 'teams' &&
                    <div className='hub-forms-modal__team-info'>
                        { props.selected_team?.logo
                            ?
                            <img src={props.selected_team?.logo} alt={`${props.selected_team?.teamname} img`} />
                            :
                            <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                        }
                        <div className='hub-forms-modal__team-info__name-username'>
                            <div className='baloa-names'>{props.selected_team?.team}</div>
                            <div className='baloa-username'>@{props.selected_team?.teamname}</div>
                        </div>
                    </div>
                }
                {/* Team/Player form */}
                <ComposeInscriptionFormComponent
                    inscription_form_template={props.inscription_form_template}
                    tournament_team_id={props.tournament_team_id}
                    tournament_player_id={props.tournament_player_id}//Null si es el formulario de un equipo
                    onNewData = { (data)=>{sendForm(data)}}
                    submitForm={submitButton}
                    resetButton={()=>{setSubmitButton(false)}}
                    team_player_form={true}
                    tournament_staff_id = {props.staff_id}
                />                 


                <div className='hub-forms-modal__buttons'>
                    <PrimaryButtonComponent
                        type={'button'}
                        onClick={ ()=>{ setSubmitButton(true) }} 
                        disabled={props.loading_forms} 
                        className='small'
                    >
                        {props.loading_forms
                        ?   <div className='new-tournament__center__header__button__spinner-container'>                                                
                                <div className="span-newtournament__disabled" />
                            </div>
                        : <span>{props.t('inscriptionModal.buttons.finalize')}</span>
                        }
                    </PrimaryButtonComponent>    
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('tournament')(HubFormsModalComponent);