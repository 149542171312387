// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-bulk-delete-member-modal-actions {
   display: flex;
   justify-content: flex-end;
}

.league-bulk-delete-member-modal-actions .secondary-button{
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-bulk-delete-member-modal/LeagueBulkDeleteMemberModalComponent.styles.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,yBAAyB;AAC5B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".league-bulk-delete-member-modal-actions {\n   display: flex;\n   justify-content: flex-end;\n}\n\n.league-bulk-delete-member-modal-actions .secondary-button{\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
