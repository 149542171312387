const Constants_profile_state = {
    ListConstants : []
}

export default function ConstantsProfileReducer(state = Constants_profile_state, action) {
    switch (action.type) {
        case "PROFILE:CHANGE_CONSTANS": {
            return { ...state, ListConstants : action.data}
        }

        // no default
    }

    return state;
}