// React
import React from 'react';

// Styles
import './HelpComponent.styles.css';

import undrawQuestions from 'assets/images/help_undraw_questions.png';
import youTubeImg from 'assets/images/help_YouTube_full-color_icon.png';
import whatsAppImg from 'assets/images/help_WhatsApp_2.png';
import callImg from 'assets/images/help_call.png';
import mailImg from 'assets/images/help_mail.png';

//i18
import { withTranslation } from 'react-i18next';

function HelpComponent(props) {

    return (
        /* eslint-disable */
        <div className='help-container'>
            <div className='help-container__header'>
                <h5 className="baloa-headline-2">{props.t('options.help.labelTitle')}</h5>
            </div>
            <div className="help-message baloa-normal-medium"><span className='hubicon-info'></span>{props.t('options.help.labelText')}</div>
            <div className="help-options">
                <a href="https://www.youtube.com/channel/UCMaP2w58i0b0lLByHVv9xLQ" target="_blank">
                    <div className="help-card">
                        <img src={youTubeImg}/>
                        <div className="help-info">
                            <p className="baloa-normal-medium">{props.t('options.help.youTubeChannelLbl')}</p>
                            
                        </div>
                    </div>
                </a>
                <a href="https://wa.me/573023517804" target="_blank">
                    <div className="help-card">
                        <img src={whatsAppImg}/>
                        <div className="help-info">
                            <p className="baloa-normal-medium">+57 3023517804</p>
                            <p className="baloa-names">{props.t('options.help.labelSupport')}</p>
                        </div>
                    </div>
                </a>
                <div className="help-card">
                    <img src={callImg}/>
                    <div className="help-info">
                        <p className="baloa-normal-medium">+1 2569987359</p>
                        <p className="baloa-names">{props.t('options.help.supportUsaLbl')}</p>
                    </div>
                </div>
                <a href="mailto:support@baloasports.com" target="_blank">
                    <div className="help-card">
                        <img src={mailImg}/>
                        <div className="help-info">
                            <p className="baloa-normal-medium">support@baloasports.com</p>
                            <p className="baloa-names">{props.t('options.help.supportEmailLbl')}</p>
                        </div>
                    </div>  
                </a>              
            </div>
            <div className='help-info__info-image'>{<img src={undrawQuestions}/>}</div>
        </div>
        /* eslint-enable */
    )
}

export default withTranslation('league')(HelpComponent);