// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './TournamentsModalFormExistingComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';

function TournamentModalFormExistingComponent( props ) {

    const [selectedIdFormTemplate, setSelectedIdFormTemplate ] = React.useState(null);

    const handleOnSelectForm = (e) => {
        setSelectedIdFormTemplate( e.target.value );
    }
    const handleOnClose = () => {
        setSelectedIdFormTemplate( null );
        props.onClose()
    }

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={handleOnClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.modalTitle')}
            className=""
        >
            <div className='existing-form form scroll-parent'>
                <div className='existing-form__content scroll'>
                <div className={'existing-form__content__messages'}>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.modalText')}</div>
                    <RadioGroup row
                        aria-labelledby="form_templates"
                        name={"form_templates"}
                        value={''}
                        className="existing-form__content__items-list inside"
                    >
                    {props.listExistingForm.map( data => {
                        return(
                            <FormControlLabel
                                value={data.id}
                                control={<Radio color="secondary" checked={selectedIdFormTemplate === data.id} />}
                                label={<div className={'existing-form__content__item__texts'}>
                                        <div className={'existing-form__content__item__texts__name baloa-table-column'}>{data.name? data.name : props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.emptyName')}</div>
                                        <div className={'existing-form__content__item__texts__creator baloa-mini'}>{data.questions_quantity +" "+ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.modalQuestion')} • {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.modalForm')+" "+ data.owner.name}</div>
                                    </div>}
                                labelPlacement="end"
                                className={`existing-form__content__item ${selectedIdFormTemplate === data.id ? 'selected' : ''}`}
                                onChange={(e) => handleOnSelectForm(e)}
                            />
                        )
                    })}
                   </RadioGroup>
                </div>
                <div className="existing-form__actions form__actions">
                    <PrimaryButtonComponent 
                        type="button"
                        disabled={!selectedIdFormTemplate} 
                        onClick={()=> {props.handleOnLoadPageFormTemplate(selectedIdFormTemplate)}} 
                        className="terms__actions__accept"
                    >
                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalExistingForm.modalButton')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>

        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentModalFormExistingComponent);