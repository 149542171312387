// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Styles
import './SignUpClaimAccountOptionsComponent.styles.css';

//Actions
import { logInEmailAction, sendPhoneAction } from 'app/authentication/actions/LoginActions';

// Actions creators
import { changeSignUpStatus, cleanClaimedAccountProfileAction, registerDocumentVerificationAction } from 'app/authentication/actions/LoginActionsCreators';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';
import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import {isMobile} from 'react-device-detect';

//Functions
import { generateBaloaToken } from 'shared/functions/GenerateBaloaToken';

class SignUpClaimAccountOptionsComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            method: 'PHONE',
            method_with_data: this.props.state.signup.claim_account_profile.phone? 'OTP': 'EMAIL_VERIFICATION',
        };
    };

    handleMethod = (event) => {        
        this.setState({method: event.target.value});
    }

    handleMethodWithData = (event) => {        
        this.setState({method_with_data: event.target.value});
    }

    async handleClaim() {
        if(this.props.state.signup.claim_account_profile.phone || this.props.state.signup.claim_account_profile.has_email ){            
            if(this.state.method_with_data === 'EMAIL_VERIFICATION'){
                this.props.logInEmailAction(this.props.state.signup.claim_account_profile.email)
            }
            if(this.state.method_with_data === 'OTP'){
                let token = await generateBaloaToken( this.props.state.signup.phone );
                this.props.sendPhoneAction({phone: this.props.state.signup.claim_account_profile.phone}, token);
            }
        }else{
            this.props.registerDocumentVerificationAction(true);
            this.props.changeSignUpStatus( SIGNUP_STATUS[this.state.method])
        }
    }
    
    hideEmailCharacters(email){  
        let newEmail = email.substring(0, 3) + email.substring(3, email.indexOf("@")).replaceAll(/[a-zA-Z0-9.!#$%&'*/=?^_+-`{|}~]/g, "*") + email.substring(email.indexOf("@"),email.length);
        return newEmail;
    }

    hidePhoneCharacters(phone){  
        let newEmail = '+' + phone.substring(0, 2)+ ' ' + phone.substring(2, phone.length - 2).replaceAll(/[0-9.!#$%&'*/=?^_+-`{|}~]/g, "*") + phone.substring(phone.length -2,phone.length);
        return newEmail;
    }
    
    render = () => {         
        return (
            <React.Fragment>
                <div className="signup-claim-account-options__container">
                    <div className="signup-claim-account-options__container__left-col">
                        <Link to="/">
                            { !isMobile 
                                ?
                                <img className="signup-header__image" src={baloa_logo} alt=""/>
                                :
                                <img className="signup-header__image" src={baloa_green_logo} alt=""/>
                            }
                        </Link>
                    </div>
                    <div className="signup-claim-account-options__container__right-col">
                        <div className='signup-claim-account-options__container__right-col__title'>
                            <span className='icon-arrow-left baloa-headline-1' onClick={() => {this.props.changeSignUpStatus(SIGNUP_STATUS['CLAIM_ACCOUNT']); this.props.cleanClaimedAccountProfileAction({})} }/>
                        </div>
                        <div className="signup-claim-account-options__text baloa-subtitle-2">{this.props.t('claimAccount.cAccMethodMsg')}</div>
                        {(this.props.state.signup.claim_account_profile.phone || this.props.state.signup.claim_account_profile.has_email) ?
                            <RadioGroup row aria-labelledby="phase_type" name="method" defaultValue="OTP" className="signup-claim-account-options__options"  >
                                {this.props.state.signup.claim_account_profile.phone &&                                
                                    <FormControlLabel
                                        value="OTP"
                                        control={ <Radio color="secondary" checked={ this.state.method_with_data === "OTP"} /> }
                                        label={                                        
                                            <div className={'signup-claim-account-options__options__option-text'}>
                                                <span className="hubicon-phone"></span>
                                                <div className={'signup-claim-account-options__options__option-data'}>
                                                    <div className={'signup-login-panel__methods__method__label'}>{this.props.t('claimAccount.cAccMethodPreRegPhoneBtnLbl')}</div>
                                                    <div className='baloa-names'>{this.hidePhoneCharacters( this.props.state.signup.claim_account_profile.phone )}</div>
                                                </div>                                                                                            
                                            </div>                                                                                
                                        }
                                        labelPlacement="start"
                                        onChange={ this.handleMethodWithData }
                                        disabled = {!this.props.state.signup.claim_account_profile.phone}
                                        className={this.state.method_with_data === "OTP"? 'selected' : '' }
                                    />
                                }
                                {this.props.state.signup.claim_account_profile.has_email &&
                                    <FormControlLabel
                                        value="EMAIL_VERIFICATION"
                                        control={ <Radio color="secondary" checked={this.state.method_with_data === "EMAIL_VERIFICATION" } /> }
                                        label={                                        
                                            <div className={'signup-claim-account-options__options__option-text'}>
                                                <span className="icon-email"></span>
                                                <div className={'signup-claim-account-options__options__option-data'}>                        
                                                    <div className={'signup-login-panel__methods__method__label'}>{this.props.t('claimAccount.cAccMethodPreRegEmailBtnLbl')}</div>
                                                    {this.props.state.signup.claim_account_profile.has_email && <div className='baloa-names'>{this.hideEmailCharacters(this.props.state.signup.claim_account_profile.email)}</div>}
                                                </div>
                                            </div>                                        
                                        }
                                        labelPlacement="start"
                                        onChange={ this.handleMethodWithData }
                                        disabled = { !this.props.state.signup.claim_account_profile.has_email }
                                        className={this.state.method_with_data === "EMAIL_VERIFICATION"? 'selected' : '' }
                                    />
                                }                      
                            </RadioGroup>
                        :
                            <RadioGroup row aria-labelledby="phase_type" name="method" defaultValue="PHONE" className="signup-claim-account-options__options"  >
                                <FormControlLabel
                                    value="PHONE"
                                    control={ <Radio color="secondary" checked={ this.state.method === "PHONE"} /> }
                                    label={
                                        <div className={'signup-claim-account-options__options__option-text'}>
                                            <span className="hubicon-phone"></span>                                                
                                            <div className={'signup-login-panel__methods__method__label'}>{this.props.t('claimAccount.cAccMethodNotRegPhoneBtnLbl')}</div>
                                        </div>
                                    }
                                    labelPlacement="start"
                                    onChange={ this.handleMethod }
                                    disabled = {false}
                                    className={this.state.method === "PHONE"? 'selected' : '' }
                                />
                                <FormControlLabel
                                    value="LOGIN_EMAIL"
                                    control={ <Radio color="secondary" checked={this.state.method === "LOGIN_EMAIL" } /> }
                                    label={
                                        <div className={'signup-claim-account-options__options__option-text'}>
                                            <span className="icon-email"></span>                                           
                                            <div className={'signup-login-panel__methods__method__label'}>{this.props.t('claimAccount.cAccMethodNotRegEmailBtnLbl')}</div>
                                        </div>
                                    }
                                    labelPlacement="start"
                                    onChange={ this.handleMethod }
                                    disabled = { false }
                                    className={this.state.method === "LOGIN_EMAIL"? 'selected' : '' }
                                />                      
                            </RadioGroup>
                        }
                        
                        
                        <div className="signup-claim-account-options__buttons">
                            <PrimaryButtonComponent disabled={false} onClick={() => { this.handleClaim() }} className="signup-claim-account-options__buttons__forward">
                                <span>{this.props.t('claimAccount.cAccMethodContinueBtnLbl')}</span>
                            </PrimaryButtonComponent>
                        </div>                                   
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        cleanClaimedAccountProfileAction: (data) => {
            dispatch(cleanClaimedAccountProfileAction(data));
        },
        logInEmailAction: (email) => {
            return dispatch(logInEmailAction(email))
        },
        sendPhoneAction: (phone, token) => {
            return dispatch(sendPhoneAction(phone, token))
        },
        registerDocumentVerificationAction: (state) => {
            dispatch (registerDocumentVerificationAction(state))
        }
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpClaimAccountOptionsComponent);
