
// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';

// Styles
import './ProfileTechnicsMeassuresModalComponent.styles.css';

// Validators
import { AnyValidator } from 'shared/validators/SignUpValidator';

// i18n
import { withTranslation } from 'react-i18next';
import ProfileStepsDatasheetComponent from '../profile-steps-datasheet/ProfileStepsDatasheetComponent';


function ProfileTechnicsMeassuresModalComponent( props ) {
    
    const formInitialValues = {
        weight_value: props.model.attributes?.weight?.value,
        weight_units: props.model.attributes?.weight?.units,
        height_value: props.model.attributes?.height?.value,
        height_units: props.model.attributes?.height?.units,
    }

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }
    
    return (
            <div className="technics-form-2">

                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        console.log("Submit!!")
                        let new_weight_data = { ...props.model.attributes?.weight, value: values['weight_value'], units: values['weight_units'] };
                        props.onNewData( new_weight_data );
                        let new_height_data = { ...props.model.attributes?.height, value: values['height_value'], units: values['height_units'] };
                        props.onNewData( new_height_data );
                        props.stepCompleted(4);

                    }}
                    className="technics-form-2__form form"
                    functionDisabled={()=>{}}
                >
                    <div className="technics-form-2__form__fields form__fields">
                        <ProfileStepsDatasheetComponent
                            step={props.step}
                            stepCompleted = {(i)=>props.stepCompleted(i)}
                        />
                        <div className="technics-form-2__form__fields__group">
                            <InputFieldComponent 
                                label={props.t('playerCard.weight')} 
                                field_name="weight_value"
                                validationFunction={ AnyValidator }
                                disabled={false}
                                className="technics-form-2__form__input"
                                input_type="number"
                                resetError={ () => props.onResetError( { 'weight_value': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder=""
                            />
                            <SelectFieldComponent 
                                label="" 
                                field_name="weight_units"
                                validationFunction={ AnyValidator }
                                disabled={false}
                                className="technics-form-2__form__select"
                                resetError={ () => props.onResetError( { 'weight_units': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder=""
                                // options={ [ { value: 'kg', content: 'kg' } ] }
                                options={ [ { value: 'kg', content: "kg" } ] }
                            />
                        </div>
                        <div className="technics-form-2__form__fields__group">
                            <InputFieldComponent 
                                label={props.t('playerCard.height')} 
                                field_name="height_value"
                                validationFunction={ AnyValidator }
                                disabled={false}
                                className="technics-form-2__form__input"
                                input_type="number"
                                resetError={ () => props.onResetError( { 'height_value': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder=""
                            />
                            <SelectFieldComponent 
                                label={ null } 
                                field_name="height_units"
                                validationFunction={ AnyValidator }
                                disabled={false}
                                className="technics-form-2__form__select"
                                resetError={ () => props.onResetError( { 'height_units': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder=""
                                options={ [ { value: 'cm', content: 'cm' } , { value: 'ft', content: 'ft' } ] }
                            />
                        </div>
                        
                    </div>

                    <div className="technics-form-2__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="technics-form-2__form__submit"
                        >
                            <span>{props.t('playerCard.button')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
    )
}

export default withTranslation('profile')(ProfileTechnicsMeassuresModalComponent);