// React
import React from 'react';

// Styles
import './TermsComponent.styles.css';

function TermsComponent ( props ) {
    
    return(

        <div className="terms-content">  
            { props.terms.map( text => {
                return (
                    <div key={ text.lenguaje_text } className="terms-content__content">
                        
                        {text.lenguaje_text === 'English' ?
                            <div className="terms-content__content__text-english">
                                <p className="baloa-subtitle-2">{text.text}</p>
                            </div>
                        :   <div className="terms-content__content__text-spanish">
                                <p className="baloa-subtitle-2">{text.text}</p>
                            </div>
                        }

                    </div>
                )
            })}
        </div> 
    )
}

export default TermsComponent;
