// React
import React from 'react'

// React device detect
//import {isMobile} from 'react-device-detect';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import CheckMultipleTournamentListComponent from 'shared/components/check-multiple-tournament-list/CheckMultipleTournamentListComponent';
import ProfileRecentMatchesFilter from 'app/profiles/components/profile-recent-matches-filter/ProfileRecentMatchesFilter';

// React Infinite Scroll Component
import InfiniteScroll from "react-infinite-scroll-component";

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Moment
import * as moment from 'moment';

// Styles
import './ProfileChooseMatchClipModalComponent.styles.css';

//Assets
import loading_ball from 'assets/images/loading_ball.gif';

// i18n
import { withTranslation } from 'react-i18next';



function ProfileChooseMatchClipModalComponent( props ) {
    const [tournamentsListString, setTournamentsListString] = React.useState('');
    const [oldfilterParam, setOldfilterParam] = React.useState('');

    const handleOnTournamentFilter = (filterArray) =>{
        const tournamentsStringFilter = filterArray.reduce((tournaments, actual) => `${tournaments}${actual}|` ,'').slice(0,-1);
        setTournamentsListString(tournamentsStringFilter);
        props.matchesFilter(tournamentsStringFilter, oldfilterParam);
    } 

    const handleOnRecentFilter = (filter) =>{

        let tempOlder = '';
        if(filter === 'older'){
            setOldfilterParam( 'True' );
            tempOlder = 'True';
        }else{
            setOldfilterParam('');
            tempOlder = '';
        }

        props.matchesFilter(tournamentsListString, tempOlder);            

    } 

    const handleCloseModal = () => {
        props.onClose();
        props.resetShareClip();
        setTournamentsListString('')
        setOldfilterParam('');
    }

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.profile_matches_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.profile_matches_next_page]); // eslint-disable-line

    return (        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={handleCloseModal}
            title={props.t('tournamentSummary.labelMatches')}
            className='profile-choose-match-clip-modal'
        >
            <div className='profile-choose-match-clip-modal__containers'>
                <div className='profile-choose-match-clip-modal__filters'>
                    <CheckMultipleTournamentListComponent
                        buttonTitle={props.t('tournamentSummary.modalTournamentMatches.tournamentsFilterBtnTitle')}
                        listTitle={props.t('tournamentSummary.modalTournamentMatches.tournamentsFilterListTitle')}
                        tournaments={props.profile_tournaments}
                        handleOnTournamentFilter={(filter)=>{handleOnTournamentFilter(filter)}}
                    />
                    <ProfileRecentMatchesFilter
                        buttonTitle={props.t('tournamentSummary.modalTournamentMatches.stateFilterBtnTitle')}
                        listTitle={props.t('tournamentSummary.modalTournamentMatches.stateFilterListTitle')}
                        handleOnMatchesFilter={(filter)=>{ handleOnRecentFilter(filter) }}
                    />
                </div>
                <div className='profile-choose-match-clip-modal__matches-container'>
                    <InfiniteScroll
                        dataLength={props.profile_matches.length}
                        next={props.loadMatches}
                        hasMore={loadMore}
                        loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                        height={'90vh'}
                        endMessage={''}
                    >
                    { props.profile_matches.map((match, index) => (
                        <React.Fragment>
                        <div key={index} className='profile-choose-match-clip-modal__table-body__container' onClick={()=>{ match.has_paid_stats ? props.setClipMatch(match.id) : console.log('blocked') }}>
                            <div className='profile-matches__tournament-match-info'>
                                <div className='profile-matches__tournament_info'>
                                    <div className='profile-matches__tournament_info__img'>
                                        { match.tournament_logo
                                            ? <img src={match.tournament_logo} alt={`${match.tournament_name} logo`} />
                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                        }
                                    </div>
                                    <div className='baloa-table'>{match.tournament_name}</div>                                                                         
                                </div>
                                <div className='profile-matches__match_date baloa-table'>
                                    {`${moment(match.match_date + ' ' + match.match_time).format('MMM DD, YYYY - h:mm A')}`}
                                </div>                              
                            </div>
                            <div className={`profile-matches__tournament-match-data ${!match.has_paid_stats ? 'profile-matches__locked_values': ''}`}>
                                <div className="profile-matches__tournament-match-data__local">
                                    {match?.local_team_photo && 
                                        <img src={ match.local_team_photo } alt={`${match.local_team_name} img`}/>
                                    }
                                    {!match.local_team_photo && 
                                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                                    }
                                    <div className="profile-matches__tournament-match-data__label baloa-table">{ match.local_team_name }</div>
                                </div>
                                <div className='profile-matches__tournament-match-data__score'>
                                    <div className="profile-matches__tournament-match-data__score__left">
                                        <div className='baloa-subtitle-2' >{ match.local_team_score }</div>
                                        { match.penalty_definition && <div className='baloa-subtitle-2' >{ `(${match.local_team_penalty_score}` }</div>}                            
                                    </div>
                                    <div className="profile-matches__tournament-match-data__score__center">
                                        <div className='baloa-subtitle-2' >-</div>
                                        {match.penalty_definition && <div className='baloa-subtitle-2' >-</div>}                            
                                    </div>
                                    <div className="profile-matches__tournament-match-data__score__right">
                                        <div className='baloa-subtitle-2' >{ match.visitant_team_score }</div>
                                        { match.penalty_definition && <div className='baloa-subtitle-2' >{ `${match.visitant_team_penalty_score})` }</div>}
                                    </div>
                                </div>
                                <div className="profile-matches__tournament-match-data__visitor">
                                    {match?.visitant_team_photo && 
                                        <img src={ match.visitant_team_photo } alt={`${match.visitant_team_name} img`}/>
                                    }
                                    {!match.visitant_team_photo && 
                                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                                    }
                                    <div className="profile-matches__tournament-match-data__label baloa-table">{ match.visitant_team_name }</div>                                   
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                    ))}
                    </InfiniteScroll>
                </div>
                {props.has_pending_tournaments &&
                    <div className='profile-choose-match-clip-modal__tournaments-blocked-message' >
                        <div className='profile-choose-match-clip-modal__blocked-message baloa-names'>
                            <span className='hubicon-info'></span>
                            {props.has_unbloqued_tournaments? props.t('tournamentSummary.modalMatchClip.matchesBlocked') : props.t('tournamentSummary.tournamentsBlocked')}
                            <PrimaryButtonComponent
                                type='button'
                                onClick={()=>{ props.handleModalUnlockData() }}
                                className='profile-choose-match-clip-modal__blocked-message__button small'
                            >
                                <span>{props.t('tournamentSummary.unlockBtn')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                }
            </div>
         </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileChooseMatchClipModalComponent);