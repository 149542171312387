import React from 'react';

//Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Validators
// import { RequiredValidator } from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';

import '../file-dynamic-form/FileTournamentFormComponent.styles.css';

function FileTournamentFormComponent({
    id,
    caption,
    question_type,
    is_required,
    multiple = false,
    onFilesChosen,
    t,
    disabled,
    defaultValue,
    formRef,}) {

    const [refInput, setRefInput] = React.useState(null);
    const [fileName, setfileName] = React.useState(''); // eslint-disable-line
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [existingFile, setExistingFile] = React.useState(null);

    const filesToArray = files => {
        if (!files) return [];
        const array = [];
        for (let i = 0; i < files.length; i += 1) {
            array.push(files.item(i));
        }
        return array;
    }

    const onFilesAdded = event => {
        setSelectedImage(event.target.files[0]);
        setExistingFile(event.target.files[0]);
        event.preventDefault();
        const files = filesToArray(event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files);
        try {
            if (onFilesChosen) {
                onChangeFile(files);
            };
        } catch (e) { }
    }

    React.useEffect(()=> onFileUpload(),[selectedImage]); // eslint-disable-line

    React.useEffect(() => {
        if(defaultValue?.length > 0 && selectedImage === null){
            setExistingFile(defaultValue);
            onFilesChosen(defaultValue);
        }
        // formRef.current?.setFieldValue(id,defaultValue);
        //"C:\\fakepath\\boyaca-dev-2.png"
    }, [formRef]); // eslint-disable-line

    const onFileUpload = () => {
        if(selectedImage){
            const reader = new FileReader();
            reader.onload = handleReaderLoaded.bind(this);
            reader.readAsBinaryString(selectedImage); 
        }                
    }


    const onDragover = e => e.preventDefault();

    const onChangeFile = (e) => {
        if (e[0].name.length > 0) {
            setfileName(e[0].name);
        }else setfileName('');
    };

    const handleReaderLoaded = ( readerEvent ) => {
        let binaString = readerEvent.target.result;
        onFilesChosen(selectedImage.name+";"+btoa(binaString));
    }

    const handleCleanFile = () => {    
        setRefInput(null)
        setfileName('')
        setSelectedImage(null);
        setExistingFile(null);
        formRef.current?.setFieldValue(id, '');
    }

    return (
        <div className="input_file_tournament_Form">
            <label className={"baloa-username input_file_tournament_Form_label"} for={id}>{caption}</label>
            <div className={"input_file_tournament_Form_input"} onDragover={onDragover} onDrop={onFilesAdded}>
            {(selectedImage?.name === null || existingFile === null)
                ?
                <div className={"input_file_tournament_Form_input"} onDragover={onDragover} onDrop={onFilesAdded}>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".jpg, .png, application/pdf"
                        multiple={multiple}
                        name={id}
                        id={id}
                        required={is_required ? "required" : ''}
                        ref={(file) => setRefInput(file)}
                        onChange={onFilesAdded} />
                    <PrimaryButtonComponent
                        disabled={disabled ? disabled : false}
                        onClick={() => refInput.click()}
                        className={`upload_file__button ${selectedImage?.name.length > 0 ? '_active' : '_empty'} general_flex xs`}
                        type="button"
                    >
                        <div className="upload_file_icon">
                            <span className='hubicon-upload' />
                            {(selectedImage?.name.length > 0 || existingFile?.length > 0)
                                ? <div>{t("registerPlayer.form.btnFileUp")}</div>
                                : <div>{t("registerPlayer.form.btnFile")}</div>
                            }
                        </div>
                    </PrimaryButtonComponent>
                </div>
                : <div className='baloa-username input_file_tournament_Form_label_img'>
                    <PrimaryButtonComponent
                        disabled={true}
                        className={'upload_file__button _active general_flex xs'}
                        type="button"
                    >
                        <div className="upload_file_icon">
                            <span className='hubicon-upload' />
                            <div>{t("registerPlayer.form.btnFile")}</div>
                        </div>
                    </PrimaryButtonComponent>
                    <span className='baloa-username input_file_tournament_label'>{selectedImage?.name}</span>
                    {existingFile?.length > 0 && <span className='baloa-username input_file_tournament_label'>{existingFile?.split(';')[0]}</span>}
                    <TextButtonComponent
                        disabled=''
                        className="invite_teams_league-addto__delete-button baloa-hyperlink-2"
                        onClick={() => handleCleanFile()}
                        type={'button'}
                    >
                        <span className="icon-x input_file_tournament_Form_label_img-delete_file" />
                    </TextButtonComponent>   
                </div>
                }
            </div>
            {(is_required && selectedImage === null && existingFile === null) && <label className={"baloa-username input-fiel_message_error"} >{t("registerPlayer.form.labelErrorFile")}</label>}
        </div>
    )
};

export default withTranslation('tournament')(FileTournamentFormComponent);