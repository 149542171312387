// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-id-viewer-stepper__buttons__button {
    background: #D9D9D9;
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.card-id-viewer-stepper__buttons__button.text-button {
    color: var(--black-color);
    padding: 0;
}

.card-id-viewer-stepper__content .MuiMobileStepper-root {
    background: transparent;
    color: var(--white-color);
    justify-content: center;
    gap: 19px;
}`, "",{"version":3,"sources":["webpack://./src/app/cards/components/card-id-viewer-stepper/CardIdViewerStepperComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,uBAAuB;IACvB,SAAS;AACb","sourcesContent":[".card-id-viewer-stepper__buttons__button {\n    background: #D9D9D9;\n    width: 24px;\n    height: 24px;\n    border-radius: 4px;\n}\n\n.card-id-viewer-stepper__buttons__button.text-button {\n    color: var(--black-color);\n    padding: 0;\n}\n\n.card-id-viewer-stepper__content .MuiMobileStepper-root {\n    background: transparent;\n    color: var(--white-color);\n    justify-content: center;\n    gap: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
