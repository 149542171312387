// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentDynamicFormsPreviewComponent from '../tournament-dynamic-forms-preview/TournamentDynamicFormsPreviewComponent';

// Styles
import './TournamentDynamicFormsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentDynamicFormsModalComponent( props ) {

     return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={ () => props.onClose() }
            title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.modalTitle')}
            className="tournament-dynamic-forms-modal"
        >
            <div class="tournament-dynamic-forms-modal__container">            
                <TournamentDynamicFormsPreviewComponent 
                    questions={props.questions}
                />                
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentDynamicFormsModalComponent);