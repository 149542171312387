// React
import React from "react";

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import "./PageTournamentTeamsListComponent.styles.css";

//i18
import { withTranslation } from "react-i18next";

function PageTournamentTeamsListComponent(props) {
    
    return (
        <div className='page-tournament-teams-list__container'>
            <div className='page-tournament-teams-list__title baloa-subtitle-2'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.enrolledTeamsLabel')}</div>
            {props.tournament_teams?.map((team, index) =>
                ( (team.team_state === "Enrolled" || team.team_state === "Register") &&
                    <div key={index} className='page-tournament-teams-list' onClick={() => props.handleTeamMembersModal(team)}>
                        <div key={index} className='page-tournament-teams-list__image'>
                            { team.team_logo?
                                <img src={team.team_logo} alt={team.team} />
                            :
                                <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                            }
                        </div>
                        <div className='page-tournament-teams-list__data'>
                            <div className='baloa-names'>{team.team}</div>
                            <div className='baloa-username'>
                                {team.team_gender? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.'+team.team_gender):team.team_gender}
                                &nbsp;&bull;&nbsp;{team.team_category? props.t('options.tournaments.pages.newTournament.tabs.tabTeams.'+ team.team_category):''}</div>
                        </div>
                    </div>
                )
            )}
        </div>
	);
}

export default withTranslation("league")(PageTournamentTeamsListComponent);
