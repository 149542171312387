// Redux
import {combineReducers} from 'redux';

// Reducers
import SignUpReducer            from 'app/authentication/reducers/SignUpReducer';
import SignUpErrorReducer       from 'app/authentication/reducers/SignUpErrorReducer';
import UsersReducer             from 'app/authentication/reducers/UsersReducer';
import HomeReducer              from 'app/home/reducers/HomeReducer';
import ProfileReducer           from 'app/profiles/reducers/ProfileReducer';
import ConstantsProfileReducer  from 'app/profiles/reducers/ConstantsProfileReducer';
import PageReducer              from 'app/pages/reducers/PageReducer';
import PageGroupsReducer        from 'app/pages/reducers/PageGroupsReducer';
import PostReducer              from 'app/posts/reducers/PostReducer';
import SearchReducer            from 'app/search/reducers/SearchReducer';
import NotificationsReducer     from 'app/notifications/reducers/NotificationsReducer';
import AttributesReducer        from 'app/generalattributes/reducers/AttributesReducer';
import LineUpsReducer           from 'app/generallineups/reducers/LineUpsReducer';
import UserSettingsReducer       from 'app/user-settings/reducers/UserSettingsReducer';
import ConstantsSingUpReducer   from  'app/authentication/reducers/ConstantsSingUpReducer';
import LeagueReducer            from 'app/leagues/reducers/LeagueReducer';
import LeagueTournamentReducer  from 'app/leagues/reducers/LeagueTournamentReducer';
import LeagueMatchReducer       from 'app/leagues/reducers/LeagueMatchReducer';
import TeamsReducer             from 'app/leagues/reducers/TeamsReducer';
import ClickstreamReducer       from 'app/clickstream/reducers/ClickstreamReducer';
import TournamentReducer        from 'app/tournaments/reducers/TournamentReducer';
import PaymentsReducer          from 'app/payments/reducers/PaymentsReducer';




export default combineReducers({
    
    // User
    user: UsersReducer,

    // Home
    home: HomeReducer,
    
    // Authentication
    signup: SignUpReducer,
    signup_error: SignUpErrorReducer,
    constantsSingup: ConstantsSingUpReducer,
    
    // Profile
    profile: ProfileReducer,
    constantsProfile: ConstantsProfileReducer,
    
    // Attributes
    attributes: AttributesReducer,

    // LineUps
    lineups: LineUpsReducer,

    // Page
    page: PageReducer,

    // Post
    post: PostReducer,

    // Search
    search: SearchReducer,

    // Notifications
    notifications: NotificationsReducer,

    // UserSetting
    userSettings: UserSettingsReducer,

    //Leagues
    leagues: LeagueReducer,

    //League Tournaments
    leagueTournaments: LeagueTournamentReducer,

    //League Matches
    leagueMatches: LeagueMatchReducer,

    //Pages Groups
    pageGroups: PageGroupsReducer,

    //teams
    teams: TeamsReducer,

    //clickstream
    clickstream: ClickstreamReducer,

    //tournaments
    tournaments: TournamentReducer,

    //payments
    payments: PaymentsReducer,

})
