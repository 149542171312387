// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './TournamentFinishGroupsPhaseModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Assets
import team_shield_icon from 'assets/icons/team-shield.svg';

function TournamentFinishGroupsPhaseModalComponent  (props) {
    
    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndGroupPhase.labelTitle')}
            className="finish_groups_phase medium"
        >
            <ul className='teams_groups_list scroll'>   
                { 
                    props.teams_classified_by_phases.map( (teams, index) => {
                        return(
                            <li>
                                <h1 className='baloa-subtitle-1 teams_groups_list_title'>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndGroupPhase.labelTitle')} {teams.group_name}</h1>
                                {
                                    <ul className='teams-list'>
                                        {
                                            teams.classified_teams.map( (team) => {
                                                return (
                                                    <li className='baloa-body-2'>
                                                        {/* <span className='icon-team' /> */}
                                                        <img src={ team.photo ? team.photo : team_shield_icon} alt="" className="teams-list__photo"/>
                                                        {team.name}
                                                    </li>
                                                    
                                                )
                                            })
                                        }
                                    </ul>
                                } 
                            </li>
                        )
                    })
                }
            </ul>
            <div className="finish_groups_phase_footer">
                <SecondaryButtonComponent 
                    className='finish_groups_phase_btn'
                    onClick={ props.onClose }
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndGroupPhase.labelBackAction')}                    
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    className='finish_groups_phase_btn'
                    onClick={() => {
                        props.finishTournamentPhase(props.phase_id);
                        props.onClose();
                    }}
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndGroupPhase.labelAction')}
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentFinishGroupsPhaseModalComponent);