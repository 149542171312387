/*eslint eqeqeq: "off"*/
// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';

// Styles
import './SignupUserNamePanelComponent.styles.css';

// Actions
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { sendUserDataAction, createNewUsernameWithPhotoAction } from 'app/authentication/actions/LoginActions';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Validators
import { UserNameValidator } from 'shared/validators/SignUpValidator';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';
class SignupFormUserNameComponent extends Component {

    constructor(props){
        super(props);
        this.state = { 
            disabled: false,
            username: this.props.state.user?.me?.username? this.props.state.user.me.username : '',
            new_user_image_modal: false,
            user_image: null,
            user_img_url: null,
            user_color_icon: false,
        };
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    resetError(){
        const data = {
            'field':'username',
            'error':''
        }
        this.props.changeSignUpError(data);  
    }

    changeUsername(uname){
        if(uname != this.state.username){
            this.setState({username: uname});
        }    
    }

   handleNewUserImageModal(new_state) {
       this.setState({user_random_color: null});
        this.setState({ new_user_image_modal: new_state });
    }

    setUserImage(user_image, user_image_url) {
        this.setState({user_image: user_image});
        this.setState({user_img_url: user_image_url});
    }

    sendUsernameWithPhoto(username) {  
        const form_data = new FormData();
        if(this.state.user_image !== null){
            form_data.append('photo', this.state.user_image, this.state.user_image.name );
        } else {
            this.setState({user_color_icon: true})
        }        
        form_data.append('username', username);
        this.props.createNewUsernameWithPhotoAction( form_data, this.props.state.user.me.username );
    }

    render = () => {
        const formInitialValues = {
            username: this.props.state.user?.me?.username? this.props.state.user.me.username : '',
            photo: null
        }
        return (
            <React.Fragment>
                <div className="signup-username-panel__container">
                    <div className="signup-username-panel__container__left-col"></div>
                    <div className="signup-username-panel__container__right-col">
                        <div className="signup-username-panel__container__title">
                            <span className="icon-arrow-left" onClick={()=> {this.props.changeSignUpStatus( SIGNUP_STATUS['FORM'] )}} />
                            <h2 className="signup-username-panel__title">{this.props.t('info.profileInfo')}</h2>
                            <div></div>
                        </div>
                        <div className="signup-username-panel__container__upload-img">
                            <label className="signup-username-panel__label">{this.props.t('info.profilePhoto')}</label>
                            <div className="signup-username-panel__container__upload-img__img">
                                { this.state.user_img_url === null && this.state.user_color_icon !== true && <span className="icon-camera"/>}
                                { this.state.user_img_url === null && this.state.user_color_icon === true && <span className="icon-ball"/>}
                                { this.state.user_img_url !== null && <img src={this.state.user_img_url} alt="user-img"/>}                                
                            </div>
                            <PrimaryButtonComponent onClick={()=>{this.handleNewUserImageModal(true)}} className="signup-username-panel__container__upload-img__upload-btn">Subir imagen</PrimaryButtonComponent>
                        </div>
                        <FormComponent 
                            formInitialValues={formInitialValues} 
                            onSubmit={( values ) => {
                                this.sendUsernameWithPhoto(values.username);
                            }}
                            className="signup-username-panel__form"
                            functionDisabled={this.disabled.bind(this)}
                        >
                            <label className="signup-username-panel__label">{this.props.t('username.inputLabel')}</label>
                            <InputFieldComponent
                                input_id="user_name-name" 
                                field_name="username"
                                validationFunction={ UserNameValidator }
                                disabled={false}
                                className="signup-username-panel__form__field"
                                input_type="text"
                                resetError={()=>this.resetError()}
                                error={ this.props.state.signup_error.error }
                                message=""
                                onChange = { (uname) => { this.changeUsername( uname ) } }
                                //value = { this.state.username }
                                placeholder="@"
                            />
                            <p className="signup-username-panel__form__tip">{this.props.t('username.tipDescription')}</p>
                            <p className="signup-username-panel__form__tip">{this.props.t('username.tipExample')}</p>
                            <PrimaryButtonComponent disabled={!this.state.disabled} onClick={()=>{}} className="signup-username-panel__form__submit">
                                <span>{this.props.t('info.button')}</span>
                            </PrimaryButtonComponent>
                            <LeagueTeamUploadImageModalComponent
                                isOpen = { this.state.new_user_image_modal}
                                onClose= { ()=>{ this.handleNewUserImageModal(false) } }
                                setTeamImage={ (new_image, imageurl, img_b64)=>{ this.setUserImage(new_image, imageurl, img_b64) } }
                                aspect = {1}
                                cropImage={true}
                            />
                        </FormComponent>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUserDataAction: ( values, actions, username ) => {
            return dispatch( sendUserDataAction( values, actions, username ));
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values));
        },
        createNewUsernameWithPhotoAction: (formData, username) => {
            return dispatch( createNewUsernameWithPhotoAction(formData, username) );
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignupFormUserNameComponent);