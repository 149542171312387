// React
import React from 'react';

// Components

// Styles
import './TablePagination.styles.css';


function TablePaginationActions(props) {
    
  const { count, page, rowsPerPage, onChangePage } = props;

  const handlePageButtonClick = (event) => {      

    for(var i = 0; i < event.target.parentElement.parentElement.children.length; i++){
      event.target.parentElement.parentElement.children[i].children[0].className = "inactive";        
    }

    event.target.className = "active";

    onChangePage(event);
  };

  const items = [];

    for (var i = 0; i < (count / rowsPerPage); i++) {      
      if(i === page){
        items.push(<li key={i}><a onClick= { handlePageButtonClick } className = "active">{i + 1}</a></li>)  // eslint-disable-line
      }else{
        items.push(<li key={i}><a onClick= { handlePageButtonClick } className = "inactive">{i + 1}</a></li>) // eslint-disable-line
      }    
    } 

  return (
      
      <section class="table-pagination">
        <ul>
          {items}
        </ul>
      </section>

  );
}

export default TablePaginationActions;