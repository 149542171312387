// React
import React, { useState } from 'react';

// Material UI
//import { Popover, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Components
import LeagueListTeamMembersComponent from 'app/leagues/components/league-list-team-members/LeagueListTeamMembersComponent';
import LeagueListTeamTournamentsComponent from 'app/leagues/components/league-list-team-tournaments/LeagueListTeamTournamentsComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './LeagueShowTeamComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Assets
//import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';


function TabPanel( props ) {
    const { children, value, index, ...other } = props;

    return(
        <div
            role="tabpanel"
            hidden={ value !== index }
            id={ `simple-tabpanel-${index}` }
            aria-labelledby={ `simple-tab-${index}` }
            { ...other }
        >
            {/* tab tournament */}
            { (value === index) && (index === 0) && (
                <React.Fragment>
                    <LeagueListTeamTournamentsComponent
                        tournaments = { props.tournaments }
                        handleViewTournament={ props.handleViewTournament }
                        league_page = {props.league_page}
                        tournament_tag = {props.tournament_tag}
                        handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                    />
                </React.Fragment>   
            )}

            {/* tab members */}
            { (value === index) && (index === 1) && (                    
                <React.Fragment>
                    <LeagueListTeamMembersComponent
                        members = { props.members }
                        handleOnMemberActionClick={ props.handleOnMemberActionClick }
                        handleOnAddButtonClick={()=>props.handleOnAddButtonClick(true)}
                        handleAddBulkMembers={ props.handleAddBulkMembers }
                        handleOnEditMemberActionClick={ props.handleOnEditMemberActionClick }
                        handleOnDeleteMemberActionClick={ props.handleOnDeleteMemberActionClick }
                    />
                </React.Fragment>   
            )}

            {/* tab members */}
            { (value === index)  && (index === 2) && (
                <React.Fragment>
                    {"tab titulos"}
                </React.Fragment>
            )}
        </div>
    )
}

function LeagueShowTeamComponent ( props ) {
    const [tabSelected, setTabSelected] = useState(props.setTabTeams? 1 : 0);
    //const [anchorEl, setAnchorEl] = React.useState(null);
    const [ boxPosition, setBoxPosition ] = React.useState(false);
    const [ isBoxOpen, setBoxOpen ] = React.useState(false);
    const [ editTeam, setEditTeam ] = React.useState(false);

    /* const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        props.handleCopyCode();
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };  */   

    //const open = Boolean(anchorEl);
    //const id = open ? 'team-code-copy-popover' : undefined;

    const handleBoxOpen = (index) => {
        if (!isBoxOpen){
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    }

    const handleOptionBtn = (state) => {
        setEditTeam(!state);
    }

    return(
        <React.Fragment>
        <div className="league-show-team__title">
            <span className='icon-arrow-left show-team__icon-back' onClick={()=>{props.handleOnBackButton()}} />
            <div className="baloa-headline-2">{props.t("league:options.tournaments.pages.tournamentInfo.labelTeamDetailTitle")}</div>
        </div>
        <div className="league-show-team__container">
            <div className="new-tournament__center">
                <div className="league-show-team__padding__white">
                    {/* <span className='icon-arrow-left show-team__icon-back' onClick={()=>{props.handleOnBackButton()}}></span> */}
                    <div className="league-show-team__center__header">
                        <div className="league-show-team__center__header__container">
                            <div className="show-team__team-photo">
                                {props.team.photo?
                                    <img src={props.team.photo} alt=""/>
                                    :
                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                }
                            </div>
                            <div className="show-team__team-data">
                                <div className="baloa-headline-2">{props.team.name}</div>
                                <div className="show-team__team-data__label baloa-table">@{props.team.teamname }</div>
                                <div className="league-show-team__gender-category baloa-table">
                                    <div className="show-team__team-data__label">{ props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${props.team.team_gender}`) }</div>
                                    &nbsp;&bull;&nbsp;
                                    {/* <div className="show-team__team-data__label">
                                        {(Array.isArray(props.team.attributes) && props.team.attributes.length > 0)?
                                            props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + props.team.attributes[0].extra.value) : ''}
                                    </div> */}
                                    <div className="show-team__team-data__label">
                                        {props.team.category ? props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + props.team.category) : ''}
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                        <div className='show-team__header__button-options'>
                            <SecondaryButtonComponent
                                onClick={()=>{handleBoxOpen(true)}}
                                className={'show-team__header__button-options__button small'}>
                                <span>
                                    {props.t('team.tabs.members.addMemberButtonLabel')}                                
                                </span>
                                <span className="icon hubicon-person_add"></span>
                            </SecondaryButtonComponent>
                            <div className={`show-team__header__button-options__box ${ boxPosition ? "active" : ""}`} >
                                
                                <div className='show-team__header__button-options__box__box-option' 
                                    onClick={()=> {
                                        props.handleOnAddButtonClick(true);
                                        handleBoxOpen(false);
                                    }}>
                                    <div className='baloa-names'>{props.t('newTeam.forms.players.multiButton.addMember')}</div>
                                    <div className={"baloa-username"} >{props.t('newTeam.forms.players.multiButton.addMemberDesc')}</div>
                                </div>
                                
                                <div className='show-team__header__button-options__box__box-option' 
                                    onClick={()=> {
                                        props.handleAddBulkMembers();
                                        handleBoxOpen(false);
                                    }}>
                                    <div className='baloa-names'>{props.t('newTeam.forms.players.multiButton.addBulkMember')}</div>
                                    <div className={"baloa-username"}>{props.t('newTeam.forms.players.multiButton.addBulkMemberDesc')}</div>
                                </div>

                                <div className='show-team__header__button-options__box__box-option' 
                                    onClick={()=> {
                                        props.handleOnShareTeamPageInscription()
                                        handleBoxOpen(false);
                                    }}>
                                    <div className='baloa-names'>{props.t('newTeam.forms.players.multiButton.invitePlayer')}</div>
                                    <div className={"baloa-username"}>{props.t('newTeam.forms.players.multiButton.invitePlayerDesc')}</div>
                                </div>
                            </div>
                            { editTeam &&
                                <div className='show-team__team-options' onClick={ () =>{ props.handleEditTeam() } } >
                                    <span className='hubicon-edit' /> 
                                    <div className='baloa-table-column'>{props.t("team:team.edit.editTitle")}</div>                                                                   
                                </div>
                            }
                            <span className="icon-options" onClick={ () => handleOptionBtn(editTeam)} />
                        </div>
                        
                        {/* <IconEdit className="show-team__icon-edit" onClick={()=>{ props.handleEditTeam()}} /> */}
                    </div>
                    {/* Se comentarea ysa que se creee que este codigo ya no se va a necesitar
                    <div className="show-team__team-actions">
                        <div class="show-team__team-code">
                            <div style={{marginRight:8}} className="show-team__team-data__label"> {props.t("league:options.tournaments.pages.tournamentInfo.labelTeamCodeTitle")}: <strong>{props.team.team_code}</strong></div>
                            <span class="icon-copy-86" onClick={handleClick}></span>
                        </div>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                    >
                        <Typography sx={{ p: 1 }}>{ props.t("teams.teamsCopyCode")}</Typography>
                    </Popover> 
                    */}

                    
                </div>
                
                <div className="show-team__center__content">
                    <Tabs
                        variant="fullWidth"
                        className="new-tournament__center__content__tabs"
                        value={ tabSelected }
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={ ( ev, new_value ) => { setTabSelected( new_value ) } }
                        aria-label="Selection between "
                    >                        
                        <Tab 
                            label={props.t('team.tabs.tournaments.tournamentTabLabel')} 
                            onClick={()=>{props.cleanTeamTab()} }
                        />
                        
                        <Tab 
                            label={props.t('team.tabs.members.memeberTabLabel')} 
                        /> 

                        {/*<Tab                             
                            label={props.t('team.tabs.members.titleTabLabel')} 
                        />*/}                                                                
                        
                    </Tabs>

                    {/* tab tournaments */}
                    {/* errors not necesary but mandatory */}
                    <TabPanel
                        index={ 0 } 
                        value={ tabSelected } 
                        tournaments = { props.tournaments }
                        handleViewTournament={ props.handleViewTournament }
                        league_page = {props.league_page}
                        tournament_tag = {props.tournament_tag}
                        handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                    />

                    {/* tab members */}
                    <TabPanel 
                        index={ 1 } 
                        value={ tabSelected }    
                        members = { props.members }      
                        handleOnMemberActionClick={ props.handleOnMemberActionClick }  
                        handleOnAddButtonClick={()=>props.handleOnAddButtonClick(true)}
                        handleAddBulkMembers={ props.handleAddBulkMembers }
                        handleOnEditMemberActionClick={ props.handleOnEditMemberActionClick }
                        handleOnDeleteMemberActionClick={ props.handleOnDeleteMemberActionClick }         
                    />

                    {/* tab titulos */}
                    <TabPanel 
                        index={ 2 } 
                        value={ tabSelected }                         
                    />
                    
                </div>

            </div>
            
        </div>
        </React.Fragment>
    )
}

export default withTranslation(['team', 'league'])(LeagueShowTeamComponent)
