// React
import React from 'react';

// Components
import HubTournamentCardComponent from '../hub-tournament-card/HubTournamentCardComponent';

// Styles
import './HubTournamentSectionCardComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HubTournamentSectionCardComponent(props) {

    const [active_tournament, setActiveTournament] = React.useState(undefined);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        // Need more tournaments?
        if (props.is_subsection && props.section_data && active_tournament === props.section_data[props.section_data.length - 1]) {
            props.handleOnLoadMoreTournaments(props.section_data?.subsection);
        }
    }, [active_tournament]); // eslint-disable-line

    const [scrollX, setscrollX] = React.useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = React.useState(false); // For detecting end of scrolling
    const scrl = React.useRef();
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    React.useEffect(() => {
        //Check width of the scollings
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
    
        //For checking if the scroll has ended
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const containerList = document.getElementsByClassName('hub-tournament-card__tournament-info__name');
    //console.log('containerList',containerList);
    for (let i = 0; i < containerList.length; i++) {
        const container = containerList[i];
        //const containerWidth = container.offsetWidth;
        //console.log('containerWidth',containerWidth);
        const text = container.textContent.trim();
        //console.log('text',text);
        //const charWidth = parseInt(getComputedStyle(container).fontSize); // Ancho estimado de cada carácter
        //console.log('charWidth',charWidth);

        //const maxChars = Math.floor(containerWidth / charWidth); // Establece el número máximo de caracteres por línea
        const maxChars = 20;
        //console.log('maxChars',maxChars);
        const lineCount = 3; // Establece el número de líneas después del cual se agregarán los puntos suspensivos

        if (text.length > maxChars * lineCount) {
            let truncatedText = text.slice(0, ((maxChars * lineCount) - 3)) + '...'; // Trunca el texto en función del número de caracteres por línea y agrega los puntos suspensivos
            
            // Encuentra el último espacio en el texto truncado
            const lastSpaceIndex = truncatedText.lastIndexOf(' ');

            // Vuelve a truncar el texto en el último espacio
            truncatedText = truncatedText.slice(0, lastSpaceIndex);

            // Agrega los puntos suspensivos al final del texto truncado
            truncatedText += '...';

            container.textContent = truncatedText;
        }
    }

    return (
        <div className='hub-tournament-section-card__container' >
            {!props.is_subsection &&
                <div className='hub-tournament-section-card__section' >
                    <div className='baloa-subtitle-1'>{props.t(`tournamentHubSections.${props.section_data?.subsection}`)}</div>
                    <div className='baloa-normal-medium' onClick={() => { props.handleOnSubsectionViewMore(props.section_data?.subsection) }}>{props.t(`tournamentHubSections.viewMore`)}</div>
                </div>
            }
            {props.is_subsection && !props.is_filterSection &&
                <div className='hub-tournament-section-card__section__subsection-title' >
                    {!props.is_path && <span className='icon-arrow-left' onClick={() => props.handleBackTournamentViewMore()} />}
                    <div className='baloa-headline-2'>{props.t(`tournamentHubSections.${props.tournament_subsection}`)}</div>

                </div>
            }
            {!isMobile && /* (props.section_data?.subsection === 'tournament_participate' || props.section_data?.subsection === 'tournament_admin') && */ /* props.section_data?.subsection !== 'tournament_follow' && */ scrollX !== 0 &&                
                <div class={`hub-tournament-section-card__section__subsection-arrow prev ${props.section_data?.subsection === 'tournament_follow'? 'follow-view' : ''}`} onClick={() => slide(-580)}>
                    <span class="hubicon-arrow_circle_left">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>
            }

            {!isMobile && /* (props.section_data?.subsection === 'tournament_participate' || props.section_data?.subsection === 'tournament_admin') && */ /* props.section_data?.subsection !== 'tournament_follow' && */ !scrolEnd &&                    
                <div class={`hub-tournament-section-card__section__subsection-arrow next ${props.section_data?.subsection === 'tournament_follow'? 'follow-view' : ''}`} onClick={() => slide(+580)}>
                    <span class="hubicon-arrow_circle_right">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>                    
            } 
            
            <div className={`hub-tournament-section-card__cards-container ${props.is_subsection ? 'hub-tournament-section-card__cards-container__subsection' : (!props.is_subsection && (props.section_data?.subsection === 'tournament_participate' || props.section_data?.subsection === 'tournament_admin'))? 'long-view' : (props.section_data?.subsection === 'tournament_follow')? 'follow-view' : ''}`} ref={ scrl } onScroll={ scrollCheck }>                                                
                {!props.is_subsection
                    ? props.section_data.tournaments?.map((tournament, index) => (
                        <HubTournamentCardComponent
                            key={index}
                            tournament={tournament}
                            subsection={props.section_data?.subsection}
                            handleOnViewTournament={props.handleOnViewTournament}
                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            handleOnRejectBtn={props.handleOnRejectBtn}
                            handleIgnoreBtn={props.handleIgnoreBtn}
                            onEnterViewport={() => { }}
                        />
                    ))
                    : 
                    props.section_data?.map((tournament, index) => (
                        <HubTournamentCardComponent
                            key={index}
                            tournament={tournament}
                            subsection={props.tournament_subsection}
                            handleOnViewTournament={props.handleOnViewTournament}
                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            handleOnRejectBtn={props.handleOnRejectBtn}
                            handleIgnoreBtn={props.handleIgnoreBtn}
                            onEnterViewport={() => {
                                setActiveTournament(tournament);
                            }}
                            onExitViewport={() => { }}
                            is_filterSection={props.is_filterSection}
                            is_subsection = { props.is_subsection }
                        />
                    ))
                }
                               
            </div>
            
            
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentSectionCardComponent)