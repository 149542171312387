// React
import React from 'react';

//Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
import TournamentFixtureAvialableDatesComponent from 'app/leagues/components/tournament-fixture-available-dates/TournamentFixtureAvialableDatesComponent';
import TournamentFixtureLockedDatesComponent from 'app/leagues/components/tournament-fixture-locked-days/TournamentFixtureLockedDatesComponent';

//Material
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFixtureFieldComponent.styles.css';

function TournamentFixtureFieldComponent(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleFieldsOptionsList = ( event ) => {
        setAnchorEl(event.currentTarget);
    }

    const handleEditField = ( action_type, field ) => {
        props.handleFieldsAdminActions( action_type, field );
        handleClose();
    }

    const handleDeleteFields  = ( action_type, field ) => {
        props.handleFieldsAdminActions( action_type, field );
        handleClose();
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    

    return (
        <div className='tournament-fixture-field__container'>
            <div className='tournament-fixture-field__field'>
                <span className='hubicon-stadium'/>
                <div className='baloa-table-column'>{props.field.field.name}</div>
                <span className='hubicon-edit' aria-controls={`simple-menu_${props.index}`} onClick={ handleFieldsOptionsList } />
                <Menu
                    id={`simple-menu_${props.index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className='tournament-fixture-field__field-options'
                >
                    <MenuItem onClick={ () => handleEditField('edit-page-field', props.field?.field) }><div className='baloa-names'>{ props.t('options.pageFields.labelInputEdit') }</div></MenuItem>
                    <MenuItem onClick={ () => handleDeleteFields('delete-tournament-field', props.field) }><div className='baloa-names'>{ props.t('options.pageFields.labelRemoveField') }</div></MenuItem>
                </Menu>
            </div>
            {props.assignDays &&    
                <React.Fragment>                                                                                
                    <TournamentFixtureAvialableDatesComponent
                        fixtureAvailableDays={props.fieldsFixtureAvailableDays}
                        handleOnDeleteFixtureAvailableDay={( tournament_field_day )=>{ props.handleOnDeleteFieldFixtureAvailableDay(tournament_field_day, props.field.id) }}
                        handleOnAddAvailableDates={ ()=>{ props.handleOnFieldAddAvailableDates(props.field.id) } }
                    />                                                
                    <TournamentFixtureLockedDatesComponent
                        fixture_locked_days={props.field_fixture_locked_days}
                        handleOnDeleteFixtureLockedDay={props.handleOnDeleteFieldFixtureLockedDay}
                        handleOnAddBlockDates={ ()=>{ props.handleOnFieldAddBlockDates(props.field.id) } }
                    />
                </React.Fragment>
            }
            <div className='tournament-fixture-field__teams'>
                <div className='tournament-fixture-field__teams-title'>
                    <span className='hubicon-shield'/>
                    <div className='baloa-table-column'>{props.t('options.tournaments.automaticFixturesModal.teamFieldTitle')}</div>                    
                </div>
                <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.teamFieldMsg')}</div>
            
                <div className='tournament-fixture-field__teams-container'>
                    {props.fixture_field_teams?.length > 0 ?
                        props.fixture_field_teams?.map(team => 
                            <div className='tournament-fixture-field__teams-tag'>
                                <div className='tournament-fixture-field__teams-tag__date-info'>
                                    {team.team.logo &&
                                        <img src={ team.team.logo } alt=""/>
                                    }
                                    {!team.team.logo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    } 
                                    <div className='baloa-username'>{team.team.name}</div>
                                </div>
                                <span className='hubicon-clear_option tournament-fixture-field__teams-tag__button' onClick={()=>{ props.handleOnDeleteFieldTeam(team.id)}} />
                            </div>
                        )
                        :
                        ''
                    }
                    <RoundedButtonComponent
                        className="tournament-fixture-field__teams__button small"
                        type='button'
                        onClick={ ()=>{ props.handleOnAddTeamField(props.field.id) } }
                    >
                        <span className='hubicon-add_option icon'></span>
                        <span>{props.t('options.tournaments.automaticFixturesModal.addTeamFieldBtnLbl')}</span>
                    </RoundedButtonComponent>
                </div>
            </div>
        </div>
    )
}

export default  withTranslation('league')(TournamentFixtureFieldComponent);