/*eslint eqeqeq: "off"*/
// React
import React from "react";

// Components
import FormComponent from "shared/components/form/FormComponent";
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";
import LeagueTeamUploadImageModalComponent from "../league-team-upload-image-modal/LeagueTeamUploadImageModalComponent";

// Styles
import "./LeagueEditTeamFormComponent.styles.css";

// i18n
import { withTranslation } from "react-i18next";

// Validators
import { RequiredValidator } from "shared/validators/RequiredValidator";
import { UserNameValidator } from "shared/validators/SignUpValidator";
import SelectFieldComponent from "shared/components/select-field/SelectFieldComponent";

function LeagueEditTeamFormComponent(props) {
   //console.log("props: "+JSON.stringify(props));


	const [state, setState] = React.useState({
		edit_image_modal: false,
	});

	const [teamImage, setStateTeamImage] = React.useState(null);
	const [teamImageUrl, setStateTeamImageUrl] = React.useState(
		props.team.photo ? props.team.photo : null
	);
	const [teamImageB64, setStateTeamImageB64] = React.useState();
	const [teamNameValue, setTeamNameValue] = React.useState(props.team.name);

   //const extra = props.team?.attributes[0]?.extra;

   const {handleNameOnChange, handleTeamGenderOnChange, handleCategoryOnChange, handleTeamImageOnChange, handleTeamImageB64OnChange} = props;


	const formInitialValues = {
		teamname: props.team.teamname,
		name: props.team.name,
		team_gender: props.team.team_gender,
		category: props.team.category,
	};

	const handleEditTeamImageModal = (new_state) => {
		setState({ edit_image_modal: new_state });
	};

	const setTeamImage = (team_image, team_image_url, img_b64) => {
		setStateTeamImage(team_image);
		setStateTeamImageUrl(team_image_url);
		setStateTeamImageB64(img_b64);
		handleTeamImageOnChange(team_image);
		handleTeamImageB64OnChange(img_b64);
	};

	return (

      
		<FormComponent
			//formInitialValues={ formikvalues.initialValues }
			formInitialValues={formInitialValues}
			enableReinitialize={true}
         
			onSubmit={(values, actions) => {
				if (Object.keys(values).length !== 0) {
					props.onEditData(values, teamImage, teamImageB64);
				}
			}}

			className="edit-team-form__form form scroll-parent"
			autocomplete="off"
		>

			<div className="edit-team-form__container edit-team-form__header">
				<div className="edit-team-form__shield">
					{teamImageUrl ? (
						<img src={teamImageUrl} alt="" />
					) : (
						<div>
							<div className="edit-team-form__shield__team-photo-label">
								{props.t("newTeam.forms.basicInfo.pictureTeamLabel")}
							</div>
							<div className="edit-team-form__shield__no-photo-icon">
								<span className="icon-ball icon-team-2-64" />
							</div>
						</div>
					)}
					<div
						className="edit-team-form__shield__new-button"
						onClick={() => {
							handleEditTeamImageModal(true);
						}}
					>
						{props.t("newTeam.forms.basicInfo.uploadPictureLabel")}
					</div>
				</div>
				<div className="edit-team-form__form__content form__fields">
					<InputFieldComponent
						label={props.t("team.edit.forms.basicInfo.teamLabel")}
						input_id="new-page-name"
						field_name="name"
						validationFunction={RequiredValidator}
						disabled={false}
						className="edit-team-form__form__input__name"
						input_type="text"
						resetError={() => props.onResetError({ name: "" })}
						error={props.errors}
						value={teamNameValue}
						onChange={(value) =>{ 
                     	handleNameOnChange(value);
                     	setTeamNameValue(value);    }}

						message=""
						placeholder=""
					/>

					<InputFieldComponent
						label={
							"@ " + props.t("team.edit.forms.basicInfo.teamnameLabel")
						}
						input_id="new-page-pagename"
						field_name="teamname"
						validationFunction={UserNameValidator}
						disabled={true}
						className="edit-team-form__form__input__pagename"
						input_type="text"
						resetError={() => props.onResetError({ teamname: "" })}
						error={props.errors}
						message={props.t("team.edit.forms.basicInfo.teamnameMessage")}
						placeholder=""
						value={props.team.teamname}
					/>

               		<SelectFieldComponent
						label={props.t("team.edit.forms.basicInfo.teamgenderLabel")}
						field_name="team_gender"
						validationFunction={RequiredValidator}
						disabled={false}
						className="new-team-form__form__input"
						resetError={() => props.onResetError({ team_gender: "" })}
						error={props.errors}
						message=""
						placeholder=""
						options={props.team_genders?props.team_genders:[]}
                  		onChange={(value) => handleTeamGenderOnChange(value)}
					/>

                	<SelectFieldComponent
						label={props.t("team.edit.forms.basicInfo.categoryLabel")}
						field_name="category"
						validationFunction={RequiredValidator}
						disabled={false}
						className="new-team-form__form__input"
						resetError={() => props.onResetError({ category: "" })}
						error={props.errors}
						message=""
						placeholder=""
						options={props.team_categories?props.team_categories:[]}
						onChange={(value) => handleCategoryOnChange(value)}
					/>
                
				</div>
			</div>
			<LeagueTeamUploadImageModalComponent
				isOpen={state.edit_image_modal}
				onClose={() => {
					handleEditTeamImageModal(false);
				}}
				setTeamImage={(new_image, imageurl, img_b64) => {
					setTeamImage(new_image, imageurl, img_b64);
				}}
				aspect = {1}
				cropImage={true}
			/>
		</FormComponent>
	);
}

export default withTranslation("team")(LeagueEditTeamFormComponent);
