// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';

// Styles
//import 'shared/components/follower/FollowerComponent.styles.css';
import './ReactionsModalComponent.styles.css';

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';

function TabPanel( props ) {

    const { children, value, index, ...other } = props;

    function handleScroll(e) {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if(props.reactions_next_page !== null){
                props.loadPostReactionsNext( props.reactions_next_page, e.target.id );
            }    
        }
    }

    const handleButton=(reaction)=>{
        if(props.page !== undefined){
            if(props.page?.pagename === reaction.user?.username ){
                return false;
            }
            else{
                return true;
            }
        }else if(props.user_me?.username === reaction.user?.username){
            return false;
        }else{
            return true;
        }
    }

    return (
        <div
            role="tabpanel"
            hidden={ value !== index }
            id={ `simple-tabpanel-${value}` }
            aria-labelledby={ `simple-tab-${value}` }
            { ...other }
        >
            
            {/* All reactions */}
            { value === 0 && (
                <div className="scroll-parent" onScroll={ handleScroll }>
                    <div className="reactions scroll" id="All">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Clap reactions */}
            { value === 1 && (
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Clap">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Clap');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Clap');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Support reactions */}
            { value === 2 && (            
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Support">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" } >
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Support');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Support');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Its funny reactions */}
            { value === 3 && (                
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Its_Funny">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Its_Funny');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Its_Funny');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Red Card funny reactions */}
            { value === 4 && (                
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Red_Card">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Red_Card');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Red_Card');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Sad funny reactions */}
            { value === 5 && (                
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Sad">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Sad');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Sad');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }

            {/* Celebration funny reactions */}
            { value === 6 && (                
                <div className="scroll-parent" onScroll={ props.handleScroll }>
                    <div className="reactions scroll" id="Celebrate">            
                        { props.reactions?.map( reaction => {
                            return (
                            <div key={ reaction.user.username } className="reactions__content">
                                <div className=" reactions__content__box">
                                    <Link to={`/profile/${reaction.user.username}/`} className="reactions__content__box" onClick={()=>{props.handleClose()}}>
                                        <div className={ "reactions__content__box__photo" }>
                                            {reaction.user.photo &&
                                                <img  src={reaction.user.photo} alt="" />
                                            }
                                            {!reaction.user.photo &&
                                                <span className="icon-ball" color={getRandonIconBallColor()}></span> 
                                            }
                                        </div>
                                        <div className="reactions__content__box__info-username">
                                            <p className="baloa-subtitle-1"> 
                                                {reaction.user.name}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="reactions__content__box__button">
                                        
                                        {handleButton(reaction) && 
                                            (reaction.is_following ? 
                                                <RoundedButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.unfollowProfile( {'user':{'username':reaction.user.username}} );
                                                        props.changePostIsFollowing(reaction.user.username);
                                                        props.changePostIsFollowingFromReactionType(reaction.user.username,'Celebrate');
                                                    } }
                                                    inverse={true}
                                                >
                                                    <span>{props.t('reactionsModal.buttonFollowing')}</span>
                                                </RoundedButtonComponent>
                                                :   <RoundedButtonComponent 
                                                        type="button"
                                                        disabled={ false } 
                                                        onClick = {() => { 
                                                            props.followProfile( {'user':{'username':reaction.user.username}} );
                                                            props.changePostIsFollowing(reaction.user.username);
                                                            props.changePostIsFollowingFromReactionType(reaction.user.username,'Celebrate');
                                                        } }                                                    
                                                    >
                                                        <span>{props.t('reactionsModal.buttonFollow')}</span>
                                                    </RoundedButtonComponent>
                                            )
                                        } 
                                    </div>   
                                </div>
                            </div>
                            )
                        }) }
                    </div> 
                </div>
            ) }
    
        </div>
    );
}

function ReactionsModalComponent ( props ) {
    const [tab_selected, setTabSelected] = React.useState(0);

    /* function handleScroll(e) {

        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if(props.reactions_next_page !== null){
                props.loadPostReactionsNext( props.reactions_next_page );
            }    
        }
    } */

    const setTab=(seltab)=>{
        setTabSelected(seltab);
    }

    const handleClose=()=>{
        setTabSelected(0);
        props.onClose();        
    }
    return (

        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={handleClose}
            title={props.t('reactionsModal.title')}
            //className={}
        >
            <React.Fragment>
                <Tabs
                    variant="fullWidth"
                    className="reactions__modal__tabs"
                    value={ tab_selected }
                    indicatorColor="primary"
                    textColor="primary"
                    //onChange={ ( ev, new_value ) => { setTabSelected( new_value ) } }
                    aria-label="Selection between "
                >   
                    <Tab label={<div className="reactions__modal__icon-text">{props.t('reactionsModal.tabs.allTabTitle',{count: props.reactions.count})}</div>} onClick={ () => { setTab( 0 ) } } />
                    {props.reactions.clap_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-clap reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>                            
                        </span>{props.reactions.clap_reactions_count}</div>  } /*label={props.reactions.clap_reactions_count}*/ onClick={ () => { setTab( 1 ); props.loadPostReactionsByType("Clap") } } />
                    }
                    {props.reactions.support_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-support reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                        </span>{props.reactions.support_reactions_count}</div>} /* label={props.reactions.support_reactions_count} */ onClick={ () => { setTab( 2 ); props.loadPostReactionsByType("Support") } } />
                    }
                    {props.reactions.its_funny_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-funny reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>{props.reactions.its_funny_reactions_count}</div>} /* label={props.reactions.its_funny_reactions_count} */ onClick={ () => { setTab( 3 ); props.loadPostReactionsByType("Its_Funny") } }  />
                    }
                    {props.reactions.red_card_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-red-card reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                        </span>{props.reactions.red_card_reactions_count}</div>} /* label={props.reactions.red_card_reactions_count} */ onClick={ () => { setTab( 4 ); props.loadPostReactionsByType("Red_Card") } } />
                    }
                    {props.reactions.sad_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-sad reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>{props.reactions.sad_reactions_count}</div>} /* label={props.reactions.sad_reactions_count} */ onClick={ () => { setTab( 5 ); props.loadPostReactionsByType("Sad") } } />
                    }
                    {props.reactions.celebrate_reactions_count > 0 &&
                        <Tab label={<div className="reactions__modal__icon-text"><span class="iconr-celebrate reactions__modal__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>{props.reactions.celebrate_reactions_count}</div>} /* label={props.reactions.celebrate_reactions_count} */ onClick={ () => { setTab( 6 ); props.loadPostReactionsByType("Celebrate") } } />
                    }
                </Tabs>
                <TabPanel 
                    index={ 0 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions.results }
                    //handleScroll = { (e)=>{ handleScroll(e) } }
                    reactions_next_page = { props.reactions_next_page }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    loadPostReactionsNext = { props.loadPostReactionsNext }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 1 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.clap }
                    reactions_next_page = { props.reactions_by_type.clap_next }                    
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 2 } 
                    value={ tab_selected }
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.support }
                    reactions_next_page = { props.reactions_by_type.support_next }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 3 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.itsfunny }
                    //handleScroll = { (e)=>{ handleScroll(e) } }
                    reactions_next_page = { props.reactions_by_type.itsfunny_next }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 4 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.redcard }
                    reactions_next_page = { props.reactions_by_type.redcard_next }
                    //handleScroll = { (e)=>{ handleScroll(e) } }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 5 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.sad }
                    reactions_next_page = { props.reactions_by_type.sad_next }
                    //handleScroll = { (e)=>{ handleScroll(e) } }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
                <TabPanel 
                    index={ 6 } 
                    value={ tab_selected } 
                    t={props.t}
                    page={ props.page }
                    user_me={ props.user_me }
                    reactions = { props.reactions_by_type.celebrate }
                    reactions_next_page = { props.reactions_by_type.celebrate_next }
                    //handleScroll = { (e)=>{ handleScroll(e) } }
                    followProfile = { props.followProfile }
                    unfollowProfile = { props.unfollowProfile }
                    changePostIsFollowing = { props.changePostIsFollowing }
                    handleClose={props.onClose}
                    changePostIsFollowingFromReactionType = { props.changePostIsFollowingFromReactionType }
                />
            </React.Fragment>
            
                   
        </Modal2Component>
    )
}

export default withTranslation('post')(ReactionsModalComponent);