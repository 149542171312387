// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post__content__tournament{
    width: 70%;
    margin: 0 auto;
}

.post__content__tournament-result__events__buttons {
    display: flex;
}



.post__content__tournament-result__events__buttons:hover {
    cursor: pointer;
}

.post__content__tournament-result__tournament-date {
    margin-top: var(--space-quarter);
} 

.post__content__tournament-result__events__container {
    background-color: white;
    margin-bottom: var(--space-x2);
    border-radius: var(--space-quarter);
    box-shadow: var(--shadow-card-2);
    padding: var(--space-and-half) var(--space)
}

.post__content__tournament-result__events__buttons .events-btns {

    padding: var(--space-quarter) var(--space);
    border-radius: var(--space);
    border: 2px solid var(--basic-gray-color);
    font-size: calc( var(--space-half) + var(--space-quarter));
    font-family: var(--secondary-font);
    font-weight: var(--font-bold);
    margin-top: var(--space-x2);
    margin-bottom: var(--space-x2);
    margin-right: var(--space);
    color: var(--basic-gray-color);
}

.post__content__tournament-result__events__buttons .active {
    border: 2px solid var(--basic-gray-color);
    background-color: var(--basic-gray-color);
    color: var(--white-color);
}


`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-events-component/LeagueEventsComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;;;AAIA;IACI,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,uBAAuB;IACvB,8BAA8B;IAC9B,mCAAmC;IACnC,gCAAgC;IAChC;AACJ;;AAEA;;IAEI,0CAA0C;IAC1C,2BAA2B;IAC3B,yCAAyC;IACzC,0DAA0D;IAC1D,kCAAkC;IAClC,6BAA6B;IAC7B,2BAA2B;IAC3B,8BAA8B;IAC9B,0BAA0B;IAC1B,8BAA8B;AAClC;;AAEA;IACI,yCAAyC;IACzC,yCAAyC;IACzC,yBAAyB;AAC7B","sourcesContent":[".post__content__tournament{\n    width: 70%;\n    margin: 0 auto;\n}\n\n.post__content__tournament-result__events__buttons {\n    display: flex;\n}\n\n\n\n.post__content__tournament-result__events__buttons:hover {\n    cursor: pointer;\n}\n\n.post__content__tournament-result__tournament-date {\n    margin-top: var(--space-quarter);\n} \n\n.post__content__tournament-result__events__container {\n    background-color: white;\n    margin-bottom: var(--space-x2);\n    border-radius: var(--space-quarter);\n    box-shadow: var(--shadow-card-2);\n    padding: var(--space-and-half) var(--space)\n}\n\n.post__content__tournament-result__events__buttons .events-btns {\n\n    padding: var(--space-quarter) var(--space);\n    border-radius: var(--space);\n    border: 2px solid var(--basic-gray-color);\n    font-size: calc( var(--space-half) + var(--space-quarter));\n    font-family: var(--secondary-font);\n    font-weight: var(--font-bold);\n    margin-top: var(--space-x2);\n    margin-bottom: var(--space-x2);\n    margin-right: var(--space);\n    color: var(--basic-gray-color);\n}\n\n.post__content__tournament-result__events__buttons .active {\n    border: 2px solid var(--basic-gray-color);\n    background-color: var(--basic-gray-color);\n    color: var(--white-color);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
