import React from "react";

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import "./ResponsiveHubModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function ResponsiveHubModalComponent(props){
    return (
        <Modal2Component
        isOpen={props.isOpen}
        onClose={props.onClose}
        title=""            
        className='responsive-hub-modal__container'>
            <div className ={"tournament-modal__container-create"}>
                <div className="tournament-modal__container-create_icon hubicon-Frame-1531">                
            </div>
            <div className="tournament-modal__container-create baloa-subtitle-1">
                {props.t('modalsInformation.hubTournamentModal.labelText')}
            </div>
            <div className="tournament-modal__container-create_button">
                <PrimaryButtonComponent
                    input_type=''
                    className='tournament-modal__container-create_button__button'
                    onClick= {props.onClose}
                >
                    <span>{props.t('modalsInformation.hubTournamentModal.confirmationBtn')}</span>
                </PrimaryButtonComponent> 
            </div>
        </div>
    </Modal2Component>
    )
}

export default withTranslation('tournament')(ResponsiveHubModalComponent);