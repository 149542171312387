// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Poll-Quiz.eot?3dcavz", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Poll-Quiz.ttf?3dcavz", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Poll-Quiz.woff?3dcavz", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Poll-Quiz.svg?3dcavz", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#Poll-Quiz" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Poll-Quiz';
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconpq-"], [class*=" iconpq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Poll-Quiz' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconpq-quote:before {
  content: "\\e902";
}
.iconpq-poll:before {
  content: "\\e900";
}
.iconpq-quiz:before {
  content: "\\e901";
}

`, "",{"version":3,"sources":["webpack://./src/assets/fonts/pollquiz.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,6CAAiC;EACjC;;;yDAGqD;EACrD,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,+EAA+E;EAC/E,mCAAmC;EACnC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;;EAEd,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Poll-Quiz';\n  src:  url('Poll-Quiz.eot?3dcavz');\n  src:  url('Poll-Quiz.eot?3dcavz#iefix') format('embedded-opentype'),\n    url('Poll-Quiz.ttf?3dcavz') format('truetype'),\n    url('Poll-Quiz.woff?3dcavz') format('woff'),\n    url('Poll-Quiz.svg?3dcavz#Poll-Quiz') format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n[class^=\"iconpq-\"], [class*=\" iconpq-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'Poll-Quiz' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.iconpq-quote:before {\n  content: \"\\e902\";\n}\n.iconpq-poll:before {\n  content: \"\\e900\";\n}\n.iconpq-quiz:before {\n  content: \"\\e901\";\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
