// React
import React, { useState } from 'react';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import Switch from '@mui/material/Switch';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import TournamentPhaseFixtureResumeComponent from '../tournament-phase-fixture-resume/TournamentPhaseFixtureResumeComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentFixtureAvialableDatesComponent from '../tournament-fixture-available-dates/TournamentFixtureAvialableDatesComponent';
import TournamentFixtureLockedDatesComponent from '../tournament-fixture-locked-days/TournamentFixtureLockedDatesComponent';
import TournamentFixtureFieldComponent from '../tournament-fixture-field/TournamentFixtureFieldComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';

//Validators
import { RequiredValidator, OnlyNumbersRequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './PhaseRoundtripFixtureSelectorModalComponent.styles.css';

function PhaseRoundtripFixtureSelectorModalComponent(props) {   

    const [step2, setStep2] = useState(false);
    const [assignDays, setAssignDays] = React.useState(false);
    const [assignFields, setAssignFields] = React.useState(false);
    const [numberOfPlays, setNumberOfPlays] = useState(1);
    const [timeBGames, setTimeBGames] = useState(null);
    const [timeBGamesHour, setTimeBGamesHour] = useState('00');
    const [timeBGamesMinutes, setTimeBGamesMinutes] = useState('00');
    const [matchDuration, setMatchDuration] = useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [fixtureAvailableDays, setFixtureAvailableDays] = useState([]);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [fieldOptionsOpen, setFieldOptionsOpen] = useState(false);
    const formRef = React.createRef();

    const NUMBER_OF_PLAYS = [
        { value: 1, content: props.t('options.tournaments.automaticFixturesModal.teamPlayTimes1') },
        { value: 2, content: props.t('options.tournaments.automaticFixturesModal.teamPlayTimes2') },
        { value: 3, content: props.t('options.tournaments.automaticFixturesModal.teamPlayTimes3') },
        { value: 4, content: props.t('options.tournaments.automaticFixturesModal.teamPlayTimes4') },
        { value: 5, content: props.t('options.tournaments.automaticFixturesModal.teamPlayTimes5') },
    ];

    const minutes = [
        {value: "00", content: "00"},
        {value: "01", content: "01"},
        {value: "02", content: "02"},
        {value: "03", content: "03"},
        {value: "04", content: "04"},
        {value: "05", content: "05"},
        {value: "06", content: "06"},
        {value: "07", content: "07"},
        {value: "08", content: "08"},
        {value: "09", content: "09"},
        {value: "10", content: "10"},
        {value: "11", content: "11"},
        {value: "12", content: "12"},
        {value: "13", content: "13"},
        {value: "14", content: "14"},
        {value: "15", content: "15"},
        {value: "16", content: "16"},
        {value: "17", content: "17"},
        {value: "18", content: "18"},
        {value: "19", content: "19"},
        {value: "20", content: "20"},
        {value: "21", content: "21"},
        {value: "22", content: "22"},
        {value: "23", content: "23"},
        {value: "24", content: "24"},
        {value: "25", content: "25"},
        {value: "26", content: "26"},
        {value: "27", content: "27"},
        {value: "28", content: "28"},
        {value: "29", content: "29"},
        {value: "30", content: "30"},
        {value: "31", content: "31"},
        {value: "32", content: "32"},
        {value: "33", content: "33"},
        {value: "34", content: "34"},
        {value: "35", content: "35"},
        {value: "36", content: "36"},
        {value: "37", content: "37"},
        {value: "38", content: "38"},
        {value: "39", content: "39"},
        {value: "40", content: "40"},
        {value: "41", content: "41"},
        {value: "42", content: "42"},
        {value: "43", content: "43"},
        {value: "44", content: "44"},
        {value: "45", content: "45"},
        {value: "46", content: "46"},
        {value: "47", content: "47"},
        {value: "48", content: "48"},
        {value: "49", content: "49"},
        {value: "50", content: "50"},
        {value: "51", content: "51"},
        {value: "52", content: "52"},
        {value: "53", content: "53"},
        {value: "54", content: "54"},
        {value: "55", content: "55"},
        {value: "56", content: "56"},
        {value: "57", content: "57"},
        {value: "58", content: "58"},
        {value: "59", content: "59"},
    ];

    const hours = [
        {value: "00", content: "00"},
        {value: "01", content: "01"},
        {value: "02", content: "02"},
        {value: "03", content: "03"},
        {value: "04", content: "04"},
        {value: "05", content: "05"},
        {value: "06", content: "06"},
        {value: "07", content: "07"},
        {value: "08", content: "08"},
        {value: "09", content: "09"},
        {value: "10", content: "10"},
        {value: "11", content: "11"},
        {value: "12", content: "12"},
        {value: "13", content: "13"},
        {value: "14", content: "14"},
        {value: "15", content: "15"},
        {value: "16", content: "16"},
        {value: "17", content: "17"},
        {value: "18", content: "18"},
        {value: "19", content: "19"},
        {value: "20", content: "20"},
        {value: "21", content: "21"},
        {value: "22", content: "22"},
        {value: "23", content: "23"}
    ];

    React.useEffect(() => {
        setFixtureAvailableDays(props.fixture_available_days)
    }, [props.fixture_available_days]);

    React.useEffect(() => {
        if( props.number_matches ){
            setNumberOfPlays(props.number_matches)
        }
    }, [props.number_matches]);

    React.useEffect(() => {
        setStartDate( props.start_date );
    }, [props.start_date]);

    React.useEffect(() => {
        setAssignDays( props.assign_days );
    }, [props.assign_days]);

    React.useEffect(() => {
        setAssignFields( props.assign_fields );
    }, [props.assign_fields]);

    React.useEffect(() => {
        setMatchDuration( props.match_duration );
    }, [props.match_duration]);

    React.useEffect(() => {
        if(props.time_between_games?.length > 1){
            setTimeBGamesHour(props.time_between_games[0]);
            setTimeBGamesMinutes(props.time_between_games[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.time_between_games) ]);

    const handleAssignDays = (event) => {
        if(!event.target.checked){
            setStartDate(null);
            formRef.current.setFieldValue("start_date", null);
            setMatchDuration(null);
            formRef.current.setFieldValue("match_duration", null);
        }
        setAssignDays( event.target.checked );
        
    };

    const handleAssignFields = (event) => {
        setAssignFields( event.target.checked );
    };

    const handleNextStep = () => {
        if((numberOfPlays && !assignDays && !assignFields) || (numberOfPlays && assignDays && assignFields && startDate && matchDuration) || (numberOfPlays && assignDays && !assignFields && startDate && matchDuration) || (numberOfPlays && !assignDays && assignFields) ){
            props.setAutomaticFixture({number_matches: numberOfPlays, start_date: startDate, match_duration: matchDuration, time_between_games: timeBGames, assign_days: assignDays, assign_fields: assignFields});
            setStep2(true);
        }
    }

    const handleClose = () => {
        setStep2(false);
        props.onClose();
    }

    const handleModalClose = () => {
        setStep2(false);
        props.onCloseModal();
    }

    function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }
        
        return today;
    }

    const handleOnStartDate = (date) => {
        if(date){
            setStartDate(date);
        }
        
    }

    const handleOnMatchDuration = (min) => {
        if(min){
            setMatchDuration(min);
        }
        
    }

    const handleOnTimeBGHour = (hr) => {
        if(hr){
            setTimeBGamesHour(hr);
            setTimeBGames(`${hr}:${timeBGamesMinutes}:00`);
        }
    }

    const handleOnTimeBGMinutes = (min) => {
        if(min){
            setTimeBGamesMinutes(min);
            setTimeBGames(`${timeBGamesHour}:${min}:00`);
        }
    }

    const handleOptionsList = () => {
        setOptionsOpen(!optionsOpen)
    }

    const handleFieldOptionsList = () => {
        setFieldOptionsOpen(!fieldOptionsOpen)
    }

    const handleCreateField = (action_type) => {
        props.handleFieldsAdminActions(action_type); 
        setOptionsOpen(false);
        setFieldOptionsOpen(false);
    }

    const handleExistingFields = () => {
        props.handleExistingFields();
        setOptionsOpen(false);
        setFieldOptionsOpen(false);
    }



    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={ handleModalClose }
            className="phase-roundtrip-fixture-selector-modal"
            title={props.t('options.tournaments.automaticFixturesModal.modaltitle')}
        >

            <div className='phases_round_fixture_selector_modal-container'>
                <div className='phases_round_fixture_selector_modal-container_stepper'>
                    <div className='phases_round_fixture_selector_modal-stepper'>
                        <div className='phases_round_fixture_selector_modal_step-point__container'>
                            <div className={"phases_round_fixture_selector_modal_step-point"}>
                                <div className='baloa-username phases_round_fixture_selector_modal_step-point-label-completed'>{props.t('options.tournaments.automaticFixturesModal.settingsLbl')}</div>
                                <div className='phases_round_fixture_selector_modal_point phases_round_fixture_selector_modal_step-point-completed' />
                            </div>
                            <div className={"phases_round_fixture_selector_modal_step-point"}>
                                <div className={`baloa-username ${step2 ? 'phases_round_fixture_selector_modal_step-point-label-completed' : ''}`}>{props.t('options.tournaments.automaticFixturesModal.scheduleLbl')}</div>
                                <div className={`phases_round_fixture_selector_modal_point ${step2 ? 'phases_round_fixture_selector_modal_step-point-completed' : ''}`} />
                            </div>
                        </div>
                        <div className='phases_round_fixture_selector_modal_step-line__container'>
                            <div className={`phases_round_fixture_selector_modal_step-line ${step2 ? 'phases_round_fixture_selector_modal_step-line-completed' : ''}`} />
                        </div>
                    </div>
                </div>
                <FormComponent
                    formInitialValues={{
                        match_day: props.number_matches? props.number_matches : 1,
                        assignDays: props.assign_days,
                        assignCourts: props.assign_fields,
                        start_date: props.start_date,
                        match_duration: props.match_duration,
                        time_between_games_hour: props.time_between_games? props.time_between_games[0] : '00',
                        time_between_games_minutes: props.time_between_games? props.time_between_games[1] : '00',
                    }}
                    onSubmit={(values) => { }}
                    className=""
                    functionDisabled={() => { }}
                    enableReinitialize={false}
                    innerRef={formRef}
                >
                    { !step2 &&
                        <div className='phases_round_fixture_selector_modal-box scroll'>
                            <div className='phases_round_fixture_selector_modal-select'>
                                <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.howManytimesLbl')}</div>
                                <SelectFieldCleanComponent
                                    field_name="match_day"
                                    className="phases_round_fixture_option_list_body_select"
                                    validationFunction={RequiredValidator}
                                    disabled={false}
                                    resetError={() => { }}
                                    error={{ match_day: '' }}
                                    message=""
                                    placeholder=""
                                    options={NUMBER_OF_PLAYS}
                                    onChange={ (value) => setNumberOfPlays(value) }
                                    defaultValue={props.number_matches? props.number_matches : 1}
                                />
                            </div>
                            <div className='phases_round_fixture_selector_modal-switches'>
                                <div className='phases_round_fixture_selector_modal-switch'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.automaticFixturesModal.automaticDaysLbl')}</div>
                                    <Switch
                                        checked={assignDays}
                                        onChange={handleAssignDays}
                                        name="assignDays"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="secondary"
                                    />
                                </div>
                                {assignDays &&
                                    <div>
                                        <div>
                                            <InputFieldComponent
                                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelStartDate')}
                                                input_id="start_date" 
                                                field_name="start_date"
                                                validationFunction={ DateFormatValidator }
                                                className="phases_round_fixture_selector_modal__date"
                                                input_type="date"
                                                resetError={ () => {} }
                                                error={ { start_date: '' }}                            
                                                disabled={ false }
                                                min={ todayDate() }
                                                onChange={ (date) => { handleOnStartDate(date)} }
                                                defaultValue={ startDate }
                                                
                                            />
                                        </div>
                                        {!assignFields &&    
                                            <React.Fragment>                                                                                
                                                <TournamentFixtureAvialableDatesComponent
                                                    fixtureAvailableDays={fixtureAvailableDays}
                                                    handleOnDeleteFixtureAvailableDay={props.handleOnDeleteFixtureAvailableDay}
                                                    handleOnAddAvailableDates={props.handleOnAddAvailableDates}
                                                />                                                
                                                <TournamentFixtureLockedDatesComponent
                                                    fixture_locked_days={props.fixture_locked_days? props.fixture_locked_days : []}
                                                    handleOnDeleteFixtureLockedDay={props.handleOnDeleteFixtureLockedDay}
                                                    handleOnAddBlockDates={props.handleOnAddBlockDates}
                                                />
                                            </React.Fragment>
                                        }
                                        <div>
                                            <InputFieldComponent
                                                label={props.t('options.tournaments.automaticFixturesModal.matchDurationLbl')}
                                                input_id="match_duration" 
                                                field_name="match_duration"
                                                validationFunction={ OnlyNumbersRequiredValidator }
                                                className="phases_round_fixture_selector_modal__number"
                                                input_type="text"
                                                resetError={ () => {} }
                                                error={ { match_duration: '' } }                            
                                                disabled={ false }
                                                min={ 0 }
                                                onChange={ (min) => { handleOnMatchDuration(min) } }
                                                defaultValue={matchDuration}
                                                
                                            />
                                        </div>
                                        <div>
                                            <div className='phases_round_fixture_selector_modal__time-between__title baloa-username'>{props.t('options.tournaments.automaticFixturesModal.spaceBtwMatchesLbl')}</div>
                                            <div className="phases_round_fixture_selector_modal__time-between">
                                                <SelectFieldCleanComponent
                                                    label={props.t('options.tournaments.automaticFixturesModal.hoursLbl')}
                                                    field_name="time_between_games_hour"
                                                    className="phases_round_fixture_selector_modal__time"
                                                    validationFunction={ RequiredValidator }
                                                    disabled={false}
                                                    resetError={ () => {}  }
                                                    error={ { time_between_games_hour: '' } }
                                                    message=""
                                                    placeholder={"00"}
                                                    options={ hours }    
                                                    onChange={(hr)=>{ handleOnTimeBGHour(hr) }}
                                                    defaultValue={timeBGamesHour}
                                                />
                                                <SelectFieldCleanComponent
                                                    label={props.t('options.tournaments.automaticFixturesModal.minutesLbl')}
                                                    field_name="time_between_games_minutes"
                                                    className="phases_round_fixture_selector_modal__time"
                                                    validationFunction={ RequiredValidator }
                                                    disabled={false}
                                                    resetError={ () => {}  }
                                                    error={ { time_between_games_minutes: '' } }
                                                    message=""
                                                    placeholder={"00"}
                                                    options={ minutes }    
                                                    onChange={(min)=>{ handleOnTimeBGMinutes(min) }}
                                                    defaultValue={timeBGamesMinutes}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                }
                                {assignDays && assignFields && 
                                    <div className='phases_round_fixture_selector_modal__info-msg'>
                                        <span className='hubicon-info' />
                                        <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.bothOptionsActiveMsg')}</div>                        
                                    </div>  
                                }
                                <div className='phases_round_fixture_selector_modal-switch'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.automaticFixturesModal.automaticFielsLbl')}</div>
                                    <Switch
                                        checked={assignFields}
                                        onChange={handleAssignFields}
                                        name="assignCourts"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="secondary"
                                    />
                                </div>
                                {assignFields &&
                                <div>
                                    
                                    <SecondaryButtonComponent
                                        type={"button"}
                                        className="fixture-matches-available-dates__button-add small"
                                        onClick={()=>{handleOptionsList()}}
                                    >
                                        <span className='hubicon-add_option icon'/>
                                        <span>{props.t('options.tournaments.automaticFixturesModal.addFieldBtnLbl')}</span>
                                    </SecondaryButtonComponent>
                                    <div className={`tournament-fields-admin__btn-options ${optionsOpen? 'open' : ''}`}>
                                        <div className='baloa-names' onClick={ () => handleCreateField('create-tournament-field') } >{ props.t('options.tournamentFields.labelNewFieldOption') }</div>
                                        {props.page_fields?.length !== 0 && 
                                            <div className='baloa-names' onClick={ () => handleExistingFields() } >{ props.t('options.tournamentFields.labelExistingFieldOption') }</div> 
                                        }
                                    </div>
                                    {props.tournament_fields.length > 0?
                                        props.tournament_fields.map((field, index) =>
                                            <TournamentFixtureFieldComponent
                                                field={field}
                                                index={index}
                                                fixture_field_teams={props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id).length > 0? props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id)[0].teams : []}
                                                handleOnDeleteFieldTeam={ props.handleOnDeleteFieldTeam }
                                                handleOnAddTeamField={ props.handleOnAddTeamField }
                                                
                                                
                                                assignDays={ assignDays }
                                                fieldsFixtureAvailableDays={ props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id).length > 0? props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id)[0].schedule : [] }
                                                handleOnDeleteFieldFixtureAvailableDay={ props.handleOnDeleteFieldFixtureAvailableDay }
                                                handleOnFieldAddAvailableDates={ props.handleOnFieldAddAvailableDates }
                                                field_fixture_locked_days={ props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id).length > 0? props.fixture_tournament_fields?.filter(tfield => tfield.id === field.id)[0].non_playable_dates : [] }
                                                handleOnDeleteFieldFixtureLockedDay={props.handleOnDeleteFieldFixtureLockedDay }
                                                handleOnFieldAddBlockDates={ props.handleOnFieldAddBlockDates }
                                                handleFieldsAdminActions={ props.handleFieldsAdminActions }
                                            />
                                        )
                                        :
                                        <div className='phases_round_fixture_selector_modal__empty-fields'>
                                            <div className='baloa-table-column'>{props.t('options.tournaments.automaticFixturesModal.emptyFieldsMsg')}</div>
                                            <div className='baloa-table-column green' onClick={()=>{handleFieldOptionsList()}}>
                                                {props.t('options.tournaments.automaticFixturesModal.addLbl')}
                                                <div className={`phases_round_fixture_selector_modal__field-btn-options ${fieldOptionsOpen? 'open' : ''}`}>
                                                    <div className='baloa-names' onClick={ () => handleCreateField('create-tournament-field') } >{ props.t('options.tournamentFields.labelNewFieldOption') }</div>
                                                    {props.tournament_available_fields.length !== 0 && 
                                                        <div className='baloa-names' onClick={ () => handleExistingFields() } >{ props.t('options.tournamentFields.labelExistingFieldOption') }</div> 
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    }
                    { step2 &&
                        <TournamentPhaseFixtureResumeComponent 
                            fixture = { props.fixture }
                            handleOnReorganize = { (phase_id) => { props.handleOnReorganize(phase_id) } }
                            handleOnAccept = { () => { console.log('handleOnAccept') } }
                            loading_fixture_state = { props.loading_fixture_state }
                            phase_teams = { props.phase_teams }
                            phase_dates = { props.phase_dates }
                            phase_type = { props.phase_type }

                            tournament_fields={ props.tournament_field_list }
                            handleSubmitEditInfoMatch={ props.handleSubmitEditInfoMatch }
                            new_page_field={ props.new_page_field }
                            handleFieldsAdminActions={ props.handleFieldsAdminActions }
                            handleTournamentFieldActions={ props.handleTournamentFieldActions }
                        />

                    }
                    <div className='phases_round_fixture_selector_modal_button'>
                        {step2 &&
                            <TextButtonComponent
                                className="small"
                                type='button'
                                onClick={ ()=>{ setStep2(false) } }
                            >
                                <span>{props.t('options.tournaments.automaticFixturesModal.backBtnLbl')}</span>
                            </TextButtonComponent>
                        }
                        
                        <PrimaryButtonComponent
                            className="modal_button small"
                            type='button'
                            onClick={ ()=>{ step2? handleClose() : handleNextStep()} }
                        >
                            <span>{step2? props.t('options.tournaments.automaticFixturesModal.endBtnLbl') : props.t('options.tournaments.automaticFixturesModal.nextBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </FormComponent>
            </div>
        </Modal2Component>
    )
    /**
     * <p className="phase-roundtrip-fixture-selector__title baloa-headline-5">
                    {props.modalTitle}
                </p>
                <div className="phase-roundtrip-fixture-selector__text baloa-body-2"
                    dangerouslySetInnerHTML={{
                        __html: props.modalContent
                    }}></div>
                <div className="phase-roundtrip-fixture-selector__actions">
                    <SecondaryButtonComponent
                        className="phase-roundtrip-fixture-selector__button"
                        onClick={props.onSecondaryButtonClick}
                    >
                        <span>{props.secondaryButtonLabel}</span>
                    </SecondaryButtonComponent>
                    <SecondaryButtonComponent
                        className="phase-roundtrip-fixture-selector__button"
                        onClick={props.onPrincipalButtonClick}
                    >
                        <span>{props.principalButtonLabel}</span>
                    </SecondaryButtonComponent>
                </div>
     * 
    */
}

export default  withTranslation('league')(PhaseRoundtripFixtureSelectorModalComponent);
