// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-search-member-to-team-container {

   width: 100%;
}

.league-search-member-to-team__input-search.baloa-subtitle-2{
    padding-bottom: var(--space);
}

.league-search-member-to-team-form__box {

}

.league-search-member-to-team-search {


}

.league-search-member-to-team__input-subtitle{
    margin-bottom: var(--space);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-search-member-to-team/LeagueSearchMemberToTeamComponent.styles.css"],"names":[],"mappings":"AAAA;;GAEG,WAAW;AACd;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;AAEA;;AAEA;;;AAGA;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".league-search-member-to-team-container {\n\n   width: 100%;\n}\n\n.league-search-member-to-team__input-search.baloa-subtitle-2{\n    padding-bottom: var(--space);\n}\n\n.league-search-member-to-team-form__box {\n\n}\n\n.league-search-member-to-team-search {\n\n\n}\n\n.league-search-member-to-team__input-subtitle{\n    margin-bottom: var(--space);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
