// i18next 
import i18n from 'i18next';

export const  EndDateValidator = (value, value1) => {
    let error;
    
    var regEx = /^\d{4}-\d{2}-\d{2}$/;    

    if(!value || !value.match(regEx)) {
        error = i18n.t('page:newPage.validators.addDate'); //"Agrega una fecha";
    }else{
       
        let date = new Date();
        let today = '';

        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }

        if(Date.parse(value) < Date.parse(today)){
            error = i18n.t('page:newPage.validators.invalidDate'); // Fecha inválida
        }
        if(Date.parse(value) < Date.parse(value1)){
            error = i18n.t('page:newPage.validators.invalidDate'); // Fecha inválida
        } 
        
    }

    return error;
}