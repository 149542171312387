/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';

// Shapes
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import SugestionsComponent from 'app/pages/components/page-groups-suggestions/SugestionsComponent';
import PageGroupsMyGroupsComponent from 'app/pages/components/page-groups-my-groups/PageGroupsMyGroupsComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './HomePagesComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Assets
import admin_pages_empty from 'assets/images/admin_pages_empty.png';
import followed_pages_empty from 'assets/images/followed_pages_empty.png';
import explore_pages_empty from 'assets/images/explore_pages_empty.png';


function HomePagesComponent( props ) {

    return(

        <React.Fragment>
            {!isMobile &&
                <div className="home-pages">
                    <div className="home-pages__content">
                        <div className="home-pages__content__title">
                                <span className="home-pages__content__title__text baloa-headline-2">{props.title}</span>
                        </div>                       
                    </div>
                </div>
            }
            {isMobile && props.page_type !== 'explore' &&
                <div className="home-pages">
                    <div className="home-pages__content">
                        <div className="home-pages__content__title">
                                <span className="home-pages__content__title__text baloa-headline-2">{props.title}</span>
                        </div>                                                                         
                        <span className='home-pages__close_icon icon-x' onClick={ ()=>{ props.handleClickMenuSugestions("pages") } } />                                                
                    </div>
                </div>
            }
            
            {/* <Card1Component
                icon={ (
                    <span className={ `home-pages__icon ${props.page_type === 'groups' ? 'icon-group-friends' : props.page_type === "pages" ? 'icon2-page' : props.page_type === "leagues" ? 'icon-trophy' : ''}`} /> 
                ) }                 
                className='home-pages__card' 
                title={ props.subTitleCaption }
                button={  ( 
                    <PrimaryButtonComponent 
                        className='home-pages__new-button'
                    >
                        {/* { props.pages_type === 'Group' ? props.t('follow.suggestions.buttonGroup') : props.pages_type === "Page" ? props.t('follow.suggestions.buttonPage') : props.t('follow.suggestions.buttonLeague')  } }
                        { props.addButtonCaption }
                    </PrimaryButtonComponent>
                ) }
                onButtonClick={ () => { props.onClickAddButton() } }                
            ></Card1Component> */}

            {/* props.page_type == 'groups' && props.memberGroupPages.length > 0 && 
                <PageGroupsMyGroupsComponent
                    title={props.t('page:groups.myGroups.title')}
                    memberGroupPages = { props.memberGroupPages }
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                /> */
            }

            {props.page_type === 'pages' && props.myPages.length > 0 && 
                <PageGroupsMyGroupsComponent //Se reutiliza con mis paginas
                    //title={props.t('profile:pages.title1')}
                    memberGroupPages = { props.myPages }
                    handleOnPageClick = { props.handleOnPageClick }
                />
            }
            {props.page_type === 'pages' && props.myPages.length === 0 &&
                <div className='home-pages__empty'>
                    <img src={admin_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.emptyPagesCaption}</div>
                    <PrimaryButtonComponent 
                        type={'button'}
                        className='home-pages__new-button'
                        onClick={ () => { props.onClickAddButton() } }    
                    >
                        { props.addButtonCaption }
                    </PrimaryButtonComponent>
                </div>
            }

            {(props.page_type === 'followed' || props.page_type === 'explore') && props.myPages.length > 0 &&
                <SugestionsComponent
                    className={`home-pages__sugestions ${props.page_type === 'explore'? 'explore' : ''}`}                
                    //pages={ props.pages }
                    pages={ props.myPages }
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                    //title = { props.cardsCaption }
                    //title = { props.cardsCaption }
                    page_type = { props.page_type }
                    handleFollow = { props.handleFollow }
                    followCaption = { props.followCaption }
                    handleUnFollow = { props.handleUnFollow }
                    followingCaption = { props.followingCaption }
                    addClassName = { props.addClassName }
                    fetchMoreData = { props.fetchMoreData }
                    pages_next_page = { props.pages_next_page }
                    scroll_height = { (props.myPages?.length < 9)? 'auto' : isMobile? '70vh' : '95vh' }
                /> 
            }

            {props.page_type === 'followed' && props.myPages.length === 0 &&
                <div className='home-pages__empty'>
                    <img src={followed_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.emptyPagesCaption}</div>
                    <PrimaryButtonComponent 
                        type={'button'}
                        className='home-pages__new-button'
                        onClick={ () => { props.handleClickMenuSugestions("explore") } }    
                    >
                        { props.addButtonCaption }
                    </PrimaryButtonComponent>
                </div>
            }

            {props.page_type === 'explore' && props.myPages.length === 0 &&
                <div className='home-pages__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.emptyPagesCaption}</div>                    
                </div>
            }               

        </React.Fragment>
     )
}

export default withTranslation(['home','page','profile'])(HomePagesComponent);
