// Action creators
import { 
    changeHubTournamentsLoadingAction, 
    loadHubTournamentsAction,
    loadSelectedTournamentAction,
    loadHubTournamentTeamsAction,
    loadHubTeamsTournamentsAction,
    loadPlayerTournamentsAction,
    loadAllManagedTournamentsAction,
    loadProfileAdminTeamsAction,
    loadTeamPlayersAction,
    loadPaymentUrlAction,
    loadPaymentTournamentTeamIdAction,
    loadPlayerCodePaymentAction,
    /* removeTeamFromTournamentAction, */
    loadFilteredHubTournamentsAction,
    removePlayerFromTournmentAction,
    cleanPlayerCodePaymentAction,
    loadTournamentInscriptionFormTemplateAction,
    loadTournamentPlayerFormTemplateAction,
    changeFormsModalStateAction,
    loadSelectedTournamentTeamsAction,
    loadHubTournamentsSectionsAction,
    loadHubTournamentSubSectionAction,
    loadFollowingHubTournamentsSubsectionAction,
    loadInformativeTournamentTeamsAction,
    loadSelectedTournamentTeamsOnlyAction,
    loadHubTournamentMatchesAction,
    loadHubNextMatchesAction,
    loadHubTournamentMatchesDatesAction,
    loadTeamManagerAndStaffAction,
    loadPhaseCalendarAction,
    changeTeamPlayersLoadingAction,
    loadSelectedTournamentTeamsPayAction,
    changeTeamStaffLoadingAction,
    changeFormsLoadingAction,
    loadSelectedShareTournamentAction,
} from './TournamentActionsCreators';

import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Apis
import { GetTournamentsApi, 
    //GetTournamentHubTeamsApi, 
    GetHubTeamsTournamentsApi, 
    GetHubPlayerTournamentsApi,
    /* GetHubTournamentInfoApi, */
    GetAllManagedTournamentsApi,
    ChangeTournamentTeamApi,
    GetProfileAdminTeamsApi,
    GetTournamentsFilterApi,
    //GetTournamentTeamPlayersApi,
    GetFrontTournamentTeamPlayersApi,
    GetPaymentApi,
    InviteTeamToTournamentApi,
    PlayerInscriptionApi,
    GetPlayerPaymentApi,
    GetHubTeamTournamentInfoApi,
    GetHubPlayerTournamentInfoApi,
    GetTournamentHubTournamentTeamsApi,
    ChangeTournamentPlayerApi,
    GetTournamentInscriptionFormTemplateApi,
    GetTournamentInscriptionFormTemplateIdApi,
    GetUnauthenticatedTournamentsApi,
    GetUnauthenticatedHubTournamentInfoApi,
    GetUnauthenticatedTournamentHubTeamsApi,
    GetUnauthenticatedTournamentsFilterApi,
    GetNewHubTournamentInfoApi,
    GetHubTournamentsSectionsApi,
    GetHubTournamentsSubsectionsApi,
    GetHubInformativeTournamentApi,
    GetHubTournamentSearchApi,
    PostFollowTournamentHubApi,
    DeleteUnfollowTournamentHubApi,
    GetHubTournamentMatchesApi,
    GetHubTournamentMatchesByDateApi,
    GetHubTournamentNextMatchesApi,
    GetHubTournamentMatchesDatesApi,
    SearchProfileAdminTeamsApi,
    GetTournamentTeamManagerAndStaffApi,
    GetPhaseCalendarApi,
    GetFrontTournamentHubTeamsApi,
    GetFrontTournamentTeamSearchPlayersApi,
} from 'app/tournaments/apis/TournamentApi';

import { AddMemberWithRolToTeamInApiInscription } from 'app/leagues/apis/LeagueTeamsApi';
import { addPlayerToTeamAction } from 'app/leagues/actions/LeagueTeamsActionsCreators';

// i18next 
import i18n from 'i18next';




export const loadHubTournaments = ( loading = false, next_page ) => ( dispatch ) => {

    if ( !loading ) {
        dispatch( changeHubTournamentsLoadingAction( true ) );        
        GetTournamentsApi( next_page )
            .then( response => {
                dispatch( loadHubTournamentsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeHubTournamentsLoadingAction( false ) );
            } );
    }

}

export const loadSelectedTournament = ( tournament_id, tournament_team_id = null) => { //TODO:revisar con el nuevo hub

    return async ( dispatch ) => {

        //await GetHubTournamentInfoApi( tournament_id )
        await GetNewHubTournamentInfoApi( tournament_id )
            .then( response => {
                if(tournament_team_id){
                    dispatch( loadSelectedTournamentTeamsOnlyAction( response ) ); 
                }else{
                    dispatch( loadSelectedTournamentTeamsAction( response ) ); 
                }
                               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSelectedTournamentTeamPayment = ( tournament_id, tournament_team_id) => { //Se crea para poder cargar en el estado el equipo (tambien segun el jugador) que realizo el pago

    return async ( dispatch ) => {

        //await GetHubTournamentInfoApi( tournament_id )
        await GetNewHubTournamentInfoApi( tournament_id )
            .then( response => {
                dispatch( loadSelectedTournamentTeamsPayAction( response, tournament_team_id ) );          
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHubTournamentTeams = ( tournament_id, teams_state ) => {
    return async ( dispatch ) => {
        
        //await GetTournamentHubTeamsApi( tournament_id, teams_state )
        await GetFrontTournamentHubTeamsApi( tournament_id, teams_state ) //Cambio apis paginadas de hub de torneos
            .then( response => {
                dispatch( loadHubTournamentTeamsAction( response ) );
                dispatch( cleanPlayerCodePaymentAction({}) );              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHubTeamsTournaments = ( teams_state ) => {

    return ( dispatch ) => {
        
        GetHubTeamsTournamentsApi( teams_state )
            .then( response => {
                dispatch( loadHubTeamsTournamentsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPlayerTournaments = ( player_state ) => {

    return ( dispatch ) => {
        
        GetHubPlayerTournamentsApi( player_state )
            .then( response => {
                dispatch( loadPlayerTournamentsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadAllManagedTournaments = ( filter ) => {

    return ( dispatch )=>{

        GetAllManagedTournamentsApi( filter )
            .then( response => {
                dispatch(loadAllManagedTournamentsAction(response))
            })
            .catch( err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) );
            }
        });
    }
}

export const changeTournamentTeamState = (tournament_team_id, new_team_state ) => {

    return async ( dispatch ) => {
        await ChangeTournamentTeamApi( tournament_team_id, new_team_state )
            .then( response => {
                dispatch( loadSelectedTournamentAction( response ) );              
                if( new_team_state === 'Cancelled' ) {
                    dispatch(loadHubTournamentsSections(  ) );
                }
                if( new_team_state === 'Rejected' ) {
                    dispatch( loadHubTournamentsSections(  ) );
                }
            })
            .catch(err => {
                if(err.response.status === 409){
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: err.response.data.baloa_error_code? i18n.t('league:snackBarMessages.'+err.response.data.baloa_error_code) :i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }else{
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
    }
}

export const loadProfileAdminTeams = ( queryParams ) => {

    return async ( dispatch ) => {
        
        await GetProfileAdminTeamsApi( queryParams )
            .then( response => {
                dispatch( loadProfileAdminTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


export const loadHubTournamentsFilter = ( filter ) => ( dispatch ) => {

        GetTournamentsFilterApi ( filter )
            .then( response => {
                dispatch( loadFilteredHubTournamentsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
}

export const loadTeamPlayers = ( tournament_team_id, state, next_team_players ) => {

    return async ( dispatch ) => {
        dispatch( changeTeamPlayersLoadingAction( true ) );
        //await GetTournamentTeamPlayersApi( tournament_team_id, state )
        await GetFrontTournamentTeamPlayersApi( tournament_team_id, state, next_team_players )
            .then( response => {
                dispatch( loadTeamPlayersAction( response ) );
                dispatch( cleanPlayerCodePaymentAction({}) ); //Se agrega para habilitar la verificacion de pago jugador con cupon desde el reducer y que se limpie despues de cada pago
            })
            .catch(err => {

                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeTeamPlayersLoadingAction( false ) );
            } );        
    }
}             

export const loadPaymentUrl = ( tournament_id, players, redirect ) => {

    return async ( dispatch ) => {

        await GetPaymentApi( tournament_id, players, redirect )
            .then( response => {
                dispatch( loadPaymentUrlAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const registerHubTeamToTournament = ( tournament_id, team_name, team_state ) => {
    return async ( dispatch ) => {
        
        await InviteTeamToTournamentApi( tournament_id, team_name, team_state )
            .then( response => {
                dispatch( loadSelectedTournamentAction( response ) );    
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const sentPlayerInscriptionForm = ( form_data, reload_data, team_name, tournament_id ) => {    

    return ( dispatch ) => {    
        // console.log('data', reload_data ,'team', team_name ,'form-->', form_data);    
        dispatch( changeFormsLoadingAction(true));

        PlayerInscriptionApi( form_data )
            .then( response => {
                //dispatch( loadLeagueTournamentsAction( response ) );                
                //dispatch( changeLeagueTournamentLoadingAction( false ) );
                dispatch(changeFormsModalStateAction(false)); //Cierra el modal de formularios
                if(reload_data && reload_data === 'adminteams'){
                    dispatch (loadTeamPlayers(form_data.tournament_team_id, 'Pending|Enrolled|Rejected|Late_Register'));
                }
                if(reload_data && reload_data === 'player'){
                    dispatch (loadTeamPlayers(form_data.tournament_team_id, 'Pending|Enrolled|Rejected|Late_Register'));
                    //dispatch (loadSelectedPlayerTournament(form_data.tournament_player_id) );
                    dispatch (loadSelectedTeamTournament(form_data.tournament_team_id) ); //Se cambia en el nuevo hub para que traiga el nuevo serializador de torneos
                }
                if(reload_data && reload_data === 'teams'){//TODO:revisar con el nuevo hub
                    dispatch (loadSelectedTeamTournament(form_data.tournament_team_id) );
                }
                if(reload_data && reload_data === 'staff'){
                    dispatch( loadTeamManagerAndStaff(team_name , tournament_id) );
                }
                
            })
            .catch(err => {
                if(err.response !== undefined && err.response.status !== 404){
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {                        
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
            .then( () => {
                dispatch( changeFormsLoadingAction( false ) );
            } ); 
    }
}

export const registerHubTeamToTournamentToPay = ( tournament_id, team_name, team_state ) => {
    return async ( dispatch ) => {
        
        await InviteTeamToTournamentApi( tournament_id, team_name, team_state )
            .then( response => {
                dispatch( loadPaymentTournamentTeamIdAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPlayerPaymentUrl = ( tournament_player_id, player_code, redirect ) => {

    return async ( dispatch ) => {

        await GetPlayerPaymentApi( tournament_player_id, player_code, redirect )
            .then( response => {

                if(player_code){
                    //pago con player_code
                    dispatch( loadPlayerCodePaymentAction( response ) );  
                }else{
                    dispatch( loadPaymentUrlAction( response ) );     
                }
                           
            })
            .catch( err => {
                if(err.response.status === 400){
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: err.response.data.player_code? err.response.data.player_code : err.response.data.payment? err.response.data.payment : err.response.data,
                            error_message_is_open: true
                        } ) )
                    }
                }else{
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
                
            })
    }
}

export const loadSelectedTeamTournament = ( tournament_team_id, social_share) => {
    return async ( dispatch ) => {

        await GetHubTeamTournamentInfoApi( tournament_team_id )
            .then( async response => {
                if(social_share){//Se emple para cuando el usuario llega por link de invitacion a un torneo y ya esta logueado en Baloa
                    dispatch( loadSelectedShareTournamentAction( response ) );//Se guarda la informacion completa del torneo para utilizarla en el registro del jugador al equipo
                }
                //dispatch( loadSelectedTournamentAction( response ) );
                await dispatch( loadSelectedTournamentTeamPayment( response.data.tournament_id, response.data.tournament_team_id ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadSelectedPlayerTournament = ( tournament_player_id ) => {
    return async ( dispatch ) => {

        await GetHubPlayerTournamentInfoApi( tournament_player_id )
            .then( async response => {
                //dispatch( loadSelectedTournamentAction( response ) ); 
                await dispatch( loadSelectedTournamentTeamPayment( response.data.tournament_id, response.data.tournament_team_id ) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTemporalPlayerTournament = ( tournament_player_id ) => {
    return async ( dispatch ) => {

        await GetHubPlayerTournamentInfoApi( tournament_player_id )
            .then( async response => {
                //Se crea accion para aprtir del tournament_player_id poder ubicar el equipo al que pertenece el jugador para dejarlo en el estado
                await dispatch( loadSelectedTournamentTeamPayment( response.data.tournament_id, response.data.tournament_team_id ) );
                //await dispatch(loadSelectedTeamTournament( response.data.tournament_team_id ) ); //TODO: CHECK AFTER CHANGE HUB APIS
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTournamentHubTournamentTeams = ( tournament_id, teams_state ) => {

    return ( dispatch ) => {
        
        GetTournamentHubTournamentTeamsApi( tournament_id, teams_state )
            .then( response => {
                dispatch( loadHubTournamentTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const changeTournamentPlayerState = (player_id, new_player_state ) => {
    
    return ( dispatch ) => {
        
        ChangeTournamentPlayerApi( player_id, new_player_state )
            .then( response => {
                if( new_player_state === 'Rejected') {
                    dispatch(removePlayerFromTournmentAction(response) );
                }              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

/* export const loadTournamentInscriptionFormTemplate = ( id_form_template ) => {

    return ( dispatch ) => {
        
        GetTournamentInscriptionFormTemplateApi( id_form_template )
            .then( response => {
                dispatch( loadTournamentInscriptionFormTemplateAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
} */

export const loadTournamentInscriptionFormTemplate = ( form_type ,id_tournament ) => {

    return async ( dispatch ) => {
        
        await GetTournamentInscriptionFormTemplateIdApi( form_type, id_tournament )
            .then( response => {
                if(response.data.length > 0){
                    GetTournamentInscriptionFormTemplateApi(response.data[0].id)
                        .then(response2 => {
                            dispatch( loadTournamentInscriptionFormTemplateAction( response2 ) );
                        })  
                }                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadUnauthenticatedHubTournaments = ( loading = false, next_page ) => ( dispatch ) => {

    if ( !loading ) {
        dispatch( changeHubTournamentsLoadingAction( true ) );        
        GetUnauthenticatedTournamentsApi( next_page )
            .then( response => {
                dispatch( loadHubTournamentsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeHubTournamentsLoadingAction( false ) );
            } );
    }

}

export const loadUnauthenticatedSelectedTournament = ( tournament_id ) => {

    return async ( dispatch ) => {

        await GetUnauthenticatedHubTournamentInfoApi( tournament_id )
            .then( response => {
                dispatch( loadSelectedTournamentAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadUnauthenticatedHubTournamentTeams = ( tournament_id, teams_state ) => {

    return ( dispatch ) => {
        
        GetUnauthenticatedTournamentHubTeamsApi( tournament_id, teams_state )
            .then( response => {
                dispatch( loadHubTournamentTeamsAction( response ) );
                dispatch( cleanPlayerCodePaymentAction({}) );              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadUnauthenticatedHubTournamentsFilter = ( filter ) => ( dispatch ) => {

    GetUnauthenticatedTournamentsFilterApi ( filter )
        .then( response => {
            dispatch( loadFilteredHubTournamentsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        })
}

export const loadTournamentPlayerFormTemplate = ( form_type ,id_tournament ) => {

    return async ( dispatch ) => {
        
        await GetTournamentInscriptionFormTemplateIdApi( form_type, id_tournament )
            .then( response => {
                if(response.data.length > 0){
                    GetTournamentInscriptionFormTemplateApi(response.data[0].id)
                        .then(response2 => {
                            dispatch( loadTournamentPlayerFormTemplateAction( response2 ) );
                        })  
                }                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addFlexiMemberToTeam = ( teamname, member, tournament_team_id ) => {

    return ( dispatch ) => {

        AddMemberWithRolToTeamInApiInscription( teamname, member )
            .then( response => {
                dispatch( addPlayerToTeamAction(response));
                dispatch (loadSelectedTeamTournament( tournament_team_id) );      
            })
            .catch( err => {
                if(err.response.status === 409){
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: err.response.data.baloa_error_code? i18n.t('league:snackBarMessages.'+err.response.data.baloa_error_code) :i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }else{
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                }
            })
    }
}

export const loadHubTournamentsSections = ( ) => {

    return ( dispatch ) => {
        dispatch( changeHubTournamentsLoadingAction( true ) );
        GetHubTournamentsSectionsApi( )
            .then( response => {
                
                dispatch( loadHubTournamentsSectionsAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeHubTournamentsLoadingAction( false ) );
            } );  
    }
}

export const loadHubTournamentsSubsections = ( tournament_subsection, loading = false, next_page ) => {

    return ( dispatch ) => {
        if( !loading ){
            dispatch( changeHubTournamentsLoadingAction( true ) );
            GetHubTournamentsSubsectionsApi( tournament_subsection, next_page )
                .then( response => {
                    dispatch( loadHubTournamentSubSectionAction( response.data ) ); 
                })
                .catch(err => {
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                })
                .then( () => {
                    dispatch( changeHubTournamentsLoadingAction( false ) );
                } );
        }
    }
}

export const loadInformativeTournament = ( tournament_id ) => {

    return async ( dispatch ) => {

        await GetHubInformativeTournamentApi( tournament_id )
            .then( response => {
                dispatch( loadInformativeTournamentTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getHubTournamentSearch = ( tournament_query, loading = false, next_page ) => {

    return ( dispatch ) => {
        if( !loading ){
            dispatch( changeHubTournamentsLoadingAction( true ) );
            GetHubTournamentSearchApi( tournament_query, next_page )
                .then( response => {
                    dispatch( loadHubTournamentSubSectionAction( response.data ) );
                })
                .catch(err => {
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                })
                .then( () => {
                    dispatch( changeHubTournamentsLoadingAction( false ) );
                } );
        }
    }
}

export const loadHubFollowingTournamentsSubsection = ( subsection ) => {

    return ( dispatch ) => {

        GetHubTournamentsSubsectionsApi( subsection )
                .then( response => {
                    dispatch( loadFollowingHubTournamentsSubsectionAction( response.data ) );             
                })
                .catch(err => {
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('league:snackBarMessages.snackBarError'),
                            error_message_is_open: true
                        } ) )
                    }
                })
    }
}

export const onUnfollowTournament = (tournamentId) => {

    return ( dispatch ) => {
        
        DeleteUnfollowTournamentHubApi( tournamentId )
            .then( response => {
                dispatch(loadSelectedTournament(tournamentId));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


export const onFollowTournament = (tournamentId ) => {

    return ( dispatch ) => {
        
        PostFollowTournamentHubApi( tournamentId )
            .then( response => {
                dispatch(loadSelectedTournament(tournamentId));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


export const loadHubTournamentMatches = ( ) => {

    return ( dispatch ) => {
        
        GetHubTournamentMatchesApi( )
            .then( response => {                
                dispatch( loadHubTournamentMatchesAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHubTournamentMatchesByDate = ( date ) => {

    return ( dispatch ) => {
        
        GetHubTournamentMatchesByDateApi( date )
            .then( response => {     
                dispatch( loadHubTournamentMatchesAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHubNextMatches = ( ) => {

    return ( dispatch ) => {
        
        GetHubTournamentNextMatchesApi( )
            .then( response => {                
                dispatch( loadHubNextMatchesAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHubTournamentMatchesDates = ( ) => {

    return ( dispatch ) => {
        
        GetHubTournamentMatchesDatesApi( )
            .then( response => {                
                dispatch( loadHubTournamentMatchesDatesAction( response.data ) );
                dispatch( loadHubTournamentMatchesByDate(response.data.find(date => date.date_to_show === true)?.calendar_date) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchProfileAdminTeams = ( queryParams ) => {

    return ( dispatch ) => {
        
        SearchProfileAdminTeamsApi( queryParams )
            .then( response => {
                dispatch( loadProfileAdminTeamsAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTeamManagerAndStaff = ( team_name, tournament_id, next_team_staff ) => {
    // console.log('***** --- api infoa enviar',team_name, tournament_id);
    return ( dispatch ) => {
        dispatch( changeTeamStaffLoadingAction( true ) );
        GetTournamentTeamManagerAndStaffApi( team_name, tournament_id, next_team_staff )
            .then( response => {
                dispatch( loadTeamManagerAndStaffAction( response.data ) );
            })
            .catch(err => {

                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeTeamStaffLoadingAction( false ) );
            } );           
    }
}

export const loadPhaseCalendar = ( tournament_phase_id ) => {
    return async ( dispatch ) => {  

       await GetPhaseCalendarApi( tournament_phase_id )
            .then( response => { 
                dispatch( loadPhaseCalendarAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchTournamentTeamPlayers = ( tournament_team_id, player, state ) => {

    return async ( dispatch ) => {
        dispatch( changeTeamPlayersLoadingAction( true ) );
        //await GetTournamentTeamPlayersApi( tournament_team_id, state )
        await GetFrontTournamentTeamSearchPlayersApi( tournament_team_id, player, state )
            .then( response => {
                dispatch( loadTeamPlayersAction( response ) );
                dispatch( cleanPlayerCodePaymentAction({}) ); //Se agrega para habilitar la verificacion de pago jugador con cupon desde el reducer y que se limpie despues de cada pago
            })
            .catch(err => {

                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeTeamPlayersLoadingAction( false ) );
            } );        
    }
}