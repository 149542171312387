export const HASHTAG_FORMATTER = string => {
    // string = string.replaceAll('\n', '<br>');
    return diferentTags(string?.match(/[@#]\S+/gi), string);
};

const diferentTags = (matchWords, originalText = '') => {
    let output = '';
    if (matchWords) {
        matchWords.forEach((data, index) => {
            let tag = '';
            if (data.indexOf('@') !== -1) tag = `<a href="${window.location.href}profile/${data.replace('@', '').trim()}/" style="color: var(--new-green-color)">\$&</a>`; // eslint-disable-line
            else if (data.indexOf('#') !== -1) tag = `<a href="${window.location.href}search/search=${data.replace('#', '%23').trim()}" style="color: var(--new-green-color)">\$&</a>`; // eslint-disable-line
            else tag = data;
            output = (!index ? originalText : output).replace(data, tag);
        });
    } else output = originalText

    return output.replaceAll('\n', '<br>');;
}