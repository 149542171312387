// React
import React from 'react';
import { connect } from 'react-redux';

// Actions
import { 
    loadLeagueMembers, 
    loadLeagueTeams,
    loadActiveTeam,
    loadActiveMember,
    deleteLeagueMember,
    deleteLeagueTeam,     
} from 'app/leagues/actions/LeagueActions';
import {     
    seeTournamentFromMemberOrTeamModalAction,    
} from 'app/leagues/actions/LeagueActionsCreators';
import { 
    changeTeamMembersModalStateAction,          
} from 'app/leagues/actions/LeagueActionsCreators';

// Components
import TableComponent from 'shared/components/dynamic-table/TableComponent';
import LeagueUserPermissionsComponent from 'app/leagues/components/league-user-permissions/LeagueUserPermissionsComponent';
import LeagueTournamentStaffMemberComponent from '../league-tournament-staff-member/LeagueTournamentStaffMemberComponent';

//Material UI
import Skeleton from '@mui/material/Skeleton';

//i18
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './TeamsMembersComponent.styles.css';
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';


class TeamsMembersComponent extends React.Component {

    constructor( props ) {
        super( props );        
        this.state = {
            tab_selected: 1,
            show_member_detail: false,
        }



    }

    componentDidMount() {
        this.props.loadLeagueMembers( this.props.leage_name );        
        //this.props.loadLeagueTeams( this.props.leage_name );            
    }


    handleOnMemberClick = ( row ) => {   
        let user_name = row.username.substring(1);
        
        this.props.loadActiveMember( this.props.page.pagename, user_name );
        //this.props.changeTeamMembersModalState(true);
        this.setState({show_member_detail: true});
    }

    /*handleOnTeamClick = ( row ) => {          

        let page_name = row.pagename.substring(1);        
        this.props.loadActiveTeam(page_name);                
        this.props.changeTeamMembersModalState(true);
    }

    handleOnMemberModalCloseClick = ( is_open ) => { 
                      
        this.props.changeTeamMembersModalState(false);
 
    }

    handleOnTeamModalCloseClick = ( is_open ) => {                
        this.props.changeTeamMembersModalState(false);

    }*/

    handleOnDeleteTeamMember = () => {        
        this.props.deleteLeagueMember( this.props.leage_name, this.props.state.leagues.active_member?.username );
        this.props.changeTeamMembersModalState(false);
        this.setState({show_member_detail: false});
    }

    /*handleOnDeleteTeam = () => {
        
        this.props.deleteLeagueTeam( this.props.leage_name, this.props.state.leagues.active_team.pagename );
        this.props.changeTeamMembersModalState(false);

    }*/


    handleOnTeamMemberProfileClick = () => {  

        if( this.props.state.leagues.active_team.pagename ) {
            this.props.history_member_team.push(`/page/${this.props.state.leagues.active_team.pagename}`);
        }
        if( this.props.state.leagues.active_member.username ){
            this.props.history_member_team.push(`/profile/${this.props.state.leagues.active_member.username}`);
        }
    }

    handleOnViewTournamentClick = ( tournament ) => {   
        this.props.seeTournamentFromMemberOrTeamModal({ "tournament": tournament });
        this.props.history_member_team.push(`/league/tournament/${this.props.leage_name}/`);
    }

    prepareMembersList(rows_members){
        let rows_members_users = rows_members.results;
        let format_rows_members = [];

        if(rows_members_users){            
            for(var j = 0; j < rows_members_users.length; j++){            
                format_rows_members.push({
                    "id": rows_members_users[j].id, 
                    "name": rows_members_users[j].first_name + " " + rows_members_users[j].last_name, 
                    "photo": rows_members_users[j].photo,
                    "email": rows_members_users[j].email ? rows_members_users[j].email : " ",
                    "username": "@" + rows_members_users[j].username
                });
            }
        }
    
        format_rows_members = format_rows_members?.filter(user => user.name !== " " && user.name !== "");
        return format_rows_members;
    }    
    
    render() {
        
        return (
            <React.Fragment>
                {!this.state.show_member_detail &&
                    <div className="teams-members__container">

                        <div className="teams-members__center">

                            <div className="teams-members__center__header">
                                <div className="teams-members__center__header__content">                        
                                    <div className="teams-members__center__header__content__title">
                                        <p className="teams-members__center__header__content__title__text">
                                            {this.props.t('options.teamsAndMembers.labelTitleStaff')}
                                        </p>
                                        
                                    </div>
                                    <p className="teams-members__center__header__content__title__description">
                                    {this.props.t('options.teamsAndMembers.labelDescription')}
                                </p>
                                </div>
                            </div>
                            <div className="teams-members__center__header__button">
                                        <RoundedButtonComponent 
                                            type="button"
                                            disabled={ false }
                                            onClick={() => { this.props.handleOnAddMemberButtonClick() } }     
                                            className="small inverse"                        
                                        >
                                            <span>{this.props.t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelTitle')}</span>                                           
                                        </RoundedButtonComponent>
                                    </div>
                        </div>

                        { this.props.league_members_is_loading &&         
                            <Skeleton animation="wave" variant="rect" component="div" height={300} style={{ marginTop: 30 }}/>                    
                        }
                        
                        { !this.props.league_members_is_loading &&
                            <div className={"teams-merbers__table__content"}>
                                <TableComponent                   
                                rows = { this.prepareMembersList(this.props.state.leagues.league_members) }
                                columns = { [
                                    "photo",
                                    "name",
                                    "username",                                
                                    "email",
                                ] }
                                columns_headers = { [
                                    "",
                                    this.props.t('options.teamsAndMembers.tabs.tabStaff.columns.labelName'),
                                    this.props.t('options.teamsAndMembers.tabs.tabStaff.columns.labelUser'),                                
                                    this.props.t('options.teamsAndMembers.tabs.tabStaff.columns.labelMail'),                                
                                ] }
                                has_image_column = { true }
                                image_column = { 0 }
                                rows_per_page = { 15 }
                                pagination = { true }
                                handleOnActionClick = { this.handleOnMemberClick }
                                action_column = { true }
                                search_by = "name"
                                searchbar_by = "name"
                                order_by = "name"
                                placeholder_search_bar = { this.props.t('options.teamsAndMembers.tabs.tabStaff.labelSearch') }
                                has_searchbar = { true }
                                has_add_button = { false }  
                                has_button_filter = { false }  
                                add_button_caption = { this.props.t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelTitle')}                                                        
                                filter_buttons_captions = { [ ] }    
                                handleOnAddButtonClick = {  this.props.handleOnAddMemberButtonClick }            
                                msg_empty_table_title={ this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.emptyMemberTableTitle')}
                                msg_empty_table_text={ this.props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.emptyMemberTableText')}
                                itemClicLabel={ this.props.t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelBtnSeePage') }

                            >
                            </TableComponent>
                            </div>
                        }
                    </div>
                }  
                {this.state.show_member_detail &&             
                    <div className='teams-members__staff-detail__container'>
                        <div className='teams-members__staff-detail__title'>
                            <span className='icon-arrow-left' onClick={ () => this.setState({show_member_detail: false}) } />
                            <h5 className='baloa-headline-5'>{ this.props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailTitle') }</h5>
                        </div>                        
                        <LeagueTournamentStaffMemberComponent 
                            active_member = { this.props.state.leagues.active_member }
                            handleOnMemberProfileClick = { this.handleOnTeamMemberProfileClick }
                            handleOnDeleteMemberProfileClick = { this.handleOnDeleteTeamMember }                        
                        />
                        <LeagueUserPermissionsComponent
                            page= {this.props.page}
                            active_member = { this.props.state.leagues.active_member }
                            handleSavePermissions = { this.props.handleSavePermissions }
                        />
                    </div>
                }                                        
            </React.Fragment>
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadLeagueMembers: (leage_name) => {
            return dispatch( loadLeagueMembers(leage_name) )
        },
        loadLeagueTeams: (leage_name) => {
            return dispatch( loadLeagueTeams(leage_name) )
        },
        changeTeamMembersModalState: (isOpen) => {
            return dispatch( changeTeamMembersModalStateAction(isOpen) )
        },
        loadActiveTeam: ( pagename ) => {            
            return dispatch( loadActiveTeam( pagename ) );
        },
        loadActiveMember: ( pagename, username ) => {            
            return dispatch( loadActiveMember( pagename, username ) );
        },
        deleteLeagueTeam: (league_pague, team_page) => {
            return dispatch(deleteLeagueTeam( league_pague, team_page ))
        },
        deleteLeagueMember: (league_pague, team_member) => {
            return dispatch(deleteLeagueMember( league_pague, team_member ))
        },
        seeTournamentFromMemberOrTeamModal: (new_state) => {
            return dispatch( seeTournamentFromMemberOrTeamModalAction(new_state) )
        },
        
    }
}

export default compose(withTranslation("league"), connect(mapStateToProps, mapDispatchToProps))(TeamsMembersComponent);
