// React
import React from 'react';

// Styles
import './LeagueNewTeamStepsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueNewTeamStepsComponent ( props ) {

    const { step } = props;

    return(
        <div className="new_team_steps__box">
            <div className="new_team_steps__box__title">
                
                <p className={ "new_team_steps__box__title__text complete-text" }
                 /*onClick={ newTeamStepState.basic_info_step? ()=>{ props.stepCompleted(0) } : undefined }*/>{props.t('newTeam.step.basicInfo')}</p>
                <p className={ step === 1 ? "new_team_steps__box__title__text complete-text" : "new_team_steps__box__title__text"} /*onClick={ newTeamStepState.players_step?  ()=>{ props.stepCompleted(1) } : undefined }*/>{props.t('newTeam.step.players')}</p>
                
               
            </div>
            <div className="new_team_steps__box__steps">
                
                <div className={ "new_team_steps__box__steps__point complete-point"}></div>            
                <div className={step === 1 ? "new_team_steps__box__steps__line complete-text":"new_team_steps__box__steps__line"}></div>
                <div className={step === 1 ? "new_team_steps__box__steps__point complete-point":"new_team_steps__box__steps__point"}></div>
                
            </div>  
              
        </div>
    )
}

export default withTranslation('team')(LeagueNewTeamStepsComponent);