/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './TeamVSTeamPhotosComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

export default function TeamVSTeamPhotosComponent(props) {

    const { photo_1 = null, photo_2 = null, photoOnly = false } = props;
    
    if(photoOnly)
    {
        const photo = photo_1 == null ? photo_2 : photo_1;
        return (
            <React.Fragment>
                <div class="team-vs-team__photos">
                    <div class="team-vs-team__photos__image">
                        {   photo && photo != ""
                            ?
                            <img src={ photo } alt="team-img" />
                            :
                            <span className="icon-ball" color={getRandonIconBallColor()}/>
                        }
                    </div>           
                </div>
            </React.Fragment>
        )
    }
    else
    {
        return (
            <React.Fragment>
                <div class="team-vs-team__photos">
                    <div class="team-vs-team__photos__image">
                        {   photo_1 && photo_1 != ""
                            ?
                            <img src={ photo_1 } alt="team-img" />
                            :
                            <span className="icon-ball" color={getRandonIconBallColor()}/>
                        }
                    </div>
                    <p className="team-vs-team__photos__vs">VS</p>
                    <div class="team-vs-team__photos__image">                    
                    {   photo_2 && photo_2 != ""
                        ?
                        <img src={ photo_2 } alt="team-img" />
                        :
                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                    }                    
                    </div>                       
                </div>
            </React.Fragment>
        )
    }
}