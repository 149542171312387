// React
import React from 'react';

//Material
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './ProfileMatchesComponent.styles.css';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileMatchesComponent( props ) {
    const [fileInputRef, setFileInputRef] = React.useState(null);

    const onVideoChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'matchVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    return (
        <div className="profile-matches__container">
            
            <div className='profile-matches__table' >
                {props.profile_matches?.length > 0 ? 
                    <div className='profile-matches__table-body'>
                        { props.profile_matches?.slice(0,3).map((match, index) => (
                            <React.Fragment>                            
                            <div key={index} className='profile-matches__table-body__container'>
                                <div className='profile-matches__tournament-match-info'>
                                    <div className='profile-matches__tournament_info'>
                                        <div className='profile-matches__tournament_info__img'>
                                            { match.tournament_logo
                                                ? <img src={match.tournament_logo} alt={`${match.tournament_name} logo`} />
                                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                                            }
                                        </div>
                                        <div className='baloa-table'>{match.tournament_name}</div>                                                                         
                                    </div>
                                    <div className='profile-matches__match_date baloa-table'>
                                        {`${moment(match.match_date + ' ' + match.match_time).format('MMM DD, YYYY - h:mm A')}`}
                                    </div>                              
                                </div>
                                <div className={`profile-matches__tournament-match-data ${!match.has_paid_stats ? 'profile-matches__locked_values': ''}`} onClick={match.has_paid_stats? ()=>{ props.handleOnMatch(match.tournament_id, match.tournament_phase_id, match.tournament_date_id, match.id)} : ()=>{}}>
                                    <div className="profile-matches__tournament-match-data__local">
                                        {match?.local_team_photo && 
                                            <img src={ match.local_team_photo } alt={`${match.local_team_name} img`}/>
                                        }
                                        {!match.local_team_photo && 
                                            <span className="icon-ball" color={getRandonIconBallColor()}/>
                                        }
                                        <div className="profile-matches__tournament-match-data__label baloa-table">{ match.local_team_name }</div>
                                    </div>
                                    <div className='profile-matches__tournament-match-data__score'>
                                        <div className="profile-matches__tournament-match-data__score__left">
                                            <div className='baloa-subtitle-2' >{ match.local_team_score }</div>
                                            { match.local_team_penalty_score && <div className='baloa-subtitle-2' >{ `(${match.local_team_penalty_score}` }</div>}                            
                                        </div>
                                        <div className="profile-matches__tournament-match-data__score__center">
                                            <div className='baloa-subtitle-2' >-</div>
                                            {match.local_team_penalty_score && <div className='baloa-subtitle-2' >-</div>}                            
                                        </div>
                                        <div className="profile-matches__tournament-match-data__score__right">
                                            <div className='baloa-subtitle-2' >{ match.visitant_team_score }</div>
                                            { match.visitant_team_penalty_score && <div className='baloa-subtitle-2' >{ `${match.visitant_team_penalty_score})` }</div>}
                                        </div>
                                    </div>
                                    <div className="profile-matches__tournament-match-data__visitor">
                                        {match?.visitant_team_photo && 
                                            <img src={ match.visitant_team_photo } alt={`${match.visitant_team_name} img`}/>
                                        }
                                        {!match.visitant_team_photo && 
                                            <span className="icon-ball" color={getRandonIconBallColor()}/>
                                        }
                                        <div className="profile-matches__tournament-match-data__label baloa-table">{ match.visitant_team_name }</div>                                   
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <Accordion className='profile-matches__clips'>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className='baloa-mini'
                                    >                                    
                                        <span className='hubicon-video_library'></span><div className='baloa-table'>{props.t('tournamentSummary.labelClips')}</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {match.post_videos.length > 0 &&
                                            match.post_videos.map(clip =>(
                                                <div className='profile-matches__clips__video-container'                                                
                                                        onClick={() => {
                                                        props.handleProfilePostModal(clip, 'matchVideoClip');
                                                    }}>
                                                    {clip.post_stream?.thumbnail
                                                        ? <img className=""
                                                            src={clip.post_stream?.thumbnail}
                                                            alt={'matchVideoClip'}
                                                        />
                                                        : 
                                                        <video className=""
                                                            src={clip?.post_video}
                                                            Spreload="auto"
                                                            muted
                                                            autoPlay={false}
                                                            controls={false}
                                                        />                                                   
                                                    }
                                                    <span className={"profile-matches__clips__video-control-icon"} />
                                                    <div className="baloa-mini">{clip.caption? clip.caption : props.t('addVideoClip.labelfeaturedClip')}</div>
                                                </div>
                                            ))
                                        }
                                    {match.post_videos.length <= 0 && !props.is_owner &&
                                        <div className='profile-matches__clips__empty-clips baloa-username'>
                                            <span className='hubicon-play_circle'></span>
                                            {props.t('No hay clips de partido')}
                                        </div>
                                    }
                                    {props.is_owner &&
                                            <div className={`profile-matches__clips__video-empty baloa-table ${(!props.is_owner || !match.has_paid_stats)? 'disabled' : ''}`} onClick={ (match.has_paid_stats && props.is_owner)? () => {fileInputRef.click(); props.setClipMatch(match.id)} :() =>  {console.log('blocked')} } >
                                                <span className='hubicon-add_circle' />
                                                <input
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    onChange={(event) => onVideoChange(event)}
                                                    ref={video_input => setFileInputRef(video_input)}
                                                    accept="video/*"
                                                    value=""
                                                />
                                                {props.t('tournamentSummary.labelAddVideo')}
                                            </div>
                                        }
                                    
                                    {!match.has_paid_stats &&
                                            <div className='profile-matches__blocked-message baloa-names'>
                                                <span className='hubicon-info'></span>
                                                {props.is_owner?
                                                <React.Fragment>
                                                    {props.t('tournamentSummary.clipsBlocked')}
                                                        <PrimaryButtonComponent
                                                            type='button'
                                                            onClick={()=>{ props.handleModalUnlockData() }}
                                                            className='profile-matches__blocked-message__button small'
                                                        >
                                                            <span>{props.t('tournamentSummary.unlockBtn')}</span>
                                                        </PrimaryButtonComponent>
                                                    </React.Fragment>
                                                :
                                                props.t('tournamentSummary.visitantBlockedText',  {user_name: props.username})
                                            }
                                            
                                        </div>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            </React.Fragment>
                        ))}
                        
                    </div>
                    : ''
                }
            </div>
        </div>
    )
}

export default withTranslation('profile')(ProfileMatchesComponent);

