
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Formik
import { Field } from 'formik';

// Styles
import './ProfileBasicInfoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileBasicInfoModalComponent( props ) {
    
    const formInitialValues = {
        profile_type: props.model?.profile_type,
    }

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('data.modal.title')}
        >
            <div className="basic-info-form">

                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        props.onNewData( values );
                    }}
                    className="basic-info-form__form form"
                    functionDisabled={()=>{}}
                >
                    <p className="basic-info-form__form__title baloa-headline-5">{props.t('data.modal.text1')}</p>
                    <div className="basic-info-form__form__fields form__fields">
                        <Field name="profile_type">
                            {
                                ({
                                    field,
                                    form: { setFieldValue }, 
                                    meta,
                                }) => (

                                    <React.Fragment>
                                        <div 
                                        onClick = { () => { setFieldValue('profile_type', 'player') } } 
                                        className={ "basic-info-form__form__fields__field " + ( field.value == 'player' ? 'active' : '' ) }
                                        >
                                            <div className="basic-info-form__form__fields__field__icon">
                                                <span className="icon-player"></span>
                                            </div>
                                            <div className="basic-info-form__form__fields__field__info">
                                                <p className="basic-info-form__form__fields__field__caption baloa-body-1">{props.t('data.modal.text2')}</p>
                                                <p className="basic-info-form__form__fields__field__description baloa-body-2">
                                                {props.t('data.modal.text3')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('profile_type', 'fanatic') } } 
                                        className={ "basic-info-form__form__fields__field " + ( field.value == 'fanatic' ? 'active' : '' ) }
                                        >
                                            <div className="basic-info-form__form__fields__field__icon">
                                                <span className="icon-fan"></span>
                                            </div>
                                            <div className="basic-info-form__form__fields__field__info">
                                                <p className="basic-info-form__form__fields__field__caption baloa-body-1">{props.t('data.modal.text4')}</p>
                                                <p className="basic-info-form__form__fields__field__description baloa-body-2">
                                                    {props.t('data.modal.text5')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('profile_type', 'professional') } } 
                                        className={ "basic-info-form__form__fields__field " + ( field.value == 'professional' ? 'active' : '' ) }
                                        >
                                            <div className="basic-info-form__form__fields__field__icon">
                                                <span className="icon-coach"></span>
                                            </div>
                                            <div className="basic-info-form__form__fields__field__info">
                                                <p className="basic-info-form__form__fields__field__caption baloa-body-1">{props.t('data.modal.text6')}</p>
                                                <p className="basic-info-form__form__fields__field__description baloa-body-2">
                                                {props.t('data.modal.text7')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </Field>
                    </div>

                    <div className="basic-info-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="basic-info-form__form__submit"
                        >
                            <span>{props.t('data.modal.btnContinue')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileBasicInfoModalComponent);