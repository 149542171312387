// React
import React, { useEffect} from 'react'; 

// Styles
import './TemplateCarnetComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//Assets
import template_baloa from 'assets/images/template-baloa.png';
import upload_file from 'assets/images/upload_file.png';
import vertical from 'assets/images/vertical.png';
import horizontal from 'assets/images/horizontal.png';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

function TemplateCarnetComponent(props){

    const [templateRadio, setTemplateRadio] = React.useState('baloa_template');
    const [orientationRadio, setOrientationRadio] = React.useState('vertical');
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [existingFile, setExistingFile] = React.useState(null);
    const [refInput, setRefInput] = React.useState(null);

    useEffect(()=>{
        props.handleSelectedOrientation(orientationRadio);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[orientationRadio] );

    const handleRadioChangeTemplate = (event) => {
       switch (event.target.value) {
        case "baloa_template":
            setTemplateRadio(event.target.value);            
            handleCleanFile();
            break;
        case "image_template":
            refInput.click(); 
            setTemplateRadio(event.target.value);
            break;
        default:
            break;
       }
    };

    const handleRadioChangeOrientation = (event) => {
        switch (event.target.value) {
         case "vertical":
            setOrientationRadio(event.target.value);
             break;
         case "horizontal":
            setOrientationRadio(event.target.value);         
             break;
         default:
             break;
        }
     };

    
    //  const filesToArray = files => {
    //     if (!files) return [];
    //     const array = [];
    //     for (let i = 0; i < files.length; i += 1) {
    //         array.push(files.item(i));
    //     }
    //     return array;
    // }

    const onFilesAdded = event => {
        setSelectedImage(event.target.files[0]);
        setExistingFile(event.target.files[0]);
        event.preventDefault();
    }

    const handleReaderLoaded = async ( readerEvent ) => {
        let binaString = await readerEvent.target.result;
        props.handleSelectedImage(templateRadio,selectedImage,`${selectedImage.name};${btoa(binaString)}`);
     }

    React.useEffect(()=> {
        onFileUpload(); },
    [selectedImage]); // eslint-disable-line

    const onFileUpload = () => {
        if(selectedImage){
            const reader = new FileReader();
            reader.onload = handleReaderLoaded.bind(this);
            reader.readAsBinaryString(selectedImage); 
        }                
    }

    const onDragover = e => e.preventDefault();

    const handleCleanFile = () => {    
        setRefInput(null);
        setSelectedImage(null);
        setExistingFile(null);
        props.handleSelectedImage("image_template",'', '');
    }

    return(
        <div className="template-carnet">
            <div className="template-carnet__title baloa-normal-text">
                {props.t("createCarnet.modal.titleCarnet")}
            </div>
            <div className="template-carnet__template">
                <RadioGroup aria-labelledby="template" name="template_carnet" value={templateRadio} onClick={handleRadioChangeTemplate } className="template-carnet__template" >
                    <div className="template-carnet__template__container">
                        <img src={template_baloa} alt="form" width="30px"/>
                        <FormControlLabel value="baloa_template" control={<Radio color='secondary' />} labelPlacement={"start"} label={`${props.t("createCarnet.modal.baloaTemplete")}`} className="template-carnet__template__container__radio"/>
                    </div>
                        
                    <div className={"template-carnet__template__container__two__image"} onDragover={onDragover} onDrop={onFilesAdded}>
                        <div className="template-carnet__template__container__two">
                            <img src={upload_file} alt="form" width="30px"/>
                                
                                <div className={"template-carnet__template__container__two__box"} onDragover={onDragover} onDrop={onFilesAdded}>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        accept="image/*"
                                        // name={id}
                                        // id={id}
                                        required={''}
                                        ref={(file) => setRefInput(file)}
                                        onChange={onFilesAdded} 
                                    />
                                    <FormControlLabel value="image_template" control={<Radio color='secondary'/>} labelPlacement={"start"} label={`${props.t("createCarnet.modal.uploadImage")}`} />
                                </div>                              
                        </div>
                        {(selectedImage?.name != null || existingFile != null) &&                           
                            <div className='baloa-username template-carnet__template__container__two__image__name'>
                                <span className="icon-image-70 template-carnet__template__container__two__image__name_image" />
                                <span className='baloa-username'>{selectedImage?.name}</span>
                                {existingFile?.length > 0 && <span className='baloa-username'>{existingFile?.split(';')[0]}</span>}
                                <TextButtonComponent
                                    disabled=''
                                    className="template-carnet__delete-button"
                                    onClick={() => handleCleanFile()}
                                    type={'button'}
                                >
                                    <span className="icon-x" />
                                </TextButtonComponent>  
                            </div>
                        }
                    </div>
                </RadioGroup>
            </div>
            <div className="template-carnet__title baloa-normal-text">
                {props.t("createCarnet.modal.titleTemplate")}
            </div>
            <div className="template-carnet__orientation">
                <RadioGroup row aria-labelledby="orientation" name="orientation_carnet" value={orientationRadio} onClick={handleRadioChangeOrientation }>
                    <div className="template-carnet__orientation__container">
                        <img src={vertical} alt="form" width="24px"/>
                        <FormControlLabel value="vertical" control={<Radio color='secondary' />} labelPlacement={"start"} label={`${props.t("createCarnet.modal.orientationV")}`} />
                    </div>
                    <div className="template-carnet__orientation__container__two">
                        <img src={horizontal} alt="form" width="30px"/>
                        <FormControlLabel value="horizontal" control={<Radio color='secondary' />} labelPlacement={"start"} label={`${props.t("createCarnet.modal.orientationH")}`} />
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}
export default withTranslation(["league"])(TemplateCarnetComponent);