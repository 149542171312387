// React
import React from 'react';

// Material UI
import Box from '@mui/material/Box';

// Formik
import { Field, useFormik } from 'formik';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import LeagueSearchMemberListComponent from 'app/leagues/components/league-search-member-list/LeagueSearchMemberListComponent';

//i18
import { useTranslation } from 'react-i18next';

// Styles
import './LeagueAddMemberModalComponent.styles.css';


function LeagueAddMemberToLeagueModalComponent( props ) {

    const formikvalues = useFormik({
        initialValues: {
            member: "",
        },
    });

    const { t } = useTranslation('league');
    const [searchedValue, setSearchedValue] = React.useState(null);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
    const [isInputFocus, setIsInputFocus] = React.useState('');
    const [isEmptySearch, setIsEmptySearch] = React.useState(true);

    return (
        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
        >
            <div className="scroll-parent">
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => { 
                   
                    let new_member_data = values['member'];
                    props.onNewData( new_member_data );
                    setIsBtnDisabled(true);
                    setIsEmptySearch(true);
                    formikvalues.initialValues('member', '' );
                    
                }}

                onKeyDown={(event) => {                    
                    if ((event.charCode || event.keyCode) === 13 ) {
                        if(searchedValue === null){
                            event.preventDefault();    
                        } else {
                            setSearchedValue(null)
                        }
                    }
                }}
                
                className="league-add-member-form__form form scroll-parent"
                autocomplete="off"
            >
                <Box className="league-add-member-form__box">
                    <InputSearchComponent
                        placeholder={t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelSearch')}
                        handleSearch={(v) => { 
                            props.handleSearchMember(v); 
                            if(v !== ''){setIsEmptySearch(false)}
                        }}
                        autoFocus = {isInputFocus}
                      
                    />

                    <Field name="member">
                        {
                          ({
                              form: { setFieldValue }, 
                          }) => (
                              <React.Fragment>
                                { props.profiles.length !== 0 &&
                                    
                                    <LeagueSearchMemberListComponent 
                                        items={props.profiles}
                                        onSelect = { (selected_profile) => { 
                                            setFieldValue('member', selected_profile );
                                            setSearchedValue(selected_profile); 
                                            setIsInputFocus('true');
                                            setIsBtnDisabled(false); 
                                        } }
                                        set_type ={"member"}
                                    />                                   
                                }
                                 
                                <div className="league-add-team-form__box__without-results">
                                    { props.profiles.length === 0 && !isEmptySearch &&
                                        <p className="baloa-subtitle-2">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelResults')}</p>
                                    }
                                </div>
                                
                              </React.Fragment>
                            )
                        }
                    </Field>
                </Box>
                <div className="league-add-member__footer">
                  <PrimaryButtonComponent 
                      type="submit"
                      disabled={isBtnDisabled}
                      onClick={()=>{}} 
                      className="league-add-member__actions__submit"
                  >
                      <span>{t('options.teamsAndMembers.tabs.tabStaff.labelButtonAdd')}</span>
                  </PrimaryButtonComponent>
                </div>
            </FormComponent>
            </div>
        </Modal2Component>

    )
}

export default LeagueAddMemberToLeagueModalComponent;
