// React
import React from 'react'

// Styles
import './ProfileExperiencesComponent.style.css'


// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import ProfileExperienceComponent from 'app/profiles/components/profile-experience/ProfileExperienceComponent';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileExperiencesComponent( props ) {

    return (

        <Card1Component 
            className={ "profile-experiences " + ( props.className ? props.className : '' ) } 
            title={props.experiences_type === 'achievements' ? props.t('profileExperiences.achievementsLabel') : props.experiences_type === 'trajectory' ? props.t('profileExperiences.trajectoriesLabel') : props.t('profileExperiences.experiencesLabel')}
            button={ props.is_owner && ( 
                <span className="profile-experiences__new-button hubicon-add_option" 
                    
                />
            ) }
            onButtonClick={ () => {props.onNewButton(props.experiences_type)} }
        >

            <div className="profile-experiences__experiences">
                {props.experiences_type === 'achievements' &&
                    props.profile?.achievements?.length > 0 &&
                        props.profile?.achievements?.map( achievement => 
                            <ProfileExperienceComponent 
                                className="profile-experience__achievements"
                                key = { achievement.id }
                                experience = { achievement }                                                            
                                onEditButton={ props.onEditButton }
                                //onDeleteButton={ props.onDeleteButton }
                                is_owner = { props.is_owner }   
                                experiences_type={props.experiences_type}                         
                            />
                        )
                }
                {(props.experiences_type === 'trajectory' || props.experiences_type === 'experiences') &&
                    props.profile?.trajectories?.length > 0 &&
                        props.profile?.trajectories?.map( trajectory => 
                            <ProfileExperienceComponent 
                                className="profile-experience__achievements"
                                key = { trajectory.id }
                                experience = { trajectory }                  
                                onEditButton={ props.onEditButton }
                                //onDeleteButton={ props.onDeleteButton }
                                is_owner = { props.is_owner }   
                                experiences_type={props.experiences_type}                         
                            />
                        )
                }
                
                {//((props.experiences_type === 'trajectory' && props.profile?.trajectories?.length === 0) || (props.experiences_type === 'achievements' && props.profile?.achievements?.length === 0)) &&
                ((props.experiences_type === 'trajectory' && props.profile?.trajectories?.length === 0) || (props.experiences_type === 'achievements' && props.profile?.achievements?.length === 0) || (props.experiences_type === 'experiences' && props.profile?.trajectories?.length === 0)) &&
                    <div className='profile-experience__no-experiences__container'>
                        {props.is_owner && props.experiences_type === 'achievements' && <div className='baloa-normal'>{props.t('profileExperiences.ownerNoAchievementsExperienceData')}</div> }
                        {!props.is_owner && props.experiences_type === 'achievements' && <div className='baloa-normal'>{props.t('profileExperiences.noAchievementsExperienceData')}</div> }
                        {props.is_owner && props.experiences_type === 'trajectory' && <div className='baloa-normal'>{props.t('profileExperiences.ownerNoTrajectoriesExperienceData')}</div>}
                        {!props.is_owner && props.experiences_type === 'trajectory' && <div className='baloa-normal'>{props.t('profileExperiences.noTrajectoriesExperienceData')}</div>}
                        {props.is_owner && props.experiences_type === 'experiences' && <div className='baloa-normal'>{props.t('profileExperiences.ownerNoExperiencesData')}</div>}
                        {!props.is_owner && props.experiences_type === 'experiences' && <div className='baloa-normal'>{props.t('profileExperiences.noExperiencesData')}</div>}
                    </div>
                    
                }   
            </div>
        </Card1Component>        
    )
}

export default withTranslation('profile')(ProfileExperiencesComponent);
