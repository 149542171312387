// React
import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentListTeamsComponent.styles.css';

//Components
// import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentListTeamsComponent( props ) {

    const [unassigned, setUnassigned] = React.useState(true);
    const [eliminatedPhases, setEliminatePhases] = React.useState(false);

    React.useEffect(() => {
        if(props.cleantAllGroups){
            setUnassigned(true);
            setEliminatePhases(false);
            props.handleCleanAllGroups();
        }        
    }, [props.cleantAllGroups]); //eslint-disable-line

    React.useEffect(() => {
        if(props.teams_filter_type) {
            if(props.teams_filter_type === 'not_assigned') {
                setUnassigned(true);
                setEliminatePhases(false);
            } else {
                setEliminatePhases(true);
                setUnassigned(false);
            }
        }
    }, [props.teams_filter_type]);
    
    const handleChange = (event) => {
        var splt = event.target.value.split("&")
        props.handleAddFunction(splt[0], splt[1]);
    };

    const handleTeamFilter = (filterType)=> {
        if(filterType === 'not_assigned') {
            setUnassigned(true);
            setEliminatePhases(false);
            props.handle_team_filter('not_assigned');
        }
        if(filterType === 'eliminatedPhases') {
            setEliminatePhases(true);
            setUnassigned(false);
            props.handle_team_filter('eliminated_from_the_previous_phase');
        }
    };

    const handleOnAddTeamsToGroups = (add_type) => {
        if(props.isCreatePhase && add_type === 'add_random') {
            if(unassigned) {
                props.handleCreateNewAtomaticGroups(true, 'not_assigned');
            } else {
                props.handleCreateNewAtomaticGroups(true, 'eliminated_from_the_previous_phase');
                
            }
        }
        if(props.is_phase_edit && add_type === 'add_all') {
            if(props.edit_phase_type === 'create_group') {
                if(unassigned) {
                    props.handleOnAddAllTeamsToGroup(props.groups[props.groups.length - 1]?.id, 'not_assigned');
                } else {
                    props.handleOnAddAllTeamsToGroup(props.groups[props.groups.length - 1]?.id, 'eliminated_from_the_previous_phase');
                                        
                } 
            }
            if(props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_free') {
                if(unassigned) {
                    props.handleOnAddAllTeamsToGroup(props.edit_phase_selected_group?.id, 'not_assigned');
                } else {
                    props.handleOnAddAllTeamsToGroup(props.edit_phase_selected_group?.id, 'eliminated_from_the_previous_phase');
                    
                } 
            }
        }             
    };
    
    const addFreePhaseTeam = ( groupId, team ) => {
        props.handleAddFunction( groupId, team );
    }

    return (

        <div className="tournament-list-teams__container scroll-parent">
            <div className="tournament-list-teams__container__title baloa-subtitle-1">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelMyTeams')}</div>
            <div className='tournament-list-teams__container__subtitle baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelistTeamsSubtitle')}</div>
            <div className="tournament-list-teams__container_filter">
            {props.select_groups_teams &&
                <div className='tournament-invitation-inscription-setup__teams-filter'>
                    <div className={`filter-label baloa-table-column ${ unassigned ? 'selected-state': '' }`} 
                        onClick={()=>{handleTeamFilter('not_assigned')}} >{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelUnassigned')}</div>
                    <div className={`filter-label baloa-table-column ${ eliminatedPhases ? 'selected-state': '' }`} 
                        onClick={()=>{handleTeamFilter('eliminatedPhases')}} >{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelEliminated')}</div>
                </div>
            }
            { props.isCreatePhase &&
                <SecondaryButtonComponent className='tournament-list-teams__container_automaticGroups small'
                    onClick={ () => {handleOnAddTeamsToGroups('add_random')} }
                    disabled={props.teams?.length === 0 }
                 >
                   {  props.tournament_active_phase_type !== 'Free' && <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAsignAutomatically')}</span>}
                   {  props.tournament_active_phase_type === 'Free' && <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddAllTeamsToGroup')}</span>}
                    <span className='hubicon-shuffle'/>
                </SecondaryButtonComponent>
            }
            { props.tournament_active_phase_type !== 'Knockout' && props.is_phase_edit &&
                <SecondaryButtonComponent className='tournament-list-teams__container_automaticGroups small'
                    onClick={ () => {handleOnAddTeamsToGroups('add_all')} }
                    disabled={props.teams?.length === 0 }
                    >
                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddAllTeamsToGroup')}</span>
                    <span className='hubicon-shuffle'/>
                </SecondaryButtonComponent>
            }
            </div>
            {props.teams?.length === 0 && 
                <div className="tournament-set-groups__list-item__empty">
                    <div className='baloa-normal-medium'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.notEnoughModalTitle')}</div>
                </div>
            }
            <ul className="tournament-list-teams__list scroll">
                {props.teams?.map((item, index) => (
                    <li key={index} className={`tournament-list-teams__list-item ${props.disable_phase_fields ? '_disabled': ''}`}>
                        <div className='tournament-list-teams__info-item'>
                            { item.photo 
                                ?
                                <img src={ item.photo } alt="" className="tournament-list-teams__list-item__img"/>
                                :
                                <span className='icon-ball'  color={getRandonIconBallColor()} />
                            }
                            <div className="tournament-list-teams__list-item__text">
                                <div className='baloa-names'>{ item.name }</div>
                            </div>
                        </div>
                        {!props.disable_phase_fields && props.isCreatePhase && props.tournament_active_phase_type !== 'Free' &&
                            <FormControl className="tournament-list-teams__list-item__add-button">
                            <InputLabel shrink={false} className="tournament-list-teams__list-item__add-button-label baloa-hyperlink-2" id={`demo-simple-select-label_${index}`}>
                                {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddButton')}
                            </InputLabel>
                            <Select
                                className="tournament-list-teams__add-button"
                                labelId={`demo-simple-select-label_${index}`}
                                id={`demo-simple-select_${index}`}
                                value={null}
                                onChange={handleChange}
                                variant='standard'
                            >
                                {props.groups?.map((group, ig) => (
                                        <MenuItem key={group.id} value={(group.id + "&" + item.team+ "&" + item.id+ "&" + item.photo)}>
                                            { (props.tournament_active_phase_type === 'Groups' || props.tournament_active_phase_type === 'Free')?
                                                props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.caption.split(" ")[0]}`,{_number: group.caption.split(" ")[1]})
                                                :
                                                props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${group.caption.split(" ")[0]}`, {phase_name: props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase_name}`), _number: group.caption.split(" ")[1] })
                                            }
                                        </MenuItem>              
                                ))}
                            </Select>
                        </FormControl>
                        }
                        {!props.disable_phase_fields && props.isCreatePhase && props.tournament_active_phase_type === 'Free' &&
                            <div className="tournament-list-teams__phase-edit__add-button-label baloa-hyperlink-2" onClick={() => {addFreePhaseTeam(props.groups[0].id, item.team)}}>
                                {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddButton')}
                            </div>
                        }
                        { props.is_phase_edit &&
                            <React.Fragment>
                                { props.maxKnockoutPhaseTeams && <div className={`tournament-list-teams__phase-edit__add-button-label baloa-hyperlink-2 disabled`} onClick={() => {}}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddButton')}</div>}
                                { !props.maxKnockoutPhaseTeams && <div className={`tournament-list-teams__phase-edit__add-button-label baloa-hyperlink-2`} onClick={() => {props.handleAddTeamToEditGroup(item)}}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelAddButton')}</div>}

                            </React.Fragment>
                            
                        }
                        {/*<TextButtonComponent
                            disabled = { props.disabled_edit_tournament_data }
                            className="tournament-list-teams__add-button"
                            //
                            onClick={() => { props.handleAddFunction(item) }}
                        >
                            <span>{"Agregar"}</span>
                        </TextButtonComponent>*/}
                        
                    </li>

                    ))
                }
            </ul>
        </div>
    );
}

export default withTranslation('league')(TournamentListTeamsComponent);