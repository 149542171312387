// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";

export const PatchUserApi = ( new_data ) => {
    
    return Axios.instance.patch(`${api_url}/users/me/`, new_data , {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const SetUserLanguageApi = ( user_id, language ) => {
    return Axios.instance.patch( `${ api_url }/users/${user_id}/language/`, 
        {
            language: language.id
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const LoadLanguagesApi = (  ) => {
    return Axios.instance.get(`${api_url}/languages/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const CheckAccountStatusApi = ( account_id ) => {
    return Axios.instance.get(`${api_url}/account/${account_id}/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const getFormsApi = ( form_id ) => {
    return Axios.instance.get( `${ api_url }/forms/${form_id}/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}