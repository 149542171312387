
//React
import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

//Styles
import './TournamentGroupsNavbarComponent.styles.css';

function TournamentGroupsNavbarComponent (props) {
    
    const[isActive, setIsActive] = React.useState('');     
    const groups_array = props.groups ? props.groups : [];
    /* eslint-disable */
    React.useEffect( () => {

        if(props.groups ){
            handleGroupClick(props.groups[0], 0);
        }

    }, [ JSON.stringify(props.groups) ] )
    /* eslint-enable */
    
    const handleGroupClick = (group, index) => {  
        setIsActive(index);  
        props.handleGroupClick(group);            
    }

    const [scrollX, setscrollX] = React.useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = React.useState(false); // For detecting end of scrolling
    const scrl = React.useRef();
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    React.useEffect(() => {
        //Check width of the scollings
        scrollCheck();
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
    };

    return(
        <React.Fragment>
            <div className={ props.showGroupNavBar ? "league-tournament__groups__navbar" : "league-tournament__groups__navbar__hidden" } ref={scrl} onScroll={scrollCheck}>
                {!isMobile && scrollX !== 0 &&
                    <div class="league-tournament__groups__navbar__arrow prev" onClick={() => slide(-150)}>
                        <span class="hubicon-arrow_circle_left">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }
                { 
                    groups_array.map((group, index) => {
                        return(                            
                            <button 
                                key={index}                            
                                className={ `baloa-table ${isActive === index ? 'active' : ''}`} 
                                onClick={ () => { handleGroupClick(group, index) } } 
                            >
                                { (props.tournament_active_phase.phase_type === 'Groups' || props.tournament_active_phase.phase_type === 'Free' ) 
                                    ?
                                        props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.caption.split(" ")[0]}`,{_number: group.caption.split(" ")[1]})
                                    :
                                        props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${group.caption.split(" ")[0]}`, {phase_name: props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase.phase_name}`), _number: group.caption.split(" ")[1] })
                                }
                            </button> 
                        )
                    })
                }
                {!isMobile && !scrolEnd &&
                    <div class="league-tournament__groups__navbar__arrow next" onClick={() => slide(+150)}>
                        <span class="hubicon-arrow_circle_right">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </div>
                }                
            </div>
        </React.Fragment>
    )
}

export default withTranslation('league')(TournamentGroupsNavbarComponent);