
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentFixtureAcceptModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentFixtureAcceptModalComponent( props ) {
    
    return (
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={"tournament-fixture-accept-modal"}
        >
            <div className='tournament-fixture-accept-modal__container'>
                <div className='baloa-normal-medium'>{props.t('options.tournaments.acceptAutomaticfixtureModalMsg')}</div>   
                <div className='tournament-fixture-accept-modal__action-btns'>
                    <SecondaryButtonComponent className="small" onClick={() => {props.handleOnCancel()}}>{props.t('options.tournaments.pages.matchRefereesPayment.cancelBtn')}</SecondaryButtonComponent>
                    <PrimaryButtonComponent className="small" onClick={() => {props.handleOnAccept()}}>{props.t('options.tournaments.pages.matchRefereesPayment.confirmRefereeingCrewTransferOkBtn')}</PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentFixtureAcceptModalComponent);