/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './InscriptionDeadlineModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function InscriptionDeadlineModalComponent ( props ) {
    return(
        <ModalComponent
            isOpen={props.isOpen} 
            title={'title'}
            className='inscription-deadline__container'
        >
            <span className='hubicon-info' />
            <div className='baloa-headline-2'>{props.t('viewTournament.inscription.inscriptionDeadlineModal.modalTitle')}</div>
            <div className='baloa-normal-text'>{props.t('viewTournament.inscription.inscriptionDeadlineModal.modalText')}</div>
            <PrimaryButtonComponent onClick={() => {props.onClose()}}>{props.t('viewTournament.inscription.inscriptionDeadlineModal.modalBtn')}</PrimaryButtonComponent>
        </ModalComponent>
    )
}

export default withTranslation('tournament')(InscriptionDeadlineModalComponent);