// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards{
    text-align: center;
    background: var(--primary-very-dark-color);
    height: 130vh;
    padding-top: 30px;
}

.cards__loader-mobile {
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.cards__loader-mobile img {
    width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/cards/views/CardsView.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;AACd","sourcesContent":[".cards{\n    text-align: center;\n    background: var(--primary-very-dark-color);\n    height: 130vh;\n    padding-top: 30px;\n}\n\n.cards__loader-mobile {\n    height: 100vh;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n}\n\n.cards__loader-mobile img {\n    width: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
