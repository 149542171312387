export const NewErrorLogin = (accion,data) => {
    return (dispatch) => {
        switch (accion) {
            case 'ERROR_CODE':
                dispatch(ErrorLoginCode(data))
                break;

            // no default
        }
    }
}
export const ChangeErrorLogin = (accion) => {
    return (dispatch) => {
        switch (accion) {
            case 'ERROR_CODE':
                dispatch(ErrorLoginCode(""))
                break;
            
            // no default
        }
    }
}

export const ErrorLoginCode = (data) => {
    return { type: "ERROR_LOGIN_CODE", data }
}