// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-container{

}

.team-container__header{
    background: var(--white-color);
    padding: 24px;
    margin-top: 2px;
}

.team-container__header__title .baloa-headline-2{
    color: var(--primary-very-dark-color);
}

.team-container__header__button{
    margin-top: var(--space-and-half);
}
.team-container__header__button button span{
    text-transform: unset;
}

.team-container__header__description{
    font-family: var(--primary-font) !important;
    font-style: normal;
    font-weight: var(--font-medium) !important;
    font-size: var(--space);
    line-height: 20px;
    letter-spacing: 0.0015em;
    color: var(--basic-gray-color);
    margin-top: var(--space);
}

.team-container__table{
    background: white;
    padding: 27px;
    border-radius: 14px;
    box-shadow: 0px 1px 4px 0px rgb(150 150 150 / 25%);
    margin: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-team/LeagueTeamComponent.styles.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,8BAA8B;IAC9B,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,iCAAiC;AACrC;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,2CAA2C;IAC3C,kBAAkB;IAClB,0CAA0C;IAC1C,uBAAuB;IACvB,iBAAiB;IACjB,wBAAwB;IACxB,8BAA8B;IAC9B,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kDAAkD;IAClD,YAAY;AAChB","sourcesContent":[".team-container{\n\n}\n\n.team-container__header{\n    background: var(--white-color);\n    padding: 24px;\n    margin-top: 2px;\n}\n\n.team-container__header__title .baloa-headline-2{\n    color: var(--primary-very-dark-color);\n}\n\n.team-container__header__button{\n    margin-top: var(--space-and-half);\n}\n.team-container__header__button button span{\n    text-transform: unset;\n}\n\n.team-container__header__description{\n    font-family: var(--primary-font) !important;\n    font-style: normal;\n    font-weight: var(--font-medium) !important;\n    font-size: var(--space);\n    line-height: 20px;\n    letter-spacing: 0.0015em;\n    color: var(--basic-gray-color);\n    margin-top: var(--space);\n}\n\n.team-container__table{\n    background: white;\n    padding: 27px;\n    border-radius: 14px;\n    box-shadow: 0px 1px 4px 0px rgb(150 150 150 / 25%);\n    margin: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
