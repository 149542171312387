
// Action creators
import { 
    loadAttributesConstantsAction,
} from './AttributeActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Apis
import { 
    DeleteAttributeApi,
    GetAttributesConstantsApi,
    ValidateAttributeApi,
} from 'app/generalattributes/apis/AttributesApi';

// Actions
import { loadProfile } from 'app/profiles/actions/ProfileActions';

// i18next 
import i18n from 'i18next';


export const deleteAttribute = ( profile, attribute_data ) => ( dispatch ) => {

    DeleteAttributeApi( attribute_data )
        .then( response => {
            dispatch( loadProfile( profile.user.username ) );
        } )
        .catch( error => {
            // let attribute_type = new_attribute_data['atype'].replace('-','_');
            // dispatch( loadProfileErrors( { [attribute_type]: "Prueba de error" }, 'technics' ) )
            console.log( error );
        } )

}

export const loadAttributesConstants = () => ( dispatch ) => {

    GetAttributesConstantsApi()
        .then( response => {
            dispatch( loadAttributesConstantsAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'),
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const validateAttribute = ( attribute, username ) => ( dispatch ) => {
    ValidateAttributeApi( attribute )
        .then( response => {
            dispatch( loadProfile( username ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'),
                    error_message_is_open: true
                } ) )
            }
        } )
}
