// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.baloa-card-1 {
    background: var(--bg-card-color);
    /* border: 1px solid var(--border-card-color); */
    padding: var(--space-and-half);
    position: relative;
}

.baloa-card-1__title.baloa-normal-medium{
    color: var(--primary-dark-color);
}

.baloa-card-1__button {
    position: absolute;
    right: var(--space-and-half);
    top: var(--space-and-half);
}
.baloa-card-1__button:hover {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/card-1/Card1Component.styles.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;IAChC,gDAAgD;IAChD,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB","sourcesContent":["\n.baloa-card-1 {\n    background: var(--bg-card-color);\n    /* border: 1px solid var(--border-card-color); */\n    padding: var(--space-and-half);\n    position: relative;\n}\n\n.baloa-card-1__title.baloa-normal-medium{\n    color: var(--primary-dark-color);\n}\n\n.baloa-card-1__button {\n    position: absolute;\n    right: var(--space-and-half);\n    top: var(--space-and-half);\n}\n.baloa-card-1__button:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
