import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import LeagueSearchMemberTeamComponent from '../league-search-member-team/LeagueSearchMemberTeamComponent';


//Assets
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

import './TournamentAddStaffModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentAddStaffModalComponent(props) {
    const [memberSelected, setMemberSelected] = React.useState();
    const handleSelectMemberTeam = (item) => {
        setMemberSelected(item)
    }
    const handleClearMemberTeam = (item) => {
        setMemberSelected(null);
    }
    const handleAddMemberTeam = () => {
        if(memberSelected){
            props.handleCleanBaloaUsers();
            props.handleAddStaff(memberSelected);
            setMemberSelected(null);
        }
    }
    const handleCleanTeamMemberSearch = () =>{
        props.handleCleanBaloaUsers();
    }

    const handleClose = () =>{
        props.handleCleanBaloaUsers();
        setMemberSelected(null);
        props.onClose();
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={()=>handleClose()}
            title={props.title}
            className='add-staff-modal__container'
        >
            <div className='add-staff-modal__search'>
                <div className="add-staff-modal__search baloa-table">
                    {props.modal_subtitle}
                </div>
                
                <div className="add-staff-modal__search__form">
                    {memberSelected?

                        <ListItem className='add-staff-modal__search-selected'
                            //key={item.id}
                            //button
                            //selected={selectedIndex === item.id}
                            //onClick={(event) => handleListItemClick(event, item)}
                        >
                            {props.set_type === 'member' &&
                                <React.Fragment>
                                    <ListItemIcon className="league-search-profile__icon" >
                                        {memberSelected.photo &&
                                            <img className="league-search-profile__icon__icon__img" src={ memberSelected.photo} alt={`${memberSelected.username} img`} />
                                        }
                                        {!memberSelected.photo &&
                                            <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                        }                              
                                        
                                    </ListItemIcon>
                                    <ListItemText primary={ memberSelected.first_name + ' ' + memberSelected.last_name} secondary={ '@'+memberSelected.username} className="league-search-profile__listitem league-search-profile__profile" />
                                </React.Fragment>
                            }
                            
                            {props.set_type === 'team' &&
                                <React.Fragment>
                                    <ListItemIcon className="league-search-profile__icon" >
                                        {memberSelected.team_logo &&
                                            <img className="league-search-profile__icon__icon__img" src={ memberSelected.team_logo} alt={`${memberSelected.teamname} img`} />
                                        }
                                        {!memberSelected.team_logo &&
                                            <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                        }                              
                                    </ListItemIcon>
                                    <ListItemText primary={ memberSelected.team } secondary={ '@'+memberSelected.teamname} className="league-search-profile__listitem league-search-profile__profile" />
                                </React.Fragment>
                            }
                            <ListItemSecondaryAction onClick={()=>{handleClearMemberTeam();}}>
                                <span className='hubicon-clear_option'></span>
                            </ListItemSecondaryAction>
                        </ListItem>

                    :
                        <LeagueSearchMemberTeamComponent
                            className="add-players-to-team__search"
                            searchType={props.searchType}
                            //handleSearchMember={ props.handleSearchBaloaUser }
                            handleSearchMember={ (word)=>{props.handleSearchBaloaUser(word);} }
                            profiles={ props.users }
                            onSelectMemberTeam={ (item)=>{handleSelectMemberTeam(item)}}
                            handleCleanMemberSearch={()=>{handleCleanTeamMemberSearch()}}
                            set_type={props.set_type}
                            tournament_id={props.tournament_id}
                            modal_search_placeholder={props.modal_search_placeholder}
                            id={'add-players-to-team__search'}
                            removeWordLenghtValidation={false}
                        />
                    }
                    <div className='add-staff-modal__search__button'>
                        <PrimaryButtonComponent
                            type="button"
                            disabled={false} 
                            onClick={() => {handleAddMemberTeam()}} 
                            className="add-staff-modal__search__button__submit-button small"
                        >
                            <span>{props.modal_button_label}</span>
                        </PrimaryButtonComponent>
                    </div>
                </div>
            </div>
            <div className={"add-staff-modal__content"}>
                <div className="add-staff-modal__o baloa-table">
                    {props.t('O')}
                </div>
                <div className="add-staff-modal__description baloa-table">
                    <span className={props.modal_icon_share_desc} />
                    {props.modal_share_title}
                </div>
                <div className="add-staff-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                    <span className="baloa-table">
                        {props.share_url}
                    </span>
                    <span className="icon-copy-86"></span>
                </div>
                <div className="add-staff-modal__condition baloa-username">
                    {props.modal_share_note}
                </div>
            </div>
            
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentAddStaffModalComponent);