/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './QuoteModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import QuotedPostComponent from '../quoted-post/QuotedPostComponent';



function QuoteModalComponent ( props ){

    const [options, setOptions] = React.useState(null); // eslint-disable-line
    const [errors, setErrors] = React.useState( {caption:''} ); // eslint-disable-line
    
    const formInitialValues = {
        caption: '',
        post_id: props.post?.id
    }

    // Video controls
    let video_player = null;
    React.useEffect( () => {
        if ( video_player != undefined ) {
            // Play video when father component tells to
            if ( props.play_video ) { video_player.play(); }
            else { video_player.pause(); }
            // Mute video when father component tells to
            video_player.muted = !props.play_audio;
        }
    }, [ props.play_video, props.play_audio ] )  // eslint-disable-line

    const setOptionsPages = () => {
        let optArray = [];
        optArray.push([props.user.me.username, props.user.me.profile_id, props.user.me.photo]);
        /* if (props.profile.loggedin.pages) {
            props.profile.loggedin.pages.map((p) =>
            optArray.push([p.pagename, p.id, p.photo])
        ); */

        setOptions(optArray);          
        
    };

    React.useEffect( () => {
        if (props.isOpen) {
            setOptionsPages();
        } 
    }, [ props.isOpen ]) // eslint-disable-line

    return(
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={ props.t('quoteModal.title') }           
        >         
            <div className="quote-modal__container">
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        let post_data = {post_context:values.post_id, caption:values.caption}
                        props.onNewData(post_data);
                        props.onClose();
                    }}
                    className="quote-modal__form scroll-parent"
                    functionDisabled={() => { }}
                >
                    <div className={"quote-modal__user"}>
                        {props.user.me.photo &&
                            <img className="quote-modal__user-image" alt={props.user.me.name_user} src={props.user.me.photo} />
                        }
                        {!props.user.me.photo &&
                            <span className={"quote-modal__user-image icon-ball"} color={getRandonIconBallColor()}></span>
                        }
                        <div className={"quote-modal__user-name baloa-table"}>{props.user.me.name_user}</div>
                    </div>
                    {/* Comment input */}
                    <InputFieldMultilineComponent
                        /* onChange={this.setCaption.bind(this)} */
                        label=""
                        input_id="new-post-caption"
                        field_name="caption"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        className="quote-modal__caption"
                        input_type="text"
                        resetError={() => { }}
                        //error={{}}
                        error={errors}
                        message=""
                        placeholder={props.t('postModal.inputAddComment') }
                        rows={1}
                    />
                    <input type="hidden" name="post_id" value={ props.post?.id } />
                    
                    <QuotedPostComponent 
                        post={props.post}
                        followPostProfile= { props.followPostProfile }
                        unfollowPostProfile= { props.unfollowPostProfile }
                        followPostPage= { props.followPostPage }
                        unfollowPostPage= { props.unfollowPostPage }
                    />
                    <div className="quote-modal__actions">
                        <PrimaryButtonComponent
                            type="submit"
                            disabled={false}
                            className="new-post__form__submit xs"
                        >
                            <span>{props.t('postModal.btnPost')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </FormComponent>
            </div>
        </Modal2Component>
    )

}
export default withTranslation('post')(QuoteModalComponent);
