//React
import React from 'react';


//Styles
import './LeagueTournamentInfoComponent.styles.css';

//i18
import { useTranslation } from 'react-i18next';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';



function LeagueTournamentInfoComponent(props) {

    const { t } = useTranslation('league');

    return (
        /* eslint-disable */
        <div className={props.className ? props.className : 'post__content__tournament-result__container__tournament'}>
            <p className='baloa-subtitle-2'>{props.tournament_info?.name}</p>
            <p className='baloa-table'>{props.tournament_info?.tournament_date}</p>
            <p className={'post__content__tournament-result__badge' + ' ' + (props.tournament_info?.state === 'Scheduled' ? 'post__content__tournament-result__badge-scheduled' : 'post__content__tournament-result__badge-finalized')}>
                {props.tournament_info?.state === 'Scheduled' ? t('scheduled') : t('ended')}
            </p>

            <div className='post__content__tournament-result__score-result'>
                <div className='post__content__tournament-result__score-result__left-col'>
                    <div className='post__content__tournament-result__score-result__score-left'>
                        {props.display === 'post'?
                            <React.Fragment>
                                <div className="shield">
                                    {props.tournament_info.local_team.photo === null && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    {props.tournament_info.local_team.photo !== null && <img src={props.tournament_info.local_team.photo} alt="" />}
                                    <p className='baloa-table-column'>{props.tournament_info.local_team.name}</p>
                                </div>
                                {props.tournament_info?.state !== 'Scheduled' ?
                                    <h3 className='baloa-headline-1'>{props.tournament_info.local_team.score || 0}</h3>
                                    : ''
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="shield">
                                    {props.tournament_info.local_team_photo === null && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    {props.tournament_info.local_team_photo !== null && <img src={props.tournament_info.local_team_photo} alt="" />}
                                    <p className='baloa-table-column'>{props.tournament_info.local_team_name}</p>
                                </div>
                                {props.tournament_info?.state !== 'Scheduled' ?
                                    <h3 className='baloa-headline-1'>{props.tournament_info.local_team_score || 0}</h3>
                                    : ''
                                }
                                
                            </React.Fragment>
                        }
                    </div>
                </div>

                {props.tournament_info?.state === 'Scheduled' ?
                    <div className='post__content__tournament-result__vs'>
                        <h3 className='baloa-headline-1'>vs</h3>
                    </div>
                    : <h3 className='baloa-headline-1'>-</h3>
                }

                <div className='post__content__tournament-result__score-result__right-col'>
                    <div className='post__content__tournament-result__score-result__score-right'>
                        {props.display === 'post'?
                            <React.Fragment>
                                {props.tournament_info?.state !== 'Scheduled' ?
                                    <h3 className='baloa-headline-1'>{props.tournament_info.visitant_team.score || 0}</h3>
                                    : ''
                                }
                                <div className="shield">
                                    {props.tournament_info.visitant_team.photo === null && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    {props.tournament_info.visitant_team.photo !== null && <img src={props.tournament_info.visitant_team.photo} className='post__content__tournament-result__score-result__score-right-icon-team-vs' alt="" />}
                                    <p className='baloa-table-column'>{props.tournament_info.visitant_team.name}</p>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {props.tournament_info?.state !== 'Scheduled' ?
                                    <h3 className='baloa-headline-1'>{props.tournament_info.visitant_team_score || 0}</h3>
                                    : ''
                                }
                                <div className="shield">
                                    {props.tournament_info.visitant_team_photo === null && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    {props.tournament_info.visitant_team_photo !== null && <img src={props.tournament_info.visitant_team_photo} className='post__content__tournament-result__score-result__score-right-icon-team-vs' alt="" />}
                                    <p className='baloa-table-column'>{props.tournament_info.visitant_team_name}</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>

            </div>



            <div className='post__content__tournament-result__info-date'>
                <div className='post__content__tournament-result__info-date__col'>
                    <span className='icon-calendar' />
                    <p className='baloa-table-column'>{props.tournament_info?.match_date}</p>
                </div>
                <div className='post__content__tournament-result__info-date__col'>
                    <span className='icon2-hour' />
                    <p className='baloa-table-column'>{props.tournament_info?.match_time}</p>
                </div>
                <div className='post__content__tournament-result__info-date__col'>
                    <span className='icon-field' />
                    {props.display === 'post'?
                        <p className='baloa-table-column'>{props.tournament_info?.stadium}</p>
                        :
                        <p className='baloa-table-column'>{props.tournament_info?.field}</p>
                    }                    
                </div>
                {props.tournament_info?.official_referee !== null && props.tournament_info?.official_referee &&
                    <div className='post__content__tournament-result__info-date__col'>
                        <span className='icon-wistle' />
                        <p className='baloa-table-column'>{props.tournament_info?.official_referee}</p>
                    </div>
                }
                {props.tournament_info?.second_assistant_referee !== null && props.tournament_info?.second_assistant_referee &&
                    <div className='post__content__tournament-result__info-date__col'>
                        <span className='icon2-flag-referee' />
                        <p className='baloa-body-2'>{props.tournament_info?.second_assistant_referee}</p>
                    </div>
                }
                {props.tournament_info?.third_assistant_referee !== null && props.tournament_info?.third_assistant_referee &&
                    <div className='post__content__tournament-result__info-date__col'>
                        <span className='icon2-flag-referee' />
                        <p className='baloa-body-2'>{props.tournament_info?.third_assistant_referee}</p>
                    </div>
                }
                {props.tournament_info?.fourth_official_referee !== null && props.tournament_info?.fourth_official_referee &&
                    <div className='post__content__tournament-result__info-date__col'>
                        <span className='icon2-4referee' />
                        <p className='baloa-body-2'>{props.tournament_info?.fourth_official_referee}</p>
                    </div>
                }
            </div>

        </div>

        /* eslint-enable */

    )


}


export default LeagueTournamentInfoComponent;