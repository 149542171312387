// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.inscription-deadline__container .modal__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.modal.inscription-deadline__container .modal__container .hubicon-info {
    font-size: var(--space-x3);
}

.modal.inscription-deadline__container .modal__container .baloa-headline-2 {
    margin-top: var(--space-x2);
}

.modal.inscription-deadline__container .modal__container .baloa-normal-text {
    margin-top: calc( var(--space) + var(--space-quarter));
    margin-bottom: calc( var(--space-x2) + var(--space-half));
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/inscription-deadline-modal/InscriptionDeadlineModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sDAAsD;IACtD,yDAAyD;AAC7D","sourcesContent":[".modal.inscription-deadline__container .modal__container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n}\n\n.modal.inscription-deadline__container .modal__container .hubicon-info {\n    font-size: var(--space-x3);\n}\n\n.modal.inscription-deadline__container .modal__container .baloa-headline-2 {\n    margin-top: var(--space-x2);\n}\n\n.modal.inscription-deadline__container .modal__container .baloa-normal-text {\n    margin-top: calc( var(--space) + var(--space-quarter));\n    margin-bottom: calc( var(--space-x2) + var(--space-half));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
