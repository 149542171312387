// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-vs-team__photos {
    display: flex;    
    justify-content: center;
    align-items: center;
}

.team-vs-team__photos__image .icon-ball {
    color: var( --white-color );
    font-size: var( --space );
    border-radius: 50%;
    padding: var( --space-quarter );  
}

.team-vs-team__photos__image img {
    width: var( --space-and-half );
    height: var( --space-and-half );
    border-radius: 50%;
}




`, "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-table/TeamVSTeamPhotosComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,kBAAkB;AACtB","sourcesContent":[".team-vs-team__photos {\n    display: flex;    \n    justify-content: center;\n    align-items: center;\n}\n\n.team-vs-team__photos__image .icon-ball {\n    color: var( --white-color );\n    font-size: var( --space );\n    border-radius: 50%;\n    padding: var( --space-quarter );  \n}\n\n.team-vs-team__photos__image img {\n    width: var( --space-and-half );\n    height: var( --space-and-half );\n    border-radius: 50%;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
