// React
import React from 'react';


// Styles
import './LeagueSearchMemberListComponent.styles.css';

import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

//Assets
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent'
  },
}));

function LeagueSearchMemberListComponent( props ) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState();
  
    const handleListItemClick = (event, item) => {
      setSelectedIndex(item.id);
      props.onSelect(item);
    };
  // console.log('l',props.items);
    return (
      <div className="scroll-parent league-search-profile__list">
        <div className={classes.root + " league-search-profile scroll"}>
          <List role="listbox">
              { props.items?.map( ( item ) => (
                      <ListItem
                          key={item.id}
                          button
                          selected={selectedIndex === item.id}
                          onClick={(event) => handleListItemClick(event, item)}
                      >
                        {props.set_type === 'member' &&
                            <React.Fragment>
                                <ListItemIcon className="league-search-profile__icon" >
                                    {
                                      item.photo &&
                                      <img className="league-search-profile__icon__icon__img" src={ item.photo} alt={`${item.username} img`} />
                                    }
                                    {
                                      !item.photo &&
                                      <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                    }                              
                                    
                                </ListItemIcon>
                                <ListItemText 
                                  primary={ item.first_name + ' ' + item.last_name} 
                                  secondary={ `@ ${item.username !== undefined  ? item.username : item.profile}`} 
                                  className="league-search-profile__listitem league-search-profile__profile"
                                />
                            </React.Fragment>
                        }
                        {props.set_type === 'team' &&
                            <React.Fragment>
                                <ListItemIcon className="league-search-profile__icon" >
                                    {
                                      item.team_logo &&
                                      <img className="league-search-profile__icon__icon__img" src={ item.team_logo} alt={`${item.teamname} img`} />
                                    }
                                    {
                                      !item.team_logo &&
                                      <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                    }                              
                                    
                                </ListItemIcon>
                                <ListItemText primary={ item.team } secondary={ '@'+item.teamname} className="league-search-profile__listitem league-search-profile__profile" />
                            </React.Fragment>
                        }
                          
                      </ListItem>
                    ))
              }
          </List>
        </div>
       </div>
    );
  }

  export default LeagueSearchMemberListComponent;