// React
import React from 'react';

// Components

import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


//Styles
import './TournamentCardIdExitValidationModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentCardIdExitValidationModalComponent ( props ) {
    
    return (
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}          
            className="card_id_exit_validation_modal medium"
         >
            <div className="card_id_exit_validation_modal__title baloa-normal">                
                {props.t('options.tournaments.cardIds.exitValidationModal.subTitle')}
                
            </div>
            <div className="card_id_exit_validation_modal__subtitle baloa-table">
                    {props.t('options.tournaments.cardIds.exitValidationModal.message')}                     
            </div>
            <div className='card_id_exit_validation_modal_footer'>
                <SecondaryButtonComponent 
                    className='card_id_exit_validation_modal_btn gray'
                    onClick={ props.onClose }
                >
                    {props.t('options.tournaments.cardIds.exitValidationModal.btnCancelLbl')}                    
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    className='card_id_exit_validation_modal_btn'
                    onClick={()=> { props.handleDeleteCardIdTemplate() }}
                >                    
                    {props.t('options.tournaments.cardIds.exitValidationModal.btnAcceptLbl')}                        
                </PrimaryButtonComponent>
            </div>
        </ModalComponent>
    )


}

export default withTranslation('league')(TournamentCardIdExitValidationModalComponent);
