// React
import React from 'react';

// i18n
import { withTranslation } from 'react-i18next';
import LeagueTournamentComponent from 'app/leagues/components/league-tournament-component/LeagueTournamentComponent';
// Styles
import './LeagueTournamentsListComponent.styles.css'

function LeagueTournamentsListComponent(props) {

    const handleOnClickList = (tournament) => {
        props.on_selected_report(tournament);
        props.on_click_tournament_report('true');
    };

    /*Function to translate tournament category  (tournament_tag) */
    const trastaleTournamentCategory = (tournament_list) => {
        let tournament_final = [];
        tournament_list.map((torneo) => { // eslint-disable-line
            let list_tournament = {
                ...torneo, category_name: torneo.tournament_tag != null
                    ? props.t("options.tournaments.pages.newTournament.tabs.tabTeams." + torneo.tournament_tag.extra.value)
                    : torneo.category
            }
            tournament_final.push(list_tournament);
        })
        return tournament_final;
    }


    return (
        <div className="league_tournament_list_container">
            {/* <div>{'componente de torneos- Pestaña equipos en pagina'}</div> */}
            <LeagueTournamentComponent // LeagueTournamentGeneralListComponent
                tournament_tag={props.tournament_tag}
                league_page={props.pagename}
                tournamentHub={false}
                //tournamentTile={props.t('options.tournaments.labelTitle')}
                filterType={'tournamentList'} // tournamentAll - tournamentSport - tournamentVideoGame
                onResetError={props.onResetError}
                errors={props.errors}
                active_tournaments={trastaleTournamentCategory(props.page_tournaments?.filter(el => { return el.state.toLowerCase() !== "finalized" }))}
                completed_tournaments={trastaleTournamentCategory(props.page_tournaments?.filter(el => { return el.state.toLowerCase() === "finalized" }))}
                handleBackButton={props.handleBackButton}
                handleOnActiveTournamentActionClick={(value) => { handleOnClickList(value) }}
                handleOnCompletedTournamentActionClick={(value) => { handleOnClickList(value) }}
                handleFilterTournament={props.handleFilterTournament}
                showFilter={true}
                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
            />
        </div>
    )
}

export default withTranslation('league')(LeagueTournamentsListComponent);
