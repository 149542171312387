
/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Formik
import { Field } from 'formik';

// Styles
import './ProfileTechnicsLateralityModalComponent.styles.css';

// Assets
import laterality_left_icon from 'assets/icons/laterality-left-icon2.svg';
import laterality_right_icon from 'assets/icons/laterality-right-icon2.svg';

// i18n
import { withTranslation } from 'react-i18next';
import ProfileStepsDatasheetComponent from '../profile-steps-datasheet/ProfileStepsDatasheetComponent';



function ProfileTechnicsLateralityModalComponent( props ) {
    
    const [ laterality, setLaterality ] = React.useState(props.model.attributes?.laterality?.value);

    React.useEffect(() => {   
        if(props.model.attributes?.laterality?.value !== laterality){
            setLaterality(props.model.attributes?.laterality?.value);
        }
    },[props.isOpen]);
    
    const formInitialValues = {
        laterality: props.model.attributes?.laterality?.value,
    }

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }

    const getInputValue = (value) => {
        if( !laterality ){ //No se ha seleccionado nada
            setLaterality(value);
            return value;
        }else if(laterality === value){ //Se hace click sobra la misma seleccionda(1 sola)
            setLaterality(null);
            return null;
        }else if (laterality && laterality !== value && laterality !== 'both-legs'){ //Se selecciona la que no esta seleccionada
            setLaterality('both-legs');
            return 'both-legs'
        }else if (laterality === 'both-legs'){//Ambas seleccionadas y marco para desseleccionar
            if(value === 'left'){
                setLaterality('right');
                return 'right'
            }else{
                setLaterality('left');
                return 'left'
            }            
        }
    }

    const handleClose = () => {
        setLaterality(null);
        props.onClose();
    }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={handleClose }
            title={props.t('playerCard.titleLaterality')}
            className="technics-laterality"
        >
            <div className="technics-laterality-form">
                <div className='technics-laterality-form__subtitle baloa-subtitle-2'>{props.t('playerCard.subTitleLaterality')}</div>
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        let new_attribute_data = { ...props.model.attributes?.laterality, value: values['laterality'] }
                        props.onNewData( new_attribute_data );
                        handleClose();
                    }}
                    className="technics-laterality-form__form form"
                    functionDisabled={()=>{}}
                >
                    <div className="technics-laterality-form__form__contect">
                        {/* <ProfileStepsDatasheetComponent
                            step={props.step}
                            stepCompleted = {(i)=>props.stepCompleted(i)}
                        /> */}
                        <div className="technics-laterality-form__form__fields">
                            <Field name="laterality">
                                {
                                    ({
                                        field,
                                        form: { setFieldValue }, 
                                        meta,
                                    }) => (
                                        <div 
                                        onClick = { () => { setFieldValue('laterality', getInputValue('left') ) } } 
                                        className={ "technics-laterality-form__form__fields__laterality " + ( (field.value == 'left' || field.value === 'both-legs') ? 'active' : '' ) }
                                        >
                                            <div className="technics-laterality-form__form__fields__laterality__img">
                                                <img src={ laterality_left_icon } alt=""/>
                                            </div>
                                            <p className="technics-laterality-form__form__fields__laterality__caption baloa-username">{props.t('playerCard.textLeft')}</p>
                                        </div>
                                    )
                                }
                            </Field>
                            <Field name="laterality">
                                {
                                    ({
                                        field,
                                        form: { setFieldValue }, 
                                        meta,
                                    }) => (
                                        <div 
                                        onClick = { () => { setFieldValue('laterality', getInputValue('right') ) } } 
                                        className={ "technics-laterality-form__form__fields__laterality " + ( (field.value === 'right' || field.value === 'both-legs') ? 'active' : '' ) }
                                        >
                                            <div className="technics-laterality-form__form__fields__laterality__img">
                                                <img src={ laterality_right_icon } alt=""/>
                                            </div>
                                            <p className="technics-laterality-form__form__fields__laterality__caption baloa-username">{props.t('playerCard.textRight')}</p>
                                        </div>
                                    )
                                }
                            </Field>
                        </div>
                    </div>

                    <div className="technics-laterality-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="technics-form__form__submit"
                        >
                            <span>{props.t('playerCard.button')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileTechnicsLateralityModalComponent);