/*eslint eqeqeq: "off"*/
// React
import React, { useState }from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ScrollTrigger from 'react-scroll-trigger';

//Components
import ShowPostFormationFieldComponent from 'shared/components/show-post-formation/ShowPostFormationFieldComponent';
//import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';
import ShowPostQuizComponent from 'shared/components/show-post-quiz/ShowPostQuizComponent';
import ShowPostPollComponent from 'shared/components/show-post-poll/ShowPostPollComponent';
//import QuotedPostComponent from '../quoted-post/QuotedPostComponent';

// Styles
import './PostProfileComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import {isMobile} from 'react-device-detect';

function PostProfileComponent(props) {

    // Video controls
    const videoRef = React.useRef();

    const [play_video, setPlayVideo] = useState(false);
    const [muted, setMuted] = useState(false);

    /*const handleOnPlayVideo=()=>{
        if ( !play_video ) { 
            videoRef.current.play(); 
        }else{
            videoRef.current.pause(); 
        }
        setPlayVideo(!play_video);

    }

    const handleOnMuteVideo=()=>{
        videoRef.current.muted = !muted
        setMuted(!muted);
    } */

    return (
        
        <ScrollTrigger 
            onEnter={ props.onEnterViewport } 
            onExit={ props.onExitViewport }
            key={props.post.id}
            className="post-profile__container"
        >
            {/* Simple post */}
            {!props.post.post_image && !props.post.post_match && !props.post.post_quoted && !props.post.post_type && !props.post.post_video && !props.post.quoted_post &&
                <div className="post-profile__content" onClick={()=>{isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Comment')}}>
                    
                    <div className='post-profile__quoted__tag baloa-table green'><span className='hubicon-article'></span>{props.t('post:post.type.opinion')}</div>
                    <div className='baloa-subtitle-2'>{props.post.caption}</div>
                                    
                </div>
            }
            {/* Post video content */}
            { props.post.post_video &&
                <div className="post-profile__post" onClick={()=>{isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Media')}}>
                    
                    {/* Video Player */}
                    {props.post?.post_stream?.hls?
                        <ReactHlsPlayer
                            className='post-profile__video'
                            playerRef={videoRef}
                            src={props.post?.post_stream?.hls}
                            preload = "auto"
                            autoPlay={false}
                            playsinline
                            width={'100%'}
                            height={'100%'}                                            
                            loop={true}
                            poster = { props.post?.post_stream?.thumbnail ? props.post?.post_stream?.thumbnail : "" }
                        />
                    :
                        <video
                            className='post-profile__video'
                            ref = { videoRef }
                            src = { props.post?.post_video }
                            loop
                            preload = "auto"
                            poster = { props.post.post_stream?.thumbnail ? props.post.post_stream?.thumbnail : "" }
                            muted 
                            playsinline
                        ></video>
                    }
                    <div className="post-profile__post__video-control"
                        onClick={ () => {} }
                    >
                        <div className={ !play_video ? "post-profile__post__video-control__background" : "" }></div>
                        <span className={ !play_video ? "post-profile__post__video-control__icon" : "" } ></span>
                    </div>
                    <div className="post-profile__post__control-mute"
                        onClick={ () => {} }
                    >
                        <span className={ muted ? "post-profile__post__control-mute__icon icon-sound-off" : "post-profile__post__control-mute__icon icon-sound-on"}></span>
                    </div>

                </div>
            }

            {/* Post image content */}
            {props.post.post_image &&
                <div className="post-profile__post" onClick={()=>{isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Media')}}>
                    <img src={props.post.post_image} alt="" />
                </div>
            }

            {/* Post Formation content */}
            { props.post.formation &&
                <div className="post-profile-formation__content" onClick={()=>{ isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Formation') }}>
                    <div className="post-formation__data">
                        <div className="post-formation__data__positions-graph">
                            <ShowPostFormationFieldComponent
                                show={props.post.formation ? "block" : "none"}
                                display="form"
                                position="secondary"
                                formation={props.post.formation}
                                sup="false"
                            />
                        </div>
                    </div>
                </div>
            }
            {/* Post match content */}
            {/* props.post.post_match &&
                <div className="post__content">
                    <LeagueTournamentInfoComponent
                        tournament_info={props.post.post_match}
                        className = { "post__content__tournament-result__container" }
                        display = { 'post' }
                    />                        
                </div> */
            }

            {/* Post Quiz */}
            {props.post.post_type === "Quiz" &&
                <div className="post-profile__content" onClick={()=>{ isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Quiz') }}>
                    <div className='post-profile__quoted__tag baloa-table quiz'><span className='iconpq-quiz'></span>{props.t('post:post.type.quiz')}</div>
                    <div className='baloa-subtitle-2'>{props.post.original_caption}</div>
                    <ShowPostQuizComponent
                        quiz_answers_count={props.post.quiz_answers_count}
                        quiz_answers={props.post.quiz_answers}
                        is_quiz_answer={props.post.is_quiz_answer}
                        handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                        post_id={props.post.id}
                    />                        
                </div>
            }

            {/* Post Poll */}
            {props.post.post_type === "Poll" &&
               <div className="post-profile__content" onClick={()=>{ isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Poll') }}>
                    <div className='post-profile__quoted__tag baloa-table poll'><span className='iconpq-poll'></span>{props.t('post:post.type.poll')}</div>
                    <div className='baloa-subtitle-2'>{props.post.original_caption}</div>
                    <ShowPostPollComponent
                        poll_answers_count={props.post.poll_answers_count}
                        poll_answers={props.post.poll_answers}
                        is_poll_answer={props.post.is_poll_answer}
                        handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                        post_id={props.post.id}
                    />                        
                </div>
            }

            {/* Post Quoted */}
            {props.post.post_quoted &&
                <div className="post-profile__content" onClick={()=>{ isMobile? props.handleOnCommentsClick(props.post) :  props.handleProfilePostModal(props.post, 'Comment') }}>                    
                    <div className='post-profile__quoted__tag baloa-table green'><span className='hubicon-article'></span>{props.t('post:post.type.opinion')}</div>
                    <div className='baloa-subtitle-2'>{props.post.original_caption}</div>
                    {/* <QuotedPostComponent
                        post={props.post.post_quoted}
                        followPostProfile= { props.followPostProfile }
                        unfollowPostProfile= { props.unfollowPostProfile }
                        followPostPage= { props.followPostPage }
                        unfollowPostPage= { props.unfollowPostPage }
                        className={'feed'}
                        handleOnPlayVideo ={ props.handleOnPlayVideo }
                        handleOnMuteVideo= { props.handleOnMuteVideo }
                        play_video={ props.play_video }
                        play_audio={ props.play_audio }
                    />  */}        
                </div>
            }
        </ScrollTrigger>
        
    )
}

// Exports
export default withTranslation('profile')(PostProfileComponent); 