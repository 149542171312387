
// Axios
import Axios from "config/axiosConfig";

// Variables
import { api_url, client_id, client_secret } from "config/GlobalData";



export const SendOtpApi = ( phone, baloa_token ) => {    
    return Axios.instance.post(`${api_url}/auth/send-otp/`,
    phone,
    {
        headers: {
            'Authorization': baloa_token
        }
    }
    );
}

export const SignInWithOtpApi = ( phone, code ) => {
    return Axios.instance.post(`${api_url}/auth/signin-with-otp/`,
    {
        phone: phone,
        otp: code,
    });
}

export const SignInWithSocialApi = ( token, social_network ) => {

    return Axios.instance.post(`${api_url}/auth/convert-token/`, { 
        "grant_type": "convert_token",
        "client_id": client_id,
        "client_secret": client_secret,
        "backend": social_network,
        "token": token,
    });
    
}

export const GetCountriesLoginApi = ( ) => {
    return Axios.instance.get(`${api_url}/constants/`, {
        // params: {
        //     'search': country
        // },
        // headers: {
        //     'Authorization': 'Token df4c89def1bd9a1e8ddb70074cd2d1353df115af'
        // }
    });
}

export const SignUpEmailApi = ( email_credentials ) => {
    return Axios.instance.post(`${api_url}/auth/signup-with-email/`, 
    {
        email: email_credentials.email,
        password: email_credentials.password,
    });
}

export const LogInEmailApi = ( email_credentials ) => {
    return Axios.instance.post(`${api_url}/auth/login-with-email/`,
    {
        email: email_credentials.email,
        password: email_credentials.password,

    });
}

export const SignUpEmailVerificationApi = ( email, code ) => {
    return Axios.instance.post(`${api_url}/auth/verify-email/`,
    {
        email: email,
        key: code,
    });
}

export const SignUpResendEmailVerificationApi = ( email ) => {
    return Axios.instance.post(`${api_url}/auth/send-email-verification-code/`,
    {
        email: email,
    });
}

export const SignUpSendRecoverPasswordApi = ( email ) => {
    return Axios.instance.post(`${api_url}/auth/send-password-recovery-code/`,
    {
        email: email,
    });
}

export const SignInVerifyRecoveryPasswordCodeApi = ( data ) => {
    return Axios.instance.post(`${api_url}/auth/recover-password/?check=True`, 
    data
    )
}

export const SignInSendNewEmailPasswordApi = ( data ) => {
    return Axios.instance.post(`${api_url}/auth/recover-password/`,
    data
    )
}

export const SendOtpEmailApi = ( email ) => {
    return Axios.instance.post(`${api_url}/auth/send-otp-email/`, 
    {
        email: email,       
    });
}

export const LoginWithEmailOtp = ( email, otp ) => {
    return Axios.instance.post(`${api_url}/auth/login-with-email-otp/`,
    {
        email: email,
        otp: otp,
    });
}

export const GetProfessionTypesApi = ( ) => {
    return Axios.instance.get(`${api_url}/profile-profession-type/`, {
    });
}

export const SearchPreRegisteredMembersApi = ( member ) => {
    return Axios.instance.get(`${api_url}/users-preregister/?search=${member}&version=2`, {
    });
}

export const PatchPreRegisterEmailPhoneApi = ( new_data, user_id ) => {
    
    return Axios.instance.patch(`${api_url}/users-edit-preregister/${user_id}/`,
    new_data , 
    );
}

export const getClaimedAccountProfileApi = ( user_id ) => {
    return Axios.instance.get(`${api_url}/users-preregister/${user_id}`, {
    });
}