/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './ProfileDeleteExternalStatsInfoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileDeleteExternalStatsInfoModalComponent( props ) {
    return (
        
        <ModalComponent 
            isOpen={props.isOpen} 
            //onClose={() => { props.onClose() }}
            //title={props.t('externalStats.externalStatsTitle')}
            className='delete-external-stat-info-modal'
        >
            <div className='delete-external-stat-info-modal__info baloa-names' dangerouslySetInnerHTML={{
                    __html: props.t('externalStats.deleteModalInfo.message')
                }}>
            </div>
            <div className='delete-external-stat-info-modal__action-btn red'>
                <TextButtonComponent 
                    className="small"
                    onClick={ props.handleOnDelete }
                >
                    <span>{ props.t('externalStats.deleteModalInfo.deleteBtnLbl') }</span>
                </TextButtonComponent>
            </div>
            <div className='delete-external-stat-info-modal__action-btn'>
                <TextButtonComponent 
                    className="small"
                    onClick={ props.handleOnCancel }
                >
                    <span>{ props.t('externalStats.deleteModalInfo.cancelBtnLbl') }</span>
                </TextButtonComponent>
            </div>
        </ModalComponent>
    )
}

export default withTranslation(['profile'])(ProfileDeleteExternalStatsInfoModalComponent);