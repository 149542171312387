/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


// Styles
import './TournamentStartTournamentModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentStartTournamentModalComponent( props ) {
    

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="tournament-start-tournament-modal__container"
        >
            <div className="baloa-normal-medium">{props.t('options.tournaments.startTournament.labelModal')}</div>
            {/* <div className="baloa-table">{props.t('options.tournaments.startTournament.warningLabelModal')}</div> */}
            <div className="tournament-start-tournament-modal__actions">
                <SecondaryButtonComponent
                    onClick={ () => props.onClose() }
                >{props.t('options.tournaments.startTournament.cancelBtn')}</SecondaryButtonComponent>
                <PrimaryButtonComponent
                    onClick={ () => props.handleOnConfirmStartTournament() }
                >{props.t('options.tournaments.startTournament.startBtn')}</PrimaryButtonComponent>
            </div>

        </Modal2Component>
    )

}

export default withTranslation('league')(TournamentStartTournamentModalComponent);
