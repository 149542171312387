// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './SelectFieldComponent.styles.css';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ListItemText } from '@mui/material';

// i18n
import { withTranslation } from 'react-i18next';


class SelectFieldCleanComponent extends React.Component {

    render() {
        
        const field_name = this.props.field_name;

        return (

            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }
                    
                    return (
                        <div className= { "select-field " + ( this.props.className ? this.props.className : '' )  }>
                            { this.props.label && <label className="select-field-label" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <Select 
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined } 
                                className={ 
                                    'select-field-input ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                }
                                { ...data.field }
                                placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                value={this.props.value}
                                defaultValue={this.props.defaultValue}
                                displayEmpty
                                variant='standard'
                            >
                                {this.props.placeholder &&
                                    <MenuItem value="" disabled>
                                        <span className='placeholder'>{this.props.placeholder}</span>
                                    </MenuItem>
                                }

                                {this.props.options.length > 0 && this.props.options.map( option => {
                                    return (
                                        <MenuItem
                                            key={ option.value } 
                                            value={ option.value }
                                        >
                                            <span className={`select-field-clean__icon-list ${option.icon}`} />
                                            <ListItemText primary={option.content} secondary={option.text ? option.text : ''}/>
                                        </MenuItem>
                                    )
                                } )} 
                            </Select>
                            <p className="select-field__message">{ this.props.message }</p>
                            <p className="input-field__message error">{ data.form.errors[ field_name ] }</p>
                        </div>
                    )

                } }
            </Field>
            
        );
    }
}

// Types
SelectFieldCleanComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
}
export default withTranslation('validators')(SelectFieldCleanComponent);
