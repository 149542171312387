// React
import React from 'react';
import ReactDOM from 'react-dom';

// Styles
import './Modal2Component.styles.css';

// i18next
import { withTranslation } from 'react-i18next';


function Modal2Component(props) {
    if (!props.isOpen) {
        return null;
    }
    return ReactDOM.createPortal(
        <div className={ "baloa-subtitle-2 modal2 " + ( props.className ? props.className : '' ) }>
            <div className="modal2__container">
                <div className="modal2__header">
                    <p className="modal2__header__title baloa-subtitle-2">{ props.title }</p>
                    <div className="modal2__header__close" onClick={props.onClose}>
                        {!props.btn_modal2_header && <span className="modal2__header__close_icon icon-x "></span>  }
                        {props.btn_modal2_header && <span className='icon-arrow-left' onClick={props.onClose}/>}
                    </div>
                    {props.btn_modal2_header && <span className="modal2__header__clear-btn icon-x" onClick={props.handleOnClearModalHeader()} /> }
                </div>
                <div className="modal2__content">
                    {props.children}
                </div>
            </div>
        </div>,
        document.getElementById('modal')
    );
}

export default withTranslation("newField")(Modal2Component);