// React
import React from 'react';

// Components

// Styles
import './TournamentPodiumTeamsComponent.styles.css';
import baloa_user from 'assets/icons/baloa-user.svg';

//i18
import { withTranslation } from 'react-i18next';
import {getRandonIconBallColor} from "../../../../shared/functions/GetRandomIconColor";

function TournamentPodiumTeamsComponent ( props ){

    return(
        <div className="podium-content__podium__content">
            <div className="podium-content__podium__content__position"
                onClick = {
                    ()=>{ props.handleOnPageClick( props.selected_tournament.runner_up_page_owner, props.selected_tournament.runner_up ) }
                }
            >
                <div className="podium-content__podium__content__position__champion">
                    <div className="podium-content__podium__content__position__champion__text">
                        <p className="podium-content__podium__content__position__champion__text__name baloa-subtitle-2">
                            {props.selected_tournament.runner_up_name}
                        </p>
                        <p className="podium-content__podium__content__position__champion__text__username baloa-table-column">
                            @{props.selected_tournament.runner_up}
                        </p>
                    </div>
                </div>
                <div className="podium-content__podium__content__position__champion__subtitle">
                    <p className="baloa-subtitle-2 podium-content__podium__content__position__champion__text__title">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelRunnerUp')}
                    </p>
                </div>
                <div className="podium-content__podium__content__position__image subcampeon__image">
                    <div className="podium-content__podium__content__position__image__content">
                        {props.selected_tournament?.runner_up_photo && <img className={'has_img'}  src={ props.selected_tournament.runner_up_photo } alt=""/>}
                        {!props.selected_tournament?.runner_up_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}

                    </div>
                </div>
            </div>
            <div className="podium-content__podium__content__position">
                <div className="podium-content__podium__content__position__champion color">
                    <div className="podium-content__podium__content__position__champion__text">
                        <p className="podium-content__podium__content__position__champion__text__name_champion baloa-headline-2"
                           onClick = {
                               ()=>{ props.handleOnPageClick( props.selected_tournament.champion_page_owner, props.selected_tournament.champion) }
                           }
                        >
                            {props.selected_tournament.champion_name}
                        </p>
                        <p className="podium-content__podium__content__position__champion__text__username baloa-names"
                           onClick = {
                               ()=>{ props.handleOnPageClick( props.selected_tournament.champion_page_owner, props.selected_tournament.champion ) }
                           }
                        >
                            @{props.selected_tournament.champion}
                        </p>
                        <div className="podium-content__podium__content__position__coach"
                             onClick = {
                                 ()=>{ props.handleOnProfileClick( props.selected_tournament.champion_coach ) }
                             }
                        >
                            <div className="podium-content__podium__content__position__coach__image">
                                {props.selected_tournament?.champion_coach_photo && <img className='has_img' src={ props.selected_tournament.champion_coach_photo } alt=""/>}
                                {!props.selected_tournament?.champion_coach_photo && <img className='no_img' src={ baloa_user } alt=""/>}
                            </div>
                            <div>
                                <p className="podium-content__podium__content__position__champion__text__name baloa-names">
                                    {props.selected_tournament.champion_coach_name}
                                </p>
                                <p className="podium-content__podium__content__position__champion__text__username baloa-username">
                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelCoach')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="podium-content__podium__content__position__champion__subtitle">
                    <p className="baloa-subtitle-2 podium-content__podium__content__position__champion__text__title">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelChampion')}
                    </p>
                </div>
                <div className="podium-content__podium__content__position__image champion__image "
                     onClick = {
                         ()=>{ props.handleOnPageClick( props.selected_tournament.champion_page_owner, props.selected_tournament.champion ) }
                     }
                >
                    <div className="podium-content__podium__content__position__image__content">
                        {props.selected_tournament?.champion_photo && <img className='has_img_champion' src={ props.selected_tournament.champion_photo } alt=""/>}
                        {!props.selected_tournament?.champion_photo && <span className={"icon-ball champion"} color={getRandonIconBallColor()}/>}
                    </div>
                </div>
            </div>
            <div className="podium-content__podium__content__position"
                onClick = {
                    props.selected_tournament?.third_place?
                        ()=>{  props.handleOnPageClick( props.selected_tournament.third_place_page_owner, props.selected_tournament.third_place )  }
                    : undefined
                }
            >
                <div className="podium-content__podium__content__position__champion">
                    <div className="podium-content__podium__content__position__champion__text">
                        <p className="podium-content__podium__content__position__champion__text__name baloa-subtitle-2">
                            {props.selected_tournament.third_place_name}
                        </p>
                        <p className="podium-content__podium__content__position__champion__text__username baloa-table-column">
                            @{props.selected_tournament.third_place}
                        </p>
                    </div>
                </div>
                <div className="podium-content__podium__content__position__champion__subtitle">
                    <p className="baloa-subtitle-2 podium-content__podium__content__position__champion__text__title">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelThirdPlace')}
                    </p>
                </div>
                <div className="podium-content__podium__content__position__image third__image">
                    <div className="podium-content__podium__content__position__image__content">
                        {props.selected_tournament?.third_place_photo && <img className='has_img' src={ props.selected_tournament.third_place_photo } alt=""/>}
                        {!props.selected_tournament?.third_place_photo && <span className={"icon-ball"} color={getRandonIconBallColor()}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentPodiumTeamsComponent);
