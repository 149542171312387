// React
import React from 'react';

// i18n
import { withTranslation } from 'react-i18next';

import { RenderProfileModalTeams } from './RenderProfileModalTeams';

function ProfileTeamsModalComponent(props) {
    const [teamCode, setTeamCode] = React.useState('')
    const [teamsList, setTeamsList] = React.useState([]);
    const formInitialValues = { teams: { input1: "", actually: false, } }

    React.useEffect(()=>{
        if (props.model.teams) {
            var initialList = [];
            for (var index_teams=0; index_teams<props.model.teams.length; index_teams++) {
                var initialTeam = props.model.teams[index_teams].team;
                initialList.push(initialTeam);
            }
            setTeamsList(initialList);
        }
    },[props]);

    const handleTeamCode = ({ target: { value } }) => {
        setTeamCode(value);
        //props.handle_team_code(value);
    };

    const handleSave = () => {
        setTeamCode('');
        props.onClose();
    };

    const handleAddProfileTeam= (team_code)=>{
        setTeamCode('');
        props.onNewData(team_code);
    };

    return (
        <RenderProfileModalTeams
            formInitialValues={formInitialValues}
            //teamCode={props.team_code}
            teamCode={teamCode}
            teamsList={teamsList}
            handleTeamCode={handleTeamCode}
            handleSave={handleSave}
            onNewData={handleAddProfileTeam}
            onDelete={props.onDelete}
            onClosed={handleSave}
            {...props}
        />
    )
}

export default withTranslation('profile')(ProfileTeamsModalComponent);
