// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-modal__form.scroll-parent{
    max-height: 70%;
}

.quote-modal__user{
    display: flex;
    align-items: center;
    padding: var(--space) 0 ;
}

.quote-modal__user-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: var(--space);
}

.quote-modal__user-name.baloa-table{
    color: var(--primary-dark-color);
    padding-left: calc(var(--space-half) + var(--space-quarter));
}

.quote-modal__user-image.icon-ball{
    font-size: 18px;
    font-weight: var(--font-bold);
    border-radius: 50%;
    padding: 5px;
    color: var(--white-color);
}

.quote-modal__caption .input-field-multiline-input {
    color: var(--primary-very-dark-color);
    border: none;
    padding: 0;
    background-color: transparent;
}

.input-field-multiline.quote-modal__caption{
    margin: 0;
    padding: 0 var(--space);
}

.quote-modal__actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: var(--space-and-half);
    padding-right: var(--space);
}`, "",{"version":3,"sources":["webpack://./src/app/posts/components/quote-modal/QuoteModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,4DAA4D;AAChE;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,qCAAqC;IACrC,YAAY;IACZ,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,qCAAqC;IACrC,2BAA2B;AAC/B","sourcesContent":[".quote-modal__form.scroll-parent{\n    max-height: 70%;\n}\n\n.quote-modal__user{\n    display: flex;\n    align-items: center;\n    padding: var(--space) 0 ;\n}\n\n.quote-modal__user-image {\n    width: 28px;\n    height: 28px;\n    border-radius: 50%;\n    margin-left: var(--space);\n}\n\n.quote-modal__user-name.baloa-table{\n    color: var(--primary-dark-color);\n    padding-left: calc(var(--space-half) + var(--space-quarter));\n}\n\n.quote-modal__user-image.icon-ball{\n    font-size: 18px;\n    font-weight: var(--font-bold);\n    border-radius: 50%;\n    padding: 5px;\n    color: var(--white-color);\n}\n\n.quote-modal__caption .input-field-multiline-input {\n    color: var(--primary-very-dark-color);\n    border: none;\n    padding: 0;\n    background-color: transparent;\n}\n\n.input-field-multiline.quote-modal__caption{\n    margin: 0;\n    padding: 0 var(--space);\n}\n\n.quote-modal__actions{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding-bottom: var(--space-and-half);\n    padding-right: var(--space);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
