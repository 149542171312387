/*eslint eqeqeq: "off"*/
// Teams state
const team_state = {
    team:{
        name: undefined,
        teamname: undefined,
        team_gender: undefined,
        //play_other_tournaments: undefined,
        photo: undefined,
        attributes: [],        
        tournaments: [],
        team_players: [],
        id: undefined,        
    },
    errors: {
        new_team:{
            name: '',
            teamname: '',
            team_gender: '',
            category: '',
            //play_other_tournaments: '',
            logo: '',
        },
        edit_team:{
            name: '',
            teamname: '',
            team_gender: '',
            category: '',
        },
        add_member_roles:{
            roles: '',
            number: '',
            positions: '',
        },
        update_rol_member:{
            rol: '',
            number: '',
            position: ''
        },
    },
    modals:{
        confirm_upload_bulk_is_open: false,
        is_open_member_team_rol_modal: false,
        is_open_bulk_delete_member_modal: false,
        is_open_edit_member_modal: false,
        is_open_delete_member_modal: false,
    },
    searched_members: [],
    page_teams: [],
    bulk_members: {
        members: [],
        total: 0,
        error: false,
    },
    member_roles: [],
    member_positions: [],
    show_bulk: false,
    loading_bulk_file: false,
    adding_bulk_member: false,
}


export default function TeamsReducer( state = team_state, action ) {
    switch ( action.type ) {

        case "TEAM:CHANGE_NEW_TEAM_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }

        case "TEAM:LOAD_TEAM": {
 
            let load_team = { ...state['team'], name: action.data.data.name, teamname: action.data.data.teamname , 
            team_gender: action.data.data.team_gender, /*play_other_tournaments: action.data.data.play_other_tournaments,*/ photo: action.data.data.photo, 
            team_code: action.data.data.team_code,
            attributes: action.data.data.attributes, tournaments: action.data.data.tournaments, id: action.data.data.id,
            category: action.data.data.team_category,
            }
            return { ...state, team: load_team}
        }
        
        case "TEAM:UPDATE_TEAM_EXTRA": {

            let new_extra = { ...state['team'], extra: action.data.data.extra }
            return { ...state, team: new_extra}

        }

        case "TEAM:SET_TEAM_PLAYERS": {
 
            let load_team_players = { ...state['team'], team_players: action.data.data.results }
            return { ...state, team: load_team_players}

        }

        case "TEAM:ADD_PLAYER_TO_TEAM": {
 
            let add_team_player = { ...state['team'], team_players: [...state['team'].team_players, action.data.data]}
            return { ...state, team: add_team_player}

        }

        case "TEAM:DELETE_PLAYER_FROM_TEAM": {

            let new_team_players = { ...state['team'], team_players: state['team'].team_players.filter(item => item.username != action.data)}
            return { ...state, team: new_team_players}

        }

        case "TEAM:SET_SEARCHED_MEMBERS": {
 
            return { ...state, searched_members: action.data}

        }

        case "TEAM:CLEAN_SEARCHED_MEMBERS": {
 
            return { ...state, searched_members: [] }

        }

        case "TEAM:LOAD_PAGE_TEAMS": {

            return { ...state, page_teams: action.data.data.results }

        }

        case "TEAM:UPDATE_TEAM_DATA": {

            let update_team = { ...state['team'], name: action.data.data.name, teamname: action.data.data.teamname , 
            team_gender: action.data.data.team_gender, photo: action.data.data.photo, 
            attributes: action.data.data.attributes, tournaments: action.data.data.tournaments}
            return { ...state, team: update_team}

        }

        case "TEAM:CLEAN_PLAYERS": {
            
            let new_team_players = { ...state['team'], team_players: [] }
            return { ...state, team: new_team_players }

        }

        case "TEAM:SET_BULK_MEMBERS": {

            let new_bulk_members = { ...state['bulk_members'], members: action.data.data.members, total: action.data.data.total, error: action.data.data.error }
            return { ...state, bulk_members: new_bulk_members }

        }

        case "TEAM:CONFIRM_UPLOAD_BULK_MODAL_STATE": {
            
            let new_modal_state = { ...state['modals'], confirm_upload_bulk_is_open: action.new_state }
            return { ...state, modals: new_modal_state }

        }

        case "TEAM:CLEAN_BULK_MEMBERS": {

            let new_bulk_members = { ...state['bulk_members'], members: [], total: 0, error: false }
            return { ...state, bulk_members: new_bulk_members }
        }
        
        case "TEAM:LOAD_MEMBER_ROLES": {
            
            return { ...state, member_roles: action.data.data }

        }

        case "TEAM:LOAD_MEMBER_POSITIONS": {
            
            return { ...state, member_positions: action.data.data }

        }

        case "TEAM:CHANGE_TEAM_MEMBERS_ROL_MODAL_STATE": {            
            let new_modals_state = { ...state['modals'], is_open_member_team_rol_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TEAM:UPDATE_TEAM_MEMBER": {

            let new_team = { ...state.team, team_players: state.team.team_players.map(member =>
                member.id == action.data.data.id ? action.data.data : member
            )}
            
            return { ...state, team: new_team }

        }

        case "TEAM:SHOW_BULK": {

            return { ...state, show_bulk: action.new_state }

        }

        case "TEAM:LOADING_BULK_FILE": {

            return { ...state, loading_bulk_file: action.new_state }

        }

        case "TEAM:ADDING_BULK_MEMBER": {

            return { ...state, adding_bulk_member: action.new_state }

        }

        case "TEAM:CHANGE_BULK_DELETE_MEMBER_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], is_open_bulk_delete_member_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TEAM:CHANGE_EDIT_MEMBER_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], is_open_edit_member_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TEAM:CHANGE_DELETE_MEMBER_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], is_open_delete_member_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "TEAM:CLEAN_TEAM":{
            return { ...state, team: {}}
        }
        
        // no default

    }
    return state;
}
