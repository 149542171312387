/*eslint eqeqeq: "off"*/
// Page state
const page_state = {
    page: {
        following_count: undefined,
        followers_count: undefined,
        attributes: {
            description: { atype: 'description', value: "" },
            experiences: [],
        },
    },
    posts: [],
    modals: {
        new_page_type_is_open: false,
        new_page_privacy_is_open: false,
        new_page_information_is_open: false,
        upload_photo_is_open: false,
        experiences_is_open: false,
        description_is_open: false,
        basic_info_is_open: false,
        followers_is_open: false,
        add_to_page_member_is_open: false,
        add_to_page_admin_is_open: false,
        new_page_category_is_open: false,
        team_member_detail_is_open: false,
        new_page_team_is_open: false,
        join_to_team_is_open:false,
        team_members_is_open: false,
        admin_responsive_is_open: false,
        admin_lock_modal: false,
        select_team_inscription_is_open: false,
    },
    forms: {
        experiences: {
            role: "",
            details: "",
            end: "",
            start: "",
            institution: "",
        },
    },
    errors: {
        creation: {
            pagename: "",
            name: "",
            privacy: '',
            page_type: '',
            subCategory: '',
            selectedPage: '',
        },
        general:{
            description: "",
        },
        experiences: {
            role: "",
            details: "",
            end: "",
            start: "",
            institution: "",
        },
        list_tournament_filters :{
            football_type: '',
            tournament_type: '',
            tournament_category: '',
            tournament_gender: '',
            start_date: '',
            end_date: '',
            modality: '',
            console: '',
            video_game: '',
            game_mode: '',
            main_city: '',
            main_country: ''
        },
    },
    followers: [],
    searched_members: [],
    page_members: [],
    page_categories: {
        organizations: [
            {value: "team", content: "team"},
            {value: "league", content: "league"},
            {value: "tournament", content: "tournament"},
            {value: "club", content: "club"},
            {value: "football_school", content: "football_school"},
            {value: "federation", content: "federation"},
            {value: "confederacy", content: "confederacy"},
        ],
        institutions: [
            {value: "university", content: "university"},
            {value: "school", content: "school"},
            {value: "company", content: "company"},
            {value: "agency", content: "agency"},
            {value: "sports_services", content: "sports_services"},
            {value: "foundation", content: "foundation"},
        ],
        content: [
            {value: "news", content: "news"},
            {value: "sports_content", content: "sports_content"},
            {value: "public_figure", content: "public_figure"},
            {value: "fans", content: "fans"},
        ]
    },
    page_inscription_teams: [],
}

export default function PageReducer( state = page_state, action ) {
    
    switch ( action.type ) {

        case "PAGE:LOAD_PAGE": {
            
            let description = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'description' 
            )
            let experiences = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'experience' 
            )
            
            let new_page_attributes = {
                description: description.length > 0 ? description[0]['extra'] : page_state['page']['attributes']['description'],
                experiences: experiences.map( experience => experience['extra'] ),
                is_group:  action.data.data.page_type == "group" ? true : false
            }
            let new_page = { ...action.data.data, attributes: new_page_attributes };
            
            return { ...state, page: new_page }

        }

        case "PAGE:LOAD_PAGE_POSTS": {
            return { ...state, posts: action.data.results }
        }

        case "PAGE:CHANGE_NEW_PAGE_TYPE_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], new_page_type_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_NEW_PAGE_CATEGORY_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], new_page_category_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_NEW_PAGE_PRIVACY_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], new_page_privacy_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_NEW_PAGE_INFORMATION_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], new_page_information_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_NEW_TEAM_MODAL_STATE" : {
            let new_modals_state = { ...state['modals'], new_page_team_is_open: action.new_state }
            return {...state, modals:new_modals_state }
        }

        case "PAGE:CHANGE_UPLOAD_PHOTO_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], upload_photo_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_BASIC_INFO_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], basic_info_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_DESCRIPTION_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], description_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_EXPERIENCES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], experiences_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:LOAD_PAGE_ERRORS": {
            // console.log(action)
            // const new_errors_section = { ...state['errors'], [action.section]: action.errors }
            // let new_errors_state = { ...state, errors: new_errors_section }
            // console.log(new_errors_state)
            // return { ...state, errors: new_errors_state }

            let new_errors_state = { ...state['errors'], [action.section]: action.errors }
            return { ...state, errors: new_errors_state }

        }

        case "PAGE:LOAD_PAGE_FOLLOWERS": {
            return {...state, followers: action.data.results}
        }

        case "PAGE:CHANGE_FOLLOWERS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], followers_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_ADD_MEMBER_TO_PAGE_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], add_to_page_member_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:CHANGE_ADD_ADMIN_TO_PAGE_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], add_to_page_admin_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PAGE:SEARCH_MEMBERS": {

            return { ...state, searched_members: action.data.data.results }

        }

        case "PAGES:LOAD_PAGE_MEMBERS": {
            return {...state, page_members: action.data.results}
        }

        case "PAGE:CLEAN_PAGE_STATE": {
            let clean_page = {following_count: undefined, followers_count: undefined, attributes: { description: { atype: 'description', value: "" }, experiences: [], is_group: false}}
            return {...state, page: clean_page}
        }

        case "PAGE:UPDATE_PAGE_POSTS_FEED": {
            let new_feed_posts = { ...state.posts, posts: [action.data, ...state.posts ]}
            return { ...state, posts: new_feed_posts.posts }
        }

        case "PAGE:DELETE_PAGE_POST": {
            let new_posts = state.posts.filter(
                newposts => newposts.id !== action.data.id
            )
            return { ...state, posts: new_posts }
        }
        case "PAGE:LOAD_MATCH_REPORT": {
            return { ...state, match_report: action.data }
        }

        case "PAGE:SET_STAFF_MEMBER_PERMISSIONS": {

            let new_page_state = { ...state, page_members: state.page_members.map(member =>
                member.username == action.data.data.username? {...member, roles: action.data.data.roles} :member)
            }

            return { ...state, page: new_page_state.page, page_members: new_page_state.page_members }
        }

        case "PAGE:UPDATE_PAGE_POSTS": {
            return { ...state, posts: action.data.results }
        }

        case "PAGE:CHANGE_TEAM_MEMBER_DETAIL_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], team_member_detail_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "PAGE:CHANGE_JOIN_TO_TEAM_MODAL_STATE" : {
            let new_modals_state = { ...state['modals'], join_to_team_is_open: action.new_state }
            return {...state, modals:new_modals_state }
        }

        case "PAGE:CHANGE_TEAM_MEMBERS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], team_members_is_open: action.new_state }
            return {...state, modals: new_modals_state }
        }

        case "PAGE:CHANGE_ADMIN_RESPONSIVE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], admin_responsive_is_open: action.new_state }
            return {...state, modals: new_modals_state }
        }

        case "PAGE:CHANGE_ADMIN_LOCK_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], admin_lock_modal: action.new_state }
            return {...state, modals: new_modals_state }
        }

        case "PAGE:CHANGE_SELECT_TEAM_INSCRIPTION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], select_team_inscription_is_open: action.new_state }
            return {...state, modals: new_modals_state }
        }

        case "PAGE:LOAD_PAGE_INSCRIPTION_TEAMS": {
            return {...state, page_inscription_teams: action.data.data}
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt
        // no default
            
    }

    return state;
}