/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';
// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CurrencyFormat from 'react-currency-format';
// Validators
import { DateFormatValidator } from 'shared/validators/DateValidator';
import { RequiredValidator, MinNumberValidation, MaxNumberValidation } from 'shared/validators/RequiredValidator';
// Styles
import './TournamentInscriptionFormEditComponent.styles.css';
import help_icon from 'assets/icons/help-resume-icon.svg';
import { styled } from "@mui/styles";
//i18
import { withTranslation } from 'react-i18next';


function TournamentInscriptionFormEditComponent(props) {

    const FIELDS_STATUS_EDITING = props.fieldStatusEditing;
    const [invitation, setInvitation] = useState('');
    const [registration, setRegistration] = useState('');
    const [unlimited, setUnlimited] = useState('');
    const [prize, setPrize] = useState('');
    const [materialPrize, setMaterialPrize] = useState(null);
    const [monetaryPrize, setMonetaryPrize] = useState(null);
    const [changeDate, setChangeDate] = useState('');
    const [minPlayersNum, setMinPlayersNum] = useState(null);
    const [maxPlayersNum, setMaxPlayersNum] = useState(null);
    const [teamForm, setTeamForm] = useState('');
    const [playerForm, setPlayerForm] = useState('');
    const [formats, setFormats] = React.useState(props.payment_option ?? []);

    const PAYMENT_OPTIONS = [
        { value: 'Paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTeamPlayerPayment') },
        { value: 'Team_paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelOnlyTeamPayment') },
        { value: 'Player_paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelOnlyPlayerPayment') },
    ];

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const checkInvitation = (event) => {
        setInvitation(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'summon_registration_type');
    };

    const checkRegistration = (event) => {
        setRegistration(event.target.value);
        props.handleRegistrationType(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'registration_type');
    };

    const checkPlayerForm = (event) => {
        setPlayerForm(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'is_player_form');
    };

    const checkTeamForm = (event) => {
        setTeamForm(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'is_team_form');
    };

    const checkUnlimited = (event) => {
        if (event.target.value == "unlimited") {
            //setMaxPlayersNum(undefined);
            setUnlimited(event.target.value);
            props.handleUnlimited(event.target.value);
        }
    };

    const checkPrize = (event) => {
        props.handleFormCheckBtns(event.target.value, 'tournament_prize_type');
        setPrize(event.target.value);
        if (event.target.value == 'Material_Prize') {
            setMonetaryPrize(null);
            props.handlePrizeType('Material_Prize');
        } else if (event.target.value == 'Monetary_Prize') {
            setMaterialPrize(null);
            props.handlePrizeType('Monetary_Prize');
        } else {
            setMonetaryPrize(null);
            setMaterialPrize(null);
            props.handlePrizeType('No_Prize');
        }
    }

    const cleanUnlimited = () => {
        setUnlimited('');
        props.handleUnlimited('');
    }

    const handleParticipantTeams = (value, min_max) => {
        /* if(min_max === "min_teams") {
            setParticipantTeams(value);
        } */
        props.handleParticipantTeams(value, min_max);
    }


    const handleMaxPlayers = (value) => {
        //setPlayersByTeams(value + 1);
        //if(value > minPlayersNum){
        setMaxPlayersNum(value);
        props.handlePlayersByTeams(value, "max_players");
        /* }else{
            setMaxPlayersNum(minPlayersNum);
            props.handlePlayersByTeams(minPlayersNum, "max_players");
        }   */
    }

    const handleMinPlayers = (value) => {
        //if(value > minPlayersNum){
        setMinPlayersNum(value);
        props.handlePlayersByTeams(value, "min_players");
        //}
    }

    function validateTrue() {
        let error;
        return error;
    }

    const handleChangeDate = (date) => {
        setChangeDate(date);
    };

    const validationDisabledFields = (field_name) => {
        for (let i in FIELDS_STATUS_EDITING) {
            if (i === field_name) return !FIELDS_STATUS_EDITING[i];
        }
    };

    const toolTipInputFiled = (input_type) => {
        return (
            <div className={`basic-data__form_edit_content___input_grid-tooltip ${input_type}`}>
                <div className="tooltip">
                    <img src={help_icon} alt="" />
                    <span className="tooltiptext">
                        <div className="tooltiptext_grid_title">
                            <span className='hubicon-info' />
                            <p className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.noEditField')}</p>
                        </div>
                        <p className="baloa-username">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.noEditcurrentState')}</p>
                    </span>
                </div>
            </div>
        );
    };

    const handleFormatPayment = (event, newFormats) => {
        setFormats(newFormats);
        if (newFormats === 'Player_paid') {
            props.handleRegistrationType('', 'registration_price_per_team')
        }
        if (newFormats === 'Team_paid') {
            props.handleRegistrationType('', 'registration_price_per_player')
        }
        if (newFormats === 'Free') setFormats('');

        props.handlePaymentOption(newFormats)
    };

    return (
        <div className="tournament-inscription__container">

            <div className="tournament-form-edit-inscription__title baloa-subtitle-2">
                {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTitle')}
            </div>
            <div className="tournament-form-edit-inscription__content">
                <div className={"tournament-form-edit-inscription__content_tooltip"}>
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__half-buttons"}>
                        <RadioGroup row aria-labelledby="tournament_summon_registration" name="tournament_summon_registration" defaultValue="top" className="tournament-form-edit-inscription__content__option-button" error={props.inscription_errors}>
                            <FormControlLabel
                                value="Closed"
                                control={props.summonRegistrationType === "Closed" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                label={
                                    <div className={'tournament-form-edit-inscription__content__option-button-label'}>
                                        <span className="icon-email"></span>
                                        <div className={'tournament-form-edit-inscription__content__option-button-label__text'}>
                                            <div className={'tournament-form-edit-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.byClosedLbl')}</div>
                                            <div className={'tournament-form-edit-inscription__content__option-button-label__caption'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.byClosedCaption')}</div>
                                        </div>
                                    </div>
                                }
                                labelPlacement="start"
                                onChange={checkInvitation}
                                className={invitation == "Closed" ? 'selected' : ''}
                                disabled={validationDisabledFields("tournament_summon_registration")}
                            />
                            <FormControlLabel
                                value="Open"
                                control={props.summonRegistrationType === "Open" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                label={
                                    <div className={'tournament-form-edit-inscription__content__option-button-label'}>
                                        <span className="icon-group-friends"></span>
                                        <div className={'tournament-form-edit-inscription__content__option-button-label__text'}>
                                            <div className={'tournament-form-edit-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.openLbl')}</div>
                                            <div className={'tournament-form-edit-inscription__content__option-button-label__caption'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.openCaption')}</div>
                                        </div>
                                    </div>
                                }
                                labelPlacement="start"
                                onChange={checkInvitation}
                                className={invitation == "Open" ? 'selected' : ''}
                                disabled={validationDisabledFields("tournament_summon_registration")}
                            />
                        </RadioGroup>
                    </FormControl>
                    {validationDisabledFields('tournament_summon_registration') &&
                        <div className="basic-data__form_edit_content_summon_registration_input_grid-tooltip">
                            {toolTipInputFiled('tournament_summon_registration')}
                        </div>
                    }
                </div>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.deadlineLbl')}</div>
                <div className={"tournament-form-edit-inscription__content_tooltip"}>
                    <InputFieldComponent
                        input_id="registration_deadline"
                        field_name="registration_deadline"
                        validationFunction={DateFormatValidator}
                        disabled={validationDisabledFields("registration_deadline")}
                        className={`basic-data__form_edit_content__input ${validationDisabledFields("registration_deadline") ? 'disabled' : ''}`}
                        input_type="date"
                        resetError={() => { }}
                        error={props.inscription_errors}
                        message=""
                        placeholder=""
                        onChange={handleChangeDate}
                        value={changeDate}
                    />
                    {validationDisabledFields('registration_deadline') &&
                        <div className="basic-data__form_edit_content_registration_deadline_input_grid-tooltip">
                            {toolTipInputFiled('registration_deadline')}
                        </div>
                    }
                </div>
                {/** Start disabled */}
                <div className={"tournament-form-edit-inscription__content_tooltip_center_input"}>
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__half-buttons"}>
                        <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.registrationTypeLbl')}</div>
                        <RadioGroup row aria-labelledby="tournament_registration_type" name="tournament_registration_type" defaultValue="top" className="tournament-form-edit-inscription__content__option-button">
                            <FormControlLabel
                                value="Paid"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLbl') + ((props.selected_country !== 'USA' && props.selected_country !== 'Colombia') ? props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLblNotUsa') : '')}</span>}
                                labelPlacement="end"
                                onChange={checkRegistration}
                                className={registration == "Paid" ? 'selected' : ''}
                                control={props.registrationType === "Paid" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                disabled={(props.selected_country !== 'USA' && props.selected_country !== 'Colombia') ? true : false}
                            />
                            <FormControlLabel
                                value="Free"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.freeTypeLbl')}</span>}
                                labelPlacement="end"
                                onChange={checkRegistration}
                                disabled={props.disableFormValues}
                                className={registration == "Free" ? 'selected' : ''}
                                control={props.registrationType === "Free" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                            />
                        </RadioGroup>
                    </FormControl>
                    {!validationDisabledFields('tournament_registration_type') &&
                        <div className="basic-data__form_edit_content_ournament_registration_type_input_grid-tooltip">
                            {toolTipInputFiled('tournament_registration_type')}
                        </div>
                    }
                </div>
                <div className={"tournament-form-edit-inscription__content_tooltip_center_input"}>
                    <div className={`basic-data__form_edit_content__button-group ${props.registrationType === 'Free' ? 'hidden' : ''}`}>
                        <p className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelWhoPayment')}</p>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={formats}
                            disabled={true}
                            onChange={handleFormatPayment}
                            exclusive
                        >
                            {PAYMENT_OPTIONS.map((payment_opt, index) => (
                                <ToggleButton key={index} value={payment_opt.value} disabled={true}>
                                    {payment_opt.content}
                                </ToggleButton>
                            ))}
                        </StyledToggleButtonGroup>
                    </div>
                </div>
                <div className={"tournament-form-edit-inscription__content__double-input " + (props.registrationType === 'Free' ? 'hidden' : '')}>
                    {(props.registrationType === 'Team_paid' || props.registrationType === 'Paid') &&
                        <div className={"tournament-form-edit-inscription__content__group-input"}>
                            <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamInscriptionValueLbl')}</div>
                            <div className="tournament-inscription__content_registration">
                                <CurrencyFormat
                                    className="tournament-inscription__form-control disabled"
                                    value={props.registrationTypePerTeam !== '' ? props.registrationTypePerTeam : ''}
                                    displayType={'input'}
                                    thousandSeparator={','}
                                    decimalSeparator={"."}
                                    prefix={'$ '}
                                    suffix={' ' + props.currency}
                                    placeholder="$"
                                    onValueChange={(value) => props.handleRegistrationType(value.value, 'registration_price_per_team')}
                                    renderText={value => <div>{value}</div>}
                                    disabled={true}
                                />
                                <div className='input-field__message error'>{props.registrationTypePerTeam === '' ? props.t('page:newPage.validators.invalidValue') : ''}</div>
                            </div>
                        </div>
                    }
                    {(props.registrationType === 'Player_paid' || props.registrationType === 'Paid') &&
                        <div className={"tournament-form-edit-inscription__content__group-input "}>
                            <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.playerInscriptionValueLbl')}</div>
                            <div className="tournament-inscription__content_registration">
                                <CurrencyFormat
                                    className="tournament-inscription__form-control"
                                    value={props.registrationTypePerPlayer !== '' ? props.registrationTypePerPlayer : ''}
                                    displayType={'input'}
                                    thousandSeparator={','}
                                    decimalSeparator={"."}
                                    prefix={'$ '}
                                    suffix={' ' + props.currency}
                                    placeholder="$"
                                    onValueChange={(value) => { props.handleRegistrationType(value.value, 'registration_price_per_player') }}
                                    renderText={value => <div>{value}</div>}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    }
                    {!validationDisabledFields('registration_price_per_team') &&
                                <div className="basic-data__form_edit_content_ournament_registration_type_input_grid-tooltip">
                                    {toolTipInputFiled('tournament_registration_type')}
                                </div>
                            }
                </div>

                <div className={"baloa-username " + (props.registrationType !== 'Paid' ? 'hidden' : '')}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.bankAccountLbl')}</div>
                <InputFieldComponent
                    input_id="bank_account"
                    field_name="bank_account"
                    validationFunction={validateTrue}
                    disabled={props.registrationType !== 'Paid'}
                    className={props.registrationType !== 'Paid' ? 'hidden' : ''}
                    input_type="text"
                    resetError={() => { }}
                    error={props.inscription_errors}
                    message=""
                    placeholder=""
                    onChange={(value) => { props.handleRegistrationType(value, 'bank_account') }}
                    value={props.bankAccount !== '' ? props.bankAccount : ''}

                />

                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamsNumberLbl')}</div>
                <div className={"tournament-form-edit-inscription__content__double-input one-column"}>
                    <InputFieldComponent
                        input_id="min_participant_teams"
                        field_name="min_participant_teams"
                        validationFunction={() => MinNumberValidation(props.minTeams, 2, props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.labelTitle'))}
                        disabled={validationDisabledFields("min_participant_teams")}
                        className={`basic-data__form_edit_content__input ${validationDisabledFields("min_participant_teams") ? 'disabled' : ''}`}
                        input_type="text"
                        resetError={() => { }}
                        error={props.inscription_errors}
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.minTeamsNumberLbl')}
                        //min={2}
                        onChange={(value) => handleParticipantTeams(value, 'min_teams')}
                        value={props.minTeams ? props.minTeams : null}
                    />
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                    <InputFieldComponent
                        input_id="max_participant_teams"
                        field_name="max_participant_teams"
                        validationFunction={() => MaxNumberValidation(props.minTeams, props.maxTeams)}
                        disabled={validationDisabledFields("max_participant_teams")}
                        className={`basic-data__form_edit_content__input ${validationDisabledFields("max_participant_teams") ? 'disabled' : ''}`}
                        input_type="text"
                        resetError={() => { }}
                        error={props.inscription_errors}
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.maxTeamsNumberLbl')}
                        //min={participantTeams}
                        onChange={(value) => handleParticipantTeams(value, 'max_teams')}
                        value={props.maxTeams ? props.maxTeams : null}
                    />
                    {validationDisabledFields('max_participant_teams') &&
                        <div className="basic-data__form_edit_content_number_teams_input_grid-tooltip">
                            {toolTipInputFiled('max_participant_teams')}
                        </div>
                    }
                </div>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.playersNumberLbl')}</div>
                <div className={"tournament-form-edit-inscription__content__double-input one-column"}>
                    <InputFieldComponent
                        input_id="min_participant_players"
                        field_name="min_participant_players"
                        validationFunction={() => MinNumberValidation(minPlayersNum, props.footballTypeMin, props.t('tournament:viewTournament.inscription.playersLbl'))}
                        //validationFunction={ RequiredValidator }
                        disabled={validationDisabledFields("min_participant_players")}
                        className={`basic-data__form_edit_content__input ${validationDisabledFields("min_participant_players") ? 'disabled' : ''}`}
                        input_type="text"
                        //min={5}
                        //max={18}
                        resetError={() => { }}
                        error={props.inscription_errors}
                        //onChange={ (value)=>{ cleanUnlimited(value) } }
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.minPlayersNumberLbl')}
                        onChange={(value) => handleMinPlayers(value)}
                        value={minPlayersNum}
                    />
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                    <InputFieldComponent
                        input_id="max_participant_players"
                        field_name="max_participant_players"
                        validationFunction={() => MaxNumberValidation(minPlayersNum, maxPlayersNum, unlimited)}
                        disabled={validationDisabledFields("max_participant_players")}
                        className={unlimited === 'unlimited' ? "disabledMax" : ''}
                        input_type="text"
                        //min={minPlayersNum}
                        resetError={() => { }}
                        error={props.inscription_errors}
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.maxPlayersNumberLbl')}
                        //max={19}
                        onChange={(value) => handleMaxPlayers(value)}
                        onClick={() => { cleanUnlimited() }}
                        value={maxPlayersNum || ""}
                    />
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__one-button"}>
                        <RadioGroup row aria-labelledby="unlimited_participant_players" name="unlimited_participant_players" value={props.isUnlimited} onChange={(e) => { checkUnlimited(e) }} className="tournament-form-edit-inscription__content__option-button inside">
                            <FormControlLabel
                                value="unlimited"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.unlimitedPlayersNumberLbl')}</span>}
                                labelPlacement="end"
                                className={props.isUnlimited ? 'selected' : ''}
                            />
                        </RadioGroup>
                    </FormControl>
                    {validationDisabledFields('max_participant_players') &&
                        <div className="basic-data__form_edit_content_number_teams_input_grid-tooltip">
                            {toolTipInputFiled('max_participant_players')}
                        </div>
                    }
                </div>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.prizeTypeLbl')}</div>
                <div className={"tournament-form-edit-inscription__content_tooltip"}>
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__tree-button"}>
                        <RadioGroup row aria-labelledby="tournament_prize_type" name="tournament_prize_type" defaultValue="top" className="tournament-form-edit-inscription__content__option-button">
                            <FormControlLabel
                                value="Monetary_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.monetaryPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={checkPrize}
                                className={prize == 'Monetary_Prize' ? 'selected' : ''}
                                disabled={validationDisabledFields("tournament_prize_type")}
                                control={props.tournamentPrizeType === "Monetary_Prize" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="Material_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.materialPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={checkPrize}
                                className={prize == 'Material_Prize' ? 'selected' : ''}
                                disabled={validationDisabledFields("tournament_prize_type")}
                                control={props.tournamentPrizeType === "Material_Prize" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="No_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.noPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={checkPrize}
                                className={prize == 'No_Prize' ? 'selected' : ''}
                                disabled={validationDisabledFields("tournament_prize_type")}
                                control={props.tournamentPrizeType === "No_Prize" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                            />
                        </RadioGroup>
                    </FormControl>
                    {validationDisabledFields('tournament_prize_type') &&
                        <div className="basic-data__form_edit_content_tournament_prize_type_input_grid-tooltip">
                            {toolTipInputFiled('tournament_prize_type')}
                        </div>
                    }
                </div>
                <InputFieldMultilineComponent
                    //label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDescription')} 
                    input_id="material_prize"
                    field_name="material_prize"
                    validationFunction={!props.materialPrize ? validateTrue : RequiredValidator}
                    disabled={prize !== 'Material_Prize' || validationDisabledFields("material_priz")}
                    //className={ !props.materialPrize? 'hidden':'' }
                    className={!props.materialPrize ? 'hidden' : ''}
                    input_type="text"
                    resetError={() => { }}
                    error={props.inscription_errors}
                    message=""
                    rows={5}
                    //onChange={ (value)=>{handlePrizeType(value, 'material_prize')}}
                    onChange={(value) => { setMaterialPrize(value) }}

                    value={materialPrize}
                />
                <InputFieldComponent
                    input_id="monetary_prize"
                    field_name="monetary_prize"
                    validationFunction={!props.monetaryPrize ? validateTrue : RequiredValidator}
                    disabled={prize !== 'Monetary_Prize'}
                    className={!props.monetaryPrize ? 'hidden' : ''}
                    //className={ !props.monetaryPrize? 'hidden':'' }
                    input_type="number"
                    min={1}
                    resetError={() => { }}
                    error={props.inscription_errors}
                    message=""
                    placeholder="$"
                    //onChange={ (value)=>{handlePrizeType(value, 'monetary_prize')}}
                    onChange={(value) => { setMonetaryPrize(value) }}

                    value={monetaryPrize}
                />
                <div className={"tournament-form-edit-inscription__content_tooltip_center_input"}>
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__half-buttons"}>
                        <div className={"baloa-username"}>
                            {props.editTournamentInscription
                                ? props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTeamFormInscriptionEdit')
                                : props.t('options.tournaments.pages.newTournament.tabs.tabInscription.team_form_check')
                            }
                        </div>
                        <RadioGroup row aria-labelledby="has_team_form"
                            name="has_team_form"
                            defaultValue="top"
                            className="tournament-form-edit-inscription__content__option-button">
                            <FormControlLabel
                                value="team_form_yes"
                                label={
                                    <span className="baloa-username">
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_yes')}
                                    </span>}
                                labelPlacement="end"
                                onChange={checkTeamForm}
                                className={teamForm == "team_form_yes" ? 'selected' : ''}
                                control={props.isTeamForm === "team_form_yes" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                disabled={validationDisabledFields("has_team_form")}
                            />
                            <FormControlLabel
                                value="team_form_no"
                                label={
                                    <span className="baloa-username">
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_Np')}
                                    </span>
                                }
                                labelPlacement="end"
                                onChange={checkTeamForm}
                                className={teamForm == "team_form_no" ? 'selected' : ''}
                                control={props.isTeamForm === "team_form_no" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                disabled={validationDisabledFields("has_team_form")}
                            />
                        </RadioGroup>
                        {/* {props.isTeamForm === 'team_form_yes'
                            ? !validationDisabledFields("has_team_form")
                                ? (<div className="tournament-inscription_radio_dinamic_Form"
                                    onClick={() => { props.handleOpenEditFormModal(true, 'edit_team_form'); }}>
                                    <p className="baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelEditForm')}</p>
                                </div>) : ''
                            : <div className="tournament-inscription_radio_dinamic_Form"
                                onClick={() => { props.handleOpenEditFormModal(true, 'create_team_form'); }}>
                                <p className="baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelCreateForm')}</p>
                            </div>
                        } */}
                    </FormControl>
                    {validationDisabledFields('has_team_form') &&
                        <div className="basic-data__form_edit_content_tournament_has_team_form_grid-tooltip">
                            {toolTipInputFiled('has_team_form')}
                        </div>
                    }
                </div>
                <div className={"tournament-form-edit-inscription__content_tooltip_center_input"}>
                    <FormControl component="fieldset" className={"tournament-form-edit-inscription__content__half-buttons"}>
                        <div className={"baloa-username"}>
                            {props.editTournamentInscription
                                ? props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelPlayerFormInscriptionEdit')
                                : props.t('options.tournaments.pages.newTournament.tabs.tabInscription.player_form_check')
                            }
                        </div>
                        <RadioGroup row
                            aria-label="has_player_form"
                            name="has_player_form"
                            defaultValue="top"
                            className="tournament-form-edit-inscription__content__option-button">
                            <FormControlLabel
                                value="player_form_yes"
                                label={<span className="baloa-username">
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_yes')}
                                </span>}
                                labelPlacement="end"
                                onChange={checkPlayerForm}
                                className={playerForm == "player_form_yes" ? 'selected' : ''}
                                control={props.isPlayerForm === "player_form_yes" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                disabled={validationDisabledFields("has_player_form")}
                            />
                            <FormControlLabel
                                value="player_form_no"
                                label={<span className="baloa-username">
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_Np')}
                                </span>}
                                labelPlacement="end"
                                onChange={checkPlayerForm}
                                className={playerForm == "player_form_no" ? 'selected' : ''}
                                control={props.isPlayerForm === "player_form_no" ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" required={true} />}
                                disabled={validationDisabledFields("has_player_form")}
                            />
                        </RadioGroup>
                        {/* {props.isPlayerForm === 'player_form_yes'
                            ? !validationDisabledFields("has_player_form")
                                ? <div className="tournament-inscription_radio_dinamic_Form"
                                    onClick={() => { props.handleOpenEditFormModal(true, 'edit_player_form'); }}>
                                    <p className="baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelEditForm')}</p>
                                </div> : ''
                            : <div className="tournament-inscription_radio_dinamic_Form"
                                onClick={() => { props.handleOpenEditFormModal(true, 'create_player_form'); }}>
                                <p className="baloa-table"> {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelCreateForm')}</p>
                            </div>
                        } */}
                    </FormControl>
                    {validationDisabledFields('has_player_form') &&
                        <div className="basic-data__form_edit_content_has_player_form_grid-tooltip">
                            {toolTipInputFiled('has_player_form')}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default withTranslation(['league', 'tournament'])(TournamentInscriptionFormEditComponent)