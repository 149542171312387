import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Material
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

import './TournamentCardIdTeamsSelectionModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentCardIdTeamsSelectionModalComponent(props) {

    const [selectedTeams, setSelectedTeams] = React.useState([]);

    const handleOnSelectedTeam = (e) => {
        if(e.target.checked){
            setSelectedTeams(current => [...current, e.target.value]);
        }else{
            setSelectedTeams((current) =>
                current.filter((selectedTeam) => selectedTeam !== e.target.value)
            );            
        }
    }
    
    const handleOnGetCardIdsData = () => {
        let teams_list_query_param = '';

        // eslint-disable-next-line array-callback-return
        selectedTeams.map(team =>{
            teams_list_query_param += team + '|'            
        });
        
        props.loadCardIdsTeamInfo(teams_list_query_param.slice(0, -1));
        setSelectedTeams([]);
        props.onClose();
    }
    
    return (
            <Modal2Component
                isOpen={props.isOpen}
                onClose={()=>{ props.onClose()}}
                title={props.title}
                className=''
            >
                <div className='card-id-team-selection-modal__content scroll'>
                    <div className='card-id-team-selection-modal__title baloa-table'>{props.t('options.tournaments.cardIds.selectCardIdsTeamsModal.subTitle')}</div>

                    {props.tournament_teams?.filter(team => team.team_state === 'Enrolled').map(team =>(
                        <FormControlLabel
                            name={'selectedTeams'}
                            key={team.id}
                            value={team.tournament_team_id}
                            control={<Checkbox color="secondary" checked={ selectedTeams.some(item => item === team.tournament_team_id) } />}
                            label={
                                <div className='card-id-team-selection-modal__team-info'>                            
                                    <div className='card-id-team-selection-modal__team-image'>
                                        {team.team_logo &&
                                            <img src={ team.team_logo } alt=""/>
                                        }
                                        {!team.team_logo &&
                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                        }                            
                                    </div>
                                    <div className='card-id-team-selection-modal__team-data'>
                                        <div className='baloa-table-column'>{team.team}</div>
                                        <div className='baloa-username'>{`@${team.teamname}`}</div>
                                    </div>
                                </div>
                            }
                            labelPlacement="end"
                            className={'card-id-team-selection-modal__team'}
                            onChange={(e) => handleOnSelectedTeam(e)}
                        />
                    ))
                    }
                </div>
                <div className='card-id-team-selection-modal__buttons'>
                    <PrimaryButtonComponent
                        disabled={selectedTeams.length === 0}
                        onClick={() => {handleOnGetCardIdsData()}}
                        className={'card-id-team-selection-modal__buttons__download-button small'}
                        type="button"
                    >
                        <span>{props.t('options.tournaments.cardIds.selectCardIdsTeamsModal.downloadBtnLbl')}</span>                                          
                    </PrimaryButtonComponent>
                </div>
            </Modal2Component>

        )
}

export default withTranslation('league')(TournamentCardIdTeamsSelectionModalComponent);