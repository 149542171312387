// React
import React from 'react';

// Styles
import './TournamentEndMatchStepsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentEndMatchStepsComponent ( props ) {

    const { step, endGameStepState } = props;

    return(
        <div className="box">
            <div className="box__title">
                <div className={ "baloa-username box__title__text complete-text" } 
                    onClick={ endGameStepState.score_step ? ()=> { props.stepCompleted(0) } : undefined }>
                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTabTitle')}
                </div>
                <div className={ `baloa-username ${(step === 1 || step === 2 || step === 3 || step === 4 || step === 5) ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endGameStepState.scorers_step?  ()=>{ props.stepCompleted(1) } : undefined }>
                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelTitle')}
                </div>
                <div className={ `baloa-username ${(step === 2 || step === 3 || step === 4 || step === 5 ) ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endGameStepState.scorers_step?  ()=>{ props.stepCompleted(2) } : undefined }>
                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelTitle')}
                </div>
                <div className={ `baloa-username ${(step === 3 || step === 4 || step === 5 ) ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endGameStepState.changes_step?()=>{ props.stepCompleted(3) } : undefined }>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelTitle')}
                </div>
                <div className={ `baloa-username ${( step === 4 || step === 5 ) ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endGameStepState.cards_step?()=>{ props.stepCompleted(4) } : undefined }>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelTitle')}
                </div>
                <div className={ `baloa-username ${step === 5 ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endGameStepState.comments_step? ()=>{ props.stepCompleted(5) } : undefined }>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelTitle')}
                </div>
            </div>
            <div className="box__steps">
                
                <div className={ "box__steps__point complete-point"} onClick={ ()=>{console.log("step-point: " + step)} }></div>
                <div className={ (step === 1 || step === 2 || step === 3 || step === 4 || step === 5) ? "box__steps__line complete-text":"box__steps__line"}></div>
                <div className={ (step === 0 ) ? "box__steps__point":"box__steps__point complete-point" }></div>
                <div className={ (step === 2 ||step === 3 || step === 4 || step === 5 ) ? "box__steps__line complete-text":"box__steps__line"}></div>
                <div className={ (step === 0 || step === 1 ) ? "box__steps__point":"box__steps__point complete-point"}></div>
                <div className={ (step === 3 || step === 4 || step === 5) ? "box__steps__line complete-text":"box__steps__line"}></div>
                <div className={ ( step === 3 || step === 4  || step === 5 )  ? "box__steps__point complete-point":"box__steps__point"}></div>
                <div className={ ( step === 4 || step === 5) ? "box__steps__line complete-text":"box__steps__line"}></div>
                <div className={ ( step === 4  || step === 5 )  ? "box__steps__point complete-point":"box__steps__point"}></div>
                <div className={step === 5 ? "box__steps__line complete-text":"box__steps__line"}></div>
                <div className={step === 5 ? "box__steps__point complete-point":"box__steps__point"}></div>
                
            </div>  
        </div>
    )
}

export default withTranslation('league')(TournamentEndMatchStepsComponent);