
export default function getPlayerNameByPosition( position, formation ) {


    

    switch( position ) {

        case 'por':
            return getPlayerText(formation.players.port);

        case 'def1':
            return getPlayerText(formation.players.defe[0]);

        case 'def2':
            return getPlayerText(formation.players.defe[1]);

        case 'def3':
            return getPlayerText(formation.players.defe[2]);

        case 'def4':
            return getPlayerText(formation.players.defe[3]);

        case 'def5':
            return getPlayerText(formation.players.defe[4]);

        case 'med1':
            return getPlayerText(formation.players.medi[0]);

        case 'med2':
            return getPlayerText(formation.players.medi[1]);

        case 'med3':            
            return getPlayerText(formation.players.medi[2]);

        case 'med4':
            return getPlayerText(formation.players.medi[3]);

        case 'med5':
            return getPlayerText(formation.players.medi[4]);            

        case 'del1':
            return getPlayerText(formation.players.dela[0]);

        case 'del2':
            return getPlayerText(formation.players.dela[1]);

        case 'del3':            
            return getPlayerText(formation.players.dela[2]);

        case 'del4':
            return getPlayerText(formation.players.dela[3]);

        case 'del5':
            return getPlayerText(formation.players.dela[4]);

        default:
            return "jugador";

    }
    
}

function getPlayerText(player){

    if(player == null || player === "undefined")
        return "  ";
    else
        return player.name;

}