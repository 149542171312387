// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_match_score-container{
    display: grid;
    justify-items: center;
    justify-content: start;
}

.match_score.baloa-username{
    color: var( --primary-dark-color );
    font-weight: var(--font-medium);
    font-size: var(--space);
}

.penalty_score.baloa-username{
    color: var( --primary-dark-color );
    font-weight: var(--font-medium);
    font-size: 13px;
}

.vs.baloa-username{
    color: var( --primary-dark-color );
    font-weight: var(--font-medium);
    font-size: var(--space);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-table/TableMatchScoreComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,uBAAuB;AAC3B","sourcesContent":[".table_match_score-container{\n    display: grid;\n    justify-items: center;\n    justify-content: start;\n}\n\n.match_score.baloa-username{\n    color: var( --primary-dark-color );\n    font-weight: var(--font-medium);\n    font-size: var(--space);\n}\n\n.penalty_score.baloa-username{\n    color: var( --primary-dark-color );\n    font-weight: var(--font-medium);\n    font-size: 13px;\n}\n\n.vs.baloa-username{\n    color: var( --primary-dark-color );\n    font-weight: var(--font-medium);\n    font-size: var(--space);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
