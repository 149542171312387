import React, { useEffect } from 'react';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

//Assets
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';

import './LeagueCountrySearchIconComponent.styles.css';

function LeagueCountrySearchIconComponent(props) {
    const [memberSelected, setMemberSelected] = React.useState(null);
    const [boxPosition, setBoxPosition] = React.useState(false);
    const [isBoxOpen, setBoxOpen] = React.useState(false);
    const [inputChange, setInputChange] = React.useState('');

    const AutocompleteTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--basic-gray-color)',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: 'var(--new-input-background-color)',
                },
            },
        },
    })(TextField);

    useEffect(()=>{
        if(inputChange === props.id){
            document.getElementById(props.id).focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props]);

    useEffect(() => { 
        if(inputChange !== ''){ 
            document.getElementById(inputChange).focus();
        }
    }, [inputChange]);// eslint-disable-line

    const handleSelectStaffMemberTeam = (item) => {
        setMemberSelected(item);
        setBoxPosition(false);
        setBoxOpen(false)
    };

    const handleOpenOptionCountries = () => {
        if (!isBoxOpen) {
            setBoxPosition('open')
            setBoxOpen(true)
        } else {
            setBoxPosition(false);
            setBoxOpen(false)
        }
    };

    return (
        <div className={`search_country_content ${props.fieldName}`}>
            <Field name={props.fieldName} validate={props.validationFunction}>
                {(data) => {
                    if (props.error[props.fieldName] !== "") {
                        data.form.errors[props.fieldName] = props.error[props.fieldName];
                    }
                    return (
                        (
                            <React.Fragment>
                                <Autocomplete
                                    disabled={false}
                                    //name={props.fieldName}
                                    //component={Autocomplete}
                                    freeSolo={props.freeSolo ? props.freeSolo : false}
                                    options={props.options}
                                    noOptionsText={props.noOptionsText}
                                    getOptionLabel={(option) => option.content ? `${option.content}` : ''}
                                    disablePortal={true}
                                    open={true}
                                    id={props.id}
                                    isOptionEqualToValue={(option, value) => {
                                        if (value) {
                                            return option[props.optionSelectedField] === value[props.optionSelectedField]
                                        } else {
                                            return null
                                        }
                                    }}
                                    value={props.value}
                                    onInputChange={(event, value) => {
                                        if (props.onChange) {
                                            props.onChange(value)
                                        }
                                        setInputChange(props.id);
                                        // handleSelectStaffMemberTeam(value);
                                        // data.form.setFieldValue( props.field_name, value )
                                    }}
                                    onChange={(e, value) => {
                                        if (props.onChange) {
                                            props.onChange(value)
                                        }
                                        handleSelectStaffMemberTeam(value);
                                        data.form.setFieldValue( props.fieldName, value )
                                    }}
                                    renderOption={(comprops,option) => {
                                        return <ListItem
                                            {...comprops}
                                            key={option.id}
                                            button
                                            className="search_country_input_autocomple__list"
                                        >
                                            <ListItemIcon className="search_country_input_autocomple__list__icon" >
                                                {option.flag_icon ?
                                                    <img className="search_country_input_autocomple__list__icon__img" src={option.flag_icon} alt="" />
                                                    :
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                }
                                            </ListItemIcon>
                                            <ListItemText primary={option.content} className="input-field-team-autocomplete__listitem" />
                                        </ListItem>
                                    }}
                                    renderInput={(params) => (
                                        <div className='search_country_autocomplete__img-selected-option'>
                                            <div className='search-field-autocomplete__selected-country'
                                                onClick={() => handleOpenOptionCountries()}>
                                                {memberSelected?.content
                                                    ? <div className='search-field-autocomplete_flag_country'>
                                                        {memberSelected.flag_icon
                                                            ? <img className="search_country_input_autocomple__list__icon__img" src={memberSelected.flag_icon} alt="" />
                                                            : <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                        }
                                                        <div className='baloa-normal'>+{memberSelected?.value}</div>
                                                    </div>
                                                    : <div className='search-field-autocomplete_flag_country'>
                                                        {props.defaultValue?.flag_icon
                                                            ? <img className="search_country_input_autocomple__list__icon__img" src={props.defaultValue?.flag_icon} alt="" />
                                                            : <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                        }
                                                        <div className='baloa-normal'>+{props.defaultValue?.value}</div>
                                                    </div>
                                                }
                                            </div>
                                            <div className={`dropdown-countries ${boxPosition ? '_active' : ''}`}>
                                                <AutocompleteTextField
                                                    {...params}
                                                    className={props.className + " input-field-team-autocomplete"}
                                                    label={props.label}
                                                    variant="outlined"
                                                    margin="normal"
                                                    placeholder={props.modalSearchPlaceholder}
                                                    onKeyDown={ () => { props.resetError() } }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                                <p className='search-field__message baloa-username'>{ data.form.errors[ props.fieldName ] ? data.form.errors[ props.fieldName ] :  '' }
                            </p>
                            </React.Fragment>
                        )
                    )
                }}

            </Field>
        </div>
    )
}

export default withTranslation('league')(LeagueCountrySearchIconComponent);