// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-modal__container-create{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:6px 12px 0px 12px;
}
.tournament-modal__container-create_icon.hubicon-Frame-1531{
    justify-content: center;
    color: #D9D9D9;
    font-size: 43px;
    margin-bottom: 22px;
    
    
}
.tournament-modal__container-create.baloa-subtitle-1{
    justify-content: center;
    color: var(--primary-very-dark-color);
    font-size: 17px;
    text-align: center;
    padding: 0px 12px 22px 12px;
}

.tournament-modal__container-create_button{
    margin-bottom: 22px;
    width: 100%;
}

.tournament-modal__container-create_button__button{
    width: 100%;
}

.responsive-hub-modal__container .modal2__container {
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/responsive-hub-modal/ResponsiveHubModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,mBAAmB;;;AAGvB;AACA;IACI,uBAAuB;IACvB,qCAAqC;IACrC,eAAe;IACf,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".tournament-modal__container-create{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding:6px 12px 0px 12px;\n}\n.tournament-modal__container-create_icon.hubicon-Frame-1531{\n    justify-content: center;\n    color: #D9D9D9;\n    font-size: 43px;\n    margin-bottom: 22px;\n    \n    \n}\n.tournament-modal__container-create.baloa-subtitle-1{\n    justify-content: center;\n    color: var(--primary-very-dark-color);\n    font-size: 17px;\n    text-align: center;\n    padding: 0px 12px 22px 12px;\n}\n\n.tournament-modal__container-create_button{\n    margin-bottom: 22px;\n    width: 100%;\n}\n\n.tournament-modal__container-create_button__button{\n    width: 100%;\n}\n\n.responsive-hub-modal__container .modal2__container {\n    width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
