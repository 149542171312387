// React
import React from 'react';

// Styles
import './SeparatorComponent.styles.css';

// Assets
import separator_line from 'assets/icons/separator-line.svg';
import separator_center from 'assets/icons/separator-center.svg';
import separator_elipse from 'assets/icons/separator-elipse.svg';


function SeparatorComponent( props ) {
    return (
        <div className={"baloa-separator " + props.className}>
            <img className="baloa-separator__line" src={separator_line} alt=""/>
            <img className="baloa-separator__center" src={separator_center} alt=""/>
            <img className="baloa-separator__elipse" src={separator_elipse} alt=""/>
        </div>
    )
}

export default SeparatorComponent;
