// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compose-inscription-form__select-field .MuiInput-underline::before{
    border-bottom:none;
    content: none;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/dynamic-fields-tournament-form/select-field-dynamic-form/SelectFieldDynamicFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".compose-inscription-form__select-field .MuiInput-underline::before{\n    border-bottom:none;\n    content: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
