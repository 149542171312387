
export const changeUserSettingsError = ( data ) => {
    return { type: "USERSETTINGS:CHANGE_ERROR", data }
}

export const changeUserMessageSnackBar = ( new_state ) => {
    return { type: "USERSETTINGS:CHANGE_USER_MESSAGE_SNACKBAR", new_state }
}

export const changeUserPaymentsModalStateAction = ( new_state ) => {
    return { type: "USERSETTINGS:CHANGE_USER_PAYMENTS_MODAL_STATE", new_state }
}

export const loadPaymentCountriesAction = ( data ) => {
    return { type: "USERSETTINGS:LOAD_PAYMENTS_COUNTRIES", data }
}

export const loadPaymentsFormAction = ( data ) => {
    return { type: "USERSETTINGS:lOAD_PAYMENTS_FORM", data }
}

export const getUserGatewayAttributeAction = ( data ) => {
    return { type: "USERSETTINGS:GET_USER_GATEWAY_ATTRIBUTE", data }
}

export const getUserCountriesAction = ( data ) => {
    return { type: "USERSETTINGS:GET_USER_COUNTRIES", data }
}

export const getUserCountryStatesAction = ( data ) => {
    return { type: "USERSETTINGS:GET_USER_COUNTRY_STATES", data }
}

export const changeUserOnboardingFormValidationStateAction = ( new_state ) => {
    return { type: "USERSETTINGS:CHANGE_USER_ONBOARDING_FORM_VALIDATION_STATE", new_state }
}

export const cleanUserCountryformAction = ( data ) => {
    return { type:"USERSETTINGS:CLEAN_USER_COUNTRY_FORM", data }
}

export const checkProfileAccountStatusAction= ( data ) => {
    return { type:"USERSETTINGS:CHECK_ACCOUNT_STATUS", data }
}

export const loadFormsDataAction = ( data ) => {
    return { type:"USERSETTINGS:LOAD_FORMS_DATA", data }
}

export const changeUserFormPaymentLoadingAction = ( new_state ) => {
    return { type: "USERSETTINGS:CHANGE_USER_FORM_PAYMENT_LOADING_STATE", new_state }
}