import React from 'react';

// Components
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';

// Styles
import './SelectFieldDynamicFormComponent.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';

function SelectFieldDynamicFormComponent(props) {

    return (
        <div className='select-field-dynamic-form__container'>
            <SelectFieldComponent
                label={props.label}
                field_name={props.field_name}
                input_id={props.input_id}
                validationFunction={props.is_required ? RequiredValidator : false}
                disabled={props.disabled}
                className="compose-inscription-form__select-field"
                type={props.type}
                resetError={() => {}}
                error={props.error}
                message=""
                options={props.options} 
                defaultValue={props.defaultValue}
            />
        </div>
    );
}

export default withTranslation('tournament')(SelectFieldDynamicFormComponent)