// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-update-location .modal__container{
    padding: 0;
}
.home-update-location__icon .icon-location{
    font-size: 67px;
}
.home-update-location__icon{
    text-align: center;
    padding-top: 38px;
}
.home-update-location__title{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.home-update-location__subtitle.baloa-table{
    color: var(--primary-dark-color);
    text-align: center; 
    padding-bottom: 30px;
}
.home-update-location__buttons{
    display: flex;
    justify-content: flex-end;
    padding: var(--space);
}
.home-update-location__buttons .secondary-button{
    margin-right: var(--space);
}

.home-update-location__separator{
    height: 0;
    width: 100%;
    border-bottom: 1px solid var(--new-input-background-color);
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-update-location-modal/HomeUpdateLocationModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,SAAS;IACT,WAAW;IACX,0DAA0D;IAC1D,SAAS;AACb","sourcesContent":[".home-update-location .modal__container{\n    padding: 0;\n}\n.home-update-location__icon .icon-location{\n    font-size: 67px;\n}\n.home-update-location__icon{\n    text-align: center;\n    padding-top: 38px;\n}\n.home-update-location__title{\n    text-align: center;\n    padding-top: 30px;\n    padding-bottom: 30px;\n}\n.home-update-location__subtitle.baloa-table{\n    color: var(--primary-dark-color);\n    text-align: center; \n    padding-bottom: 30px;\n}\n.home-update-location__buttons{\n    display: flex;\n    justify-content: flex-end;\n    padding: var(--space);\n}\n.home-update-location__buttons .secondary-button{\n    margin-right: var(--space);\n}\n\n.home-update-location__separator{\n    height: 0;\n    width: 100%;\n    border-bottom: 1px solid var(--new-input-background-color);\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
