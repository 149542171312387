// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixture-matches-available-dates .modal2__container {
    width: 50%;
}

.fixture-matches-available-dates__content {
    padding: 18px 40px;
}

.fixture-matches-available-dates__dates-content {
    height: calc(100vh - 200px);
}

.fixture-matches-available-dates__buttons {
    border-top: 1px solid var(--new-gray-background-color);
    padding: 12px 16px;
    display: flex;
    justify-content: flex-end;
}

.fixture-matches-available-dates__button-add.button.small {
    border-color: var(--new-gray-background-color);
    padding: 8px 10px;
    margin-top: 28px;
}

.fixture-matches-available-dates__button-add span.icon{
    grid-area: icon;
    margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-fixture-matches-available-dates-modal/TournamentFixtureMatchesAvailableDatesModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sDAAsD;IACtD,kBAAkB;IAClB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,8CAA8C;IAC9C,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".fixture-matches-available-dates .modal2__container {\n    width: 50%;\n}\n\n.fixture-matches-available-dates__content {\n    padding: 18px 40px;\n}\n\n.fixture-matches-available-dates__dates-content {\n    height: calc(100vh - 200px);\n}\n\n.fixture-matches-available-dates__buttons {\n    border-top: 1px solid var(--new-gray-background-color);\n    padding: 12px 16px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.fixture-matches-available-dates__button-add.button.small {\n    border-color: var(--new-gray-background-color);\n    padding: 8px 10px;\n    margin-top: 28px;\n}\n\n.fixture-matches-available-dates__button-add span.icon{\n    grid-area: icon;\n    margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
