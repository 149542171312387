// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-role {
    border-radius: 14px;
    margin-top: 12px;
}

.profile-role__container {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.profile-role__container .baloa-subtitle-2 {
    color: var(--primary-dark-color);
    margin-left: 12px;
}

.profile-role .hubicon-edit {
    font-size: 24px;
}

.profile-role__container .hubicon-edit::before {
    color: var(--primary-dark-color);
}

.profile-role__container span {
    font-size: 24px;
    color: var(--primary-dark-color);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .profile-role {
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    }  
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-role/ProfileRoleComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gCAAgC;AACpC;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,iBAAiB;QACjB,kBAAkB;QAClB,2CAA2C;IAC/C;AACJ","sourcesContent":[".profile-role {\n    border-radius: 14px;\n    margin-top: 12px;\n}\n\n.profile-role__container {\n    display: flex;\n    align-items: center;\n    margin-top: 24px;\n}\n\n.profile-role__container .baloa-subtitle-2 {\n    color: var(--primary-dark-color);\n    margin-left: 12px;\n}\n\n.profile-role .hubicon-edit {\n    font-size: 24px;\n}\n\n.profile-role__container .hubicon-edit::before {\n    color: var(--primary-dark-color);\n}\n\n.profile-role__container span {\n    font-size: 24px;\n    color: var(--primary-dark-color);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .profile-role {\n        margin-left: 10px;\n        margin-right: 10px;\n        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);\n    }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
