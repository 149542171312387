import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import './ShareIframeLinkModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function ShareIframeLinkModalComponent(props) {
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('viewTournament.inscription.shareIframeLinkModal.modalTitle')}
            className='share-iframe-link-modal__container'
        >
            <div className={"share-iframe-link-modal__content"}>
                <div className="share-iframe-link-modal__description baloa-table-column">
                    <span className='hubicon-code' />
                    {props.t('viewTournament.inscription.shareIframeLinkModal.modalText')}
                </div>
                <div className="share-iframe-link-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                    <span className="baloa-table">
                        {props.share_url}
                    </span>
                    <span className="icon-copy-86"></span>
                </div>
            </div>
            <div className="share-iframe-link-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='share-iframe-link-modal__buttons-button'
                    onClick={props.onClose}
                >
                    <span>{props.t('viewTournament.inscription.shareInscriptoionLinkModal.acceptButton')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('tournament')(ShareIframeLinkModalComponent);