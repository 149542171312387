// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetLineUpsConstantsApi = () => {

    return Axios.instance.get( `${ api_url }/formations/constants/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}

export const GetAlignmentsApi = () => {

    return Axios.instance.get( `${ api_url }/football-type/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}