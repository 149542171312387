/*eslint eqeqeq: "off"*/
// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
//import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';

// Styles
import './SignUpFormPanelComponent.styles.css';

// Actions
import { sendUserDataAction, getCountriesAction, searchCityByCountryAction, sendNoRegisterUserDataAction } from 'app/authentication/actions/LoginActions';
import { changeSignUpError , changeSignUpStatus} from 'app/authentication/actions/LoginActionsCreators';
import { loadAttributesConstants } from 'app/generalattributes/actions/AttributeActions';

// Validators
import { FirstNameValidator, LastNameValidator, UserNameValidator } from 'shared/validators/SignUpValidator';
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';
import baloa_green_logo from 'assets/logos/baloa-logo.png';
import help_icon from 'assets/icons/help-resume-icon.svg';

import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// React device detect
import {isMobile} from 'react-device-detect';
class SignUpFormPanelComponent extends Component {
    constructor(props){
        super(props);
        this.state = { 
            disabled: false,
            first_name: this.props.state.user?.me?.first_name? this.props.state.user.me.first_name : '',
            last_name: this.props.state.user?.me?.last_name? this.props.state.user.me.last_name : '',
            //countries: [],
            //city_id: '',
            //disabled_city_field: true,
            new_user_image_modal: false,
            user_image_b64: null,
            user_img_url: null,
            user_binary_img: null,
            user_color_icon: false,
            user_name: (this.props.state.user?.me.username && this.props.state.user?.me.username_asigned)? this.props.state.user?.me.username :'',
            birthday: this.props.state.user?.me?.birthday? this.props.state.user?.me?.birthday: null,
            selected_profile_type:'',
            profession_specialties : null,
            form_errors: null,
            file_input_ref: null,
            selected_file: null,
            uploadProgress: 0,
        };
        this.formRef = React.createRef();         
    };

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }

    changeName(name){
        if(name != this.state.first_name){
            this.setState({first_name: name});
        }    
    }

    changeLastName(lname){
        if(lname != this.state.last_name){
            this.setState({last_name: lname});
        }    
    }

    changeUserName(uname){
        if(uname !== this.state.user_name){
            this.setState({user_name: uname});
        }
    }

    changeBirthDay(birthday){
        if(birthday !== this.state.birthday){
            this.setState({birthday: birthday});
        }
    }

    componentDidMount() {
        this.props.loadAttributesConstants();
    }

    componentDidUpdate(prevProps){
        if(this.props.state.signup.professional_types.length > 0 && this.state.profession_specialties === null){
            const specialties =this.props.state.signup.professional_types?.map(specialty =>{
                let tspec = {};
                tspec["content"] = this.props.t('profile:professional_types.'+specialty.caption);
                tspec["value"] = specialty.caption;
                return tspec;
            });
            this.setState({profession_specialties: specialties});
        }
    }

    // handleSearchCountry(country) {
    //     this.props.getCountriesAction(country);
    //     this.setState({disabled_city_field: false});
    // }

    // handleSearchWorldCities(city) {
    //     this.props.searchCityByCountryAction(this.props.state.signup.countryId, city);
    //     let city_id = this.props.state.signup.cities?.find((value) => value.name === city);
    //     if(this.props.state.signup.cities?.find((value) => value.name === city)){
    //         this.setState({city_id: city_id.id})
    //     }
    // }

    handleNewUserImageModal(new_state) {
        //this.setState({user_random_color: null});
        this.setState({ new_user_image_modal: new_state });
    }

    setUserImage(user_image, user_image_url, img_b64) {
        this.setState({user_image_b64: img_b64});
        this.setState({user_img_url: user_image_url});
        this.setState({user_binary_img: user_image});
    }

    resetError(){
        const data = {
            'field':'username',
            'error':''
        }
        this.props.changeSignUpError(data);  
    }

    handleSelectedProfession =(value)=>{
        if(value?.length > 0 && value != this.state.selected_profile_type ){
            this.setState({selected_profile_type: value});
        }
    }

    validateForm = async () => {
        if (this.formRef.current.values.profile_type === "professional" && !this.formRef.current.values.profession_type){       
            this.formRef.current.setFieldError('profession_type',this.props.t('page:newPage.validators.requiredField'));
            this.formRef.current.setSubmitting(false);
            this.setState({form_errors:{profession_type: this.props.t('page:newPage.validators.requiredField')}})
        }else if(this.props.state.user.me?.pre_registered_user && this.props.state.user.me?.not_registered && !this.formRef.current.values.claim_file){
            this.formRef.current.setFieldError('claim_file',this.props.t('page:newPage.validators.requiredField'));
            this.formRef.current.setSubmitting(false);
            this.setState({form_errors:{claim_file: this.props.t('page:newPage.validators.requiredField')}})
        }else{
            this.formRef.current.validateForm();
        }
        
        if(this.formRef.current.isValid){

            if(this.props.state.user.me?.pre_registered_user && this.props.state.user.me?.not_registered && !this.props.state.user.me?.pre_registered_user_update){

                let formData = new FormData();
                formData.append(
                    "document_file_data",
                    this.state.selected_file,
                    this.state.selected_file.name
                );
                formData.append("username", this.formRef.current.values.username);
                formData.append("first_name",this.formRef.current.values.first_name);
                formData.append("last_name",this.formRef.current.values.last_name);
                formData.append("birthday",this.formRef.current.values.birthday);
                if(this.state.user_binary_img){
                    formData.append("photo",this.state.user_binary_img);
                }

                /* if(this.props.state.signup.phone){
                    formData.append("",this.props.state.signup.phone);
                } 
                if(this.props.state.signup.email){
                    formData.append("",this.props.state.signup.email);
                } */
                formData.append("profile_type",this.formRef.current.values.profile_type);
                if(this.formRef.current.values.profile_type === "professional" && this.formRef.current.values.profession_type ){
                    formData.append("profile_profession_type",this.formRef.current.values.profession_type.value);
                }
                formData.append("delete_document_file", false);
                await this.props.sendNoRegisterUserDataAction(formData, this.onUploadProgress);

            }else{
                let user = {};
                user.username = this.formRef.current.values.username;
                user.first_name = this.formRef.current.values.first_name;
                user.last_name = this.formRef.current.values.last_name;
                if(this.formRef.current.values.birthday.length > 0){
                    user.birthday = this.formRef.current.values.birthday;
                }                
                user.photo = this.state.user_image_b64? `data:image/jpeg;base64,${this.state.user_image_b64}`: null;
                if(this.props.state.signup.phone){
                    user.phone = this.props.state.signup.phone;
                } else if(this.props.state.signup.email){
                    user.email = this.props.state.signup.email;
                }
                user.profile_type = this.formRef.current.values.profile_type;
                if(this.formRef.current.values.profile_type === "professional" && this.formRef.current.values.profession_type ){
                    user.profile_profession_type = this.formRef.current.values.profession_type.value;
                }

                this.props.sendUserDataAction(user,null,this.props.state.user.me.username);
            }
        }

    }

    validateProfessionType(e) {
        if(e){
            this.setState({form_errors: null});
        }
    }

    onFileChange =( event )=> {
        this.setState({ selected_file: event.target.files[0] });
    };

    onUploadProgress = (progressEvent) => {
        this.setState({ uploadProgress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
    }

    clearFile(){
        this.setState({ selected_file: null });
    }

    checkNotRegisterFile(){
        
        if(this.props.state.user.me?.not_registered && !this.state.selected_file){//Si es requerido el file y no lo han cargado
            return true;
        }else if(this.props.state.user.me?.not_registered && this.state.selected_file){//Si es requerido el file y ya se cargo
            return false;
        }else{
            return false;
        }

    }

    render = () => {
        
        const formInitialValues = {
            last_name: this.props.state.user?.me?.last_name? this.props.state.user.me.last_name : '',
            birthday: this.props.state.user?.me?.birthday? this.props.state.user.me.birthday : '',
            first_name: this.props.state.user?.me?.first_name? this.props.state.user.me.first_name : '',            
            username: this.state.user_name? this.state.user_name : '',
            photo: null,
            profile_type: '',
            profession_type:'',
        }

        const role_options = [
            {
                icon: 'icon-fan',
                content: <div className='baloa-table'>{this.props.t('profile:data.modal.text4')}</div>,
                text: this.props.t('profile:data.modal.text3'),
                value: 'fanatic'
            },
            {
                icon: 'icon-player',
                content: <div className='baloa-table'>{this.props.t('profile:data.modal.text2')}</div>,
                text: this.props.t('profile:data.modal.text5'),
                value: 'player'
            },
            {   
                icon: 'icon-coach',
                content: <div className='baloa-table'>{this.props.t('profile:data.modal.text6')}</div>,
                text: this.props.t('profile:data.modal.text7'),
                value: 'professional'
            }
        ]

        return (
            <React.Fragment>
                <div className="signup-form-panel__container">
                    <div className="signup-form-panel__container__left-col" >
                        <Link to="/">
                            { !isMobile 
                                ?
                                <img className="signup-header__image" src={baloa_logo} alt=""/>
                                :
                                <img className="signup-header__image" src={baloa_green_logo} alt=""/>
                            }
                        </Link>
                    </div>
                    <div className="signup-form-panel__container__right-col">
                        <div className='signup-form-panel__container__right_back-option'>
                            <span className='icon-arrow-left baloa-headline-1' onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] )}/>
                            <div className="baloa-headline-1">{this.props.t('info.title')} </div>
                        </div>
                        <FormComponent 
                            formInitialValues={formInitialValues} 
                            onSubmit={( values, actions ) => {
                                this.validateForm();
                            }}
                            className="signup-form-panel__form"
                            functionDisabled={()=>{}}
                            // innerRef={(value)=>console.log(value.values.profile_type)}
                            innerRef={this.formRef}
                        >
                            <div className="signup-form-panel__profile-photo">
                                <div className="baloa-normal">{this.props.t('info.profilePhoto')}</div>
                                <div className='signup-form-panel__profile-photo_box'>
                                    <div className="signup-form-panel__profile-photo__container">
                                        { this.state.user_img_url === null && this.state.user_color_icon !== true && <span className="hubicon-default_pp"><span className="path1"></span><span className="path2"></span></span>}
                                        { this.state.user_img_url === null && this.state.user_color_icon === true && <span className="icon-ball"/>}
                                        { this.state.user_img_url !== null && <img src={this.state.user_img_url} alt="user-img"/>}                                
                                    </div>
                                    <div onClick={()=>{this.handleNewUserImageModal(true)}} 
                                        className='signup-form-panel__profile-photo__container_plus baloa-subtitle-2'>+</div>
                                </div>
                                <div onClick={()=>{this.handleNewUserImageModal(true)}} className="signup-form-panel__profile-photo__upload baloa-names">
                                    {this.props.t('info.uploadImgLabel')}
                                </div>
                            </div>
                            <div className="signup-form-panel__form__first-last-name__container">
                                <InputFieldComponent
                                    label={this.props.t('info.userNames')}
                                    input_id="signup-form-panel__form__first-name" 
                                    field_name="first_name"
                                    validationFunction={ FirstNameValidator }
                                    disabled={false}
                                    className="signup-form-panel__form__field"
                                    input_type="text"
                                    resetError={()=>{}}
                                    error={ this.props.state.signup_error.error }
                                    message=""                            
                                    onChange = { (fname) => { this.changeName( fname ) } }
                                    value = { this.state.first_name }
                                />
                                <InputFieldComponent 
                                    label={this.props.t('info.userSurnames')}
                                    input_id="signup-form-panel__form__last-name" 
                                    field_name="last_name"
                                    validationFunction={ LastNameValidator }
                                    disabled={false}
                                    className="signup-form-panel__form __field"
                                    input_type="text"
                                    resetError={()=>{}}
                                    error={ this.props.state.signup_error.error }
                                    message=""
                                    onChange = { (lname) => { this.changeLastName( lname ) } }
                                    value = { this.state.last_name }
                                />
                            </div>
                            <div className="signup-form-panel__form__birth-day-username__container">
                                <div className="signup-form-panel__form__birth-day-username__container__username">
                                    <InputFieldComponent
                                        label={this.props.t('username.inputLabel')}
                                        input_id="user_name-name" 
                                        field_name="username"
                                        validationFunction={ UserNameValidator }
                                        disabled={false}
                                        className="signup-form-panel__form__username"
                                        input_type="text"
                                        resetError={()=>this.resetError()}
                                        error={ this.props.state.signup_error.error }
                                        message=""
                                        onChange = { (uname) => { this.changeUserName(uname) } }
                                        placeholder="@"
                                        defaultValue={this.state.user_name}
                                    />
                                    <div className="signup-form-panel__form__birth-day-username__container__username-tip baloa-names">{this.props.t('username.tipDescription')}</div>
                                    <div className="baloa-names">{this.props.t('username.tipExample')}</div>
                                </div>
                                <div className="signup-form-panel__form__birth-day-username__container__birth-day">
                                    <InputFieldComponent 
                                        label={this.props.t('info.userBirthDay')}
                                        input_id="signup-birthday" 
                                        field_name="birthday"
                                        //validationFunction={ DateValidator }
                                        disabled={false}
                                        className="signup-form-panel__form__date-field signup-form__field"
                                        input_type="date"
                                        resetError={()=>{this.resetError()}}
                                        error={ this.props.state.signup_error.error }
                                        message=""
                                        placeholder=""
                                        onChange = { (birthday) => { this.changeBirthDay( birthday ) } }
                                        defaultValue={this.state.birthday}
                                    />
                                </div>
                            </div>
                            <div className='signup-form-panel__form__role_container'>
                                <div className='signup-form-panel__form__profile-role'>
                                    <div className='signup-form-panel__form__profile-role_label_tooltip'>
                                        <div className='label_tooltip baloa-table'>{this.props.t('info.labelSelectedRol')}</div>
                                        <div className="signup-form-panel__form__input_grid-tooltip">
                                            <div className="signup-form-panel_tooltip">
                                                <img src={help_icon} alt="" />
                                                <span className="signup-form-panel_tooltiptext">
                                                    <div className="signup-form-panel_tooltiptext_grid_title">
                                                        <p className="baloa-table">{this.props.t('info.labelTooltipText')}</p>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <SelectFieldCleanComponent
                                        field_name="profile_type"
                                        className="profile-role-selector__select-input"
                                        disabled={false}
                                        resetError={()=>{}}
                                        error={ this.props.state.signup_error.error }
                                        message=""
                                        placeholder={this.props.t('info.labelPlaceholder2')}
                                        options={role_options}
                                        defaultValue={this.state.selected_profile_type }
                                        onChange ={(profession) => this.handleSelectedProfession(profession)}
                                        validationFunction={RequiredValidator}
                                    /> 
                                </div>
                                {this.state?.selected_profile_type === 'professional' 
                                ? <div className='signup-form-panel__form__profile-role'>
                                    <InputFieldAutocompleteComponent
                                        name= { 'profession_type' }
                                        field_name="profession_type"
                                        options={this.state.profession_specialties ?? []}
                                        noOptionsText={this.props.t('info.labelNoFound')}
                                        optionLabelField={ "content" }
                                        optionSelectedField={ "value" }
                                        className="profile-profession-selector__select-input"
                                        label={this.props.t('info.labelProfession')}
                                        placeholder={this.props.t('info.labelPlaceholder')}
                                        error={ this.props.state.signup_error.error }
                                        resetError={()=>{}}
                                        disabled={this.state?.selected_profile_type !== 'professional'}
                                        onChange={(e)=>{this.validateProfessionType(e)}}
                                    />
                                    <p className="input-field__message error">{ this.state.form_errors?.profession_type? this.state.form_errors.profession_type : '' }</p>
                                    </div>
                                : ''
                                }
                            </div>
                            {/* <label>{this.props.t('info.userNationality')}</label>
                            <InputFieldAutocompleteWithSearchComponent
                                options={ this.props.state.signup.countries? this.props.state.signup.countries: null }
                                noOptionsText={this.props.t('code.error7')}
                                optionLabelField={ "name" }
                                optionSelectedField={ "id" }
                                lettercount={ "2" }
                                handleSearchWord ={ ( country ) => this.handleSearchCountry( country ) }
                                className="basic-data__form__content__country__input signup-form__field"
                                placeholder=""
                                id="country_autocomplete_new_tournament"
                                field_name="country"
                                validationFunction={ countryCityFieldsValidator }
                                resetError={ () => {}}
                                error={ this.props.state.signup_error.error }
                                disabled={false}
                                value={this.props.state.signup.countries[0]}
                            />  
                            <label>{this.props.t('info.userLocation')}</label>
                            <InputFieldAutocompleteWithSearchComponent
                                options={ this.props.state.signup.cities? this.props.state.signup.cities: null }
                                noOptionsText={this.props.t('code.error7')}
                                optionLabelField={ "name" }
                                optionSelectedField={ "id" }
                                lettercount={ "2" }
                                handleSearchWord ={ ( city ) => this.handleSearchWorldCities( city ) }
                                className="basic-data__form__content__city__input signup-form__field"
                                placeholder=""
                                id="city_autocomplete_new_tournament"
                                field_name="city"
                                validationFunction={ countryCityFieldsValidator }
                                resetError={ () => {}}
                                error={ this.props.state.signup_error.error }
                                disabled = {this.state.disabled_city_field}
                                value={this.props.state.signup.cities[0]}                             
                            />  */}
                            {this.props.state.user.me?.pre_registered_user && this.props.state.user.me?.not_registered &&
                                <div className='signup-form-panel__form__claim-account-file'>
                                    <div className='baloa-names'>{this.props.t('info.claimAccountFileTitle')}</div>
                                    <div className='baloa-table'>{this.props.t('info.claimAccountFileMsg')}</div>
                                    <div className='signup-form-panel__form__claim-account-file__button'>
                                        <input
                                            name={'claim_file'}
                                            style={{ display: 'none' }}
                                            type="file"
                                            onChange={(event) => {this.onFileChange(event)}}
                                            ref={file_input => this.file_input_ref = file_input }
                                            accept="application/pdf"
                                            value=""
                                        />
                                        <PrimaryButtonComponent
                                            disabled={false}
                                            onClick={() => {this.file_input_ref.click()}}
                                            className={'upload-file-button xs'}
                                            type="button"
                                        >
                                            <span className='hubicon-upload icon' />
                                            <span>{this.props.t("info.claimAccountFileUploadBtnLbl")}</span>                                          
                                        </PrimaryButtonComponent>
                                        <div className='baloa-table-column'>{this.state.selected_file?.name? this.state.selected_file?.name : this.props.t('info.claimAccountFileEmptyMsg')}</div>
                                        {this.state.selected_file && <span className='hubicon-clear_option' onClick={()=>{ this.clearFile() }}></span> }                                        
                                    </div>
                                    <p className="input-field__message error">{ this.state.form_errors?.claim_file? this.state.form_errors.claim_file : '' }</p>
                                </div>
                               
                            }
                            {/* <PrimaryButtonComponent type="submit" disabled={ this.props.state.signup.cities.length === 0 || this.props.state.signup.countries.length === 0 } onClick={()=>{}} className="signup-form-panel__form__submit">
                                <span>{this.props.t('code.button')}</span>
                            </PrimaryButtonComponent> */}
                            <PrimaryButtonComponent 
                                type="submit" 
                                disabled={ this.props.state.first_name === '' || this.props.state.last_name === '' || this.props.state.user_name === '' || this.checkNotRegisterFile() } 
                                onClick={()=>{}} 
                                className="signup-form-panel__form__submit"
                            >
                                <span>{this.props.t('page.login.noAccountButton')}</span>
                            </PrimaryButtonComponent>
                        </FormComponent>
                    </div>
                </div>
                <LeagueTeamUploadImageModalComponent
                    isOpen = { this.state.new_user_image_modal}
                    onClose= { ()=>{ this.handleNewUserImageModal(false) } }
                    setTeamImage={ (new_image, imageurl, img_b64)=>{ this.setUserImage(new_image, imageurl, img_b64) } }
                    aspect = {1}
                    cropImage={true}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUserDataAction: ( values, actions, username ) => {
            return dispatch( sendUserDataAction( values, actions, username ))
        },
        getCountriesAction: (country) => {
            return dispatch( getCountriesAction(country) );
        },
        searchCityByCountryAction: (country_id, city) => {
            return dispatch( searchCityByCountryAction(country_id, city) );
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        loadAttributesConstants: () => {
            dispatch( loadAttributesConstants() )
        },
        changeSignUpStatus:()=>{
            dispatch(changeSignUpStatus(SIGNUP_STATUS['LOGIN']));
        },
        sendNoRegisterUserDataAction: async ( new_data, upProgres ) =>{
            await dispatch( sendNoRegisterUserDataAction( new_data, upProgres ) );
        },

    }
}

export default compose(
    withTranslation(['signUp', 'profile']),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpFormPanelComponent);