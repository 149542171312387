// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './TableHeaderButtonsActionsComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function TableHeaderButtonsActionsComponent(props) {

  const { onSearch, has_add_button, add_button_caption, filter_buttons_captions, 
    filter_buttons_states, title_caption, message, enable_add_match_button = true, /*rowsData, 
    rowsMatches,*/ has_autofixtures_button, autofixtures_button_caption, enable_autofixtures_button, has_searchbar, placeholder, 
    has_optional_button, optional_button_caption, show_only_header_title } = props;

    const [buttonSelected, setButtonSelected] = React.useState("1");

    const handleSearch = (button_text, index) => {  
      setButtonSelected(index);                
      onSearch(button_text);
    };

    const handleAddButtonClick = () => {
        props.handleOnAddButtonClick();
    }

    return (       
     
      <div class="component-table-header-buttons__actions_grid-container">
        <div class="component-table-header-buttons__actions-search">
          { has_searchbar &&
            <div class="table-header-actions-search-form__form" autocomplete="off"> 
              <span className="icon-search" />               
              <input class="table-header-actions-search_input baloa-names" type="text" placeholder={placeholder} onChange={(event) => { props.onSearchBar(event) }}></input>                                    
            </div>
          }        
          <div class="component-table-header-buttons__add">
            {!has_add_button && show_only_header_title &&
              <div class="component-table-header-buttons__actions">
                <div className="component-table-header-buttons__actions__title only-title">{title_caption}</div>
              </div>
            }

            {has_add_button && !has_optional_button &&
              <div class="component-table-header-buttons__actions">
                <div className="component-table-header-buttons__actions__title">{title_caption}</div>
                <button type="submit" class="button primary-button component-table-header-button-button baloa-table-column"
                    disabled = { !enable_add_match_button }
                    // disabled = { !props.hasToolTipMessageInAddButton }                  
                    onClick = { ()=> handleAddButtonClick() }
                >
                  <span>{ add_button_caption }</span>
                </button>
                { props.hasToolTipMessageInAddButton && 
                  <div className="component-table-header-buttons__actions__message">{message}</div>
                }
                {has_autofixtures_button &&
                    <button type="submit" className="button secondary-button component-table-header-buttons__button-fixture"
                    disabled = { !enable_autofixtures_button }
                    onClick = { ()=> props.handleOnAutomaticFixture() }
                    >
                        <span>{ autofixtures_button_caption }</span>
                    </button>
                }
                
              </div> 
              
            }  
            {/* { rowsData === 0 && rowsMatches === 0 && !enable_add_match_button === false && 
              <div className="component-table-header-buttons__actions__message">{message}</div>
            }  */}
          
          </div>
          
          { ((has_add_button && !has_optional_button) || (!has_add_button && show_only_header_title)) && <div className="table-header-buttons-action__separator" />}
        </div>
        {has_add_button && has_optional_button &&
        <div class="component-table-header-buttons__add-optional">
          
            <React.Fragment>
              <div class="table-header-actions__form__actions">
                <button type="submit" class="button primary-button"
                    onClick = { ()=> props.handleOnAddButtonClick() }
                >
                <span>{ add_button_caption }</span></button>
              </div>
              <div class="table-header-actions__form__actions">
                <button type="button" class="button secondary-button"
                    onClick = { ()=> props.handleOnOptionalButtonClick() }
                >
                <span>{ optional_button_caption }</span></button>
              </div>
            </React.Fragment>
          
        </div>
        }
        <div class="component-table-header-buttons__filter-buttons">          
          <div class="component-table-header-buttons__list">
            <div key="todos"               
                className={ buttonSelected === "1" ? "component-table-header-button__list_selected" : "component-table-header-button__list"}
                tabindex="1"              
                onClick = { () => handleSearch("todos", "1") }                       
            >
            <div className="baloa-table">{props.t('all').trim()}</div>                      
            </div>         
            { filter_buttons_captions.map( (button_text, index) => {
                return (
                    <div key={ button_text } className={ buttonSelected === (index  + 2) ? "component-table-header-button__list_selected" : "component-table-header-button__list"} tabindex={index + 2}
                        onClick = { () => handleSearch(filter_buttons_states[index], index + 2) }                       
                    >
                        <div className="baloa-table">{ button_text }</div>                      
                    </div>
                )
            }) }          
          </div>
        </div>
      </div>  
      
    );
  }

export default withTranslation('common')(TableHeaderButtonsActionsComponent);

TableHeaderButtonsActionsComponent.propTypes = {  
  onSearch: PropTypes.func.isRequired,    
};

