// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-content{
    padding: var(--space) var(--space-x3);
    text-align: justify;
}

.terms-content__content{}

.terms-content__content__text-english{
    margin-top: var(--space);
}

.terms-content__content__text-spanish{
    margin-top: var(--space-x2);
    margin-bottom: var(--space-x2);
}`, "",{"version":3,"sources":["webpack://./src/app/terms-and-conditions/components/terms/TermsComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA,wBAAwB;;AAExB;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;AAClC","sourcesContent":[".terms-content{\n    padding: var(--space) var(--space-x3);\n    text-align: justify;\n}\n\n.terms-content__content{}\n\n.terms-content__content__text-english{\n    margin-top: var(--space);\n}\n\n.terms-content__content__text-spanish{\n    margin-top: var(--space-x2);\n    margin-bottom: var(--space-x2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
