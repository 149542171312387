// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-id-pdf-generator-modal .modal2__container{
width: 80%;
}

.card-id-pdf-generator-modal__mobile-options {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-match-report-pdf-modal/TournamentMatchReportPdfModalComponent.styles.css"],"names":[],"mappings":"AAAA;AACA,UAAU;AACV;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".card-id-pdf-generator-modal .modal2__container{\nwidth: 80%;\n}\n\n.card-id-pdf-generator-modal__mobile-options {\n    padding: 50px 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
