// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-delete-date__container .baloa-normal-medium {
    margin: 22px 18px;
    color: var(--primary-very-dark-color);
}

.tournament-delete-date__container .baloa-table {
    margin: 0 18px 22px 18px;
    color: var(--primary-dark-color);
}

.tournament-delete-date__container .modal__container {
    max-width: 400px;
    padding: 32px 0 0 0;
}

.tournament-delete-date__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;  
    border-top: 1px solid var(--new-input-background-color); 
    padding: 8px;
}

.tournament-delete-date__actions .button {
    font-size: 15px;
    line-height: 18px;
    padding: 8px 20px;
}

.tournament-delete-date__actions .primary-button {
    background-color: var(--error-color);
}

.tournament-delete-date__actions .secondary-button:hover {
    background-color: transparent;
    color: var(--basic-gray-color);
}

.tournament-delete-date__actions .secondary-button {
    border: none;
    color: var(--basic-gray-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/Tournament-delete-date-modal/TournamentDeleteDateModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,wBAAwB;IACxB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,QAAQ;IACR,uDAAuD;IACvD,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC","sourcesContent":[".tournament-delete-date__container .baloa-normal-medium {\n    margin: 22px 18px;\n    color: var(--primary-very-dark-color);\n}\n\n.tournament-delete-date__container .baloa-table {\n    margin: 0 18px 22px 18px;\n    color: var(--primary-dark-color);\n}\n\n.tournament-delete-date__container .modal__container {\n    max-width: 400px;\n    padding: 32px 0 0 0;\n}\n\n.tournament-delete-date__actions {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 8px;  \n    border-top: 1px solid var(--new-input-background-color); \n    padding: 8px;\n}\n\n.tournament-delete-date__actions .button {\n    font-size: 15px;\n    line-height: 18px;\n    padding: 8px 20px;\n}\n\n.tournament-delete-date__actions .primary-button {\n    background-color: var(--error-color);\n}\n\n.tournament-delete-date__actions .secondary-button:hover {\n    background-color: transparent;\n    color: var(--basic-gray-color);\n}\n\n.tournament-delete-date__actions .secondary-button {\n    border: none;\n    color: var(--basic-gray-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
