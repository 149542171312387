// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-pagination {
	margin: var(--space-and-half) 0;
}
 
.table-pagination ul {
	list-style:none;
	text-align: center;
}
 
.table-pagination ul li {
	display:inline-block;
	margin-right: var(--space-half);
    border: 1px solid var(--primary-light-color);
}
 
.table-pagination ul li a {
    display: flex;
    color: var(--primary-dark-color);
    background: var(--white-color);
    text-decoration: none;
    height: calc( var( --space-and-half ) + var( --space-quarter ) );
    width: calc( var( --space-x2 ) - var( --space-eighth ) );
    align-items: center;
    justify-content: center;
	font-family: var(--secondary-font);
	font-style: normal;
	font-weight: normal;
	font-size: calc( var( --space-half-quarter ) + 1px );
	line-height: var( --space );	
}
 
 .table-pagination ul li .active {
	background: var(--new-green-color);
	cursor: pointer; 
}

.table-pagination ul li .inactive {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-table/TablePagination.styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;AAChC;;AAEA;CACC,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,oBAAoB;CACpB,+BAA+B;IAC5B,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,8BAA8B;IAC9B,qBAAqB;IACrB,gEAAgE;IAChE,wDAAwD;IACxD,mBAAmB;IACnB,uBAAuB;CAC1B,kCAAkC;CAClC,kBAAkB;CAClB,mBAAmB;CACnB,oDAAoD;CACpD,2BAA2B;AAC5B;;CAEC;CACA,kCAAkC;CAClC,eAAe;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".table-pagination {\n\tmargin: var(--space-and-half) 0;\n}\n \n.table-pagination ul {\n\tlist-style:none;\n\ttext-align: center;\n}\n \n.table-pagination ul li {\n\tdisplay:inline-block;\n\tmargin-right: var(--space-half);\n    border: 1px solid var(--primary-light-color);\n}\n \n.table-pagination ul li a {\n    display: flex;\n    color: var(--primary-dark-color);\n    background: var(--white-color);\n    text-decoration: none;\n    height: calc( var( --space-and-half ) + var( --space-quarter ) );\n    width: calc( var( --space-x2 ) - var( --space-eighth ) );\n    align-items: center;\n    justify-content: center;\n\tfont-family: var(--secondary-font);\n\tfont-style: normal;\n\tfont-weight: normal;\n\tfont-size: calc( var( --space-half-quarter ) + 1px );\n\tline-height: var( --space );\t\n}\n \n .table-pagination ul li .active {\n\tbackground: var(--new-green-color);\n\tcursor: pointer; \n}\n\n.table-pagination ul li .inactive {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
