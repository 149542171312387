// React
import React from 'react';

// Styles
import './TournamentPhaseFixtureResumeComponent.styles.css';

//Components
//import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentMatchEditMainInfoComponent from '../tournament-match-edit-main-info/TournamentMatchEditMainInfoComponent';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentPhaseFixtureResumeComponent( props ) {

    const [reorganizeFixture, setStateReorganizeFixture ] = React.useState(false);
    const [infoType, setInfoType] = React.useState('');
    const [matchIndex, setMatchIndex] = React.useState('');

    const handleOnReorganize = () => {
        setStateReorganizeFixture(true);
        //props.handleOnReorganize(props.fixture.id, props.fixture.roundtrip);
        props.handleOnReorganize(props.fixture.id);
    }

    /* const handleOnAccept = () => {
        setStateReorganizeFixture(false);
        props.handleOnAccept();
    } */

    const handleEdit = (info_type, match_index, match_state) => {
        if( match_state === 'Finalized' && (info_type === 'date-time' || info_type === 'field') ) {
            setInfoType('');
            setMatchIndex(null);
        } else {
            setInfoType(info_type);
            setMatchIndex(match_index)
        }
    }

    const handleSubmitForm = (info_type, values, match) => {
        //let match_city = props.cities.filter( (city) => city.name === props.match.match_city )
        let match_info = {
            "local_team": match.local_team,
            "visitant_team": match.visitant_team,
            "match_date": match.match_date ? match.match_date : null,
            "match_time": match.match_time ? match.match_time : null,
            "field": match.match_field ? match.match_field.name : null,
            "match_city": null, 
            "official_referee": match.official_referee ? match.official_referee : null,
            "second_assistant_referee": match.second_assistant_referee ? match.second_assistant_referee : null,
            "third_assistant_referee": match.third_assistant_referee ? match.third_assistant_referee : null,
            "fourth_official_referee": match.fourth_official_referee ? match.fourth_official_referee : null,
            "referee_assigner": match.referee_assigner ? match.referee_assigner : null,
            "date_match": null,
            "match_field": match.match_field ? match.match_field.id : null,
        }

        if(info_type === 'date-time') { 
        const [date, time] = values.match_date.split('T');
            match_info.match_date = date;
            match_info.match_time = time;
        }
        if(info_type === 'field') { 
            match_info.match_field = values.field;
        }

        props.handleSubmitEditInfoMatch(match.id, match_info);
        setInfoType('');
    }

    return (
        <div className="phase-fixtures-resume__container scroll-parent">
            {props.loading_fixture_state &&
                <div className="phase-fixtures-resume__loader-container">
                    <div className="phase-fixtures-resume__loader-text baloa-body-1">{!reorganizeFixture? props.t('options.tournaments.automaticFixturesLoaderText'): `${props.t('options.tournaments.automaticFixturesLoaderText2')}...` }</div>
                    <div className="phase-fixtures-resume__loader"></div>
                </div>
            }
            {!props.loading_fixture_state &&
                <React.Fragment>
                    {/* <div className="phase-fixtures-resume__title baloa-subtitle-2">{props.t('options.tournaments.automaticFixturesTitle')}</div> */}
                    <div className="phase-fixtures-resume__counts">
                        <div className="baloa-names">{ `${props.t('options.tournaments.automaticFixturesTeams')}: ${props.phase_teams?.length}` }</div>
                        <div className="baloa-names">{ `${props.t('options.tournaments.automaticFixturesDates')}: ${props.phase_dates}` }</div>
                        {/* <div className="baloa-names">{ `${props.t('options.tournaments.automaticFixturesRoundtrip')} ${props.fixture.roundtrip? props.t('options.tournaments.automaticFixturesRoundtripYes'): props.t('options.tournaments.automaticFixturesRoundtripNo')}`}</div> */}
                    </div>   
                    <div className='tournament-phase-fixtures-resume__warning'>
                        <span className='hubicon-info' />
                        <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesMsg')}</div>                        
                    </div>                 
                    <div className="phase-fixtures-resume__buttons">
                        {/* <PrimaryButtonComponent 
                            type="submit"
                            disabled={false}
                            onClick={()=>{ handleOnAccept() }} 
                            className="phase-fixtures-resume__buttons-btn2"
                        >
                            <span>{props.t('options.tournaments.automaticFixturesBtn2')}</span>
                        </PrimaryButtonComponent> */}
                        <SecondaryButtonComponent
                            type="button"
                            onClick={ ()=>{ handleOnReorganize() } }
                            disabled={false}
                            className="phase-fixtures-resume__buttons-btn1"
                        >
                            <span>{props.t('options.tournaments.automaticFixturesBtn1')}</span>
                        </SecondaryButtonComponent>                        
                    </div>
                    {/* <div className="phase-fixtures-resume__separator"></div> */}
                    <div className="phase-fixtures-resume__matches scroll">
                        {props.fixture?.calendar?.map((jornadas, index0) =>(
                            <div className="phase-fixtures-resume__groups">
                            <div className="phase-fixtures-resume__group-title baloa-subtitle-2">{jornadas.match_date}</div>
                            {(jornadas.groups.map((groups, index1) => (
                                <div className="phase-fixtures-resume__date">
                                    {props.phase_type !== 'Free' &&
                                        <div className="phase-fixtures-resume__date-title baloa-names">{groups.group_name}</div>
                                    }
                                    
                                    {groups.matches.map((dat, index2) =>(
                                        <div className="phase-fixtures-resume__match-container" key={`${index0}-${index1}-${index2}`}>
                                            <div className="phase-fixtures-resume__match-actions">
                                                <div className='phase-fixtures-resume__match-actions__action'>
                                                    <div className={`phase-fixtures-resume__info-edit ${ (infoType === 'date-time' && matchIndex === `${index0}-${index1}-${index2}`)? 'show' : '' }`} >
                                                        <TournamentMatchEditMainInfoComponent 
                                                            key={`${index0}-${index1}-${index2}-date-time`}
                                                            info_type='date-time'
                                                            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddDateTime')}
                                                            handleClose={ () => setInfoType('') }
                                                            handleEditMatchInfo={ (values) => handleSubmitForm('date-time', values, dat) }
                                                        />
                                                    </div>
                                                        <span className='hubicon-finish_date' />
                                                        <div className='' onClick={ () =>{ handleEdit('date-time', `${index0}-${index1}-${index2}`, dat.state)} }  >
                                                            { dat.state === 'Not_scheduled' && !dat.match_date && !dat.match_time &&
                                                                <div className='baloa-username not-scheduled'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAdd')}</div>
                                                            }
                                                            { dat.match_date && dat.match_time &&
                                                                <div className={`baloa-username match-date`}>{`${moment( dat.match_date ).format( 'ddd D MMM YYYY' )} - ${moment( dat.match_time, ['HH:mm'] ).format('h:mm a')}`}</div>
                                                            }
                                                        </div>
                                                    
                                                </div>
                                                <div className='phase-fixtures-resume__match-actions__action'>
                                                    <div className={`phase-fixtures-resume__info-edit ${ (infoType === 'field' && matchIndex === `${index0}-${index1}-${index2}`)? 'show' : '' }`} >
                                                        <TournamentMatchEditMainInfoComponent
                                                            key={`${index0}-${index1}-${index2}-field`} 
                                                            info_type='field'
                                                            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddField')}
                                                            handleClose={ () => setInfoType('') }
                                                            handleEditMatchInfo={ (values) => handleSubmitForm('field', values, dat) }
                                                            tournament_fields={ props.tournament_fields }
                                                            new_page_field={ props.new_page_field }
                                                            handleFieldsAdminActions={ props.handleFieldsAdminActions }
                                                            handleTournamentFieldActions={ props.handleTournamentFieldActions }
                                                        />
                                                    </div>                 
                                                    <span className='hubicon-stadium' />
                                                    <div className='baloa-username' onClick={ () => {handleEdit('field', `${index0}-${index1}-${index2}`, dat.state)} } >
                                                        { dat.state === 'Not_scheduled' && !dat.field &&
                                                            <div className='baloa-username not-scheduled'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAdd')}</div>
                                                        }
                                                        { dat.field &&
                                                            <div className={`baloa-username match-date`}>{dat.field}</div>
                                                        }                        
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="phase-fixtures-resume__match">
                                                <div className="phase-fixtures-resume__team">
                                                    <div className="phase-fixtures-resume__team-image">
                                                        {dat.local_team_photo?
                                                            <img src={dat.local_team_photo} alt={`${dat.local_team_name}`} />
                                                        :
                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                        }                                    
                                                    </div>
                                                    <div className="phase-fixtures-resume__team-name baloa-username">{dat.local_team_name}</div>
                                                </div>
                                                <div className="phase-fixtures-resume__vs baloa-username">{"VS"}</div>
                                                <div className="phase-fixtures-resume__team">
                                                    <div className="phase-fixtures-resume__team-name baloa-username">{dat.visitant_team_name}</div>
                                                    <div className="phase-fixtures-resume__team-image">
                                                        {dat.visitant_team_photo?
                                                            <img src={dat.visitant_team_photo} alt={`${dat.visitant_team_name}`}/>
                                                        :
                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                        }                                    
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                            )))}
                            </div>
                        ))
                        }
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default withTranslation('league')(TournamentPhaseFixtureResumeComponent);
