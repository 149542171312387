// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import { useFormik } from 'formik';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';

// Styles
import './ProfileBasicInformationModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileBasicInformationModalComponent( props ) {

    const [defaultCountry, setDefaultCountry] = React.useState(null);
    const [defaultLocation, setDefaultLocation] = React.useState(null);

    React.useEffect(() => {
        setDefaultCountry(props.model?.nationality)
    }, [props.model?.nationality]); // eslint-disable-line

    React.useEffect(() => {
        setDefaultLocation(props.model?.location)
    }, [props.model?.location]); // eslint-disable-line


    const WEIGHT_TYPE = [
        {
            content: 'Kg',
            value: 'kg'
        },
        {
            content: 'Lb',
            value: 'lb'
        },
    ]

    const HEIGHT_TYPE = [
        {
            content: 'Cm',
            value: 'cm'
        },
        {
            content: 'Ft',
            value: 'ft'
        },
    ]    

    const formikvalues = useFormik({
        initialValues: {
            nationality: defaultCountry ? defaultCountry.id : null,
            birthday: props.model.user?.birthday ? props.model?.user?.birthday : null,
            weight: props.model.user?.weight ? props.model.user?.weight : null,
            height: props.model.user?.height ? props.model.user?.height : null,
            weight_unit: props.model.user?.weight_unit ? props.model.user?.weight_unit : WEIGHT_TYPE[0].value,
            height_unit: props.model.user?.height_unit ? props.model.user?.height_unit : HEIGHT_TYPE[0].value,
            location: props.model?.location? props.model?.location.id : null,
        },
    });

    return (
        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('data.title')}
            className='profile-basic-information-modal'
        >            
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => {   
                    let profile_data = {
                        "nationality": defaultCountry ? defaultCountry.id : null,
                        "birthday": values.birthday,
                        "height": values.height !== "" ? values.height : null,
                        "height_unit": values.height_unit,
                        "weight": values.weight !== "" ? values.weight : null,
                        "weight_unit": values.weight_unit,
                        "location": values.location? props.cities.find(city => city.name === values.location).id : null
                    }
                    props.handleUpdateProfileData( profile_data );
                }}
                className="profile-basic-information-modal__form form"
                autocomplete="off"
            >
                <div className="profile-basic-information-modal__content">
                    <div className='profile-basic-information-modal__title baloa-table-column'>{props.t('data.modal.modalBasicDataText',{__profile_type: props.t('role.'+props.model.profile_type)})}</div>
                    {props.model.profile_type !== 'fanatic' &&
                        <InputFieldComponent
                            label={props.t('data.labelBirthday')}
                            input_id="profile-basic-information-modal__birthday" 
                            field_name="birthday"
                            className="profile-basic-information-modal__birthday"
                            input_type="date"
                            resetError={ () => {} }
                            error={ {birthday: ''} }                            
                            disabled={ false }
                            onChange={ () => {} }
                            defaultValue={ props.model.user?.birthday ? props.model.user?.birthday : '' }                         
                        />
                    }
                    {props.model.profile_type === 'player' &&
                        <div className='profile-basic-information-modal__weight-height'>
                            <InputFieldComponent
                                label={props.t('playerCard.weight')}
                                input_id="profile-basic-information-modal__weight" 
                                field_name="weight"
                                className="profile-basic-information-modal__weight"
                                input_type="number"
                                resetError={ () => {} }
                                error={ {weight: ''} }                            
                                disabled={ false }
                                onChange={ () => {} }
                                defaultValue={ props.model.user?.weight ? props.model.user?.weight : '' }
                            />
                            <SelectFieldCleanComponent
                                field_name="weight_unit"
                                input_id="weight_unit"
                                className='profile-basic-information-modal__unit-type'
                                resetError={() => {}}
                                error={{weight_unit: ''}}
                                message=""
                                placeholder=""
                                options={WEIGHT_TYPE}
                                onChange={(v) => { }}
                                defaultValue={props.model.user?.weight_unit ? props.model.user?.weight_unit : WEIGHT_TYPE[0].value}
                            />
                        </div>
                    }
                    {props.model.profile_type === 'player' &&
                        <div className='profile-basic-information-modal__weight-height'>
                            <InputFieldComponent
                                label={props.t('playerCard.height')}
                                input_id="profile-basic-information-modal__height" 
                                field_name="height"
                                className="profile-basic-information-modal__height"
                                input_type="number"
                                resetError={ () => {} }
                                error={ {height: ''} }                            
                                disabled={ false }
                                onChange={ () => {} }
                                defaultValue={ props.model.user?.height ? props.model.user?.height : '' }
                            />
                            <SelectFieldCleanComponent
                                field_name="height_unit"
                                input_id="height_unit"
                                className='profile-basic-information-modal__unit-type'
                                resetError={() => {}}
                                error={{height_unit: ''}}
                                message=""
                                placeholder=""
                                options={HEIGHT_TYPE}
                                onChange={(v) => { }}
                                defaultValue={props.model.user?.height_unit ? props.model.user?.height_unit : HEIGHT_TYPE[0].value}
                            />
                        </div>
                    }
                    <div className='profile-basic-information__nationality__container'> 
                        <InputFieldAutocompleteWithSearchComponent
                            options={ props.nationalities? props.nationalities : null}
                            noOptionsText={props.t('data.modal.noOptionsText')}
                            optionLabelField={ "name" }
                            optionSelectedField={ "id" }
                            lettercount={ "2" }
                            handleSearchWord ={ ( newCountryValue ) => props.handleSearchNationality( newCountryValue ) }
                            className="profile-basic-information__nationality"
                            label={props.t('data.textNationality')}
                            placeholder={props.t('data.modal.nationalityPlaceholder')}
                            id="profile-basic-information__nationality"
                            field_name="nationality"
                            resetError={ () => {}}
                            error={{nationality: ''}}
                            value={defaultCountry}
                            country_logo={true}
                            disabled={false}
                            handleSetDefaultCountry={(value) => {setDefaultCountry(value)}}                            
                        />
                    </div>
                    <div className='profile-basic-information__nationality__container'> 
                        <InputFieldAutocompleteWithSearchComponent
                            options={ props.cities? props.cities : null}
                            noOptionsText={props.t('data.modal.noOptionsText')}
                            optionLabelField={ "name" }
                            optionSelectedField={ "id" }
                            lettercount={ "2" }
                            handleSearchWord ={ ( city ) => props.searchProfileWorldCities( city ) }
                            className="profile-basic-information__nationality"
                            label={props.t('data.textLocation')}
                            placeholder={props.t('data.modal.locationPlaceholder')}
                            id="profile-basic-information__location"
                            field_name="location"
                            resetError={ () => {}}
                            error={{location: ''}}
                            value={defaultLocation}
                            country_logo={false}
                            disabled={false}
                            handleSetDefaultCountry={(value) => {setDefaultLocation(value)}} 
                            world_city={true}                         
                        />
                    </div>
                </div>
                <div className="profile-basic-information-modal__actions form__actions">
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="profile-basic-information-modal__submit"
                    >
                        <span>{props.t('settings.btnSave') }</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        </Modal2Component>
    )
}
    
export default withTranslation('profile')(ProfileBasicInformationModalComponent);