
// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetUserNotificationsApi = () => {

    return Axios.instance.get( `${api_url}/notifications/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetUserNotificationsNextApi = ( next_page ) => {

    return Axios.instance.get( `${next_page}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const SetReadNotification = ( id_notification ) => {

    return Axios.instance.patch( `${ api_url }/notifications/${ id_notification }/read/`,
        {
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}
