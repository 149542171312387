
export function permissionsController( roles, accessArray ) {

    let valid = false;
    for (var i=0; i < accessArray.length; i++) {        
        valid = checkAccess(roles, accessArray[i] );
        if (valid === true)
            break;
    }

    return valid;
}

function checkAccess(roles, access){

    switch( access ) {

        case 'CONTENTAD':
            if(roles?.content_admin === true){
                return true;
            }else{
                return false;
            }
        case 'TEAMAD':
            if(roles?.team_admin === true){
                return true;
            }else{
                return false;
            }
        case 'STAFFAD':
            if(roles?.staff_admin === true){
                return true;
            }else{
                return false;
            }
        case 'TOURNAMENTAD':
            if(roles?.tournament_admin === true){
                return true;
            }else{
                return false;
            }
        case 'MATCHAD':
            if(roles?.match_admin === true){
                return true;
            }else{
                return false;
            }
        case 'HASPAGEROLES':
            if(roles?.team_admin === true || roles?.staff_admin === true || roles?.tournament_admin === true || roles?.match_admin === true ){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
}