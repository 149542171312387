// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-dynamic-forms-modal__container {
    padding: var(--space-x2);
}

.tournament-dynamic-forms-modal .modal2__content {
    overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-dynamic-forms-modal/TournamentDynamicFormsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".tournament-dynamic-forms-modal__container {\n    padding: var(--space-x2);\n}\n\n.tournament-dynamic-forms-modal .modal2__content {\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
