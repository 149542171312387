// React
import React from 'react';

//Material
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import FormComponent from 'shared/components/form/FormComponent';
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';

//Material
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


//Modals
import HomePlayersFilterModalComponent from 'app/home/components/home-players-filter/HomePlayersFilterModalComponent';

//Functions
import convertFieldPositionCode from 'shared/functions/ConvertFieldPositionCode';
import convertLateralityCode from 'shared/functions/ConvertLateralityCode';

// Styles
import './HomePlayersFilterComponent.styles.css';

//Asets
import filterIcon from 'assets/icons/filter-tournament-icon.svg';


// i18n
import { withTranslation } from 'react-i18next';

// isMobile
import { isMobile } from 'react-device-detect';

function HomePlayersFilterComponent( props ) {
    const [defaultCountry, setDefaultCountry] = React.useState(null);
    const [defaultLocation, setDefaultLocation] = React.useState(null);

    const [filterVideo, setFilterVideo] = React.useState(false);
    const [filterAge, setFilterAge] = React.useState([]);
    const [filterPrimaryPos, setFilterPrimaryPos] = React.useState([]);
    const [filterSecondaryPos, setFilterSecondaryPos] = React.useState([]);
    const [filterLaterality, setFilterLaterality] = React.useState([]);
    const [filterGoalStats, setFilterGoalStats] = React.useState(null);
    const [filterAssistances, setFilterAssistances] = React.useState(null);
    const [filterPlayedGames, setFilterPlayedGames] = React.useState(null);
    const [filterStarterGames, setFilterStarterGames] = React.useState(null);
    const [filterPlayedMinutes, setFilterPlayedMinutes] = React.useState(null);
    
    const [goalOption, setGoalOption] = React.useState('complete_history');
    const [assistanceOption, setAssistanceOption] = React.useState('complete_history');
    const [playedGamesOption, setPlayedGamesOption] = React.useState('complete_history');
    const [starterGamesOption, setStarterGamesOption] = React.useState('complete_history');
    const [playedMinutesOption, setPlayedMinutesOption] = React.useState('complete_history');

    const [searchAutoFocus, setSearchAutoFocus] = React.useState(true);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const formRef = React.useRef();
    const initialValues = {
        searchfield: '',
        nationality: '',
        location: '',
    }

    const [filterModalAge, setFilterModalAge] = React.useState(()=>[]);
    const [filterModalPrimaryPos, setFilterModalPrimaryPos] = React.useState(()=>[]);
    const [filterModalSecondaryPos, setFilterModalSecondaryPos] = React.useState(()=>[]);
    const [filterModalLaterality, setFilterModalLaterality] = React.useState(()=>[]);

    const handleOnFilterVideo  = (e) => {
        setSearchAutoFocus(false);
        if(e.target.checked){
            setFilterVideo(true);
        }else{
            setFilterVideo(false);
        }
        formRef.current?.submitForm();
    }

    const handleOnFilterAge = (e) => {
        setSearchAutoFocus(false);
        if(e.target.checked){
            setFilterAge(current => [...current, e.target.value]);
        }else{
            setFilterAge((current) =>
                current.filter((age) => age !== e.target.value)
            );            
        }
        formRef.current?.submitForm();
    }

    const handleOnFilterPrimaryPos = (e) => {
        setSearchAutoFocus(false);
        if(e.target.checked){
            setFilterPrimaryPos(current => [...current, e.target.value]);
        }else{
            setFilterPrimaryPos((current) =>
                current.filter((primaryPos) => primaryPos !== e.target.value)
            );            
        }
        formRef.current?.submitForm();
    }

    const handleOnFilterSecondaryPos = (e) => {
        setSearchAutoFocus(false);
        if(e.target.checked){
            setFilterSecondaryPos(current => [...current, e.target.value]);
        }else{
            setFilterSecondaryPos((current) =>
                current.filter((secondaryPos) => secondaryPos !== e.target.value)
            );            
        }
        formRef.current?.submitForm();
    }

    const handleOnFilterLaterality = (e) => {
        setSearchAutoFocus(false);
        if(e.target.checked){
            setFilterLaterality(current => [...current, e.target.value]);
        }else{
            setFilterLaterality((current) =>
                current.filter((laterality) => laterality !== e.target.value)
            );            
        }
        formRef.current?.submitForm();
    }    

    const handleOnFilterGoalStats = (e) => {
        setSearchAutoFocus(false);
        setFilterGoalStats( e.target.value );
        formRef.current?.submitForm();
    }

    const handleOnFilterAssistances = (e) => {
        setSearchAutoFocus(false);
        setFilterAssistances( e.target.value );
        formRef.current?.submitForm();
    }

    const handleOnFilterPlayedGames = (e) => {
        setSearchAutoFocus(false);
        setFilterPlayedGames( e.target.value );
        formRef.current?.submitForm();
    }

    const handleOnFilterStarterGames = (e) => {
        setSearchAutoFocus(false);
        setFilterStarterGames( e.target.value );
        formRef.current?.submitForm();
    }

    const handleOnFilterPlayedMinutes = (e) => {
        setSearchAutoFocus(false);
        setFilterPlayedMinutes( e.target.value );
        formRef.current?.submitForm();
    }

    const handleOnFilterModalVideo  = (e) => {
        if(e.target.checked){
            setFilterVideo(true);
        }else{
            setFilterVideo(false);
        }
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalAge = (e, ageArray) => {
        setFilterModalAge(ageArray);
        formRef.current?.setFieldValue("age", ageArray);
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalPrimaryPos = (e, ageArray) => {
        setFilterModalPrimaryPos(ageArray);
        formRef.current?.setFieldValue("primaryposition", ageArray);
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalSecondaryPos = (e, ageArray) => {
        setFilterModalSecondaryPos(ageArray);
        formRef.current?.setFieldValue("secondaryposition", ageArray);
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalLaterality = (e, ageArray) => {
        setFilterModalLaterality(ageArray);
        formRef.current?.setFieldValue("laterality", ageArray);
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalGoalStats = (e) => {
        setFilterGoalStats( e.target.value );
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalAssistances = (e) => {
        setFilterAssistances( e.target.value );
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalPlayedGames = (e) => {
        setFilterPlayedGames( e.target.value );
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalStarterGames = (e) => {
        setFilterStarterGames( e.target.value );
        //formRef.current?.submitForm();
    }

    const handleOnFilterModalPlayedMinutes = (e) => {
        setFilterPlayedMinutes( e.target.value );
        //formRef.current?.submitForm();
    }

    const buildApiRequest = (values) => {
        let nationality = null;
        if((values.nationality && values.nationality !== "")){
            nationality = props.nationalities.find(natio => natio.name === values.nationality)?.id;
        }

        let location = null;
        if((values.location && values.location !== "")){
            location = props.cities.find(location => location.name === values.location)?.id;
        }

        let age_query = '';
        if(values.age && values.age.length > 0){
            values.age.map(age=>(
                age_query = age_query + '|' + age
            ))
            age_query = age_query.substring(1);
        }

        let primaryp_query = '';
        if(values.primaryposition && values.primaryposition.length > 0){
            values.primaryposition.map(primaryp=>(
                primaryp_query = primaryp_query + '|' + primaryp
            ))
            primaryp_query = primaryp_query.substring(1);
        }

        let secondaryp_query = '';
        if(values.secondaryposition && values.secondaryposition.length > 0){
            values.secondaryposition.map(secondaryp=>(
                secondaryp_query = secondaryp_query + '|' + secondaryp
            ))
            secondaryp_query = secondaryp_query.substring(1);
        }

        let laterality_query = '';
        if(values.laterality && values.laterality.length > 0){
            values.laterality.map(laterality=>(
                laterality_query = laterality_query + '|' + laterality
            ))
            laterality_query = laterality_query.substring(1);
        }

        props.handleOnPlayersFilter(values.searchfield, filterVideo, nationality, location, age_query, primaryp_query, secondaryp_query, laterality_query, goalOption, filterGoalStats, assistanceOption, filterAssistances, playedGamesOption, filterPlayedGames, starterGamesOption, filterStarterGames, playedMinutesOption, filterPlayedMinutes, null);
    }    

    const handleFilterOpen = ( state ) => {
        setFilterOpen( state );
    }

    const handleModalFilter = () => {
        formRef.current?.submitForm();
        setFilterOpen( false );
    }

    const resetModalFilter = () => {
        setFilterVideo(false);
        setDefaultCountry(null);
        setDefaultLocation(null);
        setFilterModalAge(current => [])
        setFilterModalPrimaryPos(current => [])
        setFilterModalSecondaryPos(current => [])
        setFilterModalLaterality(current => []);
        setFilterGoalStats(null);
        setGoalOption('complete_history');
        setFilterAssistances(null);
        setAssistanceOption('complete_history');
        setFilterPlayedGames(null);
        setPlayedGamesOption('complete_history');
        setFilterStarterGames(null);
        setStarterGamesOption('complete_history');
        setFilterPlayedMinutes(null);
        setPlayedMinutesOption('complete_history');
        formRef.current?.setFieldValue("nationality", undefined);
        formRef.current?.setFieldValue("location", undefined);
        formRef.current?.setFieldValue("age", null);
        formRef.current?.setFieldValue("primaryposition", null);
        formRef.current?.setFieldValue("secondaryposition", null);
        formRef.current?.setFieldValue("laterality", null);
        formRef.current?.submitForm();
        setFilterOpen( false );
    }

    const handleWordSearch = (word) => {
        setSearchAutoFocus(true);
        formRef.current?.submitForm();
    }

    const yearsList = [
        {content:`1 ${props.t('profile:data.textYears')}`, value: '1'},
        {content:`2 ${props.t('profile:data.textYears')}`, value: '2'},
        {content:`3 ${props.t('profile:data.textYears')}`, value: '3'},
        {content:`4 ${props.t('profile:data.textYears')}`, value: '4'},
        {content:`5 ${props.t('profile:data.textYears')}`, value: '5'},
        {content:`6 ${props.t('profile:data.textYears')}`, value: '6'},
        {content:`7 ${props.t('profile:data.textYears')}`, value: '7'},
        {content:`8 ${props.t('profile:data.textYears')}`, value: '8'},
        {content:`9 ${props.t('profile:data.textYears')}`, value: '9'},
        {content:`10 ${props.t('profile:data.textYears')}`, value: '10'},
        {content:`11 ${props.t('profile:data.textYears')}`, value: '11'},
        {content:`12 ${props.t('profile:data.textYears')}`, value: '12'},
        {content:`13 ${props.t('profile:data.textYears')}`, value: '13'},
        {content:`14 ${props.t('profile:data.textYears')}`, value: '14'},
        {content:`15 ${props.t('profile:data.textYears')}`, value: '15'},
        {content:`16 ${props.t('profile:data.textYears')}`, value: '16'},
        {content:`17 ${props.t('profile:data.textYears')}`, value: '17'},
        {content:`18 ${props.t('profile:data.textYears')}`, value: '18'},
        {content:`19 ${props.t('profile:data.textYears')}`, value: '19'},
        {content:`20 ${props.t('profile:data.textYears')}`, value: '20'},
        {content:`21 ${props.t('profile:data.textYears')}`, value: '21'},
        {content:`22 ${props.t('profile:data.textYears')}`, value: '22'},
        {content:`23 ${props.t('profile:data.textYears')}`, value: '23'},
        {content: props.t('players.filter.year24Lbl'), value: '24'},
    ];

    

return (
    <div className='home-players-filter__container'>
        <div className="home-players-filter__title">
                <span className="baloa-headline-2">{props.t('quickAccess.players')}</span>
                {isMobile &&
                    <span className='home-players__close_icon icon-x' onClick={ ()=>{ props.handleClickMenuSugestions("post") } } /> 
                }
        </div>
        {!isMobile &&                
            <div className="home-players-filter__content scroll">
                <FormComponent
                    formInitialValues={ initialValues }
                    onSubmit={( values, actions ) => {
                        buildApiRequest(values);
                    }}
                    //className="search-form__form form"
                    autocomplete="off"
                    onBlur={ ()=>{setSearchAutoFocus(false)}  }
                    innerRef={formRef}
                >  
                    <InputSearchComponent         
                        id="searchfield"
                        name="searchfield"   
                        placeholder={props.t('players.filter.searchPlaceholder')}
                        handleSearch={(search) => { handleWordSearch(search) }}
                        disabled={/* filterOpen */false}
                        removeWordLenghtValidation={false}
                        autoFocus={searchAutoFocus}
                        searchword={props.playersearch}
                    />
                    <FormControlLabel
                        name={'video'}
                        value={'video'}
                        control={<Switch color="secondary" checked={ filterVideo } />}
                        label={<span className="baloa-names">
                                {props.t('players.filter.videoLbl')}
                            </span>}
                        labelPlacement="start"
                        className={'home-players-filter__switch'}
                        onChange={(e) => handleOnFilterVideo(e)}
                    />

                    <InputFieldAutocompleteWithSearchComponent
                        options={ props.nationalities? props.nationalities : null}
                        noOptionsText={props.t('profile:data.modal.noOptionsText')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "id" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( newCountryValue ) => props.handleSearchNationality( newCountryValue ) }
                        className="home-players-filter__natiolocation"
                        label={<span className="baloa-names">{props.t('players.filter.nationalityTitle')}</span>}
                        placeholder={props.t('players.filter.nationalityPlaceholder')}
                        id="nationality"
                        field_name="nationality"
                        resetError={ () => {}}
                        error={{nationality: ''}}
                        value={defaultCountry}
                        country_logo={true}
                        disabled={false}
                        handleSetDefaultCountry={(value) => {setDefaultCountry(value); formRef.current?.submitForm();}}                            
                    />

                    <InputFieldAutocompleteWithSearchComponent
                        options={ props.cities? props.cities : null}
                        noOptionsText={props.t('profile:data.modal.noOptionsText')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "id" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( city ) => props.searchProfileWorldCities( city ) }
                        className="home-players-filter__natiolocation"
                        label={<span className="baloa-names">{props.t('profile:data.textLocation')}</span>}
                        placeholder={props.t('players.filter.locationPlaceholder')}
                        id="location"
                        field_name="location"
                        resetError={ () => {}}
                        error={{location: ''}}
                        value={defaultLocation}
                        country_logo={false}
                        disabled={false}
                        handleSetDefaultCountry={(value) => {setDefaultLocation(value); formRef.current?.submitForm();}} 
                        world_city={true}                         
                    />
                                            
                    {/** Age */}
                    <Accordion className='home-players-filter__accordion' /* expanded={expanded2} onChange={()=>{ handleExpanded2() }} */>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className='baloa-mini'                
                        >                                    
                            <div className='baloa-names'>{props.t('profile:data.textAge')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {yearsList.map(age => (
                                <FormControlLabel
                                    name={'age'}
                                    key={age.value}
                                    value={age.value}
                                    control={<Checkbox color="secondary" checked={ filterAge.some(ply => ply === age.value) } />}
                                    label={<span className="baloa-names">
                                            {age.content}
                                        </span>}
                                    labelPlacement="end"
                                    className={'home-players-filter__checkbox'}
                                    onChange={(e) => handleOnFilterAge(e)}
                                />
                            ))}                
                        </AccordionDetails>
                    </Accordion>
                    {/** Primary position */}
                    <Accordion className='home-players-filter__accordion' /* expanded={expanded2} onChange={()=>{ handleExpanded2() }} */>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className='baloa-mini'                
                        >                                    
                            <div className='baloa-names'>{props.t('profile:playerCard.position')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {props.primaryPositionOptions?.map(primaryPos => (
                                <FormControlLabel
                                    name={'primaryposition'}
                                    key={primaryPos}
                                    value={primaryPos}
                                    control={<Checkbox color="secondary" checked={ filterPrimaryPos.some(ply => ply === primaryPos) } />}
                                    label={<span className="baloa-names">
                                            {convertFieldPositionCode(primaryPos)}
                                        </span>}
                                    labelPlacement="end"
                                    className={'home-players-filter__checkbox'}
                                    onChange={(e) => handleOnFilterPrimaryPos(e)}
                                />
                            ))}                
                        </AccordionDetails>
                    </Accordion>
                    {/** Secondary position */}
                    <Accordion className='home-players-filter__accordion' /* expanded={expanded2} onChange={()=>{ handleExpanded2() }} */>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className='baloa-mini'                
                        >                                    
                            <div className='baloa-names'>{props.t('profile:playerCard.altenativePosition')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {props.secondaryPositionOptions?.map(secondaryPos => (
                                <FormControlLabel
                                    name={'secondaryposition'}
                                    key={secondaryPos}
                                    value={secondaryPos}
                                    control={<Checkbox color="secondary" checked={ filterSecondaryPos.some(ply => ply === secondaryPos) } />}
                                    label={<span className="baloa-names">
                                            {convertFieldPositionCode(secondaryPos)}
                                        </span>}
                                    labelPlacement="end"
                                    className={'home-players-filter__checkbox'}
                                    onChange={(e) => handleOnFilterSecondaryPos(e)}
                                />
                            ))}                
                        </AccordionDetails>
                    </Accordion>
                    {/** Laterality */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            className='baloa-mini'                
                        >                                    
                            <div className='baloa-names'>{props.t('profile:playerCard.foot')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {props.lateralityOptions?.map(laterality => (
                                <FormControlLabel
                                    name={'laterality'}
                                    key={laterality}
                                    value={laterality}
                                    control={<Checkbox color="secondary" checked={ filterLaterality.some(ply => ply === laterality) } />}
                                    label={<span className="baloa-names">
                                            {convertLateralityCode(laterality)}
                                        </span>}
                                    labelPlacement="end"
                                    className={'home-players-filter__checkbox'}
                                    onChange={(e) => handleOnFilterLaterality(e)}
                                />
                            ))}                
                        </AccordionDetails>
                    </Accordion>
                    {/** Stats -goals */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="goalsstats"
                            id="goalsstats"
                            className='baloa-mini'
                        >                                    
                            <div className='baloa-names'>{props.t('players.filter.goalsTitle')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className='home-players-filter__option-buttons'>
                                    <div className={`option-button baloa-table ${goalOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{setGoalOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                                    <div className={`option-button baloa-table ${goalOption === 'this_year'? 'selected' : ''}`} onClick={()=>{setGoalOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                                </div>
                            <RadioGroup row
                                aria-labelledby="goalsstats"
                                name={"goalsstats"}
                                value={''}
                                className="home-players-filter__option-button inside"
                            >
                                <FormControlLabel
                                    value="10"
                                    control={<Radio color="secondary" checked={filterGoalStats === '10'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.goal10Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterGoalStats === '10' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterGoalStats(e)}
                                />
                                <FormControlLabel
                                    value="10|20"
                                    control={<Radio color="secondary" checked={filterGoalStats === '10|20'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.goal10|20Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterGoalStats === '10|20' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterGoalStats(e)}
                                />
                                <FormControlLabel
                                    value="21|30"
                                    control={<Radio color="secondary" checked={filterGoalStats === '21|30'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.goal21|30Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterGoalStats === '21|30' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterGoalStats(e)}
                                />
                                <FormControlLabel
                                    value="31|40"
                                    control={<Radio color="secondary" checked={filterGoalStats === '31|40'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.goal31|40Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterGoalStats === '31|40' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterGoalStats(e)}
                                />
                                <FormControlLabel
                                    value="40"
                                    control={<Radio color="secondary" checked={filterGoalStats === '40'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.goal40Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterGoalStats === '40' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterGoalStats(e)}
                                />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>

                    {/** Stats - Asisstances */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="assistancestats"
                            id="assistancestats"
                            className='baloa-mini'
                        >                                    
                            <div className='baloa-names'>{props.t('players.filter.assistancesTitle')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className='home-players-filter__option-buttons'>
                                    <div className={`option-button baloa-table ${assistanceOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{setAssistanceOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                                    <div className={`option-button baloa-table ${assistanceOption === 'this_year'? 'selected' : ''}`} onClick={()=>{setAssistanceOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                                </div>
                            <RadioGroup row
                                aria-labelledby="assistancestats"
                                name={"assistancestats"}
                                value={''}
                                className="home-players-filter__option-button inside"
                            >
                                <FormControlLabel
                                    value="5"
                                    control={<Radio color="secondary" checked={filterAssistances === '5'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.assistance5Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterAssistances === '5' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterAssistances(e)}
                                />
                                <FormControlLabel
                                    value="5|10"
                                    control={<Radio color="secondary" checked={filterAssistances === '5|10'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.assistance5|10Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterAssistances === '5|10' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterAssistances(e)}
                                />
                                <FormControlLabel
                                    value="11|15"
                                    control={<Radio color="secondary" checked={filterAssistances === '11|15'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.assistance11|15Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterAssistances === '11|15' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterAssistances(e)}
                                />
                                <FormControlLabel
                                    value="16|20"
                                    control={<Radio color="secondary" checked={filterAssistances === '16|20'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.assistance16|20Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterAssistances === '16|20' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterAssistances(e)}
                                />
                                <FormControlLabel
                                    value="20"
                                    control={<Radio color="secondary" checked={filterAssistances === '20'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.assistance20Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterAssistances === '20' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterAssistances(e)}
                                />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>

                    {/** Stats - Played games */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="playedgames"
                            className='baloa-mini'
                        >                                    
                            <div className='baloa-names'>{props.t('profile:tournamentSummary.labelMatchesSummary')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className='home-players-filter__option-buttons'>
                                    <div className={`option-button baloa-table ${playedGamesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{setPlayedGamesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                                    <div className={`option-button baloa-table ${playedGamesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{setPlayedGamesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                                </div>
                            <RadioGroup row
                                aria-labelledby="playedgames"
                                name={"playedgames"}
                                value={''}
                                className="home-players-filter__option-button inside"
                            >
                                <FormControlLabel
                                    value="10"
                                    control={<Radio color="secondary" checked={filterPlayedGames === '10'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedGames10Lb')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedGames === '10' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedGames(e)}
                                />
                                <FormControlLabel
                                    value="10|25"
                                    control={<Radio color="secondary" checked={filterPlayedGames === '10|25'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedGames10|25Lb')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedGames === '10|25' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedGames(e)}
                                />
                                <FormControlLabel
                                    value="26|50"
                                    control={<Radio color="secondary" checked={filterPlayedGames === '26|50'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedGames26|50Lb')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedGames === '26|50' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedGames(e)}
                                />
                                <FormControlLabel
                                    value="51|100"
                                    control={<Radio color="secondary" checked={filterPlayedGames === '51|100'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedGames51|100Lb')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedGames === '51|100' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedGames(e)}
                                />
                                <FormControlLabel
                                    value="100"
                                    control={<Radio color="secondary" checked={filterPlayedGames === '100'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedGames100Lb')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedGames === '100' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedGames(e)}
                                />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>

                    {/** Stats - Starter games */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="startedgames"
                            className='baloa-mini'
                        >                                    
                            <div className='baloa-names'>{props.t('players.filter.starterTitle')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className='home-players-filter__option-buttons'>
                                    <div className={`option-button baloa-table ${starterGamesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{setStarterGamesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                                    <div className={`option-button baloa-table ${starterGamesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{setStarterGamesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                                </div>
                            <RadioGroup row
                                aria-labelledby="startedgames"
                                name={"startedgames"}
                                value={''}
                                className="home-players-filter__option-button inside"
                            >
                                <FormControlLabel
                                    value="5"
                                    control={<Radio color="secondary" checked={filterStarterGames === '5'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.startedGames5Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterStarterGames === '5' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterStarterGames(e)}
                                />
                                <FormControlLabel
                                    value="5|10"
                                    control={<Radio color="secondary" checked={filterStarterGames === '5|10'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.startedGames5|10Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterStarterGames === '5|10' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterStarterGames(e)}
                                />
                                <FormControlLabel
                                    value="11|20"
                                    control={<Radio color="secondary" checked={filterStarterGames === '11|20'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.startedGames11|20Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterStarterGames === '11|20' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterStarterGames(e)}
                                />
                                <FormControlLabel
                                    value="21|50"
                                    control={<Radio color="secondary" checked={filterStarterGames === '21|50'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.startedGames21|50Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterStarterGames === '21|50' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterStarterGames(e)}
                                />
                                <FormControlLabel
                                    value="50"
                                    control={<Radio color="secondary" checked={filterStarterGames === '50'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.startedGames50Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterStarterGames === '50' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterStarterGames(e)}
                                />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>

                    {/** Stats - played minutes */}
                    <Accordion className='home-players-filter__accordion' >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="playedminutes"
                            className='baloa-mini'
                        >                                    
                            <div className='baloa-names'>{props.t('profile:tournamentSummary.labelMinutes')}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className='home-players-filter__option-buttons'>
                                    <div className={`option-button baloa-table ${playedMinutesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{setPlayedMinutesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                                    <div className={`option-button baloa-table ${playedMinutesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{setPlayedMinutesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                                </div>
                            <RadioGroup row
                                aria-labelledby="playedminutes"
                                name={"playedminutes"}
                                value={''}
                                className="home-players-filter__option-button inside"
                            >
                                <FormControlLabel
                                    value="500"
                                    control={<Radio color="secondary" checked={filterPlayedMinutes === '500'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedMinutes500Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedMinutes === '500' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedMinutes(e)}
                                />
                                <FormControlLabel
                                    value="500|1000"
                                    control={<Radio color="secondary" checked={filterPlayedMinutes === '500|1000'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedMinutes500|1000Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedMinutes === '500|1000' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedMinutes(e)}
                                />
                                <FormControlLabel
                                    value="1001|2000"
                                    control={<Radio color="secondary" checked={filterPlayedMinutes === '1001|2000'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedMinutes1001|2000Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedMinutes === '1001|2000' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedMinutes(e)}
                                />
                                <FormControlLabel
                                    value="2001|3000"
                                    control={<Radio color="secondary" checked={filterPlayedMinutes === '2001|3000'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedMinutes2001|3000Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedMinutes === '2001|3000' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedMinutes(e)}
                                />
                                <FormControlLabel
                                    value="3000"
                                    control={<Radio color="secondary" checked={filterPlayedMinutes === '3000'} />}
                                    label={<span className="baloa-names">
                                            {props.t('players.filter.playedMinutes3000Lbl')}
                                        </span>}
                                    labelPlacement="end"
                                    className={filterPlayedMinutes === '3000' ? 'selected' : ''}
                                    onChange={(e) => handleOnFilterPlayedMinutes(e)}
                                />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>
                    
                </FormComponent>
            </div>
        }
        {isMobile &&
            <FormComponent
                formInitialValues={ initialValues }
                onSubmit={( values, actions ) => {
                    buildApiRequest(values);
                }}
                //className="search-form__form form"
                autocomplete="off"
                //onBlur={  props.onBlur  }
                innerRef={formRef}
            >            
                {props.unAuth?
                    <div onClick={()=>{props.handleOnExplorePagesFilter(null, null, null)}}>
                        <InputSearchComponent         
                            id="searchfield"
                            name="searchfield"   
                            placeholder={props.t('players.filter.searchPlaceholder')}
                            handleSearch={(search) => { handleWordSearch(search) }}
                            disabled={true}
                            removeWordLenghtValidation={false}
                            autoFocus={true}
                        />
                    </div>
                    :
                    <InputSearchComponent         
                        id="searchfield"
                        name="searchfield"   
                        placeholder={props.t('players.filter.searchPlaceholder')}
                        handleSearch={(search) => { handleWordSearch(search) }}
                        disabled={filterOpen}
                        removeWordLenghtValidation={false}
                        autoFocus={true}
                        searchword={props.playersearch}
                    />
                }
                
                <div className={'home-players-filter__mobile-buttons'}>
                    <div className={'home-players-filter__mobile-buttons__button'} onClick={() => { /* props.unAuth? props.handleOnExplorePagesFilter(null, null, null) : */ handleFilterOpen(true) }} >
                        <img src={filterIcon} alt={"filter_icon"} />
                        <div className='baloa-names'>{props.t('home:pages.advancedFilters')}</div>
                    </div>                   
                </div>
                
                <HomePlayersFilterModalComponent
                    isOpen={filterOpen} 
                    onClose={ () => { handleFilterOpen(false) } }
                    handleOnFilterModalVideo={(e) => {handleOnFilterModalVideo(e)}}
                    filterModalVideo={filterVideo}                    
                    nationalities={props.nationalities}
                    defaultCountry={defaultCountry}
                    setDefaultCountry={(value) => {setDefaultCountry(value); formRef.current?.submitForm();}}
                    handleSearchNationality ={ ( newCountryValue ) => props.handleSearchNationality( newCountryValue ) }    
                    cities={props.cities}
                    defaultLocation={defaultLocation}
                    setDefaultLocation={(value)=>{setDefaultLocation(value); formRef.current?.submitForm();}}
                    searchProfileWorldCities ={ ( city ) => props.searchProfileWorldCities( city ) }
                    yearsList={yearsList}
                    primaryPositionOptions={props.primaryPositionOptions}
                    secondaryPositionOptions={props.secondaryPositionOptions}
                    lateralityOptions={props.lateralityOptions}                

                    handleOnFilterModalAge = { (e,arr)=> { handleOnFilterModalAge(e,arr)}}
                    handleOnFilterModalPrimaryPos={(e,arr) => {handleOnFilterModalPrimaryPos(e,arr)}}
                    handleOnFilterModalSecondaryPos={(e,arr) => {handleOnFilterModalSecondaryPos(e,arr)}}
                    handleOnFilterModalLaterality={(e,arr) => {handleOnFilterModalLaterality(e,arr)}}

                    handleOnFilterModalGoalStats={(e) => {handleOnFilterModalGoalStats(e)}}
                    handleOnFilterModalAssistances={(e) => {handleOnFilterModalAssistances(e)}}
                    handleOnFilterModalPlayedGames={(e) => {handleOnFilterModalPlayedGames(e)}}
                    handleOnFilterModalStarterGames={(e) => {handleOnFilterModalStarterGames(e)}}
                    handleOnFilterModalPlayedMinutes={(e) => {handleOnFilterModalPlayedMinutes(e)}}

                    goalOption={goalOption}
                    setGoalOption={(e) => {setGoalOption(e)}}
                    assistanceOption={assistanceOption}
                    setAssistanceOption={(e) => {setAssistanceOption(e)}}
                    playedGamesOption={playedGamesOption}
                    setPlayedGamesOption={(e) => {setPlayedGamesOption(e)}}
                    starterGamesOption={starterGamesOption}
                    setStarterGamesOption={(e) => {setStarterGamesOption(e)}}
                    playedMinutesOption={playedMinutesOption}
                    setPlayedMinutesOption={(e) => {setPlayedMinutesOption(e)}}

                    filterModalAge = {filterModalAge}
                    filterModalPrimaryPos = {filterModalPrimaryPos}
                    filterModalSecondaryPos = {filterModalSecondaryPos}
                    filterModalLaterality = {filterModalLaterality}

                    filterGoalStats = {filterGoalStats}
                    filterAssistances = {filterAssistances}
                    filterPlayedGames = {filterPlayedGames}
                    filterStarterGames = {filterStarterGames}
                    filterPlayedMinutes = {filterPlayedMinutes}

                    handleModalFilter = { ()=> { handleModalFilter() }}
                    resetFilter={ () => resetModalFilter() }
                    
                />
            </FormComponent>
        }
    </div>
    )
}

export default withTranslation(['home','page','profile'])(HomePlayersFilterComponent);