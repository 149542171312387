// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url, api_media_url } from "config/GlobalData";


export const GetProfileApi = ( username ) => {

    return Axios.instance.get( `${ api_url }/profiles/${ username }/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}

export const CreateAttributeOnProfileApi = ( profile, new_attribute_data ) => {

    return Axios.instance.post( `${ api_url }/profiles/${ profile.user.username }/attributes/`, 
        { extra: new_attribute_data },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const EditAttributeOnProfileApi = ( new_attribute_data ) => {

    return Axios.instance.patch( `${ api_url }/attributes/${ new_attribute_data.id }/`, 
        { extra: new_attribute_data },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeleteAttributeOnProfileApi = ( attribute_id ) => {

    return Axios.instance.delete( `${ api_url }/attributes/${ attribute_id }/`,         
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const UpdateProfileDataApi = ( profile_username, profile_data ) => {

    return Axios.instance.patch( `${ api_url }/profiles/${ profile_username }/`, 
        profile_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const FollowProfileApi = ( username ) => {

    return Axios.instance.post( 
        `${ api_url }/profiles/${ username }/follow/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const UnfollowProfileApi = ( username ) => {

    return Axios.instance.delete( 
        `${ api_url }/profiles/${ username }/unfollow/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetProfilePostsApi = ( username, next_page ) => {

    return Axios.instance.get( 
        next_page ? next_page : `${ api_url }/profiles/${ username }/posts/?version=2`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetProfileFollowersApi = ( username ) => {

    return Axios.instance.get(
        `${api_url}/profiles/${ username }/followers/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const GetProfileFollowingApi = ( username ) => {

    return Axios.instance.get(
        `${api_url}/profiles/${ username }/following/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const InviteProfileToValidateAttributeApi = ( attribute, username ) => {

    return Axios.instance.post( 
        `${ api_url }/attributes/${ attribute.id }/invite/`,
        { user_invite: username },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddProfileNationalityApi = (profile, new_profile_nationality) => {

    return Axios.instance.patch( `${ api_url }/profiles/${ profile.user.username }/`, 
        { 
            "nationality": new_profile_nationality[0].id,
            "location": new_profile_nationality[1].id,
            "profile_type": profile.profile_type 
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const SearchProfileNationalitiesApi = ( country ) => {

    return Axios.instance.get(
        `${api_url}/countries/?search=${ country }`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const SearchProfileLocationsApi = ( countrycode, city ) => {

    return Axios.instance.get(
        `${api_url}/countries/${ countrycode }/cities/?name=${ city }`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const BlockProfileApi = ( username ) => {

    return Axios.instance.post( 
        `${ api_url }/profiles/${ username }/block/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const AddProfileTeamByCodeApi = (username, teamCode) => {

    return Axios.instance.post(
        `${api_url}/teams-page/team-code/`,
        {
            team_code: teamCode,
            username: username,
            role: {
                player_number: null,
                position: null,
                role: "player"
            }
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    )
}

export const DeleteProfileTeamApi = (username, teamname) => {

    return Axios.instance.delete(`${api_url}/teams-page/${teamname}/members/${username}`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const LoadProfileTournamentStatsApi = (username, next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/profiles/${username}/stats/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const LoadProfileAchievementsStatsApi = (username, next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/profiles/${username}/achievements/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const ProfileTournamentStatsPaymentApi = (username, payment_data) => {

    return Axios.instance.post(`${api_url}/profiles/${username}/checkout/`,
    payment_data,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const LoadProfileTournamentsApi = (username, next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/profiles/${username}/tournaments/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const LoadProfileTeamsApi = (username, next_page) => {

    return Axios.instance.get(next_page ? next_page : `${api_url}/profiles/${username}/teams/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}


export const UploadVideoProfileApi = ( formData, configUpload ) => {
    return Axios.instance.post(
        `${ api_media_url }/backend-posts/`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            onUploadProgress: configUpload,
        }
    );
}

export const LoadAllProfileVideoApi = (username, next_page) => {
    //return Axios.instance.get( `${ api_url }/profile-video/`,
    return Axios.instance.get( next_page ? next_page : `${ api_url }/profiles/${username}/profilevideos/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const deleteProfileVideoApi = (postId) => {
    return Axios.instance.delete(`${ api_url }/backend-posts/${postId}/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const updateProfileVideoApi = (postId, titleVideo) => {
    return Axios.instance.patch(`${ api_url }/backend-posts/${postId}/`,
    {
        caption: titleVideo
    },
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const loadProfileMatchesApi = (username, next_page, tournaments_filter, old_matches ) => {
    return Axios.instance.get(next_page ? next_page : `${api_url}/profiles/${username}/matches/?tournament_id=${tournaments_filter? tournaments_filter : ''}&old_matches=${old_matches? old_matches : ''}`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const loadProfileTournamentMatchesApi = ( username ) => {
    return Axios.instance.get(`${api_url}/profiles/${username}/tournamentsmatch/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const updateProfileFeaturedMatchVideoApi = (postId, params) => {
    return Axios.instance.patch(`${ api_url }/backend-posts/${postId}/postvideomatch/`,
    params,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const loadProfileAchievementsApi = ( username ) => {
    return Axios.instance.get(`${api_url}/profiles/${username}/profileachievement/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const createProfileAchievementApi = ( username, formData ) => {
    return Axios.instance.post(`${ api_url }/profiles/${username}/profileachievement/`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            //onUploadProgress: configUpload,
        }
    );
}

export const updateProfileAchievementApi = ( profile_achievement_id, formData ) => {
    return Axios.instance.patch(`${ api_url }/profile-achievement/${profile_achievement_id}/`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            //onUploadProgress: configUpload,
        }
    );
}

export const deleteProfileAchievementApi = (profile_achievement_id) => {
    return Axios.instance.delete(`${ api_url }/profile-achievement/${profile_achievement_id}/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const loadProfileTrajectoriesApi = ( username ) => {
    return Axios.instance.get(`${api_url}/profiles/${username}/profiletrajectory/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const createProfileTrajectoryApi = ( username, formData ) => {
    return Axios.instance.post(`${ api_url }/profiles/${username}/profiletrajectory/`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            //onUploadProgress: configUpload,
        }
    );
}

export const updateProfileTrajectoryApi = ( profile_trajectory_id, formData ) => {
    return Axios.instance.patch(`${ api_url }/profile-trajectory/${profile_trajectory_id}/`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            //onUploadProgress: configUpload,
        }
    );
}

export const deleteProfileTrajectoryApi = (profile_trajectory_id) => {
    return Axios.instance.delete(`${ api_url }/profile-trajectory/${profile_trajectory_id}/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}

export const LoadProfileExternalStatsApi = ( username, next_page ) => {
    return Axios.instance.get( next_page ? next_page :`${api_url}/profiles/${username}/external_stats/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    })
}

export const AddExternalStatApi = (username, external_stat) => {

    return Axios.instance.post(`${api_url}/profiles/${username}/external_stats/`,
        external_stat,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    )
}

export const UpdateExternalStatApi = (external_stat_id, external_stat ) => {
    return Axios.instance.patch(`${ api_url }/external-stats/${external_stat_id}/`,
        external_stat,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const DeleteExternalStatApi = (external_stat_id) => {
    return Axios.instance.delete(`${ api_url }/external-stats/${external_stat_id}/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    } 
    );
}