// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url, api_media_url } from "config/GlobalData";


export const GetHomePostsApi = () => {

    return Axios.instance.get( 
        `${ api_url }/home-posts/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const ClapPostApi = ( post ) => {

    return Axios.instance.post( 
        `${ api_url }/posts/${post.id}/claps/`,
        {},    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeletePostApi = ( post ) => {

    return Axios.instance.delete( `${ api_url }/posts/${ post.id }/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetPostDetailByIdApi = ( post_id ) => {

    return Axios.instance.get( 
        `${ api_url }/posts/${post_id}/?version=2`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const ListPostCommentsByIdApi = ( post_id ) => {

    return Axios.instance.get( 
        `${ api_url }/posts/${post_id}/comments/?version=2`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const ListReplyCommentsByCommentIdApi = ( comment_id ) => {

    return Axios.instance.get( 
        `${ api_url }/comments/${comment_id}/comments/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const CommentPostByIdApi = ( post_id, comment, baloa_page= null ) => {

    return Axios.instance.post( 
        `${ api_url }/posts/${post_id}/comments/`,
        {
            caption: comment
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
                ...(baloa_page != null) && {'Baloa-Page':  baloa_page},
            }
        } 
    );

}

export const ReplyCommentPostByIdApi = ( comment_id, comment ) => {

    return Axios.instance.post( 
        `${ api_url }/comments/`,
        {
            comment: comment_id,
            caption: comment
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const DeleteCommentApi = ( comment_id ) => {

    return Axios.instance.delete( `${ api_url }/comments/${ comment_id }/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}


export const FormationPostByIdApi = ( post_id, formation ) => {

    return Axios.instance.post( 
        `${ api_url }/posts/${post_id}/formation/`,
        {
            formation: formation
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const ClapCommentByIdApi = ( comment_id ) => {

    return Axios.instance.post( 
        `${ api_url }/comments/${comment_id}/claps/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        },
    );

}

export const ListPostClapsByIdApi = ( post_id ) => {

    return Axios.instance.get( 
        `${ api_url }/posts/${post_id}/claps/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

} 

export const ReportPostByIdApi = ( post_id, denunciation ) => {

    return Axios.instance.post( 
        `${ api_url }/posts/${post_id}/report_abuse/`,
        { caption:denunciation },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        },
    );

}

export const ListPostClapsNextApi = ( next_page ) => {

    return Axios.instance.get( 
        `${ next_page }`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

} 

export const ListPostCommentsNextApi = ( next_page ) => {

    return Axios.instance.get( 
        `${ next_page }`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const UploadPostApi = ( formData, configUpload, baloa_page ) => {

    return Axios.instance.post(
        `${ api_media_url }/posts/?version=2`,
        formData,
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
                ...(baloa_page != null) && {'Baloa-Page':  baloa_page},
            },
            onUploadProgress: configUpload,
        }
    );
}

export const EditPostCaptionApi = ( post_id, profile_id, poster_id, caption ) => {

    return Axios.instance.patch(
        `${ api_media_url }/posts/${ post_id }/?version=2`,
        {
            profile: profile_id,
            page: poster_id,
            caption: caption
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const ReactionsPostApi = ( post_id, reaction ) => {

    return Axios.instance.post(`${ api_url }/posts/${post_id}/reactions/`,
        {
            reaction_type: reaction
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const OverrrideReactionsPostApi = ( reaction, reaction_id ) => {

    return Axios.instance.patch(`${ api_url }/reactions/${reaction_id}/`,
        {
            reaction_type: reaction
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPostReactionsByIdApi = ( post_id ) => {

    return Axios.instance.get( `${ api_url }/posts/${post_id}/reactions/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const GetPostReactionsNextApi = ( next_page ) => {

    return Axios.instance.get( 
        `${ next_page }`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const GetPostReactionsByReactionTypeApi = ( post_id, reaction_type ) => {

    return Axios.instance.get( `${ api_url }/posts/${post_id}/reactions/?reaction_type=${reaction_type}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const DeleteReactionsPostApi = ( reaction_id ) => {

    return Axios.instance.delete(`${ api_url }/reactions/${reaction_id}/`,   
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const CommentReactionsApi = ( comment_id, reaction ) => {

    return Axios.instance.post(`${ api_url }/comments/${comment_id}/reactions/`,
        {
            reaction_type: reaction
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const OverrrideCommentReactionsApi = ( reaction, reaction_id ) => {

    return Axios.instance.patch(`${ api_url }/reactions/${reaction_id}/`,
        {
            reaction_type: reaction
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const DeleteCommentReactionsApi = ( reaction_id ) => {

    return Axios.instance.delete(`${ api_url }/reactions/${reaction_id}/`,   
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const GetPostCommentReactionsByIdApi = ( comment_id ) => {

    return Axios.instance.get( `${ api_url }/comments/${comment_id}/reactions/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const GetPostCommentReactionsByReactionTypeApi = ( comment_id, reaction_type ) => {

    return Axios.instance.get( `${ api_url }/comments/${comment_id}/reactions/?reaction_type=${reaction_type}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );

}

export const AnswerQuizApi = ( id_post, answer_id ) => {

    return Axios.instance.post(`${ api_url }/posts/${id_post}/quiz/`,
        {
            answer: answer_id
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const AnswerPollApi = ( id_post, answer_id ) => {

    return Axios.instance.post(`${ api_url }/posts/${id_post}/poll/`,
        {
            answer: answer_id
        },    
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}