// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-validation-responsive-modal .modal2__container{
    position: absolute;
    bottom: inherit;
}

.tournament-validation-responsive-modal-button{
    width: 100%;
    padding: var(--space);
    display: grid;
    justify-items: center;
}

.tournament-validation-responsive-modal-img{
    display: grid;
    justify-items: center;
    padding-top: var(--space);
}

.tournament-validation-responsive-modal-img img{
    width: 10%;
}

.tournament-validation-responsive-modal-text{
    padding: var(--space);
}

.tournament-validation-responsive-modal-text .baloa-table-column{
    color:var(--primary-very-dark-color);
}

.tournament-validation-responsive-modal .modal2__header{
    border-bottom: none;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .tournament-validation-responsive-modal .modal2__container {
        width: 100%;
        margin: 0;
        border-radius: 14px 14px 0 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-validationResposive-modal/TournamentValidationResponsiveModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;AACvB;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,WAAW;QACX,SAAS;QACT,4BAA4B;IAChC;AACJ","sourcesContent":[".tournament-validation-responsive-modal .modal2__container{\n    position: absolute;\n    bottom: inherit;\n}\n\n.tournament-validation-responsive-modal-button{\n    width: 100%;\n    padding: var(--space);\n    display: grid;\n    justify-items: center;\n}\n\n.tournament-validation-responsive-modal-img{\n    display: grid;\n    justify-items: center;\n    padding-top: var(--space);\n}\n\n.tournament-validation-responsive-modal-img img{\n    width: 10%;\n}\n\n.tournament-validation-responsive-modal-text{\n    padding: var(--space);\n}\n\n.tournament-validation-responsive-modal-text .baloa-table-column{\n    color:var(--primary-very-dark-color);\n}\n\n.tournament-validation-responsive-modal .modal2__header{\n    border-bottom: none;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .tournament-validation-responsive-modal .modal2__container {\n        width: 100%;\n        margin: 0;\n        border-radius: 14px 14px 0 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
