// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-id-preview-modal .modal2__content{
    overflow: scroll;
}

.carnet__canvas.preview {
    border: 1px solid var(--new-gray-background-color);
    background: var(--white-color);
    zoom: 100%;
    margin: 48px auto;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-card-id-preview-modal/TournamentCardIdPreviewModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kDAAkD;IAClD,8BAA8B;IAC9B,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".card-id-preview-modal .modal2__content{\n    overflow: scroll;\n}\n\n.carnet__canvas.preview {\n    border: 1px solid var(--new-gray-background-color);\n    background: var(--white-color);\n    zoom: 100%;\n    margin: 48px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
