/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldMoreOptionsComponent from 'shared/components/select-field/SelectFieldMoreOptionsComponent';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { UserNameValidator } from 'shared/validators/SignUpValidator';

// Styles
import './PagePrivacyModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Assets
import open_padlock from '../../../../assets/icons/open-padlock-icon.png';
import closed_padlock from '../../../../assets/icons/closed-padlock-icon.png';

function PagePrivacyModalComponent(props) {
    
    const history = useHistory();
    const privacyType = [
        {
            flag_icon: open_padlock,
            value: "public",
            content: (<div>
                <p className={"baloa-names"}>{props.t('page:newPage.privacy.open.title')}</p>
                <p className={"baloa-username"}>{props.t('page:newPage.privacy.open.text')}</p>
            </div>)
        },
        {
            flag_icon: closed_padlock,
            value: "private",
            content: (<div>
                <p className={"baloa-names"}>{props.t('page:newPage.privacy.closed.title')}</p>
                <p className={"baloa-username"}>{props.t('page:newPage.privacy.closed.text')}</p>
            </div>)
        }
    ];

    const [state, setState] = React.useState(
        {
            privacy_form_is_valid: false,
            privacy_form_is_dirty: false,
        }
    );

    const formInitialValues = {
        name:'',
        pagename:'',
        privacy: null,
    }

    const onFormProps = (form_props) => {

        if (form_props.isValid && !state.privacy_form_is_valid) {
            setState({ privacy_form_is_valid: true, privacy_form_is_dirty: true })
        }
        if (!form_props.isValid && state.privacy_form_is_valid) {
            setState({ privacy_form_is_valid: false, privacy_form_is_dirty: true })
        }

    }

    const createGroup = async (new_page_privacy) => {
        const { privacy, name, pagename } = new_page_privacy;
        await props.onNewData({pagename , name,"is_private":privacy === 'private' ? true : false, page_type:"group"});
        history.push('/page/' + pagename);
        props.onClose();
    }


    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelButtonCreateGroup')}
        >
            <div className="page-privacy-form">

                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        let new_page_privacy = { ...values};
                        createGroup(new_page_privacy);
                    }}
                    className="page-privacy-form__form form"
                    functionDisabled={(form_props) => { onFormProps(form_props) }}
                >
                    <div className="page-privacy-form__form__fields form__fields">
                        <React.Fragment>

                            <div className="page-information-form__form__fields form__fields">
                                <InputFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGroupTournamentName')}
                                    input_id="new-page-name"
                                    field_name="name"
                                    validationFunction={RequiredValidator}
                                    disabled={false}
                                    className="page-information-form__form__input"
                                    input_type="text"
                                    resetError={() => props.onResetError({ 'name': "" })}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                />
                                <InputFieldComponent
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGroupTournamentAt')}
                                    input_id="new-page-pagename"
                                    field_name="pagename"
                                    validationFunction={UserNameValidator}
                                    disabled={false}
                                    className="page-information-form__form__input__pagename"
                                    input_type="text"
                                    resetError={() => props.onResetError({ 'pagename': "" })}
                                    error={props.errors}
                                    placeholder="@"
                                />
                                <SelectFieldMoreOptionsComponent
                                    label={'Privacidad'}
                                    field_name={"privacy"}
                                    disabled={false}
                                    validationFunction={RequiredValidator}
                                    className="basic-data__form__content__category__input"
                                    resetError={() => props.onResetError({ 'privacy': "" })}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={privacyType}
                                />
                            </div>
                        </React.Fragment>
                    </div>

                    <div className="page-privacy-form__form__actions form__actions">
                        <PrimaryButtonComponent
                            type="submit"
                            disabled={!state.privacy_form_is_valid || !state.privacy_form_is_dirty}
                            onClick={() => { }}
                            className="page-privacy-form__form__submit"
                        >
                            <span>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelButtonCreateGroup')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(PagePrivacyModalComponent);

//export default PagePrivacyModalComponent;