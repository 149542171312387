export const setMatchScoreAction = ( data ) => {
    return { type: "MATCH:SET_MATCH_SCORE", data }
}

export const setMatchPlayersGoalsAction = ( data ) => {
    return { type: "MATCH:SET_PLAYERS_GOALS", data }
}

export const setMatchScorersAction = ( data ) => {
    return { type: "MATCH:SET_MATCH_SCORERS", data }
}
export const setMatchChangesAction = ( data ) => {
    return { type: "MATCH:SET_MATCH_CHANGES", data }
}

export const setMatchPlayersCardsAction = ( data ) => {
    return { type: "MATCH:SET_PLAYERS_CARDS", data }
}

export const setMatchCommentsAction = ( data ) => {
    return { type: "MATCH:SET_MATCH_COMMENTS", data }
}

export const changeEditMatchModalStateAction = ( new_state ) => {
    return { type: "MATCH:CHANGE_EDIT_MATCH_MODAL_STATE", new_state }
}

export const editTeamAction = ( data ) => {
    return { type: "MATCH:EDIT_TEAM", data }
}

export const loadMatchReportAction = ( data  ) => {
    return { type: "MATCH:LOAD_MATCH_REPORT", data }
}
export const loadMatchReport = ( report_data  ) => {
    return { type: "MATCH:LOAD_MATCH_REPORT", report_data }
}

export const setMatchDataAction = ( match_data ) => {
    return { type: "MATCH:LOAD_MATCH_DATA", match_data }
}

export const loadLocalTeamPageMembersAction = ( match_data ) => {
    return { type: "MATCH:LOAD_LOCAL_TEAM_PAGE_MEMBERS", match_data }
}

export const loadVisitorTeamPageMembersAction = ( match_data ) => {
    return { type: "MATCH:LOAD_VISITOR_TEAM_PAGE_MEMBERS", match_data }
}

export const loadLocalTeamPageDataAction = ( match_data ) => {
    return { type: "MATCH:LOAD_LOCAL_TEAM_PAGE_DATA", match_data }
}

export const loadVisitorTeamPageDataAction = ( match_data ) => {
    return { type: "MATCH:LOAD_VISITOR_TEAM_PAGE_DATA", match_data }
}

export const getOfficialRefereeMemberAction = ( official_referee ) => {
    return { type: "MATCH:GET_OFFICIAL_REFEREE_MEMBER", official_referee }
}

export const getSecondRefereeMemberAction = ( second_referee ) => {
    return { type: "MATCH:GET_SECOND_ASSISTANT_REFEREE_MEMBER", second_referee }
}

export const getThirdRefereeMemberAction = ( third_referee ) => {
    return { type: "MATCH:GET_THIRD_ASSISTANT_REFEREE_MEMBER", third_referee }
}

export const getFourthRefereeMemberAction = ( fourth_referee ) => {
    return { type: "MATCH:GET_FOURTH_ASSISTANT_REFEREE_MEMBER", fourth_referee }
}

export const getRefereeAssignerMemberAction = ( referee_assigner ) => {
    return { type: "MATCH:GET_REFEREE_ASSIGNER_MEMBER", referee_assigner }
}

export const cleanRefereesMemberAction = (data) => {
    return { type: "MATCH:CLEAN_REFEREES_MEMBERS", data }
}

export const resetMatchDataAction = ( match_data ) => {
    return { type: "MATCH:RESET_MATCH_DATA", match_data }
}

export const resetLocalTeamPageMembersAction = ( match_data ) => {
    return { type: "MATCH:RESET_LOCAL_TEAM_PAGE_MEMBERS", match_data }
}

export const resetVisitorTeamPageMembersAction = ( match_data ) => {
    return { type: "MATCH:RESET_VISITOR_TEAM_PAGE_MEMBERS", match_data }
}

export const resetLocalTeamPageDataAction = ( match_data ) => {
    return { type: "MATCH:RESET_LOCAL_TEAM_PAGE_DATA", match_data }
}

export const resetVisitorTeamPageDataAction = ( match_data ) => {
    return { type: "MATCH:RESET_VISITOR_TEAM_PAGE_DATA", match_data }
}