export const loadTeamsListAction = ( data ) => {
    return { type: "TEAMS:LOAD_TEAM_LIST", data }
}
export const changePaymentsModalStateAction = ( data) => {
    return {type: "PAYMENTS:CHANGE_ACTIVE_MODAL_STATE", data}
}

export const changeClickType = ( data) => {
    return {type: "CHANGE_CLICK_TYPE", data}
}

export const loadPaymentCountryApiAction = ( data ) => {
    return { type: "LOAD_PAYMENTS_COUNTRY", data }
}

export const loadPaymentsFormAction = ( data ) => {
    return { type: "lOAD_PAYMENTS_FORM", data }
}

export const getCountriesAction = ( data ) => {
    return { type: "PAYMENTS:GET_COUNTRIES", data }
}

export const getCountryStatesAction = ( data ) => {
    return { type: "PAYMENTS:GET_COUNTRY_STATES", data }
}

export const getGatewayAttributeAction = ( data ) => {
    return { type: "PAYMENTS:GET_GATEWAY_ATTRIBUTE", data }
}

export const cleanCountryformAction = ( data ) => {
    return { type:"PAYMENTS:CLEAN_COUNTRY_FORM", data }
}

export const changeOnboardingFormValidationStateAction = ( new_state ) => {
    return { type: "PAYMENTS:CHANGE_ONBOARDING_FORM_VALIDATION_STATE", new_state }
}

export const checkPageAccountStatusAction= ( data ) => {
    return { type:"PAYMENTS:CHECK_PAGE_ACCOUNT_STATUS", data }
}

export const changeOnboardingFormPaymentLoadingAction = ( new_state ) => {
    return { type: "PAYMENTS:CHANGE_ONBOARDING_FORM_PAYMENT_LOADING_STATE", new_state }
}