import React from 'react';

//Components
import FormComponent from 'shared/components/form/FormComponent';
import InputDynamicFormComponent from '../dynamic-fields-tournament-form/input-dynamic-form/InputDynamicFormComponent';
import RadioButtonDynamicFormComponent from '../dynamic-fields-tournament-form/radioButton-dynamic-form/RadioButtonDynamicFormComponent';
import FileTournamentFormComponent from '../dynamic-fields-tournament-form/file-dynamic-form/FileTournamentFormComponent';
import CheckBoxTournamentFormComponent from '../dynamic-fields-tournament-form/check-tournament-form/CheckTournamentFormComponent';
import ListDynamicComponent from '../dynamic-fields-tournament-form/list-tournament-form/ListDynamicComponent';
import InputFieldSearchLocationComponent from '../dynamic-fields-tournament-form/input-field-search-location/InputFieldSearchLocationComponent';
import SelectFieldDynamicFormComponent from '../dynamic-fields-tournament-form/select-field-dynamic-form/SelectFieldDynamicFormComponent';


// Styles
import './ComposeInscriptionFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';


function ComposeInscriptionFormComponent(props) {

    const [state, setState] = React.useState( // eslint-disable-line
        {
            submit_button_ref: null,
            formInitialValues:{},
            errors:{},
            submitObj:{},
            files:[],
        }
    );
    const [reinitializeStatus, setReinitializeStatus] = React.useState(true);
    const formRef = React.useRef();
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0); //eslint-disable-line


    React.useEffect(() => { 
        if(props.submitForm){
            state.submit_button_ref.click()
        }
    }, [props.submitForm]);// eslint-disable-line 

    React.useEffect(() => { 
        if(props.inscription_form_template){
            let initalValues={};
            let initalErrors={};
            let initalObject={};
            let initialAnswers=[];
            initalObject['form_template'] = props.inscription_form_template.id;
            initalObject['tournament_team_id'] = props.tournament_team_id;
            initalObject['form_template_is_json'] = false;
            if(props.tournament_player_id){
                initalObject['tournament_player_id'] = props.tournament_player_id;
            }else if(props.tournament_staff_id){
                initalObject['tournament_staff_id'] = props.tournament_staff_id;
            }   
            props.inscription_form_template?.questions?.forEach((question)=>{
                if(question.question_type.caption === 'country_state'){
                    //bloqueo formik reinitialize
                    setReinitializeStatus(false);
                }
                
                if(question?.question_type?.caption === "json"){
                    initalObject['form_template_is_json'] = true;
                }
            });// Refactor validation

            if(props.fulfilled_form) {
                validationFormTemplate()?.map((question,index)=>{ // eslint-disable-line  
                    let defAnswer = getDefaultFormAnswers(question, question.question_type.caption);
                    initalValues[question.id]= defAnswer;
                    initalErrors[question.id]= '';
                    let newanswer = setFormAnswerStructure(question);    
                    initialAnswers.push(newanswer);
                })
            } else {
                validationFormTemplate()?.map((question,index)=>{ // eslint-disable-line  
                    let defAnswer = getDefaultAnswer(question.answers, question.question_type.caption, question);                
                    initalValues[question.id]= defAnswer;
                    initalErrors[question.id]= '';
                    
                    if(question.question_type.caption === 'country_state') {
                        initalValues[`${question.id}_country`]= null;
                        initalErrors[`${question.id}_country`]= '';
                        let new_question = cloneDeep(question);
                        new_question.question_type.caption = 'country';
                        new_question.id = `${question.id}_country`;
                        let newanswer = setAnswerStructure(new_question);    
                        initialAnswers.push(newanswer);
                    }
                    let newanswer = setAnswerStructure(question);    
                    initialAnswers.push(newanswer);
                })
            }

            state.formInitialValues = initalValues;
            state.errors = initalErrors;
            initalObject['questions'] = initialAnswers;
            state.submitObj = initalObject;
            forceUpdate();
        }
    }, [props.inscription_form_template,props.tournament_player_id, props.tournament_staff_id]);// eslint-disable-line   

    /**
     * Function to set default response by question
     * @param {Array} answers - Default response arrangement
     * @param {String} type  - Question type for the representation component
     * @returns {Array} - Array of answers for default
     */
    function getDefaultAnswer(answers, type, question = null){
        if(answers.length > 0){
            let checkedAnswers = answers?.filter(ans => ans.is_default == true);
            if(checkedAnswers.length > 0){
                if(type=='single_choice'){
                    return checkedAnswers[0].id
                }
                if(type=='list'){
                    return checkedAnswers[0].id
                }
                if(type=='multi_choice'){
                    let multiAnswer =[];
                    checkedAnswers.map(answ =>
                        multiAnswer.push(answ.id)
                    )
                    return multiAnswer
                }
                return null;
            }else{
                return null
            }            
        }else if(question?.hide){
            return question.default;
        }else{
            return null;
        }
    }

    /**
     * Function to return the answer by the default question type or the answer stored in the array of filled questions.
     * If hide property is true return the answer by default
     * @param {Object} answer -- Data necessary in a question
     * @param {String} type -- question type
     * @returns Answer string by question type
     */
    function getDefaultFormAnswers (answers, type) {
        // Refactor : Change all object shape Ref: Sarl23
        if(answers){            
                if(type==='text'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='date'){
                    return  answers.hide ? answers.default : answers.value;
                }
                if(type==='email'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='phone'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='debit_card_number'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='month'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='year'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='number'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='country_state'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='country'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type==='industry_type'){
                    return answers.hide ? answers.default : answers.value;
                }
                if(type=='single_choice'){
                    return getDefaultAnswer(answers.answers,type, answers);
                }
                if(type=='list'){
                    return getDefaultAnswer(answers.answers,type, answers);
                }
                if(type=='multi_choice'){
                    return getDefaultAnswer(answers.answers,type, answers);
                }
                if(type=='file'){
                    return answers.hide ? answers.default : answers.value;
                }
                return null;
        }else{
            return null;
        }

    }

    function setFormAnswerStructure(question){
        let answers = [];
        if(question.answers.length > 0){
            question.answers.map( answ => { // eslint-disable-line
                answers.push({id: answ.id, is_checked: false })
            })
        }

        return {
            question_type: question.question_type.caption,
            form_question_template: question.id,
            text_answer: question.hide ? question.default : question.value,
            file_document: null,
            date_field: null,
            boolean_answer: null,
            answers: answers,
        }
    }

    const setPropertiesForQuestion = (question)=>{
        let jsonValuesArray = [];

        const keys = Object.keys(question.json_schema.items.properties);
        
        keys.forEach(( key, index )=>{
            const jsonValueResponse = question?.json_answer?.length > 0 ? question?.json_answer[0] : '';
            let values = {
                id: question.id +'_key_'+ key,
                profile_field_info: null,
                caption: question.json_schema.items.properties[key].caption,
                question_type: {
                    id: question.id +'-'+ index,
                    caption:question.json_schema.items.properties[key].question_type,
                    regular_expression: null,
                    api:question.json_schema.items.properties[key].api,
                },
                is_required:question.json_schema.items.properties[key].is_required,
                order_position: question.json_schema.items.properties[key].order_position,
                value: question?.json_answer?.length > 0 ? jsonValueResponse[key] : '',
                code: question.code,
                json_schema: null,
                active: question.active,
                answers: question.json_schema.items.properties[key].answers ?? [],
            }
            jsonValuesArray.push(values);
        });
        return jsonValuesArray;
    }

    /**
     * Json question type validation function
     * @returns Querstion by section or new question Json question type
     */
    function validationFormTemplate(){
        let newanswer = [];
        props.inscription_form_template?.questions?.forEach((question)=>{   
            if(question?.question_type?.caption === "json"){
                newanswer = setPropertiesForQuestion(question);
            }
        });
    
        return newanswer.length > 0 ? newanswer : props.inscription_form_template?.questions
        
    }

    function setAnswerStructure(question){
        let answers = [];
        if(question.answers.length > 0){
            question.answers.map( answ => { // eslint-disable-line
                answers.push({id: answ.id, is_checked: false })
            })
        }

        return {
            question_type: question.question_type.caption,
            form_question_template: question.id,
            text_answer: "",
            file_document: null,
            date_field: null,
            boolean_answer: null,
            answers: answers
        }
    }

    const uploadFileInState=(fileValue, id)=>{
        let new_file = state.files?.filter(file => file.id == id);
        if(new_file.length > 0){
            let selected_file = new_file[0];
            selected_file.file = fileValue
            let new_file_temp = state.files.map(file => file.id == id? selected_file: file);
            state.files = new_file_temp;
        } else {
            state.files.push({id:id, file:fileValue});
        }
    };

    const validationNewQuestionsForm = (objectValues) => {
        state.submitObj.questions.map((el, index) => { // eslint-disable-line  
            if(!objectValues.hasOwnProperty(`${el.form_question_template}`)) {
                objectValues[el.form_question_template] = ""
            }
        })
        return objectValues
    }
    
    const handleSubmitForm=(values)=>{
        const keysValues = Object.keys(validationNewQuestionsForm(values));
        let questionsAnswers = [];
        for(let i=0; i < state.submitObj.questions.length; i++){
            let subQuestion = state.submitObj.questions?.filter(sobj => sobj.form_question_template === keysValues[i]);
            let response = buildQuestionAnswer(subQuestion[0],values[keysValues[i]]);
            questionsAnswers.push(response);
        };
        state.submitObj.questions = questionsAnswers;
        props.onNewData(state.submitObj);
    }

    function buildQuestionAnswer(question, answer){
        switch (question.question_type) {
            case "text":
            case "email":
            case "phone":
            case "debit_card_number":
            case "month":
            case "year":
            case "number":
                return {
                    form_question_template: question.form_question_template,
                    text_answer: answer,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    question_type:question.question_type
                }
            case "date":
                return {
                    form_question_template: question.form_question_template,
                    text_answer: "",
                    file_document: null,
                    date_field: answer !== "" ? answer : null,
                    boolean_answer: null,
                    answers: [],
                    question_type:question.question_type
                }
            case "file":{
                let new_file = state.files?.filter(file => file.id == question.form_question_template);

                return {
                    form_question_template: question.form_question_template,
                    text_answer: "",
                    file_document: new_file.length > 0 ? new_file[0].file : null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    question_type:question.question_type
                }
            }
            case "boolean":
                return {
                    form_question_template: question.form_question_template,
                    text_answer: "",
                    file_document: null,
                    date_field: null,
                    boolean_answer: answer === 'TRUE'? true : false,
                    answers: [],
                    question_type:question.question_type
                }
            case "single_choice":
            case "list":{
                let new_answers = { ...question, answers: question.answers.map(answ =>
                    answ.id == answer ? {...answ,is_checked: true} : answ
                )}
                return {
                    form_question_template: question.form_question_template,
                    text_answer: "",
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: new_answers.answers,
                    question_type:question.question_type
                }
            }
            case "multi_choice":{
                let new_answers = { ...question, answers: question.answers.map(answ =>
                    answer.includes(answ.id) ? {...answ,is_checked: true} : answ
                )}
                return {
                    form_question_template: question.form_question_template,
                    text_answer: "",
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: new_answers.answers,
                    question_type:question.question_type
                }
            }
            case "industry_type":
            case "country_state":
            case "country": {
                return {
                    form_question_template: question.form_question_template,
                    text_answer: answer,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    question_type:question.question_type
                }
            }
            // no default
        }
    }

    function handleComposeInscriptionForm(question, index) {
        const { id, caption, question_type, answers, is_required, hide} = question;
        if(!hide){ // README: Hide option is true, hide question in UI
            switch (question_type.caption) {
                case "text":
                case "date":
                case "email":
                case "phone":
                case "debit_card_number":
                case "month":
                case "year":
                case "number":
                    return (
                        <InputDynamicFormComponent
                            form_question={question}
                            resetError={()=>{}}
                            errors={state.errors}
                            className={props.className}
                            //label={props.label}
                            input_id={question.id}
                            field_name={question.id}
                            disabled={props.disabled}
                            //handleInputDynamicForm={props.handleInputDynamicForm}
                            handleInputDynamicForm={()=>{}}
                            defaultValue={question.value}
                        />);
                case "single_choice":
                case "boolean":
                    return (
                            <RadioButtonDynamicFormComponent
                                form_question={question}
                                id={question.id}
                                name={question.id}
                                defaultValue={question.value}
                            />                        
                        );
                            
                case "file":
                    return (
                        <FileTournamentFormComponent
                            id={question.id}
                            caption={caption}
                            question_type={question_type}
                            multiple={false}
                            is_required={is_required}
                            onFilesChosen={(value) => uploadFileInState(value, id)}
                            t={props.t}
                            defaultValue={question.value}
                            formRef={formRef}
                            />
                    );
                case "multi_choice":
                    return (
                        <CheckBoxTournamentFormComponent
                            id={question.id}
                            caption={caption}
                            question_type={question_type}
                            is_required={is_required}
                            answers={answers}
                            onhandleCheked={()=>{}}
                        />
                    );
                case "list":
                    return(
                        <ListDynamicComponent 
                            id={question.id}
                            caption={caption}
                            question_type={question_type}
                            is_required={is_required}
                            answers={answers}
                            t={props.t}
                            defaultValue={question.value} //TODO: verificar que cargue un valor definido
                        />
                    );
                case "country_state":                
                    return (
                        <InputFieldSearchLocationComponent 
                            countries={ props.countries }
                            disabled={false}
                            handleSearchNationality ={ props.handleSearchNationality }
                            handleSearchCountryStates={ props.handleSearchCountryStates }
                            country_states={ props.country_states }
                            id={question.id}
                            field_name={question.id}                                
                            errors={ state.errors }
                            handleCountryStates={props.handleCountryStates}
                            label_country={props.t('registerPlayer.form.labelCountry')}
                            label_state={props.t('registerPlayer.form.labelStateProvince')}
                            type="text"
                            resetError={() => {}}
                            is_required={is_required}  
                            defaultValue={question.value}    
                        />
                    );
                case "industry_type":
                    return (
                        <SelectFieldDynamicFormComponent
                            label={props.t("registerPlayer.form.labelIndustryType")}
                            field_name={question.id}
                            input_id={question.id}                            
                            disabled={false}
                            className="compose-inscription-form__select-field"
                            type="text"
                            resetError={() => {}}
                            error={state.errors}
                            message=""
                            options={props.gateway_attribute?props.gateway_attribute:[]} 
                            is_required={is_required}
                            defaultValue={question.value}
                        />
                    );
    
                // no default
            }
        }
    }

    return (
        <div className="compose-inscription-form__container">
            { props.isMultipleForms === true 
                &&  <div className='compose-inscription-form__container__title baloa-body-1'>{props.t("registerPlayer.form.labelForm")}</div> 
            }
            {props.isMultipleForms === true && !state.errors && 
                <div className='compose-inscription-form__container__error_message'>
                     <span className='hubicon-cancel_fill'></span> 
                    <div className='baloa-body-1'>{props.t("registerPlayer.form.errorMessage")}</div> 
                </div>
            }
                               
            <FormComponent
                formInitialValues={state.formInitialValues} 
                onSubmit={(values) => { handleSubmitForm(values) }}
                className=""
                functionDisabled={()=>{}}
                enableReinitialize={(props.fulfilled_form || reinitializeStatus) && !props.team_player_form}
                innerRef={formRef}
            >
                {validationFormTemplate()?.map((question, index) => handleComposeInscriptionForm(question, index))}
                <input 
                    style={{ display: 'none' }}
                    type="submit"
                    ref={button_submit => state.submit_button_ref = button_submit}
                    value={"enviar"}
                    onClick={()=>{props.resetButton()}}
                />
            </FormComponent>  
        </div>
    );
}

export default withTranslation('tournament')(ComposeInscriptionFormComponent)