// React
import React from 'react';

//i18
import { useTranslation } from 'react-i18next';

// Styles
import './TournamentSetGroupsComponent.styles.css';
import help_icon from 'assets/icons/help-resume-icon.svg';

//Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentSetGroupsComponent(props) {

    const { t } = useTranslation('league');

    const [deleted_team_id, setDeletedTeamId] = React.useState('');
    const [boxGroupPosition, setBoxGroupPosition] = React.useState(null);
    const [isBoxOpen, setIsBoxOpen] = React.useState(false);
    const [counterAction, setCounterAction] = React.useState(0);
    const [groupId, setGroupId] = React.useState('');
    const [listNumberClassified, setListNumberClassified] = React.useState([]);

    React.useEffect(() => {
        setListNumberClassified(props.groups?.map(((group) => {
            let objGroups = {}
            objGroups['groupId'] = group.id;
            objGroups['number'] = group.classified_count;
            return objGroups;
        })));
    }, [props.groups]);


    React.useEffect(() => {
        setListNumberClassified(
            listNumberClassified.map((group) => {
                let Obj = { ...group };
                if (group.groupId === groupId) {
                    Obj.number = counterAction;
                    props.handleNumberClassified(groupId, counterAction);
                }
                return Obj;
            })
        );
    }, [groupId, counterAction]); // eslint-disable-line

    const handleListDeleteItemClick = (event, item) => {
        if (deleted_team_id !== item.id) {
            props.handleDeleteTeamFromTournamentGroup(item);
            setDeletedTeamId(item.id);
        }
    };

    const titlePhaseType = (phase_type, phase_name) => {
        if (phase_type === 'Knockout') {
            return t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${phase_name}`);
        } else if (phase_type === 'Free') {
            return t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelFreeType');
        } else { return t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroups') }
    };

    const titleOptionPhaseType = (phase_type) => {
        if (phase_type === 'Knockout') {
            return {
                firtsOption: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelEditKnockout'),
                secondOption: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelDeleteKnockout'),
                createGroupBtn: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelCreateKey')
            };
        } else if (phase_type === 'Free') {
            return {
                firtsOption: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelEditFree'),
                secondOption: ''
            }
        } else {
            return {
                firtsOption: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelEditGroup'),
                secondOption: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelDeleteGroup'),
                createGroupBtn: t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelCreateGroup')
            };
        }
    };

    const handleGroupOptions = (index) => {
        setBoxGroupPosition(index)
        if (isBoxOpen) {
            setIsBoxOpen(false);
        } else {
            setIsBoxOpen(true);
        }
    };

    const handleOnEditPhaseModal = (group) => {
        if(props.tournament_active_phase_type === 'Groups') {
            props.handleOnEditPhaseModal('edit_group', group, props.isCreatePhase);
        }
        if(props.tournament_active_phase_type === 'Knockout') {
            props.handleOnEditPhaseModal('edit_key', group, props.isCreatePhase);
        }
        setIsBoxOpen(false);
    }

    const handleOnCreateGroupKey = () => {
        if(props.tournament_active_phase_type === 'Groups') {
            props.handleOnEditPhaseModal('create_group', null, props.isCreatePhase);
        }
        if(props.tournament_active_phase_type === 'Knockout') {
            props.handleOnEditPhaseModal('create_key', null, props.isCreatePhase);
        }
    }

    const handleDeleteGroup = (group) => {
        props.handleDeleteGroup(group);
        setIsBoxOpen(false);
    }

    return (
        <div className={`tournament-set-groups__container scroll-parent ${props.available_teams? 'groups-all-screen' : ''}`}>
            <div className="tournament-set-groups__container__title">
                <div className="baloa-subtitle-1">{titlePhaseType(props.tournament_active_phase_type, props.tournament_active_phase_name)}</div>
                {props.tournament_active_phase_type === 'Free' &&
                    <div className='tournament-set-groups__group-options'>
                        {!props.isCreatePhase && props.showActions &&
                            <span className='baloa-subtitle-1 icon-options' onClick={() => handleGroupOptions()} />
                        }
                        <div className={`tournament-set-groups__group-options__box ${isBoxOpen ? 'show' : ''}`}>
                            <div className='tournament-set-groups__group-options__box-option'
                                onClick={() => {
                                    setIsBoxOpen(false);
                                    props.handleOnEditPhaseModal('edit_free', props.groups[0]);
                                }}>
                                <span className='hubicon-edit' />
                                <div className='baloa-names'>{titleOptionPhaseType(props.tournament_active_phase_type)?.firtsOption}</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="tournament-set-groups__container__buttons">
                    {(props.tournament_active_phase_type === 'Groups' || props.tournament_active_phase_type === 'Knockout') && props.select_groups_teams && props.showActions && 
                        <PrimaryButtonComponent className='tournament-set-groups__container__btn small'
                            onClick={() => { handleOnCreateGroupKey() }}>
                            <span className='hubicon-add_option' />
                            {titleOptionPhaseType(props.tournament_active_phase_type)?.createGroupBtn}
                        </PrimaryButtonComponent>
                    }
                    {props.isCreatePhase &&
                        <SecondaryButtonComponent className='tournament-set-groups__container__btn small'
                            onClick={() => props.handleCreateNewAtomaticGroups(false)}>
                            {t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelCleanFields')}
                        </SecondaryButtonComponent>
                    }
                </div>
            </div>
            {props.isCreatePhase && props.tournament_active_phase_type === 'Free' && listNumberClassified.length > 0 && props.showActions && 
                <div className="tournament-set-free-number_increase-decrease">
                    <div className='tournament-set-groups-number_increase-decrease-text baloa-names'>
                        <div className="baloa-normal-medium">{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.setGroupsLabel')}</div>
                    </div>
                    <div className='tournament-set-groups_increase-decrease__value'>
                        <div className='btn' onClick={() => {
                            if (listNumberClassified[0].number > 1) {
                                setCounterAction(listNumberClassified[0].number - 1);
                                setGroupId(listNumberClassified[0].groupId);
                            }
                        }}>-</div>
                        <input type='number' min={'1'} required value={listNumberClassified[0].number ?? 0 } />                        
                        <div className='btn' onClick={() => {
                            if (listNumberClassified[0].number < props.groups[0]?.teams.length) {
                                setCounterAction(listNumberClassified[0].number + 1);
                                setGroupId(listNumberClassified[0].groupId);
                            }
                        }} >+</div>
                    </div>
                </div>
            }
            <div className="tournament-set-groups__groups scroll">
                {props.groups?.map((group, index) => (
                    <div className="tournament-set-groups_item_box" key={group.id}>
                        <div className="tournament-set-groups_item_box_text_switch">
                            <div className="tournament-set-groups__group_information">
                                {props.tournament_active_phase_type === 'Groups' &&
                                    <div className="tournament-set-groups__group_name baloa-normal-medium">{t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.caption.split(" ")[0]}`,{_number: group.caption.split(" ")[1]})}</div>
                                }
                                {props.tournament_active_phase_type === 'Knockout' &&
                                    <div className="tournament-set-groups__group_name baloa-normal-medium">
                                        {t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${group.caption.split(" ")[0]}`, {phase_name: t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase_name}`), _number: group.caption.split(" ")[1] })}
                                    </div>
                                }
                                {group.teams.length === 1 && props.tournament_active_phase_type === 'Knockout' &&
                                    <div className="basic-data__form_edit_content__input_grid-tooltip">
                                        <div className="tooltip">
                                            <img src={help_icon} alt="" />
                                            <span className="tooltiptext">
                                                <p className="baloa-username">{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNoValidateTeamsNumber')}</p>
                                            </span>
                                        </div>
                                    </div>
                                }
                                {props.isCreatePhase && props.tournament_active_phase_type !== 'Free' && props.showActions
                                    ? index !== 0 && <span className='hubicon-delete'onClick={() => props.handleDeleteGroup(group)}/>
                                    : <React.Fragment>
                                        {props.showActions && props.tournament_active_phase_type !== 'Free' &&
                                            <div className='tournament-set-groups__group-options'>
                                                <span className='icon-options' onClick={() => handleGroupOptions(index)} />
                                                <div className={`tournament-set-groups__group-options__box ${(index === boxGroupPosition && isBoxOpen) ? 'show' : ''}`}>
                                                    <div className='tournament-set-groups__group-options__box-option'
                                                        onClick={() => handleOnEditPhaseModal(group)}>
                                                        <span className='hubicon-edit' />
                                                        <div className='baloa-names'>{titleOptionPhaseType(props.tournament_active_phase_type)?.firtsOption}</div>
                                                    </div>
                                                    { index !== 0 &&
                                                        <div className='tournament-set-groups__group-options__box-option' 
                                                            onClick={() => {handleDeleteGroup(group)}}>
                                                            <span className='hubicon-delete_question' />
                                                            <div className='baloa-names'>{titleOptionPhaseType(props.tournament_active_phase_type)?.secondOption}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        {group.teams.length > 0
                            ? <div className="tournament-set-groups__list">
                                {group.teams.map((e, i) =>
                                    group.teams[i] &&
                                    <div key={i} className={`tournament-set-groups__list-item ${props.disable_phase_fields ? '_disabled' : ''}`}>
                                        <div className='tournament-set-groups__info-item'>
                                            {group.teams[i].photo
                                                ? <img src={group.teams[i].photo} alt="" className="tournament-set-groups__list-item__img" />
                                                : <span color={getRandonIconBallColor()} className="icon-ball" />
                                            }
                                            <div className="tournament-set-groups__list-item__text">
                                                <div className='baloa-names'>{group.teams[i]?.name}</div>
                                            </div>
                                        </div>
                                        { props.isCreatePhase &&
                                            <TextButtonComponent
                                                disabled={props.disabled_edit_tournament_data}
                                                className="tournament-set-groups__delete-button"
                                                onClick={(event) => { handleListDeleteItemClick(event, group.teams[i]) }}
                                            >
                                                <span className='icon-x' />
                                            </TextButtonComponent>
                                        }
                                    </div>
                                )}
                            </div>
                            : <div className="tournament-set-groups__list-item__empty">
                                { props.tournament_active_phase_type === 'Free' && <div className='baloa-normal-medium'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.noFreePhaseTeams')}</div> }
                                { props.tournament_active_phase_type === 'Knockout' && <div className='baloa-normal-medium'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.noKnockoutTeams')}</div> }
                                { props.tournament_active_phase_type === 'Groups' && <div className='baloa-normal-medium'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.noGroupsTeams')}</div> }
                            </div>
                        }
                        {props.isCreatePhase && props.tournament_active_phase_type === 'Groups' &&
                            <div className="tournament-set-groups-number_increase-decrease">
                                <div className='tournament-set-groups-number_increase-decrease-text baloa-names'>
                                    <div className="baloa-normal">{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.setGroupsLabel')}</div>
                                </div>
                                <div className='tournament-set-groups_increase-decrease__value'>
                                    <div className='btn' onClick={() => {
                                        if (listNumberClassified.filter(element => element.groupId === group.id)[0]?.number > 1) {
                                            setCounterAction(listNumberClassified.filter(element => element.groupId === group.id)[0]?.number - 1);
                                            setGroupId(group.id);
                                        }
                                    }}>-</div>
                                    <input type='number' min={'1'}
                                        required
                                        value={listNumberClassified.filter(element => element.groupId === group.id)[0]?.number ?? 0}
                                    />
                                    <div className='btn' onClick={() => {
                                        if (listNumberClassified.filter(element => element.groupId === group.id)[0]?.number < group.teams.length) {
                                            setCounterAction(listNumberClassified.filter(element => element.groupId === group.id)[0]?.number + 1);
                                            setGroupId(group.id);
                                        }
                                    }} >+</div>
                                </div>
                            </div>
                        }
                    </div>
                ))
                }
            </div>
        </div>
    );
}

export default TournamentSetGroupsComponent;