/*eslint eqeqeq: "off"*/
// React
import React from 'react'

// Styles
import './PageAdminMembersComponent.styles.css'

// Assets
import plus_icon from 'assets/icons/plus-icon.svg'
import options_icon from 'assets/icons/options-icon.svg'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import Popover from '@mui/material/Popover';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';


//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


function PageAdminMembersComponent( props ) {

    const [anchorAdminMember, setAnchorAdminMember] = React.useState(null);
    const handleClickOptionsMemberButton = (event) => {
        setAnchorAdminMember(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorAdminMember(null);        
    };

    return (
        
        <Card1Component 
            className={ "page-admin-members " + ( props.className ? props.className : '' ) } 
            title={props.t('tabs.members.admin')} 
            button={ ( 
                (props.is_admin && !isMobile) ? 
                <img className="page-admin-members__new-button" 
                    src={ plus_icon }
                    alt=""
                />
                : ""
            ) }
            onButtonClick={ props.onNewButton }
        >

            <div className="page-admin-members__container">
                {
                    props.page_admin_members?.map( member => 

                        <div className="page-admin-members__members" id={member.username}>
                            <div
                                onClick={() => { props.handleOnProfileClick(member) }}
                                className={"page-admin-members__photo " + (member.photo ? '' : 'no-photo')}>                                
                                {member.photo &&
                                    <img src={member.photo} alt="" />
                                }
                                {!member.photo &&
                                    <span className="icon-ball" color={getRandonIconBallColor()}/>
                                }
                            </div>
                            <div className="page-admin-members__info"
                                onClick={() => { props.handleOnProfileClick(member)}}
                            >
                                <div                                    
                                    className="page-admin-members__info__username baloa-names">
                                    {member.first_name + " " + member.last_name}
                                </div>
                                <div
                                    className="page-admin-members__info__username baloa-username">
                                    {"@" + member.username}
                                </div>
                               
                            </div>

                            {/* Profile Options */}
                            {props.is_admin && !isMobile &&
                                <div className="page-admin-members__options">
                                    <div className="page-admin-members__options__option" id={"options_" + member.username} >
                                        <span
                                            id={"adm_opt_" + member.username}
                                            aria-control={ "adm_opt_" + member.username } // eslint-disable-line
                                            className="page-admin-members__actions"
                                            onClick={ handleClickOptionsMemberButton }
                                        >
                                            <img src={ options_icon } alt=""/>
                                        </span>
                                        {/* User options edit popover */}
                                        {anchorAdminMember?.id == "adm_opt_" + member.username &&
                                            <Popover
                                                id={ "adm_opt_" + member.username }
                                                open={ Boolean( anchorAdminMember )}
                                                anchorEl={ anchorAdminMember }
                                                onClose={ handleCloseOptions }                   
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                container= { anchorAdminMember ? anchorAdminMember : null}
                                                >
                                                {props.page_type == "group"?
                                                    <div
                                                        className="header-auth__actions__me__popover"
                                                    > 
                                                        <TextButtonComponent 
                                                            className="header-auth__actions__me__popover__action"
                                                            onClick={ () => {
                                                                handleCloseOptions();
                                                                props.deleteAdminMemberFromPage( member ); 
                                                            }
                                                            }
                                                        >
                                                            <span>{ props.t('groups.adminAdministratorsCard.optLablRemoveAdmin') }</span>
                                                        </TextButtonComponent>
                                                        <TextButtonComponent 
                                                            className="header-auth__actions__me__popover__action"
                                                            onClick={ () => {
                                                                handleCloseOptions();
                                                                props.kickoutGroupAdminMember( member.username ); 
                                                            }}
                                                        >
                                                            <span>{ props.t('groups.adminAdministratorsCard.optLabKickOutAdmin') }</span>
                                                        </TextButtonComponent>
                                                    </div>
                                                :
                                                    <div
                                                        className="header-auth__actions__me__popover"
                                                    >                        
                                                        <SecondaryButtonComponent
                                                            onClick={ ( )=>{ 
                                                                handleCloseOptions();        
                                                                props.deleteAdminMemberFromPage( member );                          
                                                            } }
                                                            className="header-auth__actions__me__popover__action" 
                                                        >
                                                            <span>{props.t('tabs.members.button')}</span>
                                                        </SecondaryButtonComponent>  
                                                    </div>
                                                }
                                                
                                            </Popover>   
                       
                                        }        

                                    </div>
                                </div>
                            }
                        
                        </div>
                    )
                }
            </div>

        </Card1Component>

    )

}

export default withTranslation('page')(PageAdminMembersComponent)

//export default PageAdminMembersComponent;
