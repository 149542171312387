
// Action creators
import { 
    changeHomePostsLoadingAction, 
    loadHomePostsAction,
    changeHomeProfileSuggestionsLoadingAction,
    loadHomeProfileSuggestionsAction,
    changeHomePageSuggestionsLoadingAction,
    //changeHomeGroupsSuggestionsLoadingAction,
    changeHomeLeaguesSuggestionsLoadingAction,
    loadHomePageSuggestionsAction,
    //loadHomeGroupsSuggestionsAction,
    loadHomeLeaguesSuggestionsAction,
    changeErrorMessageSnackBar,
    updateHomeFeedAction,
    resetHomeFeedAction,
    loadHomePagesShortcutsAction,
    loadFollowedPagesAction,
    loadExplorePagesAction,
    updateExploreFollowPageAction,
    updateExploreUnFollowPageAction,
    updateHomePageSuggestionFollowAction,
    updateHomePageSuggestionUnFollowAction,
    loadHomePlayersAction,
    loadPlayerAttributesAction,
    loadHomeFeaturedClipsAction,
    updateHomeFeaturedClipAction,
} from './HomeActionsCreators';

import { loadUserMe } from 'app/authentication/actions/UserActionsCreators';
// Apis
import { 
    GetHomePostsApi, 
    GetProfileSuggestionsApi, 
    /*GetPageSuggestionsApi, GetGroupsSuggestionsApi, GetLeaguesSuggestionsApi,*/ 
    GetSuggestionsApi, 
    GetHomePagesShortcutsApi, 
    GetUnauthenticatedSuggestionsApi,
    GetFollowedPagesApi,
    GetExplorePagesApi,
    GetHomePlayersApi,
    GetHomeFeaturedClipsApi,
} from 'app/home/apis/HomeApi';
import { PatchUserApi } from 'app/user-settings/apis/UserSettingsApi';
import { UnfollowPageApi, FollowPageApi } from 'app/pages/apis/PageApi';
import { GetAttributesConstantsApi } from 'app/generalattributes/apis/AttributesApi';
import { ReactionsPostApi, OverrrideReactionsPostApi, DeleteReactionsPostApi } from 'app/posts/apis/PostsApi';
import { loadPostDetailById } from 'app/posts/actions/PostActions';

// i18next 
import i18n from 'i18next';


export const loadHomePosts = ( loading = false, next_page ) => ( dispatch ) => {

    if ( !loading ) {
        dispatch( changeHomePostsLoadingAction( true ) );        
        GetHomePostsApi( next_page )
            .then( response => {
                dispatch( loadHomePostsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                dispatch( changeHomePostsLoadingAction( false ) );
            } );
    }

}

export const updateHomeFeed = ( ) => ( dispatch ) => {
     
    GetHomePostsApi( )
        .then( response => {
            dispatch( updateHomeFeedAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const loadHomeProfileSuggestions = () => ( dispatch ) => {

    dispatch( changeHomeProfileSuggestionsLoadingAction( true ) );
    GetProfileSuggestionsApi()
        .then( response => {
            dispatch( loadHomeProfileSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomeProfileSuggestionsLoadingAction( false ) );
        } );
}

export const loadHomePageSuggestions = () => ( dispatch ) => {

    dispatch( changeHomePageSuggestionsLoadingAction( true ) );
    //GetPageSuggestionsApi()
    GetSuggestionsApi("")
        .then( response => {
            dispatch( loadHomePageSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomePageSuggestionsLoadingAction( false ) );
        } );
}

/*export const loadGroupsSuggestions = () => ( dispatch ) => {

    dispatch( changeHomeGroupsSuggestionsLoadingAction( true ) );
    GetGroupsSuggestionsApi()
        .then( response => {
            dispatch( loadHomeGroupsSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomeGroupsSuggestionsLoadingAction( false ) );
        } );
}*/

export const loadLeguesSuggestions = () => ( dispatch ) => {

    dispatch( changeHomeLeaguesSuggestionsLoadingAction( true ) );
    //GetLeaguesSuggestionsApi()
    GetSuggestionsApi("league")
        .then( response => {
            dispatch( loadHomeLeaguesSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomeLeaguesSuggestionsLoadingAction( false ) );
        } );
}

export const resetHomeFeed = ( ) => ( dispatch ) => {
     
    GetHomePostsApi( )
        .then( response => {
            dispatch( resetHomeFeedAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const updateUserData = ( new_user_data ) => {
    return ( dispatch ) => {
        PatchUserApi( new_user_data )
            .then( response => {
                dispatch(loadUserMe(response.data));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadHomePagesShortcuts = ( ) => ( dispatch ) => {
     
    GetHomePagesShortcutsApi( )
        .then( response => {
            dispatch( loadHomePagesShortcutsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const loadUnauthenticatedHomePageSuggestions = () => ( dispatch ) => {

    dispatch( changeHomePageSuggestionsLoadingAction( true ) );
    //GetPageSuggestionsApi()
    GetUnauthenticatedSuggestionsApi("")
        .then( response => {
            dispatch( loadHomePageSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomePageSuggestionsLoadingAction( false ) );
        } );
}

export const loadFollowedPages = ( username, next_page ) => ( dispatch ) => {
     
    GetFollowedPagesApi( username, next_page )
        .then( response => {
            dispatch( loadFollowedPagesAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const unfollowHomePages = ( page, username ) => {

    return ( dispatch ) => {
        
        UnfollowPageApi( page.pagename )
            .then( response => {
                dispatch( loadFollowedPages( username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const explorePagesFilter = ( pagename, page_type, categories, next_page ) => {
    return ( dispatch ) => {
        GetExplorePagesApi( pagename, page_type, categories, next_page )
            .then( response => {
                dispatch( loadExplorePagesAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const followExplorePage = ( page, api_type ) => {
    
    return ( dispatch ) => {
        
        FollowPageApi( page.pagename )
            .then( response => {
                if(api_type === 'suggestions'){
                    dispatch( updateHomePageSuggestionFollowAction( page ) )
                }else{
                    dispatch( updateExploreFollowPageAction( page ) )
                }
                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const unfollowExplorePage = ( page, api_type ) => {

    return ( dispatch ) => {
        
        UnfollowPageApi( page.pagename )
            .then( response => {
                if(api_type === 'suggestions'){
                    dispatch( updateHomePageSuggestionUnFollowAction( page ) )
                }else{
                    dispatch( updateExploreUnFollowPageAction( page ) )
                }
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }
}

export const loadHomePlayers = ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) => ( dispatch ) => {
     
    GetHomePlayersApi( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page )
        .then( response => {
            dispatch( loadHomePlayersAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('signUp:code.error3'),
                    error_message_is_open: true
                } ) )
            }
        })            
}

export const loadPlayerAttributes = () => ( dispatch ) => {

    GetAttributesConstantsApi()
        .then( response => {
            dispatch( loadPlayerAttributesAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'),
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const loadHomeFeaturedClips = ( next_page ) => {

    return async ( dispatch ) => {
        await GetHomeFeaturedClipsApi( next_page )
            .then( response => {
                dispatch( loadHomeFeaturedClipsAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const sendFeaturedClipReaction = ( post_id, reaction ) => {

    return ( dispatch ) => {

        ReactionsPostApi( post_id, reaction )
            .then( response => {
                dispatch( updateHomeFeaturedClipAction(response) );                
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const overrideFeaturedClipReaction = ( post_id, reaction, reaction_id ) => {

    return ( dispatch ) => {

        OverrrideReactionsPostApi(reaction, reaction_id )
            .then( response => {
                dispatch( updateHomeFeaturedClipAction(response) );
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteFeaturedClipReaction = ( post_id, reaction_id ) => {

    return ( dispatch ) => {

        DeleteReactionsPostApi( reaction_id )
            .then( response => {
                dispatch( loadPostDetailById( post_id, 'featured' ) );
                //dispatch( updateHomeFeaturedClipAction(response) );       
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}