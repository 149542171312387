// React
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './InputFieldAutocompleteWithSearchComponent.styles.css';

function InputFieldAutocompleteWithSearchComponent( props ) {
    const [searchWord, setSearchWord] = React.useState( null );
    const field_name =  props.field_name;
 
    const [input_change, setInputChange] = React.useState("");
    
    /* eslint-disable */
    React.useEffect(() => {
        if(input_change==props.id){
            document.getElementById(props.id)?.focus();
        }        
    }, [ props.options]);
    /* eslint-enable */

    React.useEffect(() => { 
        if(input_change!==""){ 
            document.getElementById(input_change)?.focus();
        }
     }, [input_change]);// eslint-disable-line

     /* React.useEffect(() => { 
        if(input_change!==""){ 
            document.getElementById(input_change).focus();
        }
     }, [searchWord]);// eslint-disable-line */

     React.useEffect(() => {
        if(props.value !== undefined){
            setSearchWord(props.value);
        }
     }, [props.value]);// eslint-disable-line

     React.useEffect(() => {
        if(props.defaultValue !== undefined){
            setSearchWord(props.defaultValue);
        }
     }, [props.defaultValue]);// eslint-disable-line

    return(

            <Field name={ props.field_name } validate={ props.validationFunction }>
        
            {  ( data ) => {
                    if ( props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = props.error[ field_name ];
                    }    
                    return(
                        <div className= { "input-field-auto-search " + ( props.className ? props.className : '' )  }>
                        {/* { props.label && <label className="input-autocomplete-label" htmlFor={ props.input_id }>{ props.label }</label> } */}
                            <Autocomplete
                                //key={ props.key }
                                //name={ props.field_name}
                                //component={Autocomplete}
                                disabled={props.disabled}
                                options={props.options}
                                noOptionsText= { props.noOptionsText }
                                getOptionLabel={(option) => option[props.optionLabelField] }
                                //getOptionSelected={option => option[props.optionSelectedField] }
                                isOptionEqualToValue={(option, values) => option[props.optionSelectedField] === values[props.optionSelectedField] }
                                getOptionKey={option => option[props.optionSelectedField] }
                                disablePortal={true}
                                value={ searchWord }
                                defaultValue={props.defaultValue}
                                onInputChange={(event, word) => {
                                    setInputChange(props.id); //se guardar el id del input donde se está escribiendo
                                    if(word !== 'undefined' && (word.length > props.lettercount) ){    
                                        props.handleSearchWord( word );  
                                    }
                                    data.form.setFieldValue( props.field_name, word )
                                }}
                                onChange={ (e,data)=>{
                                        setSearchWord(data); 
                                        if(props.country_logo || props.world_city) {
                                            props.handleSetDefaultCountry(data);
                                        }
                                        if(props.team_search) {
                                            props.setTeam(data);//Obligatorio el parametro SetTeam si team_search es true
                                        }                                        
                                    }
                                }

                                id = {props.id}
                                renderOption={(comprops,option) => {
                                    //display value in Popper elements
                                    if(props.country_logo) {
                                        return <ListItem
                                                    {...comprops}
                                                    key={option.id}
                                                    className="input-field-autocomplete-with-search__list"
                                                >
                                                    <ListItemIcon className="input-field-autocomplete-with-search__list__icon" >
                                                        { option.flag_icon
                                                            ? <img src={ option.flag_icon } alt=""/>
                                                            : <div className='input-field-autocomplete-with-search__list__icon__icon-ball'><span className='icon-ball' color={getRandonIconBallColor()} /></div>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={ option.name } className="input-field-autocomplete-with-search__listitem" />
                                                </ListItem>
                                    } else if(props.world_city){
                                        return <ListItem
                                                    {...comprops}
                                                    key={option.id}
                                                    className="input-field-autocomplete-with-search__list"
                                                >
                                                    <ListItemText primary={ <div className='input-field-autocomplete-with-search__list-selected__location'><p>{`${option.name}, `}<span>{`${option.country_name}`}</span></p></div> } className="input-field-autocomplete-with-search__listitem" />
                                                </ListItem>

                                    } else if(props.team_search){
                                        return <ListItem
                                                    {...comprops}
                                                    key={option.id}
                                                    button
                                                    className="input-field-autocomplete-with-search__list"
                                                    //selected={selectedIndex === option.id}
                                                    //onClick={(event) => handleListItemClick(event, item)}
                                                >
                                                    <ListItemIcon className="input-field-autocomplete-with-search__list__icon-team" >
                                                        {option.team_logo?
                                                            <img className="" src={ option.team_logo } alt="" />
                                                        :
                                                            <div className='input-field-autocomplete-with-search__list__icon-team__icon-ball'><span className='icon-ball' color={getRandonIconBallColor()} /></div>
                                                        }                                                            
                                                    </ListItemIcon>
                                                    <ListItemText primary={ option.team } secondary={ '@'+(option.teamname ) } className="input-field-autocomplete-with-search__listitem" />
                                                </ListItem>
                                    }else {
                                        return <ListItem
                                                    {...comprops}
                                                    key={option.id}
                                                    className="input-field-autocomplete-with-search__list"
                                                >
                                                    <ListItemText primary={ option.name } className="input-field-autocomplete-with-search__listitem" />
                                                </ListItem>

                                    }
                                }}                                
                                renderInput={(params) => (
                                    <div className='input-field-autocomplete-with-search__list__container'>
                                        { (props.country_logo && props.value)
                                            ?                                            
                                            <div className='input-field-autocomplete-with-search__list__country-logo' id={props.id}>
                                                <div className='input-field-autocomplete-with-search__list__country-logo__label'>{props.label}</div>
                                                <ListItem className="input-field-autocomplete-with-search__list-selected" {...params.inputProps}>
                                                    <ListItemIcon className="input-field-autocomplete-with-search__list__icon" >
                                                        { props.value.flag_icon
                                                            ? <img src={ props.value.flag_icon } alt=""/>
                                                            : <div className='input-field-autocomplete-with-search__list__icon__icon-ball'><span className='icon-ball' color={getRandonIconBallColor()} /></div>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={ props.value.name } className="input-field-autocomplete-with-search__listitem" />
                                                    <span className='hubicon-clear_option' onClick={() => {props.handleSetDefaultCountry(null); setSearchWord(null); }} />
                                                </ListItem>
                                               
                                            </div>                                            
                                            :
                                            (props.world_city && props.value)?
                                                <div className='input-field-autocomplete-with-search__list__country-logo' id={props.id}>
                                                    <div className='input-field-autocomplete-with-search__list__country-logo__label'>{props.label}</div>
                                                    <ListItem className="input-field-autocomplete-with-search__list-selected" {...params.inputProps}>
                                                        <ListItemText primary={ <div className='input-field-autocomplete-with-search__list-selected__location'><span className='icon-location'></span><p>{`${props.value.name}, `}<span>{`${props.value.country_name}`}</span></p></div> } className="input-field-autocomplete-with-search__listitem" />
                                                        <span className='hubicon-clear_option' onClick={() => {props.handleSetDefaultCountry(null); setSearchWord(null); }} />
                                                    </ListItem>    
                                                </div>
                                            :
                                            /* (props.team_search && props.defaultValue)
                                            ?                                            
                                            <div className='input-field-autocomplete-with-search__list__country-logo' id={props.id}>
                                                <ListItem className="input-field-autocomplete-with-search__list-selected">
                                                    <ListItemIcon className="input-field-autocomplete-with-search__list__icon" >
                                                        { props.defaultValue.team_logo
                                                            ? <img src={ props.defaultValue.team_logo } alt=""/>
                                                            : <div className='input-field-autocomplete-with-search__list__icon__icon-ball'><span className='icon-ball' color={getRandonIconBallColor()} /></div>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={ props.defaultValue.team } className="input-field-autocomplete-with-search__listitem" />
                                                    <span className='hubicon-clear_option' onClick={() => {props.setTeam(null); setSearchWord(null); }} />
                                                </ListItem>
                                               
                                            </div> 
                                            :       */     
                                            <TextField
                                                {...params}
                                                // className={ props.className + " input-field-autocomplete"}
                                                className={ 
                                                    'input-field-autocomplete-search ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                                }
                                                label={ props.label }
                                                variant="outlined"
                                                margin="normal"
                                                placeholder={ props.placeholder }
                                                onKeyDown={ () => { props.resetError() } }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}              
                                            />
                                        }
                                    </div>
                                )}
                            />
                            <p className={"input-field-autocomplete-search__message " + (data.form.errors[ field_name ] && data.form.touched[ field_name ] ? 'error' : '' )}>
                                { data.form.errors[ field_name ] ? data.form.errors[ field_name ] :  props.message }
                            </p>
        
                        </div>
                    )

            }
            }
            </Field>
    )
}

// Types
InputFieldAutocompleteWithSearchComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
}

export default InputFieldAutocompleteWithSearchComponent;
