// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("baloers.eot?8vs9uf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("baloers.ttf?8vs9uf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("baloers.woff?8vs9uf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("baloers.svg?8vs9uf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#baloers" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'baloers';
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconbal-"], [class*=" iconbal-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'baloers' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconbal-Balores .path1:before {
  content: "\\e900";
  color: rgb(201, 150, 26);
}
.iconbal-Balores .path2:before {
  content: "\\e901";
  margin-left: -1.0439453125em;
  color: rgb(255, 255, 255);
}
.iconbal-Balores .path3:before {
  content: "\\e902";
  margin-left: -1.0439453125em;
  color: rgb(255, 255, 255);
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/baloers.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,6CAA+B;EAC/B;;;yDAGiD;EACjD,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,+EAA+E;EAC/E,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;;EAEd,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,yBAAyB;AAC3B","sourcesContent":["@font-face {\n  font-family: 'baloers';\n  src:  url('baloers.eot?8vs9uf');\n  src:  url('baloers.eot?8vs9uf#iefix') format('embedded-opentype'),\n    url('baloers.ttf?8vs9uf') format('truetype'),\n    url('baloers.woff?8vs9uf') format('woff'),\n    url('baloers.svg?8vs9uf#baloers') format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n[class^=\"iconbal-\"], [class*=\" iconbal-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'baloers' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.iconbal-Balores .path1:before {\n  content: \"\\e900\";\n  color: rgb(201, 150, 26);\n}\n.iconbal-Balores .path2:before {\n  content: \"\\e901\";\n  margin-left: -1.0439453125em;\n  color: rgb(255, 255, 255);\n}\n.iconbal-Balores .path3:before {\n  content: \"\\e902\";\n  margin-left: -1.0439453125em;\n  color: rgb(255, 255, 255);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
