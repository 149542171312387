/*eslint eqeqeq: "off"*/
// React
import React from "react";

// Components
import Modal2Component from "shared/components/modal2/Modal2Component";
import FormComponent from "shared/components/form/FormComponent";
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";

// Styles

import "./LeagueTeamMemberRolModalComponent.styles.css";

// Validators
import { AnyValidator } from "shared/validators/SignUpValidator";

// Components
import SelectFieldComponent from "shared/components/select-field/SelectFieldComponent";
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";

// i18
import { useTranslation } from "react-i18next";

// Assets
import { getRandonIconBallColor } from "shared/functions/GetRandomIconColor";

function LeagueTeamMemberRolModalComponent(props) {
	const formInitialValues = {
		roles: "",
		number: "",
		positions: "",
	};

	const [is_player, setIsPlayer] = React.useState(false);
	const { t } = useTranslation("team");

	const handleChangeRol = (idrol) => {
		let rol = props.roles.find((rol) => rol?.value == idrol);
		if (rol?.value == "player") {
			setIsPlayer(true);
		} else {
			setIsPlayer(false);
		}
	};

	const selectRolPlayer = (member_rol) => {
		props.onClose();
		props.onSelectRolPlayer(member_rol);
	};

	return (
		<Modal2Component
			isOpen={props.isOpen}
			onClose={props.onClose}
			title={props.title}
			className="member_team_container"
		>
			<div class="member-rol">
				<div className="member-rol__data">
					<div className="member-rol__data__photo">
               {
                  props.playerSelected?.photo &&
                  <img className="member-rol__data__photo__img" src={props.playerSelected.photo} alt="" />
               }
               {
                  !props.playerSelected?.photo &&
                  <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
               }                   
					</div>
					<div className="member-rol__data__data">
						<span className="member-rol__data__data__name">
							{props.playerSelected?.first_name
								? props.playerSelected?.first_name + " "
								: " "}
							{props.playerSelected?.last_name
								? props.playerSelected?.last_name
								: ""}
							{/*props.playerSelected != null
                                ? props.playerSelected.first_name
                                : ""}
                            {props.playerSelected != null ? props.playerSelected.last_name : ""*/}
						</span>
						<span className="member-rol__data__data__username">
							@
							{props.playerSelected != null
								? props.playerSelected?.username 
								: ""}
						</span>
					</div>
				</div>

				<FormComponent
					formInitialValues={formInitialValues}
					onSubmit={(values, actions) => {
						let member_rol = {
							username: props.playerSelected?.username,
							role: {
								role: values.roles,
								position:
									values.positions == "" ? null : values.positions,
								player_number:
									values.number == "" ? null : values.number,
							},
						};
						setIsPlayer(false);
						//Call Api
						selectRolPlayer(member_rol);
					}}
					className="basic-data__form form"
					functionDisabled={() => {}}
				>
					<div>
						<div className="member-rol__roles__data-select">
							<SelectFieldComponent
								label={t("teams.teamMemberRol")}
								className="member-rol__roles"
								field_name="roles"
								validationFunction={AnyValidator}
								disabled={false}
								resetError={() => props.onResetError({ roles: "" })}
								error={props.errors}
								message=""
								placeholder=""
								options={props.roles}
								onClick={(e) => handleChangeRol(e.target.value)}
							/>
						</div>
						<div className="">
							{is_player === true && (
								<div className="member-rol__positions">
									<div className="member-rol__positions__positions">
										<SelectFieldComponent
											label={t("teams.teamMemberPosition")}
											field_name="positions"
											validationFunction={AnyValidator}
											disabled={false}
											resetError={() =>
												props.onResetError({ positions: "" })
											}
											error={props.errors}
											message=""
											placeholder=""
											options={props.positions}
										/>
                              <div className="member-rol__positions__optional-label">
                              {t("teams.teamsOptionalLabel")}
                              </div>
									</div>
									<div className="member-rol__positions__number">
										<InputFieldComponent
											label={t("teams.teamMemberNumberPosition")}
											field_name="number"
											//validationFunction={AnyValidator}
											disabled={false}
											input_type="number"
											resetError={() => {}}
											error={props.errors}
											message=""
											placeholder="#"
										/>
                              <div className="member-rol__positions__optional-label">
                              {t("teams.teamsOptionalLabel")}
                              </div>

									</div>
                           
								</div>
							)}
						</div>
					</div>
					<div className="member-rol__positions__button">
						<PrimaryButtonComponent
							type="submit"
							disabled={false}
							onClick={() => {}}
							className="end-tournament__form__submit"
						>
							<span>{t("teams.teamsAddMemberButtonLabel")}</span>
						</PrimaryButtonComponent>
					</div>
				</FormComponent>
			</div>
		</Modal2Component>
	);
}

export default LeagueTeamMemberRolModalComponent;
