// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './SelectFieldComponent.styles.css';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// i18n
import { withTranslation } from 'react-i18next';


class SelectFieldComponent extends React.Component {
     
    render() {
        
        const field_name = this.props.field_name;

        return (

            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    // Backend Errors

                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }
                    
                    return (
                        <div className= { "select-field " + ( this.props.className ? this.props.className : '' )  }>
                            { this.props.label && <label className="select-field-label" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <Select 
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                variant='standard'
                                onClick={ this.props.onClick } 
                                onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined} 
                                className={ 
                                    'select-field-input ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                }
                                { ...data.field }
                                placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                displayEmpty
                                defaultValue={this.props.defaultValue}
                                MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        onScroll: (event ) => {
                                          
                                        }
                                    },
                                    style: { maxHeight: 600 },                                    
                                  }}
                            >
                                <MenuItem 
                                    disabled
                                    onClick={ () => data.field.value = '' }
                                    key="-" 
                                    value="">
                                        <span className='placeholder'>{ ( this.props.placeholder ? this.props.placeholder : '-' ) }</span>
                                </MenuItem>
                                { this.props.options.map( option => {
                                    return (
                                        <MenuItem
                                        onClick={ () => data.field.value = option.value }
                                        key={ option.value } // FIXME: la Key deberia ser ser id de map y no el value, ya que puede que los mismo items compartan el valor
                                        value={ option.value }
                                        >
                                            {option.flag_icon &&
                                                <img src={option.flag_icon} alt="" className="select-field-icon"/>
                                            }
                                            { option.content }    
                                        </MenuItem>
                                    )
                                } )} 
                            </Select>
                            <p className={`select-field__message ${data.form.errors[ field_name ] ? 'error' : ''}`}>{ data.form.errors[ field_name ] ? this.props.t('requiredValidator') : this.props.message }</p>
                        </div>
                    )

                } }
            </Field>
            
        );
    }
}

// Types
SelectFieldComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
}
export default withTranslation('validators')(SelectFieldComponent);
