// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-comments {
    width: 100%;
}

.input-comments__input{
    border-radius: 18px;
    background: var(--new-background-color);
    display: flex;
    padding: 10px 12px;
    align-items: center;
    border: none;
    height: 40px;
    width: 100%;
    resize: none;
    overflow: hidden;
}




.input-with-button {
    /* border: 2px solid var(--basic-gray-color); */
    display: grid;
    gap: var(--space-and-half);
    grid-template-columns: 1fr auto;
    height: var(--space-x3);
    background: var(--new-input-background-color);
    border-radius: 20px;
}
.input-with-button__input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    margin-left: var(--space-half);
    resize: none;
    overflow: hidden;
    padding-top: 14px;
    color: var(--primary-dark-color) !important;
}

.input-with-button__button.baloa-username {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: var(--font-bold);
    color: var(--new-green-color);
    background: none;
    border: none;
    outline: none;
    padding: 0 var(--space);
    border-radius: 20px;    
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/input-comments/InputCommentsComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,uCAAuC;IACvC,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;;;;AAKA;IACI,+CAA+C;IAC/C,aAAa;IACb,0BAA0B;IAC1B,+BAA+B;IAC/B,uBAAuB;IACvB,6CAA6C;IAC7C,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;IAC7B,6BAA6B;IAC7B,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".input-comments {\n    width: 100%;\n}\n\n.input-comments__input{\n    border-radius: 18px;\n    background: var(--new-background-color);\n    display: flex;\n    padding: 10px 12px;\n    align-items: center;\n    border: none;\n    height: 40px;\n    width: 100%;\n    resize: none;\n    overflow: hidden;\n}\n\n\n\n\n.input-with-button {\n    /* border: 2px solid var(--basic-gray-color); */\n    display: grid;\n    gap: var(--space-and-half);\n    grid-template-columns: 1fr auto;\n    height: var(--space-x3);\n    background: var(--new-input-background-color);\n    border-radius: 20px;\n}\n.input-with-button__input {\n    width: 100%;\n    height: 100%;\n    background: none;\n    border: none;\n    outline: none;\n    margin-left: var(--space-half);\n    resize: none;\n    overflow: hidden;\n    padding-top: 14px;\n    color: var(--primary-dark-color) !important;\n}\n\n.input-with-button__button.baloa-username {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    font-weight: var(--font-bold);\n    color: var(--new-green-color);\n    background: none;\n    border: none;\n    outline: none;\n    padding: 0 var(--space);\n    border-radius: 20px;    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
