// Axios
import axios from 'axios';
//import { setupCache } from 'axios-cache-adapter'

/* eslint-disable */
/* const cache = setupCache({
    maxAge: 2 * 60 * 1000,
    exclude: {
        // {Array} List of regular expressions to match against request URLs.
        paths: [
            //Profile
            /profiles/, 
            //Users
            /users/,
            //Notifications
            /notifications/,
            //Feeds
            /home-feed/,
            //Suggestions
            /profile-suggestions/,
            /page-suggestions/,
            /friends-suggestions/,
            /league-suggestions/,
            //Posts
            /posts/,
            //Followers
            /followers/,
            /following/,
        ],
        // paths: [/profile-suggestions/, /page-suggestions/, /friends-suggestions/, /league-suggestions/],
        // {Boolean} Exclude requests with query parameters.
        query: false,
        // {Function} Method which returns a `Boolean` to determine if request
        // should be excluded from cache.
        filter: ['post', 'patch', 'put', 'delete'],
        // {Array} HTTP methods which will be excluded from cache.
        // Defaults to `['post', 'patch', 'put', 'delete']`
        // Any methods listed will also trigger cache invalidation while using the default `config.invalidate` method.
        //
        // Note: the HEAD method is always excluded (hard coded).
        // the OPTIONS method is ignored by this library as it is automatically handled by browsers/clients to resolve cross-site request permissions
        //methods: ['post', 'patch', 'put', 'delete']
      },
  }) */
/* eslint-enable */



/* const axiosCacheAdapter = require('axios-cache-adapter');

const cache = axiosCacheAdapter.setupCache({
    maxAge: 2 * 60 * 1000,
}); */

/* const instance = axios.create({
    //baseURL: 'https://preprod.baloa.com/api',
    //adapter: cache.adapter
}); */

const instance = axios.create();
//instance.defaults.adapter = cache.adapter;

const interceptor = ( store ) => {
    // Add a request interceptor
    instance.interceptors.request.use(function (config) {
        if(store.getState().profile.page_active && !store.getState().profile.ovewrite_interceptor){
            config.headers['Baloa-Page'] = store.getState().profile.page_active.id;
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
};

export default { interceptor, instance, };