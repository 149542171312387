// React
import React, { useState } from 'react';

// Material UI
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

import CurrencyFormat from 'react-currency-format';

// Assets
import help_icon from 'assets/icons/help-resume-icon.svg';

// Styles
import './InscriptionsFeeComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

//i18
import { withTranslation } from 'react-i18next';

///Components
import WompiPaymentFormComponent from '../wompi-payment-form/WompiPaymentFormComponent';

function InscriptionsFeeComponent ( props ) {

    const [ value, setValue ] = useState(0);
    const [ totalValue, setTotalValue ] = useState(props.registration_price_per_team);

    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            color: 'var(--primary-dark-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-quarter)',
            fontFamily: 'var(--secondary-font)',
            boxShadow: '0px 1px 4px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
        },
    }));
    const classes = useStyles();

    const handleIncreaseValue = () => {
        let increased = value + 1;
        setValue( increased );
        props.setPlayersQuantity(increased);
        let calc = Number(props.registration_price_per_player) * increased;
        setTotalValue(calc + Number(props.registration_price_per_team))
    }

    const handleDecreaseValue = () => {
        let decreased = value - 1;
        if(value > 0){
            setValue( decreased );
            props.setPlayersQuantity(decreased);
            let calc = Number(props.registration_price_per_player) * decreased;
            setTotalValue(calc + Number(props.registration_price_per_team))
        }
    }

    return(
        <div className='inscription-fee__container' >
            <div className='inscription-fee__team-player' >
                <div className='inscription-fee__team'>
                    <div className='left-col' >
                        <span className='icon-team' />
                        {!isMobile && <div className='baloa-table' >{props.t('inscriptionModal.title')}</div>}
                        {isMobile && 
                        <div className='right-col'>
                            <div className='baloa-table' >{props.t('inscriptionModal.title')}</div>
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} suffix={' ' + (props.currency ?? '')}
                                />
                            </div>
                        </div>
                    }
                    </div>
                    {!isMobile &&
                        <div className='right-col'>
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} suffix={' ' + (props.currency ?? '')}
                                />
                            </div>
                        </div>
                    }
                </div>
                {(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type ===  'Paid' ) && 
                    <div className='inscription-fee__player' >
                        <div className='left-col' >
                            <span className='icon-player' />
                            <div className='baloa-table' >{props.t('inscriptionModal.inscriptionPerPlayer')}</div>
                            <Tooltip 
                                title={props.t('inscriptionModal.tooltip')}
                                arrow
                                placement="right-end"
                                classes={{ tooltip: classes.customMessage }}>
                                <img src={help_icon} alt="" />
                            </Tooltip>
                        </div>
                        {!isMobile && 
                            <div className='right-col' >
                                <div className='baloa-username'><CurrencyFormat value={props.registration_price_per_player} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')}/></div>
                                <div className='inscription-fee__increase-decrease__value'>
                                    <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleDecreaseValue()}}} >-</div>    
                                    <input type='number' value={value} />
                                    <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleIncreaseValue()}}} >+</div>
                                </div>
                            </div>
                        }
                </div>
                }
                {isMobile && 
                        <div className='right-col_isMobile' >
                            <div className='baloa-username'>
                                <CurrencyFormat value={props.registration_price_per_player} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} suffix={' ' + (props.currency ?? '')}/>
                            </div>
                            <div className='inscription-fee__increase-decrease__value'>
                                <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleDecreaseValue()}}} >-</div>    
                                <input type='number' value={value} />
                                <div className='btn' onClick={ () => {if(props.tournament_registration_type === 'Player_paid' || props.tournament_registration_type === 'Paid'){handleIncreaseValue()}}} >+</div>
                            </div>
                        </div>
                        }
            </div>
            <div className='inscription-fee__overall-fee' >
                <div>{props.t('inscriptionModal.totalLabel')}</div>
                <div><b><CurrencyFormat value={totalValue} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')} fixedDecimalScale={true}/></b></div>
            </div>
            {props.currency === 'COP' &&
                <WompiPaymentFormComponent
                    currency={props.currency}
                    totalValue={totalValue}
                    profile_id={props.profile_id}
                    paymentCustomer={props.tournament_team_id}
                    countryCodePrefix="+57"
                    countryIataCode="CO"
                    city={'Bogota'}
                    region={'Bogota'}
                    playersQuantity={value}
                    submitWompiForm={props.submitWompiForm}
                    resetPaymentButton={props.resetPaymentButton}
                    type_redirect={props.type_redirect}
                    profile_phone={props.profile_phone}
                    metadata={`{"tournament_team_id":"${props.tournament_team_id}","tournament_player_id":null,"payment_type":"TEAM_REGISTRATION","tournament_id":"${props.tournament_id}","total_codes":"${value}"}`}
                />
            }
        </div>
    )
}

export default withTranslation('tournament')(InscriptionsFeeComponent);