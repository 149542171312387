// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components

import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


//Styles
import './TournamentWarningNoTeamsLeagueModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentWarningNoTeamsLeagueModalComponent ( props ) {
    
    return (
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningLeagueWithNoTeams.labelTitle')}            
            className="finish_free_phase_modal medium"
         >
            <p className="modal-title">                
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningLeagueWithNoTeams.labelTitle')}
                
            </p>
            <p className="modal-text">
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningLeagueWithNoTeams.labelInfo')} 
                    
            </p>
             <div className='finish_free_phase_footer'>
                <SecondaryButtonComponent 
                    className='finish_free_phase_btn'
                    onClick={ props.onClose }
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningLeagueWithNoTeams.labelBackAction')}                    
                </SecondaryButtonComponent>
                <Link to={`/league/${props.pagename}/`}>
                    <PrimaryButtonComponent 
                        className='finish_free_phase_btn'
                        onClick={()=> {
                            //props.finishTournamentPhase(props.phase_id);
                            props.onClose();
                        }}
                    >                    
                        {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningLeagueWithNoTeams.labelAction')}                        
                    </PrimaryButtonComponent>
                </Link>
             </div>
        </ModalComponent>
    )


}

export default withTranslation('league')(TournamentWarningNoTeamsLeagueModalComponent);
//league/TestEquiposLigas/ {`/league/${props.pagename}/`}