import React from 'react';

// Globals
import {api_url, wompi_public_key } from "config/GlobalData";

// Styles
import './WompiPaymentFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components

function WompiPaymentFormComponent ( props ) {

    const [state, setState] = React.useState( // eslint-disable-line
        {
            submit_button_ref: null,
        }
    );

    React.useEffect(() => { 
        if(props.submitWompiForm){
            state.submit_button_ref.click()
        }
     }, [props.submitWompiForm]);// eslint-disable-line    

    return(
        <form action="https://checkout.wompi.co/p/" method="GET">
            {/* OBLIGATORIOS */}
            <input type="hidden" name="public-key" value={wompi_public_key} />
            <input type="hidden" name="currency" value={props.currency} />
            <input type="hidden" name="amount-in-cents" value={`${props.totalValue}00`} />
            <input type="hidden" name="reference" value={props.paymentCustomer} />
            {/* OPCIONALES */}
            <input type="hidden" name="redirect-url" value={`${api_url}/success/?object_id=${props.paymentCustomer}&quantity=${props.playersQuantity}&profile=${props.profile_id}&redirect=${props.type_redirect}&mobile=False`} />
            <input type="hidden" name="customer-data:phone-number-prefix" value={props.countryCodePrefix} />
            <input type="hidden" name="shipping-address:address-line-1" value={`${props.profile_id}`} />
            <input type="hidden" name="shipping-address:address-line-2" value={`${props.metadata}`} />
            <input type="hidden" name="shipping-address:country" value={props.countryIataCode} />
            <input type="hidden" name="shipping-address:phone-number" value={props.profile_phone? props.profile_phone:'3005555555'} />{/** TODO: TRAER TELEFONO DE PERFIL SI EXISTE SI NO (3005555555) */}
            <input type="hidden" name="shipping-address:city" value={props.city} />
            <input type="hidden" name="shipping-address:region" value={props.region} />

{/**
 * metadata
 * 
 * {
 * "tournament_team_id": string || null 
 * "tournament_player_id": string || null,
 * "payment_type": "PLAYER_REGISTRATION" || "TEAM_REGISTRATION" || null,
 * "tournament_id": string,
 * "total_codes": string number,
 * }
 * 
 */}


            <input 
                style={{ display: 'none' }}
                type="submit"
                ref={button_submit => state.submit_button_ref = button_submit}
                value={"Pagar con Wompi"}
                onClick={()=>{props.resetPaymentButton()}}
            />
        </form>
    )
}

export default withTranslation('tournament')(WompiPaymentFormComponent);