// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//Actions
import {searchPreRegisteredMembers, loadClaimedAccountProfile} from 'app/authentication/actions/LoginActions';


//action creators
import { changeSignUpStatus, setClaimedAccountProfileAction, cleanPreRegisteredMembersAction, cleanClaimedAccountProfileAction } from 'app/authentication/actions/LoginActionsCreators';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueSearchMemberTeamComponent from 'app/leagues/components/league-search-member-team/LeagueSearchMemberTeamComponent';

// Styles
import './SignUpClaimAccountComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';
import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import {isMobile} from 'react-device-detect';

class SignUpClaimAccountComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            memberSelected: null,
            step: 1,
        };
    };
    
    handleSelectMember = async (item) => {
        await this.props.loadClaimedAccountProfile(item.id)
        //await this.props.setClaimedAccountProfileAction(item);//Move to action after load profile
        this.setState({memberSelected:item, step: 2});
        this.props.cleanPreRegisteredMembersAction({});
    }

    handleClearMemberTeam = (item) => {
        this.setState({memberSelected:null})
    }

    handleCleanTeamMemberSearch = () =>{
        this.props.cleanPreRegisteredMembersAction({});
    }
    
    render = () => {         
        return (
            <React.Fragment>
                <div className="signup-claim-account__container">
                    <div className="signup-claim-account__container__left-col">
                        <Link to="/">
                            { !isMobile 
                                ?
                                <img className="signup-header__image" src={baloa_logo} alt=""/>
                                :
                                <img className="signup-header__image" src={baloa_green_logo} alt=""/>
                            }
                        </Link>
                    </div>
                    <div className={`signup-claim-account__container__right-col ${isMobile? 'scroll-parent': ''}`}>
                        {this.state.step === 1 &&
                            <React.Fragment>
                                <div className='signup-claim-account__container__right-col__title'>
                                    <span className='icon-arrow-left baloa-headline-1' onClick={() => {this.handleCleanTeamMemberSearch(); this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] ); }}/>
                                    <div className='baloa-headline-1 ' >{this.props.t('claimAccount.cAccTitle')}</div>
                                </div>
                                <div className="signup-claim-account__text baloa-normal"dangerouslySetInnerHTML={{
                                    __html: this.props.t('claimAccount.cAccMessage')
                                }}></div>

                                <LeagueSearchMemberTeamComponent
                                    handleSearchMember={ (word)=>{this.props.searchPreRegisteredMembers(word);} }
                                    profiles={ this.props.state.signup.pre_registered_members }
                                    onSelectMemberTeam={ (item)=>{this.handleSelectMember(item)}}
                                    handleCleanMemberSearch={()=>{this.handleCleanTeamMemberSearch()}}
                                    set_type={'member'}
                                    
                                    modal_search_placeholder={this.props.t('claimAccount.cAccSearchPlaceholder')}
                                    id={'search-member'}
                                    removeIconSearch={false}
                                    removeWordLenghtValidation={false}
                                />
                                
                                
                            </React.Fragment>
                        }
                        {this.state.step === 2 &&
                            <React.Fragment>
                                <div className='signup-claim-account__container__right-col__title'>
                                    <span className='icon-arrow-left baloa-headline-1' onClick={() => {this.setState( { step: 1} ); this.props.cleanClaimedAccountProfileAction({})}}/>
                                </div>
                                <div className={`${isMobile? 'scroll': ''}`}>
                                    <div className='signup-claim-account__info-msg'>
                                        <span className='hubicon-info'></span>
                                        <div className='baloa-names'>{
                                            (this.props.state.signup.claim_account_profile?.phone || this.props.state.signup.claim_account_profile?.has_email)?
                                            this.props.t('claimAccount.cAccPreRegisterMsg')
                                            :
                                            this.props.t('claimAccount.cAccNoRegisterMsg')
                                        }</div>
                                    </div>
                                    <div className='signup-claim-account__player-info'>
                                        <div className='signup-claim-account__player-info__photo'>
                                            {this.props.state.signup.claim_account_profile?.photo
                                                ? <img src={this.props.state.lineups.claim_account_profile.photo} alt={'photo_team'} />
                                                : <span className="icon-ball" color={getRandonIconBallColor()} />
                                            }
                                        </div>
                                        <div >
                                            <div className='baloa-hyperlink'>
                                                {`${this.props.state.signup.claim_account_profile?.first_name} ${this.props.state.signup.claim_account_profile?.last_name}`}
                                            </div>
                                            <div className='baloa-table'>
                                                {'@' + this.props.state.signup.claim_account_profile?.username}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='signup-claim-account__user-data'>
                                        {this.props.state.signup.claim_account_profile?.birthday &&
                                            <div className='signup-claim-account__user-data__date'>
                                                <div className='baloa-names subtitle'>{this.props.t('claimAccount.cAccBDateLbl')}</div>
                                                <div className='baloa-names bdate'>{this.props.state.signup.claim_account_profile?.birthday}</div>
                                            </div>
                                        }
                                        <div className='signup-claim-account__historic-columns'>
                                            <div className={`signup-claim-account__historic-columns__column ${isMobile? '': 'scroll-parent'}`}>
                                                <div className='baloa-names subtitle'>{this.props.t('claimAccount.cAccTeamsMsg')}</div>
                                                <div className={`${isMobile? '': 'scroll'}`}>
                                                {this.props.state.signup.claim_account_profile?.teams_participate?.map(team =>
                                                    <div key={team.id} className='signup-claim-account__historic-columns__teams'>
                                                        <div className='signup-claim-account__historic-columns__teams__photo'>
                                                            {team.logo
                                                                ? <img className="" src={team.logo} alt={'photo_team'} />
                                                                : <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className='baloa-table'>{team.name}</div>
                                                            <div className='signup-claim-account__historic-columns__teams__tags'>
                                                                <div className='signup-claim-account__historic-columns__team__tag'>{this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${team.team_gender}`)}</div>
                                                                <div className='signup-claim-account__historic-columns__team__tag'>{this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabTeams.${team.category}`)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                                </div>
                                            </div>
                                            <div className={`signup-claim-account__historic-columns__column ${isMobile? '': 'scroll-parent'}`}>
                                                <div className='baloa-names subtitle'>{this.props.t('claimAccount.cAccTournamentsMsg')}</div>
                                                <div className={`${isMobile? '': 'scroll'}`}>
                                                {this.props.state.signup.claim_account_profile?.tournaments_participate?.map(tournament =>
                                                    <div key={tournament.tournament_id} className='signup-claim-account__historic-columns__tournaments'>
                                                        <div className='signup-claim-account__historic-columns__tournaments__photo'>
                                                            {tournament.tournament_logo
                                                                ? <img className="" src={tournament.tournament_logo} alt={'tournament_photo'} />
                                                                : <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className='baloa-table'>{tournament.tournament_name}</div>
                                                            <div className='signup-claim-account__historic-columns__tournaments__tags'>
                                                                <div className='signup-claim-account__historic-columns__tournaments__tag'>{this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${tournament.tournament_type}`)}</div>
                                                                <div className='signup-claim-account__historic-columns__tournaments__tag'>{this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${tournament.tournament_gender}`)}</div>
                                                                <div className='signup-claim-account__historic-columns__tournaments__tag'>{this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabTeams.${tournament.tournament_category}`)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-claim-account__buttons">
                                    <SecondaryButtonComponent disabled={false} onClick={() => { this.setState( { step: 1} ) }} className="signup-claim-account__buttons__back">
                                        <span>{this.props.t('claimAccount.cAccCancelBtnLbl')}</span>
                                    </SecondaryButtonComponent>
                                    <PrimaryButtonComponent disabled={false} onClick={() => { this.props.changeSignUpStatus( SIGNUP_STATUS['CLAIM_ACCOUNT_OPTION']) }} className="signup-claim-account__buttons__forward">
                                        <span>{this.props.t('claimAccount.cAccClaimBtnLbl')}</span>
                                    </PrimaryButtonComponent>
                                </div>
                            </React.Fragment>
                        }
                        
                                                 
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        searchPreRegisteredMembers: async (member) => {
            await dispatch( searchPreRegisteredMembers(member) );
        },
        setClaimedAccountProfileAction: (member) => {
            dispatch( setClaimedAccountProfileAction(member) );
        },
        cleanPreRegisteredMembersAction: (data) => {
            dispatch(cleanPreRegisteredMembersAction(data));
        },
        cleanClaimedAccountProfileAction: (data) => {
            dispatch(cleanClaimedAccountProfileAction(data));
        },
        loadClaimedAccountProfile: async (profile_id) => {
            await dispatch( loadClaimedAccountProfile(profile_id) );
        },
    }
}

export default compose(
    withTranslation(["signUp","league"]),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpClaimAccountComponent);
