// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextField from '@mui/material/TextField';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './ReportModalComponent.styles.css';
import { withStyles } from '@mui/styles';

// i18n
import { withTranslation } from 'react-i18next';

function ReportModalComponent ( props ){

    const formInitialValues = {
        comment: '',
    } 

    const CommentAreaTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: '#2a302a',
            },          
            '& .MuiOutlinedInput-root': {
                '& fieldset': {             
                    border: 'var(--input-field-border)',
                },      
            },
        },
      })(TextField);

      function info(values){        
        if(values.comment !== ""){
            props.reportPostById(props.post_id,values.comment);
        }else{
            props.reportPostById(props.post_id,null);
        }
      }

    return(

        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('postReport.reportPost.title')} 
        >
         
        <div className="report-post scroll-parent">

            <FormComponent
                formInitialValues={formInitialValues} 
                onSubmit={( values, actions ) => {                     
                    info(values);
                    props.onClose();
                    props.openAcceptReport( true );
                }}
                className="report-post__form form scroll-parent"
                functionDisabled={()=>{}}
            >
                <div className="report-post__form__element">
                    <div className="report-post__form__element__title">
                        {/* <p className="baloa-headline-6">
                           {props.t('postReport.reportPost.textQuestion')}
                        </p> */}
                        <p className="baloa-table">  
                        {props.t('postReport.reportPost.textReason')}
                        </p>
                    </div>

                    <CommentAreaTextField
                        id="comment"
                        hiddenLabel
                        multiline
                        rows={4}
                        // defaultValue={props.t('postReport.reportPost.textReasons')}
                        placeholder={props.t('postReport.reportPost.textReasons')}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        className="report-post__form__element__input"
                    />
                </div>
                <div className="report-post__form__actions form__actions">
                    <SecondaryButtonComponent 
                        className={"report-post__form__actions__cancel-btn"}
                        type="button"
                        disabled={false} 
                        onClick = {() => {props.onClose()}}
                    >
                        <span>{props.t('postReport.reportPost.btnCancel')}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick = {() => {}}
                    >
                        <span>{props.t('postReport.reportPost.btnSend')}</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        
        </div>
    
        </Modal2Component>
    )

}

export default withTranslation('post')(ReportModalComponent);

//export default ReportModalComponent;