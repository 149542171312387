// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TournamentGroupsComponent from 'app/leagues/components/tournament-groups/TournamentGroupsComponent';

// Styles
import './TournamentAddTeamToGroupModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentAddTeamToGroupModalComponent( props ){

    function verifyGroupTeams () {
        let groups_teams_validation = false;
        props.tournament_active_phase?.groups.forEach((group, index) => {
            if (group && group.teams?.length < 2) {
                groups_teams_validation = true;
            }
        });
        return groups_teams_validation;        
    }

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabTeams.labelTitle')}
            className="medium add-team__modal"
        >
            <div className="add-team form scroll-parent">            
                <div className="add-team__content scroll">
                    <TournamentGroupsComponent                        
                        groups={props.tournament_active_phase?.groups}
                        teams={props.tournament_teams ?? []}
                        tournament_active_phase={props.tournament_active_phase}
                        handleAddTeamToGroup={props.handleAddTeamToGroup}
                        handleDeleteTeamFromGroup={props.handleDeleteTeamFromGroup}
                        handleActivePhaseCreateState={props.handleActivePhaseCreateState}
                        active_phase_groups_create={props.active_phase_groups_create}
                        handleCreateNewAtomaticGroups={(filter_state) => props.handleCreateAutomaticGroupPhases(props.tournament_active_phase.id, filter_state)}
                        tournament_criteria_tie_breaking={props.tournament_criteria_tie_breaking}
                        tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                        select_groups_teams={true} // Show - Hidden Buttons on Section Groups                        
                        handle_team_filter={(filter_type) => { props.handle_team_filter(props.tournament_active_phase?.id, filter_type)}}
                        isCreatePhase={true}
                        handleDeleteGroupPhase={props.handleDeleteGroupPhase}
                        handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                        handleNumberClassified={props.handleNumberClassified}
                        handleCleanAllGroups={props.handleCleanAllGroups}
                        clickSourseCreatePhase={props.click_source_create_phase}
                        stepEditPhase={false}
                        deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                        handleOnAddAllTeamsToGroup={props.handleOnAddAllTeamsToGroup}
                        teams_filter_type={props.teams_filter_type}
                    />
                </div>
                <div className="add-team__actions form__actions">
                    <PrimaryButtonComponent 
                        type="button"
                        disabled={false} 
                        onClick={ () => {
                            if(verifyGroupTeams()) {
                                props.handleOnPhaseValidation(`createPhaseType${props.tournament_active_phase?.phase_type}`);
                            } else {
                                props.onClose();
                            }
                        } } 
                        className="aptitudes__actions__submit"
                    >
                        <span>{props.t('options.tournaments.pages.newTournament.saveButton')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentAddTeamToGroupModalComponent);