
export const changeNotificationsModalStateAction = ( new_state ) => {
    return { type: "NOTIFICATIONS:CHANGE_MODAL_STATE", new_state }
}

export const loadNotificationsAction = ( data ) => {
    return { type: "NOTIFICATIONS:LOAD_NOTIFICATIONS", data }
}

export const loadNotificationsNextAction = ( data ) => {
    return { type: "NOTIFICATIONS:LOAD_NOTIFICATIONS_NEXT", data }
}
