/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Redux
import { connect } from 'react-redux';

// Components
//import FeedComponent from 'shared/components/feed/FeedComponent';
import FeedComponentV2 from 'shared/components/feed/FeedComponentV2';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Actions
import { followPage } from 'app/pages/actions/PageActions';

// Styles
import './PageFeedComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

// Page Feed Component:
//   Load and render Posts and handles their actions.

class PageFeedComponent extends React.Component {  
    
    newGroupPagePost = (  ) => {
        this.props.changeHomeEditPostModal( false, null ); //Validar el llamado del primer metodo (copiado de header_auth)
        this.props.changeHomeNewPostModal( true );
        this.props.changeNewGroupPagePost( true );
    }

    newLeaguePagePost = (  ) => {
      this.props.changeHomeEditPostModal( false, null ); 
      this.props.changeHomeNewPostModal( true ); 
     // this.props.changeNewHomePostAction( true ); 
  }

    render() {
        return (
            <div 
            className = { 
                "page-feed " + ( this.props.className ? this.props.className : '' ) 
            }
            >
                {this.props.state.page?.page.page_type == "group" &&
                    <div>
                        <PrimaryButtonComponent 
                            type="button"
                            disabled={  false } 
                            onClick={ ()=>{ this.newGroupPagePost() } }
                            className="page-feed__post-button"
                        >                        
                            <span class="icon-new page-feed__post-button__icon"></span>
                            <span className="page-feed__post-button__text">{this.props.t('groups.buttons.post')}</span>                        
                        </PrimaryButtonComponent>
                    </div>
                }

                {(this.props.state.page?.page.page_type == "league" && (this.props.state.page?.page.is_admin === true || permissionsController(this.props.state.page?.page.roles, ['CONTENTAD'])) ) &&
                  
                    <div>
                        <PrimaryButtonComponent 
                            type="button"
                            disabled={  false } 
                            onClick={ ()=>{ this.newLeaguePagePost() } }
                            className="page-feed__post-button"
                        >                        
                            <span class="icon-new page-feed__post-button__icon"></span>
                            <span className="page-feed__post-button__text">{this.props.t('league:buttons.postBtn')}</span>                        
                        </PrimaryButtonComponent>
                    </div>
                }                
                
                {/* No Posts. Message no Posts on this Page */}
                { this.props.state.page?.posts?.length === 0 ? 
                    <React.Fragment>
                        <p className="page-feed__suggestions-title baloa-headline-4">
                            {this.props.t('tabs.post.text')}
                        </p>
                        <p className="page-feed__suggestions-title-sm baloa-subtitle-1">
                            {this.props.t('tabs.post.text')}
                        </p>
                    </React.Fragment>
                    : 
                    /*<FeedComponent 
                        className="page-feed__feed"
                        is_logged_in = { this.props.state.signup.logged_in }
                        posts = { this.props.state.page.posts  }
                        handleClap = { ( post ) => this.props.handleClap( post ) }
                        handleOnLoadMorePosts = { this.props.handleOnLoadMorePosts }
                        handleOnPageClick = { this.props.handleOnLoadMorePosts } 
                        handleOnMessageIconClick = { ( post ) => this.props.handleOnMessageIconClick( post ) } 
                        handleOnCommentsClick = { ( post ) => this.props.handleOnCommentsClick( post ) }
                        //handleOnComment = { ( post, comment ) => this.props.handleOnComment( post, comment ) }
                        handleOnComment = { ( post, comment ) => this.props.handleOnComment( post, comment ) }
                        handleShareMenuClick  = { ( is_open ) => this.props.handleShareMenuClick( is_open ) }
                        handleOnCopyLinkClick = { ( post ) => this.props.handleOnCopyLinkClick( post ) }
                        onCloseClapsModal={() => { this.props.onCloseClapsModal( true ) }}
                        loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                    />*/
                    <FeedComponentV2 
                        className="page-feed__feed"
                        is_logged_in = { this.props.state.signup.logged_in }
                        posts = { this.props.state.page.posts  }
                        show_group = { false }
                        handleClap = { ( post ) => this.props.handleClap( post ) }
                        handleOnLoadMorePosts = { this.props.handleOnLoadMorePosts }
                        handleOnPageClick = { this.props.handleOnLoadMorePosts } 
                        handleOnMessageIconClick = { ( post ) => this.props.handleOnMessageIconClick( post ) } 
                        handleOnCommentsClick = { ( post ) => this.props.handleOnCommentsClick( post ) }
                        //handleOnComment = { ( post, comment ) => this.props.handleOnComment( post, comment ) }
                        handleOnComment = { ( post, comment, pagename, page_id ) => this.props.handleOnComment( post, comment, pagename, page_id ) }
                        openReportPostModal={ this.props.openReportPostModal }
                        handleReportPost={ this.props.handleReportPost }
                        openBlockPostModal={ this.props.openBlockPostModal }
                        handleBlockProfile={ this.props.handleBlockProfile }
                        handleBlockPage={this.props.handleBlockPage}
                        handleShareMenuClick  = { ( is_open ) => this.props.handleShareMenuClick( is_open ) }
                        handleOnCopyLinkClick = { ( post ) => this.props.handleOnCopyLinkClick( post ) }
                        onCloseClapsModal={() => { this.props.onCloseClapsModal( true ) }}
                        loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                        me_photo={ this.props.me_photo }
                        handleOnDeletePostClick = { ( post ) => this.props.handleOnDeletePostClick( post ) }
                        handleOnAuthorClick = { this.props.handleOnAuthorClick }
                        profile = { this.props.profile }
                        user = { this.props.user }
                        is_group = { this.props.is_group }
                        page = { this.props.state.page }

                        menu_is_open = {this.props.menu_is_open}    
                        sendReaction= { this.props.sendReaction }
                        overrideReaction= { this.props.overrideReaction }
                        handleReactionsDetails = { this.props.handleReactionsDetails }
                        loadPostReactionsById = { this.props.loadPostReactionsById }
                        deleteReaction = { this.props.deleteReaction }
                        followPostProfile = { this.props.followPostProfile }                            
                        unfollowPostProfile = { this.props.unfollowPostProfile }                            
                        followPostPage = { this.props.followPostPage }                            
                        unfollowPostPage = { this.props.unfollowPostPage }

                        handleOnClickQuizAnswer={ this.props.handleOnClickQuizAnswer }
                        handleOnClickPollAnswer ={ this.props.handleOnClickPollAnswer }
                        handleOnQuoteIconClick = { this.props.handleOnQuoteIconClick }
                    />
                    
                }

            </div>
        )
    }
}


// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        followPage: ( page ) => {
            return dispatch( followPage( page ) )
        }
    }
}

// Exports

export default compose(
    withTranslation(["page","league"]),
    connect(mapStateToProps, mapDispatchToProps)
)(PageFeedComponent);


