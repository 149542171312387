/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { connect } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import {Helmet} from "react-helmet";

// Actions
import {
    clapPost,
    loadPostDetailById,
    commentPostById,
    replyCommentPostById,
    loadPostCommentsById,
    loadReplyPostCommentsById,
    deleteComment,
    clapCommentById,
    loadPostCommentsNext,
    sendReaction, 
    overrideReaction, 
    loadPostReactionsById,
    loadPostReactionsNext,
    loadPostReactionsByReactionType,
    deleteReaction,
    deletePost,
    reportPostById,
    sendCommentReaction,
    deleteCommentReaction,
    overrideCommentReaction,
    loadPostCommentReactionsById,
    loadPostCommentReactionsNext,
    loadPostCommentReactionsByReactionType,
    followPostProfile,
    unfollowPostProfile,
    followPostPage,
    unfollowPostPage,
} from 'app/posts/actions/PostActions';
import { 
    changePostReactionsModalStateAction, 
    changePostIsFollowingStateAction,
    changeReportPostModalStateAction,
    changeBlockPostModalStateAction,
    changeAcceptReportPostModalStateAction,
    changePostCommentReactionsModalStateAction,
    changePostIsFollowingStateFromReactionTypeAction,
 } from 'app/posts/actions/PostsActionsCreators';
import { overwriteInterceptorAction } from 'app/profiles/actions/ProfileActionsCreators';
import { followProfile, unfollowProfile, blockProfile } from 'app/profiles/actions/ProfileActions';
import { changeHomeEditPostModal } from 'app/home/actions/HomeActionsCreators';
import { blockPage } from 'app/pages/actions/PageActions';
import {changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';


// Styles
import './PostDetailView.styles.css';

// Assets
import options_icon from 'assets/icons/options-icon.svg';
//import message_icon from 'assets/icons/message-icon.svg';
//import share_icon from 'assets/icons/share-icon.svg';


// Components
import InputWithButtonComponent from 'shared/components/input-with-button/InputWithButtonComponent';
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';
import ButtonMenuComponent from 'app/posts/components/button-menu/ButtonMenuComponent';
import ReactionsModalComponent from 'app/posts/components/reactions-modal/ReactionsModalComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import ReportModalComponent from 'app/posts/components/report-modal/ReportModalComponent';
import BlockModalComponent from 'app/posts/components/block-modal/BlockModalComponent';
import AcceptReportModalComponent from 'app/posts/components/acceptance-modal/AcceptReportModalComponent';
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';
import ShowPostFormationFieldComponent from 'shared/components/show-post-formation/ShowPostFormationFieldComponent';



// Functions
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import { permissionsController } from 'shared/functions/PemissionsController';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// Material UI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';

class PostDetailView extends Component {
    formInitialValues = {
        caption: "",
    }

    constructor(props) {
        super(props);
        this.post_id = this.props.match.params.post_id;
        this.videoRef = React.createRef();
    }

    state = {
        edit_element: null,
        // user_menu_is_open: false,
        play: false,
        audio: false,
        video_ref: null,
        // selected_comment: null,
        show_reply_coment: [],
        state_comments_update: true,
        anchorEl: null,
        selectedIndex: 0,
        posterId: null,
        posterName: null,
        menu_open: null,
        options: [],
        caption: null,
        menuOpen: false,
        anchorReactions: null,
        reportMenuOpen: false,
        block_profile: undefined,
        block_page: undefined,
        anchorEdit: null,
        iconColor: null,
        show_reply_coment2: [],//Input para comentarios de tercer nivel
        anchorCommentReactions: null, //anchor para menu racciones de segundo nivel
        show_comment_menu_reactions:[], //open para menu racciones de segundo nivel
        anchorRCommentReactions: null, //anchor para menu racciones de tercer nivel
        show_rcomment_menu_reactions:[], ////open para menu racciones de tercer nivel
        reaction_comment_id: null, //id del comentario al que se le estan mostrando las reacciones en el modal
        shareMenuOpen: false,
        originalLanguage: true,
    }

    componentDidMount() {
        this.props.loadPostDetailById(this.post_id);
        this.props.loadPostCommentsById(this.post_id);
        this.setOptionsPages();
        this.setState({iconColor: getRandonIconBallColor()});
    }

    componentDidUpdate(prevProps) {
        if (typeof prevProps.state.profile.page_active != typeof this.props.state.profile.page_active) {
            this.props.loadPostDetailById(this.post_id);
            this.props.loadPostCommentsById(this.post_id);
        }
        if(this.state.options.length <=0 && this.props.state.profile?.loggedin?.pages?.length > 0){
            this.setOptionsPages();
        }
        
    }

    // handleOnOptionsUserClick = ( event ) => {
    //     this.setState(
    //         { 
    //             user_menu_is_open: true, 
    //             edit_element: event.currentTarget,
    //         }
    //     );
    // };

    handleOnReplyCommentClick = (index) => {
        let show_reply_coment = this.state.show_reply_coment?.slice(0);
        show_reply_coment[index] = true;
        this.setState({ show_reply_coment });
    };

    handleOnReplyCommentClose = (index) => {
        let show_reply_coment = this.state.show_reply_coment?.slice(0);
        show_reply_coment[index] = false;
        this.setState({ show_reply_coment });
    };

    handleOnReplyComment2Click = (index) => {
        let show_reply_coment2 = this.state.show_reply_coment2?.slice(0);
        show_reply_coment2[index] = true;
        this.setState({ show_reply_coment2 });
    };

    handleOnReplyComment2Close = (index) => {
        let show_reply_coment2 = this.state.show_reply_coment2?.slice(0);
        show_reply_coment2[index] = false;
        this.setState({ show_reply_coment2 });
    };

    handleUserOptionsMenuClose = () => {
        this.setState({ user_menu_is_open: false });
    };

    handleVideoPlayClick = () => {
        if (!this.state.play) {
            this.videoRef.current.play();
            this.setState({ play: true })
        }
        else {
            this.videoRef.current.pause();
            this.setState({ play: false })
        }
    }

    handleVideoMutedClick = () => {
        if (this.videoRef.current.muted) {
            this.videoRef.current.muted = false;
            this.setState({ audio: false });
        }
        else {
            this.videoRef.current.muted = true;
            this.setState({ audio: true })
        }
    }

    onEnterViewport = (index) => {
        if ((this.props.state.post.comments.length - 2) === index) {
            if (this.props.state.post.comments_next_page !== null) {
                this.props.loadPostCommentsNext(this.props.state.post.comments_next_page);
            }
        }
    }

    setAnchorEl = (e) => {
        this.setState({ anchorEl: e });
    }

    setSelectedIndex = (e) => {
        this.setState({ selectedIndex: e });
    }

    setPosterId = (id) => {
        this.setState({ posterId: id });
    }

    setPosterName = (name) => {
        this.setState({ posterName: name });
    }

    setMenuOpen = (val) => {
        this.setState({ menu_open: val });
    }

    handleClickListItem = (event) => {
        this.setAnchorEl(event.currentTarget);
        this.setMenuOpen(true);
    };

    handleMenuItemClick = (event, index, page) => {
        this.setSelectedIndex(index);

        if(index!=0){
            this.setPosterId(page[1]);
            this.setPosterName(page[0]);
        }else{
            this.setPosterId(null);
            this.setPosterName(null);
        }
        this.setMenuOpen(false);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };
    
    setOptionsPages = () => {
        let optArray = [];
        optArray.push([this.props.state.user.me.username, this.props.state.user.me.profile_id, this.props.state.user.me.photo]);
        if (this.props.state.profile?.loggedin.pages) {
            this.props.state.profile.loggedin.pages.map((p) =>
              optArray.push([p.pagename, p.id, p.photo])
             
            );
            this.setState({options: optArray});
        } 
        if (this.props.state.profile.page_active != undefined) {
            var index = optArray.findIndex(element => element[0] === this.props.state.profile.page_active.pagename);
            this.setSelectedIndex(index);
            this.setPosterName(this.props.profile?.page_active.pagename);
            this.setPosterId(this.props.state.profile.page_active.id);
        }      
        
    }
    overInter = () => {
        if(this.props.state.profile != undefined) {
            if (this.state.selectedIndex > 0) {   //post like page
                this.props.overwriteInterceptorAction(true);
            }else if( this.props.state.profile.page_active ){   //page post like profile
                this.props.overwriteInterceptorAction(true);
            }else{
                this.props.overwriteInterceptorAction(false);
            }
        }
    }

    setAnchorReactions = (e) => {
        this.setState({ anchorReactions: e });
    }

    // set Reaction
    setReaction(event){    
        this.setAnchorReactions(event.currentTarget);
    }

    handleCloseReactions = (post_id ,reaction, reaction_id, reaction_type) => {
        this.setAnchorReactions(null);
        if(reaction_id == null){
            this.props.sendReaction(post_id, reaction);
        }else if(reaction === reaction_type){
            this.props.deleteReaction(post_id, reaction_id);
        }else{
            this.props.overrideReaction(post_id, reaction, reaction_id);
        }
        
    };

    handleOnAuthorClick = ( author ) => {
        this.props.history.push(`/${ author.type_object }/${ author.identifier }`);
    }

    // Report post menu
    setReportMenuOpen = (val) => {
        this.setState({ reportMenuOpen: val });
    }

    reportMenuClick(){
        
        if(this.state.reportMenuOpen){
            this.setReportMenuOpen(false);
        }else{
            this.setReportMenuOpen(true);
        }

    }
    handleBlockProfile = ( username ) => {
        this.setState({block_profile: username});
    };

    handleBlockPage = ( pagename ) => {
        this.setState({block_page: pagename});
    }

    handleClickEditPostButton = (event) => {
        this.setState({anchorEdit: event.currentTarget});
    }

    handleCloseEditPostOption = () => {
        this.setState({anchorEdit: null});
    }

    setCommentReaction(event){   
        this.setState({ anchorCommentReactions: event.currentTarget });
    }

    handleCloseCommentReactions = (comment_id ,reaction, reaction_id, reaction_type, index) => {
        this.handleOnMenuCommentClose(index);
        this.setState({ anchorCommentReactions: null });
        if(reaction_id == null){
            this.props.sendCommentReaction(this.props.state.post.post.id, comment_id, reaction);
        }else if(reaction === reaction_type){
            this.props.deleteCommentReaction(this.props.state.post.post.id, comment_id, reaction_id);
        }else{
            this.props.overrideCommentReaction(this.props.state.post.post.id, comment_id, reaction, reaction_id);
        }
        
    };
    handleOnMenuCommentClick = (index) => {
        let show_comment_menu_reactions = this.state.show_comment_menu_reactions?.slice(0);
        show_comment_menu_reactions[index] = true;
        this.setState({ show_comment_menu_reactions });
    };

    handleOnMenuCommentClose = (index) => {
        let show_comment_menu_reactions = this.state.show_comment_menu_reactions?.slice(0);
        show_comment_menu_reactions[index] = false;
        this.setState({ show_comment_menu_reactions });
    };

    setRCommentReaction(event){   
        this.setState({ anchorRCommentReactions: event.currentTarget });
    }

    handleCloseRCommentReactions = (comment_id ,reaction, reaction_id, reaction_type, index, parent_comment_id) => {
        this.handleOnMenuRCommentClose(index);
        this.setState({ anchorRCommentReactions: null });
        if(reaction_id == null){
            this.props.sendCommentReaction(this.props.state.post.post.id, comment_id, reaction, parent_comment_id);
        }else if(reaction === reaction_type){
            this.props.deleteCommentReaction(this.props.state.post.post.id, comment_id, reaction_id, parent_comment_id);
        }else{
            this.props.overrideCommentReaction(this.props.state.post.post.id, comment_id, reaction, reaction_id, parent_comment_id);
        }
        
    };
    handleOnMenuRCommentClick = (index) => {
        let show_rcomment_menu_reactions = this.state.show_rcomment_menu_reactions?.slice(0);
        show_rcomment_menu_reactions[index] = true;
        this.setState({ show_rcomment_menu_reactions });
    };

    handleOnMenuRCommentClose = (index) => {
        let show_rcomment_menu_reactions = this.state.show_rcomment_menu_reactions?.slice(0);
        show_rcomment_menu_reactions[index] = false;
        this.setState({ show_rcomment_menu_reactions });
    };

    shareMenuClick=()=>{       
        if(this.state.shareMenuOpen){
            this.setState({ shareMenuOpen:false });
        }else{
            this.setState({ shareMenuOpen: true });
        }
    }

    handleOnCopyLinkClick = async (post) => {
        try {            
            const url = `${web_url}/api/social/posts/${post.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    changeLanguage = () => {
        if(this.state.originalLanguage){
            this.setState({originalLanguage: false});
        }else{
            this.setState({originalLanguage: true});
        }
    }

    render() {

        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: Post`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content= {`${this.props.state.post.post?.profile? this.props.state.post.post?.profile.user?.first_name +' '+ this.props.state.post.post?.profile.user?.last_name : this.props.state.post.post?.page? this.props.state.post.post?.page?.name : '' } en Baloa`} />
                    <meta property="og:description" content={`${this.props.state.post.post?.caption}`} />
                    <meta property="og:image" content={`${this.props.state.post.post?.post_image ? this.props.state.post.post?.post_image : this.props.state.post.post?.post_stream?.thumbnail? this.props.state.post.post?.post_stream?.thumbnail : web_url+'/logo_baloa_social_share.png'}`} />
                    <meta property="og:url" content={`${web_url}/posts/${this.post_id}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content={`${this.props.state.post.post?.profile? this.props.state.post.post?.profile.user?.first_name +' '+ this.props.state.post.post?.profile.user?.last_name : this.props.state.post.post?.page? this.props.state.post.post?.page?.name : '' } en Baloa`} />
                    <meta name="twitter:description" content={`${this.props.state.post.post?.caption}`} />
                    <meta name="twitter:image" content={`${this.props.state.post.post?.post_image ? this.props.state.post.post?.post_image : this.props.state.post.post?.post_stream?.thumbnail? this.props.state.post.post?.post_stream?.thumbnail : web_url+'/logo_baloa_social_share.png'}`} />
                    <meta name="twitter:url" content={`${web_url}/posts/${this.post_id}`} />
                </Helmet>

                {/* Post Detail */}

                <div className="post-detail">

                    {/* Left side */}
                    <div className="post-detail__left-container">
                    </div>

                    {/* Center */}
                    <div className="post-detail__center-container">

                        {/* Post Data */}
                        <div className="post-detail__content">                        

                            {/* Group Post header */}
                            {this.props.state.post.post?.context?.page_type == "group" &&
                                <div className="post-detail__header__group">
                                    <div
                                        onClick={ () => this.handleOnAuthorClick( this.props.state.post?.post?.context ) }
                                        className={"post-detail__header__group-photo"}>                        
                                            {this.props.state.post.post.context.photo &&
                                                <img src={this.props.state.post.post.context.photo} alt="" />
                                            }
                                            {!this.props.state.post.post.context.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            } 
                                    </div>
                                    <div className="post-detail__header__info">
                                        <div
                                            onClick={ () => this.handleOnAuthorClick( this.props.state.post?.post?.context ) }
                                            className="post-detail__header__info__group-username baloa-caption">
                                            { this.props.state.post.post.context.caption }
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Profile Post Header */}
                            { this.props.state.post?.post?.author?.type_object == "profile" && 
                                <div className="post-detail__header">

                                    {/* Profile photo */}
                                    <div
                                        onClick={ () => this.handleOnAuthorClick( this.props.state.post?.post?.author ) }
                                        className={"post-detail__header__photo"}>
                                            {this.props.state.post.post.author.photo &&
                                                <img src={this.props.state.post.post.author.photo} alt="" />
                                            }
                                            {!this.props.state.post.post.author.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            }                    
                                    </div>

                                    {/* Profile data */}
                                    <div className="post-detail__header__info">
                                        <div
                                            
                                            className="post-detail__header__info__username baloa-normal-medium"
                                        >
                                            <span onClick={ () => this.handleOnAuthorClick( this.props.state.post?.post?.author ) } >{ `${this.props.state.post?.post?.author.caption}`} </span>
                                            {this.props.state.post.post.author.is_verified &&
                                                <span class="iconb2-Verificated">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </span>
                                            } 
                                            {this.props.state.post.post.author.baloer &&
                                                <span class="iconbal-Balores">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </span>
                                            }  
                                            &nbsp;&bull;&nbsp;
                                            <span 
                                                className={"post-detail__header__info__username__follow-link baloa-hyperlink"}
                                                onClick={() => {
                                                    this.props.state.post.post.is_following ?
                                                        this.props.unfollowPostProfile(this.props.state.post.post.author.identifier, this.props.state.post.post.id)
                                                        : this.props.followPostProfile(this.props.state.post.post.author.identifier, this.props.state.post.post.id);
                                                }}
                                            >                                                
                                                {this.props.state.post.post.is_following ?
                                                    this.props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                                    :
                                                    this.props.t('post.follow')/*TODO:implementar follow/unfollow*/
                                                } 
                                            </span>
                                        </div>
                                        <div className="post-detail__header__info__date baloa-table-column">                           
                                            { `${this.props.state.post.post.author.profile_type}`}  &bull; {`${convertDateToMoment(this.props.state.post.post.created)}`}
                                            {/* {console.log(this.props.state.post.post)/**en post hace [5] horas */} 
                                        </div>
                                    </div>

                                    {/* Not Owners options */}
                                    { this.props.state.post.post.is_owner === false &&
                                        <div className="post-detail__header__options">
                                            <div 
                                                className="post-detail__header__options__option"
                                                onClick={()=>{this.reportMenuClick()}}
                                            >
                                                <img src={ options_icon } alt=""/>

                                                {this.state.reportMenuOpen && (
                                                    <ul className="post-detail__header__options__option__list">
                                                        <li>
                                                            <TextButtonComponent  
                                                                onClick={() => {
                                                                    this.props.changeReportPostModalStateAction( true );
                                                                    //props.handleReportPost(this.props.state.post.post.id); 
                                                                    this.reportMenuClick();
                                                                }}
                                                                className="post-detail__header__options__option__list__button"
                                                            >

                                                                <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                                    <span class="iconb2-report"></span>
                                                                    {this.props.t('postReport.btnReport')}
                                                                </span> 
                                                            </TextButtonComponent>
                                                        </li>
                                                        <li>
                                                            <TextButtonComponent 
                                                                onClick={() => {
                                                                    this.props.changeBlockPostModalStateAction(true);
                                                                    this.handleBlockProfile(this.props.state.post.post.author.identifier);
                                                                    this.reportMenuClick();
                                                                }}
                                                                className="post-detail__header__options__option__list__button"
                                                            >
                                                                <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                                    <span class="iconb2-block"></span>
                                                                    {this.props.t('postReport.btnBlock')} @{this.props.state.post.post.author.identifier}
                                                                </span> 
                                                            </TextButtonComponent>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {/* Owner options */}
                                    { this.props.state.post.post.is_owner &&
                                        <div className="post-detail__header__options">
                                            <div className="post-detail__header__options__option">
                                                <span 
                                                    aria-describedby='simple-popover'
                                                    // className="profile-experience__actions"
                                                    onClick={ this.handleClickEditPostButton }
                                                >
                                                    <img src={ options_icon } alt=""/>
                                                </span>    
                                            </div>
                                        </div>
                                    }

                                    {/* User options edit popover */}
                                    {/* Was necesary use open variable to close the popover div: is a special case don't use if is not necesary */}
                                    { Boolean(this.state.anchorEdit) && 
                                        <Popover
                                            id={'simple-popover'}
                                            open={ Boolean(this.state.anchorEdit) }
                                            anchorEl={ this.state.anchorEdit }
                                            onClose={this.handleCloseEditPostOption}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',    
                                            }}
                                            container= {this.state.anchorEdit ? this.state.anchorEdit : null}
                                            >
                                            <div
                                            className="post-detail__header__options__option__list__popover"
                                            >
                                                <TextButtonComponent
                                                    onClick={ ()=>{    
                                                        this.handleCloseEditPostOption();
                                                        this.props.changeHomeEditPostModal( true, this.props.state.post.post );
                                                        //props.onEditButton(props.experience_data, props.experience_data);
                                                    } }
                                                    className="post-detail__header__options__option__list__button"
                                                >
                                                    <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                        <span class="icon-edit-82"></span>
                                                        <span>{this.props.t('postEdit.btnEdit')}</span>
                                                    </span>
                                                </TextButtonComponent>
                                                <TextButtonComponent
                                                    onClick={ ()=>{ 
                                                        this.handleCloseEditPostOption();
                                                        //props.deleteAttributeOnProfile( props.state.profile.profile, props.experience_data.id )
                                                        this.props.deletePost( this.props.state.post.post );
                                                    } }
                                                    className="post-detail__header__options__option__list__button"
                                                >
                                                    <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                        <span class="icon-trash"></span>
                                                        <span>{this.props.t('postEdit.btnDel')}</span>
                                                    </span>
                                                </TextButtonComponent>  
                                            </div>
                                        </Popover>
                                    }

                                </div>
                            }

                            {/* Page Post Header */}
                            { this.props.state.post.post?.author?.type_object == "page" &&
                                <div className="post-detail__header">
                                    <div
                                        onClick={() => this.handleOnAuthorClick( this.props.state.post.post?.author ) }
                                        className={"post-detail__header__photo "}
                                    >
                                        {this.props.state.post.post?.author.photo &&
                                            <img src={this.props.state.post.post?.author.photo} alt="" />
                                        }
                                        {!this.props.state.post.post?.author.photo &&
                                            <span className={"icon-ball"} color={this.state.iconColor}></span>
                                        } 
                                    </div>
                                    <div className="post-detail__header__info">
                                        <div
                                            /* onClick={() => this.handleOnAuthorClick( this.props.state.post.post?.author ) } */
                                            className="post-detail__header__info__username baloa-normal"
                                        >
                                            <span onClick={ () => this.handleOnAuthorClick( this.props.state.post.post?.author ) } >{ `${this.props.state.post.post?.author?.caption}`} </span>
                                            {/* this.props.state.post.post?.author?.identifier */}
                                            {this.props.state.post.post.author.is_verified &&
                                                <span class="iconb2-Verificated">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </span>
                                            }
                                            {this.props.state.post.post.author.baloer &&
                                                <span class="iconbal-Balores">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </span>
                                            }    
                                            &nbsp;&bull;&nbsp;                                          
                                            <span 
                                                className={"post__header__info__username__follow-link baloa-hyperlink"}
                                                onClick={() => {
                                                    this.props.state.post.post.is_following ?
                                                        this.props.unfollowPostPage(this.props.state.post.post.author.identifier, this.props.state.post.post.id)
                                                        : this.props.followPostPage(this.props.state.post.post.author.identifier, this.props.state.post.post.id);
                                                }}
                                            >                                                 
                                                {this.props.state.post.post.is_following ?
                                                    this.props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                                    :
                                                    this.props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                                }                                                 
                                            </span>
                                        </div>
                                        <p className="post-detail__header__info__date baloa-table-column">
                                            { convertDateToMoment( this.props.state.post.post?.created ) }
                                        </p>
                                    </div>

                                    { (!this.props.state.post.post?.is_owner && !this.props.state.page?.page?.is_admin && !this.props.state.page?.page?.is_member  ) &&
                                        <div className="post-detail__header__options">
                                            <div 
                                                className="post-detail__header__options__option"
                                                onClick={()=>{this.reportMenuClick()}}
                                            >
                                                <img src={ options_icon } alt=""/>
                                                
                                                { this.state.reportMenuOpen && (
                                                    <ul className="post-detail__header__options__option__list">
                                                        <li>
                                                            <TextButtonComponent  
                                                                onClick={() => {
                                                                    this.props.changeReportPostModalStateAction(true);
                                                                    //props.handleReportPost(this.props.state.post.post.id); 
                                                                    this.reportMenuClick();
                                                                }}
                                                                className="post-detail__header__options__option__list__button"
                                                            >
                                                                <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                                    <span class="iconb2-report"></span>
                                                                    {this.props.t('postReport.btnReport')}
                                                                </span> 
                                                            </TextButtonComponent>
                                                        </li>
                                                        <li>
                                                            <TextButtonComponent 
                                                                onClick={() => {
                                                                    this.props.changeBlockPostModalStateAction(true);
                                                                    this.handleBlockPage(this.props.state.post.post.author.identifier);
                                                                    this.reportMenuClick();
                                                                }}
                                                                className="post-detail__header__options__option__list__button"
                                                            >
                                                                <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                                    <span class="iconb2-block"></span>
                                                                    {this.props.t('postReport.btnBlock')} @{this.props.state.post.post.author.identifier}
                                                                </span>
                                                            </TextButtonComponent>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    
                                    { ( this.props.state.page?.page?.is_admin || (this.props.state.page?.page?.is_member === true && permissionsController(this.props.state.page?.page?.roles, ['CONTENTAD'])) ) &&
                                        <div className="post-detail__header__options">
                                            <div className="post-detail__header__options__option">
                                                <span 
                                                    aria-describedby='simple-popover'
                                                    onClick={ this.handleClickEditPostButton }
                                                > 
                                                    <img src={ options_icon } alt=""/>
                                                </span>    
                                            </div>
                                        </div>
                                    }
                                    {Boolean(this.state.anchorEdit) && 
                                        <Popover
                                            id={'simple-popover'}
                                            open={ Boolean(this.state.anchorEdit) }
                                            anchorEl={ this.state.anchorEdit }
                                            onClose={this.handleCloseEditPostOption}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',    
                                            }}
                                            container= {this.state.anchorEdit ? this.state.anchorEdit : null}
                                            >
                                            <div
                                            className="post-detail__header__options__option__list__popover"
                                            >
                                                <TextButtonComponent
                                                    onClick={ ()=>{    
                                                        this.handleCloseEditPostOption();
                                                        this.props.changeHomeEditPostModal( true, this.props.state.post.post );
                                                    } }
                                                    className="post-detail__header__options__option__list__button"
                                                >                                        
                                                    <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                        <span class="icon-edit-82"></span>
                                                        <span>{this.props.t('postEdit.btnEdit')}</span>
                                                    </span>
                                                </TextButtonComponent>
                                                <TextButtonComponent
                                                    onClick={ ()=>{ 
                                                        this.handleCloseEditPostOption();
                                                        this.props.deletePost( this.props.state.post.post );
                                                    } }
                                                    className="post-detail__header__options__option__list__button"
                                                >
                                                    <span className="post-detail__header__options__option__list__color baloa-table-column">
                                                        <span class="icon-trash"></span>
                                                        <span>{this.props.t('postEdit.btnDel')}</span>
                                                    </span>                                        
                                                </TextButtonComponent>  
                                            </div>
                                        </Popover>
                                    }
                                </div> 
                                
                            }

                            {/* Caption */}
                            {this.props.state.post.post?.caption && 
                                <div className="post-detail__content__caption">                                    
                                    <div className="post-detail__content__caption__info">                                        
                                        <div className="post-detail__content__caption__info__caption baloa-normal" dangerouslySetInnerHTML={{
                                                    __html: HASHTAG_FORMATTER( this.state.originalLanguage? this.props.state.post.post.original_caption : this.props.state.post.post?.caption)
                                            }}>
                                        </div>
                                        {this.props.state.post.post.caption_language && this.props.state.post.post.caption_language !== this.props.state.post.post.original_caption_language &&
                                            <div className={"post-detail__content__caption__info__caption baloa-names"} onClick={() => {this.changeLanguage()} }>{this.state.originalLanguage? this.props.t("post.showTranslation") : this.props.t("post.showOriginalLanguage")}</div>
                                        }
                                    </div>
                                </div>
                            }

                            {/* Media */}
                            <div className="post-detail__content__media">
                                {this.props.state.post.post?.post_image && <img src={this.props.state.post.post?.post_image} alt="" />}

                                {this.props.state.post.post?.post_video &&
                                    <div className="post-detail__content__media__video">
                                        {/* <video
                                            ref={el_ref => { this.state.video_ref = el_ref }} // eslint-disable-line
                                            src={this.props.state.post.post?.post_video}
                                            loop
                                        >
                                        </video> */}
                                        {this.props.state.post.post?.post_stream.hls?
                                            <ReactHlsPlayer
                                                playerRef={this.videoRef}
                                                src={this.props.state.post.post?.post_stream.hls}
                                                preload = "auto"
                                                autoPlay={true}
                                                width={'100%'}
                                                height={'100%'}                                            
                                                loop={true}
                                                poster = { this.props.state.post.post?.post_stream?.thumbnail ? this.props.state.post.post?.post_stream?.thumbnail : "" }
                                            />
                                            :
                                            <video
                                                ref = { this.videoRef }
                                                src = { this.props.state.post.post?.post_video }
                                                loop
                                                preload = "auto"
                                                poster = { this.props.state.post.post.post_stream?.thumbnail ? this.props.state.post.post.post_stream?.thumbnail : "" }
                                                muted 
                                                playsinline
                                            ></video>
                                        }
                                        <div className="post-detail__content__media__video__control"
                                            onClick={() => { this.handleVideoPlayClick() }}
                                        >
                                            <div className={ !this.state.play ? "post-detail__content__media__video__control__background" : "" }></div>
                                            <span className={ !this.state.play ? "post-detail__content__media__video__control__icon" : "" } ></span>
                                        </div>
                                        <div className="post-detail__content__control-mute"
                                            onClick={() => { this.handleVideoMutedClick() }}
                                        >
                                            <span className={this.state.audio ? "post-detail__content__control-mute__icon icon-sound-off" : "post-detail__content__control-mute__icon icon-sound-on"}></span>
                                        </div>
                                    </div>
                                }

                                {/* Post match content */}
                                {this.props.state.post.post.post_match &&
                                    <LeagueTournamentInfoComponent
                                        tournament_info={this.props.state.post.post.post_match}
                                        className = { "post__content__tournament-result__container" }
                                    />                                       
                                }
                                {/* Post Formation content */}
                                { this.props.state.post.post.formation &&
                                    <div className="post-formation__content">
                                        <div className="post-formation__data">
                                            <div className="post-formation__data__positions-graph">
                                                <ShowPostFormationFieldComponent
                                                    show={this.props.state.post.post.formation ? "block" : "none"}
                                                    display="form"
                                                    position="secondary"
                                                    formation={this.props.state.post.post.formation}
                                                    sup="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* Claps */}
                            {this.props.state.post.post.reaction_count > 0 ?
                                <React.Fragment>    
                                    <div className="post-detail__clapped-by">
                                        <div className="post-detail__clapped-by__photos">
                                            {this.props.state.post.post.reacted_by.map(
                                                clap =>
                                                    <div key={clap[0]} className="post-detail__clapped-by__photos__photo">
                                                        {clap[2] &&
                                                            <img src={clap[2]} alt="" />
                                                        }
                                                        {!clap[2] &&
                                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                        }                                                        
                                                    </div>
                                            )
                                            }
                                        </div>
                                        <p className="post-detail__clapped-by__message"
                                            onClick={ () => { 
                                                this.props.changePostReactionsModalStateAction(true);
                                                this.props.loadPostReactionsById(this.props.state.post.post.id);
                                            }} 
                                        >
                                            {
                                                this.props.state.post.post.reaction_count <= 0 ?
                                                    ""
                                                    :
                                                    <span className="baloa-normal">
                                                        {/* this.props.t('postModal.titleLike')} @{this.props.state.post.post.clapped_by[0][0] */}
                                                        {/* (this.props.state.post.post.clap_count - 1) <= 0 ? "" : this.props.t('postModal.text1') + ' ' + (this.props.state.post.post.clap_count - 1) + ' ' + this.props.t('postModal.text2') */}
                                                        {this.props.state.post.post.is_reacted? (this.props.state.post.post.reaction_count - 1) <= 0 ? this.props.t('postModal.reactionText3', {user: this.props.state.post.post.reacted_by[0][1]}) : this.props.t('postModal.reactionText1', {reactionsCount: `${this.props.state.post.post.reaction_count - 1}` }) : (this.props.state.post.post.reaction_count - 1) <= 0 ? this.props.t('postModal.reactionText3', {user: this.props.state.post.post.reacted_by[0][1]}) : this.props.t('postModal.reactionText2', {user: this.props.state.post.post.reacted_by[0][1] ,reactionsCount: `${this.props.state.post.post.reaction_count}` })}
                                                    </span>
                                            }
                                        </p>                                         
                                    </div>
                                    <div className="post-detail__separator"></div>
                                </React.Fragment>    
                                : undefined
                            }

                            {/* Actions */}
                            <div className="post-detail__content__actions">                                
                                <div 
                                    /* onClick={(event)=>{this.setReaction(event)}} */                     
                                    className="post-detail__actions__action"
                                >
                                    <React.Fragment>
                                    {!this.props.state.post.post.is_reacted &&
                                        <div onClick={(event)=>{this.setReaction(event)}} >
                                            <span className="post-detail__actions__action" class="icon-claps-48"></span><span className="post-detail__actions__action__action-text baloa-normal-medium">{this.props.t('post.actions.clap')}</span>                                            
                                        </div>
                                    }
                                    
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Clap" &&
                                    <div className="post-detail__actions__action__reaction-green baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} >   
                                        <span class="iconr-clap post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.clap')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Support" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} >   
                                        <span class="iconr-support post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.support')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Its_Funny" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} > 
                                        <span class="iconr-funny post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.itsfunny')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Red_Card" &&
                                    <div className="post-detail__actions__action__reaction-red baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} > 
                                        <span class="iconr-red-card post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.redcard')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Sad" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} > 
                                        <span class="iconr-sad post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>                            
                                        </span>
                                        {this.props.t('postReactions.sad')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Celebrate" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium" onClick={(event)=>{this.props.deleteReaction(this.props.state.post.post.id, this.props.state.post.post.reaction_id)}} > 
                                        <span class="iconr-celebrate post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.celebrate')}
                                    </div>
                                    }

                                    </React.Fragment>
                                    
                                </div>
                                <Menu
                                    className = "post-detail__actions__action__menu-reactions"
                                    id={ "reactions-menu_" }
                                    anchorEl={this.state.anchorReactions}
                                    transformOrigin={{
                                        vertical: 50,
                                        horizontal: 'left',
                                    }}
                                    container ={document.getElementById('post_actions_')}
                                    
                                    open={Boolean(this.state.anchorReactions)}
                                    onClose={()=>{this.setAnchorReactions(null)}}
                                >
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Clap", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-clap post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                        </span>                        
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Support", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-support post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Its_Funny", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-funny post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Red_Card", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-red-card post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Sad", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-sad post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleCloseReactions(this.props.state.post.post.id, "Celebrate", this.props.state.post.post.reaction_id, this.props.state.post.post.reaction_type)}}>
                                        <span class="iconr-celebrate post-detail__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                </Menu>
                                {/* Comment */}
                                {/* <div
                                    onClick={() => props.handleOnMessageIconClick(props.post)}
                                    className="post__actions__action"
                                >
                                    <span class="icon-message"></span><span className="post__actions__action__action-text baloa-normal">{this.props.t('post.actions.comment')}</span>
                                </div> */}

                                {/* Share */}
                                <div
                                    id={"post_" + this.props.state.post.post.id}
                                    className="post-detail__actions__action"
                                    onClick={ () => this.shareMenuClick()}
                                >
                                    <span class="icon-share"></span><span className="post-detail__actions__action__action-text baloa-normal-medium">{this.props.t('post.actions.share')}</span>

                                    { this.state.shareMenuOpen && (
                                        <ul className="post-detail__actions__action__list">
                                            <li>
                                                <button type="button" 
                                                className ="post-detail__actions__action__list__button"
                                                onClick={ () => { this.handleOnCopyLinkClick(this.props.state.post.post) } }
                                                >                                                    
                                                    <span className="icon-copy-86"></span>
                                                    <span>{this.props.t('postModal.btnCopy')}</span> 
                                                </button>
                                            </li>
                                        </ul>
                                    ) }
                                </div>
                                {/* <div className="post-detail__content__actions__action">
                                    <img src={ message_icon } alt=""/>
                                </div> */}
                                {/* <div className="post-detail__content__actions__action">
                                    <img src={ share_icon } alt=""/>
                                </div> */}
                            </div>                            

                            <div className="post-detail__separator"></div> 
                            {/* New Comment */}
                            
                            <div className="post-detail__content__new-comment__comment">
                            {
                                 this.props.state.page.page.page_type != "group" &&
                                    <div className="post-detail__content__new-comment__comment_list">
                                        <List component="nav" aria-label="Post Optiions">
                                            {this.state.options[this.state.selectedIndex] && (
                                                <ListItem
                                                    button
                                                    aria-haspopup="true"
                                                    aria-controls={"lock-menu_" + this.props.postItemIndex }
                                                    aria-label="Poster"
                                                    onClick={this.handleClickListItem}
                                                >
                                                    <ListItemAvatar>
                                                        {this.state.options[this.state.selectedIndex][2] &&
                                                            <Avatar alt={this.state.options[this.state.selectedIndex][0]} src={this.state.options[this.state.selectedIndex][2]} />
                                                        }
                                                        {!this.state.options[this.state.selectedIndex][2] &&
                                                            <Avatar className="post-detail__content__new-comment__comment_list__avatar" alt={this.state.options[this.state.selectedIndex][0]} ><span className={"post-detail__content__new-comment__comment_list icon-ball"} color={getRandonIconBallColor()}></span></Avatar>
                                                        }                                                    
                                                    </ListItemAvatar>
                                                    <span className='icon-chevron-down' />
                                                </ListItem>
                                            )}
                                        </List>
                                
                                        <Menu
                                            id={"lock-menu_" + this.props.postItemIndex }
                                            anchorEl={this.state.anchorEl}
                                            container ={document.getElementById('post-comment_' + this.props.postItemIndex)}
                                            onClose={this.handleClose}
                                            open={this.state.menu_open}
                                            variant = {'menu'}
                                            onBlur={(event) => {
                                                this.setMenuOpen(false);
                                            }}      
                                        >
                                            {this.state.options.map((option, index) => (
                                            <MenuItem
                                                key={option[1]}
                                                selected={index === this.state.selectedIndex}
                                                onClick={(event) => this.handleMenuItemClick(event, index, option)}
                                            >
                                                {option[2] &&
                                                    <img className="post-detail__header__photo" src={option[2]} alt="" />
                                                }
                                                {!option[2] &&
                                                    <span className={"post-detail__header__photo icon-ball"} color={getRandonIconBallColor()}></span> 
                                                }
                                                {option[0]} 
                                            </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                }
                                <div className="post-detail__content__new-comment">
                                    <InputWithButtonComponent
                                        className="post-detail__content__new-comment__input"
                                        onClick={(value) => {
                                            this.overInter();
                                            this.props.commentPostById(this.props.state.post.post?.id, value, this.state.posterName, this.state.posterId)
                                        }}
                                        placeholder={this.props.t('comment.text')}
                                    >
                                        {this.props.t('postModal.btnPost')}
                                    </InputWithButtonComponent>
                                </div>
                            </div>
                        </div>                     

                        {/* Post Comments */}
                        <div className="post-detail__comments">
                            {/* <p className="post-detail__comments__title baloa-headline-6">{this.props.t('comment.title')}</p> */}

                            {/* Comments List */}
                            <div className="post-detail__comments__container">
                                {this.props.state.post.comments.map((comment, index) =>

                                    <ScrollTrigger
                                        onEnter={() => this.onEnterViewport(index)}
                                        // onExit={ this.onExitViewport }
                                    >
                                        <div className="post-detail__comments__container__comment"
                                            key={comment.id}
                                        >
                                            {/* console.log('comment: ',comment) */}
                                            {/* Author photo */}
                                            <div className={"post-detail__comments__container__comment__photo "}>
                                                {/* Options Button */}
                                                {comment.author.username === this.props.state.user.me.username &&
                                                    <ButtonMenuComponent
                                                        post_id={this.props.state.post.post.id}
                                                        comment={comment}
                                                        deleteComment={(post_id, author_id) => { this.props.deleteComment(post_id, comment) }}
                                                    />
                                                }                                                
                                                {comment.author.photo &&
                                                    <img src={comment.author.photo} alt="" />
                                                }
                                                {!comment.author.photo &&
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                }                                            
                                            </div>

                                            {/* Comment info */}
                                            <div className="post-detail__comments__container__comment__info">
                                                {/* Author */}
                                                <span className="post-detail__comments__container__comment__info__caption__author baloa-normal-medium">{comment.author?.caption}</span>
                                                {/* Caption */} 
                                                
                                                <div className="post-detail__comments__container__comment__info__caption baloa-normal">                                                        
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: HASHTAG_FORMATTER( comment?.caption )
                                                    }}
                                                    ></div>
                                                </div>

                                                <div className="post-detail__comments__container__comment__info__actions" id={'post-detail__comment__actions_' + comment.id}>

                                                    {/* Comment actions */}
                                                    <div className="post-detail__comments__container__comment__info__action">
                                                        {/* Clap */}
                                                        <div 
                                                            id={"reaction-comment_"+comment.id}
                                                            //onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}                     
                                                            className="post-detail__actions__action"
                                                        >
                                                            <React.Fragment>
                                                            {!comment.is_reacted &&
                                                                <React.Fragment>
                                                                    <span class="icon-claps-48" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}></span>
                                                                    {comment.reaction_count > 0 &&
                                                                        <span
                                                                            className={'baloa-table-column'}
                                                                            onClick={()=>{
                                                                                this.props.changePostCommentReactionsModalStateAction(true);
                                                                                this.props.loadPostCommentReactionsById(comment.id);
                                                                                this.setState({ reaction_comment_id: comment.id })
                                                                                }
                                                                            }
                                                                        >{comment.reaction_count}</span> 
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            
                                                            {comment.is_reacted &&  comment.reaction_type == "Clap" &&
                                                            <div className="post-detail__actions__action__reaction-green baloa-table-column">   
                                                                <span class="iconr-clap post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */ 
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)} }
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                    <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                    <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                    <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Support" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column">   
                                                                <span class="iconr-support post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)}} 
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>  
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Its_Funny" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-funny post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)}}
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Red_Card" &&
                                                            <div className="post-detail__actions__action__reaction-red baloa-table-column"> 
                                                                <span class="iconr-red-card post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)}}
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Sad" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-sad post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)}}
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>                            
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Celebrate" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-celebrate post-detail__actions__action__comment-reactions__icon" 
                                                                    onClick={/* (event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)} */
                                                                    ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, comment.comment_id, comment.reaction_id)}}
                                                                >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={()=>{
                                                                        this.props.changePostCommentReactionsModalStateAction(true);
                                                                        this.props.loadPostCommentReactionsById(comment.id);
                                                                        this.setState({ reaction_comment_id: comment.id })
                                                                        }
                                                                    }
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }

                                                            </React.Fragment>
                                                            
                                                        </div>
                                                        <Menu
                                                            className = "post-detail__actions__action__menu-reactions"
                                                            id={ "reactions-comment-menu_"+ comment.id }
                                                            anchorEl={this.state.anchorCommentReactions}
                                                            transformOrigin={{
                                                                vertical: 50,
                                                                horizontal: 'left',
                                                            }}
                                                            container ={document.getElementById('post-detail__comment__actions_' + comment.id)}
                                                            
                                                            //open={Boolean(this.state.anchorCommentReactions)}
                                                            open={this.state.show_comment_menu_reactions[index]}
                                                            onClose={()=>{this.setState({ anchorCommentReactions: null }); this.handleOnMenuCommentClose(index)}}
                                                        >
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Clap", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-clap post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                    <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                    <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                    <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                </span>                        
                                                            </MenuItem>
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Support", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-support post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Its_Funny", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-funny post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Red_Card", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-red-card post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Sad", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-sad post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                </span>
                                                            </MenuItem>
                                                            <MenuItem onClick={()=>{this.handleCloseCommentReactions(comment.id, "Celebrate", comment.reaction_id, comment.reaction_type, index)}}>
                                                                <span class="iconr-celebrate post-detail__actions__action__menu-reactions__icon">
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                </span>
                                                            </MenuItem>
                                                        </Menu>                                                        
                                                        <span className="post-detail__actions__action__action-text baloa-normal">&nbsp;&bull;</span>
                                                        {/* answer button */}
                                                        <div className="post-detail__comments__container__comment__info__action__reply-comment__icon baloa-names"
                                                            onClick={() => {
                                                                this.state.show_reply_coment[index] ?
                                                                    this.handleOnReplyCommentClose(index)
                                                                    : this.handleOnReplyCommentClick(index);
                                                            }
                                                            }
                                                        >
                                                            {/* <span class="icon-message post-detail__comments__container__reply-comment-icon"></span> */}
                                                            {this.props.t('post.answer')}

                                                        </div>
                                                    </div>
                                                    
                                                    {/* comment resume */}
                                                    {comment.comment_count > 0 &&
                                                        <div className={"post-detail__comments__container__comment__info__resume baloa-names"}
                                                            onClick={ ()=>{ this.props.loadReplyPostCommentsById(comment.id) } }
                                                        >
                                                            <div className={"post-detail__comments__container__comment__info__resume__line-answers"}><hr/></div> {this.props.t('post.answers',{ _answers_count: comment.comment_count})}
                                                        </div>
                                                    }

                                                    {/* New comment of Comment */}
                                                    <div className={"post-detail__content__reply-comment " + (this.state.show_reply_coment[index] ? '' : 'hide')}>
                                                        <InputWithButtonComponent
                                                            className="post-detail__content__reply-comment__input"
                                                            onClick={(value) => {
                                                                this.props.replyCommentPostById(this.props.state.post.post?.id, comment.id, value);
                                                                this.setState({ state_comments_update: true });
                                                                this.handleOnReplyCommentClose(index);
                                                            }}
                                                            placeholder={this.props.t('comment.response')}
                                                        >
                                                            {this.props.t('postModal.btnPost')}
                                                        </InputWithButtonComponent>
                                                    </div>
                                                </div>
                                                
                                                

                                                {/*comment of comment*/}
                                                {this.props.state.post.reply_comments?.filter(fcomment => fcomment.id_comment == comment.id)?.map(replycomment => (
                                                        replycomment.reply_comments != null? (replycomment.reply_comments?.map(( rcomment, rindex ) =>
                                                            <React.Fragment>
                                                            <div className="post-detail__comments__container__comment"
                                                                key={rcomment.id}
                                                            >
                                                                {/* Author photo */}
                                                                <div className={"post-detail__comments__container__comment__photo "}>
                                                                    {rcomment.author?.photo &&
                                                                        <img src={rcomment.author?.photo} alt="" />
                                                                    }
                                                                    {!rcomment.author?.photo &&
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                                    }

                                                                </div>

                                                                {/* Comment info */}
                                                                <div className="post-detail__comments__container__comment__info">

                                                                    {/* Author name }

                                                                    <p className="post-detail__comments__container__reply-comment__info__name">
                                                                        { rcomments.author?.first_name } { rcomments.author?.last_name }
                                                                    </p>*/}
                                                                    <span className="post-detail__comments__container__reply-comment__info__caption__author baloa-normal-medium">{rcomment.author?.caption}</span>
                                                                    {/* Caption */}
                                                                    <div className="post-detail__comments__container__reply-comment__info__caption baloa-normal">                                                                        
                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: HASHTAG_FORMATTER( rcomment.caption )
                                                                        }}></div>
                                                                    </div>

                                                                    {/* comment of comment actions */}            
                                                                    <div className="post-detail__comments__container__comment__info__actions" id={'post-detail__rcomment__actions_' + rcomment.id} >

                                                                        {/* Comments of comments reactions */}
                                                                        <div className="post-detail__comments__container__comment__info__action">
                                                                            <div 
                                                                                id={"reaction-comment_"+rcomment.id}
                                                                                //onClick={(event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)}}                     
                                                                                className="post-detail__actions__action"
                                                                            >
                                                                                <React.Fragment>
                                                                                {!rcomment.is_reacted &&
                                                                                    <React.Fragment>
                                                                                        <span class="icon-claps-48" onClick={(event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)}}></span>
                                                                                        {rcomment.reaction_count > 0 &&
                                                                                            <span
                                                                                                onClick={()=>{
                                                                                                    this.props.changePostCommentReactionsModalStateAction(true);
                                                                                                    this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                                    this.setState({ reaction_comment_id: rcomment.id })
                                                                                                    }
                                                                                                }
                                                                                            >{rcomment.reaction_count}</span> 
                                                                                        }
                                                                                    </React.Fragment>
                                                                                }
                                                                                
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Clap" &&
                                                                                <div className="post-detail__actions__action__reaction-green baloa-table-column">   
                                                                                    <span class="iconr-clap post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                                        <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                                        <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                                        <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Support" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column">   
                                                                                    <span class="iconr-support post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Its_Funny" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-funny post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Red_Card" &&
                                                                                <div className="post-detail__actions__action__reaction-red baloa-table-column"> 
                                                                                    <span class="iconr-red-card post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Sad" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-sad post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>                            
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Celebrate" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-celebrate post-detail__actions__action__comment-reactions__icon" 
                                                                                        onClick={/* (event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)} */
                                                                                        ()=>{this.props.deleteCommentReaction(this.props.state.post.post.id, rcomment.id, rcomment.reaction_id, comment.id)}}
                                                                                    >
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={()=>{
                                                                                            this.props.changePostCommentReactionsModalStateAction(true);
                                                                                            this.props.loadPostCommentReactionsById(rcomment.id);
                                                                                            this.setState({ reaction_comment_id: rcomment.id })
                                                                                            }
                                                                                        }
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }

                                                                                </React.Fragment>
                                                                                
                                                                            </div>
                                                                            <Menu
                                                                                className = "post-detail__actions__action__menu-reactions"
                                                                                id={ "reactions-comment-menu_"+ rcomment.id }
                                                                                anchorEl={this.state.anchorRCommentReactions}
                                                                                transformOrigin={{
                                                                                    vertical: 50,
                                                                                    horizontal: 'left',
                                                                                }}
                                                                                container ={document.getElementById('post-detail__rcomment__actions_' + rcomment.id)}
                                                                                
                                                                                open={this.state.show_rcomment_menu_reactions[rindex]}
                                                                                onClose={()=>{this.setState({ anchorRCommentReactions: null }); this.handleOnMenuRCommentClose(rindex)}}
                                                                            >
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Clap", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-clap post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                                        <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                                        <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                                        <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                                    </span>                        
                                                                                </MenuItem>
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Support", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-support post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                    </span>
                                                                                </MenuItem>
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Its_Funny", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-funny post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                    </span>
                                                                                </MenuItem>
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Red_Card", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-red-card post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                    </span>
                                                                                </MenuItem>
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Sad", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-sad post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                    </span>
                                                                                </MenuItem>
                                                                                <MenuItem onClick={()=>{this.handleCloseRCommentReactions(rcomment.id, "Celebrate", rcomment.reaction_id, rcomment.reaction_type, rindex, comment.id)}}>
                                                                                    <span class="iconr-celebrate post-detail__actions__action__menu-reactions__icon">
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                    </span>
                                                                                </MenuItem>
                                                                            </Menu>
                                                                            <span className="post-detail__actions__action__action-text baloa-normal">&nbsp;&bull;</span>
                                                                            {/* Answer coment of comment */}
                                                                            <div className="post-detail__comments__container__comment__info__action__reply-comment__icon baloa-names"
                                                                                onClick={() => {
                                                                                    this.state.show_reply_coment2[rindex] ?
                                                                                        this.handleOnReplyComment2Close(rindex)
                                                                                        : this.handleOnReplyComment2Click(rindex);
                                                                                }
                                                                                }
                                                                            >
                                                                                {/* <span class="post-detail__comments__container__reply-comment-icon baloa-names"></span> */}
                                                                                {this.props.t('post.answer')}

                                                                            </div>
                                                                        </div>
                                                                        
                                                                        {rcomment.comment_count > 0 &&
                                                                            <div className={"post-detail__comments__container__comment__info__resume baloa-names"}
                                                                                onClick={ ()=>{ this.props.loadReplyPostCommentsById(rcomment.id) } }
                                                                            >
                                                                                <div className={"post-detail__comments__container__comment__info__resume__line-answers"}><hr/></div>{this.props.t('post.answers',{ _answers_count: rcomment.comment_count})}
                                                                            </div>
                                                                        }


                                                                        {/* New comment of Comment */}
                                                                        <div className={"post-detail__content__reply-comment " + (this.state.show_reply_coment2[rindex] ? '' : 'hide')}>
                                                                            <InputWithButtonComponent
                                                                                className="post-detail__content__reply-comment__input"
                                                                                onClick={(value) => {
                                                                                    this.props.replyCommentPostById(this.props.state.post.post?.id, rcomment.id, value);
                                                                                    this.setState({ state_comments_update: true });
                                                                                    this.handleOnReplyComment2Close(rindex);
                                                                                }}
                                                                                placeholder={this.props.t('comment.response')}
                                                                                value={rcomment.author.caption}
                                                                            >                                                                                
                                                                                {this.props.t('postModal.btnPost')}
                                                                            </InputWithButtonComponent>
                                                                        </div>
                                                                        {/*comment of comment*/}
                                                                        {this.props.state.post.reply_comments?.filter(fcommentcomment => fcommentcomment.id_comment == rcomment.id).map(replycommentcomment => (
                                                                                replycommentcomment.reply_comments != null? (replycommentcomment.reply_comments.map(rcommentcoment =>
                                                                                    <React.Fragment>
                                                                                    <div className="post-detail__comments__container__comment-of-comment"
                                                                                        key={rcommentcoment.id}
                                                                                    >
                                                                                        {/* Author photo */}
                                                                                        <div className={"post-detail__comments__container__comment__photo"}>
                                                                                            {rcommentcoment.author?.photo &&
                                                                                                <img src={rcommentcoment.author?.photo} alt="" />
                                                                                            }
                                                                                            {!rcommentcoment.author?.photo &&
                                                                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                                                            }                                                                                    
                                                                                        </div>

                                                                                        {/* Comment info */}
                                                                                        <div className="post-detail__comments__container__comment__info">

                                                                                            {/* Author name }

                                                                                            <p className="post-detail__comments__container__reply-comment__info__name">
                                                                                                { rcomments.author?.first_name } { rcomments.author?.last_name }
                                                                                            </p>*/}
                                                                                            <span className="post-detail__comments__container__reply-comment__info__caption__author baloa-normal-medium">{rcommentcoment.author?.caption}</span>
                                                                                            {/* Caption */}
                                                                                            <div className="post-detail__comments__container__reply-comment__info__caption baloa-normal">                                                                        
                                                                                                <div dangerouslySetInnerHTML={{
                                                                                                    __html: HASHTAG_FORMATTER( rcommentcoment.caption )
                                                                                                }}></div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    </React.Fragment>
                                                                                )) 
                                                                                :
                                                                                undefined
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                
                                                            </div>

                                                            </React.Fragment>
                                                            )) 
                                                            :
                                                            undefined
                                                        ))                                                    
                                                                                                            
                                                }

                                            </div>
                                        </div>
                                    </ScrollTrigger>

                                )}
                            </div>
                        </div>

                    </div>

                    {/* Right side */}
                    <div className="profile__right-container">
                    </div>

                    {/* Modales */}
                    {/* Modal reacciones post */}
                    <ReactionsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.reactions_is_open } 
                        onClose={() => { this.props.changePostReactionsModalStateAction( false ) }}
                        reactions={ this.props.state.post.reactions }
                        page={this.props.state.profile.page_active}
                        user_me={this.props.state.user.me}
                        reactions_next_page = { this.props.state.post.reactions_next_page}
                        followProfile = { ( profile ) => { this.props.followProfile( profile ) } }
                        unfollowProfile = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                        changePostIsFollowing ={(username) => {this.props.changePostIsFollowingStateAction(username)}}
                        loadPostReactionsNext={(next_page, reaction_type) => this.props.loadPostReactionsNext(next_page, reaction_type)}
                        loadPostReactionsByType = { ( reaction_type ) => {this.props.loadPostReactionsByReactionType( this.props.state.post.post.id, reaction_type ) } }
                        reactions_by_type = { this.props.state.post.reactions_by_type }
                        changePostIsFollowingFromReactionType={(username, reaction_type) => {this.props.changePostIsFollowingStateFromReactionTypeAction(username, reaction_type)}}
                    />
                    {/* Modal reacciones post comments */}
                    <ReactionsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.comment_reactions_is_open } 
                        onClose={() => { this.props.changePostCommentReactionsModalStateAction( false ) }}
                        reactions={ this.props.state.post.comment_reactions }
                        page={this.props.state.profile.page_active}//TODO: validar si se elimina ya que el page_active ya no se deberia utilizar
                        user_me={this.props.state.user.me}
                        reactions_next_page = { this.props.state.post.comment_reactions_next_page}
                        followProfile = { ( profile ) => { this.props.followProfile( profile ) } }
                        unfollowProfile = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                        changePostIsFollowing ={(username) => {this.props.changePostIsFollowingStateAction(username)}}
                        loadPostReactionsNext={(next_page, reaction_type) => this.props.loadPostCommentReactionsNext(next_page, reaction_type)}
                        loadPostReactionsByType = { ( reaction_type ) => {this.props.loadPostCommentReactionsByReactionType( this.state.reaction_comment_id, reaction_type ) } }
                        reactions_by_type = { this.props.state.post.comment_reactions_by_type }
                    />
                    <ReportModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.report_is_open } 
                        onClose={() => { this.props.changeReportPostModalStateAction( false ) }}
                        post_id={this.props.state.post?.post?.id} 
                        reportPostById={ (post_id, denunciation) => {this.props.reportPostById(post_id, denunciation)}}
                        openAcceptReport={ () => this.props.changeAcceptReportPostModalStateAction( true ) }
                    />

                    <BlockModalComponent 
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.block_is_open } 
                        onClose={() => { this.props.changeBlockPostModalStateAction( false ) }}
                        profile_username={this.state.block_profile}
                        blockProfile={ (username) => {this.props.blockProfile(username)}}
                        page_pagename={this.state.block_page}
                        blockPage={ (pagename) => {this.props.blockPage(pagename)}}

                    />

                    <AcceptReportModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.accept_report_is_open }
                        onClose={() => { this.props.changeAcceptReportPostModalStateAction( false ) }}
                    />
                </div>

            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPostDetailById: (post_id) => {
            dispatch(loadPostDetailById(post_id))
        },
        loadPostCommentsById: (post_id) => {
            dispatch(loadPostCommentsById(post_id))
        },
        /* eslint-disable */
        loadReplyCommentsPostById: (comments) => {
            comments.map(comment => {
                dispatch(loadReplyPostCommentsById(comment.id))
            })
        },
        /* eslint-enable */
        commentPostById: (post_id, comment, pagename, page_id) => {
            dispatch(commentPostById(post_id, comment, pagename, page_id))
        },
        replyCommentPostById: (post_id, comment_id, comment) => {
            dispatch(replyCommentPostById(post_id, comment_id, comment))
        },
        loadReplyPostCommentsById: (comment_id) => {
            dispatch(loadReplyPostCommentsById(comment_id))
        },
        clapPost: (post) => {
            dispatch(clapPost(post))
        },
        deleteComment: (post_id, comment) => {
            dispatch(deleteComment(post_id, comment))
        },
        clapCommentById: (comment_id) => {
            dispatch(clapCommentById(comment_id))
        },
        loadPostCommentsNext: (next_page) => {
            dispatch(loadPostCommentsNext(next_page))
        },
        // clapComment: ( comment ) => {
        //     return dispatch( clapComment( comment ) )
        // },
        overwriteInterceptorAction: ( new_state ) => {
            return dispatch( overwriteInterceptorAction( new_state ) );
        },
        sendReaction:( post_id, reaction ) => {
            dispatch( sendReaction( post_id, reaction ))
        },
        overrideReaction:( post_id, reaction, reaction_id ) => {
            dispatch( overrideReaction( post_id, reaction, reaction_id ))
        },
        changePostReactionsModalStateAction: (new_modal_state) => {
            dispatch( changePostReactionsModalStateAction(new_modal_state))
        },
        loadPostReactionsById: ( post_id ) => {
            dispatch( loadPostReactionsById( post_id ))
        },
        loadPostReactionsNext: (next_page, reaction_type) => {
            dispatch( loadPostReactionsNext(next_page, reaction_type))
        },
        loadPostReactionsByReactionType:( post_id, reaction_type ) => {
            dispatch( loadPostReactionsByReactionType( post_id, reaction_type ))
        },
        deleteReaction:( post_id, reaction_id ) => {
            dispatch( deleteReaction( post_id, reaction_id ))
        },        
        unfollowProfile: ( profile ) => {
            dispatch( unfollowProfile( profile ) )
        },
        followProfile: ( profile ) => {
            return dispatch( followProfile( profile ) )
        },
        changePostIsFollowingStateAction: (username) => {
            dispatch( changePostIsFollowingStateAction(username))
        },
        changeReportPostModalStateAction: ( new_modal_state ) => {
            dispatch( changeReportPostModalStateAction( new_modal_state ))
        },
        changeBlockPostModalStateAction: (new_modal_state) => {
            dispatch( changeBlockPostModalStateAction(new_modal_state))
        },
        changeAcceptReportPostModalStateAction: (new_modal_state) => {
            dispatch( changeAcceptReportPostModalStateAction(new_modal_state))
        },
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) );
        },
        deletePost: ( post ) => {
            return dispatch( deletePost( post ) )
        },
        reportPostById: ( post_id, denunciation ) => {
            dispatch( reportPostById( post_id, denunciation ))
        },
        blockProfile:( username ) => {
            dispatch( blockProfile( username ))
        }, 
        blockPage:( pagename ) => {
            dispatch( blockPage( pagename ))
        },
        sendCommentReaction:(post_id, comment_id, reaction, parent_comment_id ) => {
            dispatch( sendCommentReaction( post_id, comment_id, reaction, parent_comment_id ))
        },
        deleteCommentReaction:(post_id, comment_id, reaction_id, parent_comment_id) => {
            dispatch( deleteCommentReaction( post_id, comment_id, reaction_id, parent_comment_id ))
        },
        overrideCommentReaction:(post_id, comment_id, reaction, reaction_id, parent_comment_id) => {
            dispatch( overrideCommentReaction( post_id, comment_id, reaction, reaction_id, parent_comment_id ))
        },
        changePostCommentReactionsModalStateAction: (new_modal_state) => {
            dispatch( changePostCommentReactionsModalStateAction(new_modal_state))
        },
        loadPostCommentReactionsById: ( comment_id ) => {
            dispatch( loadPostCommentReactionsById( comment_id ))
        },
        loadPostCommentReactionsNext: (next_page, reaction_type) => {
            dispatch( loadPostCommentReactionsNext( next_page, reaction_type ))
        },
        loadPostCommentReactionsByReactionType: ( comment_id, reaction_type ) => {
            dispatch( loadPostCommentReactionsByReactionType( comment_id, reaction_type ))
        },
        followPostProfile:( username, post_id ) => {
            dispatch( followPostProfile( username, post_id ))
        }, 
        unfollowPostProfile:( username, post_id ) => {
            dispatch( unfollowPostProfile( username, post_id ))
        }, 
        followPostPage:( pagename, post_id ) => {
            dispatch( followPostPage( pagename, post_id ))
        }, 
        unfollowPostPage:( pagename, post_id ) => {
            dispatch( unfollowPostPage( pagename, post_id ))
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changePostIsFollowingStateFromReactionTypeAction: (username, reaction_type) => {
            dispatch( changePostIsFollowingStateFromReactionTypeAction(username, reaction_type))
        },
    } 
}



export default compose(
    withTranslation("post"),
    connect(mapStateToProps, mapDispatchToProps)
)(PostDetailView);

//export default connect(mapStateToProps, mapDispatchToProps)(PostDetailView);