// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentEditPhaseValidationModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentEditPhaseValidationModalComponent( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            className="tournament-edit-phase-validation-modal__container"
        >
            <div className="tournament-edit-phase-validation-modal">
                <span className='hubicon-info' />
                <div className='baloa-normal-text'>{props.message}</div>
                { props.state_phase_actions &&
                    <div className='tournament-edit-phase-validation-modal__phase_actions__btns'>
                        <SecondaryButtonComponent onClick={() => {props.onClose()}} >{props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.closeBtn')}</SecondaryButtonComponent>
                        <PrimaryButtonComponent onClick={() => {props.handleOnPhaseActions(props.phase_action)}} >
                            { 
                                props.phase_action === 'start_phase' 
                                ? 
                                props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelAddTeams')
                                : 
                                props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndPhase')
                            }
                        </PrimaryButtonComponent>
                    </div>
                }
                { !props.state_phase_actions &&
                    <div className='tournament-edit-phase-validation-modal__action-btn'>
                        <PrimaryButtonComponent onClick={() => {props.onClose()}} >{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.labelBtn')}</PrimaryButtonComponent>
                    </div>
                }               
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentEditPhaseValidationModalComponent);