
// Moment
import * as moment from 'moment';
import i18n from 'i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

export default function convertDateToMoment( datetime ) {
    moment.locale(i18n.language);
    return moment( datetime ).fromNow();    
}
