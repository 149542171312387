/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

// Actions
import { userLogout } from 'app/authentication/actions/LoginActions';
import { changeUserSettingsError, changeUserPaymentsModalStateAction, cleanUserCountryformAction } from 'app/user-settings/actions/UserSettingsCreators';
import { sendUserSettingsDataAction, 
    setUserLanguageAction, 
    loadLanguagesAction, 
    loadPaymentCountries, 
    loadPaymentCountryId, 
    loadPaymentsFormTemplate,
    getUserGatewayAttribute,
    getUserCountries,
    getUserCountryStates,
    sendUserPaymentForms,
    checkProfileAccountStatus,
    getUserMeAfterAccount,
} from 'app/user-settings/actions/UserSettingsActions';

// Styles
import './UserSettingsView.styles.css';

//Assets
import undraw_credit_card_re_blml from 'assets/images/undraw_credit_card_re_blml.png';

// Components
import AccountInformationComponent from 'app/user-settings/components/account-information/AccountInformationComponent';
import PaymentsActiveModalComponent from 'app/payments/components/payments-active-modal/PaymentsActiveModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import UserPaymentsFormActiveComponent from '../components/user-payments-form/UserPaymentsFormActiveComponent';
import UserPaymentSettingComponent from '../components/user-payments-settings/UserPaymentSettingComponent';

// React device detect
import { isMobile } from 'react-device-detect';


function TabList ( props ){

    const { value } = props;    
    const [radio_value, setValueLanguage] = React.useState();
    const { t, i18n } = useTranslation('profile');

    React.useEffect(() => {    

        setValueLanguage(props.languageUserCode);

    }, [props]);

    const handleChangeLanguage = (event) => {                        
        
        i18n.changeLanguage(event.target.value)
        setValueLanguage(event.target.value);

        var language = props.languages.find(language => {            
            return language.language_code === event.target.value
        });

        props.setUserLanguageAction(props.user.id, language);
        
    };

    return(

        <div className="settings__content">

            {/* account information */}
            {value === 1 && (
                <React.Fragment>

                    <AccountInformationComponent
                        value={ props.value }
                        user={ props.user }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )} 
                        errors = { props.errors }
                        onResetError={props.onResetError}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }

                    />
                </React.Fragment>
                
            )}

            {/* password */}
            {value === 2 && (
                <React.Fragment>
                    <div className="settings__content__title">
                        <p className="settings__content__title__text">
                        Contraseña
                        </p>
                    </div>
                    
                </React.Fragment>
                
            )} 

            {/* notifications */} 
            {value === 3 && (
                <React.Fragment>
                    <div className="settings__content__title">
                        <p className="settings__content__title__text">
                        Notificaciones
                        </p>
                    </div>
                    
                </React.Fragment>
                
            )}

            {/* payment methods */} 
            {value === 4 && (
                <React.Fragment>
                    <div className="settings__content__title">
                        <p className="settings__content__title__text">
                        Métodos de pago
                        </p>
                    </div>
                    
                </React.Fragment>
                
            )}

            {/* payment methods */} 
            {value === 5 && (
            <React.Fragment>
                <div className="settings__content__title">
                    <div className="settings__content__title__text baloa-subtitle-2">
                    {t('settings.languageSettings')}
                    </div>
                    </div>
                <div className="settings__separator"></div> 
                <FormControl component="fieldset" className="settings__content__language__radiobuttons">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup aria-labelledby="gender" name="gender1" value={radio_value} onChange={handleChangeLanguage}>
                        
                        { props.languages.map( language => {

                            return (
                                <FormControlLabel className="baloa-names" id={language.id} value={language.language_code} control={<Radio color='secondary'/>} label={t('settings.'+language.caption)} />
                            )

                        })}

                    </RadioGroup>
                </FormControl>
                
            </React.Fragment>
                
            )}

            {/* payment methods */} 
            {value === 6 && (
            //Account states: Pending, Geteway_Error, Active, Inactive
            <React.Fragment>
                {props.user_payment_active && !props.solve_form_data &&
                    <React.Fragment>
                    {(props.account_state === 'Pending' || props.account_state === 'Gateway_Error')? 
                        <div className='settings__content__payments__center-container'>
                            <div className="settings__content__payments__center-container__action-box">
                                {props.account_state === 'Pending'?
                                    <React.Fragment>
                                        
                                        <div className="baloa-normal-text">
                                            <span className="hubicon-info" />
                                            {t('settings.userPayment.paymentsVerification')}
                                        </div>
                                    </React.Fragment>                                        
                                    : 
                                    <React.Fragment>
                                        <div className="baloa-normal-text">
                                            <span className="hubicon-info error" />
                                            <div>
                                                {t('settings.userPayment.paymentsErrorsTitle')}   
                                                <div className="baloa-names">
                                                    {t('settings.userPayment.paymentsErrorsMsg')}
                                                </div>     
                                            </div>                                    
                                        </div>                               
                                    </React.Fragment>
                                }                                                                
                                { (props.account_state === 'Gateway_Error') &&
                                    <PrimaryButtonComponent
                                        input_type='button'
                                        className='xs'
                                        onClick={ () => {props.handlePaymentSolve()}}
                                    >
                                        <span>{t('settings.userPayment.paymentButtomSolve')}</span>
                                    </PrimaryButtonComponent>
                                }
                            </div> 
                            <div className="settings__content__payments__center-container-img">
                                <img  src={undraw_credit_card_re_blml} alt=""/>
                            </div>
                        </div>
                    :
                        <div>
                            <UserPaymentSettingComponent
                                accountResponse={props.accountResponse }
                            />
                        </div>
                    }
                    </React.Fragment>
                }
                {!props.user_payment_active && 
                    props.payments_form === false && ( // eslint-disable-line  
                        <div className='settings__content__payments__center-container'>
                            <div className="settings__content__payments__center-container__action-box">
                                <div className="baloa-normal-text">
                                    <span className="hubicon-info" />
                                    <div className="container_action_box_text">
                                        { !props.confirmation 
                                            ?t('settings.userPayment.paymentActivateText')
                                            :t('settings.userPayment.paymentsVerification')
                                        }
                                    </div>
                                </div>
                                { !props.confirmation &&
                                    <PrimaryButtonComponent
                                        input_type='button'
                                        className={!isMobile ? 'xs' : ''}
                                        onClick={ () => {props.handelModalActive()}}
                                    >
                                        <span>{t('settings.userPayment.paymentButtomActive')}</span>
                                    </PrimaryButtonComponent>
                                }
                            </div> 
                            <div className="settings__content__payments__center-container-img">
                                <img  src={undraw_credit_card_re_blml} alt=""/>
                            </div>
                        </div>
                    ) ||// eslint-disable-line  
                    props.payments_form === true && (// eslint-disable-line  
                        <UserPaymentsFormActiveComponent
                            handleSearchNationality={ props.handleSearchNationality }
                            countries={ props.countries }
                            country_states={ props.country_states }
                            gateway_attribute={ props.gateway_attribute }
                            onNewData={ props.onNewPaymentData }
                            handleSearchCountryStates={ props.handleSearchCountryStates }
                            inscription_form_template={ props.inscription_form_template }
                            handleSendFormData={ props.handleSendFormData }
                            handlePreviewForm={ props.handlePreviewForm }
                            handleFinalizePayments={ props.handleFinalizePayments}
                            handleCleanCountryForm={ props.handleCleanCountryForm }
                            user_form_payment_loading= {props.user_form_payment_loading}
                        />
                    )
                    
                }
                {props.solve_form_data &&
                    <UserPaymentsFormActiveComponent
                        handleSearchNationality={ props.handleSearchNationality }
                        countries={ props.countries }
                        country_states={ props.country_states }
                        gateway_attribute={ props.gateway_attribute }
                        onNewData={ props.onNewPaymentData }
                        handleSearchCountryStates={ props.handleSearchCountryStates }
                        inscription_form_template={ props.form_data }
                        handleSendFormData={ props.handleSendFormData }
                        handlePreviewForm={ props.handlePreviewForm }
                        handleFinalizePayments={ props.handleFinalizePayments}
                        handleCleanCountryForm={ props.handleCleanCountryForm }
                        retrive_data={true}
                        handleIndustryTypeLoad={props.handleIndustryTypeLoad}
                        user_form_payment_loading= {props.user_form_payment_loading}
                    />
                }                       
                
            </React.Fragment>
                
            )}

        </div>
        
    )
}

class UserSettingView extends Component {

    //{ t, i18n } = useTranslation('profile');

    constructor( props ) {
        super(props);
        this.username = this.props.match.params.username;
        this.state = {
            tab_selected: 1,
            index: 1,
            country_tag: [],
            //sections:[],
            type_tag:[
                {value: 'account_individual_debit_card_form', content: this.props.t('settings.userPayment.type_tag.individual')}/* ,
                {value: 'account_company_form', content: this.props.t('settings.userPayment.type_tag.company')} */
            ],
            activate_payments: false,
            payments_form: false,
            industry_types: [],
            country_states: [],
            confirmation: false,
            solve_form_data: false,
        }
    }

    nextPath = (path) => {
        this.props.history.push(path)
    }

    data_edit( index ){
        if(index === 0){
            this.setState({index: 1});
        }else{
            this.setState({index: 0});  
        }     
    }

    componentDidMount() {        
        this.props.loadLanguagesAction();
        if(this.props.location.search === '?paymentAccount'){
            this.setState({tab_selected: 6});
            this.setState({index: 6});
            let clean_path = this.props.history.location.pathname;
            this.props.history.replace({
                pathname: clean_path,
                search: '',
            });
        }
    }

    handelModalActive= async () =>{
        await this.props.loadPaymentCountries();
        let countries_array= []
        this.props.state.userSettings?.country_payments.forEach(country => {
            countries_array.push({
            value:country.owner_id,
            content:country.country
            })
        })
        this.setState({country_tag:countries_array})
        this.props.changeUserPaymentsModalStateAction(true)        
    }

    handelOnloadPaymentForm = async (form_type, owner_id) =>{
        // this.props.loadPaymentCountryIdApi(owner_id);
        await this.props.loadPaymentsFormTemplate(form_type, owner_id);        
        await this.props.getUserGatewayAttribute(this.props.state.userSettings?.form_payments.owner.owner_id, 'industry_type');
        this.handleIndustryTypes();        
        this.props.changeUserPaymentsModalStateAction(false)
        this.setState({activate_payments: true})
        this.setState({payments_form: true})
    }

    handleIndustryTypes () {
        let industry_types_array = [];
        this.props.state.userSettings?.gateway_attribute.forEach(industry_type => {
            industry_types_array.push({
                value:industry_type.value,
                content:industry_type.caption
            })
        })
        this.setState({industry_types: industry_types_array})
    }

    handleCountryStates = async(country) => {
        await this.props.getUserCountryStates(country);
        let country_states_array = [];
        this.props.state.userSettings?.country_states.forEach(state => {
            country_states_array.push({
                value: state.id,
                content: state.name,
                country: state.country,
            })
        });
        this.setState({country_states: country_states_array});
    }

    handleSendFormData = async (form_data) => {
        let form_questions_array = [];
        form_data.forEach((form, index) => {
            form.forEach((question) => {
                form_questions_array.push(question);
            })
        });
        let new_form_data = {
            form_template: this.state.solve_form_data? this.props.state.userSettings.retrive_form_data?.form_template : this.props.state.userSettings.form_payments?.id,
            questions: form_questions_array
        };
        await this.props.sendUserPaymentForms(new_form_data);
        if(!this.state.solve_form_data){
            await this.props.getUserMeAfterAccount();
        }
        if(!this.props.state.userSettings?.is_user_onboarding_form_invalid) {
            this.handleFinalizePayments();
            this.props.checkProfileAccountStatus(this.props.state.user.me.stripe_account_id);
        }
    }

    handleFinalizePayments(){
        this.setState({activate_payments: false})
        this.setState({payments_form: false})
        this.setState({confirmation: true})
        this.setState({solve_form_data: false})
    }

    handlePaymentSolve(){
        this.setState({solve_form_data: true})
    }

    fixFormData(form_data){
        let new_form_data = form_data;
        if(form_data){
            form_data.sections.map((section, sec_inx) =>{// eslint-disable-line
                section.questions.map((question, qut_inx) =>{// eslint-disable-line
                    new_form_data.sections[sec_inx].questions[qut_inx].id= question.form_question_template.id;
                })
            })
        }

        return new_form_data;
    }

    handleIndustryTypeLoad = async () =>{
        await this.props.getUserGatewayAttribute(this.props.state.userSettings?.retrive_form_data.owner_id, 'industry_type');
        this.handleIndustryTypes();        
    }

    render() {
        
         return(

            <React.Fragment>
                {/*settings list*/}
                <div className="settings-list">
                    { !isMobile
                        ? 
                        <div className="settings-list__content">
                            {/* list title*/}
                            <div className="settings-list__content__title">
                                <span className="settings-list__content__title__text baloa-headline-2">{ this.props.t('settings.title') }</span>
                            </div>

                            {/* account information */}
                            <div 
                                className={"settings-list__content__field " + (this.state.tab_selected == 1? "active" : "") }
                                onClick={() => {
                                    this.setState({tab_selected: 1});
                                    this.setState({index: 1});
                                }}
                            >
                                {/* <div className="settings-list__content__field__icon">
                                    <span className="icon-user"></span>
                                </div> */}
                                <div className="settings-list__content__field__data">
                                    <span className="settings-list__content__field__data__text baloa-normal-medium">
                                        { this.props.t('settings.accountInformation') }
                                    </span>
                                </div>
                                {/* <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div> */}
                            </div>
                            
                            
                            {/*START Temporalmente deshabilitado */}
                            
                            
                            {/* password */}
                            {/* <div 
                            className="settings-list__content__field"
                            onClick={() => {
                                this.setState({tab_selected: 2})
                            }}
                            >
                                <div className="settings-list__content__field__icon">
                                    <span className="icon-lock-closed"></span>
                                </div>
                                <div className="settings-list__content__field__data">
                                    <p className="">
                                        Contraseña
                                    </p>
                                </div>
                                <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div>
                            </div>
                            <div className="settings-list__content__line"></div> */}

                            {/* notifications */}
                            {/* <div 
                            className="settings-list__content__field"
                            onClick={() => {
                                this.setState({tab_selected: 3})
                            }}
                            >
                                <div className="settings-list__content__field__icon">
                                    <span className="icon-wistle"></span>
                                </div>
                                <div className="settings-list__content__field__data">
                                    <p className="">
                                        Notificaciones
                                    </p>
                                </div>
                                <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div>
                            </div>
                            <div className="settings-list__content__line"></div> */}

                            {/* payment methods */}
                            {/* <div 
                            className="settings-list__content__field"
                            onClick={() => {
                                this.setState({tab_selected: 4})
                            }}
                            >
                                <div className="settings-list__content__field__icon">
                                    <span className="icon-credir-card"></span>
                                </div>
                                <div className="settings-list__content__field__data">
                                    <p className="">
                                        Métodos de pago
                                    </p>
                                </div>
                                <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div>
                            </div>
                            <div className="settings-list__content__line"></div> */}

                            {/*END Temporalmente deshabilitado */}

                            {/* account information */}
                            <div 
                                className={"settings-list__content__field "  + (this.state.tab_selected == 5? "active" : "")}
                                onClick={() => {
                                    this.setState({tab_selected: 5});
                                    this.setState({index: 5});
                                }}
                            >
                                {/* <div className="settings-list__content__field__icon">
                                    <span className="icon-web"></span>
                                </div> */}
                                <div className="settings-list__content__field__data">
                                    <span className="settings-list__content__field__data__text baloa-normal-medium">
                                    { this.props.t('settings.language') }
                                    </span>
                                </div>
                                {/* <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div> */}
                            </div>

                            {/* payment information */}
                            <div 
                                className={"settings-list__content__field "  + (this.state.tab_selected == 6? "active" : "")}
                                onClick={() => {
                                    this.setState({tab_selected: 6});
                                    this.setState({index: 6});
                                    if(this.props.state.user.me.is_stripe_account && this.props.state.user.me.stripe_account_id){
                                        this.props.checkProfileAccountStatus(this.props.state.user.me.stripe_account_id);
                                    }
                                }}
                            >
                                {/* <div className="settings-list__content__field__icon">
                                    <span className="icon-web"></span>
                                </div> */}
                                <div className="settings-list__content__field__data">
                                    <span className="settings-list__content__field__data__text baloa-normal-medium">
                                    { this.props.t('settings.userPayment.paymentTitle') }
                                    </span>
                                </div>
                                {/* <div className="settings-list__content__field__icon-chevron">
                                    <span className="icon-chevron-roght"></span>
                                </div> */}
                            </div>
                            

                            {/* <div 
                                className="settings-list__content__button"
                                onClick={ () => { 
                                    this.nextPath('/');
                                    this.props.userLogout();
                                } }
                            >
                                <span className="settings-list__content__button__text">{ this.props.t('settings.closeSession') }</span>
                            </div> */}

                        </div>
                        :
                        <div className="user-settings__mobile-menu__container">
                            <div className="baloa-names">{ this.props.t('settings.title') }</div>
                            <div className="user-settings__mobile-menu__actions" >
                                <div 
                                    className={`baloa-table-column ${this.state.tab_selected == 1? 'active': ''} `} 
                                    onClick={() => {this.setState({tab_selected: 1}); this.setState({index: 1}); }}
                                >
                                    { this.props.t('settings.accountInformation') }
                                </div>
                                <div 
                                    className={`baloa-table-column ${this.state.tab_selected == 5? "active" : ""} `} 
                                    onClick={() => { this.setState({tab_selected: 5}); this.setState({index: 5}); }}
                                >
                                    { this.props.t('settings.language') }
                                </div>
                                <div
                                    className={`baloa-table-column ${this.state.tab_selected == 6? "active" : ""} `} 
                                    onClick={() => { this.setState({tab_selected: 6}); this.setState({index: 6});
                                    if(this.props.state.user.me.is_stripe_account && this.props.state.user.me.stripe_account_id){
                                        this.props.checkProfileAccountStatus(this.props.state.user.me.stripe_account_id);
                                    } 
                                }}
                                >
                                    { this.props.t('settings.userPayment.paymentTitle') }
                                </div>
                            </div>
                        </div>
                    }

                    <div className="settings-list__settings">
                        {/*Modals*/}
                        <PaymentsActiveModalComponent
                            onClick={() => { }}
                            type_tag={this.state.type_tag}
                            isOpen={this.props.state.userSettings.modals.payment_settings_modal_open}
                            onClose={() => {this.props.changeUserPaymentsModalStateAction(false) } }
                            country_tag={this.state.country_tag}
                            //errors={this.props.state.page.errors.creation}
                            //onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}
                            handelOnloadPaymentForm = {(form_type, owner_id)=>{this.handelOnloadPaymentForm(form_type, owner_id,'1')}}
                        /> 
                        <TabList
                            value={ this.state.tab_selected }
                            errors = { this.props.state.userSettings.errors }
                            user={ this.props.state.user.me }
                            index={this.state.index}
                            data_edit={() => this.data_edit(this.state.index)}
                            onResetError = { ( data ) => this.props.changeUserSettingsError( data ) }
                            onNewData={ ( detail_data,new_data ) => this.props.sendUserSettingsDataAction( detail_data,new_data ) }
                            setUserLanguageAction={ ( user_id, language ) => this.props.setUserLanguageAction( user_id, language ) }
                            languages = { this.props.state.user.languages ? this.props.state.user.languages : [] }  
                            languageUserCode = { this.props.state.user.me.language?.language_code } 
                            
                            //Payments
                            handelModalActive={()=>{this.handelModalActive()}}
                            user_payment_active={this.props.state.user.me.is_stripe_account}
                            confirmation={this.state.confirmation }
                            payments_form={this.state.payments_form}

                            handleSearchNationality={(country) => {this.props.getUserCountries(country)}}
                            countries={this.props.state.userSettings?.countries}
                            country_states={this.state.country_states}
                            gateway_attribute={this.state.industry_types}
                            //onNewPaymentData={ ( data ) => { this.newFormPayments(data) }}
                            handleSearchCountryStates={(country) => {this.handleCountryStates(country)}}
                            inscription_form_template={this.props.state.userSettings?.form_payments}
                            //inscription_form_template={this.state.solve_form_data? this.fixBackendFormData(this.props.state.userSettings?.form_payments):this.props.state.userSettings?.form_payments}
                            handleSendFormData={(form_data)=>{this.handleSendFormData(form_data)}}
                            //handlePreviewForm={(questions) => { this.handlePreviewForm(questions) }}
                            handleFinalizePayments={() => {this.handleFinalizePayments()}}
                            handleCleanCountryForm={() => {this.props.cleanUserCountryformAction()}}
                            account_state={this.props.state.userSettings?.profile_account_status}
                            accountResponse={this.props.state.userSettings?.account_response ?? {}}
                            handlePaymentSolve={()=>{this.handlePaymentSolve()}}
                            solve_form_data={this.state.solve_form_data}
                            form_data={this.fixFormData(this.props.state.userSettings?.retrive_form_data)}
                            handleIndustryTypeLoad = { ()=>{ this.handleIndustryTypeLoad() } }
                            user_form_payment_loading= {this.props.state.userSettings?.user_form_payment_loading}
                        />
                    </div>
                </div>

            </React.Fragment>
         )
    }

};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userLogout: () => {
            return userLogout();
        },
        sendUserSettingsDataAction: (detail_data,new_data) => {
            dispatch( sendUserSettingsDataAction( detail_data,new_data))
        },
        changeUserSettingsError: ( data ) => {
            dispatch( changeUserSettingsError( data ))
        },
        setUserLanguageAction: (user_id, language) => {
            dispatch( setUserLanguageAction( user_id, language))
        },
        loadLanguagesAction: () => {
            dispatch( loadLanguagesAction())
        },
        changeUserPaymentsModalStateAction: (new_state) => {
            dispatch(changeUserPaymentsModalStateAction(new_state))
        },
        loadPaymentCountries: async(iata_code) => {
            await dispatch(loadPaymentCountries(iata_code))
        },
        loadPaymentCountryId:(owner_id) => {
            dispatch(loadPaymentCountryId(owner_id))
        },
        getUserGatewayAttribute: async (country_gateway_id, question_type) => {
            await dispatch(getUserGatewayAttribute(country_gateway_id, question_type))
        },
        loadPaymentsFormTemplate: async (form_type, owner_id) => {
            await dispatch(loadPaymentsFormTemplate(form_type, owner_id))
        },
        getUserCountries: async (country) => {
            await dispatch(getUserCountries(country));
        },
        getUserCountryStates: async (country) => {
            await dispatch(getUserCountryStates(country));
        },
        sendUserPaymentForms: async (form_data) => {
            await dispatch(sendUserPaymentForms(form_data))
        },
        cleanUserCountryformAction: () => {
            dispatch(cleanUserCountryformAction([]));
        },
        checkProfileAccountStatus:(account_id) => {
            dispatch(checkProfileAccountStatus(account_id))
        },
        getUserMeAfterAccount: async () => {
            await dispatch( getUserMeAfterAccount())
        },
    }
}    

export default compose(  
    withTranslation("profile"),  
    withRouter,    
    connect(mapStateToProps, mapDispatchToProps)
  )(UserSettingView);

