// React
import React from 'react';

// Components
import SignUpStartPanelComponent from 'app/authentication/components/signup-start-panel/SignUpStartPanelComponent';
import SignUpPhonePanelComponent from 'app/authentication/components/signup-phone-panel/SignUpPhonePanelComponent';
import SignUpOtpPanelComponent from 'app/authentication/components/signup-otp-panel/SignUpOtpPanelComponent';
import SignUpFormPanelComponent from 'app/authentication/components/signup-form-panel/SignUpFormPanelComponent';
import SignupFormUserName from 'app/authentication/components/signup-username-panel/SignupUserNamePanelComponent'
import SignupGoalPanelComponent from 'app/authentication/components/signup-goal-panel/SignupGoalPanelComponent'
import SignUpLoginPanelComponent from 'app/authentication/components/signup-login-panel/SignUpLoginPanelComponent';
import SignUpEmailPanelComponent from 'app/authentication/components/signup-email-panel/SignUpEmailPanelComponent';
import SignUpEmailCodeVerificationPanelComponent from 'app/authentication/components/signup-email-code-verification-panel/SignUpEmailCodeVerificationPanelComponent';
import SignUpEmailProfileTypePanelComponent from 'app/authentication/components/signup-email-specialty-panel/SignUpEmailProfileTypePanelComponent';
import LoginEmailPanelComponent from 'app/authentication/components/login-email-panel/LoginEmailPanelComponent';
import RecoverPasswordPanelComponent from 'app/authentication/components/recover-password-panel/RecoverPasswordPanelComponent';
import SignInEmailCodeRecoverPasswordComponent from 'app/authentication/components/signin-email-code-recover-password/SignInEmailCodeRecoverPasswordComponent';
import SignInNewPasswordRecoveryPanelComponent from 'app/authentication/components/signin-new-password-recovery-panel/SignInNewPasswordRecoveryPanelComponent';
import SignUpTeamPanelComponent from '../signup-team-panel/SignUpTeamPanelComponent';
import SignUpClaimAccountComponent from 'app/authentication/components/signup-claim-account/SignUpClaimAccountComponent';
import SignUpClaimAccountOptionsComponent from 'app/authentication/components/signup-claim-account-options/SignUpClaimAccountOptionsComponent';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';


// Function to select panel to show in SignUpComponent
function signUpPanelSelector( props ) {
    switch ( props.signup_status ) {
        case SIGNUP_STATUS['START']:
            return <SignUpStartPanelComponent />
        case SIGNUP_STATUS['LOGIN']:
            return <SignUpLoginPanelComponent />
        case SIGNUP_STATUS['PHONE']:
            return <SignUpPhonePanelComponent location_pathname={props.location_pathname} />
        case SIGNUP_STATUS['OTP']:
            return <SignUpOtpPanelComponent location_pathname={props.location_pathname}/>
        case SIGNUP_STATUS['FORM']:
            return <SignUpFormPanelComponent />
        case SIGNUP_STATUS['USERNAME_ASSIGNMENT']:
            return <SignupFormUserName />
        case SIGNUP_STATUS['GOAL']:
            return <SignupGoalPanelComponent />
        case SIGNUP_STATUS['EMAIL']:
            return <SignUpEmailPanelComponent />
        case SIGNUP_STATUS['EMAIL_VERIFICATION']:
            return <SignUpEmailCodeVerificationPanelComponent location_pathname={props.location_pathname}/>
        case SIGNUP_STATUS['LOGIN_EMAIL']:
            return <LoginEmailPanelComponent />
        case SIGNUP_STATUS['PROFILE_TYPE']:
            return <SignUpEmailProfileTypePanelComponent />
        case SIGNUP_STATUS['RECOVER_PASSWORD']:
            return <RecoverPasswordPanelComponent />
        case SIGNUP_STATUS['RECOVER_PASSWORD_VERIFICATION']:
            return <SignInEmailCodeRecoverPasswordComponent />
        case SIGNUP_STATUS['NEW_PASSWORD_EMAIL']:
            return <SignInNewPasswordRecoveryPanelComponent />
        case SIGNUP_STATUS['TEAM_PANEL']:
            return <SignUpTeamPanelComponent />
        case SIGNUP_STATUS['CLAIM_ACCOUNT']:
            return <SignUpClaimAccountComponent />
        case SIGNUP_STATUS['CLAIM_ACCOUNT_OPTION']:
            return <SignUpClaimAccountOptionsComponent />
        default: 
            break;
    }
}

function SignUpPanelComponent( props ) {
    return (
        <>{ signUpPanelSelector( props ) }</>
    )
}

export default SignUpPanelComponent;
