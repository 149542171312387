// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './HomeUpdateLocationModalComponent.styles.css';

// Assets
import location_icon from 'assets/icons/location.svg'

// i18n
import { withTranslation } from 'react-i18next';


function HomeUpdateLocationModalComponent( props ) {

    const { children, value, index } = props; // eslint-disable-line

    return (
        
        <ModalComponent 
            isOpen={props.isOpen} 
            className="home-update-location"        
        >
            
            <div className="home-update-location__container">  
                <div className="home-update-location__icon">
                    <img src={location_icon} alt="" />
                </div>
                <div className="home-update-location__title baloa-subtitle-1">  
                    <span>{props.t('updLocation.title')}</span>
                </div>
                <div className="home-update-location__subtitle baloa-table">  
                    <span>{props.t('updLocation.subtitle')}</span>
                </div>
                <div className="home-update-location__separator"></div>
                <div className="home-update-location__buttons">           
                    <SecondaryButtonComponent className="small" onClick={ props.onClose }>
                        <span>{props.t('updLocation.cancelBtn')}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent  className="small" onClick={ props.onHandleAccept }>
                        <span>{props.t('updLocation.acceptBtn')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>

        </ModalComponent>

        )
    }
    
    export default withTranslation('home')(HomeUpdateLocationModalComponent);