/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { compose } from 'redux';

// React device detect
// import { isMobile } from 'react-device-detect';

//Actions
import {
    loadPaymentsFormTemplate,
    loadPaymentCountryApi,
    loadPaymentCountryIdApi,
    getCountries,
    getGatewayAttribute,
    getCountryStates,
    sendPaymentForms,
    checkPageAccountStatus,
} from '../actions/PaymentsActions';

import { loadPage } from 'app/pages/actions/PageActions';

//Actions creators
import{changePaymentsModalStateAction,
    changeClickType,
    cleanCountryformAction,
    // loadPaymentCountryApiAction
} from '../actions/PaymentsActionsCreators';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import PaymentsFormActiveComponent from 'app/payments/components/payments-form/PaymentsFormActiveComponent'

//Modals
import PaymentsActiveModalComponent from 'app/payments/components/payments-active-modal/PaymentsActiveModalComponent';


// Styles
import './PaymentsView.styles.css';
import undraw_credit_card_re_blml from 'assets/images/undraw_credit_card_re_blml.png';
import UserPaymentSettingComponent from 'app/user-settings/components/user-payments-settings/UserPaymentSettingComponent';

class PaymentsView extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            openModal: false,
            typeModal: '',
            titleModal: '',
            subTitleModal: '',
            country_tag: undefined,
            sections:[],
            type_tag:[
                {value: 'account_individual_form', content: 'Individual'},
                {value: 'account_company_form', content: 'Compañia'}
            ],
            activate_payments: false,
            payments_form: false,
            industry_types: [],
            country_states: [],
            confirmation: false,
        };
    }

    
    componentDidMount() {
        this.props.loadPaymentCountryApi();
    }
    componentDidUpdate(prevProps){
    }

    handelModalActive(){
        let countries_array= []
        this.props.state.payments?.country_payments.forEach(country => {
            countries_array.push({
            value:country.owner_id,
            content:country.country
            })
        })
        this.setState({country_tag:countries_array})
        this.props.changePaymentsModalStateAction(true)        
    }

    handelOnloadPaymentForm = async (form_type, owner_id) =>{
        // this.props.loadPaymentCountryIdApi(owner_id);
        await this.props.loadPaymentsFormTemplate(form_type, owner_id);        
        await this.props.getGatewayAttribute(this.props.state.payments?.form_payments.owner.owner_id, 'industry_type');
        this.handleIndustryTypes();        
        this.props.changePaymentsModalStateAction(false)
        this.setState({activate_payments: true})
        this.setState({payments_form: true})
    }

    handleIndustryTypes () {
        let industry_types_array = [];
        this.props.state.payments?.gateway_attribute.forEach(industry_type => {
            industry_types_array.push({
                value:industry_type.value,
                content:industry_type.caption
            })
        })
        this.setState({industry_types: industry_types_array})
    }

    handleBackFormPayments(){
        this.setState({activate_payments: false})
        this.setState({payments_form: false})
    }

    handleSendFormData = async (form_data) => {
        let form_questions_array = [];
        form_data.forEach((form, index) => {
            form.forEach((question) => {
                form_questions_array.push(question);
            })
        });
        let new_form_data = {
            page_id: this.props.state.page.page?.id,
            form_template: this.props.state.payments.form_payments?.id,
            questions: form_questions_array
        };
        
        await this.props.sendPaymentForms(new_form_data);
        await this.props.loadPage(this.props.state.page.page.pagename);
        if(!this.props.state.payments?.is_onboarding_form_invalid) {
            this.handleFinalizePayments();
            this.props.checkPageAccountStatus(this.props.state.page.page.stripe_account_id);
        }
    }

    handleCountryStates = async(country) => {
        await this.props.getCountryStates(country);
        let country_states_array = [];
        this.props.state.payments?.country_states.forEach(state => {
            country_states_array.push({
                value: state.id,
                content: state.name,
                country: state.country,
            })
        });
        this.setState({country_states: country_states_array});
    }

    handleFinalizePayments(){
        this.setState({activate_payments: false})
        this.setState({payments_form: false})
        this.setState({confirmation: true})
    }

    handleCloseInscriptionViewFormDataModal () {
        this.setState({preview_dynamic_form: []});
        // this.props.changeTournamentDynamicFormsModalStateAction(false);
    }



    render() {
        return (
            <div className='payments'>
                <div className="payments__left-container"></div>
                    <div className="payments__center-container">
                        {/*Modals*/}
                        <PaymentsActiveModalComponent
                            onClick={() => { }}
                            type_tag={this.state.type_tag}
                            isOpen={this.props.state.payments.modals.active_modal_is_open}
                            onClose={() => {this.props.changePaymentsModalStateAction(false) } }
                            country_tag={this.state.country_tag}
                            //errors={this.props.state.page.errors.creation}
                            //onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}
                            handelOnloadPaymentForm = {(form_type, owner_id)=>{this.handelOnloadPaymentForm(form_type, owner_id,'1')}}
                        /> 
                        <div className="payments__center-container-title">
                            { this.state.payments_form &&
                                <div className="baloa-headline-2">
                                    <span className="icon-arrow-left" onClick={() => {this.handleBackFormPayments()}} />
                                    {this.props.t( 'paymentButtomActive')}
                                </div>
                            }
                            { !this.state.payments_form &&
                                <div className="baloa-headline-2">
                                    {this.props.t('paymentsTitle')}
                                </div>
                            }
                        </div>
                       {!this.props.state.page.page.create_stripe_account && !this.state.payments_form &&
                        <div className='payments__center-container__activate-payments-container'>
                            <div className="payments__center-container-text">
                                <div className=" baloa-normal-text">
                                    <span className="hubicon-info" />
                                    { !this.state.confirmation
                                        ?this.props.t('paymentsText')
                                        :this.props.t('paymentsVerification')
                                    }
                                </div>
                                { !this.state.confirmation &&
                                    <PrimaryButtonComponent
                                    input_type='button'
                                    className=''
                                    onClick={ () => {this.handelModalActive()}}
                                >
                                    <span>{this.props.t('paymentButtomActive')}</span>
                                </PrimaryButtonComponent>}
                            </div> 
                            <div className="payments__center-container-img">
                                <img  src={undraw_credit_card_re_blml} alt=""/>
                            </div>
                        </div>
                        }
                        {this.props.state.page.page.create_stripe_account &&
                            <React.Fragment>
                                {(this.props.state.payments.page_account_status === 'Pending' /*|| props.account_state === 'Gateway_Error'*/)? 
                                    <div className='payments__center-container__activate-payments-container'>
                                        <div className="payments__center-container-text">
                                            {//props.account_state === 'Pending'?
                                                <React.Fragment>
                                                    
                                                    <div className="baloa-normal-text">
                                                        <span className="hubicon-info" />
                                                        {this.props.t('paymentsVerification')}
                                                    </div>
                                                </React.Fragment>                                        
                                                /*: 
                                                <React.Fragment>
                                                    <div className="baloa-normal-text">
                                                        <span className="hubicon-info error" />
                                                        <div>
                                                            {t('settings.userPayment.paymentsErrorsTitle')}   
                                                            <div className="baloa-names">
                                                                {t('settings.userPayment.paymentsErrorsMsg')}
                                                            </div>     
                                                        </div>                                    
                                                    </div>                               
                                                </React.Fragment>*/
                                            }                                                                
                                            {/* (this.props.state.payments.page_account_status === 'Gateway_Error') &&
                                                <PrimaryButtonComponent
                                                    input_type='button'
                                                    className='xs'
                                                    onClick={ () => {props.handlePaymentSolve()}}
                                                >
                                                    <span>{t('settings.userPayment.paymentButtomSolve')}</span>
                                                </PrimaryButtonComponent>
                                            */}
                                        </div> 
                                        <div className="payments__center-container-img">
                                            <img  src={undraw_credit_card_re_blml} alt=""/>
                                        </div>
                                    </div>
                                :
                                    <div>
                                        <UserPaymentSettingComponent
                                            accountResponse={this.props.state.payments?.page_account_response }
                                            bank_account={true}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        }
                        { this.state.payments_form &&
                            <PaymentsFormActiveComponent
                                handleSearchNationality={(country) => {this.props.getCountries(country)}}
                                countries={this.props.state.payments?.countries}
                                country_states={this.state.country_states}
                                gateway_attribute={this.state.industry_types}
                                onNewData={ ( data ) => { this.newFormPayments(data) }}
                                handleSearchCountryStates={(country) => {this.handleCountryStates(country)}}
                                inscription_form_template={this.props.state.payments?.form_payments}
                                handleSendFormData={(form_data)=>{this.handleSendFormData(form_data)}}
                                handlePreviewForm={(questions) => { this.handlePreviewForm(questions) }}
                                handleFinalizePayments={() => {this.handleFinalizePayments()}}
                                handleCleanCountryForm={() => {this.props.cleanCountryformAction()}}
                                onboarding_form_payment_loading={ this.props.state.payments?.onboarding_form_payment_loading }
                            />
                        } 
                    </div>     
            </div>    
        )
    }
}
    
// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changePaymentsModalStateAction: (data) => {
            dispatch(changePaymentsModalStateAction(data))
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        // loadPaymentCountryApiAction: (key) => {
        //     dispatch(loadPaymentCountryApiAction(key))
        // },
        loadPaymentCountryApi:(iata_code) => {
            dispatch(loadPaymentCountryApi(iata_code))
        },
        loadPaymentCountryIdApi:(owner_id) => {
            dispatch(loadPaymentCountryIdApi(owner_id))
        },
        getCountries: async (country) => {
            await dispatch(getCountries(country));
        },
        getGatewayAttribute: async (country_gateway_id, question_type) => {
           await dispatch(getGatewayAttribute(country_gateway_id, question_type))
        },
        loadPaymentsFormTemplate: async (form_type, owner_id) => {
            await dispatch(loadPaymentsFormTemplate(form_type, owner_id))
        },
        sendPaymentForms: async (form_data) => {
            await dispatch(sendPaymentForms(form_data))
        },
        
        // loadPaymentCountryIdApi:(owner_id) => {
        //     dispatch(loadPaymentCountryIdApi(owner_id))
        // },   

        getCountryStates: async (country) => {
           await dispatch(getCountryStates(country));
        },

        cleanCountryformAction: () => {
            dispatch(cleanCountryformAction([]));
        },
        checkPageAccountStatus:(account_id) => {
            dispatch(checkPageAccountStatus(account_id))
        },
        loadPage: async ( username ) => {
            await dispatch( loadPage( username ) )
        },
    }
}

export default compose(
    withTranslation("payments"),
    connect(mapStateToProps, mapDispatchToProps)
)(PaymentsView);