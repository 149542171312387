// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-phases__content_reviewPhase{
    width: 100%;
    padding: var(--space);
    background: #FFFFFF;
    border: 2px solid #E7E7E7;
    border-radius: 8px;
}

.tournament-phases__content_reviewPhase-text{
    display: flex;
    margin-top: var(--space);
}

.tournament-phases__content_reviewPhase-general{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: var(--space) 10%;
}


.tournament-phases__content_reviewPhase_button{
    display: grid;
    justify-content: end;
    margin: var(--space-x3) 0;
}

.tournament-phases__container-text{
    display: flex;
}

.tournament-phases__container-text .color-text-blob{
    margin-left: var(--space-half);
    font-weight: var(--font-medium);
    color: var(--primary-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phases-summary/TournamentPhasesSummaryComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,wBAAwB;AAC5B;;;AAGA;IACI,aAAa;IACb,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,gCAAgC;AACpC","sourcesContent":[".tournament-phases__content_reviewPhase{\n    width: 100%;\n    padding: var(--space);\n    background: #FFFFFF;\n    border: 2px solid #E7E7E7;\n    border-radius: 8px;\n}\n\n.tournament-phases__content_reviewPhase-text{\n    display: flex;\n    margin-top: var(--space);\n}\n\n.tournament-phases__content_reviewPhase-general{\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    margin: var(--space) 10%;\n}\n\n\n.tournament-phases__content_reviewPhase_button{\n    display: grid;\n    justify-content: end;\n    margin: var(--space-x3) 0;\n}\n\n.tournament-phases__container-text{\n    display: flex;\n}\n\n.tournament-phases__container-text .color-text-blob{\n    margin-left: var(--space-half);\n    font-weight: var(--font-medium);\n    color: var(--primary-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
