// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Styles
import './PageSuggestionsModalComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';


// ProfileSuggestions Component:
//   Renders Profile suggestions and handles their actions.

function PageSuggestionsModalComponent( props ) {

    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
            className={"page-suggestions-modal"}
        >
                <div className="page-suggestions-modal__container scroll-parent">                    
                    <div className="page-suggestions-modal__list scroll">
                        {props.pages.map( page => (
                                <div className="page-suggestions-modal__list__page-item">
                                    <Link to={`/page/${page.pagename}/`} className="page-suggestions-modal__suggestion__page-info">
                                        {page.photo &&
                                            <img src={ page.photo } alt=""/>
                                        }
                                        {!page.photo &&
                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                        }                            
                                        <div className="page-suggestions-modal__list__page-item__data">
                                            <div className="page-suggestions-modal__list__page-item__data-name baloa-names">{ page.name }</div>
                                            <div className='page-suggestions-modal__list__page-item__data-username baloa-username'>@{ page.pagename }</div>
                                        </div>
                                    </Link>
                                    <div className="page-suggestions-modal__suggestion__follow-button">
                                        <RoundedButtonComponent
                                            onClick = {() => { props.handleFollow( page ) }} 
                                            className="xs"
                                        >
                                            <span>{props.t('follower.request.button.follow')}</span>
                                        </RoundedButtonComponent>
                                    </div>
                                </div>
                            ))    
                        }                                         
                    </div>
                </div>
             
        </Modal2Component>
    )
}

// Exports
export default withTranslation('page')(PageSuggestionsModalComponent);
