// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-post-formation-modal__container{
    display: flex;
    align-items: flex-start;
}

.profile-post-formation-modal__container .post-formation__data__positions-graph{
    width: 45%;
}

.profile-post-formation-modal__container .show-post-formation-field__container__img{
    width: -webkit-fill-available;
}

.profile-post-formation-modal__container img.show-post-formation-field__field-img{
    width: inherit;
}

.profile-post-formation-modal__container .show-post-field__container {
    display: flex;
    justify-content: center;
    position: relative;
}

.profile-post-formation-modal__container .show-post-field__positions {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.profile-post-formation-modal__container .show-post-formation-field{
    width: inherit;
    height: inherit;
    justify-content: space-between;
}

.profile-post-formation-modal__comments{
    width: 55%;
}

.profile-post-formation-modal__comments .post__actions__action{
    margin-right: unset;
}



@media screen and (max-width: 1600px){
    .profile-post-formation-modal .modal2__container{
        width: 60%;
    }
}

@media screen and (max-width: 1100px){
    .profile-post-formation-modal .modal2__container{
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-post-formation-modal/ProfilePostFormationModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;;;AAIA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".profile-post-formation-modal__container{\n    display: flex;\n    align-items: flex-start;\n}\n\n.profile-post-formation-modal__container .post-formation__data__positions-graph{\n    width: 45%;\n}\n\n.profile-post-formation-modal__container .show-post-formation-field__container__img{\n    width: -webkit-fill-available;\n}\n\n.profile-post-formation-modal__container img.show-post-formation-field__field-img{\n    width: inherit;\n}\n\n.profile-post-formation-modal__container .show-post-field__container {\n    display: flex;\n    justify-content: center;\n    position: relative;\n}\n\n.profile-post-formation-modal__container .show-post-field__positions {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 10;\n    width: -webkit-fill-available;\n    height: -webkit-fill-available;\n}\n\n.profile-post-formation-modal__container .show-post-formation-field{\n    width: inherit;\n    height: inherit;\n    justify-content: space-between;\n}\n\n.profile-post-formation-modal__comments{\n    width: 55%;\n}\n\n.profile-post-formation-modal__comments .post__actions__action{\n    margin-right: unset;\n}\n\n\n\n@media screen and (max-width: 1600px){\n    .profile-post-formation-modal .modal2__container{\n        width: 60%;\n    }\n}\n\n@media screen and (max-width: 1100px){\n    .profile-post-formation-modal .modal2__container{\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
