
// PropTypes
import PropTypes from 'prop-types';


export const PageShape = { 
    id: PropTypes.string, 
    page_type: PropTypes.oneOf(['player', 'fan']),
    pagename: PropTypes.string,
}
