import React, { useState } from 'react';

//Components
import ComposeInscriptionFormComponent from 'app/tournaments/components/compose-inscription-form/ComposeInscriptionFormComponent'
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './UserPaymentsFormActiveComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

// React device detect
import { isMobile } from 'react-device-detect';


function UserPaymentsFormActiveComponent(props) {
    
    const FORM_TEMPLATE_SECTIONS = props.inscription_form_template;

    const [submitButton, setSubmitButton] = useState(false);
    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);
    const [ step3, setStep3 ] = useState(false);
    const [ step4, setStep4 ] = useState(false);
    const [ step5, setStep5 ] = useState(false);// eslint-disable-line  
    const [ steper2, setSteper2 ] = useState(false);
    const [ steper3, setSteper3 ] = useState(false);
    const [ steper4, setSteper4 ] = useState(false);
    const [ steper5, setSteper5 ] = useState(false);
    const [ valueStep, setValueStep ] = useState(0);
    const [ formAnswers, setformAnswers]= useState([]);  // eslint-disable-line
    const [ lastStep, setLastStep] = useState(0);

    const [ completedForms, setCompletedForms] = useState([]); // eslint-disable-line
    const [ fulfilledForm, setFulfilledForm ] = useState(false);
    const [ formIndex, setFormIndex ] = useState(0);   // eslint-disable-line  
    const [ submitForm, setSubmitForm ] = useState(true);

    const [ findCountry, setFindCountry ] = useState(false);

    React.useEffect(() => { 
        if(props.retrive_data && !fulfilledForm){
            prepareRetriveData(props.inscription_form_template);
        }
    }, [props.retrive_data]);// eslint-disable-line 

    /**
     * Function to be able to send the json of the answers of the forms.
     * @param {Number} form_index -  The index of the form by section
     */
    const handleSendFormData = (form_index) => {
        if(form_index === (lastStep -1) && submitForm) {
            props.handleSendFormData(formAnswers);
        }
        setSubmitForm(true);
    }
    /**
     * Function in charge of adding a step to the next form validating that the questions are filled in.
     * @param {*} value -  Object containing the answer of each section
     * @param {String} step  - String indicating the active step
     * @param {Number} form_index - The index of the form by section
     * @returns 
     */
    const handleNextButton=(value, step, form_index) => {
        props.handleCleanCountryForm();
        setFormIndex(form_index); 
        if(!props.retrive_data){
            setFulfilledForm(false);
        }

        handleCompletedForms(validationJsonAnswers(value), form_index);

        if(formAnswers[form_index + 1] || form_index === (lastStep -1)) {
            setFulfilledForm(true);
            let is_country = undefined;
            if(form_index === (lastStep -1)){
                is_country = completedForms[form_index].questions.find( question => question.question_type.caption === 'country');
            }else{
                is_country = completedForms[form_index + 1].questions.find( question => question.question_type.caption === 'country');
            }
            if(is_country) {
                props.handleSearchNationality(is_country.value);
            }
        }
        //Se valida que sea el ultimo formulario con informacion para validar si se envia al backend
        if(!formAnswers[form_index + 1]) {
            handleSendFormData(form_index);
        }


        switch(step) {
            case 'step1':
                setStep1(false);
                setStep2(true);
                setSteper2(true);
                // setSubmitButton(false);
                validationHideSection(form_index + 1);
                setValueStep(2)
                break;
            case 'step2':
                setStep2(false);
                setStep3(true);
                setSteper3(true);
                // setSubmitButton(false);
                validationHideSection(form_index + 1);
                setValueStep(3)
                break;
            case 'step3':
                setStep3(false);
                setStep4(true);
                setSteper4(true);
                // setSubmitButton(false);
                validationHideSection(form_index + 1);
                setValueStep(4)
                break;
            /* case 'step4':
                if(props.inscription_form_template.form_type.caption === "account_company_form" ){
                    setStep4(false);
                    setStep5(true);
                    setSteper5(true);
                    setSubmitButton(false);
                    setValueStep(5)
                }
                break; */
            default:
                return ''
        }
        
    };
    
    /**
     * Validation on json type question constructing the answer format
     * @param {*} value -- Object containing the answer of each section
     * @returns Objest with new answer format by json type question or orginal format question
     */
    const validationJsonAnswers = (value) =>{
        let json_answer=[];
        let newJsonAnswer= {};
        let form_question_template = ''

        if(value.form_template_is_json){
            value.questions.forEach((answer) => {
                form_question_template = answer.form_question_template.split('_key_')[0]
                const answer_key = answer.form_question_template.split('_key_')[1];
                const answer_value = answer.text_answer;
                newJsonAnswer[`${answer_key}`] = answer_value;
            });
            json_answer.push(newJsonAnswer);
        };

        return value.form_template_is_json 
        ?  {...value, 
            questions:[{
                json_answer:json_answer,
                form_question_template:form_question_template,
                text_answer: '',
                file_document: null,
                date_field: null,
                boolean_answer: null,
                answers: []
            }]
        } 
        : value;
    }

    /**
     * Function in charge of eliminating a step to the previous form by validating the country type question.
     * @param {Boolean} -- Flag for save state answer  
     */

    const handleBackStep=(value) =>{
        // let backStep = null;
        if(value){
            //ultimo paso hacia atras bloquea envio de form
            setSubmitForm(false);
        }
        
        setTimeout(()=>{
            setFulfilledForm(true);
            let backStep = (valueStep-1)
            let is_country = null;
            if(completedForms[backStep-1].hide === true){
                const backHideStep = completedForms[backStep-2].hide === true ? 0 : 1
                is_country = completedForms[backHideStep].questions.find( question => question.question_type.caption === 'country');
            }else{
                is_country = completedForms[backStep - 1].questions.find( question => question.question_type.caption === 'country');
            }
            if(is_country) {
                props.handleCleanCountryForm();
                props.handleSearchNationality(is_country.value)
            }
            if(backStep === 1){
                setStep1(true);
                setStep2(false);
                setSteper2(false);
                setValueStep(1)
            }else if(backStep === 2  && completedForms[backStep-1].hide === false){
                setStep2(true);
                setStep3(false);
                setSteper3(false);
                setValueStep(2)
            }else if(backStep === 2  && completedForms[backStep-1].hide === true){
                setStep1(true);
                setStep2(false);
                setSteper2(false);
                setValueStep(1)
            }else if(backStep === 3 && completedForms[backStep-1].hide === false){
                setStep3(true);
                setStep4(false);
                setSteper4(false);
                setValueStep(3)
            }else if(backStep === 3 && completedForms[backStep-1].hide === true){
                setValueStep(completedForms[backStep-2].hide === true ? 1 : 2)
                if(completedForms[backStep-2].hide === true){
                    setStep1(true);
                    setStep2(false);
                    setSteper2(false);
                }else{
                    setStep2(true);
                    setSteper2(true);
                }
                setStep3(false);
                setSteper3(false);
                setStep4(false);
                setSteper4(false);
            }else if(backStep === 4 && steper5){
                setStep4(true);
                setStep5(false);
                setSteper5(false);
                //setformAnswers(value);
                setValueStep(4)
            }
        }, 500);
        
    }

    /**
     * Function form validating that the questions are filled of each section and save
     * @param {Array} form_values - Object/Array containing the answer/questions of each section
     * @param {number} form_index - Index of active section
     */
    function handleCompletedForms (form_values, form_index) { 
        let questions = [];
        let form_answers = [];
        form_values.questions.forEach( (form_answer, index) => {// eslint-disable-line  
            let new_answer_full = FORM_TEMPLATE_SECTIONS.sections[form_index].questions.find( question => question.id === form_answer.form_question_template);
            
            let new_answer = cloneDeep(new_answer_full);
            
            if(props.retrive_data && new_answer){
                new_answer = new_answer.form_question_template;
            }
            
            if(new_answer) {
                if(typeof(form_answer.text_answer) === 'string') {
                    new_answer.value = form_answer.text_answer;
                }
                if(form_answer.date_field !== null) {
                    new_answer.value = form_answer.date_field;
                }
                if(form_answer.json_answer) {
                    new_answer['json_answer'] = form_answer.json_answer;
                }
                if(form_answer.file_document){
                    new_answer.value = form_answer.file_document
                }
                if(!form_answer.date_field && !form_answer.boolean_answer && !form_answer.file_document && form_answer.text_answer === ""){
                    let checked_answ = form_answer.answers.filter(answ => answ.is_checked === true);
                    new_answer.answers.map(ansrs =>{ // eslint-disable-line  
                        let selected =  checked_answ.find(chkans => chkans.id === ansrs.id);
                        if(selected){
                            ansrs.is_default = true
                        }else{
                            ansrs.is_default = false
                        }
                    });
                }
                questions.push(new_answer);
                form_answers.push(form_answer);
            }else if(form_answer.form_question_template.includes('_country')) {
                let country_question = FORM_TEMPLATE_SECTIONS.sections[form_index].questions.find(question => question.question_type.caption === 'country_state');
                if(props.retrive_data){
                    let find_country_question = FORM_TEMPLATE_SECTIONS.sections[form_index].questions.find(question => question.form_question_template.question_type.caption === 'country_state');
                    country_question = find_country_question.form_question_template;
                }
                let new_question = cloneDeep(country_question);
                new_question.id = `${country_question.id}_country`;
                new_question.question_type.caption = 'country';
                new_question.value = form_answer.text_answer;
                questions.push(new_question);
            }
        })

        completedForms.splice(form_index, 1, {id:  FORM_TEMPLATE_SECTIONS.sections[form_index].id, questions: questions, hide: FORM_TEMPLATE_SECTIONS.sections[form_index].hide})
        formAnswers.splice(form_index, 1, form_answers)
    }

    function handleFormFulfilledStatus (index_form)  {   
        if(fulfilledForm ) {
            return completedForms[index_form];
        } else {
            return FORM_TEMPLATE_SECTIONS.sections[index_form];
        }
    }

    const getBase64FromUrl = (url, base64data) => {
        let file_parts = url.split("/");
        let file_name = file_parts[file_parts?.length - 1]
        let file = file_name + ";" + base64data;
        return file;
    }

    /**
     * Function called when the form is complete and builds the response for each section.
     * @param {Array} form_data  - Array of form by sections
     */
    function prepareRetriveData(form_data) { 
        
        form_data.sections.forEach( ( form_values, form_index ) => {
            let questions = [];
            let form_answers = [];
            form_values.questions.forEach( (form_question, index) => {// eslint-disable-line
                let answers = [];
                if(form_question.answers.length > 0){
                    form_question.answers.map( answ => { // eslint-disable-line
                        answers.push({id: answ.form_template_answer.id, is_default: answ.is_checked, caption: answ.form_template_answer.caption })
                    })
                }
   
                let new_answer =  form_question.form_question_template;
                if(typeof(form_question.text_answer) === 'string') {
                    new_answer.value = form_question.text_answer;
                }
                if(form_question.date_field !== null) {
                    new_answer.value = form_question.date_field;
                }
                if(form_question.json_answer) {
                    new_answer['json_answer'] = form_question.json_answer;
                }
                if(form_question.file_document){
                    //Agregamos el nombre del archivo al stringBase64
                    new_answer.value = getBase64FromUrl(form_question.file_document, form_question.parent_data);
                }

                //Agregamos el error_code
                new_answer.error_code = form_question.error_code;  

                new_answer.answers = answers;
                questions.push(new_answer);
        
                form_answers.push(form_question.form_question_template);

                if(form_question.form_question_template.question_type.caption === 'country_state') {
                    let new_question = cloneDeep(form_question.form_question_template);
                    new_question.id = `${form_question.form_question_template.id}_country`;
                    new_question.question_type.caption = 'country';
                    new_question.value = form_question.parent_data;
                    questions.push(new_question);
                    //Traemos el listado de paises en el primer formulario que se encuentre
                    if(!findCountry){
                        props.handleSearchNationality(form_question.parent_data);
                        setFindCountry(true);
                    }
                }
                if(form_question.form_question_template.question_type.caption === 'industry_type') {
                    props.handleIndustryTypeLoad()                
                } 
            })

            completedForms.splice(form_index, 1, {id:  form_values.id, questions: questions, hide: form_values.hide})
            formAnswers.splice(form_index, 1, form_answers)
        })
        setFulfilledForm(true);
    }

    /**
     * Function for validation hide section and set a state /flag
     * @param {Number} index_form 
     */
    const validationHideSection =(index_form)=>{
        if(FORM_TEMPLATE_SECTIONS.sections[index_form].hide === true){
            setSubmitButton(true);
        }else{
            setSubmitButton(false);
        }
    }

    return (
        <div className="payment-forms_parent">
            <div className="payment-forms_parent__stepper">
                <div className='step-point__container'>
                    {!props.inscription_form_template.sections[0].hide &&
                        <div className={ "step-point" }>
                            {!isMobile && 
                                    <div className='baloa-username step-point-label-completed'>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[0].code}`)}</div>
                            }
                            <div className='point step-point-completed' />
                        </div>
                    }
                    { !props.inscription_form_template.sections[1].hide &&
                        <div className={ "step-point" }>
                            {!isMobile && 
                                <div className={`baloa-username ${steper2? 'step-point-label-completed': ''}`}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[1].code}`)}</div>
                            }
                            <div className={`point ${steper2? 'step-point-completed': ''}`} />
                        </div>
                    }
                    { !props.inscription_form_template.sections[2].hide &&
                        <div className={ "step-point" }>
                            {!isMobile && 
                                <div className={`baloa-username ${steper3? 'step-point-label-completed': ''}`}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[2].code}`)}</div>
                            }
                            <div className={`point ${steper3? 'step-point-completed': ''}`} />
                        </div>
                    }
                    { !props.inscription_form_template.sections[3].hide &&
                        <div className={ "step-point" }>
                            {!isMobile &&  
                                <div className={`baloa-username ${steper4? 'step-point-label-completed': ''}`}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[3].code}`)}</div>
                            }
                            <div className={`point ${steper4? 'step-point-completed': ''}`} />
                        </div>                    
                    }
                </div>
                <div className='payments__step-line__container'>
                    <div className={ `step-line ${ steper2? 'step-line-completed': ''}` } />
                    <div className={ `step-line ${ steper3? 'step-line-completed': ''}` } />
                    <div className={ `step-line ${ steper4? 'step-line-completed': ''}` } />
                </div>
            </div>

            
                <div className='payment-forms_parent_mobile_label'>
                    {(!steper2 && !steper3 && !steper4) &&
                        <div className='payment-forms_parent_mobile_label_subLabel'>
                            {isMobile && <div className='baloa-table'>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[0].code}`)}</div>}
                            <div className='baloa-table-column'>{props.t('payments:paymentLabelPersonalData')}</div>
                        </div>
                    }
                    {(steper2 && !steper3 && !steper4) &&
                        <div className='payment-forms_parent_mobile_label_subLabel'>
                            {isMobile && <div className={'baloa-table'}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[1].code}`)}</div>}
                            <div className='baloa-table-column'>{props.t('payments:paymentLabelIndustryData')}</div>
                        </div>
                            }
                    {(!steper2 && steper3 && !steper4) &&
                        <div className='payment-forms_parent_mobile_label_subLabel'>
                            {isMobile && <div className={'baloa-table '}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[2].code}`)}</div>}
                            <div className='baloa-table-column'>{props.t('payments:paymentLabelBankStatements')}</div>
                        </div>
                    }
                    {(!steper2 && !steper3 && steper4) &&
                        <div className='payment-forms_parent_mobile_label_subLabel'>
                            {isMobile &&  <div className={'baloa-table'}>{props.t(`settings.userPayment.modal.${props.inscription_form_template.sections[3].code}`)}</div>}
                            <div className='baloa-table-column'>{props.t('payments:paymentLabelCreditCard')}</div>
                        </div>
                    }
                </div>

            { step1 &&
                <ComposeInscriptionFormComponent
                    inscription_form_template={ handleFormFulfilledStatus(0) }
                    onNewData = { (value)=>{handleNextButton(value,'step1', 0)}}
                    submitForm={submitButton}
                    resetButton={()=>{setSubmitButton(false)}}
                    countries={props.countries}
                    country_states={props.country_states}
                    handleSearchNationality={ props.handleSearchNationality }
                    gateway_attribute={props.gateway_attribute}
                    handleSearchCountryStates={props.handleSearchCountryStates}
                    fulfilled_form={fulfilledForm}
                    retrive_data={props.retrive_data}
                /> 
            } 
            { step2 &&
                <ComposeInscriptionFormComponent
                    inscription_form_template={ handleFormFulfilledStatus(1) }
                    onNewData = { (value)=>{handleNextButton(value,'step2', 1)}}
                    submitForm={submitButton}
                    resetButton={()=>{setSubmitButton(false)}}
                    countries={props.countries}
                    country_states={props.country_states}
                    handleSearchNationality={ props.handleSearchNationality }
                    gateway_attribute={props.gateway_attribute}
                    handleSearchCountryStates={props.handleSearchCountryStates}
                    fulfilled_form={fulfilledForm}
                    retrive_data={props.retrive_data}
                />                     
            } 
            { step3 &&
                <ComposeInscriptionFormComponent
                    inscription_form_template={ handleFormFulfilledStatus(2) }
                    onNewData = { (value)=>{handleNextButton(value,'step3', 2)}}
                    submitForm={submitButton}
                    resetButton={()=>{setSubmitButton(false)}}
                    countries={props.countries}
                    country_states={props.country_states}
                    handleSearchNationality={ props.handleSearchNationality }
                    gateway_attribute={props.gateway_attribute}
                    handleSearchCountryStates={props.handleSearchCountryStates}
                    fulfilled_form={fulfilledForm}
                    retrive_data={props.retrive_data}
                />  
            } 
            { step4 &&
                <ComposeInscriptionFormComponent
                    inscription_form_template={ handleFormFulfilledStatus(3) }
                    onNewData = {(value)=>{handleNextButton(value,'step4', 3)}}
                    submitForm={submitButton}
                    resetButton={()=>{setSubmitButton(false)}}
                    countries={props.countries}
                    country_states={props.country_states}
                    handleSearchNationality={ props.handleSearchNationality }
                    gateway_attribute={props.gateway_attribute}
                    handleSearchCountryStates={props.handleSearchCountryStates}
                    fulfilled_form={fulfilledForm}
                    retrive_data={props.retrive_data}
                />    
            }

            {/*Botones*/}
            
            {/* { props.inscription_form_template.form_type.caption === "account_company_form" && !steper5 && */}
                <div className='payment-forms-active__step'> 
                    <div className='payment-forms_parent__buttons'>
                        { !step1 &&
                            <SecondaryButtonComponent
                            type="button"
                            id="payment-data__back-btn"
                            onClick={ () => {
                                handleBackStep(step4); 
                                step4 && setSubmitButton(true);  
                            } } 
                            disabled={false}
                            >
                                <span>{props.t('payments:paymentButtons.backButton')}</span>
                            </SecondaryButtonComponent>
                        }
                        <PrimaryButtonComponent
                            type={'button'}
                            onClick={ ()=>{
                                /* if(props.inscription_form_template.form_type.caption === "account_company_form" ){
                                    setLastStep(4)
                                    // if(steper5)
                                    // {props.handleFinalizePayments()}
                                }else{
                                    setLastStep(3)
                                    // if(steper4)
                                    // {props.handleFinalizePayments()}
                                } */
                                setLastStep(props.inscription_form_template?.sections?.length);
                                setSubmitButton(true)
                            }}
                            disabled={props.user_form_payment_loading} 
                            className='small'
                        >
                            
                            {!steper4
                                ?<span>{props.t('payments:buttomNext')}</span>
                                :props.user_form_payment_loading
                                ?<span className='payment-forms__onboarding-submit-form' />
                                :<span>{props.t('payments:buttonFinalize')}</span>
                            }
                                    
                        </PrimaryButtonComponent>    
                    </div>
                </div>
            
        </div>
        // }
    )
}

export default withTranslation(['profile','payments'])(UserPaymentsFormActiveComponent)