import React, { useState, useMemo, useEffect } from "react";

import InputFieldComponent from "shared/components/input-field/InputFieldComponent";

// Material
import { Checkbox } from '@mui/material';

function TournamentDynamicAnswers(props) {
  //console.log('props.answer.caption ', props.answer.caption)
  const [checkboxTest, setCheckboxTest] = useState(props.answer.defaultValue);
  const [inputTest, setInputTest] = useState(props.answer.caption ==! ""? props.answer.caption: "");
  

  useMemo(() => {
    //console.log('dynamic answer memo caption ', inputTest)
    if(inputTest !== ""){
      const updateAnswers ={
        id: props.index,
        caption: inputTest,
        defaultValue: checkboxTest,
      };
      props.buildAnswer(updateAnswers);

    }
    
  },[checkboxTest, inputTest]); // eslint-disable-line

  useEffect(()=>{
      setCheckboxTest(props.answer.defaultValue)
  }, [props.answer.defaultValue])


  return (
    <React.Fragment>
      <div className={"dynamic-form-question__answers_options"}>
        <div>
          { props.input_type === 'single_choice' && <div className="dynamic-form-question__answers_options__single-choice" /> }
          { props.input_type === 'multi_choice' && <div className="dynamic-form-question__answers_options__multi-choice" /> }
          { props.input_type === 'list' && <div className="baloa-names" >{props.index + 1}.</div> }
        </div>
        <InputFieldComponent
          input_id={"answer_" + props.index + "question_" + props.idQuestion}
          field_name={"answer_" + props.index + "question_" + props.idQuestion}
          input_type={"text"}
          resetError={() => {}}
          error={props.errors}
          placeholder="Escribe una opcion..."
          value={props.answer.caption}
          onChange={(event) => setInputTest(event)}
        />
        <Checkbox
              key={"answerCheck_" + props.index + "question_" + props.idQuestion}
              id={"answerCheck_" + props.index + "question_" + props.idQuestion}
              name={"answerCheck_" + props.index + "question_" + props.idQuestion}
              onClick={(event) => { setCheckboxTest(event.target.checked) }} 
              checked={ checkboxTest }
              inputProps={{ 'aria-label': 'primary checkbox' }}
              className='tournament-dynamic-form-answer__checkbox'
              color="secondary"
          />
      </div>
    </React.Fragment>
  );
}

export default TournamentDynamicAnswers;
