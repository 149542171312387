/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';

import TextField from '@mui/material/TextField';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Styles
import './InputArrayFieldAutocompleteWithValidationComponent.styles.css';

//Assets
import baloa_user_icon from 'assets/icons/baloa-user.svg';
import team_shield_icon from 'assets/icons/team-shield.svg';

function InputArrayFieldAutocompleteWithValidationComponent( props ) {

    return(
            <Field name={ props.field_name } validate={ props.validationFunction }>
            
                { ( data ) => {
                        let fname = props.field_name.split("[");
                        var field_name_arr =  fname[0];                        
                        
                        if ( props.error[ field_name_arr ][props.index] !== "" ) {
                            data.form.errors[ field_name_arr ][props.index] = props.error[ field_name_arr ][props.index];
                        }

                        return(
                            <div className= { "input-field-validation " + ( props.className ? props.className : '' )  }>
                                <Autocomplete
                                    //key={ props.key }
                                    //name={ props.name }
                                    disabled={props.disabled}
                                    //component={Autocomplete}
                                    freeSolo={props.freeSolo ? props.freeSolo : false}
                                    options={props.options}
                                    noOptionsText= { props.noOptionsText }
                                    getOptionLabel={(option) => {
                                        if(props.optionLabelField == "players"){
                                            return (option.first_name !== undefined && (option.username !== undefined || option.name !== undefined) ?`${option.first_name} ${option.last_name} @${option.username !== undefined ? option.username: option.name}`: '')
                                        }else if(props.optionLabelField == "teams_tournament"){
                                            return (option.team ? `${option.team}` : '')
                                        }else if(option[props.optionLabelField]){
                                            return (`${option[props.optionLabelField]}`)
                                        }else if(props.optionLabelField === "revelationPlayer"){
                                        return (option?.player_name !== undefined 
                                            ? `${option.number ? `${option.number}.` : ''} ${option.player_name}`
                                            : '')
                                        }else{
                                            return '';
                                        }
                                    }}
                                    getOptionKey={option => option.id }
                                    isOptionEqualToValue={( option, value ) => {
                                        if(value){
                                            return option[props.optionSelectedField] === value[props.optionSelectedField] 
                                        }else{
                                            return null
                                        }
                                        
                                    }}
                                    //value={ local_value || "" }

                                    disableClearable={props.disableClearable}
                                    disablePortal={true}

                                    value={ props.value }
                                    onChange={(e, value) => {
                                        if(props.onChange ){
                                            props.onChange( value )
                                        }                    
                                        
                                        data.form.setFieldValue(props.field_name, value )
                                    }}
                                    renderOption={(comprops,option) => {
                                        //display value in Popper elements
                                        if(props.optionLabelField == "players"){
                                            return <ListItem
                                                        {...comprops}
                                                        key={option.id}
                                                        className="input-field-autocomplete__list"
                                                    >
                                                        {option.first_name === undefined 
                                                        ? <ListItemText primary={ option.message }  className="input-field-autocomplete__listitem" />
                                                        :   <React.Fragment>
                                                                <ListItemIcon className="input-field-autocomplete__list__icon" >
                                                                    <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt="" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={ option.first_name + ' ' + option.last_name} secondary={ '@'+(option.username !== undefined ? option.username: props.name ) } className="input-field-autocomplete__listitem" />
                                                            </React.Fragment>
                                                        }
                                                    </ListItem>
                                        }else if(props.optionLabelField == "teams_tournament"){
                                            return <ListItem
                                                        {...comprops}
                                                        key={option.id}
                                                        button
                                                        className="input-field-validation__list"
                                                        //selected={selectedIndex === option.id}
                                                        //onClick={(event) => handleListItemClick(event, item)}
                                                    >
                                                        <ListItemIcon className="input-field-validation__list__icon" >
                                                            <img className="input-field-validation__list__icon__img" src={ option.photo ? option.photo : team_shield_icon} alt="" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={ option.team } className="input-field-validation__listitem" />
                                                    </ListItem>
                                        }
                                        else if( props.optionLabelField === "revelationPlayer"){
                                            return <ListItem
                                                    {...comprops}
                                                    key={option.player_id}
                                                    className="input-field-autocomplete__list"
                                                    >
                                                    {option.player_name === undefined 
                                                        ? <ListItemText primary={ option.message }  className="input-field-autocomplete__listitem" />
                                                        :   <React.Fragment>
                                                                <ListItemIcon className="input-field-autocomplete__list__icon" >
                                                                    <img className="input-field-autocomplete__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt=""/>
                                                                </ListItemIcon>
                                                            <ListItemText primary={`${option.number ? `${option.number}.` : '' } ${option.player_name}`} 
                                                                secondary={ '@'+(option.username !== undefined ? option.username: option.profile ) } 
                                                                className="input-field-autocomplete__listitem" />
                                                        </React.Fragment>
                                                    }
                                                    </ListItem>
                                        }
                                            return <li className="input-field-validation__list">{option[props.optionLabelField]}</li>
                                        
                                    }}               
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // className={ props.className + " input-field-autocomplete"}
                                            className={ 
                                                'input-field-validation ' + ( (data.form.errors[ field_name_arr ] && data.form.touched[ field_name_arr ] && data.form.errors[ field_name_arr ][props.index] && data.form.touched[ field_name_arr ][props.index] ) ? 'error' : '' )
                                            }
                                            label={ props.label }
                                            variant="outlined"
                                            margin="normal"
                                            placeholder={ props.placeholder }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={"input-field-validation__message " + (data.form.errors[ field_name_arr ] && data.form.touched[ field_name_arr ] && data.form.errors[ field_name_arr ][props.index] && data.form.touched[ field_name_arr ][props.index] ? 'error' : '' )}>
                                    { data.form.errors[ field_name_arr ] ? data.form.errors[ field_name_arr ][props.index] :  props.message }
                                </p>
                            </div>
                        )
                    }
                }
            </Field>
    )
}

export default InputArrayFieldAutocompleteWithValidationComponent;
