// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TournamentListTeamsComponent from 'app/leagues/components/tournament-list-teams/TournamentListTeamsComponent';
import TournamentEditSetGroupsComponent from '../tournament-edit-set-groups/TournamentEditSetGroupsComponent';

// Styles
import './TournamentEditPhaseModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentEditPhaseModal( props ) {

    const [modalTitle, setModalTitle] = React.useState('');
    const [btnLabel, setBtnLabel] = React.useState('');
    const [available_teams, setAvailableTeams] = React.useState( [] );
    const [filterType, setFilterType ]= React.useState('not_assigned'); //eslint-disable-line
    const [avaibleGroups, setAvaibleGroups] = React.useState([]);
    const [maxKnockoutPhaseTeams, setMaxKnockoutPhaseTeams] = React.useState(false);
    
    React.useEffect(() => {
        if(props.edit_phase_type !== '') {
            switch(props.edit_phase_type) {
                case 'create_group':
                    setModalTitle(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.createGroupLabel'));
                    setBtnLabel(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.createGroupLabel')); 
                    break;
                case 'edit_group':
                    setModalTitle(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.editGroupLabel'));
                    setBtnLabel(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.saveChangesLabel'))    
                    break;
                case 'create_key':
                    setModalTitle(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.newKeyLabel'));
                    setBtnLabel(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.createKeyLabel'))
                    break;
                case 'edit_key':
                    setModalTitle(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.editKeyLabel'));
                    setBtnLabel(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.saveChangesLabel'))
                    break;
                case 'edit_free':
                    setModalTitle(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.editLeagueLabel'));
                    setBtnLabel(props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.saveChangesLabel'))
                    break;
                default:
                    break;
            }
        }
    }, [props.edit_phase_type, props.isOpen]); //eslint-disable-line

    React.useEffect( () => {
        setAvailableTeams((props.teams)?.filter((team)=> team?.tournament_phase_team_assing === props.teams_filter_type));
    }, [ props.teams, props.teams_filter_type] ); //eslint-disable-line

    React.useEffect( () => {
        if(props.groups_best_third || props.groups_best_third?.length > 0) {
            setAvaibleGroups(props.groups_best_third?.filter((phase)=> phase.id === props.tournament_active_phase?.id)[0]?.groups);
        }else setAvaibleGroups(props.groups);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.tournament_active_phase, props.groups_best_third]);

    const handleCloseModal = () => {
        let selected_group = props.groups?.filter( group => group.id === props.edit_phase_selected_group?.id);
        if(selected_group?.length > 0 && props.edit_phase_type === 'edit_key' && selected_group[0].teams?.length > 2) {
            props.handleOnEditPhaseAction('edit_key_validation');
        } else {
            setModalTitle('');
            setBtnLabel('');
            
            if((props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key') && props.groups[props.groups.length - 1]?.teams.length < 2) {
                props.handleDeleteGroupPhase(props.groups[props.groups.length - 1].id);
            }            
            setMaxKnockoutPhaseTeams(false);
            props.onClose();
        }        
    }

    const handleOnAddTeamToTournamentGroup = (groupId, teamName) => {

        if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key') {
            props.handleAddTeamToGroup(props.groups[props.groups.length - 1]?.id, teamName);
        }

        if(props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_key' || props.edit_phase_type === 'edit_free') {
            props.handleAddTeamToGroup(groupId, teamName);
        }
    }

    const handleTeamFilter =(filterType)=>{ 
        setFilterType(filterType);
        props.handle_team_filter(props.tournament_active_phase.id, filterType);
    }

    const handleDeleteTeamFromGroup = (group_team) => {
        if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key') {
            setMaxKnockoutPhaseTeams(false);
            props.handleDeleteTeamFromGroup(group_team);
        }
        if(props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_key' || props.edit_phase_type === 'edit_free') {
            let selected_group = props.groups.find(group => group.id === props.edit_phase_selected_group.id);            
            if(selected_group.teams.length <= 2) {
                props.handleOnEditPhaseAction();
            } else {
                props.handleDeleteTeamFromGroup(group_team);
            }
        }
    }

    const handleOnEditPhaseAction = () => {
        let selected_group = [];
        if(props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_key' || props.edit_phase_type === 'edit_free'){
            selected_group = props.groups?.filter( group => group.id === props.edit_phase_selected_group.id);
            if((props.edit_phase_type === 'edit_key' && selected_group[0].teams?.length > 2) || selected_group[0].teams?.length < 2 ) {
                if(props.edit_phase_type === 'edit_key'){
                    props.handleOnEditPhaseAction('edit_key_validation');
                } else {
                    props.handleOnEditPhaseAction();
                }                
            } else {
                handleCloseModal();
            }
        }
        if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key') {
            if(props.groups[props.groups.length - 1]?.teams.length < 2) {
                props.handleOnEditPhaseAction(props.edit_phase_type);
            } else {
                handleCloseModal();
            }
        } 
    }    

    const handleAddTeamToEditGroup = (team) => {
        if(props.edit_phase_type === 'create_key') {
            if(!maxKnockoutPhaseTeams) {
                if(props.groups[props.groups.length - 1].teams?.length === 1) {
                    setMaxKnockoutPhaseTeams(true);
                }
                handleOnAddTeamToTournamentGroup(props.groups[props.groups.length - 1]?.id, team.team);
            }
        } else {
            handleOnAddTeamToTournamentGroup(props.edit_phase_selected_group?.id, team.team);
        }        
    };

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={handleCloseModal}
            title={modalTitle}
            className="medium tournament-edit-phase-modal"
        >
            <div className='tournament-edit-phase-modal__groups'>
                <TournamentListTeamsComponent
                    teams={available_teams}
                    handleAddFunction={ handleOnAddTeamToTournamentGroup }
                    handle_team_filter= {handleTeamFilter}
                    select_groups_teams={props.select_groups_teams}
                    is_phase_edit={true}
                    edit_phase_selected_group={props.edit_phase_selected_group}
                    tournament_active_phase_type = { props.tournament_active_phase?.phase_type }
                    edit_phase_type={props.edit_phase_type}                    
                    groups={props.groups}
                    //disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                    disable_phase_fields={props.tournament_active_phase?.state === 'Finalized'}
                    handleCreateNewAtomaticGroups = {()=>{props.handleCreateNewAtomaticGroups()}}
                    isCreatePhase={false}
                    tournament_active_phase_name = { props.tournament_active_phase?.phase_name }
                    showActions={props.tournament_active_phase?.state === 'Active'}
                    handleOnAddAllTeamsToGroup={props.handleOnAddAllTeamsToGroup}
                    handleAddTeamToEditGroup={(team) => {handleAddTeamToEditGroup(team)}}
                    maxKnockoutPhaseTeams={maxKnockoutPhaseTeams}
                    teams_filter_type={props.teams_filter_type}
                />
                <TournamentEditSetGroupsComponent 
                    groups={avaibleGroups}
                    edit_phase_selected_group={props.edit_phase_selected_group}
                    edit_phase_type={props.edit_phase_type}
                    handleDeleteTeamFromGroup={(team) => {handleDeleteTeamFromGroup(team)}}
                    tournament_active_phase_name={props.tournament_active_phase?.phase_name}
                    handleNumberClassified={props.handleNumberClassified}
                    handleOnEditPhaseAction={() => {props.handleOnEditPhaseAction()}}
                />
                
            </div>
            <div className='tournament-edit-phase-modal__btn-action'>
                <PrimaryButtonComponent onClick={() => {handleOnEditPhaseAction()}}>{btnLabel}</PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentEditPhaseModal);