// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';


// Styles
import './LeagueTeamInfoModalComponent.styles.css';

// Material
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';

import { Box } from '@mui/material';

function LeagueTeamInfoComponent(props) {
    const userPic = {
        height: '180px',
        width: '180px'
    }
    const gridItem = {
        flexGrow: 1
    }
    
    return(

        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title="Tomas Rodriguez"        
        >
                {/* <h1>{props.perfil}</h1> */}
                {/* <button
                    onClick={ () => props.onDelete(props.perfil) }
                >eliminar perfil</button> */}

                    <Grid container>
                        <Grid item >
                            <List>
                                <ListItem>
                                    <img src='https://med.gov.bz/wp-content/uploads/2020/08/dummy-profile-pic-300x300.jpg' style={userPic} alt={`username img`}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <p>Seguidores</p>
                                        <span>2139</span>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <p>Siguiendo</p>
                                        <span>321</span>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item style={gridItem} >
                            <List>
                                <ListItem>
                                    <ListItemText>
                                    <h3>Datos básicos</h3>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>                
                                    <ListItemText>
                                    <p>Nombre de usuario</p>
                                    <span>@riverbogota</span>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <p>País</p>
                                        <span>Colombia</span>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <p>Teléfono</p>
                                        <span>3138903202</span>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <p>Email</p>
                                        <span>mail@mail.com</span>
                                    </ListItemText>
                                </ListItem>
                            </List>                        
                        </Grid>                        
                        <Grid item >
                            <List>
                                <ListItem>
                                    <ListItemText>
                                        <h3>Campeonatos inscritos</h3>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>                
                                    <ListItemText >
                                        <p>Campeonato pony fútbol 2020 C-2007</p>                  
                                    </ListItemText>
                                    <Box l={3}>
                                        <Button>Ver Torneo</Button>
                                    </Box>    
                                </ListItem>
                                <ListItem>                
                                    <ListItemText>
                                        <p>Campeonato pony fútbol 2020 C-2007</p>                  
                                    </ListItemText>
                                    <Box l={3}>
                                        <Button>Ver Torneo</Button>
                                    </Box>
                                </ListItem>                                
                            </List>
                    
                        </Grid>

                    </Grid>
                    <Box className='modal-team-info-button-actions' display="flex" justifyContent="flex-end" >

                        <Box flexGrow={1}>
                            <Button 
                                variant="outlined" 
                                className='del-member-btn' 
                                onClick={() => {
                                    props.deleteLeagueTeam( props.league_pagename,"BogotaMillos");
                                    props.onClose(false);
                                }}
                            >
                                BORRAR DE MIEMBROS
                            </Button>
                        </Box>
                        <Box><Button variant="outlined" className='send-msg-btn'>ENVIAR MENSAJE PRIVADO</Button></Box>
                        <Box>
                            <Button 
                                variant="outlined" 
                                className='view-profile-btn'
                                onClick={() => {props.handleOnTeamPageClick("BogotaMillos")}}
                            > 
                                VER PERFIL
                            </Button>
                        </Box>
                        
                    </Box>
        </Modal2Component>
    )
}

export default LeagueTeamInfoComponent;