// React
import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@mui/styles";
import help_icon from 'assets/icons/help-resume-icon.svg';
import CurrencyFormat from 'react-currency-format';

// Styles
import './TournamentGeneralSummaryComponent.styles.css';

// Moment
import * as moment from 'moment';
import i18n from 'i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

function TournamentGeneralSummay(props) {

    const renderDate = (dateText) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateText);
        return date.toLocaleDateString((moment.locale(i18n.language)), options);
    };

    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            color: 'var(--primary-dark-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-quarter)',
            fontFamily: 'var(--secondary-font)',
            boxShadow: '0px 1px 4px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
        },
    }));
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.tournament.name === '' || props.tournament.name === undefined ?
                <Skeleton animation="wave" variant="rect" component="div" height={600} style={{ marginTop: 30, marginBottom: 30 }} />
                : <div className={'report-resume-general-summary'}>
                    <div className={'report-resume-general-summary-title'}>
                        <p className='baloa-subtitle-2'>{props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelPhaseSummary")}</p>
                    </div>
                    <div className={'report-resume-general-summary__service-msg'}>
                        <div><span className='hubicon-info'></span></div>
                        <div className='baloa-username'>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.baloaServiceMessage")}</div>
                    </div>
                    <div className={'report-resume-container'}>
                        <div className={'report-resume-left-col'}>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labeltitle")}</p>
                                <p className={'baloa-names'}>{props.t(`options.tournaments.pages.newTournament.tabs.labelsSummary.${props.tournament.football_type}`)}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelGender")}</p>
                                <p className={'baloa-names'}>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.tournament_gender}`)}</p>
                            </div>
                            {/* <div className={'report-resume-item-text'}>
                                    <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelGameDays")}</p>
                                    <p className={'baloa-names'}>-</p>
                                </div> */}
                            {/*
                        <div className="tooltip top">
                                    <img src={help_icon} alt="" />
                                    <span className="tiptext">Texto del tooltip</span>
                                </div>
                        */}
                            {(props.tournament.tournament_registration_type === 'Paid' || props.tournament.tournament_registration_type === 'Team_paid') &&
                                <div className={'report-resume-item-text'}>
                                    <div className={'report-resume-item-text-icon'}>
                                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTotalTeam")}</p>
                                        <Tooltip title={props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.TooltipText")}
                                            arrow
                                            placement="right-end"
                                            classes={{ tooltip: classes.customMessage }}>
                                            <img src={help_icon} alt="" />
                                        </Tooltip>
                                    </div>
                                    <p className={'baloa-names'}>
                                        <CurrencyFormat
                                            value={props.registrationTypePerTeam!== ''? props.registrationTypePerTeam: ''} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.currency}
                                            onValueChange ={ props.registrationTypePerTeam}
                                            renderText={value => <div>{value}</div>}
                                        />
                                    {/*  $ {props.registrationTypePerTeam} { props.currency}*/} 
                                    </p> 
                                </div>
                            }
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelRegistrationDead")}</p>
                                <p className={'baloa-names'}>{renderDate(props.tournament.registration_deadline)}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelEndTournament")}</p>
                                <p className={'baloa-names'}>{renderDate(props.tournament.end_date)}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelNumberPlayerFTeam")}</p>
                                <p className={'baloa-names'}>{props.tournament.min_participant_players} a {props.isUnlimited? props.t('options.tournaments.pages.newTournament.tabs.tabInscription.unlimitedPlayersNumberLbl'):props.tournament.max_participant_players}</p>
                            </div>
                        </div>
                        <div className={'report-resume-right-col'}>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelCategory")}</p>
                                <p className={'baloa-names'}>{props.tournament.category}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTypeRegistration")}</p>
                                <p className={'baloa-names'}>{props.t(`options.tournaments.pages.newTournament.tabs.labelsSummary.${props.tournament.tournament_summon_registration}`)}</p>
                            </div>
                            {/* <div className={'report-resume-item-text'}>
                                    <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelPossibleGame")}</p>
                                    <p className={'baloa-names'}>-</p>
                                </div> */}
                            {(props.tournament.tournament_registration_type === 'Paid' || props.tournament.tournament_registration_type === 'Player_paid') &&
                                <div className={'report-resume-item-text'}>
                                    <div className={'report-resume-item-text-icon'}>
                                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTotalPlayers")}</p>
                                        <Tooltip title={props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.TooltipText")}
                                            arrow
                                            placement="right-end"
                                            classes={{ tooltip: classes.customMessage }}>
                                            <img src={help_icon} alt="" />
                                        </Tooltip>
                                    </div>
                                    <p className={'baloa-names'}>
                                        <CurrencyFormat
                                            className='baloa-names'
                                            value={props.registrationTypePerPlayer!== ''? props.registrationTypePerPlayer: ''} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.currency}
                                            onValueChange ={ props.registrationTypePerPlayer}
                                            renderText={value => <div>{value}</div>}
                                        />
                                    {/* $ {props.registrationTypePerPlayer} { props.currency} */}
                                    </p>
                                </div>
                            }
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTournamentStart")}</p>
                                <p className={'baloa-names'}>{renderDate(props.tournament.start_date)}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelNumberTeams")}</p>
                                <p className={'baloa-names'}>{props.tournament.min_participant_teams} a {props.tournament.max_participant_teams}</p>
                            </div>
                            <div className={'report-resume-item-text'}>
                                <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelPrize")}</p>
                                <p className={'baloa-names'}>{props.t(`options.tournaments.pages.newTournament.tabs.labelsSummary.${props.tournament.tournament_prize_type}`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </React.Fragment>
    );

};
export default withTranslation('league')(TournamentGeneralSummay);