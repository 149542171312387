// React
import React from 'react';

// Styles
import './ShowMessageComponent.styles.css';

import phases_lock from 'assets/icons/fase_bloqueada.svg';

function ShowMessageComponent(props) {

    return( 

        <div className='show_message_container'>

            { props.lock &&
                <img src={phases_lock} alt="" />
            }
            { props.message !== '' &&
                <div className='baloa-names'>{props.message}</div>
            }


        </div>
    )


}

export default ShowMessageComponent;