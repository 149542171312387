// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league_match_player_assistance .modal2__container{
    width: 80%;
}

.league_match_player_assistance-container{
    padding: var(--space);
}

.league_match_player_assistance_container-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: var(--space);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-match-player-assistant-modal/LeagueMatchPlayersAssistantModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,wBAAwB;AAC5B","sourcesContent":[".league_match_player_assistance .modal2__container{\n    width: 80%;\n}\n\n.league_match_player_assistance-container{\n    padding: var(--space);\n}\n\n.league_match_player_assistance_container-button{\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    flex-direction: row;\n    margin-top: var(--space);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
