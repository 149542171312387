export const setUserIdAction = ( data ) => {
    return { type: "CLICKSTREAM:SET_USER_ID", data }
}

export const addEventAction = ( data ) => {
    return { type: "CLICKSTREAM:ADD_EVENT", data }
}

export const cleanEventsAction = ( data ) => {
    return { type: "CLICKSTREAM:RESET_EVENTS", data }
}

export const addScreenTimeStartAction = ( data ) => {
    return { type: "CLICKSTREAM:SCREEN_TIME_START", data }
}

export const addScreenTimeEndAction = ( data ) => {
    return { type: "CLICKSTREAM:SCREEN_TIME_END", data }
}
