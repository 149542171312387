// React
import React from 'react'

//Components
import ExternalStatComponent from '../external-stat/ExternalStatComponent';

// Styles
import './ProfileExternalStatsComponent.style.css'

// i18n
import { withTranslation } from 'react-i18next';

function ProfileExternalStatsComponent( props ) {
   

    return (

        <div className={ "profile-external-stats__container " + ( props.className ? props.className : '' ) }>

                <div className='profile-external-stats__title'>
                    <div className='baloa-subtitle-2'>{props.t('externalStats.externalStatsTitle')}</div>
                    {props.is_owner &&
                        <div className='baloa-card-1__button'>
                            <span className='hubicon-add_option' onClick={() => {props.handleOnAddExternalStats()}} />
                        </div>
                    }
                </div>
                {props.externalStats?.length > 0?
                    <div className='profile-external-stats__gallery'>
                        {props.externalStats?.slice(0,3).map(stat =>(                            
                            <ExternalStatComponent
                                stat={stat}
                                is_owner={props.is_owner}
                                handleOnEditExternalStats = { props.handleOnEditExternalStats }
                            />
                        ))

                        }

                    </div>
                    :
                    <div className='profile-external-stats__empty-message baloa-normal-text'>{props.t('externalStats.emptyText')}</div>
                }
                {props.externalStats?.length > 3 &&
                    <div className='profile-external-stats__view-more baloa-normal-medium' onClick={()=>{ props.handleOnViewMore() }}>{props.t(`tournamentSummary.labelViewAll`)}</div>
                }
        </div>
        
    )

}



export default (withTranslation(["profile", "league"]))(ProfileExternalStatsComponent);