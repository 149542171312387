// React
import React from 'react';
import { connect } from 'react-redux';

// Material Ui
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { changeUserMessageSnackBar } from 'app/user-settings/actions/UserSettingsCreators';
class UserMessageComponent extends React.Component  {

  handleClose = event => { 
    this.props.changeUserMessageSnackBar( {
        message: '',
        user_message_is_open: false
    } );
  }; 
  
  render() {
      
      return (
        <Snackbar
        bodystyle={{ backgroundColor: 'teal', color: 'coral' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.props.isOpen}
        autoHideDuration={6000}
        onClose={this.handleClose}       
      >
          <SnackbarContent style={{
                backgroundColor:'#4D5451',
              }}
              message={<span id="client-snackbar">{this.props.message}</span>}          
          />
      </Snackbar>
      )
  }
}

const mapStateToProps = state => {
  return {
      state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeUserMessageSnackBar: ( new_state ) => {
        return dispatch( changeUserMessageSnackBar( new_state ) );
    }, 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMessageComponent);