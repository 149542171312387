// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-phases_phases_edit_container{
    margin-top: var(--space-x2);
    background-color: var( --white-color );
    border-radius: var( --space );
    padding: var(--space-x2);
}
.tournament-phases_phases_edit_button{
    display: flex;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phase-edit/TournamentPhaseEditComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sCAAsC;IACtC,6BAA6B;IAC7B,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".tournament-phases_phases_edit_container{\n    margin-top: var(--space-x2);\n    background-color: var( --white-color );\n    border-radius: var( --space );\n    padding: var(--space-x2);\n}\n.tournament-phases_phases_edit_button{\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
