// i18next 
import i18n from 'i18next';

export const MinuteValidator = (value, value2) => {

    let error;
    
    if ( (value !== null && value !== '' ) && ( value2 === undefined || value2 === null || value2 === '')) {
        error =  i18n.t('page:newPage.validators.requiredField'); 
    }
    if((value !== null && value !== '' ) && (!/^([0-9])*$/.test( value2 ))){
        error = i18n.t('page:newPage.validators.invalidChars');
    }
    return error; 
}