/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import moment from 'moment';

// Redux
import { compose } from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

// Actions
import { loadHubTeamsTournaments, 
    loadHubTournaments, 
    loadHubTournamentTeams, 
    loadPlayerTournaments, 
    loadSelectedTournament, 
    changeTournamentTeamState,
    loadAllManagedTournaments,
    loadProfileAdminTeams,
    loadHubTournamentsFilter,
    loadTeamPlayers,
    loadPaymentUrl,
    registerHubTeamToTournament,
    sentPlayerInscriptionForm,
    registerHubTeamToTournamentToPay,
    loadPlayerPaymentUrl,
    loadSelectedTeamTournament,
    loadSelectedPlayerTournament,
    loadTournamentHubTournamentTeams,
    changeTournamentPlayerState,
    loadTournamentInscriptionFormTemplate,
    loadTournamentPlayerFormTemplate,
    addFlexiMemberToTeam,
    loadTemporalPlayerTournament,
    loadHubTournamentsSections,
    loadHubTournamentsSubsections,
    loadInformativeTournament,
    getHubTournamentSearch,
    loadHubFollowingTournamentsSubsection,
    onUnfollowTournament,
    onFollowTournament,
    loadHubTournamentMatches,
    loadHubTournamentMatchesByDate,
    loadHubNextMatches,
    loadHubTournamentMatchesDates,
    searchProfileAdminTeams,
    loadTeamManagerAndStaff,
    loadPhaseCalendar,
    searchTournamentTeamPlayers,
} from 'app/tournaments/actions/TournamentActions';

import {
    getCountries,
    searchCityByCountry,
    changeTeamPlayerState,
    loadTournamentPhases,
    loadPhaseResultsTable,
    loadPhaseDates,
    loadPhaseMatchesByDate,
    loadTournamentInfo,
    loadTournamentMembersTeams,
    searchWorldCities,
    addUserToStaff,
    loadTournamentStaff,
    loadRefeeringPaymentUrl,
    assignRefereesMatch,
    makeRefereePayment,
    loadTeamsToMatchAssistanceAligment,
    loadCardIdTemplate,
    loadCardIdsData,
    loadPlayerCardIdData,
    loadStaffCardIdData,
} from 'app/leagues/actions/LeagueTournamentActions';
import { createPage } from 'app/pages/actions/PageActions';

import { addMemberWithRolToTeam, addNewTeam ,loadMemberPositionsData,loadMemberRolesData,searchMembersWithValidation,uploadBulkData} from 'app/leagues/actions/LeagueTeamsActions';
import {changeBulkDeleteMemberModalStateAction, changeNewTeamError, cleanTeamDataAction, cleanSearchedMembersAction} from 'app/leagues/actions/LeagueTeamsActionsCreators';

import { loadMatchReport, loadAdminTeaminMatchReportAction, updateAdminTeamsAssistanceAligment } from 'app/leagues/actions/LeagueMatchActions';
import { profileTournamentStatsPayment } from 'app/profiles/actions/ProfileActions';

import { changeMatchDetailModalStateAction } from 'app/posts/actions/PostsActionsCreators';

// Actions Creators
import { showHubTournamentDetailAction, 
    setHubTournamentHubAction, 
    setHubTournamentInscriptionsAction, 
    setHubTournamentMyTournamentsAction, 
    setHubTournamentInvitationsAction,
    changeInscriptionsModalStateAction,
    changeNewNamesError,
    cleanPlayerTeamTournamentsAction,
    changeFormsModalStateAction,
    changePaymentsModalStateAction,
    changeResponsiveHubModalStateAction,
    changeFlexiInscriptionModalStateAction,
    changeShareInscriptionLinkModalStateAction,
    changeIsShareLinkStateAction,
    //changeRejectPlayerInscriptionModalStateAction,
    changeVerifyFormPaymentModalStateAction,
    setSelectedTournamentTeamAction,
    clearHubTournamentSubsectionAction,
    changeStaffInvitationModalStateAction,
    changeStaffAcceptInvitationModalStateAction,
    changeShareIframeLinkStateAction,
    changeInscriptionDeadlineModalStateAction,
    changeUnlockStatsModalStateAction,
    cleanProfileAdminTeamsAction,
    changeTournamentCardIdPdfGeneratorModalStateAction,
    changeAddMembersWithoutAccountModalStateAction,
    changeLeaguesAddMemberToLeagueModalStateAction,
    changeAnswerMultipleFormsModalStateAction,
    cleanTeamPlayersAction,
} from '../actions/TournamentActionsCreators';
import { changePageNewTeamModalStateAction, changeTeamMembersModalStateAction } from 'app/pages/actions/PageActionsCreators'
import {loadPageErrorsAction} from 'app/pages/actions/PageActionsCreators';
import {changeErrorMessageSnackBar, } from 'app/home/actions/HomeActionsCreators';
import {changeProfileStatisticsPaymentResumeModalStateAction, } from 'app/profiles/actions/ProfileActionsCreators';
import { changeSignUpLoginModal, changeClickType, setUrlRedirect, changeSignUpWelcomeModal } from 'app/authentication/actions/LoginActionsCreators';
import {resetTournamentPhasesAction, resetPhaseDatesMatchesAction} from 'app/leagues/actions/LeagueTournamentActionsCreators';
import { 
    changeAddingBulkMemberStateAction,
    changeLoadingBulkFileStateAction,
    changeShowBulkStateAction,
    cleanBulkMembersAction,
    setBulkMembersAction,
} from 'app/leagues/actions/LeagueTeamsActionsCreators';

//Components
import TournamentDetailComponent from 'app/tournaments/components/tournament-detail/TournamentDetailComponent';
import LeagueTournamentComponent from 'app/leagues/components/league-tournament-component/LeagueTournamentComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import PageTeamCreateModalComponent from 'app/pages/components/page-team-create-modal/PageTeamCreateModalComponent';
import HubTournamentSectionComponent from '../components/hub-tournament-section/HubTournamentSectionComponent';
import HubTournamentSectionCardComponent from '../components/hub-tournament-section-card/HubTournamentSectionCardComponent';
import TournamentHubFilterComponent from '../components/tournament-hub-filter/TournamentHubFilterComponent';
import HubTournamentMatchesComponent from 'app/tournaments/components/hub-tournament-matches/HubTournamentMatchesComponent';
import HubTournamentMatchesWidgetComponent from 'app/tournaments/components/hub-tournament-matches-widget/HubTournamentMatchesWidgetComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputSearchWithButtonComponent from 'shared/components/input-search-with-button/InputSearchWithButtonComponent';

// Modals
import InscriptionsModalComponent from 'app/tournaments/components/inscriptions-modal/InscriptionsModalComponent';
import ModalDecisionDialogComponent from 'shared/components/modal-decision-dialog/ModalDecisionDialogComponent';
import HubFormsModalComponent from 'app/tournaments/components/hub-forms-modal/HubFormsModalComponent';
import HubPaymentsModalComponent from 'app/tournaments/components/hub-payments-modal/HubPaymentsModalComponent';
import ResponsiveHubModalComponent from 'app/tournaments/components/responsive-hub-modal/ResponsiveHubModalComponent';
import FlexiInscriptionModalComponent from 'app/tournaments/components/flexi-inscription-hub-modal/FlexiInscriptionModalComponent';
import ShareInscriptionLinkModalComponent from 'app/tournaments/components/share-inscription-link-modal/ShareInscriptionLinkModalComponent';
import VerifyFormPaymentTeamPlayerModalComponent from 'app/tournaments/components/verify-form-payment-team-player-modal/VerifyFormPaymentTeamPlayerModalComponent';
import MatchDetailModalComponent from 'app/posts/components/match-detail-modal/MatchDetailModalComponent';
import TournamentTeamMembersModalComponent from 'app/leagues/components/tournament-team-members-modal/TournamentTeamMembersModalComponent';
import StaffInvitationModalComponent from '../components/staff-invitation-modal/StaffInvitationModalComponent';
import StaffAcceptInvitationModalComponent from '../components/staff-accept-invitation-modal/StaffAcceptInvitationModalComponent';
import ShareIframeLinkModalComponent from 'app/tournaments/components/share-iframe-link-modal/ShareIframeLinkModalComponent';
import InscriptionDeadlineModalComponent from '../components/inscription-deadline-modal/InscriptionDeadlineModalComponent';
import ProfileStatisticsPaymentResumeModalComponent from 'app/profiles/components/profile-statistics-payment-resume-modal/ProfileStatisticsPaymentResumeModalComponent';
import UnlockStatsModalComponent from 'app/tournaments/components/unlock-stats-modal/UnlockStatsModalComponent';
//import TournamentCardIdPdfGeneratorModalComponent from 'app/leagues/components/tournament-card-id-pdf-generator-modal/TournamentCardIdPdfGeneratorModalComponent';
import AddMembersWithoutAccountModalComponent from 'app/tournaments/components/add_members_without_account_modal/AddMembersWithoutAccountModalComponent';
import TournamentCardIdViewerModalComponent from 'app/tournaments/components/tournament-card-id-viewer-modal/TournamentCardIdViewerModalComponent';
import AddMemberWithAccountModalComponent from '../components/add-member-with-account-modal/AddMemberWithAccountModalComponent';
import LeagueBulkDeleteMemberModalComponent from 'app/leagues/components/league-bulk-delete-member-modal/LeagueBulkDeleteMemberModalComponent';
import AnswerMultipleFormsModalComponent from '../components/answer-multiple-forms-modal/AnswerMultipleFormsModalComponent';

// Material UI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Skeleton } from '@mui/material';

// Styles
import './TournamentHubView.styles.css';


// Variables
import { web_url } from "config/GlobalData";
//import TournamentInvitationInscriptionActionsModalComponent from 'app/leagues/components/tournament-invitation-inscription-actions-modal/TournamentInvitationInscriptionActionsModalComponent';

//Assets
import loading_baloa from 'assets/images/loading-baloa.gif';
import loading_ball from 'assets/images/loading_ball.gif';
import inscriptions_empty from 'assets/images/inscriptions_empty.png';
import invitations_empty from 'assets/images/invitations_empty.png';
import search_off from 'assets/icons/search_off.svg';


class TournamentHubView extends React.Component {

    constructor( props ) {
        super( props );
        this.payment_response = this.props.location.pathname;
        this.state = {
            selected_tournament_team: null,
            selected_tournament_teamname: null,
            openModal: false,
            typeModal: '',
            titleModal: '',
            subTitleModal: '',
            principalButtonLabel: '',
            secondaryButtonLabel: '',
            secondaryButton:false,
            principalButtonActionLink:'',
            tournament_tag: [
                { value: 'Free', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: this.props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            selected_tournament_team_logo: null,
            selected_tournament_team_id: null,
            selected_tournament_player_id: null,
            start_flow: null,
            inscrption_steps: 1,
            payment_players_quantity: 0,
            payment_player_code: null,
            selected_team: undefined,
            from_flow: undefined,
            selected_tournament_team_state: null,
            selected_tournament_registration_type: null,
            is_inscription: false,
            profile_type: undefined,
            submitWompiForm: false,
            form_profile_type: undefined,
            form_tournament_player_id: null,
            load_detail: false,
            selected_admin_player: undefined,
            type_redirect: undefined,
            flexi_insc: false,
            flexi_state: undefined,
            flexi_team_id: undefined,
            share_inscription_url: undefined,
            new_page_team_inscription: undefined,
            new_team_gender: [
                { value: 'Male', content: this.props.t("team:newTeam.forms.basicInfo.genderMaleBtnLabel") },
                { value: 'Female', content: this.props.t("team:newTeam.forms.basicInfo.genderFemaleBtnLabel") },
                { value: 'Free', content: this.props.t("team:newTeam.forms.basicInfo.genderFreeBtnLabel") },
            ],
            onUploadProgress: 0,
            show_code_payment: undefined,
            verification_message: '',
            is_payment: false,
            is_form: false,
            is_subsection: false,
            tournament_subsection: '',
            is_path: false,
            is_best_third: false,
            active_phase_id: '',
            groups_matches_by_date: [],
            tournament_active_phase: {},
            filter_component_activation: false,
            path_next_page:'',
            word_filter: '',
            modal_selected_team:{},
            staff_profile: undefined,
            share_iframe_url : undefined,
            load_created_teams: false,
            type_inscription_link_modal:'',
            show_matches: false,
            tabSelected: 0,
            new_tournament_detail: true,
            downloadCardIdData: [],
            uploadProgress: 0,
            dataset: undefined,
            fileLoaded: false,
            fileName: undefined,
            operationInProgress: false,
            show_bulk_modal: false,
            deleted_member_index: undefined,
            playerSelected: null,
            roles_members: [], 
        };
        this.referees_payment_response = this.props.match?.params;
    }
    componentDidMount() {
        this.props.changeSignUpWelcomeModal(false);
        /**Refereee an Admin inviation */
        let staff_invitation = this.props.location.search.split('staff=');
        if(staff_invitation.length > 1){
            this.props.changeStaffInvitationModalStateAction(true);
            this.setState({staff_profile: staff_invitation[1]})
        }

        let payment_confirmation = this.payment_response?.split('/confirmpayment/');
        let flexi_inscription = this.props.location.search.split('tournament_team_id=');
        if( payment_confirmation?.length > 1 ){
            let confirmation_params = payment_confirmation[1].split('&');
            this.props.cleanPlayerTeamTournamentsAction();
            this.props.setHubTournamentInscriptionsAction(true);
            this.handleOnViewPaidTournament(confirmation_params[0],'inscriptions', confirmation_params[1]);
            if(confirmation_params[1] === 'teams') { this.handleOnShareInscriptionTournament(confirmation_params[0]) }

        }else if(flexi_inscription?.length <= 1 && !this.props.match.params.tournamentid){ //TODO: validar cuando se redirige al hub
            this.setViewPath(this.props.history.location.pathname);
        }

        if(this.props.state.tournaments?.following_hub_tournaments_subsection.length === 0) {
            this.props.loadHubFollowingTournamentsSubsection('tournament_follow');
        }

        if(this.props.location.pathname === '/tournament/hub/search'){
            this.setState({filter_component_activation: true});
        }

        let tournamentsearch = this.props.location.search.split('?search=');
        if (tournamentsearch.length > 1 && tournamentsearch[1].length > 0){
            //this.setState({word_filter: tournamentsearch[1]});
            this.handleValidationFilterComponent(tournamentsearch[1]);
        }
        


        if(this.referees_payment_response.type === 'referee' || this.referees_payment_response.type === 'match') {
            let tournament = { tournament_id: this.referees_payment_response.tournamentid }
            this.props.loadTournamentInfo(this.referees_payment_response.tournamentid)
            if(this.referees_payment_response.type === 'referee'){
                this.handleOnViewTournament(tournament, 'inscriptions', this.referees_payment_response.type);
            }else if(this.referees_payment_response.type === 'match'){ //social share de matches -> se apunta a detail desde hub, NO desde inscripciones
                if(this.referees_payment_response.participate === 'true'){
                    this.handleOnViewHubTournament({id:this.referees_payment_response.tournamentid}, 'hub', true);
                }else{
                    this.handleOnViewHubTournament({id:this.referees_payment_response.tournamentid}, 'hub', false);//No participa en el torneo
                } 
            }
            
            this.props.loadTournamentPhases(this.referees_payment_response.tournamentid);
        }
        
        this.props.loadMemberRolesData();
        this.props.loadMemberPositionsData();
    }
    
    async componentDidUpdate(prevProps){
        
        /* if((JSON.stringify(this.props.state.tournaments.selected_tournament) != JSON.stringify(prevProps.state.tournaments.selected_tournament) && !this.props.state.tournaments?.modals.forms_modal_is_open)){
            this.handleVerifyFormPaymentPlayer();
        } */

        let inscription_confirmation = this.props.location.search.split('inscriptions?');
        /** Validacion share inscription */
        let flexi_inscription = this.props.location.search.split('tournament_team_id=');

        /** Validacion hub tournament recomended */
        let participate_state = this.props.location.search.split('participate=');

        if( inscription_confirmation?.length > 1 && !(flexi_inscription.length > 1) && this.props.state.tournaments?.selected_tournament_team && !this.state.is_inscription && !this.state.load_detail){
            this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', this.state.profile_type? this.state.profile_type : inscription_confirmation[1] == 'detailplayer'? 'player': null, );
        }

        if((this.props.match.params.tournamentid && !this.state.load_detail && !this.state.new_page_team_inscription) || (prevProps.match.params?.tournamentid !== this.props.match.params?.tournamentid)){            
            if(participate_state.length > 1){//No participa en el torneo
                this.handleOnViewHubTournament({id:this.props.match.params.tournamentid}, 'hub', false);
            }else{
                this.handleOnViewHubTournament({id:this.props.match.params.tournamentid}, 'hub', true);
            }          
        }

        if( flexi_inscription?.length > 1 && !this.state.flexi_insc){
            this.props.changeIsShareLinkStateAction(true);
            this.setState({flexi_insc: true});
            await this.callTeamTournament(flexi_inscription[1]);
            this.props.changeFlexiInscriptionModalStateAction(false);
            const timeout = setTimeout(() => {
                if(this.props.state.user.me.username){
                    this.props.changeSignUpWelcomeModal(false);
                    //if(this.props.state.tournaments.selected_tournament_team?.request_is_member && this.props.state.tournaments.selected_tournament_team?.is_tournament_player){ //TODO: CHECK AFTER CHANGE HUB APIS
                    if(this.props.state.tournaments?.selected_share_tournament?.request_is_member && this.props.state.tournaments?.selected_share_tournament?.request_is_tournament_player){// Se deja linea de validacion anterior por que si el player no esta relacionado con el equipo selected_tournament_team no existe
                        //Fixme: Valdiar cuando es miembro del torneo y llega nulo
                        this.props.changeIsShareLinkStateAction(false);
                        this.props.changeFlexiInscriptionModalStateAction(false);
                        this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', 'flexiplayer', this.props.state.tournaments.selected_tournament_team);
                    //}else if(!this.props.state.tournaments.selected_tournament_team?.is_tournament_player) {//TODO: CHECK AFTER CHANGE HUB APIS
                    }else if(this.props.state.tournaments?.selected_share_tournament?.request_is_member && !this.props.state.tournaments.selected_share_tournament?.request_is_tournament_player) {// Se deja linea de validacion anterior por que si el player no esta relacionado con el equipo selected_tournament_team no existe

                        //console.log('flexiplayer selected_tournament_team',this.props.state.tournaments.selected_tournament_team)
                        //Se registra en el torneo cuando ya pertenence al equipo
                        this.props.changeIsShareLinkStateAction(false);
                        this.props.changeFlexiInscriptionModalStateAction(false);
                        //this.props.addFlexiMemberToTeam( this.props.state.tournaments.selected_tournament_team?.teamname, {username: this.props.state.user.me.username, role: {role: "player", position: this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.player_position? this.props.state.tournaments.selected_tournament_team.is_tournament_player.player_position:null, player_number: this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.player_number? this.props.state.tournaments.selected_tournament_team.is_tournament_player.player_number : null}, tournament_id :this.props.state.tournaments.selected_tournament?.id }, this.props.state.tournaments.selected_tournament_team?.id ); //Se comentarea cambio por que si el player no esta relacionado con el equipo selected_tournament_team no existe
                        this.props.addFlexiMemberToTeam( this.props.state.tournaments.selected_share_tournament?.teamname, 
                            {username: this.props.state.user.me.username, 
                                role: {role: "player", 
                                    position: this.props.state.tournaments.selected_share_tournament?.player_position? this.props.state.tournaments.selected_share_tournament.player_position:null,
                                    player_number: this.props.state.tournaments.selected_share_tournament?.player_number? this.props.state.tournaments.selected_share_tournament.player_number : null
                                },
                            tournament_id :this.props.state.tournaments.selected_tournament?.id 
                            }, 
                            this.props.state.tournaments.selected_share_tournament?.tournament_team_id );

                        const timeout2 = setTimeout(() => {
                            //this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', 'flexiplayer', this.props.state.tournaments.selected_tournament_team); Se comenta cambio por que si el player no esta relacionado con el equipo selected_tournament_team no existe
                            this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', 'flexiplayer', {id: this.props.state.tournaments.selected_share_tournament?.tournament_team_id});
                            this.props.changeFlexiInscriptionModalStateAction(false);
                        }, 2000);
                        return () => clearTimeout(timeout2);
                    }else{
                        this.props.changeFlexiInscriptionModalStateAction(true);
                        this.setState({flexi_state: 'logged'})
                        this.setState({flexi_team_id: flexi_inscription[1]});
                    }
                }else{                    
                    this.props.changeFlexiInscriptionModalStateAction(true);
                    this.setState({flexi_state: 'noLogged'});
                    this.setState({flexi_team_id: flexi_inscription[1]});                    
                }
            }, 2000);
            return () => clearTimeout(timeout);
        }

         /** Validacion hub tournament following */
         let tournament_following = this.props.location.search.split('following=');
         if(tournament_following.length > 1 /* && this.state.tournament_subsection !== 'tournament_follow' */) {
            let clean_path = this.props.history.location.pathname;
            this.props.history.replace({
                pathname: clean_path,
                search: '',
            });
            this.handleOnSubsectionViewMore('tournament_follow'); 
        }

        if(this.props.location.pathname !== '/tournament/hub/search' && this.state.filter_component_activation){
            this.setState({filter_component_activation: false, is_subsection: false, word_filter: ''});
        }

        /** Load unlocked modal */
        if(this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.id !== prevProps.state.tournaments.selected_tournament_team?.is_tournament_player?.id){
            this.setState({new_tournament_detail: true})
        }

        if(this.props.state.tournaments.show_tournament_detail && 
            this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.id && 
            !this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.unlocked_stats && 
            this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.player_role === 'player' && //TODO: CHECK AFTER CHANGE HUB APIS
            this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.player_state === 'Enrolled' && //TODO: CHECK AFTER CHANGE HUB APIS
            !this.props.state.tournaments.modals.unlock_stats_is_open &&
            this.state.new_tournament_detail){
                this.props.changeUnlockStatsModalStateAction(true);
                this.setState({new_tournament_detail: false});
        }
        /** ---- */

        /** Se valida la info de los carnets para crear el objeto que va al componente de pdf */
        if (prevProps.state.leagueTournaments?.card_ids_data?.results !== this.props.state.leagueTournaments?.card_ids_data?.results){
            this.prepareCardIdsPDFData();
        }
        if(prevProps.state.teams.member_roles != this.props.state.teams.member_roles){
            this.prepareMembersRoles(this.props.state.teams.member_roles);
        }
        if(prevProps.state.teams.member_positions != this.props.state.teams.member_positions){
            this.prepareMembersPositions(this.props.state.teams.member_positions);
        }
        if(prevProps.state.teams.team.team_players != this.props.state.teams.team.team_players){
            this.prepareTeamMembers(this.props.state.teams.team.team_players);
        }
    }

    callTeamTournament = async (tournamernt_team_id) => {
        //console.log('llama callTeamTournament tournamernt_team_id',tournamernt_team_id );
        await this.props.loadSelectedTeamTournament(tournamernt_team_id, true);
    }

    handleFlexiIncriptionButton(){
        this.props.changeIsShareLinkStateAction(false);
        switch (this.state.flexi_state) {
            case "logged":{
                //this.props.addFlexiMemberToTeam( this.props.state.tournaments.selected_tournament_team?.teamname, {username: this.props.state.user.me.username,role: {role: "player",position: null,player_number: null},tournament_id :this.props.state.tournaments.selected_tournament.id }, this.props.state.tournaments.selected_tournament_team.id );
                this.props.addFlexiMemberToTeam( this.props.state.tournaments.selected_share_tournament?.teamname, 
                    {username: this.props.state.user.me.username, 
                        role: {role: "player", 
                            position: this.props.state.tournaments.selected_share_tournament?.player_position? this.props.state.tournaments.selected_share_tournament.player_position:null,
                            player_number: this.props.state.tournaments.selected_share_tournament?.player_number? this.props.state.tournaments.selected_share_tournament.player_number : null
                        },
                    tournament_id :this.props.state.tournaments.selected_tournament?.id 
                    }, 
                    this.props.state.tournaments.selected_share_tournament?.tournament_team_id );
                const timeout = setTimeout(() => {
                    //this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', 'flexiplayer', this.props.state.tournaments.selected_tournament_team);
                    this.handleOnViewTournament(this.props.state.tournaments.selected_tournament,'inscriptions', 'flexiplayer', {id: this.props.state.tournaments.selected_share_tournament?.tournament_team_id});
                    this.props.changeFlexiInscriptionModalStateAction(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
            case "noLogged":{                
                this.handleHomeAnonModal(true, 'homeAnonJoinTeam', this.state.flexi_team_id);
                break;
            }
            // no default
        }
    }

    setViewPath = (path) => {
        let selPath = path.split('/');
        this.handleBackTournamentViewMore();        

        switch (selPath[2]) {
            case "hub": {
                this.setState({is_path: false});
                this.props.setHubTournamentMyTournamentsAction(false);
                this.props.loadHubTournamentsSections();
                this.props.loadHubNextMatches();
                this.props.setHubTournamentHubAction(true);
                return true;
            }
            case "inscriptions": {
                this.setState({is_path: true});
                this.setState({tournament_subsection: 'tournament_participate'});
                this.setState({is_subsection: true});
                this.props.setHubTournamentMyTournamentsAction(false);
                this.props.loadHubTournamentsSubsections('tournament_participate');
                return true;
            }
            case "my-tournaments": { 
                this.props.loadAllManagedTournaments();
                this.props.setHubTournamentMyTournamentsAction(true);
                return true;
            }
            case "invitations": {
                this.setState({is_path: true});
                this.setState({tournament_subsection: 'tournament_invitation'});
                this.setState({is_subsection: true});
                this.props.setHubTournamentMyTournamentsAction(false);
                this.props.loadHubTournamentsSubsections('tournament_invitation');
                return true;
            }
            case "matches": {
                this.setState({show_matches: true, tabSelected: 1});
                this.props.setHubTournamentMyTournamentsAction(false);
                this.props.loadHubTournamentMatchesDates();
                //this.props.loadHubTournamentMatches();
                return true;
            }
            // no default
        }
    }

    handleHomeAnonModal = (modal_state, click_type, tournament_team_id = null) => {
        this.props.changeSignUpLoginModal(modal_state);
        this.props.changeClickType(click_type);        
        if(tournament_team_id){
            let url_redirect = '?tournament/inscriptions?tournament_team_id=' + tournament_team_id;
            this.props.setUrlRedirect(url_redirect);
        }
    }

    handleOnViewHubTournament = async (tournament, from, participate) => {
        //console.log('torneo handleOnViewHubTournament', tournament);
        this.props.cleanTeamPlayersAction();
        this.props.loadTournamentStaff(tournament.tournament_id? tournament.tournament_id : tournament.id);
        //this.props.history.push('/tournament/hub/'+tournament.id); 
        this.setState({load_detail: true});
        this.props.loadCardIdTemplate( tournament.tournament_id? tournament.tournament_id : tournament.id );//Trae la plantilla de carnets en caso de existir
        //this.setState({profile_type: 'teams'});
        this.props.loadHubTournamentTeams(tournament.tournament_id? tournament.tournament_id : tournament.id, 'Enrolled');
        if(participate){
            await this.props.loadSelectedTournament(tournament.tournament_id? tournament.tournament_id : tournament.id); //TODO:revisar con el nuevo hub
        }else{//No participa en el torneo
            await this.props.loadInformativeTournament(tournament.tournament_id? tournament.tournament_id : tournament.id);
        }
        //Carga de nuevo el torneo en el estado si el usuario no participa en el (cuando el parametro no viene en la url)
        if(!this.props.state.tournaments?.selected_tournament){
            await this.props.loadInformativeTournament(tournament.tournament_id? tournament.tournament_id : tournament.id);
        }
        //Define el flujo de inscripcion
        this.setTournamentInscriptionFlowStart(from);
        //Carga la informacion de todo el torneo, incluyendo resultados por si se requiere (depende del estado del torneo)
        await this.props.loadTournamentInfo(tournament.tournament_id? tournament.tournament_id : tournament.id)
        this.props.loadTournamentPhases(tournament.tournament_id? tournament.tournament_id : tournament.id)
        if(this.props.state.tournaments?.selected_tournament_team){
            this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team.id, 'Pending|Enrolled|Rejected|Late_Register');
            this.props.loadTeamManagerAndStaff(this.props.state.tournaments?.selected_tournament_team.teamname,this.props.state.tournaments?.selected_tournament.id);
        }
        this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament_team?.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament_team?.teamname, 
            selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament_team?.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament_team?.id,
            selected_tournament_team_state: this.props.state.tournaments?.selected_tournament_team?.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament?.registration_type,
            selected_tournament_player_id: this.props.state.tournaments?.selected_tournament_team?.is_tournament_player?.id
        });
        this.props.showHubTournamentDetailAction(true);   
    }

    handleOnRejectInscription(){
        this.props.changeIsShareLinkStateAction(false);
        this.props.changeFlexiInscriptionModalStateAction(false);        
        this.props.history.replace({
            pathname: '/tournament/hub',
            search: '',
        });
    }

    handleOnViewTournament = async (tournament, from, profile_type, tournament_team = null) => {
        //console.log('torneo handleOnViewTournament', tournament, tournament_team);
        this.props.cleanTeamPlayersAction();
        this.props.loadTournamentStaff(tournament?.tournament_id? tournament.tournament_id : tournament?.id);
        this.setState({load_detail: true});
        this.props.loadCardIdTemplate( tournament?.tournament_id? tournament.tournament_id : tournament?.id );//Trae la plantilla de carnets en caso de existir
        /* if(profile_type){
            if(profile_type === 'detailplayer'){
                this.setState({profile_type: 'teams'});
            }else if(profile_type === 'flexiplayer'){
                this.setState({profile_type: 'player'});
                let clean_path = this.props.history.location.pathname;
                this.props.history.replace({
                    pathname: clean_path,
                    search: '',
                });
            }else{
                this.setState({profile_type: profile_type});
            }
        }else{
            this.setState({profile_type: 'teams'});
        } */
        if(profile_type === 'flexiplayer'){
            let clean_path = this.props.history.location.pathname;
            this.props.history.replace({
                pathname: clean_path,
                search: '',
            });
        }

        //** Equipos enrolados en el torneo */
        this.props.loadHubTournamentTeams(tournament?.tournament_id? tournament.tournament_id : tournament?.id, 'Enrolled');

        /* if(profile_type === 'teams' || profile_type === 'detailplayer'){
            await this.props.loadSelectedTeamTournament(tournament.tournament_team_id);
        }else if(profile_type === 'player'){
            await this.props.loadSelectedPlayerTournament(tournament.tournament_player_id);
        }else if(profile_type === 'flexiplayer'){
            if(tournament.request_tournament_player_id){
                await this.props.loadSelectedPlayerTournament(tournament.request_tournament_player_id);
            }else{
                this.props.addFlexiMemberToTeam( tournament.teamname, {username: this.props.state.user.me.username,role: {role: "player",position: null,player_number: null},tournament_id :tournament.tournament_id }, tournament.tournament_team_id );
            }
            
        }else if(from !== 'inscriptions'){ //TODO:revisar con el nuevo hub
            await this.props.loadSelectedTournament(tournament.tournament_id);
        } */

        /**Trae los equipos relacionados con el usuario (team admin o player) en el torneo */
        await this.props.loadSelectedTournament( tournament?.tournament_id? tournament.tournament_id : tournament?.id );
        
        //if(tournament.tournament_team_id){
        if(tournament_team){//TODO: CHECK AFTER CHANGE HUB APIS
            if(profile_type === 'flexiplayer'){
                if(!this.props.state.tournaments?.selected_tournament?.registration_deadline) { 
                    this.props.changeInscriptionDeadlineModalStateAction(true);
                }
                //let team_player_is_register = this.props.state.tournaments?.selected_tournament_my_teams.find(t => t.tournament_team_id === tournament.tournament_team_id);
                let team_player_is_register = this.props.state.tournaments?.selected_tournament_my_teams.find(t => t.id === tournament_team.id);//TODO: CHECK AFTER CHANGE HUB APIS
                if(team_player_is_register){
                    //console.log('ingresa por handleOnViewTournament team_player_is_register');
                    await this.props.loadSelectedTeamTournament(tournament_team.id);                
                }
            }else{
                //console.log('ingresa por handleOnViewTournament else');
                await this.props.loadSelectedTeamTournament(tournament_team.id);
            }
        }
        
        
        this.setTournamentInscriptionFlowStart(from);
        if(from === 'inscriptions'){
            this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team?.id, 'Pending|Enrolled|Rejected|Late_Register');    
            this.props.loadTeamManagerAndStaff(this.props.state.tournaments?.selected_tournament_team?.teamname, this.props.state.tournaments?.selected_tournament?.id); 
        }                

        /* if(profile_type === 'teams'){
            this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament.teamname, 
                selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament.tournament_team_id,
                selected_tournament_team_state: this.props.state.tournaments?.selected_tournament.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament.tournament_registration_type });
        }

        if(!profile_type && from === 'inscriptions'){
            this.setState({selected_tournament_team: tournament.team, selected_tournament_teamname: tournament.teamname, 
                selected_tournament_team_logo: tournament.team_logo, selected_tournament_team_id: tournament.tournament_team_id,
                selected_tournament_team_state: tournament.team_state, selected_tournament_registration_type: tournament.tournament_registration_type });
        }

        if(profile_type === 'player' || profile_type == 'flexiplayer'){  
            this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament.teamname, 
                selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament.tournament_team_id,
                selected_tournament_team_state: this.props.state.tournaments?.selected_tournament.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament.tournament_registration_type,
                selected_tournament_player_id: tournament.tournament_player_id
            });
        } */

        this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament_team?.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament_team?.teamname, 
            selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament_team?.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament_team?.id,
            selected_tournament_team_state: this.props.state.tournaments?.selected_tournament_team?.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament?.registration_type,
            selected_tournament_player_id: this.props.state.tournaments?.selected_tournament_team?.is_tournament_player?.id
        });

        this.props.showHubTournamentDetailAction(true);
        if(profile_type !== 'referee' && profile_type !== 'match') {
            this.handleVerifyFormPaymentPlayer();
        }
    }

    handleOnViewPaymentTournament = async (tournament, from, profile_type) => { //TODO: CHECK AFTER CHANGE HUB APIS
        //console.log('handleOnViewPaymentTournament props',tournament, from, profile_type);
        //console.log('handleOnViewPaymentTournament props selected state',this.props.state.tournaments?.selected_tournament);

        this.setState({load_detail: true});
        /* if(profile_type){
            if(profile_type === 'adminteams'){
                this.setState({profile_type: 'teams'});
            }else{
                this.setState({profile_type: profile_type});
            }
            
        } */
        if(profile_type === 'teams'){ //TODO:revisar con el nuevo hub
            //console.log('entra por teams');
            await this.props.loadSelectedTeamTournament(tournament.tournament_team_id);
            await this.props.loadSelectedTournament(this.props.state.tournaments?.selected_tournament.id, tournament.tournament_team_id);
            this.props.loadHubTournamentTeams(this.props.state.tournaments?.selected_tournament.id, 'Enrolled');
        }else if(profile_type === 'player'){ //TODO:revisar con el nuevo hub
            //console.log('entra por player');
            await this.props.loadTemporalPlayerTournament(tournament.tournament_player_id);
            //await this.props.loadSelectedTeamTournament(tournament.tournament_team_id);
            this.props.loadHubTournamentTeams(this.props.state.tournaments?.selected_tournament.id, 'Enrolled');
        }else if(profile_type === 'adminteams'){ //TODO:revisar con el nuevo hub
            //console.log('entra por adminteams');
            await this.props.loadTemporalPlayerTournament(tournament.tournament_player_id);            
            //await this.props.loadSelectedTeamTournament(this.props.state.tournaments?.selected_tournament.tournament_team_id);
            //await this.props.loadSelectedTeamTournament(tournament.tournament_team_id);
            this.props.loadHubTournamentTeams(this.props.state.tournaments?.selected_tournament.id, 'Enrolled');
        }else{ //TODO:revisar con el nuevo hub
            //console.log('entra por else');
            await this.props.loadSelectedTournament(tournament.tournament_id);
            this.props.loadHubTournamentTeams(tournament.tournament_id, 'Enrolled');
        }        

        this.setTournamentInscriptionFlowStart(from);
        this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team?.id, 'Pending|Enrolled|Rejected|Late_Register');//TODO:revisar con el nuevo hub
        this.props.loadTeamManagerAndStaff(this.props.state.tournaments?.selected_tournament_team?.teamname,this.props.state.tournaments?.selected_tournament?.id);
        this.props.showHubTournamentDetailAction(true);
        
        /* if(profile_type === 'teams' || profile_type === 'adminteams'){
            this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament.teamname, 
                selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament.tournament_team_id,
                selected_tournament_team_state: this.props.state.tournaments?.selected_tournament.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament.tournament_registration_type, profile_type: 'teams' });
        }
        if(profile_type === 'player'){
            this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament.teamname, 
                selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament.tournament_team_id,
                selected_tournament_team_state: this.props.state.tournaments?.selected_tournament.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament.tournament_registration_type,
                selected_tournament_player_id: tournament.tournament_player_id
            });
        } */
        this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament_team?.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament_team?.teamname, 
            selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament_team?.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament_team?.id,
            selected_tournament_team_state: this.props.state.tournaments?.selected_tournament_team?.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament?.registration_type,
            selected_tournament_player_id: this.props.state.tournaments?.selected_tournament_team?.is_tournament_player?.id
        });
        
        this.handleVerifyFormPaymentPlayer();
    }

    handleDetailBackButton = () => {    
        this.props.showHubTournamentDetailAction(false);//TODO: verificar comportamiento con nuevo hub  
        this.props.setHubTournamentHubAction(true);  
        this.props.resetTournamentPhasesAction({});
        this.props.resetPhaseDatesMatchesAction({});
        this.props.cleanTeamPlayersAction();
        this.setState( {tournament_active_phase: {}, active_phase_id: null} );
        if(this.props.history.location.pathname.includes('/tournament/hub')){
            this.setState({is_inscription: false, load_detail: false, selected_admin_player: undefined});
            this.props.history.push('/tournament/hub');
        }else{
            let clean_path = this.props.history.location.pathname;
            if(this.props.history.location.pathname.includes('/confirmpayment/')){
                clean_path = this.props.history.location.pathname.split('/confirmpayment/')[0];
            }
            this.props.history.replace({
                pathname: clean_path,
                search: '',
            });

            this.setViewPath(clean_path);
            this.setState({ is_inscription: false, selected_admin_player: undefined, load_detail: false });   
        }
    }

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.tournament_id? tournament.tournament_id : tournament.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareInscriptionTournamentLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_inscription_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareInscriptionTournament(tournament_team_id){
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/tournament/hub/team/${tournament_team_id}/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnShareAddMembersWithoutAccount(){
        this.props.cleanBulkMembersAction({});
        this.props.changeAddMembersWithoutAccountModalStateAction(true);   
    }

    handleCleanFilter = () => {
        this.props.loadAllManagedTournaments();
    }

    handleCleanFilterHub = () => {
        this.props.loadHubTournaments();
        this.props.setHubTournamentHubAction(true);
    }

    handleOnFilterTournamentHub(filter) {
        this.props.loadHubTournamentsFilter(filter.type_filter ?? '');
        this.props.setHubTournamentHubAction(true);
    }

    handleOnFilterTournamentAdmin(filter) {
        this.props.loadAllManagedTournaments(filter.type_filter ?? '');
    }

    changeDialogModalStateAction(stateModal) { this.setState({ openModal: stateModal }) };

    handleTeamInscription = async ( tournament_team_id ) => {
        
        const {category, football_type, gender} = this.props.state.tournaments.selected_tournament;
        if(this.props.state.tournaments?.selected_tournament.request_has_pages && this.props.state.tournaments?.selected_tournament.request_has_teams){
            await this.props.loadProfileAdminTeams(`tournament_id=${this.props.state.tournaments.selected_tournament?.id}&team_category=${this.props.state.tournaments.selected_tournament?.category}&team_gender=${this.props.state.tournaments.selected_tournament?.gender}`);
            this.props.changeInscriptionsModalStateAction(true);
        }else if(!this.props.state.tournaments?.selected_tournament.request_has_pages){
            this.setState({load_detail: false});
            // INSCRIPCIÓN EQUIPO PERFIL SIN PÁGINA
            // this.handleChangeModalType(
            //     {
            //         typeModal: 'linkHub-createPage',
            //         titleModal: this.props.t('modalsInformation.modalProfileWithoutPage.titleModal'),
            //         subTitleModal: this.props.t('modalsInformation.modalProfileWithoutPage.subtitleModal'),
            //         principalButtonActionLink:'/',
            //         principalButtonLabel: this.props.t('modalsInformation.modalProfileWithoutPage.principalButtonLabel'),
            //         secondaryButton: true,
            //         secondaryButtonLabel: this.props.t('modalsInformation.modalProfileWithoutPage.secondaryButtonLabel'),
            //     }); // InfoHub - waringHub - linkHub-keyword
            // this.changeDialogModalStateAction(true);
            this.setState({new_page_team_inscription: true});
            this.props.changePageNewTeamModalStateAction(true);
                
        }else if(this.props.state.tournaments?.selected_tournament.request_has_pages){
            this.setState({load_detail: false});
            // INSCRIPCIÓN EQUIPO PERFIL SIN EQUIPO PERO CON PÁGINA
            // this.handleChangeModalType(
            //     {
            //         typeModal: 'linkHub-pages',
            //         titleModal: this.props.t('modalsInformation.modalProfileWithoutTeam.titleModal'),
            //         subTitleModal: this.props.t('modalsInformation.modalProfileWithoutTeam.subtitleModal'),
            //         principalButtonActionLink:'/',
            //         principalButtonLabel: this.props.t('modalsInformation.modalProfileWithoutTeam.principalButtonLabel'),
            //         secondaryButton:true,
            //         secondaryButtonLabel: this.props.t('modalsInformation.modalProfileWithoutTeam.secondaryButtonLabel'),
            //     });// InfoHub - waringHub - linkHub-keyword
            // this.changeDialogModalStateAction(true);
            this.setState({new_page_team_inscription: true});
            this.props.changePageNewTeamModalStateAction(true);
        }else {
            this.setState({load_detail: false});
            // INSCRIPCIÓN EQUIPO EQUIPO NO COINCIDE CON EL TORNEO
            //console.log(this.props.state.tournaments.selected_tournament)
            this.handleChangeModalType(
                {
                    typeModal: 'InfoHub',
                    titleModal: this.props.t('modalsInformation.modalTeamNoMatchTournament.titleModal'),
                    subTitleModal: this.props.t('modalsInformation.modalTeamNoMatchTournament.subtitleModal',
                        {_category: category, _typeFootball:football_type, _gender: gender }),
                    principalButtonLabel: this.props.t('modalsInformation.modalTeamNoMatchTournament.principalButtonLabel'),
                    secondaryButton:false,
                });// InfoHub - waringHub - linkHub-keyword
            this.changeDialogModalStateAction(true);
        }
        
    }

    handlePlayerInscription = ( tournament_team_id ) => {
        //if(this.props.state.tournaments?.selected_tournament.request_has_teams){
            this.props.changeInscriptionsModalStateAction(true);
        //}
    }

    finalizeInscription = async ( ) => {
        
        this.props.cleanTeamDataAction();
        this.setState({load_created_teams: false});
        let nextPath = this.props.history.location.pathname;
        if(this.state.selected_tournament_team_id){

            if(this.state.profile_type === 'player'){
                //this.props.changeTeamPlayerState(this.state.selected_tournament_player_id,'Enrolled'); //Se realiza automaticamente desde el back
                nextPath = '/tournament/inscriptions?detailplayer';            
            }else{
                //nextPath = '/tournament/inscriptions?detailTeam';
                await this.props.changeTournamentTeamState(this.props.state.tournaments?.selected_tournament_team?.id,'Register');
                this.handleOnViewPaymentTournament( {tournament_team_id: this.props.state.tournaments?.selected_tournament_team?.id}, 'inscriptions', 'teams');
                this.props.setHubTournamentInscriptionsAction(true);
                this.handleOnShareInscriptionTournament(this.props.state.tournaments?.selected_tournament_team?.id);
            }
            
        }else{
            if(this.state.inscrption_steps == 1 || this.state.inscrption_steps == 3 ){
                await this.props.registerHubTeamToTournament(this.props.state.tournaments.selected_tournament.id, this.state.selected_team.teamname, 'Register');
            } else {
                await this.props.changeTournamentTeamState(this.props.state.tournaments.payment_tournament_team_id,'Register');
            }
            //Desde hub
            //await this.props.registerHubTeamToTournament(this.props.state.tournaments.selected_tournament.id, this.state.selected_team.teamname, 'Register');
            nextPath = '/tournament/inscriptions?detailTeamFree';
        } 

        this.props.changeInscriptionsModalStateAction(false);

        setTimeout(() => {  
            this.props.history.push(nextPath);
        }, 2000); 
    }

    setTournamentInscriptionFlowStart =(from) =>{
        this.setState({from_flow: from});
        switch( from ) {
            case "hub": {
                this.setState({start_flow: 0});
                //this.props.loadProfileAdminTeams(`tournament_id=${this.props.state.tournaments.selected_tournament?.tournament_id}&team_category=${this.props.state.tournaments.selected_tournament?.tournament_category}&team_gender=${this.props.state.tournaments.selected_tournament?.tournament_gender}`); //Se mueve para cuando creo un equpo en flujo de inscripcion o me incribo desde el el torneo en el hub
                if(this.props.state.tournaments.selected_tournament?.has_team_form && (this.props.state.tournaments?.selected_tournament.registration_type == 'Paid' || this.props.state.tournaments?.selected_tournament.registration_type == 'Team_paid') ){
                    this.props.loadTournamentInscriptionFormTemplate('team_tournament_form',this.props.state.tournaments?.selected_tournament.tournament_id? this.props.state.tournaments?.selected_tournament.tournament_id : this.props.state.tournaments?.selected_tournament.id );
                    this.setState({inscrption_steps: 4});
                }else if(this.props.state.tournaments.selected_tournament?.has_team_form ){                
                    this.props.loadTournamentInscriptionFormTemplate('team_tournament_form',this.props.state.tournaments?.selected_tournament.tournament_id? this.props.state.tournaments?.selected_tournament.tournament_id : this.props.state.tournaments?.selected_tournament.id);
                    this.setState({inscrption_steps: 2});
                }else if(this.props.state.tournaments.selected_tournament?.registration_type == 'Paid' || this.props.state.tournaments.selected_tournament?.registration_type == 'Team_paid'  /* || this.props.state.tournaments.selected_tournament?.tournament_registration_type == 'Player_paid' */ ){
                    this.setState({inscrption_steps: 3});
                }else if(this.props.state.tournaments.selected_tournament?.registration_type == 'Free' ){
                    this.setState({inscrption_steps: 1});
                }

                //Load player form template if available - se usa cuando llega por link
                if(this.props.state.tournaments.selected_tournament?.has_player_form ){
                    this.props.loadTournamentPlayerFormTemplate('player_tournament_form',this.props.state.tournaments?.selected_tournament.id);
                }
                                
                return true;
            }
            case "inscriptions": {
                this.setState({is_inscription: true});

                //Load player form template if available
                if(this.props.state.tournaments.selected_tournament?.has_player_form ){
                    this.props.loadTournamentPlayerFormTemplate('player_tournament_form',this.props.state.tournaments?.selected_tournament.id);
                }
                if(this.props.state.tournaments.selected_tournament?.has_team_form ){                
                    this.props.loadTournamentInscriptionFormTemplate('team_tournament_form',this.props.state.tournaments?.selected_tournament.id);
                }
                return true;
            }
            case "invitations": {
                this.setState({start_flow: 1});
                // Ajustes con profile_type undefined - unificacion teams-player
                if(/* this.state.profile_type === 'teams' && */ this.props.state.tournaments?.selected_tournament.has_team_form && (this.props.state.tournaments?.selected_tournament.registration_type == 'Paid' || this.props.state.tournaments?.selected_tournament.registration_type == 'Team_paid' ) ){
                    this.props.loadTournamentInscriptionFormTemplate('team_tournament_form',this.props.state.tournaments?.selected_tournament.id);
                    this.setState({inscrption_steps: 4});
                }/* else if(this.state.profile_type === 'player' && this.props.state.tournaments?.selected_tournament.has_player_form && (this.props.state.tournaments?.selected_tournament.tournament_registration_type == 'Paid' || this.props.state.tournaments?.selected_tournament.tournament_registration_type == 'Player_paid' ) ){  //NINGUN JUGADOR ES INVITADO A UN TORNEO
                    this.props.loadTournamentInscriptionFormTemplate('player_tournament_form',this.props.state.tournaments?.selected_tournament.tournament_id);
                    this.setState({inscrption_steps: 4});
                } */else if(/* this.state.profile_type === 'teams' && */ this.props.state.tournaments?.selected_tournament.has_team_form ){
                    this.props.loadTournamentInscriptionFormTemplate('team_tournament_form',this.props.state.tournaments?.selected_tournament.id);
                    this.setState({inscrption_steps: 2});
                }/* else if(this.state.profile_type === 'player' && this.props.state.tournaments?.selected_tournament.has_player_form ){ //NINGUN JUGADOR ES INVITADO A UN TORNEO
                    this.props.loadTournamentInscriptionFormTemplate('player_tournament_form',this.props.state.tournaments?.selected_tournament.tournament_id);
                    this.setState({inscrption_steps: 2});
                } */else if(this.props.state.tournaments?.selected_tournament?.registration_type == 'Paid' || this.props.state.tournaments?.selected_tournament?.registration_type == 'Team_paid' /* || this.props.state.tournaments?.selected_tournament?.tournament_registration_type == 'Player_paid' */ ){
                    this.setState({inscrption_steps: 3});
                }else if(this.props.state.tournaments.selected_tournament?.registration_type == 'Free' ){
                    this.setState({inscrption_steps: 1});
                }
                return true;
            }
            // no default
        }
    }

    setSelectedTeam= async (team)=>{
        this.setState({ selected_team: team});
        if(this.props.state.tournaments?.profile_admin_teams?.length === 1){
            // this.setState({start_flow: 1});
        }
        if((this.state.inscrption_steps == 2 || this.state.inscrption_steps == 4)  && team){
            //Se registra equipo en el torneo
            await this.props.registerHubTeamToTournamentToPay(this.props.state.tournaments.selected_tournament.id, team.teamname, 'Register');
            // FIXME: Se comento ya que se selecciona por debajo el equipo pero visualmente no se muestra.
        }
    }

    handleChangeModalType(optionsModal) {
        const { 
            typeModal, 
            titleModal, 
            subTitleModal, 
            principalButtonLabel,
            secondaryButton, 
            secondaryButtonLabel,
            principalButtonActionLink
            } = optionsModal;
        this.setState({ typeModal });
        this.setState({ titleModal });
        this.setState({ subTitleModal });
        this.setState({ principalButtonLabel });
        this.setState({secondaryButton});
        this.setState({ secondaryButtonLabel });
        this.setState({ principalButtonActionLink});
    }

    handleClickPrimaryButton(type_action){
        switch(type_action){
            case 'InfoHub':
                //Funtion InfoHub
                this.changeDialogModalStateAction(false);
                break;
            case 'waringHub':
                //Funtion waringHub
                //if(this.state.selected_tournament_team_state === 'Cancelled') {
                if(this.state.profile_type === 'teams'){ 
                    this.props.changeTournamentTeamState(this.state.selected_tournament_team_id, this.state.selected_tournament_team_state);
                }

                //if(this.state.selected_tournament_team_state === 'Rejected'){
                if(this.state.profile_type === 'player'){                   
                    let player = this.props.state.tournaments?.player_tournaments?.filter( (player) => player.tournament_team_id === this.state.selected_tournament_team_id );
                    this.props.changeTournamentPlayerState(player[0].tournament_player_id, this.state.selected_tournament_team_state);
                }
                
                this.setState({ selected_tournament_team_id: null });
                this.setState({ selected_tournament_team_state: null });
                this.changeDialogModalStateAction(false);
                break;
            default:
                this.changeDialogModalStateAction(false);
        }
    }
    
    setPlayersQuantity=(quantity)=>{
        this.setState({ payment_players_quantity: quantity});
    }
    handleOnPayment= async ()=>{
        if(this.state.from_flow === 'invitations'){
            if(this.state.profile_type === 'player'){                
                //pago jugador por invitacion
                if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'USD'){
                    await this.props.loadPlayerPaymentUrl(this.state.selected_tournament_player_id,this.state.payment_player_code, this.state.profile_type);
                    this.props.changeInscriptionsModalStateAction(false);
                    this.props.showHubTournamentDetailAction(false);
                    if(this.props.state.tournaments.payment_url_redirect){
                    const newWindow = window.open(this.props.state.tournaments.payment_url_redirect, '_self', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                    }
                }
                if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'COP'){
                    this.setState({submitWompiForm: true}, () => {
                        this.props.changeInscriptionsModalStateAction(false);
                        this.props.showHubTournamentDetailAction(false);   
                    });
                }                
            }else{
                //pago equipo por invitacion
                if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'USD'){
                    await this.props.loadPaymentUrl(this.state.selected_tournament_team_id,this.state.payment_players_quantity, this.state.profile_type);
                    this.props.changeInscriptionsModalStateAction(false);
                    this.props.showHubTournamentDetailAction(false);
                    if(this.props.state.tournaments.payment_url_redirect){
                    const newWindow = window.open(this.props.state.tournaments.payment_url_redirect, '_self', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                    }
                }
                if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'COP'){
                    this.setState({submitWompiForm: true}, () => {
                        this.props.changeInscriptionsModalStateAction(false);
                        this.props.showHubTournamentDetailAction(false);   
                    });
                }                
            }
            
        }
        if(this.state.from_flow === 'hub'){
            //Se registra equipo en el torneo si aun no ha sido registrado
            if(!this.props.state.tournaments.payment_tournament_team_id && !this.props.state.tournaments?.selected_tournament_team?.team_state){
                await this.props.registerHubTeamToTournamentToPay(this.props.state.tournaments.selected_tournament.id, this.state.selected_team.teamname, 'Register');
            }            
            
            //pago equipo desde hub            
            if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'USD'){
                /* Se coloca validacion de tipo de pago ya que por link entra como pago desde hub */
                if(this.state.form_profile_type == 'player'){
                    await this.props.loadPlayerPaymentUrl(this.state.selected_tournament_player_id,this.state.payment_player_code, 'player');
                }else{
                    await this.props.loadPaymentUrl(this.props.state.tournaments.payment_tournament_team_id? this.props.state.tournaments.payment_tournament_team_id :this.state.selected_tournament_team_id,this.state.payment_players_quantity, 'teams');
                }
                this.props.changeInscriptionsModalStateAction(false);
                this.props.showHubTournamentDetailAction(false);
                if(this.props.state.tournaments.payment_url_redirect){
                    const newWindow = window.open(this.props.state.tournaments.payment_url_redirect, '_self', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                    }
            }
            if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'COP'){
                this.setState({submitWompiForm: true}, () => {
                    this.props.changeInscriptionsModalStateAction(false);
                    this.props.showHubTournamentDetailAction(false);   
                });
            }
        }

        if(this.state.from_flow === 'inscriptions'){

            //pago desde inscriptions            
            if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'USD'){
                if(this.state.form_profile_type == 'player'){
                    await this.props.loadPlayerPaymentUrl(this.state.selected_tournament_player_id,this.state.payment_player_code, 'player');
                }else{
                    await this.props.loadPaymentUrl(this.props.state.tournaments.selected_tournament_team.id, this.state.payment_players_quantity, 'teams');
                }
                this.props.changePaymentsModalStateAction(false);
                this.props.showHubTournamentDetailAction(false);
                if(this.props.state.tournaments.payment_url_redirect){
                    const newWindow = window.open(this.props.state.tournaments.payment_url_redirect, '_self', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                    }
            }
            if(this.props.state.tournaments?.selected_tournament?.currency_payment === 'COP'){
                this.setState({submitWompiForm: true}, () => {
                    this.props.changePaymentsModalStateAction(false);
                    this.props.showHubTournamentDetailAction(false);   
                });
            }
        }

    }

    // Send data to form complete Player or Team in step inscriptio
    processDataPlayerInscription(data, reload_data){

        if((this.props.state.tournaments?.selected_tournament_team?.id === data.tournament_team_id) && (this.state.form_tournament_player_id === data.tournament_player_id)){
            //console.log('son iguales y haga');
        }else {
            //console.log('no son iguales ');
        }
        this.props.sentPlayerInscriptionForm(data, reload_data, this.state.selected_tournament_teamname, this.props.state.tournaments?.selected_tournament.id);
    }

    handleOnViewPaidTournament = (tournament_id, from, profile_type ) => {
        if(profile_type === 'teams'){
            this.handleOnViewPaymentTournament( {tournament_team_id: tournament_id}, from, profile_type);
        }
        if(profile_type === 'player'){
            this.handleOnViewPaymentTournament( {tournament_player_id: tournament_id}, from, profile_type);
        }
        if(profile_type === 'adminteams'){
            this.handleOnViewPaymentTournament( {tournament_player_id: tournament_id}, from, profile_type);
        }        
  
    }

    handleOnCodeButton = async (player_code) => {
        await this.props.loadPlayerPaymentUrl(this.state.selected_tournament_player_id,player_code, 'player');
        this.setState({show_code_payment: 'player_code'});
    }
    

    handleOnLoadMoreTournaments = (subsection) =>{
        if(this.props.state.tournaments?.hub_tournament_next_page !== null){
            this.props.loadHubTournamentsSubsections(subsection, this.props.state.tournaments?.loading_tournaments, this.props.state.tournaments?.hub_tournament_next_page );
        }
    }

    handleOnRejectTeamInvitation = (tournament_team_id, team_state, profile_type) => {
        this.handleChangeModalType(
            {
                typeModal: 'waringHub',
                titleModal: this.props.t('modalsInformation.modalTeamRejectInvitation.titleModal'),
                subTitleModal: this.props.t('modalsInformation.modalTeamRejectInvitation.subTitleModal'),
                principalButtonLabel: this.props.t('modalsInformation.modalTeamRejectInvitation.principalButtonLabel'),
                secondaryButton: true,
                secondaryButtonLabel: this.props.t('modalsInformation.modalTeamRejectInvitation.secondaryButtonLabel'),
            }
        );

        this.setState({ selected_tournament_team_id: tournament_team_id });
        this.setState({ selected_tournament_team_state: team_state });
        this.setState({profile_type: profile_type});
        this.changeDialogModalStateAction(true);
    }

    onNewPageInformation = (data_page) =>{
        this.props.createPage(data_page);
    };

    realizePlayerForm(selected_player, type_redirect){
        //  console.log('form--' ,selected_player);
        //this.setState({selected_admin_player: selected_player, form_tournament_player_id: selected_player.tournament_player_id, form_profile_type: 'player', type_redirect: type_redirect });
        this.setState({selected_admin_player: selected_player, form_tournament_player_id: type_redirect === "staff"? null : selected_player.id, form_profile_type: 'player', type_redirect: type_redirect }); //Cambio api players
        this.props.changeFormsModalStateAction(true);
    }

    realizeTeamForm(type_redirect){
        this.setState({form_tournament_player_id: null, form_profile_type: 'teams', type_redirect: type_redirect });
        this.props.changeFormsModalStateAction(true);
    }

    realizePlayerPayment(selected_player, type_redirect){
        //this.setState({selected_admin_player: selected_player, form_tournament_player_id: selected_player.tournament_player_id, selected_tournament_player_id: selected_player.tournament_player_id, form_profile_type: 'player', type_redirect: type_redirect });
        this.setState({selected_admin_player: selected_player, form_tournament_player_id: selected_player.id, selected_tournament_player_id: selected_player.id, form_profile_type: 'player', type_redirect: type_redirect });  //Cambio api players
        this.props.changePaymentsModalStateAction(true);
    }

    realizeTeamPayment(type_redirect){
        this.setState({form_profile_type: 'teams', type_redirect: type_redirect });
        this.props.changePaymentsModalStateAction(true);
    }

    handleCuponCodePayment= async ()=>{
        this.props.changePaymentsModalStateAction(false); 
        if(this.state.form_profile_type == 'player'){
            await this.props.loadSelectedPlayerTournament(this.props.state.tournaments?.selected_tournament_team?.is_tournament_player?.id ?? this.state.selected_tournament_player_id);       
        }else{
            //console.log('ingresa por handleCuponCodePayment');
            await this.props.loadSelectedTeamTournament(this.props.state.tournaments?.selected_tournament_team.id);       
        }
        this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team.id, 'Pending|Enrolled|Rejected|Late_Register'); 
        this.setState({selected_admin_player: undefined, show_code_payment: undefined});
    }

    onUploadProgress = (progressEvent) => {
        this.setState({ uploadProgress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
    }

    handleCreateNewTeam = async (team_data, imgb64) => {
        const new_team_data = Object.keys(team_data).reduce((object, key) => {
        if (key !== 'category' && key !== 'pagename' && key !== 'pageName') {
            object[key] = team_data[key]
        }
        object.team_tag = [{ extra: { atype: "team-tags", value: team_data.category } }];
        object.photo = imgb64;
        return object
        }, {});
        this.setState({load_created_teams: true});
        await this.props.addNewTeam(new_team_data, this.onUploadProgress, team_data.pageName);
        await this.props.loadHubTournamentTeams(this.props.state.tournaments.selected_tournament.id, '');
        
        //Precarga todos los equipos del usuario para poder seleccionar el equipo por defecto en el modal inscriptionsModal           
        this.props.loadProfileAdminTeams(`tournament_id=${this.props.state.tournaments.selected_tournament?.id}&team_category=${this.props.state.tournaments.selected_tournament?.category}&team_gender=${this.props.state.tournaments.selected_tournament?.gender}`);
        
        this.setTournamentInscriptionFlowStart('hub');
        this.props.changeInscriptionsModalStateAction(true);
    }

    /** Funcion que verifica si un equipo y / o jugador tienen formulario y pago pendiente */
    handleVerifyFormPaymentPlayer() {

        /** Team Inscrito con formulario y pago pendientes */
        if(this.props.state.tournaments.selected_tournament_team?.team_state !== 'Enrolled' && this.props.state.tournaments.selected_tournament_team?.team_state !== 'Register' && 
            this.props.state.tournaments.selected_tournament?.has_team_form && 
            (this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type === 'Team_paid' ) && 
            (this.props.state.tournaments.selected_tournament_team?.team_form_state === 'Postponed' || this.props.state.tournaments.selected_tournament_team?.team_form_state === 'Pending') && 
            (this.props.state.tournaments.selected_tournament_team?.team_payment_state === 'Postponed' || this.props.state.tournaments.selected_tournament_team?.team_payment_state === 'Pending') 
            && !this.props.state.tournaments.selected_tournament_team?.team_has_paid){
            //console.log('Team Inscrito con formulario y pago pendientes')
            this.setState({verification_message: this.props.t('viewTournament.inscription.teamPendingFormPaymentMsg')});
            this.setState({is_form: true});
            this.setState({is_payment: true});
            this.setState({profile_type: 'teams'});
            this.props.changeVerifyFormPaymentModalStateAction(true)
        }


        /** Team Inscrito con formulario pendiente */
        if(this.props.state.tournaments.selected_tournament_team?.team_state !== 'Enrolled' && this.props.state.tournaments.selected_tournament_team?.team_state !== 'Register' &&
            this.props.state.tournaments.selected_tournament?.has_team_form && 
            (this.props.state.tournaments.selected_tournament_team?.team_form_state === 'Postponed' || this.props.state.tournaments.selected_tournament_team?.team_form_state === 'Pending') && 
            (((this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type == "Team_paid" ) && this.props.state.tournaments.selected_tournament_team?.team_has_paid)  
                || this.props.state.tournaments.selected_tournament?.registration_type == "Free" )){
            //console.log('Team Inscrito con formulario pendiente')
            this.setState({verification_message: this.props.t('viewTournament.inscription.teamPendingFormMsg')});
            this.setState({is_form: true});
            this.setState({is_payment: false});
            this.setState({profile_type: 'teams'});
            this.props.changeVerifyFormPaymentModalStateAction(true)
        }

        /** Team Inscrito con pago pendiente */
        if(this.props.state.tournaments.selected_tournament_team?.team_state === 'Enrolled' && this.props.state.tournaments.selected_tournament_team?.team_state !== 'Register' &&
            (this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type == "Team_paid" ) 
            && !this.props.state.tournaments.selected_tournament_team?.team_has_paid && 
            (this.props.state.tournaments.selected_tournament_team?.team_form_state === 'Done' || this.props.state.tournaments.selected_tournament_team?.team_form_state === 'No_Needed')){
            //console.log('Team Inscrito con pago pendiente')
            this.setState({verification_message: this.props.t('viewTournament.inscription.teamPendingPaymentMsg')});
            this.setState({is_form: false});
            this.setState({is_payment: true});
            this.setState({profile_type: 'teams'});
            this.props.changeVerifyFormPaymentModalStateAction(true)
        }


        /** Player Inscrito con formulario y pago pendientes */
        if(this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.state === 'Pending' && 
            this.props.state.tournaments.selected_tournament?.has_player_form && 
            (this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type == "Player_paid" ) && 
            (this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'Postponed' || this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'Pending') && 
            !this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.has_paid){ //TODO: CHECK AFTER CHANGE HUB APIS
            //console.log('Player Inscrito con formulario y pago pendientes')
            this.setState({verification_message: this.props.t('viewTournament.inscription.playerPendingFormPaymentMsg')});
            this.setState({is_form: true});
            this.setState({is_payment: true});
            this.setState({profile_type: 'player'});
            
            //this.props.changeVerifyFormPaymentModalStateAction(true) 
            if (this.props.state.tournaments.selected_tournament?.registration_deadline) { 
                this.props.changeVerifyFormPaymentModalStateAction(true);
            } else {
                this.handleCloseVerifyFormPaymentTeamModal();
            }
        }

        /** Player Inscrito con formulario pendiente */
        if(this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.state === 'Pending' && 
            this.props.state.tournaments.selected_tournament?.has_player_form && 
            (this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'Postponed' || this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'Pending') && 
            (((this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type == "Player_paid" ) && this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.has_paid) || //TODO: CHECK AFTER CHANGE HUB APIS
                this.props.state.tournaments.selected_tournament?.registration_type == "Free")){
            //console.log('Player Inscrito con formulario pendiente')
            this.setState({verification_message: this.props.t('viewTournament.inscription.playerPendingFormMsg')});
            this.setState({is_form: true});
            this.setState({is_payment: false});
            this.setState({profile_type: 'player'});
            
            //this.props.changeVerifyFormPaymentModalStateAction(true) 
            if (this.props.state.tournaments.selected_tournament?.registration_deadline) { 
                this.props.changeVerifyFormPaymentModalStateAction(true); 
            } else {
                this.handleCloseVerifyFormPaymentTeamModal();
            }
        }

        /** Player Inscrito con pago pendiente */
        if(this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.state === 'Pending' && 
            (this.props.state.tournaments.selected_tournament?.registration_type == "Paid" || this.props.state.tournaments.selected_tournament?.registration_type == "Player_paid" ) && !this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.has_paid && 
            (this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'Done' || this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.form_state === 'No_Needed')){
            //console.log('Player Inscrito con pago pendiente')
            this.setState({verification_message: this.props.t('viewTournament.inscription.playerPendingPaymentMsg')});
            this.setState({is_form: false});
            this.setState({is_payment: true});
            this.setState({profile_type: 'player'});
            
            //this.props.changeVerifyFormPaymentModalStateAction(true) 
            if (this.props.state.tournaments.selected_tournament?.registration_deadline) { 
                this.props.changeVerifyFormPaymentModalStateAction(true);
            } else {
                this.handleCloseVerifyFormPaymentTeamModal();
            }
        }
    }

    handleCloseVerifyFormPaymentTeamModal = () => {
        this.setState({verification_message: ''});
        this.setState({is_form: false});
        this.setState({is_payment: false});
        this.props.changeVerifyFormPaymentModalStateAction(false);
    }

    handleModalForm = (btn_type) => {
        if(this.state.profile_type === 'player' && btn_type === 'form') {
            this.props.changeVerifyFormPaymentModalStateAction(false);
            this.realizePlayerForm(this.props.state.tournaments.team_players.find(team => team.username == this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.username), this.state.profile_type); //TODO: CHECK AFTER CHANGE HUB APIS
        }
        if(this.state.profile_type === 'player' && btn_type === 'payment') {
            this.props.changeVerifyFormPaymentModalStateAction(false);
            this.realizePlayerPayment(this.props.state.tournaments.team_players.find(team => team.username == this.props.state.tournaments.selected_tournament_team?.is_tournament_player?.username), this.state.profile_type);
        }
        if(this.state.profile_type === 'teams' && btn_type === 'form') {
            this.props.changeVerifyFormPaymentModalStateAction(false);
            this.realizeTeamForm(this.state.profile_type);
        }
        if(this.state.profile_type === 'teams' && btn_type === 'payment') {
            this.props.changeVerifyFormPaymentModalStateAction(false);
            this.realizeTeamPayment(this.state.profile_type);
        }
    }

    cleanMyTeams(my_teams){
        let myTeams=[];
        my_teams.forEach(element => {
            myTeams.push({ value: element.id, id: element.id, team: element.team, team_logo: element.team_logo, teamname: element.teamname  });
        });
        
        return myTeams;
    }

    changeSelectedTournamentTeam = async (tournament_team_id) => {
        //console.log('lo que llega a changeSelectedTournamentTeam',tournament_team_id)
        //let newTournamentTeam = this.props.state.tournaments.selected_tournament_my_teams.find(tournamenteam => tournamenteam.tournament_team_id === tournament_team_id);
        let newTournamentTeam = this.props.state.tournaments.selected_tournament_my_teams.find(tournamenteam => tournamenteam.id === tournament_team_id);//TODO: CHECK AFTER CHANGE HUB APIS
        if(newTournamentTeam != undefined){
            await this.props.setSelectedTournamentTeamAction(newTournamentTeam);
            this.setState({selected_tournament_team: this.props.state.tournaments?.selected_tournament_team?.team, selected_tournament_teamname: this.props.state.tournaments?.selected_tournament_team?.teamname, 
                selected_tournament_team_logo: this.props.state.tournaments?.selected_tournament_team?.team_logo, selected_tournament_team_id: this.props.state.tournaments?.selected_tournament_team?.id,
                selected_tournament_team_state: this.props.state.tournaments?.selected_tournament_team?.team_state, selected_tournament_registration_type: this.props.state.tournaments?.selected_tournament?.registration_type,
                selected_tournament_player_id: this.props.state.tournaments?.selected_tournament_team?.is_tournament_player?.id
            });
            this.props.loadTeamPlayers(tournament_team_id, 'Pending|Enrolled|Rejected|Late_Register');
            this.props.loadTeamManagerAndStaff(this.props.state.tournaments?.selected_tournament_team.teamname,this.props.state.tournaments?.selected_tournament.id);
        }
    }

    handleHubTournamentSection = async (tournament, section_hub) => {//TODO: CHECK AFTER CHANGE HUB APIS
        //console.log('handleHubTournamentSection tournament',tournament);
        this.setState({load_detail: true});        
        if(section_hub === 'tournament_invitation'){
            //this.props.loadTournamentStaff(tournament.tournament_id);
            await this.props.loadTournamentInfo(tournament.tournament_id);
            this.props.loadTournamentPhases(tournament.tournament_id);
            this.handleOnViewTournament(tournament, 'invitations', 'teams')
        } else if(section_hub === 'tournament_participate' || section_hub === 'tournament_pending'){
            //this.props.loadTournamentStaff(tournament.tournament_id);
            await this.props.loadTournamentInfo(tournament.tournament_id);
            this.props.loadTournamentPhases(tournament.tournament_id);
            this.handleOnViewTournament(tournament, 'inscriptions', 'teams')
        } else {
            if(tournament.participate){
                this.props.history.push('/tournament/hub/'+tournament.tournament_id);
            }else{
                this.props.history.push('/tournament/hub/'+tournament.tournament_id+'?participate=false');
            }
            
        }
    }

    handleOnSubsectionViewMore = (subsection) => {
        if(subsection === 'tournament_invitation') {
            this.props.history.push('/tournament/invitations');
        } else if(subsection === 'tournament_participate') {
            this.props.history.push('/tournament/inscriptions');
        } else if(subsection === 'tournament_admin'){
            this.props.history.push('/tournament/my-tournaments');
        } else if(subsection === 'matches'){
            this.props.history.push('/tournament/matches');
        } else {            
            this.setState({tournament_subsection: subsection});
            this.setState({is_subsection: true});
            this.props.loadHubTournamentsSubsections(subsection);
        }
    }

    handleBackTournamentViewMore = () => {
        this.setState({tournament_subsection: ''});
        this.setState({is_subsection: false});
        this.setState({load_detail: false});
        this.props.clearHubTournamentSubsectionAction();
    }

    handleIgnoreBtn = ({subsection,object,tournament_team_id}) => {
        if (object === "team"){
            this.props.changeTournamentTeamState(tournament_team_id,'Cancelled' );
            // this.props.loadHubTournamentsSubsections(subsection);
        }
    }

    handleFilterSearchTournament = (query_params) => {
        this.setState({tournament_subsection: query_params.state});
        this.setState({is_subsection: true});
        this.setState({filter_component_activation: true});
        this.props.history.push('/tournament/hub/search');

        if(query_params.state === 'registration_open') {
            query_params.state = 'Registration_Open'
        } 
        if(query_params.state === 'in_progress') {
            query_params.state = 'Scheduled'
        }
        let queryParams = '';
        
        if(this.state.word_filter.length > 0) {
            queryParams = `tournament_name=${this.state.word_filter}`;
        }
        for (var key in query_params) {
            if ( query_params[key].length > 0 ) {
                queryParams += `&${key}=${query_params[key]}`;
            }
        }
        
        if(this.state.path_next_page.length > 0 && (this.state.path_next_page !== query_params)){
            this.props.clearHubTournamentSubsectionAction();
            this.props.getHubTournamentSearch(queryParams, this.props.state.tournaments?.loading_tournaments, null);
        }else{
            this.setState({path_next_page: queryParams});
            this.props.getHubTournamentSearch(queryParams, this.props.state.tournaments?.loading_tournaments, this.props.state.tournaments?.hub_tournament_next_page);
        }
    }

    handleOnFollowTournament = (tournamentId) =>{
        this.props.onFollowTournament(tournamentId);
    }

    handleOnUnfollowTournament = (tournamentId)=>{
        this.props.onUnfollowTournament(tournamentId);
    }

    handleOnPhaseClick = async (phase) => {
        this.setState({tournament_active_phase: phase, active_phase_id: phase.id});
        if(phase.best_third){
            this.setState({is_best_third: true});
        } else {
            this.setState({is_best_third: false});
        }
        this.props.loadPhaseResultsTable(phase?.id);
        await this.props.loadPhaseDates(phase?.id);
        await this.props.loadPhaseCalendar(phase?.id);
        //await this.props.loadPhaseMatchesByDate(phase?.id, phase?.calendar_dates[phase?.calendar_dates.length - 1].calendar_date); 
        this.handleGroupsMatchByDate();
    }

    handleGroupsMatchByDate = () => {
        let groups_by_date = [];
        for(let i = 0; i < this.props.state.leagueTournaments.tournament_results.selected_date_matches.length; i++ ) {
            groups_by_date.push(this.props.state.leagueTournaments.tournament_results.selected_date_matches[i].group_match);            
        }
        let filtered_groups = [...new Set(groups_by_date)]
        this.setState({groups_matches_by_date: filtered_groups})
    }

    handleOnPhaseDate = async (date) => {
        await this.props.loadPhaseMatchesByDate(this.state.active_phase_id, date);
        this.handleGroupsMatchByDate();
    }

    handleMatchStatistics = (match_id) => {
        this.props.loadMatchReport(match_id);
        this.props.loadTeamsToMatchAssistanceAligment(match_id);
    }

    handleOnMatchDetailModal = () => {
        this.props.changeMatchDetailModalStateAction(true);
    }

    handleValidationFilterComponent = ( word ) => {
        //var regex = /.*\s$/; //valida espacio en blanco al final
        //var regex = /^\s.*$/; //valida espacio en blanco al inicio
        if(word?.length >= 3){
            this.setState({filter_component_activation: true});
            //if(!regex.test(word)){
                this.handleFilterSearchTournament({tournament_name: word});
            //}            
            this.setState({word_filter: word });
        }else{
            this.setState({filter_component_activation: false, is_subsection: false, word_filter: ''});
        }
    }

    handleTranslateFootballTypes () {
        let football_type_array = [];
        this.props.state.lineups.alignments.map((type) => {
            football_type_array.push(
                { "value": type.description, "content": this.props.t(`league:options.tournaments.pages.newTournament.tabs.tabBasicData.${type.description}`)
            });
            return 'none'
        });
        return football_type_array;
    }
    
    handleTeamMembersModal = (team) => {
        this.setState({modal_selected_team: team});
        this.props.loadTournamentMembersTeams(team.id);
        this.props.changeTeamMembersModalStateAction(true);
    }

    handleStaffInscriptionButton = () => {
        this.props.addUserToStaff(this.props.state.profile.loggedin?.user?.username, this.state.staff_profile, 'Pending', this.props.state.tournaments?.selected_tournament.id);
        this.props.changeStaffInvitationModalStateAction(false);
        this.props.changeStaffAcceptInvitationModalStateAction(true);
    }

    handleOnRejectStaffInvitation = () => {
        this.props.history.push('/');
        this.props.changeStaffInvitationModalStateAction(false);
    }

    handleStaffAcceptInscriptionButton = () => {
        this.props.changeStaffAcceptInvitationModalStateAction(false);
        let clean_path = this.props.history.location.pathname;
            this.props.history.replace({
                pathname: clean_path,
                search: '',
            });
    }
    
    handleOpenShareIframeLinkModal(tournament_id, iframe){
        this.props.changeShareIframeLinkStateAction(true);
        let codeIframe = "";
        if(iframe === 'results_table'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/team-positions-table/${tournament_id}/">
            </iframe>`
        }else if(iframe === 'matches'){
            codeIframe = `<iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="600"
                height="300"
                src="${web_url}/api/embed/tournament/tournament-matches/${tournament_id}/">
            </iframe>`
        }
        
        this.setState( {share_iframe_url: codeIframe } );
    };
    
    handleOnShareIFramePositionTableLink = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_iframe_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnLoadRefereeingPaymentUrl = async (payment_data, match_id, match_date_id) => {
        payment_data.redirect = `/tournament/inscriptions/${this.props.state.tournaments.selected_tournament?.id}?phase=${this.state.active_phase_id}?match_date=${match_date_id}?match=${match_id}?type=referee`;
        await this.props.loadRefeeringPaymentUrl(payment_data, match_id)
        if(this.props.state.leagueTournaments?.refereeing_payment_url){
            const newWindow = window.open(this.props.state.leagueTournaments.refereeing_payment_url.url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    handleOnShareMatchLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/match/${this.props.state.leagueMatches.match_report.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handlePageNewTeamModal = () =>{
        this.setState({new_page_team_inscription: true});
        this.props.changePageNewTeamModalStateAction(true);
    }

    handleOnShareTeamPageInscription(pagename){
        this.setState( {type_inscription_link_modal:"sharePageLink"} );
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/pages/${pagename}/teams/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnTab(tab) {
        this.setState( {tabSelected: tab});
        if (tab === 0 ){
            this.props.history.push('/tournament/hub');
        }
        if (tab === 1 ){
            this.props.history.push('/tournament/matches');
        }
    }

    handleOnResumePaymentStats = () => {
        let payment_data = {
            "tournament_id": this.props.state.tournaments?.selected_tournament.id,
            "redirect": `/tournament/hub/${this.props.state.tournaments?.selected_tournament.id}`,
            "mobile": false,
        }
        this.props.profileTournamentStatsPayment(this.props.state.profile.loggedin.user.username, payment_data);
        this.props.changeProfileStatisticsPaymentResumeModalStateAction( true );
    }

    handleOnStatsCheckout = async () => {
        if(this.props.state.profile?.profile_payment_checkout.url){
            const newWindow = window.open(this.props.state.profile?.profile_payment_checkout.url, '_self', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        this.props.changeProfileStatisticsPaymentResumeModalStateAction( false );
        //this.props.changeProfileStatisticsPaymentModalStateAction( false );
    }

    handleOnPhaseCalendar = async (tournament_phase_id) => {
        await this.props.loadPhaseCalendar(this.state.active_phase_id,tournament_phase_id);
    }

    /** CODIGO DE TORNAMENTVIEW */
    async loadCardIdsTeamInfo( teams_list ){
        await this.props.loadCardIdsData( this.props.state.leagueTournaments?.tournament?.id, teams_list );
        //this.prepareCardIdsPDFData();
        this.props.changeTournamentCardIdPdfGeneratorModalStateAction(true);
    }

    findQuestionResponses(answers){
        let newAnswers = '';
        //eslint-disable-next-line array-callback-return
        answers?.map(ans => {
            if(ans.is_checked){
                newAnswers += ans.form_template_answer.caption + ',';
            }    
        });
        newAnswers= newAnswers.slice(0, -1);
        return newAnswers;
    }
   
    prepareCardIdsPDFData(){
        let all_players_data = [];
        if(this.props.state.leagueTournaments?.card_ids_data?.results?.length > 0){
            //eslint-disable-next-line array-callback-return
            this.props.state.leagueTournaments?.card_ids_data?.results?.map(team => {
                //eslint-disable-next-line array-callback-return
                team?.player_forms.map(player => {
                    let player_data = [];
                    //eslint-disable-next-line array-callback-return
                    player?.questions?.map(ques => {
                        let card_id_question = this.props.state.leagueTournaments?.card_ids_data?.form_card.questions.find(q => q.form_question_template.caption === ques.caption )
                        if(card_id_question){
                            let new_question = undefined;
                            if((card_id_question.form_question_template.code === 'text' || card_id_question.form_question_template.code === 'email' || card_id_question.form_question_template.code === 'phone') && card_id_question.json_answer !== "{}"){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.text_answer,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'date'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.date_field,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'file'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    shape: card_id_question.json_answer.shape,
                                    width: card_id_question.json_answer.width,
                                    height: card_id_question.json_answer.height,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.file_document,
                                    data: ques.parent_data,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'boolean'){//TO DO
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.boolean_answer ? this.props.t('tournament:registerPlayer.viewForm.trueAnswer'): this.props.t('tournament:registerPlayer.viewForm.falseAnswer'),
                                }
                            }
                            if(card_id_question.form_question_template.code === 'single_choice' || card_id_question.form_question_template.code === 'multi_choice' || card_id_question.form_question_template.code === 'list'){//TO DO: , 
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: this.findQuestionResponses(ques.answers),
                                }
                            }
                            if(new_question){
                                player_data.push(new_question);
                            }
                        }
                    })
                    all_players_data.push(player_data)
                })
            })
        }
        this.setState({downloadCardIdData: all_players_data});
        return true;
    }

    async downloadPlayerCardId( tournament_team_player_id){
        await this.props.loadPlayerCardIdData( tournament_team_player_id );
        //this.prepareCardIdsPDFData();
        this.props.changeTournamentCardIdPdfGeneratorModalStateAction(true);
    }

    async downloadStaffCardId( team_profile_id, tournament_team_id){
        await this.props.loadStaffCardIdData( team_profile_id, tournament_team_id );
        //this.prepareCardIdsPDFData();
        this.props.changeTournamentCardIdPdfGeneratorModalStateAction(true);
    }
    /** END CODIGO DE TORNAMENTVIEW */
    // agregar miembros sin cuenta
    async handleOnSaveBulkButton (){
        this.setState({ uploadProgress:0 });
        this.props.changeAddingBulkMemberStateAction(false);
        await this.props.uploadBulkData( this.props.state.tournaments?.selected_tournament_team?.teamname, this.prepareBeforeSubmitBulkData(this.state.dataset));
        this.props.cleanBulkMembersAction({});
        await this.props.changeAddMembersWithoutAccountModalStateAction(false);
        this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team.id, 'Pending|Enrolled|Rejected|Late_Register'); 
    }

    handleOnCloseModal(){
        this.props.cleanBulkMembersAction({});
        this.props.changeAddMembersWithoutAccountModalStateAction(false);
    }

    uploadBulkMembersFile = async (membersFile) => {

        try{
            this.setState({ uploadProgress:0 });
            //let formData = new FormData();        
            //formData.append("file", membersFile);
            //await this.props.uploadBulkFile(this.props.state.teams.team.teamname, formData, this.onUploadProgress); 
            let newDataset = Object.assign([],this.props.state.teams.bulk_members.members);
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = new Uint8Array(e.target.result);
                let workbook = XLSX.read(data, { type: "array" });
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let sheet = XLSX.utils.sheet_to_json(worksheet);

                let dataset = await this.parseFileContent(sheet);

                newDataset = newDataset.concat(dataset);
                
                this.props.changeLoadingBulkFileStateAction(true); 
                this.setState({ 
                    dataset: newDataset,
                    fileLoaded: true,
                    fileName: membersFile.name,
                    operationInProgress: false,
                    uploadProgress: 100,
                });
                this.props.setBulkMembersAction({data:{members:newDataset, total: newDataset.length, error: null}});
            }.bind(this);
            reader.readAsArrayBuffer(membersFile);
            
        } catch (exception) {
            this.setState({
                fileLoaded: false,
                fileName: "",
                operationInProgress: false
            });
        }
        return true;
    }

    parseFileContent(sheet){
        const jDatos = [];

        for (let i = 0; i < sheet.length; i++) {
            const dato = sheet[i];
            const date = new Date((dato.BirthDate - (25567 + 1)) * 86400 * 1000);

            jDatos.push({
                ...dato,
                name:{ caption: dato.Name? dato.Name: null, error: false, error_caption: null},
                last_name:{ caption: dato.LastName? dato.LastName: null, error: false, error_caption: null},
                phone_country_code:{ caption: dato.PhoneCountryCode? dato.PhoneCountryCode: null, error: false, error_caption: null},
                mobile_number:{ caption: dato.MobileNumber? dato.MobileNumber: null, error: false, error_caption: null},
                email:{ caption: dato.Email? dato.Email: null, error: false, error_caption: null},
                shirtnumber:{ caption: dato.ShirtNumber? dato.ShirtNumber: null, error: false, error_caption: null},
                birthdate:{ caption: dato.BirthDate? moment(date).format('YYYY-MM-DD'): null, error: false, error_caption: null}
            });
        }
        return jDatos;
    }

    updateBulkData(row_data, row_index){

        let newDataset = Object.assign([],this.props.state.teams.bulk_members.members);
        
        let new_data = {};
        if(row_index >= 0){
            new_data = this.props.state.teams.bulk_members.members[row_index];
        }else{
            new_data = {
                name: { caption: null, error: false, error_caption: null },
                last_name: { caption: null, error: false, error_caption: null },
                email: { caption: null, error: false, error_caption: null },
                phone_country_code: { caption: null, error: false, error_caption: null },
                mobile_number: { caption: null, error: false, error_caption: null },
                shirtnumber: { caption: null, error: false, error_caption: null },
                birthdate: { caption: null, error: false, error_caption: null },
            }
            
        }
        this.props.changeAddingBulkMemberStateAction(true); //Define que se esta adicionando un miembro directamente a la carga masiva / o que se esta editando un registro

        new_data.name = row_data.name? { caption: row_data.name, error: false, error_caption: null} : { caption: row_data.name, error: new_data.name.error, error_caption: new_data.name.error_caption};
        new_data.last_name = row_data.last_name? { caption: row_data.last_name, error: false, error_caption: null} : { caption: row_data.last_name, error: new_data.last_name.error, error_caption: new_data.last_name.error_caption};
        new_data.email = row_data.email || (row_data.mobile_number && row_data.phone_country_code)? { caption: row_data.email, error: false, error_caption: null} : { caption: row_data.email, error: new_data.email.error, error_caption: new_data.email.error_caption};
        new_data.phone_country_code = (row_data.phone_country_code && row_data.mobile_number) || row_data.email? { caption: row_data.phone_country_code, error: false, error_caption: null} : { caption: row_data.phone_country_code, error: new_data.phone_country_code.error, error_caption: new_data.phone_country_code.error_caption} ;
        new_data.mobile_number = (row_data.mobile_number && row_data.phone_country_code) || row_data.email? { caption: row_data.mobile_number, error: false, error_caption: null} : { caption: row_data.mobile_number, error: new_data.mobile_number.error, error_caption: new_data.mobile_number.error_caption} ;
        new_data.shirtnumber = row_data.shirtnumber? { caption: row_data.shirtnumber, error: false, error_caption: null} : { caption: row_data.shirtnumber, error: new_data.shirtnumber.error, error_caption: new_data.shirtnumber.error_caption};
        new_data.birthdate = row_data.birthdate? { caption: moment(row_data.birthdate).format('YYYY-MM-DD'), error: false, error_caption: null} : { caption: row_data.birthdate, error: new_data.birthdate.error, error_caption: new_data.birthdate.error_caption};

        if(row_index >= 0){
            newDataset[row_index] = new_data;
        }else{
            newDataset.push(new_data);
        }

        this.setState({ dataset: newDataset });
        this.props.setBulkMembersAction({data:{members:newDataset, total: newDataset.length, error: null}});
    }

    handleOnDeleteMember(member_index){
        this.props.changeBulkDeleteMemberModalStateAction(true);
        this.setState({ deleted_member_index: member_index });
    }


    deleteBulkMember(){      
        let newMembers = Object.assign([],this.props.state.teams.bulk_members.members);
        newMembers.splice(this.state.deleted_member_index,1);
        this.setState({ dataset: newMembers, deleted_member_index: undefined });
        this.props.setBulkMembersAction({data:{members:newMembers, total: newMembers.length, error: null}});
        this.props.changeBulkDeleteMemberModalStateAction(false);
    }

    changeBulkPlayerStatusInfoModalState(state){
        this.setState({show_bulk_modal: state});
    }

    prepareBeforeSubmitBulkData(bulkData){
        const pData = [];
        for (let i = 0; i < bulkData.length; i++) {
            const new_dato = bulkData[i];            
            pData.push({
                name: new_dato.name.caption? new_dato.name.caption: null,
                last_name: new_dato.last_name.caption? new_dato.last_name.caption: null,
                phone_country_code: new_dato.phone_country_code.caption? new_dato.phone_country_code.caption: null,
                mobile_number: new_dato.mobile_number.caption? new_dato.mobile_number.caption: null,
                email: new_dato.email.caption? new_dato.email.caption: null,
                shirtnumber: new_dato.shirtnumber.caption? new_dato.shirtnumber.caption: null,
                birthdate: new_dato.birthdate.caption? new_dato.birthdate.caption: null
            });
        }

        return pData;
    }

    // agregar miembro con cuenta
    handleSearchMember = (searched_member) =>{
        this.props.searchMembersWithValidation( searched_member, this.props.state.teams.team.team_players);
    }

    async handleAddMember(player){
        this.props.changeLeaguesAddMemberToLeagueModalStateAction(false); 
        if(player.role.role === "player"){
            await this.props.addMemberWithRolToTeam( this.props.state.tournaments?.selected_tournament_team?.teamname, player );
            await this.props.loadTeamPlayers(this.props.state.tournaments?.selected_tournament_team.id, 'Pending|Enrolled|Rejected|Late_Register'); 
        }else {
            await this.props.addMemberWithRolToTeam( this.props.state.tournaments?.selected_tournament_team?.teamname, player );
            await this.props.loadTeamManagerAndStaff(this.props.state.tournaments?.selected_tournament_team?.teamname,this.props.state.tournaments?.selected_tournament.id);
        }
        
    }

    handleOnShareAddMembersWithAccount(){
        this.props.changeLeaguesAddMemberToLeagueModalStateAction(true);   
    }
    // Roles the members and traslate
    prepareMembersRoles = ( roles_members ) =>{

        let roles_members_traslate = [
            { value: 'player', content: this.props.t("team:team.tabs.members.player") },
            { value: 'coach', content: this.props.t("team:team.tabs.members.coach") },
            { value: 'staff', content: this.props.t("team:team.tabs.members.staff") }
        ]

        let new_roles_members_data = roles_members.map( function(rol){ 
            let rol_traslate = roles_members_traslate.find(rol_traslate => rol_traslate.value == rol[1]);
            rol.value = rol[1];
            rol.content = rol_traslate.content;
            return rol;
        });
        this.setState({roles_members: new_roles_members_data});
    }

    prepareTeamMembers = ( team_members ) =>{
        let roles_members_traslate = [
            { value: 'player', content: this.props.t("team:team.tabs.members.player") },
            { value: 'coach', content: this.props.t("team:team.tabs.members.coach") },
            { value: 'staff', content: this.props.t("team:team.tabs.members.staff") }
        ];
        let positions_members_traslate = [
            { value: 'goalkeeper', content: this.props.t("team:team.tabs.members.goalkeeper") },
            { value: 'defense', content: this.props.t("team:team.tabs.members.defense") },
            { value: 'midfield', content: this.props.t("team:team.tabs.members.midfield") },
            { value: 'forward', content: this.props.t("team:team.tabs.members.forward") }
        ];

        let new_members_data = Object.assign([]);
        /* eslint-disable */
        team_members?.map(member =>{
            member.fullname = member.first_name + ' ' + member.last_name;
            member.player_number = member.role?.number;
            member.position = member.role?.team_profile_position? positions_members_traslate.filter(pos => pos.value == member.role.team_profile_position)[0].content : null;
            member.team_role = member.role?.team_role? roles_members_traslate.filter(role => role.value == member.role.team_role)[0].content : null;
            member.player_state = member.state;
            
            new_members_data.push(member);
        });
        /* eslint-enable */ 
        this.setState({prepared_members: new_members_data});
    }

    // Positions the members and traslate
    prepareMembersPositions = ( positions_members ) =>{

        let positions_members_traslate = [
            { value: 'goalkeeper', content: this.props.t("team:team.tabs.members.goalkeeper") },
            { value: 'defense', content: this.props.t("team:team.tabs.members.defense") },
            { value: 'midfield', content: this.props.t("team:team.tabs.members.midfield") },
            { value: 'forward', content: this.props.t("team:team.tabs.members.forward") }
        ];
        let new_positions_members_data = positions_members.map( function(position){ 
            let position_traslate = positions_members_traslate.find(position_traslate => position_traslate.value == position[1]);
            position.value = position[1];
            position.content = position_traslate.content;
            return position;
        });
        this.setState({positions_members: new_positions_members_data});
    }

    // Answer multiple forms
    openMultiplePlayerForms(){
        this.setState({form_profile_type: 'player'});
        this.props.changeAnswerMultipleFormsModalStateAction( true );
    } 

    realizeMultiplePlayerForms(selected_player, type_redirect){
        // console.log('form' ,selected_player, '');
        this.setState({selected_admin_player: selected_player, form_tournament_player_id: selected_player.tournament_player_id, form_profile_type: 'player', type_redirect: type_redirect });
    } 

    async sendForm(dataForm) {
        await this.processDataPlayerInscription(dataForm,this.state.type_redirect);
    }
    
    handleOpenBaloaLanding = () => {
        const urlToOpen = 'https://info.baloa.com/';
        window.open(urlToOpen, '_blank');
    }

    handleSearchHubTournamentTeamPlayers( player, state ) {
        if( state === 'all-states') {
            state = 'Pending|Enrolled|Rejected|Late_Register'
        }

        this.props.searchTournamentTeamPlayers(this.props.state.tournaments?.selected_tournament_team.id ,player, state);
    }

    render() {

        return (
            <div className='tournament-hub'>
                <div className="tournament-hub__left-container">
                </div>
                <div className="tournament-hub__center-container">
                    {this.props.state.tournaments.show_hub && !this.state.load_detail &&
                        <React.Fragment>
                            { !isMobile &&
                                <div className="tournament-hub__center-container-create_tournament">
                                    <div className='baloa-headline-1'>{this.props.t('viewTournament.hubTournamentsTitle')}</div>
                                    <div className='baloa-subtitle-1'>{this.props.t('menu.labelCreateTournament')}</div>
                                    <div className='tournament-hub__banner-container' >
                                        <div class="tournament-hub__search-tournament">
                                            <InputSearchWithButtonComponent
                                                name={'search-tournament'}
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ this.handleValidationFilterComponent(search) } }
                                                placeholder={this.props.t('viewTournament.details.searchTournament')} 
                                            />
                                        </div>
                                        <div className='tournament-hub__header-actions'>
                                            <PrimaryButtonComponent
                                                input_type='button'
                                                className=''
                                                onClick={()=> {   
                                                    if(isMobile){
                                                        this.props.changeResponsiveHubModalStateAction(true)
                                                    }else{
                                                        this.props.changePageNewTeamModalStateAction(true)
                                                    }
                                                }}
                                            >
                                                <span>{this.props.t('league:options.tournaments.pages.newTournament.labelTitle')}</span>
                                            </PrimaryButtonComponent>
                                            <SecondaryButtonComponent 
                                                type="button" 
                                                onClick={ () => this.handleOpenBaloaLanding() }
                                                className="tournament-hub__baloa-landing"
                                            >
                                                {this.props.t('landingBaloaBtn')}<span className="hubicon-open_in_new" /> 
                                            </SecondaryButtonComponent>
                                        </div>
                                    </div>                                
                                </div>
                            }
                            { isMobile &&
                                <React.Fragment>
                                <div className="tournament-hub__center-container-create_tournament">
                                    <div className='baloa-headline-1'>{this.props.t('viewTournament.hubTournamentsTitle')}</div>
                                    <div className='baloa-subtitle-1'>{this.props.t('menu.labelCreateTournament')}</div>
                                   
                                    <div className='tournament-hub__mobile__filter-search'>
                                        <div className='tournament-hub__mobile__filter-search__btns-action'>
                                            <PrimaryButtonComponent
                                                input_type='button'
                                                className='small tournament-hub__search-tournament__button'
                                                onClick={()=> {   
                                                    if(isMobile){
                                                        this.props.changeResponsiveHubModalStateAction(true)
                                                    }else{
                                                        this.props.changePageNewTeamModalStateAction(true)
                                                    }
                                                }}
                                            >
                                                <span>{this.props.t('league:options.tournaments.pages.newTournament.labelTitle')}</span>
                                            </PrimaryButtonComponent>
                                            <SecondaryButtonComponent 
                                                type="button" 
                                                onClick={ () => this.handleOpenBaloaLanding() }
                                                className="tournament-hub__baloa-landing"
                                            >
                                                {this.props.t('landingBaloaBtn')}<span className="hubicon-open_in_new" /> 
                                            </SecondaryButtonComponent>
                                        </div>
                                        <div class="tournament-hub__search-tournament">                                            
                                            <InputSearchWithButtonComponent
                                                name={'search-tournament'}
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ this.handleValidationFilterComponent(search) } }
                                                placeholder={this.props.t('viewTournament.details.searchTournament')} 
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* Hub tournament menu */}                                
                                <Tabs
                                    variant="fullWidth"
                                    className="tournament-hub__tabs"
                                    value={this.state.tabSelected}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(ev, new_value) => {
                                        this.handleOnTab(new_value);
                                    }}
                                    aria-label="Selection between "
                                >
                                    {/* Tab Inicio */}
                                    <Tab label={this.props.t('tabs.start')} />

                                    {/* Tab Partidos */}
                                    <Tab label={this.props.t('tabs.matches')} />                                                                                        
                                </Tabs>                               
                                </React.Fragment>
                            }
                            {this.state.filter_component_activation && 
                                <TournamentHubFilterComponent
                                    tournament_tag={this.state.tournament_tag}
                                    word_to_filter={this.state.filter_component_activation}
                                    handleTranslateFootballTypes={this.handleTranslateFootballTypes()}
                                    handleFilterSearchTournament={(filterparams)=>this.handleFilterSearchTournament(filterparams)}
                                    handleSearchWorldCities = {( searchedcity ) => this.props.searchWorldCities(searchedcity )}
                                    world_cities={this.props.state.leagueTournaments.world_cities}
                                    tournament_subsection={this.state.tournament_subsection}
                                />
                            }
                        </React.Fragment>
                    }

                    {/**Se agrega skeleton en carga de secciones hub de torneos */}
                    {//this.props.state.tournaments.loading_hub_tournaments_sections && !isMobile && 
                    this.props.state.tournaments.loading_tournaments && !isMobile && 
                        <div className='skeleton-container'>
                            {[...Array(Number(6))].map((e, i) => 
                                <div className='skeleton-container__element'>
                                    <Skeleton animation="wave" variant='rect' component={'div'} width='100%' height={'140px'} sx={{borderRadius: 'inherit',}}/>
                                    <div className='skeleton-container__element__image-line'>
                                        <Skeleton animation="wave" variant='circular' component={'div'} width='35px' height={'35px'} />
                                        <Skeleton animation="wave" component={'div'} width='70%' />
                                    </div>
                                    <div className='skeleton-container__element__text-lines'>
                                        <Skeleton animation="wave" component={'div'} width='70%' />
                                        <Skeleton animation="wave" component={'div'} width='50%' />
                                        <Skeleton animation="wave" component={'div'} width='30%' />
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {//this.props.state.tournaments.loading_hub_tournaments_sections && isMobile && 
                    this.props.state.tournaments.loading_tournaments && isMobile && 
                        <div className='skeleton-container'>
                            {[...Array(Number(5))].map((e, i) => 
                                <div className='skeleton-container__element'>
                                    <Skeleton animation="wave" variant='rect' component={'div'} width='120px' height={'80px'} sx={{borderRadius: '4px',}}/>
                                    <div className='skeleton-container__element__group'>
                                        <div className='skeleton-container__element__image-line'>
                                            <Skeleton animation="wave" variant='circular' component={'div'} width='28px' height={'28px'} />
                                            <Skeleton animation="wave" component={'div'} width='70%' height={'12px'} />
                                        </div>
                                        <div className='skeleton-container__element__text-lines'>
                                            <Skeleton animation="wave" component={'div'} width='70%' height={'12px'} />
                                            <Skeleton animation="wave" component={'div'} width='50%' height={'12px'} />
                                            <Skeleton animation="wave" component={'div'} width='30%' height={'12px'} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                    {/**Se agrega skeleton en carga de secciones hub de torneos */}
                    {//!this.props.state.tournaments.loading_hub_tournaments_sections &&
                    !this.props.state.tournaments.loading_tournaments &&
                        <React.Fragment>
                        {/* Codigo exclusivo para matches mobile */}
                        {this.state.show_matches && isMobile &&
                            <React.Fragment>
                                <div className="tournament-hub__center-container-create_tournament">
                                    <div className='baloa-headline-1'>{this.props.t('viewTournament.hubTournamentsTitle')}</div>
                                    <div className='baloa-subtitle-1'>{this.props.t('menu.labelCreateTournament')}</div>
                                    
                                    <div className='tournament-hub__mobile__filter-search'>
                                        <div class="tournament-hub__search-tournament">                                        
                                            <InputSearchWithButtonComponent
                                                name={'search-tournament'}
                                                cleanValue={false}
                                                handleOnSearchClick={ (search)=>{ this.handleValidationFilterComponent(search) } }
                                                placeholder={this.props.t('viewTournament.details.searchTournament')} 
                                            />
                                        </div>
                                        <PrimaryButtonComponent
                                            input_type='button'
                                            className='small tournament-hub__search-tournament__button'
                                            onClick={()=> {   
                                                if(isMobile){
                                                    this.props.changeResponsiveHubModalStateAction(true)
                                                }else{
                                                    this.props.changePageNewTeamModalStateAction(true)
                                                }
                                            }}
                                        >
                                            <span>{this.props.t('league:options.tournaments.pages.newTournament.labelTitle')}</span>
                                        </PrimaryButtonComponent>
                                    </div>
                                    
                                </div>
                                {/* Hub tournament menu */}                                
                                <Tabs
                                    variant="fullWidth"
                                    className="tournament-hub__tabs"
                                    value={this.state.tabSelected}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(ev, new_value) => {
                                        this.handleOnTab(new_value);
                                    }}
                                    aria-label="Selection between "
                                >
                                    {/* Tab Inicio */}
                                    <Tab label={this.props.t('tabs.start')} />

                                    {/* Tab Partidos */}
                                    <Tab label={this.props.t('tabs.matches')} />                                                                                        
                                </Tabs>                               
                            </React.Fragment>
                        }

                        {/** Tournament matches widget */}
                        {this.props.state.tournaments.show_hub && !this.state.load_detail && !this.state.filter_component_activation && (this.props.state.tournaments?.hub_tournament_next_matches.length > 0) &&
                            <HubTournamentMatchesWidgetComponent
                                date_matches={this.props.state.tournaments?.hub_tournament_next_matches}
                                handleOnSubsectionViewMore={ (subsection) => {this.handleOnSubsectionViewMore(subsection)} }
                            />
                        }

                        {this.props.state.tournaments.show_hub && !this.state.is_subsection && !this.state.load_detail &&
                            <React.Fragment>
                                { this.props.state.tournaments?.hub_tournaments_sections?.map((section, index) =>                                
                                    <React.Fragment key={index}>
                                        {section.section === "my_tournaments" &&
                                            <React.Fragment>
                                                { section.section_data?.map((subsection, i) => (
                                                    subsection.tournaments.length > 0 &&
                                                        <HubTournamentSectionCardComponent
                                                            key={i} 
                                                            section_data={subsection}
                                                            handleOnViewTournament={(tournament, section_hub) => {this.handleHubTournamentSection(tournament, section_hub)}}
                                                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }   
                                                            handleOnRejectBtn={ (tournament) => this.handleOnRejectTeamInvitation(tournament.tournament_team_id, 'Rejected', 'teams')}
                                                            handleIgnoreBtn={(value) =>{this.handleIgnoreBtn(value)}}
                                                            handleOnSubsectionViewMore={ (subsection) => {this.handleOnSubsectionViewMore(subsection)} }
                                                            handleOnLoadMoreTournaments={ (subsection)=>{this.handleOnLoadMoreTournaments(subsection) } }
                                                        />
                                                    ))}
                                            </React.Fragment>
                                        }
                                        {section.section === "recommended_tournaments" &&
                                            <HubTournamentSectionComponent 
                                                section_title={section.section}
                                                section_data={section.section_data}
                                                handleOnViewTournament={(tournament, section_hub) => {this.handleHubTournamentSection(tournament, section_hub)}}
                                                handleOnSubsectionViewMore={ (subsection) => {this.handleFilterSearchTournament({state: subsection})} }
                                                handleOnLoadMoreTournaments={ (subsection)=>{this.handleOnLoadMoreTournaments(subsection) } }
                                                handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }
                                            />
                                        }
                                    </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        }
                        </React.Fragment>
                    }
                    { this.state.is_subsection && !this.state.load_detail && this.props.state.tournaments?.hub_tournament_subsection.length > 0 &&
                        <HubTournamentSectionCardComponent
                            section_data={this.props.state.tournaments?.hub_tournament_subsection}
                            handleOnViewTournament={(tournament, section_hub) => {this.handleHubTournamentSection(tournament, section_hub)}}
                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }   
                            handleOnRejectBtn={ (tournament) => this.handleOnRejectTeamInvitation(tournament.tournament_team_id, 'Rejected', 'teams')}
                            handleIgnoreBtn={(value) =>{this.handleIgnoreBtn(value)}}                                                    
                            handleOnSubsectionViewMore={ (subsection) => {this.handleOnSubsectionViewMore(subsection)} }
                            is_subsection={this.state.is_subsection}
                            is_filterSection={this.state.filter_component_activation}
                            tournament_subsection={this.state.tournament_subsection}
                            handleBackTournamentViewMore={() => {this.handleBackTournamentViewMore()}}
                            handleOnLoadMoreTournaments={ ()=>{this.handleOnLoadMoreTournaments() } }
                            is_path={this.state.is_path}
                        />
                    }

                    { this.state.is_subsection && !this.state.load_detail && !this.props.state.tournaments.loading_tournaments &&
                        this.props.state.tournaments?.hub_tournament_subsection.length === 0 && 
                        this.state.tournament_subsection !== "tournament_participate" && 
                        this.state.tournament_subsection !== "tournament_invitation" &&
                        <div className='tournament-hub__center-container__empty-section invitations'>
                            <img src={search_off} alt="search empty" />
                            <div className='baloa-subtitle-2'>{this.props.t('emptyMessages.emptyHubTournamentsSearch')}</div>                            
                            <SecondaryButtonComponent 
                                type="button" 
                                onClick={ () => this.handleOpenBaloaLanding() }
                                className="tournament-hub__baloa-landing"
                            >
                                {this.props.t('landingBaloaEmpty')}<span className="hubicon-open_in_new" /> 
                            </SecondaryButtonComponent>
                        </div>
                    } 

                    { !this.props.state.tournaments.loading_tournaments && this.props.state.tournaments?.hub_tournament_subsection.length === 0 && this.state.tournament_subsection === "tournament_participate" &&
                        <div className='tournament-hub__center-container__empty-section inscriptions'>
                           <img src={inscriptions_empty} alt="inscriptions empty" />
                            <div className='baloa-subtitle-2'>{this.props.t('emptyMessages.emptyInscriptionTournaments')}</div>
                            <PrimaryButtonComponent
                                input_type='button'
                                className='tournament-grid__empty__button'
                                onClick={ () => {this.props.history.push('/tournament/hub')} }
                            >
                                <span className='hubicon-explore' />
                                <span>{this.props.t('emptyMessages.emptyInscriptionTournamentsButton')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    }
                    { !this.props.state.tournaments.loading_tournaments && this.props.state.tournaments?.hub_tournament_subsection.length === 0 && this.state.tournament_subsection === "tournament_invitation" &&
                        <div className='tournament-hub__center-container__empty-section invitations'>
                            <img src={invitations_empty} alt="invitations empty" />
                            <div className='baloa-subtitle-2'>{this.props.t('emptyMessages.emptyInvitationsTournaments')}</div>
                            <SecondaryButtonComponent 
                                type="button" 
                                onClick={ () => this.handleOpenBaloaLanding() }
                                className="tournament-hub__baloa-landing"
                            >
                                {this.props.t('home:quickAccess.landingBaloa')}<span className="hubicon-open_in_new" /> 
                            </SecondaryButtonComponent>
                        </div>
                    }
                    {this.props.state.tournaments.show_my_tournaments &&
                        <React.Fragment>
                            <div className="tournament-hub__center-container-title baloa-headline-2">{this.props.t('viewTournament.tournamentsAdmin.title')}</div>
                            {/* <button onClick={() => {
                                this.changeDialogModalStateAction(true);
                                this.handleChangeModalType(
                                    {
                                        typeModal: 'linkHub-createPage',
                                        titleModal: this.props.t('modalsInformation.modalProfileWithoutPage.subtitleModal'),
                                        subTitleModal: 'Si cambias de opinión, tendras que ser invitado nuevamente.',
                                        principalButtonLabel: 'Aceptar',
                                        principalButtonActionLink:'/',
                                        secondaryButton: true,
                                        secondaryButtonLabel: 'Cancelar',
                                    }); // InfoHub - waringHub - linkHub+keyWord
                            }}>Ejem Modal</button> */}
                            <LeagueTournamentComponent // LeagueTournamentGeneralListComponent
                                tournament_tag={this.state.tournament_tag}
                                tournamentHub={true}
                                handleBackButton={() => this.handleCleanFilter()}
                                onResetError={(data) => { this.props.changeTournamentBasicDataError(data) }}
                                errors={this.props.state.leagueTournaments.errors.list_tournament_filters}
                                active_tournaments={this.props.state.tournaments.all_admin_tournaments?.filter(tournament => tournament.state !== "Finalized")}
                                completed_tournaments={this.props.state.tournaments.all_admin_tournaments?.filter(tournament => tournament.state === "Finalized")}
                                handleFilterTournament={(value) => { this.handleOnFilterTournamentAdmin(value) }}
                                showFilter={true} 
                            />
                        </React.Fragment>
                    }

                    { this.props.state.tournaments.is_share_link && !isMobile && <div className='tournament-hub__loader'><img src={loading_baloa} alt="loader" /></div> }
                        
                    { this.props.state.tournaments.is_share_link && isMobile && <div className='tournament-hub__loader-mobile'><img src={loading_ball} alt="loader" /></div> }
                    
                    {this.state.load_detail && !this.props.state.tournaments.show_tournament_detail && !isMobile && <div className='tournament-hub__loader'><img src={loading_baloa} alt="loader" /></div> }
                    {this.state.load_detail && !this.props.state.tournaments.show_tournament_detail && isMobile && <div className='tournament-hub__loader-mobile'><img src={loading_ball} alt="loader" /></div> }

                    {this.props.state.tournaments.show_tournament_detail &&
                        <TournamentDetailComponent
                            tournament={this.props.state.tournaments?.selected_tournament}
                            selected_team={this.props.state.tournaments?.selected_tournament_team}
                            my_teams={this.cleanMyTeams(this.props.state.tournaments?.selected_tournament_my_teams)}
                            tournament_teams={this.props.state.tournaments?.tournament_teams}
                            handleBackButton={() => { this.handleDetailBackButton() }}
                            team={this.state.selected_tournament_team}
                            teamname={this.state.selected_tournament_teamname}
                            team_logo={this.state.selected_tournament_team_logo}
                            handleOnInscriptionTeam = { ()=>{this.handleTeamInscription( this.state.selected_tournament_team_id )} }
                            handleOnInscriptionPlayer = { ()=>{this.handlePlayerInscription( this.state.selected_tournament_team_id )} }                            
                            // team_state={this.state.selected_tournament_team_state}
                            team_state={this.props.state.tournaments?.selected_tournament_team?.team_state}
                            start_flow={this.state.start_flow}
                            team_players={this.props.state.tournaments.team_players}
                            enrolled_players={ this.props.state.tournaments.enrolled_players }
                            max_participant_players={ this.props.state.tournaments.total_team_players }
                            next_team_players={this.props.state.tournaments.next_team_players}
                            handleOnNextTeamPlayers={()=>{ this.props.loadTeamPlayers(null,null,this.props.state.tournaments.next_team_players)}}
                            tournament_registration_type={this.state.selected_tournament_registration_type}
                            is_inscription={((this.state.is_inscription || (this.props.state.tournaments?.selected_tournament?.request_is_admin || this.props.state.tournaments?.selected_tournament_team?.request_is_member || this.props.state.tournaments?.selected_tournament?.participate)) && this.props.state.tournaments?.selected_tournament?.state !== "Finalized")} //Enviamos is_inscription en true para que muestre la pestaña de inscripcion
                            profile_type={this.state.profile_type}
                            is_tournament_admin={this.props.state.tournaments?.selected_tournament?.is_tournament_admin}
                            showCopyPostMessage={(copymessage)=>{this.props.showCopyPostMessage(copymessage)}}
                            handleOnShareTournamentLinkClick ={ (tournament)=>{this.handleOnShareTournamentLinkClick(tournament) } }
                            handleRealizePlayerFormButton={(selected_player, type_redirect)=>{this.realizePlayerForm(selected_player, type_redirect)}}
                            handleRealizePlayerPaymentButton={(selected_player, type_redirect)=>{this.realizePlayerPayment(selected_player, type_redirect)}}
                            handleRealizeTeamFormButton={(type_redirect)=>{this.realizeTeamForm(type_redirect)}}
                            handleRealizeTeamPaymentButton={(type_redirect)=>{this.realizeTeamPayment(type_redirect)}}
                            handleOnShareInscriptionTournamentLinkClick ={ (pagename)=>{this.handleOnShareTeamPageInscription(pagename) }}
                            handleOnShareInvitedPlayerTournamentLinkClick={(tournament_team_id)=>{
                                this.handleOnShareInscriptionTournament(tournament_team_id) 
                                this.setState( {type_inscription_link_modal:"shareTournamentLink"} )
                            }}
                            changeSelectedTournamentTeam={ (teamname)=>{ this.changeSelectedTournamentTeam(teamname) } }
                            //handleRejectButtonAction={()=>{this.props.changeRejectPlayerInscriptionModalStateAction(true)}}
                            handleOnFollowTournament={(tournamentId)=>this.handleOnFollowTournament(tournamentId)}
                            handleOnUnfollowTournament ={(tournamentId)=> this.handleOnUnfollowTournament(tournamentId)}
                            tournament_phases={this.props.state.leagueTournaments.tournament?.tournament_phases}
                            handleOnPhaseClick={ (phase) => { this.handleOnPhaseClick(phase) } }
                            phase_dates={this.props.state.leagueTournaments.tournament_results.tournament_dates}
                            active_phase_id={this.state.active_phase_id}
                            
                            handleOnPhaseDate={ (date) => this.handleOnPhaseDate(date)}
                            phase_matches_by_date={this.props.state.leagueTournaments.tournament_results?.selected_date_matches}
                            groups_matches_by_date={this.state.groups_matches_by_date}
                            handleMatchStatistics={(match_id) => this.handleMatchStatistics(match_id)}
                            match_report={this.props.state.leagueMatches.match_report}
                            handleOnMatchDetailModal = { (match) => this.handleOnMatchDetailModal(match) }

                            tournament_active_phase={this.state.tournament_active_phase}
                            tournament_results_table={this.props.state.leagueTournaments.tournament.phase_results_table}
                            is_best_third={this.state.is_best_third}
                            league_selected_tournament={this.props.state.leagueTournaments?.tournament}
                            handleTeamMembersModal={(team)=>this.handleTeamMembersModal(team)}
                            handleOpenShareIframeLinkModal={(tournament_id, iframe)=>this.handleOpenShareIframeLinkModal(tournament_id, iframe)}
                        
                            errors={this.props.state.leagueTournaments?.errors.edit_match}
                            onResetError={(data) => {this.props.changeNewMatchError(data)}}               
                            tournament_staff={this.props.state.leagueTournaments.tournament?.tournament_staff}
                            handleOnLoadRefereeingPaymentUrl={ (payment_data, match_id, match_date_id) => { this.handleOnLoadRefereeingPaymentUrl(payment_data, match_id, match_date_id) }}
                            handleUpdateRefereesMatch={(match_id, referees_match_data, match_date_id) => { this.props.assignRefereesMatch(match_id, referees_match_data, match_date_id) }}
                            handleOnAcceptCrewPayment={ (match_id) => {this.props.makeRefereePayment(match_id)}}
                            referees_payment_response={this.referees_payment_response}
                            handleOnShareMatchLinkClick={ ()=>{ this.handleOnShareMatchLinkClick() } }
                            teams_match_assistance={this.props.state.leagueTournaments.tournament.teams_match_assistance}
                            updatePlayersAssistanceAligment={(playerArray)=> {
                                this.props.updateAdminTeamsAssistanceAligment(this.props.state.leagueTournaments.tournament.teams_match_assistance?.match_id, playerArray)
                            }}
                            loadAdminPlayerAttended={(matchId)=>this.props.loadAdminTeaminMatchReportAction(matchId)}
                            loadAllPlayersAttended={(matchId)=> this.props.loadTeamsToMatchAssistanceAligment(matchId)}
                            handleOnResumePaymentStats={()=> { this.handleOnResumePaymentStats() }}
                            teams_manager_and_staff = {this.props.state.tournaments.team_manager_and_staff}
                            
                            next_team_staff={this.props.state.tournaments.next_team_staff}
                            handleOnNextTeamStaff={()=>{ this.props.loadTeamManagerAndStaff(null,null,this.props.state.tournaments.next_team_staff)}}

                            handleOnShareAddMembersWithoutAccountClick = { () => {this.handleOnShareAddMembersWithoutAccount()}}
                            handleOnShareAddMembersWithAccountClick = { () => {this.handleOnShareAddMembersWithAccount()}} 
                            openMultiplePlayerForms= {(selected_player, type_redirect) => {this.openMultiplePlayerForms(selected_player, type_redirect)}}

                            handleOnPhaseCalendar={ (tournament_phase_id) => this.handleOnPhaseCalendar(tournament_phase_id)}
                            days_calendar_view={this.props.state.tournaments.days_calendar_view}
                            loading_team_players={this.props.state.tournaments.loading_team_players}
                            loading_team_staff={this.props.state.tournaments.loading_team_staff}

                            handleSearchHubTournamentTeamPlayers={ ( player, state )=>{ this.handleSearchHubTournamentTeamPlayers( player, state ) } }
                        />
                    }

                    {this.state.show_matches &&
                        <HubTournamentMatchesComponent
                            tournaments_dates = {this.props.state.tournaments?.hub_tournament_matches_dates}
                            matches = {this.props.state.tournaments?.hub_tournament_matches}
                            loadDateMatches = { ( date )=>{ this.props.loadHubTournamentMatchesByDate( date ) } }
                        />
                    }
                </div>
                {/** Modals */}
                <ModalDecisionDialogComponent
                    isOpen={this.state.openModal}
                    onClose={() => { this.changeDialogModalStateAction(false) }}
                    modalTitle={this.state.titleModal}
                    modalContent={this.state.subTitleModal}
                    modalType={this.state.typeModal}
                    onPrincipalButtonClick={(type_action) => { this.handleClickPrimaryButton(type_action) }}
                    principalButtonLabel={this.state.principalButtonLabel}
                    principalButtonActionLink={this.state.principalButtonActionLink}
                    secondaryButton={this.state.secondaryButton}
                    onSecondaryButtonClick={() => { this.changeDialogModalStateAction(false) }}
                    secondaryButtonLabel={this.state.secondaryButtonLabel}/>

                <InscriptionsModalComponent
                    isOpen={this.props.state.tournaments?.modals.inscription_modal_is_open}
                    onClose= { ()=>{
                        this.props.cleanTeamDataAction();
                        this.props.cleanProfileAdminTeamsAction()
                        this.props.changeInscriptionsModalStateAction(false);
                    } }
                    team={this.state.selected_tournament_team}
                    teamname={this.state.selected_tournament_teamname}
                    team_logo={this.state.selected_tournament_team_logo}                    
                    handleOnInscription={()=>{this.finalizeInscription()}}
                    start_flow= {this.state.start_flow}
                    inscrption_steps={this.state.inscrption_steps}
                    profile_teams={this.props.state.tournaments.profile_admin_teams}
                    setSelectedTeam={(team)=>{this.setSelectedTeam(team)}}
                    tournament_registration_price_per_team={this.props.state.tournaments?.selected_tournament?.registration_price_per_team}
                    tournament_registration_price_per_player={this.props.state.tournaments?.selected_tournament?.registration_price_per_player}
                    setPlayersQuantity={(quantity)=>{this.setPlayersQuantity(quantity)}}
                    handleOnPayment={()=>{this.handleOnPayment()}}
                    currency={this.props.state.tournaments?.selected_tournament?.currency_payment}

                    //TournamentPlayerInscription
                    handleSearchWorldCities = {( searchedcity ) => this.props.searchCityByCountry( this.props.state.leagueTournaments.countryId, searchedcity )}
                    handleSearchNationality={(new_nationality_search) => { this.props.getCountries( new_nationality_search) }}                            
                    cities = {this.props.state.leagueTournaments?.world_cities}
                    countries =  { this.props.state.leagueTournaments?.countries}
                    tournament_tag={this.state.tournament_tag}
                    errors={this.props.state.leagueTournaments.errors.player_inscription_form}
                    onNewData = { ( data ) => { this.processDataPlayerInscription(data) }}
                    onResetError={(data) => {this.props.changeNewNamesError(data)}}
                    tournament_team_id={this.props.state.tournaments.payment_tournament_team_id? this.props.state.tournaments.payment_tournament_team_id : this.props.state.tournaments?.selected_tournament_team?.id}
                    tournament_id={this.props.state.tournaments?.selected_tournament?.id}
                    profile_type={this.state.profile_type}
                    handleOnCodeButton={(player_code)=>{this.handleOnCodeButton(player_code)}} 
                    payment_player_code={this.props.state.tournaments?.payment_player_code}
                    profile_id={this.props.state.user.me.profile_id}
                    tournament_player_id={this.state.selected_tournament_player_id}
                    tournament_currency_payment={this.props.state.tournaments?.selected_tournament?.currency_payment}
                    submitWompiForm={this.state.submitWompiForm}
                    resetPaymentButton={()=>{this.setState({submitWompiForm: true})}}
                    inscription_form_template={this.props.state.tournaments?.inscription_form_template}
                    tournament_registration_type={this.props.state.tournaments.selected_tournament?.registration_type}
                    handlePageNewTeamModal={()=>this.handlePageNewTeamModal()}
                    load_created_teams={this.state.load_created_teams}
                    team_name_created={this.props.state.teams.team}                    
                    handle_load_created_teams={()=> this.setState({load_created_teams: false})}
                    profile_phone={this.props.state.user.me.phone}

                    handleSearchHubAdminTeam={(teamname)=>{ this.props.searchProfileAdminTeams(`tournament_id=${this.props.state.tournaments.selected_tournament?.id}&team_category=${this.props.state.tournaments.selected_tournament?.category}&team_gender=${this.props.state.tournaments.selected_tournament?.gender}&team_name=${teamname}`)}}
                />
                
                <PageTeamCreateModalComponent //TODO: CHECK AFTER CHANGE HUB APIS
                    onClick={() => { }}
                    isOpen={this.props.state.page.modals?.new_page_team_is_open}
                    onClose={() => {
                            this.props.changePageNewTeamModalStateAction(false);
                        }                        
                    }
                    team={this.props.state.teams.team}
                    page_data={this.state.page_data}
                    OnlySelectedPage={this.state.new_page_team_inscription ? false : true}
                    testpage={this.props.state.page?.page.pagename}
                    pages={this.props.state.profile.loggedin?.pages?.filter(p => p.page_type != 'group')}
                    errors={this.props.state.page.errors.creation}
                    onNewData={(new_page_information) => this.onNewPageInformation(new_page_information)}
                    onResetError={(new_errors) => this.props.loadPageErrorsAction({ ...this.props.state.page.errors.creation, ...new_errors }, 'creation')}
                    new_page_team_inscription={this.state.new_page_team_inscription}                
                    profiles={this.props.state.teams.searched_members}
                    gender={this.state.new_team_gender}
                    category={this.state.tournament_tag}
                    errors_newTeam={this.props.state.teams.errors.new_team}
                    handleCreateNewTeam={(team_data, teamimage, imgb64) => { this.handleCreateNewTeam(team_data, teamimage, imgb64) }}
                    tournament={this.props.state.tournaments?.selected_tournament}
                    selected_tournament={this.props.state.tournaments?.selected_tournament_teams?.length !== 0 ? true : false} //TODO: CHECK AFTER CHANGE HUB APIS
                />
                <HubFormsModalComponent
                    isOpen={this.props.state.tournaments?.modals.forms_modal_is_open}
                    onClose= { ()=>{
                        this.props.changeFormsModalStateAction(false); 
                        this.setState({selected_admin_player: undefined}) 
                    } }
                    profile_type={this.state.form_profile_type}
                    onNewData = { ( data ) => {
                        this.processDataPlayerInscription(data,this.state.type_redirect);
                        // this.props.changeFormsModalStateAction(false); //FIXME: commet closed modal
                    }}
                    tournament_team_id={this.props.state.tournaments?.selected_tournament_team?.id}
                    inscription_form_template={this.state.form_profile_type == 'player'?this.props.state.tournaments?.player_form_template:this.props.state.tournaments?.inscription_form_template}//TODO: verificar si se reutiliza para forms de equipos
                    tournament_player_id={this.state.form_tournament_player_id}
                    selected_admin_player={this.state.selected_admin_player}
                    selected_team={{team:this.state.selected_tournament_team, logo: this.state.selected_tournament_team_logo, teamname:this.state.selected_tournament_teamname}}
                    form_state={this.props.state.tournaments?.selected_tournament?.form_state} //TODO: CHECK AFTER CHANGE HUB APIS
                    loading_forms={this.props.state.tournaments?.loading_forms}                    
                    staff_id={this.state.selected_admin_player?.id}
                />
                <HubPaymentsModalComponent
                    isOpen={this.props.state.tournaments?.modals.payments_modal_is_open}
                    onClose= { ()=>{ 
                        this.props.changePaymentsModalStateAction(false); 
                        this.setState({selected_admin_player: undefined});
                        this.setState({show_code_payment: undefined});
                    } }
                    profile_type={this.state.form_profile_type}
                    tournament_team_id={this.props.state.tournaments?.selected_tournament_team?.id}
                    tournament_player_id={this.state.form_tournament_player_id}
                    tournament_registration_price_per_team={this.props.state.tournaments?.selected_tournament?.registration_price_per_team}
                    tournament_registration_price_per_player={this.props.state.tournaments?.selected_tournament?.registration_price_per_player}
                    setPlayersQuantity={(quantity)=>{this.setPlayersQuantity(quantity)}}
                    handleOnPayment={()=>{this.handleOnPayment()}}
                    currency={this.props.state.tournaments?.selected_tournament?.currency_payment}
                    handleOnCodeButton={(player_code)=>{this.handleOnCodeButton(player_code)}}
                    payment_player_code={this.props.state.tournaments?.payment_player_code}
                    //payment_player_code={this.state.show_code_payment}
                    profile_id={this.props.state.user.me.profile_id}
                    tournament_currency_payment={this.props.state.tournaments?.selected_tournament?.currency_payment}
                    submitWompiForm={this.state.submitWompiForm}
                    resetPaymentButton={()=>{this.setState({submitWompiForm: true})}}
                    type_redirect={this.state.type_redirect}
                    selected_admin_player={this.state.selected_admin_player}
                    handleCuponCodePayment={()=>{this.handleCuponCodePayment()}}
                    tournament_registration_type={this.props.state.tournaments?.selected_tournament?.registration_type}
                    profile_phone={this.props.state.user.me.phone}
                    tournament_id={this.props.state.tournaments?.selected_tournament?.id}
                />
                <ResponsiveHubModalComponent
                    isOpen= {this.props.state.tournaments.modals.responsive_hub_modal_is_open}
                    onClose={() => {this.props.changeResponsiveHubModalStateAction(false)}}
                />
                <FlexiInscriptionModalComponent
                    isOpen= {this.props.state.tournaments.modals.flexi_incription_modal_is_open}
                    onClose={() => {this.props.changeFlexiInscriptionModalStateAction(false);  this.props.changeIsShareLinkStateAction(false);}}
                    handleFlexiIncriptionButton={()=>{this.handleFlexiIncriptionButton()}}
                    team_shield={this.props.state.tournaments?.selected_tournament?.team_logo}
                    team={this.props.state.tournaments?.selected_tournament_team?.team}
                    tournament_name={this.props.state.tournaments?.selected_tournament?.name}
                    tournament_logo={this.props.state.tournaments?.selected_tournament?.logo}
                    handleOnRejectInscription={()=>{this.handleOnRejectInscription()}}
                />
                <ShareInscriptionLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_incription_link_modal_is_open}
                    onClose={() => {this.props.changeShareInscriptionLinkModalStateAction(false)}}
                    share_url={this.state.share_inscription_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareInscriptionTournamentLinkClick()}}
                    type_inscription_link_modal={this.state.type_inscription_link_modal}
                />
                <InscriptionDeadlineModalComponent 
                    isOpen= {this.props.state.tournaments.modals.inscription_deadline_is_open}
                    onClose={() => {this.props.changeInscriptionDeadlineModalStateAction(false)}}
                />

                { /** Tournament invitations inscriptions actions modal */}
                {/* <TournamentInvitationInscriptionActionsModalComponent //TODO: se elimino boton desde hub/inscription/player/detail/widget
                    isOpen={ this.props.state.tournaments.modals?.reject_player_insc_modal }
                    onClose={ () => this.props.changeRejectPlayerInscriptionModalStateAction(false) }
                    mainLabel={"¿Estás seguro que deseas rechazar la inscripción a este toreno?..."}
                    secondaryLabel={"Si cambias de opinión, tendras que ser inscrito nuevamente...."}
                    acceptBtn={"Rechazar..."}
                    cancelBtn={"Cancelar..."}
                    handleOnInvitationInscriptionModalActions={ () => {} }
                    accept_team_inscription_btn={ this.state.accept_team_inscription_btn }
                /> */}

                <VerifyFormPaymentTeamPlayerModalComponent 
                    isOpen={ this.props.state.tournaments.modals?.verify_form_payment_is_open }
                    onClose={ () => this.handleCloseVerifyFormPaymentTeamModal() }
                    message={this.state.verification_message}
                    form_btn={this.state.is_form}
                    payment_btn={this.state.is_payment}
                    handleModalForm={(btn_type)=>{this.handleModalForm(btn_type)}}                
                />
                <MatchDetailModalComponent 
                    isOpen={this.props.state.post.modals?.match_detail_is_open}
                    onClose={() => { this.props.changeMatchDetailModalStateAction(false) }}
                    match_detail = { this.props.state.leagueMatches.match_report }
                />

                {/* Tournament Team Members Modal */}
                <TournamentTeamMembersModalComponent 
                    isOpen={ this.props.state.page.modals?.team_members_is_open } 
                    onClose={() => { this.props.changeTeamMembersModalStateAction( false ) } }
                    selected_team={this.state.modal_selected_team}
                    team_members={this.props.state.leagueTournaments.tournament?.tournament_members_team_players}
                    carnet_id_template={ this.props.state.leagueTournaments.tournament.carnet_id_template }
                    handleOnDownloadCardIds={ (team_list)=> { this.loadCardIdsTeamInfo(team_list)} }
                    handleOnDownloadPlayerCardId={ (tournament_player_id)=> { this.downloadPlayerCardId(tournament_player_id)} }
                    handleOnDownloadStaffCardId={ (team_profile_id, tournament_team_id)=> { this.downloadStaffCardId(team_profile_id, tournament_team_id)} }
                />

                {/* Staff invitation confirmation */}
                <StaffInvitationModalComponent
                    isOpen= {this.props.state.tournaments.modals.staff_invitation_is_open}
                    handleStaffInscriptionButton={()=>{this.handleStaffInscriptionButton() }}
                    tournament_name={this.props.state.tournaments?.selected_tournament?.name}
                    tournament_logo={this.props.state.tournaments?.selected_tournament?.logo}
                    handleOnRejectInscription={()=>{this.handleOnRejectStaffInvitation()}}
                    staff_profile={this.state.staff_profile}
                />
                <StaffAcceptInvitationModalComponent
                    isOpen= {this.props.state.tournaments.modals.staff_accept_invitation_is_open}
                    handleStaffInscriptionButton={()=>{this.handleStaffAcceptInscriptionButton()}}
                />
                <ShareIframeLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_iframe_link_modal_is_open}
                    onClose={() => {this.props.changeShareIframeLinkStateAction(false);}}
                    share_url={this.state.share_iframe_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareIFramePositionTableLink()}}
                />
                {/** Pay tournament stats */}
                <ProfileStatisticsPaymentResumeModalComponent //TODO: CHECK AFTER CHANGE HUB APIS
                    isOpen={ this.props.state.profile.modals?.profile_statistics_resume_payment } 
                    onClose={() => { 
                        this.props.changeProfileStatisticsPaymentResumeModalStateAction( false ); 
                    } }
                    tournament={this.props.state.tournaments?.selected_tournament}
                    handleOnStatsCheckout={() => { this.handleOnStatsCheckout() }}
                    profile_payment_checkout={this.props.state.profile?.profile_payment_checkout}
                />

                {/** Unlock stats modal */}
                <UnlockStatsModalComponent
                    isOpen= {this.props.state.tournaments.modals.unlock_stats_is_open }
                    onClose={() => {this.props.changeUnlockStatsModalStateAction(false)}}
                    onButtonClick={ ()=>{ this.handleOnResumePaymentStats(); this.props.changeUnlockStatsModalStateAction(false) } }
                />

                {/** Permite visualizar y descargar el documento pdf con el/los carnets solicitados ** SE CAMBIA POR MODAL DE SOLO VISUALIZACION ** */}
                {/* <TournamentCardIdPdfGeneratorModalComponent //NO DESCOMENTARIAR
                    isOpen = {  this.props.state.tournaments.modals?.card_id_pdf_generator_modal }                                        
                    onClose={() => { this.props.changeTournamentCardIdPdfGeneratorModalStateAction(false); this.setState({downloadCardIdData: null}); }}                                         
                    downloadCardIdData={ this.state.downloadCardIdData }
                    card_id_form_data={ this.props.state.leagueTournaments?.card_ids_data?.form_card }
                    orientation={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Orientacion')?.text_answer}
                    template_image={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Plantilla')}
                /> */}

                {/** Permite visualizar el/los carnets solicitados */}
                <TournamentCardIdViewerModalComponent
                    isOpen = {  this.props.state.tournaments.modals?.card_id_pdf_generator_modal }                                        
                    onClose={() => { this.props.changeTournamentCardIdPdfGeneratorModalStateAction(false); this.setState({downloadCardIdData: []}); }}                                         
                    downloadCardIdData={ this.state.downloadCardIdData }
                    card_id_form_data={ this.props.state.leagueTournaments?.card_ids_data?.form_card }
                    orientation={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Orientacion')?.text_answer}
                    template_image={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Plantilla')}
                />

                {/* Add members without an account */}
                <AddMembersWithoutAccountModalComponent
                    isOpen= {this.props.state.tournaments.modals.members_without_account_is_open}
                    onClose={() => {this.handleOnCloseModal()}}
                    handleOnUploadFile = { ( formdata )=> { this.uploadBulkMembersFile( formdata ) } }
                    handleOnSaveBulkButton = { ()=> { this.handleOnSaveBulkButton() } }
                    bulkMembers= { this.props.state.teams.bulk_members }
                    cleanBulkMembers = {  ()=> { this.props.cleanBulkMembersAction({}) }}
                    cleanStatusBar = { ()=> { this.setState({ uploadProgress:0 }) } }
                    updateBulkData={ (row_data, row_index)=>{ this.updateBulkData(row_data, row_index) } }
                    handleOnMoreInfo={()=> { this.changeBulkPlayerStatusInfoModalState(true) }}
                    handleOnDeleteMember={(member_index)=>{this.handleOnDeleteMember(member_index)}}
                />

                {/** Delete bulk member confirmation modal */}
                <LeagueBulkDeleteMemberModalComponent
                    isOpen={ this.props.state.teams.modals.is_open_bulk_delete_member_modal }
                    onClose = {() => this.props.changeBulkDeleteMemberModalStateAction(false) }                   
                    modalTitle={ this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersTitle') }
                    modalContent = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersText') }
                    deleteButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersDeleteButtonLbl') }
                    cancelButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersCancelButtonLbl') }
                    deleteButtonAction={()=>{this.deleteBulkMember()}}
                />

                {/* Add members with account */}
                <AddMemberWithAccountModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.tournaments.modals?.add_member_to_league_is_open } 
                    onClose={() => { this.props.changeLeaguesAddMemberToLeagueModalStateAction( false ) }}
                    title={this.props.t('team:team.tabs.titles.titleAddMemberModal')}        
                    profiles = {this.props.state.teams?.searched_members}
                    handleSearchMember = {(searched_member) => this.handleSearchMember(searched_member)}
                    onNewData = { ( new_member ) => { 
                        //this.props.changeLeaguesAddMemberToLeagueModalStateAction( false )
                        this.setState({playerSelected:new_member});                                               
                    } }
                    onSelectRolPlayer = { ( player )=> { this.handleAddMember(player) } }
                    handleCleanMemberSearch = { ()=>{ this.props.cleanSearchedMembersAction() } }
                    playerSelected = { this.state.playerSelected }
                    roles = {this.state.roles_members} 
                    positions = {this.state.positions_members} 
                    errors={ this.props.state.teams.errors.add_member_roles }
                    onResetError={(data) => {this.props.changeNewTeamError(data)}}
                />

                {/* Answer multiple forms modal */}
                <AnswerMultipleFormsModalComponent  
                    onClick={() => {}} 
                    isOpen={ this.props.state.tournaments.modals?.answer_multiple_forms_is_open } 
                    onClose={() => { this.props.changeAnswerMultipleFormsModalStateAction( false ) }}
                    title={this.props.t('team:team.tabs.titles.titleMultipleForms')} 
                    tournament_team_id={this.props.state.tournaments?.selected_tournament_team?.id}
                    inscription_form_template={this.state.form_profile_type == 'player' ? this.props.state.tournaments?.player_form_template : this.props.state.tournaments?.inscription_form_template}//TODO: verificar si se reutiliza para forms de equipos
                    // tournament_player_id={this.state.form_tournament_player_id}
                    selected_admin_player={this.state.selected_admin_player}
                    selected_team={{team:this.state.selected_tournament_team, logo: this.state.selected_tournament_team_logo, teamname:this.state.selected_tournament_teamname}}
                    team_staff={this.props.state.tournaments.team_manager_and_staff}
                    team_players={this.props.state.tournaments.team_players}
                    selectedPlayer={this.props.state.tournaments.team_players.concat(this.props.state.tournaments.team_manager_and_staff).find((element) => element?.form_id === null)}
                    onNewData = { ( data ) => { this.sendForm(data)}}
                    realizeMultiplePlayerForms={(selected_player, type_redirect) => {this.realizeMultiplePlayerForms(selected_player, type_redirect)}}
                    
                    next_team_players={this.props.state.tournaments.next_team_players}
                    loading_team_players={this.props.state.tournaments.loading_team_players}
                    handleOnNextTeamPlayers={()=>{ this.props.loadTeamPlayers(null,null,this.props.state.tournaments.next_team_players)}}
                    next_team_staff={this.props.state.tournaments.next_team_staff}
                    loading_team_staff={this.props.state.tournaments.loading_team_staff}
                    handleOnNextTeamStaff={()=>{ this.props.loadTeamManagerAndStaff(null,null,this.props.state.tournaments.next_team_staff)}}
                />
            </div>
            
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadHubTournaments: (loading = false, next_page) => {
            return dispatch(loadHubTournaments(loading, next_page))
        },
        loadHubTournamentTeams: async (tournament_id, teams_state) => {
            await dispatch(loadHubTournamentTeams(tournament_id, teams_state))
        },
        showHubTournamentDetailAction: (new_state) => {
            dispatch(showHubTournamentDetailAction(new_state))
        },
        setHubTournamentHubAction: (new_state) => {
            dispatch(setHubTournamentHubAction(new_state))
        },
        setHubTournamentInscriptionsAction: (new_state) => {
            dispatch(setHubTournamentInscriptionsAction(new_state))
        },
        setHubTournamentMyTournamentsAction: (new_state) => {
            dispatch(setHubTournamentMyTournamentsAction(new_state))
        },
        setHubTournamentInvitationsAction: (new_state) => {
            dispatch(setHubTournamentInvitationsAction(new_state))
        },
        loadHubTeamsTournaments: (teams_state) => {
            dispatch(loadHubTeamsTournaments(teams_state))
        },
        loadPlayerTournaments: (player_state) => {
            dispatch(loadPlayerTournaments(player_state))
        },
        loadSelectedTournament: async ( tournament_id ,tournament_team_id) => {
            await dispatch( loadSelectedTournament( tournament_id , tournament_team_id) )
        },
        loadAllManagedTournaments: (filter) => {
            dispatch(loadAllManagedTournaments(filter))
        },        
        /* showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        }, */
        changeTournamentTeamState: async (tournament_team_id, new_team_state) => {
            await dispatch( changeTournamentTeamState(tournament_team_id, new_team_state))
        },
        changeInscriptionsModalStateAction: ( new_state ) => {
            dispatch( changeInscriptionsModalStateAction( new_state ) )
        },
        loadProfileAdminTeams: async (queryParams) => {
            await dispatch( loadProfileAdminTeams(queryParams) )
        },
        getCountries: async (country) => {
            await dispatch(getCountries(country));
        },
        searchCityByCountry: async (countryId, searchedCity) => {
            await dispatch(searchCityByCountry(countryId, searchedCity));
        },
        loadHubTournamentsFilter: (filter) => {
            dispatch(loadHubTournamentsFilter(filter))
        }, 
        loadTeamPlayers: async (tournament_team_id, state, next_team_players) => {
            await dispatch( loadTeamPlayers( tournament_team_id, state, next_team_players ) );
        },
        loadPaymentUrl: async (tournament_id, players_quantity, type_redirect) => {
            await dispatch( loadPaymentUrl(tournament_id, players_quantity, type_redirect) )
        },
        registerHubTeamToTournament: async ( tournament_id, team_name, team_state ) => {
            await dispatch( registerHubTeamToTournament( tournament_id, team_name, team_state ))
        },
        sentPlayerInscriptionForm: ( data, reload_data, team_name,tournament_id) => {            
            dispatch( sentPlayerInscriptionForm( data, reload_data ,team_name,tournament_id) );
        },
        changeNewNamesError: ( data ) =>{
            dispatch( changeNewNamesError( data ) );
        },
        registerHubTeamToTournamentToPay: async ( tournament_id, team_name, team_state ) => {
            await dispatch( registerHubTeamToTournamentToPay( tournament_id, team_name, team_state ))
        },
        changeTeamPlayerState: (tournament_player_id, new_team_state) => {
            dispatch( changeTeamPlayerState(tournament_player_id, new_team_state))
        },
        loadPlayerPaymentUrl: async (tournament_player_id, player_code, type_redirect) => {
            await dispatch( loadPlayerPaymentUrl(tournament_player_id, player_code, type_redirect) )
        },
        loadSelectedTeamTournament: async ( tournament_team_id, social_share ) => {
            return await dispatch( loadSelectedTeamTournament( tournament_team_id, social_share ) )
        },
        loadSelectedPlayerTournament: async ( tournament_player_id ) => {
            return await dispatch( loadSelectedPlayerTournament( tournament_player_id ) )
        },
        loadTournamentHubTournamentTeams: (tournament_id, teams_state) => {
            return dispatch(loadTournamentHubTournamentTeams(tournament_id, teams_state))
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        cleanPlayerTeamTournamentsAction: () => {
            return dispatch(cleanPlayerTeamTournamentsAction({}))
        },
        changeTournamentPlayerState: ( player_id, new_player_state ) => {
            dispatch(changeTournamentPlayerState( player_id, new_player_state ));
        },
        loadTournamentInscriptionFormTemplate: (form_type, tournament_id) => {
            dispatch(loadTournamentInscriptionFormTemplate(form_type, tournament_id))
        },
        changePageNewTeamModalStateAction: (new_modal_team) => {
            dispatch(changePageNewTeamModalStateAction(new_modal_team))
        },
        loadPageErrorsAction: (errors, section) => {
            return dispatch(loadPageErrorsAction(errors, section))
        },
        createPage: (page_creation_data) => {
            return dispatch(createPage(page_creation_data))
        },
        changeFormsModalStateAction: ( new_state ) => {
            dispatch( changeFormsModalStateAction( new_state ) )
        },
        loadTournamentPlayerFormTemplate: (form_type, tournament_id) => {
            dispatch(loadTournamentPlayerFormTemplate(form_type, tournament_id))
        },
        changePaymentsModalStateAction: ( new_state ) => {
            dispatch( changePaymentsModalStateAction( new_state ) )
        },
        changeResponsiveHubModalStateAction: (new_state) => {
            dispatch(changeResponsiveHubModalStateAction(new_state));
        },
        /* changeRejectPlayerInscriptionModalStateAction: ( new_state ) => {
            dispatch( changeRejectPlayerInscriptionModalStateAction( new_state ) )
        }, */
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        setUrlRedirect: ( url_redirect ) => {
            return dispatch( setUrlRedirect( url_redirect ) );
        },
        changeFlexiInscriptionModalStateAction: ( new_state ) => {
            return dispatch( changeFlexiInscriptionModalStateAction( new_state ) )
        },
        addFlexiMemberToTeam:( teamname, member, tournament_team_id ) => {
            dispatch( addFlexiMemberToTeam( teamname, member, tournament_team_id  ) );
        },
        changeShareInscriptionLinkModalStateAction: ( new_state ) => {
            return dispatch( changeShareInscriptionLinkModalStateAction( new_state ) )
        },
        changeSignUpWelcomeModal: (new_modal_state) => {
            return dispatch(changeSignUpWelcomeModal(new_modal_state))
        },
        addNewTeam: async (new_team_data, uploadProgress, pagename) => {
            await dispatch(addNewTeam(new_team_data, uploadProgress, pagename));
        },
        changeIsShareLinkStateAction: (new_state) => {
            dispatch(changeIsShareLinkStateAction(new_state));
        },
        changeVerifyFormPaymentModalStateAction: (new_state) => {
            dispatch(changeVerifyFormPaymentModalStateAction(new_state));
        },
        setSelectedTournamentTeamAction: async (tournament) => {
            await dispatch(setSelectedTournamentTeamAction(tournament));
        },
        loadTemporalPlayerTournament: async (tournament_player_id) => {
            await dispatch(loadTemporalPlayerTournament(tournament_player_id));
        },
        loadHubTournamentsSections: () => {
            return dispatch(loadHubTournamentsSections())
        },
        loadHubTournamentsSubsections: (tournament_subsection, loading = false, next_page) => {
            return dispatch(loadHubTournamentsSubsections(tournament_subsection, loading, next_page));
        },
        clearHubTournamentSubsectionAction: () => {
            return dispatch(clearHubTournamentSubsectionAction([]))
        },
        loadInformativeTournament: async ( tournament_id ) => {
            await dispatch( loadInformativeTournament( tournament_id ) )
        },
        getHubTournamentSearch: (tournament_query, loading = false, next_page) => {
            return dispatch(getHubTournamentSearch(tournament_query, loading, next_page))
        },
        loadHubFollowingTournamentsSubsection: ( subsection ) => {
            return dispatch( loadHubFollowingTournamentsSubsection( subsection ) );
        },
        onFollowTournament:(tournamentId)=>{
            dispatch(onFollowTournament(tournamentId));
        },
        onUnfollowTournament:(tournamentId)=>{ 
            dispatch(onUnfollowTournament(tournamentId));
        },
        loadTournamentInfo: async ( id_tournament ) => {       
            await dispatch( loadTournamentInfo(  id_tournament ) )
        },
        loadTournamentPhases: ( tournament_id ) => {
            dispatch( loadTournamentPhases( tournament_id ) );
        },
        loadPhaseResultsTable: (phase_id) => {
            return dispatch(loadPhaseResultsTable(phase_id))
        },
        loadPhaseDates: async ( phase_id ) => {
            return await dispatch( loadPhaseDates( phase_id ))
        },
        loadPhaseMatchesByDate: async ( phase_id, calendar_date ) => {
            return await dispatch( loadPhaseMatchesByDate( phase_id, calendar_date ))
        },
        loadMatchReport: (match_id) => {
            return dispatch(loadMatchReport(match_id));
        },
        changeMatchDetailModalStateAction: (new_modal_state) => {
            dispatch(changeMatchDetailModalStateAction(new_modal_state))
        },
        changeTeamMembersModalStateAction: ( new_modal_state ) => {
            dispatch( changeTeamMembersModalStateAction( new_modal_state ) );
        },
        loadTournamentMembersTeams: ( team_id) => {
            dispatch( loadTournamentMembersTeams( team_id ));
        },
        searchWorldCities:( searched_city)  => {
            dispatch( searchWorldCities( searched_city ) )
        },
        changeStaffInvitationModalStateAction: ( new_modal_state ) => {
            dispatch( changeStaffInvitationModalStateAction( new_modal_state ) );
        },
        changeStaffAcceptInvitationModalStateAction: ( new_modal_state ) => {
            dispatch( changeStaffAcceptInvitationModalStateAction( new_modal_state ) );
        },
        addUserToStaff: (username, role, staff_state, tournament_id) => {
            dispatch( addUserToStaff( username, role, staff_state, tournament_id ) );
        },
        changeShareIframeLinkStateAction: ( new_state ) => {
            return dispatch( changeShareIframeLinkStateAction( new_state ) )
        },
        loadTournamentStaff: ( id_tournament ) => {
            dispatch( loadTournamentStaff( id_tournament ));
        },
        loadRefeeringPaymentUrl: async ( payment_data, match_id ) => {
            await dispatch( loadRefeeringPaymentUrl(payment_data, match_id) );
        },
        assignRefereesMatch: ( match_id, referees_match_data, match_date_id ) => {
            dispatch( assignRefereesMatch( match_id, referees_match_data, match_date_id ))
        },
        makeRefereePayment: ( match_id ) => {
            dispatch( makeRefereePayment( match_id ) );
        },
        changeInscriptionDeadlineModalStateAction: ( new_state ) => {
            dispatch( changeInscriptionDeadlineModalStateAction( new_state ) );
        },
        cleanTeamDataAction:()=>{
            dispatch(cleanTeamDataAction({}));
        },
        loadAdminTeaminMatchReportAction: (match_id)=>{
            dispatch(loadAdminTeaminMatchReportAction(match_id));
        },
        updateAdminTeamsAssistanceAligment: (matchId, playerArray) => {
            dispatch(updateAdminTeamsAssistanceAligment (matchId, playerArray))
        },
        loadTeamsToMatchAssistanceAligment: (matchId)=>{ 
            dispatch(loadTeamsToMatchAssistanceAligment(matchId))
        },
        loadHubTournamentMatches: ()=>{ 
            dispatch(loadHubTournamentMatches())
        },
        loadHubTournamentMatchesByDate: ( date )=>{ 
            dispatch(loadHubTournamentMatchesByDate( date ))
        },
        loadHubNextMatches: ()=>{ 
            dispatch(loadHubNextMatches())
        },
        loadHubTournamentMatchesDates: ()=>{ 
            dispatch(loadHubTournamentMatchesDates())
        },
        changeProfileStatisticsPaymentResumeModalStateAction: (new_modal_state) => {
            dispatch( changeProfileStatisticsPaymentResumeModalStateAction(new_modal_state) );
        },
        profileTournamentStatsPayment: (username, payment_data) => {
            dispatch( profileTournamentStatsPayment(username, payment_data) );
        },
        changeUnlockStatsModalStateAction: (new_modal_state) => {
            dispatch( changeUnlockStatsModalStateAction(new_modal_state) );
        },
        resetTournamentPhasesAction: ( data ) => {
            dispatch( resetTournamentPhasesAction( data ));
        },
        resetPhaseDatesMatchesAction: ( data ) => {
            dispatch( resetPhaseDatesMatchesAction( data ));
        },
        searchProfileAdminTeams: (queryParams) => {
            dispatch( searchProfileAdminTeams(queryParams) )
        },
        cleanProfileAdminTeamsAction:()=>{
            dispatch(cleanProfileAdminTeamsAction({}));
        },
        loadTeamManagerAndStaff: (team_name, tournament_id, next_team_staff) => {
            dispatch( loadTeamManagerAndStaff(team_name, tournament_id, next_team_staff) );
        },
        loadCardIdTemplate:( id_tournament ) => {
            dispatch( loadCardIdTemplate( 'tournament_player_card', id_tournament ))
        },
        loadCardIdsData: async( tournament_id, teams_list ) => {
            await dispatch( loadCardIdsData( tournament_id, teams_list ))
        },
        changeTournamentCardIdPdfGeneratorModalStateAction: (new_modal_state) => {
            dispatch( changeTournamentCardIdPdfGeneratorModalStateAction(new_modal_state) );
        },
        loadPlayerCardIdData:( tournament_player_id ) => {
            dispatch( loadPlayerCardIdData( tournament_player_id ))
        },
        loadStaffCardIdData:( team_profile_id, tournament_team_id ) => {
            dispatch( loadStaffCardIdData( team_profile_id, tournament_team_id ))
        },
        changeAddMembersWithoutAccountModalStateAction: ( new_state ) => {
            dispatch( changeAddMembersWithoutAccountModalStateAction( new_state ) );
        },  
        changeAddingBulkMemberStateAction: ( new_state ) => {            
            dispatch( changeAddingBulkMemberStateAction( new_state ) )
        },  
        uploadBulkData: async( teamname, membersData ) => {            
            await dispatch( uploadBulkData( teamname, membersData ) )
        },  
        changeLoadingBulkFileStateAction: ( new_state ) => {            
            dispatch( changeLoadingBulkFileStateAction( new_state ) )
        },
        changeShowBulkStateAction: ( new_state ) => {            
            dispatch( changeShowBulkStateAction( new_state ) )
        },
        cleanBulkMembersAction: ( data ) => {            
            dispatch( cleanBulkMembersAction( data ) )
        },
        setBulkMembersAction: ( data ) => {            
            dispatch( setBulkMembersAction( data ) )
        },
        changeBulkDeleteMemberModalStateAction: ( new_state ) => {            
            dispatch( changeBulkDeleteMemberModalStateAction( new_state ) )
        },
        changeLeaguesAddMemberToLeagueModalStateAction: ( new_modal_state ) => {            
            dispatch( changeLeaguesAddMemberToLeagueModalStateAction( new_modal_state ) )
        },
        searchMembersWithValidation: ( member_username, actual_members ) =>{
            dispatch( searchMembersWithValidation( member_username, actual_members ) );
        },
        cleanSearchedMembersAction: ()=>{
            dispatch(cleanSearchedMembersAction({}));
        },
        changeNewTeamError: ( data ) =>{
            dispatch( changeNewTeamError( data ) );
        },
        loadMemberRolesData: ( ) => {
            dispatch( loadMemberRolesData() );
        },
        loadMemberPositionsData: ( ) => {
            dispatch( loadMemberPositionsData() );
        },
        addMemberWithRolToTeam: async( teamname, member ) => {
            await dispatch( addMemberWithRolToTeam( teamname, member  ) );
        },
        changeAnswerMultipleFormsModalStateAction: ( new_modal_state ) => {            
            dispatch( changeAnswerMultipleFormsModalStateAction ( new_modal_state ) )
        },
        loadPhaseCalendar: async ( tournament_phase_id ) => {
            return await dispatch( loadPhaseCalendar( tournament_phase_id ))
        },
        cleanTeamPlayersAction:()=>{
            dispatch(cleanTeamPlayersAction({}));
        },
        searchTournamentTeamPlayers: async (tournament_team_id, player, state)=>{
            await dispatch(searchTournamentTeamPlayers(tournament_team_id, player, state));
        },
    }
}

export default compose(
    withTranslation(["tournament", "league", "team"]),
    connect(mapStateToProps, mapDispatchToProps)
)(TournamentHubView);
