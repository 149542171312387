/*eslint eqeqeq: "off"*/

// Apis
import { 
    ClapPostApi,
    GetPostDetailByIdApi,
    CommentPostByIdApi,
    ReplyCommentPostByIdApi,
    ListPostCommentsByIdApi,
    ListReplyCommentsByCommentIdApi,
    DeleteCommentApi,
    DeletePostApi, 
    ClapCommentByIdApi,
    ListPostClapsByIdApi,
    ReportPostByIdApi, 
    ListPostClapsNextApi,
    ListPostCommentsNextApi,
    UploadPostApi,
    EditPostCaptionApi,
    ReactionsPostApi,
    OverrrideReactionsPostApi,
    GetPostReactionsByIdApi,
    GetPostReactionsNextApi,
    GetPostReactionsByReactionTypeApi,
    DeleteReactionsPostApi,
    CommentReactionsApi,
    OverrrideCommentReactionsApi,
    DeleteCommentReactionsApi,
    GetPostCommentReactionsByIdApi,
    GetPostCommentReactionsByReactionTypeApi,
    AnswerQuizApi,
    AnswerPollApi,
    UploadVideoProfileApi,
    LoadAllProfileVideoApi,
    deleteProfileVideoApi,
    updateProfileVideoApi
} from 'app/posts/apis/PostsApi';

import { FollowProfileApi, UnfollowProfileApi } from 'app/profiles/apis/ProfileApi';
import { FollowPageApi, UnfollowPageApi } from 'app/pages/apis/PageApi';

// Actions
import { 
    loadPostAction, 
    loadPostCommentsAction, 
    clapCommentsAction, 
    loadPostClapsAction, 
    loadReplyPostCommentsAction, 
    loadPostClapsNextAction,
    loadPostCommentsNextAction,
    loadPostReactionsAction,
    loadPostReactionsNextAction,
    loadPostReactionsByTypeAction,
    loadPostCommentReactionsAction,
    loadPostCommentReactionsNextAction,
    loadPostCommentReactionsByTypeAction,
} from './PostsActionsCreators';
import { loadHomePosts, updateHomeFeed } from 'app/home/actions/HomeActions';
import { loadProfilePosts, /* updateProfilePosts */ } from 'app/profiles/actions/ProfileActions';
import { 
    updateProfilePostAction, 
    overwriteInterceptorAction, 
    updateProfilePostsFeedAction,
} from 'app/profiles/actions/ProfileActionsCreators';
import { updatePagePostsFeedAction, deletePagePostAction } from 'app/pages/actions/PageActionsCreators';
import { changeErrorMessageSnackBar, loadNewPost, updateHomePostAction, deleteHomePostAction, updateHomeFeaturedClipAction } from 'app/home/actions/HomeActionsCreators';
import { loadPagePosts, updatePagePosts } from 'app/pages/actions/PageActions';
// i18next 
import i18n from 'i18next';


export const clapPost = ( post ) => {
    
    return ( dispatch ) => {

        // dispatch( changeHomePostsLoadingAction( true ) );
        ClapPostApi( post )
            .then( response => {
                dispatch( loadHomePosts() ); // TODO: This should not be necessary!
                dispatch( loadPostDetailById( post.id ) ); // TODO: This should not be necessary!
                if(post.profile){
                    dispatch( loadProfilePosts( post.profile.user.username ) ); // TODO: This should not be necessary!
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                console.log(error.response)
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                // dispatch( changeHomePostsLoadingAction( false ) );
            } );
    }
}


export const doubleDecAction = ( post ) => {
 
    
    
}

export const deletePost = ( post ) => {
    
    return ( dispatch ) => {

        DeletePostApi( post )
            .then( response => {
                //dispatch( loadHomePosts() ); 
                dispatch( deleteHomePostAction( post ) );
                if(post.author.type_object == "profile"){
                    dispatch( loadProfilePosts( post.author.identifier ) );
                }                 
                if(post.author.type_object == "page" || post.context?.page_type == "group"){
                    dispatch( deletePagePostAction( post ) );
                }
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.alert'), 
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {                                           
                console.log(error.response)
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
            .then( () => {
                
            } );
    }
}

export const loadPostDetailById = ( post_id, type = null ) => ( dispatch ) => {

    GetPostDetailByIdApi( post_id )
        .then( response => {
            dispatch( loadPostAction( response.data ) )
            if(type === 'profile'){
                dispatch( updateProfilePostAction(response) );
            }
            if(type === 'featured'){
                dispatch( updateHomeFeaturedClipAction(response) );
            }
        } )
        .catch( error => {
            console.log( error );
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
        } )

}

export const commentPostById = ( post_id, comment, pagename = null, page_id = null, type = null ) => ( dispatch ) => {

    

    CommentPostByIdApi( post_id, comment, page_id )
        .then( response => {
            dispatch( loadPostDetailById( post_id, type ) )//TODO: validar funcionalidad 24/06/2021
            dispatch( loadPostCommentsById( post_id ) )//TODO: validar funcionalidad 24/06/2021
            dispatch( updateHomeFeed() );
            if( pagename ){
                dispatch( loadPagePosts( pagename ) );
            }          
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }            
            console.log( error ); 
        } )

}

export const replyCommentPostById = ( post_id, comment_id, comment ) => ( dispatch ) => {

    ReplyCommentPostByIdApi( comment_id, comment )
        .then( response => {
            dispatch( loadPostDetailById( post_id ) )
            dispatch( loadPostCommentsById( post_id ) )
            dispatch( loadReplyPostCommentsById( comment_id ) )
            
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'),
                    error_message_is_open: true
                } ) )
            }            
            console.log( error ); 
        } )

}

export const deleteComment = ( post_id, comment ) => ( dispatch ) => {

    DeleteCommentApi( comment.id )
        .then( response => {
            dispatch( loadPostDetailById( post_id ) )
            dispatch( loadPostCommentsById( post_id ) )            
            dispatch( changeErrorMessageSnackBar( {
                message: i18n.t('post:msgs.alert2'), 
                error_message_is_open: true
            } ) )
        })
        .catch( error => {                                           
            console.log(error.response)
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
        });

}

export const loadPostCommentsById = ( post_id ) => ( dispatch ) => {

    ListPostCommentsByIdApi( post_id )
        .then( response => {
            dispatch( loadPostCommentsAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const clapCommentById = ( comment_id ) => {
    
    return ( dispatch ) => {
     
        dispatch( clapCommentsAction(comment_id));
        ClapCommentByIdApi( comment_id )
            .then( response => {
                console.log(response);
                // dispatch( loadHomePosts() );
                // dispatch( loadPostDetailById( comment.post.id ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            })
            
    }
}

export const loadReplyPostCommentsById = ( comment_id ) => ( dispatch ) => {

    ListReplyCommentsByCommentIdApi( comment_id )
        .then( response => {
            dispatch( loadReplyPostCommentsAction( comment_id, response.data.results ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostClapsById = ( post_id ) => ( dispatch ) => {

    ListPostClapsByIdApi( post_id )
        .then( response => {
            dispatch( loadPostClapsAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const reportPostById = ( post_id, denunciation ) => {
    
    return ( dispatch ) => {
     
        ReportPostByIdApi( post_id, denunciation )
            .then( response => {
                // console.log('r',response);
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            })
            
    }
}

export const loadPostClapsNext = ( next_page ) => ( dispatch ) => {

    ListPostClapsNextApi( next_page )
        .then( response => {
            dispatch( loadPostClapsNextAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostCommentsNext = ( next_page ) => ( dispatch ) => {

    ListPostCommentsNextApi( next_page )
        .then( response => {
            dispatch( loadPostCommentsNextAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}
// export const clapComment = ( comment ) => {
    
//     return ( dispatch ) => {

//         ClapCommentApi( comment )
//             .then( response => {
//                 dispatch( loadHomePosts() );
//                 dispatch( loadPostDetailById( comment.post.id ) );
//             })
//             .catch( error => {
//                 console.log(error.response)
//             })
//             .then( () => {
//                 // dispatch( changeHomePostsLoadingAction( false ) );
//             } );
//     }
// }

export const uploadPost = ( formData, configUpload, loadPost, baloa_page ) => {

    return ( dispatch ) => {
     
        UploadPostApi( formData, configUpload, baloa_page )
            .then( response => {
                if(loadPost){
                    dispatch( loadNewPost( response.data ) );
                    if(response.data.author.type_object == "profile"){
                        dispatch( updateProfilePostsFeedAction(response.data) );
                    }
                    if(response.data.author.type_object == "page" || response.data.context?.page_type == "group"){
                        dispatch( updatePagePostsFeedAction(response.data) );
                    }
                    dispatch( overwriteInterceptorAction( false ) );
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.publishedPost'), 
                        error_message_is_open: true
                    } ) )
                }
                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            })            
    }
}

export const editPostCaption = (  post_id, profile_id, poster_id, caption ) => {
    
    return ( dispatch ) => {    

        EditPostCaptionApi( post_id, profile_id, poster_id, caption )
            .then( response => {     
                    dispatch( updateHomePostAction(response) );
                    dispatch( updateProfilePostAction(response) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            })            
    }
}


export const sendReaction = ( post_id, reaction, type = null, typename = null ) => {

    return ( dispatch ) => {

        ReactionsPostApi( post_id, reaction )
            .then( response => {
                dispatch( updateHomeFeed() );
                dispatch( updateHomePostAction(response) );
                dispatch( loadPostDetailById( post_id, type ) ); // TODO: This should not be necessary!
                if(type == 'profile'){
                    dispatch( updateProfilePostAction(response) );
                    //dispatch( updateProfilePosts( typename ) ); 
                }
                if(type == 'page'){
                    dispatch( updatePagePosts( typename ) ); 
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const overrideReaction = ( post_id, reaction, reaction_id, type = null, typename = null ) => {

    return ( dispatch ) => {

        OverrrideReactionsPostApi(reaction, reaction_id )
            .then( response => {
                dispatch( updateHomeFeed() );
                dispatch( loadPostDetailById( post_id, type ) );
                if(type == 'profile'){
                    //dispatch( updateProfilePosts( typename ) ); 
                    //dispatch( updateProfilePostAction(response) );
                }
                if(type == 'page'){
                    dispatch( updatePagePosts( typename ) ); 
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPostReactionsById = ( post_id ) => ( dispatch ) => {

    GetPostReactionsByIdApi( post_id )
        .then( response => {
            dispatch( loadPostDetailById( post_id ) );
            dispatch( loadPostReactionsAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostReactionsNext = ( next_page, reaction_type ) => ( dispatch ) => {

    GetPostReactionsNextApi( next_page )
        .then( response => {
            dispatch( loadPostReactionsNextAction( response.data, reaction_type ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostReactionsByReactionType = ( post_id, reaction_type ) => ( dispatch ) => {

    GetPostReactionsByReactionTypeApi( post_id, reaction_type )
        .then( response => {
            dispatch( loadPostReactionsByTypeAction( response.data, reaction_type ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const deleteReaction = ( post_id, reaction_id, type = null, typename = null ) => {

    return ( dispatch ) => {

        DeleteReactionsPostApi( reaction_id )
            .then( response => {
                dispatch( updateHomeFeed() ); // TODO: This should not be necessary!
                dispatch( loadPostDetailById( post_id, type ) ); 
                if(type == 'profile'){
                    //dispatch( updateProfilePosts( typename ) ); 
                    //dispatch( updateProfilePostAction(response) );
                }
                if(type == 'page'){
                    dispatch( updatePagePosts( typename ) ); 
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const sendCommentReaction = ( post_id, comment_id, reaction, parent_comment_id = null ) => {
    
    return ( dispatch ) => {

        CommentReactionsApi( comment_id, reaction )
            .then( response => {
                dispatch( loadPostCommentsById( post_id ) );
                if(parent_comment_id){
                    dispatch( loadReplyPostCommentsById( parent_comment_id ) );
                }
                
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const overrideCommentReaction = ( post_id, comment_id, reaction, reaction_id, parent_comment_id = null ) => {
    
    return ( dispatch ) => {

        OverrrideCommentReactionsApi(reaction, reaction_id )
            .then( response => {
                dispatch( loadPostCommentsById( post_id ) );
                if(parent_comment_id){
                    dispatch( loadReplyPostCommentsById( parent_comment_id ) );
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteCommentReaction = ( post_id, comment_id, reaction_id, parent_comment_id = null ) => {
    
    return ( dispatch ) => {

        DeleteCommentReactionsApi( reaction_id )
            .then( response => {
                dispatch( loadPostCommentsById( post_id ) );
                if(parent_comment_id){
                    dispatch( loadReplyPostCommentsById( parent_comment_id ) );
                }
            })
            .catch( error => {
                dispatch( changeErrorMessageSnackBar( true ) )                            
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}
export const loadPostCommentReactionsById = ( comment_id ) => ( dispatch ) => {

    GetPostCommentReactionsByIdApi( comment_id )
        .then( response => {
            //dispatch( loadPostDetailById( post_id ) );
            dispatch( loadPostCommentReactionsAction( response.data ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostCommentReactionsNext = ( next_page, reaction_type ) => ( dispatch ) => {

    GetPostReactionsNextApi( next_page )
        .then( response => {
            dispatch( loadPostCommentReactionsNextAction( response.data, reaction_type ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const loadPostCommentReactionsByReactionType = ( comment_id, reaction_type ) => ( dispatch ) => {

    GetPostCommentReactionsByReactionTypeApi( comment_id, reaction_type )
        .then( response => {
            dispatch( loadPostCommentReactionsByTypeAction( response.data, reaction_type ) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.error'), 
                    error_message_is_open: true
                } ) )
            }
            console.log( error );
        } )

}

export const followPostProfile = ( username, post_id, type = null) => {

    return ( dispatch ) => {
        
        FollowProfileApi( username )
            .then( response => {
                dispatch( loadPostDetailById( post_id, type ) );
                dispatch( updateHomeFeed() );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const unfollowPostProfile = ( username, post_id, type = null ) => {

    return ( dispatch ) => {
        
        UnfollowProfileApi( username )
            .then( response => {
                dispatch( loadPostDetailById( post_id, type ) );
                dispatch( updateHomeFeed() );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const followPostPage = ( pagename, post_id ) => {

    return ( dispatch ) => {
        
        FollowPageApi( pagename )
            .then( response => {
                dispatch( loadPostDetailById( post_id ) );
                dispatch( updateHomeFeed() );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const unfollowPostPage = ( pagename, post_id ) => {

    return ( dispatch ) => {
        
        UnfollowPageApi( pagename )
            .then( response => {
                dispatch( loadPostDetailById( post_id ) );
                dispatch( updateHomeFeed() );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                console.log( error );
            } )

    }
}

export const answerQuiz = ( post_id, answer_id, type = null, typename = null ) => ( dispatch ) => {

    AnswerQuizApi( post_id, answer_id )
        .then( response => {
            dispatch( updateHomeFeed() );
            if(type == 'profile'){
                //dispatch( updateProfilePosts( typename ) );
                dispatch( updateProfilePostAction(response) );
            }
            if(type == 'page'){
                dispatch( updatePagePosts( typename ) ); 
            }
        })
        .catch( err => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:snackBarMessages.pageExist'),
                    error_message_is_open: true
                } ) )
            }
        })
}

export const answerPoll = ( post_id, answer_id, type = null, typename = null ) => ( dispatch ) => {

    AnswerPollApi( post_id, answer_id )
        .then( response => {
            dispatch( updateHomeFeed() );
            if(type == 'profile'){
                //dispatch( updateProfilePosts( typename ) );
                dispatch( updateProfilePostAction(response) );
            }
            if(type == 'page'){
                dispatch( updatePagePosts( typename ) ); 
            }
        })
        .catch( err => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:snackBarMessages.pageExist'),
                    error_message_is_open: true
                } ) )
            }
        })
}