// React
import React from 'react';

// React device detect
import {isMobile} from 'react-device-detect';

// React Infinite Scroll Component
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//Assets
import loading_ball from 'assets/images/loading_ball.gif';

// Styles
import './ProfileStatisticsPaymentModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileStatisticsPaymentModalComponent( props ) {

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.tournaments_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.tournaments_next_page]); // eslint-disable-line

    return (        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('tournamentSummary.unlockStatisticsTitle')}
            className='profile-statistics-payment-modal'
        >    
            <div className='profile-statistics-payment-modal__container'>
                { !isMobile && <div className='profile-statistics-payment-modal__container-text baloa-table' >{props.t('tournamentSummary.unlockStatisticsText')}</div> }
                <div className='profile-statistics-payment-modal__container-table'>
                    { !isMobile &&
                        <div className='profile-statistics-payment-modal__container-table__header'>
                            <div className='profile-statistics-payment-modal__container-table__header-col__tournament baloa-table-column'>{props.t('tournamentSummary.labelTournament')}</div>
                            <div className='profile-statistics-payment-modal__container-table__header-col__value baloa-table-column'>{props.t('tournamentSummary.valueLabel')}</div>
                            <div className='profile-statistics-payment-modal__container-table__header-col__blank baloa-table-column' />
                        </div>
                    }
                    <div className='profile-statistics-payment-modal__container-table__body'>
                         <InfiniteScroll
                            dataLength={props.tournaments.length}
                            next={props.fetchMoreData}
                            hasMore={loadMore}
                            loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                            height={'90vh'}
                            endMessage={''}
                        >
                            { props.tournaments?.map((tournament, index) => (
                                    <React.Fragment>
                                        { !isMobile &&
                                            <div  key={index} className='profile-statistics-payment-modal__container-table__body-tournament__container'>
                                                { !tournament.player_has_paid &&
                                                    <React.Fragment>
                                                        <div className='profile-statistics-payment-modal__container-table__body-tournament__col data'>
                                                            <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__img'>
                                                                { tournament.tournament_logo
                                                                    ? <img src={tournament.tournament_logo} alt={`${tournament.tournament_name} logo`} />
                                                                    : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                                }
                                                            </div>
                                                            <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__tournament'>
                                                                
                                                                <div className='baloa-names'>{tournament.tournament_name}</div>
                                                                <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__tournament-labels'>
                                                                    <div className='baloa-mini'>{props.t(`tournamentSummary.footballType.${tournament.tournament_football_type}`)}</div>
                                                                    <div className='baloa-mini'>{props.t(`tournamentSummary.gender.${tournament.tournament_gender}`)}</div>
                                                                    <div className='baloa-mini'>{tournament.tournament_category}</div>
                                                                </div>                                            
                                                            </div>
                                                        </div>
                                                        <div className='profile-statistics-payment-modal__container-table__body-tournament__col value'>
                                                            <div className='baloa-names'>{tournament.tournament_stats_price}</div>
                                                        </div>
                                                        <div className='profile-statistics-payment-modal__container-table__body-tournament__col action'>
                                                            <PrimaryButtonComponent onClick={() => {props.handleOnResumePaymentStats(tournament)}} >{props.t('tournamentSummary.unlockBtn')}</PrimaryButtonComponent>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        }
                                        { (isMobile && !tournament.player_has_paid) &&
                                            <div key={index} className='profile-statistics-payment-modal__container-table__body-tournament__mobile-container'>
                                                <div className='profile-statistics-payment-modal__container-table__body-tournament__col data'>
                                                    <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__img'>
                                                        { tournament.tournament_logo
                                                            ? <img src={tournament.tournament_logo} alt={`${tournament.tournament_name} logo`} />
                                                            : <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        }
                                                    </div>
                                                    <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__tournament'>
                                                        
                                                        <div className='baloa-names'>{tournament.tournament_name}</div>
                                                        <div className='profile-statistics-payment-modal__container-table__body-tournament__col-info__tournament-labels'>
                                                            <div className='baloa-mini'>{props.t(`tournamentSummary.footballType.${tournament.tournament_football_type}`)}</div>
                                                            <div className='baloa-mini'>{props.t(`tournamentSummary.gender.${tournament.tournament_gender}`)}</div>
                                                            <div className='baloa-mini'>{tournament.tournament_category}</div>
                                                        </div>                                            
                                                    </div>
                                                </div>
                                                <div className='profile-statistics-payment-modal__container-table__body-tournament__mobile-amount'>
                                                    <div className='profile-statistics-payment-modal__container-table__body-tournament__col value'>
                                                        <div className='baloa-names'>{tournament.tournament_stats_price}</div>
                                                    </div>
                                                    <PrimaryButtonComponent onClick={() => {props.handleOnResumePaymentStats(tournament)}} >{props.t('tournamentSummary.unlockBtn')}</PrimaryButtonComponent>
                                                </div>

                                            </div>
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div>    
            {/* <div className='profile-statistics-payment-modal__unlock-all-action'>
                <PrimaryButtonComponent onClick={() => {}}>{props.t('tournamentSummary.unlockAllBtn')}</PrimaryButtonComponent>
            </div>         */}
        </Modal2Component>
        )
    }
    
    export default withTranslation('profile')(ProfileStatisticsPaymentModalComponent);