/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';

// Shapes
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import SugestionsComponent from 'app/pages/components/page-groups-suggestions/SugestionsComponent';
import PageGroupsMyGroupsComponent from 'app/pages/components/page-groups-my-groups/PageGroupsMyGroupsComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './PagesSuggestionsComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function PagesSuggestionsComponent( props ) {

    return(

        <React.Fragment>
            {/*settings list*/}
            <div className="pages-sugestions">
            
                <div className="pages-sugestions__content">

                    {/* <div className="pages-sugestions__header__content__icon"
                        onClick={() => { props.handleBackButton("post") } }
                        >
                            <span className="icon-arrow-left"></span>
                    </div> */}

                    <div className="pages-sugestions__content__title">
                            <span className="pages-sugestions__content__title__text baloa-headline-2">{props.title}</span>
                    </div>

                </div>
                
            </div>
            <Card1Component
                icon={ (
                    <span className={ `pages-sugestions__icon ${props.page_type === 'groups' ? 'icon-group-friends' : props.page_type === "pages" ? 'icon2-page' : props.page_type === "leagues" ? 'icon-trophy' : ''}`} /> 
                ) }                 
                className='pages-sugestions__card' 
                title={ props.subTitleCaption }
                button={  ( 
                    <PrimaryButtonComponent 
                        className='pages-sugestions__new-button'
                    >
                        {/* { props.pages_type === 'Group' ? props.t('follow.suggestions.buttonGroup') : props.pages_type === "Page" ? props.t('follow.suggestions.buttonPage') : props.t('follow.suggestions.buttonLeague')  } */}
                        { props.addButtonCaption }
                    </PrimaryButtonComponent>
                ) }
                onButtonClick={ () => { props.onClickAddButton() } }                
            ></Card1Component>

            {props.page_type == 'groups' && props.memberGroupPages.length > 0 && 
                <PageGroupsMyGroupsComponent
                    title={props.t('page:groups.myGroups.title')}
                    memberGroupPages = { props.memberGroupPages }
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                />
            }

            {props.page_type == 'pages' && props.myPages.length > 0 && 
                <PageGroupsMyGroupsComponent //Se reutiliza con mis paginas
                    title={props.t('profile:pages.title1')}
                    memberGroupPages = { props.myPages }
                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                />
            }

            <SugestionsComponent
                className="pages-sugestions__sugestions"                
                pages={ props.pages }
                handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                title = { props.cardsCaption }
                pages_type = { props.pages_type }
                handleFollow = { props.handleFollow }
                addClassName = { props.addClassName }
            /> 

        </React.Fragment>
     )
}

// Types
PagesSuggestionsComponent.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape( ProfileShape )
    ),
    // onClick: PropTypes.func.isRequired,
}

export default withTranslation(['home','page','profile'])(PagesSuggestionsComponent);
