// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './HomeUpdateUserInfoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function HomeUpdateUserInfoModalComponent( props ) {

    return (
        <ModalComponent 
            isOpen={props.isOpen} 
            className="home-update-user-info"        
        >
            
            <div className="home-update-user-info__container">  
                <div className="home-update-user-info__icon">
                    <span className='hubicon-info'/>
                </div>
                <div className="home-update-user-info__title baloa-subtitle-1">  
                    <span>{props.t('updUserInfo.title')}</span>
                </div>
                <div className="home-update-user-info__subtitle baloa-table">  
                    <span>{props.t('updUserInfo.subtitle')}</span>
                </div>
                <div className="home-update-user-info__separator"></div>
                <div className="home-update-user-info__buttons">           
                    <SecondaryButtonComponent className="small" onClick={ props.onClose }>
                        <span>{props.t('updLocation.cancelBtn')}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent  className="small" onClick={ props.onHandleAccept }>
                        <span>{props.t('updLocation.acceptBtn')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
        )
    }
    
    export default withTranslation('home')(HomeUpdateUserInfoModalComponent);