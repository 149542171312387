import React from 'react';
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentInviteTeamsComponent from '../tournament-invite-teams/TournamentInviteTeamsComponent';
import { withTranslation } from "react-i18next";

const TournamentModalInviteTeamComponent = (props) => {
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.modal_type_invited === 'inscription'
                ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelInscriptionTeam')
                : props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitleInviteTeam')
            }
        >
            <div>
                <TournamentInviteTeamsComponent
                    tournament={props.tournament}
                    tournament_teams={ props.tournament_teams }
                    handleOnAddTeamToTournament={  props.handleOnAddTeamToTournament }
                    sendInvitationToTeamTournament={props.sendInvitationToTeamTournament}
                    handleOnDeleteTeamFromTournament={  props.handleOnDeleteTeamFromTournament }
                    is_team = { true }
                    tabval={1}
                    handleSearchBaloaTeam = { props.handleSearchBaloaTeam }
                    baloaTeams = { props.baloaTeams }
                    handleCleanBaloaTeamsSearch = { props.handleCleanBaloaTeamsSearch }
                    eraseTeams={ true }
                    modalInvitation ={false}
                    pageRoles = { props.pageRoles }
                    modal_type_invited={props.modal_type_invited}
                />
            </div>
        </Modal2Component>
    );

};

export default withTranslation('league')(TournamentModalInviteTeamComponent)