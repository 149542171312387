import React, { useEffect, useState } from "react";
// Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import TournamentEndMatchStepsComponent from "../tournament-end-match-steps/TournamentEndMatchStepsComponent";
import FormComponent from "shared/components/form/FormComponent";
import LeagueMatchTeamAssistance from "../league-match-team-assistance/LeagueMatchTeamAssistance";
import { cloneDeep } from 'lodash';
//i18
import { withTranslation } from 'react-i18next';

import "./LeagueMatchAssistanceAligment.styles.css";

function LeagueMatchAssistanceAligment(props) {
    const [initialValues, setInitialValues] = useState({});
    const [assistanceListLocalTeam, setAssistanceListLocalTeam] = useState([]);
    const [assistanceListVisitorTeam, setAssistanceVisitorTeam] = useState([]);
    const [checkedAllMembers, setCheckedAllMembers] = useState({local_team: false, visitor_team: false})

    useEffect(() => {
        let initialLocalValues = {};
        let initialVisitorValues = {};
        props.local_team_members?.forEach(member => {
            initialLocalValues[`playerNumber_${member.player_id}`] = member.number;
            initialLocalValues[`playerPositions_${member.player_id}`] = lowerCase(member.player_position ?? '');
        });
        props.visitor_team_members?.forEach(member => {
            initialVisitorValues[`numberPlayer_${member.player_id}`] = member.number;
            initialVisitorValues[`playerPositions_${member.player_id}`] = lowerCase(member.player_position ?? '');
        });
        setInitialValues(Object.assign(initialLocalValues, initialVisitorValues));
    }, [props.local_team_members, props.visitor_team_members]);

    useEffect(() => {
        let checkAllLocalMembers = false;
        let checkAllLVisitorMembers = false;
        if (props.initialMemberAssigment.local?.length > 0) {
            setAssistanceListLocalTeam(props.initialMemberAssigment.local);
            if(props.initialMemberAssigment.local?.length === props.local_team_members.length){
                checkAllLocalMembers = true;
            }
        }
        if (props.initialMemberAssigment.visitor?.length > 0) {
            setAssistanceVisitorTeam(props.initialMemberAssigment.visitor);
            if(props.initialMemberAssigment.visitor?.length === props.visitor_team_members.length){
                checkAllLVisitorMembers = true;
            }
        }
        setCheckedAllMembers({ local_team: checkAllLocalMembers, visitor_team: checkAllLVisitorMembers});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.initialMemberAssigment])

    const lowerCase = (word) => { return word[0]?.toLowerCase() + word.slice(1) }
    const capitalize = (word) => { return word[0]?.toUpperCase() + word.slice(1) }

    const handleCheckedAllAssistance = (event, teamSide) => {
        if (teamSide === 'local_team') {
            if (teamSide === 'local_team' && event.target.checked) {
                props.local_team_members.forEach((member) => {
                    const playerLocalIndex = assistanceListLocalTeam.findIndex(player => player.playerId === member.player_id);
                    if(playerLocalIndex === -1) {
                        setAssistanceListLocalTeam((oldArray) => ([...oldArray, {
                            team_id: props.local_team.team_id,
                            playerId: member.player_id,
                            playerNumber: member.number,
                            playerPositions: lowerCase(member?.player_position ?? ''),
                            playerStateInMatch: '',
                            defaultChecked: true,
                        }]));
                    }
                });
                setCheckedAllMembers({...checkedAllMembers, local_team: true});

            } else {
                setAssistanceListLocalTeam([]);
                setCheckedAllMembers({...checkedAllMembers, local_team: false});
            }
        }

        if (teamSide === 'visitor_team') {
            if (teamSide === 'visitor_team' && event.target.checked) {
                props.visitor_team_members.forEach((member) => {
                    const playerVisitorIndex = assistanceListVisitorTeam.findIndex(player => player.playerId === member.player_id);
                    if(playerVisitorIndex === -1) {
                        setAssistanceVisitorTeam((oldArray) => ([...oldArray, {
                            team_id: props.visitor_team.team_id,
                            playerId: member.player_id,
                            playerNumber: member.number,
                            playerPositions: lowerCase(member?.player_position ?? ''),
                            playerStateInMatch: '',
                            defaultChecked: true,
                        }]));
                    }
                });
                setCheckedAllMembers({...checkedAllMembers, visitor_team: true});
            } else {
                setAssistanceVisitorTeam([]);
                setCheckedAllMembers({...checkedAllMembers, visitor_team: false});
            }
        }
    }

    const handleCheckedPlayer = (event, playerChecked, teamSide) => {
        const playerLocalIndex = assistanceListLocalTeam.findIndex(player => player.playerId === playerChecked.player_id);
        const playerVisitorIndex = assistanceListVisitorTeam.findIndex(player => player.playerId === playerChecked.player_id);

        if(teamSide === 'local_team' ){
            if (event.target.checked && playerLocalIndex === -1) {
                setAssistanceListLocalTeam((oldArray) => ([...oldArray, {
                    team_id: props.local_team.team_id,
                    playerId: playerChecked.player_id,
                    playerNumber: playerChecked.number,
                    playerPositions: lowerCase(playerChecked?.player_position ?? ''),
                    playerStateInMatch: playerChecked.rol_in_match ?? '',
                    defaultChecked: true,
                }]));
            } else {
                setAssistanceListLocalTeam(assistanceListLocalTeam?.filter(player => player.playerId !== playerChecked.player_id));
                setCheckedAllMembers({local_team: false, visitor_team:  props.visitor_team_members?.length === assistanceListVisitorTeam?.length });
            }
        }

        if(teamSide === 'visitor_team'){
            if (event.target.checked && playerVisitorIndex === -1) {
                setAssistanceVisitorTeam((oldArray) => ([...oldArray, {
                    team_id: props.visitor_team.team_id,
                    playerId: playerChecked.player_id,
                    playerNumber: playerChecked.number,
                    playerPositions: lowerCase(playerChecked?.player_position ?? ''),
                    playerStateInMatch: playerChecked.rol_in_match ?? '',
                    defaultChecked: true,
                }]));
            } else {
                setAssistanceVisitorTeam(assistanceListVisitorTeam?.filter(player => player.playerId !== playerChecked.player_id));
                setCheckedAllMembers({visitor_team: false, local_team: props.local_team_members?.length === assistanceListLocalTeam?.length});
            }
        }
    }

    const checkPlayerStateInMatch = (event, teamSide, playerId) => {
        if (teamSide === 'local_team') {
            const playerLocalIndex = assistanceListLocalTeam.findIndex(player => player.playerId === playerId);
            let copyAssistanceLocalTeam = [...assistanceListLocalTeam];
            copyAssistanceLocalTeam[playerLocalIndex] = {
                ...copyAssistanceLocalTeam[playerLocalIndex],
                playerStateInMatch: event.target.value
            }
            setAssistanceListLocalTeam(copyAssistanceLocalTeam);
        }

        if (teamSide === 'visitor_team') {
            const playerVisitorIndex = assistanceListVisitorTeam.findIndex(player => player.playerId === playerId);
            let copyassistanceVisitorTeam = [...assistanceListVisitorTeam];
            copyassistanceVisitorTeam[playerVisitorIndex] = {
                ...copyassistanceVisitorTeam[playerVisitorIndex],
                playerStateInMatch: event.target.value
            }
            setAssistanceVisitorTeam(copyassistanceVisitorTeam);
        }
    }

    const buildFormatDataSubmission = (objectForm) => {
        const allPlayers = assistanceListLocalTeam.concat(assistanceListVisitorTeam);
        const copyAllPlayer = cloneDeep(allPlayers);
        for (let key in objectForm) {
            const idValue = key.split('_');
            if (idValue.length >= 2) {
                let playerData = copyAllPlayer.find(player => player.playerId === idValue[1]);
                playerData[`${idValue[0]}`] = objectForm[key]
            }
        }
        if (allPlayers.length > 0 && allPlayers.some(player => player.playerStateInMatch !== '')) {
            props.updatePlayersAssistanceAligment(renameObjectkeysInArrayList(copyAllPlayer));
        }else if(allPlayers.length === 0){
            props.updatePlayersAssistanceAligment(allPlayers)
        }
        props.setMatchAssistant(objectForm)
        props.stepCompleted(1);
        props.setEndGameStepState(true);
    }

    const renameObjectkeysInArrayList = (array) => {
        const copyArray = cloneDeep(array);
        copyArray.forEach(player => {
            player.player_id = player.playerId;
            player.number = player.playerNumber;
            player.player_position = player.playerPositions === 'undefined' ? null : capitalize(player.playerPositions);
            player.rol_in_match = player.playerStateInMatch;

            delete player.playerId;
            delete player.playerNumber;
            delete player.playerPositions;
            delete player.playerStateInMatch;
            delete player.defaultChecked
        });
        return copyArray;
    }

    const validationDisabledNextButton = () => {
        if (assistanceListLocalTeam.length > 0 || assistanceListVisitorTeam.length > 0) {
            const completeAssistanceListLocal = assistanceListLocalTeam.some(player => player.playerStateInMatch === '');
            const completeAssistanceListVisitor = assistanceListVisitorTeam.some(player => player.playerStateInMatch === '');
            return (completeAssistanceListLocal || completeAssistanceListVisitor)
        }
        return false;
    }

    return (
        <div className="league_match_assistance-general-container scroll">
            <FormComponent
                formInitialValues={initialValues}
                onSubmit={(values, actions) => {
                    buildFormatDataSubmission(values)
                }}
                className="league_match_assistance-form-container"
                autocomplete="off"
            >
                {!props.onlyComponentInModal &&
                    <TournamentEndMatchStepsComponent
                        step={props.step}
                        stepCompleted={(i) => props.stepCompleted(i)}
                        endGameStepState={props.endGameStepState}
                    />
                }
                <div className="league_match_assistance-section">
                    {!props.onlyComponentInModal &&
                        <div className="league_match_assistance-section-subtitle baloa-table">
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTitle')}
                        </div>
                    }
                    <div className="league_match_assistance_section-teams-container">
                        {props.local_team
                            ? <LeagueMatchTeamAssistance
                                team={props.local_team}
                                teamSide={'local_team'}
                                teamMembers={props.local_team_members}
                                handleCheckedAllAssistance={handleCheckedAllAssistance}
                                positions_members={props.positions_members ?? []}
                                handleCheckedPlayer={handleCheckedPlayer}
                                assistanceListTeam={assistanceListLocalTeam}
                                checkPlayerStateInMatch={checkPlayerStateInMatch}
                                checkedAllMembers={checkedAllMembers}
                                fullScreen={props.match_data?.is_team_local_admin}
                            />
                            : ''
                        }
                        {props.visitor_team
                            ? <LeagueMatchTeamAssistance
                                team={props.visitor_team}
                                teamSide={'visitor_team'}
                                teamMembers={props.visitor_team_members}
                                handleCheckedAllAssistance={handleCheckedAllAssistance}
                                positions_members={props.positions_members ?? []}
                                handleCheckedPlayer={handleCheckedPlayer}
                                assistanceListTeam={assistanceListVisitorTeam}
                                checkPlayerStateInMatch={checkPlayerStateInMatch}
                                checkedAllMembers={checkedAllMembers}
                                fullScreen={props.match_data?.is_team_visitant_admin}
                            />
                            : ''}
                    </div>
                </div>
                <div className="league_match_assistance-footer">
                    <PrimaryButtonComponent
                        type="submit"
                        disabled={validationDisabledNextButton()}
                        onClick={() => { }}
                        className="league_match_assistance-footer-button"
                    >
                        <span>
                            {props.onlyComponentInModal 
                            ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelButtonContinue')
                            : props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonContinue')
                        }
                        </span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        </div>
    );
}

export default withTranslation('league')(LeagueMatchAssistanceAligment);
