// React
import React from 'react';

// Components
import LeagueTeamTableComponent from 'app/leagues/components/league-team-table/LeagueTeamTableComponent';

// Styles
import './LeagueTeamComponent.styles.css';


// i18n
import { withTranslation } from 'react-i18next';
//import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';


function LeagueTeamComponent( props ) {

    return (
        
        <div className="team-container">
            {/* <div className="team-container__header">
                <div className="team-container__header__title">
                    <p className="team-container__header__title__text">
                        {props.t('teams.teamsTitle')}
                    </p>  
                    
                    <div className="team-container__header__button">
                        <RoundedButtonComponent
                            type="button"
                            disabled={ false }                                        
                            onClick={()=>{ props.handleOnNewButton() }}  
                            className="small inverse"
                        >
                            <span>{props.t('teams.teamsAddTeamButtonLabel')}</span>
                        </RoundedButtonComponent>
                    </div>
                </div>
                <p className="team-container__header__description">
                    {props.t('teams.teamsText')}
                </p>
            </div> */}
            <div className="team-container__header">
                <div className="team-container__header__title baloa-headline-2">
                    {props.t('teams.teamsTitle')}                                      
                </div>
            </div>

            <div className="team-container__table">
                <LeagueTeamTableComponent
                    handleOnTeamActionClick = { props.handleOnTeamActionClick }
                    teams={ props.teams }//listado de equipos
                    handleOnNewButton={props.handleOnNewButton}
                    handleOnShareTeamPageInscription={ props.handleOnShareTeamPageInscription }
                />
            </div>
            
        </div>
                
        )
    }
    
    export default withTranslation('team')(LeagueTeamComponent);