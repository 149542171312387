// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-edit-form-tournament_container_big{
    padding: var(--space-x2);
    overflow: scroll;
    height: 40rem;
}

.tournament-edit-form-confirmations-buttons_container{
    display: grid;
    justify-content: center;
    align-items: center;
}

.tournament-edit-form-confirmations-buttons_container p{
    margin-top: var(--space-x2);
}

.tournament-edit-form-confirmations-buttons{
    display: flex;
    justify-content: flex-end;
    margin: var(--space-x2) 0;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-edit-form-modal/TournamentEditFormModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[".modal-edit-form-tournament_container_big{\n    padding: var(--space-x2);\n    overflow: scroll;\n    height: 40rem;\n}\n\n.tournament-edit-form-confirmations-buttons_container{\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.tournament-edit-form-confirmations-buttons_container p{\n    margin-top: var(--space-x2);\n}\n\n.tournament-edit-form-confirmations-buttons{\n    display: flex;\n    justify-content: flex-end;\n    margin: var(--space-x2) 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
