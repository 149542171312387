import React from 'react';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
import { withTranslation } from 'react-i18next';

import './ListDynamicComponent.styles.css';

function ListDynamicComponent({
    id,
    caption,
    question_type,
    is_required,
    answers,
    t,
}) {
    // const [selectedItem, setSelectedItem] = React.useState('');
    /* const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(!open);

    const handleListItemClick = (item) => {
        // setSelectedItem(item.caption);
        handleSelectList(item.id);
        handleClick();
    }; */

    // React.useEffect(() => {
    //     let defaultValue = answers.filter(answer => answer.is_default === true);
    //     if (defaultValue.length > 0) {
    //         setSelectedItem(defaultValue[0]?.caption);
    //         handleSelectList(defaultValue[0]?.id);
    //     }
    // }, []);// eslint-disable-line  

    function handleDefaultValue() {
        let defaultValue = answers?.filter( answer => answer.is_default === true );
        if(defaultValue.length > 0){
            return defaultValue[0].id;
        }else{
            return "";
        }        
    }

    return (
        <div className={"list_dynamic_container"}>
            <p className={"baloa-username list_dynamic_container_label"}>{caption}</p>
            {/* <List key={id}>
                <ListItem onClick={() => handleClick()}>
                    {selectedItem.length > 0
                        ? selectedItem
                        : <p className={"baloa-username list_dynamic_container_label"}>{t("inscriptionModal.labelListOption")}</p>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding key={id}>
                        {answers.map((item) => (
                            <ListItem
                                key={item.id}
                                button
                                // selected={checkItem === item.id}
                                onClick={() => handleListItemClick(item)}
                            >
                                <ListItemText primary={item.caption} className="baloa-username list_dynamic_container_Item" />
                            </ListItem>
                        ))
                        }
                    </List>
                </Collapse>
            </List> */}
            <div className={"content-select"}>
                <select name={id} id={id}  defaultValue={handleDefaultValue()} required={is_required}>
                    <option value="" disabled="disabled" >{t("inscriptionModal.labelListOption")}</option>
                    {answers.map((item) => (
                        <option 
                            key={item.id}
                            className="baloa-username list_dynamic_container_Item"
                            value={item.id} 
                            //onClick={() => handleListItemClick(item)}
                        >
                            {item.caption}
                        </option>
                    ))
                    }
                </select>
            </div>
        </div>
    );
};

export default withTranslation('tournament')(ListDynamicComponent);