import {produce} from "immer" //Libreria para evitar errores de mutacion de objetos

// Profile state
const search_state = {
    keyword: "",
    filter: "",
    filtered: false,
    show_sbox: false,
    errors:{
        searchfield:{
            searchfield: "",
        },
    },
    box_search_results:[],
    search_results:[],
    search_results_next: null,
    auto: true,
    is_search_on: false,
}

export default function SearchReducer( state = search_state, action ) {
    switch ( action.type ) {
        case "SEARCH:LOAD_SEARCH": {
            return { ...state, search_results: action.data.data.results }
        }

        case "SEARCH:LOAD_SEARCH_FILTER": {
            let new_search_results = null;
            if(state.search_results_next !== action.data.data.next && action.data.data.previous){
                new_search_results = state.search_results.concat( action.data.data.results );
            }else if(!action.data.data.previous){
                new_search_results = action.data.data.results;
            }else{
                new_search_results = state.search_results;
            }
            return { ...state, search_results: new_search_results, search_results_next: action.data.data.next }
        }

        case "SEARCH:CHANGE_SEARCH_FILTERED_STATE": {
            return { ...state, filtered: action.new_state }
        }

        case "SEARCH:CHANGE_SEARCH_BOX_STATE": {
            return { ...state, show_sbox: action.new_state }
        }
        case "SEARCH:CLEAN_SEARCH_KEYWORD":{
            return { ...state, keyword: action.new_value, box_search_results: [] }
        }
        case "SEARCH:LOAD_SEARCH_BOX": {
            return { ...state, box_search_results: action.data.data, keyword: action.data.config.search }
        }

        case "SEARCH:LOAD_SEARCH_ERROR": {
            let new_errors_state = { ...state['errors'], [action.section]: action.errors }
            return { ...state, errors: new_errors_state }
        }

        case "SEARCH:UPDATE_SEARCH_RESULT_FOLLOW":{

            let new_search_results = [];
            let update_type = "";
            if (action.data.search_type === 'profile'){
                update_type = "profiles";
                const new_search_results_pr = produce(state.search_results.profiles, draft => {
                    draft.filter((profile) => {
                //let new_search_results_pr = state.search_results.profiles.filter((profile) => {
                        if(profile.is_type === 'profile' && profile.username === action.data.followkey){
                            profile.is_following = !profile.is_following
                        }
                        return profile;
                    });
                });
                new_search_results = new_search_results_pr;
            }
            if (action.data.search_type === 'page'){
                update_type="pages";
                const new_search_results_pa = produce(state.search_results.pages, draft => {
                    draft.filter((page) => {
                //let new_search_results_pa = state.search_results.pages.filter((page) => {
                        if(page.is_type === 'page' && page.pagename === action.data.followkey){
                            page.is_following = !page.is_following
                        }
                        return page;
                    });
                });
                new_search_results = new_search_results_pa;
            }
            if (action.data.search_type === 'tournament'){
                update_type="tournaments";
                const new_search_results_t = produce(state.search_results.tournaments, draft => {
                    draft.filter((tournament) => {
                //let new_search_results_t = state.search_results.tournaments.filter((tournament) => {
                        if(tournament.is_type === 'tournament' && tournament.id === action.data.followkey){
                            tournament.is_following = !tournament.is_following
                        }
                        return tournament;
                    });
                });
                new_search_results = new_search_results_t;
            }
            let new_sr_state = { ...state['search_results'], [`${update_type}`]: new_search_results }
            return { ...state, search_results: new_sr_state }
        }

        case "SEARCH:CHANGE_SEARCH_ON_STATE": {
            return { ...state, is_search_on: action.new_state }
        }

        // no default

    }
    return state;
}

