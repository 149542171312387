// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-phase-team-warning__title {
    max-width: 272px;
}

.add-phase-team-warning__text{
    max-width: 280px;
    margin-top: calc(var(--space-half) + 2px);
}

.add-phase-team-warning__actions {
    display: flex;
    margin-top: var(--space-x2);
    margin-bottom: var(--space-x2);
    justify-content: space-between;
}

.add-phase-team-warning__button {
    display: block;
    width: 49%;
    margin-top: var(--space-half-quarter);
    height: calc(var(--space-x2) + var(--space-half));
}

.add-phase-team-warning .modal__container{
    border: 8px solid var(--error-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/add-phase-team-warning-modal/AddPhaseTeamWarningModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,UAAU;IACV,qCAAqC;IACrC,iDAAiD;AACrD;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".add-phase-team-warning__title {\n    max-width: 272px;\n}\n\n.add-phase-team-warning__text{\n    max-width: 280px;\n    margin-top: calc(var(--space-half) + 2px);\n}\n\n.add-phase-team-warning__actions {\n    display: flex;\n    margin-top: var(--space-x2);\n    margin-bottom: var(--space-x2);\n    justify-content: space-between;\n}\n\n.add-phase-team-warning__button {\n    display: block;\n    width: 49%;\n    margin-top: var(--space-half-quarter);\n    height: calc(var(--space-x2) + var(--space-half));\n}\n\n.add-phase-team-warning .modal__container{\n    border: 8px solid var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
