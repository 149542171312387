import React from 'react';
// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
// Assets
import manage_accounts from 'assets/icons/manage_accounts.svg';
// i18n
import { withTranslation } from 'react-i18next';
//stylesheet
import './TournamentValidationResponsiveModalComponent.styles.css';

function TournamentValidationResponsiveModalComponent(props) {

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className='tournament-validation-responsive-modal'
        >
            <div className='tournament-validation-responsive-modal-container'>
                <div className='tournament-validation-responsive-modal-img'>
                    <img src={manage_accounts} alt="" />
                </div>
                <div className='tournament-validation-responsive-modal-text'>
                    <p className='baloa-table-column'>
                        {props.t('validationAdminModal.textInf', {_shortcutType: props.t(`validationAdminModal.${props.shortcut_type}`)} )}
                    </p>
                    <div className={'checkbox_tournament_Form'}>
                        <input
                            className={'checkbox_tournament_Form_input'}
                            type="checkbox"
                            name={'checkbox_lock'}
                            id={'checkbox_lock'}
                            onChange={(event) => props.handleBlockModal(event.target.checked)}
                        />
                        <label className={'checkbox_tournament_Form_label'} for={'checkbox_lock'} >{props.t('validationAdminModal.noShowAgain')}</label>
                    </div>
                </div>
                <div className='tournament-validation-responsive-modal-button'>
                    <a href="https://baloa.com/" target="_blank" rel="noreferrer">
                        <PrimaryButtonComponent className={'small'}>
                            {props.t('validationAdminModal.openbutton')}
                        </PrimaryButtonComponent>
                    </a>
                </div>
            </div>
        </Modal2Component>
    );

}
export default withTranslation('page')(TournamentValidationResponsiveModalComponent);