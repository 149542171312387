// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetSearchTeamApi = ( searchedteam ) => {

    return Axios.instance.get( `${ api_url }/teams/?search=${ searchedteam }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetTeamApi = ( team_pagename ) => { //TODO: Nueva API
    
    return Axios.instance.get( `${ api_url }/teams-page/${ team_pagename }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetMemberApi = ( member_username ) => {

    return Axios.instance.get( `${ api_url }/members/${ member_username }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}



export const AddTeamToLeagueApi = ( league_page_name, team_page_name ) => {

    return Axios.instance.post( `${ api_url }/leagues/${ league_page_name }/teams/`,
        { pagename: team_page_name },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetSearchMembersApi = ( searched_member ) => {

    return Axios.instance.get( `${ api_url }/members/?search=${ searched_member }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const DeleteLeagueTeamApi = ( league_page_name, team_page_name ) => {

    return Axios.instance.delete( `${ api_url }/leagues/${ league_page_name }/teams/${ team_page_name }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


export const DeleteLeagueMemberApi = ( league_page_name, team_member ) => {

    return Axios.instance.delete( `${ api_url }/leagues/${ league_page_name }/members/${ team_member }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


export const DeleteMatchGameApi = ( match_id ) => {

    return Axios.instance.delete( `${ api_url }/tournament-matches/${ match_id }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}



export const GetSearchProfilesApi = ( searched_member ) => {

    return Axios.instance.get( `${ api_url }/profiles/?search=${ searched_member }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetLeagueMembersApi = ( leaguename ) => {
    
    return Axios.instance.get( `${ api_url }/leagues/${ leaguename }/members/?pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddMemberToLeagueApi = ( league_page_name, member_username ) => {

    return Axios.instance.post( `${ api_url }/leagues/${ league_page_name }/members/`,
        { username: member_username },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetLeagueTeamsApi = ( leaguename ) => { //TODO: Nueva API
    
    return Axios.instance.get( `${ api_url }/pages/${ leaguename }/teams/?pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetLeagueMemberDetailApi = ( username ) => {

    return Axios.instance.get( `${ api_url }/members/?search=${ username }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetPageMemberApi = ( pagename, member_username ) => {

    return Axios.instance.get( `${ api_url }/pages/${pagename}/members/?search_member=${ member_username }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetBaloaUsersApi = ( searched_member ) => {

    return Axios.instance.get( `${ api_url }/users/list_users/?username=${ searched_member }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}