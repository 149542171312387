// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-list-team-members__container{
    padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-list-team-members/LeagueListTeamMembersComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".league-list-team-members__container{\n    padding: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
