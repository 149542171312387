// React
import React from 'react';
import { Switch } from 'react-router-dom';

// Routes
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';

// Components
import HeaderUnauthenticatedComponent from 'shared/components/header-unauthenticated/HeaderUnauthenticatedComponent';

// Styles
import './GeneralUnauthenticatedLayout.styles.css';

function GeneralUnauthenticatedLayout({ routes }) {
  return (
    <React.Fragment>
      <HeaderUnauthenticatedComponent />
      <div className="unauth-view">
        <Switch>
          {routes.map(route => (
            <RouteWithSubRoutes {...route} key={route.key} />
          ))}
        </Switch>
      </div>
    </React.Fragment>
  );
}
export default GeneralUnauthenticatedLayout;