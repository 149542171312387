// Action creators
import  { loadTeamsListAction,
            loadPaymentCountryApiAction,
            loadPaymentsFormAction,
            getCountriesAction,
            getCountryStatesAction, 
            getGatewayAttributeAction,
            changeOnboardingFormValidationStateAction,
            checkPageAccountStatusAction,
            changeOnboardingFormPaymentLoadingAction,
        } from './PaymentsActionsCreators';

import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Apis
import {
    GetTeamsApi,
    GetCountryApi,
    GetCountryIdApi,
    GetPaymantesFormTemplateIdApi,
    GetCountryStatesApi,
    GetGatewayAttributeApi,
} from 'app/payments/apis/PaymentsApi'

import { GetCountriesApi } from 'app/leagues/apis/LeagueTournamentApi';

import { PlayerInscriptionApi } from 'app/tournaments/apis/TournamentApi';

import { CheckAccountStatusApi } from 'app/user-settings/apis/UserSettingsApi';

// i18next 
import i18n from 'i18next';

export const loadTeamsList = () => {

    return ( dispatch ) => {
        
        GetTeamsApi()
            .then( response => {
                dispatch( loadTeamsListAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }                
            } )      
    }
}

export const loadPaymentCountryApi = ( ) => {

    return async ( dispatch ) => {
        
        await GetCountryApi( )
            .then( response => { 
                dispatch( loadPaymentCountryApiAction( response ) );
                })                          
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPaymentCountryIdApi = ( owner_id ) => {

    return async ( dispatch ) => {
        
        await GetCountryIdApi( owner_id )
            .then( response => {      
                dispatch( loadPaymentCountryApiAction( response ) );
                })                          
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPaymentsFormTemplate = ( form_type, owner_id ) => {
    return async ( dispatch ) => {
        
        await GetPaymantesFormTemplateIdApi( form_type, owner_id )
            .then( async response => {
                await dispatch( loadPaymentsFormAction( response) );                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getCountries = (country) => {

    return async (dispatch) => {
        await GetCountriesApi(country)
            .then( response => {
                dispatch( getCountriesAction(response) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
  
}

export const getCountryStates = (country) => {
    return async (dispatch) => {
        await GetCountryStatesApi(country)
            .then( response => {
                dispatch(getCountryStatesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getGatewayAttribute = (country_gateway_id, question_type) => {
    return async (dispatch) => {
        await GetGatewayAttributeApi(country_gateway_id, question_type)
            .then( response => {
                dispatch(getGatewayAttributeAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const sendPaymentForms = (form_data) =>{
    return async (dispatch)=>{
        dispatch( changeOnboardingFormPaymentLoadingAction( true ) );
        await PlayerInscriptionApi(form_data)
        .then( response => {
            dispatch(changeOnboardingFormValidationStateAction(false));
            dispatch( changeOnboardingFormPaymentLoadingAction( false ) );
        })
        .catch(err => {
            dispatch(changeOnboardingFormValidationStateAction(true));
            dispatch( changeOnboardingFormPaymentLoadingAction( false ) );
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: err.response.data.detail,
                    error_message_is_open: true
                } ) )
            }
        })

    }
}

export const checkPageAccountStatus = (account_id) => {

    return (dispatch) => {
        CheckAccountStatusApi(account_id)
            .then( response => {
                dispatch( checkPageAccountStatusAction(response) ); 
                /* if(response.data.account_state === 'Gateway_Error'){ TODO: en caso de que se requiera implementar la correccion de datos
                    dispatch (loadFormsData(response.data.form) );
                } */
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
  
}