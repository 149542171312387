// React
import React from 'react';
//import PropTypes from 'prop-types';

// Styles
import './Card1Component.styles.css';


function Card1Component( props ) {
    return (         
        <div 
            disabled={ props.disabled }
            onClick={ props.onClick } 
            className={ 'baloa-card-1 ' + ( props.className ? props.className : '' ) }
        >
            
            <span className="baloa-card-1__title baloa-normal-medium">{ props.icon } { props.title }</span>
            <div 
                onClick={ props.onButtonClick }
                className="baloa-card-1__button"
            >
                { props.button }
            </div>
            { props.children }
        </div>
    )
}

// Types
Card1Component.propTypes = {
    // onClick: PropTypes.func.isRequired,
}
export default Card1Component;
