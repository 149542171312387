import React from 'react'
import './UserPaymentSettingComponent.styles.css';
// import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//i18
import { withTranslation } from 'react-i18next';

function UserPaymentSettingComponent(props) {
    return (
        <div className='user_payment_setting_container'>
            <div className='user_payment_setting_box'>
                <div className='user_payment_setting_box_title_option'>
                    <div className='user_payment_setting_box_title baloa-normal-medium'>{props.t('paymentTitlePaymentSetting')}</div>
                    {/* <SecondaryButtonComponent
                        className='user_payment_setting_box_button small'
                        input_type={'button'}
                        onClick={() => { }}
                    >
                        <div className='user_payment_setting_box_icon'>
                            <span class="hubicon-edit"></span>
                            <span>{props.t('paymentSettingEdit')}</span>
                        </div>
                    </SecondaryButtonComponent> */}
                </div>
                <div className='user_payment_setting_box_information'>
                    <div className='user_payment_setting_box_item-information'>
                        <div className='user_payment_setting_box_section_item'>
                            <div className='user_payments_setting_item'>
                                <div className='baloa-table-column'>{props.t('paymentSettingContactName')}</div>
                                <div className='baloa-names'>{props.accountResponse?.business_profile?.name}</div>
                            </div>
                            <div className='user_payments_setting_item'>
                                <div className='baloa-table-column'>{props.t('paymentSettingOrganizationPage')}</div>
                                <div className='baloa-names link'>{props.accountResponse?.business_profile?.url}</div>
                            </div>
                        </div>
                        <div className='user_payment_setting_box_section_item'>
                            <div className='user_payments_setting_item'>
                                <div className='baloa-table-column'>{props.t('paymentSettingEmail')}</div>
                                <div className='baloa-names link'>{props.accountResponse?.individual?.email}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {props.bank_account?
                <div className='user_payment_setting_box'>
                    <div className='user_payment_setting_box_title_option'>
                        <div className='user_payment_setting_box_title baloa-normal-medium'>{props.t('paymentSettingBankAccounts')}</div>
                    </div>
                    <div className='user_payment_setting_box_information'>
                        <div className='user_payment_setting_box_credit_card'>
                            <div className='user_payment_setting_box_item_card'>
                                <div className='user_payments_setting_item'>
                                    <div className='credit_card baloa-names'>{props.accountResponse?.external_accounts?.data[0]?.bank_name} 
                                        <span className='user_payment_setting_principal-cards'>{props.t('paymentSettingPrincipalAccount')}</span>
                                    </div>
                                    <div className='baloa-names'>{props.accountResponse?.external_accounts?.data[0]?.account_holder_name}</div>
                                    <div className='baloa-names'>••••{props.accountResponse?.external_accounts?.data[0]?.last4}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className='user_payment_setting_box'>
                    <div className='user_payment_setting_box_title_option'>
                        <div className='user_payment_setting_box_title baloa-normal-medium'>{props.t('paymentSettingCreditCard')}</div>
                    </div>
                    <div className='user_payment_setting_box_information'>
                        <div className='user_payment_setting_box_credit_card'>
                            <div className='user_payment_setting_box_item_card'>
                                <div className='user_payments_setting_item'>
                                    <div className='credit_card baloa-names'>{props.accountResponse.external_accounts?.data[0]?.brand} 
                                        <span className='user_payment_setting_principal-cards'>{props.t('paymentSettingPrincipalCard')}</span>
                                    </div>
                                    <div className='baloa-names'>{props.accountResponse.external_accounts?.data[0]?.name}</div>
                                    <div className='baloa-names'>••••{props.accountResponse.external_accounts?.data[0]?.last4}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default withTranslation('payments')(UserPaymentSettingComponent)