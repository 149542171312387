// React
import React from 'react';

// Formik
import { Formik } from 'formik'

// Components

function FormComponent( props ) {

    return (
            <Formik
                validateOnMount
                initialValues = { props.formInitialValues }
                onSubmit = { props.onSubmit }   
                enableReinitialize= {props.enableReinitialize? props.enableReinitialize : false}
                innerRef={props.innerRef}
            >
                { formikProps => {
                    if ( props.functionDisabled ) {
                        props.functionDisabled(formikProps);
                    }
                    if ( props.onFormProps ) {
                        props.onFormProps( formikProps );
                    } 
                    return(
                        <form
                            className={ 
                                'form ' + ( props.className ? props.className : '' )
                            }
                            onSubmit = { formikProps.handleSubmit } 
                            onChange = { formikProps.handleChange }
                            autocomplete = { props.autocomplete }
                            onBlur= { props.onBlur }
                            
                            onKeyDown={props.onKeyDown}
                            
                            >
                            { props.children }
                            
                        </form>
                    )
                }}
            </Formik>
    )
};
export default FormComponent;