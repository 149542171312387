import React from 'react';

//Components
import TextField from '@mui/material/TextField';

// Styles
import './InputSearchComponent.styles.css';
import { withStyles } from '@mui/styles';

function InputSearchComponent( props ) {

    const SearchTextField = withStyles({
        root: {
          '& .MuiOutlinedInput-notchedOutline':{
            borderStyle: 'none',
          },
          '& .MuiOutlinedInput-root':{
            color: 'var(--mid-gray-color)',
            padding: 'var(--space-half)',
            '& span':{
              fontSize: 'var(--space-and-half)',
              marginRight: 'var(--space-half)',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px',
            },
          },
        },
      })(TextField);
      const [searchteam, setSearchteam] = React.useState();
      const [preSearchAutofocus, setPreSearchAutofocus ] = React.useState(false);
      
      const handleChange = (event, autofocus) => {
        setPreSearchAutofocus(autofocus);//Habilita el autofocus en los primeros caracteres antes del minimo
        setSearchteam(event.target.value);
      };
    
    React.useEffect(() => {       
      if(props.cleanValue){
        setSearchteam(null);
      }  
    }, [props.cleanValue]);

    React.useEffect(() => {       
      if(props.searchword){
        setSearchteam(props.searchword);
      }  
    }, [props.searchword]);
    var regex = /.*\s$/;

    return (
        <SearchTextField
            id={props.id}
            name={props.name}
            autoFocus={(props.autoFocus === false && !preSearchAutofocus)? props.autoFocus : true} // auto focus true by default
            onChange={(event, newTeamValue) => {
                let autofocus = true;
                if(props.removeWordLenghtValidation){
                  if(!regex.test(event.target.value)){
                      props.handleSearch( event.target.value );
                      autofocus = false;
                  }
                }else{
                  if(event.target.value.length > 2 ){
                    if(!regex.test(event.target.value)){
                        props.handleSearch( event.target.value );
                        autofocus = false;
                    }                  
                  }
                }
                handleChange(event, autofocus);
            }}
            InputProps={{
                startAdornment: (
                <React.Fragment>
                    {
                      !props.removeIconSearch && <span class="icon-search"></span>
                    }
                </React.Fragment>
                ),
            }}
            className="input-search__input"
            placeholder={props.placeholder}
            variant="outlined"
            InputLabelProps={{
                shrink: true,                                    
            }}
            value={searchteam}
            disabled={props.disabled}
        />
    )
}
                
export default InputSearchComponent;

