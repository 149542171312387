import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './StepperInscriptionModalComponent.styles.css';

function StepperInscriptionModalComponent (props) {
    const {inscription_steps, currentStep}= props;
    
    return (
        <React.Fragment>
                    <div className='inscriptions_step-point__container'>
                    {inscription_steps === 2 &&
                        <>
                        <div className={ "inscriptions_step-point" }>
                            {!isMobile && <div className='baloa-username inscriptions_step-point-label-completed'>{props.t('inscriptionModal.labelsStepper.inscriptionTeam')}</div>}
                            <div className='inscriptions_point inscriptions_step-point-completed' />
                        </div>
                        <div className={ "inscriptions_step-point" }>
                        {!isMobile && <div className={`baloa-username ${currentStep === 2 ? 'inscriptions_step-point-label-completed': ''}`}>{props.t('inscriptionModal.labelsStepper.information')}</div>}
                            <div className={`inscriptions_point ${currentStep === 2 ? 'inscriptions_step-point-completed': ''}`} />
                        </div>
                        </>
                    }
                    {inscription_steps >= 3 &&
                        <>
                        <div className={ "inscriptions_step-point" }>
                        {!isMobile && <div className='baloa-username inscriptions_step-point-label-completed'>{props.t('inscriptionModal.labelsStepper.inscriptionTeam')}</div>}
                            <div className='inscriptions_point inscriptions_step-point-completed' />
                        </div>
                        <div className={ "inscriptions_step-point" }>
                        {!isMobile && <div className={`baloa-username ${currentStep >= 2 ? 'inscriptions_step-point-label-completed': ''}`}>{props.t('inscriptionModal.labelsStepper.information')}</div>}
                            <div className={`inscriptions_point ${currentStep >= 2 ? 'inscriptions_step-point-completed': ''}`} />
                        </div>
                        <div className={ "inscriptions_step-point" }>
                        {!isMobile && <div className={`baloa-username ${currentStep === 3 ? 'inscriptions_step-point-label-completed': ''}`}>{props.t('inscriptionModal.labelsStepper.payment')}</div>}
                            <div className={`inscriptions_point ${currentStep === 3 ? 'inscriptions_step-point-completed': ''}`} />
                        </div>
                        </>
                    }
                    </div>
                    <div className='inscriptions_step-line__container'>
                    {!isMobile && <div className={ `inscriptions_step-line ${ currentStep > 1? 'inscriptions_step-line-completed': ''}` } />}
                        <div className={ `inscriptions_step-line ${ currentStep >= 2? 'inscriptions_step-line-completed': ''}` } />
                        {inscription_steps === 3 && <div className={ `inscriptions_step-line ${ currentStep === 3? 'inscriptions_step-line-completed': ''}` } />}
                    </div>
                </React.Fragment>
    )
}

export default withTranslation('tournament')(StepperInscriptionModalComponent);