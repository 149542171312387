// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Styles
import './ProfileSuggestionsModalComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';


// ProfileSuggestions Component:
//   Renders Profile suggestions and handles their actions.

function ProfileSuggestionsModalComponent( props ) {

    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
            className={"profile-suggestions-modal"}
        >
                <div className="profile-suggestions-modal__container scroll-parent">                    
                    <div className="profile-suggestions-modal__list scroll">
                        {props.profiles.map( profile => (
                                <div className="profile-suggestions-modal__list__page-item">
                                    <Link to={`/profile/${profile.user.username}/`} className="profile-suggestions-modal__suggestion__profile-info">
                                        {profile.user.photo &&
                                            <img src={ profile.user.photo } alt=""/>
                                        }
                                        {!profile.user.photo &&
                                            <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                        }                            
                                        <div className="profile-suggestions-modal__list__page-item__data">
                                            <div className="profile-suggestions-modal__list__page-item__data-name baloa-names">{ profile.user.first_name } { profile.user.last_name}</div>
                                            <div className='profile-suggestions-modal__list__page-item__data-username baloa-username'>@{ profile.user.username }</div>
                                        </div>
                                    </Link>
                                    <div className="profile-suggestions-modal__suggestion__follow-button">
                                        <RoundedButtonComponent
                                            onClick = {() => { props.handleFollow( profile ) }} 
                                            className="xs"
                                        >
                                            <span>{props.t('follow')}</span>
                                        </RoundedButtonComponent>
                                    </div>
                                </div>
                            ))    
                        }                                        
                    </div>
                </div>
             
        </Modal2Component>
    )
}

// Exports
export default withTranslation('common')(ProfileSuggestionsModalComponent);
