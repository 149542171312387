// React
import React from 'react';

// Styles
import './ShowPostPollComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ShowPostPollComponent(props) {

    return( 

        <div className='show-post-poll__container'>
            {!props.is_poll_answer &&
                props.poll_answers.map( item =>(
                        <div key={item.id} onClick={ !props.is_poll_answer?  ()=>{props.handleOnClickPollAnswer(props.post_id, item.id)} : undefined  } className={"show-post-poll__answer baloa-names"}>
                            {item.caption}                                                                   
                        </div>
                ))
            }
            {props.is_poll_answer &&
                props.poll_answers.map( item =>(
                    <div key={item.id} className={"show-post-poll__answer_answered baloa-names"}>
                        <progress value={item.scorer} max="100"></progress>        
                        <div className="show-post-poll__answer_answered__caption" >
                            {item.caption}
                            {item.user_answer &&
                                <span className={"show-post-poll__answer_answered-icon icon-check-single-72"}></span>
                            }                            
                        </div>                                                           
                    </div>
                ))
            }
            <div className={"show-post-poll__counter"}>{props.poll_answers_count +props.t('post.answers')}</div>
        </div>
    )


}

export default withTranslation("post")(ShowPostPollComponent);