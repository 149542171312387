// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-column {
    display: flex;    
    justify-content: flex-start;
    align-items: center;
    
}

.player-column__image{
    display: flex;
    align-items: center;
}

.player-column__image .icon-ball {
    color: var( --white-color );
    font-size: 30px;
    border-radius: 50%;
    padding: 5px;  
}

.player-column__image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.player-column__data {
    align-items: center;
    margin-left: 8px;
}

.player-column__data .baloa-names{
    color: var(--primary-very-dark-color);
}

.player-column__data .baloa-username {
    color: var(--primary-dark-color);
}

`, "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-table/PlayerColumnComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".player-column {\n    display: flex;    \n    justify-content: flex-start;\n    align-items: center;\n    \n}\n\n.player-column__image{\n    display: flex;\n    align-items: center;\n}\n\n.player-column__image .icon-ball {\n    color: var( --white-color );\n    font-size: 30px;\n    border-radius: 50%;\n    padding: 5px;  \n}\n\n.player-column__image img {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n}\n\n.player-column__data {\n    align-items: center;\n    margin-left: 8px;\n}\n\n.player-column__data .baloa-names{\n    color: var(--primary-very-dark-color);\n}\n\n.player-column__data .baloa-username {\n    color: var(--primary-dark-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
