// React
import React from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';


// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SearchInputComponent from 'app/search/components/search-input/SearchInputComponent';
import SecondaryButtonComponent from "../secondary-button/SecondaryButtonComponent";

// Actions
import { changeSignUpLoginModal, changeClickType } from 'app/authentication/actions/LoginActionsCreators';
import { loadSearchBox } from 'app/search/actions/SearchActions';
import { changeSearchBoxStateAction, loadSearchErrorsAction, cleanSearchKeyword, changeSearchOnStateAction } from 'app/search/actions/SearchActionsCreators';
import { changeMobileMenuStateAction } from 'app/home/actions/HomeActionsCreators';

// Assets
import baloa_logo from 'assets/logos/baloa-logo.svg';

// Styles
import './HeaderUnauthenticatedComponent.styles.css';

// i18next
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

class HeaderUnauthenticatedComponent extends React.Component {
    constructor( props ){
        super( props );
        /* this.state = {
                        searchfield: "",
                        keyword: ""
                    } */
        this.handleBlur = this.handleBlur.bind(this);

    }
    state = {
        show_sbox: this.props.state.search.show_sbox? this.props.state.search.show_sbox : false,
    }
    handleModalHomeAnonView = (new_state, click_type) => {
        this.props.changeSignUpLoginModal(new_state);
        this.props.changeClickType(click_type)
    }

    handleChange = ( value ) => {
        let valueWord = value.replace('#','%23').trim();

        if( value.length > 3){
            if( valueWord !== this.props.state.search.keyword ){
                //this.props.changeSearchBoxStateAction(true, this.props.state.search.show_sbox );
            }
            this.props.loadSearchBox( valueWord, this.props.state.search.keyword );
        }else{
            //this.props.changeSearchBoxStateAction(false, this.props.state.search.show_sbox );
            this.props.cleanSearchKeyword('', this.props.state.search.keyword);
        }
    }

    onSearch = ( search ) => {
        let valueWord = search.searchfield.replace('#','%23').trim();
        this.props.history.push(`/search/search=${valueWord}`);
        this.props.changeSearchOnStateAction(false);
    }

    handleBlur = () => {
        setTimeout(() => {
            this.props.cleanSearchKeyword('', this.props.state.search.keyword);
            //this.props.changeSearchBoxStateAction(false, this.props.state.search.show_sbox ); // count is 0 here
            this.props.changeSearchBoxStateAction(false ); // count is 0 here 
          }, 500);
    }

    handleSearchLinkTo = ( section) => {
        switch (section){
            case 'players':
                this.props.history.push(`/players`);
                break;        
            case 'pages':
                this.props.history.push(`/pages`);
                break;  
            case 'tournaments':
                this.props.history.push(`/tournament/hub`);
                break;
            // no default
        }        
    }

    handleOpenBaloaLanding = () => {
        const urlToOpen = 'https://info.baloa.com/';
        window.open(urlToOpen, '_blank');
    }

    render(){
        return(
            <React.Fragment>
                { !isMobile &&
                    <div className="header_unauth">
                        <div className="header_unauth__container" >
                            <Link to="/">
                                <img className="header__image" src={baloa_logo} alt=""/>
                            </Link>

                            <div className="header_unauth__search-container">
                                {/* <span className="icon-search baloa-names" />
                                <input type="search" placeholder={this.props.t('header.search')} className="baloa-names" onClick={() => {this.handleModalHomeAnonView(true, 'homeAnonSearchBar')}} /> */}
                                <SearchInputComponent
                                    onChange={ (value) => this.handleChange(value) }
                                    onSearch = {(value) => this.onSearch(value)}
                                    onBlur={  this.handleBlur }
                                    errors = {this.props.state.search.errors.searchfield}
                                    onResetError = { ( new_errors ) => this.props.loadSearchErrorsAction( { ...this.props.state.search.errors.searchfield.searchfield, ...new_errors }, 'searchfield' ) }

                                    show_sbox= {this.props.state.search.show_sbox}
                                    results = {this.props.state.search.box_search_results.results? this.props.state.search.box_search_results.results : []}
                                    keyword = {this.props.state.search.keyword}
                                    onFocus = { ()=>{ this.props.changeSearchBoxStateAction( true ) } }
                                    handleSearchLinkTo = { (section)=>{ this.handleSearchLinkTo( section ) } }
                                    handleMobileSearch={()=>{}}
                                />
                            </div>
                        </div>
                        <SecondaryButtonComponent 
                            type="button" 
                            onClick={ () => this.handleOpenBaloaLanding() }
                            className="header-unauthenticated__baloa-landing "
                        >
                            {this.props.t('menuLabels.landingBaloa')}<span className="hubicon-open_in_new" /> 
                        </SecondaryButtonComponent>
                        <div className="header__actions">    
                            {/* Home */}
                            <Link to={'/'} className={"header__actions__home " + (this.props.location.pathname === '/' ? 'active': '')}>
                                <span className={"icon-home"} ></span>
                                <div className={"baloa-username " + (this.props.location.pathname === '/' ? 'active': '')}>{this.props.t('menuLabels.home')}</div>
                            </Link>
                            {/* Tournament Hub */}
                            <Link to={'/tournament/hub'} className={"header__actions__home " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>
                                <span className={"icon-trophy"} ></span>
                                <div className={"baloa-username " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>{this.props.t('menuLabels.tournaments')}</div>
                            </Link>
                            <span className="header__actions-icon icon-plus" onClick={() => {this.handleModalHomeAnonView(true, 'homeAnonPost')}}/>
                            <Link to="/cuenta/ingresar">
                                <PrimaryButtonComponent onClick={() => {}} className={"header_unauth__login-button"}>
                                    <span>{this.props.t('header.login')}</span>
                                </PrimaryButtonComponent>
                            </Link>
                        </div>
                    </div>
                }
                { isMobile &&
                    <div>
                        {this.props.state.search.is_search_on
                        ?
                        <div className="header-unauth__mobile__search-box">
                            {/* <span className="icon-arrow-left" onClick={() => {this.setState({is_search_on: false})}}/> */}
                            <SearchInputComponent
                                onChange={ (value) => this.handleChange(value) }
                                onSearch = {(value) => this.onSearch(value)}
                                onBlur={  this.handleBlur }
                                errors = {this.props.state.search.errors.searchfield}
                                onResetError = { ( new_errors ) => this.props.loadSearchErrorsAction( { ...this.props.state.search.errors.searchfield.searchfield, ...new_errors }, 'searchfield' ) }
                                onFocus = { ()=>{ this.props.changeSearchBoxStateAction( true ) } }
                                show_sbox= {this.props.state.search.show_sbox}
                                results = {this.props.state.search.box_search_results.results? this.props.state.search.box_search_results.results : []}
                                keyword = {this.props.state.search.keyword}
                                handleSearchLinkTo = { (section)=>{ this.props.changeSearchOnStateAction(false); this.handleSearchLinkTo( section ) } }
                                handleMobileSearch={()=>{this.props.changeSearchOnStateAction(false)}}
                            />
                        </div>
                        :                        
                        <div className="header_unauth__mobile">
                            <div className="header-unauth__mobile-menu">
                                { this.props.location.pathname === '/' && <span className="hubicon-menu" onClick={() => {this.props.changeMobileMenuStateAction(true)}}/>}
                                <span className="icon-search" onClick={() => {this.props.changeSearchOnStateAction(true)}}/>
                            </div>
                            <div className="header-unauth__mobile-actions">    
                                {/* Home */}
                                <Link to={'/'} className={"header__actions__home " + (this.props.location.pathname === '/' ? 'active': '')}>
                                    <span className={"icon-home"} ></span>
                                </Link>
                                {/* Tournament Hub */}
                                <Link to={'/tournament/hub'} className={"header__actions__home " + (this.props.location.pathname === '/tournament/hub' ? 'active': '')}>
                                    <span className={"icon-trophy"} ></span>
                                </Link>
                                <span className="header__actions-icon icon-plus" onClick={() => {this.handleModalHomeAnonView(true, 'homeAnonPost')}}/>
                                <Link to="/cuenta/ingresar">
                                    <PrimaryButtonComponent onClick={() => {}} className={"header_unauth__login-button"}>
                                        <span>{this.props.t('header.login')}</span>
                                    </PrimaryButtonComponent>
                                </Link>
                            </div>
                        </div>   
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) );
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        loadSearchBox: ( keyword, oldkeyword ) => {
            if(keyword != oldkeyword){
                return dispatch( loadSearchBox( keyword ) )
            }
        },
        /* changeSearchBoxStateAction: (new_sate, sbox_state) => {
            if(new_sate != sbox_state){
                return dispatch( changeSearchBoxStateAction(new_sate) )
            }
        }, */
        changeSearchBoxStateAction: (new_sate) => {            
            return dispatch( changeSearchBoxStateAction(new_sate) )            
        },
        cleanSearchKeyword: (new_value, keyword) => {
            if(new_value != keyword){
                return dispatch( cleanSearchKeyword(new_value) )
            }
        },
        loadSearchErrorsAction: (errors, section) => {
            return dispatch( loadSearchErrorsAction(errors, section) )
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        changeSearchOnStateAction: ( new_state ) => {
            dispatch( changeSearchOnStateAction( new_state ) );
        },
    }
}

export default compose(
    withRouter,
    withTranslation("landing"),
    connect(mapStateToProps, mapDispatchToProps)
)(HeaderUnauthenticatedComponent);