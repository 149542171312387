/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Assets
import field_colored_icon from 'assets/icons/field-icon.svg';

// Actions
import { addUserFormation } from 'app/home/actions/HomeActionsCreators';

// Functions
import getPlayerNameByPosition from 'shared/functions/GetPlayerNameByPosition';
import getPositionNameByPosition from 'shared/functions/GetPositionNameByPosition';

// Styles
import './ShowFormationFieldComponent.styles.css';

// i18next
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Positions Field Component:
// Renders positions and handles their actions.
function ShowFormationFieldComponent( props ) {
    
    /* eslint-disable */
    let [ state, setState ] = React.useState( 
        { 
            lineUp: props.new_formation && props.new_formation.lineup ? props.new_formation.lineup : '',
            primary_position_selected: props.primary_position ? props.primary_position : '',
            secondary_positions_selected: props.secondary_positions ? props.secondary_positions : [],
            formation: props.new_formation,
        } 
    )
    /* eslint-enable */
    
    let positionsMap = new Map ([
        ["4-4-2", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "med4", "del1", "del2" ]],
        ["5-3-2", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "del1", "del2" ]],
        ["4-3-3", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "del1", "del2", "del3" ]],
        ["3-4-3", ["por", "def1", "def2", "def3", "med1", "med2", "med3", "med4", "del1", "del2", "del3" ]],
        ["5-4-1", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "med4", "del1" ]]        
    ]);
    
    return (
        
        <div className="show-formation-field">
            {   state.formation != null && 
            
                <div className="show-field__container">
                    <div className="show-field__positions">
                        <div className="show-formation-field">
                            <div className="show-formation-field__row show-formation-field__row__striker"> {/**delantero */}
                                { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "del" &&
                                            <div className="show-formation-field__row__assigned" >
                                                <div
                                                    key = { position }
                                                    className="show-formation-field__row__assigned__container"
                                                >
                                                    <span className='icon-ball' color={getRandonIconBallColor()} />
                                                </div>
                                                <div className='show-formation-field__username__container'>
                                                    <span className='show-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                            </div> 
                            <div className="show-formation-field__row show-formation-field__row__midfielder"> {/**medio campo */}
                                { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "med" &&
                                            <div className="show-formation-field__row__assigned" >
                                                <div
                                                    key = { position }
                                                    className="show-formation-field__row__assigned__container"
                                                >
                                                    <span className='icon-ball' color={getRandonIconBallColor()} />
                                                </div>
                                                <div className='show-formation-field__username__container'>
                                                    <span className='show-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                    ))}
                            </div>
                            <div className="show-formation-field__row show-formation-field__row__defender"> {/**defensa */}
                                { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "def" &&
                                            <div className="show-formation-field__row__assigned" >
                                                <div
                                                    key = { position }
                                                    className="show-formation-field__row__assigned__container"                                                     
                                                >
                                                    <span className='icon-ball' color={getRandonIconBallColor()} />
                                                </div>
                                                <div className='show-formation-field__username__container'>
                                                    <span className='show-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                                </div>                                                
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                            </div> 
                            <div className="show-formation-field__row show-formation-field__row__goalkeeper"> {/**portero */}
                                {
                                    <div className="show-formation-field__row__assigned" >
                                        <div
                                            className="show-formation-field__row__assigned__container"                                                     
                                        >
                                            <span className='icon-ball' color={getRandonIconBallColor()} />
                                        </div>
                                        <div className='show-formation-field__username__container'>
                                            <span className='show-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( 'por', state.formation )}</span>
                                        </div>
                                    </div>
                                }
                            </div> 
                        </div>
                    </div>
                    { props.display === "form" && 
                        <div className="show-formation-field__container__img">
                            <img className="show-formation-field__field-img" src={ field_colored_icon } alt=""/>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

// Types
ShowFormationFieldComponent.propTypes = {
    primary_position: PropTypes.string,
    secondary_positions: PropTypes.array,
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {       
        addUserFormation: ( formation ) => {
            return dispatch( addUserFormation( formation ) );
        },
    }
}

// Exports

export default compose(
    withTranslation("newField"),
    connect(mapStateToProps, mapDispatchToProps)
)(ShowFormationFieldComponent);
