// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { UserNameValidator } from 'shared/validators/SignUpValidator';
// i18n
import { withTranslation } from 'react-i18next';
// Styles
import './PageInformationModalComponent.styles.css';

function PageInformationModalComponent(props) {

    const history = useHistory();
    //const [nextSectionModal, setNextSectionModal] = useState(false);
    //const [optionCategory, setOptionCategory] = useState('');
    //const [optionCreation, setOptionCreation] = useState(false);
    const [state, setState] = useState({ page_information_form_is_valid: undefined, upload_logo_modal: false });
    const [validationFields, setValidationFields] = useState(false);
    const [ pageLogoB64, setPageLogoB64 ] = React.useState(null);
    const [ pageLogoUrl, setPageLogoUrl ] = React.useState(null);
    const [ blockButton, setBlockButton ] = React.useState(false);
    
    const formInitialValues = {
        pagename: '',
        name: '',
        privacy: '',
        page_type: '',
        subCategory: '',
    };

    const page_type = [
        {
            icon: 'icon-ball',
            content: <div className='baloa-table'>{props.t('page:newPage.footballOrganizations.title')}</div>,
            text: props.t('page:newPage.footballOrganizations.text'),
            value: 'organizations'
        },
        {
            icon: 'icon-company',
            content: <div className='baloa-table'>{props.t('page:newPage.institutionsCorporations.title')}</div>,
            text: props.t('page:newPage.institutionsCorporations.text'),
            value: 'institutions'
        },
        {   
            icon: 'icon-pages-88',
            content: <div className='baloa-table'>{props.t('page:newPage.contenGeneration.title')}</div>,
            text: props.t('page:newPage.contenGeneration.text'),
            value: 'content'
        }
    ];

    useEffect(() => {
        if (!props.isOpen) {
            //setNextSectionModal(false);
            //setOptionCategory('');
            //setOptionCreation(false);
            setBlockButton(false);
            props.resetCategory();
            setPageLogoB64(null);
            setPageLogoUrl(null);
        }
    }, [props.isOpen]);

    const onFormProps = (form_props) => {
        if (form_props.isValid && !state.page_information_form_is_valid) {
            setState({...state, page_information_form_is_valid: true })
        }
        if (!form_props.isValid && state.page_information_form_is_valid) {
            setState({ ...state, page_information_form_is_valid: false })
        }

        if(form_props.values.name.length <= 0 || form_props.values.pagename.length <= 0 || form_props.values.subCategory.length <= 0 /* || !pageLogoB64 */){
            setValidationFields(true);
        }

        if(form_props.values.name.length > 0 && form_props.values.pagename.length > 0 && form_props.values.subCategory.length > 0 /* && pageLogoB64 */){
            setValidationFields(false);
        }
    }

    const createPage = async (new_page_data) => {
        setBlockButton(true);
        const { subCategory, name, pagename } = new_page_data;
        await props.onNewData({ is_private: false, page_type: subCategory, name, pagename, photo: pageLogoB64});
        history.push('/page/' + pagename);
        props.onClose();
    }

    const translateOptions = (arrayOptions) => {
        const newArray = [];
        arrayOptions.forEach(element => {
            newArray.push({ value: element.value, content: props.t(`page:newPage.type.${element.content}`) });
        });
        return newArray;
    };

    const handlePageLogo = (new_img, imageurl, img_b64) => {
        setPageLogoB64(`data:image/jpeg;base64,${img_b64}`);
        setPageLogoUrl(imageurl);
    }

    const handleUploadLogoModal = (new_state) => {
        setState({ ...state, upload_logo_modal: new_state });
    };


    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.page_type === 'group' ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGroupTournamentName') : (props.page_type === 'team' || props.page_type === 'company' || props.page_type === 'agency') ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelPageTournamentName') : props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCreatePageName')}
            className='page-information-modal-component'
        >
            <div className="page-information-form">
                <FormComponent
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        let new_page_data = {};
                        new_page_data = { ...values };
                        createPage(new_page_data);
                    }}
                    className="page-information-form__form form"
                    functionDisabled={(form_props) => { onFormProps(form_props) }}
                >
                    <div className="page-information-form__form__fields form__fields">
                        {/* <p className="baloa-normal-medium">{props.t('page:dataModals.infPage')}</p> */}
                        <div className='page-information-form__page-logo'>
                            <div className='baloa-username'>{props.t('modal_create_page.page_photo')}</div>
                            <div className='page-photo' onClick={() => handleUploadLogoModal(true)} >
                                { !pageLogoUrl
                                    ?
                                    <span className='icon-image-70'/>
                                    :
                                    <img src={pageLogoUrl} alt="" />
                                }
                                <div className='baloa-mini'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelUploadImg')}</div>
                            </div>
                            { /*!pageLogoUrl && <div className='input-field__message error'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}</div>*/ }                    
                        </div>

                        <LeagueTeamUploadImageModalComponent 
                            isOpen={ state.upload_logo_modal }
                            onClose={() => handleUploadLogoModal(false)}
                            setTeamImage={ (new_img, imageurl, img_b64) => handlePageLogo(new_img, imageurl, img_b64)}
                            aspect={1}
                            cropImage={true}
                        />

                        <InputFieldComponent
                            label={props.t('page:newPage.name.title')}
                            input_id="new-page-name"
                            field_name="name"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            className="page-information-form__form__input"
                            input_type="text"
                            resetError={() => props.onResetError({ 'name': "" })}
                            error={props.errors}
                            message=""
                            placeholder=""
                        />
                        <InputFieldComponent
                            label={props.t('page:newPage.name.username')}
                            input_id="new-page-pagename"
                            field_name="pagename"
                            validationFunction={UserNameValidator}
                            disabled={false}
                            className="page-information-form__form__input"
                            input_type="text"
                            resetError={() => props.onResetError({ 'pagename': "" })}
                            error={props.errors}
                            //message={ props.page_type === 'group' ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGroupTournamentAtHint') : (props.page_type === 'team' || props.page_type === 'company' || props.page_type === 'agency'  ) ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelPageTournamentAtHint') : props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTournamentAtHint')}
                            // message={ props.page_type === 'group' ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGroupTournamentAtHint') : props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelPageTournamentAtHint')}
                            placeholder="@"
                        />
                    </div>
                       
                    <div className={"page-type-form_scroll-parent"}>
                        {/* <div className={"page-type-form-form-fields-text"}>
                            <div className={"new-tournament__center__header__content__icon"}
                                onClick={() => { setNextSectionModal(false) }}>
                                <span className="icon-arrow-left"></span>
                            </div>
                            <p className={"baloa-normal-medium"}>{props.t('page:dataModals.pageType')}</p>
                        </div> */}
                        {/* <div className="page-type-form-form-fields-text_p">
                            <p className={"baloa-username"}>{props.t('page:dataModals.pageCategorySelect')}</p>
                        </div> */}
                        {/* <div className={"page-type-form__form__fields_form__fields"}>
                            <React.Fragment>
                                <div onClick={() => { props.onSelectCategory('organizations') }}
                                    className={"page-type-form__form__fields"}>
                                    <div className={"page-type-form__form__fields__icon " + (props.selectedCategory === 'organizations' ? 'active' : '')}>
                                        <span className={"icon-ball"} />
                                    </div>
                                    <div className={"page-type-form__form__fields__info"}>
                                        <p className={"baloa-names"}>{props.t('page:newPage.footballOrganizations.title')}</p>
                                        <p className={"baloa-username"}>{props.t('page:newPage.footballOrganizations.text')}</p>
                                    </div>
                                </div>
                                <div onClick={() => { props.onSelectCategory('institutions') }}
                                    className={"page-type-form__form__fields"}>
                                    <div className={"page-type-form__form__fields__icon " + (props.selectedCategory === 'institutions' ? 'active' : '')}>
                                        <span className={"icon-company"} />
                                    </div>
                                    <div className={"page-type-form__form__fields__info"}>
                                        <p className={"baloa-names"}>{props.t('page:newPage.institutionsCorporations.title')}</p>
                                        <p className={"baloa-username"}>{props.t('page:newPage.institutionsCorporations.text')}</p>
                                    </div>
                                </div>
                                <div onClick={() => { props.onSelectCategory('content') }}
                                    className={"page-type-form__form__fields"}>
                                    <div className={"page-type-form__form__fields__icon " + (props.selectedCategory === 'content' ? 'active' : '')}>
                                        <span className={"icon-pages-88"} />
                                    </div>
                                    <div className="page-type-form__form__fields__info">
                                        <p className={"baloa-names"}>{props.t('page:newPage.contenGeneration.title')}</p>
                                        <p className={"baloa-username"}>{props.t('page:newPage.contenGeneration.text')}</p>
                                    </div>
                                </div>
                            </React.Fragment> 
                        </div>*/}
                        <SelectFieldCleanComponent
                            field_name={"page_type"}
                            label={props.t('page:dataModals.pageType')}
                            className="page-information-form__form__input"
                            disabled={false}
                            resetError={() => props.onResetError({ 'page_type': "" })}
                            error={props.errors}
                            validationFunction={RequiredValidator}
                            message=""
                            placeholder={props.t('page:dataModals.pageTypeSelect')}
                            options={page_type}
                            defaultValue={''}                        
                            onChange={(page_type) => { props.onSelectCategory(page_type) }}
                        />
                        {/* <div className={"page-type-form-form-fields-text_p"}>
                            <p className={"baloa-normal-medium"}>{props.t('page:dataModals.pageCategory')}</p>
                            <p className={"baloa-username"}>{props.t('page:dataModals.infPageCategory')}</p>
                        </div> */}
                        
                        <SelectFieldComponent
                            className="page-information-form__form__input"
                            label={props.t('page:dataModals.pageCategory')}
                            field_name={"subCategory"}
                            disabled={props.selectedCategory? false : true}
                            validationFunction={RequiredValidator}
                            // className="basic-data__form__content__category__input"
                            resetError={() => props.onResetError({ 'subCategory': "" })}
                            error={props.errors}
                            message=""
                            placeholder={props.t('page:dataModals.infPageCategory')}
                            options={translateOptions(props.selectedCategoryList)}
                        />
                        
                    </div>
                    <div className="page-information-form__form__actions form__actions">
                        <PrimaryButtonComponent
                            disabled={validationFields || blockButton}
                            type={"submit"}                            
                            className="page-information-form__form__submit">
                            <span>
                                {blockButton?
                                    <div className='new-tournament__center__header__button__spinner-container'>                                                
                                        <div className="span-newtournament__disabled" />
                                    </div>
                                    :
                                    props.t('page:dataModals.createPageButton')
                                }
                                
                            </span>
                        </PrimaryButtonComponent>
                        
                    </div>
                </FormComponent>
            </div >
        </Modal2Component >
    )
}

export default withTranslation(['league', 'page'])(PageInformationModalComponent)
