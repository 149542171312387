// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-feed {
    margin: var(--space-x2) 0;
}

.profile-feed__suggestions-title {}
.profile-feed__suggestions-title-sm {
    display: none !important;
}

.profile-feed__suggestions {
    margin-top: var(--space-x2);
}

.profile-feed__feed {
    margin-top: var(--space-x2);
}

/* Small */

.profile-feed.sm .profile-feed__suggestions-title{
    font-size: var(--font-normal) !important;
    font-weight: var(--font-regular) !important;
    color: var(--basic-gray-color);
}

.profile-feed.sm .profile-feed__suggestions__sugestion{
    font-size: var(--font-normal) !important;
    font-weight: var(--font-regular) !important;
    color: var(--basic-gray-color);
}

.profile-feed.sm .profile-feed__suggestions-title-label {
    display: block;
}

@media screen and ( max-width: 1024px ) {
    
    .profile-feed__suggestions-title {
        text-align: center;
    }
    
}

@media screen and ( max-width: 576px ) {
    
    .profile-feed__suggestions-title {
        display: none;
    }
    .profile-feed__suggestions-title-sm {
        display: block !important;
        margin-bottom: var(--space-half);
    }

}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
 
    .profile-feed {
        margin: var(--space) 0;
    }
    .feed__post {
        margin-bottom: var(--space);
    }
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-feed/ProfileFeedComponent.styles.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;AAC7B;;AAEA,kCAAkC;AAClC;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA,UAAU;;AAEV;IACI,wCAAwC;IACxC,2CAA2C;IAC3C,8BAA8B;AAClC;;AAEA;IACI,wCAAwC;IACxC,2CAA2C;IAC3C,8BAA8B;AAClC;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;QACI,kBAAkB;IACtB;;AAEJ;;AAEA;;IAEI;QACI,aAAa;IACjB;IACA;QACI,yBAAyB;QACzB,gCAAgC;IACpC;;AAEJ;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,sBAAsB;IAC1B;IACA;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":["\n.profile-feed {\n    margin: var(--space-x2) 0;\n}\n\n.profile-feed__suggestions-title {}\n.profile-feed__suggestions-title-sm {\n    display: none !important;\n}\n\n.profile-feed__suggestions {\n    margin-top: var(--space-x2);\n}\n\n.profile-feed__feed {\n    margin-top: var(--space-x2);\n}\n\n/* Small */\n\n.profile-feed.sm .profile-feed__suggestions-title{\n    font-size: var(--font-normal) !important;\n    font-weight: var(--font-regular) !important;\n    color: var(--basic-gray-color);\n}\n\n.profile-feed.sm .profile-feed__suggestions__sugestion{\n    font-size: var(--font-normal) !important;\n    font-weight: var(--font-regular) !important;\n    color: var(--basic-gray-color);\n}\n\n.profile-feed.sm .profile-feed__suggestions-title-label {\n    display: block;\n}\n\n@media screen and ( max-width: 1024px ) {\n    \n    .profile-feed__suggestions-title {\n        text-align: center;\n    }\n    \n}\n\n@media screen and ( max-width: 576px ) {\n    \n    .profile-feed__suggestions-title {\n        display: none;\n    }\n    .profile-feed__suggestions-title-sm {\n        display: block !important;\n        margin-bottom: var(--space-half);\n    }\n\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n \n    .profile-feed {\n        margin: var(--space) 0;\n    }\n    .feed__post {\n        margin-bottom: var(--space);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
