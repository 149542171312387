import React from "react";

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import "./ShareInscriptionLinkModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function ShareInscriptionLinkModalComponent(props) {
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                props.type_inscription_link_modal === 'sharePageLink'
                    ? props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalTitleInvitedTeam')
                    : props.type_inscription_link_modal === 'shareTournamentLink'
                        ? props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalTitleInvitedPlayers')
                        : props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalTitle')
            }
            className='share-inscription-link-modal__container'
        >
            <div className={"share-inscription-link-modal__content"}>
                {props.type_inscription_link_modal === 'sharePageLink' &&
                    <React.Fragment>
                        <div className="share-inscription-link-modal__content_icon">
                            <span className='hubicon-team' />
                        </div>
                        <div className="share-inscription-link-modal__description baloa-table-column">
                            <span className='icon-player' />
                            {props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalTextInvitedTeam')}
                        </div>
                        <div className="share-inscription-link-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                            <span className="baloa-table">{props.share_url}</span>
                            <span className="icon-copy-86"></span>
                        </div>
                    </React.Fragment>
                }
                {props.type_inscription_link_modal === 'shareTournamentLink' &&
                    <React.Fragment>
                        <div className="share-inscription-link-modal__content_icon">
                            <span className='hubicon-players' />
                        </div>
                        <div className="share-inscription-link-modal__description baloa-table-column">
                            <span className='icon-player' />
                            {props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalTextInvitedPlayers')}
                        </div>
                        <div className="share-inscription-link-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                            <span className="baloa-table">{props.share_url}</span>
                            <span className="icon-copy-86"></span>
                        </div>
                    </React.Fragment>
                }
                {!props.type_inscription_link_modal &&
                    <React.Fragment>
                        <div className="share-inscription-link-modal__description baloa-table-column">
                            <span className='icon-player' />
                            {props.t('viewTournament.inscription.shareInscriptoionLinkModal.modalText')}
                        </div>
                        <div className="share-inscription-link-modal__link" onClick={() => { props.handleOnCopyShareLink() }}>
                            <span className="baloa-table">{props.share_url}</span>
                            <span className="icon-copy-86"></span>
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className="share-inscription-link-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='share-inscription-link-modal__buttons-button'
                    onClick={props.onClose}
                >
                    <span>{props.t('viewTournament.inscription.shareInscriptoionLinkModal.acceptButton')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('tournament')(ShareInscriptionLinkModalComponent);