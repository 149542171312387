// React
import React, { useState, useEffect } from 'react';

// Components
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentEndMatchStepsComponent from '../tournament-end-match-steps/TournamentEndMatchStepsComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import TextField from '@mui/material/TextField';

// Styles
import './TournamentEndMatchCommentComponent.styles.css';
import { withStyles } from '@mui/styles';

//i18
import { withTranslation } from 'react-i18next';

// Formik
import { Field } from "formik";

function TournamentEndMatchCommentComponent(props) {

    const [selectedPerson, setSelectedPerson] = useState(null);
    const [allPlayers, setAllPlayers] = useState([]);
    const formRef = React.useRef();
    const formInitialValues = {
        comment: props.endGameState.comments ? props.endGameState.comments : '',
        revelationPlayer: '',
    }

    const CommentAreaTextField = withStyles({
        root: {
            // '& label.Mui-focused': {
            //     color: '#2a302a',
            // },          
            // '& .MuiOutlinedInput-root': {
            //     '& fieldset': {             
            //         border: 'var(--input-field-border)',
            //     },      
            // },
        },
    })(TextField);

    useEffect(()=>{
        setAllPlayers(props.local_team_members?.concat(props.visitor_team_members));
    },[props.local_team_members, props.visitor_team_members]);

    useEffect(()=>{
        if(props.playerMatch && allPlayers.length > 0){
            setSelectedPerson(allPlayers.find(player => player.username == props.playerMatch));
        }
    },[props.playerMatch, allPlayers]);

    const CommentTextAreaField = () => {
        return (
            <Field name="comment">
                {({ field }) => {
                    return (
                        <CommentAreaTextField
                            id="comment"
                            hiddenLabel
                            multiline
                            minRows={4}
                            maxRows={8}
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelCommentsMatch')}
                            //InputLabelProps={{ shrink: true }}
                            variant="standard"
                            className="end-match-comment__form__element__input"
                            value={field.value}
                        />
                    );
                }}
            </Field>
        )
    }

    return (

        <div className="end-match-comment scroll-parent">
            <FormComponent
                formInitialValues={formInitialValues}
                onSubmit={(values, actions) => {
                    props.stepCompleted(0);
                    //props.handleCloseMatchModal( values );
                    const dataComments= {
                        profile: values.revelationPlayer?.username ?? selectedPerson?.username,
                        comment: values.comment
                    }
                    props.onNewData(dataComments);

                }}
                className="end-match-comment__form form scroll-parent"
                functionDisabled={() => { }}
                innerRef={formRef}
            >
                <div className="end-match-comment__form__element scroll">
                    <TournamentEndMatchStepsComponent
                        step={props.step}
                        stepCompleted={(i) => props.stepCompleted(i)}
                        endGameStepState={props.endGameStepState}
                    />
                    <div className='end-match-comment_form_elements-container'>
                        <div className='end-match-comment_form_elements-container_select'>
                            <div className='end-match-comment_label-area baloa-table'>
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelPlayerMatch')}
                            </div>
                            <div className='end-match-comment_form_elements-container_select_revelationPlayer'>
                                <InputFieldAutocompleteComponent
                                    name={'revelationPlayer'}
                                    options={allPlayers ?? []}
                                    noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                                    optionLabelField={"revelationPlayer"}
                                    optionSelectedField={"player_id"}
                                    className="revelationPlayer_input"
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelSelectedPlayerMatch')}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelSelectedPlayer')}
                                    onChange={(selectedReferee) => setSelectedPerson(selectedReferee)}
                                    formRef={formRef}
                                    value={selectedPerson ? selectedPerson : null}
                                    id="revelationPlayer_autocomplete_match_modal"
                                />
                            </div>
                        </div>
                        <div className='end-match-comment_form_elements-container_text-area'>
                            <div className='end-match-comment_textarea-label-area baloa-table'>
                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelComments')}
                            </div>
                            <CommentTextAreaField />
                        </div>
                    </div>
                </div>
                <div className="end-match-comment__form__actions form__actions">
                <SecondaryButtonComponent 
                    disabled={false} 
                    onClick={()=>{props.stepCompleted(4);}} 
                    className="league-match-scorer-form__actions__back-btn"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonBack')}</span>
                </SecondaryButtonComponent>
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false}
                        onClick={() => { }}
                        className="end-match-comment__form__submit"
                    >
                        <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabComments.labelButtonEndMatch')}</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        </div>
    )

}

export default withTranslation('league')(TournamentEndMatchCommentComponent);