/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import CurrencyFormat from 'react-currency-format';

// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';

//Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Validators
import { RequiredValidator, MinNumberValidation, MaxNumberValidation } from 'shared/validators/RequiredValidator';

// Styles
import './TournamentInscriptionComponent.styles.css';
import { styled } from "@mui/styles";

//i18
import { withTranslation } from 'react-i18next';

// Validators
//import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

function TournamentInscriptionComponent ( props ){

    const [invitation, setInvitation] = React.useState('');
    const [registration, setRegistration] = React.useState('');
    // const [payType, setPayType] = React.useState('');
    const [unlimited ,setUnlimited] = React.useState('');
    const [prize ,setPrize] = React.useState('');
    const [materialPrize, setMaterialPrize] = React.useState(null);
    const [monetaryPrize, setMonetaryPrize] = React.useState(null);
    //const [participantTeams, setParticipantTeams] = React.useState(3);
    // const [playersByTeams, setPlayersByTeams] = React.useState(8);
    const [changeDate, setChangeDate] = React.useState('');

    const [minPlayersNum, setMinPlayersNum] = React.useState(null);
    const [maxPlayersNum, setMaxPlayersNum] = React.useState(null);
    const [teamForm, setTeamForm] = React.useState(''); //eslint-disable-line
    const [playerForm, setPlayerForm] = React.useState(''); //eslint-disable-line
    const [formats, setFormats] = React.useState(props.payment_option ?? []);
    const [teamBox, setTeamBox] = React.useState(false);
    const [teamBox2, setTeamBox2] = React.useState(false);
    const [playerBox, setPlayerBox] = React.useState(false);
    const [playerBox2, setPlayerBox2] = React.useState(false);

    const STRIPE_IATA_CODE_COUNTRIES = ["US", "MX", "AR", "EC", "CA", "PE", "CL", "PY", "UY", "CR", "BO", "GT", "PA"]

    const PAYMENT_OPTIONS = [
        { value: 'Paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTeamPlayerPayment')},
        { value: 'Team_paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelOnlyTeamPayment')},
        { value: 'Player_paid', content: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelOnlyPlayerPayment')},
    ];

    const [state, setState] =  React.useState(
        {
            showMaxTeams: false,
            showMaxPlayers: false,
            processFee: 'Tournament'
        }
    )

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const checkInvitation = (event) =>{
        setInvitation(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'summon_registration_type');
    }
    const checkRegistration = (event) =>{
        setRegistration(event.target.value);
        //props.handleRegistrationType(event.target.value); //TODO: validar si esto se usaba para algo BAL-932
        props.handleFormCheckBtns(event.target.value, 'registration_type');
        if(STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && event.target.value === 'Paid_No_Account') {
            props.handleCheckPaymentAccount(props.country_iata_code === 'US'? 'need_account' : 'info_baloa_account');
        }
        
        if(event.target.value === 'Free') {
            props.handlePaymentOption('Free');
            props.handleInscriptionTypeProcessRate('');
            props.handleRegistrationType('', 'registration_price_per_team');
            props.handleRegistrationType('', 'registration_price_per_player');
            setFormats('');
        };
    }

    //TODO: load on new forms name after form is made
    /* const checkPlayerForm = (event) => {
        setPlayerForm(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'is_player_form');
    } */

    //TODO: load on new forms name after form is made
    /* const checkTeamForm = (event) =>{
        setTeamForm(event.target.value);
        props.handleFormCheckBtns(event.target.value, 'is_team_form');
    } */
    // const checkPayType = (event) =>{
    //     setPayType(event.target.value);
    // }
    const checkUnlimited = (event) =>{
        if(event.target.value == "unlimited"){
            //setMaxPlayersNum(undefined);
            setUnlimited(event.target.value);
            props.handleUnlimited(event.target.value);            
        }       
    }

    const checkPrize = (event) =>{
        handleMonetaryPrize(null);
        props.handleFormCheckBtns(event.target.value, 'tournament_prize_type');
        setPrize(event.target.value);
        if(event.target.value == 'Material_Prize' ){
            setMonetaryPrize(null);
            props.handlePrizeType('Material_Prize');
        }else if(event.target.value == 'Monetary_Prize'){
            setMaterialPrize(null);
            props.handlePrizeType('Monetary_Prize');
        }else{
            setMonetaryPrize(null);
            setMaterialPrize(null);
            props.handlePrizeType('No_Prize');
        }
    }

    const cleanUnlimited = () =>{
        setUnlimited(''); 
        props.handleUnlimited('');      
    }

    const handleParticipantTeams = (value, min_max) => {
        /* if(min_max === "min_teams") {
            setParticipantTeams(value);
        } */
        props.handleParticipantTeams(value, min_max);
    }

    
    const handleMaxPlayers = (value) => {        
        
        //setPlayersByTeams(value + 1);
        //if(value > minPlayersNum){
            setMaxPlayersNum(value);
            props.handlePlayersByTeams(value, "max_players");
        /* }else{
            setMaxPlayersNum(minPlayersNum);
            props.handlePlayersByTeams(minPlayersNum, "max_players");
        }   */       
    }

    const handleMinPlayers = (value) => {
        //if(value > minPlayersNum){
            setMinPlayersNum(value);
            props.handlePlayersByTeams(value, "min_players");
        //}
    }

    function validateTrue() {
        let error;
        return error;
    }

    const handleChangeDate = (date) => {
        setChangeDate(date);
    }

    const handleFormatPayment = (event, newFormats) => {
        setFormats(newFormats);
        if(newFormats === 'Player_paid'){
            props.handleRegistrationType('', 'registration_price_per_team')
        }
        if(newFormats === 'Team_paid'){
            props.handleRegistrationType('', 'registration_price_per_player')
        }
        if(newFormats === 'Free') setFormats('');

        props.handlePaymentOption(newFormats)
    };

    const handleMonetaryPrize = (value) =>{
        setMonetaryPrize(value);
        props.handleMonetaryPrize(value);
    }
    /* React.useEffect(()=>{
        if(minNum > 0 && minNum <= 18) {
            setMaxNum(minNum + 1);
        };
        props.handlePlayersByTeams(minNum, "min_players");
    },[minNum]); // eslint-disable-line */

    //const validationNumber= (value)=> value <= 7 ? setMinNum(7) : setMinNum(value);

    
    /* 
    TODO: No existe aun epica para estos cambios    
    const changeMaxTeams=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }

    const changeMaxPlayers=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    } */

    const checkProcessFee=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
        props.handleInscriptionTypeProcessRate(event.target.value);
    }

    const handleTeamBoxOpen=()=> {
        setTeamBox(!teamBox)
    }

    const handleTeamBox2Open=()=> {
        setTeamBox2(!teamBox2)
    }

    const handlePlayerBoxOpen=()=> {
        setPlayerBox(!playerBox)
    }

    const handlePlayerBox2Open=()=> {
        setPlayerBox2(!playerBox2)
    }

    return(
        <div className="tournament-inscription__container">
            
            <div className="tournament-inscription__title baloa-subtitle-2">
                {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelTitle')}
            </div>        
            <div className="tournament-inscription__content">
                <FormControl component="fieldset" className={"tournament-inscription__content__half-buttons"}>                        
                    <RadioGroup row aria-labelledby="tournament_summon_registration" name="tournament_summon_registration" defaultValue="top" className="tournament-inscription__content__option-button" error={props.inscription_errors}>
                        <FormControlLabel
                            value="Closed"
                            control={ props.summonRegistrationType === "Closed"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="icon-email"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.byClosedLbl')}</div>
                                        <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.byClosedCaption')}</div>
                                    </div>
                                </div>
                            }
                            labelPlacement="start"
                            onChange={ checkInvitation }
                            className={invitation == "Closed"? 'selected':''}
                        />
                        <FormControlLabel
                            value="Open"
                            control={ props.summonRegistrationType === "Open"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="icon-group-friends"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.openLbl')}</div>
                                        <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.openCaption')}</div>
                                    </div>
                                </div>
                            }
                            labelPlacement="start"
                            onChange={ checkInvitation }
                            className={invitation == "Open"? 'selected':''}
                        />                            
                    </RadioGroup>                        
                </FormControl>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.deadlineLbl')}</div>
                <InputFieldComponent 
                    input_id="registration_deadline" 
                    field_name="registration_deadline"
                    validationFunction={ DateFormatValidator }
                    disabled={false}
                    className=""
                    input_type="date"
                    resetError={()=>{}}
                    error={ props.inscription_errors }
                    message=""
                    placeholder=""
                    onChange={ handleChangeDate }
                    value={ changeDate }
                />
                {/** Start disabled */}
                <FormControl component="fieldset" className={"tournament-inscription__content__half-buttons"}>                        
                    <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.registrationTypeLbl')}</div>
                    <RadioGroup row aria-labelledby="tournament_registration_type" name="tournament_registration_type" defaultValue="top" className="tournament-inscription__content__option-button" error={props.inscription_errors}>
                        <FormControlLabel
                            value="Free"
                            label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="hubicon-money_off"></span>
                                <div className={'tournament-inscription__content__option-button-label__text'}>
                                    <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.freeTypeLbl')}
                                    </div>
                                    <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelSubTextFreeInscription')}
                                    </div>
                                </div>
                            </div>}
                            labelPlacement="start"
                            onChange={ checkRegistration }
                            className={registration == "Free"? 'selected':''}
                            control={ props.registrationType === "Free"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                        /> 
                        { !STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && //El país no esta habilitado para pago
                            <FormControlLabel
                                value="Paid"
                                label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="hubicon-paid"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLbl') + ((!STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && props.country_iata_code !== 'CO')?  props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLblNotUsa'): '')}
                                        </div>
                                        <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelSubTextPaidInscription')}
                                        </div>
                                    </div>
                                </div>}
                                labelPlacement="start"
                                onChange={ checkRegistration }
                                className={registration == "Paid"? 'selected':''}
                                control={ props.registrationType === "Paid"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                                disabled={(!STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && props.country_iata_code !== 'CO')? true: false}
                            />
                        }
                        { !props.create_stripe_account && STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && //La pagina no tiene cuenta y el pais esta habilitado                           
                            <FormControlLabel
                                value="Paid_No_Account"
                                label={
                                    <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="hubicon-paid"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLbl') + ((!STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && props.country_iata_code !== 'CO')? props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLblNotUsa'): '')}
                                        </div>
                                        <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelSubTextPaidInscription')}
                                        </div>
                                    </div>
                                </div>}
                                labelPlacement="start"
                                onChange={checkRegistration}
                                /* className=''
                                control={ <Radio color="secondary" checked={false} />} */
                                className={registration == "Paid_No_Account"? 'selected':''}
                                control={ (props.registrationType === "Paid_No_Account"/*  && props.create_stripe_account */)? <Radio color="secondary" checked={true} />: <Radio color="secondary" checked={false} required={true} />}
                            />
                        }
                        { props.create_stripe_account && STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && //La pagina tiene cuenta (USA) y el país esta habilitado
                            <FormControlLabel
                                value="Paid"
                                label={
                                
                                    <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="hubicon-paid"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.paidTypeLbl')}
                                        </div>
                                        <div className={'tournament-inscription__content__option-button-label__caption baloa-username'}>
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelSubTextPaidInscription')}
                                        </div>
                                    </div>
                                </div>}
                                labelPlacement="start"
                                onChange={ checkRegistration }
                                className={registration == "Paid"? 'selected':''}
                                control={ (props.registrationType === "Paid" && props.create_stripe_account)? <Radio color="secondary" checked={true} />: <Radio color="secondary" checked={false} required={true} />}
                            />
                        }                           
                    </RadioGroup>
                </FormControl>
                <div className={`tournament-inscription__content__button-group ${(props.registrationType !== 'Paid' && props.registrationType !== 'Paid_No_Account')? 'hidden':''}`}>
                    {(props.registrationType === 'Paid' || props.registrationType === 'Paid_No_Account') && STRIPE_IATA_CODE_COUNTRIES.includes(props.country_iata_code) && /* props.create_stripe_account && */
                        <React.Fragment>
                            <FormControl component="fieldset" className={"tournament-inscription__content__process-fee"}>                        
                                <div className={"baloa-names"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.processFeeLabel')}</div>
                                <RadioGroup row aria-labelledby="tournament_processing_rate_type" name="tournament_processing_rate_type" defaultValue="Tournament" className="tournament-inscription__content__option-button__list">
                                    <FormControlLabel
                                        value="Tournament_Pays_Processing"
                                        label={
                                            <React.Fragment>
                                                 <div className="baloa-table-column">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.processFeeTournamentLbl')}</div>
                                                 <div className="baloa-username"
                                                    dangerouslySetInnerHTML={{
                                                        __html: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.processFeeTournamentText')
                                                    }}></div>    
                                            </React.Fragment>
                                        }
                                        labelPlacement="end"
                                        onChange={ checkProcessFee}
                                        className={state.processFee == "Tournament"? 'selected':''}
                                        control={ props.inscription_type_process_rate === "Tournament_Pays_Processing"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                                    /> 
                                    <FormControlLabel
                                        value="User_Pays_Processing"
                                        label={
                                            <React.Fragment>
                                                 <div className="baloa-table-column">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.processFeePlayerLbl')}</div>
                                                 <div className="baloa-username"
                                                    dangerouslySetInnerHTML={{
                                                        __html: props.t('options.tournaments.pages.newTournament.tabs.tabInscription.processFeePlayerText')
                                                    }}></div>    
                                            </React.Fragment>
                                        }
                                        labelPlacement="end"
                                        onChange={ checkProcessFee }
                                        className={state.processFee == "Player"? 'selected':''}
                                        control={ props.inscription_type_process_rate === "User_Pays_Processing"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                                    />                           
                                </RadioGroup>
                            </FormControl>
                        </React.Fragment>
                    }
                    <p className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelWhoPayment')}</p>
                    <StyledToggleButtonGroup
                        color="primary"
                        value={formats}
                        onChange={handleFormatPayment}
                        exclusive
                    >
                        {PAYMENT_OPTIONS.map((payment_opt, index) => (
                            <ToggleButton key={index} value={payment_opt.value}>
                                {payment_opt.content}
                            </ToggleButton>
                        ))}
                    </StyledToggleButtonGroup>
                </div>      

                {/** End disabled */}
                
                {/** Start disabled */}
                {/* <FormControl component="fieldset" disabled={registration !== 'Paid'} className={"tournament-inscription__content__half-buttons " + (registration !== 'Paid'? 'hidden':'') }>                        
                    <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.inscriptionPaymentLbl')}</div>
                    <RadioGroup 
                        row 
                        aria-labelledby="tournament_registration_pay" 
                        name="tournament_registration_pay" 
                        defaultValue="top" 
                        className="tournament-inscription__content__option-button"
                        
                        //disabled={registration !== 'Paid'}
                        //className={ prize !== 'Material_Prize'? 'hidden':'' }
                    >
                        <FormControlLabel
                            value="Players_And_Teams"
                            control={<Radio color="secondary" />}
                            label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="icon-email"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.players&teamsLbl')}</div>
                                        <div className={'tournament-inscription__content__option-button-label__caption'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.players&teamsCaption')}</div>
                                    </div>
                                </div>
                            }
                            labelPlacement="start"
                            onChange={ checkPayType }
                            className={payType == "Players_And_Teams"? 'selected':''}
                        />
                        <FormControlLabel
                            value="Separately_Players_And_Teams"
                            control={<Radio color="secondary" />}
                            label={
                                <div className={'tournament-inscription__content__option-button-label'}>
                                    <span className="icon-team"></span>
                                    <div className={'tournament-inscription__content__option-button-label__text'}>
                                        <div className={'tournament-inscription__content__option-button-label__text-title baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.players&teamsSepLbl')}</div>
                                        <div className={'tournament-inscription__content__option-button-label__caption'}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.players&teamsSepCaption')}</div>
                                    </div>
                                </div>
                            }
                            labelPlacement="start"
                            onChange={ checkPayType }
                            className={payType == "Separately_Players_And_Teams"? 'selected':''}
                        />                            
                    </RadioGroup>
                </FormControl> */}
                {/** End disabled */}
                
                <div className={"tournament-inscription__content__double-input " + ((props.registrationType !== 'Paid' && props.registrationType !== 'Paid_No_Account')? 'hidden':'')}>
                    {(formats === 'Team_paid' || formats === 'Paid') &&
                        <div className={"tournament-inscription__content__group-input"}>
                            <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamInscriptionValueLbl')}</div>
                            <div className="tournament-inscription__content_registration">
                                <CurrencyFormat
                                    className="tournament-inscription__form-control"
                                    value={props.registrationTypePerTeam!== ''? props.registrationTypePerTeam: ''} 
                                    displayType={'input'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} 
                                    suffix={' ' + props.currency}
                                    placeholder="$"
                                    onValueChange ={ (value) => props.handleRegistrationType(value.value, 'registration_price_per_team')}
                                    renderText={value => <div>{value}</div>}
                                />
                                <div className='input-field__message error'>{props.registrationTypePerTeam === '' ? props.t('page:newPage.validators.invalidValue'):''}</div>
                            </div>
                            {/* <InputFieldComponent 
                                input_id="registration_price_per_team" 
                                field_name="registration_price_per_team"
                                validationFunction={ props.registrationType !== 'Paid'? validateTrue(): MoneyValidator }
                                disabled={props.registrationType !== 'Paid'} 
                                className="tournament-inscription__content_registration"
                                input_type="text"
                                resetError={()=>{}}
                                error={ props.inscription_errors }
                                // message=""
                                placeholder="$"
                                onChange={ (value) => {props.handleRegistrationType(value, 'registration_price_per_team')}}
                                value={ props.registrationTypePerTeam !== ''? props.registrationTypePerTeam: '' }
                            /> */}
                        </div>
                    }
                    {(formats === 'Player_paid' || formats === 'Paid') &&
                        <div className={"tournament-inscription__content__group-input"}>
                            <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.playerInscriptionValueLbl')}</div>
                            <div className="tournament-inscription__content_registration">
                                <CurrencyFormat 
                                    className="tournament-inscription__form-control"
                                    value={ props.registrationTypePerPlayer !== ''? props.registrationTypePerPlayer: ''} 
                                    displayType={'input'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} 
                                    suffix={' ' + props.currency}
                                    placeholder="$"
                                    onValueChange ={ (value) => {props.handleRegistrationType(value.value, 'registration_price_per_player')}}
                                    renderText={value => <div>{value}</div>}
                                />
                            </div>
                            <div className='input-field__message error'>{props.registrationTypePerPlayer === '' ? props.t('page:newPage.validators.invalidValue'):''}</div>
                            {/* <InputFieldComponent 
                                input_id="registration_price_per_player" 
                                field_name="registration_price_per_player"
                                validationFunction={ props.registrationType !== 'Paid'? validateTrue(): MoneyValidator }
                                disabled={props.registrationType !== 'Paid'} 
                                className="tournament-inscription__content_registration"
                                input_type="text"
                                resetError={()=>{}}
                                error={ props.inscription_errors }
                                message=""
                                placeholder="$"
                                onChange={ (value) => {props.handleRegistrationType(value,'registration_price_per_player')} }
                                value={ props.registrationTypePerPlayer !== ''? props.registrationTypePerPlayer: '' }
                            />                 */}
                    </div>
                    }
                </div>

                {/* <div className={"baloa-username " +(props.registrationType !== 'Paid'? 'hidden':'')}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.bankAccountLbl')}</div>
                <InputFieldComponent 
                    input_id="bank_account" 
                    field_name="bank_account"
                    // validationFunction={ props.registrationType !== 'Paid'? validateTrue(): RequiredValidator }
                    disabled={props.registrationType !== 'Paid'} 
                    className={props.registrationType !== 'Paid'? 'hidden':''}
                    input_type="text"
                    resetError={()=>{}}
                    error={ props.inscription_errors }
                    message=""
                    placeholder=""
                    onChange={ (value) => {props.handleRegistrationType(value, 'bank_account')}}
                    value={ props.bankAccount !== ''? props.bankAccount: '' }

                /> */}
                
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamsNumberLbl')}</div>
                {/* <div className={"tournament-inscription__content__double-input " }> TODO: No existe aun epica para estos cambios
                    <FormControlLabel
                        value="showMaxTeams"
                        control={<Switch
                            checked={ state.showMaxTeams }
                            onChange={ changeMaxTeams }
                            name="showMaxTeams"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />}
                        label="Limitar número de equipos...."
                        labelPlacement="start"
                    />
                    
                    <FormControlLabel
                        value="showMaxPlayers"
                        control={<Switch
                            checked={ state.showMaxPlayers }
                            onChange={ changeMaxPlayers }
                            name="showMaxPlayers"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />}
                        label="Limitar número de jugadores por equipo...."
                        labelPlacement="start"
                    />
                </div> */}
                <div className={"tournament-inscription__content__double-input one-column"}>
                    <InputFieldComponent 
                        input_id="min_participant_teams" 
                        field_name="min_participant_teams"
                        validationFunction={ ()=>MinNumberValidation(props.minTeams,2,props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.labelTitle')) }
                        disabled={false}
                        className=""
                        input_type="text"
                        resetError={()=>{}}
                        error={ props.inscription_errors }
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.minTeamsNumberLbl')}
                        //min={2}
                        onChange={ (value) => handleParticipantTeams(value, 'min_teams') }
                        value={props.minTeams? props.minTeams: null }
                    />
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                    <InputFieldComponent 
                        input_id="max_participant_teams" 
                        field_name="max_participant_teams"
                        validationFunction={ ()=>MaxNumberValidation(props.minTeams,props.maxTeams) }
                        disabled={false}
                        className=""
                        input_type="text"
                        resetError={()=>{}}
                        error={ props.inscription_errors }
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.maxTeamsNumberLbl')}
                        //min={participantTeams}
                        onChange={ (value) => handleParticipantTeams(value, 'max_teams') }
                        value={props.maxTeams? props.maxTeams: null}
                    />                       
                </div>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.playersNumberLbl')}</div>
                <div className={"tournament-inscription__content__double-input one-column"}>
                    <InputFieldComponent 
                        input_id="min_participant_players" 
                        field_name="min_participant_players"
                        validationFunction={ ()=>MinNumberValidation(minPlayersNum,props.footballTypeMin,props.t('tournament:viewTournament.inscription.playersLbl')) }
                        //validationFunction={ RequiredValidator }
                        disabled={false}
                        className=""
                        input_type="text"
                        //min={5}
                        //max={18}
                        resetError={()=>{}}
                        error={ props.inscription_errors }
                        //onChange={ (value)=>{ cleanUnlimited(value) } }
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.minPlayersNumberLbl')}
                        onChange={ (value) => handleMinPlayers(value) }
                        value={minPlayersNum}
                    />
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                    <InputFieldComponent 
                        input_id="max_participant_players" 
                        field_name="max_participant_players"
                        validationFunction={ ()=>MaxNumberValidation(minPlayersNum,maxPlayersNum,unlimited) }
                        disabled={false}
                        className={unlimited === 'unlimited'? "disabledMax":''}
                        input_type="text"
                        //min={minPlayersNum}
                        resetError={()=>{}}
                        error={ props.inscription_errors }
                        message=""
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabInscription.maxPlayersNumberLbl')}
                        //max={19}
                        onChange={ (value) => handleMaxPlayers(value) }
                        onClick={ ()=>{cleanUnlimited()}}
                        value={maxPlayersNum || "" }                        
                    />
                    <FormControl component="fieldset" className={"tournament-inscription__content__one-button"}>                        
                        <RadioGroup row aria-labelledby="unlimited_participant_players" name="unlimited_participant_players" value={props.isUnlimited}  onChange={ (e)=>{checkUnlimited(e) } } className="tournament-inscription__content__option-button inside">
                            <FormControlLabel
                                value="unlimited"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.unlimitedPlayersNumberLbl')}</span>}
                                labelPlacement="end"                                
                                className={props.isUnlimited? 'selected':''}                           
                            />                                                          
                        </RadioGroup>
                    </FormControl>                  
                </div>
                <div className={"baloa-username"}>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.prizeTypeLbl')}</div>
                <FormControl component="fieldset" className={"tournament-inscription__content__tree-button"}>                        
                        <RadioGroup row aria-labelledby="tournament_prize_type" name="tournament_prize_type" defaultValue="top" className="tournament-inscription__content__option-button">
                            <FormControlLabel
                                value="Monetary_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.monetaryPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={ checkPrize }
                                className={prize == 'Monetary_Prize'? 'selected':''}
                                control={ props.tournamentPrizeType === "Monetary_Prize"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="Material_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.materialPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={ checkPrize }
                                className={prize == 'Material_Prize'? 'selected':''}
                                control={ props.tournamentPrizeType === "Material_Prize"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="No_Prize"
                                label={<span className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.noPrizeLbl')}</span>}
                                labelPlacement="end"
                                onChange={ checkPrize }
                                className={prize == 'No_Prize'? 'selected':''}
                                control={ props.tournamentPrizeType === "No_Prize"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />                                                          
                        </RadioGroup>
                    </FormControl>  
                    <InputFieldMultilineComponent
                        //label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDescription')} 
                        input_id="material_prize" 
                        field_name="material_prize"
                        validationFunction={ !props.materialPrize? validateTrue(): RequiredValidator }
                        disabled={prize !== 'Material_Prize'}
                        //className={ !props.materialPrize? 'hidden':'' }
                        className={ !props.materialPrize? 'hidden':'' }

                        input_type="text"
                        resetError={ ()=>{}}
                        error={ props.inscription_errors }
                        message=""                                                    
                        rows = { 5 }
                        //onChange={ (value)=>{handlePrizeType(value, 'material_prize')}}
                        onChange={ (value)=>{setMaterialPrize(value)}}

                        value={materialPrize}
                    />
                    {props.monetaryPrize &&
                        <div className='tournament-inscription__monetary_prize'>
                            <div className="tournament-inscription__content_registration"> 
                                <CurrencyFormat
                                    className="tournament-inscription__form-control"
                                    value={props.monetary_prize ?? '' }
                                    displayType={'input'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    disabled={prize !== 'Monetary_Prize'}
                                    prefix={'$ '} 
                                    suffix={' ' + props.currency}
                                    placeholder="$"
                                    onValueChange ={ (value)=>{handleMonetaryPrize(value.value)}}
                                    renderText={value => <div>{value}</div>}
                                /> 
                                <div className='input-field__message error'>{monetaryPrize === '' ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg'):''}</div>
                            </div>
                        </div>
                    }

                    {/* <InputFieldComponent 
                        input_id="monetary_prize" 
                        field_name="monetary_prize"
                        validationFunction={ !props.monetaryPrize? validateTrue(): RequiredValidator }
                        disabled={prize !== 'Monetary_Prize'}
                        className={ !props.monetaryPrize? 'hidden':'' }
                        //className={ !props.monetaryPrize? 'hidden':'' }
                        input_type="number"
                        min={1}
                        resetError={()=>{}}
                        error={ props.inscription_errors }
                        message=""
                        placeholder="$"
                        //onChange={ (value)=>{handlePrizeType(value, 'monetary_prize')}}
                        onChange={ (value)=>{setMonetaryPrize(value)}}
                        value={monetaryPrize}
                    />  */}
                    {/** Formulario de equipos */}
                    <FormControl component="fieldset" className={"tournament-inscription__content__half-buttons form-buttons"}>                        
                        <div className={"tournament-inscription__content__form-buttons baloa-table"}>
                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.team_form_check')}
                            {props.isTeamForm === "team_form_no" &&
                                <SecondaryButtonComponent
                                    type="button"
                                    id=""
                                    className=""
                                    onClick={() => { handleTeamBoxOpen() }}
                                    disabled={false}
                                >
                                    <span className="icon icon-plus" />
                                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}</span>
                                </SecondaryButtonComponent>
                            }
                            {props.isTeamForm === "team_form_yes" &&
                                <SecondaryButtonComponent
                                    type="button"
                                    id=""
                                    className=""
                                    onClick={() => { handleTeamBoxOpen() }}
                                    disabled={false}
                                >
                                    <span className="icon hubicon-settings" />
                                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.manageForm')}</span>
                                </SecondaryButtonComponent>
                            }
                            
                        </div>
                        <div className='tournament-inscription__content__form-info'>
                            {props.isTeamForm === "team_form_no" &&
                                <div className='tournament-inscription__content__form-info__empty'>
                                    <span className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.noForm')}</span>
                                    <div className='baloa-hyperlink-2' onClick={() => { handleTeamBox2Open()} }>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}</div>
                                </div>
                            }
                            {props.isTeamForm === "team_form_yes" &&
                                <div className='tournament-inscription__content__form-info__form-data'>
                                    <span className='baloa-table-column'>{props.teamFormName}</span>
                                    <div className='baloa-username'>{props.teamForm.length + ' preguntas'}</div>
                                </div>
                            }
                            <div className={`tournament-inscription__content__create-form__options-box__empty ${ teamBox2 ? "active" : ""}`} >                            
                                <div className='tournament-inscription__content__create-form__options-box__option' 
                                    onClick={()=> {
                                        props.handleOnNewFormModal('team');
                                        handleTeamBox2Open();
                                    }}>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.newForm')}</div>
                                </div>                            
                                <div className='tournament-inscription__content__create-form__options-box__option' 
                                    onClick={()=> {
                                        props.handleOnLoadFormModal('team');
                                        handleTeamBox2Open();
                                    }}>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.loadForm')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`tournament-inscription__content__create-form__options-box ${ teamBox ? "active" : ""}`} >                            
                            {props.isTeamForm === "team_form_no" &&
                                <React.Fragment>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnNewFormModal('team');
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.newForm')}</div>
                                    </div>                            
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnLoadFormModal('team');
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.loadForm')}</div>
                                    </div>
                                </React.Fragment>
                            }
                            {props.isTeamForm === "team_form_yes" &&
                                <React.Fragment>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handlePreviewTeamForm();
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.previewBtn')}</div>
                                    </div>                            
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnNewFormModal('team');//Abre el modal de creacion de formulario con la info del form que esta en el estado del componente teamForm
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelEditForm')}</div>
                                    </div>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnLoadFormModal('team');
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.selectOtherForm')}</div>
                                    </div>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnDeleteTeamForm();
                                            handleTeamBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.removeForm')}</div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        
                        {/* <RadioGroup row aria-labelledby="has_team_form" 
                            name="has_team_form" 
                            defaultValue="top" 
                            className="tournament-inscription__content__option-button">
                            <FormControlLabel
                                value="team_form_yes"
                                label={
                                    <span className="baloa-username">
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_yes')}
                                    </span>}
                                labelPlacement="end"
                                onChange={ checkTeamForm }
                                className={teamForm == "team_form_yes"? 'selected':''}
                                control={ props.isTeamForm === "team_form_yes"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="team_form_no"
                                label={
                                    <span className="baloa-username">
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_Np')}
                                        </span>
                                    }
                                labelPlacement="end"
                                onChange={ checkTeamForm }
                                className={teamForm == "team_form_no"? 'selected':''}
                                control={ props.isTeamForm === "team_form_no"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />                            
                        </RadioGroup> */}
                    </FormControl>
                    {/** Formulario de jugadores */}
                    <FormControl component="fieldset" className={"tournament-inscription__content__half-buttons form-buttons"}>                        
                        <div className={"tournament-inscription__content__form-buttons baloa-table"}>
                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.player_form_check')}
                            {props.isPlayerForm === "player_form_no" &&
                                <SecondaryButtonComponent
                                    type="button"
                                    id=""
                                    className=""
                                    onClick={() => { handlePlayerBoxOpen() }}
                                    disabled={false}
                                >
                                    <span className="icon icon-plus" />
                                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}</span>
                                </SecondaryButtonComponent>
                            }
                            {props.isPlayerForm === "player_form_yes" &&
                                <SecondaryButtonComponent
                                    type="button"
                                    id=""
                                    className=""
                                    onClick={() => { handlePlayerBoxOpen() }}
                                    disabled={false}
                                >
                                    <span className="icon hubicon-settings" />
                                    <span>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.manageForm')}</span>
                                </SecondaryButtonComponent>
                            }
                        </div>
                        <div className='tournament-inscription__content__form-info'>
                            {props.isPlayerForm === "player_form_no" &&
                                <div className='tournament-inscription__content__form-info__empty'>
                                    <span className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.noForm')}</span>
                                    <div className='baloa-hyperlink-2' onClick={() => { handlePlayerBox2Open()} }>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.createForm')}</div>
                                </div>
                            }
                            {props.isPlayerForm === "player_form_yes" &&
                                <div className='tournament-inscription__content__form-info__form-data'>
                                    <span className='baloa-table-column'>{props.playerFormName}</span>
                                    <div className='baloa-username'>{props.playerForm.length + ' preguntas'}</div>
                                </div>
                            }
                            <div className={`tournament-inscription__content__create-form__options-box__empty ${ playerBox2 ? "active" : ""}`} >                            
                                <div className='tournament-inscription__content__create-form__options-box__option' 
                                    onClick={()=> {
                                        props.handleOnNewFormModal('player');
                                        handlePlayerBox2Open();
                                    }}>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.newForm')}</div>
                                </div>                            
                                <div className='tournament-inscription__content__create-form__options-box__option' 
                                    onClick={()=> {
                                        props.handleOnLoadFormModal('player');
                                        handlePlayerBox2Open();
                                    }}>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.loadForm')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`tournament-inscription__content__create-form__options-box ${ playerBox ? "active" : ""}`} >                            
                            {props.isPlayerForm === "player_form_no" &&
                                <React.Fragment>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnNewFormModal('player');
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.newForm')}</div>
                                    </div>                            
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnLoadFormModal('player');
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.loadForm')}</div>
                                    </div>
                                </React.Fragment>
                            }
                            {props.isPlayerForm === "player_form_yes" &&
                                <React.Fragment>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handlePreviewPlayerForm();
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.previewBtn')}</div>
                                    </div>                            
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnNewFormModal('player');//Abre el modal de creacion de formulario con la info del form que esta en el estado del componente playerForm
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.labelEditForm')}</div>
                                    </div>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnLoadFormModal('player');
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.selectOtherForm')}</div>
                                    </div>
                                    <div className='tournament-inscription__content__create-form__options-box__option' 
                                        onClick={()=> {
                                            props.handleOnDeletePlayerForm();
                                            handlePlayerBoxOpen();
                                        }}>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.removeForm')}</div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        {/* <RadioGroup row 
                            aria-labelledby="has_player_form" 
                            name="has_player_form" 
                            defaultValue="top" 
                            className="tournament-inscription__content__option-button">
                            <FormControlLabel
                                value="player_form_yes"
                                label={<span className="baloa-username">
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_yes')}
                                </span>}
                                labelPlacement="end"
                                onChange={ checkPlayerForm }
                                className={playerForm == "player_form_yes"? 'selected':''}
                                control={ props.isPlayerForm === "player_form_yes"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />
                            <FormControlLabel
                                value="player_form_no"
                                label={<span className="baloa-username">
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.label_check_Np')}
                                </span>}
                                labelPlacement="end"
                                onChange={ checkPlayerForm }
                                className={playerForm == "player_form_no"? 'selected':''}
                                control={ props.isPlayerForm === "player_form_no"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                            />                            
                        </RadioGroup> */}
                    </FormControl>
                    {props.country_iata_code === 'US' && //Se habilita pago de arbitraje solo para torneos en USA
                        <React.Fragment>
                            <div className='tournament-inscription__content__payment_settings'>                        
                                <div className='tournament-inscription__content__payment_settings__container'>
                                    <div className="tournament-inscription__content_registration baloa-names">
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.refereePaymenSettingTitle')}
                                    </div>
                                    <SecondaryButtonComponent
                                        type={'button'}
                                        className="tournament-inscription__content__payment_settings__button xs"
                                        onClick={()=>{props.handleConfigArbitrageModal(true)}}
                                    >
                                        <span className='hubicon-settings icon'></span>
                                        <span >{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.refereePaymenSettingBtn')}</span>
                                    </SecondaryButtonComponent>
                                </div>

                                { props.is_referee_confing &&
                                    <div className='tournament-inscription__content__payment_settings__saved-config'>
                                        <div className='tournament-inscription__content__payment_settings__saved-config__total-fee'>
                                            <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localOptionLabel')}</div>
                                            <div className='baloa-username'>{`$ ${props.local_team_fee} ${props.currency}`}</div>
                                        </div>
                                        <div className='tournament-inscription__content__payment_settings__saved-config__total-fee'>
                                            <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.visitantOptionLabel')}</div>
                                            <div className='baloa-username'>{`$ ${props.visitant_team_fee} ${props.currency}`}</div>
                                        </div>
                                        <div className='tournament-inscription__content__payment_settings__saved-config__total-fee'>
                                            <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.arbitrageTotalAmountLabel')}</div>
                                            <div className='baloa-username'>{`$ ${props.referee_fee_value} ${props.currency}`}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* <div className='tournament-inscription__content__payment_settings'>                        
                                <div className="tournament-inscription__content_registration baloa-names">
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.faultPaymenSettingTitle')}
                                </div>
                                <SecondaryButtonComponent
                                    type={'button'}
                                    className="tournament-inscription__content__payment_settings__button xs"
                                    onClick={()=>{}}
                                >
                                    <span className='hubicon-settings icon'></span>
                                    <span >{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.faultPaymenSettingBtn')}</span>
                                </SecondaryButtonComponent>
                            </div> */}
                    </React.Fragment>
                    }
            </div>
        </div>
    )
}

export default withTranslation(['league','tournament'])(TournamentInscriptionComponent)