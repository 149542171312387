// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-type-form {}
.page-type-form__form {
}
.page-type-form__form__title {
    padding: 0 var(--space);
    padding-top: var(--space-and-half);
}
.page-type-form__form__fields {
    padding: var(--space);
}
.page-type-form__form__fields__field {
    padding: var(--space-half);
    display: flex;
    margin-bottom: var(--space-and-half);
    box-sizing: border-box;
    border: 1px solid transparent;
}
.page-type-form__form__fields__field.active {
    border: 1px solid var(--secondary-color);
}
.page-type-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.page-type-form__form__fields__field__icon span{
    margin-right: var(--space);
    font-size: var(--space-and-half);
    color: var(--secondary-color);
}
.page-type-form__form__fields__field:hover {
    cursor: pointer;
}
.page-type-form__form__fields__field:hover span {
    color: var(--hover-pb-bg-color);
}
.page-type-form__form__fields__field__description {
    align-items: flex-start !important;
}
.page-type-form__form__fields__field__description span {
    font-size: var(--space-and-half);
}


.page-type-form__form__input {}
.page-type-form__form__submit {}`, "",{"version":3,"sources":["webpack://./src/app/pages/components/page-category-modal/PageCategoryModalComponent.styles.css"],"names":[],"mappings":";AACA,iBAAiB;AACjB;AACA;AACA;IACI,uBAAuB;IACvB,kCAAkC;AACtC;AACA;IACI,qBAAqB;AACzB;AACA;IACI,0BAA0B;IAC1B,aAAa;IACb,oCAAoC;IACpC,sBAAsB;IACtB,6BAA6B;AACjC;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,0BAA0B;IAC1B,gCAAgC;IAChC,6BAA6B;AACjC;AACA;IACI,eAAe;AACnB;AACA;IACI,+BAA+B;AACnC;AACA;IACI,kCAAkC;AACtC;AACA;IACI,gCAAgC;AACpC;;;AAGA,8BAA8B;AAC9B,+BAA+B","sourcesContent":["\n.page-type-form {}\n.page-type-form__form {\n}\n.page-type-form__form__title {\n    padding: 0 var(--space);\n    padding-top: var(--space-and-half);\n}\n.page-type-form__form__fields {\n    padding: var(--space);\n}\n.page-type-form__form__fields__field {\n    padding: var(--space-half);\n    display: flex;\n    margin-bottom: var(--space-and-half);\n    box-sizing: border-box;\n    border: 1px solid transparent;\n}\n.page-type-form__form__fields__field.active {\n    border: 1px solid var(--secondary-color);\n}\n.page-type-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n.page-type-form__form__fields__field__icon span{\n    margin-right: var(--space);\n    font-size: var(--space-and-half);\n    color: var(--secondary-color);\n}\n.page-type-form__form__fields__field:hover {\n    cursor: pointer;\n}\n.page-type-form__form__fields__field:hover span {\n    color: var(--hover-pb-bg-color);\n}\n.page-type-form__form__fields__field__description {\n    align-items: flex-start !important;\n}\n.page-type-form__form__fields__field__description span {\n    font-size: var(--space-and-half);\n}\n\n\n.page-type-form__form__input {}\n.page-type-form__form__submit {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
