// React
import React from 'react';

// Components
import HubTournamentSectionCardComponent from '../hub-tournament-section-card/HubTournamentSectionCardComponent';

// Styles
import './HubTournamentSectionComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function HubTournamentSectionComponent (props) {
    return (
        <div className='hub-tournament-section__container'>
            <div className='hub-tournament-section__title baloa-headline-2' >{props.t(`tournamentHubSections.${props.section_title}`)}</div>
            {props.section_data?.map((subsection, index) => (
                <div className='hub-tournament-section__inscription-row__container' key={index}>
                    <HubTournamentSectionCardComponent 
                        section_data={subsection}
                        handleOnViewTournament={props.handleOnViewTournament}
                        handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                        handleOnSubsectionViewMore={ props.handleOnSubsectionViewMore }
                        handleOnLoadMoreTournaments={ props.handleOnLoadMoreTournaments } 
                    />
                </div>
            ))}
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentSectionComponent)