// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './ModalConfirmDialogComponent.styles.css';

function ModalConfirmDialogComponent ( props ){

    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <p className="modal-confirm-dialog__title baloa-headline-5">                
                { props.modalTitle }
            </p>

            <div className="modal-confirm-dialog__text baloa-body-2"
                dangerouslySetInnerHTML={{
                    __html: props.modalContent
                }}></div>
            
            <div className="modal-confirm-dialog__actions"> 
                <SecondaryButtonComponent 
                    className="modal-confirm-dialog__button"
                    onClick={ props.onPrincipalButtonClick }
                >
                    <span>{ props.principalButtonLabel }</span>
                </SecondaryButtonComponent>
                {props.secondaryButton &&
                    <TextButtonComponent 
                        className="modal-confirm-dialog__button"
                        onClick={ props.onSecondaryButtonClick }
                    >
                        <span>{ props.secondaryButtonLabel }</span>
                    </TextButtonComponent>
                }
            </div>
        </ModalComponent>
    )

}

export default (ModalConfirmDialogComponent);
