/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
//import ToggleButton from '@mui/material/ToggleButton';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Styles
import './TournamentBasicDataComponent.styles.css';
//import { styled } from "@mui/styles";

//i18
import { withTranslation } from 'react-i18next';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';
import { EndDateValidator } from 'shared/validators/EndDateValidation';


function TournamentBasicDataComponent ( props ){
    const [start_date, setStateStartDate] = React.useState('');
    const [end_date, setStateEndDate] = React.useState('');
    const [state, setState] = React.useState({
		upload_photo_modal: false,
        upload_logo_modal: false,
	});
    //const [formats, setFormats] = React.useState(props.newFormatDays.map((day) => day.day) ?? []);
    //const [gameDay, setGameDay] = React.useState(props.newFormatTimeDay.map((game_day) => game_day.timeday) ?? []);
    
    // const [ name, setName ] = React.useState('');


    /* eslint-disable */
    handleChangeStartDate = handleChangeStartDate.bind(this);
    handleChangeEndDate = handleChangeEndDate.bind(this);
    // handleChangeName = handleChangeName.bind(this);
    /* eslint-enable */

    /* const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    })); */

    const tournament_gender=[
        {value:'Female' ,content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Female')},
        {value:'Male' ,content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Male')},
        {value:'Free' ,content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Free')},
    ];

    /* const DAYS_OF_WEEK = [
        { value: 'Monday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Monday') },
        { value: 'Tuesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Tuesday') },
        { value: 'Wednesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Wednesday') },
        { value: 'Thursday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Thursday') },
        { value: 'Friday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Friday') },
        { value: 'Saturday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Saturday') },
        { value: 'Sunday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Sunday') },

    ];

    const SECTIONS_DAYS = [
        { value: 'Morning', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Morning') },
        { value: 'Afternoon', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Afternoon') },
        { value: 'Evening', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Evening') }
    ]; */

    function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }
        
        return today;
    }

    function handleChangeStartDate(data) {
        setStateStartDate(data);
    }

    function handleChangeEndDate(data) {
        setStateEndDate(data);
    }

    const handleTournamentPhoto = (new_img, imageurl, img_b64) => {
        props.handleTournamentPhotoB64(`data:image/jpeg;base64,${img_b64}`, imageurl);
    }

    const handleTournamentLogo = (new_img, imageurl, img_b64) => {
        props.handleTournamentLogoB64(`data:image/jpeg;base64,${img_b64}`, imageurl);
    }

    const handleUploadPhotoModal = (new_state) => {
        setState({ upload_photo_modal: new_state });
	};

    const handleUploadLogoModal = (new_state) => {
        setState({ upload_logo_modal: new_state });
    };

    // function handleChangeName(data) {
    //     setName(data);
    //     //handleFormData()
    // };

    const chooseFooballType=(value)=>{
        props.setFootbalTypeMinPlayers(value);
    };

    /* const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
        props.handleSelectDays(newFormats);
    };

    const handleGameDayFormat = (event, newDayFormats) => {
        setGameDay(newDayFormats);
        props.handleSelectDayTime(newDayFormats);
    }; */

    return(
        <div className="basic-data">
            <div className="basic-data__form__content">
                <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBasicDataTitle')}</div>
                <div className='tournament-basic-data__tournament-logo'>
                    <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentPhoto')}</div>
                    <div className='tournament-photo' onClick={() => handleUploadLogoModal(true)} >
                        { !props.tournament_logo_url
                            ?
                            <span className='icon-image-70'/>
                            :
                            <img src={props.tournament_logo_url} alt="" />
                        }
                        <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelUploadImg')}</div>
                    </div>
                    { props.isTournamentLogo === false && <div className='tournament-basic-data__logo__required-label baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}</div> }                    
                </div>

                <LeagueTeamUploadImageModalComponent 
                    isOpen={ state.upload_logo_modal }
                    onClose={() => handleUploadLogoModal(false)}
                    setTeamImage={ (new_img, imageurl, img_b64) => handleTournamentLogo(new_img, imageurl, img_b64)}
                    aspect={1}
                    cropImage={true}
                />

                <InputFieldComponent
                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentName')} 
                    input_id="tournament-name" 
                    field_name="name"
                    validationFunction={ RequiredValidator }
                    className="basic-data__form__content__input name"
                    input_type="text"
                    resetError={ () => props.onResetError( { 'name': "" } )}
                    error={ props.errors }
                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderTournamentName')}
                    disabled={ false }
                    defaultValue={props.name}
                    // onChange={(data) => handleChangeName(data)}

                />
                <div className="basic-data__form__content__category grid-content">
                    <div className="basic-data__form__content__football-type__input">
                        <SelectFieldComponent
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelFootballType')}
                            field_name="football_type"
                            validationFunction={ RequiredValidator }
                            disabled={false}
                            resetError={ () => props.onResetError( { 'football_type': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderFootballType')}
                            options={ props.tournament_football_type }    
                            onChange={(v)=>{chooseFooballType(v)}}                        
                        />
                    </div>
                    <div className="basic-data__form__content__tournament-type__input">
                        <SelectFieldComponent
                            label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTournamentType')}
                            field_name="tournament_type"
                            validationFunction={ RequiredValidator }
                            disabled={false}
                            resetError={ () => props.onResetError( { 'tournament_type': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderTournamentType')}
                            options={ props.list_tournament_types }    
                            // onChange={(v)=>{chooseFooballType(v)}}
                        />
                    </div> 
                    
                    <div className="basic-data__form__content__category__input">
                        <SelectFieldComponent
                            label={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCategory') }
                            field_name="category"
                            validationFunction={ RequiredValidator }
                            disabled={false}
                            resetError={ () => props.onResetError( { 'category': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderCategory')}
                            options={ props.tournament_tag }
                        />
                    </div>
                    <div className="basic-data__form__content__gender__input">
                        <SelectFieldComponent
                            label={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelGender') }
                            field_name="tournament_gender"
                            validationFunction={ RequiredValidator }
                            disabled={false}
                            resetError={ () => props.onResetError( { 'gender': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderGender')}
                            options={ tournament_gender }                            
                        />
                    </div>
                </div>
                
                <div className="basic-data__form__content__citys grid-content">
                    <InputFieldAutocompleteWithSearchComponent
                        options={ props.countries? props.countries: null }
                        noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "id" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( newCountryValue ) => props.handleSearchNationality( newCountryValue ) }
                        className="basic-data__form__content__country__input"
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCountry')}
                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderCountry')}
                        id="country_autocomplete_new_tournament"
                        field_name="main_country"
                        validationFunction={ RequiredValidator }
                        resetError={ () => {}}
                        error={ props.errors }
                        defaultValue={props.countries?.length === 1? props.countries[0] : null}
                    />   
                    <InputFieldAutocompleteWithSearchComponent
                        options={ props.cities? props.cities: null }
                        noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                        optionLabelField={ "name" }
                        optionSelectedField={ "id" }
                        lettercount={ "2" }
                        handleSearchWord ={ ( newCityValue ) => props.handleSearchWorldCities( newCityValue ) }
                        className="basic-data__form__content__city__input"
                        label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCity')} 
                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderCity')}
                        id="city_autocomplete_new_tournament"
                        field_name="main_city"
                        validationFunction={ RequiredValidator }
                        resetError={ () => {}}
                        error={ props.errors }
                        disabled={props.disableCities}
                        defaultValue={props.cities?.length === 1? props.cities[0]: null}
                    />     
                </div>
                
                <div className="basic-data__form__content__date grid-content">
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelStartDate')}
                        input_id="tournament-start_date" 
                        field_name="start_date"
                        validationFunction={ DateFormatValidator }
                        className="basic-data__form__content__date__input-start"
                        input_type="date"
                        resetError={ () => {} }
                        error={ props.errors }                            
                        disabled={ props.basic_data_info ? true : false }
                        min={ todayDate() }
                        onChange={ handleChangeStartDate }
                        value={ start_date? start_date: null } //TODO check
                        
                    />
                    
                    <InputFieldComponent
                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEndDate')}
                        input_id="tournament-end_date" 
                        field_name="end_date"
                        validationFunction={ () => EndDateValidator(end_date,start_date) }
                        className="basic-data__form__content__date__input-end"
                        input_type="date"
                        resetError={ () => {}}
                        error={ props.errors }
                        disabled={ props.basic_data_info ? true : false }
                        min={ start_date === '' ? todayDate() : start_date }
                        onChange={ handleChangeEndDate }
                        value={ end_date? end_date: null } //TODO check
                    />
                </div>

                <InputFieldMultilineComponent 
                    label={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelDescription')} 
                    input_id="tournament-description" 
                    field_name="description"
                    validationFunction={ RequiredValidator }
                    disabled={false}
                    className="tournament-basic-data__description"
                    input_type="text"
                    resetError={ () => props.onResetError( { 'description': "" } )}
                    error={ props.errors }
                    message=""                        
                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderDescription')}
                    rows={ 5 }
                />
                <InputFieldMultilineComponent 
                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelRules')}
                    input_id="tournament-rules" 
                    field_name="rules"
                    //validationFunction={ RequiredValidator }
                    disabled={false}
                    className="tournament-basic-data__rules"
                    input_type="text"
                    resetError={ () => props.onResetError( { 'rules': "" } )}
                    error={ props.errors }
                    message=""                        
                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderRules')}
                    rows={ 5 }
                />
                {/* <div className="basic-data__button-group">
                    <p className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.possible_days')}</p>
                    <StyledToggleButtonGroup
                            color="primary"
                            value={formats}
                            onChange={handleFormat}
                                >
                        {DAYS_OF_WEEK.map((day, index) => (
                            <ToggleButton key={index} value={day.value}>
                                {day.content}
                            </ToggleButton>
                            ))}
                    </StyledToggleButtonGroup>
                </div> */}
                {/* <div className="basic-data__button-group">
                <p className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.possible_game_days')}</p>
                <StyledToggleButtonGroup
                    color="primary"
                    value={gameDay}
                    onChange={handleGameDayFormat}
                    aria-label="text formatting">
                        {SECTIONS_DAYS.map((section, index) => (
                            <ToggleButton key={index} value={section.value}>
                                {section.content}
                            </ToggleButton>
                        ))}
                </StyledToggleButtonGroup>
                </div> */}

                <div className='tournament-basic-data__tournament-cover' onClick={() => handleUploadPhotoModal(true)}>
                    
                    { !props.tournament_photo_url
                        ?
                        <div>
                            <div className='tournament-basic-data__tournament-cover__tittle baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.uploadImgTitle')}</div>
                            <div className='tournament-cover'>
                                <div className='tournament-cover-description'>
                                    <span className='icon-image-70'/>
                                    <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelUploadImg')}</div>
                                </div>
                                <div className='tournament-cover-info'>
                                    <span className='hubicon-info'/>
                                    <div className='baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.uploadImgDescription')}</div>
                                </div>
                            </div>
                        </div>
                       
                        :
                        <div className='img-container'><img src={props.tournament_photo_url} alt="" /></div>
                    }
                </div>
                { props.isTournamentPhoto === false && <div className='tournament-basic-data__photo__required-label baloa-username'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}</div> }
                <LeagueTeamUploadImageModalComponent 
                    isOpen={ state.upload_photo_modal }
                    onClose={() => handleUploadPhotoModal(false)}
                    setTeamImage={ (new_img, imageurl, img_b64) => handleTournamentPhoto(new_img, imageurl, img_b64)}
                    aspect={2/1}
                    className='basic-data__tournament-cover__modal'
                    cropImage={true}
                />
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentBasicDataComponent)