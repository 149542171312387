
export default function convertBirthdayToAge( birthday ) {
    var today = new Date();
    var birthday_date = new Date( birthday );
    var age = today.getFullYear() - birthday_date.getFullYear();
    var m = today.getMonth() - birthday_date.getMonth();
    if ( m < 0 || ( m === 0 && today.getDate() < birthday_date.getDate() ) ) {
        age--;
    }
    return age;
}