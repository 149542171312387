
// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
//import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './WelcomeModalComponent.styles.css';

//Images
//import goal from 'assets/images/goal.svg';

//i18
import { withTranslation } from 'react-i18next';


function WelcomeModalComponent( props ) {
    return (
        <ModalComponent 
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <div className='welcome-modal__container'>
                <div className='welcome-modal__title-container'>
                    <div className='baloa-subtitle-1'>{props.t('modal.title')}</div>
                    <span className='hubicon-baloa_logo' />
                    <div className='baloa-table' >{props.t('modal.subTitle')}</div>
                </div>

                <div className='welcome-modal__card' >
                    <Link to={"/profile/" + props.username} onClick={() => {props.onClose()}}>
                        <div className='welcome-modal__card-title' >
                            <span className='icon-user welcome-modal__card-icons' />
                            <div className='baloa-table-column' >{props.t('modal.profileLabel')}</div>
                        </div>
                        <div className='baloa-username' >{props.t('modal.profileText')}</div>
                    </Link>

                </div>

                <div className='welcome-modal__card' onClick={() => {props.handleHomeShortCuts('create-team')}}>
                    <div className='welcome-modal__card-title' >
                        <div className="clearfix">
                            <span className="hubicon-create_team"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                        </div>
                        <div className='baloa-table-column' >{props.t('modal.createTeamLabel')}</div>
                    </div>
                    <div className='baloa-username' >{props.t('modal.createTeamText')}</div>

                </div>

                <div className='welcome-modal__card' onClick={() => {props.handleHomeShortCuts('join-team')}}>
                    <div className='welcome-modal__card-title' >
                        <div className="clearfix">
                            <span className="hubicon-join_team"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                        </div>
                        <div className='baloa-table-column' >{props.t('modal.joinTeamLabel')}</div>
                    </div>
                    <div className='baloa-username' >{props.t('modal.joinTeamText')}</div>

                </div>

                <div className='welcome-modal__card' >
                    <Link to={'/tournament/hub'} onClick={() => {props.onClose()}}>
                        <div className='welcome-modal__card-title' >
                            <div className="clearfix">
                                <span className="hubicon-tournaments"><span className="path1"></span><span className="path2"></span></span>
                            </div>
                            <div className='baloa-table-column' >{props.t('modal.createTournamentLabel')}</div>
                        </div>
                        <div className='baloa-username' >{props.t('modal.createTournamentText')}</div>
                    </Link>
                </div>

                <div className='welcome-modal__card' onClick={() => {props.onClose()}}>
                    <div className='welcome-modal__card-title' >
                        <span className='icon-web welcome-modal__card-icons' />
                        <div className='baloa-table-column' >{props.t('modal.surfBaloaLabel')}</div>
                    </div>
                    <div className='baloa-username' >{props.t('modal.surfBaloaText')}</div>

                </div>
            
            
            </div>
            
        </ModalComponent>
    )
}

export default withTranslation('signUp')(WelcomeModalComponent);

//export default WelcomeModalComponent