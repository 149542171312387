// React
import React from 'react';

// the translator
import { useTranslation } from 'react-i18next';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

import { Field, useFormik } from 'formik';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

// Styles
import './ProfileSpecialtiesModalComponent.styles.css';
import {
  withStyles,
} from '@mui/styles';
/** NO SE ACTUALIZA AUTOCOMPLETE - NO SE ENCONTRO USO */

function ProfileSpecialtiesModalComponent( props ) {

    const { children, value, index  } = props; // eslint-disable-line

    const { t } = useTranslation('profile', { useSuspense: false });
    const translatedSpecialties = props.specialties.map(function(specialty){
      var tspec = {};
      tspec["label"] = t('specialties.'+specialty);
      tspec["value"] = specialty;
      return tspec;
    });


    const formikvalues = useFormik({
        initialValues: {
            specialty: props.specialty? translatedSpecialties.filter( tspecialty => tspecialty.value === props.specialty.value)[0] : null,
        },
    });

    const AutocompleteTextField = withStyles({
        root: {
          '& label.Mui-focused': {
            color: '#2a302a',
          },          
          '& .MuiOutlinedInput-root': {
            '& fieldset': {             
              border: 'none',
            },           
          },
        },
      })(TextField);

    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={t('role.title')}
        >
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => {     
                    let new_attribute_data = { 
                        atype: "specialty",
                        value: values.specialty.value, 
                    }
                    props.onNewData( new_attribute_data );
                }}
                className="specialties-form__form form"
                autocomplete="off"
            >
                <Box margin={1}>
                    <Field
                        name="specialty"
                        component={Autocomplete}
                        options={translatedSpecialties}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <AutocompleteTextField
                                {...params}
                                className="specialties-form__form__input"
                                label={t('role.title')}
                                variant="outlined"
                                margin="normal"
                                
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Box>
                <div className="specialties-form__form__actions form__actions">
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="specialties__actions__submit"
                    >
                        <span>{props.specialty? t('data.modal.label1') : t('data.modal.label2') }</span>
                    </PrimaryButtonComponent>
                </div>
            
            </FormComponent>

        </Modal2Component>

        )
    }
    
export default ProfileSpecialtiesModalComponent;