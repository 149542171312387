// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {compose} from 'redux';

// Styles
import './CardsView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Assets
import loading_ball from 'assets/images/loading_ball.gif';

// React device detect
import {isMobile} from 'react-device-detect';

// Actions
import {
    loadCardIdsData,
    loadPlayerCardIdData,
    loadStaffCardIdData,
} from 'app/leagues/actions/LeagueTournamentActions';

// Components
import CardIdViewerStepperComponent from '../components/card-id-viewer-stepper/CardIdViewerStepperComponent';

class CardsView extends Component {

    constructor( props ) {
        super(props);
        //this.username = this.props.match.params.username;
        this.state = {   
            tournament_id: undefined,
            tournament_teams: undefined,
            tournament_player_id: undefined,
            team_profile_id: undefined,
            tournament_team_id: undefined,
            showCardId: false,
            showTeamCardsIds: false,
            downloadCardIdData: null,

        }
    }

    componentDidMount() {

    }

    async componentDidUpdate(prevProps) {
        // Trae la informacion de los carnets de un equipo
        //cards/team/45a66c9f-ec15-4f43-bf4e-d65312c36e2e&tournament_teams=45481d35-8c00-4140-b82d-63b02ae829d6&value=
        if(this.props.match.params.tournament_id && this.props.match.params.tournament_teams && ((this.state.tournament_id !== this.props.match.params.tournament_id) || (this.state.tournament_teams !== this.props.match.params.tournament_teams))){
            this.setState({tournament_id: this.props.match.params.tournament_id, tournament_teams: this.props.match.params.tournament_teams});
            if(this.props.match.params.value){
                await this.props.loadCardIdsData( this.props.match.params.tournament_id, this.props.match.params.tournament_teams, `Token ${this.props.match.params.value}` );
            }else{
                await this.props.loadCardIdsData( this.props.match.params.tournament_id, this.props.match.params.tournament_teams );
            }             
            this.setState( { showTeamCardsIds: true });
        }
        // Trae la informacion de los carnets de un jugador
        //cards/player/f892749d-abe1-4d51-804f-41ff468d7a7f&value=
        if(this.props.match.params.tournament_player_id && (this.state.tournament_player_id !== this.props.match.params.tournament_player_id)){
            this.setState({tournament_player_id: this.props.match.params.tournament_player_id});
            if(this.props.match.params.value){
                this.props.loadPlayerCardIdData(this.props.match.params.tournament_player_id, `Token ${this.props.match.params.value}`);
            }else{
                this.props.loadPlayerCardIdData(this.props.match.params.tournament_player_id);
            }            
            this.setState( { showCardId: true });
        }
        // Trae la informacion de los carnets de un miembro del staff
        //cards/staff/4b383f61-e151-4ded-87a5-651bc8dae934&tournament_team_id=0f294376-c8f3-4f39-9e59-72b775c9815e&value=
        if(this.props.match.params.team_profile_id && ((this.state.team_profile_id !== this.props.match.params.team_profile_id) || (this.state.tournament_team_id !== this.props.match.params.tournament_team_id))){
            this.setState({team_profile_id: this.props.match.params.team_profile_id, tournament_team_id: this.props.match.params.tournament_team_id});
            if(this.props.match.params.value){
                this.props.loadStaffCardIdData( this.props.match.params.team_profile_id, this.props.match.params.tournament_team_id, `Token ${this.props.match.params.value}`);
            }else{
                this.props.loadStaffCardIdData( this.props.match.params.team_profile_id, this.props.match.params.tournament_team_id);
            }
            this.setState( { showCardId: true });
        }

        /** Se valida la info de los carnets para crear el objeto que va al componente de pdf */
        if (prevProps.state.leagueTournaments?.card_ids_data?.results !== this.props.state.leagueTournaments?.card_ids_data?.results){
            this.prepareCardIdsPDFData();
        }
    }

    findQuestionResponses(answers){
        let newAnswers = '';
        //eslint-disable-next-line array-callback-return
        answers?.map(ans => {
            if(ans.is_checked){
                newAnswers += ans.form_template_answer.caption + ',';
            }
        });
        newAnswers= newAnswers.slice(0, -1);
        return newAnswers;
    }

    prepareCardIdsPDFData(){
        let all_players_data = [];
        if(this.props.state.leagueTournaments?.card_ids_data?.results?.length > 0){
            //eslint-disable-next-line array-callback-return
            this.props.state.leagueTournaments?.card_ids_data?.results?.map(team => {
                //eslint-disable-next-line array-callback-return
                team?.player_forms.map(player => {
                    let player_data = [];
                    //eslint-disable-next-line array-callback-return
                    player?.questions?.map(ques => {
                        let card_id_question = this.props.state.leagueTournaments?.card_ids_data?.form_card.questions.find(q => q.form_question_template.caption === ques.caption )
                        if(card_id_question){
                            let new_question = undefined;
                            if((card_id_question.form_question_template.code === 'text' || card_id_question.form_question_template.code === 'email' || card_id_question.form_question_template.code === 'phone') && card_id_question.json_answer !== "{}"){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.text_answer,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'date'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.date_field,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'file'){
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    shape: card_id_question.json_answer.shape,
                                    width: card_id_question.json_answer.width,
                                    height: card_id_question.json_answer.height,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.file_document,
                                    data: ques.parent_data,
                                }
                            }
                            if(card_id_question.form_question_template.code === 'boolean'){//TO DO
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: ques.boolean_answer ? this.props.t('tournament:registerPlayer.viewForm.trueAnswer'): this.props.t('tournament:registerPlayer.viewForm.falseAnswer'),
                                }
                            }
                            if(card_id_question.form_question_template.code === 'single_choice' || card_id_question.form_question_template.code === 'multi_choice' || card_id_question.form_question_template.code === 'list'){//TO DO: , 
                                new_question = {
                                    caption: card_id_question.form_question_template.caption,
                                    code: card_id_question.form_question_template.code,
                                    color: card_id_question.json_answer.color,
                                    fontFamily: card_id_question.json_answer.fontFamily,
                                    fontSize: card_id_question.json_answer.fontSize,
                                    x: card_id_question.json_answer.x,
                                    y: card_id_question.json_answer.y,
                                    value: this.findQuestionResponses(ques.answers),
                                }
                            }
                            if(new_question){
                                player_data.push(new_question);
                            }
                            
                        }
                    })
                    all_players_data.push(player_data)
                })
            })
        }
        this.setState({downloadCardIdData: all_players_data});
        return true;
    }

    render() {
        
        return(
            <div className="cards">
                {isMobile &&
                    ((this.state.showTeamCardsIds || this.state.showCardId)?
                        <CardIdViewerStepperComponent 
                            downloadCardIdData={this.state.downloadCardIdData? this.state.downloadCardIdData: [] }
                            card_id_form_data={ this.props.state.leagueTournaments?.card_ids_data?.form_card }
                            orientation={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Orientacion')?.text_answer}
                            template_image={this.props.state.leagueTournaments?.card_ids_data?.form_card?.questions.find(q => q.form_question_template.caption === 'Plantilla')}
                        />
                    :
                        <div className={'cards__loader-mobile'}><img src={loading_ball} alt="loader" /></div>
                    )
                }
                {/* !isMobile &&
                    <div>{'acceso no permitido'}</div> */
                }
                            
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCardIdsData: async( tournament_id, teams_list, token ) => {
            await dispatch( loadCardIdsData( tournament_id, teams_list, token ))
        },
        loadPlayerCardIdData:( tournament_player_id, token ) => {
            dispatch( loadPlayerCardIdData( tournament_player_id, token ))
        },
        loadStaffCardIdData:( team_profile_id, tournament_team_id, token ) => {
            dispatch( loadStaffCardIdData( team_profile_id, tournament_team_id, token ))
        },
        
    }
} 

export default compose(
    withTranslation("league"),
    connect(mapStateToProps, mapDispatchToProps)
)(CardsView);