import React from 'react';

import './TableMatchScoreComponent.styles.css';

function TableMatchScoreComponent(props) {

    return (
        <div className={'table_match_score-container'}>
            {props.match_state === 'Finalized'
                ? <React.Fragment>
                    <div className={'match_score baloa-username'}>
                        {props.local_team_score} - {props.visitant_team_score}
                    </div>
                    {props.local_team_penalty_score && props.visitant_team_penalty_score &&
                        <div className={'penalty_score baloa-username'}>
                            ({props.local_team_penalty_score} - {props.visitant_team_penalty_score})
                        </div>
                    }
                </React.Fragment>
                : <p className={'vs baloa-username'}>vs</p>
            }
        </div>
    );
};

export default TableMatchScoreComponent;