// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentFieldsAdminModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentFieldsAdminModalComponent ( props ) {
    const [optionsOpen, setOptionsOpen] = React.useState(false);
    const [listType, setListType] = React.useState('');
    const [actionType, setActionType] = React.useState('');

    React.useEffect( () => {        
        if(props.new_page_field) {
            if(actionType === 'create-tournament-field') {
                let field = [ { field:  props.new_page_field.id } ]
                props.handleTournamentFieldActions('create-tournament-field', field);
                setActionType('');
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.new_page_field]);

    const handleOptionsList = (list_type) => {
        setListType(list_type);
        setOptionsOpen(optionsOpen ? false : true)
    }

    const handleCreateField = (action_type) => {
        props.handleFieldsAdminActions(action_type); 
        setActionType(action_type)
        setOptionsOpen(false);
    }

    const handleExistingFields = () => {
        props.handleExistingFields();
        setOptionsOpen(false);
    }

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.pageFields.labelTitle')}
            className="tournament-fields-admin__container"
         >
            <div className='tournament-fields-admin__subtitle baloa-table'>{ props.t('options.tournamentFields.labelText') }</div>
            <div className='tournament-fields-admin__fields'>
                <div className='tournament-fields-admin__fields__create-action'>
                    <div className='baloa-hyperlink-2'>{ props.t('options.pageFields.labelActionTitle') }</div>
                    <div className='tournament-fields-admin__btn-container'>
                        <SecondaryButtonComponent onClick={ () => handleOptionsList('btn') } ><span className='hubicon-add_option' /> <span>{ props.t('options.tournamentFields.labelActionBtn') }</span></SecondaryButtonComponent>
                        <div className={`tournament-fields-admin__btn-options ${optionsOpen && listType === 'btn' ? 'open' : ''}`}>
                            <div className='baloa-names' onClick={ () => handleCreateField('create-tournament-field') } >{ props.t('options.tournamentFields.labelNewFieldOption') }</div>
                            { props.tournament_available_fields.length !== 0 && <div className='baloa-names' onClick={ () => handleExistingFields() } >{ props.t('options.tournamentFields.labelExistingFieldOption') }</div> }
                        </div>
                    </div>
                </div>
                <div className='tournament-fields-admin__fields__list-container'>
                    { props.tournament_fields && props.tournament_fields?.length === 0 &&
                        <div className='tournament-fields-admin__fields__list__empty'>
                            <div className='baloa-table-column tournament-fields-admin__fields__list__empty-text'>{ props.t('options.pageFields.labelNoFields') }</div>
                            <div className='tournament-fields-admin__btn-container'>
                                <div className='baloa-table-column' onClick={ () => handleOptionsList('btn-empty') } >{ props.t('options.tournamentFields.labelActionBtn') }</div>
                                <div className={`tournament-fields-admin__btn-options ${optionsOpen && listType === 'btn-empty' ? 'open' : ''}`}>
                                    <div className='baloa-names' onClick={ () => handleCreateField('create-tournament-field') } >{ props.t('options.tournamentFields.labelNewFieldOption') }</div>
                                    <div className='baloa-names' onClick={ () => handleExistingFields() } >{ props.t('options.tournamentFields.labelExistingFieldOption') }</div>                            
                                </div>
                            </div>                            
                        </div>
                    }
                    { props.tournament_fields && props.tournament_fields?.length > 0 && 
                        <div className='tournament-fields-admin__fields__list'>
                            { props.tournament_fields?.map( field => (
                                <div key={field.id} className='tournament-fields-admin__fields__field' >
                                    <div className='tournament-fields-admin__fields__field-info'>
                                        <div className='baloa-table-column'>{field.field.name}</div>
                                        <div className='baloa-username'>{field.field.address}</div>
                                    </div>
                                    <div className='tournament-fields-admin__fields__field-actions'>
                                        <span className='hubicon-edit' onClick={ () => props.handleFieldsAdminActions('edit-page-field', field.field) } />
                                        <span className='hubicon-delete' onClick={ () => props.handleTournamentFieldActions('delete-tournament-field', field) } />
                                    </div>                                    
                                </div>
                            ))}
                        </div>            
                    }                    
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentFieldsAdminModalComponent);