// React
import React from 'react';

// Material
// import { Checkbox } from '@mui/material';

// Components
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import InputMinuteComponent from 'shared/components/input-minute/InputMinuteComponent';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Styles
import './LeagueMatchScorerComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueMatchScorerComponent( props ) {

    const [componentValues, setComponentValues] = React.useState({ 
        local_scorer: [null,null,null,null,null,null,null,null,null,null,null,null],
        local_assistant: [null,null,null,null,null,null,null,null,null,null,null,null],
        local_goal_minute: [], 
        local_own_goal: [false, false, false, false, false, false, false, false, false, false, false, false], 
        local_own_goal_status: [null,null,null,null,null,null,null,null,null,null,null,null], 
        visitor_scorer: [null,null,null,null,null,null,null,null,null,null,null,null],
        visitor_assistant: [null,null,null,null,null,null,null,null,null,null,null,null],
        visitor_goal_minute: [], 
        visitor_own_goal: [false, false, false, false, false, false, false, false, false, false, false, false],
        visitor_own_goal_status: [null,null,null,null,null,null,null,null,null,null,null,null] 
    });
    
    React.useEffect( () => {

        if(Object.entries(props.initialValues)?.length !== 0){
            
            setComponentValues(props.initialValues); 
        }

    }, [ props ] );

    const [focusLocal, setFocusLocal ] = React.useState(false);
    const [focusVisitor, setFocusVisitor ] = React.useState(false);

    React.useEffect(() => {
        if(props.local_score > 0 && !focusLocal){            
            setTimeout(()=>{
                document.getElementsByName('local_goal_minute[0]')[0]?.focus();
                let el1 = document.getElementById('scroll-local');
                el1.scrollTop  = 0;            
                setFocusLocal(true);
            },1000);
        }
        if(props.visitor_score > 0 && !focusVisitor){            
            setTimeout(()=>{
                document.getElementsByName('visitor_goal_minute[0]')[0]?.focus();
                let el2 = document.getElementById('scroll-visitant'); 
                el2.scrollTop  = 0;
                
                setFocusVisitor(true);
            },1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.local_score, props.visitor_score ]);

    const handleOnChangeLocalScorer=(value, index)=>{
        const newLScorer = [...componentValues.local_scorer];
        newLScorer[index] = value? value: '';
        setComponentValues({...componentValues, local_scorer: newLScorer});
    }

    const handleOnChangeLocalAssistance=(value, index)=>{
        const newLAssistance = [...componentValues.local_assistant];
        newLAssistance[index] = value ?? '';
        setComponentValues({...componentValues, local_assistant: newLAssistance});
    }

    const handleOnChangeLocalMinute=(event, index)=>{
        const newMinutes = [...componentValues.local_goal_minute];
        newMinutes[index] = event.target.value;
        setComponentValues({...componentValues, local_goal_minute: newMinutes});
    }

    const handleOnChangeVisitorScorer=(value, index)=>{
        const newVScorer = [...componentValues.visitor_scorer];
        newVScorer[index] = value? value: '';
        setComponentValues({...componentValues, visitor_scorer: newVScorer});
    }

    const handleOnChangeVisitorAssistance =(value, index)=>{
        const newVAssistance = [...componentValues.visitor_assistant];
        newVAssistance[index] = value ?? '';
        setComponentValues({...componentValues, visitor_assistant: newVAssistance});
    }
    
    const handleOnChangeVisitorMinute=(event, index)=>{
        const newVMinutes = [...componentValues.visitor_goal_minute];
        newVMinutes[index] = event.target.value;
        setComponentValues({...componentValues, visitor_goal_minute: newVMinutes});
    }

    // const handleOnChangeLocalOwnGoal = (event, index) => {

    //     let isChecked = event.target.checked;
    //     let localScorer = [...componentValues.local_scorer];
    //     localScorer[index] = null;
    //     let isLocalChecked = [...componentValues.local_own_goal];
    //     isLocalChecked[index] = isChecked;
    //     setComponentValues({...componentValues, local_own_goal: isLocalChecked, local_scorer: localScorer});

    // }

    const handleOnChangeLocalOwnGoal = (event, index) =>{
        let isChecked = event.target.value === 'ownGoal';
        let rabioButtonValue = event.target.value;

        let localScorer = [...componentValues.local_scorer];
        localScorer[index] = null;

        let localAssistant = [...componentValues.local_assistant];
        localAssistant[index] = null;

        let isLocalChecked = [...componentValues.local_own_goal];
        isLocalChecked[index] = isChecked;

        let local_own_goal_status = [...componentValues.local_own_goal_status];
        local_own_goal_status[index] = rabioButtonValue

        setComponentValues({
            ...componentValues,
            local_own_goal: isLocalChecked,
            local_scorer: localScorer,
            local_own_goal_status: local_own_goal_status,
            local_assistant: localAssistant
        });
    }

    // const handleOnChangeVisitorOwnGoal = (event, index) => {

    //     let isChecked = event.target.checked;
    //     let visitorScorer = [...componentValues.visitor_scorer];
    //     visitorScorer[index] = null;
    //     let isVisitorChecked = [...componentValues.visitor_own_goal];
    //     isVisitorChecked[index] = isChecked;
    //     setComponentValues({...componentValues, visitor_own_goal: isVisitorChecked, visitor_scorer: visitorScorer});
    // }

    const handleOnChangeVisitorOwnGoal = (event, index) => {
        let isChecked = event.target.value === 'ownGoal';
        let rabioButtonValue = event.target.value;
        
        let visitorScorer = [...componentValues.visitor_scorer];
        visitorScorer[index] = null;

        let visitorAssistant = [...componentValues.visitor_assistant];
        visitorAssistant[index] = null;

        let isVisitorChecked = [...componentValues.visitor_own_goal];
        isVisitorChecked[index] = isChecked;

        let visitor_own_goalStatus = [...componentValues.visitor_own_goal_status];
        visitor_own_goalStatus[index] = rabioButtonValue

        setComponentValues({
            ...componentValues, 
            visitor_own_goal: isVisitorChecked,
            visitor_scorer: visitorScorer,
            visitor_own_goal_status: visitor_own_goalStatus,
            visitor_assistant: visitorAssistant,
        });
    }
    
    return (

                <div className="league-match-scorer__container scroll">
                    {/* <TournamentScorerBoardComponent
                        local_team = { props.local_team }
                        visitor_team = { props.visitor_team }
                        local_score = { props.local_score }
                        visitor_score = {  props.visitor_score }
                    />                    */}
                    <div className='league-match-scorer_column-title baloa-table'>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelSubTitle')}
                    </div>
                    <div className="league-match-scorer__contianer__info-team-player">
                        <div className="league-match-scorer__cointainer__info-players__column">
                            <div className='league-match-scorer_container_column_inf-team'>
                                <div className='league-match-scorer_column_inf-team'>
                                    { props.local_team?.photo
                                        ? <img className="league-match-scorer_column_inf-team_photo" src={props.local_team?.photo} alt={'photo_team'} />
                                        : <span className="icon-ball" color={getRandonIconBallColor()} />}
                                    <div className='league-match-scorer_column_inf-team-name'>
                                        <div className="baloa-table">{props.local_team?.name}</div>
                                        <div className="baloa-mini">@{props.local_team?.teamname}</div>
                                    </div>
                                </div>
                                <div className='league-match-scorer_column_score-team baloa-subtitle-1'>
                                    {props.local_score}
                                </div>
                            </div>
                            <div className="league-match-scorer__contianer__info-player" id='scroll-local'>
                                {props.local_score === 0 &&
                                    <div className='league-match-scorer__contianer__empty-message baloa-table'>
                                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelEmpty')}
                                    </div>
                                }
                                {   
                                    [...Array(Number(props.local_score))]?.map((e, i) =>
                                        <div className="league-match-scorer__cointainer__scorer_input" key={i}>
                                            {/* <div className='league-match-scorer__own-goal__container'>
                                                <Checkbox
                                                    id={`chk__own-goal__local-${i}`}
                                                    name={"local_own_goal[" + i +"]"}
                                                    disabled={false}
                                                    className="league-match-scorer__own-goal__check"
                                                    checked={ componentValues.local_own_goal[i] }
                                                    onChange={ (e) => handleOnChangeLocalOwnGoal(e, i) }                                                    
                                                />
                                                <caption className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelOwnGoal')}</caption>
                                            </div> */}
                                            <div className='league-match-scorer__cointainer__scorer_radio-buttons'>
                                                <FormControl component="fieldset"
                                                    className={"league-match-scorer__cointainer__scorer_actions_content__button"}>
                                                    <RadioGroup row
                                                        aria-labelledby="playerStateInMatch"
                                                        name={"local_own_goal[" + i +"]"}
                                                        value={''}
                                                        className="league-match-scorer__cointainer__scorer__option-button inside">
                                                        <FormControlLabel
                                                            value="goal"
                                                            control={<Radio color="secondary" checked={componentValues.local_own_goal_status[i] === 'goal'} />}
                                                            label={<span className="baloa-username">
                                                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelGoal')}
                                                                </span>}
                                                            labelPlacement="end"
                                                            className={componentValues.local_own_goal_status[i] === 'goal' ? 'selected' : ''}
                                                            onChange={(e) => handleOnChangeLocalOwnGoal(e, i)}
                                                        />
                                                        <FormControlLabel
                                                            value="ownGoal"
                                                            control={<Radio color="secondary" checked={componentValues.local_own_goal_status[i] === 'ownGoal'} />}
                                                            label={<span className="baloa-username">
                                                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelOwnGoal')}
                                                                </span>}
                                                            labelPlacement="end"
                                                            className={componentValues.local_own_goal_status[i] === 'ownGoal' ? 'selected' : ''}
                                                            onChange={(e) => handleOnChangeLocalOwnGoal(e, i)}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className={`league-match-scorer-input_field_autcomplete local_scorer_${i}`}>
                                                <InputFieldAutocompleteComponent
                                                        key={i}
                                                        name={ "local_scorer[" + i +"]" }
                                                        options={ !componentValues.local_own_goal[i]? 
                                                                (props.local_team_members?.length !== 0 ? props.local_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}]):
                                                                (props.visitor_team_members?.length !== 0 ? props.visitor_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}])  
                                                        }
                                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}
                                                        optionLabelField={ "revelationPlayer" }
                                                        optionSelectedField={ "username" }
                                                        className="league-match-scorer__form__input"
                                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelName')}
                                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelNameHint')}
                                                        onChange={ ( v ) => { handleOnChangeLocalScorer( v, i )} }
                                                        value = { componentValues.local_scorer[i] }
                                                        freeSolo={true}
                                                    />
                                            </div>
                                            <div className='league-match-scorer__assigner_minute'>
                                                <div className={`league-match-scorer-input_field_autcomplete local_assistant${i}`}>
                                                    <InputFieldAutocompleteComponent
                                                        key={i}
                                                        name={ "local_assistant[" + i +"]" }
                                                        options={ !componentValues.local_own_goal[i]? 
                                                                (props.local_team_members?.length !== 0 ? props.local_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}]):
                                                                (props.visitor_team_members?.length !== 0 ? props.visitor_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}])  
                                                        }
                                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}
                                                        optionLabelField={ "revelationPlayer" }
                                                        optionSelectedField={ "username" }
                                                        className="league-match-scorer__form__input"
                                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelAssistance')}
                                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelNameHint')}
                                                        onChange={ ( v ) => { handleOnChangeLocalAssistance( v, i )} }
                                                        value = { componentValues.local_assistant[i] }
                                                        freeSolo={true}
                                                        disabled={componentValues.local_own_goal[i]}
                                                    />
                                                </div>
                                                <div className="league-match-scorer__input-container">
                                                    <InputMinuteComponent                 
                                                        id={"local_goal_minute_" + i }
                                                        name={"local_goal_minute[" + i +"]"}
                                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelMinute')}
                                                        onChange={ ( e ) => { handleOnChangeLocalMinute( e, i )} }
                                                        value = { componentValues.local_goal_minute[i] }                                            
                                                        className = { props.goalsValidations.local.includes(0) }
                                                        // validationFunction={ () => props.validationFunction(componentValues.local_scorer[i],componentValues.local_goal_minute[i])}
                                                        // element={'local'}
                                                        index={i}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>



                        <div className="league-match-scorer__cointainer__info-players__column">
                            <div className='league-match-scorer_container_column_inf-team'>
                                <div className='league-match-scorer_column_inf-team'>
                                    {  props.visitor_team?.photo
                                        ? <img className="league-match-scorer_column_inf-team_photo" src={props.visitor_team?.photo} alt={'photo_team'} />
                                        : <span className="icon-ball" color={getRandonIconBallColor()} />}
                                    <div className='league-match-scorer_column_inf-team-name'>
                                        <div className="baloa-table">{ props.visitor_team?.name}</div>
                                        <div className="baloa-mini">@{ props.visitor_team?.teamname}</div>
                                    </div>
                                </div>
                                <div className='league-match-scorer_column_score-team'>
                                    {props.visitor_score}
                                </div>
                            </div>
                            <div className="league-match-scorer__contianer__info-player" id='scroll-visitant'>
                                {props.visitor_score === 0 &&
                                    <div className='league-match-scorer__contianer__empty-message baloa-table'>
                                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelEmpty')}
                                    </div>
                                }
                                {   
                                [...Array(Number(props.visitor_score))].map((e, i) => 
                                    <div className="league-match-scorer__cointainer__scorer_input" key={i}>
                                            {/* <div className='league-match-scorer__own-goal__container'>
                                                <Checkbox
                                                    id={`chk__own-goal__visitor-${i}` }
                                                    name={"visitor_own_goal[" + i +"]"}
                                                    disabled={false}
                                                    className="league-match-scorer__own-goal__check"
                                                    checked={ componentValues.visitor_own_goal[i] }
                                                    onChange={ (e) => handleOnChangeVisitorOwnGoal(e, i) }                                                    
                                                />
                                                <caption className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelOwnGoal')}</caption>
                                            </div> */}
                                            <div className='league-match-scorer__cointainer__scorer_radio-buttons'>
                                                <FormControl component="fieldset"
                                                    className={"league-match-scorer__cointainer__scorer_actions_content__button"}>
                                                    <RadioGroup row
                                                        aria-labelledby="playerStateInMatch"
                                                        name={"visitor_own_goal[" + i +"]"}
                                                        value={''}
                                                        className="league-match-scorer__cointainer__scorer__option-button inside">
                                                        <FormControlLabel
                                                            value="goal"
                                                            control={<Radio color="secondary" checked={componentValues.visitor_own_goal_status[i] === 'goal'} />}
                                                            label={<span className="baloa-username">
                                                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelGoal')}
                                                                </span>}
                                                            labelPlacement="end"
                                                            className={componentValues.visitor_own_goal_status[i] === 'goal' ? 'selected' : ''}
                                                            onChange={(e) => handleOnChangeVisitorOwnGoal(e, i)}
                                                        />
                                                        <FormControlLabel
                                                            value="ownGoal"
                                                            control={<Radio color="secondary" checked={componentValues.visitor_own_goal_status[i] === 'ownGoal'} />}
                                                            label={<span className="baloa-username">
                                                                    {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelOwnGoal')}
                                                                </span>}
                                                            labelPlacement="end"
                                                            className={componentValues.visitor_own_goal_status[i] === 'ownGoal' ? 'selected' : ''}
                                                            onChange={(e) => handleOnChangeVisitorOwnGoal(e, i)}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className={`league-match-scorer-input_field_autcomplete visitor_scorer_${i}`}>
                                                <InputFieldAutocompleteComponent
                                                    key={i}
                                                    name={ "visitor_scorer[" + i +"]" }
                                                    options={ !componentValues.visitor_own_goal[i]? 
                                                        (props.visitor_team_members?.length !== 0 ? props.visitor_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}]):
                                                        (props.local_team_members?.length !== 0 ? props.local_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}])
                                                    
                                                    }
                                                    noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}
                                                    optionLabelField={ "revelationPlayer" }
                                                    optionSelectedField={ "username" }
                                                    className="league-match-scorer__form__input"
                                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelName')}
                                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelNameHint')}
                                                    onChange={ ( v ) => { handleOnChangeVisitorScorer( v, i )} }
                                                    value = { componentValues.visitor_scorer[i] }
                                                    freeSolo={true}                                                                                        
                                                />
                                            </div>
                                            <div className='league-match-scorer__assigner_minute'>
                                                <div className={`league-match-scorer-input_field_autcomplete visitor_assistant_${i}`}>
                                                    <InputFieldAutocompleteComponent
                                                        key={i}
                                                        name={ "visitor_assistant[" + i +"]" }
                                                        options={ !componentValues.visitor_own_goal[i]? 
                                                            (props.visitor_team_members?.length !== 0 ? props.visitor_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}]):
                                                            (props.local_team_members?.length !== 0 ? props.local_team_members : [{id:i,message:props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}])
                                                        
                                                        }
                                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.noOpt')}
                                                        optionLabelField={ "revelationPlayer" }
                                                        optionSelectedField={ "username" }
                                                        className="league-match-scorer__form__input"
                                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelAssistance')}
                                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelNameHint')}
                                                        onChange={ ( v ) => { handleOnChangeVisitorAssistance( v, i )} }
                                                        value = { componentValues.visitor_assistant[i] }
                                                        freeSolo={true}
                                                        disabled={componentValues.visitor_own_goal[i]}
                                                    />
                                                </div>
                                                <div className={`league-match-scorer__input-container visitor_goal_minute_${i}`}>
                                                    <InputMinuteComponent               
                                                        name={"visitor_goal_minute[" + i +"]"}
                                                        id={"visitor_goal_minute_" + i }
                                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScorers.labelMinute')}
                                                        onChange={ ( e ) => { handleOnChangeVisitorMinute( e, i )} }
                                                        value = { componentValues.visitor_goal_minute[i] }                                            
                                                        className = { props.goalsValidations.visitant.includes(i) }
                                                        // validationFunction={ () => props.validationFunction(componentValues.visitor_scorer[i],componentValues.visitor_goal_minute[i])}
                                                        // element={'visitor'}
                                                        index={i}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                )
                            } 
                            </div>                           
                        </div>
                    </div>
                </div>                

        )
}

export default withTranslation('league')(LeagueMatchScorerComponent);