// React
import React, { Component } from 'react';
import { connect } from 'react-redux';


// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './SignInNewPasswordRecoveryPanelComponent.styles.css';

// Actions
//import { ChangeErrorLogin } from 'app/authentication/actions/ErrosLogin';
import { signUpEmailAction, signInSendNewEmailPasswordAction } from 'app/authentication/actions/LoginActions';
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';


// Validators
//import { PhoneValidator } from 'shared/validators/PhoneValidator';
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';


class SignInNewPasswordRecoveryPanelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            disabled: false,
        };
        
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    validatePasswordMatch(password, verify_password) {
        if(password === verify_password) {
            const data = {
                "email": this.props.state.signup.email,
                "key": this.props.state.signup.recovery_password_code,
                "password": password
            }
            this.props.signInSendNewEmailPasswordAction(data);

        } else {
            const data = {
                'field': 'confirm_password',
                'error': 'Las contraseñas deben coincidir'
            }            
            this.props.changeSignUpError( data );
        }
    }

    resetErrorVerifyPassword(){
        const dataVerifyPassword = {
            'field': 'confirm_password',
            'error': ''
        }
        this.props.changeSignUpError( dataVerifyPassword);
    }
    
    
    render = () => {
        
        const formInitialValues = {
            password: "",
            confirm_password: ""
        }
        
        return (
            <div className="signin-new-password-recovery-panel__container">
                <div className="signin-new-password-recovery-panel__container__left-col"></div>
                <div className="signin-new-password-recovery-panel__container__right-col">
                    <h2 className="signin-new-password-recovery-panel__title">{this.props.t('loginEmail.recoverPassword')}</h2>
                    <text className="signin-new-password-recovery-panel__text">{this.props.t('loginEmail.newPasswordLabel')}</text>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { 
                            this.validatePasswordMatch(values.password, values.confirm_password);
                            } 
                        }
                        className = "signin-new-password-recovery-panel__form"
                        functionDisabled = { this.disabled.bind( this ) }
                    >
                        <div className="signup-email-form__fields">
                            
                            <label className="signup-email-form__label">{this.props.t('loginEmail.newPassword')}</label>
                            <InputFieldComponent
                                label=''
                                input_id="signin-new-password-recovery-panel__password"
                                field_name="password"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                                input_type="password"
                            />
                            <label className="signup-email-form__label">{this.props.t('password.input')}</label>
                            <InputFieldComponent
                                label=''
                                input_id="signin-new-password-recovery-panel__confirm-password"
                                field_name="confirm_password"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""
                                resetError={() => {this.resetErrorVerifyPassword()}}
                                error={this.props.state.signup_error.error}
                                message=""
                                input_type="password"
                            />
                        </div>
                        <div className="signin-new-password-recovery-panel__form__submit">
                            <PrimaryButtonComponent type="submit" disabled={!this.state.disabled} onClick={() => { }} className="signin-new-password-recovery-panel__form__submit">
                                <span>{this.props.t('loginEmail.finishBtn')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </FormComponent>                    
                    



                </div>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpEmailAction: (email_values) => {
            return dispatch(signUpEmailAction(email_values))
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        signInSendNewEmailPasswordAction: ( data ) => {
            return dispatch( signInSendNewEmailPasswordAction( data ))
        }
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignInNewPasswordRecoveryPanelComponent);
