// React
import React from 'react'

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// React device detect
import {isMobile} from 'react-device-detect';

// Styles
import './ProfileHistoricalSummaryComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileHistoricalSummaryComponent( props ) {

    function validateLockedData () {
        for(let key in props.profile_data) {
            if(props.profile_data[key] === 'ND') {
                return true;
            }
        }
        return false;
    }

    function validateEmptyData() {
        if(props.profile_data){
            return Object.values(props.profile_data).includes('ND');
        }else{
            return false;
        }        
    }

    return (
        <div className="profile-historical-summary__container">
            <div className="profile-historical-summary__title">
                <div className="baloa-subtitle-2">{props.t('tournamentSummary.title')}</div>
                <div className='profile-historical-summary__tooltip'>
                    <div className='baloa-table-column'>{props.t('tournamentSummary.toottip')}</div>
                    <span className='hubicon-help_fill'/>
                </div>
            </div>
            <div className="profile-historical-summary__row-values" >
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.tournaments_played === 'ND' || !props.profile_data) 
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.tournaments_played}</div>
                    }
                    <div className='baloa-normal-medium'>{!isMobile ? props.t('tournamentSummary.labelTournaments') : props.t('menuLabels.tournaments')}</div>
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.undefeated_fences === 'ND' || !props.profile_data)
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.player_match}</div>
                    }
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelUndefeat')}</div>
                </div>                
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.minutes_played === 'ND' || !props.profile_data) 
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.minutes_played}</div>
                    }
                    <div className='baloa-normal-medium'>{!isMobile ? props.t('tournamentSummary.labelMinutes') : props.t('tournamentSummary.tagMinutes')}</div>
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.games_played === 'ND' || !props.profile_data) 
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.games_played}</div>
                    }
                    <div className='baloa-normal-medium'>{!isMobile ? props.t('tournamentSummary.labelMatches') : props.t('tournamentSummary.tagMatches')}</div>
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.played_as_titular === 'ND' || !props.profile_data) 
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.played_as_titular}</div>
                    }
                    <div className='baloa-normal-medium'>{ props.t('tournamentSummary.labelStarter') }</div>
                </div>                                
            </div>
            <div className='profile-historical-summary__row-values'>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.goals === 'ND' || !props.profile_data) 
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.goals}</div>
                    }
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelGoals')}</div>
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.assistance === 'ND' || !props.profile_data)
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.assistance}</div>
                    }
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelAssists')}</div>
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.unbeaten_games === 'ND' || !props.profile_data)
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.unbeaten_games}</div>
                    }
                    <div className='baloa-normal-medium'>{props.t('tournamentSummary.labelUndefeatedFences')}</div>
                </div>                
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.yellow_cards === 'ND' || !props.profile_data)
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.yellow_cards}</div>
                    }
                    <div className='profile-historical-summary__col__yellow-card' />
                </div>
                <div className='profile-historical-summary__col' >
                    {(props.profile_data?.red_cards === 'ND' || !props.profile_data)
                        ? <span className='hubicon-lock' />
                        : <div className='baloa-subtitle-2'>{props.profile_data?.red_cards}</div>
                    }
                    <div className='profile-historical-summary__col__red-card' />
                </div>
            </div>
            {props.profile_tournament_paid &&
                <div className='profile-historical-summary__unlock-values__action'>
                    <div className='profile-historical-summary__unlock-values__text'>
                        <span className='hubicon-info' />
                            { props.is_owner && <div className='baloa-normal-medium'>{
                                validateLockedData() 
                                ? props.t('tournamentSummary.blockedEmptyDataTournament') // blockedText
                                : props.t('tournamentSummary.alternativeblockedText') 
                                }</div>
                            }
                        { !props.is_owner && <div className='baloa-normal-medium'>
                            { validateLockedData() 
                                ? props.t('tournamentSummary.visitantBlockedText', {user_name: props.username}) 
                                : props.t('tournamentSummary.alternativeVisitantBlockedTex', {user_name: props.username}) 
                            }
                            </div>
                        }
                    </div>
                    {!validateEmptyData()
                        ?  props.is_owner &&    
                            <PrimaryButtonComponent onClick={() => {props.handleModalUnlockData('historical_summary')}} >
                                {props.t('tournamentSummary.unlockBtn')}
                            </PrimaryButtonComponent>
                        : ''
                    }
                </div>
            }
            {!props.profile_data &&
                <div className='profile-historical-summary__unlock-values__action'>
                    <div className='profile-historical-summary__unlock-values__text'>
                        <span className='hubicon-info' />
                            { props.is_owner && <div className='baloa-normal-medium'>{
                                props.t('tournamentSummary.blockedEmptyDataTournament') // blockedText
                            }</div>
                            }
                        { !props.is_owner && <div className='baloa-normal-medium'>
                            {
                                props.t('tournamentSummary.visitantEmptyDataTournament', {user_name: props.username}) 
                            }
                            </div>
                        }
                    </div>                   
                </div>
            }
        </div>
    )
}

export default withTranslation('profile')(ProfileHistoricalSummaryComponent);

