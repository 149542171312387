/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
// import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';


// Styles
import './PaymentsActiveModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

function PaymentsActiveModalComponent ( props ) {
  
  const[businessType, setBusinessType] = React.useState('');
  const[countryType, setCountryType] = React.useState('');
  const formInitialValues = { country_payment_modal: '', bussiness_type: '' };

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('paymentButtomActive')}
            className="payment_modal_container"
        >
        
        <div className='payments_active_modal'>
        <FormComponent
          formInitialValues={formInitialValues}
          onSubmit={(  ) => {}}
        >
            <React.Fragment>
            
              <div className="payments_active_modal-information">
                <p className="baloa-table">
                    { props.t("modalsActive.paymentText")}
                </p>
              </div>
              <div className="payments_active_modal-box">
                <div className='payments_active_modal-container location'>
                    <SelectFieldComponent
                        label={props.t("modalsActive.paymentsLocation")}
                        field_name={"country"}
                        input_id="country"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        className="payments_active_modal_title"
                        type="text"
                        resetError={() => {}}
                        error={{country: ''}}
                        message=""
                        options={props.country_tag} 
                        onChange={(v)=>{setCountryType(v)}}
                        
                    />
                  </div>
                <div className='payments_active_modal-container busuness'>
                  <SelectFieldComponent
                        label={props.t("modalsActive.paymentsBusiness")}
                        field_name="bussiness_type"
                        validationFunction={RequiredValidator}
                        disabled={countryType == '' && true}
                        className="payments_active_modal_title"
                        type="text"
                        resetError={() => {}}
                        error={{bussiness_type: ''}}
                        message=""
                        options={props.type_tag} 
                        onChange={(v)=>{setBusinessType(v)}}
                      />
                </div>
              </div>
              <div className='payments_active_modal_button'>
                <PrimaryButtonComponent
                  input_type='button'
                  className=''
                  onClick={ () => {props.handelOnloadPaymentForm(businessType, countryType)}}
                  disabled={(countryType != '' && businessType != '') ? false : true }
              >
                  <span>{props.t('modalsActive.paymentsStart')}</span>
              </PrimaryButtonComponent>
              </div>
            </React.Fragment>
        </FormComponent>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('payments')(PaymentsActiveModalComponent);