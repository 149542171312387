// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-followers-modal-tabs {
    margin-top: var(--space-and-half);
    padding-left: var(--space-and-half);
    padding-right: var( --space-x2);
    margin-bottom: var(--space-and-half);
}

.profile-followers-modal-tabs .MuiTab-wrapper {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: var(--font-medium);
    font-size: calc(var(--space) + 1px);
    line-height: calc(var(--space) + var(--space-quarter));
    letter-spacing: 0.005em;
    color: var(--primary-dark-color);
    text-transform: none;
}

.profile-followers-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-followers-modal/ProfileFollowersModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mCAAmC;IACnC,+BAA+B;IAC/B,oCAAoC;AACxC;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,+BAA+B;IAC/B,mCAAmC;IACnC,sDAAsD;IACtD,uBAAuB;IACvB,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".profile-followers-modal-tabs {\n    margin-top: var(--space-and-half);\n    padding-left: var(--space-and-half);\n    padding-right: var( --space-x2);\n    margin-bottom: var(--space-and-half);\n}\n\n.profile-followers-modal-tabs .MuiTab-wrapper {\n    font-family: var(--secondary-font);\n    font-style: normal;\n    font-weight: var(--font-medium);\n    font-size: calc(var(--space) + 1px);\n    line-height: calc(var(--space) + var(--space-quarter));\n    letter-spacing: 0.005em;\n    color: var(--primary-dark-color);\n    text-transform: none;\n}\n\n.profile-followers-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
