
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
// import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Formik
import { Field } from 'formik';

// Styles
import './PageTypeModalComponent.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';


function PageTypeModalComponent( props ) {
    
    const [state, setState] = React.useState(
        {
            page_type_form_is_valid: undefined,
        }
    );

    const formInitialValues = {
        page_type: '',
    }

    const onFormProps = ( form_props ) => {
        if ( form_props.isValid && !state.page_type_form_is_valid ) {
            setState( { page_type_form_is_valid: true } )
        }
        if( !form_props.isValid && state.page_type_form_is_valid ) {
            setState( { page_type_form_is_valid: false } ) 
        }
    }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('newPage.button')} 
        >
            <div className="page-type-form scroll-parent">

                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        let new_page_type = values['page_type'];
                        props.onNewData( new_page_type );
                    }}
                    className="page-type-form__form form scroll-parent"
                    functionDisabled={ ( form_props ) => { onFormProps( form_props ) } }
                >
                    <div className="page-type-form__form__fields form__fields scroll">
                        <Field name="page_type" validate={ RequiredValidator }>
                            {
                                ({
                                    field,
                                    form: { setFieldValue }, 
                                    meta,
                                }) => (

                                    <React.Fragment>
                                        {props.selectedCategoryList.map((category) => {

                                            return (
                                                <div className="page-type-form__list-item baloa-body-1" onClick = { () => { props.onNewData( category.page_type ) } }>
                                                    {props.t('newPage.type.'+category.name)}
                                                    <div><span className="icon-chevron-roght"></span></div>
                                                </div>
                                            )
                                        })
                                        }
                                        {/*<div 
                                        onClick = { () => { setFieldValue('page_type', 'friends') } } 
                                        className={ "page-type-form__form__fields__field " + ( field.value == 'friends' ? 'active' : '' ) }
                                        >
                                            <div className="page-type-form__form__fields__field__icon">
                                                <span className="icon-group-friends"></span>
                                            </div>
                                            <div className="page-type-form__form__fields__field__info">
                                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.friends.title')}</p>
                                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                                {props.t('newPage.friends.text')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('page_type', 'team') } } 
                                        className={ "page-type-form__form__fields__field " + ( field.value == 'team' ? 'active' : '' ) }
                                        >
                                            <div className="page-type-form__form__fields__field__icon">
                                                <span className="icon-team-2-64"></span>
                                            </div>
                                            <div className="page-type-form__form__fields__field__info">
                                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.team.title')}</p>
                                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                                    {props.t('newPage.team.text')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('page_type', 'company') } } 
                                        className={ "page-type-form__form__fields__field " + ( field.value == 'company' ? 'active' : '' ) }
                                        >
                                            <div className="page-type-form__form__fields__field__icon">
                                                <span className="icon-company"></span>
                                            </div>
                                            <div className="page-type-form__form__fields__field__info">
                                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.company.title')}</p>
                                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                                    {props.t('newPage.company.text')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('page_type', 'agency') } } 
                                        className={ "page-type-form__form__fields__field " + ( field.value == 'agency' ? 'active' : '' ) }
                                        >
                                            <div className="page-type-form__form__fields__field__icon">
                                                <span className="icon-agency"></span>
                                            </div>
                                            <div className="page-type-form__form__fields__field__info">
                                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.agency.title')}</p>
                                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                                    {props.t('newPage.agency.text')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick = { () => { setFieldValue('page_type', 'league') } } 
                                        className={ "page-type-form__form__fields__field " + ( field.value == 'league' ? 'active' : '' ) }
                                        >
                                            <div className="page-type-form__form__fields__field__icon">
                                                <span className="icon-trophy"></span>
                                            </div>
                                            <div className="page-type-form__form__fields__field__info">
                                                <p className="page-type-form__form__fields__field__caption baloa-body-1">{props.t('newPage.league.title')}</p>
                                                <p className="page-type-form__form__fields__field__description baloa-body-2">
                                                    {props.t('newPage.league.text')}
                                                    <span className="icon-chevron-roght"></span>
                                                </p>
                                            </div>
                                        </div>*/}
                                    </React.Fragment>
                                )
                            }
                        </Field>
                    </div>
                    
                    <div className="page-type-form__form__actions form__actions">
                        {/*<PrimaryButtonComponent 
                            type="submit"
                            disabled={!state.page_type_form_is_valid} 
                            onClick={()=>{}} 
                            className="page-type-form__form__submit"
                        >
                            <span>{props.t('newPage.privacy.button')}</span>
                        </PrimaryButtonComponent>
                        */}
                    </div>
                    
                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('page')(PageTypeModalComponent);

//export default PageTypeModalComponent;