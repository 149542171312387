// React
import React from 'react';
// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentDynamicFormMakerComponent from '../tournament-dynamic-form-maker/TournamentDynamicFormMakerComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//i18
import { withTranslation } from 'react-i18next';
import './TournamentEditFormModalComponent.styles.css';

function TournamentEditFormComponent(props) {
    const [newPlayerForm, setNewPlayerForm] = React.useState();   // eslint-disable-line
    const [newTeamForm, setNewTeamForm] = React.useState();   // eslint-disable-line
    const [teamForm, setTeamForm] = React.useState();
    const [playerForm, setPlayerForm] = React.useState();
    const [submitTeamPlayerForm, setSubmitTeamPlayerForm] = React.useState(false);
    const REGEX = /edit/gm;

    const handleDynamicFormData = (form) => {
        const new_form = form.map((question, index) => {
            return {
                caption: question.caption,
                question_type: question.input_type,
                is_required: question.is_required,
                order_position: index + 1,
                answers: validationAnswers(question.answers)
            }
        });
        return new_form;
    };

    const validationAnswers = (array_answers) => {
        const new_answers = array_answers?.filter(answer => answer.caption !== undefined) ?? [];
        return new_answers.map((element, index) => {
            return {
                caption: element.caption,
                is_default: element.defaultValue,
                order_position: index + 1
            }
        });
    };

    const handleOnClosedModal = () => {
        setSubmitTeamPlayerForm(false);
        props.onClose();
    };

    const sendToEditFormTournament = (form, form_type) => {
        let new_form = [];
        if (form_type === 'PLAYER_FORM') {
            setNewPlayerForm(handleDynamicFormData(form));
            new_form = handleDynamicFormData(form);
        }
        else if (form_type === 'TEAM_FORM') {
            setNewTeamForm(handleDynamicFormData(form));
            new_form = handleDynamicFormData(form);
        };
        //console.log(new_form);
        props.sendToEditFormTournament(new_form, form_type);
    };

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={() => handleOnClosedModal()}
            title={ props.modal_confirmation_back_edit
                ? '' : REGEX.test(props.modal_type_edit_form) 
                    ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.editForm') 
                    : props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.createForm')}
        >
            <div className={`modal-edit-form-tournament_container_${props.modal_confirmation_back_edit ? 'small': 'big'}`}>
                {props.modal_type_edit_form === 'edit_player_form' &&
                    <TournamentDynamicFormMakerComponent
                        onNewFormValues={(form) => {
                            sendToEditFormTournament(form, 'PLAYER_FORM');
                            setTeamForm(form);
                        }}
                        form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormTitle')}
                        form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormLabel')}
                        submitTeamPlayerForm={submitTeamPlayerForm}
                        resetButton={() => { setSubmitTeamPlayerForm(false) }}
                        formData={teamForm}
                        handleSaveTournamentForm={true} // This option only save the state to form and hidden preview
                    />
                }
                {props.modal_type_edit_form === 'create_player_form' &&
                    <TournamentDynamicFormMakerComponent
                        onNewFormValues={(form) => {
                            sendToEditFormTournament(form, 'PLAYER_FORM');
                            setPlayerForm(form);
                        }}
                        form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormTitle')}
                        form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.playerFormLabel')}
                        submitTeamPlayerForm={submitTeamPlayerForm}
                        resetButton={() => { setSubmitTeamPlayerForm(false) }}
                        formData={playerForm}
                        handleSaveTournamentForm={true} // This option only save the state to form and hidden preview
                    />
                }
                {props.modal_type_edit_form === 'edit_team_form' &&
                    <TournamentDynamicFormMakerComponent
                        onNewFormValues={(form) => {
                            sendToEditFormTournament(form, 'TEAM_FORM');
                            setPlayerForm(form);
                        }}
                        form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormTitle')}
                        form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormLabel')}
                        submitTeamPlayerForm={submitTeamPlayerForm}
                        resetButton={() => { setSubmitTeamPlayerForm(false) }}
                        formData={teamForm}
                        handleSaveTournamentForm={true} // This option only save the state to form and hidden preview
                    />
                }
                {props.modal_type_edit_form === 'create_team_form' &&
                    <TournamentDynamicFormMakerComponent
                        onNewFormValues={(form) => {
                            sendToEditFormTournament(form, 'TEAM_FORM');
                            setPlayerForm(form);
                        }}
                        form_title={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormTitle')}
                        form_description={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.teamFormLabel')}
                        submitTeamPlayerForm={submitTeamPlayerForm}
                        resetButton={() => { setSubmitTeamPlayerForm(false) }}
                        formData={teamForm}
                        handleSaveTournamentForm={true} // This option only save the state to form and hidden preview
                    />
                }
                {props.modal_confirmation_back_edit &&
                    <div className="tournament-edit-form-confirmations-buttons_container">
                        <p>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.confirmationBack')}</p>
                        <div className="tournament-edit-form-confirmations-buttons">
                            <SecondaryButtonComponent
                                type="button"
                                disabled={false}
                                onClick={() => { props.onClose()}}
                                className="tournament-edit-basic-data__cancel-btn">
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditStopEditBtn')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent
                                type="submit"
                                onClick={() => {props.handlebackToSummaryTournament()}}
                                className="edit-basic-data__form__submit">
                                <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.exitEdit')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                }
            </div>
        </Modal2Component>
    )
}
export default withTranslation('league')(TournamentEditFormComponent);