// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './SignUpEmailCodeVerificationPanelComponent.styles.css';

// Actions
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { signUpEmailVerificationAction, signUpResendEmailVerificationAction } from 'app/authentication/actions/LoginActions';

// Validators
import { OtpValidator } from 'shared/validators/SignUpValidator';
//import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';

import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import { isMobile } from 'react-device-detect';


class SignUpEmailCodeVerificationPanelComponent extends Component {

    constructor(props){
        super(props);
        this.state = { 
            disabled: false,
            minutes_left: 2,
            seconds_left: 0,
            timer_id: undefined,
            sended: true,
            resended: false,
            isValid: false
        };
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({isValid:true});
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({isValid:false});
            this.setState({disabled:false}) 
        }
    }
    
    resetError(){
        const data = {
            'field':'code',
            'error':''
        }
        this.props.changeSignUpError(data);  
    }

    stopTimers() {

        for (var i = 1; i < 99999; i++)
                window.clearInterval(i);

        this.setState( { seconds_left: 0 });
        this.setState( { minutes_left: 2 });                

    }

    startTimer() {

        if(!this.state.isValid && this.state.resended){
            
            this.setState({resended:false}) 
            
        }

        this.state.timer_id = setInterval( () => {   // eslint-disable-line
            this.setState( { seconds_left: this.state.seconds_left - 1 });
            if ( this.state.seconds_left < 0 ) {
                this.setState( { seconds_left: 59 });
                this.setState( { minutes_left: this.state.minutes_left - 1 });
                if( this.state.minutes_left < 0 ) {
                    this.setState( { seconds_left: 0 });
                    this.setState( { minutes_left: 2 });
                    clearInterval( this.state.timer_id );
                    this.setState( { sended: false });
                    this.setState({resended:false}) 
                }
            }
        }, 1000 );
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval( this.state.timer_id );
    }
    
    render = () => {
        const formInitialValues = {
            code: '',
        }

        return (

            <div className="signup-email-code-verification-panel__container">
                <div className="signup-email-code-verification-panel__container__left-col">
                    <Link to="/">
                    {!isMobile
                        ?
                        <img className="signup-email-code-verification-panel__image" src={baloa_logo} alt=""/>
                        :
                        <img className="signup-email-code-verification-panel__image" src={baloa_green_logo} alt="" />
                    }
                        
                    </Link>
                </div>
                
                <div className="signup-email-code-verification-panel__container__right-col">
                    
                    {/* Title */}
                    <div className="signup-email-code-verification-panel__container-title">
                        <span className='icon-arrow-left baloa-headline-1' onClick   = { () => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN_EMAIL'] ) }/>
                        <div className="signup-email-code-verification-panel__title baloa-headline-1">{this.props.t('signUpEmailVerification.title')}</div>                        
                    </div>                    
                    <div className="signup-email-code-verification-panel__text">{this.props.t('signUpEmailVerification.message') } <span>{this.props.state.signup.email }</span></div>
                    
                    {/* Email Form */}
                    <FormComponent 
                        formInitialValues = { formInitialValues } 
                        onSubmit          = { ( values, actions ) => {                             
                            values['email'] = this.props.state.signup.email;
                            this.props.signUpEmailVerificationAction( values, actions, this.props.location_pathname );
                        } }
                        className         = "signup-email-code-verification-panel__form"
                        functionDisabled  = { this.disabled.bind(this) }
                    >
                        <label className="signup-email-code-verification-panel__form__label" >{this.props.t('code.title')}</label>
                        <InputFieldComponent 
                            input_id           = "signup-email-code-ver" 
                            field_name         = "code"
                            validationFunction = { OtpValidator }
                            disabled           = { false }
                            className          = "signup-email-code-verification-panel__form__field"
                            type               = "text"
                            resetError         = { () => this.resetError() }
                            error              = { this.props.state.signup_error.error }
                            message            = ""
                            maxLength          = "5"
                        />
                        <div className="signup-email-code-verification-panel__retry-code__valid-for">
                            <div className="signup-email-code-verification-panel__retry-code__valid-for__text">{this.props.t('signUpEmailVerification.expireMessage')}</div> 
                            <div className="signup-email-code-verification-panel__retry-code__valid-for__text-alert">
                                { this.state.seconds_left > 9? `${ this.state.minutes_left }:${this.state.seconds_left} ${this.props.t('signUpEmailVerification.minutesLabel') }`: `${ this.state.minutes_left }:0${this.state.seconds_left} ${this.props.t('signUpEmailVerification.minutesLabel') }` }
                            </div>
                        </div>
                        <div className="signup-email-code-verification-panel__action-buttons">
                            <SecondaryButtonComponent 
                                disabled = {this.state.sended && this.state.isValid}
                                onClick   = { () => { 
                                    this.props.signUpResendEmailVerificationAction( { email: this.props.state.signup.email } );
                                    this.setState( { resended: true } );
                                    this.stopTimers();
                                    this.startTimer();
                                    this.setState( { sended: true } );                                
                                    this.setState( { isValid: true } );
                                } } 
                                className = "signup-email-code-verification-panel__action-buttons__resend-btn">
                                <span>{this.props.t('signUpEmailVerification.retryCode')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent type="submit" disabled={false} onClick={()=>{}} className = "signup-email-code-verification-panel__action-buttons__submit">
                                <span>{this.props.t('code.button')}</span>
                            </PrimaryButtonComponent>
                            {/* <SecondaryButtonComponent disabled={this.state.sended} onClick={()=>{}} className="retry-otp__options__option">
                                    <span>Enviar a Whatsapp</span>
                            </SecondaryButtonComponent> */}
                        </div>                        
                    </FormComponent>
                    {/* <TextButtonComponent 
                        className = "signup-email-code-verification-panel__form__change-method"
                        onClick   = { () => this.props.changeSignUpStatus( SIGNUP_STATUS['START'] ) } >
                        <span>{this.props.t('code.method')}</span>
                    </TextButtonComponent>                     */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpEmailVerificationAction: ( values, actions, pathname ) => {
            return dispatch( signUpEmailVerificationAction( values, actions, pathname ) )
        },
        signUpResendEmailVerificationAction: ( values, data ) => {
            return dispatch( signUpResendEmailVerificationAction( values, data ) )
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        changeSignUpError : ( values ) => {
            return dispatch( changeSignUpError( values ) )
        }
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpEmailCodeVerificationPanelComponent);