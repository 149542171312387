// React
import React from 'react';

// Components
//import TableComponent from 'shared/components/dynamic-table/TableComponent';
import LeagueTournamentComponent from '../league-tournament-component/LeagueTournamentComponent';


// Styles
import './LeagueListTeamTournamentsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueListTeamTournamentsComponent( props ) {

    /* function handleTournamentStates (tournaments) {
        let listArray = [];
        tournaments.forEach(element => {
            listArray.push({ 
                name: element.name, main_country: 
                element.main_country, main_city: element.main_city,
                start_date: element.start_date,
                end_date: element.end_date,
                state: props.t(`tournamentStates.${element.state}`),
                id:element.tournament_id,
                league_page: element.league_page
            })
        });
        return listArray;
    } */

    /*Function to translate tournament category  (tournament_tag) */
    /* function trastaleTournamentCategory (tournament_list) {
        let tournament_final = [];
        tournament_list.map((torneo) =>{ // eslint-disable-line
            
            let list_tournament ={...torneo, category: torneo.tournament_tag != null ? props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams." + torneo.tournament_tag.extra.value) : torneo.category}
            console.log(list_tournament);
            tournament_final.push(list_tournament)
        })
        return tournament_final;
    } */

    return(
        <React.Fragment>
            {/* <TableComponent  
                rows = { handleTournamentStates(props.tournaments) }
                columns = { [
                    "",
                    "name",
                    "main_country",                         
                    "main_city",
                    "start_date",
                    "end_date",
                    //"category",
                    "state"
                ] }
                columns_headers = { [
                    "",
                    props.t('team.tabs.tournaments.nameLabel'),
                    props.t('team.tabs.tournaments.maincountryLabel'),     
                    props.t('team.tabs.tournaments.maincityLabel'),      
                    props.t('team.tabs.tournaments.stardateLabel'),                             
                    props.t('team.tabs.tournaments.endateLabel'),      
                    //props.t('team.tabs.tournaments.categoryLabel'), 
                    props.t('team.tabs.tournaments.stateLabel')           
                ] }
                has_image_column = { false }
                image_column = { 0 }
                rows_per_page = { 15 }
                pagination = { true }
                handleOnActionClick = { (v)=>{ props.handleViewTournament(v) }}
                action_column = { false }
                search_by = "name"
                order_by = "name"
                has_searchbar = { false }
                has_add_button = { false }  
                team = { false }
                msg_empty_table_title={props.t('team.tabs.tournaments.emptyMemberTableTitle')}
                msg_empty_table_text={props.t('team.tabs.tournaments.emptyMemberTableText')}
                itemClicLabel={ props.t('team.tabs.tournaments.teamsViewMemberLabel') }
                link_column={1}
            >
            </TableComponent> */}
            {/* <div>{'componente de torneos- Detalles del equipos'}</div> */}
            <LeagueTournamentComponent // LeagueTournamentGeneralListComponent
                tournament_tag = {props.tournament_tag}
                league_page = {props.league_page}
                tournamentHub={false}
                //tournamentTile={props.t('options.tournaments.labelCreateTournament')}
                filterType={'tournamentList'} // tournamentAll - tournamentSport - tournamentVideoGame
                //onResetError={(data) => {this.props.changeTournamentBasicDataError(data)}}
                //errors={this.props.state.leagueTournaments.errors.list_tournament_filters}
                active_tournaments= { props.tournaments?.filter(function( el ) { return el.state.toLowerCase() !== "finalized" }) }
                completed_tournaments={ props.tournaments?.filter(function( el ) { return el.state.toLowerCase() === "finalized" }) }
                //handleBackButton = { () => this.handleBackButton() } 
                handleOnActiveTournamentActionClick={ props.handleViewTournament }
                handleOnCompletedTournamentActionClick={ props.handleViewTournament } 
                //handleFilterTournament={ (value) => { this.handleOnFilterTournament(value)} }                     
                //league_teams_is_loading = {this.props.state.leagues.league_teams_is_loading}
                showFilter={false}
                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
            />
        </React.Fragment>
    )
}

export default withTranslation(['team','league'])(LeagueListTeamTournamentsComponent);

