/*eslint eqeqeq: "off"*/
// React
import React, { useState, useEffect } from 'react';

// Components
import InputArrayFieldAutocompleteWithValidationComponent from 'shared/components/input-array-field-autocomplete-with-validation/InputArrayFieldAutocompleteWithValidationComponent.js';
import InputMinuteComponent from 'shared/components/input-minute/InputMinuteComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//import TournamentScorerBoardComponent from '../tournament-scorer-board/TournamentScorerBoardComponent';

// Styles
import './LeagueMatchChangesComponent.styles.css';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

function LeagueMatchChangesComponent(props) {

    const SIZE_LOCAL_CHANGES_ARRAY = props.endGameState.changes?.local?.filter((player) => player.player_in !== null).length ?? 0;
    const SIZE_VISITOR_CHANGES_ARRAY = props.endGameState.changes?.visitant?.filter((player) => player.player_in !== null).length ?? 0;
    const [componentValues, setComponentValues] = useState({
        local_player_in: [null, null, null, null, null, null, null, null, null, null, null, null],
        local_player_out: [null, null, null, null, null, null, null, null, null, null, null, null],
        local_minute_change: [],
        visitor_player_in: [null, null, null, null, null, null, null, null, null, null, null, null],
        visitor_player_out: [null, null, null, null, null, null, null, null, null, null, null, null],
        visitor_minute_change: []
    });
    const [localChanges, setLocalChanges] = useState(props.endGameState.changes?.local ? SIZE_LOCAL_CHANGES_ARRAY : 0);
    const [visitorChanges, setVisitorChanges] = useState(props.endGameState.changes?.visitant ? SIZE_VISITOR_CHANGES_ARRAY : 0);
    const [auxLocalMembers, setAuxLocalMembers] = useState(props.local_team_members); 
    const [selectedLocalPyInId, setSelectedLocalPyInId] = useState('');
    const [selectedLocalPyOutId, setSelectedLocalPyOutId] = useState('');
    const [auxVisitorMembers, setAuxVisitorMembers] = useState(props.visitor_team_members);
    const [selectedVisitorPyInId, setSelectedVisitorPyInId] = useState('');
    const [selectedVisitorPyOutId, setSelectedVisitorPyOutId] = useState('');

    useEffect(() => {
        if (Object.entries(props.initialValues).length !== 0) {
            setComponentValues(props.initialValues);
        }
    }, [props]);

    useEffect(() => {
        if (props.initialValues.local_player_in?.length > 0) {
            (props.initialValues?.local_player_in).forEach(player => {
                if (player) handleChangeLocalPlayerState(player.id, 'inGame');
            });
            (props.initialValues?.local_player_out).forEach(player => {
                if (player) handleChangeLocalPlayerState(player.id, 'outGame');
            });
        }
        if (props.initialValues.visitor_player_in?.length > 0) {
            (props.initialValues?.visitor_player_in).forEach(player => {
                if (player) handleChangeVisitorPlayerState(player.id, 'inGame');
            });
            (props.initialValues?.visitor_player_out).forEach(player => {
                if (player) handleChangeVisitorPlayerState(player.id, 'outGame');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues]);

    const addLocalChangeClick = () => {
        if (localChanges < 9) {
            setLocalChanges(localChanges + 1);
        } else {
            props.handleOnMatchChanges();
        }
        if (selectedLocalPyInId) handleChangeLocalPlayerState(selectedLocalPyInId, 'inGame');
        if (selectedLocalPyOutId) handleChangeLocalPlayerState(selectedLocalPyOutId, 'outGame');
        validatePreviosLocalChange();
    };

    const validatePreviosLocalChange = () =>{
        const arrayPlayerInSize = componentValues.local_player_in?.filter((player) => player !== null).length;
        const arrayPlayerOutSize = componentValues.local_player_out?.filter((player) => player !== null).length;
        let localPlayerIn = cloneDeep(componentValues.local_player_in);
        let localPlayerOut = cloneDeep(componentValues.local_player_out);
        //localPlayerIn[arrayPlayerInSize === 0 ? arrayPlayerInSize : (arrayPlayerInSize - 1 )].blockedPlayer = true;
        //localPlayerOut[arrayPlayerOutSize === 0 ? arrayPlayerOutSize : (arrayPlayerOutSize - 1 )].blockedPlayer = true;
        if(arrayPlayerInSize > 0){
            localPlayerIn[arrayPlayerInSize - 1].blockedPlayer = true;
        }
        if(arrayPlayerInSize > 0){
            localPlayerOut[arrayPlayerOutSize - 1].blockedPlayer = true;
        }        
        setComponentValues({
            ...componentValues,
            local_player_in: localPlayerIn,
            local_player_out: localPlayerOut,
        });
    }

    const addVisitorChangeClick = () => {
        if (visitorChanges < 9) {
            setVisitorChanges(visitorChanges + 1);
        } else {
            props.handleOnMatchChanges();
        }
        if (selectedVisitorPyInId) handleChangeVisitorPlayerState(selectedVisitorPyInId, 'inGame');
        if (selectedVisitorPyOutId) handleChangeVisitorPlayerState(selectedVisitorPyOutId, 'outGame');
        validatePreviosVisitorChange();
    };

    const validatePreviosVisitorChange = () =>{
        const arrayPlayerInSize = componentValues.visitor_player_in?.filter((player) => player !== null).length;        
        const arrayPlayerOutSize = componentValues.visitor_player_out?.filter((player) => player !== null).length;
        let visitorPlayerIn = cloneDeep(componentValues.visitor_player_in);
        let visitorPlayerOut = cloneDeep(componentValues.visitor_player_out);
        //visitorPlayerIn[arrayPlayerInSize === 0 ? arrayPlayerInSize : (arrayPlayerInSize - 1 )].blockedPlayer = true;
        //visitorPlayerOut[arrayPlayerOutSize === 0 ? arrayPlayerOutSize : (arrayPlayerOutSize - 1 )].blockedPlayer = true;
        if(arrayPlayerInSize > 0){
            visitorPlayerIn[arrayPlayerInSize - 1].blockedPlayer = true;
        }
        if(arrayPlayerInSize > 0){
            visitorPlayerOut[arrayPlayerOutSize - 1].blockedPlayer = true;
        }
        setComponentValues({
            ...componentValues,
            visitor_player_in: visitorPlayerIn,
            visitor_player_out: visitorPlayerOut,
        });
    }

    const removeVisitorChangeClick = (index) => {
        const arrayVisitorInSize = componentValues.visitor_player_in?.filter((player) => player !== null).length;
        const arrayVisitorOutSize = componentValues.visitor_player_in?.filter((player) => player !== null).length;
        removeVisitorPlayerFormState(index, arrayVisitorInSize === 0 ? 1 : (arrayVisitorInSize - 1));

        if(index === 0 && visitorChanges> 1){
            setVisitorChanges(0);
            setComponentValues({
                ...componentValues,
                visitor_player_in: [null, null, null, null, null, null, null, null, null, null, null, null],
                visitor_player_out: [null, null, null, null, null, null, null, null, null, null, null, null],
                visitor_minute_change: []
            });
            setAuxVisitorMembers(props.visitor_team_members);
        }else{
            setVisitorChanges(visitorChanges - 1);
            if(componentValues.visitor_player_in[index]){
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_in[index].id).state = 'stanby';
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_in[index].id).blockedPlayer = false;
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_in[index === 0 ? index : (index -1 )].id).blockedPlayer = false;
            }

            if(componentValues.visitor_player_out[index]){
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_out[index].id).state = 'stanby';
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_out[index].id).blockedPlayer = false;
                auxVisitorMembers.find((player) => player.id === componentValues.visitor_player_out[index === 0 ? index : (index -1 )].id).blockedPlayer = false;
            }

            let visitorPlayerIn = cloneDeep(componentValues.visitor_player_in);
            let visitorPlayerOut = cloneDeep(componentValues.visitor_player_out);
            let visitorPlayerMinute = cloneDeep(componentValues.visitor_minute_change);

            //visitorPlayerIn[index === 0 ? index : (index -1 )].blockedPlayer = false;
            //visitorPlayerIn[index === 0 ? index : (index -1 )].state = 'inGame';
            if(visitorPlayerIn.length > 0 && visitorPlayerIn[(index -1 )]){
                visitorPlayerIn[(index -1 )].blockedPlayer = false;
                visitorPlayerIn[(index -1 )].state = 'inGame';
            }
            //visitorPlayerOut[index === 0 ? index : (index -1 )].blockedPlayer = false;
            //visitorPlayerOut[index === 0 ? index : (index -1 )].state = 'outGame';
            if(visitorPlayerOut.length > 0 && visitorPlayerOut[(index -1 )]){
                visitorPlayerOut[(index -1 )].blockedPlayer = false;
                visitorPlayerOut[(index -1 )].state = 'outGame';
            }
            visitorPlayerMinute[index] = '';

            visitorPlayerIn.splice(index, arrayVisitorInSize <= 1 ? 1 : (arrayVisitorInSize - 1));
            visitorPlayerOut.splice(index, arrayVisitorOutSize <= 1 ? 1 : (arrayVisitorOutSize - 1));
            setSelectedVisitorPyInId(visitorPlayerIn[index === 0 ? index : (index -1 )]?.id ?? '');
            setSelectedVisitorPyOutId(visitorPlayerOut[index === 0 ? index : (index -1 )]?.id ?? '');

            setComponentValues({
                ...componentValues,
                visitor_player_in: visitorPlayerIn,
                visitor_player_out: visitorPlayerOut,
                visitor_minute_change: visitorPlayerMinute
            });
        }
    };

    const removeLocalChangeClick = (index) => {
        const arrayPlayerInSize = componentValues.local_player_in?.filter((player) => player !== null).length;
        const arrayPlayerOutSize = componentValues.local_player_in?.filter((player) => player !== null).length;
        removeLocalPlayerFormState(index, arrayPlayerInSize === 0  ? 1 : (arrayPlayerInSize - 1));
        
        if(index === 0 && localChanges > 1){
            setLocalChanges(0);
            setComponentValues({
                ...componentValues,
                local_player_in: [null, null, null, null, null, null, null, null, null, null, null, null],
                local_player_out: [null, null, null, null, null, null, null, null, null, null, null, null],
                local_minute_change: [],
            });
            setAuxLocalMembers(props.local_team_members);
        }else{
            setLocalChanges(localChanges - 1);
            if(componentValues.local_player_in[index]){
                auxLocalMembers.find((player) => player.id === componentValues.local_player_in[index].id).state = 'stanby';
                auxLocalMembers.find((player) => player.id === componentValues.local_player_in[index].id).blockedPlayer = false;
                auxLocalMembers.find((player) => player.id === componentValues.local_player_in[index === 0 ? index : (index -1 )].id).blockedPlayer = false;
            }
    
            if(componentValues.local_player_out[index]){
                auxLocalMembers.find((player) => player.id === componentValues.local_player_out[index].id).state = 'stanby';
                auxLocalMembers.find((player) => player.id === componentValues.local_player_out[index].id).blockedPlayer = false;
                auxLocalMembers.find((player) => player.id === componentValues.local_player_out[index === 0 ? index : (index -1 )].id).blockedPlayer = false;
            }
            let localPlayerIn = cloneDeep(componentValues.local_player_in);
            let localPlayerOut = cloneDeep(componentValues.local_player_out);
            let localPlayerMinute = cloneDeep(componentValues.local_minute_change);
            
            //localPlayerIn[index === 0 ? index : (index -1 )].blockedPlayer = false;
            //localPlayerIn[index === 0 ? index : (index -1 )].state = 'inGame';
            if(localPlayerIn.length > 0 && localPlayerIn[(index -1 )]){
                localPlayerIn[(index -1 )].blockedPlayer = false;
                localPlayerIn[(index -1 )].state = 'inGame';
            }
            //localPlayerOut[index === 0 ? index : (index -1 )].blockedPlayer = false;
            //localPlayerOut[index === 0 ? index : (index -1 )].state = 'outGame';
            if(localPlayerIn.length > 0 && localPlayerOut[(index -1 )]){
                localPlayerOut[(index -1 )].blockedPlayer = false;
                localPlayerOut[(index -1 )].state = 'outGame';
            }
            localPlayerMinute[index] = '';

            localPlayerIn.splice(index, arrayPlayerInSize <= 1 ? 1 : (arrayPlayerInSize - 1));
            localPlayerOut.splice(index, arrayPlayerOutSize<= 1 ? 1 : (arrayPlayerOutSize - 1));
            setSelectedLocalPyInId(localPlayerIn[index === 0 ? index : (index -1 )]?.id ?? '');
            setSelectedLocalPyOutId(localPlayerOut[index === 0 ? index : (index -1 )]?.id ?? '');

            setComponentValues({
                ...componentValues,
                local_player_in: localPlayerIn,
                local_player_out: localPlayerOut,
                local_minute_change: localPlayerMinute
            });
        }
    };

    const removeLocalPlayerFormState = (startIndex, endIndex) => {
        for (let i = startIndex; i <= endIndex; i++) {
            props.formRef?.setFieldValue("local_player_in[" + i + "]", null);
            props.formRef?.setFieldValue("local_player_out[" + i + "]", null);
            props.formRef?.setFieldValue("local_minute_change[" + i + "]", null);
        }
    }

    const removeVisitorPlayerFormState = (startIndex, endIndex) => {
        for (let i = startIndex; i <= endIndex; i++) {
            props.formRef?.setFieldValue("visitor_player_in[" + i + "]", null);
            props.formRef?.setFieldValue("visitor_player_out[" + i + "]", null);
            props.formRef?.setFieldValue("visitor_minute_change[" + i + "]", null);
        }
    }

    const handleLocalPlayersInOut = (in_out) => {
        if (in_out === 'player_in') {
            return auxLocalMembers?.filter((player) => player.state != 'inGame' && selectedLocalPyOutId !== player.id);
        } else if (in_out === 'player_out') {
            return auxLocalMembers?.filter((player) => player.state != 'outGame' && selectedLocalPyInId !== player.id);
        }
    }

    const handleVisitorPlayersInOut = (in_out) => {
        if (in_out === 'player_in') {
            return auxVisitorMembers?.filter((player) => player.state != 'inGame' && selectedVisitorPyOutId !== player.id);
        } else if (in_out === 'player_out') {
            return auxVisitorMembers?.filter((player) => player.state != 'outGame' && selectedVisitorPyInId !== player.id);
        }
    }

    const handleOnChangeLocalPlayerIn = (player, index) => {
        const newLPlayerIn = [...componentValues.local_player_in];
        newLPlayerIn[index] = player ?? null;
        setComponentValues({ ...componentValues, local_player_in: newLPlayerIn });
        player && setSelectedLocalPyInId(player.id);
    };

    const handleOnChangeLocalPlayerOut = (player, index) => {
        const newLPlayerOut = [...componentValues.local_player_out];
        newLPlayerOut[index] = player ?? null;
        setComponentValues({ ...componentValues, local_player_out: newLPlayerOut });
        player && setSelectedLocalPyOutId(player.id);
    };

    const handleChangeLocalPlayerState = (playerItem, state) => {
        auxLocalMembers.find((player) => player.id === playerItem).state = state;
        // auxLocalMembers.find((player) => player.id === playerItem).blockedPlayer = true;
        setSelectedLocalPyInId('');
        setSelectedLocalPyOutId('');
    };

    const handleOnChangeVisitorPlayerIn = (player, index) => {
        const newVisitorPlayerIn = [...componentValues.visitor_player_in];
        newVisitorPlayerIn[index] = player ?? null;
        setComponentValues({ ...componentValues, visitor_player_in: newVisitorPlayerIn });
        player && setSelectedVisitorPyInId(player.id);
    };

    const handleOnChangeVisitorPlayerOut = (player, index) => {
        const newVPlayerOut = [...componentValues.visitor_player_out];
        newVPlayerOut[index] = player ?? null;
        setComponentValues({ ...componentValues, visitor_player_out: newVPlayerOut });
        player && setSelectedVisitorPyOutId(player.id);
    };

    const handleChangeVisitorPlayerState = (playerItem, state) => {
        auxVisitorMembers.find((player) => player.id === playerItem).state = state;
        // auxVisitorMembers.find((player) => player.id === playerItem).blockedPlayer = true;
        setSelectedVisitorPyInId('');
        setSelectedVisitorPyOutId('');
    };

    const handleOnChangeLocalMinuteChange = (event, index) => {
        const newLMinuteChange = [...componentValues.local_minute_change];
        newLMinuteChange[index] = event.target.value;
        setComponentValues({ ...componentValues, local_minute_change: newLMinuteChange });
    }

    const handleOnChangeVisitorMinuteChange = (event, index) => {
        const newVMinuteChange = [...componentValues.visitor_minute_change];
        newVMinuteChange[index] = event.target.value;
        setComponentValues({ ...componentValues, visitor_minute_change: newVMinuteChange });
    }

    function handleOnAddLocalChange() {
        if (componentValues.local_player_in[localChanges - 1] !== null && componentValues.local_player_out[localChanges - 1] !== null) {
            return false
        } else {
            return true
        }
    }

    const handleOnAddVisitorChange = () => {
        return !(componentValues.visitor_player_in[visitorChanges - 1] !== null && componentValues.visitor_player_in[visitorChanges - 1] !== null)
    }


    return (

        <div className="league-match-changes__container">
            {/* <TournamentScorerBoardComponent
                local_team={props.local_team}
                visitor_team={props.visitor_team}
                local_score={props.local_score}
                visitor_score={props.visitor_score}
            /> */}
            <div className="league-match-changes__contianer__change-players">
                <div className='league-match-changes__cointainer__change-players__column-title baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelSubTitle')}</div>
                <div className="league-match-changes__cointainer__change-players__column">
                    <div className='league-match-changes__container_column_inf-team'>
                        <div className='league-match-changes__column_inf-team'>
                            {  props.local_team?.photo
                                ? <img className="league-match-changes__column_inf-team_photo" src={props.local_team?.photo} alt={'photo_team'} />
                                : <span className="icon-ball" color={getRandonIconBallColor()} />}
                            <div className='league-match-changes__column_inf-team-name'>
                                <div className="baloa-table">{ props.local_team?.name}</div>
                                <div className="baloa-mini">@{ props.local_team?.teamname}</div>
                            </div>
                        </div>
                        {/* <div className='league-match-changes__column_score-team'>
                            {props.local_score}
                        </div> */}
                    </div>
                    <div className="league-match-changes__cointainer__action-btn">
                        <SecondaryButtonComponent
                            type="button"
                            disabled={handleOnAddLocalChange()}
                            onClick={() => { addLocalChangeClick() }}
                            className="league-match-changes__actions__add"
                        >
                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelAddButton')}</span>
                        </SecondaryButtonComponent>
                    </div>
                    <div className="league-match-changes__cointainer__players scroll">
                        {localChanges === 0 &&
                            <div className='league-match-changes__cointainer__change-players__empty-message baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelEmpty')}</div>
                        }
                        {[...Array(Number(localChanges))].map((e, i) =>
                            <div className="league-match-changes__cointainer__input" key={i}>
                                <div className="league-match-changes__cointainer__input-in">
                                    <InputArrayFieldAutocompleteWithValidationComponent
                                        key={i}
                                        field_name={"local_player_in[" + i + "]"}
                                        options={handleLocalPlayersInOut('player_in') ?? [{ id: i, message: props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt') }]}
                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt')}
                                        optionLabelField={"revelationPlayer"}
                                        optionSelectedField={"username"}
                                        className="league-match-changes__form__input"
                                        label={<React.Fragment>
                                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelIn')}</span>
                                            <span className='arrow_changes icon-arrow-right' />
                                        </React.Fragment>}
                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelInHint')}
                                        onChange={(v) => { handleOnChangeLocalPlayerIn(v, i) }}
                                        value={componentValues.local_player_in[i]}
                                        freeSolo={true}
                                        validationFunction={(event) => props.validationFunction(componentValues.local_player_out[i], event)}
                                        error={props.chanagesErrors.errors}
                                        index={i}
                                        disabled={componentValues.local_player_in[i]?.blockedPlayer}
                                        disableClearable={componentValues.local_player_in[i]?.blockedPlayer}
                                    />
                                    <div className="league-match-changes__remove-change__container">
                                        <div className="league-match-changes__remove-change"
                                            onClick={() => removeLocalChangeClick(i)}>
                                            <span className="icon-x" />
                                        </div>
                                    </div>
                                </div>
                                <div className="league-match-changes__cointainer__input-out">
                                    <InputArrayFieldAutocompleteWithValidationComponent
                                        key={i}
                                        field_name={"local_player_out[" + i + "]"}
                                        options={handleLocalPlayersInOut('player_out') ?? [{ id: i, message: props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt') }]}
                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt')}
                                        optionLabelField={"revelationPlayer"}
                                        optionSelectedField={"username"}
                                        className="league-match-changes__form__input_out"
                                        label={<React.Fragment>
                                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelOut')}</span>
                                            <span className='arrow_changes icon-arrow-left' />
                                        </React.Fragment>}
                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelOutHint')}
                                        onChange={(v) => { handleOnChangeLocalPlayerOut(v, i) }}
                                        value={componentValues.local_player_out[i]}
                                        freeSolo={true}
                                        validationFunction={(event) => props.validationFunction(componentValues.local_player_in[i], event)}
                                        error={props.chanagesErrors.errors}
                                        index={i}
                                        disabled={componentValues.local_player_out[i]?.blockedPlayer}
                                        disableClearable={componentValues.local_player_out[i]?.blockedPlayer}
                                    />                                    
                                    <InputMinuteComponent
                                        id={"local_minute_change_" + i}
                                        name={"local_minute_change[" + i + "]"}
                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelMinute')}
                                        onChange={(e) => { handleOnChangeLocalMinuteChange(e, i) }}
                                        value={componentValues.local_minute_change[i]}
                                        className={props.goalsValidations.local.includes(0)}
                                        index={i}
                                    />
                                </div>
                            </div>
                            )
                        }
                    </div>                    
                </div>
                <div className="league-match-changes__cointainer__change-players__column">
                    <div className='league-match-changes__container_column_inf-team'>
                        <div className='league-match-changes__column_inf-team'>
                            {  props.visitor_team?.photo
                                ? <img className="league-match-changes__column_inf-team_photo" src={props.visitor_team?.photo} alt={'photo_team'} />
                                : <span className="icon-ball" color={getRandonIconBallColor()} />}
                            <div className='league-match-changes__column_inf-team-name'>
                                <div className="baloa-table">{ props.visitor_team?.name}</div>
                                <div className="baloa-mini">@{ props.visitor_team?.teamname}</div>
                            </div>
                        </div>
                        {/* <div className='league-match-changes__column_score-team'>
                            {props.visitor_score}
                        </div> */}
                    </div>
                    <div className="league-match-changes__cointainer__action-btn">
                        <SecondaryButtonComponent
                            type="button"
                            disabled={handleOnAddVisitorChange()}
                            onClick={() => { addVisitorChangeClick() }}
                            className="league-match-changes__actions__add"
                        >
                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelAddButton')}</span>
                        </SecondaryButtonComponent>
                    </div>
                    <div className="league-match-changes__cointainer__players scroll">
                        {visitorChanges === 0 &&
                            <div className='league-match-changes__cointainer__change-players__empty-message baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelEmpty')}</div>
                        }
                        {[...Array(Number(visitorChanges))].map((e, i) =>
                            <div className="league-match-changes__cointainer__input" key={i}>
                                <div className="league-match-changes__cointainer__input-in">
                                    <InputArrayFieldAutocompleteWithValidationComponent
                                        key={i}
                                        field_name={"visitor_player_in[" + i + "]"}
                                        options={handleVisitorPlayersInOut('player_in') ?? [{ id: i, message: props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt') }]}
                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt')}
                                        optionLabelField={"revelationPlayer"}
                                        optionSelectedField={"username"}
                                        className="league-match-changes__form__input"
                                        label={<React.Fragment><span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelIn')}</span><span className='arrow_changes icon-arrow-right' /></React.Fragment>}
                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelInHint')}
                                        onChange={(v) => { handleOnChangeVisitorPlayerIn(v, i) }}
                                        value={componentValues.visitor_player_in[i]}
                                        freeSolo={true}
                                        validationFunction={(event) => props.validationFunction(componentValues.visitor_player_out[i], event)}
                                        error={props.chanagesErrors.errors}
                                        index={i}
                                        disabled={componentValues.visitor_player_in[i]?.blockedPlayer}
                                        disableClearable={componentValues.visitor_player_in[i]?.blockedPlayer}
                                    />
                                    <div className="league-match-changes__remove-change__container">
                                        <div className="league-match-changes__remove-change"
                                            onClick={() => { removeVisitorChangeClick(i) }}>
                                            <span className="icon-x" />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="league-match-changes__cointainer__input-out">
                                    <InputArrayFieldAutocompleteWithValidationComponent
                                        key={i}
                                        field_name={"visitor_player_out[" + i + "]"}
                                        options={handleVisitorPlayersInOut('player_out') ?? [{ id: i, message: props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt') }]}
                                        noOptionsText={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.noOpt')}
                                        optionLabelField={"revelationPlayer"}
                                        optionSelectedField={"username"}
                                        className="league-match-changes__form__input_out"
                                        label={<React.Fragment><span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelOut')}</span><span className='arrow_changes icon-arrow-left' /></React.Fragment>}
                                        placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelOutHint')}
                                        onChange={(v) => { handleOnChangeVisitorPlayerOut(v, i) }}
                                        value={componentValues.visitor_player_out[i]}
                                        freeSolo={true}
                                        validationFunction={(event) => props.validationFunction(componentValues.visitor_player_in[i], event)}
                                        error={props.chanagesErrors.errors}
                                        index={i}
                                        disabled={componentValues.visitor_player_out[i]?.blockedPlayer}
                                        disableClearable={componentValues.visitor_player_out[i]?.blockedPlayer}
                                    />
                                    <InputMinuteComponent
                                        name={"visitor_minute_change[" + i + "]"}
                                        id={"visitor_minute_change_" + i}
                                        label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabChanges.labelMinute')}
                                        onChange={(e) => { handleOnChangeVisitorMinuteChange(e, i) }}
                                        value={componentValues.visitor_minute_change[i]}
                                        index={i}
                                    />                                    
                                </div>
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation('league')(LeagueMatchChangesComponent);
