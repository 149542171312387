// React
import React from 'react';

//Material
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import FormComponent from 'shared/components/form/FormComponent';
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//Modals
import HomePagesExploreFilterModalComponent from '../home-pages-explore-filter/HomePagesExploreFilterModalComponent';

// Styles
import './HomePagesExploreFilterComponent.styles.css';

//Asets
import filterIcon from 'assets/icons/filter-tournament-icon.svg';


// i18n
import { withTranslation } from 'react-i18next';

// isMobile
import { isMobile } from 'react-device-detect';

function HomePagesExploreFilterComponent( props ) {
    const [filterPageType, setFilterPageType] = React.useState('');
    const [filterCategoryList, setFilterCategoryList] = React.useState([]);
    const [filterCategory, setFilterCategory] = React.useState([]);
    const [expanded1, setExpanded1] = React.useState(true);
    const [expanded2, setExpanded2] = React.useState(true);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [searchAutoFocus, setSearchAutoFocus] = React.useState(true);
    const formRef = React.useRef();
    const initialValues = {
        searchpage: '',
    }

    const handleOnFilterPageType = (e) => {
        if(!props.unAuth){
            setFilterPageType( e.target.value );
            setFilterCategoryList(props.page_categories[e.target.value])
            setFilterCategory(current => []);
            formRef.current?.setFieldValue("category", null);
            formRef.current?.submitForm();
        }else{
            props.handleOnExplorePagesFilter(null, null, null)
        }
        
    }

    const handleOnFilterCategory = (e) => {
        if(e.target.checked){
            setFilterCategory(current => [...current, e.target.value]);
        }else{
            setFilterCategory((current) =>
                current.filter((cat) => cat !== e.target.value)
            );            
        }

        formRef.current?.submitForm();
    }

    const handleOnFilterModalCategory = (e, modalCategories) => {
        /* if(e.target.checked){
            setFilterCategory(current => [...current, e.target.value]);
        }else{
            setFilterCategory((current) =>
                current.filter((cat) => cat !== e.target.value)
            );            
        } */
        setFilterCategory(modalCategories);
        formRef.current?.setFieldValue("category", modalCategories);
        formRef.current?.submitForm();
    }

    const handleExpanded1 = () => {
        setExpanded1(!expanded1);
    }

    const handleExpanded2 = () => {
        setExpanded2(!expanded2);
    }

    const buildApiRequest = (values) => {
        let categories = '';
        if(values.category && values.category.length > 0){
            values.category.map(cat=>(
                categories = categories + '|' + cat
            ))
            categories = categories.substring(1);
        }else{
            filterCategoryList.map(cat=>(
                categories = categories + '|' + cat.value
            ))
            categories = categories.substring(1);
        }

        let page_type = values.page_type? values.page_type : null;

        props.handleOnExplorePagesFilter(values.searchpage, page_type, categories);
    }    

    const handleFilterOpen = ( state ) => {
        setFilterOpen( state );
    }

    const handleModalFilter = () => {
        //formRef.current?.submitForm();
        setFilterOpen( false );
    }

    const resetFilter = () => {
        setFilterPageType( '' );
        setFilterCategoryList(current => [])
        setFilterCategory(current => []);
        formRef.current?.setFieldValue("category", null);
        setFilterOpen( false );
    }

    const handleWordSearch = (word) => {
        setSearchAutoFocus(true);
        formRef.current?.submitForm();
    }

return (
    <div className='home-pages-explorer-filter__container'>
        {!isMobile &&
            <FormComponent
                formInitialValues={ initialValues }
                onSubmit={( values, actions ) => {
                    buildApiRequest(values);
                }}
                //className="search-form__form form"
                autocomplete="off"
                onBlur={ ()=>{setSearchAutoFocus(false)}  }
                innerRef={formRef}
            >  
                {props.unAuth?
                    <div onClick={()=>{props.handleOnExplorePagesFilter(null, null, null)}}>
                        <InputSearchComponent         
                            id="searchpage"
                            name="searchpage"   
                            placeholder={props.t('page:searchPages')}
                            handleSearch={(search) => { handleWordSearch(search) }}
                            disabled={true}
                            removeWordLenghtValidation={false}
                            autoFocus={false}
                            searchword={props.searchword}
                        />
                    </div>
                    :
                    <InputSearchComponent         
                        id="searchpage"
                        name="searchpage"   
                        placeholder={props.t('page:searchPages')}
                        handleSearch={(search) => { handleWordSearch(search) }}
                        disabled={props.disableFilterInputSearch}
                        removeWordLenghtValidation={false}
                        autoFocus={searchAutoFocus}
                        searchword={props.searchword}
                    />
                }      
                
                <Accordion className='home-pages-explorer-filter__accordion' expanded={expanded1} onChange={()=>{ handleExpanded1() }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className='baloa-mini'
                    >                                    
                        <div className='baloa-names'>{props.t('page:dataModals.pageType')}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RadioGroup row
                            aria-labelledby="page_type"
                            name={"page_type"}
                            value={''}
                            className="home-pages-explorer-filter__option-button inside"
                        >
                            <FormControlLabel
                                value="organizations"
                                control={<Radio color="secondary" checked={filterPageType === 'organizations'} />}
                                label={<span className="baloa-names">
                                        {props.t('page:newPage.footballOrganizations.title')}
                                    </span>}
                                labelPlacement="end"
                                className={filterPageType === 'organizations' ? 'selected' : ''}
                                onChange={(e) => handleOnFilterPageType(e)}
                            />
                            <FormControlLabel
                                value="institutions"
                                control={<Radio color="secondary" checked={filterPageType === 'institutions'} />}
                                label={<span className="baloa-names">
                                        {props.t('page:newPage.institutionsCorporations.title')}
                                    </span>}
                                labelPlacement="end"
                                className={filterPageType === 'institutions' ? 'selected' : ''}
                                onChange={(e) => handleOnFilterPageType(e)}
                            />
                            <FormControlLabel
                                value="content"
                                control={<Radio color="secondary" checked={filterPageType === 'content'} />}
                                label={<span className="baloa-names">
                                        {props.t('page:newPage.contenGeneration.title')}
                                    </span>}
                                labelPlacement="end"
                                className={filterPageType === 'content' ? 'selected' : ''}
                                onChange={(e) => handleOnFilterPageType(e)}
                            />
                        </RadioGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='home-pages-explorer-filter__accordion' expanded={expanded2} onChange={()=>{ handleExpanded2() }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className='baloa-mini'                
                    >                                    
                        <div className='baloa-names'>{props.t('page:dataModals.pageCategory')}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {filterCategoryList.map(category => (
                            <FormControlLabel
                                name={'category'}
                                key={category.value}
                                value={category.value}
                                control={<Checkbox color="secondary" checked={ filterCategory.some(ply => ply === category.value) } />}
                                label={<span className="baloa-names">
                                        {props.t(`page:newPage.type.${category.content}`)}
                                    </span>}
                                labelPlacement="end"
                                className={'home-pages-explorer-filter__checkbox'}
                                onChange={(e) => handleOnFilterCategory(e)}
                            />
                        ))}                
                    </AccordionDetails>
                </Accordion>
            </FormComponent>
        }
        {isMobile &&
            <FormComponent
                formInitialValues={ initialValues }
                onSubmit={( values, actions ) => {
                    buildApiRequest(values);
                }}
                //className="search-form__form form"
                autocomplete="off"
                //onBlur={  props.onBlur  }
                innerRef={formRef}
            >            
                {props.unAuth?
                    <div onClick={()=>{props.handleOnExplorePagesFilter(null, null, null)}}>
                        <InputSearchComponent         
                            id="searchpage"
                            name="searchpage"   
                            placeholder={props.t('page:searchPages')}
                            handleSearch={(search) => { handleWordSearch(search) }}
                            disabled={true}
                            removeWordLenghtValidation={false}
                            autoFocus={true}
                            searchword={props.searchword}
                        />
                    </div>
                    :
                    <InputSearchComponent         
                        id="searchpage"
                        name="searchpage"   
                        placeholder={props.t('page:searchPages')}
                        handleSearch={(search) => { handleWordSearch(search) }}
                        disabled={filterOpen}
                        removeWordLenghtValidation={false}
                        autoFocus={true}
                        searchword={props.searchword}
                    />
                }
                
                <div className={'home-pages-explorer-filter__mobile-buttons'}>
                    <div className={'home-pages-explorer-filter__mobile-buttons__button'} onClick={() => { props.unAuth? props.handleOnExplorePagesFilter(null, null, null) : handleFilterOpen(true) }} >
                        <img src={filterIcon} alt={"filter_icon"} />
                        <div className='baloa-names'>{props.t('home:pages.advancedFilters')}</div>
                    </div>                   
                </div>
                
                <HomePagesExploreFilterModalComponent
                    isOpen={filterOpen} 
                    onClose={ () => { handleFilterOpen(false) } }
                    filterPageType={filterPageType}
                    filterCategoryList={filterCategoryList}
                    filterCategory={filterCategory}
                    handleOnFilterPageType={ (e) => handleOnFilterPageType(e) }
                    handleOnFilterCategory={(e) => handleOnFilterCategory(e)}
                    handleModalFilter = { ()=> { handleModalFilter() }}
                    resetFilter={ () => resetFilter() }
                    handleOnFilterModalCategory = { (e,arr)=> { handleOnFilterModalCategory(e,arr)}}
                />
            </FormComponent>
        }
    </div>
    )
}

export default withTranslation(['home','page'])(HomePagesExploreFilterComponent);