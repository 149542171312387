// React
import React from "react";
import CurrencyFormat from 'react-currency-format';

// Material UI
import Switch from "@mui/material/Switch";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentConfigArbitragePaymentsModalComponent.styles.css';
import { styled } from "@mui/styles";

//i18
import { withTranslation } from 'react-i18next';

function TournamentConfigArbitragePaymentsModalComponent(props) {
    const [ activatePayment, setActivatePayment ] = React.useState(false);
    const [ processingRateType, setProcessingRateType ] = React.useState('');
    const [ localVisitantSelection, setLocalVisitantSelection ] = React.useState('');
    const [ localTeamFee, setLocalTeamFee ] = React.useState(0);
    const [ visitantTeamFee, setVisitantTeamFee ] = React.useState(0);
    const [ mainRefereeFee, setMainRefereeFee ] = React.useState(0);
    const [ lineReferee1Fee, setLineReferee1Fee ] = React.useState(0);
    const [ lineReferee2Fee, setLineReferee2Fee ] = React.useState(0);
    const [ fourthRefereeFee, setFourthRefereeFee ] = React.useState(0);
    const [ refereeAssigner, setRefereeAssigner ] = React.useState(0);
    const [ disabledInput, setDisabledInput ] = React.useState(true);
    const [ refereesValuesSum, setRefereesValuesSum ] = React.useState(0);
    const [ differenceTotalAmount, setDifferenceTotalAmount ] = React.useState(0);
    const [ overValueTotalAmount, setOverValueTotalAmount ] = React.useState(false);
    const [ isSaveBtn, setIsSaveBtn] = React.useState(true);
    const [ editTeamsFeeValues, setEditTeamsFeeValues ] = React.useState(false);

    React.useEffect(() => {
        if(mainRefereeFee > 0 || lineReferee1Fee > 0 || lineReferee2Fee > 0 || fourthRefereeFee > 0 || refereeAssigner > 0 ) {
            let referees_values_sum = mainRefereeFee + lineReferee1Fee + lineReferee2Fee + fourthRefereeFee + refereeAssigner
            let difference_total_amount = props.referee_fee_value - referees_values_sum;
            setRefereesValuesSum(referees_values_sum);
            setDifferenceTotalAmount(difference_total_amount);
            if(difference_total_amount < 0) {
                setOverValueTotalAmount(true);
            } else {
                setOverValueTotalAmount(false);
            }
        }
    }, [mainRefereeFee, lineReferee1Fee, lineReferee2Fee, fourthRefereeFee, refereeAssigner, props.referee_fee_value]);

    React.useEffect(() => {
        if(processingRateType === '' || localVisitantSelection === '' || visitantTeamFee === 0 || localTeamFee === 0 || isNaN(visitantTeamFee)  || isNaN(localTeamFee) ) {
            setIsSaveBtn(true);
        }

        if(processingRateType !== '' && localVisitantSelection !== '' && ((visitantTeamFee !== 0 || localTeamFee !== 0) && (!isNaN(visitantTeamFee) || !isNaN(localTeamFee)))) {
            setIsSaveBtn(false);
        }
    }, [processingRateType, localVisitantSelection, visitantTeamFee, localTeamFee]);

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const PAYMENT_OPTIONS = [
        { value: 'Both_Teams', content: props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localVisitantOptionLable')},
        { value: 'Local_Team', content: props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localOptionLabel')},
        { value: 'Visitant_Team', content: props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.visitantOptionLabel')},
    ];

    const handleActivatePayment = (e) => {
        setActivatePayment(e.target.checked);
        if(!e.target.checked) {
            handleClearFormValues();
        }
    } 

    const handleProcessingRateType = (e) => {
        setProcessingRateType(e.target.value);
    }

    const handleLocalVisitantSelection = (event, selection) => {
        handleClearInputValues();
        props.handleOnResetRefereesFee();
        setEditTeamsFeeValues(false);        
        setLocalVisitantSelection(selection);
    }

    const handleClearInputValues = () => {
        setLocalTeamFee(0)
        setVisitantTeamFee(0)
        setMainRefereeFee(0);
        setLineReferee1Fee(0);
        setLineReferee2Fee(0);
        setFourthRefereeFee(0);
        setRefereeAssigner(0);
        setRefereesValuesSum(0);
        setDifferenceTotalAmount(0);
        setDisabledInput(true);
    }

    const handleClearFormValues = () => {
        setLocalTeamFee(0)
        setVisitantTeamFee(0)
        setMainRefereeFee(0);
        setLineReferee1Fee(0);
        setLineReferee2Fee(0);
        setFourthRefereeFee(0);
        setRefereeAssigner(0);
        setRefereesValuesSum(0);
        setDifferenceTotalAmount(0);
        setProcessingRateType('');
        setLocalVisitantSelection('');
        setActivatePayment(false);
        setDisabledInput(true);
    }

    const handleLocalVisitantTeamFee = (team_fee, team_type) => {
        if(team_type === 'local') {
            setLocalTeamFee(parseFloat(team_fee));
        } else {
            setVisitantTeamFee(parseFloat(team_fee));
        }        
        handleDisabledInput();                
    }

    const handleDisabledInput = () => {
        if(localVisitantSelection === 'Both_Teams' && (localTeamFee !== ""  && visitantTeamFee !== "")) {
            setDisabledInput( false);
        } else if(localVisitantSelection === 'Local_Team' && localTeamFee !== "" ) {
            setDisabledInput( false);
        } else if(localVisitantSelection === 'Visitant_Team' && visitantTeamFee !== "") {
            setDisabledInput( false);
        } else {
            setDisabledInput( true);
        }
    }

    const handleRefereesFee = (referee_fee, referee_type) => {
        switch (referee_type) {
            case 'main_referee':
                setMainRefereeFee(parseFloat(referee_fee));
                break;
            case 'line_referee_1':
                setLineReferee1Fee(parseFloat(referee_fee));
                break;
            case 'line_referee_2':
                setLineReferee2Fee(parseFloat(referee_fee));
                break;
            case 'fourth_referee':
                setFourthRefereeFee(parseFloat(referee_fee));
                break;
            default:
                break;
        }
    }

    const handleRefereeAssigner = (referee_assigner) => {
        setRefereeAssigner(parseFloat(referee_assigner));
    }

    const handleOnSaveArbitrageConfig = () => {
        if(differenceTotalAmount < 0) {
            props.handleExceededValue(props.referee_fee_value, differenceTotalAmount);
        } else {
            let form_values = {
                referee_payments: activatePayment,
                referee_processing_rate_type: processingRateType,
                tournament_referee_payment: localVisitantSelection,
                refereeing_value_local_team: localTeamFee,
                refereeing_value_visitant_team: visitantTeamFee,
                official_referee_value: mainRefereeFee,
                second_assistant_referee_value: lineReferee1Fee,
                third_assistant_referee_value: lineReferee2Fee,
                fourth_official_referee_value: fourthRefereeFee,
                referee_assigner_value: refereeAssigner,
            }
            props.handleOnSaveArbitrageConfig(form_values, localTeamFee, visitantTeamFee);
            props.onClose();
        }        
    }    

    const calculateRefereeFee = () => {
        setEditTeamsFeeValues(true);
        let obj = {
            referee_processing_rate_type: processingRateType,
            refereeing_value_local_team: localTeamFee,
            refereeing_value_visitant_team: visitantTeamFee,
        }
        props.calculateRefereeFee(obj);
    }

    const handleEditRefereeFee = () => {
        handleClearInputValues();
        props.handleOnResetRefereesFee();
        setEditTeamsFeeValues(false);
    }    

    return (
        <Modal2Component 
            className="tournament-config-arbitrage-payments-modal scroll-parent"
            isOpen={props.isOpen} 
            onClose={ () => props.onClose() }
            title={props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.modalTitle')}
        >
            <div className="tournament-config-arbitrage-payments-modal_container scroll " >
                <div className="tournament-config-arbitrage-payments-modal__switch-container">
                    <label className="tournament-config-arbitrage-payments-modal__switch">
                        <div className="baloa-names" >{props.t("options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.activateArbitrageLabel")}</div>
                        <Switch 
                            id={'referee_payments'} 
                            checked={activatePayment}
                            onChange={ handleActivatePayment }
                            name="referee_payments"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            color="secondary"
                        />
                    </label>
                    <div className="baloa-table" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.modalLabelInfo')}</div>
                </div>
                { activatePayment &&
                    <React.Fragment>
                        <div className="tournament-config-arbitrage-payments-modal__processing-fee__container" >
                            <div className="baloa-names">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.processingFeeLabel')}</div>
                            <FormControl component="fieldset" className={""}>                        
                                <RadioGroup row aria-labelledby="referee_processing_rate_type" name="referee_processing_rate_type" defaultValue="top" className="" error={''}>
                                    <FormControlLabel
                                        value="Tournament_Pays_Processing"
                                        control={ processingRateType === 'Tournament_Pays_Processing' ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" /> }
                                        label={
                                            <div className='tournament-config-arbitrage-payments-modal__processing-fee__form-control-label__title'>
                                                <div className="baloa-table-column" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.tournamentProcessingFeeLabel')}</div>
                                                <div className="baloa-username config-info" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.tournamentProcessingFeeInfo')}</div>
                                                <div className="baloa-username" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.tournamentProcessingFeeExample')}</div>
                                            </div>
                                        }
                                        labelPlacement="end"
                                        onChange={ handleProcessingRateType }
                                        className={''}
                                    />
                                    <FormControlLabel
                                        value="User_Pays_Processing"
                                        control={ processingRateType === 'User_Pays_Processing' ? <Radio color="secondary" checked={true} /> : <Radio color="secondary" /> }
                                        label={
                                            <div className='tournament-config-arbitrage-payments-modal__processing-fee__form-control-label__title'>
                                                <div className="baloa-table-column" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.userProcessingFeeLabel')}</div>
                                                <div className="baloa-username config-info" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.userProcessingFeeInfo')}</div>
                                                <div className="baloa-username" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.userProcessingFeeExample')}</div>
                                            </div>
                                        }
                                        labelPlacement="end"
                                        onChange={ handleProcessingRateType }
                                        className={''}
                                    />                            
                                </RadioGroup>                        
                            </FormControl>
                        </div>
                        <div className="tournament-config-arbitrage-payments-modal__set-teams-fee__container" >
                            <div className="baloa-username" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localVisitantTeamOptionTitle')}</div>
                            <StyledToggleButtonGroup
                                color="primary"
                                value={localVisitantSelection}
                                onChange={handleLocalVisitantSelection}
                                exclusive
                            >
                                {PAYMENT_OPTIONS.map((payment_opt, index) => (
                                    <ToggleButton key={index} value={payment_opt.value} >
                                        {payment_opt.content}
                                    </ToggleButton>
                                ))}
                            </StyledToggleButtonGroup>
                            <div className="tournament-config-arbitrage-payments-modal__set-teams-fee__inputs-value">
                                { (localVisitantSelection === 'Both_Teams' || localVisitantSelection === 'Local_Team') &&
                                    <div className="tournament-config-arbitrage-payments-modal__set-teams-fee__team">
                                        <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localArbitregeFee')}</div>
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="tournament-config-arbitrage-payments-modal__set-teams-fee__input"
                                            value={ `${localTeamFee}` }
                                            displayType={'input'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            disabled={editTeamsFeeValues}
                                            prefix={'$ '} 
                                            suffix={' ' + props.new_tournament_currency}
                                            placeholder="$"
                                            onValueChange ={ (value) => { handleLocalVisitantTeamFee(value.floatValue, 'local') }}
                                            renderText={value => <div>{value}</div>}
                                        />
                                    </div>
                                }
                                { (localVisitantSelection === 'Both_Teams' || localVisitantSelection === 'Visitant_Team') &&
                                    <div className="tournament-config-arbitrage-payments-modal__set-teams-fee__team">
                                        <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.visitantArbitrageFee')}</div>
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="tournament-config-arbitrage-payments-modal__set-teams-fee__input"
                                            value={ `${visitantTeamFee}` }
                                            displayType={'input'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            disabled={editTeamsFeeValues}
                                            prefix={'$ '} 
                                            suffix={' ' + props.new_tournament_currency}
                                            placeholder="$"
                                            onValueChange ={ (value) => { handleLocalVisitantTeamFee(value.value, 'visitant' ) }}
                                            renderText={value => <div>{value}</div>}
                                        />
                                    </div> 
                                }                               
                            </div>
                            { (localVisitantSelection === 'Both_Teams' || localVisitantSelection === 'Visitant_Team' || localVisitantSelection === 'Local_Team') && !editTeamsFeeValues &&
                                <SecondaryButtonComponent disabled={(processingRateType === "" || disabledInput ) ? true : false}  className='tournament-config-arbitrage-payments__calculate-fees' onClick={() => {calculateRefereeFee()}}>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.setValuesBtn')}</SecondaryButtonComponent> 
                            }
                            { (localVisitantSelection === 'Both_Teams' || localVisitantSelection === 'Visitant_Team' || localVisitantSelection === 'Local_Team') && editTeamsFeeValues &&
                                <SecondaryButtonComponent disabled={(processingRateType === "" || disabledInput ) ? true : false}  className='tournament-config-arbitrage-payments__calculate-fees' onClick={() => {handleEditRefereeFee()}}>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.editValuesBtn')}</SecondaryButtonComponent> 
                            }
                            { props.referee_fee_value && props.referee_fee_value !== 0 &&
                                <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__container">
                                    <div className="baloa-names" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.refereesTitle')}</div>
                                    <div className="baloa-table" >{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.refereesInfo')}</div>
                                    <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__input-values" >
                                        <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__left-col">
                                            <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__referee">
                                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOfficialReferee')}</div>
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="tournament-config-arbitrage-payments-modal__set-referees-fee__input"
                                                    value={ `${mainRefereeFee}` }
                                                    displayType={'input'} 
                                                    thousandSeparator={','} 
                                                    decimalSeparator={"."} 
                                                    disabled={disabledInput}
                                                    prefix={'$ '} 
                                                    suffix={' ' + props.new_tournament_currency}
                                                    placeholder="$"
                                                    onValueChange ={ (value) => { handleRefereesFee(value.value, 'main_referee') } }
                                                    renderText={value => <div>{value}</div>}
                                                />
                                            </div>
                                            <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__referee">
                                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelThirdAssistantReferee')}</div>
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="tournament-config-arbitrage-payments-modal__set-referees-fee__input"
                                                    value={ `${lineReferee2Fee}` }
                                                    displayType={'input'} 
                                                    thousandSeparator={','} 
                                                    decimalSeparator={"."} 
                                                    disabled={disabledInput}
                                                    prefix={'$ '} 
                                                    suffix={' ' + props.new_tournament_currency}
                                                    placeholder="$"
                                                    onValueChange ={ (value) => {handleRefereesFee(value.value, 'line_referee_2')} }
                                                    renderText={value => <div>{value}</div>}
                                                />
                                            </div>
                                        </div>
                                        <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__rigth-col">
                                            <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__referee">
                                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelSecondAssistantReferee')}</div>
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="tournament-config-arbitrage-payments-modal__set-referees-fee__input"
                                                    value={ `${lineReferee1Fee}` }
                                                    displayType={'input'} 
                                                    thousandSeparator={','} 
                                                    decimalSeparator={"."} 
                                                    disabled={disabledInput}
                                                    prefix={'$ '} 
                                                    suffix={' ' + props.new_tournament_currency}
                                                    placeholder="$"
                                                    onValueChange ={ (value) => {handleRefereesFee(value.value, 'line_referee_1')} }
                                                    renderText={value => <div>{value}</div>}
                                                />
                                            </div>
                                            <div className="tournament-config-arbitrage-payments-modal__set-referees-fee__referee">
                                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}</div>
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="tournament-config-arbitrage-payments-modal__set-referees-fee__input"
                                                    value={ `${fourthRefereeFee}` }
                                                    displayType={'input'} 
                                                    thousandSeparator={','} 
                                                    decimalSeparator={"."} 
                                                    disabled={disabledInput}
                                                    prefix={'$ '} 
                                                    suffix={' ' + props.new_tournament_currency}
                                                    placeholder="$"
                                                    onValueChange ={ (value) => {handleRefereesFee(value.value, 'fourth_referee')} }
                                                    renderText={value => <div>{value}</div>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            { props.referee_fee_value && props.referee_fee_value !== 0 &&
                                <div className="tournament-config-arbitrage-payments-modal__referee-assigner__container" >
                                    <div className="baloa-names">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.refereeAssingerTitle')}</div>
                                    <div className="baloa-table">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.refereeAssignerInfo')}</div>
                                    <div className="tournament-config-arbitrage-payments-modal__referee-assigner">
                                        <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.refereeAssignerFee')}</div>
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className="tournament-config-arbitrage-payments-modal__referee-assigner__input"
                                            value={ `${refereeAssigner}` }
                                            displayType={'input'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            disabled={disabledInput}
                                            prefix={'$ '} 
                                            suffix={' ' + props.new_tournament_currency}
                                            placeholder="$"
                                            onValueChange ={ (value) => { handleRefereeAssigner(value.value) } }
                                            renderText={value => <div>{value}</div>}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className="tournament-config-arbitrage-payments-modal__btns-action__container">
                { activatePayment && localTeamFee === 0  && visitantTeamFee === 0 &&
                    <div className="tournament-config-arbitrage-payments-modal__btns-action__info-text" >
                        <span className="hubicon-info" />
                        <div className="baloa-names">{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.modalWarningLabel')}</div>
                    </div>
                }
                { activatePayment && (localTeamFee !== 0 || visitantTeamFee !== 0) &&
                    <div className="tournament-config-arbitrage-payments-modal__btns-action__amount-info" >
                        <div className="tournament-config-arbitrage-payments-modal__btns-action__amount-info__values">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="tournament-config-arbitrage-payments-modal__results__text"
                                value={ `${refereesValuesSum}` }
                                displayType={'text'} 
                                thousandSeparator={','} 
                                decimalSeparator={"."} 
                                disabled={disabledInput}
                                prefix={'$ '} 
                                suffix={' '}
                                placeholder="$"
                                onValueChange ={ (value) => {  } }
                                renderText={value => <div className="baloa-names">{`${value} ${props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.ofLabel')}`}</div>}
                            />
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="tournament-config-arbitrage-payments-modal__results__text"
                                value={ `${props.referee_fee_value}` }
                                displayType={'text'} 
                                thousandSeparator={','} 
                                decimalSeparator={"."} 
                                disabled={false}
                                prefix={'$ '} 
                                suffix={' '}
                                placeholder="$"
                                onValueChange ={ (value) => {  } }
                                renderText={value => <div className="baloa-names">{value} {props.new_tournament_currency}</div>}
                            />
                        </div>
                        <div className="tournament-config-arbitrage-payments-modal__btns-action__amount-info__values">
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="tournament-config-arbitrage-payments-modal__results__text"
                                value={ `${differenceTotalAmount}` }
                                displayType={'text'} 
                                thousandSeparator={','} 
                                decimalSeparator={"."} 
                                disabled={false}
                                prefix={'$ '} 
                                suffix={''}
                                placeholder="$"
                                onValueChange ={ (value) => {  } }
                                renderText={value => <div className={`baloa-table ${overValueTotalAmount ? 'over_value' : ''}`}>{props.new_tournament_currency} {value} {`${overValueTotalAmount ? props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.totalOverValueInfo') : props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.totalDifferenceInfo')}`}</div>}
                            />
                            
                        </div>
                    </div>
                }
                <div className="tournament-config-arbitrage-payments-modal__btns-action">
                    <SecondaryButtonComponent 
                        type="button"
                        className=""
                        onClick={ () => {props.onClose()} } 
                        disabled={false}
                    >
                        {props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelCancel')}
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent
                        type="button"
                        disabled={isSaveBtn}
                        onClick={()=> {handleOnSaveArbitrageConfig()}}
                        className=""                
                    >
                        {props.t('options.tournaments.pages.newTournament.saveButton')}
                    </PrimaryButtonComponent>
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentConfigArbitragePaymentsModalComponent);