
// Action creators
import { loadNotificationsAction, loadNotificationsNextAction} from 'app/notifications/actions/NotificationsActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Apis
import { GetUserNotificationsApi, GetUserNotificationsNextApi, SetReadNotification } from 'app/notifications/apis/NotificationsApi';

// i18next 
import i18n from 'i18next';


export const loadUserNotifications = () => {
    
    return ( dispatch ) => {

        GetUserNotificationsApi()
            .then( response => {
                dispatch( loadNotificationsAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
                console.log(error.response)
            })
            .then( () => {
                
            } );
    }
}

export const loadUserNotificationsNext = (next_page) => {
    
    return ( dispatch ) => {

        GetUserNotificationsNextApi(next_page )
            .then( response => {                
                dispatch( loadNotificationsNextAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('signUp:code.error3'),
                        error_message_is_open: true
                    } ) )
                }
                console.log(error.response)
            })
            
    }
}

export const setReadNotification = (id_notification) => {
    
    return ( dispatch ) => {
        
        SetReadNotification( id_notification )
            .then( response => {
                console.log("Cargando Notificaciones");
                dispatch(loadUserNotifications)                            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}
