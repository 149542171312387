// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './SignupGoalPanelComponent.styles.css';

// Actions
import { ChangeStateIsLogin } from 'app/authentication/actions/LoginActions';

//Images
import goal from 'assets/images/goal.svg';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

class SignupGoalPanelComponent extends Component {

    render = () => {

        return (

            <div className="new-account-goal">
                <img src={goal} className="new-account-goal__image" alt=""/>
                <h2 className="new-account-goal__yield">{this.props.t('success.title')}</h2>
                <h3 className="new-account-goal__title">{this.props.t('success.textOne')}</h3>
                <p className="new-account-goal__text">{this.props.t('success.textTwo')}</p>
                <Link to="/" className="new-account-goal__go-home">
                    <SecondaryButtonComponent  onClick={() => { this.props.ChangeStateIsLogin(true) }} className="large">
                        <span>{this.props.t('success.button')}</span>
                    </SecondaryButtonComponent>
                </Link>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChangeStateIsLogin: (value) => {
            return dispatch(ChangeStateIsLogin(value))
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignupGoalPanelComponent)


//export default connect(mapStateToProps, mapDispatchToProps)(SignupGoalPanelComponent);
