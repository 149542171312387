// React
import React from 'react'

// Styles
import './ProfileExperienceComponent.style.css'

// Moment
import * as moment from 'moment';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileExperienceComponent( props ) {
   

    return (

        <div className={ "profile-experience " + ( props.className ? props.className : '' ) }>

            {  props.experiences_type === 'achievements' && /* !props.experience_data.value.actually && */
                <div className='profile-experience__achievement'>
                    <div className='profile-experience__achievement-title'>
                        <div className='baloa-subtitle-2'>{props.experience.achievement_name}</div>
                        {props.is_owner?
                                <span className='hubicon-edit' onClick={() => {props.onEditButton(props.experience, props.experiences_type)}} />
                            :''
                        }
                    </div>
                    <div className='profile-experience__institution baloa-normal-medium'>{`${props.experience.institution} - ${props.experience.year}`}</div>
                    <div className='profile-experience__details baloa-normal'>{props.experience.description}</div>
                    {props.experience.photos?.length > 0 &&
                        <div className='profile-experience__gallery'>
                            {props.experience.photos?.map((img) => (

                                <div className='profile-experience__gallery__img' >
                                    <img src={img.url} alt={'img'} />
                                </div>
                                
                                )
                            )}

                        </div>
                    }
                    
                </div>
            }
            
            { (props.experiences_type === 'trajectory' || props.experiences_type === 'experiences') &&
                <div className='profile-experience__trajectory'>                    
                    <div className='profile-experience__trajectory-team'>
                        <div className='profile-experience__trajectory-team__team-data'>
                            <div className='profile-experience__trajectory-team__team-data__img'>
                                {props.experience.team_logo 
                                    ? <img src={props.experience.team_logo} alt='logo' />
                                    : <span className='icon-ball' color={getRandonIconBallColor()}/>
                                }
                            </div>
                            <div className='profile-experience__trajectory-team__team-data__data'>
                                <div className='baloa-subtitle-2'>{props.experience.team_name}</div>
                                <div className='baloa-normal-medium'>{`${moment(props.experience.start_year).format("YYYY")} - ${props.experience.currently_playing? props.t('profileExperiences.presentMsg') :moment(props.experience.end_year).format("YYYY")}`}</div>
                            </div>
                        </div>
                        {props.is_owner?
                                <span className='hubicon-edit' onClick={() => {props.onEditButton(props.experience, props.experiences_type)}}/>
                            :''
                        }
                    </div>
                    <div className='profile-experience__details baloa-normal'>{props.experience.description}</div>
                    {props.experience.photos?.length > 0 &&
                        <div className='profile-experience__gallery'>
                            {props.experience.photos?.map((img) => (

                                <div className='profile-experience__gallery__img' >
                                    <img src={img.url} alt={'img'} />
                                </div>
                                
                                )
                            )}

                        </div>
                    }
                </div>
            }

            {/* props.experience?.gallery?.map( gallery_item => {
                return <div key={ gallery_item } className="profile-experience__content__carrousel__item">
                    <img src={ `/api/media/${gallery_item}` } alt=""/>
                </div>
            } ) */}
        </div>
        
    )

}



export default (withTranslation("profile"))(ProfileExperienceComponent);

//export default connect(mapStateToProps, mapDispatchToProps)(ProfileExperienceComponent);
