// Action creators
import { 
    loadTeamDataAction,
    updateTeamExtraDataAction,
    searchMembersWithValidationAction,
    deletePlayerFromTeamAction,
    addPlayerToTeamAction,
    getTeamPlayersAction,
    loadPageTeamsAction,
    updateTeamDataAction,
    setBulkMembersAction,
    loadMemberRolesDataAction,
    loadMemberPositionsDataAction,
    updateTeamMemberAction,
    changeLoadingBulkFileStateAction,
} from './LeagueTeamsActionsCreators';

// Apis
import { 
    AddNewTeamApi,
    SetTeamExtraApi,
    DeletePlayerFromTeamApi,
    GetTeamPlayersApi,
    GetPageTeamsApi,
    GetTeamApi,
    EditTeamDataApi,
    UploadBulkFileApi,
    GetMemberRolesApi,
    GetMemberPositionsApi,
    AddMemberWithRolToTeamApi,
    UpdateTeamMemberApi,
    UploadBulkDataApi,
} from '../apis/LeagueTeamsApi';

import { 
    GetSearchMembersApi,
} from '../apis/LeagueApi';

import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { loadHomePagesShortcuts } from 'app/home/actions/HomeActions';

// i18next 
import i18n from 'i18next';

export const addNewTeam = ( team_data, configUpload, pagename ) => {

    /*return ( dispatch ) => {

        AddNewTeamApi( team_data )
            .then( response => {
                console.log("team_data response");
                console.log(response);
                dispatch( loadTeamDataAction( response ));
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }*/

    return async dispatch => {
        try {
            const response = await AddNewTeamApi( team_data, configUpload, pagename );
            dispatch( loadTeamDataAction( response ));
            dispatch( getTeamPlayers( response.data.teamname ));
            dispatch( loadHomePagesShortcuts() );
        }
        catch( error) {
                
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const setTeamExtra = ( extra_data, teamname ) => {

    return ( dispatch ) => {

        SetTeamExtraApi( extra_data, teamname )
            .then( response => {
                dispatch( updateTeamExtraDataAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchMembersWithValidation = ( searched_profile, actual_members ) => {

    return ( dispatch ) => {
        
        GetSearchMembersApi( searched_profile )
            .then( response => {

                let results = [];
                for(var i = 0; i < response.data.results.length; i++){
                    /* eslint-disable */
                    if(actual_members.find(item => item.id === response.data.results[i].id)){
                        continue;
                    }
                    results.push(response.data.results[i]);
                    /* eslint-enable */
                }

                dispatch( searchMembersWithValidationAction( results, actual_members ) );                
            })
            .catch( err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const deletePlayerFromTeam = ( teamname, username ) => {

    return ( dispatch ) => {

        DeletePlayerFromTeamApi( teamname, username )
            .then( response => {
                dispatch( deletePlayerFromTeamAction(username));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getTeamPlayers = ( teamname ) => {

    return ( dispatch ) => {

        GetTeamPlayersApi( teamname )
            .then( response => {
                dispatch( getTeamPlayersAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPageTeams = ( pagename ) => {

    return ( dispatch ) => {

        GetPageTeamsApi( pagename )
            .then( response => {
                dispatch( loadPageTeamsAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadTeamData = ( teamname ) => {

    return ( dispatch ) => {

        GetTeamApi( teamname )
            .then( response => {
                dispatch( loadTeamDataAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}
export const editTeamData = ( team_data, teamname, configUpload ) => {

    return async dispatch => {
        try {
            const response = await EditTeamDataApi( team_data, teamname, configUpload );
            dispatch( updateTeamDataAction( response ));
            dispatch( getTeamPlayers( teamname ));
            dispatch( changeErrorMessageSnackBar( {
                message: i18n.t('team:team.snackBarMessages.updateSuccessful'),
                error_message_is_open: true
            } ) )
        }
        catch( error) {
                
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const uploadBulkFile = ( teamname,  formdata, configUpload ) => {

    return async dispatch => {
        try {
            const response = await UploadBulkFileApi( teamname, formdata, configUpload );
            dispatch( setBulkMembersAction( response ));  
            dispatch( getTeamPlayers( teamname ));          
        }
        catch( error) {
            if(error.response.status === 400){
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: error.response.data.detail,
                        error_message_is_open: true
                    } ) )
                }
            }else{
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            }                
        }
        return 'done';
    }
}

export const loadMemberRolesData = ( ) => {

    return ( dispatch ) => {

        GetMemberRolesApi( )
            .then( response => {
                dispatch( loadMemberRolesDataAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadMemberPositionsData = ( ) => {

    // console.log('position ---*** ');

    return ( dispatch ) => {

        GetMemberPositionsApi( )
            .then( response => {
                dispatch( loadMemberPositionsDataAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addMemberWithRolToTeam = ( teamname, member ) => {

    return async( dispatch ) => {

        await AddMemberWithRolToTeamApi( teamname, member )
            .then( response => {
                dispatch( addPlayerToTeamAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const updateTeamMember = ( teamname, member ) => {

    return ( dispatch ) => {

        UpdateTeamMemberApi( teamname, member )
            .then( response => {
                dispatch( updateTeamMemberAction(response));                
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const uploadBulkData = ( teamname, membersData ) => {

    return async( dispatch ) => {

        await UploadBulkDataApi( teamname, membersData )
            .then( response => {
                if(!response.data.error){
                    dispatch( changeLoadingBulkFileStateAction(false)); 
                }                
                dispatch( setBulkMembersAction( response ));
                dispatch( getTeamPlayers( teamname ));             
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}