//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Styles
import './LeagueBulkPlayerStatusInfoModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueBulkPlayerStatusInfoModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            //onClose={props.onClose}  
            className={"league-bulk-player-status-info-modal"}     
        >
            <div className="league-bulk-player-status-info-modal__title">                
                {props.modalTitle}
            </div>
            <div className="league-bulk-player-status-info-modal__text baloa-table" dangerouslySetInnerHTML={{
                __html: props.t('newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.message')
                }}>
            </div>
            
            <div className="league-bulk-player-status-info-modal__status-container baloa-table">
                <div className="league-bulk-player-status-info-modal__status-title baloa-table">
                    {props.t('newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.stautsTitle')}
                </div>
                <div className='league-bulk-player-status-info-modal__status-info'>
                    <div className='league-bulk-player-status-info-modal__status-tag baloa-username green'>{"Registrado"}</div>
                    <div className='league-bulk-player-status-info-modal__status-text baloa-username'>{props.t('newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.registerStatusText')}</div>
                </div>
                <div className='league-bulk-player-status-info-modal__status-info'>
                    <div className='league-bulk-player-status-info-modal__status-tag baloa-username orange'>{"Pre registrado"}</div>
                    <div className='league-bulk-player-status-info-modal__status-text baloa-username'>{props.t('newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.preregisterStatusText')}</div>
                </div>
                <div className='league-bulk-player-status-info-modal__status-info'>
                    <div className='league-bulk-player-status-info-modal__status-tag baloa-username'>{"No registrado"}</div>
                    <div className='league-bulk-player-status-info-modal__status-text baloa-username'>{props.t('newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.notRegisterStatusText')}</div>
                </div>
            </div>
            
            <div className="league-bulk-player-status-info-modal__actions"> 
                
                <PrimaryButtonComponent 
                    onClick={ props.onClose }
                    className={"small"}
                >
                    <span>{props.acceptButtonText}</span>
                </PrimaryButtonComponent>
                
            </div>
        </ModalComponent>
    )
}

export default withTranslation('team')(LeagueBulkPlayerStatusInfoModalComponent);