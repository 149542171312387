
import React from 'react';

//Styles
import './LeagueTournamentReportCalendarComponent.styles.css'

// Moment
import * as moment from 'moment';
import i18n from 'i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function LeagueTournamentReportCalendarComponent(props) {
    React.useEffect(() => {
        props.handleOnPhaseCalendar(props.tournament_phase_id, props.active_phase_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_phase_id, props.active_phase_id]);

    return (
        <>
            {props.days_calendar_view.calendar?.map((jornada, indexJornada) => (
                <React.Fragment key={jornada.match_date}>
                    <div className='calendar-info__container'>
                        <div className='league-tournament-report-calendar'></div>
                        <div className="league-tournament-report-calendar__container">
                            <div className='baloa-normal-medium'>{jornada.match_date}</div>
                        </div>
                        {jornada.groups?.map((group) => (
                            <div key={group.id} className="league-tournament-report-calendar__group-title_container">
                                {props.tournament_active_phase.phase_type === 'Groups' &&
                                    <div className="league-tournament-report-calendar__group-title baloa-hyperlink-2">
                                        {i18n.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.group_name.split(" ")[0]}`,{_number: group.group_name.split(" ")[1]})}
                                        {/* // : i18n.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.group_name}`, { phase_name: i18n.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${props.phase_name}`) }) */}
                                    </div>
                                }
                                <div className="league-tournament-report-calendar__date-by-group">
                                    {group.matches.map((match) => (
                                        <div key={match.id} className={props.tournament_active_phase.phase_type === 'Groups' ? "league-tournament-report-calendar__match-detail-container" : "league-tournament-report-calendar__match-detail-container_margin"} onClick={() => props.handleOnPostMatch(match)} >
                                            <div className="league-tournament-report-calendar__match-info-container">
                                                {match.state === "Finalized" ?
                                                    <div className="league-tournament-report-calendar__teams-result-container">
                                                        <div className="baloa-table finalized">{i18n.t('league:ended')}</div>
                                                    </div>
                                                    :match.match_date ?
                                                        <div className="league-tournament-report-calendar__teams-result-container">
                                                            <span className='hubicon-finish_date' />
                                                            <div className="baloa-table">{moment(match.match_date).format("ddd DD MMM YYYY")}</div> -
                                                            <div className="baloa-table">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                                        </div>
                                                        :
                                                        <div className="league-tournament-report-calendar__teams-result-container">
                                                            <span className='hubicon-finish_date' />
                                                            <div className="baloa-table">N/A</div>
                                                        </div>
                                                }
                                                <div className='league-tournament-report-calendar__teams-stadium-container'>
                                                    <span className='hubicon-stadium' />
                                                    <div className='baloa-table' >
                                                        {match.state !== "Not_scheduled"
                                                            ? match.field
                                                            : ' N/A'
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="league-tournament-report-calendar__teams-names-container">
                                                <div className="league-tournament-report-calendar__teams_local-container">
                                                    <div className='league-tournament-report-calendar__teams-container '>
                                                        <div className="baloa-names">{match.local_team_name}</div>
                                                        {match.local_team_photo === null
                                                            ?
                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            :
                                                            <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="league-tournament-report-calendar-score__teams-container">
                                                    {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                                    {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                                    {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                                </div>
                                                <div className="league-tournament-report-calendar__teams_visitant-container">
                                                    <div className="league-tournament-report-calendar__teams-container">

                                                        {match.visitant_team_photo === null
                                                            ?
                                                            <span className="icon-ball" color={getRandonIconBallColor()} />
                                                            :
                                                            <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                                        }
                                                        <div className="baloa-names">{match.visitant_team_name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ))}
        </>
    );
}
export default LeagueTournamentReportCalendarComponent;