
// React
import React from 'react';

// Components
import ButtonComponent from 'shared/components/button/ButtonComponent';

// Styles
import './PrimaryButtonComponent.styles.css';

// Assets


function PrimaryButtonComponent( props ) {
    return (
        <ButtonComponent 
        type={props.type}
        onClick={props.onClick} 
        disabled={props.disabled} 
        className={ "primary-button " + props.className }
        >
            {props.children}
        </ButtonComponent>
    )
}

export default PrimaryButtonComponent;