import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
//Material Ui Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//Project Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import team_shield_page from 'assets/icons/team-page-shield.svg';
import SelectFieldMoreOptionsComponent from 'shared/components/select-field/SelectFieldMoreOptionsComponent';
import ModalPageCreateInformation from '../modal-page-create-information/ModalPageCreateInformation';
import PageNewTeamFormComponent from '../page-new-team-form/PageNewTeamFormComponent';
import LeagueAddPlayersToTeamComponent from 'app/leagues/components/league-add-players-to-team/LeagueAddPlayersToTeamComponent';
// i18n
import { withTranslation } from 'react-i18next';
//Styles
import './PageTeamCreateModalComponent.styles.css';

function PageTeamCreateModalComponent(props) {

  const [arrayPages, setArrayPages] = useState([]);
  const [nextStepModal, setNextStepModal] = useState(false);
  const [radioNewPage, setRadioNewPage] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);
  const [createPage, setCreatePage] = useState(false);
  const [addMembers, setAddMembers] = useState(false);
  const [successCreateTeam, setSuccessCreateTeam] = useState(false);
  const [idPageSelected, setIdPageSelected] = useState('');
  const [changeLink, setChangeLink] = useState(false);

  const formInitialValues = { selectedPage: '' };

  useEffect(() => {
    if (props.pages) {
      setArrayPages(props.pages?.map(page => {
        return {
          flag_icon: page.photo ?? team_shield_page,
          value: page.pagename,
          content: (<div className={'modal-page-create_newPage_select_item'}>
            <p className={"baloa-names"}>{page.name}</p>
            <p className={"baloa-username"}>@{page.pagename}</p>
          </div>)
        }
      }));
    }
  }, [props.pages]);

  const handleCreateNewPage = (event) => {
    if (radioNewPage) {
      setRadioNewPage(false);
    } else {
      setRadioNewPage(event?.target.value === 'new_page' ? true : false);
    }
  };

  const validationStep = () => {
    if (radioNewPage) {
      setCreatePage(true);
      setCreateTeam(false);
      setNextStepModal(true);
    }
    if (idPageSelected.length > 0) {
      if (props.OnlySelectedPage) {
        setChangeLink(true);
      } else {
        setCreateTeam(true);
        setCreatePage(false);
        setNextStepModal(true);
      }
    }
  };

  const handleSentToCreatePage = (data_new) => {
    setIdPageSelected(data_new.pagename);
    props.onNewData(data_new);
    if (props.OnlySelectedPage) {      
        const timeout = setTimeout(() => { 
          setChangeLink(true);
        }, 1000);
        return () => clearTimeout(timeout);
    } else {
      setCreatePage(false);
      setCreateTeam(true);
      setAddMembers(false);
    }
    
  };

  const handleSentToCreateTeam = (team_data) => {
    props.handleCreateNewTeam(team_data, team_data.photo);
    setCreatePage(false);
    setCreateTeam(false);
    //   setAddMembers(true);
    //cleanValuesModal();
    if(props.new_page_team_inscription) {
      cleanValuesModal();
    } else {
      setSuccessCreateTeam(true);
    }
  };

  const cleanValuesModal = () => {
    setNextStepModal(false);
    setRadioNewPage(false);
    setCreatePage(false);
    setCreateTeam(false);
    setAddMembers(false);
    setSuccessCreateTeam(false);
    setChangeLink(false);
    props.onClose();
  };

  const handleOnCopyPlayerCode = async (code) => {
    await navigator.clipboard.writeText(code);
    props.showCopyPostMessage(props.t('team:teams.teamsModalHome_copyLabel'));
  };

  const builStyleClass = () => {
    if (createPage) return "create-form";
    else if (createTeam) return "create-form";
    else if (addMembers) return "create-form";
    else return '';
  };

  if (changeLink) {
    props.onClose();
    return <Redirect to={{
      pathname: `/league/tournament/${idPageSelected}`
    }} />;
  }

  return (
    <Modal2Component
      isOpen={props.isOpen}
      onClose={() => cleanValuesModal()}
      title={props.OnlySelectedPage
        ? props.t("league:options.tournaments.pages.newTournament.labelTitle")
        : createPage
          ? props.t("league:modal_create_page.create_page")
          : successCreateTeam
            ? props.t("team:newTeam.codeTeam")
            : props.t("team:newTeam.title")        
        }
      className={`modal-page-create-team ${builStyleClass()} ${successCreateTeam ? 'page-team-create__team-code' : ''}`}>
      <div className="modal-page-create-team-container">
        <FormComponent
          formInitialValues={formInitialValues}
          onSubmit={() => { }}
        >
          {/* Selected Page*/}
          {!nextStepModal &&
            <React.Fragment>
              <div className="modal-page-create-team-information">
                <div className= 'modal-page-create-team-information_icon'>
                  <span className='icon-trophy'></span>
                </div>
                {/* <img className="modal-page-create-team-inf_img" src={team_shield_page} alt='' /> */}
                <p className="baloa-table">
                  {props.OnlySelectedPage
                    ? props.t("league:options.tournaments.pages.newTournament.createTournamentHubNewSubtitle")
                    : props.t("team:teams.teamsModalHome_Subtitle")
                  }
                </p>
              </div>
              {props.OnlySelectedPage &&
              <div className="modal-page-create-team-information_steps">
                <div className="modal-page-create-team-information_steps-info">
                  <p className="baloa-table">
                      {props.t("league:options.tournaments.pages.newTournament.createTournamentHubSteps")}
                  </p>
                </div>
                <div className='modal-page-create-team-steps-icon'>
                <div className='modal-page-create-team-steps-positions'>1</div>
                  <p className="baloa-table">
                      {props.t("league:options.tournaments.pages.newTournament.createTournamentHubStepsOne")}
                  </p>
                </div>
                <div className='modal-page-create-team-steps-icon'>
                  <div className='modal-page-create-team-steps-positions'>2</div>
                  <p className="baloa-table">
                      {props.t("league:options.tournaments.pages.newTournament.createTournamentHubStepsTwo")}
                  </p>
                </div>
                <div className='modal-page-create-team-steps-icon'>
                <div className='modal-page-create-team-steps-positions'>3</div>
                  <p className="baloa-table">
                      {props.t("league:options.tournaments.pages.newTournament.createTournamentHubStepsThree")}
                  </p>
                </div>
              </div>
              }
              <p className='modal-page-create-team-selected_option_title baloa-names'>
                {props.OnlySelectedPage
                  ? props.t("league:options.tournaments.pages.newTournament.createTournamentHubSubtitle_2")
                  : props.t("team:teams.teamsModalHome_Subtitle_2")
                }
              </p>
              <div className="modal-page-create-team-selected_option">
                <div className={`modal-page-create__check-box ${radioNewPage ? 'active' : ''}`}>
                  <div className='modal-page-create__check-box__icon'>
                  <span class="hubicon-new_page">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                  </span>
                    <div className='baloa-hyperlink-2'>{props.t("league:modal_create_page.new_page")}</div>
                  </div>
                  <input
                    className=''
                    type="checkbox"
                    name='modal-page-create_newPage'
                    id=''
                    value='new_page'
                    onChange={handleCreateNewPage}
                  />
                </div>
                <div className="modal-page-create_newPage_select">
                  <SelectFieldMoreOptionsComponent
                    onChange={(e) => setIdPageSelected(radioNewPage ? '' : e)}
                    label={props.t("league:modal_create_page.existing_page")}
                    field_name={"selectedPage"}
                    disabled={arrayPages.length === 0 || radioNewPage}
                    validationFunction={() => { }}
                    resetError={() => { }}
                    error={props.errors}
                    message=""
                    placeholder={props.t("league:modal_create_page.selected_page")}
                    options={radioNewPage ? [] : arrayPages}
                    className="page-team-create-modal__existing-page"
                  />
                </div>
              </div>
            </React.Fragment>
          }

          {/*Form to create page*/}
          {createPage &&
            <ModalPageCreateInformation
              idPageSelected={idPageSelected}
              errors={props.errors}
              onlySelectedPage={props.OnlySelectedPage}
              handleSentToCreatePage={(data_page) => handleSentToCreatePage(data_page)}
            />
          }

          {/*Form to create team*/}
          {createTeam &&
            <PageNewTeamFormComponent
              gender={props.gender}
              category={props.category}
              errors={props.errors_newTeam}
              idPageSelected={idPageSelected}
              page_data={props.page_data}
              handleSentToCreateTeam={(data_team) => handleSentToCreateTeam(data_team)}
              new_page_team_inscription={props.new_page_team_inscription}
              tournament={props.tournament}
              selected_tournament={props.selected_tournament}
            />
          }

          {/* Add members to team*/}
          {addMembers &&
            <LeagueAddPlayersToTeamComponent
              addPlayerHome={true}
              team={props.team}
              profiles={props.profiles}
              handleSearchMember={props.handleSearchMember}
              onDeletePlayerButton={props.onDeletePlayerButton}
              onSelectTeamPlayer={props.onSelectTeamPlayer}
              handleCleanMemberSearch={props.handleCleanMemberSearch}
              handleOnSaveButton={props.handleOnSaveButton} // Revisar el nombre de la Pagina
              handleOnAddBulk={(new_val) => { console.log(new_val) }}
              handleOnMemberActionClick={props.handleOnMemberActionClick}
            />
          }
          {successCreateTeam &&
            <div className="modal-finish_createTeam">
              <div className="modal-finish_creaTeam_code">
                <p className="modal-finish_creaTeam_code_text baloa-table">{props.t("team:teams.teamsModalHome_successTitle")}</p>
                <div className="modal-finish_createTeam_codeTeam">
                  <p className="modal-finish_createTeam_code baloa-names">{props.team?.team_code}</p>
                  <div clasName="modal-finish_createTeam_code_click"
                    onClick={() => handleOnCopyPlayerCode(props.team?.team_code)}>
                    <span className='icon-copy-86' />
                  </div>
                </div>
              </div>
              <p className="modal-finish_creaTeam_code_text baloa-table">
                {props.t("team:teams.teamsModalHome_successSubTitle")}
              </p>
              <div className="modal-finish_createTeam-buttons">
                <SecondaryButtonComponent
                  type="button"
                  onClick={() => cleanValuesModal()}
                  className="page-information-form_left_submit small">
                  <span>{props.t("team:teams.teamsModalHome_labelClosed")}</span>
                </SecondaryButtonComponent>
                <Link to={'/tournament/hub'} className={"header-auth__actions__home "}>
                  <PrimaryButtonComponent
                    type="button"
                    onClick={() => cleanValuesModal()}
                    className="page-information-form__form__submit small">
                    <span>{props.t("team:teams.teamsModalHome_GoTournament")}</span>
                  </PrimaryButtonComponent>
                </Link>
              </div>
            </div>
          }

          <div className={'modal-page-create_newPage_buttons'}>
            {!nextStepModal &&
              <PrimaryButtonComponent
                type="button"
                onClick={() => validationStep()}
                className="page-information-form__form__submi`t">
                <span>{props.t("team:teams.teamsModalHome_Continue")}</span>
              </PrimaryButtonComponent>
            }

            {addMembers &&
              <PrimaryButtonComponent
                type="button"
                onClick={() => {
                  setAddMembers(false);
                  setSuccessCreateTeam(true);
                }}
                className="page-information-form__form__submi`t">
                <span>{props.t("team:teams.teamsModalHome_Finish")}</span>
              </PrimaryButtonComponent>
            }
          </div>
        </FormComponent>
      </div>
    </Modal2Component>
  )
}

export default withTranslation(['team', 'league'])(PageTeamCreateModalComponent);