
// League state
const league_state = {
    searched_teams: [],
    searched_members: [],
    searched_profiles: [],
    active_team: {},
    active_member: {},
    modals: {
        team_info_is_open: false,
        add_member_to_league_is_open: false,
        add_team_to_league_is_open: false,
        is_open_member_team_modal: false,
    },
    members:[],
    league_teams: [],
    league_members: [],
    see_tournament_from_team_member_modal: null,
    league_teams_is_loading: false,  
    league_members_is_loading: false,
    new_league_page: false,
}


export default function LeagueReducer( state = league_state, action ) {
    
    switch ( action.type ) {

        case "LEAGUE:SEARCH_TEAMS": {

            return { ...state, searched_teams: action.data.data.results }

        }

        case "LEAGUE:SEARCH_TEAMS_WITH_VALIDATION": {
            
            return { ...state, searched_teams: action.data }

        }

        case "LEAGUE:LOAD_ACTIVE_TEAM": {

            return { ...state, active_team: action.team_data }

        }

        case "LEAGUE:LOAD_ACTIVE_MEMBER": {

            return { ...state, active_member: action.member_data }

        }

        case "LEAGUE:CHANGE_TEAM_INFO_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], team_info_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }  
        case "LEAGUE:SEARCH_MEMBERS": {

            return { ...state, searched_members: action.data.data.results }

        }

        case "LEAGUE:SEARCH_MEMBERS_WITH_VALIDATION": {

            return { ...state, searched_members: action.data }

        }

        

	    case "LEAGUE:CHANGE_LEAGUES_ADD_MEMBER_TO_LEAGUE_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], add_member_to_league_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "LEAGUE:CHANGE_LEAGUES_ADD_TEAM_TO_LEAGUE_MODAL_STATE":{

            let new_modals_state = { ...state['modals'], add_team_to_league_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "LEAGUE:ADD_MEMBER_TO_LEAGUE":{

            return { ...state, members: [...state.members, action.data.data], searched_members:[]}            

        }

        case "LEAGUES:LOAD_LEAGUE_MEMBERS": {
            
            return { ...state, league_members : action.data}

        }
        case "LEAGUES:LOAD_LEAGUE_TEAMS": {

            return { ...state, league_teams : action.data}

        }
        case "LEAGUE:CHANGE_TEAM_MEMBERS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], is_open_member_team_modal: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "LEAGUE:SEE_TOURNAMENT_FROM_MEMBER_TEAM_MODAL": {
            
            return { ...state, see_tournament_from_team_member_modal : action.new_state}

        }

        case "LEAGUE:CHANGE_LOADING_LEAGUE_TEAMS": {            

            return { ...state, league_teams_is_loading: action.data }

        }

        case "LEAGUE:CHANGE_LOADING_LEAGUE_MEMBERS": {            

            return { ...state, league_members_is_loading: action.data }

        }

        case "HOME:CHANGE_NEW_LEAGUE_PAGE": {

            return { ...state, new_league_page: action.new_state }

        }

        case"LEAGUE:UPDATE_ACTIVE_MEMBER_ROLES": {

            let new_active_member_state = { ...state['active_member'], roles: action.data.data.roles }
            return { ...state, active_member: new_active_member_state }

        }

        // no default
        
    }

    return state;
}
