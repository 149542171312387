
/*eslint eqeqeq: "off"*/
// Action creators
import { 
    changeProfileLoadingAction, 
    loadProfileAction, 
    loadLogguedinProfileAction,
    changeProfilePostsLoadingAction,
    loadProfilePostsAction,
    // changeProfileDescriptionModalStateAction,
    changeProfileDataSheetModalStateAction,
    // changeProfileTechnicsPrimaryPositionModalStateAction,
    // changeProfileTechnicsSecondaryPositionModalStateAction,
    // changeProfileTechnicsMeassuresModalStateAction,
    // changeProfileTechnicsLateralityModalStateAction,
    // changeProfileExperiencesModalStateAction,
    // changeProfileSpecialtiesModalStateAction,
    loadProfileFollowersAction,
    loadProfileFollowingAction,
    addProfileNationalityAction,
    searchProfileNationalitiesAction,
    searchProfileLocationsAction,
    switchProfileToPageAction,
    updateProfilePostsAction,
    loadProfileStatsAction,
    loadProfileTournamentsAction,
    loadProfilePaymentCheckoutAction,
    addProfilePresentationVideo,
    addProfileFeaturedVideo,
    updateProfileOneVideo,
    loadProfileMatchesAction,
    loadProfileTournamentMatchesAction,
    loadProfileAchievementsAction,
    addProfileAchievementAction,
    updateProfileAchievementAction,
    loadProfileTrajectoriesAction,
    addProfileTrajectoryAction,
    updateProfileTrajectoryAction,
    searchProfileWorldCitiesAction,
    loadProfileExternalStatsAction,
    loadingProfilesFeaturedVideosAction,
    loadingProfilesFeaturedVideosNextAction,
} from './ProfileActionsCreators';
import { removeFromProfileSuggestions, changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
//import { loadHomeProfileSuggestions, loadHomePageSuggestions, loadHomePosts } from 'app/home/actions/HomeActions';
import { getUserMeAction } from 'app/authentication/actions/LoginActions';

// Apis
import { 
    GetProfileApi, 
    FollowProfileApi, 
    UnfollowProfileApi,
    GetProfilePostsApi,
    CreateAttributeOnProfileApi,
    EditAttributeOnProfileApi,
    UpdateProfileDataApi,
    GetProfileFollowersApi,
    GetProfileFollowingApi,
    DeleteAttributeOnProfileApi,
    InviteProfileToValidateAttributeApi,
    AddProfileNationalityApi,
    SearchProfileNationalitiesApi,
    SearchProfileLocationsApi,
    BlockProfileApi,
    AddProfileTeamByCodeApi,
    DeleteProfileTeamApi,    
    LoadProfileTournamentStatsApi,
    LoadProfileAchievementsStatsApi,
    LoadProfileTournamentsApi,
    ProfileTournamentStatsPaymentApi,
    LoadProfileTeamsApi,
    UploadVideoProfileApi,
    LoadAllProfileVideoApi,
    deleteProfileVideoApi,
    updateProfileVideoApi,
    loadProfileMatchesApi,
    loadProfileTournamentMatchesApi,
    updateProfileFeaturedMatchVideoApi,
    loadProfileAchievementsApi,
    createProfileAchievementApi,
    updateProfileAchievementApi,
    deleteProfileAchievementApi,
    loadProfileTrajectoriesApi,
    createProfileTrajectoryApi,
    updateProfileTrajectoryApi,
    deleteProfileTrajectoryApi,
    LoadProfileExternalStatsApi,
    AddExternalStatApi,
    UpdateExternalStatApi,
    DeleteExternalStatApi,
} from '../apis/ProfileApi';
import { FollowPageApi, UnfollowPageApi } from 'app/pages/apis/PageApi';

import {SearchWorldCitiesApi} from 'app/leagues/apis/LeagueTournamentApi';

// i18next 
import i18n from 'i18next';

 
export const loadProfile = ( username ) => {

    return ( dispatch ) => {
        
        dispatch( changeProfileLoadingAction( true ) );
        GetProfileApi( username )
            .then( response => {
                dispatch( loadProfileAction( response ) );
                if(response.data.profile_pay_a_tournament){
                    dispatch( loadProfileMatches(username)); 
                    dispatch( loadProfileTournamentMatches(username)); 
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log(err);
            })
    }
}

export const loadLogguedinProfile = ( username ) => {

    return ( dispatch ) => {
        
        GetProfileApi( username )
            .then( response => {
                dispatch( loadLogguedinProfileAction( response ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log(err);
            })
    }
}

export const updateProfileData = ( profile_username, profile_data ) => ( dispatch ) => {

    UpdateProfileDataApi( profile_username, profile_data )
        .then( response => {
            dispatch( loadProfile( profile_username ) )
            dispatch( loadProfileAchievements( profile_username ) )
            dispatch( loadProfileTrajectories( profile_username ) )
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log( error )
        })

}

export const followProfile = ( profile ) => {
    
    return ( dispatch ) => {
        
        FollowProfileApi( profile.user.username )
            .then( response => {
                dispatch( removeFromProfileSuggestions( profile ) )
                dispatch( loadProfile( profile.user.username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }
};

export const followProfileByUsername = ( profile_username, current_username ) => {

    return ( dispatch ) => {
        
        FollowProfileApi( profile_username )
            .then( response => {
                dispatch( loadProfileFollowers( current_username ) )
                dispatch( loadProfileFollowing( current_username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }
}

export const followPageByUsername = ( page_pagename, current_username ) => {
    return ( dispatch) => {

        FollowPageApi( page_pagename )
            .then( response => {
                
                dispatch( loadProfileFollowers( current_username ) )
                dispatch( loadProfileFollowing( current_username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const unfollowProfile = ( profile ) => {

    return ( dispatch ) => {
        
        UnfollowProfileApi( profile.user.username )
            .then( response => {
                dispatch( loadProfile( profile.user.username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }
}

export const unfollowProfileByUsername = ( profile_username, current_username ) => {

    return ( dispatch ) => {
        
        UnfollowProfileApi( profile_username )
            .then( response => {
                dispatch( loadProfileFollowers( current_username ) )
                dispatch( loadProfileFollowing( current_username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }
}

export const unfollowPageByUsername = ( page_pagename, current_username ) => {
    return ( dispatch) => {

        UnfollowPageApi( page_pagename )
            .then( response => {
                
                dispatch( loadProfileFollowers( current_username ) )
                dispatch( loadProfileFollowing( current_username ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}


export const createOrEditAttributeOnProfile = ( profile, new_attribute_data ) => {

    return ( dispatch ) => {

        if ( 'id' in new_attribute_data ) {
            dispatch( editAttributeOnProfile( profile, new_attribute_data ) )
        }
        else {
            dispatch( createAttributeOnProfile( profile, new_attribute_data ) );
        }

    }

}

export const deleteAttributeOnProfile = ( profile, attribute_id ) => {

    return ( dispatch ) => {
        
        DeleteAttributeOnProfileApi( attribute_id )
            .then( response => {                
                dispatch( loadProfile( profile.user.username ) );                
            })
            .catch( error => {
                console.log( error );
            } )

    }
}

export const createAttributeOnProfile = ( profile, new_attribute_data ) => {

    return ( dispatch ) => {

        CreateAttributeOnProfileApi( profile, new_attribute_data )
            .then( response => {
                dispatch( loadProfile( profile.user.username ) );
                //dispatch( moveThroughAttributeForms( new_attribute_data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }

}

export const editAttributeOnProfile = ( profile, new_attribute_data ) => {

    return ( dispatch ) => {

        EditAttributeOnProfileApi( new_attribute_data )
            .then( response => {
                dispatch( loadProfile( profile.user.username ) );
                //dispatch( moveThroughAttributeForms( new_attribute_data ) );
            } )
            .catch( error => {
                // let attribute_type = new_attribute_data['atype'].replace('-','_');
                // dispatch( loadProfileErrors( { [attribute_type]: "Prueba de error" }, 'technics' ) )
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )

    }

}

export const moveThroughAttributeForms = ( new_attribute_data ) => {

    return ( dispatch ) => {

        // if ( new_attribute_data['atype'] == 'primary-team' ) {
            // dispatch( changeProfileTechnicsPrimaryTeamModalStateAction( false ) );
            dispatch( changeProfileDataSheetModalStateAction( true ) )
        // } else if ( new_attribute_data['atype'] == 'primary-position' ) {
        //     dispatch( changeProfileTechnicsPrimaryPositionModalStateAction( false ) )
        //     dispatch( changeProfileTechnicsSecondaryPositionModalStateAction( true ) );
        // } else if ( new_attribute_data['atype'] == 'secondary-position' ) {
        //     dispatch( changeProfileTechnicsSecondaryPositionModalStateAction( false ) );
        //     dispatch( changeProfileTechnicsMeassuresModalStateAction( true ) );
        // } else if ( new_attribute_data['atype'] == 'height' ) {
        //     dispatch( changeProfileTechnicsMeassuresModalStateAction( false ) );
        //     dispatch( changeProfileTechnicsLateralityModalStateAction( true ) );
        // } else if ( new_attribute_data['atype'] == 'laterality' ) {
        //     dispatch( changeProfileTechnicsLateralityModalStateAction( false ) );
        // } else if ( new_attribute_data['atype'] == 'experience' ) {
        //     dispatch( changeProfileExperiencesModalStateAction( false ) );
        // }  else if ( new_attribute_data['atype'] == 'specialty' ) {
        //     dispatch( changeProfileSpecialtiesModalStateAction( false ) );
        // } else if ( new_attribute_data['atype'] == 'description' ) {
        //     dispatch( changeProfileDescriptionModalStateAction( false ) );
        // }

    }

}

export const loadProfilePosts = ( username, loading = false, next_page, previous_page ) => ( dispatch ) => {

    if ( !loading ) {
        
        if(previous_page === null || next_page !== null){
            dispatch( changeProfilePostsLoadingAction( true ) );
            GetProfilePostsApi( username, next_page )
                .then( response => {
                    dispatch( loadProfilePostsAction( response.data ) );
                })
                .catch( error => {
                    if(localStorage.getItem('token') != null){
                        dispatch( changeErrorMessageSnackBar( {
                            message: i18n.t('profile:postError'), //'Tenemos un problema!',
                            error_message_is_open: true
                        } ) )
                    }
                    //console.log(error.response)
                })
                .then( () => {
                    dispatch( changeProfilePostsLoadingAction( false ) );
                } );
        }
    }
}

export const loadProfileFollowers = ( username ) => ( dispatch ) => {

    // dispatch( changeProfilePostsLoadingAction( true ) );
    GetProfileFollowersApi( username )
        .then( response => {
            dispatch( loadProfileFollowersAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const loadProfileFollowing = ( username ) => ( dispatch ) => {

    dispatch( changeProfilePostsLoadingAction( true ) );
    GetProfileFollowingApi( username )
        .then( response => {
            dispatch( loadProfileFollowingAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const inviteProfileToValidateAttribute = ( attribute, username ) => {

    return ( dispatch ) => {
        
        InviteProfileToValidateAttributeApi( attribute, username )
            .then( response => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:inviteMsg') + ` ${username}!`, //`Hemos enviado tu invitación a ${username}!`,
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            } )
    }
}

export const addProfileNationality = ( profile, new_nationality_data ) => ( dispatch ) => {
    
    AddProfileNationalityApi( profile, new_nationality_data )
        .then( response => {
            dispatch( addProfileNationalityAction( response.data ) );
            dispatch( loadProfile( profile.user.username ) )
            dispatch( getUserMeAction(profile.user.username) )
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const searchProfileNationality = ( profile, new_nationalities_data ) => ( dispatch ) => {

    SearchProfileNationalitiesApi( profile, new_nationalities_data )
        .then( response => {
            dispatch( searchProfileNationalitiesAction( response.data ) );
        } )
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log( error );
        } )
}

export const searchProfileLocations = ( countrycode, city ) => ( dispatch ) => {

    if(countrycode){
        SearchProfileLocationsApi( countrycode, city )
            .then( response => {
                dispatch( searchProfileLocationsAction( response.data ) );
            } )
            .catch( error => {
                if(localStorage.getItem('token') != null){                
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            } )
    }
}

export const blockProfile= ( username ) => {

    return ( dispatch ) => {
        
        BlockProfileApi( username )
            .then( response => {
                // console.log('r', response);
                dispatch( changeErrorMessageSnackBar( {
                    message: `@${username} ` + i18n.t('profile:blockedMsg'),
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            } )
    }
}
export const switchProfileToPage = ( page ) => {
    return ( dispatch ) => {
        dispatch( switchProfileToPageAction( page ) );
    }
}


export const updateProfilePosts = ( username ) => ( dispatch ) => {
    
    GetProfilePostsApi( username )
        .then( response => {
            dispatch( updateProfilePostsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:postError'), //'Tenemos un problema!',
                    error_message_is_open: true
                } ) )
            }
            //console.log(error.response)
        })
           
}

export const addProfileTeamByCode = ( username, teamCode ) => {
    return ( dispatch ) => {
        AddProfileTeamByCodeApi( username, teamCode )
            .then( response => {
                dispatch(loadProfile(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const deleteProfileTeam = ( username, teamname ) => {
    return ( dispatch ) => {
        DeleteProfileTeamApi( username,teamname )
            .then( () => {
                dispatch(loadProfile(username));
            })
            .catch( error => {
                //console.log(error);
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const  loadProfileTournamentStats = ( username, next_page ) => {
    return ( dispatch ) => {
        LoadProfileTournamentStatsApi( username, next_page )
            .then( response  => {
                dispatch(loadProfileStatsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const  loadProfileAchievementsStats = ( username, next_page ) => {
    return ( dispatch ) => {
        LoadProfileAchievementsStatsApi( username, next_page )
            .then( response  => {
                dispatch(loadProfileStatsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const loadProfileTournaments = ( username, next_page ) => {
    return ( dispatch ) => {
        LoadProfileTournamentsApi( username, next_page )
            .then( response  => {
                dispatch(loadProfileTournamentsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}


export const  profileTournamentStatsPayment = ( username, payment_data ) => {
    return ( dispatch ) => {
        ProfileTournamentStatsPaymentApi( username, payment_data )
            .then( response  => {
                let payment_data = {
                    amount_total: response.data.amount_total,
                    currency: response.data.currency,
                    url: response.data.url
                }
                dispatch( loadProfilePaymentCheckoutAction(payment_data) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const  loadProfileTeamStats = ( username, next_page ) => {
    return ( dispatch ) => {
        LoadProfileTeamsApi( username, next_page )
            .then( response  => {
                dispatch(loadProfileStatsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('profile:postError'), //'Tenemos un problema!',
                        error_message_is_open: true
                    } ) )
                }
            });
    }
}

export const uploadVideoProfile = ( formData, configUpload, loadPost, username ) => {
    return ( dispatch ) => {
        UploadVideoProfileApi( formData, configUpload )
            .then( response => {
                if(loadPost){
                    dispatch(loadAllProfileVideo(username));
                    dispatch(loadProfileMatches(username));
                }
                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const loadAllProfileVideo = (username) => {
    
    return ( dispatch ) => {

        dispatch( loadingProfilesFeaturedVideosAction(true) );

        LoadAllProfileVideoApi(username)
            .then( response => {
                dispatch(addProfilePresentationVideo(response));
                dispatch(addProfileFeaturedVideo(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
            .then( () => {
                dispatch( loadingProfilesFeaturedVideosAction(false) );
            })          
    }
}

export const loadMoreProfileVideo = (next_page) => {
    
    return ( dispatch ) => {

        dispatch( loadingProfilesFeaturedVideosNextAction(true) );

        LoadAllProfileVideoApi(null, next_page)
            .then( response => {
                dispatch(addProfileFeaturedVideo(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })
            .then( () => {
                dispatch( loadingProfilesFeaturedVideosNextAction(false) );
            })          
    }
}

export const deleteProfileVideo = (postId, username) => {
    return ( dispatch ) => {
        deleteProfileVideoApi(postId)
            .then( response => {
                dispatch(loadAllProfileVideo(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const updateProfileVideo = (postId, titleVideo, username) => {
    return ( dispatch ) => {
        updateProfileVideoApi(postId, titleVideo)
            .then( response => {
                dispatch(loadAllProfileVideo(username));
                dispatch(loadProfileMatches(username));
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('post:msgs.editTitleVideo'), 
                    error_message_is_open: true
                } ) );
                dispatch(updateProfileOneVideo(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const loadProfileMatches = (username, next_page, tournaments_filter = null, old_matches = null) => {
    return ( dispatch ) => {
        loadProfileMatchesApi(username, next_page, tournaments_filter, old_matches )
            .then( response => {
                dispatch(loadProfileMatchesAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const loadProfileTournamentMatches = (username) => {
    return ( dispatch ) => {
        loadProfileTournamentMatchesApi( username )
            .then( response => {
                dispatch(loadProfileTournamentMatchesAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const updateProfileFeaturedMatchVideo = (postId, params, username) => {
    return ( dispatch ) => {
        updateProfileFeaturedMatchVideoApi(postId, params)
            .then( response => {
                dispatch(loadAllProfileVideo(username));
                dispatch(loadProfileMatches(username));
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:addVideoClip.editProfileClip'), 
                    error_message_is_open: true
                } ) );
                dispatch(updateProfileOneVideo(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const loadProfileAchievements = (username) => {
    return ( dispatch ) => {
        loadProfileAchievementsApi( username )
            .then( response => {
                dispatch(loadProfileAchievementsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const createProfileAchievement = (username, formData) => {
    return ( dispatch ) => {
        createProfileAchievementApi(username, formData)
            .then( response => {
                dispatch(addProfileAchievementAction(response));                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const updateProfileAchievement = (profile_achievement_id, formData) => {
    return ( dispatch ) => {
        updateProfileAchievementApi(profile_achievement_id, formData)
            .then( response => {
                dispatch(updateProfileAchievementAction(response));
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:profileExperiences.editAchievement'), 
                    error_message_is_open: true
                } ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const deleteProfileAchievement = (profile_achievement_id, username) => {
    return ( dispatch ) => {
        deleteProfileAchievementApi(profile_achievement_id)
            .then( response => {
                dispatch(loadProfileAchievements(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const loadProfileTrajectories = (username) => {
    return async ( dispatch ) => {
        await loadProfileTrajectoriesApi( username )
            .then( response => {
                dispatch(loadProfileTrajectoriesAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const createProfileTrajectory = (username, formData) => {
    return ( dispatch ) => {
        createProfileTrajectoryApi(username, formData)
            .then( response => {
                dispatch(addProfileTrajectoryAction(response));                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const updateProfileTrajectory = (profile_achievement_id, formData) => {
    return ( dispatch ) => {
        updateProfileTrajectoryApi(profile_achievement_id, formData)
            .then( response => {
                dispatch(updateProfileTrajectoryAction(response));
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('profile:profileExperiences.editTrajectory'), 
                    error_message_is_open: true
                } ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const deleteProfileTrajectory = (profile_achievement_id, username) => {
    return ( dispatch ) => {
        deleteProfileTrajectoryApi(profile_achievement_id)
            .then( response => {
                dispatch(loadProfileTrajectories(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const searchProfileWorldCities = ( searched_city ) => {

    return ( dispatch ) => {
        
        SearchWorldCitiesApi( searched_city )
            .then( response => {
                dispatch( searchProfileWorldCitiesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadProfileExternalStats = ( username, next_page) => {
    return ( dispatch ) => {
        LoadProfileExternalStatsApi( username, next_page )
            .then( response => {
                dispatch(loadProfileExternalStatsAction(response));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const addExternalStat = (username, external_stat) => {
    return ( dispatch ) => {
        AddExternalStatApi(username, external_stat)
            .then( response => {
                dispatch( loadProfileExternalStats(username) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const updateExternalStat = (external_stat_id, external_stat, username) => {
    return ( dispatch ) => {
        UpdateExternalStatApi(external_stat_id, external_stat)
            .then( response => {
                dispatch( loadProfileExternalStats(username) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}

export const deleteExternalStat = (external_stat_id, username) => {
    return ( dispatch ) => {
        DeleteExternalStatApi(external_stat_id)
            .then( response => {
                dispatch(loadProfileExternalStats(username));
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('post:msgs.error'), 
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            })            
    }
}
