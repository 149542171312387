
// React
import React from 'react';

// Components
import ButtonComponent from 'shared/components/button/ButtonComponent';

// Styles
import './SecondaryButtonComponent.styles.css';

// Assets


function SecondaryButtonComponent( props ) {
    return (
        <ButtonComponent 
            id={props.id}
            type={props.type}
            onClick={props.onClick} 
            disabled={props.disabled} 
            className={ "secondary-button " + props.className }
        >
            {props.children}
        </ButtonComponent>
    )
}

export default SecondaryButtonComponent;