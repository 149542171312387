
// Match state
const match_state = {     
    match: {
        //id: "cd897309-55f2-40f2-8cc6-d2b990817b3f", //TODO: Define "" when match be loaded in the state
        //id: "41f42be0-467a-4bcf-8b1a-7cf0117a3cb1",
    },    
    match_report: {},
    match_ended: {
        score: {
            local: 0,
            visitor: 0,
            penalty: false,
            local_penalty: 0,
            visitor_penalty: 0
        },
        goals: [],
        players_cards: [],
        match_comments: '',    
        scorers: [],
        changes: [],      
    },
    modals: {
        edit_match_is_open: false,            
    },
    // errors: {
    //     match_scorer:{
    //         local_goal_minute: '',
    //         visitor_goal_minute: '',
    //     }
    // },
    match_referees: {
        official_referee: undefined,
        second_assistant_referee: undefined,
        third_assistant_referee: undefined,
        fourth_official_referee: undefined,
        referee_assigner: undefined,
    }
    
}


export default function MatchReducer( state = match_state, action ) {
    
    switch ( action.type ) {
        
        case "MATCH:SET_MATCH_SCORE": {
            
            let new_score = { ...state['match_ended'], score: {local: action.data.data.local_team_score, visitor: action.data.data.visitant_team_score, penalty: action.data.data.penalty_definition, local_penalty: action.data.data.local_team_penalty_score, visitor_penalty: action.data.data.visitant_team_penalty_score } }
            return { ...state, match_ended: new_score }

        }
        case "MATCH:SET_PLAYERS_GOALS": {

            let player_goals = { ...state['match_ended'], goals: action.data }
            return { ...state, match_ended: player_goals }

        }
        case "MATCH:SET_PLAYERS_CARDS": {

            let player_cards = { ...state['match_ended'], players_cards: action.data.data }
            return { ...state, match_ended: player_cards }

        }
        case "MATCH:SET_MATCH_COMMENTS": {

            let match_comments = { ...state['match_ended'], match_comments: action.data.data.comments }
            let match_state = { ...state['match'], state: action.data.data.state }
            return { ...state, match_ended: match_comments, match: match_state  }

        }
        case "MATCH:CHANGE_EDIT_MATCH_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], edit_match_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        case "MATCH:LOAD_MATCH_REPORT": {
            return { ...state, match_report: action.data}
        }

        case "MATCH:SET_MATCH_SCORERS": {

            let scorers = { ...state['match_ended'], scorers: action.data.data }
            return { ...state, match_ended: scorers }

        }
        case "MATCH:SET_MATCH_CHANGES": {

            let new_changes = { ...state['match_ended'], changes: action.data.data }
            return { ...state, match_ended: new_changes }

        }
        case "MATCH:LOAD_MATCH_DATA":{
            return { ...state, match: action.match_data}
        }

        case "MATCH:LOAD_LOCAL_TEAM_PAGE_MEMBERS":{

            let local_members = { ...state['match'], local_team_members: action.match_data.data.results }
            return { ...state, match: local_members}

        }

        case "MATCH:LOAD_VISITOR_TEAM_PAGE_MEMBERS":{

            let visitor_members = { ...state['match'], visitor_team_members: action.match_data.data.results }
            return { ...state, match: visitor_members}

        }

        case "MATCH:LOAD_LOCAL_TEAM_PAGE_DATA":{

            let local_team = { ...state['match'], local_team_data: action.match_data.data }
            return { ...state, match: local_team}

        }

        case "MATCH:LOAD_VISITOR_TEAM_PAGE_DATA":{

            let visitor_team = { ...state['match'], visitor_team_data: action.match_data.data }
            return { ...state, match: visitor_team}

        }

        case "MATCH:GET_OFFICIAL_REFEREE_MEMBER":{
            let official_referee = { ...state['match_referees'], official_referee: action.official_referee }
            return { ...state, match_referees: official_referee }
        }

        case "MATCH:GET_SECOND_ASSISTANT_REFEREE_MEMBER":{
            let second_assistant_referee = { ...state['match_referees'], second_assistant_referee: action.second_referee }
            return { ...state, match_referees: second_assistant_referee }
        }

        case "MATCH:GET_THIRD_ASSISTANT_REFEREE_MEMBER":{
            let third_assistant_referee = { ...state['match_referees'], third_assistant_referee: action.third_referee }
            return { ...state, match_referees: third_assistant_referee }
        }

        case "MATCH:GET_FOURTH_ASSISTANT_REFEREE_MEMBER":{
            let fourth_official_referee = { ...state['match_referees'], fourth_official_referee: action.fourth_referee }
            return { ...state, match_referees: fourth_official_referee }
        }

        case "MATCH:GET_REFEREE_ASSIGNER_MEMBER":{
            let referee_assigner = { ...state['match_referees'], referee_assigner: action.referee_assigner }
            return { ...state, match_referees: referee_assigner }
        }


        case "MATCH:CLEAN_REFEREES_MEMBERS":{
            let referees = { ...state['match_referees'], official_referee: undefined, second_assistant_referee: undefined, third_assistant_referee: undefined, fourth_official_referee: undefined, referee_assigner: undefined }
            return { ...state, match_referees: referees } 
        }

        case "MATCH:RESET_MATCH_DATA":{
            return { ...state, match: undefined}
        }

        case "MATCH:RESET_LOCAL_TEAM_PAGE_MEMBERS":{

            let local_members = { ...state['match'], local_team_members: [] }
            return { ...state, match: local_members}

        }

        case "MATCH:RESET_VISITOR_TEAM_PAGE_MEMBERS":{

            let visitor_members = { ...state['match'], visitor_team_members: [] }
            return { ...state, match: visitor_members}

        }

        case "MATCH:RESET_LOCAL_TEAM_PAGE_DATA":{

            let local_team = { ...state['match'], local_team_data: undefined }
            return { ...state, match: local_team}

        }

        case "MATCH:RESET_VISITOR_TEAM_PAGE_DATA":{

            let visitor_team = { ...state['match'], visitor_team_data: undefined }
            return { ...state, match: visitor_team}

        }

        // no default
    }

    return state;
}
