// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.technics-form {}
.technics-form__form {
}
.technics-form__form__fields {
    padding: var(--space-x2-and-half) var(--space);
}
.technics-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top:  1px solid var(--new-input-background-color);
}
.technics-form__form__input {}
.margin-input {
    margin-left: calc(var(--space-x3)*3);
    margin-right: calc(var(--space-x3)*3);
}
.technics-form__form__submit {}
.technics-form__form__fields__content{
    text-align: center;
    color: var( --primary-dark-color) !important;
    margin-top: var(--space-x2);
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-technics-primary-team-modal/ProfileTechnicsPrimaryTeamModalComponent.styles.css"],"names":[],"mappings":";AACA,gBAAgB;AAChB;AACA;AACA;IACI,8CAA8C;AAClD;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,wDAAwD;AAC5D;AACA,6BAA6B;AAC7B;IACI,oCAAoC;IACpC,qCAAqC;AACzC;AACA,8BAA8B;AAC9B;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,2BAA2B;AAC/B","sourcesContent":["\n.technics-form {}\n.technics-form__form {\n}\n.technics-form__form__fields {\n    padding: var(--space-x2-and-half) var(--space);\n}\n.technics-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    border-top:  1px solid var(--new-input-background-color);\n}\n.technics-form__form__input {}\n.margin-input {\n    margin-left: calc(var(--space-x3)*3);\n    margin-right: calc(var(--space-x3)*3);\n}\n.technics-form__form__submit {}\n.technics-form__form__fields__content{\n    text-align: center;\n    color: var( --primary-dark-color) !important;\n    margin-top: var(--space-x2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
