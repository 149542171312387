// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
import CurrencyFormat from 'react-currency-format';
import SelectActionBtnComponent from 'shared/components/select-action-btn/SelectActionBtnComponent';
import SelectTournamentTeamComponent from 'shared/components/select-tournament-team/SelectTournamentTeamComponent';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './TournamentEditMatchModal.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';

function TournamentEditMatchModal( props ) {
    //const [match_date, setStateMatchDate] = React.useState(null);
    //const [match_time, setStateMatchTime] = React.useState(null);
    const [matchDateTime, setMatchDateTime] = React.useState('');
    const [field, setStateField] = React.useState('');
    const [refereeFields, setRefereeFields] = React.useState(false); // eslint-disable-line
    const [selectedPerson, setSelectedPerson] = React.useState({
        official_referee: null,
        second_assistant_referee: null,
        third_assistant_referee: null,
        fourth_official_referee: null,
        match_assigner:null,
    });
    const [tournamentDates, setTournamentDates] = React.useState([]);
    const [actionType, setActionType] = React.useState('');
    const [visitor_teams, setVisitorTeams] = React.useState( props.tournament_teams );
    const [localTeam, setLocalTeam] =  React.useState(props.tournament_teams?.filter(team => team.team === props.row.visitant_team)[0]); // eslint-disable-line
    const [visitorTeam, setVisitorTeam] = React.useState(props.tournament_teams?.filter(team => team.team != props.row.visitant_team)); // eslint-disable-line
    const [newField, setNewField] = React.useState(null);
    const [defaultField, setDefaultField] = React.useState(null); // eslint-disable-line

    // handleChangeMatchDate = handleChangeMatchDate.bind(this);
    // handleChangeMatchTime = handleChangeMatchTime.bind(this);
    // handleChangeField = handleChangeField.bind(this);

    const formRef = React.useRef();

    React.useEffect(()=>{
        setSelectedPerson(
            {
                official_referee: props.official_referee ?? null,
                second_assistant_referee: props.second_assistant_referee ?? null,
                third_assistant_referee: props.third_assistant_referee ?? null,
                fourth_official_referee: props.fourth_assistant_referee ?? null,
                match_assigner:props.referee_assigner ?? null,
            }
        );
    },[props])

    React.useEffect( () => {
        let dates_array = [];
        if(props.tournament_dates && props.tournament_dates.length > 0) {
            props.tournament_dates.forEach( (date, index) => {
                let obj = {
                    caption: `${index + 1}`,
                    value: date.value
                }
                dates_array.push(obj);
            });
            setTournamentDates(dates_array);
        }
    }, [props.tournament_dates]);

    React.useEffect( () => {        
        if(props.new_page_field) {
            if(actionType === 'create-tournament-field') {
                let field = [ { field:  props.new_page_field.id } ]
                props.handleTournamentFieldActions('create-tournament-field', field);
                setActionType('');
                setNewField(props.new_page_field.id);
                props.cleanNewPageField();
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.new_page_field]);

    React.useEffect( () => {
        if(props.row?.match_date && props.row?.match_time) {            
            setMatchDateTime(`${props.row?.match_date}T${props.row?.match_time}`);
        }
    }, [props.row?.match_date, props.row?.match_time]);

    React.useEffect( () => {        
        if(props.row.visitant_team) {
            setLocalTeam(props.tournament_teams?.filter(team => team.team === props.row?.visitant_team)[0]);
            setVisitorTeams(props.tournament_teams?.filter(team => team.team != props.row?.visitant_team));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.row.visitant_team]);

    React.useEffect( ()=> {
        if(newField){
            let selectedField = props.tournament_fields?.filter(fild => fild.value === newField);
            setDefaultField(selectedField[0].value);
            setStateField(selectedField[0].value);
            formRef.current?.setFieldValue("field",selectedField[0].value)
            setNewField(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_fields]);

    const formInitialValues = {
        local_team: props.row.local_team,
        visitant_team: props.row.visitant_team,
        match_date_time: (props.row?.match_date && props.row?.match_time)? `${props.row?.match_date}T${props.row?.match_time}` : '',
        field: props.row.match_field?.id,
        persons: '',
        match_city: props.row.match_city,
        official_referee: props.row.official_referee ? props.row.official_referee : null,
        second_assistant_referee: props.row.second_assistant_referee ? props.row.second_assistant_referee : null,
        third_assistant_referee: props.row.third_assistant_referee ? props.row.third_assistant_referee : null,
        fourth_official_referee: props.row.fourth_official_referee ? props.row.fourth_official_referee : null,
        match_assigner: props.row.referee_assigner ? props.row.referee_assigner : null,
        day: props.row.date_id,
    } 

    function handleChangeMatchDateTime(data) {
        setMatchDateTime(data);
    }
    
    /* 
    function handleChangeMatchDate(data) {
       setStateMatchDate(data);
    }
    
    çfunction handleChangeMatchTime(data) {
        setStateMatchTime(data);
    }

    function handleChangeField(data) {
        setStateField(data);
       
    } */
    
    /*function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }
       
        return today;
    }*/

    const handleClose = () => {
        setMatchDateTime('');
        setStateField('');
        setRefereeFields(false);
        setDefaultField(null);
        setSelectedPerson({
            official_referee: null,
            second_assistant_referee: null,
            third_assistant_referee: null,
            fourth_official_referee: null,
            match_assigner:null,
        });
        props.onClose();
    };

    const handleSelectedStaff=(item, inputType)=>{
        setSelectedPerson((oldArray) => ({
            ...oldArray, [inputType]: item
        }));
        formRef.current?.setFieldValue(inputType,null);
    };

    function handleDefaultTeamValue (team_name) {
        if(props.tournament_teams && team_name) {
            return props.tournament_teams?.filter( team => team.team === team_name )[0];
        }        
    }

    function handleDefaultFieldValue () {
        if(props.tournament_fields && props.row.match_field?.id) {
            return props.tournament_fields.find( field => field.value === props.row.match_field?.id );
        }
    }

    function handleDefaultDayValue () {
        if(props.row.date_id) {
            return tournamentDates.find( date => date.value === props.row.date_id );
        }   
    }

    const handleCreateField = (action_type) => {
        props.handleFieldsAdminActions(action_type, {}); 
        setActionType(action_type);
    }

    const clearDateTime = () => {
        setMatchDateTime('');
        formRef.current?.setFieldValue("match_date_time",'')
    }

    const handleField = (value) => {
        setStateField(value);
    }

    /* const clearField = () => {
        setStateField('');
        formRef.current?.setFieldValue("field",'')
    } */

    const filterVisitor = (localTeam)=>{
        if(localTeam){
            // console.log("visitantes sin el local");
            setLocalTeam(props.tournament_teams?.filter(team => team.id === localTeam.id)[0]);
            setVisitorTeams(props.tournament_teams?.filter(team => team.id != localTeam.id));
        }else{
            // console.log("todos los visitantes");
            setLocalTeam(localTeam);
            setVisitorTeams( props.tournament_teams );
        }
        
    }

    const handleVisitorTeam=(team)=>{
        setVisitorTeam(team);
    };

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={ () => handleClose() }
            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelTitle')}
            className="medium"
        >
            <div class="tournament-edit-match scroll-parent">
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => {
                        let match_date_time = values.match_date_time.split('T');
                        let match_city = props.cities?.filter((city) => city.name === props.tournament_city)
                        let edit_match_data = {
                            "local_team": values.local_team.team,
                            "visitant_team": values.visitant_team.team,
                            "match_date": match_date_time[0] !== "" ? match_date_time[0] : null,
                            "match_time": match_date_time[1]? match_date_time[1] !== "" ? match_date_time[1] : null : null,
                            "field": (props.row.field || values.field )? props.row.field : null,
                            "match_city": match_city[0] ? match_city[0]?.id : null,  
                            "official_referee": values.official_referee?.profile ? values.official_referee?.profile : values.official_referee ?  values.official_referee : null,
                            "second_assistant_referee": values.second_assistant_referee?.profile ? values.second_assistant_referee?.profile : values.second_assistant_referee ? values.second_assistant_referee : null,
                            "third_assistant_referee": values.third_assistant_referee?.profile ? values.third_assistant_referee?.profile : values.third_assistant_referee ? values.third_assistant_referee : null,
                            "fourth_official_referee": values.fourth_official_referee?.profile ? values.fourth_official_referee?.profile : values.fourth_official_referee ? values.fourth_official_referee : null,
                            "referee_assigner": values.match_assigner?.profile ? values.match_assigner?.profile : values.match_assigner,
                            "date_match": values.day,
                            "match_field": values.field ? values.field : (props.row.match_field ? props.row.match_field : null),
                        }
                        props.editMatch(props.row.id, edit_match_data);
                        handleClose();                        
                    }}
                    className="scroll-parent"
                    functionDisabled={()=>{}}
                    innerRef={formRef}
                >
                    <div className="tournament-edit-match__container scroll">
                        <div className="tournament-edit-match__first-last-name__container">
                            <SelectTournamentTeamComponent
                                id='local_team'
                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelHome')} 
                                field_name="local_team"
                                optionLabelField="team"
                                optionSelectedField="id"
                                className=""
                                disabled={false}
                                resetError={ () => props.onResetError( { 'local_team': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam') }
                                options={props.tournament_teams}
                                onChange={(team) => filterVisitor(team)}
                                validationFunction={RequiredValidator}  
                                defaultValue={ handleDefaultTeamValue(props.row.local_team)  }
                                value={localTeam}

                            />
                            <SelectTournamentTeamComponent
                                id='visitant_team'
                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelAway')} 
                                field_name="visitant_team"
                                optionLabelField="team"
                                optionSelectedField="id"
                                className=""
                                disabled={false}
                                resetError={ () => props.onResetError( { 'visitant_team': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam') }
                                options={visitor_teams}
                                onChange={(team) => handleVisitorTeam(team)}
                                validationFunction={RequiredValidator} 
                                defaultValue={ handleDefaultTeamValue(props.row.visitant_team)  }
                                value={visitorTeam}
                            />
                        </div>
                        <div className="tournament-edit-match__date-time__container">
                            <div className="tournament-edit-match___form__elements__date__input">
                                <InputFieldComponent
                                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelDate')} 
                                    input_id="match-date_time" 
                                    field_name="match_date_time"
                                    //validationFunction={ DateFormatValidator }
                                    disabled={props.row.state === 'Finalized'}
                                    className="new-match__form__elements__date__input"
                                    input_type="datetime-local"
                                    resetError={ () => {}}
                                    error={ props.errors }
                                    message=""                                    
                                    value = { matchDateTime !== ''  ? matchDateTime : '' } 
                                    onChange = { (val) => handleChangeMatchDateTime(val) }  
                                />
                                {matchDateTime !== '' && props.row.state !== 'Finalized' &&
                                    <div className="tournament-edit-match___form__elements__date__input_selected_date"><div className='baloa-table'>{moment(matchDateTime).format("DD/MM/YYYY HH:mm")}</div><div className="new-match__form__elements__date__input_selected_date__icon" onClick={ ()=> { clearDateTime() }}><span className='hubicon-clear_option'/></div></div>
                                }
                            </div>
                            <div className="tournament-edit-match___form__elements__date__input">
                                <SelectActionBtnComponent
                                    id='field'
                                    label={ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelCourt') }
                                    field_name="field"
                                    className={`edit-match__selector__add ${field !== ''? 'clear' : ''}`}
                                    disabled={false}
                                    resetError={ () => props.onResetError( { 'field': "" } ) }
                                    error={ props.errors }
                                    message=""
                                    placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectField') }
                                    btn_add_label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewField') }
                                    options={ props?.tournament_fields }
                                    defaultValue={ handleDefaultFieldValue() }
                                    onChange={ (field) => { handleField() } }
                                    //validationFunction={RequiredValidator}  
                                    //select_type='text'
                                    select_type='fields'
                                    addItem={ () => handleCreateField('create-tournament-field') } 
                                    optionLabelField={"caption"}
                                    optionSelectedField={"value"}
                                /> 
                                {/* field !== '' &&
                                    <div className="tournament-edit-match__form__elements__input_field"><div className='baloa-table'></div><div className="new-match__form__elements__date__input_selected_date__icon" onClick={ ()=> { clearField() }}><span className='hubicon-clear_option'/></div></div>
                                 */}
                            </div>
                        </div>
                        <div className="tournament-edit-match__city-field__container">
                        <SelectActionBtnComponent
                                id='day'
                                label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelDay') }
                                field_name="day"
                                className="edit-match__selector__add day"
                                disabled={false}
                                resetError={ () => props.onResetError( { 'day': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelSelectDay') }
                                btn_add_label={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelNewDay') }
                                options={ tournamentDates }
                                defaultValue={ handleDefaultDayValue() }
                                onChange={(value) => {}}
                                validationFunction={RequiredValidator}  
                                //select_type='text'
                                select_type='fields'
                                addItem={ () => props.handleOnNewEditTournamentDate() }
                                optionLabelField={"caption"}
                                optionSelectedField={"value"}
                            />
                        </div>
                        { props.referee_payments &&
                            <React.Fragment>
                                <div className='tournament_edit_match_payment_title baloa-table'>
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelReferee')}
                                </div>
                                <div className='tournament_edit_match__payment_settings'>
                                    <div className='tournament_edit_match__payment_settings__container'>
                                        <div className="tournament_edit_match_registration baloa-names">
                                            {props.t('options.tournaments.pages.newTournament.tabs.tabInscription.refereePaymenSettingTitle')}
                                        </div>
                                    </div>
                                        <div className='tournament_edit_match__payment_settings__saved-config'>
                                            <div className='tournament_edit_match__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.localOptionLabel')}</div>
                                                <div className='baloa-username'>{props.tournament_referee_payment_config?.refereeing_value_local_team} {props.currency_payment}</div>
                                            </div>
                                            <div className='tournament_edit_match__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tournamentArbitragePaymentsModal.visitantOptionLabel')}</div>
                                                <div className='baloa-username'>{props.tournament_referee_payment_config?.refereeing_value_visitant_team} {props.currency_payment}</div>
                                            </div>
                                            <div className='tournament_edit_match__payment_settings__saved-config__total-fee'>
                                                <div className='baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.arbitrageTotalAmountLabel')}</div>
                                                <div className='baloa-username'>
                                                    {`${Number(props.tournament_referee_payment_config?.refereeing_value_local_team)+Number(props.tournament_referee_payment_config?.refereeing_value_visitant_team)} 
                                                    ${props.currency_payment}`}</div>
                                            </div>
                                        </div>
                                </div>
                                <div className='tournament_edit_match_payment_subtitle baloa-table'>
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelRefereeSubtitlePayment')}
                                </div>
                            </React.Fragment>
                        }
                        { props.row.official_referee === null && props.row.second_assistant_referee === null && props.row.third_assistant_referee === null && props.row.fourth_official_referee === null &&                            
                            <div className='tournament-edit-match_referee_general_container'>
                                <div className='tournament-edit-match__referee__input-fields__title baloa-normal-medium'>{ props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelReferee') }</div>
                                <div className="tournament-edit-match__referee__container">                            
                                    <div className="tournament-edit-match__referee__container-column">
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOfficialReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="official_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'official_referee') }
                                                    formRef={formRef}
                                                    value={ selectedPerson?.official_referee}
                                                    id="official_referee_autocomplete_edit_match_modal" 
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'}
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.official_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelThirdAssistantReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'third_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="third_assistant_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'third_assistant_referee') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.third_assistant_referee}
                                                    id="third_assistant_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.third_assistant_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.third_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tournament-edit-match__referee__container-column">
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelSecondAssistantReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'second_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="second_assistant_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'second_assistant_referee') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.second_assistant_referee}
                                                    id="second_assistant_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                        <CurrencyFormat
                                                            value={props.tournament_referee_payment_config?.second_assistant_referee_value} 
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={'$ '} 
                                                            suffix={` ${props.currency_payment}`}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className={`baloa-username ${selectedPerson?.second_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'fourth_official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="official_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.fourth_official_referee}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'fourth_official_referee') }
                                                    id="fourth_official_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.fourth_official_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.fourth_official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tournament-edit-match__input-match_assigner">
                                    <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelInputAssigner')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'match_assigner' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="tournament-match_assigner_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'match_assigner') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson.match_assigner}
                                                    id="match_assigner_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.referee_assigner_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.match_assigner !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                    }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        }
                        { (props.row.official_referee !== null || props.row.second_assistant_referee !== null || props.row.third_assistant_referee !== null || props.row.fourth_official_referee !== null) &&
                            
                            <div className='tournament-edit-match_referee_general_container'>
                                <div className="tournament-edit-match__referee__container">                            
                                    <div className="tournament-edit-match__referee__container-column">
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOfficialReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="official_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'official_referee') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.official_referee}
                                                    id="official_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.official_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelThirdAssistantReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'third_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="third_assistant_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'third_assistant_referee') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.third_assistant_referee}
                                                    id="third_assistant_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.third_assistant_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.third_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="tournament-edit-match__referee__container-column">
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelSecondAssistantReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'second_assistant_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="second_assistant_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'second_assistant_referee') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.second_assistant_referee}
                                                    id="second_assistant_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                        <CurrencyFormat
                                                            value={props.tournament_referee_payment_config?.second_assistant_referee_value} 
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={'$ '} 
                                                            suffix={` ${props.currency_payment}`}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className={`baloa-username ${selectedPerson?.second_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'fourth_official_referee' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="official_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    // formRef={formRef}
                                                    value={ selectedPerson?.fourth_official_referee}
                                                    onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'fourth_official_referee') }
                                                    id="fourth_official_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.fourth_official_referee_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.fourth_official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tournament-edit-match__input-match_assigner">
                                    <div className='tournament-edit-match__referee__container-column_label'>
                                            <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelInputAssigner')}</div>
                                            <div className={`tournament_edit_match_form_elements_input_value ${props.referee_payments? 'with-payment':''}`}>
                                                <InputFieldAutocompleteComponent
                                                    name= { 'match_assigner' }
                                                    options={ props.staff }
                                                    noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                                    optionLabelField={ "staff" }
                                                    optionSelectedField={ "id" }
                                                    className="tournament-match_assigner_referee_input"
                                                    label={''}
                                                    placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                                    onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'match_assigner') }
                                                    // formRef={formRef}
                                                    value={ selectedPerson.match_assigner }
                                                    id="match_assigner_referee_autocomplete_edit_match_modal"
                                                    disabled={ props.row.tournament_match_state_payment === 'Done'} 
                                                />
                                                { props.referee_payments &&
                                                    <CurrencyFormat
                                                        value={props.tournament_referee_payment_config?.referee_assigner_value} 
                                                        displayType={'text'} 
                                                        thousandSeparator={','} 
                                                        decimalSeparator={"."} 
                                                        prefix={'$ '} 
                                                        suffix={` ${props.currency_payment}`}
                                                        onValueChange ={()=>{}}
                                                        renderText={value => <div className={`baloa-username ${selectedPerson?.match_assigner !== null ? 'selected' : ''}`}>{value}</div>}
                                                    />
                                                    }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="new-match__form__actions form__actions">                       
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="new-match__form__submit"
                        >
                            <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEditMatch.labelButtonContinue')}</span>
                        </PrimaryButtonComponent>
                    </div>
                    
                </FormComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentEditMatchModal);