// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './InputFieldComponent.styles.css';


class InputFieldComponent extends React.Component {

    render() {

        const field_name = this.props.field_name;

        return (
            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }
                    return (
                        <div className= { "input-field " + ( this.props.className ? this.props.className : '' )  }>
                            { this.props.label && <label className="input-field-label" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <input
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                onClick={ this.props.onClick }
                                onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined }
                                className={
                                    'input-field-input ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                }
                                type = { this.props.input_type }
                                { ...data.field }
                                onKeyDown={ () => { this.props.resetError() } }
                                placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                maxLength={ this.props.maxLength ? this.props.maxLength : undefined }
                                max={this.props.max ? this.props.max : undefined }
                                min={ this.props.min ? this.props.min : undefined }
                                value= { this.props.value }
                                defaultValue= { this.props.defaultValue }
                                autocomplete='off'
                                onKeyPress={(event) => {
                                    if ((this.props.input_type === 'phone') && (!/[0-9]/.test(event.key))) {
                                      event.preventDefault();
                                    }
                                }}
                                onFocus={ this.props.onFocus }
                            />
                            <p className="input-field__tip-message">{ this.props.message }</p>
                            <p className="input-field__message error">{ data.form.errors[ field_name ] }</p>
                        </div>
                    )

                } }
            </Field>

        );
    }
}

// Types
InputFieldComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
}
export default InputFieldComponent;
