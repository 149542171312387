import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/styles";
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';

//i18
import { withTranslation } from 'react-i18next';

import './TournamentFilterItemComponent.styles.css';

function TournamentFilterItemComponent(props) {
    const [formats, setFormats] = useState(props.selectedFilter ?? []);
    const [listUbication, setListUbication] = useState(props.selectedCities ?? []);

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
        props.handleFilterButtonsFormat({ newFormats, typeFilter: props.field_id });
    };

    const handleSearchWorldCities = (city) => {
        props.handleSearchWorldCities(city);
        const selectedCity = props.world_cities?.find((value) => value.name === city);
        if (props.world_cities?.find((value) => value.name === city)) {
            setListUbication((oldArray) => [...oldArray, {
                id: selectedCity.id,
                name: selectedCity.name
            }]);
            props.handleFilterButtonsFormat({
                newFormats: [...listUbication,
                {
                    id: selectedCity.id,
                    name: selectedCity.name
                }],
                typeFilter: props.filterType
            });
        }
    };

    const handleDeleteCity = (id) => {
        setListUbication(listUbication.filter((ubication) => ubication.id !== id));
        props.handleFilterButtonsFormat({
            newFormats: listUbication.filter((ubication) => ubication.id !== id),
            typeFilter: props.filterType
        });
    };

    const handleChecked = (event) => {
        const newFormats = { value: event.target.value, checked: event.target.checked };
        props.handleFilterButtonsFormat({ newFormats, typeFilter: props.field_id });
    }

    return (
        <div className={`tournament_filter ${props.field_id}`}>
            {props.filterType === 'tournament_state' &&
                <div>
                    {props.selectedFilter.map((state, index) =>
                        <div className={'tournament_filter_item_checkbox'} key={index}>
                            <label className={'tournament_filter_item_checkbox_label baloa-table-column'}
                                id={state.value}>
                                {state.content}
                            </label>
                            <input
                                className={'checkbox_tournament_Form_input'}
                                type="checkbox"
                                name={props.field_id}
                                id={props.field_id}
                                value={state.value}
                                defaultChecked={state.checked}
                                onChange={(event) => handleChecked(event)}
                            />
                        </div>
                    )}
                </div>
            }
            {(props.filterType === 'tournament_gender' || props.filterType === 'tournament_category' || props.filterType === 'tournament_type_soccer') &&
                <div className={'tournament_filter_item toggle_button_group'}>
                    <StyledToggleButtonGroup
                        color="primary"
                        value={formats}
                        onChange={handleFormat}
                        name={props.field_id}
                        id={props.field_id}
                    >
                        {props.tournament_options?.map((item, index) => (
                            <ToggleButton key={index} value={item.value}>
                                {item.content}
                            </ToggleButton>
                        ))}
                    </StyledToggleButtonGroup>
                </div>
            }
            {props.filterType === 'tournament_ubication' &&
                <div className={'tournament_filter_item_ubication'}>
                    {listUbication?.map((item, index) => (
                        <div key={index} className='tournament_item_ubication_city baloa-username'>
                            {item.name}
                            <span className='icon-x baloa-names' onClick={() => {
                                handleDeleteCity(item.id);
                            }} />
                        </div>
                    ))}
                    <InputFieldAutocompleteWithSearchComponent
                        options={props.world_cities ?? []}
                        noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelNoOptionFilter')}
                        optionLabelField={"name"}
                        optionSelectedField={"id"}
                        lettercount={"2"}
                        handleSearchWord={(newCityValue) => handleSearchWorldCities(newCityValue)}
                        className="basic-data_filter_content__city__input"
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelOptionFilter')}
                        id={props.field_id}
                        field_name="tournament_main_city"
                        validationFunction={() => { }}
                        resetError={() => { }}
                        error={{}}
                        disabled={false}
                    />
                </div>
            }
        </div>
    )
}

export default withTranslation('league')(TournamentFilterItemComponent)