// Redux
import { configureStore, /* applyMiddleware */ getDefaultMiddleware } from '@reduxjs/toolkit'; //eslint-disable-line
//import thunk from 'redux-thunk';

// Reducers
import reducers from './CombineReducers';

export default function setStore () {
    let store = configureStore(
        //{reducer: reducers}, 
        //applyMiddleware(thunk),
        {
            reducer: reducers,
            middleware: getDefaultMiddleware =>
                getDefaultMiddleware({
                    serializableCheck: false,
                }),
        }
    );
    return store;
}
