// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentFixtureWeekDaysSelectorComponent from '../tournament-fixture-week-days-selector/TournamentFixtureWeekDaysSelectorComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFixtureMatchesAvailableDatesModalComponent.styles.css';

function TournamentFixtureMatchesAvailableDatesModalComponent(props) {
    /* const [daysConditions, setDaysConditions] = React.useState(props.availableTime.length > 0 ? props.availableTime.length : 1);
    const [availableTime, setAvailableTime] = React.useState(props.availableTime);
    const [availableDays, setAvailableDays] = React.useState(props.availableDays); */

    const [daysConditions, setDaysConditions] = React.useState(1);
    const [availableTime, setAvailableTime] = React.useState([]);
    const [availableDays, setAvailableDays] = React.useState([]);

    React.useEffect(() => {
        if(props.availableTime.length > 0 ){
            setDaysConditions(props.availableTime.length);
        }
        setAvailableTime(props.availableTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.availableTime)]);

    React.useEffect(() => {        
        setAvailableDays(props.availableDays);
    }, [props.availableDays]);

    const setTime = ( ev, type, index) => {
        if(availableTime[index]){
            switch(type){
                case 'start':
                    availableTime[index].start = ev.target.value;
                    break;
                case 'end':
                    availableTime[index].end = ev.target.value;
                    break;
                // no default
            }
            props.setAvailableTime(availableTime);
        }else{
            let newTime = {};
            switch(type){
                case 'start':
                    newTime.start = ev.target.value;
                    break;
                case 'end':
                    newTime.end = ev.target.value;
                    break;
                // no default
            }
            availableTime[index]= newTime;
            props.setAvailableTime(availableTime);
        }

    }

    const setDays = ( daysArray, index ) => {
        availableDays[index] = daysArray;
        props.setAvailableDays(availableDays);
    }

    const handleOnSave = () => {
        let tournament_days = [];
        // eslint-disable-next-line array-callback-return
        availableDays?.map((days ,index) =>{ 
            // eslint-disable-next-line array-callback-return
            days.map( weekDay =>{
                let newDay = {};
                if(availableTime[index] && availableTime[index]?.start && availableTime[index]?.end){
                    newDay.day = weekDay;                
                    newDay.start_time = availableTime[index]?.start;
                    newDay.end_time = availableTime[index]?.end;
                }
                if(Object.keys(newDay).length !== 0){
                    tournament_days.push(newDay);
                }
            });
        });
        props.handleOnSaveFixtureAvailableDays(tournament_days);
        /* setDaysConditions(1);
        setAvailableTime([]);
        setAvailableDays([]); */
    }

    const handleClose = () => {
        /* setDaysConditions(1);
        setAvailableTime([]);
        setAvailableDays([]); */
        props.onClose();
    }
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={ handleClose }
            title={props.t('options.tournaments.automaticFixturesModal.availableDatesModal.modalTitle')}
            className="fixture-matches-available-dates"
        >
            <div className='fixture-matches-available-dates__content'>
                <div className='tournament-phase-fixtures-resume__warning'>
                    <span className='hubicon-info' />
                    <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.availableDatesModal.modalMessage')}</div>                        
                </div>
                <div className='fixture-matches-available-dates__dates-content scroll'>
                    {[...Array(Number(daysConditions))].map((e, i) => 
                        <TournamentFixtureWeekDaysSelectorComponent
                            handleOnSelectDays={(daysArray)=>{ setDays(daysArray, i)}}
                            setStartTime={(v)=>{ setTime(v, 'start', i)}}
                            setEndTime={(v)=>{ setTime(v, 'end', i) }}
                            availableDays={availableDays[i]? availableDays[i] : null}
                            availableTime={availableTime[i]? availableTime[i] : null}
                        />
                    )}
                </div>
                <div>
                    <SecondaryButtonComponent
                        type={"button"}
                        className="fixture-matches-available-dates__button-add small"
                        onClick={()=>{ setDaysConditions(daysConditions +1) }}
                    >
                        <span className='hubicon-add_option icon'/>
                        <span>{props.t('options.tournaments.automaticFixturesModal.availableDatesModal.addBtnLbl')}</span>
                    </SecondaryButtonComponent>
                </div>
            </div>
            <div className='fixture-matches-available-dates__buttons'>
                <PrimaryButtonComponent
                    type={"button"}
                    className="small"
                    onClick={()=>{ handleOnSave() }}
                >
                    <span>{props.t('options.tournaments.automaticFixturesModal.availableDatesModal.saveBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default  withTranslation('league')(TournamentFixtureMatchesAvailableDatesModalComponent);