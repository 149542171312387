// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-edit-phase-modal__groups {
    margin: var(--space);
    display: flex;
    justify-content: space-between;
    background-color: var(--new-background-color);
}

.tournament-edit-phase-modal__btn-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--space);
    border-top: 1px solid var(--new-input-background-color);
}

.tournament-edit-phase-modal .tournament-groups__container {
    margin-bottom: 0;    
}
.tournament-edit-phase-modal__groups{
    margin: var(--space);
    border-radius: 8px;
    padding: 12px;
    background: var(--new-background-color);
}

.tournament-edit-phase-modal__groups .tournament-list-teams__container,
.tournament-edit-phase-modal__groups .tournament-edit-set-groups {
    width: 50%;
}

.tournament-edit-phase-modal .tournament-list-teams__container {
    background-color: var(--white-color);
    max-height: 100vh;
}

.tournament-edit-phase-modal .tournament-list-teams__list {
    max-height: 450px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-edit-phase-modal/TournamentEditPhaseModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;IACrB,uDAAuD;AAC3D;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,uCAAuC;AAC3C;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".tournament-edit-phase-modal__groups {\n    margin: var(--space);\n    display: flex;\n    justify-content: space-between;\n    background-color: var(--new-background-color);\n}\n\n.tournament-edit-phase-modal__btn-action {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding: var(--space);\n    border-top: 1px solid var(--new-input-background-color);\n}\n\n.tournament-edit-phase-modal .tournament-groups__container {\n    margin-bottom: 0;    \n}\n.tournament-edit-phase-modal__groups{\n    margin: var(--space);\n    border-radius: 8px;\n    padding: 12px;\n    background: var(--new-background-color);\n}\n\n.tournament-edit-phase-modal__groups .tournament-list-teams__container,\n.tournament-edit-phase-modal__groups .tournament-edit-set-groups {\n    width: 50%;\n}\n\n.tournament-edit-phase-modal .tournament-list-teams__container {\n    background-color: var(--white-color);\n    max-height: 100vh;\n}\n\n.tournament-edit-phase-modal .tournament-list-teams__list {\n    max-height: 450px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
