// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-fields-create__input-fields {
    padding: 30px 40px;
}

.tournament-fields-create__action {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    border-top: 1px solid var(--new-input-background-color);
}

.tournament-fields-create__container .modal2__container {
    width: 580px;
}

.tournament-fields-create__container .input-field-input {
    background-color: var(--white-color);
    border: 1px solid var(--new-gray-background-color);
}

.tournament-fields-create__container .input-field-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: var(--font-regular);
    color: var(--primary-very-dark-color);
}

.tournament-fields-create__action .button {
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-fields-form-modal/TournamentFieldsFormModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uDAAuD;AAC3D;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,kDAAkD;AACtD;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gCAAgC;IAChC,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".tournament-fields-create__input-fields {\n    padding: 30px 40px;\n}\n\n.tournament-fields-create__action {\n    display: flex;\n    justify-content: flex-end;\n    padding: 12px 16px;\n    border-top: 1px solid var(--new-input-background-color);\n}\n\n.tournament-fields-create__container .modal2__container {\n    width: 580px;\n}\n\n.tournament-fields-create__container .input-field-input {\n    background-color: var(--white-color);\n    border: 1px solid var(--new-gray-background-color);\n}\n\n.tournament-fields-create__container .input-field-label {\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: var(--font-regular);\n    color: var(--primary-very-dark-color);\n}\n\n.tournament-fields-create__action .button {\n    padding: 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
