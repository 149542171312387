// i18next 
import i18n from 'i18next';


export default function convertFieldPositionCode( field_position_code ) {
    let field_position_code_readable;

    switch( field_position_code ) {

        case 'dc':
            return i18n.t('profile:apt.aptPosition.centerForward'); //'Delantero Centro';

        case 'por':
            return i18n.t('profile:apt.aptPosition.goalKeeper'); //'Portero';

        case 'li':
            return i18n.t('profile:apt.aptPosition.leftBack'); //'Lateral izquierdo';

        case 'dci':
            return i18n.t('profile:apt.aptPosition.leftCenterBack'); //'Delantero Centro I';
        
        case 'dcd':
            return i18n.t('profile:apt.aptPosition.rightCenterBack'); //'Delantero Centro D';
        
        case 'ld':
            return i18n.t('profile:apt.aptPosition.rightBack'); //'Lateral derecho';

        case 'ci':
            return i18n.t('profile:apt.aptPosition.leftCenter'); //'Centro izquierdo';
        
        case 'mcd':
            return i18n.t('profile:apt.aptPosition.middleCenterDefensive'); //'Medio centro derecho';
        
        case 'cad'://cd
            return i18n.t('profile:apt.aptPosition.rightCenter'); //'Centro a derecho';

        case 'mi':
            return i18n.t('profile:apt.aptPosition.middleLeft'); //'Medio izquierdo';
        
        case 'mc':
            return i18n.t('profile:apt.aptPosition.middleCenter'); //'Medio centro';
        
        case 'md':
            return i18n.t('profile:apt.aptPosition.middleRight'); //'Medio derecho';

        case 'ei':
            return i18n.t('profile:apt.aptPosition.leftMost'); //'Extremo izquierdo';
        
        case 'mco':
            return i18n.t('profile:apt.aptPosition.centerMiddleO'); //'Medio centro o';
        
        case 'ed':
            return i18n.t('profile:apt.aptPosition.rightMost'); //'Extremo derecho';

        default:
            break

    }

    return field_position_code_readable;
}