/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Styles
import './TounamentPhasesConfigSetComponent.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// material components
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import help_icon from 'assets/icons/help-resume-icon.svg';
import drag_icon from 'assets/icons/drag_and_drop.svg';

//i18
import { withTranslation } from 'react-i18next';

function TounamentPhasesConfigSetComponent(props){
    const [state, setState] = React.useState( // eslint-disable-line
        {
            submit_button_ref: null,
        }
    );
    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            color: 'var(--primary-dark-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-quarter)',
            fontFamily: 'var(--secondary-font)',
            boxShadow: '0px 1px 4px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
            position: 'relative',
            bottom: '-12em',
        },
    }));
    const classes = useStyles();

    React.useEffect( () => {
        if(props.phase_type === "Free"){
            setMaxClasifiedByGroup( props.teams_count );
        }        
    }, [ props ] ) 

    React.useEffect(() => { 
        if(props.submitForm){
            state.submit_button_ref.click();
            props.resetButton();
        }
    }, [props.submitForm]);// eslint-disable-line 

    const formInitialValues = {
        number_of_groups: props.statusRememberPhase ? props.selectedPhase?.groups_count : 0,
        //best_third: false,
        //best_third_team: '',
        clasified_by_group: '',
        groups_teams: [],
        phase_name: props.statusRememberPhase ? props.selectedPhase?.phase_name : '',
        match_duration: props.statusRememberPhase ? props.selectedPhase?.match_duration : 0,
        // round_trip_matches: true,
        // groups_set: 'MANUAL',
    }
    const value_phase ="options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1."
    const phase_name_values = [
        {
            value:"labelPhaseOfGroups",
            content: props.t(value_phase + "labelPhaseOfGroups"),
            phase_type: "Groups",
        },
        {
            value:"labelPhaseTriangular",
            content: props.t(value_phase + "labelPhaseTriangular"),
            phase_type: "Groups",
        },
        {
            value:"labelPhaseHomeRouns",
            content: props.t(value_phase + "labelPhaseHomeRouns"),
            phase_type: "Groups",
        },
        {
            value:"labelPhasePentagonal",
            content: props.t(value_phase + "labelPhasePentagonal"),
            phase_type: "Groups",
        },
        {
            value:"labelPhaseHexagonal",
            content: props.t(value_phase + "labelPhaseHexagonal"),
            phase_type: "Groups",
        },
        {
            value:"labelPhaseRoundOf32",
            content: props.t(value_phase + "labelPhaseRoundOf32"),
            phase_type: "Knockout",
        },        
        {
            value:"labelPhaseRoundOf16",
            content: props.t(value_phase + "labelPhaseRoundOf16"),
            phase_type: "Knockout",
        },
        {
            value:"labelPhaseQuarterFinal",
            content: props.t(value_phase + "labelPhaseQuarterFinal"),
            phase_type: "Knockout",
        },
        {
            value:"labelPhaseSemiFinal",
            content: props.t(value_phase + "labelPhaseSemiFinal"),
            phase_type: "Knockout",
        },
        {
            value:"labelPhaseFinal",
            content: props.t(value_phase + "labelPhaseFinal"),
            phase_type: "Knockout",
        },        
        {
            value:"labelPhaseLeague",
            content: props.t(value_phase + "labelPhaseLeague"),
            phase_type: "Free",
        },
    ];

    const phase_teams_number = props.edit_tournament? props.tournament_teams?.length !== 0 ? props.tournament_teams?.length : props.teams_count : props.teams_count ; 
    //const [bestThirdChkInput, setBestThirdChkInput] = React.useState(props.edit_phase ? props.selectedPhase?.best_third : false)
    const [maxClasifiedByGroup, setMaxClasifiedByGroup] = React.useState(null); //eslint-disable-line
    const [maxTeamsBestThird, setMaxTeamsBestThird] = React.useState(0); //eslint-disable-line
    const [countTeams, setCountTeams] = React.useState(0); //eslint-disable-line

    //const [inputValue, setInputValue] = React.useState(undefined);
    const [numberOfGroups, setNumberOfGroups] = React.useState(0); //eslint-disable-line
    const [tieBreakerTopis, setTieBreakerTopis] = React.useState([]);  
    const [valueNumberGroup, setValueNumberGroup] = React.useState(props.statusRememberPhase ? props.selectedPhase?.groups_count : 1);
    const [valueClasifiedGroups, setValueClasifiedGroups] = React.useState(props.statusRememberPhase ? props.selectedPhase?.classified_count : 1);
    const [phasesName, setPhasesName] = React.useState(props.statusRememberPhase ? props.selectedPhase?.phase_name : '');
    const [disabledGroupNumber, setDisabledGroupNumber] = React.useState(props.statusRememberPhase ? props.disabled_group_number_field: false); // eslint-disable-line

    React.useEffect(()=>{
        setTieBreakerTopis(
            orderArray(props.tournament_criteria_tie_breaking, 'priority_order', 1)
        );
    },[props.tournament_criteria_tie_breaking]);

    React.useMemo(()=>{
        if((props.teams_count)/2 < 1 ){
            setCountTeams(Math.round((props.teams_count)/2));
        }else{
        setCountTeams(Math.floor((props.teams_count)/2)) ;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.teams_count])


    const orderArray=(list, param, order, secondParam = '')=>{
        let data = [];
        try {
            if (!secondParam) {
                data = (list || []).sort((a, b) => {
                return a[param] > b[param] ? order : -order;
            });
            } else {
                data = (list || []).sort((a, b) => {
                return a[param][secondParam] > b[param][secondParam]
                ? order
                : -order;
            });
            }
        } catch (err) {
            data = list;
        }
        return data;
    }

    /* const handleSwitchChangeBestThird =(event)=>{ 
        if(event?.target?.checked) {
            setBestThirdChkInput(true)            
        } else {
            setBestThirdChkInput(false)
        }
        document.getElementById("number_of_best_third_teams").value = "";
    }; */

    function handleTeamsClasifiedByGroup(clasified_by_group) {
        props.handle_classified_group(clasified_by_group);
        if(phase_teams_number - (numberOfGroups * clasified_by_group) > numberOfGroups){
            setMaxTeamsBestThird(numberOfGroups);                
        }else{
            setMaxTeamsBestThird(phase_teams_number - (numberOfGroups * clasified_by_group));
        }
    }

    
    function onClickNewGroupPhasesContinue(new_group_phases_data){

        /* let config_groups_info = "";
        if(document.getElementById("config_groups_info")){
            config_groups_info = document.getElementById("config_groups_info").innerText;
        } */
        /* if(phase_teams_number >= 2){
            props.onClickNewGroupPhasesContinue(new_group_phases_data, config_groups_info);
        } */
        props.onClickNewGroupPhasesContinue(new_group_phases_data);
    }
            
    /* function handleGroupNumberOptions(groups_number){

        if(groups_number === 0) props.handle_number_group([{groups_number:0, teams_number: 0},{},{ mixedGroups: false }]);
        if(inputValue != groups_number && groups_number != ''){
            let basic_teams_number =  Math.floor(phase_teams_number/groups_number);
            let module = phase_teams_number%groups_number;

            setNumberOfGroups(groups_number);
            if(module == 0){
                props.handle_number_group([{groups_number:groups_number, teams_number:basic_teams_number},{},{ mixedGroups: false }]);//[#grupos,#equipos]
            }else{
                console.log(module, (groups_number - module), [{groups_number:(groups_number - module),teams_number:basic_teams_number},
                    {groups_number:module, teams_number:(basic_teams_number + 1)},{ mixedGroups: true }])
                props.handle_number_group([{groups_number:(groups_number - module),teams_number:basic_teams_number},
                    {groups_number:module, teams_number:(basic_teams_number + 1)},{ mixedGroups: true }]);
            }
            setMaxClasifiedByGroup(basic_teams_number);
            setInputValue(groups_number)

            //document.getElementById("clasified_by_groups").value = "";
            //document.getElementById("number_of_best_third_teams").value = "";
        }
    }; */

    const reorderList = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0,removed);
        build_priority_order(result);
        return result;
    };

    const build_priority_order = (array_result) =>{
        const newArray = array_result.map((item, index)=>{
            return {id: item.id, priority_order: (index + 1)}
        });
        props.tournament_new_order_criteria_tie_breaking(newArray);
    };

    const handlePhasesName = (item_phase) => {
        setPhasesName(item_phase);
        props.handle_phase_name_selected(phase_name_values.filter((phase)=> phase.value === item_phase)[0]);
    };

    const handleDecrementNumber = (action_type) => {
        if(action_type === 'number_of_groups'){
            if(valueNumberGroup > 0 ){
                setValueNumberGroup(valueNumberGroup - 1);
                //handleGroupNumberOptions(valueNumberGroup - 1);
            }
        }
        if(action_type === 'clasified_by_groups'){
            if(valueClasifiedGroups > 0 ){
                setValueClasifiedGroups(valueClasifiedGroups - 1);
                handleTeamsClasifiedByGroup(valueClasifiedGroups - 1);
            }
        }
    };

    const handleIncrementNumber = (action_type) => {
        if(action_type === 'number_of_groups'){
            setValueNumberGroup(valueNumberGroup + 1);
            //handleGroupNumberOptions(valueNumberGroup + 1);
        }
        if(action_type === 'clasified_by_groups'){
            setValueClasifiedGroups(valueClasifiedGroups + 1);
            handleTeamsClasifiedByGroup(valueClasifiedGroups + 1);
        }
    }

    const onClickEditPhases = (phases_data_edit) =>{
        let dataToEdit = phases_data_edit;
        for(let item in props.selectedPhase){
            if ( phases_data_edit.hasOwnProperty(item)) {
                if(phases_data_edit[item] !== props.selectedPhase[item]){
                    dataToEdit[item]= phases_data_edit[item];
                }else {
                    delete dataToEdit[item];
                }
            }
        }
        props.handleEditPhase({dataToEdit, id_phase: props.selectedPhase});
    };

    return(

        <React.Fragment>
            
            <div className="phases-group">
                <FormComponent
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => {
                        //let groups_count = props.phase_type === "Free" ? 1 : props.phase_type === "Knockout" ? countTeams : valueNumberGroup;     
                        let groups_count = props.phase_type === "Free" ? 1 : valueNumberGroup;                        
                        //let best_third = props.phase_type === "Free" ? false : bestThirdChkInput;                        
                        // let best_third_teams = props.phase_type === "Free" ? 0 : !bestThirdChkInput ? 0 : 0;
                        //let best_third_teams = bestThirdChkInput ? 1 : 0;
                        let classified_count = props.phase_type === "Free" ? valueClasifiedGroups : props.phase_type === "Knockout" ? 1 : valueClasifiedGroups;

                        let new_group_phases_data = {
                            //"groups_teams": groupsTeams,
                            //"best_third": classified_count == 2 ? best_third : false,
                            //"best_third_teams": best_third_teams,
                            "phase_type": props.phase_type,
                            "groups_count": groups_count,
                            "classified_count": classified_count,
                            "phase_name": values.phase_name,
                            "match_duration": values.match_duration,
                            // "is_shuffle": false, Preguntar que es ? 
                            //"automatic_calendar": radioChangeAutomatic,
                            //"roundtrip": radioChangeRoundTrip,
                        }
                        if(props.edit_phase){
                            onClickEditPhases(new_group_phases_data);
                        }else {
                            onClickNewGroupPhasesContinue( new_group_phases_data );
                        }
                        props.handleNextStep();
                        
                    }}
                    className="phases-group__form form"
                > 
                    <div className="tournament-phases-config__title_conf_phase">
                        <div className="baloa-subtitle-1">
                            {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseConfiguration')}
                        </div>
                    </div>
                    <div className="phases-group__form__content">
                        <div className="baloa-names">
                            {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseConfiguration')}.
                        </div>
                        <div className="right-col">
                            {/* Type of phases = Groups */}
                            { props.phase_type === "Groups" && 
                                <div className="phases-group__form__content_form">                                    
                                    <div className="phases-group__form__content_form__grid_content">
                                        {/* Type of phases = Groups - Select phases Name */}
                                        <div className="phases-group__form__form_select">   
                                            <SelectFieldComponent
                                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroupsType')}   
                                                field_name="phase_name"
                                                validationFunction={ RequiredValidator }
                                                disabled={false}
                                                className=""
                                                resetError={ () => {} }
                                                error={ props.errors }
                                                message=""
                                                placeholder=""
                                                onChange={handlePhasesName}
                                                value={phasesName}
                                                options= { phase_name_values.filter(phases_type =>  phases_type.phase_type ==='Groups')}
                                            />
                                        </div>
                                        <div className='phases-group__form__form-match_duration'>
                                            <InputFieldComponent
                                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelMatchDuration')}   
                                                input_id="match_duration" 
                                                field_name="match_duration"
                                                className="basic-data__form__content__match_duration"
                                                input_type="number"
                                                resetError={ () =>{}}
                                                error={ props.errors }
                                                placeholder={''}
                                                disabled={ props.edit_phase && !props.selectedPhase?.editable_phase }
                                                defaultValue={props.statusRememberPhase ? props.selectedPhase?.match_duration : 0}
                                                // onChange={(data) => handleChangeName(data)}
                                            />
                                        </div>
                                        {/* Type of phases = Groups - Number of Groups  - Revisar validadcion "RequiredValidator"*/}
                                        <div className="number_of_groups">
                                            <div className= "number_of_groups_phases-group_increase-decrease">
                                                <div className='number_of_groups_phases-group_increase-decrease-text baloa-names'>
                                                    {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroups')}
                                                </div>
                                                <div className='phases-group__form_increase-decrease__value'>
                                                    <div className='btn' onClick={ () => !props.edit_phase && handleDecrementNumber('number_of_groups')} >-</div>
                                                    <input name="number_of_groups" type='number' value={valueNumberGroup} />
                                                    <div className='btn' onClick={ () =>!props.edit_phase &&  handleIncrementNumber('number_of_groups')} >+</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Type of phases = Groups - Clasified by Group*/}
                                        {/* <div className="clasified_by_group">
                                            <div className= "number_of_groups_phases-group_increase-decrease">
                                                <div className='number_of_groups_phases-group_increase-decrease-text baloa-names'>
                                                    {props.clasified_caption}
                                                </div>
                                                <div className='phases-group__form_increase-decrease__value'>
                                                    <div className='btn' onClick={ () => handleDecrementNumber('clasified_by_groups')} >-</div>    
                                                        <input type='number' min={'1' } required  value={valueClasifiedGroups} />
                                                    <div className='btn' onClick={ () => handleIncrementNumber('clasified_by_groups')} >+</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* Type of phases = Groups - Best third team*/}
                                    {/*  props.phase_type === "Groups" && valueClasifiedGroups === 2 &&
                                        <div className="best_third_team">
                                            <div className="phases-group__form__content_form__grid_content">
                                                <div className="best_third">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                className="phases-group_switch_best-third"
                                                                checked={ bestThirdChkInput}
                                                                onChange={ (event)=>handleSwitchChangeBestThird(event) }
                                                                name="best_third"
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelBestThird')}/>
                                                </div>
                                            </div>
                                        </div> */
                                    }
                                </div>
                            }
                            {/* Type of phases = Free (Liga) */}
                            { props.phase_type === "Free" &&
                                <div className="phases-group__form__content_form">
                                    <div className="phases-group__form__content_form__grid_content">
                                        {/* Type of phases = Free - Select Phase Name */}
                                        <div className="phases-group__form__form_select">
                                            <SelectFieldComponent
                                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroupsType')}   
                                                field_name="phase_name"
                                                validationFunction={ RequiredValidator }
                                                disabled={false}
                                                className=""
                                                resetError={ () => {} }
                                                error={ props.errors }
                                                message=""
                                                placeholder=""
                                                onChange={handlePhasesName}
                                                options= { phase_name_values.filter(phases_type =>  phases_type.phase_type ==='Groups' || phases_type.phase_type ==='Free') }
                                            />
                                        </div>
                                        <div className='phases-group__form__form-match_duration'>
                                            <InputFieldComponent
                                                label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelMatchDuration')}   
                                                input_id="match_duration" 
                                                field_name="match_duration"
                                                className="basic-data__form__content__match_duration"
                                                input_type="number"
                                                resetError={ () =>{}}
                                                error={ props.errors }
                                                placeholder={''}
                                                disabled={ props.edit_phase && !props.selectedPhase?.editable_phase }
                                                defaultValue={props.statusRememberPhase ? props.selectedPhase?.match_duration : 0}
                                                // onChange={(data) => handleChangeName(data)}
                                            />
                                        </div>
                                        <div className="clasified_by_group free">
                                            {/* Type of phases = Free - Clasified by Group*/}
                                            {/* <div className="clasified_by_group">
                                                <div className= "number_of_groups_phases-group_increase-decrease">
                                                    <div className='number_of_groups_phases-group_increase-decrease-text baloa-names'>
                                                        {props.clasified_caption}
                                                    </div>
                                                    <div className='phases-group__form_increase-decrease__value'>
                                                        <div className='btn' onClick={ () => handleDecrementNumber('clasified_by_groups')} >-</div>    
                                                            <input type='number' min={'1'} required  value={valueClasifiedGroups} />
                                                            <div className='btn' onClick={ () => handleIncrementNumber('clasified_by_groups')} >+</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>  
                                    </div>
                                </div>
                            }
                            {/* Type of phases = Knockout */}
                            {props.phase_type === "Knockout"  &&
                                    <div className="phases-group__form__content_form">
                                        <div className="phases-group__form__content_form__grid_content">
                                            {/* Type of phases = Knockout - Select Phase Name */}
                                            <div className="phases-group__form__form_select">
                                                <SelectFieldComponent
                                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroupsType')}   
                                                    field_name="phase_name"
                                                    validationFunction={ RequiredValidator }
                                                    disabled={false}
                                                    className=""
                                                    resetError={ () => {} }
                                                    error={ props.errors }
                                                    message=""
                                                    placeholder=""
                                                    onChange={handlePhasesName}
                                                    options= { phase_name_values.filter(phases_type => phases_type.phase_type == "Knockout") }
                                                />                                                
                                            </div>
                                            <div className='phases-group__form__form-match_duration'>
                                                <InputFieldComponent
                                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelMatchDuration')}   
                                                    input_id="match_duration" 
                                                    field_name="match_duration"
                                                    className="basic-data__form__content__match_duration"
                                                    input_type="number"
                                                    resetError={ () =>{}}
                                                    error={ props.errors }
                                                    placeholder={''}
                                                    disabled={ props.edit_phase && !props.selectedPhase?.editable_phase }
                                                    defaultValue={props.statusRememberPhase ? props.selectedPhase?.match_duration : 0}
                                                    // onChange={(data) => handleChangeName(data)}
                                                />
                                            </div>
                                            {/* Type of phases = Knockout - Number of key*/}
                                            <div className="number_of_groups">
                                                <div className= "number_of_groups_phases-group_increase-decrease">
                                                    <div className='number_of_groups_phases-group_increase-decrease-text baloa-names'>
                                                        {props.keys_caption}
                                                    </div>
                                                    <div className='phases-group__form_increase-decrease__value'>
                                                        {/* <div className='btn' disabled={true} >-</div>
                                                        <input disabled={true} name="number_of_groups" 
                                                            type='number' min={'1'} 
                                                            max={ `${(phase_teams_number)/2}` }  
                                                            required 
                                                            value={ `${countTeams}`} 
                                                        />
                                                        <div className='btn' disabled={true} >+</div> */}
                                                        <div className='phases-group__form_increase-decrease__value'>
                                                        {! props.edit_phase && <div className='btn' onClick={ () => handleDecrementNumber('number_of_groups')} >-</div>}
                                                            <input name="number_of_groups" type='number' value={valueNumberGroup} />
                                                        {!disabledGroupNumber && 
                                                            <div className='btn' onClick={ () => handleIncrementNumber('number_of_groups')} >+</div>
                                                        }
                                                </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            {/* Type of phases = Knockout - Clasified by key*/}
                                            {/* <div className="clasified_by_group">
                                                <div className= "number_of_groups_phases-group_increase-decrease">
                                                    <div className='number_of_groups_phases-group_increase-decrease-text baloa-names'>
                                                        {props.clasified_caption}
                                                    </div>
                                                    <div className='phases-group__form_increase-decrease__value'>
                                                        <div className='btn' disabled={true} >-</div>    
                                                        <input disabled={true} type='number' min={'1'} required  value = {1} />
                                                        <div className='btn' disabled={true} >+</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                            }
                            <div className='drag_drop_criteria'>
                                <DragDropContext onDragEnd={(result)=> { 
                                    const { source, destination } = result;
                                    if(!destination) return;
                                    if(source.index === destination.index && source.droppableId === destination.droppableId) return;
                                    setTieBreakerTopis(tieBreakerTopis =>  reorderList(tieBreakerTopis, source.index,destination.index ));
                                }}>
                                    <div className="phases-group__item_dragDrop">
                                        <div className="phases-group__item_dragDrop_title">
                                            <p className="baloa-names" >{props.t('options.tournaments.criteria.tie_breaking_criteria')}</p>
                                            <Tooltip title={props.t('options.tournaments.criteria.tooltip_breaking_criteria')}
                                                arrow
                                                placement="top-end"
                                                classes={{ tooltip: classes.customMessage }}>
                                                <img src={help_icon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div className="phases-group__item_dragDrop_Subtitle baloa-names">
                                            {props.t('options.tournaments.criteria.subtitle_breaking_criteria')}
                                        </div>
                                        <Droppable droppableId='items_tie'>
                                            {(droppableProvided)=>(
                                                <ul 
                                                    {...droppableProvided.droppableProps}
                                                    ref={droppableProvided.innerRef}
                                                    className="phases-group__item__ul"
                                                >
                                                    {tieBreakerTopis.map((element, index)=>(
                                                        <div className='phases-group__item__row'>
                                                            <p className="phases-group__item__row_subtitle baloa-names">{props.t('options.tournaments.criteria.criteria')} {index+1} </p>
                                                            <Draggable key={element.id} draggableId={element.id} index={index}>
                                                            {(draggableProvider)=>(
                                                                <li className="phases-group__item__li" 
                                                                    {...draggableProvider.draggableProps}  
                                                                    ref={draggableProvider.innerRef}
                                                                    {...draggableProvider.dragHandleProps}
                                                                    >
                                                                    <div className="phases-group__item__li__container">
                                                                        <div className="phases-group__item__li_text">
                                                                            <img src={drag_icon} alt="" />
                                                                            <p className="baloa-names">{props.t(`options.tournaments.criteria.${element.caption}`)}</p>
                                                                        </div>
                                                                        <div className={'phases-group__item__li_text_icon'}>
                                                                            <Tooltip title={props.t(`options.tournaments.criteria.${element.caption}_desc`)}
                                                                                arrow
                                                                                placement="left-end"
                                                                                classes={{ tooltip: classes.customMessage }}>
                                                                                <img src={help_icon} alt="" />
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                            </Draggable>
                                                        </div>
                                                    ))}
                                                {droppableProvided.placeholder}
                                                </ul>
                                            )} 
                                        </Droppable>
                                    </div>
                                </DragDropContext>
                            </div>
                            <div className="button-content">
                                {/* <PrimaryButtonComponent 
                                    type="submit"
                                    disabled={ false } 
                                    onClick={()=>{  }}                                                         
                                    className="basic-data__form__submit"                        
                                >
                                    <span>
                                        {props.edit_phase 
                                        ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditSaveBasicDataBtn')
                                        : props.t('buttons.continueBtn')}
                                    </span>
                                </PrimaryButtonComponent> */}
                                <input 
                                    style={{ display: 'none' }}
                                    type="submit"
                                    disabled={!props.phase_type}
                                    ref={button_submit => state.submit_button_ref = button_submit}
                                    value={"enviar"}
                                    //onClick={()=>{props.resetButton()}}
                                />
                            </div>

                        </div>
                    </div>
                </FormComponent>
            </div>
        </React.Fragment>
    )
}

export default withTranslation('league')(TounamentPhasesConfigSetComponent)