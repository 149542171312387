// React
import React from 'react';

// Components

//Material
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFixtureWeekDaysSelectorComponent.styles.css';
import { styled } from "@mui/styles";

function TournamentFixtureWeekDaysSelectorComponent(props) {

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: "1px solid var(--new-gray-background-color)",
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: "9px",
            },
            '&:first-of-type': {
                borderRadius: "9px",
            },
        },
    }));

    const DAYS_OF_WEEK = [
        { value: 'Monday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Monday') },
        { value: 'Tuesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Tuesday') },
        { value: 'Wednesday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Wednesday') },
        { value: 'Thursday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Thursday') },
        { value: 'Friday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Friday') },
        { value: 'Saturday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Saturday') },
        { value: 'Sunday', content: props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.Sunday') },

    ];

    const [availableDays, setAvailableDays] = React.useState([]);

    const handleDay = (event, newDays) => {
        setAvailableDays(newDays);
        props.handleOnSelectDays(newDays);
    };

    React.useEffect(() => {
        if(props.availableDays?.length > 0 ){
            setAvailableDays(props.availableDays);
        }
    }, [props.availableDays]);

    return (
        <div className='fixture-week-days-selector__content'>
            <StyledToggleButtonGroup
                color="primary"
                value={availableDays}
                onChange={handleDay}

            >
                {DAYS_OF_WEEK.map((day, index) => (
                    <ToggleButton key={index} value={day.value} className='fixture-week-days-selector__button'>
                        {day.content}
                    </ToggleButton>
                    ))}
            </StyledToggleButtonGroup>
            <div className='fixture-week-days-selector__time'>                
                <div className='fixture-week-days-selector__time-group'>
                    <div className='fixture-week-days-selector__time-label baloa-username'>{props.t('options.tournaments.automaticFixturesModal.availableDatesModal.startHourLbl')}</div>
                    <input 
                        type='time'
                        onChange={(v)=>props.setStartTime(v)}
                        className='fixture-week-days-selector__time-input baloa-username'
                        value={props.availableTime?.start}
                    />
                </div>
                <div className='fixture-week-days-selector__time-group'>
                    <div className='fixture-week-days-selector__time-label baloa-username'>{props.t('options.tournaments.automaticFixturesModal.availableDatesModal.endHourLbl')}</div>
                    <input 
                        type='time'
                        onChange={(v)=>props.setEndTime(v)}
                        className='fixture-week-days-selector__time-input baloa-username'
                        value={props.availableTime?.end}
                    />
                </div>
            </div>
        </div>
    )
}

export default  withTranslation('league')(TournamentFixtureWeekDaysSelectorComponent);