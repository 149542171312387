//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentStartInfoModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentStartInfoModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            //onClose={props.onClose}  
            className={"tournament-start-info-modal"}     
        >
            <div className="tournament-start-info-modal__title">                
                {props.modalTitle}
            </div>
            <div className="tournament-start-info-modal__text baloa-normal" dangerouslySetInnerHTML={{
                __html: props.modalText
                }}>
            </div>                        
            
            <div className="tournament-start-info-modal__actions"> 
                <SecondaryButtonComponent
                    onClick={ props.onOptionButtonAction }
                    className={"small"}
                >
                    <span>{props.optiontButtonText}</span>
                </SecondaryButtonComponent>

                <PrimaryButtonComponent 
                    onClick={ props.onClose }
                    className={"small"}
                >
                    <span>{props.acceptButtonText}</span>
                </PrimaryButtonComponent>
                
            </div>
        </ModalComponent>
    )
}

export default withTranslation('tournament')(TournamentStartInfoModalComponent);