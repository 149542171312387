// React
import React from 'react';

// Styles
import './HubTournamentMatchesDatesComponent.styles.css';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HubTournamentMatchesDatesComponent( props ) {
    
    const loadDefaultDate = () => {
        var index = props.tournaments_dates.map(function(e) { return e.date_to_show; }).indexOf(true);
        return index;
    }
    const [dateIndex, setDateIndex ] = React.useState(loadDefaultDate());
    const scrl = React.useRef();
    const setSelectedDate = (index) => {
        setDateIndex(index);
        props.loadDateMatches(props.tournaments_dates[index].calendar_date);
    }

    const startPosition = () => {
        //var startp = props.tournaments_dates?.length * 93 / 2;
        var startp = 0;
        if(isMobile){
            startp = (dateIndex * (50 + 8)) - (3 * (50 + 8));
        }else{
            startp = (dateIndex * (85 + 8)) - (4 * (85 + 8));
        }
        return startp;
    }

    const [scrollX, setscrollX] = React.useState( startPosition() ); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = React.useState(false); // For detecting end of scrolling
    
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    React.useEffect(() => {
        //Check width of the scollings
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    React.useEffect(() => {
        //coloca el scroll donde esta la fecha actual en la primera carga
        if(scrl.current.scrollLeft === 0 && scrollX === startPosition()){
            scrl.current.scrollLeft += scrollX;
        }

    }, [scrl?.current?.scrollLeft]); //eslint-disable-line

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
        //For checking if the scroll has ended
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    
    return (
        <div className='hub-tournament-matches-dates__container'>
            {!isMobile && scrollX !== 0 &&                
                <div class={`hub-tournament-matches-dates__arrow prev`} onClick={() => slide(-200)}>
                    <span class="hubicon-arrow_circle_left">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>
            }
            <div className='hub-tournament-matches-dates__dates-container' ref={ scrl } onScroll={ scrollCheck }>
                {props.tournaments_dates.map((date, index) => (
                    <div key={index} className={`hub-tournament-matches-dates__date baloa-table ${(index === dateIndex)? 'selected' : '' }`} onClick={()=>{ setSelectedDate(index) }}>
                        {`${moment(date.calendar_date).format('DD MMM')}`}
                    </div>
                    ))
                }
            </div>
            {!isMobile && !scrolEnd &&                    
                <div class={`hub-tournament-matches-dates__arrow next`} onClick={() => slide(+200)}>
                    <span class="hubicon-arrow_circle_right">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>                    
            } 
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentMatchesDatesComponent);