// React
import React from 'react';

// Formik
import { Field } from 'formik';

// Components
import TextField from '@mui/material/TextField';

// Styles
import './InputMinuteComponent.styles.css';
import { withStyles } from '@mui/styles';

function InputMinuteComponent( props ) {

    const field_name = props.name;
    const index = props.index;

    /* eslint-disable */
    React.useEffect(() => {

        document.getElementById(props.id)?.focus();

    }, [ props.value ]);
    /* eslint-enable */

    const MinuteTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--basic-gray-color)',
            },          
            '& .MuiOutlinedInput-root': {
                '& fieldset': {             
                    border: 'var(--new-input-background-color)',
                },      
            },
        },
    })(TextField);
    
    return (
        <Field name={ field_name } validate={ props.validationFunction } >
            { ( data ) => {
                let error;
                let number;
                
                if(props.element === 'local' && data?.form?.errors?.local_goal_minute?.length >= index){
                    error = data?.form?.errors.local_goal_minute[index];
                }else if(props.element === 'visitor' && data?.form?.errors?.visitor_goal_minute?.length >= index){
                    error = data?.form?.errors.visitor_goal_minute[index];
                }

                if(props.element === 'local' && data?.form?.values?.local_goal_minute?.length >= index){  
                    if(data?.form?.values?.local_goal_minute[index] !== ''){
                        number = /^([0-9])*$/.test(data?.form?.values?.local_goal_minute[index]);
                    }
 
                }else if(props.element === 'visitor' && data?.form?.values?.visitor_goal_minute?.length >= index){
                    if(data?.form?.values?.visitor_goal_minute[index] !== ''){
                        number = /^([0-9])*$/.test(data?.form?.values?.visitor_goal_minute[index]);
                    }      
                }
                
                return(
                    <div className= { "minute-input " + ( props.className ? props.className : '' )  }>
                        <MinuteTextField                 
                            className={ 
                                'form-minute-input ' + ( error !== undefined && number !== true ? 'error' : '' )
                            }
                            id={props.id }
                            name={props.name}
                            label={props.label}
                            variant="outlined"
                            margin="normal"                                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={ props.onChange }
                            value = { props.value? props.value: undefined }
                        />
                        <p className={"minute-input__message "+ ( error !== undefined && number !== true ? 'error' : '' )}>
                            { error !== undefined && number !== true ? error : ""}
                        </p>
                    </div>
                )
                
            } }
        </Field>
    )
}

export default (InputMinuteComponent);