// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.checkbox-field {
    margin: var(--space-and-half) 0;
    display: flex;
    justify-content: left;
}

.checkbox-field span.MuiButtonBase-root.MuiIconButton-root {
    padding: 0 !important;
    align-self: flex-start !important;
}

.checkbox-field__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: var(--space-half);
}

.checkbox-field__message {
    font-family: var(--secondary-font);
    font-size: var(--font-normal);
    line-height: var(--space-and-half);
    font-weight: var(--font-regular);
    color: var(--primary-very-dark-color);
}

.checkbox-field__sub-message {
    margin-top: var(--space-half);
    font-family: var(--secondary-font);
    font-size: var(--font-small);
    line-height: var(--space);
    font-weight: var(--font-regular);
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/check-field/CheckFieldComponent.styles.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;IAC/B,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,kCAAkC;IAClC,6BAA6B;IAC7B,kCAAkC;IAClC,gCAAgC;IAChC,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;IAC7B,kCAAkC;IAClC,4BAA4B;IAC5B,yBAAyB;IACzB,gCAAgC;IAChC,2BAA2B;AAC/B","sourcesContent":["\n.checkbox-field {\n    margin: var(--space-and-half) 0;\n    display: flex;\n    justify-content: left;\n}\n\n.checkbox-field span.MuiButtonBase-root.MuiIconButton-root {\n    padding: 0 !important;\n    align-self: flex-start !important;\n}\n\n.checkbox-field__content {\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n    margin-left: var(--space-half);\n}\n\n.checkbox-field__message {\n    font-family: var(--secondary-font);\n    font-size: var(--font-normal);\n    line-height: var(--space-and-half);\n    font-weight: var(--font-regular);\n    color: var(--primary-very-dark-color);\n}\n\n.checkbox-field__sub-message {\n    margin-top: var(--space-half);\n    font-family: var(--secondary-font);\n    font-size: var(--font-small);\n    line-height: var(--space);\n    font-weight: var(--font-regular);\n    color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
