/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './UnlockStatsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function UnlockStatsModalComponent ( props ) {
    return(
        <ModalComponent
            isOpen={props.isOpen} 
            title={'title'}
            className='unlock-stats__container'
        >
            <div className='unlock-stats__header'>
                <span className="unlock-stats__header__close_icon icon-x " onClick={() => {props.onClose()}}></span>
            </div>
            <span className='hubicon-info' />
            <div className="baloa-names"
                dangerouslySetInnerHTML={{
                    __html: props.t('viewTournament.inscription.unlockedStatsModal.modalText')
                }}></div>
            <PrimaryButtonComponent 
                onClick={() => {props.onButtonClick()}}
                className={'small'}
            >
                <span>{props.t('viewTournament.inscription.unlockedStatsModal.modalBtn')}</span>
            </PrimaryButtonComponent>
        </ModalComponent>
    )
}

export default withTranslation('tournament')(UnlockStatsModalComponent);