// React
import React from 'react';

// Styles
import './ProfileStepsDatasheetComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function ProfileStepsDatasheetComponent ( props ) {

    const { step } = props;

    return(
        <div className="profile-steps-datasheet__box">
            <div className="profile-steps-datasheet__box__title">
                
                <div className={ "profile-steps-datasheet__box__title__text complete-text margin-text" } >{props.t('playerCard.title3')}</div>
                <div className={ (step === 1 || step === 2 || step === 3 || step === 4) ? "profile-steps-datasheet__box__title__text complete-text" : "profile-steps-datasheet__box__title__text"}>{props.t('playerCard.position')}</div>
                <div className={ (step === 2 || step === 3 || step === 4) ? "profile-steps-datasheet__box__title__text complete-text" : "profile-steps-datasheet__box__title__text"} >{props.t('playerCard.altenativePosition')}</div>
                <div className={ (step === 3 || step === 4) ? "profile-steps-datasheet__box__title__text complete-text" : "profile-steps-datasheet__box__title__text"}>{props.t('playerCard.title2')}</div>
                <div className={ step === 4 ? "profile-steps-datasheet__box__title__text complete-text" : "profile-steps-datasheet__box__title__text" }>{props.t('playerCard.foot')}</div>
               
            </div>
            <div className="profile-steps-datasheet__box__steps">
                
                <div className={ "profile-steps-datasheet__box__steps__point complete-point"} onClick={ ()=>{console.log("step-point: " + step)} }></div>
                <div className={ (step === 1 || step === 2 || step === 3 || step === 4) ? "profile-steps-datasheet__box__steps__line complete-text":"profile-steps-datasheet__box__steps__line"}></div>
                <div className={ (step === 0 ) ? "profile-steps-datasheet__box__steps__point":"profile-steps-datasheet__box__steps__point complete-point" }></div>
                <div className={ (step === 2 ||step === 3 || step === 4) ? "profile-steps-datasheet__box__steps__line complete-text":"profile-steps-datasheet__box__steps__line"}></div>
                <div className={ (step === 0 || step === 1 ) ? "profile-steps-datasheet__box__steps__point":"profile-steps-datasheet__box__steps__point complete-point"}></div>
                <div className={ (step === 3 || step === 4) ? "profile-steps-datasheet__box__steps__line complete-text":"profile-steps-datasheet__box__steps__line"}></div>
                <div className={ ( step === 3 || step === 4 )  ? "profile-steps-datasheet__box__steps__point complete-point":"profile-steps-datasheet__box__steps__point"}></div>
                <div className={step === 4 ? "profile-steps-datasheet__box__steps__line complete-text":"profile-steps-datasheet__box__steps__line"}></div>
                <div className={step === 4 ? "profile-steps-datasheet__box__steps__point complete-point":"profile-steps-datasheet__box__steps__point"}></div>
                
            </div>  
              
        </div>
    )
}

export default withTranslation('profile')(ProfileStepsDatasheetComponent);