// React
import React from 'react'

// Styles
import './PageBasicInfoComponent.style.css'

// Assets
import plus_icon from 'assets/icons/plus-icon.svg';
import location_icon from 'assets/icons/location-icon.svg';
//import web_icon from 'assets/icons/web-icon.svg';

// Components
import Card1Component from 'shared/components/card-1/Card1Component';

// React device detect
import { isMobile } from 'react-device-detect';

// i18next
import { withTranslation } from 'react-i18next';


function PageBasicInfoComponent( props ) {
    return (

        <Card1Component 
            className={ "page-basic-info " + ( props.className ? props.className : '' ) } 
            title={props.t('profile.title')} 
            button={ props.is_owner && !isMobile && ( 
                <img className="page-basic-info__new-button" 
                    src={ plus_icon }
                    alt=""
                />
            ) }
            onButtonClick={ props.onNewButton }            
        >

            <div className="page-basic-info__content">
                <div className="page-basic-info__content__section">
                    <div className="page-basic-info__content__country">
                        <div className="page-basic-info__content__content">
                            { props.page?.nationality?.flag_icon && <img src={ props.page?.nationality?.flag_icon } alt=""/> }
                        </div>
                        <p className="page-basic-info__content__label baloa-caption">{
                            props.page?.nationality?.name ? props.page?.nationality?.name : props.t('settings.info.country')
                        }</p>
                    </div>
                    <div className="page-basic-info__content__city">
                        <div className="page-basic-info__content__content">
                            { props.page?.location?.name && <img src={ location_icon } alt=""/> }
                        </div>
                        <p className="page-basic-info__content__label baloa-caption">{
                            props.page?.location?.name ? props.page?.location?.name : props.t('settings.info.location')
                        }</p>
                    </div>
                </div>
                {/* <div className="page-basic-info__content__section">
                    <div className="page-basic-info__content__website">
                        <div className="page-basic-info__content__website__icon">
                            { !props.page?.website && <img src={ web_icon } alt=""/> }
                        </div>
                        <p className="page-basic-info__content__website__caption baloa-body-1">{
                            props.page?.website ? props.page?.website : props.t('settings.info.web')
                        }</p>
                    </div>
                </div> */}
            </div>

        </Card1Component>
        
    )

}

export default withTranslation('page')(PageBasicInfoComponent)

//export default PageBasicInfoComponent;
