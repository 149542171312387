import React, { useMemo, useState } from 'react';
//Component
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import './LeagueMatchTeamAssistance.styles.css'
//i18
import { withTranslation } from 'react-i18next';

const LeagueMatchTeamAssistance = (props) => {
    const [numberAssistance, setNumberAssistance] = useState( 0);
    const [numberStarters, setNumberStarters] = useState(0);
    const [numberSubstitute, setNumberSubstitute] = useState(0);

    useMemo(()=>{
        setNumberAssistance(props.assistanceListTeam?.length);
        setNumberStarters(props.assistanceListTeam?.filter(member => member.playerStateInMatch === 'Titular').length)
        setNumberSubstitute(props.assistanceListTeam?.filter(member => member.playerStateInMatch === 'Substitute').length)
    },[props.assistanceListTeam])

    const activeRadioButton = (memberId) => {
        return props.assistanceListTeam?.find(player => player.playerId === memberId)?.playerStateInMatch;
    }

    const activeActionsMember = (memberId) => {
        return !props.assistanceListTeam?.find(player => player.playerId === memberId)?.defaultChecked;
    }

    const lowerCase=(word)=> {return word[0]?.toLowerCase() + word.slice(1)}

    const validationValueCheckedAllPlayers = (teamSide) =>{
        return props.checkedAllMembers[`${teamSide}`]
    }

    return (
        <div className={`league_match_team_assistance_section-team ${props.fullScreen ? 'fullScreen': ''}`}>
            <div className="league_match_team_assistance_section-team-information">
                <div className="league_match_team_assistance_team-ball-photo">
                    {props.team?.team_logo
                        ? <img className="league_match_team_assistance_section-team_photo" src={props.team?.team_logo} alt={'photo_team'} />
                        : <span className="icon-ball" color={getRandonIconBallColor()} />}
                </div>
                <div className="league_match_team_assistance_section-team_names">
                    <div className="baloa-table">{props.team?.name}</div>
                    <div className="baloa-mini">@{props.team?.teamname}</div>
                </div>
            </div>
            <div className="league_match_team_assistance_section-team-options">
                <div className="league_match_team_assistance_section-title-optionsInf">
                    <div className="baloa-mini">
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelAssistance')} {numberAssistance}
                    </div>
                    <div className="league_match_team_assistance_section-title-option-rigth">
                        <div className="baloa-mini">
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTitulares')} {numberStarters}
                            </div>
                        <div className="baloa-mini">
                            {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelSustitutes')} {numberSubstitute}
                        </div>
                    </div>
                </div>
                <div className={'league_match_team_assistance_checkbox-form'}>
                    <input
                        className={'league_match_team_assistance_checkbox-form_input'}
                        type="checkbox"
                        name='allAssistance'
                        id='allAssistance'
                        value='allAssistance'
                        disabled={false}
                        checked={validationValueCheckedAllPlayers(props.teamSide)}
                        onChange={(event) => props.handleCheckedAllAssistance(event, props.teamSide)}
                    />
                    <label className={'league_match_team_assistance_checkbox-form_label baloa-username'} >
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelSelectedAssistance')}
                    </label>
                </div>
            </div>
            <div className="league__match_assistance_section-player-team">
                {props.teamMembers?.map((member, index) => (
                    <div key={member.player_id} className='league_match_team_assistance_section-player'>
                        <div className="league_match_team_assistance_section-player-information">
                            <div className="league_match_team_assistance_section-player-photo">
                                <div className={'league_match_team_assistance_checkbox-form'}>
                                    <input
                                        className={'league_match_team_assistance_checkbox-form_input'}
                                        type="checkbox"
                                        name='playerAssitance'
                                        id='playerAssitance'
                                        value=''
                                        checked={props.assistanceListTeam?.find(player => player.playerId === member.player_id)?.defaultChecked}
                                        onChange={(event) => props.handleCheckedPlayer(event, member, props.teamSide)}
                                    />
                                </div>
                                <div className={`league_match_team_assistance_section--photo ${activeActionsMember(member.player_id) ? 'disabled' : ''}`}>
                                    {member?.photo
                                        ? <img className="league_match_team_assistance_section-team_photo" src={member?.photo} alt={'photo_team'} />
                                        : <span className="icon-ball" color={getRandonIconBallColor()} />}
                                </div>
                                <div className={`league_match_team_assistance_section-player_names ${activeActionsMember(member.player_id) ? 'disabled' : ''}`}>
                                    <div className="baloa-table">{member.player_name}</div>
                                    <div className="baloa-mini">@{member.username}</div>
                                </div>
                            </div>
                            <div className={`league_match_team_assistance_section-player-rol ${activeActionsMember(member.player_id) ? 'disabled' : ''}`}>
                                <InputFieldComponent
                                    key={`playerNumber_${member.player_id}`}
                                    label={''}
                                    input_id={`playerNumber_${member.player_id}`}
                                    field_name={`playerNumber_${member.player_id}`}
                                    validationFunction={() => { }}
                                    className="league_match_team_assistance_form-player-number"
                                    input_type="text"
                                    resetError={() => { }}
                                    error={{ [`playerNumber_${member.player_id}`]: '' }}
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTshirtNUmber')}
                                    disabled={activeActionsMember(member.player_id)}
                                    defaultValue={member.number}
                                />
                                <SelectFieldComponent
                                    key={`playerPositions_${member.player_id}`}
                                    label={''}
                                    field_name={`playerPositions_${member.player_id}`}
                                    validationFunction={() => { }}
                                    disabled={activeActionsMember(member.player_id)}
                                    resetError={() => { }}
                                    error={{ [`playerPositions_${member.player_id}`]: '' }}
                                    defaultValue={lowerCase(member.player_position ?? '')}
                                    message=""
                                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelPosition')}
                                    options={props.positions_members}
                                />
                            </div>
                        </div>
                        <div className={`league_match_team_assistance_section-player-actions ${activeActionsMember(member.player_id) ? 'disabled' : ''}`}>
                            <FormControl component="fieldset"
                                className={"league_match_team_assistance-player-actions_content__button"}>
                                <RadioGroup row
                                    aria-labelledby="playerStateInMatch"
                                    name={`playerStateInMatch_${member.player_id}`}
                                    value={''}
                                    className="league_match_team_assistance_content__option-button inside">
                                    <FormControlLabel
                                        value="Titular"
                                        control={<Radio color="secondary" checked={activeRadioButton(member.player_id) === 'Titular'} />}
                                        label={<span className="baloa-username">
                                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelTitular')}
                                            </span>}
                                        labelPlacement="end"
                                        className={activeRadioButton(member.player_id) === 'Titular' ? 'selected' : ''}
                                        disabled={activeActionsMember(member.player_id)}
                                        onChange={(event) => props.checkPlayerStateInMatch(event, props.teamSide, member.player_id)}
                                    />
                                    <FormControlLabel
                                        value="Substitute"
                                        control={<Radio color="secondary" checked={activeRadioButton(member.player_id) === 'Substitute'} />}
                                        label={<span className="baloa-username">
                                                {props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabMatchAssistance.labelSustitute')}
                                            </span>}
                                        labelPlacement="end"
                                        className={activeRadioButton(member.player_id) === 'Substitute' ? 'selected' : ''}
                                        disabled={activeActionsMember(member.player_id)}
                                        onChange={(event) => props.checkPlayerStateInMatch(event, props.teamSide, member.player_id)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default withTranslation('league')(LeagueMatchTeamAssistance)