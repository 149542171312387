// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import LeagueTeamMembersComponent from 'app/leagues/components/league-team-members/LeagueTeamMembersComponent';

// React device detect
import { isMobile } from 'react-device-detect'

// Styles
import './PageTeamDetailModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function PageTeamDetailModalComponent ( props ){

    return(
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className="medium page-team-detail-modal"            
        >         
            <div className={`match-detail-modal__container ${isMobile? 'scroll-parent' : ''}`}>
                {/* <div>{"detail modal"}</div> */}
                <LeagueTeamMembersComponent 
                    team= { props.team }
                    tournaments = { props.tournaments }
                    followProfile={(profile) => { props.followProfile(profile) }}
                    unfollowProfile={(profile) => { props.unfollowProfile(profile) }}
                    onClose={props.onClose}
                    is_page_admin={props.is_page_admin}
                    handleOnShareTeamPageInscription={props.handleOnShareTeamPageInscription}
                    handleOnTournamentSelected={ props.handleOnTournamentSelected }
                />
            </div>
        </Modal2Component>
    )

}

export default withTranslation('post')(PageTeamDetailModalComponent);
