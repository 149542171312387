// React
import React from 'react';

// the translator
import { useTranslation } from 'react-i18next';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import { Field, useFormik } from 'formik';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

// Styles
import './SignUpProffesionalSpecialtyPanelComponent.styles.css';
/** NO SE ACTUALIZA AUTOCOMPLETE - NO SE ENCONTRO USO -Pendiente verificar */

function SignUpProffesionalSpecialtyPanelComponent( props ) {

    const { children, value, index  } = props; // eslint-disable-line

    const { t } = useTranslation('profile', { useSuspense: false });
    const translatedSpecialties = props.specialties.map(function(specialty){
      let tspec = {};
      tspec["label"] = t('specialties.'+specialty);
      tspec["value"] = specialty;
      return tspec;
    });
    
    const formikvalues = useFormik({
        initialValues: {
            specialty: props.specialty? translatedSpecialties?.filter( tspecialty => tspecialty.value === props.specialty.value)[0] : null,
        },
    });    
    
    return (
        <React.Fragment>
            <div className="signup-proffesional-specialty-panel__container__title">
                <span className="icon-arrow-left" onClick={  () => props.handleOnCloseSpecialtyPanel()} />
                <h3 className="signup-proffesional-specialty-panel__title">{t('data.modal.text6')}</h3>
                <div></div>
            </div>
            <h2 className="signup-proffesional-specialty-panel__subtitle">{t('role.text')}</h2>
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => {     
                    let new_attribute_data = { 
                        atype: "specialty",
                        value: values.specialty.value, 
                    }
                    props.handleCreateAttributeOnProfileAction( new_attribute_data );
                }}
                className="proffesional-specialties-form__form form"
                autocomplete="off"
            >
                <label className="signup-proffesional-specialty-panel__label">{t('role.title')}</label>
                <Field
                    name="specialty"
                    component={Autocomplete}
                    options={translatedSpecialties}
                    
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className="proffesional-specialties-form__form__input"
                            variant="outlined"
                            margin="normal"                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
                
                <div className="proffesional-specialties-form__form__actions">
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="proffesional-specialties__actions__submit"
                    >
                        <span>{t('btnFinalize')}</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        </React.Fragment>
        )
    }
    
export default SignUpProffesionalSpecialtyPanelComponent;