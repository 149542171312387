// React
import React from 'react';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './HomePagesMenuComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

import HomePagesExploreFilterComponent from 'app/pages/components/home-pages-explore-filter/HomePagesExploreFilterComponent';

function HomePagesUnauthMenuComponent( props ) {

    return(
        <React.Fragment>
            { !isMobile
                ?
                <div className="home-pages-menu">
                    <div className="home-pages-menu__title">
                            <span className="baloa-headline-2">{props.t('pages.pagesTitle')}</span>
                    </div>
                    <div className="home-pages-menu__content scroll">

                        {/* Crear página */}
                        <div 
                            className={ `home-pages-menu__content__field_create` }
                            onClick={() => {
                                props.onClickAddButton();
                            }}
                        >
                            
                            <div className="home-pages-menu__content__field__data-create">
                                <div className="home-pages-menu__content__field__icon-create">
                                    <span className="icon-plus"/>
                                </div>
                                <span className="home-pages-menu__content__field__data__text_create baloa-normal-medium">                                
                                    {props.t('pages.createPage')}
                                </span>
                            </div>
                            {/* <div className="home-pages-menu__content__field__icon-chevron">
                                <span className="icon-chevron-roght"></span>
                            </div> */}
                        </div>
                        
                        {/* Explorar páginas */}
                        <div 
                            className={ `home-pages-menu__content__field ${props.handleContentInfoActive === "explore" ? 'active' : ''}` }
                            onClick={() => {
                                props.handleClickMenuSugestions("explore");
                            }}
                        >
                            
                            <div className="home-pages-menu__content__field__data">
                                <div className="home-pages-menu__content__field__icon">
                                    <span className="hubicon-explore_2"/>
                                </div>
                                <span className="home-pages-menu__content__field__data__text baloa-normal-medium">
                                    {props.t('pages.explorePages')}
                                </span>
                            </div>
                            {/* <div className="home-pages-menu__content__field__icon-chevron">
                                <span className="icon-chevron-roght"></span>
                            </div> */}
                        </div>
                        {props.handleContentInfoActive === "explore" &&
                            <HomePagesExploreFilterComponent
                                page_categories={ props.page_categories }
                                handleOnExplorePagesFilter={props.handleOnExplorePagesFilter}
                                unAuth={true}
                            />
                        }
                        
                    </div>
                    
                </div>
                :(// eslint-disable-next-line
                (props.handleContentInfoActive === "pages") &&
                <div className="home-pages-menu__mobile-menu__container">
                    <div className="baloa-names">
                        {props.t('pages.pagesTitle')}
                        <PrimaryButtonComponent 
                            type={'button'}
                            className='home-pages-menu__mobile-menu__new-button'
                            onClick={ () => { props.onClickAddButton() } }    
                        >
                            <span className='icon-plus icon'/>
                            <span>{ props.t('pages.createPage') }</span>
                        </PrimaryButtonComponent>
                    </div>
                    <div className="home-pages-menu__mobile-menu__actions" >
                        {/* <div 
                            className={`baloa-table-column ${props.handleContentInfoActive === "pages" ? 'active' : ''} `} 
                            onClick={() => { props.handleClickMenuSugestions("pages") }}
                        >
                            {props.t('pages.yourPages')}
                        </div> */}
                        <div 
                            className={`baloa-table-column ${/* props.handleContentInfoActive === "followed" ? 'active' : '' */ 'active'} `} 
                            onClick={() => { props.handleClickMenuSugestions("followed") }}
                        >
                            <div className="home-pages-menu__content__field__icon">
                                    <span className="hubicon-favorite_fill"/>
                                </div>
                            {props.t('pages.pagesFollowed')}
                        </div>
                        <div 
                            className={`baloa-table-column ${/* props.handleContentInfoActive === "explore" ? 'active' : '' */ 'active'} `} 
                            onClick={() => { props.handleClickMenuSugestions("explore") }}
                        >
                            <div className="home-pages-menu__content__field__icon">
                                <span className="hubicon-explore"/>
                            </div>
                            
                            {props.t('pages.explorePages')}
                        </div>                                  
                    </div>
                </div>
                // eslint-disable-next-line
                ||
                // eslint-disable-next-line
                (props.handleContentInfoActive === "explore") &&
                <div className="home-pages-menu__mobile-menu__container">
                    
                        <div className="home-pages explore">
                            <div className="home-pages__content">
                                <div className="home-pages__content__title">
                                        <span className="home-pages__content__title__text baloa-headline-2">{props.title}</span>
                                </div>                                                                         
                                <span className='home-pages__close_icon icon-x' onClick={ ()=>{ props.handleClickMenuSugestions("post") } } />                                                
                            </div>
                        </div>
                    
                    <HomePagesExploreFilterComponent
                        page_categories={ props.page_categories }
                        handleOnExplorePagesFilter={props.handleOnExplorePagesFilter}
                        unAuth={true}
                    />
                </div>
                )
            }
        </React.Fragment>
    )
}

export default withTranslation('home')(HomePagesUnauthMenuComponent);