// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";

export const AddNewTeamApi = ( team_data, configUpload, pagename ) => {

    //return Axios.instance.post( `${ api_url }/teams-page/`, //Cambio de api-eliminacion Baloa-page
    return Axios.instance.post( `${ api_url }/pages/${pagename}/teams/`,
        /*{   
            teamname: team_data.teamname,
            name: team_data.name,
            team_gender: team_data.team_gender,
            play_other_tournaments: team_data.play_other_tournaments,
            logo: team_data.logo
        },*/
        team_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
            onUploadProgress: configUpload,
        }    
    );    
}

export const SetTeamExtraApi = ( team_extra_data, teamname ) => {

    return Axios.instance.post( `${ api_url }/teams-page/${ teamname }/attributes/`,
        { extra: team_extra_data },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const DeletePlayerFromTeamApi = ( teamname, username ) => {

    return Axios.instance.delete( `${ api_url }/teams-page/${ teamname }/members/${ username }/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const GetTeamPlayersApi = ( teamname ) => {

    return Axios.instance.get( `${ api_url }/teams-page/${ teamname }/members/?pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const GetPageTeamsApi = ( pagename ) => {

    return Axios.instance.get( `${ api_url }/pages/${ pagename }/teams/?pagination=False`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const GetTeamApi = ( teamname ) => {

    return Axios.instance.get( `${ api_url }/teams-page/${ teamname }/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

// export const EditTeamDataApi = ( team_data, teamname, configUpload ) => {
//     return Axios.instance.patch( `${ api_url }/teams-page/${ teamname }/edit_team/`,
//         team_data,
//         {
//             headers: {
//                 'Authorization': localStorage.getItem('token'),
//             },
//             onUploadProgress: configUpload,
//         }    
//     );    
// }

export const EditTeamDataApi = ( team_data, teamname, configUpload ) => {
   
    return Axios.instance.patch( `${ api_url }/teams-page/${ teamname }/`,
        team_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
            onUploadProgress: configUpload,
        }    
    );    
}

export const UploadBulkFileApi = ( teamname, formdata, configUpload ) => {

    return Axios.instance.post( `${ api_url }/teams-page/${ teamname }/bulk_load_members/`,
        formdata,
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
            onUploadProgress: configUpload,
        }    
    );    
}

export const GetMemberRolesApi = () => {

    return Axios.instance.get( `${ api_url }/roles/profiles-roles/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const GetMemberPositionsApi = () => {

    return Axios.instance.get( `${ api_url }/roles/profiles-positions/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const AddMemberWithRolToTeamApi = ( teamname, member ) => {

    return Axios.instance.post( `${ api_url }/teams-page/${ teamname }/members/`,
        member,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const AddMemberWithRolToTeamInApiInscription = ( teamname, member ) => {

    return Axios.instance.post( `${ api_url }/teams-page/${ teamname }/inscriptions/`,
        member,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const UpdateTeamMemberApi = ( teamname, member ) => {

    return Axios.instance.patch( `${ api_url }/teams-page/${ teamname }/members/`,
        member,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const SearchTeamByCodeApi = ( teamcode ) => {

    return Axios.instance.get( `${ api_url }/teams-page/?team_code=${ teamcode }`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );    
}

export const UploadBulkDataApi = ( teamname, membersData ) => {

    return Axios.instance.post( `${ api_url }/teams-page/${ teamname }/massive_player_load/`,
        {
            members: membersData
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
            }
        }    
    );    
}