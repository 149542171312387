// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';

//Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormComponent from 'shared/components/form/FormComponent';

//Validators
import { DateFormatValidator } from 'shared/validators/DateValidator';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFixtureMatchesLockedDatesModalComponent.styles.css';

function TournamentFixtureMatchesLockedDatesModalComponent(props) {

    const [type, setType] = React.useState('Single');
    const formRef = React.useRef();

    function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }
        
        return today;
    }

    const handleType = (e) => {
        setType(e.target.value);
        if(e.target.value === 'Single'){
            formRef.current.setFieldValue('end_date','');
        }
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={ props.onClose }
            title={props.t('options.tournaments.automaticFixturesModal.lockedDayModal.modalTitle')}
            className="fixture-matches-locked-dates"
        >
            <div className='fixture-matches-locked-dates__content'>
                <div className='tournament-phase-fixtures-resume__warning'>
                    <span className='hubicon-info' />
                    <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.lockedDayModal.modalMessage')}</div>                        
                </div>
                <FormComponent
                    formInitialValues={{
                        type: "Single",
                        start_date: null,
                        end_date: null
                    }}
                    onSubmit={(values) => { 
                        props.handleOnSaveFixtureLockedDay(values);
                        props.onClose();
                    }}
                    className=""
                    functionDisabled={() => { }}
                    enableReinitialize={false}
                    innerRef={formRef}
                >
                <div className='fixture-matches-locked-dates__dates-content'>
                
                    <RadioGroup row aria-labelledby="type" name="type" defaultValue="Single" className="fixture-matches-locked-dates__types"  >
                        <FormControlLabel
                            value="Single"
                            control={ <Radio color="secondary" checked={ type === "Single"} /> }
                            label={
                                <div className={'baloa-table'}>{props.t('options.tournaments.automaticFixturesModal.lockedDayModal.specificDayOption')}</div>
                            }
                            labelPlacement="end"
                            onChange={ handleType }
                            disabled = {false}
                            className={type === "Single"? 'selected' : '' }
                        />
                        <FormControlLabel
                            value="Range"
                            control={ <Radio color="secondary" checked={type === "Range" } /> }
                            label={
                                <div className={'baloa-table'}>{props.t('options.tournaments.automaticFixturesModal.lockedDayModal.rangeDayOption')}</div>
                            }
                            labelPlacement="end"
                            onChange={ handleType }
                            disabled = { false }
                            className={type === "Range"? 'selected' : '' }
                        />                      
                    </RadioGroup>
                </div>
                <div className='fixture-matches-locked-dates__dates'>
                    <InputFieldComponent
                        label={type === "Range"? props.t('options.tournaments.automaticFixturesModal.lockedDayModal.startDayLbl') :  props.t('options.tournaments.automaticFixturesModal.lockedDayModal.dayLbl')}
                        input_id="start_date" 
                        field_name="start_date"
                        validationFunction={ DateFormatValidator }
                        className="fixture-matches-locked-dates__date"
                        input_type="date"
                        resetError={ () => {} }
                        error={ { start_date: '' }}                            
                        disabled={ false }
                        min={ todayDate() }
                        onChange={ () => {} }
                        //value={ start_date? start_date: null }
                        
                    />
                    {type === "Range" &&
                        <InputFieldComponent
                            label={props.t('options.tournaments.automaticFixturesModal.lockedDayModal.endDayLbl')}
                            input_id="end_date" 
                            field_name="end_date"
                            validationFunction={ DateFormatValidator }
                            className="fixture-matches-locked-dates__date"
                            input_type="date"
                            resetError={ () => {} }
                            error={ { end_date: '' }}                            
                            disabled={ false }
                            min={ todayDate() }
                            onChange={ () => {} }
                            //value={ start_date? start_date: null }
                            
                        />
                    }
                </div>
            </FormComponent>
            </div>
            <div className='fixture-matches-locked-dates__buttons'>
                <PrimaryButtonComponent
                    type={"submit"}
                    className="small"
                    onClick={()=>{ formRef.current?.submitForm(); }}
                >
                    <span>{props.t('options.tournaments.automaticFixturesModal.lockedDayModal.addBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default  withTranslation('league')(TournamentFixtureMatchesLockedDatesModalComponent);