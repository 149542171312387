/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './PlayerColumnComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

export default function TeamColumnComponent(props) {

    return (
        <React.Fragment>
            <div class="player-column">
                <div class="player-column__image">
                    {props.row.photo
                        ?
                        <img src={ props.row.photo } alt="player-img" />
                        :
                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                    }
                </div>
                <div class="player-column__data">                    
                    <div className='baloa-names'>{props.row.name}</div>
                    <div className='baloa-username'>@{props.row.teamname}</div>
                </div>                       
            </div>
        </React.Fragment>
    )
    
}