// i18next 
import i18n from 'i18next';

export const OtpValidator = (value) => {
    let error;
    if (value && !/^[0-9]{5}$/i.test(value)) {
        error = i18n.t('signUp:code.error'); //"Deben ser 5 números";
    }
    return error;
}

export const FirstNameValidator = (value) => {
    let error;
    if (value === "") {
        error = i18n.t('page:newPage.validators.requiredField'); //"Este campo es obligatorio";
    }
    return error;
}

export const LastNameValidator = (value) => {
    let error;
    if (value === "") {
        error = i18n.t('page:newPage.validators.requiredField'); //"Este campo es obligatorio";
    }
    return error;
}

export const DateValidator = (value) => {
    let error;
    if (!value) {
        error = i18n.t('page:newPage.validators.requiredFieldBirthDate'); //"Campo obligatorio, completa tu fecha de nacimiento";
    } /* else {
        var hoy = new Date();
        var cumpleanos = new Date(value);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        if (edad < 13) {
            error = i18n.t('page:newPage.validators.underAge'); //"No tienes la edad mínima para registrarte";
        }
    } */
    return error;
}

export const CheckValidator = ( value ) =>{
    let error;
    if(!value){
        error = i18n.t('page:newPage.validators.termsConditions'); //"Debes leer y aceptar los términos y condiciones"; 
    }
    // TODO: This does not run ever!
    console.log(`Validando checkbox => valor: ${value}, validado: ${error}`);
    return error; 
}

export const AnyValidator = () => {
    let error;
    return error;
}

export const UserNameValidator = (value) => {
    let error;
   
    if (!value || value.trim() === "") {
        error = i18n.t('page:newPage.validators.requiredField'); //"Este campo es obligatorio";
    }
    if (!/^([a-zA-Z]*\.?\d*\ñ*)*$/g.test(value)) { // eslint-disable-line
        error = i18n.t('page:newPage.validators.invalidChars') //"Caracteres no válidos";
    }
    
    return error;
}

export const countryCityFieldsValidator = (value) => {
    //console.log(value)
    let error;
    if ( (value === "") || (value === null) || (value === undefined) ) {
        error = i18n.t('page:newPage.validators.requiredField'); //"Este campo es obligatorio";
    }
    return error;
}
