import React from 'react';

// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';

// Styles
import './InputDynamicFormComponent.styles.css';

// Validators
import { RequiredValidator, 
    DateRequiredValidator, 
    EmailRequiredValidator, 
    EmailValidator, 
    PhoneNumberRequiredValidator, 
    PhoneNumberValidator, 
    CreditCardValidator, 
    CreditCardRequiredValidator,
    monthValidator,
    monthRequiredValidator,
    yearValidator,
    yearRequiredValidator,
    OnlyNumbersValidator,
    OnlyNumbersRequiredValidator,
} from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';

function InputDynamicFormComponent(props) {

    function handleTypeInput(value) {
        if (props.form_question.is_required === true) {
            if (props.form_question.question_type.caption === "text") {
                return RequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "date") {
                return DateRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "email") {
                return EmailRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "phone") {
                return PhoneNumberRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "debit_card_number") {
                return CreditCardRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "month") {
                return monthRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "year") {
                return yearRequiredValidator(value);
            }
            if (props.form_question.question_type.caption === "number") {
                return OnlyNumbersRequiredValidator(value);
            }
        } else {
            if (props.form_question.question_type.caption === "email") {
                return EmailValidator(value);
            }
            if (props.form_question.question_type.caption === "phone") {
                return PhoneNumberValidator(value);
            }
            if (props.form_question.question_type.caption === "debit_card_number") {
                return CreditCardValidator(value);
            }
            if (props.form_question.question_type.caption === "month") {
                return monthValidator(value);
            }
            if (props.form_question.question_type.caption === "year") {
                return yearValidator(value);
            }
            if (props.form_question.question_type.caption === "number") {
                return OnlyNumbersValidator(value);
            }
            return undefined;
        }

    }

    /* function todayDate(){
        let date = new Date();
        let today = '';
        
        if(date.getMonth()+1 < 10 && date.getDate() < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+0+date.getDate();
        }else if(date.getMonth()+1 < 10){
            today = date.getFullYear()+"-"+0+(date.getMonth()+1)+"-"+date.getDate();
        }else if(date.getDate() < 10){
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+0+date.getDate();
        }else{
            today = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
        }        
        return today;
    } */

    const buildInputStyles = (question_type) => {
        const inputClass = {
            date: '_input_date',
            phone: '_input_phone',
            month: '_input_month',
            year: '_input_year'
        }

        return inputClass[question_type] ?? '';
    }

    const setInputType = (input_type) =>{
        let set_input_type = 'text'

        switch(input_type){
            case 'month':
                set_input_type = 'text'
                break;
            case 'year':
                set_input_type = 'text'
                break;
            case 'debit_card_number':
                set_input_type = 'text'
                break;
            case 'number':
                set_input_type = 'text'
                break;
            default: 
                set_input_type = input_type
                break;
        }
        return set_input_type;
    }

    return (
        <div className={`input-dynamic-form__container ${buildInputStyles(props.form_question.question_type.caption)} ${props.form_question.error_code? ' invalid_data':'' }`}>
            <InputFieldComponent
                label={props.form_question.caption}
                input_id={props.input_id}
                field_name={props.field_name}
                validationFunction={(value) => handleTypeInput(value)}
                className={props.className}
                input_type={setInputType(props.form_question.question_type.caption)}
                resetError={props.resetError}
                error={props.errors}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
            //value=''
            //onChange={(value)=> props.handleInputDynamicForm(value) }
            //min={ props.form_question.question_type.caption === 'date' ?? todayDate() }
            />
            {props.form_question.error_code &&
                <p className="input-dynamic-form__error error">{ props.t('profile:settings.userPayment.create_account_errors.'+props.form_question.error_code) }</p>
            }
        </div>
    );
}

export default withTranslation('tournament')(InputDynamicFormComponent)