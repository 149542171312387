import React from "react";

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

import "./FlexiInscriptionModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function FlexiInscriptionModalComponent(props){
    return (
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            title=""            
            className='flexi-inscription-modal__container'
        >
        <div className ={"flexi-inscription-modal__content"}>
            <div className="flexi-inscription-modal__team-image">
                {props.team_shield?
                    <img src={props.team_shield} alt={props.team}/>
                :
                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                }
            </div>
            <div className="baloa-normal">{props.t('viewTournament.inscription.flexiInscriptionModal.invitationText')}</div>
            <div className="flexi-inscription-modal__team-name baloa-subtitle-1">
                {props.team}
            </div>
            <div className="flexi-inscription-modal__tournament-info baloa-normal">
                {props.tournament_logo?
                    <img src={props.tournament_logo} alt={props.team}/>
                :
                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                }&nbsp; {props.t('viewTournament.inscription.flexiInscriptionModal.tournamentText')}&nbsp;<strong>{props.tournament_name}</strong>
            </div>
            <div className="flexi-inscription-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='flexi-inscription-modal__buttons-button'
                    onClick= {props.handleFlexiIncriptionButton}
                >
                    <span>{props.t('viewTournament.inscription.flexiInscriptionModal.buttonText')}</span>
                </PrimaryButtonComponent> 
                <div className="baloa-table-column" onClick={()=>{props.handleOnRejectInscription()}}>{props.t('viewTournament.inscription.flexiInscriptionModal.rejectText')}</div>
            </div>
        </div>
    </ModalComponent>
    )
}

export default withTranslation('tournament')(FlexiInscriptionModalComponent);