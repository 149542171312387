/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Assets
import field_colored_icon from 'assets/icons/field-icon.svg';
import {ReactComponent as AddPlayerPlus} from 'assets/icons/add-player-plus.svg';

// Functions
import getPositionNameByPosition from 'shared/functions/GetPositionNameByPosition';

// Styles
import './FormationFieldComponent.styles.css';

// Components
//import InputSearchComponent from 'shared/components/input-search/InputSearchComponent';
import InputWithButtonComponent from 'shared/components/input-with-button/InputWithButtonComponent';

// i18n
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Positions Field Component:
// Renders positions and handles their actions.


function FormationFieldComponent( props ) {

    const [lineUp, setLineUp] = React.useState(props.lineUp);
    const [positionSelected, setPositionSelected] = React.useState();
    const [positionsTableArray, setPositionsTableArray] = React.useState([]);
    const [state, updateState] = React.useState(); // eslint-disable-line
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [cleanSearch, setCleanSearch] = React.useState(false); // eslint-disable-line
    const [isPlayerPosition, setIsPlayerPosition] = React.useState(false);
    const [isPlayerSelected, setIsPlayerSelected] = React.useState(false);
    
    
    React.useEffect(()=>{
        if(props.clear === "true") {
            setPositionsTableArray([]);
        }
    }, [props.clear]);

    const positionsMap = new Map ([
        ["4-4-2", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "med4", "del1", "del2" ]],
        ["5-3-2", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "del1", "del2" ]],
        ["4-3-3", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "del1", "del2", "del3" ]],
        ["3-4-3", ["por", "def1", "def2", "def3", "med1", "med2", "med3", "med4", "del1", "del2", "del3" ]],
        ["5-4-1", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "med4", "del1" ]]
    ]);

    const handleLineUpChange = event => {
        setLineUp(event.target.value);
        setPositionsTableArray([]);
    };

    const sendLineData = event => {
        props.returnLineData({
            lineUp: event.target.value, 
        });
    }

    const sendFormationData = event => {
        props.returnFormationData(
            {
            lineUp: props.lineUp, 
            final_positions_table: event
            }
        );
     }

    //  function findDataPosition(position) {
    //     for (let index = 0; index < positionsTableArray.length; index++) {
    //         if( positionsTableArray[index][0] === position ){  
    //             return positionsTableArray[index];
    //         }
    //     }
    //  }

     // Finds if position already in array
    function handleDuplicatePosition(pos_selected, selected_username){
        for (let i = 0; i < positionsTableArray.length; i++) {
            if(positionsTableArray[i][2] === selected_username || positionsTableArray[i][0] === pos_selected){
                return true;
            }
        }
    }

    // Removes 1 element from positionsTableArray
    function handleRemovePosition(positionToRemove){
        for(let i = 0; i < positionsTableArray.length; i++){
            if(positionsTableArray[i][0] === positionToRemove){ 
                positionsTableArray.splice(i, 1)
            }
        }
        forceUpdate();
    }

    const handleCleanSearch = () => {
        props.handleSearchMember(null);
        setCleanSearch(true);
        forceUpdate();
    }

    const handleOnFillFormationField = (value) => { 
        setIsPlayerSelected(false);      
        if(value !== ''){
            if(positionsTableArray.length === 0){
                let positionArray = positionsTableArray;
                positionArray.push([positionSelected.positionSelected, null, value]);
                setPositionsTableArray(positionArray);
                handleCleanSearch();
                setIsPlayerPosition(false);
            } else if(handleDuplicatePosition(positionSelected.positionSelected, value) !== true){
                let positionArray = positionsTableArray;
                positionArray.push([positionSelected.positionSelected, null, value]);
                setPositionsTableArray(positionArray);
                handleCleanSearch();
                if(positionsTableArray.length === 11){
                    sendFormationData(positionsTableArray);
                }
                setIsPlayerPosition(false);
            } else {
                setIsPlayerSelected(true);
            }
        }
    }

    const handleOnPlayerPosition = (position) => {
        setPositionSelected({positionSelected: position});
        setIsPlayerPosition(true);
    }



    return (
        
        <div className="formation-field">
            <div className="formation-field__container">
                <div className="formation-field__col formation-field__col-left">
                    { props.lineUpSelector === "true" &&            
                        <div onChange={ sendLineData } className="formation-field__line-up">
                            <label for="line-up" className='baloa-names'>{props.t('module')}</label>
                            <div className='formation-field__select'>
                                <select value={lineUp} onChange={handleLineUpChange} >
                                    <React.Fragment> { 
                                            props.lineUps?.map( lineUp => {
                                                    return <option key = { lineUp } value = { `${ lineUp }` }>{ lineUp }</option>
                                                } 
                                            )
                                        } 
                                    </React.Fragment>
                                </select>
                            </div>
                        </div>
                    }

                    <div className='formation-field__input-player__container'>
                        { isPlayerPosition &&
                            <div className='formation-field__input-player'>
                                <InputWithButtonComponent 
                                    onClick={ (event) => handleOnFillFormationField(event) }
                                    autoFocus={true}
                                >
                                    {props.t('add.button')}
                                </InputWithButtonComponent>
                                { isPlayerSelected && <div className='formation-field__input-error baloa-username'>{props.t('inputErrorLabel')}</div>}
                            </div>
                        }
                    </div>                    
                </div>
                <div className="formation-field__col formation-field__col-right">
                    <div className="formation-field-right">
                        <div className="formation-field__container__line-up">
                            <div className="formation-field__row formation-field__row__striker"> {/**striker */}
                                { positionsMap.get(lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "del" && 
                                            <React.Fragment>
                                                { !positionsTableArray.find(el => el[0] === position) &&
                                                    <div
                                                        key = { position }
                                                        onClick={ () => handleOnPlayerPosition(position) }
                                                        className={ `formation-field__row__unassigned__container ${ (positionSelected?.positionSelected === position)? "selected-field": ""}`}
                                                    >
                                                        <AddPlayerPlus className='formation-field__row__img' alt="" />
                                                        <span className='formation-field__username'>{props.t('attack')}</span>
                                                    </div> 
                                                } 
                                                { positionsTableArray.find(el => el[0] === position ) &&
                                                    <div className="formation-field__row__assigned" >
                                                        <div 
                                                            key = { position }
                                                            className = {"formation-field__row__assigned__container"}
                                                        >   
                                                            <span 
                                                                className='icon-x formation-field__erase-field' 
                                                                id={position}
                                                                onClick={ ()=>{                                    
                                                                    handleRemovePosition(position)                                                                       
                                                                }} 
                                                            />
                                                            {/* <img className='formation-field__row__img' src={ findDataPosition(position)[1] !== null? findDataPosition(position)[1]: baloa_user } alt={`${findDataPosition(position)[2]} img`}/> */}
                                                            <span className='icon-ball' color={getRandonIconBallColor()} />
                                                        </div>
                                                        <div className="formation-field__username__container">
                                                            <span className='formation-field__username baloa-username baloa-truncate'>{positionsTableArray.map(function(el){if(el[0] === position){return el[2]}})}</span>  {/*eslint-disable-line*/}
                                                        </div>
                                                    </div>
                                                }  
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                    ))
                                }
                            </div> 
                            <div className="formation-field__row formation-field__row__midfielder"> {/**midfielder */}
                                { positionsMap.get(lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "med" &&                                    
                                            <React.Fragment>                                            
                                                { !positionsTableArray.find(el => el[0] === position) &&
                                                    <div
                                                        key = { position }
                                                        onClick={ () => handleOnPlayerPosition(position) }
                                                        className={ `formation-field__row__unassigned__container ${ (positionSelected?.positionSelected === position)? "selected-field": ""}`}                                                     
                                                    >
                                                        <AddPlayerPlus className='formation-field__row__img' />
                                                        <span className='formation-field__username'>{props.t('midField')}</span>
                                                    </div> 
                                                } 
                                                { positionsTableArray.find(el => el[0] === position ) &&
                                                    <div className="formation-field__row__assigned" >
                                                        <div 
                                                            key = { position }
                                                            className = {"formation-field__row__assigned__container"}
                                                        >   
                                                            <span 
                                                                className='icon-x formation-field__erase-field' 
                                                                id={position}
                                                                onClick={ ()=>{                                    
                                                                    handleRemovePosition(position)                                                                       
                                                                }} 
                                                            />                      
                                                            {
                                                                // <img className='formation-field__row__img' src={ findDataPosition(position)[1] !== null? findDataPosition(position)[1]: baloa_user } alt="" />
                                                                <span className='icon-ball' color={getRandonIconBallColor()} />
                                                            }
                                                        </div>
                                                        <div className="formation-field__username__container">
                                                            <span className='formation-field__username baloa-username baloa-truncate'>{positionsTableArray.map(function(el){if(el[0] === position){return el[2]}})}</span>  {/*eslint-disable-line*/}
                                                        </div>
                                                    </div>
                                                }  
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                    ))
                                }
                            </div>
                            <div className="formation-field__row formation-field__row__defender"> {/**defense */}
                                { positionsMap.get(lineUp)?.map( (position, index) => ( 
                                    <React.Fragment key={position + index}>
                                        { getPositionNameByPosition(position) === "def" &&                                    
                                            
                                            <React.Fragment>                                        
                                                { !positionsTableArray.find(el => el[0] === position) &&
                                                    <div
                                                        key = { position }
                                                        onClick={ () => handleOnPlayerPosition(position) }
                                                        className={ `formation-field__row__unassigned__container ${ (positionSelected?.positionSelected === position)? "selected-field": ""}`}                                                     
                                                    >
                                                        <AddPlayerPlus className='formation-field__row__img'  />
                                                        <span className='formation-field__username'>{props.t('defense')}</span>
                                                    </div> 
                                                } 
                                                { positionsTableArray.find(el => el[0] === position ) &&
                                                    <div className="formation-field__row__assigned" >
                                                        <div 
                                                            key = { position }
                                                            className = {"formation-field__row__assigned__container"}
                                                        >       
                                                            <span 
                                                                className='icon-x formation-field__erase-field' 
                                                                id={position}
                                                                onClick={ ()=>{                                    
                                                                    handleRemovePosition(position)                                                                       
                                                                }} 
                                                            />                      
                                                            {
                                                                // <img className='formation-field__row__img' src={ findDataPosition(position)[1] !== null? findDataPosition(position)[1]: baloa_user } alt="" />
                                                                <span className='icon-ball' color={getRandonIconBallColor()} />
                                                            }
                                                        </div>
                                                        <div className="formation-field__username__container">
                                                            <span className='formation-field__username baloa-username baloa-truncate'>{positionsTableArray.map(function(el){if(el[0] === position){return el[2]}})}</span> {/*eslint-disable-line*/}
                                                        </div>
                                                    </div>
                                                }  
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                ))
                            }
                            </div> 
                            <div className="formation-field__row formation-field__row__goalkeeper"> {/**goal keeper */}
                            {
                                <React.Fragment>
                                
                                    { !positionsTableArray.find(el => el[0] === 'por') &&
                                        <div
                                            onClick={ () => handleOnPlayerPosition('por') }
                                            className={ `formation-field__row__unassigned__container ${ (positionSelected?.positionSelected === 'por')? "selected-field": ""}`}                                                     
                                        >
                                            <AddPlayerPlus className='formation-field__row__img'  />
                                            <span className='formation-field__username'>{props.t('goalKeeper')}</span>
                                        </div> 
                                    } 
                                    { positionsTableArray.find(el => el[0] === 'por' ) &&
                                        <div className="formation-field__row__assigned" >
                                            <div                                                   
                                                className = {"formation-field__row__assigned__container"}
                                            >       
                                                <span 
                                                    className='icon-x formation-field__erase-field' 
                                                    id={'por'}
                                                    onClick={ ()=>{                                    
                                                        handleRemovePosition('por')                                                                       
                                                    }} 
                                                />                      
                                                {
                                                    // <img className='formation-field__row__img' src={ findDataPosition('por')[1] !== null? findDataPosition('por')[1]: baloa_user } alt="" />
                                                    <span className='icon-ball' color={getRandonIconBallColor()} />
                                                }
                                            </div>
                                            <div className="formation-field__username__container">
                                                <span className='formation-field__username baloa-username baloa-truncate'>{positionsTableArray.map(function(el){if(el[0] === 'por'){return el[2]}})}</span>  {/*eslint-disable-line*/}
                                            </div>
                                        </div>
                                    }  
                                </React.Fragment> 
                            }
                            </div> 
                        </div>
                    </div>
                    { props.display === "form" && 
                        <div className="formation-field__container__img">
                            <img src={field_colored_icon} alt="" />
                        </div>
                    }
                    
                    
                    
                    {/* START disabled input search */}
                    {/* <InputSearchComponent
                        placeholder={props.t('text')}
                        handleSearch={(v)=>{props.handleSearchMember(v)}}
                        disabled={positionSelected !== undefined? false: true}
                        cleanValue={cleanSearch}                        
                    /> */}
                    {/* END disabled input search*/}
                    
                    {/* <InputWithButtonComponent 
                        onClick={ (event) => handleOnFillFormationField(event) }
                        disabled={positionSelected !== undefined? false: true}
                    >
                        {props.t('add.button')}
                    </InputWithButtonComponent> */}
                    
                    {/* { props.profiles.length === 0 &&
                        <div className="info-formation">                                                    
                            <AddPlayerLogo className="add-player-logo"/>
                            <h5 className="baloa-headline-5">{props.t('title')}</h5>
                        </div>
                    } */}
                   
                    {/* <div className="formation-field__searched-list scroll">
                    {       
                         props.profiles.map( selected => {
                            return( 

                                <div key={selected.id} className="display-form-players">
                                    
                                        <img src={selected.photo || baloa_user} alt="" />
                                
                                        <div className="selected-user">
                                            <p className="baloa-body-1">@{selected.username}</p>
                                            <p className="baloa-caption">{selected.first_name} {selected.last_name}</p>
                                            
                                        </div>                                            
                                        <Button className="button-position-selected" 
                                            onClick={ ()=> { 
                                                
                                                if(positionsTableArray.length === 0){
                                                    let positionArray = positionsTableArray;
                                                    positionArray.push([positionSelected.positionSelected, selected.photo, selected.username]);
                                                    setPositionsTableArray(positionArray);
                                                    handleCleanSearch();
                                                } else if(handleDuplicatePosition(positionSelected.positionSelected, selected.username) !== true){
                                                    let positionArray = positionsTableArray;
                                                    positionArray.push([positionSelected.positionSelected, selected.photo, selected.username]);
                                                    setPositionsTableArray(positionArray);
                                                    handleCleanSearch();
                                                    if(positionsTableArray.length === 11){
                                                        sendFormationData(positionsTableArray);
                                                    }
                                                }
                                            }}
                                        >{props.t('add.button')}</Button>
                                </div>
                            )
                        })
                    }
                    </div> */}
                </div>
            </div>
        </div>
    )
}


// Exports
export default withTranslation('newField')(FormationFieldComponent);