/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './TournamentPositionGoalScorerTableComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentPositionGoalScorerTableComponent(props){ 

    const listPositionFreeTableHtml = props.tournament_results_table?.groups?.map((free, index) => {
        return(
            <div className='tournament-position-goal-scorer-table__container'>
                <div className="baloa-subtitle-1">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelLeague')}</div>
                <table className='tournament-position-goal-scorer-table'>
                    <thead className="tournament-position-goal-scorer-table__table-header">
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelTeam')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPoints')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPlayed')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelWon')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelDrawn')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelLost')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsOnFavor')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsAgainst')}</div></th>
                        <th><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelGoalDiff')}</div></th>
                    </thead>
                    <tbody className="tournament-position-goal-scorer-table__table-body">
                        { free.positions_table.map( (position, index) => {
                            return(
                                <React.Fragment>
                                    { !position.disabled &&
                                        <tr key={index} >
                                            <td className="tournament-position-goal-scorer-table__team-name">
                                                <div className={ ( 
                                                    !position.best_third && position.classified 
                                                    ? "tournament-position-goal-scorer-table__vertical-line__clasified-team" 
                                                    : "tournament-position-goal-scorer-table__vertical-line" 
                                                ) } />
                                                <div className={ "baloa-body-2 " }>{index + 1}</div>
                                                { position.photo
                                                    ?
                                                    <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                    :
                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                }
                                                <div className="baloa-table">{position.page_name}</div> 
                                            </td>                                            
                                            <td><div className="baloa-table">{position.points}</div></td>
                                            <td><div className="baloa-table">{position.PJ}</div></td>
                                            <td><div className="baloa-table">{position.PG}</div></td>
                                            <td><div className="baloa-table">{position.PE}</div></td>
                                            <td><div className="baloa-table">{position.PP}</div></td>
                                            <td><div className="baloa-table">{position.GF}</div></td>
                                            <td><div className="baloa-table">{position.GC}</div></td>
                                            <td><div className="baloa-table">{position.GD}</div></td>
                                        </tr>
                                    }
                                    { position.disabled &&
                                        <tr key={index} className="disabled-team">
                                            <td className="table-position-clasified-team__item"> 
                                                <div className="baloa-table">{index + 1}</div>
                                                { position.photo
                                                    ?
                                                    <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                    :
                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                }
                                            </td>
                                            <td className='disabled'><div className="baloa-table">{position.page_name}</div></td>
                                            <td className='disabled'><div className="baloa-table">{position.points}</div></td>
                                            <td></td>
                                            <td className='disabled-msg'><div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.disabledTeam')}</div></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    });

    const listPositionGroupTableHtml = props.tournament_results_table?.groups?.map((group, index) => {
        return(
            <div className='tournament-position-goal-scorer-table__container'>                    
                <div className="baloa-subtitle-1">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelGroup')} { index + 1 }</div>
                <table className='tournament-position-goal-scorer-table'>
                    <thead className="tournament-position-goal-scorer-table__table-header">
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelTeam')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPoints')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPlayed')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelWon')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelDrawn')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelLost')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsOnFavor')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.goalsAgainst')}</th>
                        <th className="baloa-table" >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelGoalDiff')}</th>
                    </thead>
                    <tbody className="tournament-position-goal-scorer-table__table-body">
                        { group.positions_table.map( (position, index) => {
                            return (
                                <React.Fragment>
                                    { !position.disabled &&
                                        <tr key={index} >
                                            <td className="tournament-position-goal-scorer-table__team-name">
                                                <div className={ ( 
                                                    position.best_third && position.classified 
                                                    ? "tournament-position-goal-scorer-table__vertical-line__best-third-team" 
                                                    : !position.best_third && position.classified 
                                                    ? "tournament-position-goal-scorer-table__vertical-line__clasified-team" 
                                                    : "tournament-position-goal-scorer-table__vertical-line" 
                                                ) } />
                                                <div className={ "baloa-body-2 " }>{index + 1}</div>
                                                { position.photo
                                                    ?
                                                    <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                    :
                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                }
                                                <div className="baloa-table">{position.page_name}</div> 
                                            </td>
                                            <td className='baloa-table'>{position.points}</td>
                                            <td className='baloa-table'>{position.PJ}</td>
                                            <td className='baloa-table'>{position.PG}</td>
                                            <td className='baloa-table'>{position.PE}</td>
                                            <td className='baloa-table'>{position.PP}</td>
                                            <td className='baloa-table'>{position.GF}</td>
                                            <td className='baloa-table'>{position.GC}</td>
                                            <td className='baloa-table'>{position.GD}</td>
                                        </tr>
                                    }
                                    { position.disabled &&
                                        <tr key={index} className="disabled-team">
                                            <td className="table-position-clasified-team__item"> 
                                                <div className="baloa-table">{index + 1}</div>
                                                { position.photo
                                                    ?
                                                    <img src={ position.photo } alt="" className="table-players-scored-item__img"/>
                                                    :
                                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                                }
                                            </td>
                                            <td className='disabled'><div className="baloa-table">{position.page_name}</div></td>
                                            <td className='disabled'><div className="baloa-table">{position.points}</div></td>
                                            <td></td>
                                            <td className='disabled-msg'><div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.disabledTeam')}</div></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )   
    });

    return(
        <div className="container-position-goal-scorer-table">
            { props.tournament_type === "Free" && 
                <div className="col-left">
                    { listPositionFreeTableHtml }
                </div>
            }
            { (props.tournament_type === "Groups" || props.tournament_type === "Knockout") &&
                <div className="col-left">
                    { listPositionGroupTableHtml }
                </div>
            }
        </div>
    )
}

export default withTranslation('league')(TournamentPositionGoalScorerTableComponent);