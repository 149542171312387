// React
import React from 'react'

// Styles
import './PageInformationComponent.style.css'

// Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
import Popover from '@mui/material/Popover';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// i18n
import { withTranslation } from 'react-i18next';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function PageInformationComponent(props) {
    const [anchorMemberBtn, setAnchorMemberBtn] = React.useState(null);
    const handleClickMemberButton = (event) => {
        setAnchorMemberBtn(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorMemberBtn(null);
    };
    return (

        // Page Information
        <div className={"page-info " + (props.className ? props.className : '') + (props.orientation ? " " + props.orientation : " ")}>
            <div className={"page-info__photo " + (props.page?.photo ? '' : '')}>
                
                <div className="page-info__photo__img">
                    {props.page.photo &&
                        <img src={props.page?.photo} alt="" />
                    }

                    {!props.page.photo &&
                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                    }
                    {props.is_admin &&
                        <div className="page-info__photo__new-button" onClick={props.onNewPhoto}>
                            <span className="icon-plus"></span>
                        </div>
                    }
                </div>

                {/* props.is_admin &&                    
                    <div className="page-info__photo__edit-button" onClick={ props.onEditDescription }>
                        <img src={ edit_icon } alt=""/>
                    </div> */
                }

            </div>
            <div className="page-info__col2">
                <p className="page-info__fullname baloa-headline-2">{props.page?.name}</p>
                <p className="page-info__username baloa-normal-medium">@{props.page?.pagename} • {props.page?.page_type}</p>

                {/* Follwings & Followers */}
                <div className="page-info__follows">
                    <div className="page-info__follows__item">
                        <p className="baloa-subtitle-2">{`${props.page?.post_per_page_count}`}</p>
                        <p className="baloa-table">{`${props.t('profile.posts')}`}</p>
                    </div>
                    <div className="page-info__follows__item" onClick={() => props.onFollowers()}>
                        <p className="baloa-subtitle-2">{`${props.page?.followers_count}`}</p>
                        <p className="baloa-table">{`${props.t('profileModal.tabs.tabFollowers').toLowerCase()}`}</p>
                    </div>
                    <div className="page-info__follows__item" onClick={() => props.onFollowers()}>
                        <p className="baloa-subtitle-2">{`${props.page?.following_count}`}</p>
                        <p className="baloa-table">{`${props.t('profileModal.tabs.tabFollowing').toLowerCase()}`}</p>
                    </div>
                    {!props.page.is_admin &&
                        <div className={`page-info__follows__item new-line`}>
                            {!props.page?.is_admin && !props.page?.is_following && props.page?.page_type !== "group" &&
                                <SecondaryButtonComponent
                                    type="button"
                                    disabled={false}
                                    onClick={() => props.onFollow(props.page)}
                                    className="page-info__action__button"
                                >
                                    <span>{props.t('profile.buttonFollow')}</span>
                                </SecondaryButtonComponent>
                            }
                            {/* Unfollow Button */}
                            {!props.page?.is_admin && props.page?.is_following && props.page?.page_type !== "group" &&                                
                                <SecondaryButtonComponent
                                    type="button"
                                    disabled={false}
                                    onClick={() => props.onUnfollow(props.page)}
                                    className="page-info__action__button"
                                >
                                    <span>{props.t('profile.buttonStopFollowing')}</span>
                                </SecondaryButtonComponent>
                            }
                            {!props.page.is_admin &&
                                <React.Fragment>
                                    {!props.page.member_request_sent && !props.page.is_member && props.page?.page_type === "group" &&
                                        <RoundedButtonComponent
                                            className="page-info__action__button"
                                            type="button"
                                            disabled={props.page.is_blocked}
                                            onClick={() => { props.onClickMemberRequest(props.page.pagename) }}
                                        >
                                            <span className="page-groups-badge__btn-text">{props.t('groups.buttons.memeberRequest')}</span>
                                        </RoundedButtonComponent>
                                    }

                                    {props.page.member_request_sent && props.page?.page_type === "group" &&
                                        <RoundedButtonComponent
                                            className="page-info__action__button"
                                            type="button"
                                            disabled={false}
                                            onClick={() => { props.onClickCancelRequest(props.page.member_request_id) }}
                                        >
                                            <span className="page-groups-badge__btn-text">{props.t('groups.buttons.requestSent')}</span>
                                        </RoundedButtonComponent>
                                    }

                                    {props.page.is_member && props.page?.page_type === "group" &&
                                        <div className="page-groups-badge__member-buttons">
                                            <RoundedButtonComponent
                                                className="page-info__action__button"
                                                type="button"
                                                disabled={false}
                                                //onClick={ () => { props.onClickMemberOptions() } }
                                                onClick={handleClickMemberButton}
                                                id={"page-groups-badge_btn-member-options"}
                                            >
                                                <span className="page-groups-badge__btn-text">{props.t('groups.buttons.member')}</span>
                                            </RoundedButtonComponent>

                                            {anchorMemberBtn?.id === "page-groups-badge_btn-member-options" &&
                                                <Popover
                                                    id={"page-groups-badge_btn-member-options"}
                                                    open={Boolean(anchorMemberBtn)}
                                                    anchorEl={anchorMemberBtn}
                                                    onClose={handleCloseOptions}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    container={anchorMemberBtn ? anchorMemberBtn : null}
                                                >

                                                    <div
                                                        className="page-groups-badge__member-buttons__popover"
                                                    >
                                                        <TextButtonComponent
                                                            //className="header-auth__actions__me__popover__action"
                                                            onClick={() => {
                                                                handleCloseOptions();
                                                                props.onClickLeaveGroup(props.page.pagename);
                                                            }
                                                            }
                                                        >
                                                            <span>{props.t('groups.buttons.leaveGroup')}</span>
                                                        </TextButtonComponent>
                                                    </div>


                                                </Popover>

                                            }



                                            {/*<SecondaryButtonComponent 
                                    className='page-groups-badge__btn'
                                    type="button"
                                    disabled={false} 
                                    onClick={ () => { props.onClickChat() } }
                                >
                                    <span className="page-groups-badge__btn-text">{t('groups.buttons.groupChat')}</span>
                                </SecondaryButtonComponent>*/}
                                        </div>
                                    }
                                </React.Fragment>
                            }
                            {/* {props.page.is_admin &&
                                <RoundedButtonComponent
                                    type="button"
                                    disabled={false}
                                    onClick={()=>{}}
                                    className="page-info__action__button"
                                >
                                    <span>{props.t('profile.buttonShare')}</span>
                                </RoundedButtonComponent>
                            } */}
                        </div>
                    }
                </div>
                {props.is_admin &&                    
                    <div className="page-info__admin-buttons">
                        <SecondaryButtonComponent
                            onClick={ props.onEditDescription }
                        >
                            <span>{props.t('profile.btnEditPage')}</span>
                        </SecondaryButtonComponent>
                        <SecondaryButtonComponent
                            onClick={props.handleOnSharePageButtonClick}
                        >                            
                            <span>{props.t('profile.btnSharePage')}</span>
                        </SecondaryButtonComponent>
                    </div>
                }

                {props.page?.attributes?.description?.value && !props.is_admin &&
                    <p className="page-info__description baloa-table ">
                        {
                            props.page?.attributes?.description?.value
                        }
                    </p>
                }
                {props.page?.attributes?.description?.value && props.is_admin &&
                    <p onClick={props.onEditDescription} className="page-info__description baloa-table link">
                        {
                            props.page?.attributes?.description?.value
                        }
                    </p>
                }

                {/* !props.page?.attributes?.description?.value && props.is_admin &&
                    <div
                        onClick={props.onEditDescription}
                        className="page-info__description baloa-hyperlink">
                        <span>{props.t('profile.titleButton')}</span>
                    </div> */
                }

            </div>


        </div>
    )
}

export default withTranslation('page')(PageInformationComponent)

//export default PageInformationComponent;