/*eslint eqeqeq: "off"*/
// React
import React, { useState }from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollTrigger from 'react-scroll-trigger';

// Material UI
import Popover from '@mui/material/Popover';

// Assets
import options_icon from 'assets/icons/options-icon.svg';
import sm_copy_icon from 'assets/icons/share-menu-copy-icon.svg';

// Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import ShowPostFormationFieldComponent from 'shared/components/show-post-formation/ShowPostFormationFieldComponent';
import InputWithButtonComponent from 'shared/components/input-with-button/InputWithButtonComponent';
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';
import ShowPostQuizComponent from 'shared/components/show-post-quiz/ShowPostQuizComponent';
import ShowPostPollComponent from 'shared/components/show-post-poll/ShowPostPollComponent';
import QuotedPostComponent from '../quoted-post/QuotedPostComponent';

// Formaters
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';

// Actions
import { changeHomeEditPostModal } from 'app/home/actions/HomeActionsCreators';
import {overwriteInterceptorAction } from 'app/profiles/actions/ProfileActionsCreators';

// Functions
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';

// Shapes
import { PostShape } from 'app/posts/shapes/PostShape';

// Styles
import './PostComponent.styles.css';


// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

// add components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Post Component:
//   Renders Post.

function PostComponentV2(props) {
    
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [posterName, setPosterName] = React.useState (null); // eslint-disable-line
    const [posterId, setPosterId] = React.useState (null); // eslint-disable-line
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [originalLanguage, setOriginalLanguage ] = React.useState(false);


    // Video controls
    const videoRef = React.useRef();

    //let video_player = null;
    /* React.useEffect( () => {
        if ( video_player != undefined ) {
            // Play video when father component tells to
            if ( props.play_video ) { video_player.play(); }
            else { video_player.pause(); }
            // Mute video when father component tells to
            video_player.muted = !props.play_audio;
        }
    }, [ props.play_video, props.play_audio ] )  // eslint-disable-line */
    React.useEffect( () => {
        if ( videoRef.current != undefined ) {
            // Play video when father component tells to
            if ( props.play_video ) { 
                videoRef.current.play(); 
            }else { 
                videoRef.current.pause(); 
            }
            // Mute video when father component tells to
            videoRef.current.muted = !props.play_audio;
        }
    }, [ props.play_video, props.play_audio ] )  // eslint-disable-line

    React.useEffect( () => {

        if (props.profile?.page_active != undefined && props.listUserPages) {
            var index = props.listUserPages.findIndex(element => element[1] === props.profile.page_active.id);
            setSelectedIndex(index);
            setPosterName(props.profile?.page_active.pagename);
            setPosterId(props.profile?.page_active.id);
        }  
          
    }, [ props.profile?.page_active ]) // eslint-disable-line

    // Edit post
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorCombo, setAnchorCombo] = React.useState(false);
    const [anchorReactions, setAnchorReactions] = React.useState(null);

    const handleClickEditPostButton = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseEditPostOption = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    // Share post menu
    const [shareMenuOpen, setShareMenuOpen] = useState(false);
    function shareMenuClick(){
        if(props.menu_is_open){
            if(shareMenuOpen){
                setShareMenuOpen(false);
            }else{
                setShareMenuOpen(true);
            }
        }
    }
    
    // Report post menu
    const [reportMenuOpen, setReportMenuOpen] = useState(false);
    function reportMenuClick(){
        if(props.menu_is_open){
            if(reportMenuOpen){
                setReportMenuOpen(false);
            }else{
                setReportMenuOpen(true);
            }
        }
    }

    // Change clap color
    /* function changeClapColor(event){    
        props.post.is_clapped = !props.post.is_clapped;
        props.handleClap(props.post);
    } */
    
    // List 
    const handleClickListItem = (event) => {
        setAnchorCombo(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuItemClick = (event, index, page) => {
        setSelectedIndex(index);
        if(index!=0){
            setPosterId(page[1]);
            setPosterName(page[0]);
        }else{
            setPosterId(null);
            setPosterName(null);
        }
        setMenuOpen(false);
    };
    
    const handleClose = () => {
        setAnchorCombo(null);
    };

    const overInter = () => {
        if(props.profile != undefined) {
            if (selectedIndex > 0) {   //post like page
                props.overwriteInterceptorAction(true);
            }else if( props.profile.page_active ){   //page post like profile
                props.overwriteInterceptorAction(true);
            }else{
                props.overwriteInterceptorAction(false);
            }
        }
    }

    // set Reaction
    function setReaction(event){    
        setAnchorReactions(event.currentTarget);
    }

    const handleCloseReactions = (post_id ,reaction, reaction_id, reaction_type) => {
        setAnchorReactions(null);
        if(reaction_id == null){
            props.sendReaction(post_id, reaction);
        }else if(reaction === reaction_type){
            props.deleteReaction(post_id, reaction_id);
        }else{
            props.overrideReaction(post_id, reaction, reaction_id);
        }
        
    };

    const changeLanguage = () => {
        if(originalLanguage){
            setOriginalLanguage(false);
        }else{
            setOriginalLanguage(true);
        }
    }

    const handleExitViewport= () => {
        if ( videoRef.current != undefined ) {
            videoRef.current.pause(); 
        }
        props.onExitViewport();
    }
    return (
    
        <div className={"post " + (props.className ? props.className : '')} key={ props.key }>
            {props.post?.context?.page_type == "group" && props.show_group &&
                <div className="post__header__group">
                    <div
                        onClick={ () => props.handleOnAuthorClick( props.post.context ) }
                        className={"post__header__group-photo"}>                        
                            {props.post.context.photo &&
                                <img src={props.post.context.photo} alt="" />
                            }
                            {!props.post.context.photo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            } 
                    </div>
                    <div className="post__header__info">
                        <div
                            onClick={ () => props.handleOnAuthorClick( props.post.context ) }
                            className="post__header__info__group-username baloa-caption">
                            { props.post.context.caption }
                        </div>
                    </div>
                </div>
            }
            {/* Profile Post */}
            { props.post.author.type_object == "profile" &&
                <div className="post__header">

                    {/* Profile photo */}
                    <div
                        onClick={ () => props.handleOnAuthorClick( props.post.author ) }
                        className={"post__header__photo"}>
                            {props.post.author.photo &&
                                <img src={props.post.author.photo} alt="" />
                            }
                            {!props.post.author.photo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            }                    
                    </div>

                    {/* Profile data */}
                    <div className="post__header__info">
                        <div
                            
                            className="post__header__info__username baloa-normal-medium"
                        >
                            <span onClick={ () => props.handleOnAuthorClick( props.post.author ) } >{ `${props.post.author.caption}`} </span>
                            {props.post.author.is_verified &&
                                <span class="iconb2-Verificated">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            }
                            {props.post.author.baloer &&
                                <span class="iconbal-Balores">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </span>
                            }  
                            { !isMobile &&
                                <React.Fragment>
                                    &nbsp;&bull;&nbsp;
                                    <span 
                                        className={"post__header__info__username__follow-link baloa-hyperlink"}
                                        onClick={() => {
                                            props.post.is_following ?
                                                props.unfollowPostProfile(props.post.author.identifier, props.post.id)
                                                : props.followPostProfile(props.post.author.identifier, props.post.id);
                                        }}
                                    >                                  
                                        {props.post.is_following ?
                                            props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                            :
                                            props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                        } 
                                    </span>
                                </React.Fragment>
                            }
                        </div>
                        <div className="post__header__info__date baloa-table-column">                           
                            { `${props.post.author.profile_type}`}  &bull; {`${convertDateToMoment(props.post.created)}`}
                            {/* {console.log(props.post)/**en post hace [5] horas */} 
                            { isMobile &&
                                <React.Fragment>
                                    &nbsp;&bull;&nbsp;
                                    <span 
                                        className={"post__header__info__username__follow-link baloa-hyperlink mobile"}
                                        onClick={() => {
                                            props.post.is_following ?
                                                props.unfollowPostProfile(props.post.author.identifier, props.post.id)
                                                : props.followPostProfile(props.post.author.identifier, props.post.id);
                                        }}
                                    >                          
                                        {props.post.is_following ?
                                            props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                            :
                                            props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                        } 
                                    </span>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {/* Not Owners options */}
                    { props.post.is_owner === false &&
                        <div className="post__header__options">
                            <div 
                                className="post__header__options__option"
                                onClick={()=>{reportMenuClick()}}
                            >
                                <img src={ options_icon } alt=""/>
                                { reportMenuOpen && (
                                    <ul className="post__header__options__option__list">
                                        <li>
                                            <TextButtonComponent  
                                                onClick={() => {
                                                    props.openReportPostModal(true);
                                                    props.handleReportPost(props.post.id); 
                                                    reportMenuClick();
                                                }}
                                                className="post__header__options__option__list__button"
                                            >

                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="iconb2-report"></span>
                                                    {props.t('postReport.btnReport')}
                                                </span> 
                                            </TextButtonComponent>
                                        </li>
                                        <li>
                                            <TextButtonComponent 
                                                onClick={() => {
                                                    props.openBlockPostModal(true);
                                                    props.handleBlockProfile(props.post.author.identifier);
                                                    reportMenuClick();
                                                }}
                                                className="post__header__options__option__list__button"
                                            >
                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="iconb2-block"></span>
                                                    {props.t('postReport.btnBlock')} @{props.post.author.identifier}
                                                </span> 
                                            </TextButtonComponent>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    }

                    {/* Owner options */}
                    { props.post.is_owner &&
                        <div className="post__header__options">
                            <div className="post__header__options__option">
                                <span 
                                    aria-describedby='simple-popover'
                                    // className="profile-experience__actions"
                                    onClick={ handleClickEditPostButton }
                                >
                                    <img src={ options_icon } alt=""/>
                                </span>    
                            </div>
                        </div>
                    }

                    {/* User options edit popover */}
                    {/* Was necesary use open variable to close the popover div: is a special case don't use if is not necesary */}
                    { open && 
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={ anchorEl }
                            onClose={handleCloseEditPostOption}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',    
                            }}
                            container= {anchorEl ? anchorEl : null}
                            >
                            <div
                            className="post__header__options__option__list__popover"
                            >
                                <TextButtonComponent
                                    onClick={ ()=>{    
                                        handleCloseEditPostOption();
                                        props.changeHomeEditPostModal( true, props.post );
                                        //props.onEditButton(props.experience_data, props.experience_data);
                                    } }
                                    className="post__header__options__option__list__button"
                                >
                                    <span className="post__header__options__option__list__color baloa-table-column">
                                        <span class="icon-edit-82"></span>
                                        <span>{props.t('postEdit.btnEdit')}</span>
                                    </span>
                                </TextButtonComponent>
                                <TextButtonComponent
                                    onClick={ ()=>{ 
                                        handleCloseEditPostOption();
                                        //props.deleteAttributeOnProfile( props.state.profile.profile, props.experience_data.id )
                                        props.handleOnDeletePostClick( props.post );
                                    } }
                                    className="post__header__options__option__list__button"
                                >
                                    <span className="post__header__options__option__list__color baloa-table-column">
                                        <span class="icon-trash"></span>
                                        <span>{props.t('postEdit.btnDel')}</span>
                                    </span>
                                </TextButtonComponent>  
                            </div>
                        </Popover>
                    }
                </div>                
            }

            {/* Page Post */}
            { props.post?.author?.type_object == "page" &&
                <div className="post__header">
                    <div
                        onClick={() => props.handleOnAuthorClick( props.post?.author ) }
                        className={"post__header__photo"}
                    >
                        {props.post?.author.photo &&
                            <img src={props.post?.author.photo} alt="" />
                        }
                        {!props.post?.author.photo &&
                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                        }
                    </div>
                    <div className="post__header__info">
                        <div className="post__header__info__username baloa-normal-medium">
                            <span onClick={ () => props.handleOnAuthorClick( props.post?.author ) } >{ `${props.post?.author?.caption}`} </span>
                            {/* props.post?.author?.identifier */}
                            {props.post.author.is_verified &&
                                <span class="iconb2-Verificated">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            }
                            {props.post.author.baloer &&
                                <span class="iconbal-Balores">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </span>
                            } 
                            { !isMobile &&
                                <React.Fragment>
                                    &nbsp;&bull;&nbsp;                                             
                                    <span 
                                        className={"post__header__info__username__follow-link baloa-hyperlink"}
                                        onClick={() => {
                                            props.post.is_following ?
                                                props.unfollowPostPage(props.post.author.identifier, props.post.id)
                                                : props.followPostPage(props.post.author.identifier, props.post.id);
                                        }}
                                    >                                
                                        {props.post.is_following ?
                                            props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                            :
                                            props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                        } 
                                        
                                    </span>
                                </React.Fragment>
                            }
                        </div>
                        <p className="post__header__info__date baloa-table-column">
                            { convertDateToMoment( props.post?.created ) }
                            { isMobile &&
                                <React.Fragment>
                                    &nbsp;&bull;&nbsp;                                             
                                    <span 
                                        className={"post__header__info__username__follow-link baloa-hyperlink mobile"}
                                        onClick={() => {
                                            props.post.is_following ?
                                                props.unfollowPostPage(props.post.author.identifier, props.post.id)
                                                : props.followPostPage(props.post.author.identifier, props.post.id);
                                        }}
                                    >                                
                                        {props.post.is_following ?
                                            props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                            :
                                            props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                        }                                        
                                    </span>
                                </React.Fragment>
                            }
                        </p>

                    </div>

                    { (!props.post?.is_owner && !props.page?.page?.is_admin && !props.page?.page?.is_member  ) &&
                        <div className="post__header__options">
                            <div 
                                className="post__header__options__option"
                                onClick={()=>{reportMenuClick()}}
                            >
                                <img src={ options_icon } alt=""/>
                                
                                { reportMenuOpen && (
                                    <ul className="post__header__options__option__list">
                                        <li>
                                            <TextButtonComponent  
                                                onClick={() => {
                                                    props.openReportPostModal(true);
                                                    props.handleReportPost(props.post.id); 
                                                    reportMenuClick();
                                                }}
                                                className="post__header__options__option__list__button"
                                            >
                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="iconb2-report"></span>
                                                    {props.t('postReport.btnReport')}
                                                </span> 
                                            </TextButtonComponent>
                                        </li>
                                        <li>
                                            <TextButtonComponent 
                                                onClick={() => {
                                                    props.openBlockPostModal(true);
                                                    props.handleBlockPage(props.post.author.identifier);
                                                    reportMenuClick();
                                                }}
                                                className="post__header__options__option__list__button"
                                            >
                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="iconb2-block"></span>
                                                    {props.t('postReport.btnBlock')} @{props.post.author.identifier}
                                                </span>
                                            </TextButtonComponent>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    }
                    
                    { ( props.page?.page?.is_admin || (props.page?.page?.is_member === true && permissionsController(props.page?.page?.roles, ['CONTENTAD'])) ) &&
                        <div className="post__header__options">
                            <div className="post__header__options__option">
                                <span 
                                    aria-describedby='simple-popover'
                                    onClick={ handleClickEditPostButton }
                                > 
                                    <img src={ options_icon } alt=""/>
                                </span>    
                            </div>
                        </div>
                    }
                    {  open && 
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={ anchorEl }
                                onClose={handleCloseEditPostOption}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',    
                                }}
                                container= {anchorEl ? anchorEl : null}
                                >
                                <div
                                className="post__header__options__option__list__popover"
                                >
                                    <TextButtonComponent
                                        onClick={ ()=>{    
                                            handleCloseEditPostOption();
                                            props.changeHomeEditPostModal( true, props.post );
                                            //props.onEditButton(props.experience_data, props.experience_data);
                                        } }
                                        className="post__header__options__option__list__button"
                                    >                                        
                                        <span className="post__header__options__option__list__color baloa-table-column">
                                            <span class="icon-edit-82"></span>
                                            <span>{props.t('postEdit.btnEdit')}</span>
                                        </span>
                                    </TextButtonComponent>
                                    <TextButtonComponent
                                        onClick={ ()=>{ 
                                            handleCloseEditPostOption();
                                            //props.deleteAttributeOnProfile( props.state.profile.profile, props.experience_data.id )
                                            props.handleOnDeletePostClick( props.post );
                                        } }
                                        className="post__header__options__option__list__button"
                                    >
                                        <span className="post__header__options__option__list__color baloa-table-column">
                                            <span class="icon-trash"></span>
                                            <span>{props.t('postEdit.btnDel')}</span>
                                        </span>                                        
                                    </TextButtonComponent>  
                                </div>
                            </Popover>
                        }
                </div> 
            }

            {/* Post caption */}
            <div className="post__caption-me">
                {props.post.post_type === "Poll" &&
                    <div className={"post__caption-me__type-poll"}>
                        <span className={"iconpq-poll"}></span>
                        {props.t("post.type.poll")}
                    </div>
                }
                {props.post.post_type === "Quiz" &&
                    <div className={"post__caption-me__type-quiz"}>
                        <span className={"iconpq-quiz"}></span>
                        {props.t("post.type.quiz")}
                    </div>
                }
                <div className="post__caption-me__text baloa-normal" dangerouslySetInnerHTML={{                            
                        __html: HASHTAG_FORMATTER( originalLanguage? props.post.original_caption : props.post.caption)
                    }}>
                </div>
                {props.post.caption_language && props.post.caption_language !== props.post.original_caption_language &&
                    <div className={"post__caption-me baloa-names"} onClick={changeLanguage}>{ originalLanguage? props.t("post.showTranslation") : props.t("post.showOriginalLanguage")}</div>
                }                
            </div>

            {/* Post Content */}

            <ScrollTrigger 
                onEnter={ props.onEnterViewport } 
                onExit={ /* props.onExitViewport */()=>{ handleExitViewport() }  }
                key={props.post.id}
            >
            
                {/* Post Formation content */}
                { props.post.formation &&
                    <div className="post-formation__content">
                        <div className="post-formation__data">
                            <div className="post-formation__data__positions-graph">
                                <ShowPostFormationFieldComponent
                                    show={props.post.formation ? "block" : "none"}
                                    display="form"
                                    position="secondary"
                                    formation={props.post.formation}
                                    sup="false"
                                />
                            </div>
                        </div>
                    </div>
                }

                {/* Post video content */}
                { props.post.post_video &&
                    <div className="post__content">
                        
                        {/* Video Player */}
                        {/* <video
                            ref = { el_ref => { video_player = el_ref } }
                            src = { props.post.post_video }
                            loop
                            preload = "auto"
                            poster = { props.post.post_stream?.thumbnail ? props.post.post_stream?.thumbnail : "" }
                            muted 
                            playsinline
                        ></video> */}
                        {props.post?.post_stream?.hls?
                            <ReactHlsPlayer
                                playerRef={videoRef}
                                src={props.post?.post_stream?.hls}
                                preload = "auto"
                                autoPlay={false}
                                playsinline
                                width={'100%'}
                                height={'auto'}                                            
                                loop={true}
                                poster = { props.post?.post_stream?.thumbnail ? props.post?.post_stream?.thumbnail : "" }
                                controls={true}
                                hlsConfig={{
                                    startLevel: 4, // El índice de la calidad deseada
                                    //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                                    capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                                    maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                                    maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                                    maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                                    maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                                    lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                                    highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                                    nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                                    nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
                                }}
                            />
                        :
                            <video
                                ref = { videoRef }
                                src = { props.post?.post_video }
                                loop
                                preload = "auto"
                                poster = { props.post.post_stream?.thumbnail ? props.post.post_stream?.thumbnail : "" }
                                muted 
                                playsinline
                                controls={true}
                            ></video>
                        }
                        {/* <div className="post__content__video-control"
                            onClick={ () => props.handleOnPlayVideo() }
                        >
                            <div className={ !props.play_video ? "post__content__video-control__background" : "" }></div>
                            <span className={ !props.play_video ? "post__content__video-control__icon" : "" } ></span>
                        </div>
                        <div className="post__content__control-mute"
                            onClick={ () => props.handleOnMuteVideo() }
                        >
                            <span className={ !props.play_audio ? "post__content__control-mute__icon icon-sound-off" : "post__content__control-mute__icon icon-sound-on"}></span>
                        </div> */}

                    </div>
                }

                {/* Post image content */}
                {props.post.post_image &&
                    <div className="post__content">
                        <img src={props.post.post_image} alt="" />
                    </div>
                }

                {/* Post match content */}
                {props.post.post_match &&
                    <div className="post__content">
                        <LeagueTournamentInfoComponent
                            tournament_info={props.post.post_match}
                            className = { "post__content__tournament-result__container" }
                            display = { 'post' }
                        />                        
                    </div>
                }

                {/* Post Quiz */}
                {props.post.post_type === "Quiz" &&
                    <div className="post__content">
                        <ShowPostQuizComponent
                            quiz_answers_count={props.post.quiz_answers_count}
                            quiz_answers={props.post.quiz_answers}
                            is_quiz_answer={props.post.is_quiz_answer}
                            handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                            post_id={props.post.id}
                        />                        
                    </div>
                }

                {/* Post Poll */}
                {props.post.post_type === "Poll" &&
                    <div className="post__content">
                        <ShowPostPollComponent
                            poll_answers_count={props.post.poll_answers_count}
                            poll_answers={props.post.poll_answers}
                            is_poll_answer={props.post.is_poll_answer}
                            handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                            post_id={props.post.id}
                        />                        
                    </div>
                }

                {/* Post Quoted */}
                {props.post.post_quoted &&
                    <div className="post__content">
                        <QuotedPostComponent
                            post={props.post.post_quoted}
                            followPostProfile= { props.followPostProfile }
                            unfollowPostProfile= { props.unfollowPostProfile }
                            followPostPage= { props.followPostPage }
                            unfollowPostPage= { props.unfollowPostPage }
                            className={'feed'}
                            handleOnPlayVideo ={ props.handleOnPlayVideo }
                            handleOnMuteVideo= { props.handleOnMuteVideo }
                            play_video={ props.play_video }
                            play_audio={ props.play_audio }
                        />                     
                    </div>
                }
            </ScrollTrigger>
            
            {/** Condicional para mostrar o no comentarios, reacciones y botones de accion */}       
            {props.post.post_type !== "Poll" && props.post.post_type !== "Quiz" &&
            <React.Fragment>
            {/* Claps resume */}
            { props.post.reaction_count > 0 &&    
                <React.Fragment>                      
                    <div className="post__clapped-by">                    
                        {/* Clap Photos */}
                        <div className="post__clapped-by__photos">
                            { props.post.reacted_by.map(
                                clap =>
                                    <div key={clap[0]} className="post__clapped-by__photos__photo">
                                        {clap[2] &&
                                            <img src={clap[2]} alt="" />
                                        }
                                        {!clap[2] &&
                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                        }                                        
                                    </div>
                            ) }
                        </div>

                        {/* Claps caption */}
                        <p className="post__clapped-by__message" 
                            onClick={ () => { 
                                //props.onCloseClapsModal(true);
                                props.handleReactionsDetails(true);
                                //props.loadPostClapsById(props.post.id);
                                props.loadPostReactionsById(props.post.id);
                            }}  
                        >
                            { props.post.reaction_count <= 0 ?
                                ""
                                :
                                <span className="baloa-normal" >
                                    {/* props.t('postModal.titleLike')} @{props.post.reacted_by[0][0] */} 
                                    {/* {(props.post.reaction_count - 1) <= 0 ? "" :  ' ' + props.t('postModal.text1') + ' ' + (props.post.reaction_count - 1) + ' ' + props.t('postModal.text2')} */}
                                    {props.post.is_reacted? (props.post.reaction_count - 1) <= 0 ? props.t('postModal.reactionText3', {user: props.post.reacted_by[0][1]}) : props.t('postModal.reactionText1', {reactionsCount: `${props.post.reaction_count - 1}` }) : (props.post.reaction_count - 1) <= 0 ? props.t('postModal.reactionText3', {user: props.post.reacted_by[0][1]}) :props.t('postModal.reactionText2', {user: props.post.reacted_by[0][1] ,reactionsCount: `${(props.post.reaction_count - 1)}` })}
                                </span>
                            }
                        </p>

                    </div>
                    <div className="post__separator"></div>        
                </React.Fragment> 
            }   
            
            {/* Post actions */}
            <div className="post__actions" id={ 'post_actions_' + props.postItemIndex }>

                {/* Clap */}
                <div 
                    /* onClick={(event)=>{setReaction(event)}}   */                   
                    className="post__actions__action"
                >
                    <React.Fragment>
                    {!props.post.is_reacted &&
                        <div className="post__actions__action" onClick={(event)=>{setReaction(event)}}>
                            <span class="icon-claps-48"></span><span className="post__actions__action__action-text baloa-normal">{props.t('postReactions.react')}</span>
                        </div>
                    }
                    
                    {props.post.is_reacted &&  props.post.reaction_type == "Clap" &&
                    <div className="post__actions__action__reaction-green baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}>   
                        <span class="iconr-clap post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                            
                        </span>
                        {props.t('postReactions.clap')}
                    </div>
                    }
                    {props.post.is_reacted &&  props.post.reaction_type == "Support" &&
                    <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}>   
                        <span class="iconr-support post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            
                        </span>
                        {props.t('postReactions.support')}
                    </div>
                    }
                    {props.post.is_reacted &&  props.post.reaction_type == "Its_Funny" &&
                    <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}> 
                        <span class="iconr-funny post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                            
                        </span>
                        {props.t('postReactions.itsfunny')}
                    </div>
                    }
                    {props.post.is_reacted &&  props.post.reaction_type == "Red_Card" &&
                    <div className="post__actions__action__reaction-red baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}> 
                        <span class="iconr-red-card post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            
                        </span>
                        {props.t('postReactions.redcard')}
                    </div>
                    }
                    {props.post.is_reacted &&  props.post.reaction_type == "Sad" &&
                    <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}> 
                        <span class="iconr-sad post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>                            
                        </span>
                        {props.t('postReactions.sad')}
                    </div>
                    }
                    {props.post.is_reacted &&  props.post.reaction_type == "Celebrate" &&
                    <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={()=>{props.deleteReaction(props.post.id, props.post.reaction_id)}}> 
                        <span class="iconr-celebrate post__actions__action__reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                            
                        </span>
                        {props.t('postReactions.celebrate')}
                    </div>
                    }

                    </React.Fragment>
                    
                </div>
                <Menu
                    className = "post__actions__action__menu-reactions"
                    id={ "reactions-menu_"+ props.postItemIndex }
                    anchorEl={anchorReactions}
                    transformOrigin={{
                        vertical: 50,
                        horizontal: 'left',
                    }}
                    container ={document.getElementById('post_actions_' + props.postItemIndex)}
                    
                    open={Boolean(anchorReactions)}
                    onClose={()=>{setAnchorReactions(null)}}
                >
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Clap", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-clap post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                        </span>                        
                    </MenuItem>
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Support", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-support post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                        </span>
                    </MenuItem>
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Its_Funny", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-funny post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    </MenuItem>
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Red_Card", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-red-card post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                        </span>
                    </MenuItem>
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Sad", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-sad post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    </MenuItem>
                    <MenuItem onClick={()=>{handleCloseReactions(props.post.id, "Celebrate", props.post.reaction_id, props.post.reaction_type)}}>
                        <span class="iconr-celebrate post__actions__action__menu-reactions__icon">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                            <span class="path13"></span><span class="path14"></span>
                        </span>
                    </MenuItem>
                </Menu>
                {/* Comment */}
                <div
                    onClick={() => props.handleOnMessageIconClick(props.post)}
                    className="post__actions__action"
                >
                    <span class="icon-message"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post.actions.comment')}</span>
                </div>

                {/* Quote */}
                {!props.post.post_quoted &&
                <div
                    onClick={() => props.handleOnQuoteIconClick(props.post)}
                    className="post__actions__action"
                >
                    <span class="iconpq-quote"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post.actions.quote')}</span>
                </div>
                }

                {/* Share */}
                <div
                    id={"post_" + props.post.id}
                    className="post__actions__action"
                    onClick={ () => shareMenuClick()}
                >
                    <span class="icon-share"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post.actions.share')}</span>

                    { shareMenuOpen && (
                        <ul className="post__actions__action__list">
                            <li>
                                <button type="button" 
                                className ="post__actions__action__list__button"
                                onClick={ () => { props.handleOnCopyLinkClick(props.post) } }
                                >
                                    <img src={sm_copy_icon} alt=""/>
                                    <span>{props.t('postModal.btnCopy')}</span> 
                                </button>
                            </li>
                        </ul>
                    ) }
                </div>

            </div>                        
            <div className="post__separator"></div>
            {/* Comment box */}
            {/* From the reducer, control was added to not send a list pages for groups */}
            <div className="post__new-comment">                
                { props.is_group &&
                    <div className={"post__new-comment__photo"}>                        
                        {props.me_photo &&
                            <img src={props.me_photo} alt="" />
                        }
                        {!props.me_photo &&
                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                        }
                    </div>
                }
                {props.listUserPages != undefined &&  !props.is_group &&
                
                <div>
                    <List component="ul" aria-label="Device settings"  id={"list-menu_" + props.postItemIndex }>  
                        {props.listUserPages[selectedIndex] && (
                        <ListItem
                            button
                            aria-haspopup="true"
                            aria-controls={"lock-menu_" + props.postItemIndex }
                            aria-label="Poster"
                            onClick={(event) => {handleClickListItem(event)}}
                            
                        >
                            <ListItemAvatar>
                                {props.listUserPages[selectedIndex][2] &&
                                    <Avatar alt={props.listUserPages[selectedIndex][0]} src={props.listUserPages[selectedIndex][2]} />
                                }
                                {!props.listUserPages[selectedIndex][2] &&
                                    <Avatar className="post__new-comment_list__avatar" alt={props.listUserPages[selectedIndex][0]} ><span className={"post__new-comment_list icon-ball"} color={getRandonIconBallColor()}></span></Avatar>
                                }
                            </ListItemAvatar>
                            <span className='icon-chevron-down' />
                        </ListItem>
                        )}
                    </List>

                    <Menu
                        id={"lock-menu_" + props.postItemIndex }
                        anchorEl={anchorCombo}
                        container ={document.getElementById('list-menu_' + props.postItemIndex)}
                        onClose={handleClose} 
                        open = {menuOpen}
                        variant = {'menu'}         
                    >
                    {props.listUserPages.map((option, index) => (
                        <MenuItem
                            key={option[1]}
                            selected={index === selectedIndex }
                            onClick={(event) => handleMenuItemClick(event, index, option)}
                            onBlur={(event) => {
                                setMenuOpen(false);
                            }}
                        >
                            {option[2] &&
                                <img className="post__header__photo" src={option[2]} alt="" />
                            }
                            {!option[2] &&
                                <span className={"post__header__photo icon-ball"} color={getRandonIconBallColor()}></span> 
                            }
                            {option[0]}                            
                        </MenuItem>
                    ))}
                    </Menu>
                </div>
                }

                <div className="post__new-comment__comment" id={"post-comment_" + props.postItemIndex }>
                    <InputWithButtonComponent 
                        onClick={ ( new_comment ) => { 
                            overInter();
                            props.handleOnComment( new_comment, posterName, posterId ) 
                        } }
                        placeholder={props.t('comment.text')} 
                    >
                        {props.t('postModal.btnPost')}
                    </InputWithButtonComponent>
                </div>
            </div>

            {/* Post comment Caption */}
            <div className="post__caption">                
                { props.post?.last_three_comments?.map(comment => 
                    <div className="post__caption__text">   
                        <div className={"post__caption__photo"}>                        
                                {comment?.author.photo &&
                                    <img src={comment?.author.photo} alt="" />
                                }
                                {!comment?.author.photo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                } 
                        </div>
                        <span key={comment.id} className="post__caption__text__author baloa-normal-medium">
                            {comment?.author &&                                
                                comment?.author.type == "page"? `${comment?.author.username}`: `${comment?.author.first_name} ${comment?.author.last_name}`
                            }
                        </span>
                        <div className="post__caption__text__caption baloa-normal" dangerouslySetInnerHTML={{
                            __html: HASHTAG_FORMATTER( comment?.caption )
                        }}>
                        </div>
                    </div>
                )   
                }
            </div>

            {/* More comments */}
            { props.post.comment_count > 0 && 
                <div className="post__view-comments">
                    <div
                        onClick={() => props.handleOnCommentsClick(props.post)}
                    >
                        <TextButtonComponent
                            onClick={() => { }}
                            className="baloa-names"
                        >
                            <span>{props.t('postModal.btnText', {commentCount: props.post.comment_count})}</span>
                        </TextButtonComponent>
                    </div>
                </div>
            }

            </React.Fragment>  
            }
        </div>

    )
}

// Types
PostComponentV2.propTypes = {
    
    className: PropTypes.string,
    
    // Data
    post: PropTypes.shape(PostShape).isRequired,
    me_photo: PropTypes.string.isRequired,
    play_video: PropTypes.bool.isRequired,
    play_audio: PropTypes.bool.isRequired,

    // Video player
    handleOnPlayVideo: PropTypes.func.isRequired,
    handleOnMuteVideo: PropTypes.func.isRequired,
    
    // Profile/Page clicks
    /* handleOnProfileClick: PropTypes.func.isRequired,
    handleOnPageClick: PropTypes.func.isRequired, */
    
    // Claps
    handleClap: PropTypes.func.isRequired,
    onCloseClapsModal: PropTypes.func, // TODO: Bad name
    loadPostClapsById: PropTypes.func, // TODO: To be removed
    
    // Comments
    handleOnMessageIconClick: PropTypes.func.isRequired,
    handleOnCommentsClick: PropTypes.func.isRequired,
    handleOnComment: PropTypes.func.isRequired,
    
    // Abuse report
    openReportPostModal: PropTypes.func, // TODO: Bad name
    handleReportPost: PropTypes.func,

    // Block author
    openBlockPostModal: PropTypes.func, // TODO: Bad name
    handleBlockProfile: PropTypes.func,

    // Options
    handleOnEditPostClick: PropTypes.func, // TODO: To be implemented
    handleOnDeletePostClick: PropTypes.func,

    // Scroll
    onEnterViewport: PropTypes.func.isRequired,
    onExitViewport: PropTypes.func.isRequired,

    // Share
    handleOnCopyLinkClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        state
    }
} // TODO: To be removed

const mapDispatchToProps = dispatch => {
    return {
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) );
        },
        overwriteInterceptorAction: ( new_state ) => {
            return dispatch( overwriteInterceptorAction( new_state ) );
        },
    }
} // TODO: To be removed


export default compose(
    withTranslation("post"),
    connect(mapStateToProps, mapDispatchToProps)
)(PostComponentV2);

