// React
import React from 'react'

//Material
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Styles
import './TournamentPhaseTypeComponent.styles.css'

// Assets
import analysis_1_image from 'assets/icons/tournament-groups-type-new-green.svg';
import analysis_3_image from 'assets/icons/tournament-knockout-type-new-green.svg';
import analysis_4_image from 'assets/icons/tournament-league-type-new-green.svg';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

// i18n
import { withTranslation } from 'react-i18next';


function TournamentTypeComponent( props ) {

    const [phaseType, setPhaseType] = React.useState(props.phaseToEdit?.phase_type ?? '');
    const checkPhaseType = (event) =>{
        setPhaseType(event.target.value);
        props.onSelectTournamentType(event.target.value);
    }

    return (


        <div className="tournament-phase-type__container">
            <div className="tournament-phase-type__title baloa-subtitle-2">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseType2')}</div>
            <div className="tournament-phase-type__example baloa-names" dangerouslySetInnerHTML={{
                __html: props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseTypeExample')
            }}></div>
            <div className="tournament-phase-type__phase-type-subtitle baloa-names">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseTypeCreate')}</div>
            <FormControl component="fieldset" className={`tournament-phase-type__three-buttons ${props.edit_tournament ? '_disabled' :  ''}`}>                    
                <RadioGroup row aria-labelledby="phase_type" name="phase_type" defaultValue="top" className="tournament-phase-type__option-button" error={props.inscription_errors} disabled={props.disabled}>
                    <FormControlLabel
                        value="Free"
                        control={ phaseType === "Free"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                        label={
                            <div className={'tournament-phase-type__option-button-label'}>
                                <div className={'tournament-phase-type__option-button-label__text-title baloa-normal'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelLeagueType')}</div>
                                <div className={'tournament-phase-type__option-button-label__text'}>
                                    <img src={analysis_4_image} className="tournament-phase-type__option-button-label__photo" alt=""/>                                    
                                    <div className={'tournament-phase-type__option-button-label__caption baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelLeagueTypeDesc')}</div>
                                </div>
                            </div>
                        }
                        labelPlacement="start"
                        onChange={ checkPhaseType }
                        className={phaseType == "Free"? 'selected':''}
                        disabled = { !permissionsController( props.pageRoles, ['TOURNAMENTAD']) || props.edit_tournament }
                    />
                    <FormControlLabel
                        value="Knockout"
                        control={ phaseType === "Knockout"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                        label={
                            <div className={'tournament-phase-type__option-button-label'}>
                                <div className={'tournament-phase-type__option-button-label__text-title baloa-normal'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelKOType')}</div>
                                <div className={'tournament-phase-type__option-button-label__text'}>
                                    <img src={analysis_3_image} className="tournament-phase-type__option-button-label__photo" alt=""/>
                                    <div className={'tournament-phase-type__option-button-label__caption baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelKOTypeDesc')}</div>
                                </div>
                            </div>
                        }
                        labelPlacement="start"
                        onChange={ checkPhaseType }
                        className={phaseType == "Knockout"? 'selected':''}
                        disabled = { !permissionsController( props.pageRoles, ['TOURNAMENTAD']) || props.edit_tournament}
                    />
                    <FormControlLabel
                        value="Groups"
                        control={ phaseType === "Groups"? <Radio color="secondary" checked={true} />: <Radio color="secondary" required={true} />}
                        label={
                            <div className={'tournament-phase-type__option-button-label'}>
                                <div className={'tournament-phase-type__option-button-label__text-title baloa-normal'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelGroupType')}</div>
                                <div className={'tournament-phase-type__option-button-label__text'}>
                                    <img src={analysis_1_image} className="tournament-phase-type__option-button-label__photo" alt=""/>                                    
                                    <div className={'tournament-phase-type__option-button-label__caption baloa-table-column'}>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelGroupTypeDesc')}</div>
                                </div>
                            </div>
                        }
                        labelPlacement="start"
                        onChange={ checkPhaseType }
                        className={phaseType == "Groups"? 'selected':''}
                        disabled = { !permissionsController( props.pageRoles, ['TOURNAMENTAD']) || props.edit_tournament }
                    />    
                </RadioGroup>                        
            </FormControl>
        </div>
        
    )

}

export default withTranslation('league')(TournamentTypeComponent);