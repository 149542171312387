// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauth-view {
    height: 100%;
    margin-top: 72px;
}

/* Mobile web media queries */
@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .unauth-view {
        margin-top: 56px;
    }
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/general-unauthenticated/GeneralUnauthenticatedLayout.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA,6BAA6B;AAC7B,yCAAyC,4BAA4B;IACjE;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".unauth-view {\n    height: 100%;\n    margin-top: 72px;\n}\n\n/* Mobile web media queries */\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .unauth-view {\n        margin-top: 56px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
