// React
import React, { Component } from 'react';

//components
import HelpComponent from 'app/leagues/components/help/HelpComponent.jsx';

// Styles
import './HelpView.styles.css';

class HelpView extends Component {
    /* eslint-disable */
    constructor( props ) {
        super( props );
    }
    /* eslint-enable */

    componentDidMount() {
        //this.props.loadPage( this.pagename );        
    }
    
    render(){
        //console.log(this.props.state);
        return(
            <div className="league-help">
                <div className="league-help__left-container">
                </div>
                <div className="league-help__center-container">
                    <HelpComponent />                        
                </div>

            </div>
        )
    }

}

export default HelpView;
