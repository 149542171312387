// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-restriction-modal-container {
    padding: var(--space-and-half);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.responsive-restriction-modal__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--space-and-half);
}

.responsive-restriction-modal__icons span {
    font-size: var(--space-x3);
}

.responsive-restriction-modal__icons span:first-of-type {
    margin-right: var(--space-and-half);
}

.responsive-restriction-modal__icons span:last-of-type {
    margin-left: var(--space-and-half);
}

.responsive-restriction-modal-container .baloa-names {
    margin-bottom: var(--space-and-half);
    text-align: center;
}

.responsive-restriction-modal-button .button {
    width: 100%;
    grid-template-columns: unset;
    grid-template-areas: unset;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/responsive-restriction-modal/ResponsiveRestrictionModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".responsive-restriction-modal-container {\n    padding: var(--space-and-half);\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.responsive-restriction-modal__icons {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: var(--space-and-half);\n}\n\n.responsive-restriction-modal__icons span {\n    font-size: var(--space-x3);\n}\n\n.responsive-restriction-modal__icons span:first-of-type {\n    margin-right: var(--space-and-half);\n}\n\n.responsive-restriction-modal__icons span:last-of-type {\n    margin-left: var(--space-and-half);\n}\n\n.responsive-restriction-modal-container .baloa-names {\n    margin-bottom: var(--space-and-half);\n    text-align: center;\n}\n\n.responsive-restriction-modal-button .button {\n    width: 100%;\n    grid-template-columns: unset;\n    grid-template-areas: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
