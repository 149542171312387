// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.basic-info-form {}
.basic-info-form__form {
}
.basic-info-form__form__title {
    padding: 0 var(--space);
    padding-top: var(--space-and-half);
}
.basic-info-form__form__fields {
    padding: var(--space);
    margin-top: var(--space);
}
.basic-info-form__form__fields__field {
    padding: var(--space-half);
    display: flex;
    margin-bottom: var(--space-and-half);
    box-sizing: border-box;
    border: 1px solid transparent;
}
.basic-info-form__form__fields__field.active {
    border: 1px solid var(--secondary-color);
}
.basic-info-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.basic-info-form__form__fields__field__icon span{
    margin-right: var(--space);
    font-size: var(--space-and-half);
    color: var(--secondary-color);
}
.basic-info-form__form__fields__field:hover {
    cursor: pointer;
}
.basic-info-form__form__fields__field:hover span {
    color: var(--hover-pb-bg-color);
}
.basic-info-form__form__fields__field__description {
    align-items: flex-start !important;
}
.basic-info-form__form__fields__field__description span {
    font-size: var(--space-and-half);
}

.basic-info-form__form__input {}
.basic-info-form__form__submit {}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-basic-info-modal/ProfileBasicInfoModalComponent.styles.css"],"names":[],"mappings":";AACA,kBAAkB;AAClB;AACA;AACA;IACI,uBAAuB;IACvB,kCAAkC;AACtC;AACA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;AACA;IACI,0BAA0B;IAC1B,aAAa;IACb,oCAAoC;IACpC,sBAAsB;IACtB,6BAA6B;AACjC;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,0BAA0B;IAC1B,gCAAgC;IAChC,6BAA6B;AACjC;AACA;IACI,eAAe;AACnB;AACA;IACI,+BAA+B;AACnC;AACA;IACI,kCAAkC;AACtC;AACA;IACI,gCAAgC;AACpC;;AAEA,+BAA+B;AAC/B,gCAAgC","sourcesContent":["\n.basic-info-form {}\n.basic-info-form__form {\n}\n.basic-info-form__form__title {\n    padding: 0 var(--space);\n    padding-top: var(--space-and-half);\n}\n.basic-info-form__form__fields {\n    padding: var(--space);\n    margin-top: var(--space);\n}\n.basic-info-form__form__fields__field {\n    padding: var(--space-half);\n    display: flex;\n    margin-bottom: var(--space-and-half);\n    box-sizing: border-box;\n    border: 1px solid transparent;\n}\n.basic-info-form__form__fields__field.active {\n    border: 1px solid var(--secondary-color);\n}\n.basic-info-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n.basic-info-form__form__fields__field__icon span{\n    margin-right: var(--space);\n    font-size: var(--space-and-half);\n    color: var(--secondary-color);\n}\n.basic-info-form__form__fields__field:hover {\n    cursor: pointer;\n}\n.basic-info-form__form__fields__field:hover span {\n    color: var(--hover-pb-bg-color);\n}\n.basic-info-form__form__fields__field__description {\n    align-items: flex-start !important;\n}\n.basic-info-form__form__fields__field__description span {\n    font-size: var(--space-and-half);\n}\n\n.basic-info-form__form__input {}\n.basic-info-form__form__submit {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
