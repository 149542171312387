// React
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';
import React from 'react';
import { Switch } from 'react-router-dom';

// Components
import TournamentMenuComponent from 'shared/components/tournament-menu/TournamentMenuComponent';

function TournamentMenuLayout ({ routes }) {
    return(
        <React.Fragment>
            <TournamentMenuComponent/>
                <Switch>
                    {routes.map(route => (
                        <RouteWithSubRoutes {...route} key={route.key} />
                    ))}
                </Switch>
        </React.Fragment>
    );

}

export default TournamentMenuLayout;