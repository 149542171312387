// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";



export const CreatePageApi = ( page_creation_data ) => {

    return Axios.instance.post( `${ api_url }/pages/`, 
        page_creation_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetPageApi = ( username ) => {

    return Axios.instance.get( `${ api_url }/pages/${ username }/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}

export const CreateAttributeOnPageApi = ( page, new_attribute_data ) => {

    return Axios.instance.post( `${ api_url }/pages/${ page.pagename }/attributes/`, 
        { extra: new_attribute_data },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const EditAttributeOnPageApi = ( new_attribute_data ) => {

    return Axios.instance.patch( `${ api_url }/attributes/${ new_attribute_data.id }/`, 
        { extra: new_attribute_data },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );

}

export const FollowPageApi = ( pagename ) => {

    return Axios.instance.post( 
        `${ api_url }/pages/${ pagename }/follow/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const UnfollowPageApi = ( pagename ) => {

    return Axios.instance.delete( 
        `${ api_url }/pages/${ pagename }/unfollow/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetPagePostsApi = ( pagename ) => {

    return Axios.instance.get( 
        `${ api_url }/pages/${ pagename }/posts/?version=2`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}

export const GetPageFollowersApi = ( pagename ) => {

    return Axios.instance.get(
        `${api_url}/pages/${ pagename }/followers/`,{
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const AddMemberToPageApi = ( page_name, member_username ) => {

    return Axios.instance.post( `${ api_url }/pages/${ page_name }/members/`,
        { username: member_username },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const AddAdminToPageApi = ( page_name, member_username ) => {

    return Axios.instance.post( `${ api_url }/pages/${ page_name }/admins/`,
        { username: member_username },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetSearchMembersApi = ( searched_member ) => {

    return Axios.instance.get( `${ api_url }/members/?search=${ searched_member }`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetMembersApi = ( page_name ) => {

    return Axios.instance.get( `${ api_url }/pages/${page_name}/members/?pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const RemoveMembersApi = ( page_name, username ) => {

    return Axios.instance.delete( `${ api_url }/pages/${page_name}/members/${username}/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const RemoveAdminApi = ( page_name, username ) => {

    return Axios.instance.delete( `${ api_url }/pages/${page_name}/admins/${username}/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const AddPageNationalityApi = (page, new_profile_nationality) => {

    return Axios.instance.put( `${ api_url }/pages/${ page.pagename }/databasic/`, 
        { 
            "nationality": new_profile_nationality[0].id,
            "location": new_profile_nationality[1].id, 
            "extra": {                                      //TODO: Validar cuando este el nuevo API (Estos campos)
                "atype": null, 
                "value": null, 
                "atype1": "telephone-page", 
                "value1": "3105551741"
            }
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const BlockPageApi = ( pagename ) => {

    return Axios.instance.post( 
        `${ api_url }/pages/${ pagename }/block/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const SetStaffMemeberPermissionsApi = ( username, pagename, roles ) => {

    return Axios.instance.post(`${ api_url }/auth/user-page-permissions/${ username }/`,
        {
            username: username,
            pagename: pagename,
            roles: roles
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetTeamsToJoinApi = ( pagename ) => {

    return Axios.instance.get( `${ api_url }/pages/${pagename}/teams_to_join/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}