// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';

//Material
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

//Functions
import convertFieldPositionCode from 'shared/functions/ConvertFieldPositionCode';
import convertLateralityCode from 'shared/functions/ConvertLateralityCode';


// Styles
import './HomePlayersFilterModalComponent.styles.css';
import { withStyles } from "@mui/styles";

//i18
import { withTranslation } from 'react-i18next';

function HomePlayersFilterModalComponent ( props ){
    const StyledToggleButtonGroup = withStyles({
        root: {
            display: 'inline-block',
            '& .MuiToggleButtonGroup-grouped': {
                margin: '6px',
                border: '1px solid var(--new-gray-background-color)',
                
                borderRadius: 4,
                padding: '5px 10px',
            },
            '& .MuiToggleButton-root': {
                textTransform: 'none',
                padding: '5px 10px',
                '&.Mui-selected': {
                    border: '1px solid var(--new-green-color)',
                    background: '#E6F6EC',
                    color: 'var(--new-green-color)',
                },
            },
        },
    })(ToggleButtonGroup);
    //border-radius: 4px;
    //border: 0.5px solid var(--new-green, #30B36B);
    //background: #E6F6EC;

    const getSelectedValue=(array, value)=> {
        if (Array.isArray(array)) {
            array.some((fma) => fma === value)
        }
    }
    return(
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'home-players-filter-modal'}
            title={props.t('pages.advancedFilters')}
        >
            <div className={`home-players-filter-modal__container scroll-parent`}>
                <div className='home-players-filter-modal__filter-container scroll' >     
                    {/** Video */}
                    <div className='home-players-filter-modal__filter-param' >
                        <FormControlLabel
                            name={'video'}
                            value={'video'}
                            control={<Switch color="secondary" checked={ props.filterModalVideo } />}
                            label={<span className="baloa-names">
                                    {props.t('players.filter.videoLbl')}
                                </span>}
                            labelPlacement="start"
                            className={'home-players-filter-modal__switch'}
                            onChange={(e) => props.handleOnFilterModalVideo(e)}
                        />
                    </div>

                    {/** Nationality */}
                    <div className='home-players-filter-modal__filter-param' >
                        <InputFieldAutocompleteWithSearchComponent
                            options={ props.nationalities? props.nationalities : null}
                            noOptionsText={props.t('data.modal.noOptionsText')}
                            optionLabelField={ "name" }
                            optionSelectedField={ "id" }
                            lettercount={ "2" }
                            handleSearchWord ={ ( newCountryValue ) => props.handleSearchNationality( newCountryValue ) }
                            className="home-players-filter-modal__natiolocation"
                            label={<span className="baloa-names">{props.t('players.filter.nationalityTitle')}</span>}
                            placeholder={props.t('players.filter.nationalityPlaceholder')}
                            id="nationality"
                            field_name="nationality"
                            resetError={ () => {}}
                            error={{nationality: ''}}
                            value={props.defaultCountry}
                            country_logo={true}
                            disabled={false}
                            handleSetDefaultCountry={(value) => {props.setDefaultCountry(value)}}                            
                        />              
                    </div>
                    {/** Localtion */}
                    <div className='home-players-filter-modal__filter-param' >
                        <InputFieldAutocompleteWithSearchComponent
                            options={ props.cities? props.cities : null}
                            noOptionsText={props.t('data.modal.noOptionsText')}
                            optionLabelField={ "name" }
                            optionSelectedField={ "id" }
                            lettercount={ "2" }
                            handleSearchWord ={ ( city ) => props.searchProfileWorldCities( city ) }
                            className="home-players-filter-modal__natiolocation"
                            label={<span className="baloa-names">{props.t('profile:data.textLocation')}</span>}
                            placeholder={props.t('players.filter.locationPlaceholder')}
                            id="location"
                            field_name="location"
                            resetError={ () => {}}
                            error={{location: ''}}
                            value={props.defaultLocation}
                            country_logo={false}
                            disabled={false}
                            handleSetDefaultCountry={(value) => {props.setDefaultLocation(value)}} 
                            world_city={true}                         
                        />             
                    </div>
                    {/** Age */}
                    <div className='home-players-filter-modal__filter-param' >
                        <div className='baloa-names'>{props.t('profile:data.textAge')}</div>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={props.filterModalAge}
                            onChange={(e, arr) => props.handleOnFilterModalAge(e, arr)}
                            aria-label="age"
                            className={'home-players-filter-modal__checkbox'}
                        >
                            {props.yearsList.map(age => (                                
                                <ToggleButton 
                                    key={age.value}
                                    value={`${age.value}`}
                                    selected={ getSelectedValue(props.filterModalAge, age.value) }
                                    //onChange={(e, arr) => props.handleOnFilterModalAge(e, arr)}
                                >
                                    {<span className="baloa-names">
                                            {age.content}
                                        </span>}
                                </ToggleButton>
                            ))}                
                        </StyledToggleButtonGroup>
                    </div>
                    {/** Primary position */}
                    <div className='home-players-filter-modal__filter-param' >
                        <div className='baloa-names'>{props.t('profile:playerCard.position')}</div>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={props.filterModalPrimaryPos}
                            onChange={(e, arr) => props.handleOnFilterModalPrimaryPos(e, arr)}
                            aria-label="primaryposition"
                            className={'home-players-filter-modal__checkbox'}
                        >
                            {props.primaryPositionOptions?.map(primaryPos => (
                                <ToggleButton 
                                    key={primaryPos}
                                    value={primaryPos}
                                    selected={ getSelectedValue(props.filterModalPrimaryPos, primaryPos)}
                                    //onChange={(e, arr) => props.handleOnFilterModalPrimaryPos(e, arr)}
                                >
                                    {<span className="baloa-names">
                                        {convertFieldPositionCode(primaryPos)}
                                    </span>}
                                </ToggleButton>
                            ))}                
                        </StyledToggleButtonGroup>
                    </div>
                    {/** Secondary position */}
                    <div className='home-players-filter-modal__filter-param' >
                        <div className='baloa-names'>{props.t('profile:playerCard.altenativePosition')}</div>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={props.filterModalSecondaryPos}
                            onChange={(e, arr) => props.handleOnFilterModalSecondaryPos(e, arr)}
                            aria-label="secondaryposition"
                            className={'home-players-filter-modal__checkbox'}
                        >
                            {props.secondaryPositionOptions?.map(secondaryPos => (
                                <ToggleButton 
                                    key={secondaryPos}
                                    value={secondaryPos}
                                    selected={ getSelectedValue(props.filterModalSecondaryPos, secondaryPos)}
                                    //onChange={(e, arr) => props.handleOnFilterModalSecondaryPos(e, arr)}
                                >
                                    {<span className="baloa-names">
                                        {convertFieldPositionCode(secondaryPos)}
                                    </span>}
                                </ToggleButton>
                            ))}                
                        </StyledToggleButtonGroup>
                    </div>
                    {/** Laterality */}
                    <div className='home-players-filter-modal__filter-param' >
                        <div className='baloa-names'>{props.t('profile:playerCard.foot')}</div>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={props.filterModalLaterality}
                            onChange={(e, arr) => props.handleOnFilterModalLaterality(e, arr)}
                            aria-label="laterality"
                            className={'home-players-filter-modal__checkbox'}
                        >
                            {props.lateralityOptions?.map(laterality => (
                                <ToggleButton 
                                    key={laterality}
                                    value={laterality}
                                    selected={ getSelectedValue(props.filterModalLaterality, laterality) }
                                    //onChange={(e, arr) => props.handleOnFilterModalLaterality(e, arr)}
                                >
                                    {<span className="baloa-names">
                                        {convertLateralityCode(laterality)}
                                    </span>}
                                </ToggleButton>
                            ))}                
                        </StyledToggleButtonGroup>
                    </div>
                    {/** Stats -goals */}
                    <div className='home-players-filter-modal__filter-param' >
                        <div className='baloa-names'>{props.t('players.filter.goalsTitle')}</div>
                        <div className='home-players-filter-modal__option-buttons'>
                            <div className={`option-button baloa-table ${props.goalOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{props.setGoalOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                            <div className={`option-button baloa-table ${props.goalOption === 'this_year'? 'selected' : ''}`} onClick={()=>{props.setGoalOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                        </div>
                        <RadioGroup row
                            aria-labelledby="goalsstats"
                            name={"goalsstats"}
                            value={''}
                            className="home-players-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="10"
                                control={<Radio color="secondary" checked={props.filterGoalStats === '10'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.goal10Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterGoalStats === '10' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalGoalStats(e)}
                            />
                            <FormControlLabel
                                value="10|20"
                                control={<Radio color="secondary" checked={props.filterGoalStats === '10|20'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.goal10|20Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterGoalStats === '10|20' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalGoalStats(e)}
                            />
                            <FormControlLabel
                                value="21|30"
                                control={<Radio color="secondary" checked={props.filterGoalStats === '21|30'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.goal21|30Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterGoalStats === '21|30' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalGoalStats(e)}
                            />
                            <FormControlLabel
                                value="31|40"
                                control={<Radio color="secondary" checked={props.filterGoalStats === '31|40'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.goal31|40Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterGoalStats === '31|40' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalGoalStats(e)}
                            />
                            <FormControlLabel
                                value="40"
                                control={<Radio color="secondary" checked={props.filterGoalStats === '40'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.goal40Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterGoalStats === '40' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalGoalStats(e)}
                            />
                        </RadioGroup>

                    </div>

                    {/** Stats - Asisstances */}
                    <div className='home-players-filter-modal__filter-param' >                              
                        <div className='baloa-names'>{props.t('players.filter.assistancesTitle')}</div>
                        <div className='home-players-filter-modal__option-buttons'>
                            <div className={`option-button baloa-table ${props.assistanceOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{props.setAssistanceOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                            <div className={`option-button baloa-table ${props.assistanceOption === 'this_year'? 'selected' : ''}`} onClick={()=>{props.setAssistanceOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                        </div>
                        <RadioGroup row
                            aria-labelledby="assistancestats"
                            name={"assistancestats"}
                            value={''}
                            className="home-players-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="5"
                                control={<Radio color="secondary" checked={props.filterAssistances === '5'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.assistance5Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterAssistances === '5' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalAssistances(e)}
                            />
                            <FormControlLabel
                                value="5|10"
                                control={<Radio color="secondary" checked={props.filterAssistances === '5|10'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.assistance5|10Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterAssistances === '5|10' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalAssistances(e)}
                            />
                            <FormControlLabel
                                value="11|15"
                                control={<Radio color="secondary" checked={props.filterAssistances === '11|15'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.assistance11|15Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterAssistances === '11|15' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalAssistances(e)}
                            />
                            <FormControlLabel
                                value="16|20"
                                control={<Radio color="secondary" checked={props.filterAssistances === '16|20'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.assistance16|20Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterAssistances === '16|20' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalAssistances(e)}
                            />
                            <FormControlLabel
                                value="20"
                                control={<Radio color="secondary" checked={props.filterAssistances === '20'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.assistance20Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterAssistances === '20' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalAssistances(e)}
                            />
                        </RadioGroup>
                    </div>

                    {/** Stats - Played games */}
                    <div className='home-players-filter-modal__filter-param' >                                  
                        <div className='baloa-names'>{props.t('profile:tournamentSummary.labelMatchesSummary')}</div>
                        <div className='home-players-filter-modal__option-buttons'>
                            <div className={`option-button baloa-table ${props.playedGamesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{props.setPlayedGamesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                            <div className={`option-button baloa-table ${props.playedGamesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{props.setPlayedGamesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                        </div>
                        <RadioGroup row
                            aria-labelledby="playedgames"
                            name={"playedgames"}
                            value={''}
                            className="home-players-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="10"
                                control={<Radio color="secondary" checked={props.filterPlayedGames === '10'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedGames10Lb')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedGames === '10' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedGames(e)}
                            />
                            <FormControlLabel
                                value="10|25"
                                control={<Radio color="secondary" checked={props.filterPlayedGames === '10|25'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedGames10|25Lb')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedGames === '10|25' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedGames(e)}
                            />
                            <FormControlLabel
                                value="26|50"
                                control={<Radio color="secondary" checked={props.filterPlayedGames === '26|50'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedGames26|50Lb')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedGames === '26|50' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedGames(e)}
                            />
                            <FormControlLabel
                                value="51|100"
                                control={<Radio color="secondary" checked={props.filterPlayedGames === '51|100'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedGames51|100Lb')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedGames === '51|100' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedGames(e)}
                            />
                            <FormControlLabel
                                value="100"
                                control={<Radio color="secondary" checked={props.filterPlayedGames === '100'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedGames100Lb')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedGames === '100' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedGames(e)}
                            />
                        </RadioGroup>
                    </div>

                    {/** Stats - Starter games */}
                    <div className='home-players-filter-modal__filter-param' >                                 
                        <div className='baloa-names'>{props.t('players.filter.starterTitle')}</div>
                        <div className='home-players-filter-modal__option-buttons'>
                            <div className={`option-button baloa-table ${props.starterGamesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{props.setStarterGamesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                            <div className={`option-button baloa-table ${props.starterGamesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{props.setStarterGamesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                        </div>
                        <RadioGroup row
                            aria-labelledby="startedgames"
                            name={"startedgames"}
                            value={''}
                            className="home-players-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="5"
                                control={<Radio color="secondary" checked={props.filterStarterGames === '5'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.startedGames5Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterStarterGames === '5' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalStarterGames(e)}
                            />
                            <FormControlLabel
                                value="5|10"
                                control={<Radio color="secondary" checked={props.filterStarterGames === '5|10'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.startedGames5|10Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterStarterGames === '5|10' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalStarterGames(e)}
                            />
                            <FormControlLabel
                                value="11|20"
                                control={<Radio color="secondary" checked={props.filterStarterGames === '11|20'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.startedGames11|20Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterStarterGames === '11|20' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalStarterGames(e)}
                            />
                            <FormControlLabel
                                value="21|50"
                                control={<Radio color="secondary" checked={props.filterStarterGames === '21|50'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.startedGames21|50Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterStarterGames === '21|50' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalStarterGames(e)}
                            />
                            <FormControlLabel
                                value="50"
                                control={<Radio color="secondary" checked={props.filterStarterGames === '50'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.startedGames50Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterStarterGames === '50' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalStarterGames(e)}
                            />
                        </RadioGroup>
                    </div>

                    {/** Stats - played minutes */}
                    <div className='home-players-filter-modal__filter-param' >                                
                        <div className='baloa-names'>{props.t('profile:tournamentSummary.labelMinutes')}</div>
                        <div className='home-players-filter-modal__option-buttons'>
                            <div className={`option-button baloa-table ${props.playedMinutesOption === 'complete_history'? 'selected' : ''}`} onClick={()=>{props.setPlayedMinutesOption('complete_history')}}>{props.t('players.filter.completeHistory')}</div>
                            <div className={`option-button baloa-table ${props.playedMinutesOption === 'this_year'? 'selected' : ''}`} onClick={()=>{props.setPlayedMinutesOption('this_year')}}>{props.t('players.filter.thisYear')}</div>
                        </div>
                        <RadioGroup row
                            aria-labelledby="playedminutes"
                            name={"playedminutes"}
                            value={''}
                            className="home-players-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="500"
                                control={<Radio color="secondary" checked={props.filterPlayedMinutes === '500'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedMinutes500Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedMinutes === '500' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedMinutes(e)}
                            />
                            <FormControlLabel
                                value="500|1000"
                                control={<Radio color="secondary" checked={props.filterPlayedMinutes === '500|1000'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedMinutes500|1000Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedMinutes === '500|1000' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedMinutes(e)}
                            />
                            <FormControlLabel
                                value="1001|2000"
                                control={<Radio color="secondary" checked={props.filterPlayedMinutes === '1001|2000'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedMinutes1001|2000Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedMinutes === '1001|2000' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedMinutes(e)}
                            />
                            <FormControlLabel
                                value="2001|3000"
                                control={<Radio color="secondary" checked={props.filterPlayedMinutes === '2001|3000'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedMinutes2001|3000Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedMinutes === '2001|3000' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedMinutes(e)}
                            />
                            <FormControlLabel
                                value="3000"
                                control={<Radio color="secondary" checked={props.filterPlayedMinutes === '3000'} />}
                                label={<span className="baloa-names">
                                        {props.t('players.filter.playedMinutes3000Lbl')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPlayedMinutes === '3000' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterModalPlayedMinutes(e)}
                            />
                        </RadioGroup>
                    </div>     
                    
                    <div className={'home-players-filter-modal__mobile-submit-buttons'}>
                        <TextButtonComponent
                            onClick={props.resetFilter}
                        >
                            <span>{props.t('pages.cleanButtonFilterLbl')}</span>
                        </TextButtonComponent>
                        <PrimaryButtonComponent
                            onClick={props.handleModalFilter}
                        >
                            <span>{props.t('pages.applyButtonFilterLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation(['home','profile'])(HomePlayersFilterModalComponent);