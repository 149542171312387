// React
import React, { useState } from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueMatchChangesComponent from 'app/leagues/components/league-match-changes/LeagueMatchChangesComponent';
import TournamentEndMatchStepsComponent from '../tournament-end-match-steps/TournamentEndMatchStepsComponent';

import { ChangePlayersValidator } from 'shared/validators/ChangePlayersValidator';

// Styles
import './LeagueMatchChangesFormComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function LeagueMatchChangesFormComponent( props ) {
    /* eslint-disable */
    const [localPlayerIn, stLocalPlayerIn ]= React.useState([]);
    const [localPlayerOut, setLocalPlayerOut ]= React.useState([]);
    const [localMinuteChange, setLocalMinuteChange ]= React.useState([]);
    const [visitorPlayerIn, stVisitorPlayerIn ]= React.useState([]);
    const [visitorPlayerOut, setVisitorPlayerOut ]= React.useState([]);
    const [visitorMinuteChange, setVisitorMinuteChange ]= React.useState([]);
    const [ chanagesErrors, setChanagesErrors ] = React.useState( {errors: {
        local_player_in: ["","","","","","","","","",""], 
        local_player_out: ["","","","","","","","","",""], 
        visitor_player_in: ["","","","","","","","","",""], 
        visitor_player_out: ["","","","","","","","","",""] 
    }});
    /* eslint-enable */

    const [initialValues, setInitialValues] = useState({});
    const formRef = React.useRef();
    /* eslint-disable */
    React.useEffect( () => {
        if(props.endGameState.changes){
            let fval = setFormValues(props.endGameState.changes);
            setInitialValues(fval);
        }
    }, [ props.endGameState ] )
    /* eslint-enable */

    function setFormValues ( form_data ){
        if(form_data.local.length >0){
            /* eslint-disable */
            form_data.local.map((lchanges, lindex) =>{
                const blockedPreviosLocalChange = form_data.local?.filter(change => change !== null)?.length >=2 ? (form_data.local?.length-2) === lindex : false;
                if(lchanges.player_in != null){
                    const localPlayerFound = props.local_team_members.find(player => player.username === lchanges.player_in.username);
                    localPlayerIn[lindex]={
                        id: localPlayerFound.id ?? localPlayerFound.player_id,
                        is_assigned : localPlayerFound.is_assigned,
                        number: localPlayerFound.number,
                        photo: localPlayerFound.photo,
                        player_id: localPlayerFound.id ?? localPlayerFound.player_id,
                        player_name:localPlayerFound.player_name,
                        player_position: localPlayerFound.player_position,
                        rol_in_match:localPlayerFound.rol_in_match,
                        username: lchanges.player_in.username,
                        blockedPlayer: blockedPreviosLocalChange,
                    };
                }else{
                    localPlayerIn[lindex]= null;
                }
                if(lchanges.player_out != null){
                    const localPlayerOutFound = props.local_team_members.find(player => player.username === lchanges.player_out.username);
                    localPlayerOut[lindex]={
                        id: localPlayerOutFound.id ?? localPlayerOutFound.player_id,
                        is_assigned : localPlayerOutFound.is_assigned,
                        number: localPlayerOutFound.number,
                        photo: localPlayerOutFound.photo,
                        player_id: localPlayerOutFound.id ?? localPlayerOutFound.player_id,
                        player_name:localPlayerOutFound.player_name,
                        player_position: localPlayerOutFound.player_position,
                        rol_in_match:localPlayerOutFound.rol_in_match,
                        username: lchanges.player_out.username,
                        blockedPlayer: blockedPreviosLocalChange,
                    };
                }else{
                    localPlayerOut[lindex]= null;
                }                
                localMinuteChange[lindex]= lchanges.minute;
            })
            /* eslint-enable */
        }
        if(form_data.visitant.length >0){
            /* eslint-disable */
            form_data.visitant.map((vchanges, vindex) =>{
                const blockedPreviosVisitorChange = form_data.visitant?.filter(change => change !== null)?.length >=2 ? (form_data.visitant?.length-2) === vindex : false;
                if(vchanges.player_in != null){
                    const visitorPlayerFound = props.visitor_team_members.find(player => player.username === vchanges.player_in.username);
                    visitorPlayerIn[vindex] = {
                        id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                        is_assigned : visitorPlayerFound.is_assigned,
                        number: visitorPlayerFound.number,
                        photo: visitorPlayerFound.photo,
                        player_id: visitorPlayerFound.id ?? visitorPlayerFound.player_id,
                        player_name:visitorPlayerFound.player_name,
                        player_position: visitorPlayerFound.player_position,
                        rol_in_match:visitorPlayerFound.rol_in_match,
                        username: vchanges.player_in.username,
                        blockedPlayer: blockedPreviosVisitorChange,
                    };
                }else{
                    visitorPlayerIn[vindex]= null;
                }
                if(vchanges.player_out != null){
                    const visitorPlayerOutFound = props.visitor_team_members.find(player => player.username === vchanges.player_out.username);
                    visitorPlayerOut[vindex]={
                        id: visitorPlayerOutFound.id ?? visitorPlayerOutFound.player_id,
                        is_assigned : visitorPlayerOutFound.is_assigned,
                        number: visitorPlayerOutFound.number,
                        photo: visitorPlayerOutFound.photo,
                        player_id: visitorPlayerOutFound.id ?? visitorPlayerOutFound.player_id,
                        player_name:visitorPlayerOutFound.player_name,
                        player_position: visitorPlayerOutFound.player_position,
                        rol_in_match:visitorPlayerOutFound.rol_in_match,
                        username: vchanges.player_out.username,
                        blockedPlayer: blockedPreviosVisitorChange,
                    };
                }else{
                    visitorPlayerOut[vindex]= null;
                }
                visitorMinuteChange[vindex]= vchanges.minute;   
            })
            /* eslint-enable */                                
        }

        let formValues = {
            local_player_in: localPlayerIn,
            local_player_out: localPlayerOut,
            local_minute_change: localMinuteChange,
            visitor_player_in: visitorPlayerIn,
            visitor_player_out: visitorPlayerOut,
            visitor_minute_change: visitorMinuteChange
        }
        return formValues;
    }

    const { children, index, ...other } = props; // eslint-disable-line

    const changes = {
        local: [],
        visitant: [],
    }
    const changesfull = {
        local: [],
        visitant: [],
    }

    const goalsValidations = {
        local: [],
        visitant: [],
    }

    //Crear arreglos
    
    /* eslint-disable */
    const prepareData = (input_values) => {

        if(input_values.local_player_in){
            input_values.local_player_in.map((localplayerin, index) => {
                if(localplayerin && (localplayerin?.username != null && input_values.local_player_out[index]?.username !== null)){
                    changes.local.push({ 
                        player_in: localplayerin.username ?? '',
                        player_out: input_values.local_player_out[index].username ?? '', 
                        minute: (input_values.local_minute_change && input_values.local_minute_change[index])
                            ? input_values.local_minute_change[index] 
                            : null 
                    });
                    changesfull.local.push({ 
                        player_in: localplayerin, 
                        player_out: input_values.local_player_out[index], 
                        minute: (input_values.local_minute_change && input_values.local_minute_change[index])
                            ? input_values.local_minute_change[index] 
                            : null 
                    });
                }
                // else{
                //     changes.local.push({ 
                //         player_in: null, 
                //         player_out: null, 
                //         minute: null 
                //     });
                //     changesfull.local.push({ 
                //         player_in: null, 
                //         player_out: null, 
                //         minute: null 
                //     });
                // }
                
            })
        }
        if(input_values.visitor_player_in){
            input_values.visitor_player_in.map((visitorplayerin, index) => {
                if(visitorplayerin){
                    changes.visitant.push( { 
                        player_in: visitorplayerin.username ? visitorplayerin.username : '', 
                        player_out: input_values.visitor_player_out[index].username ? input_values.visitor_player_out[index].username : '', 
                        minute: (input_values.visitor_minute_change && input_values.visitor_minute_change[index]) ? input_values.visitor_minute_change[index] : null 
                    })
                    changesfull.visitant.push( { 
                        player_in: visitorplayerin, 
                        player_out: input_values.visitor_player_out[index], 
                        minute: (input_values.visitor_minute_change && input_values.visitor_minute_change[index])? input_values.visitor_minute_change[index] : null 
                    })
                }
                // else{
                //     changes.visitant.push({ player_in: null, player_out: null, minute: null  });
                //     changesfull.visitant.push({ player_in: null, player_out: null, minute: null  });
                // }
            })
        }
    }
    /* eslint-enable */

    const preChangeLocalPlayerStatus = () => {
        return props.local_team_members?.map((player) => ({
                ...player,
                id: player.player_id,
                state: 'standby', 
                blockedPlayer: false,
        }));
    };

    const preChangeVisitorPlayerStatus = () => {
        return props.visitor_team_members?.map((player) => ({
                ...player,
                id: player.player_id,
                state: 'standby', 
                blockedPlayer: false,
        }));
    };
    
    return (
        
        <FormComponent 
            //formInitialValues={ formikvalues.initialValues } 
            formInitialValues={ initialValues } 
            enableReinitialize= {true}
            onSubmit={( values, actions ) => {   
                
                // if(validateData(values)){
                    props.stepCompleted(4);
                    if(Object.keys(values).length !== 0){
                        prepareData(values);
                        props.onNewData( changes );
                        props.setChangesGameState( changesfull ); 
                    } 
                    props.setEndGameStepState(true);
                    
                // }else{
                //     props.stepCompleted(1);
                // }

                
            }}
            className="league-match-scorer-form__form form scroll-parent"
            autocomplete="off"
            innerRef={formRef}
        >
            <TournamentEndMatchStepsComponent
                step = { props.step }
                stepCompleted = {(i)=>props.stepCompleted(i)}
                endGameStepState = { props.endGameStepState }
            />
            <LeagueMatchChangesComponent
                local_score= { props.local_score }
                local_team = { props.local_team }
                local_team_members={ preChangeLocalPlayerStatus() }
                visitor_score= { props.visitor_score }
                visitor_team={ props.visitor_team }
                visitor_team_members={ preChangeVisitorPlayerStatus()}
                endGameState = { props.endGameState }
                chanagesErrors = { chanagesErrors }
                initialValues = { initialValues }
                goalsValidations = { goalsValidations }
                validationFunction={ ( player_in, player_out ) => ChangePlayersValidator( player_in, player_out ) }
                handleOnMatchChanges = { props.handleOnMatchChanges }
                formRef={formRef.current}
            />
            
            <div className="league-match-scorer-form__form__actions">
                <SecondaryButtonComponent 
                    disabled={false} 
                    onClick={()=>{props.stepCompleted(2);}} 
                    className="league-match-scorer-form__actions__back-btn"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonBack')}</span>
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    type="submit"
                    disabled={false} 
                    onClick={()=>{}} 
                    className="league-match-scorer-form__actions__submit"
                >
                    <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonContinue')}</span>
                </PrimaryButtonComponent>
            </div>
        </FormComponent>

    )
}

export default withTranslation('league')(LeagueMatchChangesFormComponent);