// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phases-created{}

.phases-created__phases .baloa-subtitle-2 {
    color: var( --basic-gray-color );
    margin: var( --space-half-quarter );
}

.phases-created__phases__content{
    display: flex;
    align-items: center;
}

.phases-created__phases__content__add {
    margin-right: var( --space-and-half );
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phases-created/TournamentPhasesCreatedComponent.styles.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,gCAAgC;IAChC,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".phases-created{}\n\n.phases-created__phases .baloa-subtitle-2 {\n    color: var( --basic-gray-color );\n    margin: var( --space-half-quarter );\n}\n\n.phases-created__phases__content{\n    display: flex;\n    align-items: center;\n}\n\n.phases-created__phases__content__add {\n    margin-right: var( --space-and-half );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
