/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';

import TextField from '@mui/material/TextField';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Styles
import './InputFieldAutocompleteWithValidationComponent.styles.css';
import { withStyles } from '@mui/styles';

//Assets
import baloa_user_icon from 'assets/icons/baloa-user.svg';
import team_shield_icon from 'assets/icons/team-shield.svg';

function InputFieldAutocompleteWithValidationComponent( props ) {

    const field_name =  props.field_name;

    const AutocompleteTextField = withStyles({
        // root: {
        //     '& label.Mui-focused': {
        //         color: '#2a302a',
        //     },          
        //     '& .MuiOutlinedInput-root': {
        //         '& fieldset': {             
        //             border: 'var(--input-field-border)',
        //         },      
        //     },
        // },
    })(TextField);

    // const local_value = props.value? props.options.find(opt => opt[props.optionSelectedField] == props.value[props.optionSelectedField]) : '';
    
    return(
            <Field name={ props.field_name } validate={ props.validationFunction }>
            
                { ( data ) => {
                        if ( props.error[ field_name ] !== "" ) {
                            data.form.errors[ field_name ] = props.error[ field_name ];
                        }

                        return(
                            <div className= { "input-field-validation " + ( props.className ? props.className : '' )  }>
                                <Autocomplete
                                    //key={ props.key }
                                    //name={ props.name }
                                    //component={Autocomplete}
                                    freeSolo={props.freeSolo ? props.freeSolo : false}
                                    options={props.options}
                                    noOptionsText= { props.noOptionsText }
                                    disablePortal={true}
                                    getOptionLabel={(option) => {
                                        if(props.optionLabelField == "players"){
                                            return (`${option.first_name} ${option.last_name} @${option.username}`)
                                        }else if(props.optionLabelField == "teams_tournament"){
                                            return (`${option.team}`)
                                        }else if(option[props.optionLabelField]){
                                            return (`${option[props.optionLabelField]}`)
                                        }else{
                                            return '';
                                        }
                                    }}

                                    isOptionEqualToValue={( option, value ) => {
                                        if(value){
                                            return option[props.optionSelectedField] === value[props.optionSelectedField] 
                                        }else{
                                            return null
                                        }
                                        
                                    }}
                                    getOptionKey={option => option[props.optionSelectedField] }
                                    //value={ local_value || "" }
                                    value={ props.value }
                                    defaultValue={props.defaultValue}
                                    onChange={(e, value) => {
                                        if(props.onChange ){
                                            props.onChange( value )
                                        }                    
                                        
                                        data.form.setFieldValue(props.field_name, value )
                                    }}
                                    renderOption={(comprops,option) => {
                                        //display value in Popper elements
                                        if(props.optionLabelField == "players"){
                                            return <ListItem
                                                        {...comprops}
                                                        key={option.id}
                                                        button
                                                        className="input-field-validation__list"
                                                        //selected={selectedIndex === option.id}
                                                        //onClick={(event) => handleListItemClick(event, item)}
                                                    >
                                                        <ListItemIcon className="input-field-validation__list__icon" >
                                                            <img className="input-field-validation__list__icon__img" src={ option.photo ? option.photo : baloa_user_icon} alt="" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={ option.first_name + ' ' + option.last_name} secondary={ '@'+option.username } className="input-field-autocomplete__listitem" />
                                                    </ListItem>
                                        }else if(props.optionLabelField == "teams_tournament"){
                                            return <ListItem
                                                        {...comprops}
                                                        key={option.id}
                                                        button
                                                        className="input-field-validation__list"
                                                        //selected={selectedIndex === option.id}
                                                        //onClick={(event) => handleListItemClick(event, item)}
                                                    >
                                                        <ListItemIcon className="input-field-validation__list__icon" >
                                                            <img className="input-field-validation__list__icon__img" src={ option.photo ? option.photo : team_shield_icon} alt="" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={ option.team } className="input-field-validation__listitem" />
                                                    </ListItem>
                                        }
                                            return <li className="input-field-validation__list">{option[props.optionLabelField]}</li>
                                        
                                    }}               
                                    renderInput={(params) => (
                                        <AutocompleteTextField
                                            {...params}
                                            // className={ props.className + " input-field-autocomplete"}
                                            className={ 
                                                'input-field-validation ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                            }
                                            label={ props.label }
                                            variant="outlined"
                                            margin="normal"
                                            placeholder={ props.placeholder }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={"input-field-validation__message " + (data.form.errors[ field_name ] && data.form.touched[ field_name ] ? 'error' : '' )}>
                                    { data.form.errors[ field_name ] ? data.form.errors[ field_name ] :  props.message }
                                </p>
                            </div>
                        )
                    }
                }
            </Field>
    )
}

export default InputFieldAutocompleteWithValidationComponent;
