// React
import React from 'react';

// Components
//import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';
import ScrollTrigger from 'react-scroll-trigger';


// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './SugestionComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function SugestionComponent ( props ) {  
    return (
        <div className="sugestion">
            <ScrollTrigger 
                onEnter={ props.onEnterViewport } 
                onExit={ props.onExitViewport }
                key={props.page.pagename}
                className="sugestion__scroll"
            >
            <div className="sugestion__content">
                {isMobile 
                ?
                <div className="sugestion__mobile-content">
                    <div onClick={() => {props.handleOnPageClick(props.page)}} className="sugestions__mobile-content__page-info">
                        {props.page.photo &&
                            <img src={ props.page.photo } alt=""/>
                        }
                        {!props.page.photo &&
                            <span className={"sugestion__mobile-content__attributes__photo icon-ball"} color={getRandonIconBallColor()}></span>
                        }
                        <div className="sugestion__mobile-content__title__text">
                            <div className="sugestion__mobile-content__title__text baloa-normal-medium">{ props.page.name }</div>
                            <div className='sugestion__mobile-content__title__text baloa-username'>@{ props.page.pagename }</div>
                        </div>
                    </div>
                    {(props.page.is_following || props.page_type === 'followed')?
                        <span className="hubicon-following_respon" onClick = {() => { props.handleUnFollow( props.page ) }} ><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        :   
                        <span className="hubicon-follow_respon" onClick = {() => { props.handleFollow( props.page ) }} ><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    }
                    
                </div>
                :
                <div className="sugestion__content__attributes">
                    <div className='sugestion__content__attributes__data' onClick={() => props.handleOnPageClick(props.page)}>                    
                        <div className={"sugestion__content__attributes__photo"}>
                            {props.page.photo?
                                <img src={props.page.photo} alt="" />
                                :
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span> 
                            }
                        </div>
                        <div className='sugestion__content__attributes__info'>
                            <div className="sugestion__content__title__text baloa-normal-medium">{ props.page.name }</div>
                            <div className='sugestion__content__title__text baloa-names'>@{ props.page.pagename }</div>
                        </div>
                    </div>
                    <div className='sugestion__content__footer_btn'>
                        {/* <RoundedButtonComponent
                            onClick = {() => { props.handleFollow( props.page ) }} 
                            className="small"
                        >
                            <span>{props.t('follower.request.button.follow')}</span>
                        </RoundedButtonComponent> */}
                        {(props.page.is_following || props.page_type === 'followed')?
                            <div 
                                className={ `sugestion__content__field_create following` }
                                onClick={() => {
                                    props.handleUnFollow( props.page )
                                }}
                            >                            
                                <div className="sugestion__content__field__data-create">
                                    <span className="sugestion__content__field__data__text_create baloa-names">                                
                                        { props.followingCaption }
                                    </span>
                                </div>
                            </div>
                        :
                            <div 
                                className={ `sugestion__content__field_create` }
                                onClick={() => {
                                    props.handleFollow( props.page )
                                }}
                            >                            
                                <div className="sugestion__content__field__data-create">
                                    <span className="sugestion__content__field__data__text_create baloa-names">                                
                                        { props.followCaption }
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                }
            </div>
            </ScrollTrigger>
        </div>
    )

}

export default withTranslation('page')(SugestionComponent);
