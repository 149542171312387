// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-dynamic-form-maker-modal .dynamic-form-maker__container {
    padding: 18px 46px;
}

.tournament-dynamic-form-maker-modal__buttons {
    padding: 16px 16px 12px 16px;
    border-top: 1px solid var(--new-gray-background-color);
}

.tournament-dynamic-form-maker-modal__buttons .primary-button {
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-dynamic-form-maker-modal/TournamentDynamicFormMakerModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,sDAAsD;AAC1D;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".tournament-dynamic-form-maker-modal .dynamic-form-maker__container {\n    padding: 18px 46px;\n}\n\n.tournament-dynamic-form-maker-modal__buttons {\n    padding: 16px 16px 12px 16px;\n    border-top: 1px solid var(--new-gray-background-color);\n}\n\n.tournament-dynamic-form-maker-modal__buttons .primary-button {\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
