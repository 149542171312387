
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';

// Validators
import { FirstNameValidator, LastNameValidator, DateValidator, UserNameValidator } from 'shared/validators/SignUpValidator';
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// Styles
import './CompleteUserInfoModalComponent.styles.css';

import help_icon from 'assets/icons/help-resume-icon.svg';

//i18
import { withTranslation } from 'react-i18next';

function CompleteUserInfoModalComponent(props) {

    const [uploadUserImg, setUploadUserImg] = React.useState(null);
    const [userImgUrl, setUserImgUrl] = React.useState(null);
    const [userImgB64, setUserImgB64] = React.useState(null);
    const [userImgBinary, setUserImgBinary] = React.useState(null);
    const [selectedProfileType, setSelectedProfileType] = React.useState(null);
    const [formErrors, setFormErrors ] = React.useState(null);
    const formRef = React.createRef();
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [setUploadProgress, uploadProgress] = React.useState(null); //eslint-disable-line
    const [fileInputRef, setFileInputRef] = React.useState(null);

    const role_options = [
        {
            icon: 'icon-fan',
            content: <div className='baloa-table'>{props.t('profile:data.modal.text4')}</div>,
            text: props.t('profile:data.modal.text3'),
            value: 'fanatic'
        },
        {
            icon: 'icon-player',
            content: <div className='baloa-table'>{props.t('profile:data.modal.text2')}</div>,
            text: props.t('profile:data.modal.text5'),
            value: 'player'
        },
        {
            icon: 'icon-coach',
            content: <div className='baloa-table'>{props.t('profile:data.modal.text6')}</div>,
            text: props.t('profile:data.modal.text7'),
            value: 'professional'
        }
    ]

    const formInitialValues = {
        first_name: props.user_first_name? props.user_first_name : '',
        last_name: props.user_last_name? props.user_last_name : '',
        birthday: props.user_birthday? props.user_birthday : '',
        username: props.user_username? props.user_username : '',
        profile_type: '',
        profession_type: '',
    }

    const setUserImage = (new_image, user_image_url, img_b64) => {
        setUserImgB64(img_b64);
        setUserImgUrl(user_image_url);
        setUserImgBinary(new_image);
    }

    const resetError = () => {
        const data = {
            'field': 'username',
            'error': ''
        }
        props.changeSignUpError(data);
    }

    const handleSelectedProfession = (value) => {
        if (value?.length > 0 && value != selectedProfileType) {
            setSelectedProfileType(value);
        }
    }

    const validateForm = () => {
        if (formRef.current.values.profile_type === "professional" && !formRef.current.values.profession_type){       
            formRef.current.setFieldError('profession_type', props.t('page:newPage.validators.requiredField'));
            formRef.current.setSubmitting(false);
            setFormErrors({profession_type: props.t('page:newPage.validators.requiredField')})
        }else if(props.pre_registered_user && !props.pre_registered_user_update && !formRef.current.values.claim_file){
            formRef.current.setFieldError('claim_file',props.t('page:newPage.validators.requiredField'));
            formRef.current.setSubmitting(false);
            setFormErrors({claim_file: props.t('page:newPage.validators.requiredField')})
        }else{
            formRef.current.validateForm();
        }
        
        if(formRef.current.isValid){

            if(props.pre_registered_user && !props.pre_registered_user_update){

                let formData = new FormData();
                formData.append(
                    "document_file_data",
                    selectedFile,
                    selectedFile.name
                );
                formData.append("username", formRef.current.values.username);
                formData.append("first_name",formRef.current.values.first_name);
                formData.append("last_name",formRef.current.values.last_name);
                formData.append("birthday",formRef.current.values.birthday);
                if(userImgBinary){
                    formData.append("photo", userImgBinary);
                }

                /* if(this.props.state.signup.phone){
                    formData.append("",this.props.state.signup.phone);
                } 
                if(this.props.state.signup.email){
                    formData.append("",this.props.state.signup.email);
                } */
                formData.append("profile_type",formRef.current.values.profile_type);
                if(formRef.current.values.profile_type === "professional" && formRef.current.values.profession_type ){
                    formData.append("profile_profession_type",formRef.current.values.profession_type.value);
                }
                formData.append("delete_document_file", false);
                props.handleNoRegisterUserDataAction(formData, onUploadProgress);

            }else{
                let user = {};
                user.username = formRef.current.values.username;
                user.first_name = formRef.current.values.first_name;
                user.last_name = formRef.current.values.last_name;
                user.birthday = formRef.current.values.birthday;
                user.photo = userImgB64 ? `data:image/jpeg;base64,${userImgB64}` : null;
                if (props.user_phone) {
                    user.phone = props.user_phone;
                }
                if (props.user_email) {
                    user.email = props.user_email;
                }
                user.profile_type = formRef.current.values.profile_type;
                if(formRef.current.values.profile_type === "professional" && formRef.current.values.profession_type ){
                    user.profile_profession_type = formRef.current.values.profession_type.value;
                }
                
                props.updateUserDataAction(user);
            }
        }

    }

    const validateProfessionType = (e) => {
        if(e){
            setFormErrors(null);
        }
    }

    const onFileChange =( event )=> {
        setSelectedFile( event.target.files[0] );
    };

    const onUploadProgress = (progressEvent) => {
        setUploadProgress( Math.round(progressEvent.loaded / progressEvent.total * 100) );
    }

    const clearFile = () => {
        setSelectedFile( null );
    }


    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('modal.completeUserInfoTitle')}
            className='complete-user-info__container scroll-parent'
        >
            <div className='complete-user-info__title baloa-table'>{props.t('modal.completeUserInfoText')}</div>
            <div className='complete-user-info__img-title baloa-table'>{props.t('info.uploadImgLabel')}</div>
            <div className='complete-user-info__upload-img__container'>
                <div className='complete-user-info__upload-img'>
                    {userImgUrl
                        ?
                        <img src={userImgUrl} alt='user-img' />
                        :
                        <span className="hubicon-default_pp"><span className="path1"></span><span className="path2"></span></span>
                    }
                </div>
                <div onClick={() => { setUploadUserImg(true) }} className='complete-user-info_profile-photo__container_plus baloa-subtitle-2'>+</div>
            </div>
            <div onClick={() => { setUploadUserImg(true) }} className="complete-user-info__upload-img__upload baloa-names">{props.t('info.uploadImgLabel')}</div>

            <FormComponent
                formInitialValues={formInitialValues}
                onSubmit={(values, actions) => {
                    validateForm();
                }}
                innerRef={formRef}
                className="scroll-parent"
            >
                <div className='complete-user-info__form scroll'>
                    <div className='complete-user-info__form__col'>
                        <InputFieldComponent
                            label={props.t('info.userNames')}
                            input_id=""
                            field_name="first_name"
                            validationFunction={FirstNameValidator}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={props.errors}
                            message=""
                            onChange={() => { }}
                            defaultValue={props.user_first_name? props.user_first_name :''}
                        />
                        <InputFieldComponent
                            label={props.t('info.userSurnames')}
                            input_id=""
                            field_name="last_name"
                            validationFunction={LastNameValidator}
                            disabled={false}
                            input_type="text"
                            resetError={() => { }}
                            error={props.errors}
                            message=""
                            onChange={() => { }}
                            defaultValue={props.user_last_name? props.user_last_name :''}
                        />

                    </div>
                    <div className='complete-user-info__form__col'>
                        <div className="complete-user-info__form__username">
                            <InputFieldComponent
                                label={props.t('username.inputLabel')}
                                input_id=""
                                field_name="username"
                                validationFunction={UserNameValidator}
                                disabled={false}
                                input_type="text"
                                resetError={() => { resetError() }}
                                error={props.errors}
                                message=""
                                onChange={() => { }}
                                placeholder="@"
                                defaultValue={props.user_username? props.user_username :''}
                            />
                            <div className="complete-user-info__form__username__example baloa-names">{props.t('username.tipDescription')}</div>
                            <div className="complete-user-info__form__username__exampleSubtitle baloa-names">{props.t('username.tipExample')}</div>
                        </div>
                        <InputFieldComponent
                            label={props.t('info.userBirthDay')}
                            input_id=""
                            field_name="birthday"
                            validationFunction={DateValidator}
                            disabled={false}
                            input_type="date"
                            resetError={() => { }}
                            error={props.errors}
                            message=""
                            placeholder=""
                            onChange={() => { }}
                            defaultValue={props.user_birthday? props.user_birthday :''}
                        />
                    </div>
                    <div className='complete-user-info__form__role_container'>
                        <div className='complete-user-info__form__profile-role'>
                            <div className='complete-user-info__form__profile-role_label_tooltip'>
                                <div className=' label_tooltip baloa-table'>{props.t('info.labelSelectedRol')}</div>
                                <div className="complete-user-info__form__input_grid-tooltip">
                                    <div className="complete-user-info_tooltip">
                                        <img src={help_icon} alt="" />
                                        <span className="complete-user-info_tooltiptext">
                                            <div className="complete-user-info_tooltiptext_grid_title">
                                                <p className="baloa-table">{props.t('info.labelTooltipText')}</p>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <SelectFieldCleanComponent
                                field_name="profile_type"
                                className="profile-role-selector__select-input"
                                disabled={false}
                                resetError={() => { }}
                                error={props.errors}
                                message=""
                                placeholder={props.t('info.labelPlaceholder2')}
                                options={role_options}
                                defaultValue={selectedProfileType}
                                onChange={(profession) => handleSelectedProfession(profession)}
                                validationFunction={RequiredValidator}
                            />
                        </div>
                        {selectedProfileType === 'professional'
                            ? <div className='complete-user-info__form__profile-profession-type'>
                                <InputFieldAutocompleteComponent
                                    name={'profession_type'}
                                    field_name="profession_type"
                                    options={props.profession_specialties}
                                    noOptionsText={props.t('info.labelNoFound')}
                                    optionLabelField={"content"}
                                    optionSelectedField={"value"}
                                    className="profile-profession-selector__select-input"
                                    label={props.t('info.labelProfession')}
                                    placeholder={props.t('info.labelPlaceholder')}
                                    error={props.errors}
                                    resetError={() => { }}
                                    disabled={selectedProfileType !== 'professional'}
                                    onChange={(e)=>{validateProfessionType(e)}}
                                />
                                <p className="input-field__message error">{ formErrors?.profession_type }</p>
                            </div>
                            : ''
                        }
                    </div>
                    {props.pre_registered_user && props.not_registered &&
                        <div className='signup-form-panel__form__claim-account-file'>
                            <div className='baloa-names'>{props.t('info.claimAccountFileTitle')}</div>
                            <div className='baloa-table'>{props.t('info.claimAccountFileMsg')}</div>
                            <div className='signup-form-panel__form__claim-account-file__button'>
                                <input
                                    name={'claim_file'}
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={(event) => {onFileChange(event)}}
                                    ref={file_input => setFileInputRef(file_input) }
                                    accept="application/pdf"
                                    value=""
                                />
                                <PrimaryButtonComponent
                                    disabled={false}
                                    onClick={() => {fileInputRef.click()}}
                                    className={'upload-file-button xs'}
                                    type="button"
                                >
                                    <span className='hubicon-upload icon' />
                                    <span>{props.t("info.claimAccountFileUploadBtnLbl")}</span>                                          
                                </PrimaryButtonComponent>
                                <div className='baloa-table-column'>{selectedFile?.name? selectedFile.name : props.t('info.claimAccountFileEmptyMsg')}</div>
                                {selectedFile && <span className='hubicon-clear_option' onClick={()=>{ clearFile() }}></span> }                                        
                            </div>
                            <p className="input-field__message error">{ formErrors?.claim_file? formErrors.claim_file : '' }</p>
                        </div>
                    }
                </div>

                <div className='complete-user-info__actions'>
                    <SecondaryButtonComponent onClick={() => { props.handleLogOut() }}>{props.t('modal.logOutBtn')}</SecondaryButtonComponent>
                    <PrimaryButtonComponent input_type='submit'>{props.t('modal.completeUserInfoTitle')}</PrimaryButtonComponent>
                </div>
            </FormComponent>
            <LeagueTeamUploadImageModalComponent
                isOpen={uploadUserImg}
                onClose={() => { setUploadUserImg(false) }}
                setTeamImage={(new_image, imageurl, img_b64) => { setUserImage(new_image, imageurl, img_b64) }}
                aspect={1}
                className='complete-user-info__upload-img__modal'
                cropImage={true}
            />
        </Modal2Component>
    )
}

export default withTranslation('signUp')(CompleteUserInfoModalComponent);
