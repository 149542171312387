// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tournament-start-info-modal .modal__container{
    padding: 16px 0 10px 0;
}

.tournament-start-info-modal__actions {
   display: flex;
   justify-content: flex-end;
   border-top: 1px solid var(--new-input-background-color);
   margin-right: 14px;
   padding-top: 8px;
}

.tournament-start-info-modal__actions .secondary-button {
    margin-right: 16px;
}

.tournament-start-info-modal__title{
    text-align: center;
}

.tournament-start-info-modal__text{
    padding: 40px 22px;
}

.tournament-start-info-modal__text.baloa-table{
    color: var(--primary-very-dark-color);
}

.tournament-start-info-modal__status-title.baloa-table{
    color: var(--primary-very-dark-color);
    font-weight: var(--font-medium);
}

.tournament-start-info-modal__status-text{
    width: 75%;
}

.tournament-start-info-modal__status-text.baloa-normal{
    color: var(--primary-very-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-start-info-modal/TournamentStartInfoModalComponent.styles.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;AAC1B;;AAEA;GACG,aAAa;GACb,yBAAyB;GACzB,uDAAuD;GACvD,kBAAkB;GAClB,gBAAgB;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;IACrC,+BAA+B;AACnC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":["\n.tournament-start-info-modal .modal__container{\n    padding: 16px 0 10px 0;\n}\n\n.tournament-start-info-modal__actions {\n   display: flex;\n   justify-content: flex-end;\n   border-top: 1px solid var(--new-input-background-color);\n   margin-right: 14px;\n   padding-top: 8px;\n}\n\n.tournament-start-info-modal__actions .secondary-button {\n    margin-right: 16px;\n}\n\n.tournament-start-info-modal__title{\n    text-align: center;\n}\n\n.tournament-start-info-modal__text{\n    padding: 40px 22px;\n}\n\n.tournament-start-info-modal__text.baloa-table{\n    color: var(--primary-very-dark-color);\n}\n\n.tournament-start-info-modal__status-title.baloa-table{\n    color: var(--primary-very-dark-color);\n    font-weight: var(--font-medium);\n}\n\n.tournament-start-info-modal__status-text{\n    width: 75%;\n}\n\n.tournament-start-info-modal__status-text.baloa-normal{\n    color: var(--primary-very-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
