// React
import React, { Component }from 'react';
import { connect } from 'react-redux';

// Components
import SignUpComponent from 'app/authentication/components/signup/SignUpComponent';

// Actions
import { changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Styles
import './LoginAccountView.styles.css';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';


class LoginAccountView extends Component {

    componentDidMount(){
        this.props.changeSignUpStatus(SIGNUP_STATUS['LOGIN'])
    }

    render = () => {
        return (
            <React.Fragment>
                <SignUpComponent 
                    signup_status={ this.props.state.signup.status }
                    location_pathname={ this.props.location.pathname} 
                />
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeSignUpStatus: (value) => {
            return dispatch(changeSignUpStatus(value))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginAccountView);
