// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentNewTournamentWarningModal.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentNewTournamentWarningModal( props ) {

    const handleOnButtonAction = (warning_type) => {
        if(warning_type === 'exceeded_amount' || warning_type === 'info_baloa_account') {
            props.onClose();
        } 
        if(warning_type === 'need_account' || warning_type === 'need_referee_account') {
            props.handleNewTournamentCreateOnBoardingAccount();
        }
    }

    return (
        <ModalComponent 
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="tournament-new-tournament-warning"
        >
            <div className="tournament-new-tournament-warning__container">  
                <span className='hubicon-info' />
                { props.warning_type === 'need_account' && <div className='baloa-normal-text'>{props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.needAccountText')}</div> }
                { props.warning_type === 'need_referee_account' && <div className='baloa-normal-text'>{props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.needRefereeAccountText')}</div> }
                { props.warning_type === 'exceeded_amount' && <div className='baloa-normal-text'>{props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.confingArbitrageText', {total_value: props.total_amount_arbitrage_config, currency: props.currency, exceeded_value: props.exceeded_amount_arbitrage_config})}</div> }
                { props.warning_type === 'info_baloa_account' && <div className='baloa-normal-text' dangerouslySetInnerHTML={{ __html: props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.infoBaloaAccount')}}></div> }
            </div>
            <div className='tournament-new-tournament-warning__btns-action' >
                { (props.warning_type === 'need_account' || props.warning_type === 'need_referee_account') && <SecondaryButtonComponent onClick={ () => {props.onClose()} }>{props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.closeBtn')}</SecondaryButtonComponent>}
                <PrimaryButtonComponent onClick={ () => { handleOnButtonAction(props.warning_type) } } >{ (props.warning_type === 'need_account' || props.warning_type === 'need_referee_account') ? props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.activatePaymentsBtn') : props.t('options.tournaments.pages.newTournament.tournamentWarningsModal.understoodBtn')}</PrimaryButtonComponent>
            </div>
        </ModalComponent>
  )
}

export default withTranslation('league')(TournamentNewTournamentWarningModal);
