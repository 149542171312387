// React
import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentEditSetGroupsComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TournamentEditSetGroupsComponent(props) {

    const [teamsToClassify, setTeamsToClassify] = React.useState(1);
    const [teamsGroup, setTeamsGroups] = React.useState();

    React.useEffect(() => {
        if(props.groups) {
            let selected_group = {}
            if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key'){
                selected_group =  props.groups.find(group => group.id === props.groups[props.groups.length - 1]?.id);
                if(selected_group) {
                    setTeamsToClassify(selected_group.classified_count);
                    setTeamsGroups(selected_group.teams);
                }
            }
            if(props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_key' || props.edit_phase_type === 'edit_free'){
                selected_group =  props.groups.find(group => (group.id === props.edit_phase_selected_group?.id ));
                if(selected_group) {
                    setTeamsToClassify(selected_group.classified_count);
                    setTeamsGroups(selected_group.teams);
                }
            }
        }

    }, [props.groups]); // eslint-disable-line

    const handleIncreaseDecreaseNumber = (action_type) => {
        if(action_type === 'increase') {
            let increase = teamsToClassify + 1;
            setTeamsToClassify(increase);
            if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key'){
                props.handleNumberClassified(props.groups[props.groups.length - 1].id, increase);
            } else {
                props.handleNumberClassified(props.edit_phase_selected_group.id, increase);
            } 
        }

        if(action_type === 'decrease' && teamsToClassify > 0) {
            let decrease = teamsToClassify - 1;
            setTeamsToClassify(decrease);
            if(props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key'){
                props.handleNumberClassified(props.groups[props.groups.length - 1].id, decrease);
            } else {
                props.handleNumberClassified(props.edit_phase_selected_group.id, decrease);
            } 
        }
    }

    return (
        <div className='tournament-edit-set-groups'>
            <div className='tournament-edit-set-groups__classify-teams'>
                { (props.edit_phase_selected_group && props.edit_phase_type === 'edit_group') &&
                    <div className='baloa-subtitle-1'>{props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${props.edit_phase_selected_group?.caption.split(" ")[0]}`,{_number: props.edit_phase_selected_group?.caption.split(" ")[1]})}</div>
                }
                { (props.edit_phase_type === 'create_group') &&
                    <div className='baloa-subtitle-1'>{props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${props.groups[props.groups.length - 1]?.caption.split(" ")[0]}`,{_number: props.groups[props.groups.length - 1]?.caption.split(" ")[1]})}</div>
                }
                { props.edit_phase_type === 'edit_free' &&
                    <div className='baloa-subtitle-1'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelFreeType')}</div>
                }
                { (props.edit_phase_type === 'edit_key') &&
                    <div className="baloa-subtitle-1">{props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${props.edit_phase_selected_group?.caption.split(" ")[0]}`, {phase_name: props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase_name}`), _number: props.edit_phase_selected_group?.caption.split(" ")[1] })}</div>
                }
                { (props.edit_phase_type === 'create_key') &&
                    <div className="baloa-subtitle-1">{props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelKnockOutNumber.${props.groups[props.groups.length - 1]?.caption.split(" ")[0]}`, {phase_name: props.t(`options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.tournament_active_phase_name}`), _number: props.groups[props.groups.length - 1]?.caption.split(" ")[1] })}</div>
                }
                { (props.edit_phase_type === 'create_group' || props.edit_phase_type === 'edit_group' || props.edit_phase_type === 'edit_free') &&
                    <div className='tournament-edit-set-groups__teams-to-classify'>
                        <div className='baloa-normal-medium'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tournamentEditPhaseModal.setGroupsLabel')}</div>
                        
                        <div className='tournament-edit-set-groups__classify-counter__container'>
                            <div onClick={() => {handleIncreaseDecreaseNumber('decrease')}} className='tournament-edit-set-groups__classify-counter baloa-normal-medium'>-</div>
                            <input type='number' min={'1' } value={teamsToClassify} />
                            <div onClick={() => {handleIncreaseDecreaseNumber('increase')}} className='tournament-edit-set-groups__classify-counter baloa-normal-medium'>+</div>
                        </div>                    
                    </div>
                }
            </div>
            <div className='tournament-edit-set-groups__team-list scroll-parent'>
                { teamsGroup?.length > 0 &&
                    <div className='tournament-edit-set-groups__team-list__container scroll'>
                        { teamsGroup?.map((team, index) => (
                            <div className='tournament-edit-set-groups__team-list__team' key={index}>
                                <div className='tournament-edit-set-groups__team-list__team-info'>
                                    <div className='tournament-edit-set-groups__team-list__team-info__img'>
                                        { team.photo 
                                            ?
                                            <img src={team.photo} alt={`${team.name}`}/>
                                            :
                                            <span className='icon-ball' color={getRandonIconBallColor()}/>
                                        }
                                    </div>
                                    <div className='baloa-normal-medium'>{team.name}</div>
                                </div>
                                <div className='tournament-edit-set-groups__team-list__team-action'>
                                    <span className='hubicon-cancel' onClick={() => {props.handleDeleteTeamFromGroup(team)}}/>
                                </div>
                            </div>
                          ))
                        }
                    </div>
                }
                { teamsGroup?.length === 0 && (props.edit_phase_type === 'create_group' || props.edit_phase_type === 'create_key') &&
                    <div className='tournament-edit-set-groups__team-list__empty'>
                        { props.edit_phase_type === 'create_group' && <div className='baloa-normal'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.noGroupsTeams')}</div> }
                        { props.edit_phase_type === 'create_key' && <div className='baloa-normal'>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.noKnockoutTeams')}</div> }
                    </div>
                }
            </div>
        </div>
    );
}

export default withTranslation('league')(TournamentEditSetGroupsComponent);