export const searchTeamsLeagueAction = ( data ) => {
    return { type: "LEAGUE:SEARCH_TEAMS", data }
}

export const searchTeamsLeagueWithValidationAction = ( data ) => {
    return { type: "LEAGUE:SEARCH_TEAMS_WITH_VALIDATION", data }
}

export const changeTeamInfoModalStateAction = ( new_state ) => {
    return { type: "LEAGUE:CHANGE_TEAM_INFO_MODAL_STATE", new_state }
}

export const searchMembersLeagueAction = ( data ) => {
    return { type: "LEAGUE:SEARCH_MEMBERS", data }
}

export const searchMembersLeagueWithValidationAction = ( data, actual_members ) => {
    return { type: "LEAGUE:SEARCH_MEMBERS_WITH_VALIDATION", data, actual_members }
}

export const changeLeaguesAddMemberToLeagueModalStateAction = ( new_state ) => {
    return { type: "LEAGUE:CHANGE_LEAGUES_ADD_MEMBER_TO_LEAGUE_MODAL_STATE", new_state }
}

export const addMemberToLeagueAction = ( data ) => {
    return { type: "LEAGUE:ADD_MEMBER_TO_LEAGUE", data }
}

export const loadLeagueMembersAction = ( data ) => {
    return { type: "LEAGUES:LOAD_LEAGUE_MEMBERS", data }
}

export const loadLeagueTeamsAction = ( data ) => {
    return { type: "LEAGUES:LOAD_LEAGUE_TEAMS", data }
}

export const addTeamToLeagueAction = ( data ) => {
    return { type: "LEAGUE:ADD_TEAM_TO_LEAGUE", data }
}

export const changeLeaguesAddTeamToLeagueModalStateAction = ( new_state ) => {
    return { type: "LEAGUE:CHANGE_LEAGUES_ADD_TEAM_TO_LEAGUE_MODAL_STATE", new_state }
}

export const changeTeamMembersModalStateAction = ( new_state ) => {
    return { type: "LEAGUE:CHANGE_TEAM_MEMBERS_MODAL_STATE", new_state }
}

export const loadActiveTeamAction = ( team_data) => {
    return { type: "LEAGUE:LOAD_ACTIVE_TEAM", team_data }
}
export const loadActiveMemberAction = ( member_data) => {
    return { type: "LEAGUE:LOAD_ACTIVE_MEMBER", member_data }
}

export const loadLeagueMemberDetailAction = ( new_state ) => {
    return { type: "LEAGUE:LOAD_LEAGUE_MEMBER_DETAIL", new_state }
}

export const seeTournamentFromMemberOrTeamModalAction = ( new_state ) => {
    return { type: "LEAGUE:SEE_TOURNAMENT_FROM_MEMBER_TEAM_MODAL", new_state }
}

export const changeLeagueTeamsLoadingAction = ( data ) => {
    return { type: "LEAGUE:CHANGE_LOADING_LEAGUE_TEAMS", data }
}

export const changeLeagueMembersLoadingAction = ( data ) => {
    return { type: "LEAGUE:CHANGE_LOADING_LEAGUE_MEMBERS", data }
}

export const changeNewLeaguePage = ( new_state ) => {
    return { type: "HOME:CHANGE_NEW_LEAGUE_PAGE", new_state }
}

export const updateActiveMemberRolesAction = ( data) => {
    return { type: "LEAGUE:UPDATE_ACTIVE_MEMBER_ROLES", data }
}