// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './AddPhaseTeamWarningModalComponent.styles.css';

function AddPhaseTeamWarningModalComponent ( props ){

    return(
        <ModalComponent             
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'add-phase-team-warning'}
        >
            <p className="add-phase-team-warning__title baloa-headline-5">                
                { props.modalTitle }
            </p>

            <div className="add-phase-team-warning__text baloa-body-2"
                dangerouslySetInnerHTML={{
                    __html: props.modalContent
                }}></div>
            
            <div className="add-phase-team-warning__actions"> 
                <TextButtonComponent 
                    className="add-phase-team-warning__button"
                    onClick={ props.onSecondaryButtonClick }
                >
                    <span>{ props.secondaryButtonLabel }</span>
                </TextButtonComponent>
                <SecondaryButtonComponent 
                    className="add-phase-team-warning__button"
                    onClick={ props.onPrincipalButtonClick }
                >
                    <span>{ props.principalButtonLabel }</span>
                </SecondaryButtonComponent>

            </div>
        </ModalComponent>
    )

}

export default (AddPhaseTeamWarningModalComponent);
