// React
import React from 'react';

// Components
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import LeagueSearchMemberListComponent from 'app/leagues/components/league-search-member-list/LeagueSearchMemberListComponent';

// Material UI
import Box from '@mui/material/Box';

// Styles
import './LeagueSearchMemberTeamComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function LeagueSearchMemberTeamComponent( props ) {

    const [isInputFocus, setIsInputFocus] = React.useState(true);
    const [isEmptySearch, setIsEmptySearch] = React.useState(true);
    const [cleanValue, setcleanValue] = React.useState(false);
    const [input_change, setInputChange] = React.useState("");
    const [wordSearch, setWordSearch]= React.useState('');

    React.useEffect(() => {
        if(input_change==props.id){
            document.getElementById(props.id)?.focus();
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ wordSearch]);

    React.useEffect(() => { 
        if(input_change!==""){ 
            document.getElementById(input_change)?.focus();
        }
    }, [input_change]);
    
    return (
            <div className="league-search-member-team-container">          
                <Box className="league-search-member-team-form__box">
                    <InputSearchComponent
                        className="league-search-member-team-search"
                        placeholder={props.modal_search_placeholder}
                        handleSearch={(v) => {           
                            setInputChange(props.id);
                            setWordSearch(v);
                            if(v !== '' && v.length > 3){
                                if(props.set_type ==='member'){
                                    props.handleSearchMember(v); 
                                }
                                if(props.set_type ==='team'){
                                    props.handleSearchMember({ id: props.tournament_id, teamName: v}); 
                                }
                                
                                setIsEmptySearch(false); 
                                setcleanValue(false); 
                            }else{
                                props.handleCleanMemberSearch();
                            }
                        }}
                        autoFocus = {isInputFocus}
                        cleanValue = {cleanValue}
                        name={props.name}
                        id={props.id}
                        removeIconSearch={props.removeIconSearch}
                        removeWordLenghtValidation={props.removeWordLenghtValidation}
                    
                    />

                    { props.profiles.length !== 0 &&
                        
                        <LeagueSearchMemberListComponent 
                            items={props.profiles}
                            onSelect = { (selected_profile) => {
                                setIsInputFocus(false);
                                props.onSelectMemberTeam(selected_profile);
                                props.handleCleanMemberSearch();
                                setcleanValue(true);
                                setIsEmptySearch(true);                        
                            } }
                            set_type ={props.set_type}
                        />                                   
                    }
                    { props.profiles.length === 0 && !isEmptySearch &&
                        <div className="league-search-member-team__box__without-results">                        
                            <p className="baloa-names">{props.t('newTeam.forms.players.searchEmptyMsg')}</p>                        
                        </div>
                    }
                </Box>    
            </div>
    )
}

export default withTranslation('team')(LeagueSearchMemberTeamComponent);