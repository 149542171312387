// React
import React, { useState } from 'react';
import EditInformationComponent from '../edit-information/EditInformationComponent';
// import TextButtonComponent from 'shared/components/text-button/TextButtonComponent'

// Validators
import { FirstNameValidator , UserNameValidator } from 'shared/validators/SignUpValidator';
import { PhoneValidator } from 'shared/validators/PhoneValidator';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './AccountInformationComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';


function TabEdit ( props ) {

    const { label, value } = props;

    return(

        <div className="tabEdit-content">

            {/* edit name */}
            { label === props.t('settings.info.name') && (
                <React.Fragment>
                    <EditInformationComponent
                        label={label} 
                        input_id="edit-first_name" 
                        field_name="first_name"
                        resetError={ props.onResetError }
                        error={ props.errors }
                        validationFunction={ FirstNameValidator }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }
                        value={value}
                    />
                </React.Fragment>
            )}

            {/* edit lastname */}
            { label === props.t('settings.info.lastName') && (
                <React.Fragment>
                    <EditInformationComponent
                        label={label} 
                        input_id="edit-last_name" 
                        field_name="last_name"
                        resetError={ props.onResetError }
                        error={ props.errors }
                        validationFunction={ FirstNameValidator }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }
                        value={value}
                    />
                </React.Fragment>
            )}

            {/* edit username */}
            { label === props.t('settings.info.username') && (
                <React.Fragment>
                    <EditInformationComponent
                        label={label} 
                        input_id="edit-username" 
                        field_name="username"
                        resetError={ (data) => props.onResetError( data )}
                        error={ props.errors }
                        validationFunction={ UserNameValidator }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }
                        value={value}
                    />
                </React.Fragment>
            )}

            {/* edit email */}
            { label === props.t('settings.info.email') && (
                <React.Fragment>
                    <EditInformationComponent
                        label={label} 
                        input_id="edit-email" 
                        field_name="email"
                        resetError={ props.onResetError }
                        error={ props.errors }
                        validationFunction={ FirstNameValidator }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }
                        value={value}
                    />
                </React.Fragment>
            )}

            {/* edit phone */}
            { label === props.t('settings.info.mobile') && (
                <React.Fragment>
                    <EditInformationComponent
                        label={label} 
                        input_id="edit-phone" 
                        field_name="phone"
                        resetError={ props.onResetError }
                        error={ props.errors }
                        validationFunction={ PhoneValidator }
                        index={ props.index }
                        data_edit={() => props.data_edit( props.index )}
                        onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data ) }
                        value={value}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

function AccountInformationComponent ( props ) {

    const [edit, setEdit] = useState(null);
    const [editValue, setEditValue] = useState(null);
   
    return (

        <div className="edit-content">
            
            { (props.value === props.index) && ( 
                <React.Fragment>
                    
                    <div className="edit-content__title baloa-subtitle-2">
                            { props.t('settings.info.title') }
                    </div>
                    {/* edit name */}
                    <div className="edit-content__info">
                        { !isMobile
                            ?
                            <React.Fragment>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.name')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.first_name }</span>
                            </React.Fragment>
                            :
                            <div className='edit-content__mobile-info'>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.name')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.first_name }</span>
                            </div>
                        }
                        
                        <div 
                            className="edit-content__button"
                            onClick={() => {
                                setEdit(props.t('settings.info.name'));
                                setEditValue(props.user.first_name);
                                props.data_edit(0);
                            }}
                        >
                            <span className="edit-content__button__text baloa-hyperlink-2">{props.t('settings.btnEdit')}</span>
                        </div>                           
                    </div>
                    {/* edit lastname */}
                    <div className="edit-content__info">
                        { !isMobile
                            ?
                            <React.Fragment>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.lastName')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.last_name }</span>
                            </React.Fragment>
                            :
                            <div className='edit-content__mobile-info'>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.lastName')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.last_name }</span>
                            </div>
                        }
                        
                        <div 
                            className="edit-content__button"
                            onClick={() => {
                                setEdit(props.t('settings.info.lastName'));
                                setEditValue(props.user.last_name);
                                props.data_edit(0);
                            }}
                        >
                            <span className="edit-content__button__text baloa-hyperlink-2">{props.t('settings.btnEdit')}</span>
                        </div>                           
                    </div>

                    {/* edit username */}
                    <div className="edit-content__info">
                        { !isMobile
                            ?
                            <React.Fragment>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.username')} </span>                                           
                                <span className="edit-content__text baloa-names">@{ props.user.username }</span>
                            </React.Fragment>
                            :
                            <div className='edit-content__mobile-info'>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.username')} </span>                                           
                                <span className="edit-content__text baloa-names">@{ props.user.username }</span>
                            </div>
                        }
                        
                        <div 
                            className="edit-content__button"
                            onClick={() => {
                                setEdit(props.t('settings.info.username'));
                                setEditValue(props.user.username);
                                props.data_edit(0);
                            }}
                        >
                            <span className="edit-content__button__text baloa-hyperlink-2">{props.t('settings.btnEdit')}</span>
                        </div>                           
                    </div>

                    {/* edit email */}
                    <div className="edit-content__info">
                        { !isMobile
                            ?
                            <React.Fragment>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.email')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.email ? props.user.email : props.t('settings.textAlert') }</span>
                            </React.Fragment>
                            :
                            <div className='edit-content__mobile-info'>
                                <span className="edit-content__info__content baloa-names email"> {props.t('settings.info.email')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.email ? props.user.email : props.t('settings.textAlert') }</span>
                            </div>

                        }
                        
                        <div 
                            className="edit-content__button"
                            onClick={() => {
                                setEdit(props.t('settings.info.email'));
                                setEditValue(props.user.email);
                                props.data_edit(0);
                            }}
                        >
                            <span className="edit-content__button__text baloa-hyperlink-2">
                                { props.user.email ? props.t('settings.btnEdit') : props.t('settings.btnComplete') }
                            </span>
                        </div>                           
                    </div>

                    {/* edit phone */}
                    <div className="edit-content__info">
                        { !isMobile
                            ?
                            <React.Fragment>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.mobile')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.phone ? props.user.phone : props.t('settings.textAlert') }</span>
                            </React.Fragment>
                            :
                            <div className='edit-content__mobile-info'>
                                <span className="edit-content__info__content baloa-names"> {props.t('settings.info.mobile')} </span>                                           
                                <span className="edit-content__text baloa-names">{ props.user.phone ? props.user.phone : props.t('settings.textAlert') }</span>
                            </div>
                        }
                        
                        <div 
                            className="edit-content__button"
                            onClick={() => {
                                setEdit(props.t('settings.info.mobile'));
                                setEditValue(props.user.phone);
                                props.data_edit(0);
                            }}
                        >
                            <span className="edit-content__button__text baloa-hyperlink-2">
                                { props.user.phone ? props.t('settings.btnEdit') : props.t('settings.btnComplete') }
                            </span>
                        </div>                           
                    </div>
                </React.Fragment>
            )} 
            
            { props.index === 0 && (
                <React.Fragment>
                    {/* edit element */}
                    {/* <div> */}
                        <TabEdit
                            label={edit}
                            index={ props.index }
                            data_edit={() => props.data_edit(props.index)}
                            errors = { props.errors }
                            onResetError={props.onResetError}
                            onNewData={ ( detail_data,new_data ) => props.onNewData( detail_data,new_data) }
                            value={editValue}
                            t={props.t}
                        />
                    {/* </div> */}
                </React.Fragment>
            )}
        </div>
        
    )
}

export default withTranslation("profile")(AccountInformationComponent);