import React from 'react';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextField from '@mui/material/TextField';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import "./TournamentInvitationInscriptionSetupComponent.styles.css";

//i18
import { withTranslation } from "react-i18next";

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import ModalCreateCarnetComponent from '../create-carnet/modal-create-carnet/ModalCreateCarnetComponent';

function TournamentInvitationInscriptionSetupComponent ( props ) {

    const [ allStates, setAllStates ] = React.useState(true);
    const [ registeredState, setRegisteredState ] = React.useState(false);
    const [ enrolledState, setEnrolledState ] = React.useState(false);
    const [ invitedState, setInvitedState ] = React.useState(false);
    const [ cancelEnrollment, setCancelEnrollment ] = React.useState(false);
    const [ indexEnrollment, setIndexEnrollment ] = React.useState(0);
    const [ tournamentSetupTeams, setTournamentSetupTeams ] = React.useState([]);
    const [ filterState, setFilterState ] = React.useState(undefined);
    const [ boxPosition, setBoxPosition ] = React.useState(false);
    const [ isBoxOpen, setBoxOpen ] = React.useState(false);
    const [ modalCreateCarnet, setModalCreateCarnet ] = React.useState(false);
    const [ manageCardMenu, setManageCardMenu ] = React.useState(false);

    React.useEffect(() => {
        setTournamentSetupTeams(props.tournament_teams);
    }, [props.tournament_teams]);
    
    /* function handleProgressBar(max_players, current_players) {
        let percent = 0;
        percent = (current_players*100)/max_players;
        return percent;
    } */

    const handleTeamState = (state) => {
        if(state === 'all-states') {
            setAllStates(true);
            setRegisteredState(false);
            setEnrolledState(false);
            setInvitedState(false);
            setFilterState(undefined);
        }
        if(state === 'registered') {
            setRegisteredState(true);
            setAllStates(false);
            setEnrolledState(false);
            setInvitedState(false);
            setFilterState('Register');
        }
        if(state === 'enrolled') {
            setEnrolledState(true);
            setAllStates(false);
            setRegisteredState(false);
            setInvitedState(false);
            setFilterState('Enrolled');
        }
        if(state === 'invited') {
            setInvitedState(true);
            setAllStates(false);
            setRegisteredState(false);
            setEnrolledState(false);
            setFilterState('Invited');
        }
    }


   const handleSearchTeams = (value) => {
        if(value.length < 2){
            setTournamentSetupTeams(props.tournament_teams);
        } else {
            let val = value.toLowerCase();
            let teams_array = props.tournament_teams;
            let searched_team = teams_array?.filter( (v) => v.team?.toLowerCase().includes(val) || v.team_name?.toLowerCase().includes(val) );
            setTournamentSetupTeams(searched_team);
        }
    }

    const handleRefuseTeamEnrollment = (team, state) => {
        props.handleInscriptionInvitationModalActions(team, state);
        setCancelEnrollment(false); 
    }

    const handleRefuseTeamEnrollmentBtn = (index) => {
        setCancelEnrollment(!cancelEnrollment);
        setIndexEnrollment(index); 
    }

    const handleBoxOpen = (index) => {
        if (!isBoxOpen){
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    }

    const handleOnModalCarnet = (tournament_id) => {
        setModalCreateCarnet(true);
        props.handleLoadformPlayer(tournament_id);
        props.GetCodeFormTemplate();
    }

    const handleOnManageCardOn = ()=> {
        setManageCardMenu(!manageCardMenu)
    }

    const handleOnEditCardId = (tournament_id) => {
        props.deleteCardIdTemplate();
        setModalCreateCarnet(true);
        props.handleLoadformPlayer(tournament_id);
        props.GetCodeFormTemplate();
    }

    const handleOnDeleteCardId = () => {
        props.handleOnDeleteCardIdTemplate();
    }

    return (
        <React.Fragment>

        <ModalCreateCarnetComponent
            onClick={() => {}} 
            isOpen={ modalCreateCarnet } 
            onClose={() => {setModalCreateCarnet(false)}}
            playerForm={props.tournament.player_form_template}
            sendCarnet = {props.sendCarnet}
            formID = {props.tournament.tournament_forms_ids_template}
        />
        
        <div className='tournament-invitation-inscription-setup__container'>
            <div className='tournament-invitation-inscription-setup__col enrolled-team'>
                <div className='tournament-invitation-inscription-setup__title'>
                    <div className='baloa-table-column '>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.teamsAppliancesInscriptionTitle')}</div>
                    <div className='tournament-invitation-inscription_options'>
                        {props.tournament.has_player_form? 
                            props.card_id_template?
                                <SecondaryButtonComponent
                                    onClick={()=>{handleOnManageCardOn()}}
                                    className={'tournament-create-carnet_button secondary-button'}>
                                    <span>
                                        {props.t('options.tournaments.cardIds.buttonOptions.manageBtnLbl')}                                
                                    </span>
                                </SecondaryButtonComponent> 
                                :
                                <SecondaryButtonComponent
                                    onClick={()=>{handleOnModalCarnet(props.tournament.id)}}
                                    className={'tournament-create-carnet_button secondary-button'}>
                                    <span>
                                        {props.t('options.tournaments.cardIds.buttonOptions.createBtnLbl')}                                
                                    </span>
                                </SecondaryButtonComponent> 
                            : ''
                        }
                        <div className={`tournament-invitation-inscription_options-box card-id ${manageCardMenu?  "active" : ""}`}>
                            <div className='tournament-invitation-inscription_options-box__card-id-option' 
                                onClick={()=> {
                                    props.handleOnDownloadCardIds();
                                    handleOnManageCardOn();
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.cardIds.buttonOptions.downloadBtnLbl')}</div>
                            </div>
                            <div className='tournament-invitation-inscription_options-box__card-id-option' 
                                onClick={()=> {
                                    handleOnEditCardId(props.tournament.id);
                                    handleOnManageCardOn();
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.cardIds.buttonOptions.editBtnLbl')}</div>
                            </div>
                            <div className='tournament-invitation-inscription_options-box__card-id-option' 
                                onClick={()=> {
                                    handleOnDeleteCardId();
                                    handleOnManageCardOn();
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.cardIds.buttonOptions.deleteBtnLbl')}</div>
                            </div>
                        </div>
                        
                        {props.tournament.state !== 'Finalized' &&
                            <SecondaryButtonComponent
                                onClick={()=>{handleBoxOpen(true)}}
                                className={'tournament-invited-teams-col_right_button secondary-button'}>
                                <span>
                                    {props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelAddTeams')}                                
                                </span>
                                <span className="hubicon-create_team">
                                    {/* <span className="path1"></span> */}
                                    <span className="path2"></span>
                                    <span className="path3"></span> 
                                </span>
                            </SecondaryButtonComponent>
                        }
                        <div className={`tournament-invitation-inscription_options-box ${ boxPosition ? "active" : ""}`} >
                            {!props.registration_only && 
                                <div className='tournament-invitation-inscription_options-box_cancel' 
                                    onClick={()=> {
                                        props.handleOnInviteTeamModal('invitation');
                                        handleBoxOpen(false);
                                    }}>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitleInviteTeam')}</div>
                                    <div className={"baloa-table"} >{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelSubtitleInvitationTeams')}</div>
                                </div>
                            }
                            <div className='tournament-invitation-inscription_options-box_transfer' 
                                onClick={()=> {
                                    props.handleOnInviteTeamModal('inscription');
                                    handleBoxOpen(false);
                                }}>
                                <div className='baloa-names'>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelInscriptionTeam')}</div>
                                <div className={"baloa-table"}>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelSubtitleInscription')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tournament-invitation-inscription-setup__teams-filter'>
                    <div className={`filter-label baloa-table-column ${ allStates? 'selected-state': '' }`} onClick={()=>{handleTeamState('all-states')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.allStatesLabel')}</div>
                    <div className={`filter-label baloa-table-column ${ registeredState? 'selected-state': '' }`} onClick={()=>{handleTeamState('registered')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.registeredLabel')}</div>
                    <div className={`filter-label baloa-table-column ${ enrolledState? 'selected-state': '' }`} onClick={()=>{handleTeamState('enrolled')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.enrolledLabel')}</div>
                    <div className={`filter-label baloa-table-column ${ invitedState? 'selected-state': '' }`} onClick={()=>{handleTeamState('invited')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.invitedLabel')}</div>
                </div>
                <div className='tournament-invitation-inscription-setup__search-and-buttons'>
                    <TextField 
                        onChange={(event) => handleSearchTeams(event.target.value)}
                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabTeams.searchPlaceholder')}
                        className='tournament-invitation-inscription-setup__search-input'
                        InputProps={{
                            startAdornment: (
                            <React.Fragment>
                                {<span class="icon-search"></span>}
                            </React.Fragment>
                            ),
                        }}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,                                    
                        }}                    
                    />
                    <div className='tournament-invitation-inscription-setup__download-buttons'>
                        {(props.tournament.has_player_form || props.tournament.has_team_form) &&
                            <TextButtonComponent
                                className="tournament-invitation-inscription-setup__download-buttons__button xs"
                                onClick={ ()=>{ props.handleOnDownloadForms( props.tournament_id) } }
                            >
                                <span className='hubicon-file_download'></span>
                                <span>{ props.t('options.tournaments.pages.newTournament.tabs.tabTeams.downloadFormDataLbl') }</span>
                            </TextButtonComponent>
                        }
                        {(props.tournament.tournament_registration_type === 'Player_paid' || props.tournament.tournament_registration_type === 'Team_paid' || props.tournament.tournament_registration_type === 'Paid') &&
                            <TextButtonComponent
                                className="tournament-invitation-inscription-setup__download-buttons__button xs"
                                onClick={ ()=>{ props.handleOnDownloadPayments( props.tournament_id ) } }
                            >
                                <span className='hubicon-file_download'></span>
                                <span>{ props.t('options.tournaments.pages.newTournament.tabs.tabTeams.downloadPaymentDataLbl') }</span>
                            </TextButtonComponent>
                        }                        
                    </div>
                </div>
                
                <div className='tournament-invitation-inscription-setup__filter-container'>
                    { tournamentSetupTeams.filter( filterState? t => t.team_state == filterState :  t => (t.team_state != null && t.team_state != 'Cancelled')).map((team, index) => (
                        <div key={index} className='tournament-invitation-inscription-setup__team-container'>
                            <div className='tournament-invitation-inscription-setup__col'>
                                { allStates && team.team_state !== 'Cancelled' &&
                                    <React.Fragment>
                                        <div className={`tournament-invitation-inscription-setup__team ${team.team_state === 'Register'? 'is-register': ''}`}>
                                            { team.team_logo
                                                ?
                                                <img alt="" src={team.team_logo} />
                                                :
                                                <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                            }
                                            <div className='tournament-invitation-inscription-setup__team-info'>
                                                <div className='baloa-table-column'>{team.team}</div>
                                                <div className='baloa-username'>{`@${team.teamname}`}</div>
                                            </div>
                                        </div>
                                        {team.team_state !== 'Cancelled' && team.team_state !== 'Refused' &&
                                            <div className='baloa-table-column enrolled-players'>{props.t('options.tournaments.setupTournamentInvitationInscription.participantPlayers', { inscribed_players: team.team_enrolled_players, min_players: props.tournament.min_participant_players, max_players: props.tournament.max_participant_players})}</div>
                                        }                                        
                                    </React.Fragment>
                                }
                                { registeredState && (team.team_state === 'Register' || team.team_state === 'Rejected') &&
                                    <div className={`tournament-invitation-inscription-setup__team ${team.team_state === 'Register'? 'is-register': ''}`}>
                                        { team.team_logo
                                            ?
                                            <img alt="" src={team.team_logo} />
                                            :
                                            <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                        }
                                        <div className='tournament-invitation-inscription-setup__team-info'>
                                            <div className='baloa-table-column'>{team.team}</div>
                                            <div className='baloa-username'>{`@${team.teamname}`}</div>
                                        </div>
                                    </div>
                                }
                                { enrolledState && (team.team_state === 'Enrolled' || team.team_state === 'Refused') &&
                                    <div className='tournament-invitation-inscription-setup__team'>
                                        { team.team_logo
                                            ?
                                            <img alt="" src={team.team_logo} />
                                            :
                                            <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                        }
                                        <div className='tournament-invitation-inscription-setup__team-info'>
                                            <div className='baloa-table-column'>{team.team}</div>
                                            <div className='baloa-username'>{`@${team.teamname}`}</div>
                                        </div>
                                    </div>
                                    
                                }
                                { /* (registeredState || allStates)  && team.team_state === 'Register' &&
                                    <div>
                                        <div className='tournament-invitation-inscription-setup__progress-bar'>
                                            <div class="progress">
                                                <div class="bar" style={{ width: `${handleProgressBar(props.tournament.min_participant_players, team.enrolled_players)}%`}} />
                                            </div>
                                        </div>
                                        <div className='baloa-table-column enrolled-players'>{props.t('options.tournaments.setupTournamentInvitationInscription.participantPlayers', { inscribed_players: team.enrolled_players, min_players: props.tournament.min_participant_players, max_players: props.tournament.max_participant_players})}</div>
                                    </div> */
                                }
                                { invitedState && 
                                    <div>
                                        { (team.team_state === 'Invited' || team.team_state === 'Expired') &&
                                            <div className='tournament-invitation-inscription-setup__team'>
                                                { team.team_logo
                                                    ?
                                                    <img alt="" src={team.team_logo} />
                                                    :
                                                    <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                                }
                                                <div className='tournament-invitation-inscription-setup__team-info'>
                                                    <div className='baloa-table-column'>{team.team}</div>
                                                    <div className='baloa-username'>{`@${team.teamname}`}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {/*  (allStates || registeredState) &&  */
                                (team.team_state === 'Register' || team.team_state === 'Rejected' || team.team_state === 'Unpaid') &&
                                    <div className='tournament-invitation-inscription-setup__col__registered-actions' >
                                        { team.team_state !== 'Rejected' && <div className='baloa-username register'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div>}
                                        { team.team_state === 'Rejected' && <div className='baloa-username rejected'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div>}
                                        { team.team_state !== 'Rejected' && props.tournament.state !== 'Finalized' &&
                                            /* <div className='tournament-invitation-inscription-setup__validate baloa-username' onClick={ () => props.handleOnValidateTeamInscriptionModal(team, '') } >
                                                {props.t('options.tournaments.setupTournamentInvitationInscription.validateBtn')}
                                            </div> */
                                            <PrimaryButtonComponent
                                                onClick={ () => props.handleOnValidateTeamInscriptionModal(team, '') } 
                                                className={'xs'}
                                            >
                                                {props.t('options.tournaments.setupTournamentInvitationInscription.validateBtn')}
                                            </PrimaryButtonComponent>
                                        }
                                        {props.tournament.state !== 'Finalized' && <span className={`icon-options ${team.team_state === 'Register' ? 'hide-options' : ' '}`} onClick={ () => handleRefuseTeamEnrollmentBtn(index) } ></span>}
                                    </div>
                      
                            }
                            { /* (allStates || enrolledState) &&   */               
                                (team.team_state === 'Enrolled' || team.team_state === 'Refused') &&
                                    <div className='tournament-invitation-inscription-setup__col__enrolled-actions' >
                                        { team.team_state === 'Enrolled' && (indexEnrollment !== index || !cancelEnrollment) && <div className='baloa-username enrolled'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div> }
                                        { team.team_state === 'Enrolled' && cancelEnrollment &&
                                        
                                            <div className={`cancel-enrollment ${ indexEnrollment !== index? 'hidden': ''}`} onClick={ () =>  handleRefuseTeamEnrollment(team, 'Refused') }>
                                                <span className='icon-x' />
                                                <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.cancelEnrollment')}</div>
                                            </div>
                                        }
                                        
                                        { team.team_state === 'Refused' && <div className='baloa-username refused'>{props.t(`options.tournaments.setupTournamentInvitationInscription.refusedEnrollment`)}</div> }
                                        { team.team_state === 'Enrolled' &&
                                            /* <div className='tournament-invitation-inscription-setup__view-info baloa-username' onClick={ () => props.handleOnValidateTeamInscriptionModal(team, 'Enrolled') } >
                                                {props.t('options.tournaments.setupTournamentInvitationInscription.infoBtn')}
                                            </div> */
                                            <PrimaryButtonComponent
                                                onClick={ () => props.handleOnValidateTeamInscriptionModal(team, 'Enrolled') } 
                                                className={'xs'}
                                            >
                                                {props.t('options.tournaments.setupTournamentInvitationInscription.infoBtn')}
                                            </PrimaryButtonComponent>
                                        }
                                        
                                        { team.team_state === 'Enrolled' /* && (indexEnrollment !== index || !cancelEnrollment) */ && props.tournament.state !== 'Finalized' && <span className='icon-options' onClick={ () => handleRefuseTeamEnrollmentBtn(index) } /> }
                                    </div>
                            }
                            { /* (allStates || invitedState) && */ 
                                (team.team_state === 'Invited' || team.team_state === 'Expired') && 
                                    <div className='tournament-invitation-inscription-setup__col__invited-actions' >
                                        { team.team_state === 'Invited' && <div className='baloa-username invited'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div> }
                                        { team.team_state === 'Expired' && props.tournament.state !== 'Registration_open' && <div className='baloa-username expired'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div> }
                                        { team.team_state === 'Invited' && props.tournament.state !== 'Finalized' &&
                                            <div className='tournament-invitation-inscription-setup__del-invitation' onClick={ () => props.handleInscriptionInvitationModalActions(team, 'Cancelled')} >
                                                <span className='icon-options' />
                                                <span className='baloa-table-column tournament-invitation-inscription-setup__invited-tooltip' >{props.t('options.tournaments.setupTournamentInvitationInscription.deleteInvitationTooltip')}</span>
                                            </div>
                                        }
                                    </div>

                            }
                            {/* (team.team_state === 'Cancelled') &&
                                <div className='tournament-invitation-inscription-setup__col__registered-actions' >
                                    { team.team_state === 'Cancelled' && <div className='baloa-username cancelled'>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${team.team_state}`)}</div> }                                        
                                    { team.team_state === 'Cancelled' &&
                                        <div className='tournament-invitation-inscription-setup__del-invitation' >
                                            <span className='icon-options' />
                                        </div>
                                    }
                                </div> */
                            }
                        </div>
                        ))
                    }
                </div>
                
                {/** No pending requests label */}
                { props.tournament.tournament_teams.length === 0 &&
                    <div className='tournament-invitation-inscription-setup__no-inscriptions__msg baloa-table-column'>{props.t('options.tournaments.pages.newTournament.tabs.tabInscription.noPendingAppliancesLabel')}</div>
                }
            </div>
        </div>
        </React.Fragment>
    )
}

export default withTranslation('league')(TournamentInvitationInscriptionSetupComponent);