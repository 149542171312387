/*eslint eqeqeq: "off"*/
import React from 'react';

//Language
import { withTranslation } from 'react-i18next'; // i18n

//Styles
import './SearchMenuComponent.styles.css'; // Styles

// React device detect
import { isMobile } from 'react-device-detect';

function SearchMenuComponent(props) {
    const [filter, setFilter] = React.useState(null);

    const handleSearchParam = (filter) => {
        
        switch (filter){
            
            case 'post':
                props.handleSearchFilter(`/search/search=${props.keyword}&filter=post`);
                break;
            case 'tournament':
                props.handleSearchFilter(`/search/search=${props.keyword}&filter=tournament`);
                break;
            case 'team':
                props.handleSearchFilter(`/search/search=${props.keyword}&filter=team`);
                break;
            case 'page':
                props.handleSearchFilter(`/search/search=${props.keyword}&filter=page`);
                break;
            case 'profile':
                props.handleSearchFilter(`/search/search=${props.keyword}&filter=profile`);
                    break;
            default:
                props.handleSearchFilter(`/search/search=${props.keyword}`);
                break;
        }
    }

    React.useEffect( () => {
        setFilter(props.filter);
  
  }, [ props.filter ] )

    return (
        <div className={`search-menu__container ${isMobile? 'mobile-container':''}`}>
            {!isMobile &&
                <div className='baloa-headline-2'>
                    {props.t('searchFilterTitle')}
                </div>
            }
            <div
                onClick={() => { handleSearchParam(null) }}
                className={`search-menu__button ${isMobile? 'mobile-button':''} ${filter ? '' : 'active'}`}
            >
                {!isMobile && <span className='hubicon-all icon'/>}
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('all')}</span>                
            </div>

            {/* <div
                onClick={() => { handleSearchParam('post') }}
                className={`search-menu__button ${isMobile? 'mobile-button':''} ${filter === 'post' ? 'active' : ''}`}
            >
                {!isMobile && <span className={`${filter === "post"? 'hubicon-post_fill' : 'hubicon-post'} icon`}/> }
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('post')}</span>
            </div> */}
           
            <div
                onClick={() => { handleSearchParam('tournament') }}
                className={`search-menu__button ${isMobile? 'mobile-button':''} ${filter === 'tournament' ? 'active' : ''}`}
            >
                {!isMobile && <span className={`${filter === "tournament"? 'hubicon-tournament_fill' : 'icon-trophy'} icon`}/> }
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('tournaments')}</span>
            </div>

            <div
                onClick={() => { handleSearchParam('team') }}
                className={`small search-menu__button ${isMobile? 'mobile-button':''} ${filter === 'team' ? 'active' : ''}`}
            >
                {!isMobile && <span className={`${filter === "team"? 'hubicon-team_fill' : 'icon-team'} icon`}/> }
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('teams')}</span>
            </div>

            <div
                onClick={() => { handleSearchParam('page') }}
                className={`small search-menu__button ${isMobile? 'mobile-button':''} ${filter === 'page' ? 'active' : ''}`}
            >
                {!isMobile && <span className="hubicon-pages_v2 icon" /> }
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('pages')}</span>
            </div>

            <div
                onClick={() => { handleSearchParam('profile') }}
                className={`small search-menu__button ${isMobile? 'mobile-button':''} ${filter === 'profile' ? 'active' : ''}`}
            >
                {!isMobile && <span className={`${filter === "profile"? 'hubicon-roles_fill' : 'hubicon-roles'} icon`}/> }
                <span className={`${isMobile? 'baloa-table' : 'baloa-normal-medium'}`}>{props.t('people')}</span>
            </div>

      </div>
    )
}

// Exports
export default withTranslation('search')(SearchMenuComponent);