// React
import React from 'react';

// Styles
import './StepsCreateCarnetComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function StepsCreateCarnetComponent(props){


    const { step, endCarnetState } = props;

    return(
        <div className="box">
            <div className="box__title">
            <div className={ "baloa-username box__title__text complete-text" } 
                    onClick={ endCarnetState ? ()=> { props.stepCompleted(0) } : undefined }>
                    {props.t("createCarnet.modal.titleStepOne")}
                </div>
                <div className={ `baloa-username ${(step === 1) ? "box__title__text complete-text" : "box__title__text"}` } 
                    onClick={ endCarnetState ?  ()=>{ props.stepCompleted(1) } : undefined }>
                    {props.t("createCarnet.modal.titleStepTwo")}
                </div>
            </div>
            <div className="box__steps">
            <div className={ "box__steps__point complete-point"} onClick={ ()=>{console.log("step-point: " + step)} }></div>
                <div className={ ( step === 1) ? "box__steps__line complete-text":"box__steps__line"}></div>
            {/* <div className={ (step === 1 ) ? "box__steps__line complete-text":"box__steps__line"}></div> */}
                <div className={ ( step === 0 ) ? "box__steps__point":"box__steps__point complete-point"}></div>
            </div>
        </div>
    )
    
}
export default withTranslation(["league", "team"])(StepsCreateCarnetComponent);