// React
import React, { Component } from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Routes
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes'

class Router extends Component {

  render() {

    const is_logged_in = this.props.state.signup.logged_in;
    
    return (
      <ReactRouter history = { this.props.history }>
        { is_logged_in ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes /> }
      </ReactRouter>
    );
  }

}

const mapStateToProps = state => {
  
  return {
    state
  }
}

export default connect(mapStateToProps)(Router);
