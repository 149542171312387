// React
import React,{useEffect} from 'react';
import CurrencyFormat from 'react-currency-format';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';
// Styles
import './LeagueMatchRefereeAssignmentModal.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueMatchRefereeAssignmentModal( props ) {

    const [selectedPerson, setSelectedPerson] = React.useState({
        official_referee: null,
        second_assistant_referee: null,
        third_assistant_referee: null,
        fourth_official_referee: null,
        match_assigner: null,
    });
    
    const formInitialValues = {
        official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.official_referee) ?? null,
        second_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Second_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.second_assistant_referee) ?? null,
        third_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Third_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.third_assistant_referee) ?? null,
        fourth_official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Fourth_Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.fourth_official_referee) ?? null,
        match_assigner: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Referee_Assigner') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.referee_assigner) ?? null,
    }

    useEffect( () => {
        if( props.match_data ) {
            setSelectedPerson({
                official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.official_referee) ?? null,
                second_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Second_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.second_assistant_referee) ?? null,
                third_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Third_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.third_assistant_referee) ?? null,
                fourth_official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Fourth_Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.fourth_official_referee) ?? null,
                match_assigner: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Referee_Assigner') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.referee_assigner) ?? null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match_data]);

    const handleClose = () => {
        setSelectedPerson({
            official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.official_referee) ?? null,
            second_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Second_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.second_assistant_referee) ?? null,
            third_assistant_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Third_Assistant_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.third_assistant_referee) ?? null,
            fourth_official_referee: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Fourth_Official_Referee') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.fourth_official_referee) ?? null,
            match_assigner: props.referee_payments? props.match_data?.referees?.find(referee => referee.referee_type === 'Referee_Assigner') ?? null : props.tournament_staff?.find(staff => staff.profile === props.match_data?.referee_assigner) ?? null,
        });
        props.onClose();
    };

    const handleSelectedStaff=(item, inputType)=>{
        setSelectedPerson((oldArray) => ({
            ...oldArray, [inputType]: item
        }));
    };


    const formRef = React.useRef();

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={ () => handleClose() }
            title={props.t('options.tournaments.pages.matchRefereesPayment.assignRefereeBtn')}
            className="small"
        >
            <div class="league-match-referee-assignment scroll-parent">
                <div className='league-match-referee-assignment__teams-payment-info'>
                    {/* <div className='baloa-hyperlink-2'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingTitle')}</div> */}
                    { props.referee_payments &&
                        <div className='league-match-referee-assignment__teams-payment-info__values'>
                            <div className='baloa-hyperlink-2'>{props.t('options.tournaments.pages.matchRefereesPayment.refereePayment')}</div>
                            <div className='league-match-referee-assignment__teams-payment-info__values__container'>
                                <div className='league-match-referee-assignment__team'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingLocalTeam')}</div>
                                    <CurrencyFormat
                                            value={props.match_data.refereeing_value_local_team} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className='baloa-username'>{value}</div>}
                                        />
                                </div>
                                <div className='league-match-referee-assignment__team'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingVisitantTeam')}</div>
                                    <CurrencyFormat
                                            value={props.match_data.refereeing_value_visitant_team} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className='baloa-username'>{value}</div>}
                                        />
                                </div>
                                <div className='league-match-referee-assignment__team'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingCollect')}</div>
                                    <CurrencyFormat
                                            value={(props.match_data.refereeing_value_local_team + props.match_data.refereeing_value_visitant_team)} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className='baloa-username'>{value}</div>}
                                        />
                                </div>

                            </div>
                        </div>
                    }
                </div>
                {props.referee_payments && 
                    <div className='league-match-referee-assignment__teams-payment-info baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingText')}</div>
                }
                {!props.referee_payments && 
                    <div className='league-match-referee-assignment__teams-payment-info baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingTextWithoutPayments')}</div>
                }
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => {
                        let referees_match_data = {                            
                            "match_date": props.match_data.match_date,
                            "field": props.match_data.field,
                            "match_time": props.match_data.match_time,
                            "official_referee": values.official_referee?.profile ? values.official_referee?.profile : values.official_referee?.referee_username? values.official_referee?.referee_username : null,
                            "second_assistant_referee": values.second_assistant_referee?.profile ? values.second_assistant_referee?.profile : values.second_assistant_referee?.referee_username? values.second_assistant_referee?.referee_username : null,
                            "third_assistant_referee": values.third_assistant_referee?.profile ? values.third_assistant_referee?.profile : values.third_assistant_referee?.referee_username? values.third_assistant_referee?.referee_username : null,
                            "fourth_official_referee": values.fourth_official_referee?.profile ? values.fourth_official_referee?.profile : values.fourth_official_referee?.referee_username? values.fourth_official_referee?.referee_username : null,
                            "referee_assigner": values.match_assigner?.profile ? values.match_assigner?.profile : values.match_assigner?.referee_username? values.match_assigner?.referee_username : null,
                        }
                        props.handleUpdateRefereesMatch(props.match_data.id, referees_match_data, props.match_data.date_id);
                    }}
                    className="scroll-parent"
                    functionDisabled={()=>{}}
                    innerRef={formRef}
                >
                    <div className="league-match-referee-assignment__container">
                        <div className='league-match-referee-assignment__referees'>
                            <div className='league-match-referee-assignment__referee-container'>
                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelOfficialReferee')}</div>
                                <div className={`league-match-referee-assignment__referee ${props.referee_payments? 'with-payment':''}`}>
                                    <InputFieldAutocompleteComponent
                                        name= { 'official_referee' }
                                        options={ props.tournament_staff }
                                        noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                        optionLabelField={ "staff" }
                                        optionSelectedField={ "id" }
                                        className="official_referee_input"
                                        label={''}
                                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                        onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'official_referee') }
                                        formRef={formRef}
                                        value={ selectedPerson?.official_referee}
                                        id="official_referee_autocomplete_new_match_modal" 
                                    />
                                    { props.referee_payments &&
                                        <CurrencyFormat
                                            value={props.match_data.tournament_official_referee_value} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className={`baloa-username ${selectedPerson.official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='league-match-referee-assignment__referee-container'>
                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelSecondAssistantReferee')}</div>
                                <div className={`league-match-referee-assignment__referee ${props.referee_payments? 'with-payment':''}`}>
                                    <InputFieldAutocompleteComponent
                                        name= { 'second_assistant_referee' }
                                        options={ props.tournament_staff }
                                        noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                        optionLabelField={ "staff" }
                                        optionSelectedField={ "id" }
                                        className="second_assistant_referee_input"
                                        label={''}
                                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                        onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'second_assistant_referee') }
                                        formRef={formRef}
                                        value={ selectedPerson?.second_assistant_referee}
                                        id="second_assistant_referee_autocomplete_new_match_modal" 
                                    />
                                    { props.referee_payments &&
                                        <CurrencyFormat
                                            value={props.match_data.tournament_second_assistant_referee_value} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className={`baloa-username ${selectedPerson?.second_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='league-match-referee-assignment__referee-container'>
                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelThirdAssistantReferee')}</div>
                                <div className={`league-match-referee-assignment__referee ${props.referee_payments? 'with-payment':''}`}>
                                    <InputFieldAutocompleteComponent
                                        name= { 'third_assistant_referee' }
                                        options={ props.tournament_staff }
                                        noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                        optionLabelField={ "staff" }
                                        optionSelectedField={ "id" }
                                        className="third_assistant_referee_input"
                                        label={''}
                                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                        onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'third_assistant_referee') }
                                        formRef={formRef}
                                        value={ selectedPerson?.third_assistant_referee}
                                        id="third_assistant_referee_autocomplete_new_match_modal" 
                                    />
                                    { props.referee_payments &&
                                        <CurrencyFormat
                                            value={props.match_data.tournament_third_assistant_referee_value} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className={`baloa-username ${selectedPerson?.third_assistant_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                        />
                                    }
                                </div>
                            </div>                            
                            <div className='league-match-referee-assignment__referee-container'>
                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelFourthOfficialReferee')}</div>
                                <div className={`league-match-referee-assignment__referee ${props.referee_payments? 'with-payment':''}`}>
                                    <InputFieldAutocompleteComponent
                                        name= { 'fourth_official_referee' }
                                        options={ props.tournament_staff }
                                        noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                        optionLabelField={ "staff" }
                                        optionSelectedField={ "id" }
                                        className="official_referee_input"
                                        label={''}
                                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                        formRef={formRef}
                                        value={ selectedPerson?.fourth_official_referee}
                                        onChange={ (selectedTeam) => handleSelectedStaff(selectedTeam, 'fourth_official_referee') }
                                        id="fourth_official_referee_autocomplete_new_match_modal" 
                                    />
                                    { props.referee_payments &&
                                        <CurrencyFormat
                                            value={props.match_data.tournament_fourth_official_referee_value} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className={`baloa-username ${selectedPerson?.fourth_official_referee !== null ? 'selected' : ''}`}>{value}</div>}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='league-match-referee-assignment__referee-container'>
                                <div className="baloa-username">{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelInputAssigner')}</div>
                                <div className={`league-match-referee-assignment__referee ${props.referee_payments? 'with-payment':''}`}>
                                    <InputFieldAutocompleteComponent
                                        name= { 'match_assigner' }
                                        options={ props.tournament_staff }
                                        noOptionsText={ props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt') }
                                        optionLabelField={ "staff" }
                                        optionSelectedField={ "id" }
                                        className="tournament-match_assigner_referee_input"
                                        label={''}
                                        placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalNewMatch.labelModalSearchPlaceholder')}
                                        onChange={ (selectedReferee) => handleSelectedStaff(selectedReferee, 'match_assigner') }
                                        formRef={formRef}
                                        value={ selectedPerson ? selectedPerson.match_assigner : null}
                                        id="match_assigner_referee_autocomplete_new_match_modal" 
                                    />
                                    { props.referee_payments &&
                                        <CurrencyFormat
                                            value={props.match_data.tournament_referee_assigner_value} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={' ' + props.tournament_currency}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className={`baloa-username ${selectedPerson.match_assigner !== null ? 'selected' : ''}`}>{value}</div>}
                                        />
                                    }
                                </div>
                            </div> 
                        </div>                       
                    </div>
                    <div className="league-match-referee-assignment__btns-action">  
                        <SecondaryButtonComponent>{props.t('options.tournaments.pages.matchRefereesPayment.cancelBtn')}</SecondaryButtonComponent>                     
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className=""
                        >
                            <span>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingSaveBtn')}</span>
                        </PrimaryButtonComponent>
                    </div>
                    
                </FormComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(LeagueMatchRefereeAssignmentModal);