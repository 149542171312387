// React
import React from 'react';


// Styles
import './LeagueSearchTeamListComponent.styles.css';

import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Assets
import team_shield_icon from 'assets/icons/team-shield.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
}));

function LeagueSearchTeamListComponent( props ) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState();
  
    const handleListItemClick = (event, item) => {
      setSelectedIndex(item.id);
      props.onSelect(item);
    };
  // console.log('team',props.items);
    return (
      <div className={classes.root + " league-search-team scroll"}>
        <List role="listbox">
          {props.items?.map((item) => (
              <ListItem
                  key={item.id}
                  button
                  selected={selectedIndex === item.id}
                  onClick={(event) => handleListItemClick(event, item)}
              >
                  <ListItemIcon className="league-search-team__icon" >
                      <img className="league-search-team__icon__icon__img" src={ item.team_logo ? item.team_logo : team_shield_icon} alt={`${item.pagename} shield`}/>                            
                  </ListItemIcon>
                  <ListItemText primary={ item.team } secondary={'@'+item.teamname} className="league-search-team__listitem league-search-team__team" />
                  {/* <ListItemText primary={ '@'+item.pagename } className="league-search-team__listitem league-search-team__username"/> */}
                  <ListItemText primary={ item.team_country ? `${item.team_country?.substring(0, 3)}, ${item.team_city}` : '' } 
                      className="league-search-team__listitem league-search-team__username"/>
              </ListItem>
            ))
          }
        </List>
      </div>
    );
  }

  export default LeagueSearchTeamListComponent;