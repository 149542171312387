// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Shapes
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';

// Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Styles
import './ProfileSuggestionsComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';


// ProfileSuggestions Component:
//   Renders Profile suggestions and handles their actions.

function ProfileSuggestionsComponent( props ) {

    return (
        <div 
        className = { 
            "profile-suggestions " + ( props.className ? props.className : '' ) 
        }
        >
            { props.loading_profile_suggestions &&
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                </React.Fragment>
            }
            {!props.loading_profile_suggestions && 
                /*!props.loading_profile_suggestions && props.profiles.map( profile => 
            
                <div 
                key = { profile.id }
                className = "profile-suggestions__suggestion"
                > 
                    <Link to={`/profile/${profile.user.username}/`} className="profile-suggestions__suggestion__profile-info">
                        <div className={ "profile-suggestions__suggestion__profile-info__photo " + ( profile.user.photo ? '' : 'no-photo' ) }>
                            <img src={ profile.user.photo ? profile.user.photo : baloa_user_icon } alt=""/>
                        </div>
                        <div className="profile-suggestions__suggestion__profile-info__data">
                            <div className="profile-suggestions__suggestion__profile-info__data__fullname">
                                { profile.user.first_name } { profile.user.last_name}
                            </div>
                            <div className="profile-suggestions__suggestion__profile-info__data__username baloa-truncate">
                                @{ profile.user.username }
                            </div>
                        </div>
                    </Link>
                    <div className="profile-suggestions__suggestion__follow-button">
                        <SecondaryButtonComponent
                            onClick = {() => { props.handleFollow( profile ) }} 
                            className="small"
                        >
                            <span>{props.t('follow')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div> */
                <div className="profile-suggestions__container">
                    <div className="profile-suggestions__title baloa-subtitle-2">{props.title}</div>   
                    <div className="profile-suggestions__list">
                        {props.profiles.map( profile => (
                                <div className="profile-suggestions__list__page-item">
                                    <Link to={`/profile/${profile.user.username}/`} className="profile-suggestions__suggestion__profile-info">
                                        {profile.user.photo &&
                                            <img src={ profile.user.photo } alt=""/>
                                        }
                                        {!profile.user.photo &&
                                            <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                        }                            
                                        <div className="profile-suggestions__list__page-item__data">
                                            <div className="profile-suggestions__list__page-item__data-name baloa-names baloa-truncate">{ profile.user.first_name } { profile.user.last_name}</div>
                                            <div className='profile-suggestions__list__page-item__data-username baloa-username baloa-truncate'>@{ profile.user.username }</div>
                                        </div>
                                    </Link>
                                    <div className="profile-suggestions__suggestion__follow-button">
                                        <RoundedButtonComponent
                                            onClick = {() => { props.handleFollow( profile ) }} 
                                            className="xs"
                                        >
                                            <span>{props.t('follow')}</span>
                                        </RoundedButtonComponent>
                                    </div>
                                </div>
                            ))    
                        }                                        
                    </div>
                    <div className="profile-suggestions__separator"></div>
                    <div className="profile-suggestions__menu-link baloa-hyperlink-2" onClick={props.handleGoLink}>{props.go_link}</div>
                </div>
             }
        </div>
    )
}

// Types
ProfileSuggestionsComponent.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape( ProfileShape )
    ),
    // onClick: PropTypes.func.isRequired,
}

// Exports
export default withTranslation('common')(ProfileSuggestionsComponent);
