//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentDeleteDateModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentDeleteDateModalComponent(props) {

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}  
            className='tournament-delete-date__container'   
        >
            <div className="baloa-normal-medium">                
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningDeleteDate.labelTitle')}
            </div>
            <div className="baloa-table">
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningDeleteDate.labelInfo')}                    
            </div>
            
            <div className="tournament-delete-date__actions">                 
                <SecondaryButtonComponent 
                    onClick={ props.onClose }
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningDeleteDate.labelBackAction')}
                </SecondaryButtonComponent>
                <PrimaryButtonComponent
                    onClick={ ()=>{
                        props.deleteDate(props.date, props.index, props.group_id);
                        props.onClose();
                    }}
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningDeleteDate.labelAction')}
                </PrimaryButtonComponent>                
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentDeleteDateModalComponent);