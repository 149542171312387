
// Notifications State
const notifications_state = {
    loading_notifications: false,
    notifications: [],
    unreaded_notifications_count: null,
    notifications_next_page: '',
    show_notifications: false,
}


export default function NotificationsReducer ( state = notifications_state, action ) { 
    switch( action.type ) {

        case "NOTIFICATIONS:CHANGE_MODAL_STATE": {
            return { ...state, show_notifications: action.new_state }
        }

        case "NOTIFICATIONS:LOAD_NOTIFICATIONS": {
           
            return { ...state, notifications: action.data.results, notifications_next_page: action.data.next, unreaded_notifications_count: action.data.count_unreaded }
        }

        case "NOTIFICATIONS:LOAD_NOTIFICATIONS_NEXT": {
            console.log('action', action.data );
            let new_notifications = state.posts.concat( action.data.results );
            return { ...state, notifications: new_notifications, notifications_next_page: action.data.next }
        }

        // no default

    }
    return state;
}

