import { SignJWT } from 'jose'
import { wompi_public_key } from "config/GlobalData";

export async function generateBaloaToken( phone ) {

    const secret = new TextEncoder().encode(
        wompi_public_key,
    )
    const alg = 'HS256'
    const baloa_token = await new SignJWT({ phone } )
    .setProtectedHeader({alg})
    .setExpirationTime('10s')
    .sign(secret);
    return baloa_token;
}