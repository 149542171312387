/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';


// Axios
//import axios from 'axios';

// Globals
//import { api_media_url } from "config/GlobalData";

// Actions
import { loadHomePosts } from 'app/home/actions/HomeActions';
import { changePostNewFormationModal, loadNewPost, changeNewHomePostAction } from 'app/home/actions/HomeActionsCreators';
import { changeHomeNewPostModal } from 'app/home/actions/HomeActionsCreators';
import { addUserFormation } from 'app/home/actions/HomeActionsCreators';
import { changeHomeEditPostModal } from 'app/home/actions/HomeActionsCreators';
import { uploadPost, editPostCaption } from 'app/posts/actions/PostActions';
import { changeNewGroupPagePost } from 'app/pages/actions/PageGroupsActionsCreators';
import { overwriteInterceptorAction } from 'app/profiles/actions/ProfileActionsCreators';


// Cropper
import Cropper from 'react-easy-crop'

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';
import ShowFormationFieldComponent from 'shared/components/show-formation/ShowFormationFieldComponent';

// add components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';



// Styles
import './CreatePostModalComponent.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';


// Utilities
import getCroppedImg from 'shared/functions/CropImage';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';


class CreatePostModalComponent extends React.Component {

  formInitialValues = {
    caption: "",
  }


  state = {
    post_image: null,
    selected_image: null,
    selected_image_url: null,
    image_input_ref: null,
    selected_video: null,
    selected_video_url: null,
    video_input_ref: null,
    upload_progress: 0,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    croppedAreaPixels: {},
    croppedImage: null,
    croppedImageUrl: null,
    caption: null,
    formation: null,
    is_video: false,
    play: false,
    audio: false,
    remove_action: false,
    anchorEl: null,
    selectedIndex: 0,
    options: [],
    poster_id: null,
    comment_rows: 10,
  };

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    this.setState({ croppedAreaPixels: croppedAreaPixels })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onImageChange = event => {
    try {
      if (event) {
        this.setState({ selected_image: event.target.files[0] });
        this.setState({ selected_image_url: URL.createObjectURL(event.target.files[0]) });
      }
    } catch (e) {
      console.error(e)
    }

  };

  onImageSelect = event => {
    // this.setState( { selected_image: event.target.files[0] } ); 
    // this.setState( { selected_image_url: URL.createObjectURL( event.target.files[0] ) } );
    try {
      this.setState({ selected_image: event.target.files[0] });
      this.setState({ selected_image_url: URL.createObjectURL(event.target.files[0]) });
    } catch (e) {
      console.error(e)
    }

  };

  onVideoChange = event => {
    try {
      this.setState({ selected_video: event.target.files[0] });
      this.setState({ selected_video_url: URL.createObjectURL(event.target.files[0]) });
    } catch (e) {
      console.error(e)
    }

  };

  onUploadProgress = (progressEvent) => {
    this.setState({ upload_progress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
  }

  setCaption = (data_from_child) => {
    if(this.state.caption !== data_from_child){
        this.setState({ caption: data_from_child } );
    }
  }

  onFileUpload = async () => {

      const formData = new FormData();

      /*if (this.state.selectedIndex > 0) {
        formData.append("page", this.state.poster_id);        
      } else {
        formData.append("profile", this.props.state.user.me.profile_id);
      }*/
      let baloa_page = null;
      if (this.state.selectedIndex > 0) {   //post like page
          this.props.overwriteInterceptorAction(true);
            baloa_page = this.state.poster_id;
      }else if( this.props.state.profile.page_active ){   //page post like profile
          this.props.overwriteInterceptorAction(true);
      }else{
          this.props.overwriteInterceptorAction(false);
      }

      formData.append("caption", this.state.caption ? this.state.caption : "");

      if(this.props.state.page?.page && this.props.state.page?.page?.page_type == "group" && !this.props.state.home.home_post){
          console.log("agrega contexto para pagina de grupos");
          formData.append("page_context", this.props.state.page?.page?.pagename);
      }

      if (this.state.selected_video) {

          formData.append(
              "post_video",
              this.state.selected_video,
              this.state.selected_video.name,
          );
          this.props.uploadPost(formData, this.onUploadProgress, true, baloa_page);
      }

      if (this.state.croppedImage) {
          const image = new File([this.state.croppedImage], 'post-image.jpg', {
            type: 'image/png'
          });
          formData.append(
            "post_image",
            image,
            this.state.croppedImage.name,
          );

          this.props.uploadPost(formData, this.onUploadProgress, true, baloa_page);
      }

      if (this.state.formation) {
        let obj = {formation: {
          players: this.props.state.home.formation.new_formation.players,
          lineup: this.props.state.home.formation.new_formation.lineup            
          },
          caption: this.state.caption ? this.state.caption : ""
        }
        this.props.uploadPost(obj, this.onUploadProgress, true, baloa_page);
        this.handleModalClose();
      }
      if (this.state.caption && !this.state.formation && !this.state.croppedImage && !this.state.selected_video) {
          this.props.uploadPost(formData, this.onUploadProgress, true, baloa_page);
          this.handleModalClose();
      }
     
  };

  onEditCaption = async () => {
    let post_id = this.props.state.home.post.id;
    let profile_id = this.state.selectedIndex > 0 ? null : this.props.state.user.me.profile_id;
    let poster_id = this.state.selectedIndex > 0 ? this.state.poster_id : null;
    let caption = this.state.caption ? this.state.caption : '';
    this.props.editPostCaption( post_id, profile_id, poster_id, caption );
    this.handleModalClose();
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.selected_image_url,
        this.state.croppedAreaPixels,
        0
      )
      this.setState({ croppedImage: croppedImage });
      this.setState({ croppedImageUrl: URL.createObjectURL(croppedImage) });
    } catch (e) {
      console.error(e)
    }
	this.setState({ aspect: 4/3 });
  }

  onRemoveImage = () => {
    this.setState({ croppedImage: null, croppedImageUrl: null, selected_image_url: null });
    this.onImageChange()
  }

  onRemoveFormation = () => {
    this.setState({ formation: null });
    this.props.addUserFormation(null);
  }

  setAnchorEl = (e) => {
    this.setState({ anchorEl: e });
  }

  setSelectedIndex = (e) => {
    this.setState({ selectedIndex: e });
  }

  setPosterId = (id) => {
    this.setState({ poster_id: id });
  }

  handleClickListItem = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleMenuItemClick = (event, index, id) => {
    this.setSelectedIndex(index);
    this.setAnchorEl(null);
    this.setPosterId(id);

  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  setOptions = (name, id, photo) => {
    this.setState({ options: [[name, id, photo]] });
    if (this.state.poster_id == null) {
      this.setPosterId(id);
    }
  };

  setOptionsPages = () => {
   let optArray = [];
   optArray.push([this.props.state.user.me.username, this.props.state.user.me.profile_id, this.props.state.user.me.photo]);
    if (this.props.state.profile.loggedin.pages) {
      this.props.state.profile.loggedin.pages.map((p) =>
        optArray.push([p.pagename, p.id, p.photo])
      );

      this.setState({options: optArray});
      if (this.props.state.profile.page_active != undefined) {
         var index = optArray.findIndex(element => element[0] === this.props.state.profile.page_active.pagename);
         this.setSelectedIndex(index);
         this.setPosterId(this.props.state.profile.page_active.id);
      }      
    }
  }

  handleModalClose = () =>{
      this.setState({ selected_image: null });
      this.setState({ selected_image_url: null });
      this.setState({ formation: null });
      this.setState({ selected_video: null });
      this.setState({ selected_video_url: null });
      this.onRemoveImage();
      this.onRemoveFormation();
      this.props.changeNewGroupPagePost(false);
      this.props.changeNewHomePostAction(false);

      this.props.onClose();
  }

  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen != this.props.isOpen && this.props.isOpen) {
       this.setOptionsPages();
    }        
}

  handleAspectRatio(position) {
    if(position === 'vertical') {
      this.setState({aspect: 3 / 4})
    }
    if(position === 'horizontal') {
      this.setState({aspect: 4 / 3})
    }
	if(position === 'square') {
		this.setState({aspect: 1 })
	  }  
  }

  render() {

    if (this.state.options.length == 0 && this.props.state.user.me.username) {
      this.setOptions( this.props.state.user.me.username, this.props.state.user.me.profile_id, this.props.state.user.me.photo);
    };

    if (this.props.state.profile.loggedin.pages?.length > 0 && this.state.options.length == 1) {
      this.setOptionsPages();
    };

    return (
      <Modal2Component
        isOpen={this.props.isOpen}
        onClose={
          () => {
            //if (this.props.state.home.post) {
              this.setState({ selected_image_url: null, selected_image: null, croppedImage: null, croppedImageUrl: null, formation: null });
            //}
            this.onRemoveImage();
            this.onRemoveFormation();
            this.props.changeHomeNewPostModal(null);
            this.props.changeHomeEditPostModal(false, null);
            this.props.changeNewGroupPagePost(false);
            this.props.changeNewHomePostAction(false);
            this.props.onClose();
          }
        }
        title={this.props.state.home.post ? this.props.t('postModal.titleEdit') : this.props.t('postModal.titleNew')}
        className="medium"
      >
        <div className={!this.props.state.home.post ? "new-post scroll-parent" : ''}>

        <div className="new-post__form">
          <FormComponent
            formInitialValues={this.formInitialValues}
            onSubmit={(values, actions) => {
            }}
            className="new-post__form__form"
            functionDisabled={() => { }}
          >
            





          {/* Poster selector */}
          {!this.props.state.pageGroups.new_group_page_post &&
          <div className="new-post__header">          
              <List component="nav" aria-label="Device settings">
                {this.state.options[this.state.selectedIndex] && (
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="Poster"
                    onClick={this.handleClickListItem}
                  >
                    <ListItemAvatar>
                        {this.state.options[this.state.selectedIndex][2] &&
                            <Avatar className="new-post__list-item__avatar" alt={this.state.options[this.state.selectedIndex][0]} src={this.state.options[this.state.selectedIndex][2]} />
                        }
                        {!this.state.options[this.state.selectedIndex][2] &&
                            <Avatar className="new-post__list-item__avatar" alt={this.state.options[this.state.selectedIndex][0]} ><span className={"new-post__list-item icon-ball"} color={getRandonIconBallColor()}></span></Avatar>
                        }
                      {/* <Avatar className="new-post__list-item__avatar" alt={this.state.options[this.state.selectedIndex][0]} src={this.state.options[this.state.selectedIndex][2] != null ? this.state.options[this.state.selectedIndex][2] : baloa_user_icon} /> */}
                    </ListItemAvatar>
                    <ListItemText className="new-post__list-item__text"  primary={'@' + this.state.options[this.state.selectedIndex][0]} />
                    <span className='icon-chevron-down' />
                  </ListItem>
                )}
              </List>
            
              <Menu
                id="lock-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}          
              >
                {this.state.options.map((option, index) => (
                  <MenuItem
                    key={option[1]}
                    //disabled={index === 0}
                    selected={index === this.state.selectedIndex}
                    onClick={(event) => this.handleMenuItemClick(event, index, option[1])}
                  >
                      {option[2] &&
                          <img className="post__header__photo" src={option[2]} alt="" />
                      }
                      {!option[2] &&
                          <span className={"post__header__photo icon-ball"} color={getRandonIconBallColor()}></span> 
                      }
                      {option[0]}                        
                  </MenuItem>
                ))}
              </Menu>
            
          </div>
          }
            {/* Comment input */}
            <InputFieldMultilineComponent
                onChange={this.setCaption}
                //onChange={this.setCaption.bind(this)}
                label=""
                input_id="new-post-caption"
                field_name="caption"
                validationFunction={RequiredValidator}
                disabled={false}
                className="new-post__form__field"
                input_type="text"
                resetError={() => { }}
                error={{}}
                message=""
                placeholder={!this.props.state.home.post ? this.props.t('postModal.inputAddComment') : this.props.t('postModal.inputEditComment')}
                rows={this.state.comment_rows}
                maxLength={999}
            />
            {/* Content */}
            {!this.props.state.home.post &&
              <div className="new-post__content">

                {/* Crop Image */}
                {/* { this.state.selected_image_url && !this.state.croppedImage && 
                              <div className="new-post__content__media"> */}

                {this.state.is_video ? this.state.selected_video_url && !this.state.croppedImage &&
                  <div className="new-post__content__media">

                    <video className="new-post__content__media__video" preload="metadata"
                      ref={el_ref => { this.video_ref = el_ref }}
                      src={this.state.selected_video_url+"#t=0.001"}>
                    </video>
                    <div className="new-post__content__media__video-control"
                      onClick={
                        () => {
                          if (this.video_ref.paused) {
                            this.video_ref.play();
                            this.setState({ play: true })
                            // console.log();                          
                          }
                          else {
                            this.video_ref.pause();
                            this.setState({ play: false })
                          }
                        }
                      }>
                      <span className={!this.state.play ? "new-post__content__media__video-control__icon" : ""} ></span>
                    </div>
                    <div className="new-post__content__media__control-mute"
                      onClick={() => {
                        if (this.video_ref.muted) {
                          this.video_ref.muted = false;
                          this.setState({ audio: false })
                        }
                        else {
                          this.video_ref.muted = true;
                          this.setState({ audio: true })
                        }
                      }}
                    >
                      <span className={this.state.audio ? "new-post__content__media__control-mute__icon icon-sound-off" : "new-post__content__media__control-mute__icon icon-sound-on"}></span>
                    </div>
                  </div>
                  : this.state.selected_image_url && !this.state.croppedImage &&
                  <div className="new-post__content__media">
                    <Cropper
                      image={this.state.selected_image_url}
                      crop={this.state.crop}
                      zoom={this.state.zoom}
                      aspect={this.state.aspect}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onZoomChange={this.onZoomChange}
                    />
                    <div className='create-post-modal__aspect-ratio' >
											<div className='baloa-table-column'>{this.props.t('postModal.aspectLabel')}</div>
											<div className={`create-post-modal__aspect-ratio__horizontal ${ this.state.aspect === 4/3? 'active': ''}`} onClick={() => this.handleAspectRatio('horizontal') } >4:3</div>
											<div className={`create-post-modal__aspect-ratio__square ${ this.state.aspect === 1/1? 'active': ''}`} onClick={() => this.handleAspectRatio('square') } >1:1</div>
											<div className={`create-post-modal__aspect-ratio__vertical ${ this.state.aspect === 3/4? 'active': ''}`} onClick={() => this.handleAspectRatio('vertical') } >3:4</div>
										</div>
                  </div>
              }

              {/* Display Image */}
              {this.state.croppedImage &&
                  <div className="new-post__content__result">
                      <div className="new-post__content__result__container">
                          <div className="new-post__content__result__container__remove">
                              {!this.props.state.home.post &&
                                  <SecondaryButtonComponent
                                      type="button"
                                      disabled={false}
                                      onClick={this.onRemoveImage}
                                      className="button secondary-button small"
                                  >
                                      <span>{this.props.t('postModal.btnRemove')}</span>
                                  </SecondaryButtonComponent>
                              }
                          </div>
                          <img src={this.state.croppedImageUrl} alt="" />
                      </div>
                  </div>
              }

              {/* Display Formation */}
              {this.state.formation &&
              this.props.state.home.formation &&
              this.props.state.home.formation.new_formation &&
              this.props.state.home.formation.new_formation !== undefined &&
                  <div className="new-post__content__result">
                      <div className="new-post__content__result__container__remove__revert">
                          <SecondaryButtonComponent
                              type="button"
                              disabled={this.state.croppedImage && !this.state.formation ? true : false}
                              onClick={this.onRemoveFormation}
                              className="button secondary-button small"
                          >
                              <span>{this.props.t('postModal.btnRemove')}</span>
                          </SecondaryButtonComponent>
                      </div>
                      <div className="post-formation__data">
                          <div className="create-post-modal__show-formation">
                              <ShowFormationFieldComponent
                                  display="form"
                                  position="secondary"
                                  lineUps={this.props.state.lineups.constants}
                                  new_formation={this.props.state.home.formation.new_formation}
                              />
                          </div>
                      </div>
                  </div>
              }
          






          

              {/* <div>
                            {this.state.selected_image?.name}
                        </div>
                        <div>
                            {this.state.selected_video?.type}
                        </div> */}

            </div>}

            { 
              (this.state.formation === false && this.state.upload_progress > 0) &&
                <div className="new-post__content__progress">
                  <progress max="100" value={this.state.upload_progress} className="new-post__content__progress__element"> {this.state.upload_progress} </progress>
                  {/* <p>{this.state.upload_progress}%</p> */}
                  {this.state.upload_progress === 100 ? (this.setState({ upload_progress: 0 }), this.handleModalClose()): null}
                </div>
            }

            <div className="new-post__form__group">
                {/* Post options buttons */}
                {!this.props.state.home.post &&
                    <div className="new-post__header">
                        <div
                            onClick={() => {
                                this.image_input_ref.click();
                                this.onRemoveFormation();
                                this.setState({ formation: false });
                                this.setState({ is_video: false });
                                this.setState({ comment_rows: 2 });
                            }}
                            className="new-post__header__button baloa-names"
                            disabled={this.state.formation || this.props.state.home.post ? true : false}
                        >
                            <span className={"icon-image-70"}></span><span>{this.props.t('postModal.titleImg')}</span>                            
                        </div>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.onImageChange}
                            onSelect={this.onImageSelect}
                            onImageSelect={this.onImageSelect}
                            ref={image_input => this.image_input_ref = image_input}
                            accept="image/*"
                        />
                        <div
                            onClick={() => {
                                this.video_input_ref.click();
                                this.setState({ formation: false });
                                this.setState({ is_video: true });
                                this.setState({ comment_rows: 2 });
                            }}
                            className="new-post__header__button baloa-names"
                            disabled={this.state.croppedImage || this.state.formation || this.props.state.home.post ? true : false}
                        >
                            <span className={"icon2-video-offer"}></span><span>{this.props.t('postModal.titleVideo')}</span>                            
                        </div>
                        <div
                            onClick={ ()=>{ 
                                this.props.changePostNewFormationModal( true );
                                this.props.changeHomeNewPostModal( false );
                                this.setState({ formation: true });
                                this.onRemoveImage();
                                this.setState({ comment_rows: 2 });
                            }}
                            className="new-post__header__button baloa-names" 
                            disabled={this.state.croppedImage || this.state.selected_image_url || this.props.state.home.post ? true : false } 
                        >                            
                            <span className={"icon-field"}></span><span>{this.props.t('postModal.titleFormation')}</span>
                        </div> 
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.onVideoChange}
                            ref={video_input => this.video_input_ref = video_input}
                            accept="video/*"
                        />
                    </div>
                }
                <div className="new-post__header__buttons">
                    {!this.props.state.home.post &&
                        <PrimaryButtonComponent
                            type="button"
                            disabled={(this.state.croppedImage || this.state.is_video || !this.state.selected_image_url) && !this.state.formation ? true : false}
                            onClick={this.showCroppedImage}
                            className="new-post__form__submit small"
                        >
                            <span>{this.props.t('postModal.btnCrop')}</span>
                        </PrimaryButtonComponent>
                    }

                    <PrimaryButtonComponent
                        type="submit"
                        disabled={(this.state.croppedImage || this.state.formation || this.state.is_video || (this.state.caption && !this.state.selected_image_url) ? false : true) && !this.props.state.home.post}
                        onClick={this.props.state.home.post ? this.onEditCaption : this.onFileUpload}
                        className="new-post__form__submit small"
                    >
                        <span>{this.props.t('postModal.btnPost')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
          </FormComponent>

        </div>            




        </div>

        
            

      </Modal2Component>
    )
  }
}

const mapStateToProps = state => {
  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadHomePosts: () => {
        return dispatch( loadHomePosts(undefined) )
    },
    loadNewPost: (data) => {
      return dispatch(loadNewPost(data))
    },
    changePostNewFormationModal: (new_modal_state) => {
      return dispatch(changePostNewFormationModal(new_modal_state));
    },
    changeHomeNewPostModal: (new_modal_state) => {
      return dispatch(changeHomeNewPostModal(new_modal_state));
    },
    changeHomeEditPostModal: (new_state, post) => {
      return dispatch(changeHomeEditPostModal(new_state, post));
    },
    addUserFormation: (formation) => {
      return dispatch(addUserFormation(formation));
    },
    uploadPost: (formData, configUpload, loadPost, baloa_page) => {
        return dispatch(uploadPost(formData, configUpload, loadPost, baloa_page));
    },
    editPostCaption: ( post_id, profile_id, poster_id, caption )=> {
      return dispatch( editPostCaption( post_id, profile_id, poster_id, caption ) );
    },
    changeNewGroupPagePost: ( new_state ) => {
      return dispatch( changeNewGroupPagePost( new_state ) );
    },
    overwriteInterceptorAction: ( new_state ) => {
      return dispatch( overwriteInterceptorAction( new_state ) );
    },
    changeNewHomePostAction: ( new_state ) => {
      return dispatch( changeNewHomePostAction( new_state ) );
    },
    
  }
}

export default compose(
  withTranslation("post"),
  connect(mapStateToProps, mapDispatchToProps)
)(CreatePostModalComponent);



//export default connect(mapStateToProps, mapDispatchToProps)(CreatePostModalComponent);