// React
import React from 'react';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './LeagueTournamentStaffMemberComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueTournamentStaffMemberComponent (props) {

    return(
        <div className='league-tournament-staff-member'>
            <div className='staff-member__container'>
                <div className='staff-member__card'>
                    { props.active_member.photo !== null && 
                        <img src={props.active_member.photo} alt={`${props.active_member.username} img`}/>
                    }
                    { props.active_member.photo === null && 
                        <span className='icon-user'/>
                    }
                    <div className='staff-member__card__info'>
                        <h4 className='baloa-headline-2'>{`${props.active_member.first_name} ${props.active_member.last_name}`}</h4>
                        <p className='baloa-normal-text'>{`@ ${props.active_member.username}`}</p>
                        <p className='baloa-normal-text'>{props.active_member.nationality}</p>
                        <p className='baloa-normal-text'>{props.active_member.location}</p>                    
                    </div>
                </div>
                <div className={'staff-member__btns-actions'}>
                    {/* <PrimaryButtonComponent >ENVIAR MENSAJE</PrimaryButtonComponent> */}
                    <div className={'baloa-hyperlink'} onClick={ props.handleOnMemberProfileClick } >{ props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailViewProfileBtn') }</div>
                    <SecondaryButtonComponent onClick={ props.handleOnDeleteMemberProfileClick } >{ props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailDeleteProfileBtn') }</SecondaryButtonComponent>
                </div>
            </div>
            {/* <div className='staff-member__registered-championships__container'>
                <h6 className='baloa-headline-6'>{ props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailRegisteredTournaments') }</h6>
                <ul>
                    {props.active_member.tournaments?.map((tournament, index) => (
                        <li className='registered-championships__card' key={index}>
                            <div className='registered-championships__card__info'>
                                <p className='baloa-subtitle-1'>{tournament.tournament}</p>
                                <p className='baloa-body-2'>{`${tournament.main_country}, ${tournament.main_city}`}</p>
                                <p className='baloa-caption'>{tournament.tournament_tag?.extra?.value}</p>
                                <p className='baloa-caption'>{`${tournament.start_date} - ${tournament.end_date}`}</p>
                            </div>
                            <span className='icon-chevron-roght' />
                        </li>
                        ))
                    }                    
                </ul>
            </div> */}

        </div>
    );
}

export default withTranslation("league")(LeagueTournamentStaffMemberComponent);