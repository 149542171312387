// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";

/*export const GetGroupsSuggestionsApi = () => {

    return Axios.instance.get( `${api_url}/friends-suggestions/`,
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });

}*/

export const MakeMemberRequestApi = ( pagename ) => {

    return Axios.instance.post( `${ api_url }/pages/${ pagename }/member_request/`, 
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const CheckMemberRequestApi = ( pagename ) => {
    
    return Axios.instance.get( `${ api_url }/pages/${ pagename }/member_request/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const AcceptMemberRequestApi = ( pagemember_request_id ) => {

    return Axios.instance.patch( `${ api_url }/page-member-requests/${ pagemember_request_id }/accept/`, 
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const RejectMemberRequestApi = ( pagemember_request_id ) => {

    return Axios.instance.delete( `${ api_url }/page-member-requests/${ pagemember_request_id }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const LeaveGroupApi = ( pagename, username ) => {

    return Axios.instance.delete( `${ api_url }/pages/${ pagename }/members/${ username }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const GetMemberGroupPagesApi = ( username ) => {

    return Axios.instance.get( `${ api_url }/profiles/${ username }/groups/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}
