// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-create-carnet__button{
    margin: var(--space-and-half);
    display: flex;
    justify-content: flex-end;
}

.modal-create-carnet__button__back{
    margin-right: var(--space-x3);
    display: flex;
    align-items: center;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/create-carnet/modal-create-carnet/ModalCreateCarnetComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".modal-create-carnet__button{\n    margin: var(--space-and-half);\n    display: flex;\n    justify-content: flex-end;\n}\n\n.modal-create-carnet__button__back{\n    margin-right: var(--space-x3);\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
