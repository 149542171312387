import React from 'react';
// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
// i18n
import { withTranslation } from 'react-i18next';
//stylesheet
import './ResponsiveRestrictionModalComponent.styles.css';

function ResponsiveRestrictionModalComponent(props) {

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className='responsive-restriction-modal'
        >
            <div className='responsive-restriction-modal-container'>
                <div className='responsive-restriction-modal__icons'>
                    <span className='hubicon-Frame-1531'/>
                    <span className='hubicon-smartphone'/>
                </div>
                <div className='baloa-names'>{props.t('responsiveRestrictionModal.text')}</div>
                <div className='responsive-restriction-modal-button'>
                    <a href="https://baloa.com/" target="_blank" rel="noreferrer" onClick={()=>{props.onClose()}}>
                        <PrimaryButtonComponent className={'small'}>
                            {props.t('responsiveRestrictionModal.btn')}
                        </PrimaryButtonComponent>
                    </a>
                </div>
            </div>
        </Modal2Component>);
}

export default withTranslation('home')(ResponsiveRestrictionModalComponent);