
export const changePageLoadingAction = ( data ) => {
    return { type: "PAGE:CHANGE_LOADING_PAGE", data }
}

export const loadPageAction = ( data ) => {
    return { type: "PAGE:LOAD_PAGE", data }
}

export const followPageAction = ( data ) => {
    return { type: "PAGE:FOLLOW_PAGE", data }
}

export const changePagePostsLoadingAction = ( data ) => {
    return { type: "PAGE:CHANGE_LOADING_PAGE_POSTS", data }
}

export const loadPagePostsAction = ( data ) => {
    return { type: "PAGE:LOAD_PAGE_POSTS", data }
}

export const changePageNewPageTypeModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_NEW_PAGE_TYPE_MODAL_STATE", new_state }
}

export const changePageNewPageCategoryModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_NEW_PAGE_CATEGORY_MODAL_STATE", new_state }
}

export const changePageNewPagePrivacyModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_NEW_PAGE_PRIVACY_MODAL_STATE", new_state }
}

export const changePageNewPageInformationModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_NEW_PAGE_INFORMATION_MODAL_STATE", new_state }
}

export const changePageNewTeamModalStateAction = ( new_state) => {
    return {type: "PAGE:CHANGE_NEW_TEAM_MODAL_STATE", new_state }
}

export const changePageUploadPhotoModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_UPLOAD_PHOTO_MODAL_STATE", new_state }
}

export const changePageDescriptionModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_DESCRIPTION_MODAL_STATE", new_state }
}

export const changePageExperiencesModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_EXPERIENCES_MODAL_STATE", new_state }
}

export const loadPageErrorsAction = ( errors, section ) => {
    return { type: "PAGE:LOAD_PAGE_ERRORS", errors, section }
}

export const loadPageFollowersAction = ( data ) => {
    return { type: "PAGE:LOAD_PAGE_FOLLOWERS", data }
}

export const changePageFollowersModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_FOLLOWERS_MODAL_STATE", new_state }
}

export const changePageAddMemberToPageModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_ADD_MEMBER_TO_PAGE_MODAL_STATE", new_state }
}

export const changePageAddAdminToPageModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_ADD_ADMIN_TO_PAGE_MODAL_STATE", new_state }
}

export const searchMembersPageAction = ( data ) => {   
    return { type: "PAGE:SEARCH_MEMBERS", data }
}

export const loadPageMembersAction = ( data ) => {
    return { type: "PAGES:LOAD_PAGE_MEMBERS", data }
}

export const addPageNationalityAction = ( data ) => {         
    return { type: "PAGES:ADD_PROFILE_NATIONALITY", data }
}

export const cleanPagesStateAction = ( ) => {         
    return { type: "PAGE:CLEAN_PAGE_STATE" }
}

export const updatePagePostsFeedAction = ( data ) => {         
    return { type: "PAGE:UPDATE_PAGE_POSTS_FEED", data }
}

export const deletePagePostAction = ( data ) => {         
    return { type: "PAGE:DELETE_PAGE_POST", data }
}

export const loadMatchReportAction = ( data ) => {
    return { type: "PAGE:LOAD_MATCH_REPORT", data }
}

export const setStaffMemeberPermissionsAction = ( data ) => {
    return { type: "PAGE:SET_STAFF_MEMBER_PERMISSIONS", data }
}

export const updatePagePostsAction = ( data ) => {
    return { type: "PAGE:UPDATE_PAGE_POSTS", data }
}

export const changeTeamMemberDetailModalStateAction = ( new_state ) => {
    return { type: "PAGE:CHANGE_TEAM_MEMBER_DETAIL_MODAL_STATE", new_state }
}

export const changePageJoinToTeamStateAction = ( new_state ) => {
    return {type: "PAGE:CHANGE_JOIN_TO_TEAM_MODAL_STATE", new_state }
}

export const changeTeamMembersModalStateAction = ( new_state ) => {
    return {type: "PAGE:CHANGE_TEAM_MEMBERS_MODAL_STATE", new_state }
}

export const changeAdminResponsiveModalStateAction = ( new_state ) => {
    return {type: "PAGE:CHANGE_ADMIN_RESPONSIVE_MODAL_STATE", new_state }
}

export const changeAdminResponsiveLockModalStateAction = ( new_state ) => {
    return {type: "PAGE:CHANGE_ADMIN_LOCK_MODAL_STATE", new_state }
}

export const changeSelectTeamInscriptionModalStateAction = ( new_state ) => {
    return {type: "PAGE:CHANGE_SELECT_TEAM_INSCRIPTION_MODAL_STATE", new_state }
}

export const loadPageInscriptionTeamsAction = ( data ) => {
    return { type: "PAGE:LOAD_PAGE_INSCRIPTION_TEAMS", data }
}