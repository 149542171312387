// React
import React from 'react';

// React device detect
import { isMobile } from 'react-device-detect';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './PageGroupsMyGroupsComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function PageGroupsMyGroupsComponent( props ) {
    return(
        <div className="page-groups-my-groups__container">
            {/* <div className="page-groups-my-groups__title baloa-subtitle-1">{props.title}</div> */}
            <div className="page-groups-my-groups__list-container">
            {
                props.memberGroupPages?.map( page => 

                    <div className={"page-groups-my-groups__page"} onClick={() => props.handleOnPageClick(page)} key={page.pagename}>
                        <div className={"page-groups-my-groups__page-photo"}>
                            {page.photo?
                                <img src={page.photo} alt="" />
                                :
                                <span className={"page-groups-my-groups__page-photo icon-ball"} color={getRandonIconBallColor()}></span> 
                            }
                        </div>
                        <div className="page-groups-my-groups__page-info">                            
                            <div
                                className="page-groups-my-groups__page-info__pagetype baloa-normal-medium">
                                {page.name}
                            </div>
                            <div                                    
                                className="page-groups-my-groups__page-info__pagename baloa-names">
                                {"@" + page.pagename}
                            </div>
                        </div>
                        {/* <div className="page-groups-my-groups__page-info__chevron">
                            <span className="icon-chevron-roght"></span>
                        </div> */}
                    </div>
            )}
            </div>
        </div>
        
    )
}

export default withTranslation('page')(PageGroupsMyGroupsComponent);
