// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-post-media-modal__container{
    display: flex;
    align-items: flex-start;
}

.profile-post-media-modal__container .profile-post-media__data__positions-graph{
    width: 55%;
}

.profile-post-media__data__positions-graph .post-profile__post{
    aspect-ratio: unset;
}

.profile-post-media__data__positions-graph .post-profile__post .post-profile__video{
    width: 100%;
    height: 100%;
}

.profile-post-media__data__positions-graph .post-profile__post .post-profile__post__video-control{
    height: 90%;
}

.profile-post-media-modal__comments{
    width: 45%;
}

.profile-post-media-modal__comments .post__actions__action{
    margin-right: unset;
}



@media screen and (max-width: 1600px){
    .profile-post-media-modal .modal2__container{
        width: 90%;
    }
}

@media screen and (max-width: 1100px){
    .profile-post-media-modal .modal2__container{
        width: 90%;
    }
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .profile-post-media-modal .modal2__container{
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0px;
        max-height: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-post-media-modal/ProfilePostMediaModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;;;AAIA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,WAAW;QACX,YAAY;QACZ,SAAS;QACT,kBAAkB;QAClB,gBAAgB;IACpB;AACJ","sourcesContent":[".profile-post-media-modal__container{\n    display: flex;\n    align-items: flex-start;\n}\n\n.profile-post-media-modal__container .profile-post-media__data__positions-graph{\n    width: 55%;\n}\n\n.profile-post-media__data__positions-graph .post-profile__post{\n    aspect-ratio: unset;\n}\n\n.profile-post-media__data__positions-graph .post-profile__post .post-profile__video{\n    width: 100%;\n    height: 100%;\n}\n\n.profile-post-media__data__positions-graph .post-profile__post .post-profile__post__video-control{\n    height: 90%;\n}\n\n.profile-post-media-modal__comments{\n    width: 45%;\n}\n\n.profile-post-media-modal__comments .post__actions__action{\n    margin-right: unset;\n}\n\n\n\n@media screen and (max-width: 1600px){\n    .profile-post-media-modal .modal2__container{\n        width: 90%;\n    }\n}\n\n@media screen and (max-width: 1100px){\n    .profile-post-media-modal .modal2__container{\n        width: 90%;\n    }\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .profile-post-media-modal .modal2__container{\n        width: 100%;\n        height: 100%;\n        margin: 0;\n        border-radius: 0px;\n        max-height: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
