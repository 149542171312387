// React
import React from 'react';

// Components
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';

// Styles
import './TournamentTeamPlayerComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function TournamentTeamPlayerComponent( props ) {

    const setPlayers = ( team ) => {
        //setTeam(team);
        if(team){
            props.handleSearchTeamPlayers(team);
        }
        else{
            props.handleSearchTeamPlayers(null);
        }
        
    }

    //const [scorer, setScorer] = React.useState(null);
    //const [team ,setTeam] = React.useState(props.tournament_teams.find(team => team.team === props?.scorer?.team));
    //const [team ,setTeam] = React.useState(null);
    //const [loadingPlayer, setLoadingPlayer] = React.useState(false);

    /*React.useEffect(() => {         
        if(props.scorer) {
            console.log("entro a actualizar");
            console.log("props.tournament_players",props.tournament_players);
            console.log("props.scorer.id",props.scorer.id);
            //let newScorer = props.tournament_players.find(player => player.id === '54779182-d15d-447a-bc26-7a5c77962eea');
            //console.log("newScorer", newScorer);
            //setScorer(newScorer);
            //let newTeam = props.tournament_teams.find(team => team.team === props?.scorer?.team);
            //console.log("newTeam", newTeam);
            //setTeam(newTeam);
        }       
    },[props]);*/


    //handleChangeScorer = handleChangeScorer.bind(this);

    /*const handleChangeScorer = (selected) => {
        setScorer(selected);
    }*/

    /*if(team?.team === props?.scorer?.team && !loadingPlayer){
      setLoadingPlayer(true);
      setPlayers(team);
    }*/
    return (
        

        <React.Fragment>  

            <div className='tournament_team_player_container_input'>
                <InputFieldAutocompleteComponent
                    name= { props.teamFieldName }
                    options={ props.tournament_teams? props.tournament_teams : null }
                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                    optionLabelField={ "teams_tournament" }
                    optionSelectedField={ "id" }
                    className="tournament-team-player__input"
                    label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelTeam')}
                    placeholder={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderTeam')}
                    onChange={ (selectedTeam) => setPlayers(selectedTeam) }
                    //value={ team }
                    value={ props.teamScorer? props.teamScorer : null}
                />
            </div>
            <div className='tournament_team_player_container_input'>
                <InputFieldAutocompleteComponent
                    name= { props.playerFieldName }
                    options={ props.tournament_players? props.tournament_players : [] }
                    noOptionsText={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.noOpt') }
                    optionLabelField={ "players" }
                    optionSelectedField={ "id" }
                    className="tournament-team-player__input"
                    label={ props.playerFieldName === 'third_place_coach' 
                        ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.label1stPlaceCoach')
                        : props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelName')
                    }
                    placeholder={ props.playerFieldName === 'third_place_coach' 
                        ? props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderName')
                        : props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndTournament.labelPlaceHolderPlayerName')
                    }
                    //value={ scorer === null ? props.tournament_players.find(player => player.id === props?.scorer?.id) : scorer }
                    value={ props.scorer? props.scorer : null }
                    onChange={ props.handleChangeScorer }
                />
            </div>                            

        </React.Fragment>
        )
    }
    
    export default withTranslation('league')(TournamentTeamPlayerComponent);