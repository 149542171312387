/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './ProfileEditExternalStatsInfoModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileEditExternalStatsInfoModalComponent( props ) {
    return (
        
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={() => { props.onClose() }}
            //title={props.t('externalStats.externalStatsTitle')}
            className='edit-external-stat-info-modal'
        >
            <div className='edit-external-stat-info-modal__icon'>
                <span className='hubicon-verified'/>
            </div>
            <div className='edit-external-stat-info-modal__info baloa-normal-medium' dangerouslySetInnerHTML={{
                    __html: props.t('externalStats.editModalInfo.message')
                }}>
            </div>
            <div className='edit-external-stat-info-modal__actions'>
                <TextButtonComponent 
                    className="small"
                    onClick={ props.handleOnCancel }
                >
                    <span>{ props.t('externalStats.editModalInfo.cancelBtnLbl') }</span>
                </TextButtonComponent>
                <PrimaryButtonComponent 
                    className="small"
                    onClick={ props.handeleOnAccept }
                >
                    <span>{ props.t('externalStats.editModalInfo.continueBtnLbl') }</span>
                </PrimaryButtonComponent>                
            </div>
        </ModalComponent>
    )
}

export default withTranslation(['profile'])(ProfileEditExternalStatsInfoModalComponent);