// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-help {
    margin: 0;
    display: grid;
    grid-template-areas: "left left center center center center center center center center center center";
    grid-template-columns: repeat(12, 1fr);
    gap: var(--space-and-half);    
}
.league-help__action-link {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold !important;
    font-size: var(--space-half-quarter);
    line-height: var(--space);    
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--primary-very-dark-color);
}
.league-help__center-container {
    grid-area: center;
}
.league-help-tabs {
    padding-top: var(--space-and-half);
}
@media screen and ( max-width: 1024px ) {
    .league-help {
        grid-template-areas: "left left center center center center center center center center center center ";
    }
    .league-help__right-container, .league-help__left-container {
        display: none;
    }
}
@media screen and ( max-width: 576px ) {
    .league-help {
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/views/HelpView.styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,sGAAsG;IACtG,sCAAsC;IACtC,0BAA0B;AAC9B;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,4BAA4B;IAC5B,oCAAoC;IACpC,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,qCAAqC;AACzC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kCAAkC;AACtC;AACA;IACI;QACI,uGAAuG;IAC3G;IACA;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".league-help {\n    margin: 0;\n    display: grid;\n    grid-template-areas: \"left left center center center center center center center center center center\";\n    grid-template-columns: repeat(12, 1fr);\n    gap: var(--space-and-half);    \n}\n.league-help__action-link {\n    font-family: Noto Sans;\n    font-style: normal;\n    font-weight: bold !important;\n    font-size: var(--space-half-quarter);\n    line-height: var(--space);    \n    letter-spacing: 0.04em;\n    text-transform: uppercase;\n    color: var(--primary-very-dark-color);\n}\n.league-help__center-container {\n    grid-area: center;\n}\n.league-help-tabs {\n    padding-top: var(--space-and-half);\n}\n@media screen and ( max-width: 1024px ) {\n    .league-help {\n        grid-template-areas: \"left left center center center center center center center center center center \";\n    }\n    .league-help__right-container, .league-help__left-container {\n        display: none;\n    }\n}\n@media screen and ( max-width: 576px ) {\n    .league-help {\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
