// React
import React from 'react';

import CurrencyFormat from 'react-currency-format';


// Styles
import './InscriptionsFeePlayerComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import WompiPaymentFormComponent from '../wompi-payment-form/WompiPaymentFormComponent';

function InscriptionsFeePlayerComponent ( props ) {
    
    const [totalPay, setTotalPay] = React.useState(props.registration_price_per_player);

    React.useEffect(() => {
        if(props.payment_player_code === "player_code"){
            setTotalPay(0);
        }
	}, [props.payment_player_code]); // eslint-disable-line

    return(
        <div className='inscription-fee-player__container' >
            <div className='inscription-fee-player__team-player' >                
                <div className='inscription-fee-player__player' >
                    <div className='left-col' >
                        <span className='icon-player' />
                        <div className='baloa-table' >{props.t('inscriptionModal.inscriptionPerPlayer')}</div>
                        
                    </div>
                    <div className='right-col' >
                        <div className='baloa-username'><CurrencyFormat value={props.registration_price_per_player} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')}/></div>                        
                    </div>
                </div>
            </div>
            {!props.payment_player_code &&
            <div className='inscription-fee-player__cupon-code' >

                    <FormComponent
                        formInitialValues={{inscode:''}} 
                        onSubmit={( values, actions ) => { props.submitCode(values) }}
                        className="inscription-fee-player__cupon-code__form"
                        functionDisabled={()=>{}}
                    >
                        <InputFieldComponent
                            label={props.t("inscriptionModal.paymentCodeLbl")}
                            input_id="inscode"
                            field_name="inscode"
                            //validationFunction={RequiredValidator}
                            disabled={false}
                            className=""
                            input_type="text"
                            resetError={() => {}}
                            error={{inscode:''}}
                        />
                        <SecondaryButtonComponent
                            type={"submit"}
                            onClick={()=>{}}
                            className={"xs"}
                        >
                            <span>{props.t('inscriptionModal.paymentCodeBtnLbl')}</span>
                        </SecondaryButtonComponent>
                    </FormComponent>
             
            </div>
            }
            <div className='inscription-fee-player__overall-fee' >
                <div>{props.t('inscriptionModal.totalLabel')}</div>
                <div><b><CurrencyFormat value={totalPay} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.currency ?? '')} fixedDecimalScale={true}/></b></div>
            </div>
            {props.currency === 'COP' &&
                <WompiPaymentFormComponent
                    currency={props.currency}
                    totalValue={totalPay}
                    profile_id={props.profile_id}
                    paymentCustomer={props.tournament_player_id}
                    countryCodePrefix="+57"
                    countryIataCode="CO"
                    city={'Bogota'}
                    region={'Bogota'}
                    playersQuantity={'0'}
                    submitWompiForm={props.submitWompiForm}
                    resetPaymentButton={props.resetPaymentButton}
                    type_redirect={props.type_redirect}
                    profile_phone={props.profile_phone}
                    metadata={`{"tournament_team_id":null,"tournament_player_id":"${props.tournament_player_id}","payment_type":"PLAYER_REGISTRATION","tournament_id":"${props.tournament_id}","total_codes":"0"}`}
                />
            }
        </div>
    )
}

export default withTranslation('tournament')(InscriptionsFeePlayerComponent);