
// Action creators
import {
    loadLineUpsConstantsAction,
    loadAlignmentsAction,
} from './LineUpCreators';

// Apis
import {
    GetLineUpsConstantsApi,
    GetAlignmentsApi,
} from 'app/generallineups/apis/LineUpApi';

export const loadLineUpsConstants = () => ( dispatch ) => {

    GetLineUpsConstantsApi()
        .then( response => {
            dispatch( loadLineUpsConstantsAction( response.data ) );
        } )
        .catch( error => {            
            //console.log( error );
        } )

}

export const loadAlignments = () => ( dispatch ) => {

    GetAlignmentsApi()
        .then( response => {
            dispatch( loadAlignmentsAction( response.data ) );
        } )
        .catch( error => {            
            //console.log( error );
        } )

}

