// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compose-inscription-form__form-container {
    display: flex;
    flex-direction: column;
}

.tournament-dynamic-forms-preview__container {
    margin: var(--space-and-half);

}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-dynamic-forms-preview/TournamentDynamicFormsPreviewComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;;AAEjC","sourcesContent":[".compose-inscription-form__form-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.tournament-dynamic-forms-preview__container {\n    margin: var(--space-and-half);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
