// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import CarnetInformationComponent from '../carnet-information/CarnetInformationComponent';
import TemplateCarnetComponent from '../template-carnet/TemplateCarnetComponent';

// Styles
import './ModalCreateCarnetComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import StepsCreateCarnetComponent from '../steps-create-carnet/StepsCreateCarnetComponent';



function ModalCreateCarnetComponent ( props ) {
    const [step, setStep] = useState(0);
    const [selectedItems, setSelectedItems] = useState(true);
    const [itemsList, setItemsList] = useState([]);
    const [selectedOrientation, setSelectedOrientation] = useState("");
    const [cardIdTemplateImage, setCardIdTemplateImage] = useState("");
    const [selectedImage, setSelectedImage] = useState("");

    function stepCompleted ( index ){
        //Initial step 0  = Match Assignment
        if(index === 1){
            setStep(1);//matchAssintance->score
        }else if(index === 0){
            setStep(0);//matchAssistant
        }
        
    }

    function onClickNextStep(){
        if(step === 0){
            setStep(1);
        }else{
            setStep(0);
        }
    }

    function onCloseModal(){
       return  props.onClose;
    }
    
    function handleSelectedItems (items) {
        if(items?.length > 0){
            setItemsList(items);
            setSelectedItems(false);
        }else{
            setSelectedItems(true);
        }
    }

    function handleSelectedImage (template ,image, imageBase64){
        if(template == "image_template"){
            setSelectedImage(image);
            setCardIdTemplateImage(imageBase64);
        }
    }

    function handleSelectedOrientation (orientation){
        setSelectedOrientation(orientation);
    }

    function sendInfo(){
        let itemList = [];
        let formIdCarnet = props.formID.find((id) => id.caption === "tournament_player_card");
        if(step === 1){
            for (let index = 0; index < itemsList.length; index++) {
                itemList.push({
                    "caption": itemsList[index].caption,
                    //"question_type": itemsList[index].question_type.caption,
                    "question_type": "json",
                    "is_required": itemsList[index].is_required,
                    "hide": itemsList[index].hide,
                    "order_position": index+1,
                    "answers": itemsList[index].answers,
                    "code": itemsList[index].question_type.caption
                });
            }
            if(selectedImage != null && selectedImage != ""){
                itemList.push({
                    "caption": "Plantilla",
                    "question_type": "file",
                    "is_required": false,
                    "hide": false,
                    "order_position": itemsList.length + 1,
                    "answers": [],
                    //"file_document": selectedImage.name+"<base64>",
                })
            }
            itemList.push({
                "caption": "Orientacion",
                "question_type": "text",
                "is_required": false,
                "hide": false,
                "order_position": selectedImage === null ? itemsList.length + 1 : itemsList.length + 2,
                "answers": [],
                //"text_answer": selectedOrientation,
            })
            props.sendCarnet(itemList, formIdCarnet.id, "CARD_ID_FORM", cardIdTemplateImage, selectedOrientation);
            setStep(0);
            props.onClose();
        }else{
            onClickNextStep();
        }
    }

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={onCloseModal()}
            title={props.t("createCarnet.modal.title")}
            className="medium"
        >
            <div className="scroll-parent">
                <StepsCreateCarnetComponent
                    step={step}
                    stepCompleted={(index) => {stepCompleted(index)}}
                />
                {/* carnet information */}
                {step === 0 && <CarnetInformationComponent
                    playerForm={props.playerForm}
                    handleSelectedItems={(items)=>{handleSelectedItems(items)}}
                />}
                {/* template */}
                {step === 1 && <TemplateCarnetComponent
                    handleSelectedImage={(template ,image, imageBase64)=>{handleSelectedImage(template ,image, imageBase64)}}
                    handleSelectedOrientation={(items)=>{handleSelectedOrientation(items)}}
                />}
            </div>
            <div className="modal-create-carnet__button form__actions">
                   {step === 1 && <div className="modal-create-carnet__button__back" onClick={() => {onClickNextStep()}}>
                    {props.t("createCarnet.modal.backButton")}
                   </div>}
                    <PrimaryButtonComponent
                        type="submit"
                        disabled={selectedItems}
                        onClick={() => {sendInfo()}}
                        // className="league_match_assistance-footer-button"
                    >
                        <span>
                            {props.t("createCarnet.modal.nextButton")}
                        </span>
                    </PrimaryButtonComponent>
                </div>
        </Modal2Component>
    )
}

export default withTranslation(["league", "team"])(ModalCreateCarnetComponent);