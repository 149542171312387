/*eslint eqeqeq: "off"*/
// Action creators
import { 
    changeUserSettingsError,
    changeUserMessageSnackBar,
    loadPaymentCountriesAction,
    loadPaymentsFormAction,
    getUserGatewayAttributeAction,
    getUserCountriesAction,
    getUserCountryStatesAction,
    changeUserOnboardingFormValidationStateAction,
    checkProfileAccountStatusAction,
    loadFormsDataAction,
    changeUserFormPaymentLoadingAction,
 } from './UserSettingsCreators';
 import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
 import { loadUserMe, loadLanguages } from 'app/authentication/actions/UserActionsCreators';
 import { resetHomeFeed } from 'app/home/actions/HomeActions';
 
 // Apis
import { 
    PatchUserApi,
    SetUserLanguageApi,
    LoadLanguagesApi,
    CheckAccountStatusApi,
    getFormsApi,
} from '../apis/UserSettingsApi';
import {
    GetCountryApi,
    GetCountryIdApi,
    GetPaymantesFormTemplateIdApi,
    GetGatewayAttributeApi,
    GetCountryStatesApi,
} from 'app/payments/apis/PaymentsApi'
import { GetCountriesApi } from 'app/leagues/apis/LeagueTournamentApi';
import { PlayerInscriptionApi } from 'app/tournaments/apis/TournamentApi';
import { GetUserMeApi } from 'app/authentication/apis/UserApi';


import i18n from 'config/i18n';

export const sendUserSettingsDataAction = ( detail_data,new_data ) => {
    return (dispatch) => {

        PatchUserApi(new_data)
            .then(response => {
                dispatch(loadUserMe(response.data));
                dispatch( changeUserMessageSnackBar( {
                    message: i18n.t('signUp:userSettings.updatedUser', {user: detail_data}), 
                    user_message_is_open: true
                } ))
            })
            .catch(error => {
                const data = {
                    'field': 'username',
                    'error': i18n.t('signUp:code.error2'), 
                }
                dispatch(changeUserSettingsError(data))
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'), 
                    error_message_is_open: true
                } ) )
            });
    }
}

export const loadLanguagesAction = () => {

    return (dispatch) => {
    
        LoadLanguagesApi()
            .then(response => {
                // console.log('r',response);
                dispatch( loadLanguages(response.data));
            })
            .catch(error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('common:snackBar'), 
                        error_message_is_open: true
                    } ) )
                }
            });

    }
}

export const setUserLanguageAction = ( user_id, language ) => {

    let message = "";

    return (dispatch) => {

        SetUserLanguageApi(user_id, language)
            .then(response => {
                
                dispatch(loadUserMe(response.data));
                dispatch( resetHomeFeed() );
                if(language.language_code == "es")
                    message = "Se configuró Baloa en Español";                
                else if (language.language_code == "en")
                    message = "Baloa has changed to English";

                dispatch( changeUserMessageSnackBar( {
                    message: message,
                    user_message_is_open: true
                } ))

            })
            .catch(error => {                
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('common:snackBar'), 
                    error_message_is_open: true
                } ) )
            });
    }
}

export const setAplicationLanguage = (language) => {

    return () => {
    
        i18n.changeLanguage(language);

    }
}

export const loadPaymentCountries = ( ) => {

    return async ( dispatch ) => {
        
        await GetCountryApi( )
            .then( response => { 
                dispatch( loadPaymentCountriesAction( response ) );
                })                          
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPaymentCountryId = ( owner_id ) => {

    return async ( dispatch ) => {
        
        await GetCountryIdApi( owner_id )
            .then( response => {      
                dispatch( loadPaymentCountriesAction( response ) );
                })                          
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadPaymentsFormTemplate = ( form_type, owner_id ) => {
    return async ( dispatch ) => {
        
        await GetPaymantesFormTemplateIdApi( form_type, owner_id )
            .then( async response => {
                await dispatch( loadPaymentsFormAction( response) );                              
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: 'error',
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getUserGatewayAttribute = (country_gateway_id, question_type) => {
    return async (dispatch) => {
        await GetGatewayAttributeApi(country_gateway_id, question_type)
            .then( response => {
                dispatch(getUserGatewayAttributeAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getUserCountries = (country) => {

    return async (dispatch) => {
        await GetCountriesApi(country)
            .then( response => {
                dispatch( getUserCountriesAction(response) ); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
  
}

export const getUserCountryStates = (country) => {
    return async (dispatch) => {
        await GetCountryStatesApi(country)
            .then( response => {
                dispatch(getUserCountryStatesAction(response) );               
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const sendUserPaymentForms = (form_data) =>{
    return async (dispatch)=>{
        dispatch( changeUserFormPaymentLoadingAction( true ) );
        await PlayerInscriptionApi(form_data)
        .then( response => {
            dispatch(changeUserOnboardingFormValidationStateAction(false));
            dispatch( changeUserFormPaymentLoadingAction( false ) );
        })
        .catch(err => {
            dispatch(changeUserOnboardingFormValidationStateAction(true));
            dispatch( changeUserFormPaymentLoadingAction( false ) );
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: err.response.data.detail,
                    error_message_is_open: true
                } ) )
            }
        })

    }
}

export const checkProfileAccountStatus = (account_id) => {

    return (dispatch) => {
        CheckAccountStatusApi(account_id)
            .then( response => {
                dispatch( checkProfileAccountStatusAction(response) ); 
                if(response.data.account_state === 'Gateway_Error'){
                    dispatch (loadFormsData(response.data.form) );
                }
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
  
}

export const loadFormsData = ( form_id ) => {

    return ( dispatch ) => {
        
        getFormsApi( form_id )
            .then( response => {
                dispatch( loadFormsDataAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const getUserMeAfterAccount = ( ) => {
    return async (dispatch) => {
        await GetUserMeApi()
            .then(response => {
                dispatch(loadUserMe(response.data));
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}