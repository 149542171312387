// React
import React from 'react';
import CurrencyFormat from 'react-currency-format';

// React device detect
import { isMobile } from 'react-device-detect';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './ProfileStatisticsPaymentResumeModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileStatisticsPaymentResumeModalComponent( props ) {

    const [totalAmount, setTotalAmount] = React.useState();

    React.useEffect(() => {

        if(props.profile_payment_checkout?.currency.toUpperCase() === 'USD') {
            let currency = (props.profile_payment_checkout?.amount_total/100);
            setTotalAmount(currency);
        } else {
            setTotalAmount(props.profile_payment_checkout?.amount_total);
        }

    }, [props.profile_payment_checkout?.amount_total]); // eslint-disable-line

    return (        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={!isMobile ? false : props.onClose }
            className='profile-statistics-payment-resume-modal'
            title={!isMobile ? '' : props.t('tournamentSummary.modalPaymentResume.modalTitle')}
        >  
            <div className='profile-statistics-payment-resume-modal__payment-info'>
                { !isMobile && <div className='profile-statistics-payment-resume-modal__payment-info__title baloa-subtitle-2'>{props.t('tournamentSummary.modalPaymentResume.modalTitle')}</div> }
                <div className='profile-statistics-payment-resume-modal__payment-info__ref'>
                    <div className='baloa-table'>{props.t('tournamentSummary.modalPaymentResume.modalRefTitle')}</div>
                    <div className='baloa-hyperlink-2'>{props.t('tournamentSummary.modalPaymentResume.modalRef')}</div>
                </div>
                
                <div className='profile-statistics-payment-resume-modal__payment-info__tournament-container'>
                    <div className='baloa-table'>{props.t('tournamentSummary.labelTournament')}</div>
                    
                    <div className='profile-statistics-payment-resume-modal__payment-info__tournament-info'>
                        <div className='profile-statistics-payment-resume-modal__payment-info__tournament-info__img'>
                            { props.tournament?.logo
                                ? <img src={props.tournament?.logo} alt={`${props.tournament?.name} logo`} />
                                : <span className='icon-ball' color={getRandonIconBallColor()} />
                            }
                        </div>
                        <div className='profile-statistics-payment-resume-modal__payment-info__tournament-info__tournament'>                            
                            <div className='baloa-names'>{props.tournament?.name}</div>
                            <div className='profile-statistics-payment-resume-modal__payment-info__tournament-info__tournament-labels'>
                                <div className='baloa-mini'>{props.t(`tournamentSummary.footballType.${props.tournament?.football_type}`)}</div>
                                <div className='baloa-mini'>{props.t(`tournamentSummary.gender.${props.tournament?.gender}`)}</div>
                                <div className='baloa-mini'>{props.tournament?.category}</div>
                            </div>                                            
                        </div>
                    </div>
                </div>
                <div className='profile-statistics-payment-resume-modal__payment-amount'>
                    <div className='baloa-hyperlink-2'>{props.t('tournamentSummary.modalPaymentResume.modalAmountLabel')}</div>
                    <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className="profile-statistics-payment-resume-modal__payment-amount__value"
                        value={ `${totalAmount}` }
                        displayType={'text'} 
                        thousandSeparator={','} 
                        decimalSeparator={"."} 
                        disabled={false}
                        prefix={'$ '} 
                        suffix={' ' + props.profile_payment_checkout?.currency.toUpperCase()}
                        placeholder="$"
                        onValueChange ={ () => {  }}
                        renderText={value => <div className='baloa-table'>{value}</div>}
                    />
                </div>
            </div>  
            
            <div className='profile-statistics-payment-resume-modal__payment-info__actions'>
                <SecondaryButtonComponent onClick={() => {props.onClose()}} >{props.t('tournamentSummary.modalPaymentResume.modalCancelBtn')}</SecondaryButtonComponent>
                <PrimaryButtonComponent onClick={() => {props.handleOnStatsCheckout()}}>{props.t('tournamentSummary.modalPaymentResume.modalContinueBtn')}</PrimaryButtonComponent>
            
            </div>  
                   
        </Modal2Component>
        )
    }
    
    export default withTranslation('profile')(ProfileStatisticsPaymentResumeModalComponent);