// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-team{

}
.add-team__content{
    margin: var(--space);
}
.add-team__actions{
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* .add-team__modal .tournament-set-groups__container__btn {
    font-size: var(--space-half);
    padding: var(--space-quarter) var(--space-half-quarter);
    
} */

.add-team__modal .tournament-set-groups__container__btn:first-of-type {
    margin-right: var(--space-half);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-add-team-to-group-modal/TournamentAddTeamToGroupModalComponent.styles.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,oBAAoB;AACxB;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;;;;GAIG;;AAEH;IACI,+BAA+B;AACnC","sourcesContent":[".add-team{\n\n}\n.add-team__content{\n    margin: var(--space);\n}\n.add-team__actions{\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n/* .add-team__modal .tournament-set-groups__container__btn {\n    font-size: var(--space-half);\n    padding: var(--space-quarter) var(--space-half-quarter);\n    \n} */\n\n.add-team__modal .tournament-set-groups__container__btn:first-of-type {\n    margin-right: var(--space-half);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
