
// React
import React from 'react';
import { connect } from 'react-redux';

// Axios
import axios from 'axios';

// Globals
import { api_url } from "config/GlobalData";

// Actions
import { loadHomePosts } from 'app/home/actions/HomeActions';
import { loadProfile } from 'app/profiles/actions/ProfileActions';

// Cropper
import Cropper from 'react-easy-crop'

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './ProfileUploadPhotoModalComponent.styles.css';

// Assets
import blankProfile from 'assets/icons/blankProfile.svg';

// Utilities
import getCroppedImg from 'shared/functions/CropImage';
import Loader from "react-loader-spinner";

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';


class ProfileUploadPhotoModalComponent extends React.Component {

   formInitialValues = {
      caption: "",
   }

   state = {
      selected_image: null,
      selected_image_url: null,
      image_input_ref: null,
      upload_progress: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      cropped_area_pixels: {},
      cropped_image: null,
      cropped_image_url: null,
   };

   onCropChange = crop => {
      this.setState({ crop })
   }

   onCropComplete = (cropped_area, cropped_area_pixels) => {
      this.setState({ cropped_area_pixels: cropped_area_pixels })
   }

   onZoomChange = zoom => {
      this.setState({ zoom })
   }

   onImageChange = event => {
      this.setState({ selected_image: event.target.files[0] });
      this.setState({ selected_image_url: URL.createObjectURL(event.target.files[0]) });
   };

   onUploadProgress = (progressEvent) => {
      this.setState({ upload_progress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
   }

   executeImageAction = () =>  {
      if (this.state.selected_image) {
         this.setState({loading: true});
         this.showCroppedImage()
            .then(()=> {
               this.onFileUpload()
            }
            )
            .catch(err => console.log(err));
      }
    }

   onFileUpload = async () => {

      const formData = new FormData();

      if (this.state.cropped_image) {
         const image = new File([this.state.cropped_image], 'profile-image.jpg', {
            type: 'image/png'
         });
         formData.append(
            "photo",
            image,
            this.state.cropped_image.name,
         );

      }

      axios.patch(
         `${api_url}/users/me/`,
         formData,
         {
            headers: {
               'Authorization': localStorage.getItem('token')
            },
            onUploadProgress: this.onUploadProgress,
         }
      ).then(() => {
         this.onRemoveImage();
         this.props.loadProfile(this.props.state.profile.profile.user.username);
         this.props.onClose();
      }
      ).catch(
         console.log('Error uploading image')
      )
   };

   showCroppedImage = async () => {
      try {
         const cropped_image = await getCroppedImg(
            this.state.selected_image_url,
            this.state.cropped_area_pixels,
            0
         )
         this.setState({ cropped_image: cropped_image });
         this.setState({ cropped_image_url: URL.createObjectURL(cropped_image) });
      } catch (e) {
         console.error(e)
      }
   }

   onRemoveImage = () => {
      this.setState({ cropped_image: null, cropped_image_url: null, selected_image_url:null, selected_image:null });
   }

   render() {

      return (
         <Modal2Component
            isOpen={this.props.isOpen}
            onClose={this.props.onClose}
            title={this.props.t('uploadPhotoProfile.title')}
            className="medium"
         >
            <div className="profile-new-photo">
               {/* Content */}
               <div className="">
                  {/* Crop Image */}
                  {this.state.selected_image_url && !this.state.cropped_image && <div className="profile-new-photo__content__media">
                     <Cropper
                        image={this.state.selected_image_url}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}/>                         
                  </div>}
                  {/* Display Image */}
                  {this.state.cropped_image && <div className="profile-new-photo__content__result">
                     <div className="profile-new-photo__content__result__container">
                        <div className="profile-new-photo__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="profile-new-photo__content__result"/>                           
                        </div>                        
                     <img src={ this.state.cropped_image_url} alt=""/>                        
                     </div>
                  </div>}
                  

                  {/* Blank Image */}
                  {!this.state.cropped_image && !this.state.selected_image_url && <div className="profile-new-photo__content__result">
                     <div className="profile-new-photo__content__result__container">
                        <img src={blankProfile} alt="" />
                     </div>
                  </div>}

                  {/* Load image button */}
                  {!this.state.cropped_image && !this.state.selected_image_url && <div className="profile-new-photo__action">
                     <SecondaryButtonComponent
                        onClick={() => { this.image_input_ref.click() }}
                        className="profile-new-photo__action__button"
                        disabled={this.state.cropped_image ? true : false}
                     >
                        <span>{this.props.t('uploadPhotoProfile.buttonImg')}</span>
                     </SecondaryButtonComponent>
                     <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={this.onImageChange}
                        ref={image_input => this.image_input_ref = image_input}
                        accept="image/*"
                     />
                  </div>}
               </div>


            </div>



            <div className="profile-new-photo__form">
               <FormComponent
                  formInitialValues={this.formInitialValues}
                  onSubmit={(values, actions) => {

                  }}
                  className="profile-new-photo__form__form"
                  functionDisabled={() => { }}
               >
                  <div className="profile-new-photo__form__group">
                     <SecondaryButtonComponent
                        type="button"
                        onClick={ this.onRemoveImage }
                        disabled={(!this.state.selected_image) ? true : false}
                        className="profile-new-photo__form__submit">
                        <span>{this.props.t('common:actions.delete')}</span>
                     </SecondaryButtonComponent>
                     <PrimaryButtonComponent
                        type="button"
                        disabled={(!this.state.selected_image) ? true : false}
                        onClick={this.executeImageAction}
                        className="profile-new-photo__form__submit">
                        <span>{this.props.t('uploadPhotoProfile.buttonOk')}</span>
                     </PrimaryButtonComponent>
                  </div>
               </FormComponent>
            </div>
         </Modal2Component>
      )
   }
}

const mapStateToProps = state => {
   return {
      state
   }
}

const mapDispatchToProps = dispatch => {
   return {
      loadHomePosts: () => {
         return dispatch(loadHomePosts())
      },
      loadProfile: (username) => {
         return dispatch(loadProfile(username))
      },
   }
}

export default compose(
   withTranslation(["profile","common"]),
   connect(mapStateToProps, mapDispatchToProps)
)(ProfileUploadPhotoModalComponent);
