// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Baloa-icons2.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Baloa-icons2.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Baloa-icons2.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Baloa-icons2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Baloa-icons2';
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconb2-"], [class*=" iconb2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Baloa-icons2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconb2-block:before {
  content: "\\e902";
}
.iconb2-report:before {
  content: "\\e903";
}
.iconb2-Verificated .path1:before {
  content: "\\e900";
  color: rgb(18, 182, 90);
}
.iconb2-Verificated .path2:before {
  content: "\\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.iconb2-exit:before {
  content: "\\e904";
  color: #71847b;
}
.iconb2-settings:before {
  content: "\\e905";
  color: #71847b;
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/Baloa-icons2.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,6CAA6B;EAC7B;;;yDAGuC;EACvC,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,+EAA+E;EAC/E,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;;EAEd,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["@font-face {\n  font-family: 'Baloa-icons2';\n  src:  url('Baloa-icons2.eot');\n  src:  url('Baloa-icons2.eot') format('embedded-opentype'),\n    url('Baloa-icons2.ttf') format('truetype'),\n    url('Baloa-icons2.woff') format('woff'),\n    url('Baloa-icons2.svg') format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n[class^=\"iconb2-\"], [class*=\" iconb2-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'Baloa-icons2' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.iconb2-block:before {\n  content: \"\\e902\";\n}\n.iconb2-report:before {\n  content: \"\\e903\";\n}\n.iconb2-Verificated .path1:before {\n  content: \"\\e900\";\n  color: rgb(18, 182, 90);\n}\n.iconb2-Verificated .path2:before {\n  content: \"\\e901\";\n  margin-left: -1em;\n  color: rgb(255, 255, 255);\n}\n.iconb2-exit:before {\n  content: \"\\e904\";\n  color: #71847b;\n}\n.iconb2-settings:before {\n  content: \"\\e905\";\n  color: #71847b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
