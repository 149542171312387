// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// React Infinite Scroll Component
import InfiniteScroll from "react-infinite-scroll-component";

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Styles
import './ProfileFeaturedClipsViewMoreModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function ProfileFeaturedClipsViewMoreModalComponent(props) {

    const [fileInputRef, setFileInputRef] = useState(null);
    const [fileInputClipsRef, setFileInputClipsRef] = useState(null);

    /**
     * Takes the file type input file for the video presentation and sends it to the view so 
     * that the other components know what type of video it is.
     * @param {*} event 
     */
    const onVideoChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'presentationVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    /**
     * Takes the file type input file for the video featured and sends it to the view so 
     * that the other components know what type of video it is.
     * @param {*} event 
     */
    const onVideoClipChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'featuredVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.profile_featured_clip_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.profile_featured_clip_next_page]); // eslint-disable-line


    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('addVideoClip.titlefeaturedClips')}
            className="featured-clips-view-more-modal"
        >
            {/* <div className='featured-clips-view-more-modal__container scroll'> */}
                <InfiniteScroll
                    dataLength={props.profile_featured_clip?.length + 1}
                    next={props.loadMoreFeaturedClips}
                    hasMore={loadMore}
                    //loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                    height={isMobile? 'calc(100vh - 52px)' : '80vh'}
                    endMessage={''}
                    className='featured-clips-view-more-modal__container'
                >
                    {props.loading_profile_featured_videos &&
                        isMobile &&
                            <React.Fragment>
                                <Skeleton animation="wave" variant="rounded" component="div" width={111} height={191} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                            </React.Fragment>
                        ||
                        props.loading_profile_featured_videos && !isMobile &&
                            <React.Fragment>
                                <Skeleton animation="wave" variant="rounded" component="div" width={180} height={310} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                                <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                            </React.Fragment>
                    }
                
                    {(props.profile_presentation_video && !props.loading_profile_featured_videos)
                        ? <div className="featured-clips-view-more-modal__reel"
                            onClick={() => {
                                props.handleProfilePostModal(props.profile_presentation_video, 'presentationVideoClip');
                            }}>
                            {props.profile_presentation_video.post_stream?.thumbnail
                                ? <img className="featured-clips-view-more-modal__reel_media_video"
                                    src={props.profile_presentation_video.post_stream?.thumbnail}
                                    alt={'videoPresentation'}
                                />
                                : <div className="featured-clips-view-more-modal__reel_media_video_container">
                                    <video className="featured-clips-view-more-modal__reel_media_video_fit"
                                        src={props.profile_presentation_video?.post_video}
                                        preload="auto"
                                        muted
                                        autoPlay={false}
                                        controls={false}
                                    />
                                </div>
                            }
                            {/* <span className={"profile_featured_post_video-control__icon"} /> */}
                            <div className="featured-clips-view-more-modal__reel-video__title baloa-table-column">
                                {props.profile_presentation_video.caption?.length > 0 ? props.profile_presentation_video.caption : ''}
                            </div>
                        </div>
                        :
                        props.isProfileOwner && !props.loading_profile_featured_videos &&
                            <div className="featured-clips-view-more-modal__reel">
                                <div className="featured-clips-view-more-modal__reel-video"
                                    onClick={() => { fileInputRef.click() }}>
                                    <span className="hubicon-video_camera_front" />
                                </div>
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={(event) => onVideoChange(event)}
                                    ref={video_input => setFileInputRef(video_input)}
                                    accept="video/*"
                                    value=""
                                />
                                <div className="featured-clips-view-more-modal__reel-video__empty-title baloa-table-column">
                                    {props.t('addVideoClip.labelVideoPresentation')}
                                </div>
                            </div>
                    }

                    {!props.loading_profile_featured_videos && props.isProfileOwner &&
                        <div className="featured-clips-view-more-modal__reel">
                            <div className="featured-clips-view-more-modal__reel-video"
                                onClick={() => { fileInputClipsRef.click() }}>
                                <span className="hubicon-video_library" />
                            </div>
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(event) => onVideoClipChange(event)}
                                ref={video_input => setFileInputClipsRef(video_input)}
                                accept="video/*"
                                value=""
                            />
                            <div className="featured-clips-view-more-modal__reel-video__empty-title baloa-table-column">
                                {props.t('addVideoClip.labelNewFeatureClip')}
                            </div>
                        </div>
                    }
                    {!props.loading_profile_featured_videos && props.profile_featured_clip && props.profile_featured_clip?.length > 0 &&
                        props.profile_featured_clip?.map((clip, index) => (
                            <div key={index} className="featured-clips-view-more-modal__reel"
                                onClick={() => {
                                    props.handleProfilePostModal(clip, 'featuredVideoClip');
                                }}>
                                {clip.post_stream?.thumbnail
                                    ? <img className="featured-clips-view-more-modal__reel_media_video"
                                        src={clip.post_stream?.thumbnail}
                                        alt={'FeaturedClip'}
                                    />
                                    : <div className="featured-clips-view-more-modal__reel_media_video_container">
                                        <video className="featured-clips-view-more-modal__reel_media_video_fit"
                                            src={clip?.post_video}
                                            Spreload="auto"
                                            muted
                                            autoPlay={false}
                                            controls={false}
                                        />
                                    </div>
                                }
                                {/* <span className={"profile_featured_post_video-control__icon"} /> */}
                                <div className="featured-clips-view-more-modal__reel-video__title baloa-table-column">{clip.caption? clip.caption : props.t('addVideoClip.labelfeaturedClip')}</div>
                            </div>
                        ))
                    }
                    {props.loading_profile_featured_videos_next &&
                        isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111} height={191} />
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} />
                        </React.Fragment>
                        ||
                        props.loading_profile_featured_videos_next && !isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={180} height={310} />
                            <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                            <Skeleton animation="wave" variant="rounded" component="div" width={180}height={310} />
                        </React.Fragment>
                    }
                </InfiniteScroll>
            {/* </div> */}
        </Modal2Component>
    )
}

export default withTranslation(['profile','home'])(ProfileFeaturedClipsViewMoreModalComponent);