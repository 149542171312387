// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-search-member-team-container {

   width: 100%;
}

.league-search-member-team__input-search.baloa-subtitle-2{
    padding-bottom: var(--space);
}

.league-search-member-team-form__box {
    padding-right: 10px;
}

.league-search-member-team-form__box .input-search__input{
    border-radius: 8px;
    background-color: var(--new-background-color);
    border: 1px solid var(--new-gray-background-color);
}

.league-search-member-team-search {


}

.league-search-member-team__input-subtitle{
    margin-bottom: var(--space);
}

.league-search-member-team__box__without-results .baloa-names{
    color: var(--basic-gray-color);
    font-weight: var(--font-regular)
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-search-member-team/LeagueSearchMemberTeamComponent.styles.css"],"names":[],"mappings":"AAAA;;GAEG,WAAW;AACd;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,6CAA6C;IAC7C,kDAAkD;AACtD;;AAEA;;;AAGA;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;IAC9B;AACJ","sourcesContent":[".league-search-member-team-container {\n\n   width: 100%;\n}\n\n.league-search-member-team__input-search.baloa-subtitle-2{\n    padding-bottom: var(--space);\n}\n\n.league-search-member-team-form__box {\n    padding-right: 10px;\n}\n\n.league-search-member-team-form__box .input-search__input{\n    border-radius: 8px;\n    background-color: var(--new-background-color);\n    border: 1px solid var(--new-gray-background-color);\n}\n\n.league-search-member-team-search {\n\n\n}\n\n.league-search-member-team__input-subtitle{\n    margin-bottom: var(--space);\n}\n\n.league-search-member-team__box__without-results .baloa-names{\n    color: var(--basic-gray-color);\n    font-weight: var(--font-regular)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
