/*eslint eqeqeq: "off"*/
// Action creators
import { 
    cleanEventsAction, 
} from './ClickstreamActionsCreators';

// Apis
import { 
    SendEventsApi,
} from 'app/clickstream/apis/ClickstreamApi';

export const sendEvents = ( clickstream_data) => {

    return ( dispatch ) => {
        
        SendEventsApi( clickstream_data )
            .then( response => {    
                dispatch( cleanEventsAction( {} ) );                  
            })
            .catch(err => {
                console.log('Clickstream error', err);
            })
    }
}
