// React
import React, { useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';

// Material UI
import Popover from '@mui/material/Popover';

// Assets
import options_icon from 'assets/icons/options-icon.svg';
import sm_copy_icon from 'assets/icons/share-menu-copy-icon.svg';

//Material components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import InputWithButtonComponent from 'shared/components/input-with-button/InputWithButtonComponent';
import InputCommentsComponent from 'shared/components/input-comments/InputCommentsComponent';

// Formaters
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';

// Moment
import * as moment from 'moment';

// Styles
import './HomeFeaturedClipsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HomeFeaturedClipsModalComponent(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [posterName, setPosterName] = React.useState(null);
    const [posterId, setPosterId] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    // const [originalLanguage, setOriginalLanguage] = React.useState(true);
    // Edit post
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorCombo, setAnchorCombo] = React.useState(false);
    const [anchorReactions, setAnchorReactions] = React.useState(null);
    // Share post menu
    const [shareMenuOpen, setShareMenuOpen] = useState(false);
    // Report post menu
    const [reportMenuOpen, setReportMenuOpen] = useState(false);
    // Video controls
    const videoRef = React.useRef();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [showComments, setShowComments] = useState(false);

    const handleCloseEditPostOption = () => {
        setAnchorEl(null);
    };

    function shareMenuClick() {
        if (props.menu_is_open) {
            if (shareMenuOpen) {
                setShareMenuOpen(false);
            } else {
                setShareMenuOpen(true);
            }
        }
    }

    function reportMenuClick() {
        if (props.menu_is_open) {
            if (reportMenuOpen) {
                setReportMenuOpen(false);
            } else {
                setReportMenuOpen(true);
            }
        }
    }

    const handleClickListItem = (event) => {
        setAnchorCombo(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuItemClick = (event, index, page) => {
        setSelectedIndex(index);
        if (index != 0) {
            setPosterId(page[1]);
            setPosterName(page[0]);
        } else {
            setPosterId(null);
            setPosterName(null);
        }
        setMenuOpen(false);
    };

    const handleClose = () => {
        setAnchorCombo(null);
    };

    // set Reaction
    function setReaction(event) {
        setAnchorReactions(event.currentTarget);
    }

    const handleCloseReactions = (post_id, reaction, reaction_id, reaction_type) => {
        setAnchorReactions(null);
        if (reaction_id == null) {
            props.sendReaction(post_id, reaction);
        } else if (reaction === reaction_type) {
            props.deleteReaction(post_id, reaction_id);
        } else {
            props.overrideReaction(post_id, reaction, reaction_id);
        }

    };

    // const changeLanguage = () => {
    //     if (originalLanguage) {
    //         setOriginalLanguage(false);
    //     } else {
    //         setOriginalLanguage(true);
    //     }
    // }

    const handleBackClip = ( ev ) => {
        ev.preventDefault( ev );
        if(props.featured_clip_selected_index > 0){
            let new_index = props.featured_clip_selected_index -1;
            props.handleOnClip(new_index)
        }
    }
    
    const handleNextClip = ( ev ) => {
        ev.preventDefault();
        if(props.featured_clip_selected_index >= 0){
            let new_index = props.featured_clip_selected_index + 1;
            props.handleOnClip(new_index);
        }
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('home:featuredClips.fcTitle')}
            className="home-featured-clips-modal"
        >
            <div className={`home-featured-clips-modal__container ${isMobile? 'scroll': ''}`}>
                <div className='home-featured-clips-modal__slide-button left' onClick={(e)=>{ handleBackClip(e)}} >
                    <span class="hubicon-arrow_circle_left">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>
                <div className="home-featured-clips-modal__media-container">
                    {/* Post video content */}
                    {props.post?.post_video &&
                        <div className="post-profile_video_clip_post">

                            {/* Video Player */}
                            {props.post?.post_stream?.hls ?
                                <ReactHlsPlayer
                                    className='post-profile__video_clip'
                                    playerRef={videoRef}
                                    src={props.post?.post_stream?.hls}
                                    preload="auto"
                                    autoPlay={false}
                                    playsinline
                                    width={'100%'}
                                    height={'auto'}
                                    loop={true}
                                    poster={props.post?.post_stream?.thumbnail ? props.post?.post_stream?.thumbnail : ""}
                                    controls={true}
                                    hlsConfig={{
                                        startLevel: 4, // El índice de la calidad deseada
                                        //startLevel: -1, // -1 significa que hls.js determinará el mejor nivel automáticamente
                                        capLevelToPlayerSize: true, // Limitar el nivel de calidad al tamaño del reproductor
                                        maxLoadingDelay: 4, // Establecer el tiempo máximo de espera para cargar el siguiente fragmento
                                        maxBufferLength: 30, // Establecer el tamaño máximo del buffer
                                        maxBufferSize: 60 * 1000 * 1000, // Establecer el tamaño máximo del buffer en bytes
                                        maxBufferHole: 0.5, // Establecer el agujero máximo del buffer
                                        lowBufferWatchdogPeriod: 0.5, // Establecer el período de vigilancia de buffer bajo
                                        highBufferWatchdogPeriod: 3, // Establecer el período de vigilancia de buffer alto
                                        nudgeOffset: 0.1, // Establecer el desplazamiento para empujar el video si el buffer está por debajo del nivel esperado
                                        nudgeMaxRetry: 3, // Establecer el número máximo de reintentos para empujar el video
                                      }}
                                />
                                :
                                <video
                                    className='post-profile__video_clip'
                                    ref={videoRef}
                                    src={props.post?.post_video}
                                    loop
                                    preload="auto"
                                    poster={props.post.post_stream?.thumbnail ? props.post.post_stream?.thumbnail : ""}
                                    muted
                                    playsinline
                                    controls={true}
                                    width={'100%'}
                                    height={'auto'}
                                ></video>
                            }

                        </div>
                    }
                    {/* Post image content */}
                    {props.post?.post_image &&
                        <div className="post-profile_video_clip_post">
                            <img src={props.post.post_image} alt="" />
                        </div>
                    }
                </div>
                {!isMobile &&
                    <div className='home-featured-clips-modal__comments'>
                    
                        {/* Profile Post */}
                        {props.post?.author?.type_object == "profile" &&
                            <div className="post__header">
                                {/* Profile photo */}
                                <div className={"post__header__photo"} onClick={() => props.handleOnAuthorClick(props.post?.author)}>
                                    {props.post?.author?.photo && <img src={props.post?.author?.photo} alt="" />}
                                    {!props.post?.author?.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()} />
                                    }
                                </div>
                                {/* Profile data */}
                                <div className="post__header__info">
                                    <div className="post__header__info__username baloa-normal-medium">
                                        <span onClick={() => props.handleOnAuthorClick(props.post?.author)} >
                                            {`${props.post?.author.caption}`}
                                        </span>
                                        {props.post?.author.is_verified &&
                                            <span class="iconb2-Verificated">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </span>
                                        }
                                        {props.post?.author.baloer &&
                                            <span class="iconbal-Balores">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                                <span class="path3"></span>
                                            </span>
                                        }
                                    </div>
                                    <div className="post__header__info__date baloa-table-column">
                                        {`${props.post?.author.profile_type}`}  &bull; {`${convertDateToMoment(props.post?.created)}`}
                                    </div>
                                </div>

                                {/* Not Owners options */}
                                {props.post?.is_owner === false &&
                                    <div className="post__header__options">
                                        <div className="post__header__options__option" onClick={() => reportMenuClick()}>
                                            <img src={options_icon} alt="" />
                                            {reportMenuOpen && (
                                                <ul className="post__header__options__option__list">
                                                    <li>
                                                        <TextButtonComponent
                                                            onClick={() => {
                                                                props.openReportPostModal(true);
                                                                props.handleReportPost(props.post?.id);
                                                                reportMenuClick();
                                                            }}
                                                            className="post__header__options__option__list__button"
                                                        >

                                                            <span className="post__header__options__option__list__color baloa-table-column">
                                                                <span class="iconb2-report"></span>
                                                                {props.t('post:postReport.btnReport')}
                                                            </span>
                                                        </TextButtonComponent>
                                                    </li>
                                                    <li>
                                                        <TextButtonComponent
                                                            onClick={() => {
                                                                props.openBlockPostModal(true);
                                                                props.handleBlockProfile(props.post?.author.identifier);
                                                                reportMenuClick();
                                                            }}
                                                            className="post__header__options__option__list__button"
                                                        >
                                                            <span className="post__header__options__option__list__color baloa-table-column">
                                                                <span class="iconb2-block"></span>
                                                                {props.t('post:postReport.btnBlock')} @{props.post?.author.identifier}
                                                            </span>
                                                        </TextButtonComponent>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                }

                                {/* Owner options */}
                                {/* props.post?.is_owner &&
                                    <div className="post__header__options">
                                        <div className="post__header__options__option">
                                            <span
                                                aria-describedby='simple-popover'
                                                // className="profile-experience__actions"
                                                // onClick={handleClickEditPostButton}
                                                onClick={()=>props.handleVideoClipOptionMenu()}
                                            >
                                                <img src={options_icon} alt="" />
                                            </span>
                                        </div>
                                    </div> */
                                }

                                {/* User options edit popover */}
                                {/* Was necesary use open variable to close the popover div: is a special case don't use if is not necesary */}
                                {open &&
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleCloseEditPostOption}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        container={anchorEl ? anchorEl : null}
                                    >
                                        <div
                                            className="post__header__options__option__list__popover"
                                        >
                                            <TextButtonComponent
                                                onClick={() => {
                                                    handleCloseEditPostOption();
                                                    props.changeHomeEditPostModal(true, props.post);
                                                    //props.onEditButton(props.experience_data, props.experience_data);
                                                }}
                                                className="post__header__options__option__list__button"
                                            >
                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="icon-edit-82"></span>
                                                    <span>{props.t('post:postEdit.btnEdit')}</span>
                                                </span>
                                            </TextButtonComponent>
                                            <TextButtonComponent
                                                onClick={() => {
                                                    handleCloseEditPostOption();
                                                    //props.deleteAttributeOnProfile( props.state.profile.profile, props.experience_data.id )
                                                    props.handleOnDeletePostClick(props.post);
                                                }}
                                                className="post__header__options__option__list__button"
                                            >
                                                <span className="post__header__options__option__list__color baloa-table-column">
                                                    <span class="icon-trash"></span>
                                                    <span>{props.t('post:postEdit.btnDel')}</span>
                                                </span>
                                            </TextButtonComponent>
                                        </div>
                                    </Popover>
                                }
                            </div>
                        }
                        
                        {/* Profile Caption */}
                        <div className='home-featured-clips-modal__post-caption baloa-names'>{props.post?.caption? props.post?.caption : ''}</div>
                        
                        <React.Fragment>
                            {/* Claps resume */}
                            {props.post?.reaction_count > 0 &&
                                <React.Fragment>
                                    <div className="post__clapped-by">
                                        {/* Clap Photos */}
                                        <div className="post__clapped-by__photos">
                                            {props.post.reacted_by.map(
                                                clap =>
                                                    <div key={clap[0]} className="post__clapped-by__photos__photo">
                                                        {clap[2] &&
                                                            <img src={clap[2]} alt="" />
                                                        }
                                                        {!clap[2] &&
                                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                        }
                                                    </div>
                                            )}
                                        </div>

                                        {/* Claps caption */}
                                        <p className="post__clapped-by__message"
                                            onClick={() => {
                                                //props.onCloseClapsModal(true);
                                                props.handleReactionsDetails(true);
                                                //props.loadPostClapsById(props.post.id);
                                                props.loadPostReactionsById(props.post.id);
                                            }}
                                        >
                                            {props.post.reaction_count <= 0 ?
                                                ""
                                                :
                                                <span className="baloa-normal" >
                                                    {/* props.t('postModal.titleLike')} @{props.post.reacted_by[0][0] */}
                                                    {/* {(props.post.reaction_count - 1) <= 0 ? "" :  ' ' + props.t('postModal.text1') + ' ' + (props.post.reaction_count - 1) + ' ' + props.t('postModal.text2')} */}
                                                    {props.post.is_reacted ? (props.post.reaction_count - 1) <= 0 ? props.t('post:postModal.reactionText3', { user: props.post.reacted_by[0][1] }) : props.t('post:postModal.reactionText1', { reactionsCount: `${props.post.reaction_count - 1}` }) : (props.post.reaction_count - 1) <= 0 ? props.t('post:postModal.reactionText3', { user: props.post.reacted_by[0][1] }) : props.t('post:postModal.reactionText2', { user: props.post.reacted_by[0][1], reactionsCount: `${(props.post.reaction_count - 1)}` })}
                                                </span>
                                            }
                                        </p>

                                    </div>
                                    <div className="post__separator"></div>
                                </React.Fragment>
                            }

                            {/* Post actions */}
                            <div className="post__actions" id={'post_actions_' + props.postItemIndex}>

                                {/* Clap */}
                                <div
                                    /* onClick={(event)=>{setReaction(event)}}   */
                                    className="post_video_clips_actions__action"
                                >
                                    <React.Fragment>
                                        {!props.post?.is_reacted &&
                                            <div className="post_video_clips_actions__action" onClick={(event) => { setReaction(event) }}>
                                                <span class="icon-claps-48"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post:postReactions.react')}</span>
                                            </div>
                                        }

                                        {props.post?.is_reacted && props.post?.reaction_type == "Clap" &&
                                            <div className="post__actions__action__reaction-green baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-clap post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                    <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                    <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                    <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                    <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>

                                                </span>
                                                {props.t('post:postReactions.clap')}
                                            </div>
                                        }
                                        {props.post?.is_reacted && props.post?.reaction_type == "Support" &&
                                            <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-support post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>

                                                </span>
                                                {props.t('post:postReactions.support')}
                                            </div>
                                        }
                                        {props.post?.is_reacted && props.post?.reaction_type == "Its_Funny" &&
                                            <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-funny post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                    <span class="path13"></span><span class="path14"></span>

                                                </span>
                                                {props.t('post:postReactions.itsfunny')}
                                            </div>
                                        }
                                        {props.post?.is_reacted && props.post?.reaction_type == "Red_Card" &&
                                            <div className="post__actions__action__reaction-red baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-red-card post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>

                                                </span>
                                                {props.t('post:postReactions.redcard')}
                                            </div>
                                        }
                                        {props.post?.is_reacted && props.post?.reaction_type == "Sad" &&
                                            <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-sad post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                    <span class="path13"></span><span class="path14"></span>
                                                </span>
                                                {props.t('post:postReactions.sad')}
                                            </div>
                                        }
                                        {props.post?.is_reacted && props.post?.reaction_type == "Celebrate" &&
                                            <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                                <span class="iconr-celebrate post__actions__action__reactions__icon">
                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                    <span class="path13"></span><span class="path14"></span>

                                                </span>
                                                {props.t('post:postReactions.celebrate')}
                                            </div>
                                        }

                                    </React.Fragment>

                                </div>
                                <Menu
                                    className="post__actions__action__menu-reactions"
                                    id={"reactions-menu_" + props.postItemIndex}
                                    anchorEl={anchorReactions}
                                    transformOrigin={{
                                        vertical: 50,
                                        horizontal: 'left',
                                    }}
                                    container={document.getElementById('post_actions_' + props.postItemIndex)}

                                    open={Boolean(anchorReactions)}
                                    onClose={() => { setAnchorReactions(null) }}
                                >
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Clap", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-clap post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Support", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-support post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Its_Funny", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-funny post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Red_Card", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-red-card post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Sad", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-sad post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Celebrate", props.post.reaction_id, props.post.reaction_type) }}>
                                        <span class="iconr-celebrate post__actions__action__menu-reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                        </span>
                                    </MenuItem>
                                </Menu>
                                {/* Comment */}
                                {/* <div
                                    onClick={() => props.handleOnMessageIconClick(props.post)}
                                    className="post_video_clips_actions__action"
                                >
                                    <span class="icon-message"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post:post.actions.comment')}</span>
                                </div> */}

                                {/* Share */}
                                <div
                                    id={"post_" + props.post?.id}
                                    className="post_video_clips_actions__action"
                                    onClick={() => shareMenuClick()}
                                >
                                    <span class="icon-share"></span><span className="post__actions__action__action-text baloa-normal">{props.t('post:post.actions.share')}</span>

                                    {shareMenuOpen && (
                                        <ul className="post__actions__action__list">
                                            <li>
                                                <button type="button"
                                                    className="post__actions__action__list__button"
                                                    onClick={() => { props.handleOnCopyLinkClick(props.post) }}
                                                >
                                                    <img src={sm_copy_icon} alt="" />
                                                    <span>{props.t('post:postModal.btnCopy')}</span>
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                                </div>

                            </div>
                            <div className="post__separator"></div>
                            {/* Comment box */}
                            {/* From the reducer, control was added to not send a list pages for groups */}
                            <div className="post__new-comment">
                                <div className={"profile_post_new-comment__photo"}>
                                    {props.me_photo &&
                                        <img src={props.me_photo} alt="" />
                                    }
                                    {!props.me_photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }
                                </div>
                                {props.listUserPages != undefined && !props.is_group &&

                                    <div>
                                        <List component="ul" aria-label="Device settings" id={"list-menu_" + props.postItemIndex}>
                                            {props.listUserPages[selectedIndex] && (
                                                <ListItem
                                                    button
                                                    aria-haspopup="true"
                                                    aria-controls={"lock-menu_" + props.postItemIndex}
                                                    aria-label="Poster"
                                                    onClick={(event) => { handleClickListItem(event) }}

                                                >
                                                    <ListItemAvatar>
                                                        {props.listUserPages[selectedIndex][2] &&
                                                            <Avatar alt={props.listUserPages[selectedIndex][0]} src={props.listUserPages[selectedIndex][2]} />
                                                        }
                                                        {!props.listUserPages[selectedIndex][2] &&
                                                            <Avatar className="post__new-comment_list__avatar" alt={props.listUserPages[selectedIndex][0]} ><span className={"post__new-comment_list icon-ball"} color={getRandonIconBallColor()}></span></Avatar>
                                                        }
                                                    </ListItemAvatar>
                                                    <span className='icon-chevron-down' />
                                                </ListItem>
                                            )}
                                        </List>

                                        <Menu
                                            id={"lock-menu_" + props.postItemIndex}
                                            anchorEl={anchorCombo}
                                            container={document.getElementById('list-menu_' + props.postItemIndex)}
                                            onClose={handleClose}
                                            open={menuOpen}
                                            variant={'menu'}
                                        >
                                            {props.listUserPages.map((option, index) => (
                                                <MenuItem
                                                    key={option[1]}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index, option)}
                                                    onBlur={(event) => {
                                                        setMenuOpen(false);
                                                    }}
                                                >
                                                    {option[2] &&
                                                        <img className="post__header__photo" src={option[2]} alt="" />
                                                    }
                                                    {!option[2] &&
                                                        <span className={"post__header__photo icon-ball"} color={getRandonIconBallColor()}></span>
                                                    }
                                                    {option[0]}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                }

                                <div className="post__new-comment__comment" id={"post-comment_" + props.postItemIndex}>
                                    {/* <InputWithButtonComponent
                                        onClick={(new_comment) => {
                                            props.handleOnComment(props.post, new_comment, posterName, posterId)
                                        }}
                                        placeholder={props.t('post:comment.text')}
                                    >
                                        {props.t('post:postModal.btnPost')}
                                    </InputWithButtonComponent> */}
                                    <InputCommentsComponent 
                                        placeholder={props.t('post:comment.text')}
                                        handleOnCommentClick={(new_comment) => {
                                            props.handleOnComment(props.post, new_comment, posterName, posterId)
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Post comment Caption */}
                            <div className={`post__caption scroll ${props.post?.reaction_count > 0 ? 'reacted' : ''}`}>
                                {props.post_comments?.map(comment =>
                                    <div className="post__caption__text">
                                        <div className={"post__caption__photo"}>
                                            {comment?.author.photo &&
                                                <img src={comment?.author.photo} alt="" />
                                            }
                                            {!comment?.author.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            }
                                        </div>
                                        <span key={comment.id} className="post__caption__text__author baloa-normal-medium">
                                            {comment?.author &&
                                                comment?.author.type == "page" ? `${comment?.author.username}` : `${comment?.author.first_name} ${comment?.author.last_name}`
                                            }
                                        </span>
                                        <div className="post__caption__text__caption baloa-normal" dangerouslySetInnerHTML={{
                                            __html: HASHTAG_FORMATTER(comment?.caption)
                                        }}>
                                        </div>
                                    </div>
                                )
                                }
                            </div>

                            {/* More comments */}
                            {/* props.post?.comment_count > 0 &&
                                <div className="post__view-comments">
                                    <div
                                        onClick={() => props.handleOnCommentsClick(props.post)}
                                    >
                                        <TextButtonComponent
                                            onClick={() => { }}
                                            className="baloa-names"
                                        >
                                            <span>{props.t('post:postModal.btnText', { commentCount: props.post.comment_count })}</span>
                                        </TextButtonComponent>
                                    </div>
                                </div> */
                            }

                        </React.Fragment>
                    </div>
                }
                {isMobile &&
                    <div className='home-featured-clips-modal__comments-mobile'>
                        <div className='home-featured-clips-modal__post-info'>
                            <div className='home-featured-clips-modal__post-info__author'>
                                <div className='home-featured-clips-modal__post-info__author-photo'>
                                    {props.post?.author?.photo && <img src={props.post?.author?.photo} alt="" />}
                                    {!props.post?.author?.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()} />
                                    }
                                </div>
                                <div className='home-featured-clips-modal__post-info__author-info'>
                                    <div className='home-featured-clips-modal__post-info__author-name baloa-table'>
                                        <span onClick={() => props.handleOnAuthorClick(props.post?.author)} >
                                            {`${props.post?.author?.caption}`}
                                        </span>
                                        {props.post?.author?.is_verified &&
                                            <span class="iconb2-Verificated">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </span>
                                        }
                                        {props.post?.author?.baloer &&
                                            <span class="iconbal-Balores">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                                <span class="path3"></span>
                                            </span>
                                        }
                                    </div>
                                    <div className='home-featured-clips-modal__post-info__author-username baloa-username'>{`@${props.post?.author?.identifier}`}</div>
                                </div>                               
                            </div>
                            <div className='home-featured-clips-modal__post-info__caption baloa-table'>
                                {props.post?.caption? props.post?.caption : ''}
                            </div>
                        </div>
                        {/* Post actions */}
                        <div className='home-featured-clips-modal__post-actions'>                            
                            {/* Clap */}

                            {!props.post?.is_reacted &&
                                <div className="home-featured-clips-modal__post-action" onClick={(event) => { setReaction(event) }}>
                                    <span class="icon-claps-48"></span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }

                            {props.post?.is_reacted && props.post?.reaction_type == "Clap" &&
                                <div className="post__actions__action__reaction-green baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-clap post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                        <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                        <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                        <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>

                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }
                            {props.post?.is_reacted && props.post?.reaction_type == "Support" &&
                                <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-support post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>

                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }
                            {props.post?.is_reacted && props.post?.reaction_type == "Its_Funny" &&
                                <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-funny post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>

                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }
                            {props.post?.is_reacted && props.post?.reaction_type == "Red_Card" &&
                                <div className="post__actions__action__reaction-red baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-red-card post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>

                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }
                            {props.post?.is_reacted && props.post?.reaction_type == "Sad" &&
                                <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-sad post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>
                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }
                            {props.post?.is_reacted && props.post?.reaction_type == "Celebrate" &&
                                <div className="post__actions__action__reaction-yellow baloa-headline-6" onClick={() => { props.deleteReaction(props.post.id, props.post.reaction_id) }}>
                                    <span class="iconr-celebrate post__actions__action__reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>

                                    </span>
                                    <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.post?.reaction_count}</span>
                                </div>
                            }

                            <Menu
                                className="home-featured-clips-modal__menu-reactions"
                                id={"reactions-menu_" + props.postItemIndex}
                                anchorEl={anchorReactions}
                                transformOrigin={{
                                    vertical: 50,
                                    horizontal: 280,
                                }}
                                variant={'menu'}
                                //container={document.getElementById('post_actions_' + props.postItemIndex)}

                                open={Boolean(anchorReactions)}
                                onClose={() => { setAnchorReactions(null) }}
                            >
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Clap", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-clap post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                        <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                        <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                        <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Support", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-support post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Its_Funny", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-funny post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Red_Card", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-red-card post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Sad", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-sad post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => { handleCloseReactions(props.post.id, "Celebrate", props.post.reaction_id, props.post.reaction_type) }}>
                                    <span class="iconr-celebrate post__actions__action__menu-reactions__icon">
                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                        <span class="path13"></span><span class="path14"></span>
                                    </span>
                                </MenuItem>
                            </Menu>
                            {/* Comment */}
                            <div className="home-featured-clips-modal__post-action" onClick={() =>{ /* props.handleOnMessageIconClick(props.post) */ setShowComments(true)}} >
                                <span class="icon-message"></span>
                                <span className="home-featured-clips-modal__post-action__text baloa-mini">{/* props.t('post:post.actions.comment') */props.post?.comment_count}</span>
                            </div>

                            {/* Share */}
                            <div  className="home-featured-clips-modal__post-action" onClick={() => shareMenuClick()} >
                                <span class="icon-share"></span>
                                <span className="home-featured-clips-modal__post-action__text baloa-mini">{props.t('post:post.actions.share')}</span>

                                {shareMenuOpen && (
                                    <ul className="home-featured-clips-modal__post-action__option">
                                        <li>
                                            <button type="button"
                                                className="post__actions__action__list__button"
                                                onClick={() => { props.handleOnCopyLinkClick(props.post) }}
                                            >
                                                <img src={sm_copy_icon} alt="" />
                                                <span>{props.t('post:postModal.btnCopy')}</span>
                                            </button>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            {/* Options */}
                            {props.post?.is_owner === false &&
                                <div className="post__header__options">
                                    <div className="post__header__options__option" onClick={() => reportMenuClick()}>
                                        <span className='home-featured-clips-modal__post-action icon-options' />
                                        {reportMenuOpen && (
                                            <ul className="post__header__options__option__list">
                                                <li>
                                                    <TextButtonComponent
                                                        onClick={() => {
                                                            props.openReportPostModal(true);
                                                            props.handleReportPost(props.post?.id);
                                                            reportMenuClick();
                                                        }}
                                                        className="post__header__options__option__list__button"
                                                    >

                                                        <span className="post__header__options__option__list__color baloa-table-column">
                                                            <span class="iconb2-report"></span>
                                                            {props.t('post:postReport.btnReport')}
                                                        </span>
                                                    </TextButtonComponent>
                                                </li>
                                                <li>
                                                    <TextButtonComponent
                                                        onClick={() => {
                                                            props.openBlockPostModal(true);
                                                            props.handleBlockProfile(props.post?.author.identifier);
                                                            reportMenuClick();
                                                        }}
                                                        className="post__header__options__option__list__button"
                                                    >
                                                        <span className="post__header__options__option__list__color baloa-table-column">
                                                            <span class="iconb2-block"></span>
                                                            {props.t('post:postReport.btnBlock')} @{props.post?.author.identifier}
                                                        </span>
                                                    </TextButtonComponent>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                }
                        </div>
                    </div>
                }
                {showComments && 
                    <div className='home-featured-clips-modal__comments-box'>
                        <div className='home-featured-clips-modal__comments-box__title'>
                            <div className='baloa-table'>{props.t('common:comment_noun_plural')}</div>
                            <span className="icon-x " onClick={ ()=>{ setShowComments(false) }} />
                        </div>
                        

                        {/* Post comment Caption */}
                        <div className="post__caption scroll">
                            {props.post_comments?.map(comment =>
                                <div className="post__caption__text">
                                    <div className={"post__caption__photo"}>
                                        {comment?.author.photo &&
                                            <img src={comment?.author.photo} alt="" />
                                        }
                                        {!comment?.author.photo &&
                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                        }
                                    </div>
                                    <span key={comment.id} className="post__caption__text__author baloa-normal-medium">
                                        {comment?.author &&
                                            comment?.author.type == "page" ? `${comment?.author.username}` : `${comment?.author.first_name} ${comment?.author.last_name}`
                                        }
                                    </span>
                                    <div className="post__caption__text__caption baloa-normal" dangerouslySetInnerHTML={{
                                        __html: HASHTAG_FORMATTER(comment?.caption)
                                    }}>
                                    </div>
                                </div>
                            )
                            }
                        </div>

                        {/* More comments */}
                        {/* props.post?.comment_count > 0 &&
                            <div className="post__view-comments">
                                <div
                                    onClick={() => props.handleOnCommentsClick(props.post)}
                                >
                                    <TextButtonComponent
                                        onClick={() => { }}
                                        className="baloa-names"
                                    >
                                        <span>{props.t('post:postModal.btnText', { commentCount: props.post.comment_count })}</span>
                                    </TextButtonComponent>
                                </div>
                            </div> */
                        }

                        {/* Comment box */}
                        {/* From the reducer, control was added to not send a list pages for groups */}
                        <div className="post__new-comment">
                            <div className={"profile_post_new-comment__photo"}>
                                {props.me_photo &&
                                    <img src={props.me_photo} alt="" />
                                }
                                {!props.me_photo &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                }
                            </div>
                            {props.listUserPages != undefined && !props.is_group &&

                                <div>
                                    <List component="ul" aria-label="Device settings" id={"list-menu_" + props.postItemIndex}>
                                        {props.listUserPages[selectedIndex] && (
                                            <ListItem
                                                button
                                                aria-haspopup="true"
                                                aria-controls={"lock-menu_" + props.postItemIndex}
                                                aria-label="Poster"
                                                onClick={(event) => { handleClickListItem(event) }}

                                            >
                                                <ListItemAvatar>
                                                    {props.listUserPages[selectedIndex][2] &&
                                                        <Avatar alt={props.listUserPages[selectedIndex][0]} src={props.listUserPages[selectedIndex][2]} />
                                                    }
                                                    {!props.listUserPages[selectedIndex][2] &&
                                                        <Avatar className="post__new-comment_list__avatar" alt={props.listUserPages[selectedIndex][0]} ><span className={"post__new-comment_list icon-ball"} color={getRandonIconBallColor()}></span></Avatar>
                                                    }
                                                </ListItemAvatar>
                                                <span className='icon-chevron-down' />
                                            </ListItem>
                                        )}
                                    </List>

                                    <Menu
                                        id={"lock-menu_" + props.postItemIndex}
                                        anchorEl={anchorCombo}
                                        container={document.getElementById('list-menu_' + props.postItemIndex)}
                                        onClose={handleClose}
                                        open={menuOpen}
                                        variant={'menu'}
                                    >
                                        {props.listUserPages.map((option, index) => (
                                            <MenuItem
                                                key={option[1]}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index, option)}
                                                onBlur={(event) => {
                                                    setMenuOpen(false);
                                                }}
                                            >
                                                {option[2] &&
                                                    <img className="post__header__photo" src={option[2]} alt="" />
                                                }
                                                {!option[2] &&
                                                    <span className={"post__header__photo icon-ball"} color={getRandonIconBallColor()}></span>
                                                }
                                                {option[0]}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            }

                            <div className="post__new-comment__comment" id={"post-comment_" + props.postItemIndex}>
                                {/* <InputWithButtonComponent
                                    onClick={(new_comment) => {
                                        props.handleOnComment(props.post, new_comment, posterName, posterId)
                                    }}
                                    placeholder={props.t('post:comment.text')}
                                >
                                    {props.t('post:postModal.btnPost')}
                                </InputWithButtonComponent> */}
                                <InputCommentsComponent 
                                    placeholder={props.t('post:comment.text')}
                                    handleOnCommentClick={(new_comment) => {
                                        props.handleOnComment(props.post, new_comment, posterName, posterId)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className='home-featured-clips-modal__slide-button right' onClick={(e)=>{ handleNextClip(e)}} >
                    <span class="hubicon-arrow_circle_right">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                </div>                
            </div>
        </Modal2Component>
    )
}

export default withTranslation(['profile','home'])(HomeFeaturedClipsModalComponent);