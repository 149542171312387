/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

// Actions
import { deleteAttribute } from 'app/generalattributes/actions/AttributeActions';
import { 
    loadPage, 
    loadPagePosts, 
    createOrEditAttributeOnPage, 
    followPage,
    unfollowPage,
    loadPageFollowers,
    followPageByPagename,
    unfollowPageByPagename,
    followProfileByPagename,
    unfollowProfileByPagename,
    addAdminToPage,
    addMemberToPage,
    loadPageMembers, 
    searchMembersPage,   
    removeMemberPage,
    removeAdminPage,
    addPageNationality,
    loadPageInscriptionTeams,      
} from 'app/pages/actions/PageActions';
import { loadLeagueTournaments, 
    loadTournamentPhases, 
    loadPhaseResultsTable, 
    loadTournamentPhaseGroupDates, 
    loadTournamentTeams, 
    loadTournamentPosts, 
    loadPhaseMatchesByDate, 
    loadPhaseDates,
    loadfilterTournament,
} from 'app/leagues/actions/LeagueTournamentActions'
import { clapPost, deletePost, loadPostClapsById, commentPostById } from 'app/posts/actions/PostActions';
import { 
    changePageExperiencesModalStateAction,
    changePageUploadPhotoModalStateAction,
    changePageDescriptionModalStateAction,
    loadPageErrorsAction, 
    changePageFollowersModalStateAction,
    changePageAddMemberToPageModalStateAction,
    changePageAddAdminToPageModalStateAction,
    changeTeamMemberDetailModalStateAction,
    changeSelectTeamInscriptionModalStateAction,
} from 'app/pages/actions/PageActionsCreators';
import { 
    changePostClapsModalStateAction, 
    changeMatchDetailModalStateAction,
 } from 'app/posts/actions/PostsActionsCreators';
 import {     
    changeProfileNationalityModalStateAction,     
} from 'app/profiles/actions/ProfileActionsCreators';
import {     
    searchProfileNationality, 
    searchProfileLocations,    
    switchProfileToPage,
    followProfile,
} from 'app/profiles/actions/ProfileActions';

import { makeMemberRequest, rejectMemberRequest, checkMemberRequest, leaveGroup, acceptMemberRequest, kickoutGroupMember } from 'app/pages/actions/PageGroupsActions';
import { changeCancelMemberRequestDialogModalStateAction, changeNewGroupPagePost } from 'app/pages/actions/PageGroupsActionsCreators';

import { changeHomeNewPostModal, changeHomeEditPostModal, changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { loadPageTeams, getTeamPlayers, loadTeamData } from 'app/leagues/actions/LeagueTeamsActions';
import { addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';
import { loadMatchReport } from 'app/leagues/actions/LeagueMatchActions';
import { changeSignUpLoginModal, changeClickType, setUrlRedirect } from 'app/authentication/actions/LoginActionsCreators';


// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

// Styles
import './PageView.styles.css';

// Components
import PageFeedComponent from 'app/pages/components/page-feed/PageFeedComponent';
import PageInformationComponent from 'app/pages/components/page-information/PageInformationComponent';
// import ProfileCompletionComponent from 'app/profiles/components/profile-completion/ProfileCompletionComponent';
import ProfileDescriptionModalComponent from 'app/profiles/components/profile-description-modal/ProfileDescriptionModalComponent';
import PageBasicInfoComponent from 'app/pages/components/page-basic-info/PageBasicInfoComponent';
// import ProfileSkillsComponent from 'app/profiles/components/profile-skills/ProfileSkillsComponent';
import ProfileExperiencesComponent from 'app/profiles/components/profile-experiences/ProfileExperiencesComponent';
import PageMembersComponent from 'app/pages/components/page-members/PageMembersComponent';
import PageAdminMembersComponent from 'app/pages/components/page-admin-members/PageAdminMembersComponent';
import PageAdminGroupsRequestComponent from 'app/pages/components/page-admin-groups-requests/PageAdminGroupsRequestComponent';
import LeagueTournamentsListComponent from 'app/leagues/components/league-tournaments-list-component/LeagueTournamentsListComponent';
import LeagueEventsComponent from 'app/leagues/components/league-events-component/LeagueEventsComponent';
import LeagueTournamentReportComponent from 'app/leagues/components/league-tournament-report-component/LeagueTournamentReportComponent';
import SeparatorComponent from 'shared/components/separator/SeparatorComponent';
// import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
import LeagueTeamListComponent from 'app/leagues/components/league-team-list/LeagueTeamListComponent';
import SelectTeamInscriptionComponent from 'app/pages/components/select-team-inscription/SelectTeamInscriptionComponent';


// Modals
import PageUploadPhotoModalComponent from 'app/pages/components/page-upload-photo-modal/PageUploadPhotoModalComponent';
import ProfileExperiencesModalComponent from 'app/profiles/components/profile-experiences-modal/ProfileExperiencesModalComponent';
import PageFollowersModalComponent from 'app/pages/components/page-followers-modal/PageFollowersModalComponent';
import LeagueAddMemberModalComponent from 'app/leagues/components/league-add-member-modal/LeagueAddMemberModalComponent';
import ProfileNationalityModalComponent from 'app/profiles/components/profile-nationality-modal/ProfileNationalityModalComponent';
import ModalConfirmDialogComponent from 'shared/components/modal-confirm-dialog/ModalConfirmDialogComponent';
import MatchDetailModalComponent from 'app/posts/components/match-detail-modal/MatchDetailModalComponent';
import PageTeamDetailModalComponent from '../components/page-team-detail-modal/PageTeamDetailModalComponent';


// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Variables
import { web_url } from "config/GlobalData";


function TabPanel( props ) {

    const { children, value, index, ...other } = props;

    // if ( props.profile.loading ) { { !this.props.state.page.page.id &&
    if ( !props.page?.pagename ) {        
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={200} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                </React.Fragment>
            </div>
            );
    }else{
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                                
                {/* Page Feed */}
                { index === 0 && 
                    <PageFeedComponent 
                        me_photo={ props.me_photo }
                        handleFollow = { ( page ) => console.log( page ) }
                        handleClap = { ( post ) => props.handleClap( post ) }
                        handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                        handleOnComment = { ( post, comment, pagename, page_id ) => props.handleOnComment( post.id, comment, pagename, page_id )}                    
                        handleOnLoadMorePosts = { () => {} }
                        handleOnPageClick = { () => {} }
                        handleOnMessageIconClick = { props.handleOnMessageIconClick }                    
                        handleShareMenuClick = {(is_open) => console.log( is_open )}
                        handleOnCopyLinkClick = { props.handleOnCopyLinkClick }
                        onCloseClapsModal={() => { props.onCloseClapsModal( true ) }}
                        loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}
                        openReportPostModal={ props.openReportPostModal }
                        handleReportPost={ props.handleReportPost }
                        openBlockPostModal={ props.openBlockPostModal }
                        handleBlockProfile={ props.handleBlockProfile }
                        handleBlockPage={ props.handleBlockPage }
                        changeHomeNewPostModal = { props.changeHomeNewPostModal }
                        changeHomeEditPostModal = { props.changeHomeEditPostModal }
                        changeNewGroupPagePost = { props.changeNewGroupPagePost }
                        //changeNewHomePostAction = {props.changeNewHomePostAction}
                        handleOnDeletePostClick = { props.handleOnDeletePostClick }
                        handleOnAuthorClick = { props.handleOnAuthorClick }
                        isOpen={ props.isOpen }
                        profile = { props.profile }
                        user = { props.user }
                        is_group = {props.page?.attributes.is_group}
                        menu_is_open = {props.menu_is_open}    
                        sendReaction= { props.sendReaction }
                        overrideReaction= { props.overrideReaction }
                        handleReactionsDetails = { props.handleReactionsDetails }
                        loadPostReactionsById = { props.loadPostReactionsById }
                        deleteReaction = { props.deleteReaction }
                        followPostProfile = { props.followPostProfile }                            
                        unfollowPostProfile = { props.unfollowPostProfile }                            
                        followPostPage = { props.followPostPage }                            
                        unfollowPostPage = { props.unfollowPostPage }

                        handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                        handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                    />
                }

                {/* Page Tournaments */}    
                {/* this.props.state.page.page.page_type === "league"         */}
                { index === 1 &&
                    <LeagueTournamentsListComponent 
                        page_tournaments = { props.page_tournaments }
                        page_events = { props.page_events }
                        on_click_tournament_report = { (selected_tournament) => props.on_click_tournament_report(selected_tournament) }
                        on_selected_report = {  props.on_selected_report }
                        tournament_tag ={ props.tournament_tag }
                        pagename={props.pagename}
                        errors={props.errors}
                        onResetError={props.onResetError}
                        handleFilterTournament={props.handleFilterTournament}
                        handleBackButton={props.handleBackButton}
                        handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                    />
                }

                {/* Page Teams */}
                { index === 2 &&
                    <LeagueTeamListComponent
                        page_events = { props.page_events }
                        page_teams = { props.pague_teams }
                        pagename = { props.page?.pagename }
                        handleOnTeamInfoModal={ props.handleOnTeamInfoModal }
                    /> 
                }

                {/* Page Members */}
                { index === 3 && 
                    <React.Fragment>
                        {props.page_type == "group" && props.is_private &&
                            <PageAdminGroupsRequestComponent
                                className = {"page-admin-members__container"}
                                page_member_request = { props.page_member_request }
                                handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                                onAcceptButtonClick = { props.onAcceptButtonClick }
                                onRejectButtonClick = { props.onRejectButtonClick }
                            />
                        }
                        
                        <PageAdminMembersComponent 
                            className = {''}
                            onNewButton = { props.onNewAdminMemberButton }
                            page_admin_members = { props.page_admin_members }
                            is_admin = { props.is_admin }
                            handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                            deleteAdminMemberFromPage = { ( id_member ) => props.deleteAdminMemberFromPage( id_member ) }
                            kickoutGroupAdminMember = { ( id_member ) => props.kickoutGroupMember( id_member ) }   
                            page_type = { props.page_type }                     
                        />
                        <PageMembersComponent 
                            className = {''}
                            onNewButton = { props.onNewMemberButton }
                            page_members = { props.page_members }
                            is_admin={ props.is_admin }
                            handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                            deleteMemberFromPage = { ( id_member ) => props.deleteMemberFromPage( id_member ) }
                            kickoutGroupMember = { ( id_member ) => props.kickoutGroupMember( id_member ) }
                            page_type = { props.page_type }
                        />
                    </React.Fragment>
                    
                }

                {/* Page Data */}
                { index === 4 && 
    
                    <React.Fragment>

                        {/* Page Completion */}
                        {/* <ProfileCompletionComponent 
                            percentage={ 15 }
                            className="page__completion"
                            is_owner={ props.is_admin } 
                            message="de la información completada"
                        /> */}

                        {/* Page Basic Information */}
                        <PageBasicInfoComponent 
                            className="page__basic-info"
                            page={ props.page }
                            is_owner={ props.page?.is_admin } 
                            onNewButton={ props.onNewBasicInfoButton }
                            //onNewButton={ () =>  { console.log("Add button") } }
                        />

                        {/* Page Skills */}
                        {/* { ( props.page?.is_admin ) && 
                            <ProfileSkillsComponent 
                                className="page__skills"
                                profile={ props.page }
                                is_owner={ props.page?.is_admin } 
                            />
                        } */}

                        {/* Page Experiences */}
                        { /* ( props.page?.is_admin || props.page?.attributes.experiences.length > 0 ) && 
                            <ProfileExperiencesComponent 
                                className="page__experiences"
                                profile={ props.page }
                                is_owner={ props.page?.is_admin } 
                                onNewButton={ props.onNewExperiencesButton }
                            /> */
                        }

                    </React.Fragment>

 }


    
            </div>
        );
    }

}


class PageUnauthenticatedView extends Component {

    constructor( props ) {
        super( props );
        this.pagename = this.props.match.params.pagename;
        this.showtournament = this.props.match.params.showtournament? this.props.match.params.showtournament : false ; 
        this.setshowtournament = this.props.match.params.setshowtournament? this.props.match.params.setshowtournament : undefined ;
        this.showteam = this.props.match.params.showteam? this.props.match.params.showteam : false ; 
        this.setshowteam = this.props.match.params.setshowteam? this.props.match.params.setshowteam : undefined ;
        this.state = {
            tab_selected: 0,
            tab_group_selected: 0,
            show_report_tournament: false,
            selected_tournament: [],
            member_request_to_cancel: undefined,
            check_memeber_request_intent: 0,
            show_report_team: false,
            active_phase_id: '',
            phase_matches_by_date: [],
            match_detail: {},
            groups_matches_by_date: [],
            tournament_tag: [
                { value: 'Free', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            modal_selected_team:{},
            is_best_third: false,
        }
    }

    componentDidMount() {
        this.props.loadPage( this.pagename );
        this.props.loadPagePosts( this.pagename );
        //this.props.loadPageFollowers( this.pagename );
        this.props.loadPageMembers( this.pagename );        
        this.props.loadLeagueTournaments( this.pagename );
        this.props.loadPageTeams(this.pagename);
        
        // if(this.props.state.page.page.is_admin){
        //     this.props.switchProfileToPage( this.pagename );
        // }

        /*this.interval = setInterval(() => {
            this.props.checkMemberRequest( this.pagename );
          }, 60000);*/
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    componentDidUpdate(prevProps) {
        
        if(this.props.match.params.pagename !== prevProps.match.params.pagename){

            this.pagename = this.props.match.params.pagename;

            this.props.loadPage( this.pagename );
            this.props.loadPagePosts( this.pagename );
            //this.props.loadPageFollowers( this.pagename );
            this.props.loadPageMembers( this.pagename );

            // if(this.props.state.page.page.is_admin){
            //     this.props.switchProfileToPage( this.pagename );  
            // }

            //if(this.props.state.page.page.page_type === "league"){
                this.props.loadLeagueTournaments( this.pagename );
                this.props.loadPageTeams(this.pagename);
                this.setState( { tab_selected: 0 } )
            //}
            
        }

        if(this.props.state.page.page.is_admin && this.props.state.page.page.page_type == "group" ){

            if(this.props.state.pageGroups.member_request === undefined && this.state.check_memeber_request_intent == 0){       
                this.props.checkMemberRequest( this.pagename );
                this.setState({ check_memeber_request_intent: 1 });    
                this.interval = setInterval(() => {
                    this.props.checkMemberRequest( this.pagename );
                  }, 60000);           
            }


            
        }

        if(this.props.state.page.page.is_admin && (this.props.state.profile.page_active == undefined || this.props.state.page.page != prevProps.state.page.page)){
            
            //this.props.switchProfileToPage( this.props.state.page.page );

        }
        if( prevProps.state.page.page?.pagename !== this.props.state.page.page?.pagename ){            
            this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'page-visit', PageId: this.props.state.page.page.id }});  //set Clickstream profile-visit event            
        }

        if(this.showtournament && this.setshowtournament && this.props.state.leagueTournaments.league_tournaments && (prevProps.state.leagueTournaments.league_tournaments !== this.props.state.leagueTournaments.league_tournaments)){
            let filtered_tournament =this.props.state.leagueTournaments.league_tournaments?.filter( tournament => tournament.id == this.setshowtournament);
            this.handleOnTournamentSelected(filtered_tournament[0]);
            this.setState({ show_report_tournament: true });
            
        }
        if(this.showteam && this.setshowteam && !this.props.state.page.modals?.team_member_detail_is_open){
            this.handleOnTeamInfoModal({teamname: this.setshowteam});
        }

        /** Validamos si es el link de unirse a un equipo de pagina */
        let team_inscription = this.props.location.search.split('?teams_inscription=');
        if(team_inscription.length > 1 && !this.props.state.page.modals.select_team_inscription_is_open){
            this.props.changeSelectTeamInscriptionModalStateAction(true);
            this.props.loadPageInscriptionTeams(this.pagename);
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: '',
            }); 
        }
     
    }


    handleOnProfileClick = ( profile ) => {
        this.props.history.push(`/profile/${ profile.user?.username? profile.user.username : profile.username? profile.username : profile }`);
    }
    
    handleOnPageClick = ( page, teampagename ) => {
        if(page.pagename){
            this.props.history.push(`/page/${page.pagename}`);
            this.props.changePageFollowersModalStateAction( false );
            this.props.loadPage( page.pagename );
            this.props.loadPagePosts( page.pagename );
            this.props.loadPageFollowers( page.pagename );
            // this.props.loadPageFollowing( page.pagename );
        }
        else if(page && teampagename ){
            //this.props.history.push("/league/team/"+ page + "&setshowteam=" + teampagename +"&showteam=true");
            this.props.getTeamPlayers(teampagename);
            this.props.loadTeamData(teampagename);
            this.props.changeTeamMemberDetailModalStateAction(true);
        }
        else{
            this.props.history.push(`/page/${ page }`);
            this.props.changePageFollowersModalStateAction( false );
            this.props.loadPage( page );
            this.props.loadPagePosts( page );
            this.props.loadPageFollowers( page );
        }
        
    }

    handleOnAuthorClickV2 = ( author ) => {
        this.props.history.push(`/${ author.type_object }/${ author.identifier }`);
        if(author.type_object == "page"){
            this.props.changePageFollowersModalStateAction( false );
            this.props.loadPage( author.identifier );
            this.props.loadPagePosts( author.identifier );
            this.props.loadPageFollowers( author.identifier );
        }
    }

    handleOnDeleteAdmin = ( member ) => {
        this.props.removeAdminOfPage( this.pagename, member.username);
    }

    handleOnDeleteMember = ( member ) => {
        this.props.removeMemberOfPage( this.pagename, member.username);
    }

     handleOnLoadMorePosts = () => {
        // if(this.props.state.home.posts_next_page !== null){
        //     this.props.loadHomePosts( this.props.state.home.loading_posts, this.props.state.home.posts_next_page );
        // }
    }

    handleOnMessageIconClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCommentsClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnTournamentClicked = ( is_tournament_clicked ) => {

        if(is_tournament_clicked === 'true') {
            this.setState({ show_report_tournament: true });
        } else {
            this.props.history.push(`/page/${this.pagename}`);
            this.showtournament = false;
            this.setshowtournament = undefined;
            this.setState({ show_report_tournament: false });
        }

    }

    handleOnTeamClicked = ( is_team_clicked ) => {

        if(is_team_clicked === 'true') {
            this.setState({ show_report_team: true });
        } else {
            this.setState({ show_report_team: false });
        }

    }

    handleOnPhaseClick = async (phase) => {
        if(phase.best_third){
            this.setState({is_best_third: true});
        } else {
            this.setState({is_best_third: false});
        }
        this.props.loadPhaseResultsTable(phase?.id);
        await this.props.loadPhaseDates(phase?.id)
        //await this.props.loadPhaseMatchesByDate(phase?.id, this.props.state.leagueTournaments.tournament_results.tournament_dates[0]?.calendar_date);
        this.setState({ active_phase_id: phase.id });
        this.handleGroupsMatchByDate();
    }

    handleOnPhaseDate = async (date) => {
        await this.props.loadPhaseMatchesByDate(this.state.active_phase_id, date);
        this.handleGroupsMatchByDate();
    }

    handleOnTournamentDates = ( group_id ) => {
        this.props.loadTournamentPhaseGroupDates(  group_id )
    }

    handleOnTournamentSelected = ( tournament ) => { 
        /* this.setState({ selected_tournament: tournament });       
        this.props.loadTournamentPhases(  tournament.id ); 
        this.props.loadTournamentTeams( tournament.id );
        this.props.loadTournamentPosts( tournament.league_page, tournament.id.replaceAll('-','') ); */
        this.props.history.push(`/tournament/hub/${tournament.id}`);
    }

    handleOnTeamSelected = ( team ) => { 
        this.setState({ selected_team: team });       
        this.props.loadPageTeams(  team.id ); 
        
    }

    handleOnClickCancelRequest = ( member_request_id ) => {
        this.setState( { member_request_to_cancel: member_request_id } ); 
        this.props.changeCancelMemberRequestDialogModalStateAction( true );
    }
    
    handleMatchStatistics = ( match_id ) => {
        this.props.loadMatchReport( match_id );
    }

    handleOnTeamInfoModal = (team) => {
        this.props.getTeamPlayers(team.teamname);
        this.props.loadTeamData(team.teamname);
        this.props.changeTeamMemberDetailModalStateAction(true);
    }
    
    handleOnMatchDetailModal = () => {
        this.props.changeMatchDetailModalStateAction(true);
    }

    handleGroupsMatchByDate = () => {
        let groups_by_date = [];
        for(let i = 0; i < this.props.state.leagueTournaments.tournament_results.selected_date_matches.length; i++ ) {
            groups_by_date.push(this.props.state.leagueTournaments.tournament_results.selected_date_matches[i].group_match);            
        }
        let filtered_groups = [...new Set(groups_by_date)]
        this.setState({groups_matches_by_date: filtered_groups})
    }

    handleFilterTournament=(filter)=>{
        this.props.loadfilterTournament(filter.league_pagename, filter.type_filter);
    }

    handleCleanFilterTournament=()=>{
        this.props.loadLeagueTournaments(this.pagename);
    }

    handleOnClosePageTeamModal=()=>{
        this.props.history.push(`/page/${this.pagename}`);
        this.showteam= false;
        this.setshowteam= undefined;
        this.props.changeTeamMemberDetailModalStateAction(false);
    }

    handleTeamMembersModal = (team) => {
        this.setState({modal_selected_team: team})
        this.props.loadTournamentTeamPlayers(team.id, "Enrolled");
        this.props.changeTeamMembersModalStateAction(true);
    }

    handleOnTeamButtonClick(pagename, teamname, is_player, click_type){
        /* if(!isplayer){
            this.props.addMemberWithRolToTeam(teamname, { username: this.props.state.user.me.username, role:{ role: 'player', position: null, player_number: null}});
        }
        this.props.changeSelectTeamInscriptionModalStateAction(false);
        this.props.history.push(`/page/${pagename}&setshowteam=${teamname}&showteam=true`) */

        this.props.changeSignUpLoginModal(true);
        this.props.changeClickType(click_type);
        let url_redirect = `?page/${pagename}?teams_inscription=true`;
        this.props.setUrlRedirect(url_redirect);
    }

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    render() {
       
        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: ${this.props.state.page.page.name} (${this.pagename})`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${this.props.state.page.page.name} (@${this.pagename})`} />
                    <meta property="og:description" content={`${this.props.state.page?.page?.attributes?.description?.value}`} />
                    <meta property="og:image" content={this.props.state.page.page?.photo? this.props.state.page.page.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta property="og:url" content={`${web_url}/page/${this.pagename}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content={`${this.props.state.page.page.name} (@${this.pagename})`} />
                    <meta name="twitter:description" content={`${this.props.state.page?.page?.attributes?.description?.value}`} />
                    <meta name="twitter:image" content={this.props.state.page.page?.photo? this.props.state.page.page.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta name="twitter:url" content={`${web_url}/page/${this.pagename}`} />
                </Helmet>

                {/* Page Modals */}

                {/* Page Photo Modal */}
                <PageUploadPhotoModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.page.modals?.upload_photo_is_open } 
                    onClose={() => { this.props.changePageUploadPhotoModalStateAction( false ) }}
                />

                {/* Page Description Modal */}
                <ProfileDescriptionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.page.modals?.description_is_open } 
                    onClose={() => { this.props.changePageDescriptionModalStateAction( false ) }}
                    errors = { this.props.state.page.errors.general } 
                    model = { this.props.state.page.page }
                    onNewData = {                         
                        ( new_attribute_data ) => {
                            this.props.createOrEditAttributeOnPage( this.props.state.page.page, new_attribute_data );
                            this.props.changePageDescriptionModalStateAction( false )
                        }
                     }
                    onResetError = { ( new_errors ) => this.props.loadPageErrorsAction( new_errors, 'general' ) }
                />

                {/* Page Experiences Modal */}
                <ProfileExperiencesModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.page.modals?.experiences_is_open } 
                    onClose={() => { this.props.changePageExperiencesModalStateAction( false ) }}
                    errors = { this.props.state.page.errors.experiences } 
                    experience = { null }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnPage( this.props.state.page.page, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadPageErrorsAction( { ...this.props.state.page.errors.experiences, ...new_errors }, 'experiences' ) }
                    is_profile = { false }
                />
                
                {/* Page followers Modal */}
                <PageFollowersModalComponent
                    onClick={() => {}}
                    isOpen={ this.props.state.page.modals?.followers_is_open } 
                    onClose={ () => {this.props.changePageFollowersModalStateAction( false ) }}
                    followers={ this.props.state.page.followers }
                    onFollowProfile = { ( profile_username, pagename ) => { this.props.followProfileByPagename( profile_username, pagename ) } }
                    current_username= { this.pagename }
                    onUnfollowProfile={ ( profile_username, pagename ) => { this.props.unfollowProfileByPagename( profile_username, pagename ) } }
                    onFollowPage = { ( page_pagename, pagename ) => { this.props.followPageByPagename( page_pagename, pagename ) } }
                    onUnfollowPage = { ( page_pagename, pagename ) => { this.props.unfollowPageByPagename( page_pagename, pagename ) } }
                    handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                    handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                />
                
                {/* Page Add Admin Member */}
                <LeagueAddMemberModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.page.modals?.add_to_page_admin_is_open } 
                    onClose={() => { this.props.changePageAddAdminToPageModalStateAction( false ) }}
                    title={this.props.t('tabs.members.followers.new.title1')}
                    profiles = {this.props.state.page?.searched_members}
                    handleSearchMember = {( member_username ) => this.props.searchMembersPage( member_username )}
                    onNewData = { ( new_member ) => { 
                        this.props.changePageAddAdminToPageModalStateAction( false )
                        this.props.addAdminToPage( this.pagename, new_member.username )                                                 
                    } }                    
                />

                {/* Page Add Member */}
                <LeagueAddMemberModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.page.modals?.add_to_page_member_is_open } 
                    onClose={() => { this.props.changePageAddMemberToPageModalStateAction( false ) }}
                    title={this.props.t('tabs.members.followers.new.title2')}
                    profiles = {this.props.state.page?.searched_members}
                    handleSearchMember = {( member_username ) => this.props.searchMembersPage( member_username )}                    
                    onNewData = { ( new_member ) => { 
                        this.props.changePageAddMemberToPageModalStateAction( false )
                        this.props.addMemberToPage( this.pagename, new_member.username )                                                 
                    } }                    
                />

                {/* Profile Nationality Modal */}
                <ProfileNationalityModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.nationality_is_open } 
                    onClose={() => { this.props.changeProfileNationalityModalStateAction( false ) }}
                    handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                    handleSearchLocation={( nationality, new_location_search ) => { this.props.searchProfileLocations( nationality, new_location_search ) }}
                    model = { this.props.state.profile.profile }
                    nationalities = { this.props.state.profile.nationalities_search_results }
                    locations = { this.props.state.profile.locations_search_results }
                    onNewData = { ( new_nationality_data ) => {                         
                        this.props.changeProfileNationalityModalStateAction( false )
                        this.props.addPageNationality( this.props.state.page.page, new_nationality_data )  

                    } }
                />

                {/* Confirm Dialog Cancel member request */}
                <ModalConfirmDialogComponent
                    onPrincipalButtonClick = { ( ) => { this.props.changeCancelMemberRequestDialogModalStateAction( false ) } }
                    principalButtonLabel = { this.props.t('groups.modalButtons.back') }
                    secondaryButton = { true }
                    onSecondaryButtonClick = { () => { 
                        this.props.rejectMemberRequest( this.state.member_request_to_cancel, this.props.state.user.me.username, this.pagename , this.props.state.page?.page?.is_admin);
                        this.props.changeCancelMemberRequestDialogModalStateAction( false );
                    } }
                    secondaryButtonLabel = { this.props.t('groups.modalButtons.cancelRequest') }
                    isOpen = { this.props.state.pageGroups.modals.reject_member_request_dialog_is_open } 
                    onClose={() => { this.props.changeCancelMemberRequestDialogModalStateAction( false ) }}
                    modalTitle = { this.props.t('groups.modalLabels.cancelRequestTitle') }
                    modalContent = { this.props.t('groups.modalLabels.cancelRequestText') }
   
                />

                <MatchDetailModalComponent
                    isOpen={this.props.state.post.modals?.match_detail_is_open}
                    onClose={() => { this.props.changeMatchDetailModalStateAction(false) }}
                    match_detail = { this.props.state.leagueMatches.match_report }
                />

                {/* Team Detail Modal */}
                <PageTeamDetailModalComponent
                    isOpen={this.props.state.page.modals?.team_member_detail_is_open}
                    onClose={() => { this.handleOnClosePageTeamModal() }}
                    team= { this.props.state.teams.team }
                    tournaments = { this.props.state.teams.team.tournaments }
                    followProfile={(profile) => { this.props.followProfile(profile) }}
                    unfollowProfile={(profile) => { this.props.unfollowProfile(profile) }}
                />

                {/* Page Detail */}
                {/* <MenuLigaComponent/> */}
                <div className="page">

                    { !this.props.state.page.page.id && 

                        <div className="profile__left-container">
                        <div className="page__left-container__info">
                            <Skeleton animation="wave" variant="rect" component="div" className="profile-info__photo_skeleton" />
                            <Skeleton animation="wave" className="profile-info__fullname baloa-headline-5" />
                            <Skeleton animation="wave" className="profile-info__username baloa-subtitle-2" />
                            <SeparatorComponent className="profile-info__separator"/>
                            <div className="profile-info__follows">
                                <Skeleton animation="wave" width="40%"/>&nbsp;&nbsp;&nbsp;
                                <Skeleton animation="wave" width="40%"/>
                            </div>
                        </div>                        
                        </div>

                    }

                    {this.props.state.page.modals.select_team_inscription_is_open && //Link de inscripcion a equipos de pagina
                        <div className={"page__center-container "} >
                            <SelectTeamInscriptionComponent
                                onClose={() => {this.props.changeSelectTeamInscriptionModalStateAction(false)}}
                                page={this.props.state.page.page_inscription_teams}
                                handleOnTeamButtonClick={ (pagename, teamname, is_player)=>{ this.handleOnTeamButtonClick(pagename, teamname, is_player, 'homeAnonJoinTeam') }}
                            />
                        </div>
                    }

                    { ( this.props.state.page.page?.is_private == false && !this.props.state.page.modals.select_team_inscription_is_open ) && (
                        <React.Fragment>
                            { this.state.show_report_tournament === false &&
                                <div className="page__center-container unauth">
            
                                    {/* Page Info */}
                                    { //(this.props.state.page.page.page_type === "league" && this.props.state.page.page.is_admin === true) && (
                                        (this.props.state.page.page.page_type != "group" && (this.props.state.page.page.is_admin === true || this.props.state.page.page.is_member === true) ) && (
                                        <PageInformationComponent 
                                            className="page__center-container__info-horizontal"
                                            orientation="vertical"
                                            page = { this.props.state.page.page }
                                            is_admin = { this.props.state.page.page.is_admin }
                                            onEditDescription = { () => this.props.changeSignUpLoginModal(true) }
                                            onFollow = { () => this.props.changeSignUpLoginModal(true) }
                                            onUnfollow = { () => this.props.changeSignUpLoginModal(true) }
                                            onNewPhoto={ () => this.props.changeSignUpLoginModal(true) }
                                            onFollowers={ () => this.props.changeSignUpLoginModal(true) }
                                        />
                                    )}

                                    {(this.props.state.page.page.page_type != "group" && this.props.state.page.page.is_admin !== true && this.props.state.page.page.is_member !== true &&
                                        <PageInformationComponent
                                            className="page__center-container__info-horizontal"
                                            orientation="vertical"
                                            page={this.props.state.page.page}
                                            is_admin={this.props.state.page.page.is_admin}
                                            onEditDescription={ () => this.props.changeSignUpLoginModal(true) }
                                            onFollow={ () => this.props.changeSignUpLoginModal(true) }
                                            onUnfollow={ () => this.props.changeSignUpLoginModal(true) }
                                            onNewPhoto={ () => this.props.changeSignUpLoginModal(true) }
                                            onFollowers={ () => this.props.changeSignUpLoginModal(true) }
                                        />
                                    )}
                                    
                                    {this.props.state.page.page.page_type == "group" &&
                                        <PageInformationComponent
                                        className="page__center-container__info-horizontal"
                                        orientation="vertical"
                                        page={this.props.state.page.page}
                                        is_admin={this.props.state.page.page.is_admin}
                                        onEditDescription={ () => this.props.changeSignUpLoginModal(true) }
                                        onClickMemberRequest={ () => this.props.changeSignUpLoginModal(true) }
                                        onClickCancelRequest={ () => this.props.changeSignUpLoginModal(true) }
                                        onClickLeaveGroup={ () => this.props.changeSignUpLoginModal(true) }
                                        onNewPhoto={ () => this.props.changeSignUpLoginModal(true) }
                                        onFollowers={ () => this.props.changeSignUpLoginModal(true) }
                                    />
                                    }

                                    <Tabs
                                        variant="fullWidth"
                                        className="page__center-container__tabs"
                                        value={ this.state.tab_selected }
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={ ( ev, new_value ) => { this.setState( { tab_selected: new_value } ) } }
                                        aria-label="Selection between "
                                    >
                                        {/* Con torneos */}
                                        {this.props.state.page.page?.tournaments_per_page_count > 0 && this.props.state.page.page.page_type !== "group" &&                                           
                                            <Tab label={this.props.t('tabs.tournaments')}/> /* Torneos id=1*/                                      
                                        }
                                        {this.props.state.page.page?.tournaments_per_page_count > 0 && this.props.state.page.page.page_type !== "group" &&
                                           <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/                                       
                                        }
                                        {/* Fin con torneos */}
                                        {/* Sin torneos */}
                                        {this.props.state.page.page?.tournaments_per_page_count === 0 && this.props.state.page.page.page_type !== "group" &&
                                                <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/
                                        }
                                        {this.props.state.page.page?.tournaments_per_page_count === 0 && this.props.state.page.page.page_type !== "group" &&
                                                <Tab label={this.props.t('tabs.tournaments')}/> /* Torneos id=1*/
                                        }
                                        {/* Fin sin torneos */}
                                        {/* Solo para grupos */}
                                        {this.props.state.page.page.page_type === "group" &&
                                            <Tab label={this.props.t('tabs.postTab')}/> /*Publicaciones id=0*/                                        
                                        }
                                        {/* Fin solo para grupos */}
                                        {this.props.state.page.page.page_type !== "group" &&
                                            <Tab label={this.props.t('tabs.teamsTab')}/>
                                        } { /* Equipos id=2*/}
                                        <Tab label={this.props.t('tabs.memberTab')} /> {/*Miembros id=3*/}
                                        {this.props.state.page.page.page_type !== "group" &&
                                            <Tab label={this.props.t('tabs.dataTab')} />
                                        }{/*Informacion Basica id =4*/}          
                                    
                                    </Tabs>                                    

                                    {/* Page Feed */}
                                    <TabPanel 
                                        index={ 0 } 
                                        //value={ this.props.state.page.page.page_type !== "group"? this.state.tab_selected : (this.state.tab_selected + 1) } 
                                        value={this.props.state.page.page.page_type !== "group" ? (this.props.state.page.page?.tournaments_per_page_count > 0 ? (this.state.tab_selected -1) :this.state.tab_selected) :  (this.state.tab_selected )}
                                        page={ this.props.state.page.page }
                                        handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                                        handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                                        handleClap = { () => this.props.changeSignUpLoginModal(true) }
                                        onCloseClapsModal={() => this.props.changeSignUpLoginModal(true)}
                                        loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                                        handleOnComment = { () => this.props.changeSignUpLoginModal(true)}
                                        handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                                        me_photo = { this.props.state.user.me.photo }
                                        page_events={this.props.state.page.posts}
                                        openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                                        handleReportPost={ () => this.props.changeSignUpLoginModal(true) }
                                        openBlockPostModal={() => this.props.changeSignUpLoginModal(true)}
                                        handleBlockProfile={() => this.props.changeSignUpLoginModal(true)}
                                        handleBlockPage={() => this.props.changeSignUpLoginModal(true)}
                                        changeHomeNewPostModal = { () => this.props.changeSignUpLoginModal(true) }
                                        changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }
                                        changeNewGroupPagePost = { () => this.props.changeSignUpLoginModal(true) }
                                        handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }
                                        handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }
                                        isOpen={ this.props.state.home.modals.new_post_is_open }
                                        profile = { this.props.state.profile }
                                        user = { this.props.state.user }
                                        menu_is_open = {true}    
                                        sendReaction= { () => this.props.changeSignUpLoginModal(true)}
                                        overrideReaction= { () => this.props.changeSignUpLoginModal(true)}
                                        handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                                        loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                                        deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                                        followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                                        unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                                        followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                                        unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }

                                        handleOnClickQuizAnswer={ () => this.props.changeSignUpLoginModal(true) }
                                        handleOnClickPollAnswer={ () => this.props.changeSignUpLoginModal(true) }
                                    />

                                    
                                    {/* Page Tournaments */}
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel 
                                            index={ 1 }                                             
                                            value={this.props.state.page.page?.tournaments_per_page_count > 0 ? (this.state.tab_selected + 1) :this.state.tab_selected}
                                            page={ this.props.state.page.page }
                                            page_tournaments = { this.props.state.leagueTournaments.league_tournaments }
                                            on_click_tournament_report = { (is_tournament_clicked) => { this.handleOnTournamentClicked(is_tournament_clicked) } }
                                            on_selected_report = { (on_selected_report) =>{ this.handleOnTournamentSelected(on_selected_report) } }
                                            page_events={this.props.state.page.posts}
                                            tournament_tag={this.state.tournament_tag}
                                            pagename={this.pagename}
                                            errors={this.props.state.page.errors.list_tournament_filters}
                                            onResetError={(new_errors) => this.props.loadPageErrorsAction(new_errors, 'list_tournament_filters')}
                                            handleFilterTournament={(value)=> this.handleFilterTournament(value)}
                                            handleBackButton={()=> this.handleCleanFilterTournament()}
                                            handleOnShareTournamentLinkClick={(tournament)=>{this.handleOnShareTournamentLinkClick(tournament)}}
                                        />
                                    }
                                    
                                    {this.props.state.page.page.page_type !== "group" &&
                                        //{/* Teams */}
                                        <TabPanel
                                            index={ 2 } 
                                            value={this.state.tab_selected}
                                            page={ this.props.state.page.page }
                                            pague_teams = { this.props.state.teams.page_teams}
                                            handleOnTeamInfoModal = { (team) => this.handleOnTeamInfoModal(team) }
                                            //page_events={this.props.state.page.posts}
                                        />
                                    }
                                    {/* Page Members */}
                                    <TabPanel 
                                        index={ 3 } 
                                        //value={ this.props.state.page.page.page_type !== "group"? this.state.tab_selected : (this.state.tab_selected + 1) } 
                                        value={this.props.state.page.page.page_type !== "group" ? this.state.tab_selected : (this.state.tab_selected + 2)}
                                        page={ this.props.state.page.page }
                                        is_admin = { this.props.state.page.page.is_admin }   
                                        onNewAdminMemberButton = { () => this.props.changeSignUpLoginModal(true) }
                                        onNewMemberButton = { () => this.props.changeSignUpLoginModal(true) }
                                        page_admin_members = { this.props.state.page.page.admins }
                                        page_members = { this.props.state.page.page_members }
                                        handleOnProfileClick = { () => this.props.changeSignUpLoginModal(true) }
                                        deleteAdminMemberFromPage = {() => this.props.changeSignUpLoginModal(true)}
                                        page_events={this.props.state.page.posts}                            
                                        deleteMemberFromPage = {() => this.props.changeSignUpLoginModal(true)}     
                                        page_type = { this.props.state.page.page.page_type }        
                                        kickoutGroupMember = { () => this.props.changeSignUpLoginModal(true) }    
                                        is_private = { this.props.state.page.page.is_private }                          
                                    />
                                   
                                    {/* Page Data */}
                                    {this.props.state.page.page.page_type !== "group" &&
                                        <TabPanel 
                                            index={ 4 } 
                                            value={ this.state.tab_selected } 
                                            page={ this.props.state.page.page }
                                            onNewExperiencesButton = { () => this.props.changeSignUpLoginModal(true) }
                                            onNewBasicInfoButton = { () => this.props.changeSignUpLoginModal(true) }
                                            errors = { this.props.state.page.errors }
                                            page_events={this.props.state.page.posts}
                                            openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                                            handleReportPost={ () => this.props.changeSignUpLoginModal(true) }
                                            openBlockPostModal={() => this.props.changeSignUpLoginModal(true)}
                                            handleBlockProfile={() => this.props.changeSignUpLoginModal(true)}
                                        />
                                    }                                
                                </div>
                            }

                            {   this.state.show_report_tournament &&
                                    <div className="page__center-container unauth">
                                        <LeagueTournamentReportComponent 
                                            selected_tournament = { this.state.selected_tournament }
                                            handle_back_btn={() => {this.setState({ show_report_tournament: false })}}
                                            page_events={this.props.state.leagueTournaments.tournament.posts}
                                            tournament_phases = { this.props.state.leagueTournaments.tournament.tournament_phases }
                                            tournament_active_phase = { this.props.state.leagueTournaments.tournament.tournament_phases[0] }
                                            tournament_results_table = { this.props.state.leagueTournaments.tournament.phase_results_table }                                             
                                            handleOnPhaseClick={(id_phase) => { this.handleOnPhaseClick(id_phase) }}
                                            handleOnTournamentDates={() => this.props.changeSignUpLoginModal(true)}
                                            tournament_teams={this.props.state.leagueTournaments.tournament.tournament_teams}
                                            handleOnProfileClick = { ( profile ) => { this.handleOnProfileClick( profile ) } }
                                            handleOnPageClick = { () => this.props.changeSignUpLoginModal(true) }
                                            handleMatchStatistics = { (match_id) => this.handleMatchStatistics(match_id) }
                                            match_report = { this.props.state.leagueMatches.match_report }

                                            phase_dates={this.props.state.leagueTournaments.tournament_results.tournament_dates}
                                            phase_matches_by_date={this.props.state.leagueTournaments.tournament_results?.selected_date_matches}
                                            handleOnPhaseDate = { (date) => this.handleOnPhaseDate(date)}
                                            handleOnMatchDetailModal = { (match) => this.handleOnMatchDetailModal(match) }
                                            groups_matches_by_date = {this.state.groups_matches_by_date}
                                            handleMatchPhaseError = { () => this.props.changeSignUpLoginModal(true) }
                                            //handleMatchPhaseError = { () => this.handleMatchPhaseError() }
                                            handleTeamMembersModal={()=>{this.props.changeSignUpLoginModal(true)}}
                                            //handleTeamMembersModal={(team)=>{this.handleTeamMembersModal(team)}}
                                            is_best_third={this.state.is_best_third}
                                        />
                                    </div>
                            }
                        </React.Fragment>

                    ) }

                    { ( this.props.state.page.page?.is_private == true && this.props.state.page.page?.is_member == false && this.props.state.page.page?.is_admin == false ) && (

                        <div className="page__center-container unauth">
                            <div className="page__private-message">
                                <span className="page__private-message__icon icon-lock-open"></span>
                                <p className="page__private-message__title baloa-headline-6">{this.props.t('follower.request.title')}</p>
                                <p className="page__private-message__subtitle baloa-body-2">{this.props.t('follower.request.text')}</p>
                            </div>
                        </div>

                    ) }

                    { ( this.props.state.page.page?.is_private == true && (this.props.state.page.page?.is_member == true || this.props.state.page.page?.is_admin == true )) && (

                        <div className="page__center-container unauth">
                            <PageInformationComponent
                                className="page__center-container__info-horizontal"
                                orientation="vertical"
                                page={this.props.state.page.page}
                                is_admin={this.props.state.page.page.is_admin}
                                onEditDescription={ () => this.props.changeSignUpLoginModal(true) }
                                onClickMemberRequest={ () => this.props.changeSignUpLoginModal(true) }
                                onClickCancelRequest={ () => this.props.changeSignUpLoginModal(true) }
                                onClickLeaveGroup={ () => this.props.changeSignUpLoginModal(true) }
                                onNewPhoto={ () => this.props.changeSignUpLoginModal(true) }
                                onFollowers={ () => this.props.changeSignUpLoginModal(true) }
                            />
                            <Tabs
                                variant="fullWidth"
                                className="page__center-container__tabs"
                                value={ this.state.tab_group_selected }
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={ ( ev, new_value ) => { this.setState( { tab_group_selected: new_value } ) } }
                                aria-label="Selection between "
                            >
            
                                <Tab label={this.props.t('tabs.postTab')} />
                                <Tab label={this.props.t('tabs.memberTab')} />
                                
                            </Tabs>
                            {/* Page Feed */}
                            <TabPanel 
                                index={ 0 } 
                                value={ (this.state.tab_group_selected ) } 
                                page={ this.props.state.page.page }
                                handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                                handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                                handleClap = { () => this.props.changeSignUpLoginModal(true) }
                                onCloseClapsModal={() => this.props.changeSignUpLoginModal(true)}
                                loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}
                                handleOnComment = { () => this.props.changeSignUpLoginModal(true)}
                                handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                                openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                                handleReportPost={ () => this.props.changeSignUpLoginModal(true) }
                                openBlockPostModal={() => this.props.changeSignUpLoginModal(true)}
                                handleBlockProfile={() => this.props.changeSignUpLoginModal(true)}
                                handleBlockPage={() => this.props.changeSignUpLoginModal(true)}
                                changeHomeNewPostModal = { () => this.props.changeSignUpLoginModal(true) }
                                changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }
                                changeNewGroupPagePost = { () => this.props.changeSignUpLoginModal(true) }
                                handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }
                                handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }
                                user = { this.props.state.user }
                                profile = { this.props.state.profile }
                                menu_is_open = {true}    
                                sendReaction= { () => this.props.changeSignUpLoginModal(true)}
                                overrideReaction= { () => this.props.changeSignUpLoginModal(true)}
                                handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                                loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                                deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                                followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                                unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                                followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                                unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }
                                
                                handleOnClickQuizAnswer={ () => this.props.changeSignUpLoginModal(true) }
                                handleOnClickPollAnswer={ () => this.props.changeSignUpLoginModal(true) }
                            />

                            {/* Page Members */}
                            <TabPanel 
                                index={ 3 } 
                                value={ (this.state.tab_group_selected + 2) } 
                                page={ this.props.state.page.page }
                                is_admin = { this.props.state.page.page.is_admin }   
                                onNewAdminMemberButton = { () => { this.props.changePageAddAdminToPageModalStateAction( true ) } }
                                onNewMemberButton = { () => { this.props.changePageAddMemberToPageModalStateAction( true ) } }
                                page_admin_members = { this.props.state.page.page.admins }                                
                                page_members = { this.props.state.page.page_members }
                                handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                                deleteAdminMemberFromPage = {( member )=> this.handleOnDeleteAdmin( member )}
                                deleteMemberFromPage = {( member )=> this.handleOnDeleteMember( member )}    
                                page_member_request = { this.props.state.pageGroups.member_request }    
                                page_type = { this.props.state.page.page.page_type }        
                                onAcceptButtonClick = { ( member_request_id, username ) => { this.props.acceptMemberRequest(member_request_id, username, this.pagename) } }
                                onRejectButtonClick = { ( member_request_id, username ) => { this.props.rejectMemberRequest(member_request_id, username, this.pagename, this.props.state.page?.page?.is_admin) } }
                                kickoutGroupMember = { ( username ) => { this.props.kickoutGroupMember( username, this.pagename ) } }
                                is_private = { this.props.state.page.page.is_private }             
                            />
                        </div>

                    ) }


                    <div className="page__right-container">
                        {/* <p className="home__right-container__title baloa-headline-6">{this.props.t('followSuggestions.title')}</p>
                        <ProfileSuggestionsComponent
                            profiles = { this.props.state.home.profile_suggestions.slice(0, this.number_of_showing_profiles) }
                            className = "home__right-container__profile-suggestions sm"
                            handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                            loading_profile_suggestions = { this.props.state.home.loading_profile_suggestions }
                        /> */}
                    </div>

                </div>

            </React.Fragment>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: ( username ) => {
            dispatch( loadPage( username ) )
        },
        loadPagePosts: ( username ) => {
            dispatch( loadPagePosts( username ) )
        },
        loadLeagueTournaments: ( league_name ) => {
            dispatch( loadLeagueTournaments(  league_name ) )
        },
        loadTournamentPhases:( tournament_id ) => {
            dispatch( loadTournamentPhases( tournament_id ) )
        },
        loadTournamentTeams:( tournament_id ) => {
            dispatch( loadTournamentTeams( tournament_id ) )
        },
        loadTournamentPhaseGroupDates:(tournament_id) => {
            dispatch( loadTournamentPhaseGroupDates(tournament_id) )
        },
        loadPageTeams:( page_name ) => {
            dispatch ( loadPageTeams( page_name) );
        }, 
        changePageExperiencesModalStateAction: ( new_modal_state ) => {
            dispatch( changePageExperiencesModalStateAction( new_modal_state ) )
        },
        changePageUploadPhotoModalStateAction: ( new_modal_state ) => {
            dispatch( changePageUploadPhotoModalStateAction( new_modal_state ) )
        },
        changePageDescriptionModalStateAction: ( new_modal_state ) => {
            dispatch( changePageDescriptionModalStateAction( new_modal_state ) )
        },
        createOrEditAttributeOnPage: ( page, new_attribute_data ) => {
            dispatch( createOrEditAttributeOnPage( page, new_attribute_data ) )
        },
        loadPageErrorsAction: ( errors, section ) => {
            dispatch( loadPageErrorsAction( errors, section ) )
        },
        deleteAttribute: ( page, attribute_data ) => {
            dispatch( deleteAttribute( page, attribute_data ) )
        },
        followPage: ( page ) => {
            dispatch( followPage( page ) )
        },
        unfollowPage: ( page ) => {
            dispatch( unfollowPage( page ) )
        },
        loadPageFollowers: ( page ) => {
            dispatch( loadPageFollowers( page ))
        },
        changePageFollowersModalStateAction: ( new_modal_state ) => {
            dispatch( changePageFollowersModalStateAction( new_modal_state ) )
        },
        followPageByPagename: ( page_pagename, pagename ) => {
            dispatch( followPageByPagename ( page_pagename, pagename ))
        },
        unfollowPageByPagename: ( page_pagename, pagename ) => {
            dispatch( unfollowPageByPagename ( page_pagename, pagename ))
        },
        followProfileByPagename: ( profile_username, pagename) => {
            dispatch( followProfileByPagename( profile_username, pagename) )
        },
        unfollowProfileByPagename: ( profile_username, pagename ) => {
            dispatch( unfollowProfileByPagename( profile_username, pagename) )
        },
        changePageAddMemberToPageModalStateAction: ( new_modal_state ) => {
            dispatch( changePageAddMemberToPageModalStateAction( new_modal_state ) )
        },
        changePageAddAdminToPageModalStateAction: ( new_modal_state ) => {
            dispatch( changePageAddAdminToPageModalStateAction( new_modal_state ) )
        },
        addAdminToPage: ( page_name, member_username ) => {
            dispatch( addAdminToPage( page_name, member_username ) )
        },
        addMemberToPage: ( page_name, member_username ) => {
            dispatch( addMemberToPage( page_name, member_username ) )
        },
        loadPageMembers: ( page_name ) => {
            dispatch( loadPageMembers( page_name ) )
        },
        searchMembersPage: ( searched_profile ) => {
            dispatch( searchMembersPage( searched_profile ) )
        },
        removeMemberOfPage: ( page_name, username) => {
            dispatch( removeMemberPage( page_name, username ) )
        },
        removeAdminOfPage: ( page_name, username ) => {
            dispatch( removeAdminPage( page_name, username ) )
        },
        clapPost: ( post ) => {
            return dispatch( clapPost( post ) )
        },
        changePostClapsModalStateAction: ( new_modal_state ) => {
            dispatch( changePostClapsModalStateAction( new_modal_state ) )
        },
        loadPostClapsById: ( post_id ) => {
            dispatch( loadPostClapsById( post_id ))
        },  
        commentPostById: ( post_id, comment, pagename, page_id ) => {            
            return dispatch( commentPostById( post_id, comment, pagename, page_id ))
        },  
        changeProfileNationalityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileNationalityModalStateAction( new_modal_state ) )
        },   
        searchProfileLocations: ( nationality, new_location_search ) => {
            return dispatch( searchProfileLocations( nationality, new_location_search ) )
        },
        searchProfileNationality: (new_nationality_search) => {
            return dispatch( searchProfileNationality( new_nationality_search ) )
        },
        addPageNationality: ( page, new_nationality_data ) => {            
            return dispatch( addPageNationality( page, new_nationality_data ) )
        },
        loadPhaseResultsTable: ( phase_id ) => {            
            return dispatch( loadPhaseResultsTable( phase_id ) )
        },   
        switchProfileToPage: ( page ) => {
            return dispatch( switchProfileToPage( page ) )
        },
        makeMemberRequest: ( pagename ) => {
            return dispatch( makeMemberRequest( pagename ) )
        },
        changeCancelMemberRequestDialogModalStateAction: ( new_modal_state ) => {
            return dispatch( changeCancelMemberRequestDialogModalStateAction( new_modal_state ) )
        },
        checkMemberRequest: ( pagename ) => {
            return dispatch( checkMemberRequest( pagename ) )
        },
        acceptMemberRequest: ( member_request_id, username, pagename ) => {
            return dispatch( acceptMemberRequest( member_request_id, username, pagename ) )
        },
        rejectMemberRequest: ( member_request_id, username, pagename, is_admin ) => {
            return dispatch( rejectMemberRequest( member_request_id, username, pagename, is_admin ) )
        },
        leaveGroup: ( pagename, username ) => {
            return dispatch( leaveGroup( pagename, username ) )
        },
        kickoutGroupMember: ( username, pagename ) => {
            return dispatch( kickoutGroupMember( username, pagename ) )
        },
        changeHomeNewPostModal: ( new_modal_state ) => {
            return dispatch( changeHomeNewPostModal( new_modal_state ) );
        },
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) );
        },
        changeNewGroupPagePost: ( new_state ) => {
            return dispatch( changeNewGroupPagePost( new_state ) );
        },
        followProfile: ( profile ) => {
            return dispatch( followProfile( profile ) )
        },
        deletePost: ( post ) => {
            return dispatch( deletePost( post ) )
        },
        loadTournamentPosts: ( pagename, tournament_id, state, page_type ) => {
            return dispatch( loadTournamentPosts( pagename, tournament_id, state, page_type ) )
        },
        loadMatchReport: ( match_id ) => {
            return dispatch( loadMatchReport( match_id ) );
        },
        addEventAction: ( data ) => {
            return dispatch( addEventAction( data ) )
        },
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        setUrlRedirect: ( url_redirect ) => {
            return dispatch( setUrlRedirect( url_redirect ) );
        },
        getTeamPlayers: ( teamname ) => {
            dispatch( getTeamPlayers( teamname ) );
        },
        loadTeamData: ( teamname ) => {
            dispatch( loadTeamData( teamname ) );
        },
        changeTeamMemberDetailModalStateAction: (new_modal_state) => {
            dispatch(changeTeamMemberDetailModalStateAction(new_modal_state))
        },
        loadPhaseMatchesByDate: async ( phase_id, calendar_date ) => {
            return await dispatch( loadPhaseMatchesByDate( phase_id, calendar_date ))
        },
        loadPhaseDates: async ( phase_id ) => {
            return await dispatch( loadPhaseDates( phase_id ))
        },
        changeMatchDetailModalStateAction: (new_modal_state) => {
            dispatch(changeMatchDetailModalStateAction(new_modal_state))
        },
        changeSelectTeamInscriptionModalStateAction: ( new_state ) => {
            return dispatch( changeSelectTeamInscriptionModalStateAction( new_state ) )
        },
        loadPageInscriptionTeams: (pagename) => {
            dispatch(loadPageInscriptionTeams(pagename))
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        loadfilterTournament: ( league_pagename, type_filter ) => {
            dispatch(loadfilterTournament( league_pagename, type_filter ));
        },
    }
}

export default compose(
    withTranslation(["page", "common"]),
    connect(mapStateToProps, mapDispatchToProps)
)(PageUnauthenticatedView);

