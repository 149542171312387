// React
import React from 'react';
import CurrencyFormat from 'react-currency-format';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Moment
import * as moment from 'moment';

// Styles
import './LeagueMatchRefereesPaymentModal.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueMatchRefereesPaymentModal( props ) {

    const handleOnRefereeTeamPayment = (team_type) => {        
        if(team_type === 'local') {
            let team_obj = {
                match_id: props.match_data.id,
                team: props.match_data.local_team,
                team_name: props.match_data.local_team_name,
                refereeing_value_team: props.match_data.refereeing_value_local_team,
                team_photo: props.match_data.local_team_photo,
                team_type: team_type,
            }
            props.handleOnRefereeTeamPayment(team_obj);
        }
        if(team_type === 'visitant') {
            let team_obj = {
                match_id: props.match_data.id,
                team: props.match_data.visitant_team,
                team_name: props.match_data.visitant_team_name,
                refereeing_value_team: props.match_data.refereeing_value_visitant_team,
                team_photo: props.match_data.visitant_team_photo,
                team_type: team_type,
            }
            props.handleOnRefereeTeamPayment(team_obj);
        }
    } 

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.matchRefereesPayment.refereePayment')}
            className="small scroll-parent"
        >
            <div className="league-match-referees-payment__container scroll">
                <div className='league-match-referees-payment__match-info'>
                    <div className='league-match-referees-payment__match-info__title baloa-table-column'>{`${props.t(`options.tournaments.pages.matchRefereesPayment.${props.match_data.date_match}`)} (${moment(props.match_data.match_date).format("DD MMM YYYY")})`}</div>
                    <div className='league-match-referees-payment__match-info__teams'>
                        <div className='league-match-referees-payment__match-info__team'>
                            <div className='league-match-referees-payment__match-info__team-img'>
                                { props.match_data.local_team_photo && <img src={ props.match_data.local_team_photo } alt={`${props.match_data.local_team} img`}/>}
                                { !props.match_data.local_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </div>
                            <div className='league-match-referees-payment__match-info__team-name'>
                                <div className='baloa-names'>{props.match_data.local_team_name}</div>
                                <div className='baloa-username'>@{props.match_data.local_team}</div>
                            </div>
                        </div>
                        <div className='baloa-table-column'>vs</div>
                        <div className='league-match-referees-payment__match-info__team'>
                            <div className='league-match-referees-payment__match-info__team-name'>
                                <div className='baloa-names'>{props.match_data.visitant_team_name}</div>
                                <div className='baloa-username'>@{props.match_data.visitant_team}</div>
                            </div>
                            <div className='league-match-referees-payment__match-info__team-img'>
                                { props.match_data.visitant_team_photo && <img src={ props.match_data.visitant_team_photo } alt={`${props.match_data.visitant_team} img`}/>}
                                { !props.match_data.visitant_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </div>
                        </div>
                    </div>
                </div>
                { (props.match_data.role_tournament === 'Owner' || props.match_data.role_tournament === 'Administrator' || props.match_data.role_match === 'Assigner' || props.match_data.role_tournament === 'Assigner') &&
                    <div className='league-match-referees-crew__container'>
                        {!props.match_data.transfer_enabled && props.match_data.tournament_match_state_payment === 'Pending' &&
                            <div className='league-match-referees-crew__warning'>
                                <span className='hubicon-info' />
                                <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.refereesCrewWarning')}</div>
                            </div>
                        }

                        { props.match_data.tournament_match_state_payment === 'Pending_Instant_Payment' &&
                            <div className='league-match-referees-crew__inf_pending_instant_payment'>
                                <span className='hubicon-schedule' />
                                <div className='baloa-table'>
                                    {props.t('options.tournaments.pages.matchRefereesPayment.refereeingPendingPaymentsSubText')}
                                </div>
                            </div>
                        }
                        <div className='league-match-referees-crew__info-text baloa-table'>
                            {props.t('options.tournaments.pages.matchRefereesPayment.refereesCrewInfo')}
                        </div>
                        <div className='league-match-referees-crew__referees-table__container'>
                            <div className='league-match-referees-crew__referees-table'>
                                <div className='league-match-referees-crew__referees-table__header'>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewName')}</div>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewRole')}</div>
                                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewFee')}</div>
                                </div>
                                <div className='league-match-referees-crew__referees-table__body'>
                                    { props.match_data.referees?.map((referee, index) => (
                                        <div key={referee.referee_username} className={`league-match-referees-crew__referees-table__body-referee ${index === (props.match_data.referees?.length - 1) ? 'last' : ''}`}>
                                            <div className='league-match-referees-payment__match-info__referee'>
                                                <div className='league-match-referees-payment__match-info__referee-img'>
                                                    { referee.referee_photo && <img src={ referee.referee_photo } alt={`${referee.referee_username} img`}/>}
                                                    { !referee.referee_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                                </div>
                                                <div className='league-match-referees-payment__match-info__referee-name'>
                                                    <div className='baloa-names'>{referee.referee_name}</div>
                                                    <div className='baloa-username'>@{referee.referee_username}</div>
                                                </div>
                                            </div>
                                            <div className='league-match-referees-payment__role__referee'>
                                                <div className='baloa-names'>{props.t(`options.tournaments.pages.matchRefereesPayment.${referee.referee_type}`)}</div>
                                            </div>
                                            <div className='league-match-referees-payment__payment-table__body__team-fee'>
                                                { !referee.gateway_account &&
                                                    <div className='league-match-referees-payment__payment-table__body__tooltip baloa-hyperlink-2'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.refereeingInactivePaymentsText')}
                                                    </div>
                                                }
                                                { referee.gateway_account && referee.instance_payout_status === 'Pending' && props.match_data.tournament_match_state_payment === 'Pending_Instant_Payment' &&
                                                    <div className='league-match-referees-payment__payment-table__body__tooltip baloa-hyperlink-2'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.refereeingPendingPaymentsText')}
                                                    </div> 
                                                }
                                                { referee.gateway_account && referee.instance_payout_status === 'Done' && referee.instance_payout_date &&
                                                    <div className='league-match-referees-payment__payment-table__body__tooltip baloa-hyperlink-2'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.refereeingSuccessfulPayment',
                                                            { month : moment(referee.instance_payout_date).format("DD MMM YYYY"),
                                                            time: moment.parseZone(referee.instance_payout_date).format("hh:mm a") }
                                                        )}
                                                    </div> 
                                                }
                                                <CurrencyFormat
                                                    value={referee.referee_value} 
                                                    displayType={'text'} 
                                                    thousandSeparator={','} 
                                                    decimalSeparator={"."} 
                                                    prefix={'$ '} 
                                                    suffix={' ' + props.tournament_currency}
                                                    onValueChange ={()=>{}}
                                                    renderText={value => <div className={`baloa-names ${!referee.gateway_account ? 'no-account' : ''} `}>{value}</div>}
                                                />
                                                { !referee.gateway_account &&
                                                    <div className='baloa-table-column'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.refereeingInactivePayments')}
                                                    </div>
                                                }
                                                { referee.gateway_account && referee.instance_payout_status === 'Pending' && props.match_data.tournament_match_state_payment === 'Pending_Instant_Payment' &&
                                                    <div className='baloa-payout_status_pending baloa-table-column'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.refereeingPendingPayments')}
                                                    </div>
                                                }
                                                { referee.gateway_account && referee.instance_payout_status === 'Done' &&
                                                    <div className='league-match-referees-payment__done baloa-table-column'>
                                                        {props.t('options.tournaments.pages.matchRefereesPayment.paidLabel')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                    }
                                    { props.match_data.referees.length === 0 &&
                                        <div className='league-match-referees-crew__referees-table__body-empty'>
                                            <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewEmptyTable')}</div>
                                            <SecondaryButtonComponent onClick={() => { props.handleOnRefereesAssignmentBtn() }}>{props.t('options.tournaments.pages.matchRefereesPayment.assignRefereeBtn')}</SecondaryButtonComponent>
                                        </div>
                                    }
                                    { 
                                        <div className='league-match-referees-crew__referees-table__total-amount__container'>
                                            { (props.match_data.referees.length === 0 || props.match_data.value_collected !== 0) &&
                                                <div className='league-match-referees-crew__referees-table__total-amount__warning'>
                                                    <span className='hubicon-info' />
                                                    <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewInfoAmount')}</div>
                                                </div>
                                            }
                                            { (props.match_data?.refereeing_value_local_team !== 0 || props.match_data?.refereeing_value_visitant_team !== 0) &&
                                                <div className='league-match-referees-crew__referees-table__total'>
                                                    <div className='league-match-referees-crew__referees-table__total-fee'>
                                                        <CurrencyFormat
                                                            value={props.match_data.value_collected_in_match}
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={`$`} 
                                                            suffix={` ${props.tournament_currency}`}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className='baloa-names'>{value}</div>}
                                                        />&nbsp;
                                                        <CurrencyFormat
                                                            value={props.match_data.total_value_in_match}
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={` ${props.t('options.tournaments.pages.matchRefereesPayment.refereesTotalRaised')} $`} 
                                                            suffix={' ' + props.tournament_currency}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className='baloa-names sub-color'>{value}</div>}
                                                        />
                                                    </div>
                                                    <div className='league-match-referees-crew__referees-table__total-remaining'>
                                                        <CurrencyFormat
                                                            value={props.match_data.remaining_value}
                                                            displayType={'text'} 
                                                            thousandSeparator={','} 
                                                            decimalSeparator={"."} 
                                                            prefix={ `${props.t('options.tournaments.pages.matchRefereesPayment.refereesRemainingAmountPrefix')} $`} 
                                                            suffix={` ${props.tournament_currency} ${props.t('options.tournaments.pages.matchRefereesPayment.refereesRemainingAmountSuffix')} `}
                                                            onValueChange ={()=>{}}
                                                            renderText={value => <div className='baloa-table'>{value}</div>}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    { props.match_data.tournament_match_state_payment === 'Pending' && 
                                        <PrimaryButtonComponent
                                            disabled={!props.match_data.transfer_enabled} 
                                            onClick={() => {props.handleOnConfirmCrewPayment()}}>
                                                {props.t('options.tournaments.pages.matchRefereesPayment.refereeCrewPaymentBtn')}
                                        </PrimaryButtonComponent>}
                                    { props.match_data.tournament_match_state_payment === 'Pending_Instant_Payment' &&
                                        <div className='league-match-referees-payment__pending_instant_payments-label' >
                                            <span className='hubicon-schedule' />
                                            <div className='baloa-names'>
                                                {props.t('options.tournaments.pages.matchRefereesPayment.refereeingPendingPayments')}
                                            </div>
                                        </div>
                                    }
                                    { props.match_data.tournament_match_state_payment === 'Done' && 
                                        <div className='league-match-referees-payment__confirmation-label' >
                                            <div className='baloa-names'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingCrewConfirmationLabel')}</div>
                                            <span className='hubicon-check_circle' />
                                        </div>
                                    }
                                        
                                </div>
                            </div>                            
                        </div>
                    </div>
                }
                <div className='league-match-referees-payment__info-text baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.matchInfoLabel')}</div>
                <div className='league-match-referees-payment__payment-table__container'>
                    <div className='league-match-referees-payment__payment-table__header'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.matchTeam')}</div>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.teamFee')}</div>
                        <div className='baloa-table-column'></div>
                    </div>
                    <div className='league-match-referees-payment__payment-table__body'>
                        <div className='league-match-referees-payment__payment-table__body__team'>
                            <div className='league-match-referees-payment__match-info__team'>
                                <div className='league-match-referees-payment__match-info__team-img'>
                                    { props.match_data.local_team_photo && <img src={ props.match_data.local_team_photo } alt={`${props.match_data.local_team} img`}/>}
                                    { !props.match_data.local_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                </div>
                                <div className='league-match-referees-payment__match-info__team-name'>
                                    <div className='baloa-names'>{props.match_data.local_team_name}</div>
                                    <div className='baloa-username'>@{props.match_data.local_team}</div>
                                </div>
                            </div>
                            <div className='league-match-referees-payment__payment-table__body__team-fee baloa-names'>
                                <CurrencyFormat
                                    value={props.match_data.refereeing_value_local_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} 
                                    suffix={' ' + props.tournament_currency}
                                    onValueChange ={()=>{}}
                                    renderText={value => <div className='baloa-hyperlink-2'>{value}</div>}
                                />
                            </div>
                            <div className='league-match-referees-payment__payment-table__body__team-btn'>
                                { props.match_data.refereeing_payment_local_team_state === 'Pending' && 
                                    <PrimaryButtonComponent onClick={ () => {handleOnRefereeTeamPayment('local')} }>
                                        {props.t('options.tournaments.pages.matchRefereesPayment.payBtn')}
                                    </PrimaryButtonComponent> 
                                }
                                { props.match_data.refereeing_payment_local_team_state === 'Done' && 
                                <div className='league-match-referees-payment_team-tooltip'>
                                    <div className='league-match-referees-payment_team_body__tooltip baloa-hyperlink-2'>
                                        {/* time: moment((props.match_data.refereeing_payment_local_team_date).split('T')[1], ["HH.mm"]).format("hh:mm a")*/}
                                        {props.match_data.refereeing_payment_local_team_date && 
                                            props.t('options.tournaments.pages.matchRefereesPayment.refereeingSuccessfulPayment',
                                            { month : moment(props.match_data.refereeing_payment_local_team_date).format("DD MMM YYYY"),
                                            time: moment.parseZone((props.match_data.refereeing_payment_local_team_date)).format("hh:mm a") }
                                        )}
                                    </div> 
                                    <div className='league-match-referees-payment__paid baloa-table-column'>
                                        {props.t('options.tournaments.pages.matchRefereesPayment.paidLabel')}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='league-match-referees-payment__payment-table__body__team'>
                            <div className='league-match-referees-payment__match-info__team'>
                                <div className='league-match-referees-payment__match-info__team-img'>
                                    { props.match_data.visitant_team_photo && <img src={ props.match_data.visitant_team_photo } alt={`${props.match_data.visitant_team} img`}/>}
                                    { !props.match_data.visitant_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                </div>
                                <div className='league-match-referees-payment__match-info__team-name'>
                                    <div className='baloa-names'>{props.match_data.visitant_team_name}</div>
                                    <div className='baloa-username'>@{props.match_data.visitant_team}</div>
                                </div>
                            </div>
                            <div className='league-match-referees-payment__payment-table__body__team-fee baloa-names'>
                                <CurrencyFormat
                                    value={props.match_data.refereeing_value_visitant_team} 
                                    displayType={'text'} 
                                    thousandSeparator={','} 
                                    decimalSeparator={"."} 
                                    prefix={'$ '} 
                                    suffix={' ' + props.tournament_currency}
                                    onValueChange ={()=>{}}
                                    renderText={value => <div className='baloa-hyperlink-2'>{value}</div>}
                                />
                            </div>
                            <div className='league-match-referees-payment__payment-table__body__team-btn'>
                                { props.match_data.refereeing_payment_visitant_team_state === 'Pending' && 
                                    <PrimaryButtonComponent onClick={ () => {handleOnRefereeTeamPayment('visitant')} }>
                                        {props.t('options.tournaments.pages.matchRefereesPayment.payBtn')}
                                    </PrimaryButtonComponent>
                                }
                                { props.match_data.refereeing_payment_visitant_team_state === 'Done' &&
                                <div className='league-match-referees-payment_team-tooltip'>
                                    <div className='league-match-referees-payment_team_body__tooltip baloa-hyperlink-2'>
                                        {props.match_data.refereeing_payment_visitant_team_date &&
                                            props.t('options.tournaments.pages.matchRefereesPayment.refereeingSuccessfulPayment',
                                            { month : moment(props.match_data.refereeing_payment_visitant_team_date).format("DD MMM YYYY"),
                                            time: moment.parseZone(props.match_data.refereeing_payment_visitant_team_date).format("hh:mm a") }
                                            )
                                        }
                                    </div> 
                                    <div className='league-match-referees-payment__paid baloa-table-column'>
                                        {props.t('options.tournaments.pages.matchRefereesPayment.paidLabel')}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Modal2Component>
    )
}

export default withTranslation('league')(LeagueMatchRefereesPaymentModal);
