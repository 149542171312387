// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import SignUpComponent from 'app/authentication/components/signup/SignUpComponent';

// Styles
import './NewAccountView.styles.css';

// Actions
import { changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

class NewAccountView extends Component {

    componentDidMount(){
        this.props.changeSignUpStatus(SIGNUP_STATUS['START'])
    }

    render = () => {

        return (
            <React.Fragment>
                <SignUpComponent 
                    signup_status={ this.props.state.signup.status } 
                    location_pathname={ this.props.location.pathname} 
                />
            </React.Fragment>
        )
    }
}

// NewAccount.propTypes = {
//     appTitle: PropTypes.string,
// }

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeSignUpStatus: (value) => {
            return dispatch(changeSignUpStatus(value))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewAccountView);
