// React
import React from 'react';


//Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';

//i18
import { withTranslation } from 'react-i18next';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './TournamentFixtureAvialableDatesComponent.styles.css';

function TournamentFixtureAvialableDatesComponent(props) {   
    return (
        <div className='phases_round_fixture_selector_modal__days'>
            <div className='baloa-table-column'><span className='hubicon-event_available'></span>{props.t('options.tournaments.automaticFixturesModal.availableDatesTitle')}</div>
            <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.availableDatesMsg')}</div>
            <div className='phases_round_fixture_selector_modal__days-container'>
                {props.fixtureAvailableDays?.length > 0 ?
                    props.fixtureAvailableDays?.map(date => 
                        <div className='phases_round_fixture_selector_modal__days-tag'>
                            <div className='phases_round_fixture_selector_modal__days-tag__date-info'>
                                <div className='baloa-username'>{props.t(`options.tournaments.pages.tournamentInfo.tabs.tabBasicData.${date.day}`)}</div>
                                <div className='baloa-mini'>{`${props.t('options.tournaments.automaticFixturesModal.availableDatesFrom')} ${moment(date.start_time, ["HH:mm:ss"]).format("hh:mm a")} ${props.t('options.tournaments.automaticFixturesModal.availableDatesTo')} ${moment(date.end_time, ["HH:mm:ss"]).format("hh:mm a")}`}</div>
                            </div>
                            <span className='hubicon-clear_option phases_round_fixture_selector_modal__days-tag__button' onClick={()=>{ props.handleOnDeleteFixtureAvailableDay(date)}} />
                        </div>
                    )
                    :
                    ''
                }
                <RoundedButtonComponent
                    className="phases_round_fixture_selector_modal__days__button small"
                    type='button'
                    onClick={ ()=>{ props.handleOnAddAvailableDates() } }
                >
                    <span className='hubicon-add_option icon'></span>
                    <span>{props.t('options.tournaments.automaticFixturesModal.availableDatesAddScheduleBtnLbl')}</span>
                </RoundedButtonComponent>
            </div>
        </div>
    )
}

export default  withTranslation('league')(TournamentFixtureAvialableDatesComponent);