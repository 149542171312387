// React
import React from 'react';

// Styles
import './EditInformationComponent.styles.css';

//component
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

//i18
import { withTranslation } from 'react-i18next';


function EditInformationComponent ( props ) {

    const [fieldValue, setFieldValue] = React.useState(props.value);
    
    const formInitialValues = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone: '',

    }

    function info (values){
        
        if(values.first_name !== ""){
            let new_data = { 
                "first_name": values.first_name, 
            }
            props.onNewData( props.label, new_data );                
        }else if(values.last_name !== ""){
            let new_data = { 
                "last_name": values.last_name, 
            }
            props.onNewData( props.label, new_data );
        }else if(values.username !== ""){
            let new_data = { 
                "username": values.username, 
            }
            props.onNewData( '@',new_data );
        }else if(values.email !== ""){
            let new_data = { 
                "email": values.email, 
            }
            props.onNewData( props.label ,new_data );
        }else if(values.phone !== ""){
            let new_data = { 
                "phone": values.phone, 
            }
            props.onNewData( props.label ,new_data );
        }
    }

    const handleChange=(value)=>{
        setFieldValue(value);
    }

    return(
        <div className="info">
            <div className="info__content">
                <div className="info__content__icon"
                onClick={() => {
                    props.data_edit(0);
                }}
                >
                    <span className="icon-arrow-left"></span>
                </div>
                <div className="info__content__title">
                    <span className="info__content__title__text baloa-subtitle-2">
                        {props.label === "usuario" ? props.t('settings.textEdit')+" "+props.label : props.t('settings.textEdit2')+" "+props.label}
                    </span>
                </div>
            </div>
            <div className="info__form">
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        info(values);
                    }}
                    className="info__form__form form"
                    functionDisabled={ ()=>{} }
                >
                    <InputFieldComponent 
                        label={props.label} 
                        input_id={props.input_id}
                        field_name={props.field_name}
                        validationFunction={ props.validationFunction }
                        disabled={false}
                        className="info__form__form__input"
                        input_type="text"
                        resetError={ () =>props.resetError( {'field':props.field_name, 'error':''} ) }
                        error={ props.error }
                        message=""
                        placeholder=""
                        value={fieldValue}
                        onChange={handleChange}
                    />
                    <div className="info__form__form__actions ">
                    <SecondaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}}
                    >
                        <span>{props.t('settings.btnSave')}</span>
                    </SecondaryButtonComponent>
                    </div>
                </FormComponent>
            </div> 
            <div className="info__recommendations">
                <p className="info__recommendations__text">
                    {props.label === "usuario" ? props.t('settings.textRecommend') : ''}
                </p>
            </div>
            
        </div>
    )
}

export default withTranslation('profile')(EditInformationComponent);