/*eslint eqeqeq: "off"*/
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent'; 
import ScrollTrigger from 'react-scroll-trigger';
//import PostComponentV2 from 'app/posts/components/post/PostComponentV2';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Assets
import explore_pages_empty from 'assets/images/explore_pages_empty.png';

// Styles
import './SearchResultComponent.styles.css'; 

 // i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function SearchResultComponent(props) {
    const [showRecords, setShowRecords] = React.useState(4);

    React.useEffect( () => {
        if(props?.search_path?.split('&').length > 1){
            setShowRecords(undefined); 
        }

    }, [ props.search_path ] )

    const searchName = props.keyword.replace('%23', '#').trim();

    let personas = props.search_results
        ? props.search_results.profiles? 
                props.search_results.profiles
                :   
                props.search_results?.filter(res => {
                    return res.is_type === "profile";
                })
        : {}
    let pages = props.search_results
        ? props.search_results.pages?
            props.search_results.pages
            :
            props.search_results?.filter(res => {
                return res.is_type === "page";
            })
        : {}

    let teams = props.search_results
        ? props.search_results.teams?
            props.search_results.teams
            :
            props.search_results?.filter(res => {
                return res.is_type === "team";
            })
        : {}

    let tournaments = props.search_results
        ? props.search_results.tournaments?
            props.search_results.tournaments
            :
            props.search_results?.filter(res => {
                return res.is_type === "tournament";
            })
        : {}

    let posts = props.search_results
        ? props.search_results.posts?
            props.search_results.posts
            :
            props.search_results?.filter(res => {
                return res.is_type === "post";
            })
        : {}

    const [activeResult, setActiveResult]= React.useState(undefined);

    React.useEffect( () => {
        // Need more pages?
        if(props.search_results_next && (activeResult == props.search_results[ props.search_results.length -1 ] || activeResult == props.search_results[ props.search_results.length -2 ] || activeResult == props.search_results[ props.search_results.length -3 ]) ) { 
            props.fetchMoreData();
        }
    }, [ activeResult ] );// eslint-disable-line
    
    return (
        <div className={"search-result " + (props.className ? props.className : '')}>
            {!isMobile &&
                <React.Fragment>
                    <span className={"baloa-subtitle-2 search-result__result__subtitle"}>{props.t('subTitle')}</span>
                    <h1 className={" baloa-headline-2 search-result__result__searchtitle"}>"{searchName}"</h1>
                </React.Fragment>
            }
            
            {/** Profiles */}
            {personas.length > 0 &&
                <div className={`search-result__cat-personas ${props.filter === 'profile'? 'filtered' : ''}`}>
                    {props.filter !== 'profile' &&
                        <div className={"search-result__cat-personas__options"}>
                            <span className={"baloa-subtitle-2"}>{props.t('people')}</span>
                            {showRecords &&
                                <Link to={`/search/search=${props.keyword}&filter=profile`}>
                                <span className={'baloa-subtitle-2'}>{props.t('seeAll')}</span>
                                </Link>
                            }
                        </div>
                    }
                    <div className={`search-result__cat-personas__buttons ${props.filter === 'profile'? 'filtered' : ''}`}>
                        <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('profile')}}>
                            <span className="icon-player"/>
                            <div className="baloa-names" >
                            {props.t('home:quickAccess.searchIn')}{props.t('home:quickAccess.players')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div>
                        {/* <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('professionals')}}>
                            <span className="icon-coach"/>
                            <div className="baloa-names" >
                                {props.t('home:quickAccess.professionals')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div> */}
                    </div>
                    {personas.slice(0, showRecords).map(result =>                        
                        <ScrollTrigger 
                            onEnter = { () => { 
                                setActiveResult( result );
                            } }
                            onExit = { () => { } }
                            key={result.id}
                            className={`search-result__result ${props.filter === 'profile'? 'filtered' : ''}`}
                        >
                            <Link to={`/profile/${result.username}`} className="search-result__result__profile-info">
                                <div className={"search-result__result__profile-info__photo"}>
                                    {result.image
                                    ? <img src={result.image} alt="" />
                                    : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                </div>
                                <div className={"search-result__result__profile-info__data"}>
                                    <p className={"baloa-names"}>{result.full_name}</p>
                                    <p className={"baloa-username"}>@{result.username}</p>
                                </div>
                            </Link>
                            <div className="search-result__result__follow-button">                
                                {isMobile
                                    ?
                                        result.is_following?
                                            <span className="hubicon-following_respon" onClick = {() => { props.handleUnFollowProfile( result.username ) }} >
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                            </span>
                                            :
                                            <span className="hubicon-follow_respon" onClick = {() => { props.handleFollowProfile( result.username ) }} >
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>                                    
                                    :
                                    <SecondaryButtonComponent
                                        onClick={
                                            result.is_following?
                                            () => {props.handleUnFollowProfile( result.username )}
                                            :
                                            () => {props.handleFollowProfile( result.username )}
                                        }
                                        className={result.is_following? 'following' : ''}
                                    >
                                        {result.is_following?
                                            <span className='hubicon-check icon' />
                                            :
                                            <span className='hubicon-add_option icon' />
                                        }
                                        {result.is_following?
                                            <span>{props.t('following')}</span>
                                            :
                                            <span>{props.t('follow')}</span>
                                        }
                                    </SecondaryButtonComponent>
                                }
                            </div>
                        </ScrollTrigger>                   
                    )}
                </div>
            }
            {personas.length === 0 && props.filter === 'profile' &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            }
            
            {/** Torneos */}
            {tournaments.length > 0 &&
                <div className={`search-result__cat-personas ${props.filter === 'tournament'? 'filtered' : ''}`}>
                    {props.filter !== 'tournament' && 
                        <div className={"search-result__cat-personas__options"}>
                            <span className={"baloa-subtitle-2"}>{props.t('tournaments')}</span>
                            {showRecords &&
                            <Link to={`/search/search=${props.keyword}&filter=tournament`}>
                                <span className={'baloa-subtitle-2'}>{props.t('seeAll')}</span>
                            </Link>
                            }
                        </div>
                    }
                    <div className={`search-result__cat-personas__buttons ${props.filter === 'tournament'? 'filtered' : ''}`}>
                        <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('tournament')}}>
                            <span className="hubicon-tournaments">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <div className="baloa-names">
                                {props.t('home:quickAccess.searchIn')}{props.t('home:quickAccess.tournamentAccess')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div>
                    </div>
                    {tournaments.slice(0, showRecords).map(result =>
                        <ScrollTrigger 
                            onEnter = { () => { 
                                setActiveResult( result );
                            } }
                            onExit = { () => { } }
                            key={result.id}
                            className={`search-result__result ${props.filter === 'tournament'? 'tournament' : ''}`}
                        >
                            {!props.filter &&
                                <React.Fragment>
                                    <Link to={`/page/${result.pagename}&setshowtournament=${result.id}&showtournament=true`} className="search-result__result__profile-info">
                                        <div className={"search-result__result__profile-info__photo"}>
                                            {result.tournament_photo
                                            ? <img src={result.tournament_photo} alt="" />
                                            : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                        </div>
                                        <div className={"search-result__result__profile-info__data"}>
                                            <p className={"baloa-names"}>{result.name}</p>
                                            <div className='search-result__result__data-tags'>
                                                {result.tournament_type && <div className='search-result__result__data-tag baloa-username'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.tournament_type)}</div>}
                                                {result.gender && <div className='search-result__result__data-tag baloa-username'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.gender)}</div>}
                                                {result.category && <div className='search-result__result__data-tag baloa-username'>{props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams."+result.category)}</div>}
                                            </div>
                                        </div>
                                    </Link>              
                                    <div className="search-result__result__follow-button"> 
                                    {isMobile
                                        ?
                                            result.is_following?
                                                <span className="hubicon-following_respon" onClick = {() => { props.handleUnFollowTournament(result.id) }} >
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                    <span className="path3"></span>
                                                    <span className="path4"></span>
                                                </span>
                                                :
                                                <span className="hubicon-follow_respon" onClick = {() => { props.handleFollowTournament(result.id) }} >
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                    <span className="path3"></span>
                                                </span>
                                        :
                                        <SecondaryButtonComponent
                                        onClick={
                                            result.is_following?
                                            () => {props.handleUnFollowTournament(result.id)}
                                            :
                                            () => {props.handleFollowTournament(result.id)}
                                        }
                                        className={result.is_following? 'following' : ''}
                                        >
                                        {result.is_following?
                                            <span className='hubicon-check icon' />
                                        :
                                            <span className='hubicon-add_option icon' />
                                        }
                                        {result.is_following?
                                            <span>{props.t('following')}</span>
                                        :
                                            <span>{props.t('follow')}</span>
                                        }
                                        
                                        </SecondaryButtonComponent>
                                    }
                                    </div>
                                </React.Fragment>
                            }
                            {props.filter === 'tournament' &&
                                <Link to={`/page/${result.pagename}&setshowtournament=${result.id}&showtournament=true`} className="search-result__result__tournament-info">
                                    <div className={"search-result__result__profile-info__tournament-photo"}>                    
                                        <img className={"search-result__result__profile-info__tournament-photo-img"} src={result.tournament_photo} alt="" />
                                        <div class="search-result__result__profile-info__tournament-labels-container ">
                                        <div class="search-result__result__profile-info__tournament-labels-container__label">
                                            <div class={`baloa-table-column ${result.tournament_state}`}>{props.t(`team:tournamentStates.${result.tournament_state}`)}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className={"search-result__result__profile-info__data"}>
                                        <div className={"search-result__result__tournament-data"}>
                                            <div className={"search-result__result__tournament-data__image"}>
                                                {result.tournament_logo
                                                ? <img src={result.tournament_logo} alt="" />
                                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                            </div>
                                            <div className="baloa-names">{result.name}</div>
                                        </div>
                                        <div className='search-result__result__data-tags'>
                                            {result.tournament_type && <div className='search-result__result__data-tag baloa-table-column'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.tournament_type)}</div>}
                                            {result.gender && <div className='search-result__result__data-tag baloa-table-column'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.gender)}</div>}
                                            {result.category && <div className='search-result__result__data-tag baloa-table-column'>{props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams."+result.category)}</div>}
                                        </div>
                                        <div className='search-result__result__location baloa-table-column'>
                                            <span className='hubicon-location'/>
                                            <div>{result.tournament_location}</div>
                                            {'-'}
                                            <div>{result.tournament_country}</div>
                                        </div>
                                        <div className='search-result__result__location baloa-table-column'>
                                            <div className={"search-result__result__tournament-page-data__image"}>
                                                {result.page_photo
                                                ? <img src={result.page_photo} alt="" />
                                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                            </div>
                                            <div>{result.page_name}</div>
                                        </div>
                                    </div>
                                </Link> 
                            }              
                        </ScrollTrigger>
                    )}
                </div>
            }
            {tournaments.length === 0 && props.filter === 'tournament' &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            }

            {/** Paginas */}
            {pages.length > 0 &&
                <div className={`search-result__cat-personas ${props.filter === 'page'? 'filtered' : ''}`}>
                {props.filter !== 'page' && 
                    <div className={"search-result__cat-personas__options"}>
                    <span className={"baloa-subtitle-2"}>{props.t('pages')}</span>
                    {showRecords &&
                        <Link to={`/search/search=${props.keyword}&filter=page`}>
                        <span className={'baloa-subtitle-2'}>{props.t('seeAll')}</span>
                        </Link>
                    }
                    </div>
                }
                <div className={`search-result__cat-personas__buttons ${props.filter === 'page'? 'filtered' : ''}`}>
                    <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('page')}}>
                        <span className="hubicon-pages">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                        </span>
                        <div className="baloa-names">
                        {props.t('home:quickAccess.searchIn')}{props.t('home:quickAccess.pageAccess')}
                        </div>
                        <span className='icon-chevron-roght'/>
                    </div>
                </div>
                {pages.slice(0, showRecords).map(result =>
                    <ScrollTrigger 
                        onEnter = { () => { 
                            setActiveResult( result );
                        } }
                        onExit = { () => { } }
                        key={result.id}
                        className={`search-result__result ${props.filter === 'page'? 'filtered' : ''}`}
                    >
                        <Link to={`/page/${result.pagename}`} className="search-result__result__profile-info">
                            <div className={"search-result__result__profile-info__photo "}>
                            {result.page_photo
                                ? <img src={result.page_photo} alt="" />
                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                            </div>
                            <div className={"search-result__result__profile-info__data"}>
                            <p className={"baloa-names"}>{result.name}</p>
                            <p className={"baloa-username"}>@{result.pagename}</p>
                            </div>
                        </Link>
                        <div className="search-result__result__follow-button">
                            {/* Se comenta acción seguir/dejar de seguir. api de busqueda de perfiles restringe api de seguir / dejar de seguir*/}
                            {isMobile
                            ? 
                                result.is_following?
                                    <span className="hubicon-following_respon" onClick = {() => { props.handleUnFollowPage( result.pagename ) }} >
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                    </span>
                                    :
                                    <span className="hubicon-follow_respon" onClick = {() => { props.handleFollowPage( result.pagename ) }} >
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </span>
                            :
                            <SecondaryButtonComponent
                                onClick={
                                result.is_following?
                                    () => props.handleUnFollowPage( result.pagename )
                                :
                                    () => props.handleFollowPage( result.pagename )
                                }
                                className={result.is_following? 'following' : ''}
                            >
                                {result.is_following?
                                <span className='hubicon-check icon' />
                                :
                                <span className='hubicon-add_option icon' />
                                }
                                {result.is_following?
                                <span>{props.t('following')}</span>
                                :
                                <span>{props.t('follow')}</span>
                                }
                            </SecondaryButtonComponent>
                            }
                        </div>
                    </ScrollTrigger>
                )}
                </div>
            }
            {pages.length === 0 && props.filter === 'page' &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            }

            {/** Teams */}
            {teams.length > 0 &&
                <div className={`search-result__cat-personas ${props.filter === 'team'? 'filtered' : ''}`}>
                    {props.filter !== 'team' && 
                        <div className={"search-result__cat-personas__options"}>
                            <span className={"baloa-subtitle-2"}>{props.t('teams')}</span>
                            {showRecords &&
                            <Link to={`search=${props.keyword}&filter=team`}>
                                <span className={'baloa-subtitle-2'}>{props.t('seeAll')}</span>
                            </Link>
                            }
                        </div>
                    }
                    {/** TODO: insertar boton a seccion equipos */}
                    {teams.slice(0, showRecords).map(result =>
                        <ScrollTrigger 
                            onEnter = { () => { 
                                setActiveResult( result );
                            } }
                            onExit = { () => { } }
                            key={result.id}
                            className={`search-result__result ${props.filter === 'team'? 'tournament' : ''}`}
                        >
                            {!props.filter &&
                                <React.Fragment>
                                    <Link to={`/page/${result.pagename}&setshowteam=${result.teamname}&showteam=true`} className="search-result__result__profile-info">
                                        <div className={"search-result__result__profile-info__photo"}>
                                            {result.team_logo
                                                ? <img src={result.team_logo} alt="" />
                                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                        </div>
                                        <div className={"search-result__result__profile-info__data"}>
                                            <p className={"baloa-names"}>{result.name}</p>
                                            <div className='search-result__result__data-tags'>
                                                    {result.gender && <div className='search-result__result__data-tag baloa-username'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.gender)}</div>}
                                                    {result.category && <div className='search-result__result__data-tag baloa-username'>{props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams."+result.category)}</div>}
                                            </div>
                                        </div>
                                    </Link>
                                    {/* <div className="search-result__result__follow-button">
                                        <SecondaryButtonComponent
                                        onClick={() => {
                                            props.handleFollowProfile({ ...result, user: { username: result.ref } })
                                        }}
                                        >
                                        <span>{props.t('follow')}</span>
                                        </SecondaryButtonComponent>
                                    </div> */}
                                </React.Fragment>
                            }
                            {props.filter === 'team' &&
                                <Link to={`/page/${result.pagename}&setshowteam=${result.teamname}&showteam=true`} className="search-result__result__team-info">
                                    <div className='search-result__result__team-data'> 
                                        <div className={"search-result__result__profile-info__photo"}>
                                            {result.team_logo
                                                ? <img src={result.team_logo} alt="" />
                                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                        </div>
                                        <div className={"search-result__result__profile-info__data"}>
                                            <p className={"baloa-names"}>{result.name}</p>
                                            <p className={"baloa-username"}>@{result.teamname}</p>
                                        </div>
                                    </div>
                                    <div className='search-result__result__data-tags'>
                                        {result.gender && <div className='search-result__result__data-tag baloa-table-column'>{props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+result.gender)}</div>}
                                        {result.category && <div className='search-result__result__data-tag baloa-table-column'>{props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams."+result.category)}</div>}
                                        <span className="hubicon-roles_fill"/>
                                        <div className='baloa-table-column'>{`${result.members_count} ${props.t('page:groups.members')}`}</div>
                                    </div>
                                    <div className='search-result__result__location baloa-table-column teamsearch'>
                                        <div className={"search-result__result__tournament-page-data__image"}>
                                            {result.page_photo
                                            ? <img src={result.page_photo} alt="" />
                                            : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                                        </div>
                                        <div>{result.page_name}</div>
                                    </div>
                                </Link>
                            }
                        </ScrollTrigger>
                    )}
                </div>
            }
            {teams.length === 0 && props.filter === 'team' &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            } 

            {/** Posts */}
            {posts?.length > 0 &&
                <div className={"search-result__cat-personas"}>
                    {/* <div className={"search-result__cat-personas__options"}>
                        <span className={"baloa-subtitle-2"}>{props.t('subTitle2')}</span>
                        {showRecords &&
                        <Link to={`/search/search=${props.keyword}&filter=post`}>
                            <span className={'baloa-subtitle-2'}>{props.t('seeAll')}</span>
                        </Link>
                        }
                    </div> */}
                    
                    {//eslint-disable-next-line array-callback-return
                     posts.slice(0, showRecords).map(result =>
                        /* <div className={'search-result__result_post'}>
                        <div key={result.id}>
                            <Link to={`/posts/${result.id}/`} className={"search-result__result__profile-info"}>
                            <div className={"search-result__result__profile-info__photo "}>
                                {result.image
                                ? <img src={result.image} alt="" />
                                : <span className={"icon-ball"} color={getRandonIconBallColor()}></span>}
                            </div>
                            <div className={"search-result__result__profile-info__data"}>
                                <p className={"baloa-names"}>{result.caption}</p>
                                <p className={"baloa-username"}>@{result.ref}</p>
                            </div>
                            </Link>
                            <div className={"search-result__result__profile-info__post"}>
                                <p className={"baloa-table-column"}>Lorem ips</p>
                            </div>
                        </div>
                        </div> */
                            {/* <PostComponentV2 
                                className = "feed__post"
                                
                                key = { result.id }
                                post = { result } 
                                me_photo = { null }
                                //show_group = { props.show_group }
                                onEnterViewport = { () => { 
                                    //setActivePost( result );
                                } }
                                onExitViewport = { () => {} } */}

                                //play_video = { /* ( active_post?.id == result.id ) && play_videos */ false}
                                //play_audio = { /* ( active_post?.id == result.id ) && !muted */ false}
                                //handleOnPlayVideo = { () => {/* setPlayVideos( !play_videos ) */} }
                                //handleOnMuteVideo = { () => {/* setMuted( !muted ) */} }

                                //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                                //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                
                                /* handleClap = { ( post ) => props.handleClap( post ) }
                                onCloseClapsModal={ () => { props.onCloseClapsModal(true) } }
                                loadPostClapsById={ ( post_id ) => props.loadPostClapsById( post_id ) }
                                
                                handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                                handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                                handleOnComment = { ( comment, pagename, page_id ) => props.handleOnComment( result, comment, pagename, page_id ) }

                                openReportPostModal = { () => props.openReportPostModal( true ) }
                                handleReportPost = { ( post_id ) => props.handleReportPost( post_id ) }

                                openBlockPostModal = { () => props.openBlockPostModal( true ) }
                                handleBlockProfile = { ( username ) => props.handleBlockProfile( username ) }
                                handleBlockPage={(pagename) => {props.handleBlockPage(pagename)}}

                                handleOnDeletePostClick = { ( post ) => props.handleOnDeletePostClick( post ) }

                                handleOnCopyLinkClick = { ( post ) => props.handleOnCopyLinkClick( post ) }

                                handleOnAuthorClick = { props.handleOnAuthorClick } */

                                /* menu_is_open = {props.menu_is_open}

                                profile = { props.profile }
                                listUserPages = {options} 
                                postItemIndex = {index}
                                is_group = {props.is_group}
                                page = { props.page }
                                sendReaction= { props.sendReaction }
                                overrideReaction= { props.overrideReaction }
                                handleReactionsDetails = { props.handleReactionsDetails }
                                loadPostReactionsById = { props.loadPostReactionsById }
                                deleteReaction = { props.deleteReaction }
                                followPostProfile = { props.followPostProfile }                            
                                unfollowPostProfile = { props.unfollowPostProfile }                            
                                followPostPage = { props.followPostPage }                            
                                unfollowPostPage = { props.unfollowPostPage }

                                handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                                handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                                handleOnQuoteIconClick = { props.handleOnQuoteIconClick } */
                            ///> 
                    )}
                </div>
            }
            {posts.length === 0 && props.filter === 'post' &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            }
            {personas.length === 0 && tournaments.length === 0 && pages.length === 0 && teams.length === 0 && posts.length === 0 && !props.filter &&
                <div className='search-result__empty'>
                    <img src={explore_pages_empty} alt={""} />
                    <div className='baloa-normal-medium'>{props.t('emptySearch')}</div>                    
                </div>
            }
        </div>
    )
}

// Exports
export default withTranslation('search')(SearchResultComponent);
