/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Functions
//import { permissionsController } from 'shared/functions/PemissionsController';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './TournamentMenuComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

class UnauthenticatedTournamentMenuComponent extends Component {

    constructor( props ) {
        super(props);
        this.state = {
            tab_selected: 0,
        }
    }

    nextPath = (path) => {
        this.props.history.push(path)
    }

    selectedTab (tab) {
        
        var patt;

        if(tab === 0){
            patt = new RegExp("^\/tournament\/hub"); // eslint-disable-line
        }

        return patt.test(this.props.location.pathname);

    }

    render() {

        return(
            <React.Fragment>
                {/* menu list */}
                {/*<div className={"tournament-menu " + ((this.props.state.page.page.page_type === "league" && this.props.state.page.page.is_admin) ? "" :"hide-menu")}>*/}
                { isMobile 
                    ?
                    <div className='tournament-menu__container' >
                        <div className='tournament-menu__mobile-title' >{this.props.t('menu.labelTitleMenu')}</div>
                        <div className='tournament-menu__actions-buttons-container'>
                            <div className={'tournament-menu__actions-buttons '+(this.selectedTab(0) ? "active":"")}
                                onClick={() => {
                                    this.setState({tab_selected:0});
                                    this.nextPath(`/tournament/hub`);                            
                                }}
                            >
                                {/* <div className="tournament-menu__actions-buttons__data">
                                <p className={"tournament-menu__actions-buttons__data__text baloa-names "}>
                                    {this.props.t('menu.hubLabelTitle')} 
                                </p>
                                </div> */}
                            </div>
                            
                            </div>  
                        </div>
                    :
                        <div className={"tournament-menu "}>    
                            <div className='tournament-menu__menu-links' >
                                <div className="tournament-menu__title">
                                    <p className="tournament-menu__title__text baloa-headline-2">{this.props.t('menu.labelTitleMenu')}</p>
                                </div>
                                
                                <div className={"tournament-menu__field " +(this.selectedTab(0) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:0});
                                        this.nextPath(`/tournament/hub`);                            
                                    }}
                                >                        
                                    <div className="tournament-menu__field__data">
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            <span className='hubicon-home-1' />
                                            <div>{this.props.t('menu.hubLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div>                                                                                              
                            </div>
                        </div>
        }
            </React.Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}    

export default compose(
    withRouter,
    withTranslation("tournament"),
    connect(mapStateToProps, mapDispatchToProps)
)(UnauthenticatedTournamentMenuComponent);

