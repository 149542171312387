// React
import React from 'react';

// Formik
import { Field } from 'formik';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import { ListItemText, Autocomplete } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';


// Component styles
import './SelectTournamentTeamComponent.styles.css'

export default function SelectTournamentTeamComponent(props) {


    const handleOnChange = (value, form) => {
        form.setFieldValue(props.field_name, value);
        props.onChange(value);
    }

    const handleClearMemberTeam = (e, form) => {
        e.preventDefault();
        form.setFieldValue(props.field_name, null);
        props.onChange( null );
    };

    return (
        <FormControl className={`select-tournament-team__container ${props.className}`} >
            <Field name={ props.field_name } validate={ props.validationFunction }   >
                {({ field, form }) => (
                    /* <React.Fragment>
                        { props.label && <label htmlFor={ props.id }>{ props.label }</label> }
                        <Select                        
                            id={props.id}
                            {...field}
                            value={field.value || ''}
                            onChange={ (event) => handleOnChange(event.target.value, form) }
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            getOptionLabel={(option) => option ? option.name : ''} // Provide a valid getOptionLabel function
                            defaultValue={ props.defaultValue ? () => handleOnChange(props.defaultValue, form) : '' }
                            variant='standard'
                        >
                            <MenuItem className='select-tournament-team__placeholder' value="" disabled>
                                {props.placeholder}
                            </MenuItem>                            
                            { props.options?.map( (option, index) => (
                                <MenuItem key={index} value={option} className='select-tournament-team__item'>
                                    { option.photo &&
                                        <img src={ option.photo } alt=""/>
                                    }
                                    { !option.photo &&
                                        <span className="icon-ball" color={ getRandonIconBallColor() } />
                                    }
                                    <ListItemText primary={option.name} secondary={`@ ${option.team}`}/>
                                </MenuItem>
                            ))}
                            
                        </Select>        
                        {form.errors[props.field_name] && form.touched[props.field_name] && (
                            <FormHelperText error>{form.errors[props.field_name]}</FormHelperText>
                        )}              
                    </React.Fragment> */
                    <React.Fragment>
                        { props.label && <label htmlFor={ props.id }>{ props.label }</label> }
                        <Autocomplete
                            disabled={ props.disabled }
                            freeSolo={props.freeSolo ? props.freeSolo : false}
                            options={props.options}
                            noOptionsText= { props.noOptionsText }
                            disablePortal={true}
                            getOptionLabel={(option) => {
                                if(option[props.optionLabelField]){
                                    return (`${option[props.optionLabelField]}`)
                                }else{
                                    return '';
                                }
                            }}

                            isOptionEqualToValue={( option, value ) => {
                                if(value){
                                    return option[props.optionSelectedField] === value[props.optionSelectedField] 
                                }else{
                                    return null
                                }
                                
                            }}
                            getOptionKey={option => option[props.optionSelectedField] }
                            value={ props.value }
                            defaultValue={ props.defaultValue ? () => handleOnChange(props.defaultValue, form) : null }
                            onChange={(e, value) => {
                                if(props.onChange ){
                                    props.onChange( value )
                                }                    
                                
                                form.setFieldValue(props.field_name, value )
                            }}
                            renderOption={(comprops,option) => {
                                //display value in Popper elements
                                return <MenuItem key={option.id} value={option} {...comprops}>
                                    { option.photo &&
                                        <img src={ option.photo } alt=""/>
                                    }
                                    { !option.photo &&
                                        <span className="icon-ball" color={ getRandonIconBallColor() } />
                                    }
                                    <ListItemText primary={option.name} secondary={`@ ${option.team}`} className="select-tournament-team__item"/>
                                </MenuItem>
                                
                            }}               
                            renderInput={(params) => (                            
                                (props.value && Object.keys(props.value).length !== 0)?
                                    <MenuItem key={props.value.id} value={props.value} {...params.inputProps}>
                                        { props.value.photo &&
                                            <img src={ props.value.photo } alt=""/>
                                        }
                                        { !props.value.photo &&
                                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                                        }
                                        <ListItemText primary={props.value.name} secondary={`@ ${props.value.team}`} className="select-tournament-team__item"/>
                                        {!props.disabled && 
                                            <ListItemSecondaryAction onClick={(e) => { handleClearMemberTeam(e, form) }}>
                                                <span className='hubicon-clear_option' />
                                            </ListItemSecondaryAction>
                                        }
                                    </MenuItem>
                                :
                                <TextField
                                    {...params}
                                    className={ props.className + " select-tournament-team__input-autocomplete"}
                                    //label={ props.label }
                                    variant="standard"
                                    margin="normal"
                                    placeholder={ props.placeholder }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                
                            )}
                        />
                        {form.errors[props.field_name] && form.touched[props.field_name] && (
                            <FormHelperText error>{form.errors[props.field_name]}</FormHelperText>
                        )}
                    </React.Fragment>
            )}                
            </Field>
        </FormControl>
    );
}