import React, { useState} from 'react';
import TounamentPhasesConfigSetComponent from 'app/leagues/components/tournament-phases-config-set/TounamentPhasesConfigSetComponent';
import TournamentPhaseTypeComponent from 'app/leagues/components/tournament-phase-type/TournamentPhaseTypeComponent';


//styles
import './TournamentNewPhaseComponent.styles.css';
//i18
import { withTranslation } from 'react-i18next';

function TournamentNewPhaseComponent(props) {

    const [phaseType, setPhaseType] = React.useState(props.statusRememberPhase ? props.phaseToEdit?.phase_type : null);
    const [phaseName, setPhaseName] = useState(''); //eslint-disable-line
    const [groupsCount, setGroupsCount] = useState([]); //eslint-disable-line
    const [classifiedGroup, setClasifiedGroup] = useState(props.selected_phase?.classified_count ?? []); //eslint-disable-line

    const handlePhaseNameSelected = (phase) => {
        setPhaseName(phase?.content);
    };

    const handleNumberGroup = (number_group) => {
        setGroupsCount(number_group);
    };

    const handleClassifiedGroup = (number_classified_group) => {
        setClasifiedGroup(number_classified_group)
    }

    const handleOnSelectTournamentType = (type) => {
        setPhaseType(type);
        props.hanldeSelectedPhaseType(type)
        if((props.tournament_phases.length === 0 || props.tournament_phases[props.tournament_phases.length -1].phase_type !== null)){
            props.addTournamentPhase(props.tournament_id);
        }else if(props.tournament_phases[props.tournament_phases.length -1].phase_type !== type && props.statusRememberPhase){
            props.hanldeDeleteNewPhase(props.tournament_phases[props.tournament_phases.length -1]);
        }
        /*if (type == 'Knockout') checkTeamsNumber();*/
    };

    const handleOnClickNewGroupPhasesContinue = (new_group_phases, config_groups_info) => {//TODO: check functionality
        props.createNewGroupPhases(props.tournament_id, props.selected_phase.id, new_group_phases);
    };

    return (
        <React.Fragment>

            <div className="tournament-new-phase__container">
                <TournamentPhaseTypeComponent
                    onSelectTournamentType={(type) => { handleOnSelectTournamentType(type) }}
                    pageRoles={props.pageRoles}
                    className={ 'tournament-type' }
                    phaseToEdit={props.statusRememberPhase? props.phaseToEdit : null}
                    edit_tournament={props.edit_tournament}
                />
                {phaseType ?
                    <TounamentPhasesConfigSetComponent
                    onResetError={(data) => { props.onResetError(data) }}
                    errors={props.errorsNewGroupTournament}
                    onClickNewGroupPhasesContinue={(group_phases_data) => { handleOnClickNewGroupPhasesContinue(group_phases_data) }}
                    league_page={props.league_page}
                    keys_caption={`${props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelKnockoutNumberOfKeys')}`}
                    clasified_caption={phaseType === 'Knockout'
                        ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelKnockoutClasifiedByKey')
                        : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelGroupQualified')
                    }
                    tournament_teams={props.phase_teams}//phase_teams
                    phase_type={phaseType}
                    tournament_phases={props.tournament_phases}
                    edit_tournament={props.edit_tournament}
                    teams_count={props.tournament_phases.length > 1
                        ? (props.tournament_phases[props.tournament_phases.length - 2].classified_count * props.tournament_phases[props.tournament_phases.length - 2].groups_count) + props.tournament_phases[props.tournament_phases.length - 2].best_third_teams
                        : props.tournament_teams
                            ? props.tournament_teams.length
                            : 0
                    }
                    //disabledTabs= { (v)=>props.disabledTabs(v) }
                    phase_name_values={props.phase_name_values}
                    tournament_criteria_tie_breaking={props.tournament_criteria_tie_breaking}
                    tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                    handle_phase_name_selected={handlePhaseNameSelected}
                    handle_number_group={handleNumberGroup}
                    handle_classified_group={handleClassifiedGroup}
                    submitForm={props.submitForm}
                    handleNextStep={props.handleNextStep}
                    resetButton={props.resetButton}
                    phaseToEdit={props.phaseToEdit}// Selected data phase to edit
                    selectedPhase={props.phaseToEdit} // Selected data phase
                    edit_phase={props.stepEditPhase}
                    handleEditPhase={props.handleEditPhase}
                    statusRememberPhase={props.statusRememberPhase}
                    />
                : ''
                }
            </div>
        </React.Fragment>
    );

}

export default withTranslation('league')(TournamentNewPhaseComponent);