// PropTypes
import PropTypes from 'prop-types';

// Shapes
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';
import { PageShape } from 'app/pages/shapes/PageShape';

export const PostShape = { 
    id: PropTypes.string, 
    profile: PropTypes.shape( ProfileShape ),
    page: PropTypes.shape( PageShape ),
    caption: PropTypes.string,
    clap_count: PropTypes.number,
    post_image: PropTypes.string,
    post_video: PropTypes.string,
    clapped_by: PropTypes.array,
    created: PropTypes.string,
}


// "comments": [],
