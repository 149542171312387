// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feed {}

.feed__post {
    margin-bottom: var(--space-x2);
}

.feed__profile-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    background: var(--white-color);
    border-radius: 14px;
}

`, "",{"version":3,"sources":["webpack://./src/shared/components/feed/FeedComponent.styles.css"],"names":[],"mappings":"AAAA,OAAO;;AAEP;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".feed {}\n\n.feed__post {\n    margin-bottom: var(--space-x2);\n}\n\n.feed__profile-container {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap; \n    background: var(--white-color);\n    border-radius: 14px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
