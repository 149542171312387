/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { useFormikContext } from 'formik';

// Assets
import field_icon from 'assets/icons/field-icon.svg';
import field_colored_icon from 'assets/icons/field-icon.svg';
//import user_icon from 'assets/icons/baloa-user.svg';
//import user_white_icon from 'assets/icons/user-white-icon.svg';

// Styles
import './PositionsFieldComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

// Positions Field Component:
//   Renders positions and handles their actions.

function PositionsFieldComponent( props ) {

    let [ state, setState ] = React.useState( 
        { 
            primary_position_selected: props.primary_position ? props.primary_position : '',
            secondary_positions_selected: props.secondary_positions ? props.secondary_positions : [],
        } 
    )

    const { setFieldValue } = useFormikContext();

    return (
        <div className={ "positions-field " + ( props.className ? props.className : '' ) }>
            <div className="positions-field__field">
                { props.display == 'simple' && <img src={ field_icon } alt=""/> }
                { props.display == 'form' && <img src={ field_colored_icon } alt=""/> }
            </div>
            <div className="positions-field__positions">
                { props.display == 'simple' && 
                    <React.Fragment>
                        {props.primary_position &&
                            <div 
                                className = { `positions-field__positions__position pos-${ props.primary_position } display-${ props.display }` }
                            >
                                <span className={`hubicon-account_circle `}/>
                                <p>{ props.t(`apt.aptPositionCodes.${props.primary_position}`) }</p>
                            </div>
                        }
                        {props.secondary_positions?.map( position =>
                            <div 
                                key = { position }
                                className = {`positions-field__positions__position pos-${ position } display-${ props.display }`}
                            >
                                <span className={`hubicon-account_circle `}/>
                                <p>{ props.t(`apt.aptPositionCodes.${position}`) }</p>
                            </div>
                        ) }
                    </React.Fragment>
                }
                { props.display == 'form' &&
                    <React.Fragment>
                        { ( props.position == "primary" ) && <React.Fragment> { 
                            props.all_positions?.map( all_position => {
                                return <div 
                                    key = { all_position }
                                    onClick = { () => { 
                                        setFieldValue( 'primary_position', ( state.primary_position_selected == all_position ) ? '' : all_position )
                                        setState( { primary_position_selected: ( state.primary_position_selected == all_position ) ? '' : all_position } );
                                    } } 
                                    className = { 
                                        `positions-field__positions__position pos-${ all_position } display-${ props.display } ` 
                                        + ( state.primary_position_selected == all_position ? 'active' : '' )
                                    }
                                >
                                    <React.Fragment>
                                        {/* <img src={ state.primary_position_selected == all_position ? user_white_icon : user_icon } alt=""/> */}
                                        <span className={`hubicon-account_circle `}/>
                                        <p>{ props.t(`apt.aptPositionCodes.${all_position}`) }</p>
                                    </React.Fragment> 
                                </div>
                            } ) 
                        } </React.Fragment> }
                        { ( props.position == "secondary" ) && <React.Fragment> { 
                            props.all_positions?.map( all_position => {
                                return <div 
                                    key = { all_position }
                                    onClick = { () => { 
                                        let new_secondary_positions = state.secondary_positions_selected;
                                        const index = state.secondary_positions_selected.indexOf( all_position );
                                        if (index > -1) {
                                            new_secondary_positions.splice(index, 1);
                                        } 
                                        else {
                                            new_secondary_positions.push( all_position );
                                        }
                                        setState( { secondary_positions_selected: new_secondary_positions } );
                                    } } 
                                    className = { 
                                        `positions-field__positions__position pos-${ all_position } display-${ props.display } ` 
                                        + ( state.secondary_positions_selected.includes( all_position ) ? 'active' : '' )
                                    }
                                >
                                    <React.Fragment>
                                        {/* <img src={ state.secondary_positions_selected.includes( all_position ) ? user_white_icon : user_icon } alt=""/> */}
                                        <span className={`hubicon-account_circle `}/>
                                        <p>{ props.t(`apt.aptPositionCodes.${all_position}`) }</p>
                                    </React.Fragment> 
                                </div>
                            } ) 
                        } </React.Fragment> }
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

// Types
PositionsFieldComponent.propTypes = {
    primary_position: PropTypes.string,
    secondary_positions: PropTypes.array,
}

// Exports
export default withTranslation('profile')(PositionsFieldComponent);