// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';


// Validators
import { AnyValidator } from 'shared/validators/SignUpValidator';
import { OnlyNumbersValidator, EmailValidator, RequiredValidator } from 'shared/validators/RequiredValidator';

//i18
import { withTranslation } from 'react-i18next';

//Material
import ErrorIcon from "@mui/icons-material/Error";

// Styles
import './LeagueTeamEditMemberModalComponent.styles.css';

function LeagueTeamEditMemberModalComponent( props ) {

    const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);

    /* React.useEffect(() => {   
        if(props.member?.player_state === 'no_registered'){
            setIsBtnDisabled(true)
        }else{
            setIsBtnDisabled(false)
        }
    }, [props.member]); */

    const initialValues= {
        first_name: props.member?.first_name? props.member.first_name:"",
        last_name: props.member?.last_name? props.member.last_name:"",
        email: (props.member?.email && props.member?.player_state !== 'no_registered' && !props.member?.email.includes('baloa.com'))? props.member.email:"",
        phone: props.member?.phone? props.member.phone:"",
        role: props.member?.team_role? props.roles?.filter(e => e.content == props.member?.team_role)[0].value : "",
        position: props.member?.position? props.positions.filter(e => e.content == props.member?.position)[0].value : "",
        player_number:  props.member?.player_number? props.member.player_number:"",
    }
   
    const formRef = React.useRef();

    /* const checkSubmitButtonState=( phoneInp, emailInp)=>{
        if((phoneInp?.length > 7 || emailInp?.length > 5) && formRef.current?.isValid){
            setIsBtnDisabled(false)
        }else{
            setIsBtnDisabled(true)
        }        
    } */

    return (
        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.title}
        >
            <div className="scroll-parent">
            <FormComponent 
                formInitialValues={ initialValues } 
                onSubmit={( values, {resetForm} ) => { 
                    
                    let valuesToSend = {
                        username: props.member?.username,
                        first_name: values.first_name,
                        last_name: values.last_name,

                        role:{
                            role: values.role,
                            position: values.position,
                            player_number: values.player_number
                        }
                    }
                    if(values.email && props.member.player_state !== 'registered'){
                        valuesToSend.email = values.email;
                    }
                    if(values.phone && props.member.player_state !== 'registered'){
                        valuesToSend.phone = values.phone;
                    }
                    //console.log("values to send", valuesToSend);
                    resetForm({values:''});
                    setIsBtnDisabled(false);
                    props.onNewData( valuesToSend );
                    
                }}

                className="league-team-edit-member__form scroll-parent"
                autocomplete="off"
                innerRef={formRef}
            >
                {props.member?.player_state === 'no_registered' &&
                    <div className='league-team-edit-member__message baloa-username'>
                        <ErrorIcon
                            style={{
                                fontSize: 24,
                                color: "var(--primary-dark-color)",
                                marginRight: "var(--space)",
                                alignSelf: "center",
                            }}
                        />
                        {props.t("team.edit.members.notRegisteredMsg")}
                    </div>
                }
                {props.member?.player_state === 'pre_registered' &&
                    <div className='league-team-edit-member__message baloa-username'>
                        <ErrorIcon
                            style={{
                                fontSize: 24,
                                color: "var(--primary-dark-color)",
                                marginRight: "var(--space)",
                                alignSelf: "center",
                            }}
                        />
                        {props.t("team.edit.members.preRegisteredMsg")}
                    </div>
                }
                <div className='league-team-edit-member__subtitle baloa-table-column'>{props.t("team.edit.members.subtitle")}</div>
                <div className='league-team-edit-member__row'>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.firstNameLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <InputFieldComponent
                                field_name="first_name"
                                className="league-team-edit-member__row__input__input"
                                validationFunction={RequiredValidator}
                                disabled={props.member?.player_state === 'registered'}
                                input_type="text"   
                                resetError={()=>{}}
                                error ={{first_name: ''}}
                                message=""
                                placeholder=""
                                defaultValue={props.member?.first_name}
                            />
                        </div>
                    </div>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.lastNameLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <InputFieldComponent
                                field_name="last_name"
                                className="league-team-edit-member__row__input__input"
                                validationFunction={RequiredValidator}
                                disabled={props.member?.player_state === 'registered'}
                                input_type="text"   
                                resetError={()=>{}}
                                error ={{last_name: ''}}
                                message=""
                                placeholder=""
                                defaultValue={props.member?.last_name}
                            />
                        </div>
                    </div>
                </div>

                <div className='league-team-edit-member__row'>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.phoneNumberLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <InputFieldComponent
                                field_name="phone"
                                className="league-team-edit-member__row__input__input"
                                validationFunction={OnlyNumbersValidator}
                                disabled={props.member?.player_state === 'registered'}
                                input_type="text"   
                                resetError={()=>{}}
                                error ={{phone: ''}}
                                message=""
                                placeholder=""
                                //onChange={()=>{checkSubmitButtonState( formRef.current?.values.phone, formRef.current?.values.email)}}
                                defaultValue={props.member?.phone}                                
                            />
                        </div>
                    </div>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.emailLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <InputFieldComponent
                                field_name="email"
                                className="league-team-edit-member__row__input__input"
                                validationFunction={EmailValidator}
                                disabled={props.member?.player_state === 'registered'}
                                input_type="text"   
                                resetError={()=>{}}
                                error ={{email: ''}}
                                message=""
                                placeholder=""
                                defaultValue={ (props.member?.email && props.member?.player_state !== 'no_registered' && !props.member?.email.includes('baloa.com'))? props.member.email:"" }
                            />
                        </div>
                    </div>
                </div>
                {isBtnDisabled &&
                <div className='league-team-edit-member__row-error input-field__message error'>{props.t("team.edit.members.emailPhoneErrorMsg")}</div>
                }
                <div className='league-team-edit-member__row'>
                    <div className='league-team-edit-member__row-full'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.roleLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <SelectFieldComponent
                                field_name="role"
                                className="league-team-edit-member__row__input__select-input"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                resetError={()=>{}}
                                error={{role: ''}}
                                message=""
                                placeholder=""
                                options={props.roles}
                                defaultValue={ props.member?.team_role? props.roles?.filter(e => e.content == props.member?.team_role)[0].value : null }
                            />
                        </div>
                    </div>
                </div>

                <div className='league-team-edit-member__row'>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.positionLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <SelectFieldComponent
                                field_name="position"
                                className="league-team-edit-member__row__input__select-input"
                                validationFunction={AnyValidator}
                                disabled={false}
                                resetError={()=>{}}
                                error={{position: ''}}
                                message=""
                                placeholder=""
                                options={props.positions}
                                defaultValue={  props.member?.position? props.positions?.filter(e => e.content == props.member?.position)[0].value : null }
                            />
                        </div>
                    </div>
                    <div className='league-team-edit-member__row-half'>
                        <div className='league-team-edit-member__row__label baloa-username'>{props.t("team.edit.members.fields.numberLbl")}</div>
                        <div className='league-team-edit-member__row__input'>
                            <InputFieldComponent
                                field_name="player_number"
                                className="league-team-edit-member__row__input__input"
                                validationFunction={OnlyNumbersValidator}
                                disabled={false}
                                input_type="number"   
                                resetError={()=>{}}
                                error ={{player_number: ''}}
                                message=""
                                placeholder="#"
                                min="1"
                                defaultValue={props.member?.player_number}
                            />
                        </div>
                    </div>
                </div>                
                
                <div className="league-team-edit-member__footer">
                    <TextButtonComponent
                        disabled={false}
                        onClick={()=>{ props.onClose() }}
                        className=""
                    >
                        <span>{props.t('team.edit.members.cancelBtn')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={isBtnDisabled}
                        onClick={()=>{ }} 
                        className=""
                    >
                        <span>{props.t('team.edit.members.saveBtn')}</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
            </div>
        </Modal2Component>

    )
}

export default withTranslation('team')(LeagueTeamEditMemberModalComponent);
