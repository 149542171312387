// React
import React from 'react';

//Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFixtureLockedDatesComponent.styles.css';

function TournamentFixtureLockedDatesComponent(props) {   
    return (
        <div className='phases_round_fixture_selector_modal__days'>
            <div className='baloa-table-column'><span className='hubicon-event_busy'></span>{props.t('options.tournaments.automaticFixturesModal.lockedDatesTitle')}</div>
            <div className='baloa-username'>{props.t('options.tournaments.automaticFixturesModal.lockedDatesMsg')}</div>
            <div className='phases_round_fixture_selector_modal__days-container'>
                {props.fixture_locked_days.length > 0 ?
                    props.fixture_locked_days.map(date => 
                        <div className='phases_round_fixture_selector_modal__days-tag'>
                            <div className='phases_round_fixture_selector_modal__days-tag__date-info'>
                                {date.start_date === date.end_date?
                                    <div className='baloa-username'>{moment(date.start_date).format('ddd D MMM YYYY')}</div>
                                    :
                                    <div className='baloa-username'>{`${moment(date.start_date).format('ddd D MMM YYYY')} - ${moment(date.end_date).format('ddd D MMM YYYY')}`}</div>
                                }
                            </div>
                            <span className='hubicon-clear_option phases_round_fixture_selector_modal__days-tag__button' onClick={()=>{ props.handleOnDeleteFixtureLockedDay(date.id)}} />
                        </div>
                    )
                    :
                    ''
                }
                <RoundedButtonComponent
                    className="phases_round_fixture_selector_modal__days__button small"
                    type='button'
                    onClick={ ()=>{ props.handleOnAddBlockDates() } }
                >
                    <span className='hubicon-add_option icon'></span>
                    <span>{props.t('options.tournaments.automaticFixturesModal.lockedDatesAddDaysBtnLbl')}</span>
                </RoundedButtonComponent>
            </div>
        </div>
    )
}

export default  withTranslation('league')(TournamentFixtureLockedDatesComponent);
