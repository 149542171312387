import React, { useState } from 'react';
//import LeagueNewTeamStepsComponent from 'app/leagues/components/league-new-team-steps/LeagueNewTeamStepsComponent';
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";
import SelectFieldComponent from "shared/components/select-field/SelectFieldComponent";
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
// i18n
import { withTranslation } from 'react-i18next';
// Validators
import { RequiredValidator } from "shared/validators/RequiredValidator";
import { UserNameValidator } from "shared/validators/SignUpValidator";
//Styles
import './PageNewTeamFormComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

function PageNewTeamFormComponent(props) {

    //const [step, setStep] = useState(0);
    const [state, setState] = useState({ new_team_image_modal: false });
    const [teamImage, setStateTeamImage] = useState({});
    const [validateSendBtn, setValidateSendBtn] = useState(false);
    const formRef = React.useRef();
    // const [newTeamStepState, setNewTeamStepState] = useState( // eslint-disable-line
    //     {
    //         basic_info_step: false,
    //         player_step: false
    //     });

    // const stepCompleted = (index) => {
    //     if (index === 1) setStep(1);
    //     else if (index === 0) setStep(0);
    // }

    const handleNewTeamImageModal = (new_state) => {
        setState({ new_team_image_modal: new_state });
    };

    const formInitialValues = {
        name: '',
        team_gender: props.selected_tournament ? props.tournament?.gender : '',
        teamname: '',
        category: props.selected_tournament ? props.tournament?.category: '',
    };

    const handleSendTeamData = (team_data) => {
        const pageName = props.idPageSelected.length > 0 ? props.idPageSelected : props.page_data.pagename;
        const data_create_team = {
            category: team_data.category,
            photo: teamImage.img_b64,
            name: team_data.name,
            team_gender: team_data.team_gender,
            teamname: team_data.teamname,
            pageName,
        };
        if(Object.keys(teamImage).length === 0){
            setValidateSendBtn(true);
        } else {
            props.handleSentToCreateTeam(data_create_team);
            setValidateSendBtn(false);
        }        
    };

    const handleSubmit=()=>{
        handleSendTeamData(formRef.current?.values);
    }

    return (
        <React.Fragment>
            {/* { !props.new_page_team_inscription &&
                <LeagueNewTeamStepsComponent
                    step={step}
                    stepCompleted={(i) => stepCompleted(i)}
                    newTeamStepState={newTeamStepState}
                    
                />
            } */}
            {/*Form Team*/}
            <div>
                <div className='modal-page-create_newPage_data_logo_title baloa-username'>
                    {props.t("team:teams.temasModalHome_imgUpload")}
                </div>
                <div className={"modal-page-create_newPage_data_logo"}>
                    <div className='tournament-photo' onClick={() => handleNewTeamImageModal(true)} >
                        {!teamImage.imageurl
                            ? <span className='icon-image-70' />
                            : <img className='img-url' src={teamImage.imageurl} alt="" />
                        }
                        <div className='baloa-username'>
                            {props.t("league:modal_create_page.img_upload")}
                        </div>
                    </div>
                    {validateSendBtn && <div className='page-new-team-form__required-img baloa-username'>{props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.requiredTournamentImg')}</div>}
                </div>
                <LeagueTeamUploadImageModalComponent
                    isOpen={state.new_team_image_modal}
                    onClose={() => handleNewTeamImageModal(false)}
                    setTeamImage={(new_image, imageurl, img_b64) => {
                        setStateTeamImage({ new_image, imageurl, img_b64 });
                    }}
                    aspect={1}
                    cropImage={true}
                />
                <FormComponent formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        /* let new_page_data = {};
                        new_page_data = { ...values };
                        handleSendTeamData(new_page_data); */
                    }}
                    innerRef={formRef}
                >
                    { !isMobile &&
                        <div className="modal-new-team_form__fields">
                            <div className={"modal-new-team-form__form_left"}>
                                <InputFieldComponent
                                    label={props.t("team:team.edit.forms.basicInfo.teamLabel")}
                                    input_id="new-page-name"
                                    field_name="name"
                                    validationFunction={RequiredValidator}
                                    disabled={false}
                                    className="new-team-form__form__input__name"
                                    input_type="text"
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                />
                                <SelectFieldComponent
                                    label={props.t("team:team.edit.forms.basicInfo.teamgenderLabel")}
                                    field_name="team_gender"
                                    validationFunction={UserNameValidator}
                                    disabled={false}
                                    className="new-team-form__form__input"
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={props.gender}
                                />
                            </div>
                            <div>
                                <InputFieldComponent
                                    label={props.t("team:team.edit.forms.basicInfo.teamnameLabel")}
                                    input_id="new-page-pagename"
                                    field_name="teamname"
                                    validationFunction={RequiredValidator}
                                    disabled={false}
                                    className="new-team-form__form__input__pagename"
                                    input_type="text"
                                    resetError={() => { }}
                                    error={props.errors}
                                    placeholder="@"
                                />
                                <SelectFieldComponent
                                    label={props.t("team:team.edit.forms.basicInfo.categoryLabel")}
                                    field_name="category"
                                    validationFunction={RequiredValidator}
                                    disabled={false}
                                    className="new-team-form__form__input"
                                    resetError={() => { }}
                                    error={props.errors}
                                    message=""
                                    placeholder=""
                                    options={props.category}
                                />
                            </div>
                        </div>
                    }
                    { isMobile &&
                        <div className="page-new-team-form__mobile-container">
                            <InputFieldComponent
                                label={props.t("team:team.edit.forms.basicInfo.teamLabel")}
                                input_id="new-page-name"
                                field_name="name"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className="page-new-team-form__mobile-name"
                                input_type="text"
                                resetError={() => { }}
                                error={props.errors}
                                message=""
                                placeholder=""
                            />
                            <InputFieldComponent
                                label={props.t("team:team.edit.forms.basicInfo.teamnameLabel")}
                                input_id="new-page-pagename"
                                field_name="teamname"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className="page-new-team-form__mobile-pagename"
                                input_type="text"
                                resetError={() => { }}
                                error={props.errors}
                                placeholder="@"
                            />
                            <SelectFieldComponent
                                label={props.t("team:team.edit.forms.basicInfo.teamgenderLabel")}
                                field_name="team_gender"
                                validationFunction={UserNameValidator}
                                disabled={false}
                                className="page-new-team-form__mobile-gender"
                                resetError={() => { }}
                                error={props.errors}
                                message=""
                                placeholder=""
                                options={props.gender}
                            />
                            
                            <SelectFieldComponent
                                label={props.t("team:team.edit.forms.basicInfo.categoryLabel")}
                                field_name="category"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className="page-new-team-form__mobile-name"
                                resetError={() => { }}
                                error={props.errors}
                                message=""
                                placeholder=""
                                options={props.category}
                            />
                        </div>
                    }
                    <div className="form-group-new-team_form-buttons">
                        <PrimaryButtonComponent
                            type="button"
                            onClick={() => { 
                                if(Object.keys(teamImage).length === 0){
                                    setValidateSendBtn(true);
                                } else {
                                    setValidateSendBtn(false);
                                    handleSubmit();
                                }
                             }}
                            className="page-information-form__form__submi`t">
                            <span>{props.t("team:teams.teamsModalHome_Continue")}</span>
                        </PrimaryButtonComponent>
                    </div>
                </FormComponent>
            </div>
        </React.Fragment>
    );
};

export default withTranslation(['team', 'league'])(PageNewTeamFormComponent);