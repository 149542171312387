// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import FormComponent from 'shared/components/form/FormComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator'; 

//Styles
import './TournamentFieldsFormModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentFieldsFormModalComponent ( props ) {

    const formInitialValues = {
        name: props.field_to_edit ? props.field_to_edit.name : '',
        address: props.field_to_edit ? props.field_to_edit.address : '',
    } 

    const handleTitleLabel = () => {
        switch (props.field_action_type) {
            case 'create-page-field':
                return props.t('options.pageFields.labelInputCreate');
            case 'edit-page-field':
                return props.t('options.pageFields.labelInputEdit');
            case 'create-tournament-field':
                return props.t('options.tournamentFields.labelTitle');
            default:
                break;
        }
    }

    const handleActionLabel = () => {
        switch (props.field_action_type) {
            case 'create-page-field':
                return props.t("options.pageFields.labelActionBtn");
            case 'edit-page-field':
                return props.t("options.pageFields.labelBtnSave")
            case 'create-tournament-field':
                return props.t('options.tournamentFields.labelActionBtn');
            default:
                break;
        }
    }
    
    return (
        <Modal2Component
            isOpen={ props.isOpen } 
            onClose={ props.onClose }
            title={ handleTitleLabel()  }
            className="tournament-fields-create__container"
         >
            <FormComponent 
                formInitialValues={formInitialValues} 
                onSubmit={ values => {
                    let form_values = {
                        name: values.name,
                        address: values.address ? values.address : null,
                    }     
                    props.handlePageFieldsActions(form_values);           
                }}
                functionDisabled={()=>{}}
            >
                <div className="tournament-fields-create__input-fields" >
                    <InputFieldComponent 
                        label={props.t("options.pageFields.labelInputFieldText")}
                        input_id="name_field"
                        field_name="name"
                        validationFunction={RequiredValidator}
                        disabled={false}
                        className=""
                        input_type="text"
                        resetError={() => props.onResetError({ name: "" })}
                        error={props.errors}
                        message=""
                        placeholder=""
                        defaultValue={props.field_to_edit ? props.field_to_edit.name : ''} 
                    />
                    <InputFieldComponent 
                        label={props.t("options.pageFields.labelInputAddressText")}
                        input_id="address_field"
                        field_name="address"
                        //validationFunction={RequiredValidator}
                        disabled={false}
                        className=""
                        input_type="text"
                        resetError={() => props.onResetError({ address: "" })}
                        error={props.errors}
                        message=""
                        placeholder="" 
                        defaultValue={props.field_to_edit ? props.field_to_edit.address : ''}
                    />                    
                </div>                
                <div className='tournament-fields-create__action'>
                    <PrimaryButtonComponent type="submit" onClick={ () => {} } >{ handleActionLabel() }</PrimaryButtonComponent>
                </div>                
            </FormComponent>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentFieldsFormModalComponent);