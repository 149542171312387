// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-date{
    margin-bottom: var(--space);
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-matches-to-close-modal/TournamentMatchesToCloseModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,uBAAuB;AAC3B","sourcesContent":[".modal-date{\n    margin-bottom: var(--space);\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
