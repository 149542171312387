// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Input style */
.tournament-team-player__input input{
    /* background-color: var(--primary-very-light-color); */
    background: transparent;

    padding: var(--space) var(--space-and-half);
    width: 100%;
    font-family: var(--secondary-font);
    font-size: var(--space);
    max-height: var(--space-x3);
}

/* label style */

.tournament-team-player__input label {
    font-weight: var( --font-medium );
    font-family: var( --secondary-font );
    line-height: var( --space );
    font-size: calc( var( --space ) + 1px );
    letter-spacing: 0.0025em;
    color: var( --basic-gray-color );
    text-transform: capitalize;
}


div.tournament-team-player__input {
    border-radius: 0px;
    padding: 0px !important;
    min-width: 200px;
}

.tournament-team-player__container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: var(--space-and-half);
}


.tournament-team-player__container .MuiAutocomplete-root {
    width: 50%;
    padding-right: var(--space);
}

.input-field-validation.end-tournament__form__elements__content__input .MuiInputBase-input{
    color: var(--primary-very-dark-color);
}

.tournament_team_player_container_input .tournament-team-player__input .MuiInputBase-root{
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-team-player/TournamentTeamPlayerComponent.styles.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,uDAAuD;IACvD,uBAAuB;;IAEvB,2CAA2C;IAC3C,WAAW;IACX,kCAAkC;IAClC,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA,gBAAgB;;AAEhB;IACI,iCAAiC;IACjC,oCAAoC;IACpC,2BAA2B;IAC3B,uCAAuC;IACvC,wBAAwB;IACxB,gCAAgC;IAChC,0BAA0B;AAC9B;;;AAGA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,iCAAiC;AACrC;;;AAGA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["/*Input style */\n.tournament-team-player__input input{\n    /* background-color: var(--primary-very-light-color); */\n    background: transparent;\n\n    padding: var(--space) var(--space-and-half);\n    width: 100%;\n    font-family: var(--secondary-font);\n    font-size: var(--space);\n    max-height: var(--space-x3);\n}\n\n/* label style */\n\n.tournament-team-player__input label {\n    font-weight: var( --font-medium );\n    font-family: var( --secondary-font );\n    line-height: var( --space );\n    font-size: calc( var( --space ) + 1px );\n    letter-spacing: 0.0025em;\n    color: var( --basic-gray-color );\n    text-transform: capitalize;\n}\n\n\ndiv.tournament-team-player__input {\n    border-radius: 0px;\n    padding: 0px !important;\n    min-width: 200px;\n}\n\n.tournament-team-player__container{\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    margin-top: var(--space-and-half);\n}\n\n\n.tournament-team-player__container .MuiAutocomplete-root {\n    width: 50%;\n    padding-right: var(--space);\n}\n\n.input-field-validation.end-tournament__form__elements__content__input .MuiInputBase-input{\n    color: var(--primary-very-dark-color);\n}\n\n.tournament_team_player_container_input .tournament-team-player__input .MuiInputBase-root{\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
