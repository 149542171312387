// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Styles
import './TournamentAddExistingFieldsModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentAddExistingFieldsModalComponent ( props ) {

    // State to manage the checked state for each checkbox
    const [checkboxes, setCheckboxes] = React.useState(() => {
            const initialState = {};
            props.tournament_available_fields.forEach(({ id }) => {
            initialState[id] = false;
        });
        return initialState;
    });

    const [fieldsArray, setFiledsArray] = React.useState([]);

    // Function to handle individual checkbox change and manage the array to be sent
    const handleCheckboxChange = (field_id) => {
        
        // Check if the checkbox is already in the array
        const isChecked = fieldsArray.some(field => field.field === field_id);
        
        // Update the array based on the checkbox state
        if (isChecked) {
            setFiledsArray(prevState => prevState.filter(item => item.field !== field_id));
        } else {
            setFiledsArray(prevState => [...prevState, { field: field_id }]);
        }
        
        // Handles checkbox state
        setCheckboxes({
            ...checkboxes,
            [field_id]: !checkboxes[field_id],
        });
    };

    // Resets all checkboxes to false
    const resetCheckboxes = () => {
        const initialState = {};
        props.tournament_available_fields.forEach(({ id }) => {
            initialState[id] = false;
        });
        setCheckboxes(initialState);
    };
    
    // Submit data
    const handleSubmit = () => {
        props.handleTournamentFieldActions('create-tournament-field', fieldsArray);
        setFiledsArray([]);
        resetCheckboxes();
        props.onClose();
    }

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.tournamentAvailableFields.labelTitle')}
            className="tournament-add-existing-fields__container"
         >
            <div className='tournament-add-existing-fields__chk-list scroll'>
                <div className='tournament-add-existing-fields__text baloa-table'>{ props.t('options.tournamentAvailableFields.labelText') }</div>
                { props.tournament_available_fields?.map( ( field, index ) => (
                    <div className='tournament-add-existing-fields__field' key={index}>
                        <input
                            key={index}
                            type="checkbox"
                            checked={checkboxes[field.id]}
                            onChange={() => handleCheckboxChange(field.id)}
                            className='tournament-add-existing-fields__field-input'
                        />
                        <div className='tournament-add-existing-fields__field-info'>
                            <div className='baloa-table-column'>{field.name}</div>
                            { field.address && <div className='baloa-username'>{field.address}</div> }
                        </div>
                    </div>
                ))}
            </div>
            <div className='tournament-add-existing-fields__actions'>
                    <PrimaryButtonComponent onClick={ () => handleSubmit() }>{ props.t('options.tournamentAvailableFields.labelBtn') }</PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentAddExistingFieldsModalComponent);