// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    padding-top: var(--space-and-half);
    padding-left: var(--space-x3);
    padding-right: var(--space-x3);
}

.box__title{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.baloa-username.box__title__text {    
    font-weight: var( --font-medium );    
    color: var( --basic-gray-color );
    width: 16.6%;
    text-align: center;
}

.box__steps{
    display: flex;
    justify-content: space-between;
    /* margin: var(--space-half ) calc( var(--space-x2 ) + var(--space-half )) 0 calc( var(--space ) - var(--space-quarter )); */
    width: 85%;
    align-items: center;
    margin: var(--space-half) auto;
}
.box__steps__point{
    height: calc( var( --space-and-half ) + var( --space-eighth ) );
    border-radius: 50%;
    background: var( --new-gray-disabled-color );
    min-width: calc( var( --space-and-half ) + var( --space-eighth ) );
    max-width: calc( var( --space-and-half ) + var( --space-eighth ) );
}

.box__steps__line{
    border-top: var( --space-eighth ) solid;
    padding: 0;
    margin: 0 0 0 0;
    width: 100%;
    /* width: calc(var(--space-x3)*4.3); */
    color: var(--basic-gray-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/create-carnet/steps-create-carnet/StepsCreateCarnetComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,4HAA4H;IAC5H,UAAU;IACV,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,+DAA+D;IAC/D,kBAAkB;IAClB,4CAA4C;IAC5C,kEAAkE;IAClE,kEAAkE;AACtE;;AAEA;IACI,uCAAuC;IACvC,UAAU;IACV,eAAe;IACf,WAAW;IACX,sCAAsC;IACtC,8BAA8B;AAClC","sourcesContent":[".box {\n    padding-top: var(--space-and-half);\n    padding-left: var(--space-x3);\n    padding-right: var(--space-x3);\n}\n\n.box__title{\n    display: flex;\n    justify-content: space-between;\n    cursor: pointer;\n}\n\n.baloa-username.box__title__text {    \n    font-weight: var( --font-medium );    \n    color: var( --basic-gray-color );\n    width: 16.6%;\n    text-align: center;\n}\n\n.box__steps{\n    display: flex;\n    justify-content: space-between;\n    /* margin: var(--space-half ) calc( var(--space-x2 ) + var(--space-half )) 0 calc( var(--space ) - var(--space-quarter )); */\n    width: 85%;\n    align-items: center;\n    margin: var(--space-half) auto;\n}\n.box__steps__point{\n    height: calc( var( --space-and-half ) + var( --space-eighth ) );\n    border-radius: 50%;\n    background: var( --new-gray-disabled-color );\n    min-width: calc( var( --space-and-half ) + var( --space-eighth ) );\n    max-width: calc( var( --space-and-half ) + var( --space-eighth ) );\n}\n\n.box__steps__line{\n    border-top: var( --space-eighth ) solid;\n    padding: 0;\n    margin: 0 0 0 0;\n    width: 100%;\n    /* width: calc(var(--space-x3)*4.3); */\n    color: var(--basic-gray-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
