// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Material
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//Styles
import './TournamentFixtureAddTeamToFieldModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentFixtureAddTeamToFieldModalComponent ( props ) {
    const [selectedTeams, setSelectedTeams] = React.useState([]);

    React.useEffect(() => {
        if(props.fixture_field_teams?.length > 0 && selectedTeams.length === 0){
            props.fixture_field_teams?.filter(team => team.assigned === true).map( teamSelected =>
                setSelectedTeams(current => [...current, teamSelected.page_team.id])
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fixture_field_teams]);
    

    const handleOnSelectedTeam = (e) => {
        if(e.target.checked){
            setSelectedTeams(current => [...current, e.target.value]);
        }else{
            setSelectedTeams((current) =>
                current.filter((selectedTeam) => selectedTeam !== e.target.value)
            );            
        }
    }

    const handleOnAdd = () => {
        let newSelectedTeams = [];
        //eslint-disable-next-line array-callback-return
        selectedTeams?.map(teamId =>{
            let newItem = {team: teamId};
            newSelectedTeams.push(newItem);            
        });
        props.handleOnAddFieldTeams(newSelectedTeams);
        handleClose();
    }

    const handleClose = () => {
        setSelectedTeams([]);
        props.onClose();
    }

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={handleClose}
            title={props.t('options.tournaments.automaticFixturesModal.addTeamTofieldModal.modalTitle')}
            className="tournament-add-team-to-field__container"
         >
            <div className='tournament-add-team-to-field__chk-list scroll'>
                <div className='tournament-add-team-to-field__text baloa-table'>{ props.t('options.tournamentAvailableFields.labelText') }</div>
                { props.fixture_field_teams?.map( ( team, index ) => (
                    /* <div className='tournament-add-team-to-field__field'>
                        <input
                            key={index}
                            type="checkbox"
                            //checked={checkboxes[field.id]}
                            //onChange={() => handleCheckboxChange(field.id)}
                            className='tournament-add-team-to-field__field-input'
                        />
                        <div className='tournament-add-team-to-field__field-info'>
                            {team.page_team.logo &&
                                <img src={ team.page_team.logo } alt=""/>
                            }
                            {!team.page_team.logo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            }
                            <div>  
                                <div className='baloa-table-column'>{team.page_team.name}</div>   
                                <div className='baloa-table-column'>{team.page_team.teamname}</div>
                            </div>
                        </div>
                    </div> */
                    <FormControlLabel
                        name={'selectedTeams'}
                        key={team.id}
                        value={team.page_team.id}
                        control={<Checkbox color="secondary" checked={ selectedTeams.some(item => item === team.page_team.id) } />}
                        label={
                            <div className='card-id-team-selection-modal__team-info'>                            
                                <div className='card-id-team-selection-modal__team-image'>
                                    {team.page_team.logo &&
                                        <img src={ team.page_team.logo } alt=""/>
                                    }
                                    {!team.page_team.logo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }                            
                                </div>
                                <div className='card-id-team-selection-modal__team-data'>
                                    <div className='baloa-table-column'>{team.page_team.name}</div>
                                    <div className='baloa-username'>{`@${team.page_team.teamname}`}</div>
                                </div>
                            </div>
                        }
                        labelPlacement="end"
                        className={'card-id-team-selection-modal__team'}
                        onChange={(e) => handleOnSelectedTeam(e)}
                    />
                ))}
            </div>
            <div className='tournament-add-team-to-field__actions'>
                    <PrimaryButtonComponent onClick={ () => { handleOnAdd() } }>{ props.t('options.tournaments.automaticFixturesModal.addTeamTofieldModal.addBtnLbl') }</PrimaryButtonComponent>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentFixtureAddTeamToFieldModalComponent);   