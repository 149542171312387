// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import scriptjs from 'scriptjs';
// import PropTypes from 'prop-types';

// Assets
//import google_logo_icon from 'assets/icons/google-logo-icon.svg';
//import facebook_logo_icon from 'assets/icons/facebook-logo-icon.svg';
//import apple_logo_icon from 'assets/icons/apple-logo-icon.svg';
//import {  ReactComponent as AppleLogoIcon   } from 'assets/icons/apple-logo-icon.svg';
import phone_icon from 'assets/icons/phone-icon.svg';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TermsAndConditionsModalComponent from 'app/authentication/components/terms-and-conditions-modal/TermsAndConditionsModalComponent';

// Styles
import './SignUpStartPanelComponent.styles.css';

// Actions
import { ChangeErrorLogin } from 'app/authentication/actions/ErrosLogin';
import { changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { signUpWithSocialAction, getTermsAndConditionsAction } from 'app/authentication/actions/LoginActions';
import {changeTermsModal} from 'app/authentication/actions/LoginActionsCreators';

// Facebook Social Auth Button
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//Google Social Auth Button
//import GoogleLogin from 'react-google-login';

//Apple Social Auth Button
//import AppleLogin from 'react-apple-login'

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';




class SignUpStartPanelComponent extends Component {


    componentDidMount () {
        scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', async () => {
            const params = {
                clientId: 'com.baloa.singinservice',
                redirectURI: 'https://dev.baloasports.com/callbacks/sign_in_with_apple',
                //redirectURI: 'com.baloa.singinservice',                
                //redirectURI: 'https://dev.baloasports.com',
                scope: 'name email', 
                //state : "initial",
                usePopup: true,
            };
            const data = await window.AppleID.auth.init(params); 
            console.log("Respuesta AppleID", data);
        });
        this.props.getTermsAndConditionsAction();
    }

    render = () => {
        return (
            <React.Fragment>
                {/* Modals */}
                <TermsAndConditionsModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.signup.terms_modal } 
                    onClose={() => { this.props.changeTermsModal( false ) }} 
                    terms={this.props.state.signup.terms_conditions}
                    checkTerms = { () => {} }
                />
                <div className="signup-start-panel__new-account__container">
                    <div className="signup-start-panel__new-account__left-col" />
                    <div className="signup-start-panel__new-account__right-col">                        
                        <h3 className="signup-start-panel__new-account__title">{this.props.t('page.title')}</h3>
                        <div className="new-account__methods">
                            <SecondaryButtonComponent
                                onClick={() => { this.props.changeSignUpStatus(SIGNUP_STATUS['EMAIL']) }} 
                                className="large signup-start-panel__new-account__methods__method"
                            >
                                <span className="icon-email" />
                                <span className="signup-start-panel__new-account__methods__method__label">{this.props.t('email.btnLabel')}</span>
                            </SecondaryButtonComponent>
                            <SecondaryButtonComponent
                                onClick={() => { this.props.changeSignUpStatus(SIGNUP_STATUS['PHONE']) }}
                                className="large signup-start-panel__new-account__methods__method phone"
                            >
                                <img src={phone_icon} alt="" />
                                <span className="signup-start-panel__new-account__methods__method__label">{this.props.t('page.button')}</span>
                            </SecondaryButtonComponent>
                            {/* <div className="signup-start-panel__separator__container">
                                <div className="signup-start-panel__separator" />
                                <span>o</span>
                                <div className="signup-start-panel__separator" />
                            </div> */}
                            {/* <GoogleLogin
                                autoLoad={false}
                                clientId="86128099741-0qbt1o8gqtukuspavks1aleertm1t3h0.apps.googleusercontent.com"
                                //clientId="773972066940-o9m3fp4s258f90fua21aj9jglocu50d4.apps.googleusercontent.com" //Google localhost
                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={renderProps.onClick}
                                        className="large signup-start-panel__new-account__methods__method">
                                        <img src={google_logo_icon}  alt="" /><span>Google</span>
                                    </SecondaryButtonComponent>
                                )}
                                onSuccess={(data) => {                            
                                    this.props.signUpWithSocialAction(data.accessToken, 'google-oauth2')
                                }}
                                cookiePolicy={'single_host_origin'}
                                icon={false}
                            /> */}
                            {/* <FacebookLogin
                                appId="1203616303336440"   
                                callback={(data) => {
                                    this.props.signUpWithSocialAction(data.accessToken, 'facebook')
                                }}
                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={() => { renderProps.onClick() }}
                                        className="large signup-start-panel__new-account__methods__method">
                                        <img src={facebook_logo_icon} alt="" /><span>Facebook</span>
                                    </SecondaryButtonComponent>
                                )}
                            /> */}
                            {/* <AppleLogin 
                                callback={(data) => {
                                    if(!data.error){
                                        this.props.signUpWithSocialAction(data.authorization.id_token, 'apple-id')                                
                                    }                                    
                                }}
                                clientId="com.baloa.singinservice" 
                                redirectURI="https://dev.baloasports.com/callbacks/sign_in_with_apple" 
                                usePopup = {true}
                                render={renderProps => (
                                    <SecondaryButtonComponent
                                        onClick={() => { renderProps.onClick() }}
                                        className="large signup-start-panel__new-account__methods__method signup-start-panel__new-account__methods__method__apple">
                                        <AppleLogoIcon className="signup-start-panel__apple-logo apple-logo" alt="" /><span className="apple-logo">Apple</span>
                                    </SecondaryButtonComponent>
                                )}
                            /> */}
                        </div>
                        <p className="signup-start-panel__new-account__terms-conditions__text">Al crear una cuenta estas aceptando <span className="signup-start-panel__terms-conditions" onClick={() => this.props.changeTermsModal(true) }>los terminos y condiciones</span></p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChangeErrorLogin: (values) => {
            return dispatch(ChangeErrorLogin(values))
        },
        changeSignUpStatus: (value) => {
            return dispatch(changeSignUpStatus(value))
        },
        signUpWithSocialAction: (value, social_network) => {
            return dispatch(signUpWithSocialAction(value, social_network));
        },
        changeTermsModal: ( new_modal_state ) => {
            return dispatch( changeTermsModal( new_modal_state ))
        },
        getTermsAndConditionsAction:( ) => {
            return dispatch( getTermsAndConditionsAction())
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpStartPanelComponent);
