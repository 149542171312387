// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ModalComponent from 'shared/components/modal/ModalComponent';

//Material
import ErrorIcon from "@mui/icons-material/Error";

// Assets
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';

//i18
import { useTranslation } from 'react-i18next';

// Styles
import './TeamMemberInfoModal.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function TeamMemberInfoModal(props) {

    const { t } = useTranslation('league');

    const viewProfile = () => {        
        props.onViewProfile();
        props.onClose();
    }

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={t('options.teamsAndMembers.tabs.tabTeams.modals.modalTeamInfo.labelInfoMemberTitle')}
            className="team-member-info-modal"
        >
            <div className="team-member-info-modal__container">
                { props.infoMember?.photo === null 
                    ?
                    <span className="icon-ball" color={getRandonIconBallColor()} />
                    :
                    <img src={props.infoMember?.photo} alt={`${props.infoMember?.username} img`} />                
                }
                <div className="baloa-names">{props.infoMember?.fullname}</div>
                <div className="baloa-username">{`@${props.infoMember?.username}`}</div>
                <div dangerouslySetInnerHTML={{__html: props.infoMember?.table_state}}></div>
                {props.infoMember?.player_state === 'no_registered' &&
                    <div className='team-member-info-modal__message baloa-username'>
                        <ErrorIcon
                            style={{
                                fontSize: 24,
                                color: "var(--primary-dark-color)",
                                marginRight: "var(--space)",
                                alignSelf: "center",
                            }}
                        />
                        <span>{t("team:team.edit.members.notRegisteredMsgInfoModal")}<span className='team-member-info-modal__message__link' onClick={()=>{props.onEditMember(props.infoMember);props.onClose();}}>{t("team:team.edit.members.notRegisteredMsgInfoModalLink")}</span></span>
                    </div>
                }
                {props.infoMember?.player_state === 'pre_registered' &&
                    <div className='team-member-info-modal__message baloa-username'>
                        <ErrorIcon
                            style={{
                                fontSize: 24,
                                color: "var(--primary-dark-color)",
                                marginRight: "var(--space)",
                                alignSelf: "center",
                            }}
                        />
                        {t("team:team.edit.members.preRegisteredMsg")}
                    </div>
                }
                <div className="team-member-info-modal__btns-action">
                    <PrimaryButtonComponent
                        className="xs"
                        onClick={ () => viewProfile() }
                        type="button"
                        disabled={false}
                    > {t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelBtnSeePage')} 
                    </PrimaryButtonComponent>
                    {/* <SecondaryButtonComponent
                        id=""
                        className=""
                        onClick={ () => props.onDelete() }
                        type="button"
                        disabled={false}
                    > {t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelBtnDelete')} 
                    </SecondaryButtonComponent> */}
                </div>
                <div className="team-member-info-modal__info-subtitle baloa-table-column">{t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTitle')}<IconEdit className="team-member-info-modal__icon-edit" onClick={()=>{props.onEditMember(props.infoMember);props.onClose();}}/></div>
                <div className="team-member-info-modal__info-row">
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelRole')}</div>
                        <div className="baloa-username">{props.infoMember?.table_role? props.infoMember?.table_role : t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}</div>
                    </div>
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelCountry')}</div>
                        <div className="baloa-username">{props.infoMember?.nationality? props.infoMember.nationality : t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}</div>
                    </div>                    
                </div>
                <div className="team-member-info-modal__info-row">
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelPosition')}</div>
                        <div className="baloa-username">{props.infoMember?.position? props.infoMember.position : t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}</div>
                    </div>
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelNumber')}</div>
                        <div className="baloa-username">{props.infoMember?.shirt_number? props.infoMember?.shirt_number : t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}</div>
                    </div>
                </div>
                <div className="team-member-info-modal__info-row">
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelPhone')}</div>
                        <div className="baloa-username">{`${props.infoMember?.phone? `+${props.infoMember?.phone}`: t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}`}</div>
                    </div>
                    <div className="team-member-info-modal__info-column">
                        <div className="baloa-username">{t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelMail')}</div>
                        <div className="baloa-username">{(props.infoMember?.email && !props.infoMember?.email.includes('baloa.com'))? props.infoMember.email : t('options.teamsAndMembers.tabs.tabStaff.modals.modalMemberInfo.labelEmpty')}</div>
                    </div>                    
                </div>
            </div>            
        </ModalComponent>
    )
}

export default TeamMemberInfoModal