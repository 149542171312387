// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from 'config/GlobalData';

export const GetTeamsApi = () => {
    return Axios.instance.get( `${ api_url }/teams/`,
        {
            headers: {
                'Authorization': localStorage.getTeams('token')
            }
        }
    );
}

export const GetCountryApi = () => {

    return Axios.instance.get(`${api_url}/country-gateway/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetCountryIdApi = (owner_id) => {

    return Axios.instance.get(`${api_url}/country-gateway/?search=${owner_id}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetPaymantesFormTemplateIdApi = ( form_type, owner_id ) => {

    return Axios.instance.get(`${api_url}/forms-template/?form_type=${form_type}&owner_id=${owner_id}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetCountryStatesApi = (country) => {
    return Axios.instance.get(`${api_url}/states/?country=${country}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetGatewayAttributeApi = (country_gateway_id, question_type) => {
    return Axios.instance.get(`${api_url}/gateway-attribute/?country_gateway=${country_gateway_id}&question_type=${question_type}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}


