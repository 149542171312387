// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './SignUpEmailPanelComponent.styles.css';

// Actions
import { signUpEmailAction } from 'app/authentication/actions/LoginActions';
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

class SignUpEmailPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            disabled: false,
        };        
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    validatePasswordMatch(password, verify_password, email_values) {
        if(password === verify_password) {
            this.props.signUpEmailAction(email_values);
        } else {
            const data = {
                'field': 'confirm_password',
                'error': 'Las contraseñas deben coincidir'
            }            
            this.props.changeSignUpError( data );
        }
    }

    resetErrorVerifyPassword(){
        const dataVerifyPassword = {
            'field': 'confirm_password',
            'error': ''
        }
        this.props.changeSignUpError( dataVerifyPassword);
    }    
    
    render = () => {        
        const formInitialValues = {
            email: "",
            password: "",
            confirm_password: ""
        }
        
        return (
            <div className="signup-email-panel__container">
                <div className="signup-email-panel__container__left-col"></div>
                <div className="signup-email-panel__container__right-col">
                    <div className="signup-email-panel__title">{this.props.t('page.title')}</div>
                    <div className="signup-email-panel__text">{this.props.t('email.text')}</div>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { 
                            let email_values = { email: values.email, password: values.password }
                            this.validatePasswordMatch(values.password, values.confirm_password, email_values);
                            } 
                        }
                        className = "signup-email-panel__form"
                        functionDisabled = { this.disabled.bind( this ) }
                    >
                        <div className="signup-email-form__fields">                            
                            <label className="signup-email-form__label">{this.props.t('email.btnLabel')}</label>
                            <InputFieldComponent
                                input_id="signup-email-panel__email"
                                field_name="email"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""
                                type="text"
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                            />
                            <label className="signup-email-form__label">{this.props.t('password.title')}</label>
                            <InputFieldComponent
                                label=''
                                input_id="signup-email-panel__password"
                                field_name="password"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""                               
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                                input_type="password"
                            />
                            <label className="signup-email-form__label">{this.props.t('password.input')}</label>
                            <InputFieldComponent
                                label=''
                                input_id="signup-email-panel__confirm-password"
                                field_name="confirm_password"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""                                
                                resetError={() => {this.resetErrorVerifyPassword()}}
                                error={this.props.state.signup_error.error}
                                message=""
                                input_type="password"
                            />
                        </div>
                        <div className="signup-email-panel__form__submit">
                            <PrimaryButtonComponent type="submit" disabled={!this.state.disabled} onClick={() => {}} className="signup-email-panel__form__submit">
                                <span>{this.props.t('code.button')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </FormComponent>                    
                    <TextButtonComponent className="signup-email-panel__form__change-method" onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['START'] )}>
                        <span>{this.props.t('code.method')}</span>
                    </TextButtonComponent>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpEmailAction: (email_values) => {
            return dispatch(signUpEmailAction(email_values))
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpEmailPanelComponent);