// React
import React from 'react';
import CurrencyFormat from 'react-currency-format';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import ModalComponent from 'shared/components/modal/ModalComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Moment
import * as moment from 'moment';

// Styles
import './LeagueMatchRefereesPaymentResumeModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function LeagueMatchRefereesPaymentResumeModalComponent( props ) {

    const handleOnLoadRefereeingPaymentUrl = () => {
        let payment_data = {
            "teamname": props.team_payment.team,
            "team_venue": props.team_payment.team_type,
            "reference": "PAY_TOURNAMENT_MATCH_REFEREE",
        }
        props.handleOnLoadRefereeingPaymentUrl(payment_data, props.team_payment.match_id, props.match_data.date_id);
    }

    return(

        <ModalComponent
            isOpen={props.isOpen}
            className='league-match-referees-payment-resume'
        >
            <div className='league-match-referees-payment-resume__container'>
                <div className='league-match-referees-payment-resume__title baloa-subtitle-2'>{props.t('options.tournaments.pages.matchRefereesPayment.infoPaymentTitle')}</div>

                <div className='league-match-referees-payment-resume__match-info'>
                    <div className='league-match-referees-payment-resume__match-info__title baloa-table-column'>{`${props.t(`options.tournaments.pages.matchRefereesPayment.${props.match_data.date_match}`)} (${moment(props.match_data.match_date).format("DD MMM YYYY")})`}</div>
                    
                    <div className='league-match-referees-payment-resume__match-info__teams'>
                        <div className='league-match-referees-payment-resume__match-info__team'>
                            <div className='league-match-referees-payment-resume__match-info__team-img'>
                                { props.match_data.local_team_photo && <img src={ props.match_data.local_team_photo } alt={`${props.match_data.local_team} img`}/>}
                                { !props.match_data.local_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </div>
                            <div className='league-match-referees-payment-resume__match-info__team-name'>
                                <div className='baloa-names'>{props.match_data.local_team_name}</div>
                                <div className='baloa-username'>@{props.match_data.local_team}</div>
                            </div>
                        </div>
                        <div className='baloa-table-column'>vs</div>
                        <div className='league-match-referees-payment-resume__match-info__team'>
                            <div className='league-match-referees-payment-resume__match-info__team-name'>
                                <div className='baloa-names'>{props.match_data.visitant_team_name}</div>
                                <div className='baloa-username'>@{props.match_data.visitant_team}</div>
                            </div>
                            <div className='league-match-referees-payment-resume__match-info__team-img'>
                                { props.match_data.visitant_team_photo && <img src={ props.match_data.visitant_team_photo } alt={`${props.match_data.visitant_team} img`}/>}
                                { !props.match_data.visitant_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='league-match-referees-payment-resume__reference-container'>
                    <div className='league-match-referees-payment-resume__reference'>
                        <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.referenceLabel')}</div>
                        { props.tournament?.referee_payments && <div className='baloa-hyperlink-2'>{props.t('options.tournaments.pages.matchRefereesPayment.refereeingTitle')}</div>}
                    </div>
                    <div className='league-match-referees-payment-resume__tournament-reference'>
                        <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.tournamentLabel')}</div>
                        <div className='league-match-referees-payment-resume__tournament-info'>
                            <div className='league-match-referees-payment-resume__tournament-img'>
                                { props.tournament.tournament_logo && <img src={ props.tournament.tournament_logo } alt={`${props.tournament.name} img`}/>}
                                { !props.tournament.tournament_logo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                            </div>
                            <div className='league-match-referees-payment-resume__tournament-name'>
                                <div className='baloa-hyperlink-2'>{props.tournament.name}</div>
                                <div className='league-match-referees-payment-resume__tournament-name__labels'>
                                    <div className='baloa-mini'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.football_type}`)}</div>
                                    <div className='baloa-mini'>{props.t(`options.tournaments.pages.newTournament.tabs.tabTeams.${props.tournament.category}`)}</div>
                                    <div className='baloa-mini'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.tournament_gender}`)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='league-match-referees-payment-resume__team-pays__container'>
                    <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.teamPaysLabel')}</div>
                    <div className='league-match-referees-payment-resume__team-info'>
                        <div className='league-match-referees-payment-resume__team-img'>
                            { props.team_payment?.team_photo && <img src={ props.team_payment.team_photo } alt={`${props.team_payment.team} img`}/>}
                            { !props.team_payment?.team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                        </div>
                        <div className='league-match-referees-payment-resume__team-name'>
                            <div className='baloa-hyperlink-2'>{props.team_payment?.team_name}</div>
                            <div className='baloa-username'>@{props.team_payment?.team}</div>
                            
                        </div>
                    </div>
                </div>

                {/* <div className='league-match-referees-payment-resume__discount-code'>
                    <div className='baloa-table-column'>{props.t('options.tournaments.pages.matchRefereesPayment.discountCodeLabel')}</div>
                    <div className='league-match-referees-payment-resume__discount-code__input'>
                        <input 
                            type='text'
                            className='baloa-hyperlink-2'
                        />
                        <SecondaryButtonComponent onClick={ () => {} } >{props.t('options.tournaments.pages.matchRefereesPayment.applyLabel')}</SecondaryButtonComponent>
                    </div>
                </div> */}

                <div className='league-match-referees-payment-resume__total-amount'>
                    <div className='baloa-table'>{props.t('options.tournaments.pages.matchRefereesPayment.totalFee')}</div>
                    <CurrencyFormat
                        value={props.team_payment?.refereeing_value_team} 
                        displayType={'text'} 
                        thousandSeparator={','} 
                        decimalSeparator={"."} 
                        prefix={'$ '} 
                        suffix={' ' + props.tournament?.currency_payment}
                        onValueChange ={()=>{}}
                        renderText={value => <div className='baloa-hyperlink-2'>{value}</div>}
                    />
                </div>

            </div>

            <div className='league-match-referees-payment-resume__buttons-action'>
                <SecondaryButtonComponent onClick={ () => {props.onClose()} } >{props.t('options.tournaments.pages.matchRefereesPayment.cancelBtn')}</SecondaryButtonComponent>
                <PrimaryButtonComponent onClick={ () => { handleOnLoadRefereeingPaymentUrl() }} >{props.t('options.tournaments.pages.matchRefereesPayment.continueBtn')}</PrimaryButtonComponent>
            </div>
        </ModalComponent>
        

    )

}

export default withTranslation('league')(LeagueMatchRefereesPaymentResumeModalComponent);
