// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league_match_assistance-section{
    margin: var(--space-and-half);
}

.league_match_assistance-section .league_match_assistance-section-subtitle.baloa-table{
    margin: var(--space) 0;
}

.league_match_assistance_section-teams-container{
    display: flex;
    align-items: flex-start;
    gap:var(--space-half);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-match-assistance-aligment/LeagueMatchAssistanceAligment.styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;AACzB","sourcesContent":[".league_match_assistance-section{\n    margin: var(--space-and-half);\n}\n\n.league_match_assistance-section .league_match_assistance-section-subtitle.baloa-table{\n    margin: var(--space) 0;\n}\n\n.league_match_assistance_section-teams-container{\n    display: flex;\n    align-items: flex-start;\n    gap:var(--space-half);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
