// user settings state
const user_settings_state = {
    country_payments: [],
    form_payments: {},
    gateway_attribute: [],
    countries: [],
    country_states: [],
    is_user_onboarding_form_invalid: false,
    profile_account_status: undefined,
    account_response: undefined,
    retrive_form_data: undefined,
    user_form_payment_loading: false,
    errors: {
        first_name: '',
        last_name: '',
        phone: '',
        username : '',
    },
    user_message: {
        message: '',
        user_message_is_open: false,
    },
    modals:{
        payment_settings_modal_open: false
    }
}

export default function UserSettingsReducer( state = user_settings_state, action ) {

    switch ( action.type ) {

        case "USERSETTINGS:CHANGE_ERROR": {
            const errorData = state.errors;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }

        }
        
        case "USERSETTINGS:CHANGE_USER_MESSAGE_SNACKBAR": {
            return { ...state, user_message: action.new_state }
        }

        case "USERSETTINGS:CHANGE_USER_PAYMENTS_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], payment_settings_modal_open: action.new_state }
            return { ...state, modals: new_modals_state}
        }

        case "USERSETTINGS:LOAD_PAYMENTS_COUNTRIES":{
            return{...state, country_payments: action.data.data.results}
        }

        case "USERSETTINGS:lOAD_PAYMENTS_FORM":{
            return{...state, form_payments: action.data.data[0]}
        }
        
        case "USERSETTINGS:GET_USER_GATEWAY_ATTRIBUTE": {
            return { ...state, gateway_attribute: action.data?.data}
        }

        case "USERSETTINGS:GET_USER_COUNTRIES": {
            return { ...state, countries: action.data.data?.results }
        }

        case "USERSETTINGS:GET_USER_COUNTRY_STATES": {
            return { ...state, country_states: action.data?.data }
        }
        
        case "USERSETTINGS:CHANGE_USER_ONBOARDING_FORM_VALIDATION_STATE": {
            return { ...state, is_user_onboarding_form_invalid: action.new_state }
        }

        case "USERSETTINGS:CLEAN_USER_COUNTRY_FORM": {
            return{...state, countries: action.data}
        }

        case "USERSETTINGS:CHECK_ACCOUNT_STATUS": {
            return{...state, profile_account_status: action.data.data.account_state, account_response: action.data.data.account_response }
        }

        case "USERSETTINGS:LOAD_FORMS_DATA": {
            return { ...state, retrive_form_data: action.data.data }
        }

        case "USERSETTINGS:CHANGE_USER_FORM_PAYMENT_LOADING_STATE": {
            return { ...state, user_form_payment_loading: action.new_state }
        }
        
        // no default

    }
    return state;

}