// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
    margin-top: 27px;
    padding: var(--space-x2);
}
.info__content {
    display: flex;
    align-items: center;
}
.info__content__title{
    /* margin-bottom: var(--space-x2-and-half); */
}
.info__content__title__text.baloa-subtitle-2{
    color: var(--primary-very-dark-color);
}
.info__content__icon span{
    margin-right: var(--space);
    font-size: var(--space-x2);
    color: var(--new-green-color);
    cursor: pointer;
}
.info__form {
}
.info__form__form__input{
    margin-top: var(--space-x3);
    margin-bottom: var(--space-and-half);
}
.info__form__form__actions {
    display: flex;
    justify-content: flex-end;
}
.info__recommendations {
    margin-top: var(--space-x2);
}
.info__recommendations__text {
    font-size: 15px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/user-settings/components/edit-information/EditInformationComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,6CAA6C;AACjD;AACA;IACI,qCAAqC;AACzC;AACA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,6BAA6B;IAC7B,eAAe;AACnB;AACA;AACA;AACA;IACI,2BAA2B;IAC3B,oCAAoC;AACxC;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".info {\n    margin-top: 27px;\n    padding: var(--space-x2);\n}\n.info__content {\n    display: flex;\n    align-items: center;\n}\n.info__content__title{\n    /* margin-bottom: var(--space-x2-and-half); */\n}\n.info__content__title__text.baloa-subtitle-2{\n    color: var(--primary-very-dark-color);\n}\n.info__content__icon span{\n    margin-right: var(--space);\n    font-size: var(--space-x2);\n    color: var(--new-green-color);\n    cursor: pointer;\n}\n.info__form {\n}\n.info__form__form__input{\n    margin-top: var(--space-x3);\n    margin-bottom: var(--space-and-half);\n}\n.info__form__form__actions {\n    display: flex;\n    justify-content: flex-end;\n}\n.info__recommendations {\n    margin-top: var(--space-x2);\n}\n.info__recommendations__text {\n    font-size: 15px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
