// React
import React from 'react';
import PropTypes from 'prop-types';

// Components

// Styles
import './TableHeaderActions.styles.css';
function TableHeaderActions(props) {
  
    const { onSearch, placeholder, has_searchbar, has_add_button, add_button_caption, has_optional_button, optional_button_caption, toggle_header_buttons, button_icon, optional_button_icon } = props;

    const handleSearch = (event) => {      
      onSearch(event);
    };

    return (       
     
      <div class="table-header-actions__form__actions_grid-container">
        <div class="table-header-actions__form__actions-search">
          { has_searchbar &&
            <div class="table-header-actions-search-form__form" autocomplete="off"> 
              <span className="icon-search" />               
              <input class="table-header-actions-search_input baloa-names" type="text" placeholder={placeholder} onChange={(event) => { handleSearch(event) }}></input>                                    
            </div>
          }
        </div>
        <div class="table-header-actions__add">
          {has_add_button &&
            <div class="table-header-actions__form__actions">
              <button type="submit" className={`button ${toggle_header_buttons? 'secondary-button small' : 'primary-button small'}`}
                  onClick = { ()=> props.handleOnAddButtonClick() }
              >
                {button_icon &&
                  button_icon !== 'hubicon-create_team'?
                  <span className={`icon ${button_icon}`}></span>
                  :
                  <span className={`icon ${button_icon}`}>
                      <span className="path2"></span>
                      <span className="path3"></span> 
                  </span>
                }
                <span>{ add_button_caption }</span></button>
            </div>
          }          
        </div>
        <div className='table-header-actions__optional-button'>
            {has_optional_button &&
                <div class="table-header-actions__form__actions">
                  <button type="button" className={`button ${toggle_header_buttons? 'primary-button small' : 'secondary-button'}`}
                      onClick = { ()=> props.handleOnOptionalButtonClick() }
                  >
                    {optional_button_icon &&
                      optional_button_icon !== 'hubicon-create_team'?
                      <span className={`icon ${optional_button_icon}`}></span>
                      :
                      <span className={`icon ${optional_button_icon}`}>
                          <span className="path2"></span>
                          <span className="path3"></span> 
                      </span>
                    }                                      
                    <span>{ optional_button_caption }</span>
                  </button>
                </div>
            }
        </div>
      </div>  
      
    );
  }

export default TableHeaderActions;

TableHeaderActions.propTypes = {  
  onSearch: PropTypes.func.isRequired,    
};

