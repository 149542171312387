// React
import React from 'react'

// Styles
import './PageAdminGroupsRequestComponent.styles.css'

// Assets
import baloa_user_icon from 'assets/icons/baloa-user.svg'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

//i18
import { withTranslation } from 'react-i18next';


function PageAdminGroupsRequestComponent( props ) {

    return (
        
        <Card1Component 
            className={ "page-admin-requests " + ( props.className ? props.className : '' ) } 
            title={props.t('groups.adminRequestCard.title')} 
        >

            <div className="page-admin-requests__container">
                {
                    props.page_member_request?.map( request => 

                        <div className="page-admin-requests__members" id={request.user?.username}>
                            <div
                                onClick={() => props.handleOnProfileClick( request.user )}
                                className={"page-admin-requests__photo " + (request.user?.photo ? '' : 'no-photo')}>
                                <img src={request.user?.photo ? request.user.photo : baloa_user_icon} alt="" />
                            </div>
                            <div className="page-admin-requests__info"
                                onClick={() => props.handleOnProfileClick( request.user )}
                            >
                                <div                                    
                                    className="page-admin-requests__info__username baloa-subtitle-2">
                                    {"@" + request.user?.username}
                                </div>
                                <div
                                    className="page-admin-requests__info__username baloa-caption">
                                    {request.user?.first_name + " " + request.user?.last_name}
                                </div>
                               
                            </div>
                            <div className="page-admin-members__actions"> 
             
                                <TextButtonComponent 
                                    className="page-admin-members__button"
                                    onClick={ () => props.onRejectButtonClick(request.id, request.user?.username) }
                                >
                                    <span>{ props.t('groups.adminRequestCard.btnLablReject') }</span>
                                </TextButtonComponent>
                
                                <SecondaryButtonComponent 
                                    className="page-admin-members__button"
                                    onClick={ () => props.onAcceptButtonClick(request.id, request.user?.username) }
                                >
                                    <span>{ props.t('groups.adminRequestCard.btnLablAccept') }</span>
                                </SecondaryButtonComponent>
                
                            </div>

      
                        </div>
                    )
                }
            </div>

        </Card1Component>

    )

}

export default withTranslation('page')(PageAdminGroupsRequestComponent)
