import React from "react";

import Modal2Component from 'shared/components/modal2/Modal2Component';
import LeagueMatchAssistanceAligment from '../league-match-assistance-aligment/LeagueMatchAssistanceAligment';

//i18
import { withTranslation } from 'react-i18next';

import './LeagueMatchPlayersAssistantModalComponent.styles.css';

function LeagueMatchPlayersAssistantModalComponent(props) {

    const positions_members_traslate = [
        { value: 'goalkeeper', content: props.t("team:team.tabs.members.goalkeeper") },
        { value: 'defense', content: props.t("team:team.tabs.members.defense") },
        { value: 'midfield', content: props.t("team:team.tabs.members.midfield") },
        { value: 'forward', content: props.t("team:team.tabs.members.forward") }
    ];

    const lowerCase = (word) => { return word[0]?.toLowerCase() + word.slice(1) }

    const validationInitialMemberAssignment = () => {
        let initialMemberAssigment = {
            local: [],
            visitor: [],
        };
        initialMemberAssigment.local = props.teams_match_assistance?.local_team?.players?.map((player)=>{
            return player.is_assigned ? {
                team_id: props.teams_match_assistance?.local_team.team_id,
                playerId: player.player_id,
                playerNumber: player.number,
                playerPositions: lowerCase(player?.player_position ?? ''),
                playerStateInMatch: player.rol_in_match ?? '',
                defaultChecked: true,
            } : null
        }).filter(member => member !==  null );

        initialMemberAssigment.visitor = props.teams_match_assistance?.visitant_team?.players?.map((member)=>{
            return member.is_assigned ? {
                team_id: props.teams_match_assistance?.visitant_team.team_id,
                playerId: member.player_id,
                playerNumber: member.number,
                playerPositions: lowerCase(member?.player_position ?? ''),
                playerStateInMatch: member.rol_in_match ?? '',
                defaultChecked: true,
            } : null
        }).filter(member => member !==  null );

        return initialMemberAssigment;
    }

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.matchInfo.labelTitleAsisstanceModal')}
            className="league_match_player_assistance small scroll-parent"
        >
            <div className="league_match_player_assistance-container">
                <LeagueMatchAssistanceAligment
                    step={0}
                    stepCompleted={{}}
                    endGameStepState={{}}
                    setMatchAssistant={{}}
                    setEndGameStepState={props.onClose}
                    local_team={props.teams_match_assistance?.local_team}
                    local_team_members={props.teams_match_assistance?.local_team?.players ?? []}
                    visitor_team={props.teams_match_assistance?.visitant_team}
                    visitor_team_members={props.teams_match_assistance?.visitant_team?.players ?? []}
                    positions_members={positions_members_traslate}
                    updatePlayersAssistanceAligment={(allPlayer) => {
                        props.updatePlayersAssistanceAligment(allPlayer);
                        props.onClose();

                    }}
                    initialMemberAssigment={validationInitialMemberAssignment()}
                    onlyComponentInModal= {true} // README: This property hides the stepper and changes the label of the button
                    match_data={props.match_data}
                />
            </div>
        </Modal2Component>);
};

export default withTranslation(["league", "team"])(LeagueMatchPlayersAssistantModalComponent);
