/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './HomePostWidgetComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


function HomePostWidgetComponent( props ) {

    return (
        <div className={ "home-post-widget"}>
            <div className={"home-post-widget__input"}>
                <div className={"home-post-widget__input__image"}>
                    {props.photo &&
                        <img src={ props.photo } alt=""/>
                    }
                    {!props.photo &&
                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                    }
                </div>
                <div className={"home-post-widget__input__input baloa-names"} onClick={ props.onClick } >{ props.t('homeFeed.widgetPlaceholder') }</div>
            </div>
            <div className={"home-post-widget__separator"}></div>
            <div className={"home-post-widget__buttons"}>
                <div className={"home-post-widget__buttons__button"} onClick={ props.onClick } >
                    <span className={"icon-image-70"}></span><span className="baloa-names" >{props.t('post:postModal.titleImg')}</span> 
                </div>
                <div className={"home-post-widget__buttons__button"} onClick={ props.onClick } >
                    <span className={"icon2-video-offer"}></span><span className="baloa-names" >{props.t('post:postModal.titleVideo')}</span> 
                </div>
                <div className={"home-post-widget__buttons__button"} onClick={ props.onClick } >
                    <span className={"icon-field"}></span><span className="baloa-names" >{props.t('post:postModal.titleFormation')}</span>
                </div>
            </div>
        </div>
    )
}

// Exports
export default withTranslation(["home","post"])(HomePostWidgetComponent);
