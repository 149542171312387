// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

// Styles
import './HomePagesExploreFilterModalComponent.styles.css';
import { withStyles } from "@mui/styles";

//i18
import { withTranslation } from 'react-i18next';

function HomePagesExploreFilterModalComponent ( props ){
    const StyledToggleButtonGroup = withStyles({
        root: {
            display: 'inline-block',
            '& .MuiToggleButtonGroup-grouped': {
                margin: '6px',
                border: '1px solid var(--new-gray-background-color)',
                
                borderRadius: 4,
                padding: '5px 10px',
            },
            '& .MuiToggleButton-root': {
                textTransform: 'none',
                padding: '5px 10px',
                '&.Mui-selected': {
                    border: '1px solid var(--new-green-color)',
                    background: '#E6F6EC',
                    color: 'var(--new-green-color)',
                },
            },
        },
    })(ToggleButtonGroup);
    //border-radius: 4px;
    //border: 0.5px solid var(--new-green, #30B36B);
    //background: #E6F6EC;
    return(
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className={'home-pages-explorer-filter-modal'}
            title={props.t('pages.advancedFilters')}
        >
            <div className={`home-pages-explorer-filter-modal__container`}>                    
                    <div className={`home-pages-explorer-filter-modal__filter`}>
                        <div className='baloa-subtitle-2'>{props.t('page:dataModals.pageType')}</div>
                        <RadioGroup row
                            aria-labelledby="page_type"
                            name={"page_type"}
                            value={''}
                            className="home-pages-explorer-filter-modal__option-button inside"
                        >
                            <FormControlLabel
                                value="organizations"
                                control={<Radio color="secondary" checked={props.filterPageType === 'organizations'} />}
                                label={<span className="baloa-subtitle-2">
                                        {props.t('page:newPage.footballOrganizations.title')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPageType === 'organizations' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterPageType(e)}
                            />
                            <FormControlLabel
                                value="institutions"
                                control={<Radio color="secondary" checked={props.filterPageType === 'institutions'} />}
                                label={<span className="baloa-subtitle-2">
                                        {props.t('page:newPage.institutionsCorporations.title')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPageType === 'institutions' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterPageType(e)}
                            />
                            <FormControlLabel
                                value="content"
                                control={<Radio color="secondary" checked={props.filterPageType === 'content'} />}
                                label={<span className="baloa-subtitle-2">
                                        {props.t('page:newPage.contenGeneration.title')}
                                    </span>}
                                labelPlacement="end"
                                className={props.filterPageType === 'content' ? 'selected' : ''}
                                onChange={(e) => props.handleOnFilterPageType(e)}
                            />
                        </RadioGroup>
                    </div>
                    <div className={`home-pages-explorer-filter-modal__filter`}>
                        <div className='baloa-subtitle-2'>{props.t('page:dataModals.pageCategory')}</div>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={props.filterCategory}
                            onChange={(e, arr) => props.handleOnFilterModalCategory(e, arr)}
                            aria-label="category"
                            className={'home-pages-explorer-filter-modal__checkbox'}
                        >
                        {props.filterCategoryList.map(category => (
                            /* <FormControlLabel
                                name={'category'}
                                key={category.value}
                                value={category.value}
                                control={<Switch color="secondary" checked={ props.filterCategory.some(ply => ply === category.value) } />}
                                label={<span className="baloa-subtitle-2">
                                        {props.t(`page:newPage.type.${category.content}`)}
                                    </span>}
                                labelPlacement="end"
                                className={'home-pages-explorer-filter__checkbox'}
                                onChange={(e) => props.handleOnFilterCategory(e)}
                            /> */
                            
                        
                            <ToggleButton 
                                key={category.value}
                                value={category.value}
                                //selected={ props.filterCategory.some(ply => ply === category.value) }
                                //onChange={(e, arr) => props.handleOnFilterModalCategory(e, arr)}
                            >
                                {<span className="baloa-table">
                                        {props.t(`page:newPage.type.${category.content}`)}
                                    </span>}
                            </ToggleButton>
                        
                
                        ))}  
                        </StyledToggleButtonGroup>
                    </div>
                    <div className={'home-pages-explorer-filter-modal__mobile-submit-buttons'}>
                        <TextButtonComponent
                            onClick={props.resetFilter}
                        >
                            <span>{props.t('pages.cleanButtonFilterLbl')}</span>
                        </TextButtonComponent>
                        <PrimaryButtonComponent
                            onClick={props.handleModalFilter}
                        >
                            <span>{props.t('pages.applyButtonFilterLbl')}</span>
                        </PrimaryButtonComponent>
                    </div>
                
                </div>
        </Modal2Component>
    )

}

export default withTranslation('home')(HomePagesExploreFilterModalComponent);