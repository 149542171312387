import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      //order: ['navigator'],
    },
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      //wait: true,
      useSuspense: true,
    },
    fallbackLng: 'en',
    preload: ['en', 'es'],
    ns: ['home','post','page','dashboard','landing','login','common','profile', 'settings', 'league'],
    backend: {      
      // for all available options read the backend's repository readme file
      loadPath(lngs, namespaces) {
        return '/locales/{{lng}}/{{ns}}.json';
      },
      
    },
  });

export default i18n;
