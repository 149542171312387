import React, { useState } from 'react';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
//Validations
import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { UserNameValidator } from 'shared/validators/SignUpValidator';
// i18n
import { withTranslation } from 'react-i18next';

import './ModalPageCreateInformation.styles.css';

function ModalPageCreateInformation(props) {
    const [state, setState] = useState({ upload_logo_modal: false });
    const [img_Page, setImg_Team] = useState({});
    const formRef = React.useRef();

    const handleUploadLogoModal = (new_state) => setState({ upload_logo_modal: new_state });

    const handleTournamentLogo = (new_img, imageurl, img_b64) => {
        setImg_Team({ new_img, imageurl, img_b64 });
    };

    const formInitialValues = {
        name: '',
        pagename: '',
    };

    const handleSendPageData = (page_data) => {
        const { name, pagename } = page_data;
        const page_type = props.onlySelectedPage ? 'tournament' : 'team';
        props.handleSentToCreatePage({
            is_private: false,
            page_type,
            name,
            pagename,
            photo: img_Page.img_b64 ?? '',
        });
    }

    const handleSubmit=()=>{
        handleSendPageData(formRef.current?.values);
    }
    

    return (
        <React.Fragment>
            <div className='modal-page-create_newPage_data_logo_title baloa-username'>
                {props.t("league:modal_create_page.page_photo")}
            </div>
            <div className={"modal-page-create_newPage_data_logo"}>
                <div className='tournament-photo' onClick={() => handleUploadLogoModal(true)} >
                    {!img_Page.imageurl
                        ? <span className='icon-image-70' />
                        : <img className='img-url' src={img_Page.imageurl} alt="" />
                    }
                    <div className='baloa-username'>
                        {props.t("league:modal_create_page.img_upload")}
                    </div>
                </div>
            </div>
            <LeagueTeamUploadImageModalComponent
                isOpen={state.upload_logo_modal}
                onClose={() => handleUploadLogoModal(false)}
                setTeamImage={(new_img, imageurl, img_b64) => handleTournamentLogo(new_img, imageurl, img_b64)}
                aspect={1}
                cropImage={true}
            />
            <div className={'modal-page-create_newPage_form_input'}>
                <FormComponent 
                    formInitialValues={formInitialValues}
                    onSubmit={(values, actions) => {
                        //let new_page_data = {};
                        //new_page_data = { ...values };
                        //handleSendPageData(new_page_data);
                    }}
                    className="create-page"
                    functionDisabled={()=>{}}
                    enableReinitialize={false}
                    innerRef={formRef}
                >
                    <div className="modal-page-create-information-form">
                        <InputFieldComponent
                            label={props.t("league:modal_create_page.page_name")}
                            input_id="new-page-name"
                            field_name="name"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            className="modal-page-create-information-form_input"
                            input_type="text"
                            resetError={() => { }}
                            error={props.errors}
                            message=""
                            placeholder=""
                        />
                        <InputFieldComponent
                            label={props.t("league:modal_create_page.user_page_name")}
                            input_id="new-page-pagename"
                            field_name="pagename"
                            validationFunction={UserNameValidator}
                            disabled={false}
                            className="modal-page-create-information-form_input_pagename"
                            input_type="text"
                            resetError={() => { }}
                            error={props.errors}
                            placeholder="@"
                        />
                    </div>
                    <div className="modal-page-create-information-form_buttons">
                        <PrimaryButtonComponent
                            type="button"
                            onClick={() => { handleSubmit() }}
                            disabled={false}
                            className="modal-page-create-information-form_submit"
                        >
                            <span>{props.t("league:modal_create_page.create_page")}</span>
                        </PrimaryButtonComponent>
                    </div>
                </FormComponent>
            </div>
        </React.Fragment>
    );
};

export default withTranslation("league")(ModalPageCreateInformation);