// Page state
const page_group_state = {
    new_group_page: false,
    loading_group_suggestions: false,
    group_suggestions: [],
    group_suggestions_next_page: '',
    member_request: undefined,
    member_group_pages: [],
    error_message: {
        message: '',
        error_message_is_open: false,
    },
    modals: {
        reject_member_request_dialog_is_open: false,
    },
    new_group_page_post: false,
}

export default function PageGroupsReducer( state = page_group_state, action ) {
    
    switch ( action.type ) {

        case "HOME:LOAD_GROUP_SUGGESTIONS": {
            return { ...state, group_suggestions: action.data.results, group_suggestions_next_page: action.data.next }
        }

        case "HOME:CHANGE_LOADING_GROUP_SUGGESTIONS": {
            return { ...state, loading_group_suggestions: action.data }
        }

        case "HOME:CHANGE_ERROR_MESSAGE_SNACKBAR": {
            return { ...state, error_message: action.new_state }
        }
        case "HOME:CHANGE_NEW_GROUP_PAGE": {
            return { ...state, new_group_page: action.new_state }
        }

        case "PAGEGROUP:CHANGE_CANCEL_MEMBER_REQUEST_DIALOG_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], reject_member_request_dialog_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PAGEGROUP:CHECK_MEMBER_REQUEST": {
            return { ...state, member_request: action.data.data.results }
        }

        case "PAGEGROUP:SET_MEMBER_GROUP_PAGES": {
            return { ...state, member_group_pages: action.data.data.results }
        }

        case "PAGEGROUP:CHANGE_NEW_GROUP_PAGE_POST": {
            return { ...state, new_group_page_post: action.new_state }
        }

        // no default

    }

    return state;
}