/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Styles
import './TournamentGroupsComponent.styles.css';

//Components
import TournamentSetGroupsComponent from 'app/leagues/components/tournament-set-groups/TournamentSetGroupsComponent';
import TournamentListTeamsComponent from 'app/leagues/components/tournament-list-teams/TournamentListTeamsComponent';

//i18
import { withTranslation } from 'react-i18next';

function TournamentGroupsComponent( props ) {

    const [available_teams, setAvailableTeams] = React.useState( [] );
    const [avaibleGroups, setAvaibleGroups] = React.useState([]);
    const [avaibleListGroups, setAvaibleListGroups] = React.useState([]);
    const [cleantAllGroups, setCleanAllGroups] = React.useState(false);

    /* eslint-disable */
    React.useEffect( () => {
        if(/* available_teams?.length <= 0 || */ props.groups[0]?.teams.length >=2){
            props.handleActivePhaseCreateState(false)
        }else if(!props.active_phase_groups_create /*&& available_teams?.length > 0*/){
            props.handleActivePhaseCreateState(true)
        }
    }, [ available_teams ] );

    React.useEffect( () => {
        setAvailableTeams(props.teams);
    }, [ props.teams] );

    React.useEffect( () => {

        // if(props.groups_best_third || props.groups_best_third?.length > 0) {
        //     console.log('Entra a valiacion groups mejor tercero');
        //     setAvaibleGroups(props.groups_best_third?.filter((phase)=> phase.id === props.tournament_active_phase?.id)[0]?.groups);
        // }else {
        //     console.log('Entra a valiacion groups normal');
        //     setAvaibleGroups(props.groups);
        // }

        if(props.tournament_active_phase?.phase_type === 'Knockout') {
            setAvaibleListGroups(props.tournament_active_phase?.groups?.filter((groupPhase)=> groupPhase.teams.length < 2));   
        }
        setAvaibleGroups(props.groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.tournament_active_phase]);

    const handleOnAddTeamToTournamentGroup = (groupId, teamName) => {
        props.handleAddTeamToGroup(groupId, teamName);
    }

    const handleDeleteTeamFromTournamentGroup = ( team ) => {
        if(props.isCreatePhase){
            props.deleteTeamFromCreatePhase(team, team.team, props.tournament_active_phase?.id);
        } else {
            props.handleDeleteTeamFromGroup( team );
        }
    }

    const handleTournamentListTeams = (is_shuffle, filter_state) => {  
              
        if(is_shuffle) {
            //Asiganar automaticamente
            setAvailableTeams(props.teams);
            props.handleCreateNewAtomaticGroups(filter_state);
        } else {
            // Limpiar los grupos
            setCleanAllGroups(true);
            setAvailableTeams(props.teams);
            props.handleCleanAllGroups(props.tournament_active_phase?.id);
        }
    }

    const handleTeamFilter =(filterType)=>{ 
        props.handle_team_filter(filterType);
    };

    const handleDeleteGroupPhase = (group) =>{
        setAvaibleGroups(props.groups?.filter((groupItem)=> groupItem.id !== group.id));
        props.handleDeleteGroupPhase(group.id);
    }
    
    return (
            <React.Fragment>
                { (props.isCreatePhase || props.stepEditPhase) &&
                    <div className='tournament-groups__title_section'>
                        <div className='baloa-subtitle-1'>
                            {props.clickSourseCreatePhase === 'onClickNewPhase'
                                ? props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelAsingTeams'))
                                : props.stepEditPhase ? props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')) : props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase'))
                            }
                        </div>
                        <div className='baloa-names'>
                        {props.clickSourseCreatePhase === 'onClickNewPhase'
                                ? props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelAsigTeamsSubtitle'))
                                : props.t(('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelAsignewTeamsPhase'))
                            }
                        </div>
                    </div>
                }
                <div className={`tournament-groups__container ${props.isCreatePhase? 'full_screen' : ''}`}>
                <TournamentListTeamsComponent
                    teams={available_teams}
                    groups={props.tournament_active_phase?.phase_type === 'Knockout' ? avaibleListGroups : props.groups}
                    handleAddFunction={ handleOnAddTeamToTournamentGroup }
                    disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                    handle_team_filter= {handleTeamFilter}
                    select_groups_teams={props.select_groups_teams}
                    disable_phase_fields={props.disable_phase_fields}
                    handleCreateNewAtomaticGroups = {(is_shuffle, filter_state)=>{handleTournamentListTeams(is_shuffle, filter_state)}}
                    tournament_active_phase_type = { props.tournament_active_phase?.phase_type }
                    isCreatePhase={props.isCreatePhase}
                    tournament_active_phase_name = { props.tournament_active_phase?.phase_name }
                    showActions={props.tournament_active_phase?.state === 'Active'}
                    handleOnAddAllTeamsToGroup={props.handleOnAddAllTeamsToGroup}
                    cleantAllGroups={cleantAllGroups}
                    handleCleanAllGroups={() => {setCleanAllGroups(false)}}
                    teams_filter_type={props.teams_filter_type}
                />
                <TournamentSetGroupsComponent
                    groups={avaibleGroups}
                    handleDeleteFunction={ props.handleOnDeleteTeamFromGroup }
                    disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                    handleDeleteTeamFromTournamentGroup = { handleDeleteTeamFromTournamentGroup }
                    handleCreateNewAtomaticGroups = {(is_shuffle)=>{handleTournamentListTeams(is_shuffle)}}
                    tournament_active_phase_type = { props.tournament_active_phase?.phase_type }
                    select_groups_teams={props.select_groups_teams}
                    // handleBestThirdByGroup={props.handleBestThirdByGroup}
                    selectedPhaseBestThird={props.selectedPhaseBestThird ?? props.tournament_active_phase?.best_third}
                    available_teams={false}
                    handleMoveToGroup={props.handleMoveToGroup}
                    handleTeamGroupPhaseknockout={props.handleTeamGroupPhaseknockout}
                    disable_phase_fields={props.disable_phase_fields}

                    handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                    isCreatePhase={props.isCreatePhase}
                    handleDeleteGroup={(group)=> handleDeleteGroupPhase(group)}
                    tournament_active_phase_name = { props.tournament_active_phase?.phase_name }
                    handleNumberClassified={props.handleNumberClassified}
                    showActions={props.tournament_active_phase?.state === 'Active' || props.selected_tournament.state !== 'Finalized' }
                />
            </div>
            </React.Fragment>

        );
  }

export default withTranslation('league')(TournamentGroupsComponent);