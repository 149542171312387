/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Styles
import './TournamentPaymentInfoModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Moment
import * as moment from 'moment';

// Currency format
import CurrencyFormat from 'react-currency-format';


function TournamentPaymentInfoModalComponent( props ) {

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="tournament-payment-info-modal"
            title={props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.titleModal')}
        >
            <div className='tournament-payment-info-modal__container' >
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.tournament')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' >{props.team_player_payment_info.tournament}</div>
                </div>
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.state')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' >{props.t(`options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.${props.team_player_payment_info?.transaction_state}`)}</div>
                </div>
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.idTransaction')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' >{props.team_player_payment_info.transaction_id}</div>
                </div>
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.dateTransaction')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' >
                        {`${moment(props.team_player_payment_info.transaction_date).format("MM/DD/YYYY")} 
                         ${moment(props.team_player_payment_info.transaction_time, ["HH.mm"]).format("hh:mm a")}`}
                    </div>
                </div>
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.paymentMethod')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' >{props.t(`options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.${props.team_player_payment_info.payment_method}`)}</div>
                </div>
                <div className='tournament-payment-info-modal__row' >
                    <div className='tournament-payment-info-modal__title baloa-normal-medium' >{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.total')}</div>
                    <div className='tournament-payment-info-modal__value baloa-normal-medium' ><CurrencyFormat value={props.team_player_payment_info.amount_total} displayType={'text'} thousandSeparator={','} decimalSeparator={"."} prefix={'$ '} suffix={' ' + (props.team_player_payment_currency ?? '')}/></div>
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentPaymentInfoModalComponent);
