// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import { useFormik } from 'formik';
import Tooltip from "@mui/material/Tooltip";

// Styles
import './ProfileRoleModalComponent.styles.css';
import { makeStyles } from "@mui/styles";


// Components
import SelectFieldCleanComponent from 'shared/components/select-field/SelectFieldCleanComponent';
import InputFieldAutocompleteComponent from 'shared/components/input-field-autocomplete/InputFieldAutocompleteComponent';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileRoleModalComponent( props ) {

    const [selectedProfileType, setSelectedProfileType] = React.useState(null);
    const [formErrors, setFormErrors ] = React.useState(null);
    const formRef = React.createRef();

    const formikvalues = useFormik({
        initialValues: {
            profile_type: props.profile.profile_type ? props.profile.profile_type : null,
            profession_type: props.profile.profile_profession_type ? props.profile.profile_profession_type : null
        }
    });

    const role_options = [
        {
            icon: 'icon-fan',
            content: <div className='baloa-table'>{props.t('data.modal.text4')}</div>,
            text: props.t('data.modal.text3'),
            value: 'fanatic'
        },
        {
            icon: 'icon-player',
            content: <div className='baloa-table'>{props.t('data.modal.text2')}</div>,
            text: props.t('data.modal.text5'),
            value: 'player'
        },
        {   
            icon: 'icon-coach',
            content: <div className='baloa-table'>{props.t('data.modal.text6')}</div>,
            text: props.t('data.modal.text7'),
            value: 'professional'
        }
    ]

    const useStyles = makeStyles((theme) => ({
        customMessage: {
            backgroundColor: 'var(--white-color)',
            borderRadius: 'var(--space-half)',
            boxShadow: '0px 2px 6px rgba(150, 150, 150, 0.25)',
            padding: 'var(--space)',
        },
    }));
    const classes = useStyles();

    const handleSelectedProfession = (value) => {
        if (value?.length > 0 && value != selectedProfileType) {
            setSelectedProfileType(value);
        }
    }

    const validateForm = () => {
        if (formRef.current.values.profile_type === "professional" && !formRef.current.values.profession_type){       
            formRef.current.setFieldError('profession_type', props.t('page:newPage.validators.requiredField'));
            formRef.current.setSubmitting(false);
            setFormErrors({profession_type: props.t('page:newPage.validators.requiredField')})
        }else{
            formRef.current.validateForm();
        }
        
        if(formRef.current.isValid){

            let user = {};
            user.profile_type = formRef.current.values.profile_type;
            if(formRef.current.values.profile_type === "professional" && formRef.current.values.profession_type ){
                user.profile_profession_type = formRef.current.values.profession_type.value;
            }
            
            //props.updateUserDataAction(user);
            props.handleSubmitProfileType(user); 
            props.onClose();
        }

    }

    const validateProfessionType = (e) => {
        if(e){
            setFormErrors(null);
        }
    }

    return (        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('exp.label2')}
            className='profile-role-modal__container'
        >            
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                /* onSubmit={( values, actions ) => {   
                    props.handleSubmitProfileType(values); 
                    props.onClose();
                }} */
                onSubmit={(values, actions) => {
                    validateForm();
                }}
                innerRef={formRef}
                className="profile-role-modal form"
                autocomplete="off"
            >
                <div className="profile-role-modal__content">
                    <div className='profile-role-modal__text baloa-table-column'>{props.t('data.modal.roleText')}</div>
                    <div className='profile-role-modal__role-selector__title'>
                        <div className='baloa-username'>{props.t('exp.label2')}</div>
                        <Tooltip 
                            title={
                                <React.Fragment>
                                    <div className='profile-role-modal__role-selector__title__tooltip baloa-table-column'>{props.t('data.modal.tooltipText')}</div>
                                    <div className='profile-role-modal__role-selector__title__tooltip baloa-table-column'>{props.t('data.modal.tooltipExampleText')}</div>
                                </React.Fragment>
                            }
                            arrow
                            placement="top"
                            classes={{ tooltip: classes.customMessage }}
                        >
                            <span className='hubicon-help_fill'/>
                        </Tooltip>
                    </div>
                    <SelectFieldCleanComponent
                        field_name="profile_type"
                        className="profile-role-modal__role-selector__select-input"
                        disabled={false}
                        resetError={()=>{}}
                        error={{profile_type: ''}}
                        message=""
                        placeholder=""
                        options={role_options}
                        defaultValue={props.profile.profile_type}                        
                        onChange={(profession) => handleSelectedProfession(profession)}
                    />
                    {selectedProfileType === 'professional'
                        ? <div className='profile-role-modal__profile-profession-type'>
                            <InputFieldAutocompleteComponent
                                name={'profession_type'}
                                field_name="profession_type"
                                options={props.profession_specialties}
                                noOptionsText={props.t('info.labelNoFound')}
                                optionLabelField={"content"}
                                optionSelectedField={"value"}
                                className="profile-profession-selector__select-input"
                                label={props.t('data.modal.profession')}
                                placeholder={props.t('signUp:info.labelPlaceholder')}
                                error={props.errors}
                                resetError={() => { }}
                                disabled={selectedProfileType !== 'professional'}
                                onChange={(e)=>{validateProfessionType(e)}}
                                defaultValue={props.profession_specialties?.find(spc => spc.value === props.profile.profile_profession_type)}
                            />
                            <p className="input-field__message error">{ formErrors?.profession_type }</p>
                        </div>
                        : ''
                    }                    
                </div>
                <div className="profile-role-modal__actions form__actions">
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="profile-role-modal__actions__submit"
                    >
                        <span>{props.t('settings.btnSave') }</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
        </Modal2Component>
        )
    }
    
    export default withTranslation('profile')(ProfileRoleModalComponent);