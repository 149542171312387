import React from "react";

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//i18
import { withTranslation } from 'react-i18next';

import './VerifyFormPaymentTeamPlayerModalComponent.styles.css'

function VerifyFormPaymentTeamPlayerModalComponent(props) {
    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className='verify-form-payment-team-player-modal__container'
        >
            <span className="hubicon-info" />
            <div className="verify-form-payment-team-player-modal__message baloa-names">{props.message}</div>
            { props.form_btn && 
                <PrimaryButtonComponent 
                    className="verify-form-payment-team-player-modal__form-btn"
                    onClick={()=>{props.handleModalForm('form')}}
                >
                    {props.t('verifyFormPaymentTeamPlayer.formBtn')}                
                </PrimaryButtonComponent>}
            {props.payment_btn && 
                <PrimaryButtonComponent 
                    className="verify-form-payment-team-player-modal__payment_btn"
                    onClick={()=>{props.handleModalForm('payment')}}
                >
                    {props.t('verifyFormPaymentTeamPlayer.paymentBtn')}
                </PrimaryButtonComponent>}

         
        </Modal2Component>
       
    )
}

export default withTranslation("tournament")(VerifyFormPaymentTeamPlayerModalComponent )