// React
import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
//import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './HubTournamentCardComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function HubTournamentCardComponent (props) {
    const history = useHistory();
    const clickLink = () => {
        console.log('va a link');
        history.push(`/tournament/hub/${props.tournament.tournament_id? props.tournament.tournament_id : props.tournament.id}`);
    }
    return (
        /* <div className='hub-tournament-card' onClick={ isMobile ? ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } : ()=>{} } > */
        <div className={`hub-tournament-card ${(!props.is_subsection && (props.subsection === 'tournament_participate' || props.subsection === 'tournament_admin'))? 'long-view' : (props.subsection === 'tournament_follow' && !props.is_subsection)? 'follow-view' : ''} ${(!(props?.subsection === 'tournament_pending' && props.tournament.ignore === true) && props?.subsection !=='tournament_invitation')? 'hub-cursor':''}`} 
            onClick={ isMobile ? ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } : 
                ((props?.subsection !=='tournament_invitation' && !isMobile) && !(props?.subsection === 'tournament_pending' && props.tournament.ignore === true) )? ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } : 
                (props.tournament_admin_view && props.is_tournamentList)? ()=>{ props.handleOnViewTournament(props.tournament) }: (!props.tournament_admin_view && props.is_tournamentList)? ()=>{ clickLink() } : ()=>{} } >
            <ScrollTrigger 
                onEnter={ props.onEnterViewport } 
                onExit={ props.onExitViewport }
                className={`hub-tournament-card__container ${(!props.is_subsection && (props.subsection === 'tournament_participate' || props.subsection === 'tournament_admin'))? 'long-view' : (props.subsection === 'tournament_follow')? 'follow-view' : ''}`}
            >
                <div className={`hub-tournament-card__img-container ${(!props.is_subsection && (props.subsection === 'tournament_participate' || props.subsection === 'tournament_admin'))? 'long-view' : (props.subsection === 'tournament_follow')? 'follow-view' : ''}`} >
                    { props.tournament?.tournament_photo
                        ?
                        <img className={`hub-tournament-card__tournament-photo ${(!props.is_subsection && (props.subsection === 'tournament_participate' || props.subsection === 'tournament_admin'))? 'long-view' : ''}`} src={props.tournament?.tournament_photo} alt={props.tournament?.name}/>
                        :
                        <span className={"icon-trophy"} color={getRandonIconBallColor()} ></span>
                    }
                    <div className={`hub-tournament-card__labels-container `}>
                        { props.subsection === 'tournament_pending' &&
                            <div className={'hub-tournament-card__labels-container__tournament-pending'}>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>
                                <div className='baloa-table-column to_finalize'>{props.t('tournamentHubSections.tournamentPending')}</div> 
                            </div>
                        }
                        { props.subsection === 'tournament_invitation' &&
                            <div className='hub-tournament-card__labels-container__tournament-invitation'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>
                            </div>
                        }
                        { props.subsection === 'tournament_participate' &&
                            <div className='hub-tournament-card__labels-container__tournament-participate'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>
                                <div className='baloa-table-column enrolled'>{props.t('viewTournament.inscription.inscriptionStates.Enrolled')}</div>
                            </div>
                        }
                        {/*  props.subsection === 'tournament_follow' &&
                            <div className='hub-tournament-card__labels-container__tournament-follow'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>       
                            </div> */
                        }
                        { props.subsection === 'tournament_admin' &&
                            <div className='hub-tournament-card__labels-container__tournament-admin'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>
                            </div>
                        }
                        { props.subsection === 'registration_open' && !props?.is_filterSection &&
                            <div className='hub-tournament-card__labels-container__tournament-registration-open'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t('tournamentHubSections.registration_open')}</div>                               
                            </div>
                        }
                        { props.subsection === 'in_progress' && !props?.is_filterSection &&                        
                            <div className='hub-tournament-card__labels-container__tournament-in-progress'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t('tournamentHubSections.in_progress')}</div>
                            </div>
                        }
                        { (props?.tournament?.tournament_state ==='Registration_Open' || props.tournament?.tournament_state == "Scheduled" || props.tournament?.tournament_state == "Finalized") && props?.is_filterSection &&
                            <div className='hub-tournament-card__labels-container__tournament-registration-open'>
                                <div className={'baloa-table-column ' +(props.tournament?.tournament_state === "Finalized" ? 'finalized' :(props.tournament?.tournament_state === "Scheduled" || props.tournament?.tournament_state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.tournament_state}`)}</div>
                            </div>
                        }
                        { (props?.tournament?.state ==='Registration_Open' || props.tournament?.state == "Scheduled" || props.tournament?.state == "Finalized") && props.is_tournamentList &&
                            <div className='hub-tournament-card__labels-container__tournament-registration-open'>
                                <div className={'baloa-table-column ' +(props.tournament?.state === "Finalized" ? 'finalized' :(props.tournament?.state === "Scheduled" || props.tournament?.state === "Started"? 'scheduled':'registration-open '))}>
                                    {props.t(`tournamentStates.${props.tournament?.state}`)}</div>
                            </div>
                        }
                    </div>
                    { (props.subsection === 'tournament_pending' || props.subsection === 'tournament_invitation') &&
                        <div className='hub-tournament-card__labels__team'>
                            <div className='hub-tournament-card__card-team-label__img'>
                                { props.tournament?.team_logo 
                                    ?
                                    <img src={props.tournament?.team_logo} alt={props.tournament?.team}/>
                                    :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                            </div>
                            <div className='baloa-table-column' >{props.tournament?.team}</div>
                        </div>
                    }
                </div>
                <div className='hub-tournament-card__tournament-data__container' >
                    <div className={`hub-tournament-card__tournament-data  ${(!props.is_subsection && (props.subsection === 'tournament_participate' || props.subsection === 'tournament_admin'))? 'long-view' : (props.subsection === 'tournament_follow')? 'follow-view' : ''}`}>
                        <div className='hub-tournament-card__tournament-info'>
                            <div className='hub-tournament-card__tournament-info__img'>
                                { props.tournament?.tournament_logo 
                                    ?
                                    <img src={props.tournament?.tournament_logo} alt={props.tournament?.tournament_logo}/>
                                    :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                            </div>
                            <div className={`hub-tournament-card__tournament-info__name baloa-names ${isMobile ? 'baloa-truncate' : ''}`}>{props.tournament.name}</div>
                        </div>                        
                        <div className='hub-tournament-card__tournament-tags'>
                            {/* <div className='hub-tournament-card__tournament-tags__tag baloa-username'>{props.tournament.football_type? props.t('viewTournament.footballType.'+props.tournament.football_type): props.tournament.tournament_football_type? props.t('viewTournament.footballType.'+props.tournament.tournament_football_type):''}</div> */}
                            <div className='hub-tournament-card__tournament-tags__tag baloa-username'>{props.tournament.tournament_type? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+ props.tournament.tournament_type):''}</div>
                            <div className='hub-tournament-card__tournament-tags__tag baloa-username'>{props.tournament.tournament_gender? props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+props.tournament.tournament_gender):''}</div>
                            <div className='hub-tournament-card__tournament-tags__tag baloa-username'>{props.tournament.category? props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.'+ props.tournament.category):''}</div>
                            
                        </div>
                        {/* <div className='hub-tournament-card__tournament-dates baloa-username'><span className={"icon-calendar"} ></span> {props.tournament.start_date} - {props.tournament.end_date}</div> */}
                        {props.subsection !== 'tournament_follow' &&
                            <div className='hub-tournament-card__tournament-location baloa-username'><span className={"icon-location"} ></span> {props.tournament.main_city} - {props.tournament.main_country}</div>
                        }
                        <div className='hub-tournament-card__tournament-page'>                            
                            {props.tournament?.tournament_page_logo 
                                ?
                                <img src={props.tournament?.tournament_page_logo} alt={props.tournament?.tournament_page_logo}/>
                                :
                                <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                            }                            
                            <div className='hub-tournament-card__tournament-page baloa-username'>{props.tournament.tournament_page_name}</div>
                        </div>
                        
                    </div>
                    {(props.is_tournamentList || props?.subsection ==='tournament_invitation' || props?.subsection === 'tournament_pending') &&                       
                        <div className='hub-tournament-card__buttons'>
                            {/*(props?.subsection ==='tournament_follow' || props?.subsection ==='tournament_admin' || props?.subsection ==='registration_open' || props?.subsection ==='in_progress' || props?.is_filterSection) && !isMobile &&
                                <React.Fragment>
                                    <PrimaryButtonComponent
                                        className='hub-tournament-card__buttons__btn-more small'
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } }
                                    >                            
                                        <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                    </PrimaryButtonComponent>
                                    <SecondaryButtonComponent
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnShareTournamentLinkClick(props.tournament) } }
                                    >
                                        <span className='icon-share'></span>
                                    </SecondaryButtonComponent>
                                </React.Fragment>
                                */}
                            {props?.subsection ==='tournament_invitation' && !isMobile &&
                                <React.Fragment>
                                    <PrimaryButtonComponent
                                        className='hub-tournament-card__buttons__btn-50 small'
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } }
                                    >                            
                                        <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                    </PrimaryButtonComponent>                                 
                                    <PrimaryButtonComponent
                                        className='hub-tournament-card__buttons__btn-50 small grey-btn'
                                        onClick={ () => props.handleOnRejectBtn(props.tournament) }
                                    >                            
                                        <span>{props.t('tournamentWidget.buttons.rejectTournament')}</span>     
                                    </PrimaryButtonComponent>                                
                                </React.Fragment>
                            }
                            {props?.subsection ==='tournament_invitation' && isMobile &&
                                                                
                                <PrimaryButtonComponent
                                    className='hub-tournament-card__buttons__btn-50 small grey-btn'
                                    onClick={ () => props.handleOnRejectBtn(props.tournament) }
                                >                            
                                    <span>{props.t('tournamentWidget.buttons.rejectTournament')}</span>     
                                </PrimaryButtonComponent>                                
                                
                            }
                            {/* props?.subsection ==='tournament_participate' && !isMobile &&
                                <React.Fragment>
                                    <PrimaryButtonComponent
                                        className='hub-tournament-card__buttons__btn-more small'
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } }
                                    >                            
                                        <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                    </PrimaryButtonComponent>                        
                                </React.Fragment>
                            */}
                            {props?.subsection === 'tournament_pending' && !isMobile && props.tournament.ignore === true &&
                                <React.Fragment>
                                    <PrimaryButtonComponent
                                        className={ props.tournament.ignore === true ?'hub-tournament-card__buttons__btn-50 small' :'hub-tournament-card__buttons__btn-more small'}
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnViewTournament(props.tournament, props.subsection) } }
                                    >                            
                                        <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                    </PrimaryButtonComponent>   
                                { //props.tournament.ignore === true &&
                                    <PrimaryButtonComponent
                                        className='hub-tournament-card__buttons__btn-50 small grey-btn'
                                        onClick={ () =>props.handleIgnoreBtn(props.tournament) }
                                        >                        
                                    <span>{props.t('tournamentWidget.buttons.ignoreTournament')}</span>     
                                    </PrimaryButtonComponent>
                                }
                                </React.Fragment>  
                            }   
                            {props?.subsection === 'tournament_pending' && isMobile &&
                                <React.Fragment>  
                                    { props.tournament.ignore === true &&
                                        <PrimaryButtonComponent
                                            className='hub-tournament-card__buttons__btn-50 small grey-btn'
                                            onClick={ () =>props.handleIgnoreBtn(props.tournament) }
                                        >
                                        <span>{props.t('tournamentWidget.buttons.ignoreTournament')}</span>     
                                        </PrimaryButtonComponent>
                                    }
                                </React.Fragment>                              
                            }
                            {/*props.is_tournamentList && !isMobile &&
                                <React.Fragment>
                                    {!props.tournament_admin_view && 
                                        <Link to={`/tournament/hub/${props.tournament.tournament_id? props.tournament.tournament_id : props.tournament.id}`} className={'hub-tournament-card__buttons__btn-more'}>
                                            <PrimaryButtonComponent
                                                className='hub-tournament-card__buttons__btn-more small'
                                                input_type={'button'}
                                                onClick={ ()=>{ } }
                                            >                            
                                                <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                            </PrimaryButtonComponent>
                                        </Link> 
                                    }
                                    {props.tournament_admin_view && 
                                        <PrimaryButtonComponent
                                            className='hub-tournament-card__buttons__btn-more small'
                                            input_type={'button'}
                                            onClick={ ()=>{ props.handleOnViewTournament(props.tournament) } }
                                        >                            
                                            <span>{props.t('tournamentWidget.buttons.viewTtournament')}</span>     
                                        </PrimaryButtonComponent>
                                    }
                                    <SecondaryButtonComponent
                                        input_type={'button'}
                                        onClick={ ()=>{ props.handleOnShareTournamentLinkClick(props.tournament) } }
                                    >
                                        <span className='icon-share'></span>
                                    </SecondaryButtonComponent>
                                </React.Fragment>
                                */
                            }                                          
                        </div>
                    } 
                </div>
            </ScrollTrigger>
        </div>
    )
}

export default withTranslation('tournament')(HubTournamentCardComponent)