
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import PositionFieldComponent from 'shared/components/positions-field/PositionsFieldComponent';

// Styles
import './ProfileTechnicsPrimaryPositionModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import ProfileStepsDatasheetComponent from '../profile-steps-datasheet/ProfileStepsDatasheetComponent';



function ProfileTechnicsPrimaryPositionModalComponent( props ) {
    
    const formInitialValues = {
        primary_position: props.model.attributes?.primary_position?.value,
    }

    // disabled(data) {
    //     if (data.isValid && !this.state.disabled) {
    //         this.setState({disabled:true})
    //     }
    //     if(!data.isValid && this.state.disabled){
    //         this.setState({disabled:false}) 
    //     }
    // }
    
    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('playerCard.titlePrimaryPosition')}
            className="technics-form"
        >
            <div className="technics-form scroll-parent">
                <div className='technics-form__subtitle baloa-subtitle-2'>{props.t('playerCard.subTitlePrimaryPosition')}</div>
                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        let new_attribute_data = { ...props.model.attributes?.primary_position, value: values['primary_position'] }
                        props.onNewData( new_attribute_data );
                        props.onClose();
                    }}
                    className="technics-form__form form scroll-parent"
                    functionDisabled={()=>{}}
                >
                    <div className="technics-form__form__fields form__fields scroll">
                        {/* <ProfileStepsDatasheetComponent
                            step={props.step}
                            stepCompleted = {(i)=>props.stepCompleted(i)}
                        /> */}
                        <div className="technics-form__form__fields__primary-position">
                            <div className="technics-form__form__fields__primary-position__positions-graph">
                                <PositionFieldComponent 
                                    display = "form"
                                    position = "primary"
                                    primary_position = { props.model?.attributes?.primary_position?.value }
                                    secondary_positions = { null }
                                    all_positions = {  ["por", "li", "dci", "dcd", "ld", "ci", "mcd", "cad", "mi", "mc", "md", "ei", "mco", "ed", "dc"] }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="technics-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="technics-form__form__submit"
                        >
                            <span>{props.t('playerCard.button')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileTechnicsPrimaryPositionModalComponent);