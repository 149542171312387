// i18next 
import i18n from 'i18next';

export const RequiredValidator = (value) => {
    let value_trim = 0
    if(value && typeof value === 'string') {
        value_trim = value.trim().length;
    } else {
        value_trim = 1;
    }
     
    let error;
    if ( (value === "") || (value === null) || (value === undefined)  || (value_trim <= 0) ) {
        error =  i18n.t('page:newPage.validators.requiredField'); 
    }
    return error;
}

export const DateRequiredValidator = (value) => {
    let error;
    if (!value) {
        error = i18n.t('page:newPage.validators.addDate'); 
    } 
    return error;
}

export const OnlyNumbersValidator = (value) => {
    let error;
    
    if(value && !/^([0-9])*$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidChars');
    }
    return error;
}

export const OnlyNumbersRequiredValidator = (value) => {
    let error;
    if((value === "") || (value === null) || (value === undefined)){
        error = i18n.t('page:newPage.validators.requiredField'); 
    } 
    if(!/^([0-9])*$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidChars');
    }
    return error;
}

export const PhoneNumberValidator = (value) => {
    let error;
    
    if(value && !/^\+?([0-9])*$/.test( value )){
        error = i18n.t('page:newPage.validators.validationPhone');
    }
    return error;
}

export const PhoneNumberRequiredValidator = (value) => {
    let error;
    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    }
    if(!/^\+?([0-9])*$/.test( value )){
        error = i18n.t('page:newPage.validators.validationPhone');
    }
    return error;
}

export const EmailValidator = (value) => {
    let error;
    /*eslint-disable*/
    // Nueva expresion regular que valida email
    if(value && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test( value )){
    // if(value && !/\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidEmail');
    }
    /*eslint-enable*/

    return error;
}

export const EmailRequiredValidator = (value) => {
    let error;
    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    } 

    /*eslint-disable*/
    if(!/\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidEmail');
    }
    /*eslint-enable*/

    return error;
}

export const MoneyValidator = (value) => {
    let error;
    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    } 
    if(!/^([0-9]+\.?[0-9]{2}|\.[0-9]+)$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidValue');
    }
    return error;
}


export const MinNumberValidation = (value, minLimit, type ) => {

    let error;
    if ( (value === "") || (value === null) || (value === undefined) ) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    }else if(!/^([0-9])*$/.test( value )){
        error = i18n.t('page:newPage.validators.invalidChars');
    } 
    
    if(parseInt(value) < parseInt(minLimit)){
        error = i18n.t('page:newPage.validators.validationMinType',{ _type: type, _minPlayers: minLimit});
    }

    return error;
}

export const MaxNumberValidation = (value, value2, unlimited ) => {

    let error;
    if(!unlimited){
        if ( (value2 === "") || (value2 === null) || (value2 === undefined) ) {
            error = i18n.t('page:newPage.validators.requiredField'); 
        }else if(!/^([0-9])*$/.test( value2 )){
            error = i18n.t('page:newPage.validators.invalidChars');
        } 

        if(parseInt(value) > parseInt(value2)){
            error =  i18n.t('page:newPage.validators.validationMaxValue');
        }
    }

    return error;
}

export const CreditCardValidator = (value) => {
    let error;
    
    if(value && !/^([0-9]{15,16})$/.test( value )){
        error = i18n.t('page:newPage.validators.validationCard');
    }
    return error;
}

export const CreditCardRequiredValidator = (value) => {
    let error;

    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    }

    if(!/^([0-9]{15,16})$/.test( value )){
        error = i18n.t('page:newPage.validators.validationCard');
    }
    return error;
}

export const monthValidator = (value) => {
    let error;
    
    if(value && !/^(0[0-9]|10|11|12)$/.test( value )){
        error = i18n.t('page:newPage.validators.validationMonth');
    }
    return error;
}

export const monthRequiredValidator = (value) => {
    let error;

    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    }

    if(!/^(0[0-9]|10|11|12)$/.test( value )){
        error = i18n.t('page:newPage.validators.validationMonth');
    }
    return error;
}

export const yearValidator = (value) => {
    let error;
    
    if(value && !/^(([1][9][0-9][0-9])|([2][0-9][0-9][0-9]))$/.test( value )){
        error = i18n.t('page:newPage.validators.validationYear');
    }
    return error;
}

export const yearRequiredValidator = (value) => {
    let error;

    if (!value) {
        error = i18n.t('page:newPage.validators.requiredField'); 
    }

    if(!/^(([1][9][0-9][0-9])|([2][0-9][0-9][0-9]))$/.test( value )){
        error = i18n.t('page:newPage.validators.validationYear');
    }
    return error;
}