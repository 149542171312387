// React
import React, { useState, useEffect} from 'react';

//Material
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Styles
import './CarnetInformationComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Assets
import form from 'assets/images/forms.png';

function CarnetInformationComponent(props){
    const [checkboxItem, setCheckboxItem] = useState([]);
    useEffect (()=>{
        props.handleSelectedItems(checkboxItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkboxItem]);

    function handleOnClick (e, data){
        if(e.target.checked){ 
            setCheckboxItem(current => [...current, data]);       
        }else{
            setCheckboxItem((current) =>
                current.filter((item) => item !== data)
            );  
                          
        }  
        
    }

    return (
        <div className="carnet-information">
            <div className="carnet-information__title">
            <img src={form} alt="form" width="30px"/>
                <div className="carnet-information__title__text baloa-table-column">
                    {props.t("createCarnet.modal.form")}
                </div>
            </div>
            <div className="carnet-information__item scroll-parent">
                <div className="carnet-information__item__text baloa-normal-text">
                    {props.t("createCarnet.modal.messageForm")}
                </div>
                <div className="carnet-information__item__list scroll">
                    {props.playerForm[0]?.questions.map(text => (
                        <FormControlLabel
                            name={'itemsCarnet'}
                            key={text.id}
                            value={text}
                            control={<Checkbox color="secondary" checked={ checkboxItem.some(item => item === text) } />}
                            label={<span className="carnet-information__item__text baloa-normal-text">
                                    {text.caption}
                                </span>}
                            labelPlacement="end"
                            className={'carnet-information__item__text baloa-normal-text'}
                            onChange={(e) => handleOnClick(e, text)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default withTranslation(["league", "team"])(CarnetInformationComponent);