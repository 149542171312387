// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.teams_groups_list li {
    list-style-type: none;

}

.teams_groups_list ul {
    padding-left: 0;
}

.teams_groups_list_title {
    margin-bottom: var(--space-and-half);
    text-transform: uppercase;
}

.teams-list{
    margin-bottom: var(--space-x2);
}

.teams-list li{
    padding: var(--space) 0;
    border-bottom: solid 1px var(--mid-gray-color);
    width: 60%;
}

.teams-list__photo{
    width: var(--space-and-half);
    height: var(--space-and-half);
    float: left;
    margin-top: var(--space);
    margin-bottom: var(--space);
    margin-right: var(--space-half);
    margin-left: var(--space-half);
}

.teams-list li span {
    width: var(--space);
    height: var(--space);
    margin-right: var(--space-half);
    color: #00C256;

}

.finish_groups_phase_footer {
    display: flex;
    height: calc( var(--space-x3) * 2 );
    justify-content: flex-end;
    background-color: var(--white-color);
    align-items: center;
}

.finish_groups_phase_btn {
    height: calc( var(--space-x2) + var(--space-half) );
    width: calc( var(--space-x3) * 4 );
    grid-template-columns: none; 
    grid-template-areas: none;
    margin-right: var(--space-and-half); 
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-finish-groups-phase-modal/TournamentFinishGroupsPhaseModalComponent.styles.css"],"names":[],"mappings":";;AAEA;IACI,qBAAqB;;AAEzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;IACvB,8CAA8C;IAC9C,UAAU;AACd;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,WAAW;IACX,wBAAwB;IACxB,2BAA2B;IAC3B,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,+BAA+B;IAC/B,cAAc;;AAElB;;AAEA;IACI,aAAa;IACb,mCAAmC;IACnC,yBAAyB;IACzB,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,mDAAmD;IACnD,kCAAkC;IAClC,2BAA2B;IAC3B,yBAAyB;IACzB,mCAAmC;AACvC","sourcesContent":["\n\n.teams_groups_list li {\n    list-style-type: none;\n\n}\n\n.teams_groups_list ul {\n    padding-left: 0;\n}\n\n.teams_groups_list_title {\n    margin-bottom: var(--space-and-half);\n    text-transform: uppercase;\n}\n\n.teams-list{\n    margin-bottom: var(--space-x2);\n}\n\n.teams-list li{\n    padding: var(--space) 0;\n    border-bottom: solid 1px var(--mid-gray-color);\n    width: 60%;\n}\n\n.teams-list__photo{\n    width: var(--space-and-half);\n    height: var(--space-and-half);\n    float: left;\n    margin-top: var(--space);\n    margin-bottom: var(--space);\n    margin-right: var(--space-half);\n    margin-left: var(--space-half);\n}\n\n.teams-list li span {\n    width: var(--space);\n    height: var(--space);\n    margin-right: var(--space-half);\n    color: #00C256;\n\n}\n\n.finish_groups_phase_footer {\n    display: flex;\n    height: calc( var(--space-x3) * 2 );\n    justify-content: flex-end;\n    background-color: var(--white-color);\n    align-items: center;\n}\n\n.finish_groups_phase_btn {\n    height: calc( var(--space-x2) + var(--space-half) );\n    width: calc( var(--space-x3) * 4 );\n    grid-template-columns: none; \n    grid-template-areas: none;\n    margin-right: var(--space-and-half); \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
