/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';

// Styles
import './ProfileExternalStatsModalComponent.styles.css';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { DateFormatValidator } from 'shared/validators/DateValidator';


// i18n
import { withTranslation } from 'react-i18next';
import SumRestInputComponent from 'shared/components/sum-rest-input/SumRestInputComponent';
import { isMobile } from 'react-device-detect';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';



function ProfileExternalStatsModalComponent( props ) {

    const football_type = [
        { value: 'Football_11', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_11') },
        { value: 'Football_8', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_8') },
        { value: 'Football_7', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_7') },
        { value: 'Football_5', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_5') },
        { value: 'Football_6', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_6') },
        { value: 'Football_9', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_9') },
        { value: 'Football_10', content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Football_10') },
    ];

    const tournament_gender = [
        { content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Female'), value: "Female" },
        { content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Male'), value: "Male" },
        { content: props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.Free'), value: "Free" }
    ];

    const tournament_tag = [
        { value: 'Free', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
        { value: 'sub-23', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
        { value: 'sub-22', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
        { value: 'sub-21', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
        { value: 'sub-20', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
        { value: 'sub-19', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
        { value: 'sub-18', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
        { value: 'sub-17', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
        { value: 'sub-16', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
        { value: 'sub-15', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
        { value: 'sub-14', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
        { value: 'sub-13', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
        { value: 'sub-12', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
        { value: 'sub-11', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
        { value: 'sub-10', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
        { value: 'sub-9', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
        { value: 'sub-8', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
        { value: 'sub-7', content: props.t("league:options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
    ];
    
    const formInitialValues = {
        tournament_name: props.selected_stat? props.selected_stat.tournament_name : "",
        //tournament_logo: props.selected_stat? props.selected_stat.tournament_logo : "",
        tournament_gender: props.selected_stat? props.selected_stat.tournament_gender : "",
        tournament_category: props.selected_stat? props.selected_stat.tournament_category : "",
        football_type: props.selected_stat? props.selected_stat.football_type : "",
        start_date: props.selected_stat? props.selected_stat.start_date : "",
        end_date: props.selected_stat? props.selected_stat.end_date : "",
        goals: props.selected_stat? props.selected_stat.goals : 0,
        goal_assists: props.selected_stat? props.selected_stat.goal_assists : 0,
        go_undefeated: props.selected_stat? props.selected_stat.go_undefeated : 0,
        matches_played: props.selected_stat? props.selected_stat.matches_played : 0,
        matches_as_starter: props.selected_stat? props.selected_stat.matches_as_starter : 0,
        yellow_cards: props.selected_stat? props.selected_stat.yellow_cards : 0,
        red_card: props.selected_stat? props.selected_stat.red_card : 0,
        external_url: props.selected_stat? props.selected_stat.external_url : ""
    }

    const [modalUploadImage, setModalUploadImage] = React.useState(false);
    const [tournamentLogo, setTournamentLogo] = React.useState();
    const formAchievementRef = React.createRef();

    const handleUploadTournamentLogo = (image, imageurl, img_b64) => {
        setTournamentLogo({image: image, url:imageurl, name:image.name, imageB64: img_b64});
    }

    const closeModal = () =>{
        setTournamentLogo();
        props.onClose();
    }

    return (
       
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={() => { closeModal() }}
            title={ props.edit_stat? props.t('externalStats.editStatModalTitle') : props.t('externalStats.addStatModalTitle')}
            // button_type="submit"
            className='profile-external-stats-modal'
        >


            <div className="profile-external-stats-modal__container scroll-parent">
                <FormComponent 
                    formInitialValues={ formInitialValues } 
                    onSubmit={( values, actions ) => {
                        if(!props.selected_stat?.tournament_logo){
                            values.tournament_logo = tournamentLogo? tournamentLogo.imageB64 : "";
                        }
                        if(props.edit_stat){
                            props.onEditData(props.selected_stat.id,values);
                        }else{
                            //Add new stat
                            props.onNewData(values);
                        }                                                
                        closeModal();
                    }}
                    className="scroll"
                    functionDisabled={()=>{}}
                    innerRef={formAchievementRef}
                >
                    <div className="profile-external-stats-modal__form">
                        <div className={"profile-external-stats-modal__tournament-logo "}>
                            {tournamentLogo?.url &&
                                <img src={ tournamentLogo?.url } alt=""/>
                            }
                            {props.selected_stat?.tournament_logo &&
                                <img src={ props.selected_stat.tournament_logo } alt=""/>
                            }
                            {(!tournamentLogo?.url && !props.selected_stat?.tournament_logo) &&
                                <span className={"icon-trophy "}></span>
                            } 

                            <div className="profile-external-stats-modal__new-button" onClick={ () => setModalUploadImage(true) }>
                                <span className={"hubicon-add_option "}></span>
                            </div>                            
                        </div>
                        <div className='profile-external-stats-modal__image-msg baloa-table-column' onClick={ () => setModalUploadImage(true) } >{props.t('externalStats.uploadTournamentLogoLbl')}</div>
                        <InputFieldComponent 
                            label={props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentName')} 
                            input_id="tournament_name" 
                            field_name="tournament_name"
                            validationFunction={ RequiredValidator }
                            disabled={false}
                            className="profile-external-stats-modal__tournament-name"
                            input_type="text"
                            resetError={ () => props.onResetError( { 'tournament_name': "" } ) }
                            error={ props.errors }
                            message=""
                            placeholder={''}
                            defaultValue={formInitialValues.tournament_name}
                        />
                        <SelectFieldComponent
                            label={props.t("league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelFootballType")}
                            field_name="football_type"
                            input_id="football_type"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            className="profile-external-stats-modal__select"
                            resetError={() => {}}
                            error={{ 'football_type': "" }}
                            message=""
                            placeholder={props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderFootballType')}
                            options={football_type}
                            onChange={() => {}}
                            //defaultValue={ props.member?.team_role? props.roles?.filter(e => e.content == props.member?.team_role)[0].value : null }
                        />
                        <SelectFieldComponent
                            label={props.t("league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelCategory")}
                            field_name="tournament_category"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            className="profile-external-stats-modal__select"
                            resetError={() => {}}
                            error={{ 'tournament_category': "" }}
                            message=""
                            placeholder={props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderCategory')}
                            options={tournament_tag}
                            onChange={() => {}}
                            //defaultValue={ props.member?.team_role? props.roles?.filter(e => e.content == props.member?.team_role)[0].value : null }
                        />
                        <SelectFieldComponent
                            label={props.t("league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelGender")}
                            field_name="tournament_gender"
                            validationFunction={RequiredValidator}
                            disabled={false}
                            className="profile-external-stats-modal__select"
                            resetError={() => {}}
                            error={ { 'tournament_gender': "" } }
                            message=""
                            placeholder={props.t('league:options.tournaments.pages.tournamentInfo.tabs.tabBasicData.placeholderGender')}
                            options={tournament_gender}
                            onChange={() => {}}
                            //defaultValue={ props.member?.team_role? props.roles?.filter(e => e.content == props.member?.team_role)[0].value : null }
                        />
                        <div className="profile-external-stats-modal__form-dates">
                            <InputFieldComponent 
                                label={props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.start_date')} 
                                input_id="start_date" 
                                field_name="start_date"
                                validationFunction={ DateFormatValidator }
                                disabled={false}
                                className="profile-external-stats-modal__form-dates__date"
                                input_type="date"
                                resetError={ () => {} }
                                error={ { 'start_date': "" } }
                                message=""
                                placeholder={props.t('dd/mm/aaaa')}
                                defaultValue={formInitialValues.start_date}
                            />
                            <InputFieldComponent 
                                label={props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.end_start')} 
                                input_id="end_date" 
                                field_name="end_date"
                                validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={`profile-external-stats-modal__form-dates__date `}
                                input_type="date"
                                resetError={ () => {}}
                                error={  { 'end_date': "" } }
                                message=""
                                placeholder={props.t('dd/mm/aaaa')}
                                defaultValue={formInitialValues.end_date}
                            />
                        </div>
                        <div className={`profile-external-stats-modal__form-dates ${isMobile? 'mobile':''}`}>
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.labelGoals')} 
                                input_id="goals" 
                                field_name="goals"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.goals}
                            />
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.labelAssists')} 
                                input_id="goal_assists" 
                                field_name="goal_assists"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={""}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.goal_assists}
                            />
                        </div>
                        <div className={`profile-external-stats-modal__form-dates ${isMobile? 'mobile':''}`}>
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.labelUndefeatedFences')} 
                                input_id="go_undefeated" 
                                field_name="go_undefeated"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.go_undefeated}
                            />
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.labelMatchesSummary')} 
                                input_id="matches_played" 
                                field_name="matches_played"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={""}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.matches_played}
                            />
                        </div>
                        <div className={`profile-external-stats-modal__form-dates ${isMobile? 'mobile':''}`}>
                            <SumRestInputComponent 
                                label={props.t('home:players.filter.starterTitle')} 
                                input_id="matches_as_starter" 
                                field_name="matches_as_starter"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={``}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.matches_as_starter}
                            />
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.yellowCardTooltip')} 
                                input_id="yellow_cards" 
                                field_name="yellow_cards"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={""}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.yellow_cards}
                            />
                        </div>
                        <div className="profile-external-stats-modal__center">
                            <SumRestInputComponent 
                                label={props.t('tournamentSummary.redCardTooltip')} 
                                input_id="red_card" 
                                field_name="red_card"
                                //validationFunction={ DateFormatValidator }
                                disabled={false}
                                className={""}
                                input_type="text"
                                //resetError={ () => props.onResetError( { 'end_date': "" } ) }
                                //error={ props.errors }
                                message=""
                                placeholder={""}
                                defaultValue={formInitialValues.red_card}
                            />
                        </div>
                        <InputFieldComponent 
                            label={props.t('externalStats.tournamentUrl')} 
                            input_id="external_url" 
                            field_name="external_url"
                            validationFunction={ ()=>{} }
                            disabled={false}
                            className="profile-external-stats-modal__url"
                            input_type="text"
                            resetError={ () => {} }
                            error={ { 'external_url': "" } }
                            message=""
                            placeholder={props.t('externalStats.tournamentUrlPlaceholder')}
                            defaultValue={formInitialValues.external_url}
                        />
                        <div className='profile-external-stats-modal__info-msg baloa-mini'><span className='hubicon-info'></span>{props.t('externalStats.statValidationInfo')}</div>
                        {(props.edit_stat && isMobile) &&
                            <TextButtonComponent
                                type="button"
                                className="small"
                                onClick={()=>{ props.handleDelete(props.selected_stat.id) }} 
                            >
                                <span>{props.t('externalStats.deleteStatBtnLbl')}</span>
                            </TextButtonComponent>
                        }
                    </div>                        
                </FormComponent>
                
            </div>
            <div className="profile-external-stats-modal__actions">
                {(props.edit_stat && !isMobile) &&
                    <TextButtonComponent
                        type="button"
                        className="small"
                        onClick={()=>{ props.handleDelete(props.selected_stat.id) }} 
                    >
                        <span>{props.t('externalStats.deleteStatBtnLbl')}</span>
                    </TextButtonComponent>
                }
                <PrimaryButtonComponent 
                    type="submit"
                    //disabled={ !state.achievements_form_is_valid } 
                    onClick={()=>{formAchievementRef.current.handleSubmit()}} 
                    className="small"
                >
                    {props.edit_stat?
                        <span>{props.t('settings.btnSave')}</span>
                    :
                        <span>{props.t('externalStats.addStatBtnLbl')}</span>
                    }
                </PrimaryButtonComponent>
            </div>
            

            <LeagueTeamUploadImageModalComponent 
                isOpen={ modalUploadImage }
                onClose={() => {setModalUploadImage(false)}}
                setTeamImage={ (new_img, imageurl, img_b64, binary) => { handleUploadTournamentLogo(new_img, imageurl, img_b64, binary) }}
                aspect={1}
                cropImage={true}
            />


        </Modal2Component>
    )
}

export default withTranslation(['profile','league'])(ProfileExternalStatsModalComponent);