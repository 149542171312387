// React
import React,{ useState } from 'react';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent'

// Styles
import './ButtonMenuComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function ButtonMunuComponent ( props ) {

    const [menuOpen, setMenuOpen] = useState(false);

    function OnDeleteOptionsUserClick() {
        if(menuOpen){
            setMenuOpen( false );
        }else{
            setMenuOpen( true );
        }
    }

    return(

        <div 
        className="post-detail__comments__container__comment__info__button"
        onClick={ () => {
            OnDeleteOptionsUserClick();
            
        }}
        >
            <span className="icon-options"></span>
            
            {/* User options popover */}                                        
            { menuOpen &&
                <ul className="button-menu">
                    <li className="button-menu__item">
                        <SecondaryButtonComponent
                        onClick={ () => {                                                
                            props.deleteComment(props.post_id,props.comment);                                                 
                        } }
                        className="post-detail__comments__actions__popover__action small" 
                        >
                            <span>{props.t('actions.delete')}</span>
                        </SecondaryButtonComponent>
                    </li>
                </ul>
            } 
        </div>  
    )

}

export default withTranslation('common')(ButtonMunuComponent)