// Payments state

const payments_state = {
    payments_list:[],
    country_payments:[],
    countries: [],
    country_states: [],
    gateway_attribute: [],
    modals: {
        active_modal_is_open: false,
    },
    form_payments:{},
    is_onboarding_form_invalid: false,
    page_account_status: undefined,
    page_account_response: undefined,
    onboarding_form_payment_loading: false
}

export default function PaymentsReducer( state = payments_state, action ) {
    switch ( action.type ) {

        case "PAYMENTS:LOAD_TEAM_LIST": {
            return { ...state, team_list: action.data }
        }

        case "PAYMENTS:CHANGE_ACTIVE_MODAL_STATE": {
            let active_modals_state = { ...state['modals'], active_modal_is_open: action.data }
            return { ...state, modals: active_modals_state }

        }

        case "PAYMENTS:GET_COUNTRIES": {
            return { ...state, countries: action.data.data?.results }
        }

        case "PAYMENTS:GET_COUNTRY_STATES": {
            return { ...state, country_states: action.data?.data }
        }

        case "PAYMENTS:GET_GATEWAY_ATTRIBUTE": {
            return { ...state, gateway_attribute: action.data?.data}
        }
    
        case "LOAD_PAYMENTS_COUNTRY":{
            return{...state, country_payments: action.data.data.results}
        }

        case "lOAD_PAYMENTS_FORM":{
            return{...state, form_payments: action.data.data[0]}
        }

        case "PAYMENTS:CLEAN_COUNTRY_FORM": {
            return{...state, countries: action.data}
        }

        case "PAYMENTS:CHANGE_ONBOARDING_FORM_VALIDATION_STATE": {
            return { ...state, is_onboarding_form_invalid: action.new_state }
        }

        case "PAYMENTS:CHECK_PAGE_ACCOUNT_STATUS":{
            return{...state, page_account_status: action.data.data.account_state, page_account_response: action.data.data.account_response }

        }

        case "PAYMENTS:CHANGE_ONBOARDING_FORM_PAYMENT_LOADING_STATE": {
            return { ...state, onboarding_form_payment_loading: action.new_state }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of document
        // no default
    }
    return state;
}