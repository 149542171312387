/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import * as XLSX from 'xlsx';
import {produce} from "immer" //Libreria para evitar errores de mutacion de objetos

// Actions
import { addNewTeam, setTeamExtra, searchMembersWithValidation, deletePlayerFromTeam,    
    getTeamPlayers, 
    loadPageTeams,
    loadTeamData,
    editTeamData,
    uploadBulkFile,
    loadMemberRolesData,
    loadMemberPositionsData,
    addMemberWithRolToTeam,
    updateTeamMember,
    uploadBulkData,
} from 'app/leagues/actions/LeagueTeamsActions';

import { changeNewTeamError, 
    cleanSearchedMembersAction, 
    cleanPlayersAction, 
    changeConfirmationUploadBulkModalStateAction,
    cleanBulkMembersAction,
    changeTeamMembersRolModalStateAction,
    setBulkMembersAction,
    changeShowBulkStateAction,
    changeLoadingBulkFileStateAction,
    changeAddingBulkMemberStateAction,
    changeBulkDeleteMemberModalStateAction,
    changeEditMemberModalState,
    changeDeleteMemberModalState,
} from 'app/leagues/actions/LeagueTeamsActionsCreators';

import { changeLeaguesAddMemberToLeagueModalStateAction, changeTeamMembersModalStateAction } from 'app/leagues/actions/LeagueActionsCreators';
import { followPage, unfollowPage, loadPage} from 'app/pages/actions/PageActions';
import { followProfile } from 'app/profiles/actions/ProfileActions';
import { cleanPagesStateAction } from 'app/pages/actions/PageActionsCreators';
import { changeMobileMenuStateAction, changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { changeShareInscriptionLinkModalStateAction } from 'app/tournaments/actions/TournamentActionsCreators';


// Components
import LeagueNewTeamComponent from 'app/leagues/components/league-new-team-component/LeagueNewTeamComponent';
import LeagueTeamComponent from 'app/leagues/components/league-team/LeagueTeamComponent';
import LeagueShowTeamComponent from 'app/leagues/components/league-show-team/LeagueShowTeamComponent';
import TeamMemberInfoModal from 'app/leagues/components/team-member-info-modal/TeamMemberInfoModal';
import LeagueAddMemberModalComponent from 'app/leagues/components/league-add-member-modal/LeagueAddMemberModalComponent';
import LeagueEditTeamComponent from 'app/leagues/components/league-edit-team/LeagueEditTeamComponent';
//import LeagueBadgeComponent from '../components/league-badge-component/LeagueBadgeComponent';
//import LeagueTeamMembersComponent from '../components/league-team-members/LeagueTeamMembersComponent';
//import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
import LeagueTeamBulkUploadMemberConfirmationModalComponent from 'app/leagues/components/league-team-bulk-upload-member-confirmation-modal/LeagueTeamBulkUploadMemberConfirmationModalComponent';
import LeagueTeamMemberRolModalComponent from 'app/leagues/components/league-team-member-rol-modal/LeagueTeamMemberRolModalComponent';
import LeagueBulkDeleteMemberModalComponent from '../components/league-bulk-delete-member-modal/LeagueBulkDeleteMemberModalComponent';
import LeagueBulkPlayerStatusInfoModalComponent from '../components/league-bulk-player-status-info-modal/LeagueBulkPlayerStatusInfoModalComponent';
import LeagueTeamEditMemberModalComponent from '../components/league-team-edit-member-modal/LeagueTeamEditMemberModalComponent';
import ShareInscriptionLinkModalComponent from 'app/tournaments/components/share-inscription-link-modal/ShareInscriptionLinkModalComponent';

//Material
import ErrorIcon from "@mui/icons-material/Error";

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

//i18
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// Styles
import './TeamView.styles.css';

class TeamView extends Component {

    constructor( props ) {
        super( props );
        this.pagename = this.props.match.params.pagename;
        this.showteam = this.props.match.params.showteam? this.props.match.params.showteam : false ; 
        this.setshowteam = this.props.match.params.setshowteam? this.props.match.params.setshowteam : undefined ;
        this.state = {
            new_team: false,
            edit_team: false,
            team_detail: false,
            new_team_categories: [
                { value: 'Free', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.Free") },
                { value: 'sub-23', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-23") },
                { value: 'sub-22', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-22") },
                { value: 'sub-21', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-21") },
                { value: 'sub-20', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-20") },
                { value: 'sub-19', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-19") },
                { value: 'sub-18', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-18") },
                { value: 'sub-17', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-17") },
                { value: 'sub-16', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-16") },
                { value: 'sub-15', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-15") },
                { value: 'sub-14', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-14") },
                { value: 'sub-13', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-13") },
                { value: 'sub-12', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-12") },
                { value: 'sub-11', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-11") },
                { value: 'sub-10', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-10") },
                { value: 'sub-9', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-9") },
                { value: 'sub-8', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-8") },
                { value: 'sub-7', content: props.t("options.tournaments.pages.newTournament.tabs.tabTeams.sub-7") }
            ],
            prepared_teams: [],
            uploadProgress: 0,
            logobase64: '',
            prepared_members: [],
            prepared_team: null,
            member: null,
            playerSelected: null,
            roles_members: [],
            roles_positions: [],
            new_team_genders: [
               {value: 'Male', content: props.t("team:newTeam.forms.basicInfo.genderMaleBtnLabel")},
               {value: 'Female', content: props.t("team:newTeam.forms.basicInfo.genderFemaleBtnLabel")},
               {value: 'Free', content: props.t("team:newTeam.forms.basicInfo.genderFreeBtnLabel")},
            ],
            dataset: undefined,
            fileLoaded: false,
            fileName: undefined,
            operationInProgress: false,
            deleted_member_index: undefined,
            add_bulk_members: false,
            show_bulk_modal: false,
            share_inscription_url: undefined,
            set_tab_teams: false,
        }
    }
    
    componentDidMount() {
        this.props.cleanPagesStateAction({});
        this.props.loadPageTeams(this.pagename);
        this.props.loadPage( this.pagename );
        
        
        if(this.props.state.teams.team.teamname){
            this.props.getTeamPlayers(this.props.state.teams.team.teamname);
        }
        if(this.props.state.teams.page_teams){
            this.prepareTeamsData(this.props.state.teams.page_teams);
        }
        this.props.changeTeamMembersModalState(false);
        if(this.showteam && this.setshowteam ){
            this.props.loadTeamData(this.setshowteam);
            this.props.getTeamPlayers(this.setshowteam);
            this.setState({team_detail:true});
        }
        this.props.loadMemberRolesData();
        this.props.loadMemberPositionsData();
        this.props.changeMobileMenuStateAction(false);
        if(this.props.location.search === '?new_team'){
            this.handleOnNewButton();
            this.props.history.replace({
                search: ''
            });
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.teams.page_teams != this.props.state.teams.page_teams){
            this.prepareTeamsData(this.props.state.teams.page_teams);
        }
        if(prevProps.state.teams.team.team_players != this.props.state.teams.team.team_players){
            this.prepareTeamMembers(this.props.state.teams.team.team_players);
        }
        if(prevProps.state.teams.member_roles != this.props.state.teams.member_roles){
            this.prepareMembersRoles(this.props.state.teams.member_roles);
        }
        if(prevProps.state.teams.member_positions != this.props.state.teams.member_positions){
            this.prepareMembersPositions(this.props.state.teams.member_positions);
        }
        if(prevProps.state.teams.bulk_members != this.props.state.teams.bulk_members && this.props.state.teams.bulk_members.members.length > 0 && this.props.state.teams.bulk_members != null ){
            if(!this.props.state.teams.bulk_members.error && !this.props.state.teams.loading_bulk_file && !this.props.state.teams.adding_bulk_member){
                this.props.changeShowBulkStateAction( false );
                this.props.changeConfirmationUploadBulkModalStateAction(true);                
            }
        }
        
    }

    handleOnTeamActionClick = (team) =>{
        this.props.loadTeamData(team.teamname);
        this.props.getTeamPlayers(team.teamname);
        this.setState({team_detail:true});
    }               
    
    handleOnNewTeam = async (team_data, teamimage, imgb64) => {
        const new_team_data = Object.keys(team_data).reduce((object, key) => {
            if (key !== 'category' && key !== 'photo_in') {
                object[key] = team_data[key]
            }
            object.team_tag = [ {extra:{atype: "team-tags", value: team_data.category }} ];

            //object.photo = teamimage;
            object.photo = imgb64; //se fija imagen en base 64
            return object
          }, {});
        await this.createTeam(new_team_data); 

        //let extra_data = {atype: "team-tags", value: team_data.category }       
        //this.props.setTeamExtra(extra_data, this.props.state.teams.team.teamname);
    }

    onUploadProgress = (progressEvent) => {
        this.setState({ uploadProgress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
    }

    _handleReaderLoaded = async ( readerEvent ) => {
        let binaString = readerEvent.target.result;
        this.setState( {logobase64: btoa(binaString)} )
    }

    setTobase64 = async (img) => {
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(img); 
    }


    createTeam = async (team_data) => {
        let formData = new FormData();
        //Si se utiliza imagen tipo bynary
        /*formData.append(
            "logo",
            team_data.logo,
            "team-image.jpg",
        );*/
        
        //Para imagen en base 64
        formData.append("photo", team_data.photo);
        
        formData.append("teamname", team_data.teamname);
        formData.append("name", team_data.name);
        formData.append("team_gender", team_data.team_gender);
        formData.append("team_tag", team_data["team_tag"]);

        //await this.props.addNewTeam(formData, this.onUploadProgress); 
        await this.props.addNewTeam(team_data, this.onUploadProgress, this.pagename); 
    }

    handleSearchMember = (searched_member) =>{
        this.props.searchMembersWithValidation( searched_member, this.props.state.teams.team.team_players);
    }

    handleModalAddPlayerRol = ( player ) => {
        this.props.changeTeamMembersRolModalStateAction(true);
        this.setState({playerSelected:player});
    }

    handleDeletePlayer = ( player ) => {
        this.props.deletePlayerFromTeam( this.props.state.teams.team.teamname, player.username );
    }

    handleCleanMemberSearch = () => {
        this.props.cleanSearchedMembersAction({});
    }
    
    handleOnNewButton= () => {
        this.props.cleanPlayersAction({});
        this.setState({new_team:true});
    }

    handleOnEditButton= () => {
        this.setState({team_detail:false});
        this.setState({edit_team:true});
    }

    handleOnSaveButton= () => {
        this.props.loadPageTeams(this.pagename);
        this.setState({new_team:false});
    }

    handleOnCloseButton= () => {
        this.props.loadPageTeams(this.pagename);
        this.setState({new_team:false});
        this.props.cleanBulkMembersAction({});
    }

    handleOnEditCloseButton= () => {
        this.props.loadPageTeams(this.pagename);
        this.props.loadTeamData(this.props.state.teams.team.teamname);
        this.props.getTeamPlayers(this.props.state.teams.team.teamname);
        this.setState({edit_team:false, add_bulk_members:false, team_detail:true, set_tab_teams: true});
        this.props.cleanBulkMembersAction({});
    }

    handleOnTeamDetailBackButton= () => {
        this.props.history.push(`/league/team/${this.pagename}`);
        this.setState({team_detail:false});
    }

    // Función cuando se van a usar la propiedad attributes del objeto equipo.
    // prepareTeamsData= (teams_data) => {
    //     console.log(teams_data)
    //     let new_teams_data = teams_data.map(function(dato){
    //         if(Array.isArray(dato.attributes) && dato.attributes.length > 0){
    //             dato.category = dato.attributes[0].extra.value;
    //         }            
    //         return dato;
    //     });
    //     this.setState({prepared_teams: new_teams_data});
    // }

    prepareTeamsData(teams_data){
        const new_teams_data = produce(teams_data, draft => {
            draft.map(function(team){
                if(team.team_category){
                    team.category = team.team_category;
                }
                return team;
            });            
        });
        this.setState({prepared_teams: new_teams_data});       
    }

    prepareTeamMembers = ( team_members ) =>{
        let roles_members_traslate = [
            { value: 'player', content: this.props.t("team:team.tabs.members.player") },
            { value: 'coach', content: this.props.t("team:team.tabs.members.coach") },
            { value: 'staff', content: this.props.t("team:team.tabs.members.staff") }
        ];
        let positions_members_traslate = [
            { value: 'goalkeeper', content: this.props.t("team:team.tabs.members.goalkeeper") },
            { value: 'defense', content: this.props.t("team:team.tabs.members.defense") },
            { value: 'midfield', content: this.props.t("team:team.tabs.members.midfield") },
            { value: 'forward', content: this.props.t("team:team.tabs.members.forward") }
        ];

        let register_player_state= [
            { value: 'registered', content: this.props.t("team:team.tabs.members.registered") },
            { value: 'no_registered' , content: this.props.t("team:team.tabs.members.no_registered")},
            { value: "pre_registered" , content: this.props.t("team:team.tabs.members.pre_registered")}
        ]

        /* let new_members_data = team_members.map( function(member){ 
            member.fullname = member.first_name + ' ' + member.last_name;
            member.player_number = member.role.number;
            member.position = member.role.team_profile_position? positions_members_traslate.filter(pos => pos.value == member.role.team_profile_position)[0].content : null;
            member.team_role = member.role.team_role? roles_members_traslate.filter(role => role.value == member.role.team_role)[0].content : null;
            member.player_state = member.state;
            
            member.table_state = member.state? '<div class="column-tag tooltip '+member.state+' baloa-username">'+register_player_state.filter(state => state.value == member.state)[0].content+'<span class="column-tag__tooltip-msg">'+this.getTooltipMessge(member.state)+'</span></div>' : null;

            return member;
        }); */
        let new_members_data = Object.assign([]);
        /* eslint-disable */
        team_members.map(member =>{
            member.fullname = member.first_name + ' ' + member.last_name;
            member.player_number = member.role?.number;
            member.position = member.role?.team_profile_position? positions_members_traslate?.filter(pos => pos.value == member.role.team_profile_position)[0].content : null;
            member.team_role = member.role?.team_role? roles_members_traslate?.filter(role => role.value == member.role.team_role)[0].content : null;
            member.player_state = member.state;
            
            member.table_state = member.state? '<div class="column-tag tooltip '+member.state+' baloa-username">'+register_player_state?.filter(state => state.value == member.state)[0].content+'<span class="column-tag__tooltip-msg">'+this.props.t("team:team.tabs.members.tooltipsMsgs." + member.state)+'</span></div>' : null;
            new_members_data.push(member);
        });
        /* eslint-enable */ 
        this.setState({prepared_members: new_members_data});
    }

    // Roles the members and traslate
    prepareMembersRoles = ( roles_members ) =>{

        let roles_members_traslate = [
            { value: 'player', content: this.props.t("team:team.tabs.members.player") },
            { value: 'coach', content: this.props.t("team:team.tabs.members.coach") },
            { value: 'staff', content: this.props.t("team:team.tabs.members.staff") }
        ]

        let new_roles_members_data = roles_members.map( function(rol){ 
            let rol_traslate = roles_members_traslate.find(rol_traslate => rol_traslate.value == rol[1]);
            rol.value = rol[1];
            rol.content = rol_traslate.content;
            return rol;
        });
        this.setState({roles_members: new_roles_members_data});
    }

    // Positions the members and traslate
    prepareMembersPositions = ( positions_members ) =>{

        let positions_members_traslate = [
            { value: 'goalkeeper', content: this.props.t("team:team.tabs.members.goalkeeper") },
            { value: 'defense', content: this.props.t("team:team.tabs.members.defense") },
            { value: 'midfield', content: this.props.t("team:team.tabs.members.midfield") },
            { value: 'forward', content: this.props.t("team:team.tabs.members.forward") }
        ];
        let new_positions_members_data = positions_members.map( function(position){ 
            let position_traslate = positions_members_traslate.find(position_traslate => position_traslate.value == position[1]);
            position.value = position[1];
            position.content = position_traslate.content;
            return position;
        });
        this.setState({positions_members: new_positions_members_data});
    }

    handleOnViewTournament ( tournament ) {  
        this.props.history.push(`/tournament/hub/${tournament.tournament_id}`);
    }

    handleOnMemberActionClick= ( member ) => {
        this.setState({member: member});
        this.props.changeTeamMembersModalState(true);
    }

    handleOnDeleteTeamMember = () => {
        
        this.props.deletePlayerFromTeam( this.props.state.teams.team.teamname, this.state.member?.username );
        this.props.changeTeamMembersModalState(false);
        
    }

    handleOnTeamMemberProfileClick = () => {  

        // if( this.props.state.leagues.active_team.pagename ) {
        //     this.props.history.push(`/page/${this.props.state.leagues.active_team.pagename}`);
        // }
        if( this.state.member.username ){
            this.props.history.push(`/profile/${this.state.member.username}`);
        }
        
    }

    handleOnEditTeam = async (team_data, teamimage, imgb64) => {
        const edit_team_data = Object.keys(team_data).reduce((object, key) => {
            // if (key !== 'category' && key !== 'photo_in') {
            //     object[key] = team_data[key]
            // }

            if (key !== 'photo_in') {
                object[key] = team_data[key]
            }
             
            //object.team_tag = "[ {extra:{atype: \"team-tags\", value: \"Free\" }} ]";
            if(teamimage){
                //object.photo = teamimage; //se fija en binary
                object.photo = imgb64; //se fija imagen en base 64
            }
            
            return object
          }, {});

        await this.editTeam(edit_team_data); 
    }

    editTeam = async (team_data) => {
        let formData = new FormData();
        if(team_data.photo){
            //Si se utiliza imagen tipo bynary
            // formData.append(
            //     "photo",
            //     team_data.photo,
            //     "team-image.jpg",
            // );
            
            //Para imagen en base 64
            formData.append("photo", team_data.photo);
        }

        formData.append("name", team_data.name);
        formData.append("team_gender", team_data.team_gender);
        //formData.append("team_tag", team_data["team_tag"]);
        formData.append("team_category", team_data.category);
        
        await this.props.editTeamData(formData, team_data.teamname, this.onUploadProgress); 
    }

    /*Function to translate team  category  */
    trastaleTeamCategory (team_list) {
        let team_final = [];
        let new_team_list= team_list.map((team) =>{  // eslint-disable-line
            
            let list_team ={...team, category_name: team.category != null ? this.props.t("options.tournaments.pages.newTournament.tabs.tabTeams." + team.category) : "", team_gender: team.team_gender != null ? this.props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${team.team_gender}`): "" }
            team_final.push(list_team)
        })
        return team_final;
    }

    uploadBulkMembersFile = async (membersFile) => {

        try{
            this.setState({ uploadProgress:0 });
            //let formData = new FormData();        
            //formData.append("file", membersFile);
            //await this.props.uploadBulkFile(this.props.state.teams.team.teamname, formData, this.onUploadProgress); 
            let newDataset = Object.assign([],this.props.state.teams.bulk_members.members);
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = new Uint8Array(e.target.result);
                let workbook = XLSX.read(data, { type: "array" });
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let sheet = XLSX.utils.sheet_to_json(worksheet);

                let dataset = await this.parseFileContent(sheet);

                newDataset = newDataset.concat(dataset);
                
                this.props.changeLoadingBulkFileStateAction(true); 
                this.setState({ 
                    dataset: newDataset,
                    fileLoaded: true,
                    fileName: membersFile.name,
                    operationInProgress: false,
                    uploadProgress: 100,
                });
                this.props.setBulkMembersAction({data:{members:newDataset, total: newDataset.length, error: null}});
            }.bind(this);
            reader.readAsArrayBuffer(membersFile);
            
        } catch (exception) {
            this.setState({
                fileLoaded: false,
                fileName: "",
                operationInProgress: false
            });
        }
        return true;
    }

    handleOnSaveBulkButton(){
        this.setState({ uploadProgress:0 });
        this.props.changeAddingBulkMemberStateAction(false);
        this.props.uploadBulkData( this.props.state.teams.team.teamname, this.prepareBeforeSubmitBulkData(this.state.dataset));
        //this.props.changeConfirmationUploadBulkModalStateAction(true);
    }

    handleOnCopyCodeButton= () => {
        navigator.clipboard.writeText(this.props.state.teams.team.team_code);
    }

    parseFileContent(sheet){
        const jDatos = [];

        for (let i = 0; i < sheet.length; i++) {
            const dato = sheet[i];
            const date = new Date((dato.BirthDate - (25567 + 1)) * 86400 * 1000);

            jDatos.push({
                ...dato,
                name:{ caption: dato.Name? dato.Name: null, error: false, error_caption: null},
                last_name:{ caption: dato.LastName? dato.LastName: null, error: false, error_caption: null},
                phone_country_code:{ caption: dato.PhoneCountryCode? dato.PhoneCountryCode: null, error: false, error_caption: null},
                mobile_number:{ caption: dato.MobileNumber? dato.MobileNumber: null, error: false, error_caption: null},
                email:{ caption: dato.Email? dato.Email: null, error: false, error_caption: null},
                shirtnumber:{ caption: dato.ShirtNumber? dato.ShirtNumber: null, error: false, error_caption: null},
                birthdate:{ caption: dato.BirthDate? moment(date).format('YYYY-MM-DD'): null, error: false, error_caption: null}
            });
        }
        return jDatos;
    }

    updateBulkData(row_data, row_index){

        let newDataset = Object.assign([],this.props.state.teams.bulk_members.members);
        
        let new_data = {};
        if(row_index >= 0){
            new_data = this.props.state.teams.bulk_members.members[row_index];
        }else{
            new_data = {
                name: { caption: null, error: false, error_caption: null },
                last_name: { caption: null, error: false, error_caption: null },
                email: { caption: null, error: false, error_caption: null },
                phone_country_code: { caption: null, error: false, error_caption: null },
                mobile_number: { caption: null, error: false, error_caption: null },
                shirtnumber: { caption: null, error: false, error_caption: null },
                birthdate: { caption: null, error: false, error_caption: null },
            }
            
        }
        this.props.changeAddingBulkMemberStateAction(true); //Define que se esta adicionando un miembro directamente a la carga masiva / o que se esta editando un registro

        new_data.name = row_data.name? { caption: row_data.name, error: false, error_caption: null} : { caption: row_data.name, error: new_data.name.error, error_caption: new_data.name.error_caption};
        new_data.last_name = row_data.last_name? { caption: row_data.last_name, error: false, error_caption: null} : { caption: row_data.last_name, error: new_data.last_name.error, error_caption: new_data.last_name.error_caption};
        new_data.email = row_data.email || (row_data.mobile_number && row_data.phone_country_code)? { caption: row_data.email, error: false, error_caption: null} : { caption: row_data.email, error: new_data.email.error, error_caption: new_data.email.error_caption};
        new_data.phone_country_code = (row_data.phone_country_code && row_data.mobile_number) || row_data.email? { caption: row_data.phone_country_code, error: false, error_caption: null} : { caption: row_data.phone_country_code, error: new_data.phone_country_code.error, error_caption: new_data.phone_country_code.error_caption} ;
        new_data.mobile_number = (row_data.mobile_number && row_data.phone_country_code) || row_data.email? { caption: row_data.mobile_number, error: false, error_caption: null} : { caption: row_data.mobile_number, error: new_data.mobile_number.error, error_caption: new_data.mobile_number.error_caption} ;
        new_data.shirtnumber = row_data.shirtnumber? { caption: row_data.shirtnumber, error: false, error_caption: null} : { caption: row_data.shirtnumber, error: new_data.shirtnumber.error, error_caption: new_data.shirtnumber.error_caption};
        new_data.birthdate = row_data.birthdate? { caption: moment(row_data.birthdate).format('YYYY-MM-DD'), error: false, error_caption: null} : { caption: row_data.birthdate, error: new_data.birthdate.error, error_caption: new_data.birthdate.error_caption};

        if(row_index >= 0){
            newDataset[row_index] = new_data;
        }else{
            newDataset.push(new_data);
        }

        this.setState({ dataset: newDataset });
        this.props.setBulkMembersAction({data:{members:newDataset, total: newDataset.length, error: null}});
    }

    handleOnAddBulk( new_state ){
        this.props.changeShowBulkStateAction( new_state );
    }

    prepareBeforeSubmitBulkData(bulkData){
        const pData = [];
        for (let i = 0; i < bulkData.length; i++) {
            const new_dato = bulkData[i];            
            pData.push({
                name: new_dato.name.caption? new_dato.name.caption: null,
                last_name: new_dato.last_name.caption? new_dato.last_name.caption: null,
                phone_country_code: new_dato.phone_country_code.caption? new_dato.phone_country_code.caption: null,
                mobile_number: new_dato.mobile_number.caption? new_dato.mobile_number.caption: null,
                email: new_dato.email.caption? new_dato.email.caption: null,
                shirtnumber: new_dato.shirtnumber.caption? new_dato.shirtnumber.caption: null,
                birthdate: new_dato.birthdate.caption? new_dato.birthdate.caption: null
            });
        }

        return pData;
    }

    handleOnDeleteMember(member_index){
        this.props.changeBulkDeleteMemberModalStateAction(true);
        this.setState({ deleted_member_index: member_index });
    }

    deleteBulkMember(){      
        let newMembers = Object.assign([],this.props.state.teams.bulk_members.members);
        newMembers.splice(this.state.deleted_member_index,1);
        this.setState({ dataset: newMembers, deleted_member_index: undefined });
        this.props.setBulkMembersAction({data:{members:newMembers, total: newMembers.length, error: null}});
        this.props.changeBulkDeleteMemberModalStateAction(false);
    }

    handleAddBulkMembers(){
        this.setState({team_detail:false});
        this.setState({edit_team:true});
        this.setState({add_bulk_members:true});
        this.props.changeShowBulkStateAction( true );
    }
    changeBulkPlayerStatusInfoModalState(state){
        this.setState({show_bulk_modal: state});
    }

    handleOnEditMemberActionClick ( member ) {
        this.setState({member: member});
        this.props.changeEditMemberModalState(true);
    }

    handleOnDeleteMemberActionClick ( member ) {
        this.setState({member: member});
        this.props.changeDeleteMemberModalState(true);
    }

    handleOnShareTeamPageInscription(pagename){
        this.props.changeShareInscriptionLinkModalStateAction(true);
        const url = `${web_url}/api/social/pages/${pagename}/teams/`;
        this.setState( {share_inscription_url: url } );
    }

    handleOnShareInscriptionTournamentLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_inscription_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleOnShareTournamentLinkClick = async (tournament) => {
        try {
            const url = `${web_url}/api/social/tournament/hub/${tournament.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    cleanTeamTab(){
        this.setState({set_tab_teams: false})
    }

    render(){
        
        return(

            <React.Fragment>
                {/* member info modal */}
                <TeamMemberInfoModal  
                   isOpen={ this.props.state.leagues.modals.is_open_member_team_modal }
                   onClose = {() => this.props.changeTeamMembersModalState(false) }                    
                //    infoTeam = {active_team}
                   infoMember = {this.state.member}
                //    leagueName = {this.props.leage_name}                    
                //    onDeleteTeam = { handleOnDeleteTeam }
                   onDelete = { () => this.handleOnDeleteTeamMember() }  
                   onViewProfile = { () => this.handleOnTeamMemberProfileClick() }                  
                //    onViewTournament = { handleOnViewTournamentClick }
                    onEditMember = { (member)=>{ this.handleOnEditMemberActionClick( member ) } }
               />

                {/* add member modal */}
                <LeagueAddMemberModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.leagues.modals?.add_member_to_league_is_open } 
                    onClose={() => { this.props.changeLeaguesAddMemberToLeagueModalStateAction( false ) }}
                    title={this.props.t('team:team.tabs.titles.titleAddMemberModal')}        
                    profiles = {this.props.state.teams?.searched_members}
                    handleSearchMember = {( member_username ) => this.props.searchMemberWithValidation( member_username, this.props.state.teams.team?.team_players )}
                    onNewData = { ( new_member ) => { 
                        this.props.changeLeaguesAddMemberToLeagueModalStateAction( false )
                        this.handleModalAddPlayerRol( new_member )                                                
                    } }
                />

                {/* confirm upload bulk modal */}
                <LeagueTeamBulkUploadMemberConfirmationModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.teams.modals?.confirm_upload_bulk_is_open } 
                    onClose={() => { this.props.changeConfirmationUploadBulkModalStateAction( false ); this.props.cleanBulkMembersAction({}); }}
                    modalTitle={ this.props.t('team:newTeam.forms.players.bulkMemberUpload.confirmationBulkModal.addedMembersTitle', {_totalAddedMembers: this.props.state.teams.bulk_members.total } ) }
                    modalContent = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.confirmationBulkModal.addedMembersText') }
                    acceptButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.confirmationBulkModal.addedMembersButtonLbl') }
                />
                
                {/* add Team Member Rol modal */}
                <LeagueTeamMemberRolModalComponent
                    isOpen={ this.props.state.teams.modals.is_open_member_team_rol_modal }
                    title={this.props.t('team:teams.teamsMemberRolesTitle')}
                    onClose = {() => this.props.changeTeamMembersRolModalStateAction(false) }
                    onSelectRolPlayer = { ( player )=> { this.props.addMemberWithRolToTeam( this.props.state.teams.team.teamname, player ) } }
                    playerSelected = { this.state.playerSelected }
                    roles = {this.state.roles_members} 
                    positions = {this.state.positions_members} 
                    errors={ this.props.state.teams.errors.add_member_roles }
                    onResetError={(data) => {this.props.changeNewTeamError(data)}}
                />

                {/** Delete bulk member confirmation modal */}
                <LeagueBulkDeleteMemberModalComponent
                    isOpen={ this.props.state.teams.modals.is_open_bulk_delete_member_modal }
                    onClose = {() => this.props.changeBulkDeleteMemberModalStateAction(false) }                   
                    modalTitle={ this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersTitle') }
                    modalContent = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersText') }
                    deleteButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersDeleteButtonLbl') }
                    cancelButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersCancelButtonLbl') }
                    deleteButtonAction={()=>{this.deleteBulkMember()}}
                />
                {/** Delete member confirmation modal */}
                <LeagueBulkDeleteMemberModalComponent
                    isOpen={ this.props.state.teams.modals.is_open_delete_member_modal }
                    onClose = {() => this.props.changeDeleteMemberModalState(false) }                   
                    modalTitle={ this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersTitle') }
                    modalContent = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersText') }
                    deleteButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersDeleteButtonLbl') }
                    cancelButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.deleteBulkModal.deletedMembersCancelButtonLbl') }
                    deleteButtonAction={()=>{this.handleOnDeleteTeamMember(); this.props.changeDeleteMemberModalState(false);}}
                />
                <LeagueBulkPlayerStatusInfoModalComponent
                    isOpen={ this.state.show_bulk_modal }
                    onClose = {() => this.changeBulkPlayerStatusInfoModalState(false) }  
                    modalTitle={ <ErrorIcon
                        style={{
                            fontSize: 38,
                            color: "var(--primary-dark-color)",
                            marginRight: "var(--space)",
                            alignSelf: "center",
                        }}
                    />}
                    acceptButtonText = { this.props.t('team:newTeam.forms.players.bulkMemberUpload.bulKPlayerStatusInfoModal.okBtnLbl') }
                />

                <LeagueTeamEditMemberModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.teams.modals.is_open_edit_member_modal } 
                    onClose={() => { this.props.changeEditMemberModalState(false); }}
                    title={this.props.t('team:team.edit.members.title')}
                    roles={this.props.state.teams.member_roles}
                    positions={this.props.state.teams.member_positions}
                    member={this.state.member}
                    onNewData = { ( member )=> { this.props.updateTeamMember( this.props.state.teams.team.teamname, member ); this.props.changeEditMemberModalState(false); } }
                />

                <ShareInscriptionLinkModalComponent
                    isOpen= {this.props.state.tournaments.modals.share_incription_link_modal_is_open}
                    onClose={() => {this.props.changeShareInscriptionLinkModalStateAction(false)}}
                    share_url={this.state.share_inscription_url}
                    handleOnCopyShareLink={()=>{this.handleOnShareInscriptionTournamentLinkClick()}}
                />

                {/* this.setshowteam = this.props.match.params.setshowteam? this.props.match.params.setshowteam : undefined ;
                this.showteam = this.props.match.params.showteam? this.props.match.params.showteam : false ; */}
               
                {/* { this.props.state.page.page.is_admin === false && !permissionsController(this.props.state.page.page.roles, ['TEAMAD']) &&
                    <div className="team__pague">
                        {!permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) &&
                            <div className="team__left-container">
                                <LeagueBadgeComponent
                                        badge_data = { this.props.state.page.page }
                                        onFollow = { ( page ) => { this.props.followPage( page ) } }
                                        onUnfollow = { ( page ) => { this.props.unfollowPage( page ) } }
                                />
                            </div>
                        }
                        <div className="team__right-container">
                            <ProfileSuggestionsComponent
                                profiles = { this.props.state.home.profile_suggestions.slice(0, this.number_of_showing_profiles) }
                                className = "home__right-container__profile-suggestions sm"
                                handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                                loading_profile_suggestions = { this.props.state.home.loading_profile_suggestions }
                            />
                        </div>
                        <div className="team__center-container">
                            <LeagueTeamMembersComponent
                                pague = { this.props.match.params.pagename }
                                team= { this.props.state.teams.team }
                                members = { this.state.prepared_members }
                                tournaments = { this.props.state.teams.team.tournaments }
                                handleOnBackButton = { ()=>{ this.handleOnTeamDetailBackButton()}}
                            />
                        </div>
                        
                    </div>
                } */}
                
                <div className="team">

                    <div className="team__left-container"></div>

                    <div className={"team__center-container " + (this.state.new_team? "new-team" : (this.state.edit_team? "edit-team": ''))}>

                        {/* show teams */}
                        { !this.state.new_team && !this.state.team_detail && !this.state.edit_team && (
                            <LeagueTeamComponent
                                handleOnNewButton = { ()=>{this.handleOnNewButton()}}
                                handleOnTeamActionClick = { ( team )=> { this.handleOnTeamActionClick( team ) } }
                                teams={ this.trastaleTeamCategory(this.state.prepared_teams) }
                                handleOnShareTeamPageInscription={()=>{ this.handleOnShareTeamPageInscription(this.pagename) } }  
                            />
                        )} 

                        {/* create team */}
                        { this.state.new_team && (                            
                            <LeagueNewTeamComponent
                                new_team_categories = {this.state.new_team_categories}
                                new_team_genders = {this.state.new_team_genders}
                                errors={ this.props.state.teams.errors.new_team }
                                onResetError={(data) => {this.props.changeNewTeamError(data)}}
                                handleOnNewTeam = {(data, img, imgb64) => {this.handleOnNewTeam(data ,img, imgb64)}}
                                team= { this.props.state.teams.team }
                                profiles={this.props.state.teams.searched_members}
                                handleSearchMember = { (v)=> { this.handleSearchMember(v) } }
                                onDeletePlayerButton = { ( player )=> { this.handleDeletePlayer( player ) } }
                                onSelectTeamPlayer = { ( player )=> { this.handleModalAddPlayerRol( player ) } }
                                // onSelectTeamPlayer = { ( player )=> { this.handleModalAddPlayerRol( player ) } }
                                handleCleanMemberSearch = { ( )=> { this.handleCleanMemberSearch() }}
                                handleOnSaveButton =  { ( )=> { this.handleOnSaveButton() }}
                                onCloseButton = {( )=> { this.handleOnCloseButton() }}
                                handleOnUploadFile = { ( formdata )=> { this.uploadBulkMembersFile( formdata ) } }
                                bulkMembers= { this.props.state.teams.bulk_members }
                                handleOnSaveBulkButton = { ()=> { this.handleOnSaveBulkButton() } }
                                cleanBulkMembers = {  ()=> { this.props.cleanBulkMembersAction({}) }}
                                uploadProgress = { this.state.uploadProgress }
                                cleanStatusBar = { ()=> { this.setState({ uploadProgress:0 }) } }
                                handleOnMemberActionClick = {( member)=>{ this.handleOnMemberActionClick( member ) }}
                                updateBulkData={ (row_data, row_index)=>{ this.updateBulkData(row_data, row_index) } }
                                handleOnAddBulk={(new_state)=>{ this.handleOnAddBulk(new_state) }}
                                handleOnBackBulk={(new_state)=>{ this.handleOnAddBulk(new_state) }}
                                showBulk={ this.props.state.teams.show_bulk }
                                handleOnDeleteMember={(member_index)=>{this.handleOnDeleteMember(member_index)}}
                                handleOnMoreInfo={()=> { this.changeBulkPlayerStatusInfoModalState(true) }}
                            />
                        )}
                        {/* view team */}
                        { this.state.team_detail && (this.props.state.page.page.is_admin === true || permissionsController(this.props.state.page.page.roles, ['TEAMAD']) ) &&
                                <LeagueShowTeamComponent
                                    team= { this.props.state.teams.team }
                                    members = { this.state.prepared_members }
                                    handleOnBackButton = { ()=>{ this.handleOnTeamDetailBackButton()}}
                                    tournaments = { this.props.state.teams.team.tournaments }
                                    //onHandleAddMember = { ( player )=> { this.handleAddMember( player ) } } //TODO
                                    handleOnMemberActionClick = {( member)=>{ this.handleOnMemberActionClick( member ) }} //TODO
                                    handleViewTournament = { ( tournament )=>{ this.handleOnViewTournament(tournament) } } //TODO
                                    handleEditTeam = { ( )=> { this.handleOnEditButton( ) }} //TODO
                                    handleOnAddButtonClick={()=>this.props.changeLeaguesAddMemberToLeagueModalStateAction( true )}
                                    handleCopyCode = { ( )=> { this.handleOnCopyCodeButton( ) }} 
                                    handleAddBulkMembers = { ( )=> { this.handleAddBulkMembers() } }    
                                    handleOnEditMemberActionClick = {( member)=>{ this.handleOnEditMemberActionClick( member ) }}
                                    handleOnDeleteMemberActionClick  = {( member)=>{ this.handleOnDeleteMemberActionClick( member ) }}
                                    league_page = {this.pagename}
                                    tournament_tag = {this.state.new_team_categories}
                                    handleOnShareTeamPageInscription={()=>{ this.handleOnShareTeamPageInscription(this.pagename) } }
                                    handleOnShareTournamentLinkClick={(tournament)=>{this.handleOnShareTournamentLinkClick(tournament)}}
                                    setTabTeams={this.state.set_tab_teams}
                                    cleanTeamTab={()=>{this.cleanTeamTab()}}
                                />
                        }
                        {/* view team */}
                        { this.state.edit_team && (                            
                            <LeagueEditTeamComponent
                                edit_team_categories = {this.state.new_team_categories}
                                edit_team_genders = {this.state.new_team_genders}
                                errors={ this.props.state.teams.errors.edit_team }
                                onResetError={(data) => {this.props.changeNewTeamError(data)}}
                                onEditData = {(data, img, imgb64) => {this.handleOnEditTeam(data ,img, imgb64)}}

                                team= { this.props.state.teams.team }
                                profiles={this.props.state.teams.searched_members}
                                handleSearchMember = { (v)=> { this.handleSearchMember(v) } }
                                onDeletePlayerButton = { ( player )=> { this.handleDeletePlayer( player ) } }
                                onSelectTeamPlayer = { ( player )=> { this.handleModalAddPlayerRol( player ) } }
                                handleCleanMemberSearch = { ( )=> { this.handleCleanMemberSearch() }}
                                handleOnSaveButton =  { ( )=> { this.handleOnEditCloseButton() }}
                                onCloseButton = {( )=> { this.handleOnEditCloseButton() }}
                                handleOnUploadFile = { ( formdata )=> { this.uploadBulkMembersFile( formdata ) } }
                                bulkMembers= { this.props.state.teams.bulk_members }
                                handleOnSaveBulkButton = { ()=> { this.handleOnSaveBulkButton() } }
                                cleanBulkMembers = {  ()=> { this.props.cleanBulkMembersAction({}) }}
                                uploadProgress = { this.state.uploadProgress }
                                cleanStatusBar = { ()=> { this.setState({ uploadProgress:0 }) } }
                                roles = {this.state.roles_members} 
                                positions = {this.state.positions_members} 
                                errors_member_rol={this.props.state.teams.errors.update_rol_member}
                                onSelectRolMember = { ( player )=> { this.props.updateTeamMember( this.props.state.teams.team.teamname, player ) } }
                                updateBulkData={ (row_data, row_index)=>{ this.updateBulkData(row_data, row_index) } }
                                handleOnAddBulk={(new_state)=>{ this.handleOnAddBulk(new_state) }}
                                handleOnBackBulk={(new_state)=>{ this.handleOnAddBulk(new_state) }}
                                showBulk={ this.props.state.teams.show_bulk }
                                handleOnDeleteMember={(member_index)=>{this.handleOnDeleteMember(member_index)}}
                                add_bulk_members = { this.state.add_bulk_members }
                                handleOnMoreInfo={()=> { this.changeBulkPlayerStatusInfoModalState(true) }}
                            />
                        )}
                        
                     </div>
                     
                </div>




            </React.Fragment>
  
        )
    }

}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {

        changeNewTeamError: ( data ) =>{
            dispatch( changeNewTeamError( data ) );
        },
        addNewTeam: async ( new_team_data, uploadProgress, pagename ) =>{
            await dispatch( addNewTeam( new_team_data, uploadProgress, pagename ) );
        },
        setTeamExtra: ( extra_team_data, teamname ) =>{
            dispatch( setTeamExtra( extra_team_data, teamname ) );
        },
        searchMembersWithValidation: ( member_username, actual_members ) =>{
            dispatch( searchMembersWithValidation( member_username, actual_members ) );
        },
        deletePlayerFromTeam: ( teamname, username ) => {
            dispatch( deletePlayerFromTeam( teamname, username ) );
        },
        getTeamPlayers: ( teamname ) => {
            dispatch( getTeamPlayers( teamname ) );
        },
        cleanSearchedMembersAction: ( data ) => {
            dispatch( cleanSearchedMembersAction( data ) );
        },
        loadPageTeams: ( page_name ) => {
            dispatch ( loadPageTeams( page_name ) );
        },
        loadTeamData: ( teamname ) => {
            dispatch( loadTeamData( teamname ) );
        },
        changeTeamMembersModalState: (isOpen) => {
            dispatch( changeTeamMembersModalStateAction(isOpen) )
        },
        changeLeaguesAddMemberToLeagueModalStateAction: ( new_modal_state ) => {            
            dispatch( changeLeaguesAddMemberToLeagueModalStateAction( new_modal_state ) )
        },

        searchMemberWithValidation: ( member_username, actual_members ) =>{
            dispatch( searchMembersWithValidation( member_username, actual_members ) );
        },
        editTeamData: async ( new_team_data, teamname, upProgres ) =>{
            await dispatch( editTeamData( new_team_data, teamname, upProgres ) );
        },
        cleanPlayersAction: ( data ) => {
            dispatch ( cleanPlayersAction( data ) );
        },
        followPage: ( page ) => {
            dispatch( followPage( page ) )
        },
        unfollowPage: ( page ) => {
            dispatch( unfollowPage( page ) )
        },
        followProfile: ( profile ) => {
            return dispatch( followProfile( profile ) )
        },
        loadPage: ( username ) => {
            dispatch( loadPage( username ) )
        },
        cleanPagesStateAction: ( data ) => {
            dispatch ( cleanPagesStateAction( data ) );
        },
        uploadBulkFile: async ( teamname, formdata, upProgres ) =>{
            await dispatch( uploadBulkFile( teamname, formdata, upProgres ) );
        },
        changeConfirmationUploadBulkModalStateAction: ( new_modal_state ) => {            
            dispatch( changeConfirmationUploadBulkModalStateAction( new_modal_state ) )
        },
        cleanBulkMembersAction: ( data ) => {            
            dispatch( cleanBulkMembersAction( data ) )
        },
        loadMemberRolesData: ( ) => {
            dispatch( loadMemberRolesData() );
        },
        loadMemberPositionsData: ( ) => {
            dispatch( loadMemberPositionsData() );
        },
        changeTeamMembersRolModalStateAction: (isOpen) => {
            dispatch( changeTeamMembersRolModalStateAction(isOpen) )
        },
        addMemberWithRolToTeam:( teamname, member ) => {
            dispatch( addMemberWithRolToTeam( teamname, member  ) );
        },
        updateTeamMember:( teamname, member ) => {
            dispatch( updateTeamMember( teamname, member  ) );
        },
        setBulkMembersAction: ( data ) => {            
            dispatch( setBulkMembersAction( data ) )
        },
        uploadBulkData: ( teamname, membersData ) => {            
            dispatch( uploadBulkData( teamname, membersData ) )
        },
        changeShowBulkStateAction: ( new_state ) => {            
            dispatch( changeShowBulkStateAction( new_state ) )
        },
        changeLoadingBulkFileStateAction: ( new_state ) => {            
            dispatch( changeLoadingBulkFileStateAction( new_state ) )
        },
        changeAddingBulkMemberStateAction: ( new_state ) => {            
            dispatch( changeAddingBulkMemberStateAction( new_state ) )
        },
        changeBulkDeleteMemberModalStateAction: ( new_state ) => {            
            dispatch( changeBulkDeleteMemberModalStateAction( new_state ) )
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        changeEditMemberModalState: ( new_state ) => {
            dispatch( changeEditMemberModalState( new_state ) );
        },
        changeDeleteMemberModalState: ( new_state ) => {
            dispatch( changeDeleteMemberModalState( new_state ) );
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changeShareInscriptionLinkModalStateAction: ( new_state ) => {
            return dispatch( changeShareInscriptionLinkModalStateAction( new_state ) )
        },
    }
}

export default compose(withTranslation(["league", "team"]), connect(mapStateToProps, mapDispatchToProps))(TeamView);
