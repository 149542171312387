// React
import React from 'react';

// Components
import LeagueAddedMemberRolComponent from '../league-added-member-rol/LeagueAddedMemberRolComponent';

// Styles
import './LeagueAddedPlayersListComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

function LeagueAddedPlayersListComponent( props ) {
 
    return (

            <div className="added-players-list__container">
                <div className="added-players-list__subtitle baloa-normal-text">{props.t('newTeam.forms.players.addedTeamsLabel', {_playersCount: props.players.length })}</div>
                {props.players.map( ( player, index ) => (
                    <div className="added-players-list__member" key={index}>
                       <div className="added-players-list__member__member">
                            <div className="added-players-list__member-photo">
                                {player.photo? 
                                    <img src={player.photo} alt={`${player.username} img`}></img>
                                    :
                                    <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                }
                            </div>
                            <div className="added-players-list__member-data">
                                <div className="added-players-list__member-name baloa-body-2">{player.first_name + " " + player.last_name}</div>
                                <div className="added-players-list__member-username baloa-caption">@{player.username}</div>
                            </div>
                        </div>
                        {/* Information data roles */}
                        { (props.positions === undefined && props.roles === undefined) ?
                        <div className="added-players-list__member-data__role">
                            <div className="added-players-list__member-data__rol">
                                <span className="added-players-list__member-data__rol__title">{props.t("team.tabs.members." + player.role?.team_role)}</span>
                            </div>
                            {  player.role?.team_role === 'player' &&
                            <div className="added-players-list__member-data__player">
                                <div className="added-players-list__member-data__player__number">
                                    <span className="added-players-list__member-data__player__number__number">{player.role?.number}</span>
                                </div>
                                <div className="added-players-list__member-data__player__position">
                                    <span className="added-players-list__member-data__player__position__title">{player.role?.team_profile_position?props.t("team.tabs.members." + player.role?.team_profile_position):''}</span>
                                </div>
                            </div>
                            } 
                        </div>
                        :
                         <LeagueAddedMemberRolComponent
                            member_rol = {player}
                            roles = {props.roles}
                            positions = { props.positions}
                            errors_member_rol = {props.errors_member_rol}
                            onSelectRolMember = {props.onSelectRolMember}
                        />

                        }

                        <div className="added-players-list__close-button"
                                onClick={()=>{props.onDeletePlayerButton(player)}}
                            >
                                <span className="icon-x"></span>
                        </div>
                    </div>
                ))
                }

            </div>

    )
}

export default withTranslation('team')(LeagueAddedPlayersListComponent);