import React from 'react';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import CardIdViewerStepperComponent from 'app/cards/components/card-id-viewer-stepper/CardIdViewerStepperComponent';

//Material
import CircularProgress from '@mui/material/CircularProgress';

//Styles
import './TournamentCardIdViewerModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//IsMobile
import { isMobile } from 'react-device-detect';

function TournamentCardIdViewerModalComponent(props) {
    const [downloadCardIdData, setDownloadCardIdData] = React.useState([]);

    React.useEffect( () => {
        setDownloadCardIdData(props.downloadCardIdData);
    }, [ props.downloadCardIdData ] );// eslint-disable-line

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={()=>{ setDownloadCardIdData([]); props.onClose()}}
            title={props.title}
            className={`tournament-card-id-viewer-modal ${isMobile? 'mobile':''}`}
        >
            <div className={`tournament-card-id-viewer-modal__container ${isMobile? 'mobile':''} ${props.orientation === 'horizontal'? 'horizontal' : ''}`}>
                {downloadCardIdData.length > 0?
                    <CardIdViewerStepperComponent 
                        downloadCardIdData={ props.downloadCardIdData }
                        card_id_form_data={ props.card_id_form_data }
                        orientation={ props.orientation }
                        template_image={ props.template_image }
                    />
                :
                    <div className='tournament-card-id-viewer-modal__loader'>
                        <CircularProgress 
                            size={80}
                            thickness={4.5}
                        />
                    </div>
                }
            </div>
            
        </Modal2Component>

    )
}

export default withTranslation('league')(TournamentCardIdViewerModalComponent);