// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Input style */
.input-search__input input{
    background: transparent;
    width: 100%;
    border-style: none;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: var(--font-medium);
    font-size: calc( var(--space) + 1px );
    line-height: calc( var(--space-and-half) - 2px );;
    display: flex;
    align-items: center;
    letter-spacing: 0.001em;
    color: var(--basic-gray-color);
}

.input-search__input {
    width: 100%;
    background: var(--new-gray-background-color);
    border-radius: calc( var(--space-and-half) - 2px );
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/input-search/InputSearchComponent.styles.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,kBAAkB;IAClB,+BAA+B;IAC/B,qCAAqC;IACrC,gDAAgD;IAChD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,4CAA4C;IAC5C,kDAAkD;AACtD","sourcesContent":["/*Input style */\n.input-search__input input{\n    background: transparent;\n    width: 100%;\n    border-style: none;\n    font-family: var(--primary-font);\n    font-style: normal;\n    font-weight: var(--font-medium);\n    font-size: calc( var(--space) + 1px );\n    line-height: calc( var(--space-and-half) - 2px );;\n    display: flex;\n    align-items: center;\n    letter-spacing: 0.001em;\n    color: var(--basic-gray-color);\n}\n\n.input-search__input {\n    width: 100%;\n    background: var(--new-gray-background-color);\n    border-radius: calc( var(--space-and-half) - 2px );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
