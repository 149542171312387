// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.technics-form {}
.technics-form__form {
}
.technics-form__form__fields {
    padding: var(--space) var(--space);
}
.technics-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.technics-form__form__input .input-field-multiline-label{
    position: unset;
    background: transparent;
}
.technics-form__form__input .input-field-multiline-input {
    border: none;
    border-radius: 12px;
    background: var(--new-input-background-color); 
    padding: var(--space-half);
}
.technics-form__form__submit {}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-description-modal/ProfileDescriptionModalComponent.styles.css"],"names":[],"mappings":";AACA,gBAAgB;AAChB;AACA;AACA;IACI,kCAAkC;AACtC;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,6CAA6C;IAC7C,0BAA0B;AAC9B;AACA,8BAA8B","sourcesContent":["\n.technics-form {}\n.technics-form__form {\n}\n.technics-form__form__fields {\n    padding: var(--space) var(--space);\n}\n.technics-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n.technics-form__form__input .input-field-multiline-label{\n    position: unset;\n    background: transparent;\n}\n.technics-form__form__input .input-field-multiline-input {\n    border: none;\n    border-radius: 12px;\n    background: var(--new-input-background-color); \n    padding: var(--space-half);\n}\n.technics-form__form__submit {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
