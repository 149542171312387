// Action creators
import { 
    searchTeamsLeagueAction, 
    searchTeamsLeagueWithValidationAction,
    searchMembersLeagueAction,
    searchMembersLeagueWithValidationAction,
    addMemberToLeagueAction,
    loadLeagueMembersAction,
    loadLeagueTeamsAction,
    addTeamToLeagueAction,    
    loadActiveTeamAction,
    loadActiveMemberAction,
    loadLeagueMemberDetailAction,
    changeLeagueTeamsLoadingAction,   
    changeLeagueMembersLoadingAction, 
} from './LeagueActionsCreators';

// Apis
import { 
    GetSearchTeamApi,
    AddTeamToLeagueApi,
    DeleteLeagueTeamApi,
    DeleteLeagueMemberApi, 
    GetSearchMembersApi,
    AddMemberToLeagueApi,
    GetLeagueMembersApi,
    GetLeagueTeamsApi,
    GetTeamApi,
    //GetMemberApi,
    DeleteMatchGameApi,
    GetLeagueMemberDetailApi,
    GetPageMemberApi,    
} from '../apis/LeagueApi';

import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// i18next 
import i18n from 'i18next';


export const searchTeamsLeague = ( searchedteam ) => {

    return ( dispatch ) => {
        
        GetSearchTeamApi( searchedteam )
            .then( response => {
                
                dispatch( searchTeamsLeagueAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const searchTeamsLeagueWithValidation = ( searchedteam, actual_teams ) => {

    return ( dispatch ) => {
        
        GetSearchTeamApi( searchedteam )
            .then( response => {
                
                let results = [];
                /* eslint-disable */
                for(var i = 0; i < response.data.results.length; i++){

                    if(actual_teams.find(item => item.id === response.data.results[i].id)){
                        continue;
                    }

                    results.push(response.data.results[i]);

                }
                /* eslint-enable */

                dispatch( searchTeamsLeagueWithValidationAction( results ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadActiveTeam = ( team_pagename ) => {

    return ( dispatch ) => {
        
        GetTeamApi( team_pagename )
            .then( response => {

                dispatch( loadActiveTeamAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadActiveMember = ( pagename, member_username ) => {

    return ( dispatch ) => {
        
        GetPageMemberApi( pagename, member_username )
        //GetMemberApi( member_username )
            .then( response => {
                dispatch( loadActiveMemberAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteMatchGame = ( match_id ) => {

    return ( dispatch ) => {

        DeleteMatchGameApi( match_id )
            .then( response => {
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.deletedMatch'),
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addTeamToLeague = ( league_name, team_name ) => {

    return ( dispatch ) => {
        
        AddTeamToLeagueApi( league_name, team_name )
            .then( response => {
                dispatch( addTeamToLeagueAction( response ) );
                dispatch( loadLeagueTeams( league_name ) );                
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.addedTeamMember', {team_member: response.data.name}),
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteLeagueTeam = ( league_page, team_page ) => {

    return ( dispatch ) => {

        DeleteLeagueTeamApi( league_page, team_page )
            .then( response => {
                dispatch( loadLeagueTeams( league_page ) );
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.deletedTeam'),
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const deleteLeagueMember = ( league_page, team_member ) => {

    return ( dispatch ) => {

        DeleteLeagueMemberApi( league_page, team_member )
            .then( response => {
                dispatch( loadLeagueMembers( league_page ) );
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.deletedMember'),
                    error_message_is_open: true
                } ) )
            })
            .catch( error => {
                
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}



export const searchMembersLeague = ( searched_profile ) => {

    return ( dispatch ) => {
        
        GetSearchMembersApi( searched_profile )
            .then( response => {
                dispatch( searchMembersLeagueAction( response ) );
            })
            .catch( err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const searchMembersLeagueWithValidation = ( searched_profile, actual_members ) => {

    return ( dispatch ) => {
        
        GetSearchMembersApi( searched_profile )
            .then( response => {

                let results = [];
                /* eslint-disable */
                for(var i = 0; i < response.data.results.length; i++){

                    if(actual_members.find(item => item.id === response.data.results[i].id)){
                        continue;
                    }

                    results.push(response.data.results[i]);

                }
                /* eslint-enable */

                dispatch( searchMembersLeagueWithValidationAction( results, actual_members ) );                
            })
            .catch( err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
        
    }
}

export const loadLeagueMembers = ( leaguename ) => {
    
    return ( dispatch ) => {
        
        dispatch( changeLeagueMembersLoadingAction( true ) );
        GetLeagueMembersApi( leaguename )
            .then( response => {
                dispatch( loadLeagueMembersAction( response.data ) );
                dispatch( changeLeagueMembersLoadingAction( false ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const addMemberToLeague = ( league_name, member_username ) => {

    return ( dispatch ) => {
        
        AddMemberToLeagueApi( league_name, member_username )
            .then( response => {
                dispatch( addMemberToLeagueAction( response ) );
                dispatch( loadLeagueMembers( league_name ) );                
                dispatch(changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.addedTeamMember', {team_member: response.data.username}), 
                    error_message_is_open: true
                    } )
                );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadLeagueTeams = ( leaguename ) => {
    
    return ( dispatch ) => {
        
        dispatch( changeLeagueTeamsLoadingAction( true ) );
        GetLeagueTeamsApi( leaguename )
            .then( response => {
                dispatch( loadLeagueTeamsAction( response.data ) );
                dispatch( changeLeagueTeamsLoadingAction( false ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadLeagueMemberDetail = ( username ) => {
    
    return ( dispatch ) => {
        
        GetLeagueMemberDetailApi( username )
            .then( response => {
                dispatch( loadLeagueMemberDetailAction( response.data ) );
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}


