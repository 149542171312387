// React
import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import { AppState } from "react-native";

import * as moment from 'moment';

// Routes
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';

// Components
import HeaderAuthenticatedComponent from 'shared/components/header-authenticated/HeaderAuthenticatedComponent';

// Actions
import { getUserMeAction } from 'app/authentication/actions/LoginActions';
import { loadProfile, } from 'app/profiles/actions/ProfileActions';
import { addEventAction, addScreenTimeStartAction, addScreenTimeEndAction } from 'app/clickstream/actions/ClickstreamActionsCreators';
import { sendEvents } from 'app/clickstream/actions/ClickstreamActions';

// Globals
import { clickstream_interval } from "config/GlobalData";

/**
 * General Authenticated Layout is responsible for: 
 *  - Loading the user-specific general data, like:
 *    - User Me
 *    - User Profile
 *    - User Managed Pages
 *    - User Group Memberships
 *  - Show Authenticated Header
 */
class GeneralAuthenticatedLayout extends React.Component {
  
  componentDidMount () {
    this.timer = setInterval(() => this.props.sendEvents(this.props.state.clickstream), clickstream_interval);
    //this.props.getUserMeAction( this.props.state.user?.me?.username ); TODO: verificar si es o no necesario, se elimina por conflicto en redireccion after login
    document.addEventListener('visibilitychange',this.handleVisibilityChange, false);
  }

  handleVisibilityChange = () => {
    if( document.hidden ){
        this.props.addScreenTimeEndAction(new Date());  //set Clickstream foreground ended datetime
        this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'background', ScreenTime: this.getScreenTime()}});  //set Clickstream background event            
    }else if ( !document.hidden ){ 
        this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'foreground'}});  //set Clickstream foreground event
        this.props.addScreenTimeStartAction(new Date()); //set Clickstream foreground initial datetime
    }
  }
  componentWillUnmount() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      clearInterval(this.timer);
  }

  getScreenTime(){
      let result=moment(this.props.state.clickstream.screen_time_end).diff(this.props.state.clickstream.screen_time_start,'seconds');
      return result
  }
  
  render() {
      
    return (
      <React.Fragment>
        <HeaderAuthenticatedComponent />
        <div className="view"> 
          <Switch>
            { this.props.routes.map( route => (
              <RouteWithSubRoutes {...route} key={route.key} />
            )) }
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

// Redux connection
const mapStateToProps = state => {
  return {
      state
  }
}
const mapDispatchToProps = ( dispatch ) => {
    return {
        getUserMeAction: ( username ) => {
            dispatch( getUserMeAction( username ) );
        },
        loadProfile: ( username ) => {
            dispatch( loadProfile( username ) )
        },
        addEventAction: ( data ) => {
            return dispatch( addEventAction( data ) )
        },
        sendEvents: ( data ) => {
            if (data.Events.length > 0 ){
                return dispatch( sendEvents( data ) )
            }          
        },
        addScreenTimeStartAction: ( data ) => {
          return dispatch( addScreenTimeStartAction( data ) )
        },
        addScreenTimeEndAction: ( data ) => {
          return dispatch( addScreenTimeEndAction( data ) )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAuthenticatedLayout);