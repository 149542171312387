// React
import React from 'react';

// Components
import TableComponent from 'shared/components/dynamic-table/TableComponent';

// Material UI
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Skeleton from '@mui/material/Skeleton';

// Styles
import './LeagueTeamTableComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueTeamTableComponent( props ) {
    return(
        <React.Fragment>
            <TableComponent                   
                rows = { props.teams }
                columns = { [
                    "photo",
                    "category_name",
                    "team_gender",                    
                    "players_count",
                ] }
                columns_headers = { [
                    props.t('teams.teamsNameLabel'),                                       
                    props.t('teams.teamsCategoryLabel'),
                    props.t('teams.teamsGenderLabel'),
                    props.t('teams.teamsMembersLabel'),
                ] }
                has_image_column = { false }
                //image_column = { 0 }
                rows_per_page = { 5 }
                pagination = { true }
                handleOnActionClick = { (v)=>{ props.handleOnTeamActionClick(v) }}
                action_column = { false }
                search_by = "name"
                searchbar_by = "name"
                order_by = "name"
                placeholder_search_bar = {props.t('teams.teamsSearchLabel')}
                has_searchbar = { true }
                has_add_button = { true }  
                // has_button_filter = { false }  
                add_button_caption = {props.t('newTeam.forms.players.multiButton.invitePlayer')}                                   
                // filter_buttons_captions = { [ ] }   
                handleOnAddButtonClick = { props.handleOnShareTeamPageInscription } //Invitar jugadores
                has_optional_button={ true } 
                optional_button_caption={ props.t('teams.teamsAddTeamButtonLabel') }
                handleOnOptionalButtonClick = { props.handleOnNewButton } //Agregar equipo   
                team = { true }
                msg_empty_table_title={props.t('teams.emptyTeamTableTitle')}
                msg_empty_table_text={props.t('teams.emptyTeamTableText')}
                itemClicLabel={ props.t('teams.teamsViewTeamLabel') }
                link_row={true}
                has_team_column={true}
                team_column={0}
                toggle_header_buttons={true}
                button_icon={'hubicon-person_add'}
                optional_button_icon={'hubicon-create_team'}
            >
            </TableComponent>
        </React.Fragment>
    )
}

export default withTranslation('team')(LeagueTeamTableComponent);

