// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TermsAndConditionsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TermsAndConditionsModalComponent( props ) {

    // function spacesText(text){
    //     var spaces = text.replace('\n', "{<br/>}");

    //     return spaces;
    // }

    const accepTerms = () => {
        props.checkTerms(true);
        props.onClose();        
    };

    // const returnTerms = () => {
    //     props.checkTerms(false);
    //     props.onClose();        
    // };
    
    return (

        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('termsConditions.title')} 
            className="medium"
        >

            <div className="terms form scroll-parent">

                <div className="terms__content scroll">  
                    { props.terms?.map( text => {
                        return (
                            <div key={ text.lenguaje_text } className="terms__content__text">
                                
                                {text.lenguaje_text === 'Spanish' ?
                                text.text
                                :''}

                            </div>
                        )
                    })}
                </div> 

                <div className="terms__actions form__actions">
                    {/* <SecondaryButtonComponent 
                        type="button"
                        disabled={false} 
                        onClick={ returnTerms }  
                        className="terms__actions__return"
                    >
                        <span>{props.t('termsConditions.buttonBack')}</span>
                    </SecondaryButtonComponent> */}
                    <PrimaryButtonComponent 
                        type="button"
                        disabled={false} 
                        onClick={ accepTerms } 
                        className="terms__actions__accept"
                    >
                        <span>{props.t('termsConditions.buttonOk')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
            
        </Modal2Component>
    )
}

export default withTranslation('signUp')(TermsAndConditionsModalComponent);

//export default TermsAndConditionsModalComponent