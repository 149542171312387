// React
import React from 'react';
import ReactDOM from 'react-dom';

// Styles
import './ModalComponent.styles.css';

// Assets
import close_icon from 'assets/icons/close-icon.svg';


function ModalComponent(props) {
    if (!props.isOpen) {
        return null;
    }
    return ReactDOM.createPortal(
        <div className={"modal " + ( props.className ? props.className : '' )}>
            <div className="modal__container">
                {props.onClose &&
                    <div className="modal__container__close" onClick={props.onClose}>
                        <img src={close_icon} alt="close"/>
                    </div>
                }                
                {props.children}
            </div>
        </div>,
        document.getElementById('modal')
    );
}

export default ModalComponent;