//React
import React from 'react';

//Styles
import './LeagueTournamentReportDatesComponent.styles.css'

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Moment
import * as moment from 'moment';
import i18n from 'i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >

            {props.phase_dates?.map((d, index) => {
                return (
                    <React.Fragment>
                        {value === index && (
                            <div className='date-info__container'>
                                {props.groups_matches_by_date.map((group, index) => {
                                    return (
                                        <div className="league-tournament-report-dates__container">
                                            {(props.phase_type === 'Groups') &&
                                                <div className="league-tournament-report-dates__group-title baloa-normal-medium">
                                                    {i18n.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelGroupNumber.${group.split(" ")[0]}`,{_number: group.split(" ")[1]})}
                                                    {/* : i18n.t(`league:options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.${props.phase_name}`) */}
                                                </div>
                                            }
                                            <div className="league-tournament-report-dates__date-by-group">
                                                {props.phase_matches_by_date.map((match) => {
                                                    if (match.group_match === group) {
                                                        return (
                                                            <div key={match.id} className="league-tournament-report-dates__match-detail-container" onClick={() => props.handleOnPostMatch(match)}>
                                                                <div className="league-tournament-report-dates__match-info-container">
                                                                    {match.state === "Finalized" ?
                                                                        <div className="league-tournament-report-dates__teams-result-container">
                                                                            <div className="baloa-table-column finalized">{i18n.t('league:ended')}</div>
                                                                        </div>
                                                                        :
                                                                        <div className="league-tournament-report-dates__teams-result-container">
                                                                            <span className='hubicon-schedule' />
                                                                            <div className="baloa-table-column">{moment(match.match_time, ["HH.mm"]).format("hh:mm A")}</div>
                                                                        </div>
                                                                    }
                                                                    <div className='league-tournament-report-dates__teams-stadium-container'>
                                                                        <span className='hubicon-stadium' />
                                                                        <div className={`baloa-table-column ${(match.state === 'Scheduled' || match.state === 'Not_scheduled') ? 'tournament-match__info__scheduled' : ''}`}>{match.field ? match.field : 'N/A'}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="league-tournament-report-dates__teams-names-container">
                                                                    <div className="league-tournament-report-dates__teams_local-container">
                                                                        <div className='league-tournament-report-dates__teams-container'>
                                                                            <div className="baloa-names">{match.local_team_name}</div>
                                                                            {match.local_team_photo === null
                                                                                ?
                                                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                                :
                                                                                <img src={match.local_team_photo} alt={`${match.local_team_name} img`} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="league-tournament-report-score__teams-container">
                                                                        {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.local_team_score} {`${match.local_team_penalty_score ? `(${match.local_team_penalty_score})` : ''}`}</div>}
                                                                        {match.state === 'Finalized' ? <div className="baloa-normal-medium"> - </div> : <div className="baloa-normal-medium">VS</div>}
                                                                        {match.state === 'Finalized' && <div className="baloa-normal-medium">{match.visitant_team_score} {`${match.visitant_team_penalty_score ? `(${match.visitant_team_penalty_score})` : ''}`}</div>}
                                                                    </div>
                                                                    <div className="league-tournament-report-dates__teams_visitant-container">
                                                                        <div className="league-tournament-report-dates__teams-container">
                                                                            {match.visitant_team_photo === null
                                                                                ?
                                                                                <span className="icon-ball" color={getRandonIconBallColor()} />
                                                                                :
                                                                                <img src={match.visitant_team_photo} alt={`${match.visitant_team_name} img`} />
                                                                            }
                                                                            <div className="baloa-names">{match.visitant_team_name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return null;
                                                })
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </React.Fragment>
                )
            })}
        </div>
    );
}


function LeagueTournamentReportDatesComponent(props) {

    const [tabSelected, setTabSelected] = React.useState(props.dateTab ? props.dateTab : 0);
    // const [tabSelected, setTabSelected] = React.useState(undefined);
    const [phaseIndex, setPhaseIndex] = React.useState(0);

    moment.locale(i18n.language);

    // READ_ME: Se agrega la nueva variable " date_to_show ", para difereciar la  phase activa y no devolver siempre la ultima phase
    React.useEffect(() => {
        if (props.phase_dates.length > 0 && props.active_phase_id) {
            //const phaseIndex = props.phase_dates?.findIndex(date => date.date_to_show === true);
            const newPhaseIndex = props.phase_dates?.findIndex(date => date.date_to_show === true);
            if (newPhaseIndex !== phaseIndex || props.groups_matches_by_date?.length === 0) {
                setPhaseIndex(newPhaseIndex);
                props.handleOnPhaseDate(props.phase_dates[newPhaseIndex].calendar_date);
                props.setDateTab(newPhaseIndex)
                setTabSelected(newPhaseIndex)
            }
        }
    }, [props.phase_dates, props.active_phase_id]); // eslint-disable-line
    return (

        <React.Fragment>
            <Tabs
                className="league-tournament-report-dates__tabs"
                value={tabSelected}
                indicatorColor="primary"
                textColor="primary"
                onChange={(ev, new_value) => { setTabSelected(new_value); props.setDateTab(new_value) }}
                aria-label="Selection between "
                variant="scrollable"
                scrollButtons="auto"
            >
                {
                    props.phase_dates.map((date, index) => {
                        return (
                            <Tab key={index} label={moment(date.calendar_date).format('MMMM DD')} onClick={() => { props.handleOnPhaseDate(date.calendar_date) }} />
                        )
                    })
                }

            </Tabs>

            {
                props.phase_dates.map((date, index) => {

                    return (
                        <TabPanel
                            index={index}
                            value={tabSelected}
                            key={index}
                            phase_dates={props.phase_dates}
                            selected_tournament={props.selected_tournament}
                            handleOnPostMatch={(post_match) => props.handleOnPostMatch(post_match)}
                            phase_matches_by_date={props.phase_matches_by_date}
                            groups_matches_by_date={props.groups_matches_by_date}
                            phase_type={props.tournament_active_phase?.phase_type}
                            phase_name={props.tournament_active_phase?.phase_name}
                        />
                    )
                })
            }
        </React.Fragment>

    )
}

export default LeagueTournamentReportDatesComponent;