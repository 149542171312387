// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.follower {
    background-color: var(--white-color);
    margin-left:  var(--space-and-half);
    margin-right:  var(--space);
    margin-top: 1%;
}

.follower__box {
    display: flex;
    padding: 5px;
    align-items: center;
    margin-bottom: 2%;
}

.follower__box__photo {
    width: var(--space-x3);
    height: var(--space-x3);
    min-width: var(--space-x3);
    min-height: var(--space-x3);
    margin-right: var(--space-half);
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

.follower__box__photo img {
    width: var(--space-x3);
    height: var(--space-x3);
    border-radius: inherit;
}

.follower__box__photo .icon-ball{
    /* height: 48px; */
    color: var(--white-color);
    font-weight: bold;
    font-size: calc(var(--space-x3) - 12px);
    padding: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follower__box__info-username {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    cursor: pointer;
}

.follower__box__button {
    align-items: center;
    
}`, "",{"version":3,"sources":["webpack://./src/shared/components/follower/FollowerComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mCAAmC;IACnC,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,+BAA+B;IAC/B,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,uCAAuC;IACvC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,mBAAmB;;AAEvB","sourcesContent":[".follower {\n    background-color: var(--white-color);\n    margin-left:  var(--space-and-half);\n    margin-right:  var(--space);\n    margin-top: 1%;\n}\n\n.follower__box {\n    display: flex;\n    padding: 5px;\n    align-items: center;\n    margin-bottom: 2%;\n}\n\n.follower__box__photo {\n    width: var(--space-x3);\n    height: var(--space-x3);\n    min-width: var(--space-x3);\n    min-height: var(--space-x3);\n    margin-right: var(--space-half);\n    cursor: pointer;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n}\n\n.follower__box__photo img {\n    width: var(--space-x3);\n    height: var(--space-x3);\n    border-radius: inherit;\n}\n\n.follower__box__photo .icon-ball{\n    /* height: 48px; */\n    color: var(--white-color);\n    font-weight: bold;\n    font-size: calc(var(--space-x3) - 12px);\n    padding: 6px;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.follower__box__info-username {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    cursor: pointer;\n}\n\n.follower__box__button {\n    align-items: center;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
