// React
import React, { useState, useEffect} from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TournamentEndMatchCommentComponent from '../tournament-end-match-comment/TournamentEndMatchCommentComponent';
import TournamentEndMatchScoreboardComponent from '../tournament-end-match-scoreboard/TournamentEndMatchScoreboardComponent';
import LeagueMatchScorerFormComponent from '../league-match-scorer-form/LeagueMatchScorerFormComponent';
import LeagueMatchCardsFormComponent from '../league-match-cards-form/LeagueMatchCardsFormComponent';
import LeagueMatchChangesFormComponent from '../league-match-changes-form/LeagueMatchChangesFormComponent';
import LeagueMatchAssistanceAligment from '../league-match-assistance-aligment/LeagueMatchAssistanceAligment';

// Styles
import './TournamentEndMatchModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentEndMatchModalComponent ( props ) {

    const [step, setStep] = useState(0);
    const [endGameState, setEndGameState] = React.useState( {
        matchAssintance: undefined,
        score: undefined, 
        scorers: undefined, 
        changes: undefined, 
        cards: undefined, 
        comments: undefined
    });
    const [endGameStepState, setEndGameStepState] = React.useState({
        matchAssintance: false,
        score_step: false, 
        scorers_step: false, 
        changes_step: false, 
        cards_step: false, 
        comments_step: false
    });
    const [positionPlayer, setPositionPlayer] = useState(null);

    useEffect(() => {
        if(props.can_edit_match && props.state_match === "Finalized"){
            setEndGameState(props.data_match_end);
            setEndGameStepState(props.data_match_end_step);
        }
    },[props.data_match_end, props.data_match_end_step ]); // eslint-disable-line

    useEffect(()=>{
        if(props.positions_members.length > 0){
            prepareMembersPositions(props.positions_members);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.positions_members]);

    function stepCompleted ( index ){
        //console.log("llamado a: " + index)
        //Initial step 0  = Match Assignment
        if(index === 1){
            setStep(1);//matchAssintance->score
        }else if(index === 2){
            setStep(2);// scorers -> scorers 
        }else if (index === 3) {
            setStep(3);//scorers -> changes 
        }else if (index === 4) {
            setStep(4);//changes -> cards
        }else if (index === 5) {
            setStep(5);//cards -> comments
        }else if(index === 0){
            setStep(0);//matchAssistant
        }
        
    }
    const closeModal=()=>{
        stepCompleted(0);
        setEndGameState( {
            ...endGameState,
            matchAssintance: undefined,
            score: undefined,
            scorers: undefined,
            changes: undefined,
            cards: undefined,
            comments: undefined
        });
        setEndGameStepState( {
            ...endGameStepState,
            matchAssintance: false,
            score_step: false,
            scorers_step: false,
            changes_step: false,
            cards_step: false,
            comments_step: false
        } );
        props.onClose();
        props.cleanTeamsMatchAssistance();
    }

    const setMatchAssistant = ( matchAssintance )=>{
        setEndGameState( {...endGameState, matchAssintance: matchAssintance });
    }

    const setScoreGameState = ( score )=>{
        setEndGameState( {...endGameState, score: score });
    }

    const setScorersGameState = ( scorers )=>{
        setEndGameState( {...endGameState, scorers: scorers });
    }

    const setChangesGameState = ( changes )=>{
        setEndGameState( {...endGameState, changes: changes });
    }

    const setCardsGameState = ( cards )=>{
        setEndGameState( {...endGameState, cards: cards });
    }

    const setCommentsGameState = ( comments )=>{
        setEndGameState( {...endGameState, comments: comments });
    }
    // const handleCleanData = () =>{
    //     setEndGameState( {...endGameState, score: undefined, scorers: undefined, cards: undefined, comments: undefined });
    //     setEndGameStepState( {...endGameStepState, score_step: false, scorers_step: false, cards_step: false, comments_step: false } );
    // }

    // Positions the members and traslate
    const prepareMembersPositions = ( positions_members ) =>{

    let positions_members_traslate = [
        { value: 'goalkeeper', content: props.t("team:team.tabs.members.goalkeeper") },
        { value: 'defense', content: props.t("team:team.tabs.members.defense") },
        { value: 'midfield', content: props.t("team:team.tabs.members.midfield") },
        { value: 'forward', content: props.t("team:team.tabs.members.forward") }
    ];
    // let new_positions_members_data = [];
    // positions_members.map( position => { 
    //     let position_translate = positions_members_traslate.find(position_traslate => position_traslate.value == position[1]);
    //     position.value = position[1];
    //     position.content = position_translate.content;
    //     console.log(position);
    //     return position;
    // });
        
        setPositionPlayer(positions_members_traslate);
    };

    const lowerCase = (word) => { return word[0]?.toLowerCase() + word.slice(1) }

    const validationInitialMemberAssignment = () =>{
        let initialMemberAssigment = {
            local: [],
            visitor: [],
        };
        initialMemberAssigment.local = props.teams_match_assistance.local_team?.players?.map((player)=>{
            return player.is_assigned ? {
                team_id: props.teams_match_assistance?.local_team.team_id,
                playerId: player.player_id,
                playerNumber: player.number,
                playerPositions: lowerCase(player?.player_position ?? ''),
                playerStateInMatch: player.rol_in_match ?? '',
                defaultChecked: true,
            } : null
        }).filter(member => member !==  null );

        initialMemberAssigment.visitor = props.teams_match_assistance.visitant_team?.players?.map((member)=>{
            return member.is_assigned ? {
                team_id: props.teams_match_assistance?.visitant_team.team_id,
                playerId: member.player_id,
                playerNumber: member.number,
                playerPositions: lowerCase(member?.player_position ?? ''),
                playerStateInMatch: member.rol_in_match ?? '',
                defaultChecked: true,
            } : null
        }).filter(member => member !==  null );

        return initialMemberAssigment;
    }
    
    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelTitle')}
            className="medium"
        >
            <div className="end-match scroll-parent"> 
                {/* Assistance and alignment  */}
                { step === 0 && (
                <LeagueMatchAssistanceAligment
                    step = { step }
                    stepCompleted= {(index) => {stepCompleted(index)}}
                    setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, score_step: state }) } }
                    endGameStepState = { endGameStepState }
                    local_team = {props.teams_match_assistance?.local_team}
                    local_team_members={ props.teams_match_assistance.local_team?.players}
                    visitor_team = { props.teams_match_assistance?.visitant_team }
                    visitor_team_members={ props.teams_match_assistance.visitant_team?.players }
                    positions_members={positionPlayer}
                    updatePlayersAssistanceAligment={props.updatePlayersAssistanceAligment}
                    setMatchAssistant={(matchAssintance)=>setMatchAssistant(matchAssintance)}
                    initialMemberAssigment={validationInitialMemberAssignment()}
                />
                )}

                {/* scoreboard */}
                { step === 1 && (
                    <React.Fragment>
                            
                        {endGameStepState.score_step === true && props.clean_data ?
                            setEndGameState( {...endGameState,  matchAssintance: undefined, score: undefined, scorers: undefined,changes: undefined, cards: undefined, comments: undefined })
                        : ''}
                        {endGameStepState.score_step === true && props.clean_data ?
                            setEndGameStepState( {...endGameStepState,  matchAssintance: false, score_step: false, scorers_step: false, changes_step: false, cards_step: false, comments_step: false } ) 
                        : ''}
                        <TournamentEndMatchScoreboardComponent
                            errors={props.errors_scoreboard}
                            onResetError={(data) => {props.onResetError(data)}}
                            step = { step }
                            stepCompleted= {(index) => {stepCompleted(index)}}
                            local_team = {props.local_team}
                            visitor_team = { props.visitor_team }
                            onNewData={ props.onNewDataScoreboard }
                            setScoreGameState = { (score) => { setScoreGameState(score) } }
                            endGameState = { endGameState }
                            setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, score_step: state }) } }
                            endGameStepState = { endGameStepState }
                        />
                    </React.Fragment>
                )}
                
                {/* scorers */}
                { step === 2 && (
                    <LeagueMatchScorerFormComponent
                        local_score= {props.local_score}
                        local_team = {props.local_team}
                        //local_team_members={ props.local_team_members }
                        local_team_members={ props.teams_match_assistance.local_team?.players}
                        visitor_score= { props.visitor_score }
                        visitor_team={ props.visitor_team }
                        //visitor_team_members={ props.visitor_team_members }
                        visitor_team_members={ props.teams_match_assistance.visitant_team?.players }
                        onNewData={ props.onNewDataScorers }
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                        setScorersGameState = { (scorers) => { setScorersGameState(scorers) } }
                        endGameState = { endGameState }
                        setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, scorers_step: state }) } }
                        endGameStepState = { endGameStepState }
                    />
                )}

                {/* changes */}
                { step === 3 && (
                    <LeagueMatchChangesFormComponent
                        local_score= {props.local_score}
                        local_team = {props.local_team}
                        //local_team_members={ props.local_team_members }
                        local_team_members={ props.teams_match_assistance.local_team?.players}
                        visitor_score= { props.visitor_score }
                        visitor_team={ props.visitor_team }
                        //visitor_team_members={ props.visitor_team_members }
                        visitor_team_members={ props.teams_match_assistance.visitant_team?.players }
                        onNewData={ props.onNewDataChanges }
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                        setChangesGameState = { (changes) => { setChangesGameState(changes) } }
                        endGameState = { endGameState }
                        setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, changes_step: state }) } }
                        endGameStepState = { endGameStepState }
                        handleOnMatchChanges = { props.handleOnMatchChanges }
                    />
                )}
                
                {/* admonished */}
                { step === 4 && (
                    <LeagueMatchCardsFormComponent
                        local_score= {props.local_score}
                        local_team = {props.local_team}
                        //local_team_members={ props.local_team_members }
                        local_team_members={ props.teams_match_assistance.local_team?.players}
                        visitor_score= { props.visitor_score }
                        visitor_team={ props.visitor_team }
                        //visitor_team_members={ props.visitor_team_members }
                        visitor_team_members={ props.teams_match_assistance.visitant_team?.players }
                        onNewData={ props.onNewDataCards }
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        step = { step }
                        setCardsGameState = { (cards) => { setCardsGameState(cards) } }
                        endGameState = { endGameState }
                        setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, cards_step: state }) } }
                        endGameStepState = { endGameStepState }
                    />
                )}
                {/* comment */}
                { step === 5 && (
                    <TournamentEndMatchCommentComponent
                        errors={props.errors_comment}
                        onResetError={(data) => {props.onResetError(data)}}
                        step = { step }
                        stepCompleted= {(index) => {stepCompleted(index)}}
                        onNewData={ (dataComments) => {
                            props.onNewDataComment(dataComments); 
                            closeModal();
                        } }
                        setCommentsGameState = { (comments) => { setCommentsGameState(comments) } }
                        endGameState = {  endGameState }
                        setEndGameStepState = { (state) => { setEndGameStepState({...endGameStepState, comments_step: state }) } }
                        endGameStepState = { endGameStepState }
                        //local_team_members={ props.local_team_members }
                        //visitor_team_members={ props.visitor_team_members }
                        playerMatch={props.playerMatch}
                        local_team_members={ props.teams_match_assistance.local_team?.players}
                        visitor_team_members={ props.teams_match_assistance.visitant_team?.players }
                        //handleCloseMatchModal={(values) => { props.handleCloseMatchModal(values) }}
                        // handleCleanData={(clean) => {this.handleCleanData(clean)}}
                    />
                )}

            </div>
        </Modal2Component>
    )

}

export default withTranslation(["league", "team"])(TournamentEndMatchModalComponent);