// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './InputWithButtonComponent.styles.css';


function InputWithButtonComponent( props ) {

    const input_ref = React.createRef();

    const [textAreaValue, setTextAreaValue] = React.useState(props.value? props.value:'');
    const handleChange =(event)=>{
        setTextAreaValue(event.target.value);
    }

    return (
        <div 
            className={ 'input-with-button ' + ( props.className ? props.className : '' ) }
        >
            <textarea 
                ref={ input_ref }
                disabled={ props.disabled }
                type={ props.type } 
                className="input-with-button__input baloa-names"
                placeholder={ props.placeholder ? props.placeholder : '' }
                value={textAreaValue}
                onChange={handleChange}
                autoFocus={props.autoFocus}
            />
            <button 
            onClick={ () => {
                props.onClick( input_ref?.current?.value );
                input_ref.current.value = '';
                setTextAreaValue('');
            }} 
            className="input-with-button__button baloa-username">
                { props.children }
            </button>
        </div>
    )
}

// Types
InputWithButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
}
export default InputWithButtonComponent;
