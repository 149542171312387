// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-card-id-viewer-modal__container {
    padding: 16px;
    background: var(--primary-very-dark-color);
    height: inherit;
    zoom: 150%;
}

.tournament-card-id-viewer-modal__loader {
    text-align: center;
    padding-top: 150px;
    height: 400px;
    width: inherit;
}

.tournament-card-id-viewer-modal__container .MuiMobileStepper-positionBottom {
    position: relative;
}

.tournament-card-id-viewer-modal.mobile.modal2 {
    bottom: unset;
}

.tournament-card-id-viewer-modal.mobile .modal2__container {
    margin: 0;
    height: 100%;
    border-radius: 0;
}

.tournament-card-id-viewer-modal.mobile .modal2__content {
    height: 100vh;
    position: inherit;
}

.tournament-card-id-viewer-modal__container.mobile {
    zoom: 100%;
}

.tournament-card-id-viewer-modal__container.mobile.horizontal {
    zoom: 80%;
}

.tournament-card-id-viewer-modal__container.mobile .MuiMobileStepper-positionBottom {
    position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/tournament-card-id-viewer-modal/TournamentCardIdViewerModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,eAAe;IACf,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tournament-card-id-viewer-modal__container {\n    padding: 16px;\n    background: var(--primary-very-dark-color);\n    height: inherit;\n    zoom: 150%;\n}\n\n.tournament-card-id-viewer-modal__loader {\n    text-align: center;\n    padding-top: 150px;\n    height: 400px;\n    width: inherit;\n}\n\n.tournament-card-id-viewer-modal__container .MuiMobileStepper-positionBottom {\n    position: relative;\n}\n\n.tournament-card-id-viewer-modal.mobile.modal2 {\n    bottom: unset;\n}\n\n.tournament-card-id-viewer-modal.mobile .modal2__container {\n    margin: 0;\n    height: 100%;\n    border-radius: 0;\n}\n\n.tournament-card-id-viewer-modal.mobile .modal2__content {\n    height: 100vh;\n    position: inherit;\n}\n\n.tournament-card-id-viewer-modal__container.mobile {\n    zoom: 100%;\n}\n\n.tournament-card-id-viewer-modal__container.mobile.horizontal {\n    zoom: 80%;\n}\n\n.tournament-card-id-viewer-modal__container.mobile .MuiMobileStepper-positionBottom {\n    position: fixed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
