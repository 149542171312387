// React
import React, { useState } from 'react';

// Components
import LeagueNewTeamFormComponent from 'app/leagues/components/league-new-team-form-component/LeagueNewTeamFormComponent';
import LeagueNewTeamStepsComponent from 'app/leagues/components/league-new-team-steps/LeagueNewTeamStepsComponent';
import LeagueAddPlayersToTeamComponent from 'app/leagues/components/league-add-players-to-team/LeagueAddPlayersToTeamComponent';
import LeagueBulkAddMembersComponentV2 from 'app/leagues/components/league-bulk-add-members/LeagueBulkAddMembersComponentV2';


// Styles
import './LeagueNewTeamComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';



function LeagueNewTeamComponent( props ) {    

    const [step, setStep] = useState(0);
    const [newTeamStepState, setNewTeamStepState] = React.useState( {basic_info_step: false, player_step: false } );
    const [addBulk, setAddBulk] = React.useState(false); // eslint-disable-line


    function stepCompleted ( index ){
        if(index === 1){
            setStep(1);
        }else if(index === 0){
            setStep(0);
        }        
    }
    const handleNewData=( data, timg,imgb64 )=>{
        props.handleOnNewTeam(data, timg, imgb64);
    }

    const onSaveBulkButton=()=>{
        setAddBulk(false);
        props.handleOnSaveBulkButton();
    }

    return (
        <div className="">
                <div className="league-new-team__header baloa-headline-3">
                        <div className="league-new-team__icon-close"></div>
                        <div>{props.t('newTeam.title')}</div>
                        <span className="league-new-team__icon-close icon-x " onClick={()=>{props.onCloseButton()}}></span>                      
                </div>                          
                  
                <LeagueNewTeamStepsComponent
                    step= { step }
                    stepCompleted = {(i)=>stepCompleted(i)}
                    newTeamStepState = { newTeamStepState }
                />
                {/* scoreboard */}
                { step === 0 && (
                    <LeagueNewTeamFormComponent
                        errors= { props.errors }
                        step= { step }
                        setNewTeamStepState = { (state) => { setNewTeamStepState({...newTeamStepState, basic_info_step: state }) } }
                        new_team_categories = { props.new_team_categories }
                        new_team_genders = { props.new_team_genders }
                        onNewData = { ( data, timg, imgb64 ) => { handleNewData( data, timg, imgb64 ) } }
                        onResetError = { props.onResetError }
                        stepCompleted= {(i)=>stepCompleted(i)}
                    />
                )}

                { step === 1 && (
                    <React.Fragment>
                    {!props.showBulk &&
                        <LeagueAddPlayersToTeamComponent
                            team= { props.team }
                            profiles={ props.profiles }
                            handleSearchMember = { props.handleSearchMember }
                            onDeletePlayerButton = { props.onDeletePlayerButton }
                            onSelectTeamPlayer = { props.onSelectTeamPlayer }
                            handleCleanMemberSearch = { props.handleCleanMemberSearch }
                            handleOnSaveButton = { props.handleOnSaveButton }
                            handleOnAddBulk={props.handleOnAddBulk}
                            handleOnMemberActionClick={ props.handleOnMemberActionClick }
                        />
                    }
                    {props.showBulk &&
                        <LeagueBulkAddMembersComponentV2
                            handleOnSaveBulkButton = { ()=>{ onSaveBulkButton() } }  
                            handleOnUploadFile = { props.handleOnUploadFile }
                            bulkMembers = { props.bulkMembers }
                            handleOnBackBulk={ props.handleOnBackBulk }
                            cleanBulkMembers = { props.cleanBulkMembers }
                            uploadProgress = { props.uploadProgress }
                            cleanStatusBar = { props.cleanStatusBar }
                            updateBulkData={ props.updateBulkData }
                            handleOnDeleteMember={props.handleOnDeleteMember}
                            handleOnMoreInfo={props.handleOnMoreInfo}
                        />
                    }
                    </React.Fragment>
                )}
                
        </div>
)
}

export default withTranslation('team')(LeagueNewTeamComponent);