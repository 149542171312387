import React from "react";

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


import "./StaffAcceptInvitationModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

//Assets
import mail_sent from 'assets/images/mail_sent.png';

function StaffAcceptInvitationModalComponent(props){
    return (
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            title=""            
            className='staff-accept-invitation-modal__container'
        >
        <div className ={"staff-accept-invitation-modal__content"}>
            <img src={mail_sent} alt='mail_sent'/>
            <div className="staff-accept-invitation-modal__tournament-info baloa-subtitle-1">
                <strong>{props.t('tournamentStaff.modalsInvitation.submitInvitationMessage')}</strong>
                <span className="baloa-normal">{props.t('tournamentStaff.modalsInvitation.submitInvitationNote')}</span>
            </div>
            
            <div className="staff-accept-invitation-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='staff-accept-invitation-modal__buttons-button small'
                    onClick= {props.handleStaffInscriptionButton}
                >
                    <span>{props.t('tournamentStaff.modalsInvitation.understandBtn')}</span>
                </PrimaryButtonComponent> 
            </div>
        </div>
    </ModalComponent>
    )
}

export default withTranslation('tournament')(StaffAcceptInvitationModalComponent);