/*eslint eqeqeq: "off"*/
// Action creators
import {     
    setMatchScoreAction,
    //setMatchPlayersGoalsAction,
    setMatchScorersAction,
    setMatchChangesAction,
    setMatchPlayersCardsAction,
    setMatchCommentsAction,
    loadMatchReportAction,
    loadLocalTeamPageMembersAction,
    loadVisitorTeamPageMembersAction,
    loadLocalTeamPageDataAction,
    loadVisitorTeamPageDataAction,
    getOfficialRefereeMemberAction,
    getSecondRefereeMemberAction,
    getThirdRefereeMemberAction,
    getFourthRefereeMemberAction,
    getRefereeAssignerMemberAction,
} from './LeagueMatchActionsCreators';

import {  
    loadDateMatches,
    finishedMatchesByPhase,
} from './LeagueTournamentActions';

import  { setTeamsToMatchAssistanceAligment } from './LeagueTournamentActionsCreators'

// Apis
import { 
    SetMatchScoreApi,
    //SetMatchPlayersGoalsApi,
    SetMatchScorersApi,
    SetMatchChangesApi,
    SetMatchPlayersCardsApi,
    SetMatchCommentsApi,
    GetMatchReportApi,
    //GetTeamPageMembersApi,
    GetTeamPageDataApi,
    GetTeamMembersApi,
    GetAdminTeaminMatchReportApi,
    PatchAdminTeamsAssistanceAligmentApi
} from '../apis/LeagueMatchApi';

import { GetMemberApi } from '../apis/LeagueApi';

// i18next 
import i18n from 'i18next';



import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';


export const setMatchScore = ( match, score ) => {

    return ( dispatch ) => {
        
        SetMatchScoreApi( match, score )
            .then( response => {
                dispatch( setMatchScoreAction( response ) );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

/* export const setMatchPlayersGoals = ( match, goals ) => {

    return ( dispatch ) => {

        SetMatchPlayersGoalsApi( match, goals )
            .then( response => {
                dispatch( setMatchPlayersGoalsAction( goals ) );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
} */

export const setMatchScorers = ( match, scorers ) => {

    return ( dispatch ) => {

        SetMatchScorersApi( match, scorers )
            .then( response => {
                dispatch( setMatchScorersAction( response ) );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setMatchChanges = ( match, changes ) => {

    return ( dispatch ) => {

        SetMatchChangesApi( match, changes )
            .then( response => {
                dispatch( setMatchChangesAction( response ) );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setMatchPlayersCards = ( match_id, cards ) => {

    return ( dispatch ) => {

        SetMatchPlayersCardsApi( match_id, cards )
            .then( response => {
                dispatch( setMatchPlayersCardsAction( response ) );            
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const setMatchComments = ( match_id, comments, selected_date_id, phase ) => {

    return ( dispatch ) => {

        SetMatchCommentsApi( match_id, comments )
            .then( response => {
                dispatch( setMatchCommentsAction( response ) );   
                dispatch( loadDateMatches( selected_date_id )); 
                
                dispatch( finishedMatchesByPhase( phase ) );
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const loadMatchReport = ( match_id ) => {
    
    return ( dispatch ) => {
        GetMatchReportApi( match_id )
            .then( response => {
                dispatch( loadMatchReportAction( response.data ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadLocalTeamPageMembers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamMembersApi( team_page_name )
            .then( response => {
                dispatch( loadLocalTeamPageMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadVisitorTeamPageMembers = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamMembersApi( team_page_name )
            .then( response => {
                dispatch( loadVisitorTeamPageMembersAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadLocalTeamPageData = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageDataApi( team_page_name )
            .then( response => {
                dispatch( loadLocalTeamPageDataAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const loadVisitorTeamPageData = ( team_page_name ) => {
    return ( dispatch ) => {
        GetTeamPageDataApi( team_page_name )
            .then( response => {
                dispatch( loadVisitorTeamPageDataAction( response ) );                
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })

    }
}

export const getOfficialRefereeMember = ( member_username ) => {
    return async ( dispatch ) => {
        try {
            const response = await GetMemberApi( member_username )
            dispatch( getOfficialRefereeMemberAction( response.data ) );
        }        
        catch(err) {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const getSecondRefereeMember = ( member_username ) => {
    return async ( dispatch ) => {
        try {
            const response = await GetMemberApi( member_username )
            dispatch( getSecondRefereeMemberAction( response.data ) );
        }        
        catch(err) {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const getThirdRefereeMember = ( member_username ) => {
    return async ( dispatch ) => { 
        try {
            const response = await GetMemberApi( member_username )
            dispatch( getThirdRefereeMemberAction( response.data ) );
        }       
       catch(err) {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done';
    }
}

export const getFourthRefereeMember = ( member_username ) => {
    return async  dispatch  => { 
        try {
            const response = await GetMemberApi( member_username )
            dispatch( getFourthRefereeMemberAction( response.data ) );
        }       
        catch(err)  {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done'
    }
}

export const getRefereeAssignerMember = ( member_username ) => {
    return async  dispatch  => { 
        try {
            const response = await GetMemberApi( member_username )
            dispatch( getRefereeAssignerMemberAction( response.data ) );
        }       
        catch(err)  {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('league:snackBarMessages.snackBarError'),
                    error_message_is_open: true
                } ) )
            }
        }
        return 'done'
    }
}

export const loadAdminTeaminMatchReportAction = (match_id) =>{
    return ( dispatch ) => {
        GetAdminTeaminMatchReportApi( match_id )
            .then( response => {
                dispatch(setTeamsToMatchAssistanceAligment(response)); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}

export const updateAdminTeamsAssistanceAligment = ( matchId, playerArray) => {
    return ( dispatch ) => {
        PatchAdminTeamsAssistanceAligmentApi(  matchId, playerArray )
            .then( response => {
                dispatch(loadAdminTeaminMatchReportAction(matchId)); 
            })
            .catch(err => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
            })
    }
}