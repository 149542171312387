// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radioButton-dynamic-form__label.MuiFormLabel-root {
    line-height: calc( var(--space) + var(--space-quarter) );
    color: var(--basic-gray-color);
    letter-spacing: 0.0025em;
    font-style: normal;
    font-weight: var(--font-medium);
    font-family: var(--secondary-font);
    line-height: calc( var(--space) + var(--space-quarter) );
    font-size: calc(var(--space-half-quarter) + var(--space-eighth));
}

.MuiFormControl-root.radioButton-dynamic-form{
    padding-bottom: var(--space-and-half);
}

.radioButton-dynamic-form span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: var(--basic-gray-color);
    letter-spacing: 0.0025em;
    font-family: var(--secondary-font);
    line-height: calc( var(--space) + var(--space-quarter) );
}

.radioButton-dynamic-form__boolean-question {
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/dynamic-fields-tournament-form/radioButton-dynamic-form/RadioButtonDynamicFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;IACxD,8BAA8B;IAC9B,wBAAwB;IACxB,kBAAkB;IAClB,+BAA+B;IAC/B,kCAAkC;IAClC,wDAAwD;IACxD,gEAAgE;AACpE;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,8BAA8B;IAC9B,wBAAwB;IACxB,kCAAkC;IAClC,wDAAwD;AAC5D;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".radioButton-dynamic-form__label.MuiFormLabel-root {\n    line-height: calc( var(--space) + var(--space-quarter) );\n    color: var(--basic-gray-color);\n    letter-spacing: 0.0025em;\n    font-style: normal;\n    font-weight: var(--font-medium);\n    font-family: var(--secondary-font);\n    line-height: calc( var(--space) + var(--space-quarter) );\n    font-size: calc(var(--space-half-quarter) + var(--space-eighth));\n}\n\n.MuiFormControl-root.radioButton-dynamic-form{\n    padding-bottom: var(--space-and-half);\n}\n\n.radioButton-dynamic-form span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {\n    color: var(--basic-gray-color);\n    letter-spacing: 0.0025em;\n    font-family: var(--secondary-font);\n    line-height: calc( var(--space) + var(--space-quarter) );\n}\n\n.radioButton-dynamic-form__boolean-question {\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
