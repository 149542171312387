/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { compose } from 'redux';

// Styles
import './CampaignBaloersView.styles.css';

// Assets
import baloer_verified from 'assets/images/baloer_verified.png';
import how_to_apply from 'assets/images/how-to-apply.png';
import logo_baloa_white from 'assets/images/logo-baloa-white.png';
import gplay_logo from 'assets/images/googleplay-banner-clean.png';
import appstore_logo from 'assets/images/appstore-banner-clean.png';

// Formaters
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';
class CampaignBaloersView extends React.Component { 

    render() {

        return (
            <div className='campaign-baloers'>
                <div className='campaign-baloers__banner'>
                    <div className='campaign-baloers__banner-text-intro'>{this.props.t('baloers.banner.intro')}</div>
                    <div className='campaign-baloers__banner-text-prizes'>{this.props.t('baloers.banner.prizes')}</div>
                    <div className='campaign-baloers__banner-text-winners'>{this.props.t('baloers.banner.winners')}</div>
                    <div className='campaign-baloers__banner-apps'>
                        <a href="https://n9.cl/l3epk">
                            <img class="campaign-baloers__banner__gplay-icon" src={gplay_logo} alt="Google Play" />
                        </a>    
                        <a href="https://n9.cl/ybq9">
                            <img class="campaign-baloers__banner__appstore-icon" src={appstore_logo} alt="App Store" />
                        </a>
                    </div>
                </div>
                <div className='campaign-baloers__how-to'>
                    <div className='campaign-baloers__text-intro'>{this.props.t('baloers.howto.title')}</div>
                    <div className='campaign-baloers__how-to__intro' dangerouslySetInnerHTML={{
                        __html: HASHTAG_FORMATTER( this.props.t('baloers.howto.intro') )
                        }}
                    ></div>
                    <div className='campaign-baloers__how-to__message'>
                        <div className='campaign-baloers__how-to__green-message'>{this.props.t('baloers.howto.message')}</div>   
                        <div className='campaign-baloers__how-to__callto'>{this.props.t('baloers.howto.callto')}</div>    
                    </div>                                     
                </div>
                <div className='campaign-baloers__prizes'>
                    <div className='campaign-baloers__text-intro'>{this.props.t('baloers.prizes.title')}</div>
                    <div className='campaign-baloers__prizes-table'>
                        <table className='campaign-baloers__prizes__table'>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize1')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>700 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize2')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>500 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize3')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>250 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize4')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>200 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize5')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>150 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize6')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>100 USD</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__prizes__column-prize-desc'>{this.props.t('baloers.prizes.prize7')}</td>
                                <td className='campaign-baloers__prizes__column-prize'>50 USD</td>
                            </tr>
                        </table>
                    </div>
                    <div className='campaign-baloers__prizes-measurement'>
                        <div className='campaign-baloers__prizes-measurement-title'>{this.props.t('baloers.prizes.measurement.title')}</div>
                        <div className='campaign-baloers__prizes-measurement-text' dangerouslySetInnerHTML={{
                            __html: HASHTAG_FORMATTER( this.props.t('baloers.prizes.measurement.text') )
                            }}
                        ></div>
                    </div>                 
                </div>
                <div className='campaign-baloers__example'>
                    <div className='campaign-baloers__example-title'>{this.props.t('baloers.example.title')}</div>
                    <div className='campaign-baloers__example-table'>
                        <table className='campaign-baloers__example__table'>
                            <tr>
                                <td className='campaign-baloers__example__column-header'></td>
                                <td className='campaign-baloers__example__column-header'>{this.props.t('baloers.example.quantity')}</td>
                                <td className='campaign-baloers__example__column-header'>{this.props.t('baloers.example.value')}</td>
                                <td className='campaign-baloers__example__column-header'>{this.props.t('baloers.example.calc')}</td>
                                <td className='campaign-baloers__example__column-header'>{this.props.t('baloers.example.points')}</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__example__column-lbl'>{this.props.t('baloers.example.followers')}</td>
                                <td className='campaign-baloers__example__column-text'>1500</td>
                                <td className='campaign-baloers__example__column-text'>60%</td>
                                <td className='campaign-baloers__example__column-text'>1500 * 0.6</td>
                                <td className='campaign-baloers__example__column-text'>900</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__example__column-lbl'>{this.props.t('baloers.example.content')}</td>
                                <td className='campaign-baloers__example__column-text'>200</td>
                                <td className='campaign-baloers__example__column-text'>25%</td>
                                <td className='campaign-baloers__example__column-text'>200 * 0.25</td>
                                <td className='campaign-baloers__example__column-text'>50</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__example__column-lbl'>{this.props.t('baloers.example.interactions')}</td>
                                <td className='campaign-baloers__example__column-text'>400</td>
                                <td className='campaign-baloers__example__column-text'>15%</td>
                                <td className='campaign-baloers__example__column-text'>400 * 0.15</td>
                                <td className='campaign-baloers__example__column-text'>60</td>
                            </tr>
                            <tr>
                                <td className='campaign-baloers__example__column-text' colSpan={3}></td>
                                <td className='campaign-baloers__example__column-lbl'>{this.props.t('baloers.example.total')}</td>
                                <td className='campaign-baloers__example__column-header'>1010</td>
                            </tr>
                        </table>
                    </div>                   
                </div>
                <div className='campaign-baloers__definitions'>
                    <div className='campaign-baloers__definitions-image'>
                        <img src={baloer_verified} alt="baloer-verified" />
                    </div>
                    <div className='campaign-baloers__definitions-baloers'>
                        <span class="iconbal-Balores">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                        </span>
                        <div className='campaign-baloers__definitions-title'>{this.props.t('baloers.definitions.baloerLbl')}</div>
                        <div className='campaign-baloers__definitions-text'>{this.props.t('baloers.definitions.baloer')}</div>
                    </div>
                    <div className='campaign-baloers__definitions-verified'>
                        <span class="iconb2-Verificated">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                        <div className='campaign-baloers__definitions-title'>{this.props.t('baloers.definitions.verifiedLbl')}</div>
                        <div className='campaign-baloers__definitions-text'>{this.props.t('baloers.definitions.verified')}</div>
                    </div>              
                </div>
                <div className='campaign-baloers__howto-apply'>
                    <div className='campaign-baloers__howto-apply-steps'>
                        <div className='campaign-baloers__definitions-title'>{this.props.t('baloers.howtoapply.title')}</div>
                        <div className='campaign-baloers__howto-apply-steps__steps'>
                            <div className='campaign-baloers__howto-apply-steps__steps-step'><span className='campaign-baloers__howto-apply-steps__number'>1.</span>{this.props.t('baloers.howtoapply.steps1')}</div>
                            <div className='campaign-baloers__howto-apply-steps__steps-step'><span className='campaign-baloers__howto-apply-steps__number'>2.</span>{this.props.t('baloers.howtoapply.steps2')}</div>
                            <div className='campaign-baloers__howto-apply-steps__steps-step'><span className='campaign-baloers__howto-apply-steps__number'>3.</span>{this.props.t('baloers.howtoapply.steps3')}</div>
                            <div className='campaign-baloers__howto-apply-steps__steps-step'><span className='campaign-baloers__howto-apply-steps__number'>4.</span>{this.props.t('baloers.howtoapply.steps4')}</div>
                            <div className='campaign-baloers__howto-apply-steps__steps-step'><span className='campaign-baloers__howto-apply-steps__number'>5.</span>{this.props.t('baloers.howtoapply.steps5')}</div>
                        </div> 
                        <div className='campaign-baloers__howto-apply-steps__apps-images'>
                            <a href="https://n9.cl/l3epk">
                                <img src={gplay_logo} alt="Google Play" />
                            </a>    
                            <a href="https://n9.cl/ybq9">
                                <img src={appstore_logo} alt="App Store" />
                            </a>
                        </div> 
                    </div>
                    <div className='campaign-baloers__howto-apply-image'>
                        <img src={how_to_apply} alt="how to aplly" />
                    </div>                                 
                </div>
                <div className='campaign-baloers__termsandconditions'>
                    <div className='campaign-baloers__termsandconditions_container'>
                        <div className='campaign-baloers__termsandconditions-title'>{this.props.t('baloers.tyc.title')}</div>
                        <div className='campaign-baloers__termsandconditions__tyc'>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms1')}</div>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms2')}</div>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms3')}</div>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms4')}</div>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms5')}</div>
                            <div className='campaign-baloers__termsandconditions__tyc__terms'>&nbsp;&bull;&nbsp;{this.props.t('baloers.tyc.terms6')}</div>
                        </div>  
                    </div>                            
                </div>
                <div className='campaign-baloers__footer'>
                    <div className='campaign-baloers__footer-logo'>
                        <img src={logo_baloa_white} alt='logo_baloa_white' />
                    </div>
                    <div className='campaign-baloers__footer-text'>{this.props.t('baloers.footer.text')}</div>                              
                </div>
            </div>
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default compose(  
    withTranslation("ganaconbaloa"),        
    connect(mapStateToProps, mapDispatchToProps)
  )(CampaignBaloersView);