/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { connect } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import {Helmet} from "react-helmet";

// Actions
import {
    clapPost,
    loadPostDetailById,
    commentPostById,
    replyCommentPostById,
    loadPostCommentsById,
    loadReplyPostCommentsById,
    deleteComment,
    clapCommentById,
    loadPostCommentsNext,
} from 'app/posts/actions/PostActions';
import { overwriteInterceptorAction } from 'app/profiles/actions/ProfileActionsCreators';

// Styles
import './PostDetailView.styles.css';

// Assets
import options_icon from 'assets/icons/options-icon.svg';

// Components
import InputWithButtonComponent from 'shared/components/input-with-button/InputWithButtonComponent';
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';
import { changeSignUpLoginModal } from 'app/authentication/actions/LoginActionsCreators';
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';
import ShowPostFormationFieldComponent from 'shared/components/show-post-formation/ShowPostFormationFieldComponent';

// Functions
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';

// Variables
import { web_url } from "config/GlobalData";

// React device detect
import { isMobile } from 'react-device-detect';

class PostDetailUnauthenticatedView extends Component {
    formInitialValues = {
        caption: "",
    }

    constructor(props) {
        super(props);
        this.post_id = this.props.match.params.post_id;
        this.videoRef = React.createRef();
    }

    state = {
        edit_element: null,
        // user_menu_is_open: false,
        play: false,
        audio: false,
        video_ref: null,
        // selected_comment: null,
        show_reply_coment: [],
        state_comments_update: true,
        anchorEl: null,
        selectedIndex: 0,
        posterId: null,
        posterName: null,
        menu_open: null,
        options: [],
        caption: null,
        menuOpen: false,
        iconColor: null,
    }

    componentDidMount() {
        this.props.loadPostDetailById(this.post_id);
        this.props.loadPostCommentsById(this.post_id);
        this.setOptionsPages();
        this.setState({iconColor: getRandonIconBallColor()});
    }

    componentDidUpdate(prevProps) {
        if (typeof prevProps.state.profile.page_active != typeof this.props.state.profile.page_active) {
            this.props.loadPostDetailById(this.post_id);
            this.props.loadPostCommentsById(this.post_id);
        }
    }

    // handleOnOptionsUserClick = ( event ) => {
    //     this.setState(
    //         { 
    //             user_menu_is_open: true, 
    //             edit_element: event.currentTarget,
    //         }
    //     );
    // };

    handleOnReplyCommentClick = (index) => {
        let show_reply_coment = this.state.show_reply_coment?.slice(0);
        show_reply_coment[index] = true;
        this.setState({ show_reply_coment });
    };

    handleOnReplyCommentClose = (index) => {
        let show_reply_coment = this.state.show_reply_coment?.slice(0);
        show_reply_coment[index] = false;
        this.setState({ show_reply_coment });
    };

    handleUserOptionsMenuClose = () => {
        this.setState({ user_menu_is_open: false });
    };

    handleVideoPlayClick = () => {

        if (!this.state.play) {
            this.videoRef.current.play();
            this.setState({ play: true })
        }
        else {
            this.videoRef.current.pause();
            this.setState({ play: false })
        }
    }

    handleVideoMutedClick = () => {
        if (this.videoRef.current.muted) {
            this.videoRef.current.muted = false;
            this.setState({ audio: false });
        }
        else {
            this.videoRef.current.muted = true;
            this.setState({ audio: true })
        }
    }

    onEnterViewport = (index) => {
        if ((this.props.state.post.comments.length - 2) === index) {
            if (this.props.state.post.comments_next_page !== null) {
                this.props.loadPostCommentsNext(this.props.state.post.comments_next_page);
            }
        }
    }

    setAnchorEl = (e) => {
        this.setState({ anchorEl: e });
    }

    setSelectedIndex = (e) => {
        this.setState({ selectedIndex: e });
    }

    setPosterId = (id) => {
        this.setState({ posterId: id });
    }

    setPosterName = (name) => {
        this.setState({ posterName: name });
    }

    setMenuOpen = (val) => {
        this.setState({ menu_open: val });
    }

    handleClickListItem = (event) => {
        this.setAnchorEl(event.currentTarget);
        this.setMenuOpen(true);
    };

    handleMenuItemClick = (event, index, page) => {
        this.setSelectedIndex(index);

        if(index!=0){
            this.setPosterId(page[1]);
            this.setPosterName(page[0]);
        }else{
            this.setPosterId(null);
            this.setPosterName(null);
        }
        this.setMenuOpen(false);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };
    
    setOptionsPages = () => {
        let optArray = [];
        optArray.push([this.props.state.user.me.username, this.props.state.user.me.profile_id, this.props.state.user.me.photo]);
        if (this.props.state.profile?.loggedin.pages) {
            this.props.state.profile.loggedin.pages.map((p) =>
              optArray.push([p.pagename, p.id, p.photo])
             
            );
            this.setState({options: optArray});
         } 
           if (this.props.state.profile.page_active != undefined) {
              var index = optArray.findIndex(element => element[0] === this.props.state.profile.page_active.pagename);
              this.setSelectedIndex(index);
              this.setPosterName(this.props.profile?.page_active.pagename);
              this.setPosterId(this.props.state.profile.page_active.id);
           }      
         
       }
    overInter = () => {
        if(this.props.state.profile != undefined) {
            if (this.state.selectedIndex > 0) {   //post like page
                this.props.overwriteInterceptorAction(true);
            }else if( this.props.state.profile.page_active ){   //page post like profile
                this.props.overwriteInterceptorAction(true);
            }else{
                this.props.overwriteInterceptorAction(false);
            }
        }
    }

    render() {

        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: Post`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content= {`${this.props.state.post.post?.profile? this.props.state.post.post?.profile.user?.first_name +' '+ this.props.state.post.post?.profile.user?.last_name : this.props.state.post.post?.page? this.props.state.post.post?.page?.name : '' } en Baloa`} />
                    <meta property="og:description" content={`${this.props.state.post.post?.caption}`} />
                    <meta property="og:image" content={`${this.props.state.post.post?.post_image ? this.props.state.post.post?.post_image : this.props.state.post.post?.post_stream?.thumbnail? this.props.state.post.post?.post_stream?.thumbnail : web_url+'/logo_baloa_social_share.png'}`} />
                    <meta property="og:url" content={`${web_url}/posts/${this.post_id}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content={`${this.props.state.post.post?.profile? this.props.state.post.post?.profile.user?.first_name +' '+ this.props.state.post.post?.profile.user?.last_name : this.props.state.post.post?.page? this.props.state.post.post?.page?.name : '' } en Baloa`} />
                    <meta name="twitter:description" content={`${this.props.state.post.post?.caption}`} />
                    <meta name="twitter:image" content={`${this.props.state.post.post?.post_image ? this.props.state.post.post?.post_image : this.props.state.post.post?.post_stream?.thumbnail? this.props.state.post.post?.post_stream?.thumbnail : web_url+'/logo_baloa_social_share.png'}`} />
                    <meta name="twitter:url" content={`${web_url}/posts/${this.post_id}`} />
                </Helmet>

                {/* Post Detail */}

                <div className="post-detail">

                    {/* Left side */}
                    <div className="post-detail__left-container">
                    </div>

                    {/* Center */}
                    <div className="post-detail__center-container">

                        {/* Post Data */}
                        <div className="post-detail__content">                        

                            {/* Group Post header */}
                            {this.props.state.post.post?.context?.page_type == "group" &&
                                <div className="post-detail__header__group">
                                    <div
                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                        className={"post-detail__header__group-photo"}>                        
                                            {this.props.state.post.post.context.photo &&
                                                <img src={this.props.state.post.post.context.photo} alt="" />
                                            }
                                            {!this.props.state.post.post.context.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            } 
                                    </div>
                                    <div className="post-detail__header__info">
                                        <div
                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                            className="post-detail__header__info__group-username baloa-caption">
                                            { this.props.state.post.post.context.caption }
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Profile Post Header */}
                            { this.props.state.post?.post?.author?.type_object == "profile" && 
                                <div className="post-detail__header">

                                    {/* Profile photo */}
                                    <div
                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                        className={"post-detail__header__photo"}>
                                            {this.props.state.post.post.author.photo &&
                                                <img src={this.props.state.post.post.author.photo} alt="" />
                                            }
                                            {!this.props.state.post.post.author.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            }                    
                                    </div>

                                    {/* Profile data */}
                                    <div className="post-detail__header__info">
                                        <div
                                            
                                            className="post-detail__header__info__username baloa-normal-medium"
                                        >
                                            <span onClick={() => this.props.changeSignUpLoginModal(true)} >{ `${this.props.state.post?.post?.author.caption}`} </span>
                                            {this.props.state.post.post.author.is_verified &&
                                                <span class="iconb2-Verificated">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </span>
                                            }
                                            {this.props.state.post.post.author.baloer &&
                                                <span class="iconbal-Balores">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </span>
                                            } 
                                            { !isMobile &&
                                                <React.Fragment>
                                                    &nbsp;&bull;&nbsp;
                                                    <span 
                                                        className={"post-detail__header__info__username__follow-link baloa-hyperlink"}
                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                    > 
                                                    
                                                        {this.props.state.post.post.is_following ?
                                                            this.props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                                            :
                                                            this.props.t('post.follow')/*TODO:implementar follow/unfollow*/
                                                        } 
                                                    </span>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className="post-detail__header__info__date baloa-table-column">                           
                                            { `${this.props.state.post.post.author.profile_type}`}  &bull; {`${convertDateToMoment(this.props.state.post.post.created)}`}
                                            {/* {console.log(this.props.state.post.post)/**en post hace [5] horas */} 
                                            {isMobile &&
                                                <React.Fragment>
                                                    &nbsp;&bull;&nbsp;
                                                    <span
                                                        className={"post-detail__header__info__username__follow-link baloa-hyperlink mobile"}
                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                    >
                                                        {this.props.state.post.post.is_following ?
                                                            this.props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                                            :
                                                            this.props.t('post.follow')/*TODO:implementar follow/unfollow*/
                                                        }
                                                    </span>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>

                                    {/* Not Owners options */}
                                    { this.props.state.post.post.is_owner === false &&
                                        <div className="post-detail__header__options">
                                            <div 
                                                className="post-detail__header__options__option"
                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                            >
                                                <img src={ options_icon } alt=""/>

                                               
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            }

                            {/* Page Post Header */}
                            { this.props.state.post.post?.author?.type_object == "page" &&
                                <div className="post-detail__header">
                                    <div
                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                        className={"post-detail__header__photo "}
                                    >
                                        {this.props.state.post.post?.author.photo &&
                                            <img src={this.props.state.post.post?.author.photo} alt="" />
                                        }
                                        {!this.props.state.post.post?.author.photo &&
                                            <span className={"icon-ball"} color={this.state.iconColor}></span>
                                        } 
                                    </div>
                                    <div className="post-detail__header__info">
                                        <div
                                            /* onClick={() => this.handleOnAuthorClick( this.props.state.post.post?.author ) } */
                                            className="post-detail__header__info__username baloa-normal"
                                        >
                                            <span onClick={() => this.props.changeSignUpLoginModal(true)} >{ `${this.props.state.post.post?.author?.caption}`} </span>
                                            {/* this.props.state.post.post?.author?.identifier */}
                                            {this.props.state.post.post.author.is_verified &&
                                                <span class="iconb2-Verificated">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </span>
                                            }
                                            {this.props.state.post.post.author.baloer &&
                                                <span class="iconbal-Balores">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                </span>
                                            }          
                                            &nbsp;&bull;&nbsp;                               
                                            <span 
                                                className={"post__header__info__username__follow-link baloa-hyperlink"}
                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                            >                                                 
                                                {this.props.state.post.post.is_following ?
                                                    this.props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                                    :
                                                    this.props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                                } 
                                                
                                            </span>
                                        </div>
                                        <p className="post-detail__header__info__date baloa-table-column">
                                            { convertDateToMoment( this.props.state.post.post?.created ) }
                                        </p>
                                    </div>

                                    { (!this.props.state.post.post?.is_owner && !this.props.state.page?.page?.is_admin && !this.props.state.page?.page?.is_member  ) &&
                                        <div className="post-detail__header__options">
                                            <div 
                                                className="post-detail__header__options__option"
                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                            >
                                                <img src={ options_icon } alt=""/>
                                                                                                
                                            </div>
                                        </div>
                                    }
                                   
                                </div> 
                                
                            }

                            {/* Caption */}
                            {this.props.state.post.post?.caption && 
                                <div className="post-detail__content__caption">                                    
                                    <div className="post-detail__content__caption__info">                                        
                                        <div className="post-detail__content__caption__info__caption baloa-normal" dangerouslySetInnerHTML={{
                                                    __html: HASHTAG_FORMATTER( this.props.state.post.post?.caption )
                                            }}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Media */}
                            <div className="post-detail__content__media">
                                {this.props.state.post.post?.post_image && <img src={this.props.state.post.post?.post_image} alt="" />}

                                {this.props.state.post.post?.post_video &&
                                    <div className="post-detail__content__media__video">
                                        <ReactHlsPlayer
                                            playerRef={this.videoRef}
                                            src={this.props.state.post.post?.post_stream.hls}
                                            autoPlay={true}
                                            width={'100%'}
                                            height={'100%'}                                            
                                            loop={true}
                                            poster = { this.props.state.post.post?.post_stream?.thumbnail ? this.props.state.post.post?.post_stream?.thumbnail : "" }
                                        /> 
                                        <div className="post-detail__content__media__video__control"
                                            onClick={() => { this.handleVideoPlayClick() }}
                                        >
                                            <div className={ !this.state.play ? "post-detail__content__media__video__control__background" : "" }></div>
                                            <span className={ !this.state.play ? "post-detail__content__media__video__control__icon" : "" } ></span>
                                        </div>
                                        <div className="post-detail__content__control-mute"
                                            onClick={() => { this.handleVideoMutedClick() }}
                                        >
                                            <span className={this.state.audio ? "post-detail__content__control-mute__icon icon-sound-off" : "post-detail__content__control-mute__icon icon-sound-on"}></span>
                                        </div>
                                    </div>
                                }

                                {/* Post match content */}
                                {this.props.state.post.post.post_match &&
                                    <LeagueTournamentInfoComponent
                                        tournament_info={this.props.state.post.post.post_match}
                                        className = { "post__content__tournament-result__container" }
                                    />                                       
                                }
                                {/* Post Formation content */}
                                { this.props.state.post.post.formation &&
                                    <div className="post-formation__content">
                                        <div className="post-formation__data">
                                            <div className="post-formation__data__positions-graph">
                                                <ShowPostFormationFieldComponent
                                                    show={this.props.state.post.post.formation ? "block" : "none"}
                                                    display="form"
                                                    position="secondary"
                                                    formation={this.props.state.post.post.formation}
                                                    sup="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* Claps */}
                            {this.props.state.post.post.reaction_count > 0 ?
                                <React.Fragment>    
                                    <div className="post-detail__clapped-by">
                                        <div className="post-detail__clapped-by__photos">
                                            {this.props.state.post.post.reacted_by.map(
                                                clap =>
                                                    <div key={clap[0]} className="post-detail__clapped-by__photos__photo">
                                                        {clap[2] &&
                                                            <img src={clap[2]} alt="" />
                                                        }
                                                        {!clap[2] &&
                                                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                        }                                                        
                                                    </div>
                                            )
                                            }
                                        </div>
                                        <p className="post-detail__clapped-by__message"
                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                        >
                                            {
                                                this.props.state.post.post.reaction_count <= 0 ?
                                                    ""
                                                    :
                                                    <span className="baloa-normal">
                                                        {/* this.props.t('postModal.titleLike')} @{this.props.state.post.post.clapped_by[0][0] */}
                                                        {/* (this.props.state.post.post.clap_count - 1) <= 0 ? "" : this.props.t('postModal.text1') + ' ' + (this.props.state.post.post.clap_count - 1) + ' ' + this.props.t('postModal.text2') */}
                                                        {this.props.state.post.post.is_reacted? (this.props.state.post.post.reaction_count - 1) <= 0 ? this.props.t('postModal.reactionText3', {user: this.props.state.post.post.reacted_by[0][1]}) : this.props.t('postModal.reactionText1', {reactionsCount: `${this.props.state.post.post.reaction_count - 1}` }) : (this.props.state.post.post.reaction_count - 1) <= 0 ? this.props.t('postModal.reactionText3', {user: this.props.state.post.post.reacted_by[0][1]}) : this.props.t('postModal.reactionText2', {user: this.props.state.post.post.reacted_by[0][1] ,reactionsCount: `${this.props.state.post.post.reaction_count}` })}
                                                    </span>
                                            }
                                        </p>                                         
                                    </div>
                                    <div className="post-detail__separator"></div>
                                </React.Fragment>    
                                : undefined
                            }

                            {/* Actions */}
                            <div className="post-detail__content__actions">                                
                                <div 
                                    onClick={() => this.props.changeSignUpLoginModal(true)}                   
                                    className="post-detail__actions__action"
                                >
                                    <React.Fragment>
                                    {!this.props.state.post.post.is_reacted &&
                                        <React.Fragment>
                                            <span class="icon-claps-48"></span><span className="post-detail__actions__action__action-text baloa-normal-medium">{this.props.t('post.actions.clap')}</span>                                            
                                        </React.Fragment>
                                    }
                                    
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Clap" &&
                                    <div className="post-detail__actions__action__reaction-green baloa-normal-medium">   
                                        <span class="iconr-clap post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                            <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                            <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                            <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.clap')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Support" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium">   
                                        <span class="iconr-support post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.support')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Its_Funny" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium"> 
                                        <span class="iconr-funny post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.itsfunny')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Red_Card" &&
                                    <div className="post-detail__actions__action__reaction-red baloa-normal-medium"> 
                                        <span class="iconr-red-card post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.redcard')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Sad" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium"> 
                                        <span class="iconr-sad post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>                            
                                        </span>
                                        {this.props.t('postReactions.sad')}
                                    </div>
                                    }
                                    {this.props.state.post.post.is_reacted &&  this.props.state.post.post.reaction_type == "Celebrate" &&
                                    <div className="post-detail__actions__action__reaction-yellow baloa-normal-medium"> 
                                        <span class="iconr-celebrate post-detail__actions__action__reactions__icon">
                                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                            <span class="path13"></span><span class="path14"></span>
                                            
                                        </span>
                                        {this.props.t('postReactions.celebrate')}
                                    </div>
                                    }

                                    </React.Fragment>
                                    
                                </div>
                                
                                {/* Comment */}
                                {/* <div
                                    onClick={() => props.handleOnMessageIconClick(props.post)}
                                    className="post__actions__action"
                                >
                                    <span class="icon-message"></span><span className="post__actions__action__action-text baloa-normal">{this.props.t('post.actions.comment')}</span>
                                </div> */}

                                {/* Share */}
                                <div
                                    id={"post_" + this.props.state.post.post.id}
                                    className="post-detail__actions__action"
                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                >
                                    <span class="icon-share"></span><span className="post-detail__actions__action__action-text baloa-normal-medium">{this.props.t('post.actions.share')}</span>
                                </div>                                
                            </div>                            

                            <div className="post-detail__separator"></div> 
                            {/* New Comment */}
                            
                            <div className="post-detail__content__new-comment__comment">
                           
                                <div className="post-detail__content__new-comment">
                                    <InputWithButtonComponent
                                        className="post-detail__content__new-comment__input"
                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                        placeholder={this.props.t('comment.text')}
                                    >
                                        {this.props.t('postModal.btnPost')}
                                    </InputWithButtonComponent>
                                </div>
                            </div>
                        </div> 
                                               
                        {/* Post Comments */}
                        <div className="post-detail__comments">
                            {/* <p className="post-detail__comments__title baloa-headline-6">{this.props.t('comment.title')}</p> */}

                            {/* Comments List */}
                            <div className="post-detail__comments__container">
                                {this.props.state.post.comments.map((comment, index) =>

                                    <ScrollTrigger
                                        onEnter={() => this.onEnterViewport(index)}
                                        // onExit={ this.onExitViewport }
                                    >
                                        <div className="post-detail__comments__container__comment"
                                            key={comment.id}
                                        >
                                            {/* console.log('comment: ',comment) */}
                                            {/* Author photo */}
                                            <div className={"post-detail__comments__container__comment__photo "}>
                                                                                              
                                                {comment.author.photo &&
                                                    <img src={comment.author.photo} alt="" />
                                                }
                                                {!comment.author.photo &&
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                }                                            
                                            </div>

                                            {/* Comment info */}
                                            <div className="post-detail__comments__container__comment__info">
                                                {/* Author */}
                                                <span className="post-detail__comments__container__comment__info__caption__author baloa-normal-medium">{comment.author?.caption}</span>
                                                {/* Caption */} 
                                                
                                                <div className="post-detail__comments__container__comment__info__caption baloa-normal">                                                        
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: HASHTAG_FORMATTER( comment?.caption )
                                                    }}
                                                    ></div>
                                                </div>

                                                <div className="post-detail__comments__container__comment__info__actions" id={'post-detail__comment__actions_' + comment.id}>

                                                    {/* Comment actions */}
                                                    <div className="post-detail__comments__container__comment__info__action">
                                                        {/* Clap */}
                                                        <div 
                                                            id={"reaction-comment_"+comment.id}
                                                            //onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}                     
                                                            className="post-detail__actions__action"
                                                        >
                                                            <React.Fragment>
                                                            {!comment.is_reacted &&
                                                                <React.Fragment>
                                                                    <span class="icon-claps-48" onClick={() => this.props.changeSignUpLoginModal(true)}></span>
                                                                    {comment.reaction_count > 0 &&
                                                                        <span
                                                                            className={'baloa-table-column'}
                                                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                        >{comment.reaction_count}</span> 
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            
                                                            {comment.is_reacted &&  comment.reaction_type == "Clap" &&
                                                            <div className="post-detail__actions__action__reaction-green baloa-table-column">   
                                                                <span class="iconr-clap post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}>
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                    <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                    <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                    <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Support" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column">   
                                                                <span class="iconr-support post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}} >
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>  
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Its_Funny" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-funny post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}>
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Red_Card" &&
                                                            <div className="post-detail__actions__action__reaction-red baloa-table-column"> 
                                                                <span class="iconr-red-card post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}>
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Sad" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-sad post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}>
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>                            
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }
                                                            {comment.is_reacted &&  comment.reaction_type == "Celebrate" &&
                                                            <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                <span class="iconr-celebrate post-detail__actions__action__comment-reactions__icon" onClick={(event)=>{this.setCommentReaction(event); this.handleOnMenuCommentClick(index)}}>
                                                                    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                    <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                    <span class="path13"></span><span class="path14"></span>
                                                                    
                                                                </span>
                                                                <span
                                                                    onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                >{comment.reaction_count}</span>
                                                            </div>
                                                            }

                                                            </React.Fragment>
                                                            
                                                        </div>
                                                                                                              
                                                        <span className="post-detail__actions__action__action-text baloa-normal">&nbsp;&bull;</span>
                                                        {/* answer button */}
                                                        <div className="post-detail__comments__container__comment__info__action__reply-comment__icon baloa-names"
                                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                                        >
                                                            {/* <span class="icon-message post-detail__comments__container__reply-comment-icon"></span> */}
                                                            {this.props.t('post.answer')}

                                                        </div>
                                                    </div>
                                                    
                                                    {/* comment resume */}
                                                    {comment.comment_count > 0 &&
                                                        <div className={"post-detail__comments__container__comment__info__resume baloa-names"}
                                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                                        >
                                                            <div className={"post-detail__comments__container__comment__info__resume__line-answers"}><hr/></div> {this.props.t('post.answers',{ _answers_count: comment.comment_count})}
                                                        </div>
                                                    }

                                                    {/* New comment of Comment */}
                                                    <div className={"post-detail__content__reply-comment " + (this.state.show_reply_coment[index] ? '' : 'hide')}>
                                                        <InputWithButtonComponent
                                                            className="post-detail__content__reply-comment__input"
                                                            onClick={() => this.props.changeSignUpLoginModal(true)}
                                                            placeholder={this.props.t('comment.response')}
                                                        >
                                                            {this.props.t('postModal.btnPost')}
                                                        </InputWithButtonComponent>
                                                    </div>
                                                </div>
                                                
                                                

                                                {/*comment of comment*/}
                                                {this.props.state.post.reply_comments?.filter(fcomment => fcomment.id_comment == comment.id)?.map(replycomment => (
                                                        replycomment.reply_comments != null? (replycomment.reply_comments?.map(( rcomment, rindex ) =>
                                                            <React.Fragment>
                                                            <div className="post-detail__comments__container__comment"
                                                                key={rcomment.id}
                                                            >
                                                                {/* Author photo */}
                                                                <div className={"post-detail__comments__container__comment__photo "}>
                                                                    {rcomment.author?.photo &&
                                                                        <img src={rcomment.author?.photo} alt="" />
                                                                    }
                                                                    {!rcomment.author?.photo &&
                                                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                                    }

                                                                </div>

                                                                {/* Comment info */}
                                                                <div className="post-detail__comments__container__comment__info">

                                                                    {/* Author name }

                                                                    <p className="post-detail__comments__container__reply-comment__info__name">
                                                                        { rcomments.author?.first_name } { rcomments.author?.last_name }
                                                                    </p>*/}
                                                                    <span className="post-detail__comments__container__reply-comment__info__caption__author baloa-normal-medium">{rcomment.author?.caption}</span>
                                                                    {/* Caption */}
                                                                    <div className="post-detail__comments__container__reply-comment__info__caption baloa-normal">                                                                        
                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: HASHTAG_FORMATTER( rcomment.caption )
                                                                        }}></div>
                                                                    </div>

                                                                    {/* comment of comment actions */}            
                                                                    <div className="post-detail__comments__container__comment__info__actions" id={'post-detail__rcomment__actions_' + rcomment.id} >

                                                                        {/* Comments of comments reactions */}
                                                                        <div className="post-detail__comments__container__comment__info__action">
                                                                            <div 
                                                                                id={"reaction-comment_"+rcomment.id}
                                                                                //onClick={(event)=>{this.setRCommentReaction(event); this.handleOnMenuRCommentClick(rindex)}}                     
                                                                                className="post-detail__actions__action"
                                                                            >
                                                                                <React.Fragment>
                                                                                {!rcomment.is_reacted &&
                                                                                    <React.Fragment>
                                                                                        <span class="icon-claps-48" onClick={() => this.props.changeSignUpLoginModal(true)}></span>
                                                                                        {rcomment.reaction_count > 0 &&
                                                                                            <span
                                                                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                            >{rcomment.reaction_count}</span> 
                                                                                        }
                                                                                    </React.Fragment>
                                                                                }
                                                                                
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Clap" &&
                                                                                <div className="post-detail__actions__action__reaction-green baloa-table-column">   
                                                                                    <span class="iconr-clap post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span>
                                                                                        <span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>
                                                                                        <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span>
                                                                                        <span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Support" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column">   
                                                                                    <span class="iconr-support post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Its_Funny" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-funny post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Red_Card" &&
                                                                                <div className="post-detail__actions__action__reaction-red baloa-table-column"> 
                                                                                    <span class="iconr-red-card post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Sad" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-sad post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>                            
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }
                                                                                {rcomment.is_reacted &&  rcomment.reaction_type == "Celebrate" &&
                                                                                <div className="post-detail__actions__action__reaction-yellow baloa-table-column"> 
                                                                                    <span class="iconr-celebrate post-detail__actions__action__comment-reactions__icon" onClick={() => this.props.changeSignUpLoginModal(true)}>
                                                                                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
                                                                                        <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>
                                                                                        <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>
                                                                                        <span class="path13"></span><span class="path14"></span>
                                                                                        
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                    >{rcomment.reaction_count}</span>
                                                                                </div>
                                                                                }

                                                                                </React.Fragment>
                                                                                
                                                                            </div>
                                                                            
                                                                            <span className="post-detail__actions__action__action-text baloa-normal">&nbsp;&bull;</span>
                                                                            {/* Answer coment of comment */}
                                                                            <div className="post-detail__comments__container__comment__info__action__reply-comment__icon baloa-names"
                                                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                            >
                                                                                {/* <span class="post-detail__comments__container__reply-comment-icon baloa-names"></span> */}
                                                                                {this.props.t('post.answer')}

                                                                            </div>
                                                                        </div>
                                                                        
                                                                        {rcomment.comment_count > 0 &&
                                                                            <div className={"post-detail__comments__container__comment__info__resume baloa-names"}
                                                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                            >
                                                                                <div className={"post-detail__comments__container__comment__info__resume__line-answers"}><hr/></div>{this.props.t('post.answers',{ _answers_count: rcomment.comment_count})}
                                                                            </div>
                                                                        }


                                                                        {/* New comment of Comment */}
                                                                        <div className={"post-detail__content__reply-comment " + (this.state.show_reply_coment2[rindex] ? '' : 'hide')}>
                                                                            <InputWithButtonComponent
                                                                                className="post-detail__content__reply-comment__input"
                                                                                onClick={() => this.props.changeSignUpLoginModal(true)}
                                                                                placeholder={this.props.t('comment.response')}
                                                                                value={rcomment.author.caption}
                                                                            >                                                                                
                                                                                {this.props.t('postModal.btnPost')}
                                                                            </InputWithButtonComponent>
                                                                        </div>
                                                                        {/*comment of comment*/}
                                                                        {this.props.state.post.reply_comments?.filter(fcommentcomment => fcommentcomment.id_comment == rcomment.id).map(replycommentcomment => (
                                                                                replycommentcomment.reply_comments != null? (replycommentcomment.reply_comments.map(rcommentcoment =>
                                                                                    <React.Fragment>
                                                                                    <div className="post-detail__comments__container__comment-of-comment"
                                                                                        key={rcommentcoment.id}
                                                                                    >
                                                                                        {/* Author photo */}
                                                                                        <div className={"post-detail__comments__container__comment__photo"}>
                                                                                            {rcommentcoment.author?.photo &&
                                                                                                <img src={rcommentcoment.author?.photo} alt="" />
                                                                                            }
                                                                                            {!rcommentcoment.author?.photo &&
                                                                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                                                            }                                                                                    
                                                                                        </div>

                                                                                        {/* Comment info */}
                                                                                        <div className="post-detail__comments__container__comment__info">

                                                                                            {/* Author name }

                                                                                            <p className="post-detail__comments__container__reply-comment__info__name">
                                                                                                { rcomments.author?.first_name } { rcomments.author?.last_name }
                                                                                            </p>*/}
                                                                                            <span className="post-detail__comments__container__reply-comment__info__caption__author baloa-normal-medium">{rcommentcoment.author?.caption}</span>
                                                                                            {/* Caption */}
                                                                                            <div className="post-detail__comments__container__reply-comment__info__caption baloa-normal">                                                                        
                                                                                                <div dangerouslySetInnerHTML={{
                                                                                                    __html: HASHTAG_FORMATTER( rcommentcoment.caption )
                                                                                                }}></div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    </React.Fragment>
                                                                                )) 
                                                                                :
                                                                                undefined
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                
                                                            </div>

                                                            </React.Fragment>
                                                            )) 
                                                            :
                                                            undefined
                                                        ))                                                    
                                                                                                            
                                                }

                                            </div>
                                        </div>
                                    </ScrollTrigger>

                                )}
                            </div>
                        </div>

                    </div>

                    {/* Right side */}
                    <div className="profile__right-container">
                    </div>

                </div>

            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPostDetailById: (post_id) => {
            dispatch(loadPostDetailById(post_id))
        },
        loadPostCommentsById: (post_id) => {
            dispatch(loadPostCommentsById(post_id))
        },
        /* eslint-disable */
        loadReplyCommentsPostById: (comments) => {
            comments.map(comment => {
                dispatch(loadReplyPostCommentsById(comment.id))
            })
        },
        /* eslint-enable */
        commentPostById: (post_id, comment, pagename, page_id) => {
            dispatch(commentPostById(post_id, comment, pagename, page_id))
        },
        replyCommentPostById: (post_id, comment_id, comment) => {
            dispatch(replyCommentPostById(post_id, comment_id, comment))
        },
        loadReplyPostCommentsById: (comment_id) => {
            dispatch(loadReplyPostCommentsById(comment_id))
        },
        clapPost: (post) => {
            dispatch(clapPost(post))
        },
        deleteComment: (post_id, comment) => {
            dispatch(deleteComment(post_id, comment))
        },
        clapCommentById: (comment_id) => {
            dispatch(clapCommentById(comment_id))
        },
        loadPostCommentsNext: (next_page) => {
            dispatch(loadPostCommentsNext(next_page))
        },
        // clapComment: ( comment ) => {
        //     return dispatch( clapComment( comment ) )
        // },
        overwriteInterceptorAction: ( new_state ) => {
            return dispatch( overwriteInterceptorAction( new_state ) );
        },
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
    }
}



export default compose(
    withTranslation("post"),
    connect(mapStateToProps, mapDispatchToProps)
)(PostDetailUnauthenticatedView);

//export default connect(mapStateToProps, mapDispatchToProps)(PostDetailView);