// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-modal__container {
    padding: 16px;
}

.block-modal__accept {
    width: 100%;
    margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/posts/components/block-modal/BlockModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".block-modal__container {\n    padding: 16px;\n}\n\n.block-modal__accept {\n    width: 100%;\n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
