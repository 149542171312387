// React
import React, { Component } from 'react'
import { connect } from 'react-redux';

// Actions
import {
  loadSearch,
  loadSearchFilter,
  loadSearchBox
} from 'app/search/actions/SearchActions';
import { loadHomeProfileSuggestions } from 'app/home/actions/HomeActions';
import { changeSignUpLoginModal, changeClickType } from 'app/authentication/actions/LoginActionsCreators';

// Components
import SearchResultComponent from 'app/search/components/search-result/SearchResultComponent';
import SearchMenuComponent from 'app/search/components/search-menu/SearchMenuComponent';
//import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './SearchView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';


class SearchUnauthenticatedView extends Component {
  constructor(props) {
    super(props);
    this.filter = this.props.match.params.filter;
    this.keyword = this.props.match.params.keyword;
  }

  componentDidMount() {
    this.props.loadHomeProfileSuggestions();
    //this.props.loadSearchBox(this.keyword);
    this.filter ? this.props.loadSearchFilter(this.keyword, this.filter) : this.props.loadSearch(this.keyword);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.filter !== prevProps.match.params.filter) {
      this.props.loadSearchFilter(this.props.match.params.keyword, this.props.match.params.filter);
    }
    if (this.props.match.params.keyword !== prevProps.match.params.keyword && !this.props.match.params.filter ) {
      this.props.loadSearch(this.props.match.params.keyword);
    }
  }

  handleHomeAnonModal = (modal_state, click_type) => {
    this.props.changeSignUpLoginModal(modal_state);
    this.props.changeClickType(click_type);
  }

  handleSearchFilter(searchUrl) {
    this.props.history.push(searchUrl);
  }

  handleSearchLinkTo=(page_type, keyword)=>{
      switch(page_type){
          case 'profile':
              this.props.history.push(`/players?search=${keyword}`);
              break;        
          case 'page':
              this.props.history.push(`/pages?search=${keyword}`);
              break;  
          case 'tournament':
              this.props.history.push(`/tournament/hub/search?search=${keyword}`);
              break;
          //no default
      }
  }

  render() {
    return (
      <div className={`search ${(isMobile && !this.props.match.params.filter)? 'search-mobile' : ''}`}>
        <div className="search__left-container">
          <SearchMenuComponent 
            keyword={this.props.match.params.keyword}
            handleSearchFilter={(filterUrl)=>{ this.handleSearchFilter(filterUrl) }}
            filter={this.props.match.params.filter}
          />
        </div>
        <div className="search__center-container">
          <SearchResultComponent
            keyword={this.props.match.params.keyword}
            filter={this.props.match.params.filter}
            search_results={this.props.state.search.search_results}
            search_path={this.props.location?.pathname}
            //handleFollowProfile={(profile) => { this.props.followProfile(profile) }}
            //handleFollowPage={(page) => { this.props.followPage(page) }}
            handleFollowProfile= { () => this.handleHomeAnonModal(true, 'homeAnonFollowProfile') }                            
            handleFollowPage= { () => this.handleHomeAnonModal(true, '') }   
            handleSearchLinkTo={(page_type)=>{ this.handleSearchLinkTo(page_type, this.props.match.params.keyword ) }}
            fetchMoreData={()=>{ this.props.loadSearchFilter(this.keyword, this.filter, this.props.state.search.search_results_next) }}
          />
        </div>
        <div className="search__right-container">          
          {/* <ProfileSuggestionsComponent
            title={this.props.t('whoFollow')}
            profiles={this.props.state.home.profile_suggestions.slice(0, 4)}
            className="home__right-container__profile-suggestions sm"
            //handleFollow={(profile) => { this.props.followProfile(profile) }}
            handleFollow = {() => { this.handleHomeAnonModal(true, 'homeAnonFollowProfile') } }
            loading_profile_suggestions={this.props.state.home.loading_profile_suggestions}
            go_link={this.props.t('seeAll')}
            //handleGoLink={() => { this.props.changeProfileSuggestionsModalStateAction(true) }}
            handleGoLink={()=>this.handleHomeAnonModal(true, '')}
            handleAnonSuggestions={() => this.handleHomeAnonModal( true, 'homeAnonAuthor' )}
          /> */}
          {/* <ProfileSuggestionsModalComponent
            onClick={() => { }}
            isOpen={this.props.state.home.modals.profile_suggestions_is_open}
            onClose={() => { this.props.changeProfileSuggestionsModalStateAction(false) }}
            title={this.props.t('whoFollow')}
            profiles={this.props.state.home.profile_suggestions}
            //className = "home__right-container__profile-suggestions sm"
            handleFollow={(profile) => { this.props.followProfile(profile) }}
          /> */}
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSearch: (keyword) => {
      dispatch(loadSearch(keyword))
    },
    loadHomeProfileSuggestions: () => {
      return dispatch(loadHomeProfileSuggestions())
    },
    loadSearchFilter: (keyword, filter) => {
      dispatch(loadSearchFilter(keyword, filter))
    },
    loadSearchBox: (keyword) => {
      dispatch(loadSearchBox(keyword))
    },
    /* loadProfile: (username) => {
      dispatch(loadProfile(username))
    }, */
    /* loadProfilePosts: (username) => {
      dispatch(loadProfilePosts(username))
    }, */
    /* followProfile: ( profile ) => {
      return dispatch( followProfile( profile ) )
    },
    followPage: ( page ) => {
      return dispatch( followPage( page ) )
    }, */
    /* changeProfileSuggestionsModalStateAction: (new_modal_state) => {
      dispatch( changeProfileSuggestionsModalStateAction(new_modal_state))
    }, */
    changeSignUpLoginModal: ( new_state ) => {
      return dispatch( changeSignUpLoginModal( new_state ) )
    },
    changeClickType: ( click_type ) => {
        return dispatch( changeClickType( click_type ) );
    }
  }
}

export default compose(
  withTranslation("search"),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchUnauthenticatedView);
