// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-page-create_newPage_form_input {
    display: flex;
}

.modal-page-create-information-form {
    display: flex;
}

.modal-page-create-information-form_buttons {
    display: grid;
    justify-content: end;
    margin-right: var(--space-x2);
}

.modal-page-create_newPage_form_input .modal-page-create-information-form_input {
    /* margin-left: var(--space); */
    width: 100%;
    margin-right: 12px;
    margin-left: 66px;
}

.modal-page-create_newPage_form_input .modal-page-create-information-form_input .input-field-input,
.modal-page-create_newPage_form_input .modal-page-create-information-form_input_pagename .input-field-input {
    background-color: var(--white-color);
    border: 1px solid #e7e7e7;
}

.modal-page-create_newPage_form_input .modal-page-create-information-form_input_pagename {
    width: 100%;
    margin: var(--space-x3) 66px var(--space-x3) 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/components/modal-page-create-information/ModalPageCreateInformation.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,+BAA+B;IAC/B,WAAW;IACX,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,iDAAiD;AACrD","sourcesContent":[".modal-page-create_newPage_form_input {\n    display: flex;\n}\n\n.modal-page-create-information-form {\n    display: flex;\n}\n\n.modal-page-create-information-form_buttons {\n    display: grid;\n    justify-content: end;\n    margin-right: var(--space-x2);\n}\n\n.modal-page-create_newPage_form_input .modal-page-create-information-form_input {\n    /* margin-left: var(--space); */\n    width: 100%;\n    margin-right: 12px;\n    margin-left: 66px;\n}\n\n.modal-page-create_newPage_form_input .modal-page-create-information-form_input .input-field-input,\n.modal-page-create_newPage_form_input .modal-page-create-information-form_input_pagename .input-field-input {\n    background-color: var(--white-color);\n    border: 1px solid #e7e7e7;\n}\n\n.modal-page-create_newPage_form_input .modal-page-create-information-form_input_pagename {\n    width: 100%;\n    margin: var(--space-x3) 66px var(--space-x3) 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
