// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claps{}

.claps__content{
    margin-left:  var(--space);
    margin-right:  var(--space);
    margin-top: 1%;
}

.claps__content__box{
    display: flex;
    padding: 5px;
    align-items: center;
    margin-bottom: 2%;
}

.claps__content__box__photo {
    width: var(--space-x3);
    height: var(--space-x3);
    min-width: var(--space-x2);
    min-height: var(--space-x2);
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-right: var(--space);
    background: var(--primary-light-color);
    padding: var(--space-quarter);
    cursor: pointer;
}

.claps__content__box__photo img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.claps__content__box__info-username {
    max-height: var(--space-x2);
    flex-grow: 1;
    cursor: pointer;
}

.claps__content__box__button {
    align-items: center;
    
}

.claps__bottom{
    background-color: var(--white-color);
    height: var(--space-x2);

}`, "",{"version":3,"sources":["webpack://./src/app/posts/components/claps-modal/ClapsModalComponent.styles.css"],"names":[],"mappings":"AAAA,OAAO;;AAEP;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;IAC1B,sCAAsC;IACtC,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;AACZ;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;;AAE3B","sourcesContent":[".claps{}\n\n.claps__content{\n    margin-left:  var(--space);\n    margin-right:  var(--space);\n    margin-top: 1%;\n}\n\n.claps__content__box{\n    display: flex;\n    padding: 5px;\n    align-items: center;\n    margin-bottom: 2%;\n}\n\n.claps__content__box__photo {\n    width: var(--space-x3);\n    height: var(--space-x3);\n    min-width: var(--space-x2);\n    min-height: var(--space-x2);\n    position: relative;\n    border-radius: 4px;\n    overflow: hidden;\n    margin-right: var(--space);\n    background: var(--primary-light-color);\n    padding: var(--space-quarter);\n    cursor: pointer;\n}\n\n.claps__content__box__photo img {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}\n\n.claps__content__box__info-username {\n    max-height: var(--space-x2);\n    flex-grow: 1;\n    cursor: pointer;\n}\n\n.claps__content__box__button {\n    align-items: center;\n    \n}\n\n.claps__bottom{\n    background-color: var(--white-color);\n    height: var(--space-x2);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
