import React from "react";

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

import "./StaffInvitationModalComponent.styles.css";

//i18
import { withTranslation } from 'react-i18next';

function StaffInvitationModalComponent(props){
    return (
        <ModalComponent
            isOpen={props.isOpen}
            //onClose={props.onClose}
            title=""            
            className='staff-invitation-modal__container'
        >
        <div className ={"staff-invitation-modal__content"}>
            
            <div className="staff-invitation-modal__tournament-info baloa-normal">
                {props.tournament_logo?
                    <img src={props.tournament_logo} alt={props.tournament_name}/>
                :
                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                }
                {props.staff_profile === 'Referee' && 
                    props.t('tournamentStaff.modalsInvitation.refereeInvitationMessage')
                }
                {props.staff_profile === 'Administrator' && 
                    props.t('tournamentStaff.modalsInvitation.administratorInvitationMessage')
                }
                {props.staff_profile === 'Assigner' && 
                    props.t('tournamentStaff.modalsInvitation.assignerInvitationMessage')
                }
                <strong>{props.tournament_name}</strong>
            </div>
            
            <div className="staff-invitation-modal__buttons">
                <PrimaryButtonComponent
                    input_type=''
                    className='staff-invitation-modal__buttons-button small'
                    onClick= {props.handleStaffInscriptionButton}
                >
                    <span>{props.t('tournamentStaff.modalsInvitation.acceptInvitationBtn')}</span>
                </PrimaryButtonComponent> 
                <div className="baloa-table-column" onClick={()=>{props.handleOnRejectInscription()}}>{props.t('tournamentStaff.modalsInvitation.rejectInvitationLink')}</div>
            </div>
        </div>
    </ModalComponent>
    )
}

export default withTranslation('tournament')(StaffInvitationModalComponent);