// React
import React from 'react'

// Styles
import './ProfileBasicInfoComponent.style.css'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';

// Functions
import convertBirthdayToAge from 'shared/functions/ConvertBirthdayToAge';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileBasicInfoComponent( props ) {

    return (

        <Card1Component 
            className={ "profile-basic-info " + ( props.className ? props.className : '' ) } 
            title={props.t('data.title')}
            button={ props.is_owner && ( <span className='hubicon-edit' /> ) }
            onButtonClick={ props.onNewButton }
        >
            <div className="profile-basic-info__content">
                {props.profile.profile_type !== 'fanatic' &&
                    <div className="profile-basic-info__content-col profile-basic-info__content__age">
                        <div className="baloa-subtitle-2">{props.profile.user?.birthday ? String(convertBirthdayToAge( props.profile?.user?.birthday )) + ' ' + props.t('data.textYears') : '-'}</div>
                        <div className="baloa-normal-medium">{props.profile.user?.birthday ? moment(props.profile.user?.birthday).calendar() : '-'}</div>
                    </div>
                }
                {props.profile.profile_type === 'player' &&
                    <div className="profile-basic-info__content-col profile-basic-info__content__weight">
                        <div className='profile-basic-info__content__weight-label'>
                            <div className="baloa-subtitle-2">{props.profile.user?.weight ? props.profile.user?.weight : '-'}</div>
                            <div className="baloa-subtitle-2">{props.profile.user?.weight_unit ? props.profile.user?.weight_unit : ''}</div>
                        </div>                    
                        <div className="baloa-normal-medium">{props.t('playerCard.weight')}</div>
                    </div>
                }
                {props.profile.profile_type === 'player' &&
                    <div className="profile-basic-info__content-col profile-basic-info__content__height">
                        <div className='profile-basic-info__content__height-label'>
                            <div className="baloa-subtitle-2">{props.profile.user?.height ? props.profile.user?.height : '-'}</div>
                            <div className="baloa-subtitle-2">{props.profile.user?.height_unit ? props.profile.user?.height_unit : ''}</div>
                        </div>
                        <div className="baloa-normal-medium">{props.t('playerCard.height')}</div>
                    </div>
                }
                <div className="profile-basic-info__content-col profile-basic-info__content__country">
                    <div className="profile-basic-info__content__country-img">
                        { props.profile?.nationality?.flag_icon
                            ? <img src={props.profile?.nationality?.flag_icon } alt=""/>
                            : props.profile?.nationality?
                                <span className='icon-ball' color={getRandonIconBallColor()} />
                                : ''
                        }
                        <div className="baloa-subtitle-2">{props.profile?.nationality? props.profile?.nationality?.name : '-'}</div>
                    </div>
                    <div className="baloa-normal-medium">{props.t('data.textNationality')}</div>
                </div>
                <div className="profile-basic-info__content-col profile-basic-info__content__country">
                    <div className="profile-basic-info__content__country-img">
                        {/* <div className="baloa-subtitle-2"><p className="baloa-subtitle-2">{props.profile?.location? `${props.profile?.location?.name}, ${props.profile?.location?.country_name}` : '-'}</p></div> */}
                        <div className="baloa-subtitle-2">{props.profile?.location? <p className="baloa-subtitle-2">{`${props.profile?.location?.name}, `} <span className='prueb44'>{`${props.profile?.location?.country_name}`}</span></p> : '-'}</div>
                    </div>
                    <div className="baloa-normal-medium">{props.t('data.textLocation')}</div>
                </div>
            </div>

        </Card1Component>
        
    )

}

export default withTranslation('profile')(ProfileBasicInfoComponent);

