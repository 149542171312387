// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-new-team-form__form{
    margin: 0 var(--space);
    padding-top: var(--space-and-half);
}

.modal-new-team_form__fields{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modal-new-team-form__form_left{
    margin-right: var(--space-x2);
}

.form-group-new-team_form-buttons{
    display: grid;
    justify-content: end;
    margin-right: var(--space-x2);
}

.page-new-team-form__mobile-gender .MuiInput-underline.select-field-input:before, 
.page-new-team-form__mobile-name .MuiInput-underline.select-field-input:before {
    border: none;
    transition: none;
}

.page-new-team-form__mobile-container {
    margin: 0 var(--space);
}

.select-field.page-new-team-form__mobile-gender {
    margin: 0 0 var(--space-x3) 0;
}

.page-new-team-form__required-img.baloa-username {
    color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/components/page-new-team-form/PageNewTeamFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;;IAEI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".modal-new-team-form__form{\n    margin: 0 var(--space);\n    padding-top: var(--space-and-half);\n}\n\n.modal-new-team_form__fields{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.modal-new-team-form__form_left{\n    margin-right: var(--space-x2);\n}\n\n.form-group-new-team_form-buttons{\n    display: grid;\n    justify-content: end;\n    margin-right: var(--space-x2);\n}\n\n.page-new-team-form__mobile-gender .MuiInput-underline.select-field-input:before, \n.page-new-team-form__mobile-name .MuiInput-underline.select-field-input:before {\n    border: none;\n    transition: none;\n}\n\n.page-new-team-form__mobile-container {\n    margin: 0 var(--space);\n}\n\n.select-field.page-new-team-form__mobile-gender {\n    margin: 0 0 var(--space-x3) 0;\n}\n\n.page-new-team-form__required-img.baloa-username {\n    color: var(--error-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
