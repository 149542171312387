// React
import React from 'react';

//import CurrencyFormat from 'react-currency-format';

// Styles
import "./TournamentPlayersInformationComponent.styles.css";

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from "react-i18next";

// Globals
import { api_url } from "config/GlobalData";

// Material
import { Checkbox } from '@mui/material';

// Components
import TextField from '@mui/material/TextField';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Assets
import loading_baloa from 'assets/images/loading-baloa.gif';

function TournamentPlayersInformationComponent (props) {
    
    const [ tournamentTeamPlayers, setTournamentTeamPlayers ] = React.useState([]);
    const [ filterState, setFilterState ] = React.useState(undefined);
    const [ isBoxOpen, setBoxOpen] = React.useState(false);
    const [ boxPosition, setBoxPosition] = React.useState();
    const [ isStaffBoxOpen, setStaffBoxOpen] = React.useState(false);
    const [ boxStaffPosition, setStaffBoxPosition] = React.useState();
    const [ invitationBoxOpen, setInvitationBoxOpen ]= React.useState();
    const [ invitationBoxPosition, setInvitationBoxPosition ]= React.useState();
    const [ checkAll, setCheckAll ] = React.useState(false);
    const [ checkedArray, setCheckedArray ] = React.useState([]);
    const [ tournamentTeamStaff, setTournamentTeamStaff ] = React.useState([]);

    React.useEffect(() => {
        setTournamentTeamPlayers(props.tournament.tournament_team_players?.results);
    }, [props.tournament.tournament_team_players?.results]);

    React.useEffect(() => {
        setTournamentTeamStaff(props.tournament.list_coaches_staff);
    }, [props.tournament.list_coaches_staff]);
    
    const handleTeamState = (state) => {
        setCheckAll(false);
        setCheckedArray([]);
        if(state === "AllStates") {
            setFilterState(undefined);
        } else {
            setFilterState(state);
        }
    }

    const handleSearchTeams = (value) => {
        if(value.length < 2){
            setTournamentTeamPlayers(props.tournament.tournament_team_players?.results);
        } else {
            let val = value.toLowerCase();
            let players_array = props.tournament.tournament_team_players?.results;
            let players_result = players_array?.filter( (v) => v.full_name?.toLowerCase().includes(val) || v.username?.toLowerCase().includes(val) );
            setTournamentTeamPlayers(players_result);
        }
    }    

    const handleBoxOpen = (index) => {
        if (!isBoxOpen){
            setBoxPosition(index);
            setBoxOpen(true);
        } else {
            setBoxPosition();
            setBoxOpen(false);
        }
    }

    const handleStaffBoxOpen = (index) => {
        if (!isStaffBoxOpen){
            setStaffBoxPosition(index);
            setStaffBoxOpen(true);
        } else {
            setStaffBoxPosition();
            setStaffBoxOpen(false);
        }
    }

    const handlePlayerInscription = (player_id, player_state, tournament_team_id) => {
        props.handlePlayerInscription(player_id, player_state, tournament_team_id);
        setBoxPosition();
        setBoxOpen(false);
    }

    const handleTransferPlayer = (player) => {
        props.handleTransferPlayer(player);
        setBoxPosition();
        setBoxOpen(false);
    }

    const handleInvitationBoxOpen = (index) => {
        if (!invitationBoxOpen){
            setInvitationBoxPosition(index)
            setInvitationBoxOpen(true)
        } else {
            setInvitationBoxPosition()
            setInvitationBoxOpen(false)
        }
    }

    const handlePlayerCheck = (e) => {
        if(e.target.checked){
            setCheckedArray(current => [...current, e.target.value]);
        }else{
            setCheckedArray((current) =>
                current.filter((player) => player !== e.target.value)
            );            
        }
        
        const timeout = setTimeout(() => {
            setCheckAll(false);
        }, 500);
        return () => clearTimeout(timeout);
        
    }

    const handleCheckAll = ( e ) => {
        setCheckAll(e.target.checked);
        if(e.target.checked){
            setCheckedArray([]);
            tournamentTeamPlayers?.filter( filterState?  filterState === 'Pending'? p => (p.player_state == filterState || p.player_state == 'Late_Register') : p => p.player_state == filterState :  p => (p.player_state != null && p.player_state != 'Cancelled')).map((player, index) => (
                setCheckedArray(current => [...current, player.tournament_player_id])
            ));
        }else{
            setCheckedArray([]);
        }
    }

    const handleEnrollRejectMultiple = (state) => {
        let player_states = [];
        checkedArray.map(player_id =>(
            player_states.push({'tournament_player_id': player_id, 'state': state })
        ))
        if(state === "Enrolled"){
            props.handleEnrollRejectMultiple(props.team.tournament_team_id, player_states, 'Enrolled|Pending|Late_Register');
        }else{
            props.handleRejectMultiplePlayers( player_states );
        }
        setCheckAll(false);
        setCheckedArray([]);
    }

    const handleTeamPlayersList = () => (
         tournamentTeamPlayers?.filter( filterState? filterState === 'Pending'? p => (p.player_state == filterState || p.player_state == 'Late_Register'): p => p.player_state == filterState :  p => (p.player_state != null && p.player_state != 'Cancelled')).map((player, index) => (
            <div key={index} className={`tournament-players-information-setup__team ${player.player_state === 'Register'? 'is-register': ''}`}>   
                <Checkbox
                    key={player.id}
                    edge="end"
                    id={`player-chk-${index}` }
                    name={"player-chk[" + index +"]"}
                    inputProps={{ 'aria-labelledby': player.id }}
                    disabled={false}
                    className="tournament-players-information__checkbox"
                    checked={ (checkAll || checkedArray.some(ply => ply === player.tournament_player_id))}
                    onChange={ (e) => {handlePlayerCheck(e)} }  
                    value={player.tournament_player_id} 
                    color='secondary'
                />                                         
                <div className='tournament-players-information tournament-players-information-setup__team-data'>
                    { player.profile_photo
                        ?
                        <img alt="" src={player.profile_photo} />
                        :
                        <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                    }
                
                    <div className='tournament-players-information-player'>
                        <div className='baloa-table-column'>{player.full_name}</div>
                        <div className='baloa-username'>{`@${player.username}`}</div>
                    </div>
                </div>
                { player.has_player_form && player.form_id !== null 
                    ? 
                    <div className='tournament-players-information tournament-players-information-btn-actions__view-form' onClick={()=>{props.loadInfoFormModal(player.form_id)}}>
                        <span className='hubicon-view_form' />
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.viewFormLabel')}</div>
                    </div>
                    : 
                    <div className='tournament-players-information tournament-players-information-btn-actions__undone'>
                        <span className='tournament-players-information__circle' />
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.undoneLabel')}</div>
                    </div>
                }
                { player.player_has_paid && player.payment_info !== null 
                    ? 
                    <div className='tournament-players-information tournament-players-information-btn-actions__payment-info' onClick={ () => props.handleTeamPlayerPaymentInfo(player.payment_info, player.currency_payment) } >
                        <span className='hubicon-payment_info' />
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.paymentInfo')}</div>
                    </div>
                    : 
                    <div className='tournament-players-information tournament-players-information-btn-actions__undone'>
                        <span className='tournament-players-information__circle' />
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.undoneLabel')}</div>
                    </div>                            
                }    
                <div className='tournament-players-information tournament-players-information__profile-stats__state'>
                    { player.unlocked_stats ? <span className='hubicon-lock_open' /> : <span className='hubicon-lock_fill' />  }
                    <div className='baloa-username'>
                        {player.unlocked_stats
                            ? props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.infoPlayerProfileStatsUnlockedLabel')
                            : props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.infoPlayerProfileStatsLockedLabel') }
                    </div>
                </div>        
                <div className='tournament-players-information tournament-players-information_options-container'>
                    <div className='tournament-players-information__label-state__container' >
                        { player.player_state === "Enrolled" && <div className="tournament-players-information__enrolled baloa-table-column" >{props.t('options.tournaments.setupTournamentInvitationInscription.enrolledPlayer')}</div>}
                        { (player.player_state === "Pending" || player.player_state === "Late_Register") && <div className="tournament-players-information__pending baloa-table-column" >{props.t('options.tournaments.setupTournamentInvitationInscription.pendingPlayer')}</div>}
                    </div>
                    <div className='tournament-players-information-options'>
                        { props.team.team_state !== "Rejected" && props.tournament.state !== 'Finalized' &&
                            <span className="icon-options" onClick={()=>{ handleBoxOpen(index) }}/>
                        }
                        <div className={`tournament-players-information_options-box ${ index === boxPosition ? "active" : ""}`} >
                            { player.player_state === "Enrolled" &&
                                <div className='tournament-players-information_options-box__enrolled-container'>
                                    <div className='tournament-players-information_options-box__reject-inscription' onClick={()=>{handlePlayerInscription(player.id, 'Pending', player.tournament_team_id)}}>
                                        <div className='baloa-names'>{props.t('options.tournaments.setupTournamentInvitationInscription.cancelEnrollment')}</div>
                                        <div className='baloa-username'>{props.t('options.tournaments.setupTournamentInvitationInscription.stateTranfer')}</div>
                                    </div>
                                    { props.team.team_state === 'Enrolled' &&
                                        <div className='tournament-players-information_options-box__transfer-player' onClick={()=>{handleTransferPlayer(player)}} >
                                            <div className='baloa-names'>{props.t('options.tournaments.setupTournamentInvitationInscription.transferTeam')}</div>
                                            <div className='baloa-username'>{props.t('options.tournaments.setupTournamentInvitationInscription.tranferPlayer')}</div>
                                        </div>
                                    }
                                    {player.form_state === 'Done' && props.tournament?.carnet_id_template?.caption === 'CARD_ID_FORM' &&                                        
                                        <div className='tournament-players-information_options-box__transfer-player' onClick={()=>{ handleDownloadPlayerCardId(player.tournament_player_id) }} >
                                            <div className='baloa-names'>{props.t('options.tournaments.cardIds.playerOption.subTitle')}</div>
                                            <div className='baloa-username'>{props.t('options.tournaments.cardIds.playerOption.description')}</div>
                                        </div>
                                    }
                                </div>                                
                            }
                            { (player.player_state === "Pending" || player.player_state === "Late_Register") &&
                                <div className='tournament-players-information_options-box__pending-container' onClick={() => { handlePlayerInscription(player.id, 'Enrolled', player.tournament_team_id) } }>
                                    <div className='baloa-names'>{props.t('options.tournaments.setupTournamentInvitationInscription.enrollmentPlayer')}</div>
                                    <div className='baloa-username'>{props.t('options.tournaments.setupTournamentInvitationInscription.enrollmentLabel')}</div>
                                </div>                                
                            }
                        </div>
                    </div>
                </div>
            </div> 
        ))
    )

    const handleDownloadPlayerCardId = (tournament_player_id) => {
        props.handleOnDownloadPlayerCardId(tournament_player_id)
        setBoxPosition();
        setBoxOpen(false);
    }

    const handleDownloadStaffCardId = (team_profile_id, tournament_team_id) => {
        props.handleOnDownloadStaffCardId(team_profile_id, tournament_team_id)
        setStaffBoxPosition();
        setStaffBoxOpen(false);
    }    

    return(
        <React.Fragment>
            <div className="tournament-players-information__back-action-container">
                <span className="icon-arrow-left baloa-subtitle-1" onClick={() => {props.handleBackAction()} } />
                <div className="baloa-subtitle-1">{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.backTour')}</div>
            </div>
            {props.full_response_team_members 
                ? <div className='tournament-players-information__container'>                
                <div className='tournament-players-information__players-team__team-info'>                            
                    <div className='tournament-players-information__players-team__team-info__left'>
                        <div className='tournament-players-information__team'>
                            <div className="tournament-players-information-setup__img">
                                { !props.team.team_logo
                                    ?
                                    <span className="icon-ball" color={ getRandonIconBallColor() } />
                                    :
                                    <img alt="" src={props.team.team_logo} />
                                }
                            </div>
                            <div className="tournament-players-information-team-info">
                                <div className="baloa-normal-medium">{props.team.team}</div>
                                <div className="baloa-username">@{props.team.teamname}</div>
                            </div>
                            <div className={` ${props.team.team_state === 'Register'? 'register': props.team.team_state === 'Enrolled'? 'enrolled': props.team.team_state === 'Rejected'? 'rejected': ''} tournament-players-information__label-state baloa-username`}>{props.t(`options.tournaments.setupTournamentInvitationInscription.teamsStates.${props.team.team_state}`)}</div>
                        </div>
                        <div className='tournament-players-information__team-forms'>
                            { props.team.has_team_form && props.team.form_id !== null &&
                                <div className='tournament-players-information-team-btn-actions__view-form' onClick={()=>{props.loadInfoFormModal(props.team.form_id)}}>
                                    <span className='hubicon-view_form' />
                                    <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.viewFormLabel')}</div>
                                </div>
                            }
                            { props.team.team_has_paid && props.team.payment_info !== null &&
                                <div className='tournament-players-information-team-btn-actions__payment-info' onClick={ () => props.handleTeamPlayerPaymentInfo(props.team.payment_info, props.team.currency_payment) }>
                                    <span className='hubicon-payment_info' />
                                    <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.paymentInfo')}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        { props.team.team_state === 'Enrolled' && props.tournament.state !== 'Finalized' &&                           
                        <React.Fragment>
                            <SecondaryButtonComponent
                                className={'tournament-players-information__share-button'}
                                onClick={()=>{
                                    handleInvitationBoxOpen(true)
                                    //props.handleOnShareInscriptionTournamentLinkClick(props.team.team_page)
                                }}
                            >
                                <span>{props.t('options.tournaments.setupTournamentInvitationInscription.shareInscription')}</span>
                                <span className='hubicon-share_v2'></span>
                            </SecondaryButtonComponent>
                            <div className={`tournament-players-information__button-options__box ${ invitationBoxPosition ? "active" : ""}`} >
                                <div className='tournament-players-information__button-options__box__box-option' 
                                    onClick={()=> {
                                        props.handleOnShareInvitedPlayerTournamentLinkClick(props.team.tournament_team_id)
                                        handleInvitationBoxOpen(false);
                                        }}>
                                    <div className='baloa-names'>{props.t('tournament:viewTournament.inscription.teamPlayerWidget.labelInvitedPlayerTournament')}</div>
                                    <div className={"baloa-username"} >{props.t('tournament:viewTournament.inscription.teamPlayerWidget.subLabelInvitedPlayerTournament')}</div>
                                </div>
                                <div className='tournament-players-information__button-options__box__box-option' 
                                    onClick={()=> {
                                        props.handleOnShareInscriptionTournamentLinkClick(props.team.team_page)
                                        handleInvitationBoxOpen(false);
                                        }}>
                                    <div className='baloa-names'>{props.t('tournament:viewTournament.inscription.teamPlayerWidget.labelInvitedTeamTournament')}</div>
                                    <div className={"baloa-username"}>{props.t('tournament:viewTournament.inscription.teamPlayerWidget.subLabelInvitedTeamTournament')}</div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        { props.team.team_state === 'Register' && props.tournament.state !== 'Finalized' &&
                            <div>
                                <div className="tournament-players-information__actions">
                                    <PrimaryButtonComponent
                                        type='button'
                                        onClick={ () => props.handleInscriptionInvitationModalActions(props.team, 'Enrolled') }                                        
                                    >
                                        {props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.acceptBtn')}
                                    </PrimaryButtonComponent>
                                    <SecondaryButtonComponent
                                        type='button'
                                        onClick={ () => props.handleInscriptionInvitationModalActions(props.team, 'Rejected')  }
                                    >
                                        {props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.rejectBtn')}
                                    </SecondaryButtonComponent>
                                    
                                </div>
                            </div>                            
                        }
                    </div>
                </div>
                {/* 
                <div className='tournament-players-information__player-resume-stats' >
                    <div className='tournament-players-information__player-resume-stats__title'>
                        <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsPlayerTitle')}</div>
                        <div className='tournament-players-information__player-resume-stats__title__tooltip'>
                            <div className='baloa-table-column'></div>
                            <span className='hubicon-help_fill'/>
                        </div>
                    </div>
                    <div className='tournament-players-information__player-resume-stats__stats'>
                        <div className='tournament-players-information__player-resume-stats__stats-col'>
                            <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsActiveLabel')}</div>
                            <div className='baloa-names'>{props.team.active_stats ?? 0}</div>
                        </div>
                        <div className='tournament-players-information__player-resume-stats__stats-col'>
                            <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsPendingLabel')}</div>
                            <div className='baloa-names'>{props.team.pending_stats ?? 0}</div>
                        </div>
                        <div className='tournament-players-information__player-resume-stats__stats-col'>
                            <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsAmountLabel')}</div>
                            <CurrencyFormat
                                value={props.team.total_values_stats ?? 0} 
                                displayType={'text'} 
                                thousandSeparator={','} 
                                decimalSeparator={"."} 
                                prefix={'$ '} 
                                suffix={` ${props.team.currency_payment}`}
                                onValueChange ={()=>{}}
                                renderText={value => <div className='baloa-names'>{value}</div>}
                            />
                        </div>
                    </div>
                </div>
                 */}
                
                    <div className='tournament-players-information_filter'>
                        <TextField 
                            onChange={(event) => handleSearchTeams(event.target.value)}
                            placeholder={props.t('options.tournaments.pages.newTournament.tabs.tabTeams.searchPlaceholder')}
                            className='tournament-players-information-setup__search-input'
                            InputProps={{
                                startAdornment: (
                                <React.Fragment>
                                    {<span class="icon-search"></span>}
                                </React.Fragment>
                                ),
                            }}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,                                    
                            }}                    
                        />
                        <div className='tournament-players-information-setup__teams-filter'>
                            <div className={`filter-label baloa-table-column ${ !filterState? 'selected-state': '' }`} onClick={()=>{handleTeamState('AllStates')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.allState')}</div>
                            <div className={`filter-label baloa-table-column ${ filterState === "Enrolled"? 'selected-state': '' }`} onClick={()=>{handleTeamState('Enrolled')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.enrolledLabel')}</div>
                            <div className={`filter-label baloa-table-column ${ filterState === "Pending"? 'selected-state': '' }`} onClick={()=>{handleTeamState('Pending')}} >{props.t('options.tournaments.setupTournamentInvitationInscription.teamsFilter.notEnrolledLabel')}</div>
                        </div>
                        {(props.team.is_tournament_admin || props.team.request_is_admin ) && tournamentTeamPlayers?.some(team => team.team_state === 'Enrolled') &&
                            <div className='tournament-players-information_filter_roster'>                        
                                <a href={`${api_url}/team/roster-pdf/${props.team.tournament_team_id}/`} target='_blank' 
                                    rel="noopener noreferrer" download 
                                    className='tournament-player_information_download_template' >
                                    <span className='hubicon-roster'></span>
                                    <div className='tournament-player_information_download_template_text baloa-table-column'>
                                        {props.t('options.tournaments.pages.tournamentInfo.labelDownloadRoster')}
                                    </div>
                                </a>
                                {props.tournament?.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                                     <div className='tournament-player_information_download_template' onClick={()=>{ props.handleOnDownloadCardIds([props.team.tournament_team_id]) }} >
                                        <span className='hubicon-id_card'></span>
                                        <div className='tournament-player_information_download_template_text baloa-table-column'>
                                            {props.t('options.tournaments.cardIds.buttonOptions.downloadBtnLbl')}
                                        </div>
                                    </div>
                                }                                
                            </div>
                        }
                    </div>
                    
                    
                
                <div className='tournament-players-information-setup__team-title'>
                    <div className='tournament-players-information-setup__team-info'>                        
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.playerListTitle')}{`(${props.tournament.tournament_team_players.results?.length})`}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.formLabel')}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.PaymentLabel')}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.infoPlayerProfileStatsTableTitle')}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.paymentInformationModal.state')}</div>
                    </div>
                </div>
                <div className='tournament-players-information-setup__filter-container'>
                    <div className={`tournament-players-information-setup__team special-actions`}>   
                        <Checkbox
                            //key={player.id}
                            //edge="end"
                            //id={`player-chk-${index}` }
                            //name={"player-chk[" + index +"]"}
                            //inputProps={{ 'aria-labelledby': player.id }}
                            disabled={false}
                            className="tournament-players-information__checkbox"
                            checked={ checkAll }
                            onChange={ (e) => { handleCheckAll(e) } }  
                            value={'all'}
                            color='secondary' 
                        />
                        {(checkAll || checkedArray.length > 0) &&
                            <React.Fragment>                        
                                <SecondaryButtonComponent
                                    type='button'
                                    onClick={ () => ( handleEnrollRejectMultiple( 'Enrolled' ) ) }
                                    className={'tournament-players-information__enroll-btn'}
                                >
                                    <span className='hubicon-how_to_reg icon'></span>
                                    {props.t('options.tournaments.setupTournamentInvitationInscription.enroll')}
                                </SecondaryButtonComponent>
                                <SecondaryButtonComponent
                                    type='button'
                                    onClick={ () => (  handleEnrollRejectMultiple( 'Pending' ) ) }
                                    className={'tournament-players-information__cancel-btn red'}
                                >
                                    <span class="hubicon-block icon"></span>
                                    <span>{props.t('options.tournaments.setupTournamentInvitationInscription.cancelEnrollment')}</span>
                                </SecondaryButtonComponent>
                            </React.Fragment>
                        }
                    </div>
                    {handleTeamPlayersList()}
                </div>
                <div className='tournament-players-information-setup__team-title'>
                    <div className='tournament-players-information-setup__team-info'>                        
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.staffListTitle')}{`(${props.tournament.list_coaches_staff?.length})`}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.formLabel')}</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>&nbsp;</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>&nbsp;</div>
                    </div>
                    <div className='tournament-players-information-setup__team-info'>
                        <div className='baloa-table-column'>&nbsp;</div>
                    </div>
                </div>
                {tournamentTeamStaff?.map((staff, index) => {
                    return (                      
                            <div key={index} className='tournament-players-information-setup__filter-container'>
                                <div className={`tournament-players-information-setup__team ${staff.state === 'Register'? 'is-register': ''}`}> 
                                    <div className='tournament-players-information tournament-players-information-setup__team-data'>
                                        { staff.photo
                                            ?
                                            <img alt="" src={staff.photo} />
                                            :
                                            <span className='icon-ball' color={getRandonIconBallColor()}/>                            
                                        }
                                    
                                        <div className='tournament-players-information-player'>
                                            <div className='baloa-table-column'>{staff.first_name} {staff.last_name}</div>
                                            <div className='baloa-username'>{`@${staff.username}`}</div>
                                        </div>
                                    </div>
                                    { staff.has_form && staff.form_id !== null 
                                        ? 
                                        <div className='tournament-players-information tournament-players-information-btn-actions__view-form' onClick={()=>{props.loadInfoFormModal(staff.form_id)}}>
                                            <span className='hubicon-view_form' />
                                            <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.teamInscriptionModal.viewFormLabel')}</div>
                                        </div>
                                        : 
                                        <div className='tournament-players-information tournament-players-information-btn-actions__undone'>
                                            <span className='tournament-players-information__circle' />
                                            <div className='baloa-table-column'>{props.t('options.tournaments.setupTournamentInvitationInscription.undoneLabel')}</div>
                                        </div>
                                    }
                                    <div className='tournament-players-information-setup__team_width'>
                                        {props.team.team_state !== "Rejected" && props.tournament.state !== 'Finalized' && staff.has_form && staff.form_id !== null && props.tournament?.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                                            <span className="icon-options" onClick={()=>{ handleStaffBoxOpen(index) }}/>
                                        }
                                        <div className={`tournament-players-information_options-box ${ index === boxStaffPosition ? "active" : ""}`} >                            
                                            <div className='tournament-players-information_options-box__enrolled-container'>
                                                {staff.has_form && staff.form_id !== null && props.tournament?.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                                                    <div className='tournament-players-information_options-box__download-card' onClick={()=>{ handleDownloadStaffCardId(staff.team_profile_id, props.team.tournament_team_id) }} >
                                                        <div className='baloa-names'>{props.t('options.tournaments.cardIds.playerOption.subTitle')}</div>
                                                        <div className='baloa-username'>{props.t('options.tournaments.cardIds.playerOption.description')}</div>
                                                    </div>
                                                }
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )
                })}
                
            </div> 
                : <img src={loading_baloa} alt="loader" />
            }
        </React.Fragment>
    )
}

export default withTranslation(['league','tournament'])(TournamentPlayersInformationComponent);