// React
import React from 'react';

// Styles
import './InputCommentsComponent.styles.css';


function InputCommentsComponent( props ) {

    const input_ref = React.createRef();

    const [textAreaValue, setTextAreaValue] = React.useState(props.value? props.value:'');
    const handleChange =(event)=>{
        setTextAreaValue(event.target.value);
    }

    return (
        <div 
            className={ 'input-comments ' + ( props.className ? props.className : '' ) }
        >
            <textarea 
                ref={ input_ref }
                disabled={ props.disabled }
                type={ props.type } 
                className="input-comments__input baloa-table"
                placeholder={ props.placeholder ? props.placeholder : '' }
                value={textAreaValue}
                onChange={handleChange}
                autoFocus={props.autoFocus}
                onKeyDown={(event) => {      
                    if (event.key === 'Enter' ) {
                        if(textAreaValue === undefined || textAreaValue === ""){
                            event.preventDefault();    
                        } else {
                            props.handleOnCommentClick(textAreaValue);
                            input_ref.current.value = '';
                            setTextAreaValue('');
                        }
                    }
                }}
            />            
        </div>
    )
}


export default InputCommentsComponent;
