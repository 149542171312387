
export const loadPostAction = ( post_data ) => {
    return { type: "POST:LOAD_POST", post_data }
}

export const loadPostCommentsAction = ( post_comments ) => {
    return { type: "POST:LOAD_COMMENTS", post_comments }
}

export const clapCommentsAction = ( comments_id ) => {
    return { type: "POST:CLAP_COMMENTS", comments_id }
}

export const loadReplyPostCommentsAction = ( id_comment, post_reply_comments ) => {
    return { type: "POST:LOAD_REPLY_COMMENTS", id_comment, post_reply_comments }
}

export const loadPostClapsAction = ( post_claps ) => {
    return { type: "POST:LOAD_CLAPS", post_claps }
}

export const changePostClapsModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_CLAPS_MODAL_STATE", new_state }
}

export const changePostIsFollowingStateAction = ( username ) => {
    return { type: "POST:CHANGE_IS_FOLLOWING_STATE", username }
}

export const changeReportPostModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_REPORT_MODAL_STATE", new_state }
}

export const changeBlockPostModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_BLOCK_MODAL_STATE", new_state }
}

export const changeAcceptReportPostModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_ACCEPT_REPORT_MODAL_STATE", new_state }
}

export const loadPostClapsNextAction = ( post_claps ) => {
    return { type: "POST:LOAD_CLAPS_NEXT", post_claps }
}

export const loadPostCommentsNextAction = ( post_comments ) => {
    return { type: "POST:LOAD_COMMENTS_NEXT", post_comments }
}

export const updateHomePostAction= ( post ) => {
    return { type: "POST:UPDATE_POST", post }
}

export const loadPostReactionsAction = ( post_reactions ) => {
    return { type: "POST:LOAD_POST_REACTIONS", post_reactions }
}

export const loadPostReactionsNextAction = ( post_reactions, reaction_type ) => {
    return { type: "POST:LOAD_POST_REACTIONS_NEXT", post_reactions, reaction_type }
}

export const changePostReactionsModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_REACTIONS_MODAL_STATE", new_state }
}

export const loadPostReactionsByTypeAction = ( post_reactions, reaction_type ) => {
    return { type: "POST:LOAD_POST_REACTIONS_BY_TYPE", post_reactions, reaction_type }
}

export const changePostCommentReactionsModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_COMMENT_REACTIONS_MODAL_STATE", new_state }
}

export const loadPostCommentReactionsAction = ( comment_reactions ) => {
    return { type: "POST:LOAD_POST_COMMENT_REACTIONS", comment_reactions }
}

export const loadPostCommentReactionsNextAction = ( comment_reactions, reaction_type ) => {
    return { type: "POST:LOAD_POST_COMMENT_REACTIONS_NEXT", comment_reactions, reaction_type }
}

export const loadPostCommentReactionsByTypeAction = ( comment_reactions, reaction_type ) => {
    return { type: "POST:LOAD_POST_COMMENT_REACTIONS_BY_TYPE", comment_reactions, reaction_type }
}

export const changeMatchDetailModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_MATCH_DETAIL_MODAL_STATE", new_state }
}

export const changeQuotePostModalStateAction = ( new_state ) => {
    return { type: "POST:CHANGE_QUOTE_POST_MODAL_STATE", new_state }
}

export const postToQuoteAction= ( post ) => {
    return { type: "POST:POST_TO_QUOTE", post }
}

export const changePostIsFollowingStateFromReactionTypeAction = ( username, reaction_type ) => {
    return { type: "POST:CHANGE_IS_FOLLOWING_STATE_FROM_REACTION_TYPE", username, reaction_type }
}

