// React
import React from 'react';

// Styles
import './CheckMultipleTournamentListComponent.styles.css';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

//Material
import {ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { withStyles } from '@mui/styles';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';


// i18n
import { withTranslation } from 'react-i18next';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// React device detect
import { isMobile } from 'react-device-detect';

const StyledMenu = withStyles({
    paper: {
        boxShadow:' 0px 5.30739px 5.30739px rgba(0, 0, 0, 0.25)',
        borderRadius: '14px'
    },
  })((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
  ));

function CheckMultipleTournamentListComponent (props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [checked, setChecked] = React.useState(props.tournaments?.map( (option) => {return option.tournament_id}));
    const [tournametsFilterText, setTournametsFilterText] = React.useState(props.buttonTitle);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    
    const handleOnAccept = () =>{
        if(checkTournamentFilter()){
            setTournametsFilterText(props.t('tournamentSummary.modalTournamentMatches.selectedTournaments',  {__number: checked.length } ))
        }else{
            setTournametsFilterText(props.buttonTitle);
        }
        props.handleOnTournamentFilter(checked);
        handleClose();
    }

    const handleOnCancel = () =>{
        setChecked(props.tournaments.map( (option) => {return option.tournament_id}));
        setTournametsFilterText(props.buttonTitle);
        handleClose();
    }

    const checkTournamentFilter = () =>{
        if(checked.length !== props.tournaments.length){
            return true;
        }else{
            return false;
        }
    }


    return (
        <div className= { "check-multiple-tournament-list " + ( props.className ? props.className : '' )  }>
            <div id="tbtn" onClick={ checkTournamentFilter()? console.log('filtered'): handleClick } >
            {checkTournamentFilter() ?
                <div className={`check-multiple-tournament-list__list-button filtered`}>
                    <div className='baloa-table' dangerouslySetInnerHTML={{ __html: tournametsFilterText}}></div>
                    <span className='hubicon-clear_option' onClick={()=>{handleOnCancel()}}></span>
                </div>
                :
                <Button aria-controls="simple-menu" aria-haspopup="true" /* onClick={handleClick} */  id="set-simple" className={`check-multiple-tournament-list__list-button`}>
                    <div className='baloa-table' dangerouslySetInnerHTML={{ __html: tournametsFilterText}}></div>
                    <span className='hubicon-expand_more'></span>
                </Button>
            }
            </div>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={'check-multiple-tournament-list__list'}

            >
                <div className='check-multiple-tournament-list__list__title baloa-table'>{props.listTitle}<span className='hubicon-clear_option' onClick={()=>{handleClose()}}></span></div>
                { props.tournaments?.map( (option) => {
                    return (
                        <ListItem
                            key={ option.tournament_name }                                             
                        >
                            <ListItemIcon className="check-multiple-tournament-list__list-icon" >
                                {option.tournament_logo?
                                    <img className="check-multiple-tournament-list__list-img" src={ option.tournament_logo} alt=""/>
                                    :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                            </ListItemIcon>
                            {isMobile
                            ? <ListItemText 
                                id={option.id}
                                primary={ <p className={'baloa-normal-medium'}>{option.tournament_name}</p> } 
                                secondary={
                                <React.Fragment>
                                    <span className={'baloa-username'}>@{option.tournament_football_type}</span>
                                    <span className={'baloa-username'}>{option.tournament_gender}-{option.tournament_category}</span>
                                </React.Fragment>
                                } 
                                className="check-multiple-tournament-list__list-item" 
                            />
                            : 
                            <ListItemText 
                                id={option.tournament_id}
                                primary={<p className={'baloa-normal-medium'}>{option.tournament_name}</p> } 
                                secondary={
                                    <React.Fragment>
                                        <span className={'baloa-table-column'}>{option.tournament_football_type}</span>
                                        <span className={'baloa-table-column'}>{option.tournament_gender}</span>
                                        <span className={'baloa-table-column'}>{option.tournament_category}</span>
                                    </React.Fragment>
                                } 
                                className="check-multiple-tournament-list__list-item" 
                            />
                            }
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(option.tournament_id)}
                                    checked={checked.indexOf(option.tournament_id) !== -1}
                                    inputProps={{ 'aria-labelledby': option.tournament_id }}
                                    color='secondary'
                                />
                            </ListItemSecondaryAction>
                        </ListItem> 
                        
                    )
                } )}
                <div className='check-multiple-tournament-list__buttons'>
                    <TextButtonComponent
                        className={'check-multiple-tournament-list__button-cancel xs'}
                        onClick={()=>{handleOnCancel()}}
                    >
                        <span>{props.t('tournamentSummary.modalTournamentMatches.cancelBtnLbl')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent
                            className={'xs'}
                            onClick={()=>{handleOnAccept()}}
                    >
                        <span>{props.t('tournamentSummary.modalTournamentMatches.acceptBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div> 
            </StyledMenu>
            
        </div>
    )

}

export default withTranslation('profile')(CheckMultipleTournamentListComponent);
