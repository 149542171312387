// React
import React from 'react';

// Components
import InputSearchComponent  from 'shared/components/input-search/InputSearchComponent';
import LeagueSearchMemberListComponent from 'app/leagues/components/league-search-member-list/LeagueSearchMemberListComponent';

// Material UI
import Box from '@mui/material/Box';

// Styles
import './LeagueSearchMemberToTeamComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function LeagueSearchMemberToTeamComponent( props ) {

    const [isInputFocus, setIsInputFocus] = React.useState('');
    const [isEmptySearch, setIsEmptySearch] = React.useState(true);
    const [cleanValue, setcleanValue] = React.useState(false);
    
    return (
            <div className="league-search-member-to-team-container">          
                <div className="league-search-member-to-team__input-search baloa-subtitle-2">{ props.t('newTeam.forms.players.searchLabel') }</div>
                {props.addPlayerHome && 
                    <div className="league-search-member-to-team__input-subtitle baloa-table">
                        {props.t("team:teams.teamsModalHome_subTitleAddMember")}
                    </div>
                }
                <Box className="league-search-member-to-team-form__box">
                    <InputSearchComponent
                        className="league-search-member-to-team-search"
                        placeholder={props.t('newTeam.forms.players.searchPlaceholder')}
                        handleSearch={(v) => { 
                            props.handleSearchMember(v); 
                            if(v !== ''){
                                setIsEmptySearch(false); 
                                setcleanValue(false); 
                            }else{
                                props.handleCleanMemberSearch();
                            }
                        }}
                        autoFocus = {isInputFocus}
                        cleanValue = {cleanValue}
                    
                    />

                    { props.profiles.length !== 0 &&
                        
                        <LeagueSearchMemberListComponent 
                            items={props.profiles}
                            onSelect = { (selected_profile) => { 
                                setIsInputFocus('true');
                                props.onSelectTeamPlayer(selected_profile);
                                props.handleCleanMemberSearch();
                                setcleanValue(true);
                                setIsEmptySearch(true);                        
                            } }
                            set_type ={"member"}
                        />                                   
                    }
                    
                    <div className="league-add-team-form__box__without-results">
                        { props.profiles.length === 0 && !isEmptySearch &&
                            <p className="baloa-subtitle-2">{props.t('newTeam.forms.players.searchEmptyMsg')}</p>
                        }
                    </div>
                </Box>    
            </div>
    )
}

export default withTranslation('team')(LeagueSearchMemberToTeamComponent);