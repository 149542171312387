// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-experiences__new-button:hover {
    /* background: var(--hover-pb-bg-color); */
    cursor: pointer;
}

.profile-experiences__experiences {
    margin-top: var(--space);
}


.profile-experience__no-experiences__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.profile-experience__no-experiences__container .baloa-normal {
    color: var(--basic-gray-color);
}

.profile-experiences__new-button.hubicon-add_option {
    font-size: 28px;
    color: var( --basic-gray-color);
}

.baloa-card-1.profile-experiences.profile__experiences{
    border-radius: 14px;
}


`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-experiences/ProfileExperiencesComponent.style.css"],"names":[],"mappings":";AACA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["\n.profile-experiences__new-button:hover {\n    /* background: var(--hover-pb-bg-color); */\n    cursor: pointer;\n}\n\n.profile-experiences__experiences {\n    margin-top: var(--space);\n}\n\n\n.profile-experience__no-experiences__container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 40px 0;\n}\n\n.profile-experience__no-experiences__container .baloa-normal {\n    color: var(--basic-gray-color);\n}\n\n.profile-experiences__new-button.hubicon-add_option {\n    font-size: 28px;\n    color: var( --basic-gray-color);\n}\n\n.baloa-card-1.profile-experiences.profile__experiences{\n    border-radius: 14px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
