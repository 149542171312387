// PropTypes
import PropTypes from 'prop-types';

// Shapes

export const UserShape = { 
    id: PropTypes.string, 
    username: PropTypes.string,
    photo: PropTypes.string,
}
