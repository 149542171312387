// i18next 
import i18n from 'i18next';

const Constants_sign_up_state = {
    countries: [],
}

export default function ConstantsSingUpReducer(state = Constants_sign_up_state, action) {
    switch (action.type) {
        case "SIGNUP:LOAD_COUNTRY_IN_LOGIN": {
            let new_data = [];
            let auxCountryCode = {
                id: '',
                flag_icon:'', 
                value: "", 
                content:i18n.t('signUp:phone.countryCode')
            };
            new_data.push(auxCountryCode);
            action.data.filter((country) => { 
                return country.indicative !== null;
            }).forEach(element => {

                let aux = {
                    id: element.id,
                    flag_icon:element.flag_icon, 
                    value:element.indicative, 
                    content:element.name+" (+"+element.indicative+")",
                };
                new_data.push(aux);
            });

            return { ...state, countries: new_data }
        }

        // no default
    }

    return state;
}