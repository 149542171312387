// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './SignInEmailCodeRecoverPasswordComponent.styles.css';

// Actions
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { signUpResendEmailVerificationAction, signInVerifyRecoveryPasswordCodeAction } from 'app/authentication/actions/LoginActions';

// Validators
import { OtpValidator } from 'shared/validators/SignUpValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';


class SignInEmailCodeRecoverPasswordComponent extends Component {

    constructor(props){
        super(props);
        this.state = { 
            disabled: false,
            minutes_left: 5,
            seconds_left: 0,
            timer_id: undefined,
            sended: true,
            resended: false,
            isValid: false
        };
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({isValid:true});
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({isValid:false});
            this.setState({disabled:false}) 
        }
    }
    
    resetError(){
        const data = {
            'field':'code',
            'error':''
        }
        this.props.changeSignUpError(data);  
    }

    stopTimers() {

        for (var i = 1; i < 99999; i++)
                window.clearInterval(i);

        this.setState( { seconds_left: 0 });
        this.setState( { minutes_left: 5 });                

    }

    startTimer() {

        if(!this.state.isValid && this.state.resended){
            
            this.setState({resended:false}) 
            
        }

        this.state.timer_id = setInterval( () => {   // eslint-disable-line
            this.setState( { seconds_left: this.state.seconds_left - 1 });
            if ( this.state.seconds_left < 0 ) {
                this.setState( { seconds_left: 59 });
                this.setState( { minutes_left: this.state.minutes_left - 1 });
                if( this.state.minutes_left < 0 ) {
                    this.setState( { seconds_left: 0 });
                    this.setState( { minutes_left: 5 });
                    clearInterval( this.state.timer_id );
                    this.setState( { sended: false });
                    this.setState({resended:false}) 
                }
            }
        }, 1000 );

        
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval( this.state.timer_id );
    }
    
    render = () => {

        const formInitialValues = {
            code: '',
        }

        return (

            <div className="signin-email-code-recover-password__container">
                <div className="signin-email-code-recover-password__container__left-col"></div>
                
                <div className="signin-email-code-recover-password__container__right-col">
                    {/* Title */}
                    <div className="signin-email-code-recover-password__container-title">
                        <h2 className="signin-email-code-recover-password__title">{this.props.t('loginEmail.recoverPassword')}</h2>
                        <span className="icon-email" />
                    </div>
                    
                    <text className="signin-email-code-recover-password__text">{this.props.t('signUpEmailVerification.message')}</text>
                    
                    {/* Recover password email Form */}
                    <FormComponent 
                        formInitialValues = { formInitialValues } 
                        onSubmit          = { ( values, actions ) => { 
                            values['email'] = this.props.state.signup.email;
                            values['key'] = values.code;
                            this.props.signInVerifyRecoveryPasswordCodeAction( values );
                        } }
                        className         = "signin-email-code-recover-password__form"
                        functionDisabled  = { this.disabled.bind(this) }
                    >
                        <label className="signin-email-code-recover-password__form__label" >{this.props.t('loginEmail.verCodeEmail')}</label>
                        <InputFieldComponent 
                            input_id           = "signup-email-code-ver" 
                            field_name         = "code"
                            validationFunction = { OtpValidator }
                            disabled           = { false }
                            className          = "signin-email-code-recover-password__form__field"
                            type               = "text"
                            resetError         = { () => this.resetError() }
                            error              = { this.props.state.signup_error.error }
                            message            = ""
                            maxLength          = "5"
                        />
                        <div className="signin-email-code-recover-password__retry-code__valid-for">
                            <text className="signin-email-code-recover-password__retry-code__valid-for__text">El código expirará en: </text> 
                            <text className="signin-email-code-recover-password__retry-code__valid-for__text-alert">
                                { this.state.seconds_left > 9? `${ this.state.minutes_left }:${ this.state.seconds_left } ${this.props.t('signUpEmailVerification.minutesLabel')}`: `${ this.state.minutes_left }:0${ this.state.seconds_left } ${this.props.t('signUpEmailVerification.minutesLabel')}` }
                            </text>
                        </div>


                        <div className="signin-email-code-recover-password__action-buttons">
                            <SecondaryButtonComponent 
                                disabled = {this.state.sended && this.state.isValid}
                                onClick   = { () => { 
                                    this.props.signUpResendEmailVerificationAction( { email: this.props.state.signup.email } );
                                    this.setState( { resended: true } );
                                    this.stopTimers();
                                    this.startTimer();
                                    this.setState( { sended: true } );                                
                                    this.setState( { isValid: true } );
                                } } 
                                className = "signin-email-code-recover-password__action-buttons__resend-btn"
                            >
                                <span>Reenviar código</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent type="submit" disabled={false} onClick={()=>{}} className = "signin-email-code-recover-password__action-buttons__submit">
                                <span>{this.props.t('code.button')}</span>
                            </PrimaryButtonComponent>
                        </div>                        
                    </FormComponent>                    
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpResendEmailVerificationAction: ( values, data ) => {
            return dispatch( signUpResendEmailVerificationAction( values, data ) )
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        changeSignUpError : ( values ) => {
            return dispatch( changeSignUpError( values ) )
        },
        signInVerifyRecoveryPasswordCodeAction: ( values ) => {
            return dispatch( signInVerifyRecoveryPasswordCodeAction( values ) )
        }
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignInEmailCodeRecoverPasswordComponent);
