/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

// Actions
import { deleteAttribute, loadAttributesConstants } from 'app/generalattributes/actions/AttributeActions';
import { loadHomeProfileSuggestions, loadHomePageSuggestions } from 'app/home/actions/HomeActions';
import { changePageNewPageTypeModalStateAction } from 'app/pages/actions/PageActionsCreators';
import { 
    loadProfile,
    loadProfilePosts,
    createOrEditAttributeOnProfile,
    updateProfileData,
    followProfile,
    unfollowProfile,
    loadProfileFollowers,
    loadProfileFollowing,
    followProfileByUsername,
    unfollowProfileByUsername,
    followPageByUsername,
    unfollowPageByUsername,
    inviteProfileToValidateAttribute,
    searchProfileNationality, 
    searchProfileLocations,
    addProfileNationality,
    switchProfileToPage,
    loadProfileTournamentStats,
    loadProfileAchievementsStats,
    loadProfileTournaments,
    loadProfileTeamStats,
    loadAllProfileVideo,
    loadProfileMatches,
    loadProfileAchievements,
    loadProfileTrajectories,
    loadMoreProfileVideo,
} from 'app/profiles/actions/ProfileActions';
import { 
    changeProfileUploadPhotoModalStateAction, 
    changeProfileBasicInfoModalStateAction,
    changeProfileNationalityModalStateAction,
    changeProfileDescriptionModalStateAction, 
    changeProfileDataSheetModalStateAction, 
    changeProfileTechnicsPrimaryPositionModalStateAction, 
    changeProfileTechnicsSecondaryPositionModalStateAction, 
    changeProfileTechnicsMeassuresModalStateAction,
    changeProfileTechnicsLateralityModalStateAction,
    changeProfileExperiencesModalStateAction,
    changeProfileSpecialtiesModalStateAction,
    changeProfileAptitudesModalStateAction,
    changeProfileAptitudesAdminModalStateAction,
    loadProfileErrorsAction, 
    changeProfileFollowersModalStateAction,
    editProfileExperiencesAction,
    deleteProfileExperiencesAction,
    changeInviteToValidateAptitudeModalStateAction,
    changeProfileTournamentsTeamsSummaryModalStateAction,
    clearProfileStatsAction,
    setProfileSelectedPostAction,
    changeProfilePostFormationModalStateAction,
    changeProfilePollQuizPostModalStateAction,
    changeProfilePostCommentModalStateAction,
    changeProfilePostMediaModalStateAction,
    changeProfileTournamentsMatchesModalStateAction,
    changeProfilePostVideoClipModalStateAction,
    changeProfileFeaturedClipsViewMoreModalStateAction,
} from 'app/profiles/actions/ProfileActionsCreators';
import { followPage, loadPage } from 'app/pages/actions/PageActions';
import { clapPost, deletePost, loadPostClapsById, commentPostById } from 'app/posts/actions/PostActions';
import { changeHomeEditPostModal, changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';
import { validateAttribute } from 'app/generalattributes/actions/AttributeActions';

import { 
    changePostClapsModalStateAction, 
    changeReportPostModalStateAction,
    changeBlockPostModalStateAction,
    changeAcceptReportPostModalStateAction,
 } from 'app/posts/actions/PostsActionsCreators';

import { addEventAction } from 'app/clickstream/actions/ClickstreamActionsCreators';



// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

// Styles
import './ProfileView.styles.css';

// Components
import ProfileFeedComponent from 'app/profiles/components/profile-feed/ProfileFeedComponent';
import ProfileInformationComponent from 'app/profiles/components/profile-information/ProfileInformationComponent';
//import ProfileCompletionComponent from 'app/profiles/components/profile-completion/ProfileCompletionComponent';
import ProfileBasicInfoComponent from 'app/profiles/components/profile-basic-info/ProfileBasicInfoComponent';
import ProfileTechnicsComponent from 'app/profiles/components/profile-technics/ProfileTechnicsComponent';
//import ProfileSkillsComponent from 'app/profiles/components/profile-skills/ProfileSkillsComponent';
import ProfileExperiencesComponent from 'app/profiles/components/profile-experiences/ProfileExperiencesComponent';
//import ProfileSpecialtiesComponent from 'app/profiles/components/profile-specialties/ProfileSpecialtiesComponent';
import ProfileAptitudesComponent from 'app/profiles/components/profile-aptitudes/ProfileAptitudesComponent';
//import MyPagesComponent from 'app/profiles/components/profile-pages/MyPagesComponent';
//import ProfileInterestsComponent from '../components/profile-interests/ProfileInterestsComponent';
import SeparatorComponent from 'shared/components/separator/SeparatorComponent';
//import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
//import PageSuggestionsComponent from 'app/pages/components/page-suggestions/PageSuggestionsComponent';
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import { changeSignUpLoginModal } from 'app/authentication/actions/LoginActionsCreators';
import ProfileHistoricalSummaryComponent from '../components/profile-historical-summary/ProfileHistoricalSummaryComponent';
import ProfileTournamentsTeamsSummaryComponent from '../components/profile-tournaments-teams-summary/ProfileTournamentsTeamsSummaryComponent';
import ProfileTournamentVictoriesComponent from '../components/profile-tournament-victories/ProfileTournamentVictoriesComponent';
import ProfileFeaturedClips from '../components/profile-featured-clips/ProfileFeaturedClips';
import ProfileTournamentsMatchesComponent from 'app/profiles/components/profile-tournaments-matches/ProfileTournamentsMatchesComponent';


// Modals
import ProfileUploadPhotoModalComponent from 'app/profiles/components/profile-upload-photo-modal/ProfileUploadPhotoModalComponent';
import ProfileDescriptionModalComponent from 'app/profiles/components/profile-description-modal/ProfileDescriptionModalComponent';
import ProfileBasicInfoModalComponent from 'app/profiles/components/profile-basic-info-modal/ProfileBasicInfoModalComponent';
import ProfileNationalityModalComponent from 'app/profiles/components/profile-nationality-modal/ProfileNationalityModalComponent';
// import ProfileTechnicsPrimaryPositionModalComponent from 'app/profiles/components/profile-technics-primary-position-modal/ProfileTechnicsPrimaryPositionModalComponent';
// import ProfileTechnicsSecondaryPositionModalComponent from 'app/profiles/components/profile-technics-secondary-position-modal/ProfileTechnicsSecondaryPositionModalComponent';
// import ProfileTechnicsMeassuresModalComponent from 'app/profiles/components/profile-technics-meassures-modal/ProfileTechnicsMeassuresModalComponent';
// import ProfileTechnicsLateralityModalComponent from 'app/profiles/components/profile-technics-laterality-modal/ProfileTechnicsLateralityModalComponent';
import ProfileExperiencesModalComponent from 'app/profiles/components/profile-experiences-modal/ProfileExperiencesModalComponent';
import ProfileSpecialtiesModalComponent from 'app/profiles/components/profile-specialties-modal/ProfileSpecialtiesModalComponent';
//import ProfileAptitudesModalComponent from 'app/profiles/components/profile-aptitudes-modal/ProfileAptitudesModalComponent';
//import ProfileAptitudesAdminModalComponent from 'app/profiles/components/profile-aptitudes-admin-modal/ProfileAptitudesAdminModalComponent';
import ProfileFollowersModalComponent from 'app/profiles/components/profile-followers-modal/ProfileFollowersModalComponent';
import ProfileInviteToValidateAptitudeModalComponent from 'app/profiles/components/profile-invite-to-validate-aptitude-modal/ProfileInviteToValidateAptitudeModalComponent';
import ReportModalComponent from 'app/posts/components/report-modal/ReportModalComponent';
import BlockModalComponent from 'app/posts/components/block-modal/BlockModalComponent';
import AcceptReportModalComponent from 'app/posts/components/acceptance-modal/AcceptReportModalComponent';
import ProfileTournamentsTeamsSummaryModalComponent from '../components/profile-tournaments-teams-summary-modal/ProfileTournamentsTeamsSummaryModalComponent';
import ProfilePostFormationModalComponent from 'app/profiles/components/profile-post-formation-modal/ProfilePostFormationModalComponent';
import ProfilePostQuizPollModalComponent from 'app/profiles/components/profile-post-quiz-poll-modal/ProfilePostQuizPollModalComponent';
import ProfilePostCommentModalComponent from 'app/profiles/components/profile-post-comment-modal/ProfilePostCommentModalComponent';
import ProfilePostMediaModalComponent from 'app/profiles/components/profile-post-media-modal/ProfilePostMediaModalComponent';
import ProfilePostVideoClipsComponent from '../components/profile-post-video-clips/ProfilePostVideoClipsComponent';
import ProfileTournamentsMatchesModalComponent from '../components/profile-tournaments-matches-modal/ProfileTournamentsMatchesModalComponent';
import ProfileFeaturedClipsViewMoreModalComponent from 'app/profiles/components/profile-featured-clips-view-more-modal/ProfileFeaturedClipsViewMoreModalComponent';


// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Variables
import { web_url } from "config/GlobalData";
import ProfileDataSheetModalComponent from '../components/profile-data-sheet/ProfileDataSheetModalComponent';


function TabPanel( props ) {

    const { children, value, index, ...other } = props;

    if ( props.profile.loading ) {
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={200} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={100} style={{ marginTop: 30 }}/>
                </React.Fragment>
            </div>
            );
    } 
    else {
  
        return (
            <div
                role="tabpanel"
                hidden={ value !== index }
                id={ `simple-tabpanel-${index}` }
                aria-labelledby={ `simple-tab-${index}` }
                { ...other }
            >
                {/* Profile Data */}
                { index === 0 && 
                    <React.Fragment>
                                
                        <div className='profile__information-type__btns-container'>
                            <div onClick={() => {props.handleFilterInfo('stats')}} className={`baloa-table-column ${props.information_type === 'stats' ? 'selected' : ''}`}>{props.infoAndStatsTabTitle}</div>
                            <div onClick={() => {props.handleFilterInfo('achievements_trajectory')}} className={`baloa-table-column ${props.information_type === 'achievements_trajectory' ? 'selected' : ''}`}>{props.achievementsAndCareerTabTitle}</div>
                        </div>
                        { props.information_type === 'stats' &&
                            <div className='profile__stats-container'>
                                {/* Profile Completion */}
                                { /* props.profile.is_owner && 
                                    <ProfileCompletionComponent 
                                        percentage={ props.profile.profile_completion | 0 }
                                        className="profile__completion"
                                        is_owner={ props.profile.is_owner } 
                                        message={t('profileCompletedText')}
                                    /> */ 
                                }
        
                                {/* Profile Basic Information */}
                                <ProfileBasicInfoComponent 
                                    className="profile__basic-info"
                                    profile={ props.profile }
                                    is_owner={ props.profile.is_owner } 
                                    onNewButton={ props.onNewBasicInfoButton }
                                />                        

                                {/* Profile Tournament Historical Summary */}
                                { props.profile?.summary_historic_player_stats &&
                                    <ProfileHistoricalSummaryComponent 
                                        handleUnlockData={() => {console.log('unlock data modal')}} 
                                        profile_data={props.profile.summary_historic_player_stats}  
                                        is_owner={props.profile.is_owner}    
                                        username={props.profile.user.username}                 
                                    />
                                }

                                {/* Profile Tournaments Matches */}
                                { props.profile.profile_type === 'player' && 
                                    <ProfileTournamentsMatchesComponent 
                                        profile_matches ={props.profile_matches}
                                        handleOnViewAllMatches={props.handleOnViewAllMatches}
                                        handleProfilePostModal={ props.handleProfilePostModal }
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        handleOnVideoChange={ props.handleOnVideoChange }
                                        setClipMatch={ props.setClipMatch }
                                        has_unbloqued_tournaments={ props.has_unbloqued_tournaments }
                                        has_pending_tournaments={ props.has_pending_tournaments}
                                        is_owner={ props.profile.is_owner }
                                        username={props.profile.user.username} 
                                        user_me={ props.user_me }
                                        handleOnMatch={ props.handleOnMatch }
                                    />
                                }

                                {/* Profile Technics */}
                                { props.profile.profile_type === 'player' && 
                                    <ProfileTechnicsComponent 
                                        className="profile__technics"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewTechnicsButton }
                                    /> 
                                }

                                {/* Profile Tournaments Summary */}
                                { props.profile.profile_type === 'player' && 
                                    <ProfileTournamentsTeamsSummaryComponent 
                                        summary_type='tournaments_summary'
                                        handleUnlockData={() => {console.log('unlock data modal')}}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll}
                                        table_data={props.profile?.tournament_player_stats}
                                        is_owner={props.profile.is_owner}    
                                        username={props.profile.user.username}
                                        profile_tournament_paid={props.profile_tournament_paid}
                                    />
                                }

                                {/* Profile Teams Summary */}
                                { props.profile.profile_type === 'player' && 
                                    <ProfileTournamentsTeamsSummaryComponent 
                                        summary_type='teams_summary'
                                        handleUnlockData={() => {console.log('unlock data modal')}}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll}
                                        table_data={props.profile?.team_player_stats}
                                        is_owner={props.profile.is_owner}    
                                        username={props.profile.user.username}
                                        profile_tournament_paid={props.profile_tournament_paid}                                
                                    />
                                }

                                {/* Profile Tournament Victories (Palmarés) */}
                                { props.profile.profile_type === 'player' &&                     
                                    <ProfileTournamentVictoriesComponent 
                                        handleModalUnlockData={props.handleModalUnlockData}
                                        summary_type={'victories_summary'}
                                        handleOnSummaryViewAll={props.handleOnSummaryViewAll} 
                                        tournaments={props.profile?.tournament_player_achievements}
                                        is_owner={props.profile.is_owner}    
                                        username={props.profile.user.username}
                                        profile_tournament_paid={props.profile_tournament_paid}
                                    />
                                }

                                {/* Profile Skills */}
                                {/* { props.profile.is_owner && 
                                    <ProfileSkillsComponent 
                                        className="profile__skills"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                    /> 
                                } */}
                                {/* Profile Specialties */}
                                { /* (props.profile.is_owner || props.profile.attributes.specialty.length > 0 ) && 
                                    <ProfileSpecialtiesComponent 
                                        className="profile__specialties"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewSpecialtiesButton }

                                    />  */
                                }
                                {/* Profile Aptitudes */}
                                { 
                                    <ProfileAptitudesComponent 
                                        className="profile__aptitudes"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        onNewButton={ props.onNewAptitudesButton }
                                        onValidateAptitude={ props.onValidateAptitude }
                                        onInviteToValidateAptitude={ props.onInviteToValidateAptitude }
                                    /> 
                                }

                                {/* Profile Interests */}
                                {/* <ProfileInterestsComponent
                                    className="profile__interests"
                                    pages={ props.profile.pages }
                                    handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                                />  */}

                            </div>
                        }
                        { props.information_type === 'achievements_trajectory' &&
                            <div className='profile__achievements-container'>
                                {/* Profile Experiences Achievements*/}
                                { /* ( props.profile.is_owner || props.profile.attributes.experiences.length > 0 ) &&  */
                                    <ProfileExperiencesComponent 
                                        className="profile__experiences"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        //onNewButton={ props.onNewExperiencesButton }
                                        //onEditButton={ props.onEditExperiencesButton }
                                        //onDeleteButton={ props.onDeleteExperiencesButton }
                                        experiences_type={'achievements'}                                 
                                    /> 
                                }
                                {/* Profile Experiences Trajectories */}
                                { /* ( props.profile.is_owner || props.profile.attributes.experiences.length > 0 ) &&  */
                                    <ProfileExperiencesComponent 
                                        className="profile__experiences"
                                        profile={ props.profile }
                                        is_owner={ props.profile.is_owner } 
                                        //onNewButton={ props.onNewExperiencesButton }
                                        //onEditButton={ props.onEditExperiencesButton }
                                        //onDeleteButton={ props.onDeleteExperiencesButton } 
                                        experiences_type={'trajectory'}                           
                                    /> 
                                }
                            </div>
                        }
                    </React.Fragment>
                }

                {/* Profile Feed */}
                { index === 1 && 
                    
                    <ProfileFeedComponent 

                        posts = { props.posts }
                        profile_suggestions = { [] }
                        me_photo = { props.me_photo }

                        handleOnLoadMorePosts = { () => props.loadProfilePosts() }
                        
                        //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                        //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }

                        handleClap = { ( post ) => props.handleClap( post ) }
                        onCloseClapsModal={() => { props.onCloseClapsModal( true ) }}
                        loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}

                        handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                        handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                        handleOnComment = { ( post, comment ) => props.handleOnComment( post, comment )}

                        openReportPostModal={() => {props.openReportPostModal( true )}}
                        handleReportPost={(post_id) => {props.handleReportPost(post_id)}}

                        openBlockPostModal={() => {props.openBlockPostModal( true )}}
                        handleBlockProfile={(username) => {props.handleBlockProfile(username)}}

                        handleOnDeletePostClick = { ( post ) => props.deletePost( post ) }

                        handleOnCopyLinkClick = {(post) => {props.handleOnCopyLinkClick(post)}}
                        
                        handleOnAuthorClick = { props.handleOnAuthorClick }

                        menu_is_open = {props.menu_is_open}    
                        profile = { props.profile_post }
                        user = { props.user }
                        sendReaction= { props.sendReaction }
                        overrideReaction= { props.overrideReaction }
                        handleReactionsDetails = { props.handleReactionsDetails }
                        loadPostReactionsById = { props.loadPostReactionsById }
                        deleteReaction = { props.deleteReaction }
                        followPostProfile = { props.followPostProfile }                            
                        unfollowPostProfile = { props.unfollowPostProfile }                            
                        followPostPage = { props.followPostPage }                            
                        unfollowPostPage = { props.unfollowPostPage }

                        handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                        handleOnClickPollAnswer={ props.handleOnClickPollAnswer }
                        handleOnQuoteIconClick={props.handleOnQuoteIconClick}
                        handleProfilePostModal={ props.handleProfilePostModal }
                    />
                }

            </div>
        );
    }
}


class ProfileUnauthenticatedView extends Component {

    constructor( props ) {
        super( props );
        this.username = this.props.match.params.username;
        this.state = {
            tab_selected: 0,
            aptitude_id: null,
            share_menu_is_open: false,
            summary_type: '',
            selected_tournament: {},
            selected_video_profile: null,
            optionMenu_video_clip:false,
            type_modal_video_clip:'',
            type_action_modal_video_clip:'',
            share_clip_on_match: undefined,
            clip_match: undefined,
            information_type: 'stats',
            experience_type: '',
            edit_experience: false,
        }
    }

    number_of_showing_aptitudes = 2;
    number_of_showing_pages = 10;
    number_of_showing_profiles = 10;
    
    componentDidMount() {
        this.props.loadProfile( this.username );
        this.props.loadProfilePosts( this.username );
        this.props.loadAttributesConstants();
        //this.props.loadProfileFollowers( this.username );
        //this.props.loadProfileFollowing( this.username );
        //this.props.loadHomeProfileSuggestions();
        //this.props.loadHomePageSuggestions();
        this.props.loadAllProfileVideo( this.props.match.params.username );
        this.props.loadProfileAchievements( this.props.match.params.username );
        this.props.loadProfileTrajectories( this.props.match.params.username );   
    }

        
    componentDidUpdate( prevProps ) {
        if ( this.props.match.params.username !== prevProps.match.params.username ) {
            this.props.loadProfile( this.props.match.params.username );
            this.props.loadProfilePosts( this.props.match.params.username );
            this.props.loadAllProfileVideo( this.props.match.params.username );
            this.props.loadProfileAchievements( this.props.match.params.username );
            this.props.loadProfileTrajectories( this.props.match.params.username );    
            //this.props.loadProfileFollowers( this.props.match.params.username );
            //this.props.loadProfileFollowing( this.props.match.params.username );            
        }
        if( prevProps.state.profile.profile?.user?.username !== this.props.state.profile?.profile?.user?.username ){            
            this.props.addEventAction({Datetime: new Date().toISOString(),EventData: {EventType: 'profile-visit', ProfileId: this.props.state.profile.profile.id }});  //set Clickstream profile-visit event            
        }
    }

    handleOnProfileClick = ( profile ) => {
        this.props.history.push(`/profile/${profile.user.username}`);
        this.props.changeProfileFollowersModalStateAction( false );
        this.props.loadProfile( profile.user.username );
        this.props.loadProfilePosts( profile.user.username );
        this.props.loadProfileFollowers(profile.user.username);
        this.props.loadProfileFollowing(profile.user.username);
    }

    handleOnPageClick = ( page ) => {
        this.props.history.push(`/page/${page.pagename}`);
    }

    handleOnAuthorClickV2 = ( author ) => {
        this.props.history.push(`/${ author.type_object }/${ author.identifier }`);
        if(author.type_object == "profile"){
            this.props.changeProfileFollowersModalStateAction( false );
            this.props.loadProfile( author.identifier );
            this.props.loadProfilePosts( author.identifier );
            this.props.loadProfileFollowers( author.identifier );
            this.props.loadProfileFollowing( author.identifier );
        }
    }

    handleOnMessageIconClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCommentsClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCopyLinkClick = async (post) => {
        try {
            const url = `${web_url}/api/social/posts/${post.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('copyLink'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('copyLinkError'));
        }
    };

    handleOnShareProfileLinkClick = async () => {
        try {
            await navigator.clipboard.writeText(this.state.share_profile_url);
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('home:copyLink.copyLinkError'));
        }
    };

    handleReportPost = ( post ) => {
        this.setState({post_id: post});
    };

    handleBlockProfile = ( username ) => {
        this.setState({profile_username: username});
    };

    handleOnSummaryViewAll = (summary_type) => {
        this.setState({summary_type: summary_type})
        if(summary_type === 'tournaments_summary') {
            this.props.loadProfileTournamentStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        if(summary_type === 'victories_summary') {
            this.props.loadProfileAchievementsStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        if(summary_type === 'teams_summary') {
            this.props.loadProfileTeamStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
        }
        this.props.changeProfileTournamentsTeamsSummaryModalStateAction(true);
    }

    handleProfilePostModal=(post, modal_post_type) => {
        this.props.setProfileSelectedPostAction(post);
        switch(modal_post_type){
            case 'Formation':                
                this.props.changeProfilePostFormationModalStateAction(true);
            break;
            case 'Comment':
                this.props.changeProfilePostCommentModalStateAction(true);
            break;
            case 'Poll':
                this.props.changeProfilePollQuizPostModalStateAction(true);
                break;
            case 'Quiz':
                this.props.changeProfilePollQuizPostModalStateAction(true);
                break;
            case 'Media':
                this.props.changeProfilePostMediaModalStateAction(true);
                break;
            case 'presentationVideoClip':
                this.setState({type_modal_video_clip: modal_post_type});
                this.props.changeProfilePostVideoClipModalStateAction(true);
                break;
            case 'featuredVideoClip':
                this.props.changeProfilePostVideoClipModalStateAction(true);
                this.setState({type_modal_video_clip: modal_post_type});
                break;
            case 'matchVideoClip':
                this.props.changeProfilePostVideoClipModalStateAction(true);
                this.setState({type_modal_video_clip: modal_post_type});
                break;
            // no default
        }
    }

    handleOnMatch = (tournament_id,phase_id, match_date_id, match_id) => {
        this.props.history.push(`/tournament/hub/${tournament_id}&phase=${phase_id}&match_date=${match_date_id}&match=${match_id}&type=match&participate=False`);
    }

    render() {

        return (

            <React.Fragment>
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: ${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (${this.props.state.profile?.profile?.user?.username})`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (@${this.props.state.profile?.profile?.user?.username})`} />
                    <meta property="og:description" content={`${this.props.state.profile?.profile?.attributes?.description?.value}`} />
                    <meta property="og:image" content={this.props.state.profile?.profile?.user?.photo? this.props.state.profile?.profile?.user?.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta property="og:url" content={`${web_url}/profile/${this.props.state.profile?.profile?.user?.username}`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content={`${this.props.state.profile?.profile?.user?.first_name} ${this.props.state.profile?.profile?.user?.last_name} (@${this.props.state.profile?.profile?.user?.username})`} />
                    <meta name="twitter:description" content={`${this.props.state.profile?.profile?.attributes?.description?.value}`} />
                    <meta name="twitter:image" content={this.props.state.profile?.profile?.user?.photo? this.props.state.profile?.profile?.user?.photo : `${web_url}/logo_baloa_social_share.png`} />
                    <meta name="twitter:url" content={`${web_url}/profile/${this.props.state.profile?.profile?.user?.username}`} />
                </Helmet>

                {/* Profile Modals */}

                {/* Profile Photo Modal */}
                <ProfileUploadPhotoModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.upload_photo_is_open } 
                    onClose={() => { this.props.changeProfileUploadPhotoModalStateAction( false ) }}
                />

                {/* Profile Basic Info Modal */}
                <ProfileBasicInfoModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.basic_info_is_open } 
                    onClose={() => { this.props.changeProfileBasicInfoModalStateAction( false ) }}
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_profile_type ) => { 
                        this.props.updateProfileData( this.props.state.profile.profile.user?.username, new_profile_type );
                        this.props.changeProfileBasicInfoModalStateAction( false );
                        if(new_profile_type === 'professional'){
                            this.props.changeProfileSpecialtiesModalStateAction( true )
                        }
                    } }
                />

                {/* Profile Nationality Modal */}
                <ProfileNationalityModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.nationality_is_open } 
                    onClose={() => { this.props.changeProfileNationalityModalStateAction( false ) }}
                    handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                    handleSearchLocation={( nationality, new_location_search ) => { this.props.searchProfileLocations( nationality, new_location_search ) }}
                    model = { this.props.state.profile.profile }
                    nationalities = { this.props.state.profile.nationalities_search_results }
                    locations = { this.props.state.profile.locations_search_results }
                    onNewData = { ( new_nationality_data ) => { 
                        this.props.changeProfileNationalityModalStateAction( false )
                        this.props.addProfileNationality( this.props.state.profile.profile, new_nationality_data ) } }
                />

                {/* Profile Description Modal */}
                <ProfileDescriptionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.description_is_open } 
                    onClose={() => { this.props.changeProfileDescriptionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.general } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) =>{ 
                        this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data );
                        this.props.changeProfileDescriptionModalStateAction( false );
                    }}
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( new_errors, 'general' ) }
                />

                {/* Profile Technics Primary Team Modal */}
                <ProfileDataSheetModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.primary_team_is_open } 
                    onClose={() => { this.props.changeProfileDataSheetModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                />

                {/* Profile Technics Primary Position Modal */}
                {/* <ProfileTechnicsPrimaryPositionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.primary_position_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsPrimaryPositionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Technics Secondary Position Modal */}
                {/* <ProfileTechnicsSecondaryPositionModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.secondary_position_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsSecondaryPositionModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Technics Meassures Modal */}
                {/* <ProfileTechnicsMeassuresModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.meassures_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsMeassuresModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Technics Laterality Modal */}
                {/* <ProfileTechnicsLateralityModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.technics?.laterality_is_open } 
                    onClose={() => { this.props.changeProfileTechnicsLateralityModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.technics } 
                    model = { this.props.state.profile.profile }
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.technics, ...new_errors }, 'technics' ) }
                /> */}

                {/* Profile Experiences Modal */}
                <ProfileExperiencesModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.experiences_is_open }                     
                    onClose={() => { this.props.changeProfileExperiencesModalStateAction( false ) }}
                    errors = { this.props.state.profile.errors.experiences } 
                    experience = { this.props.state.profile.experience }                    
                    onNewData = { ( new_attribute_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_attribute_data ) }                    
                    onResetError = { ( new_errors ) => this.props.loadProfileErrorsAction( { ...this.props.state.profile.errors.experiences, ...new_errors }, 'experiences' ) }
                    is_profile = { true }
                />

                {/* Profile Specialties Modal */}
                <ProfileSpecialtiesModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals?.specialties_is_open } 
                    onClose={() => { this.props.changeProfileSpecialtiesModalStateAction( false ) }}
                    specialties = { this.props.state.attributes.constants.specialties }
                    specialty = {this.props.state.profile.profile.attributes.specialty }
                    onNewData = { ( new_specialty_data ) => this.props.createOrEditAttributeOnProfile( this.props.state.profile.profile, new_specialty_data ) }
                />                

                {/* Profile followers Modal */}
                <ProfileFollowersModalComponent
                    onClick={() => {}}
                    isOpen={ this.props.state.profile.modals?.followers_is_open } 
                    onClose={ () => {this.props.changeProfileFollowersModalStateAction( false ) }}
                    followers={ this.props.state.profile.followers }
                    following={ this.props.state.profile.following }
                    onFollowProfile = { ( profile_username, username ) => { this.props.followProfileByUsername( profile_username, username ) } }
                    current_username= { this.username }
                    onUnfollowProfile={ ( profile_username, username ) => { this.props.unfollowProfileByUsername( profile_username, username ) } }
                    onFollowPage = { ( page_pagename, username ) => { this.props.followPageByUsername( page_pagename, username ) } }
                    onUnfollowPage = { ( page_pagename, username ) => { this.props.unfollowPageByUsername( page_pagename, username ) } }
                    handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                    handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                />
                
                {/* Profile validate Aptitudes Modal */}
                <ProfileInviteToValidateAptitudeModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.profile.modals.invite_to_validate_aptitude.is_open } 
                    onClose={() => { this.props.changeInviteToValidateAptitudeModalState( false, undefined ) }}
                    followers={ this.props.state.profile.followers }
                    aptitude={ this.props.state.profile.modals.invite_to_validate_aptitude?.aptitude }
                    onInviteToValidate = { ( aptitude, username ) => { this.props.inviteProfileToValidateAttribute( aptitude, username ) } }
                />

                {/* Report Post Modal */}
                <ReportModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.report_is_open } 
                    onClose={() => { this.props.changeReportPostModalStateAction( false ) }}
                    post_id={this.state.post_id}
                    reportPostById={ (post_id, denunciation) => {this.props.reportPostById(post_id, denunciation)}}
                    openAcceptReport={ () => this.props.changeAcceptReportPostModalStateAction( true ) }
                />

                {/* Block Profile Modal */}
                <BlockModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.block_is_open } 
                    onClose={() => { this.props.changeBlockPostModalStateAction( false ) }}
                    profile_username={this.state.profile_username}
                    blockProfile={ (username) => {this.props.blockProfile(username)}}
                />

                {/* Accept Report Modal */}
                <AcceptReportModalComponent
                    onClick={() => {}} 
                    isOpen={ this.props.state.post.modals?.accept_report_is_open } 
                    onClose={() => { this.props.changeAcceptReportPostModalStateAction( false ) }}
                />

                <ProfileTournamentsTeamsSummaryModalComponent 
                    isOpen={ this.props.state.profile.modals?.profile_tournaments_teams_summary } 
                    onClose={() => { 
                        this.props.clearProfileStatsAction(); 
                        this.props.changeProfileTournamentsTeamsSummaryModalStateAction( false ) } 
                    }
                    summary_type={this.state.summary_type}
                    is_owner={this.props.state.profile.profile.is_owner}
                    profile_stats_next_page={this.props.state.profile?.profile_stats_next_page}
                    profile_stats={this.props.state.profile?.profile_stats}
                    fetchMoreData={() => {
                        if(this.state.summary_type === 'tournaments_summary') {
                            this.props.loadProfileTournamentStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
                        }
                        if(this.state.summary_type === 'victories_summary') {
                            this.props.loadProfileAchievementsStats(this.props.state.profile.profile.user.username, this.props.state.profile.profile_stats_next_page);
                        }
                    }}
                />

                <ProfilePostFormationModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_formation}
                    onClose={()=>{this.props.changeProfilePostFormationModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnComment = { () => this.props.changeSignUpLoginModal(true) }

                    openReportPostModal={() => this.props.changeSignUpLoginModal(true)}
                    handleReportPost={() => this.props.changeSignUpLoginModal(true)}

                    openBlockPostModal={() => this.props.changeSignUpLoginModal(true)}
                    handleBlockProfile={() => this.props.changeSignUpLoginModal(true)}

                    handleOnDeletePostClick = {() => this.props.changeSignUpLoginModal(true)}

                    handleOnCopyLinkClick = {() => this.props.changeSignUpLoginModal(true)}
                    
                    handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }

                    changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }

                    menu_is_open = {true}
                    sendReaction= { () => this.props.changeSignUpLoginModal(true)}
                    overrideReaction= { () => this.props.changeSignUpLoginModal(true)}
                    handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                    loadPostReactionsById = { () => this.props.changeSignUpLoginModal(true) }
                    deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                    followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }

                    handleOnQuoteIconClick = { () => this.props.changeSignUpLoginModal(true) }
                />

                <ProfilePostQuizPollModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_quiz_poll}
                    onClose={()=>{this.props.changeProfilePollQuizPostModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleReportPost={ () => this.props.changeSignUpLoginModal(true) }

                    openBlockPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleBlockProfile={ () => this.props.changeSignUpLoginModal(true) }

                    handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }
                    
                    handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }

                    changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }

                    menu_is_open = {true}
                    followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            

                    handleOnClickQuizAnswer={ () => this.props.changeSignUpLoginModal(true) }
                    handleOnClickPollAnswer={ () => this.props.changeSignUpLoginModal(true) }
                    handleOnQuoteIconClick = { () => this.props.changeSignUpLoginModal(true) }
                />

                <ProfilePostCommentModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_comment}
                    onClose={()=>{this.props.changeProfilePostCommentModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnComment = { () => this.props.changeSignUpLoginModal(true) }

                    openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleReportPost={ () => this.props.changeSignUpLoginModal(true) }

                    openBlockPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleBlockProfile={ () => this.props.changeSignUpLoginModal(true) }

                    handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }

                    handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                    
                    handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }

                    changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }

                    menu_is_open = {true}
                    sendReaction= { () => this.props.changeSignUpLoginModal(true) }
                    overrideReaction= { () => this.props.changeSignUpLoginModal(true) }
                    handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                    loadPostReactionsById = { () => this.props.changeSignUpLoginModal(true) }
                    deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                    followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }
                    
                    handleOnQuoteIconClick = { () => this.props.changeSignUpLoginModal(true) }
                />    
                <ProfilePostMediaModalComponent 
                    isOpen={this.props.state.profile?.modals.profile_post_media}
                    onClose={()=>{this.props.changeProfilePostMediaModalStateAction(false);}}
                    post={this.props.state.profile?.profile_selected_post}

                    //Actions
                    handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnComment = { () => this.props.changeSignUpLoginModal(true) }

                    openReportPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleReportPost={ () => this.props.changeSignUpLoginModal(true) }

                    openBlockPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleBlockProfile={ () => this.props.changeSignUpLoginModal(true) }

                    handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }

                    handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                    
                    handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }

                    changeHomeEditPostModal = { () => this.props.changeSignUpLoginModal(true) }

                    menu_is_open = {true}
                    sendReaction= { () => this.props.changeSignUpLoginModal(true) }
                    overrideReaction= { () => this.props.changeSignUpLoginModal(true) }
                    handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                    loadPostReactionsById = { () => this.props.changeSignUpLoginModal(true) }
                    deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                    followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }

                    handleOnQuoteIconClick = { () => this.props.changeSignUpLoginModal(true) }
                />

                {/* Profile tournaments matches modal*/}
                <ProfileTournamentsMatchesModalComponent
                    isOpen={this.props.state.profile?.modals.profile_tournaments_matches}
                    onClose={()=>{this.props.changeProfileTournamentsMatchesModalStateAction(false)}}
                    profile_matches={this.props.state.profile.profile_matches}
                    profile_tournaments={this.props.state.profile.profile_tournaments_matches_data}
                    profile_matches_next_page={this.props.state.profile.profile_matches_next_page}
                    handleOnVideoChange={() => this.props.changeSignUpLoginModal(true)}
                    setClipMatch={(match_id)=>{ this.setState({clip_match: match_id})}}
                    handleModalUnlockData= { () => this.props.changeSignUpLoginModal(true) }
                    loadMatches={() => { this.props.loadProfileMatches(this.props.state.profile.profile.user.username, this.props.state.profile.profile_matches_next_page) } }
                    matchesFilter={ (tournamentsListString, matchesOrder)=> this.props.loadProfileMatches(this.props.state.profile.profile.user.username, null, tournamentsListString, matchesOrder ) }
                    handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                    has_unbloqued_tournaments={ this.props.state.profile.profile.profile_pay_a_tournament } //Tiene uno o mas torneos desbloqueados
                    has_pending_tournaments={ this.props.state.profile.profile.profile_tournament_paid} //Tiene torneos pendientes por desbloquear
                    is_owner={ this.props.state.profile.profile.is_owner }
                    handleOnMatch ={ (tournament_id,phase_id, match_date_id, match_id)=>{ this.handleOnMatch(tournament_id,phase_id, match_date_id, match_id) } }
                    username={ this.props.state.profile.profile?.user?.username }
                />

                {/** Profile Videos Modal  */}
                <ProfilePostVideoClipsComponent
                    isOpen={this.props.state.profile?.modals.profile_post_video_clips}
                    onClose={()=>{                        
                        this.setState({type_modal_video_clip: '', type_action_modal_video_clip: ''});
                        this.props.changeProfilePostVideoClipModalStateAction(false);
                    }}
                    post={this.props.state.profile?.profile_selected_post}
                    //Actions
                    handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnComment = { () => this.props.changeSignUpLoginModal(true)}
                    openReportPostModal={() => this.props.changeSignUpLoginModal(true) }
                    handleReportPost={ () => this.props.changeSignUpLoginModal(true) }
                    openBlockPostModal={ () => this.props.changeSignUpLoginModal(true) }
                    handleBlockProfile={ () => this.props.changeSignUpLoginModal(true) }
                    handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                    handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }
                    changeHomeEditPostModal = {() => this.props.changeSignUpLoginModal(true) }
                    menu_is_open = {true}
                    sendReaction= { () => this.props.changeSignUpLoginModal(true) }
                    overrideReaction= { () => this.props.changeSignUpLoginModal(true) }
                    handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                    loadPostReactionsById = { () => this.props.changeSignUpLoginModal(true) }
                    deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                    followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                    followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                    unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }
                    handleVideoClipOptionMenu={() => this.props.changeSignUpLoginModal(true)}
                    type_modal_video_clip={this.state.type_modal_video_clip}
                    me_photo={this.props.state.user.me.photo}
                />

                {/** Profile view more featured clips */}
                <ProfileFeaturedClipsViewMoreModalComponent
                    isOpen={this.props.state.profile.modals.view_more_featured_clips }
                    onClose={()=>{ this.props.changeProfileFeaturedClipsViewMoreModalStateAction(false) }}
                    handleOnVideoChange={ () => this.props.changeSignUpLoginModal(true) }
                    profile_featured_clip={this.props.state.profile.profile_featured_clip}
                    profile_presentation_video={this.props.state.profile.profile_presentation_video ?? null}
                    handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                    isProfileOwner={this.props.state.profile.profile.is_owner}
                    loading_profile_featured_videos={ this.props.state.profile.loading_profile_featured_videos }
                    profile_featured_clip_next_page={ this.props.state.profile.profile_featured_clip_next_page }
                    loading_profile_featured_videos_next={ this.props.state.profile.loading_profile_featured_videos_next }
                    loadMoreFeaturedClips={ ()=>{ this.props.loadMoreProfileVideo(this.props.state.profile.profile_featured_clip_next_page) } }
                />

                {/* Profile Detail */}
                
                <div className="profile">
                    {this.props.state.profile.profile.loading
                    ?<div className="profile__left-container">
                        <div className="profile-info profile__left-container__my-pages-info">
                            <Skeleton animation="wave" variant="rect" component="div" className="profile-info__photo_skeleton" />
                            <Skeleton animation="wave" className="profile-info__fullname baloa-headline-5" />
                            <Skeleton animation="wave" className="profile-info__username baloa-subtitle-2" />
                            <SeparatorComponent className="profile-info__separator"/>
                            <div className="profile-info__follows">
                                <Skeleton animation="wave" width="40%"/>&nbsp;&nbsp;&nbsp;
                                <Skeleton animation="wave" width="40%"/>
                            </div>
                        </div>
                        <div className="profile__left-container__my-pages">
                            <p className="home__right-container__subtitle baloa-subtitle-1">
                                {this.props.state.profile.profile.is_owner ? this.props.t('pages.title1') : this.props.t('pages.title2')}
                            </p>
                            <div class="my-pages profile__left-container__my-pages-info">
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            <Skeleton animation="wave" variant="rect" component="div" className="my-pages__suggestion" />
                            </div>
                        </div>
                    </div>
                    :<div className="profile__left-container">

                        {/* Profile Info */}
                        {/* <ProfileInformationComponent 
                            className="profile__left-container__info"
                            profile = { this.props.state.profile.profile }
                            is_owner = { this.props.state.profile.profile?.user?.username === this.props.state.user.me?.username }
                            onEditDescription = { () => this.props.changeSignUpLoginModal(true) }
                            onFollow = { () => this.props.changeSignUpLoginModal(true) }
                            onUnfollow = { () => this.props.changeSignUpLoginModal(true) }
                            onNewPhoto={ () => this.props.changeProfileUploadPhotoModalStateAction( true ) }
                            onFollowers={ () => this.props.changeSignUpLoginModal(true)}                            
                            handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                        /> */}

                                    
                    </div>
                    }
                    <div className="profile__center-container unauth">
                        {/* Profile Info */}
                        <ProfileInformationComponent 
                            className="profile__left-container__info"
                            profile = { this.props.state.profile.profile }
                            is_owner = { this.props.state.profile.profile?.user?.username === this.props.state.user.me?.username }
                            onEditDescription = { () => this.props.changeSignUpLoginModal(true) }
                            onFollow = { () => this.props.changeSignUpLoginModal(true) }
                            onUnfollow = { () => this.props.changeSignUpLoginModal(true) }
                            onNewPhoto={ () => this.props.changeProfileUploadPhotoModalStateAction( true ) }
                            onFollowers={ () => this.props.changeSignUpLoginModal(true)}                            
                            handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                            handleOnShareProfile={ ()=>{ this.handleOnShareProfileLinkClick() }}
                        />
                        {this.props.state.profile.profile.profile_type === 'player' && ((this.props.state.profile.profile_featured_clip && this.props.state.profile.profile_featured_clip.length > 0) || this.props.state.profile.profile_presentation_video ) &&
                            <ProfileFeaturedClips
                                //handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                                profile_featured_clip={this.props.state.profile.profile_featured_clip}
                                profile_presentation_video={this.props.state.profile.profile_presentation_video ?? null}
                                handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                                isProfileOwner={false}
                                loading_profile_featured_videos={this.props.state.profile.loading_profile_featured_videos}
                                view_more_featured_clips={()=>{ this.props.changeProfileFeaturedClipsViewMoreModalStateAction(true) }}
                            />
                        }  
                        <Tabs
                            variant="fullWidth"
                            className="profile__center-container__tabs"
                            value={ this.state.tab_selected }
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={ ( ev, new_value ) => { this.setState( { tab_selected: new_value } ) } }
                            aria-label="Selection between "
                        >                            
                            <Tab label={this.props.t('pages.tabs.tabData')} />
                            <Tab label={this.props.t('pages.tabs.tabPosts')} />
                        </Tabs>                        
                        
                        {/* Profile Data */}
                        <TabPanel 
                            index={ 0 } 
                            value={ this.state.tab_selected } 
                            profile={ this.props.state.profile.profile }
                            onNewBasicInfoButton = { () => this.props.changeSignUpLoginModal(true) }
                            onNewTechnicsButton = {() => this.props.changeSignUpLoginModal(true) }
                            onNewExperiencesButton = { () => this.props.changeSignUpLoginModal(true) }
                            onNewSpecialtiesButton = { () => this.props.changeSignUpLoginModal(true) }
                            onEditExperiencesButton = { () => this.props.changeSignUpLoginModal(true)} 
                            onDeleteExperiencesButton = { () => this.props.changeSignUpLoginModal(true) } 
                            onNewAptitudesButton = { () => this.props.changeSignUpLoginModal(true) }
                            errors = { this.props.state.profile.errors }
                            handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }
                            handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                            onValidateAptitude = { () => this.props.changeSignUpLoginModal(true) }
                            onInviteToValidateAptitude = { () => this.props.changeSignUpLoginModal(true) }
                            posts = { this.props.state.profile.posts }
                            loadProfilePosts = { () => {} }
                            handleOnSummaryViewAll={(summary_type) => { this.handleOnSummaryViewAll(summary_type) }}
                            profile_tournament_paid={this.props.state.profile.profile?.profile_tournament_paid}
                            handleModalUnlockData={() => this.props.changeSignUpLoginModal(true)}
                            profile_matches={this.props.state.profile.profile_matches}
                            handleOnViewAllMatches={(new_state)=>{ this.props.changeProfileTournamentsMatchesModalStateAction(new_state)}}
                            handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                            handleOnVideoChange={() => this.props.changeSignUpLoginModal(true)}
                            setClipMatch={(match_id)=>{ this.setState({clip_match: match_id})}}
                            has_unbloqued_tournaments={ this.props.state.profile.profile.profile_pay_a_tournament } //Tiene uno o mas torneos desbloqueados
                            has_pending_tournaments={ this.props.state.profile.profile.profile_tournament_paid} //Tiene torneos pendientes por desbloquear
                            user_me={ this.props.state.user.me.id }
                            handleOnMatch ={ (tournament_id,phase_id, match_date_id, match_id)=>{ this.handleOnMatch(tournament_id,phase_id, match_date_id, match_id) } }
                            information_type={this.state.information_type}
                            handleFilterInfo={(filter_type) => {this.setState({information_type: filter_type})}}
                            infoAndStatsTabTitle = {this.props.t('tournamentSummary.infoAndStatsTabTitle')}
                            achievementsAndCareerTabTitle = {this.props.t('profileExperiences.achievementsAndCareerTabTitle')}
                        />

                        {/* Profile Feed */}
                        <TabPanel 
                            index={ 1 } 
                            value={ this.state.tab_selected } 
                            profile={ this.props.state.profile.profile }

                            posts = { this.props.state.profile.posts }
                            profile_suggestions = { [] }
                            me_photo = { this.props.state.user.me.photo }

                            loadProfilePosts = { () => this.props.loadProfilePosts( this.username, this.props.state.profile.loading_posts, this.props.state.profile.posts_next_page ) }
                            
                            handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                            handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }

                            handleClap = { () => this.props.changeSignUpLoginModal(true) }
                            onCloseClapsModal={() => this.props.changeSignUpLoginModal(true)}
                            loadPostClapsById={() => this.props.changeSignUpLoginModal(true)}

                            handleOnMessageIconClick = { () => this.props.changeSignUpLoginModal(true) }
                            handleOnCommentsClick = { () => this.props.changeSignUpLoginModal(true) }
                            handleOnComment = { () => this.props.changeSignUpLoginModal(true)}

                            openReportPostModal={() => this.props.changeSignUpLoginModal(true)}
                            handleReportPost={ () => this.props.changeSignUpLoginModal(true) }

                            openBlockPostModal={() => this.props.changeSignUpLoginModal(true)}
                            handleBlockProfile={() => this.props.changeSignUpLoginModal(true)}

                            handleOnDeletePostClick = { () => this.props.changeSignUpLoginModal(true) }

                            handleOnCopyLinkClick = { () => this.props.changeSignUpLoginModal(true) }
                            
                            deletePost = { () => this.props.changeSignUpLoginModal(true) }

                            handleOnAuthorClick = { () => this.props.changeSignUpLoginModal(true) }

                            menu_is_open = {true}
                            profile_post = { this.props.state.profile } //Se renombra paramatro "profile" del FeedComponentV2
                            user = { this.props.state.user }
                            sendReaction= { () => this.props.changeSignUpLoginModal(true)}
                            overrideReaction= { () => this.props.changeSignUpLoginModal(true)}
                            handleReactionsDetails = { () => this.props.changeSignUpLoginModal(true) }
                            loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                            deleteReaction = { () => this.props.changeSignUpLoginModal(true) }
                            followPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                            unfollowPostProfile= { () => this.props.changeSignUpLoginModal(true) }                            
                            followPostPage= { () => this.props.changeSignUpLoginModal(true) }                            
                            unfollowPostPage= { () => this.props.changeSignUpLoginModal(true) }

                            handleOnClickQuizAnswer={ () => this.props.changeSignUpLoginModal(true) }
                            handleOnClickPollAnswer={ () => this.props.changeSignUpLoginModal(true) }

                            handleProfilePostModal={ ( post, modal_post_type )=>{ this.handleProfilePostModal( post, modal_post_type ) }}
                        />


                    </div>

                    <div className="profile__right-container">
                        {/* <p className="home__right-container__title baloa-headline-6">{this.props.t('pages.title3')}</p> */}
                       
                        {/* <ProfileSuggestionsComponent
                            profiles = { this.props.state.home.profile_suggestions.slice(0, this.number_of_showing_profiles) }
                            className = "home__right-container__profile-suggestions sm"
                            handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                            loading_profile_suggestions = { this.props.state.home.loading_profile_suggestions }
                        /> */}
                       
                        {/* <p className="home__right-container__subtitle baloa-headline-6">{this.props.t('pages.text')}</p> */}
                        
                        {/* <PageSuggestionsComponent
                            pages = { this.props.state.home.page_suggestions.slice(0, this.number_of_showing_pages) }
                            className = "home__right-container__pages-suggestions sm"
                            handleFollow = { ( page ) => { this.props.followPage( page ) } }
                            loading_page_suggestions = { this.props.state.home.loading_page_suggestions }
                        /> */}
                        
                        {/*<div className="home__right-container__cta-pages">
                            <p className="home__right-container__cta-pages__subtitle baloa-subtitle-1">{this.props.t('pages.text2')}</p>
                            <p className="home__right-container__cta-pages__text baloa-body-2">{this.props.t('pages.text3')}</p>
                            <SecondaryButtonComponent 
                            onClick={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }} 
                            className="home__right-container__cta-pages__new"
                            >
                                <span>{this.props.t('pages.button')}</span>
                            </SecondaryButtonComponent>
                        </div>*/}
                    </div>

                </div>

            </React.Fragment>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProfile: ( username ) => {
            dispatch( loadProfile( username ) )
        },
        loadAttributesConstants: () => {
            dispatch( loadAttributesConstants() )
        },       
        loadProfilePosts: ( username, loading = false, next_page ) => {
            dispatch( loadProfilePosts( username, loading, next_page ) )
        },
        loadPage: ( pagename ) => {
            dispatch( loadPage( pagename ) )
        },
        updateProfileData: ( profile, new_profile_type ) => {
            dispatch( updateProfileData( profile, new_profile_type ) )
        },
        followProfile: ( profile ) => {
            dispatch( followProfile( profile ) )
        },
        unfollowProfile: ( profile ) => {
            dispatch( unfollowProfile( profile ) )
        },
        changeProfileUploadPhotoModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileUploadPhotoModalStateAction( new_modal_state ) )
        },
        changeProfileBasicInfoModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileBasicInfoModalStateAction( new_modal_state ) )
        },
        changeProfileNationalityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileNationalityModalStateAction( new_modal_state ) )
        },
        changeProfileDescriptionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileDescriptionModalStateAction( new_modal_state ) )
        },
        changeProfileDataSheetModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileDataSheetModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsPrimaryPositionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsPrimaryPositionModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsSecondaryPositionModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsSecondaryPositionModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsMeassuresModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsMeassuresModalStateAction( new_modal_state ) )
        },
        changeProfileTechnicsLateralityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileTechnicsLateralityModalStateAction( new_modal_state ) )
        },
        changeProfileExperiencesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileExperiencesModalStateAction( new_modal_state ) )
        },
        changeProfileSpecialtiesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileSpecialtiesModalStateAction( new_modal_state ) )
        },
        changeProfileAptitudesModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileAptitudesModalStateAction( new_modal_state ) )
        },
        changeProfileAptitudesAdminModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileAptitudesAdminModalStateAction( new_modal_state ) )
        },
        createOrEditAttributeOnProfile: ( profile, new_attribute_data ) => {
            dispatch( createOrEditAttributeOnProfile( profile, new_attribute_data ) )
        },
        loadProfileErrorsAction: ( errors, section ) => {
            dispatch( loadProfileErrorsAction( errors, section ) )
        },
        deleteAttribute: ( profile, attribute_data ) => {
            dispatch( deleteAttribute( profile, attribute_data ) )
        },
        clapPost: ( post ) => {
            return dispatch( clapPost( post ) )
        },
        loadProfileFollowers: ( username ) => {
            return dispatch( loadProfileFollowers( username ) )
        },
        changeProfileFollowersModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileFollowersModalStateAction( new_modal_state ) )
        },
        loadProfileFollowing: ( username ) => {
            return dispatch( loadProfileFollowing( username ) )
        },
        followProfileByUsername: ( profile_username, username ) => {
            dispatch( followProfileByUsername( profile_username, username ) )
        },
        unfollowProfileByUsername: ( profile_username, username ) => {
            dispatch( unfollowProfileByUsername( profile_username, username) )
        },
        followPageByUsername: ( page_pagename, username ) => {
            dispatch( followPageByUsername( page_pagename, username) )
        },
        unfollowPageByUsername: ( page_pagename, username ) => {
            dispatch( unfollowPageByUsername( page_pagename, username) )
        },
        editProfileExperiencesAction: ( experience ) => {
            dispatch( editProfileExperiencesAction( experience ) )
        },
        deleteProfileExperiencesAction: ( attribute_data ) => {            
            dispatch( deleteProfileExperiencesAction( attribute_data ) )
        },
        deletePost: ( post ) => {
            return dispatch( deletePost( post ) )
        },
        changeHomeEditPostModal: ( new_state, post ) => {
            return dispatch( changeHomeEditPostModal( new_state, post ) )
        },
        changeInviteToValidateAptitudeModalState: ( new_modal_state, aptitude ) => {
            dispatch( changeInviteToValidateAptitudeModalStateAction( new_modal_state, aptitude ) )
        },
        inviteProfileToValidateAttribute: ( attribute, username ) => {
            dispatch( inviteProfileToValidateAttribute( attribute, username ) )
        },
        validateAttribute: ( attribute ) => {
            dispatch( validateAttribute( attribute ) )
        },
        searchProfileNationality: (new_nationality_search) => {
            return dispatch( searchProfileNationality( new_nationality_search ) )
        },
        searchProfileLocations: ( nationality, new_location_search ) => {
            return dispatch( searchProfileLocations( nationality, new_location_search ) )
        },
        addProfileNationality: ( profile, new_nationality_data ) => {
            return dispatch( addProfileNationality( profile, new_nationality_data ) )
        },
        loadHomeProfileSuggestions: () => {
            return dispatch( loadHomeProfileSuggestions() )
        },
        loadHomePageSuggestions: () => {
            return dispatch( loadHomePageSuggestions() )
        },
        followPage: ( page ) => {
            return dispatch( followPage( page ) )
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        changePageNewPageTypeModalStateAction: ( new_modal_state ) => {
            return dispatch( changePageNewPageTypeModalStateAction( new_modal_state ) )
        },
        changePostClapsModalStateAction: ( new_modal_state ) => {
            dispatch( changePostClapsModalStateAction( new_modal_state ) )
        },
        loadPostClapsById: ( post_id ) => {
            dispatch( loadPostClapsById( post_id ))
        },
        changeReportPostModalStateAction: ( new_modal_state ) => {
            dispatch( changeReportPostModalStateAction( new_modal_state ))
        },
        changeBlockPostModalStateAction: (new_modal_state) => {
            dispatch( changeBlockPostModalStateAction(new_modal_state))
        },
        changeAcceptReportPostModalStateAction: (new_modal_state) => {
            dispatch( changeAcceptReportPostModalStateAction(new_modal_state))
        },
        switchProfileToPage: ( page ) => {
            return dispatch( switchProfileToPage( page ) )
        },
        commentPostById: ( post_id, comment ) => {            
            return dispatch( commentPostById( post_id, comment ))
        },
        addEventAction: ( data ) => {
            return dispatch( addEventAction( data ) )
        },
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
        changeProfileTournamentsTeamsSummaryModalStateAction: (new_modal_state) => {
            dispatch( changeProfileTournamentsTeamsSummaryModalStateAction(new_modal_state) );
        },
        loadProfileTournamentStats: (username, next_page) => {
            dispatch( loadProfileTournamentStats(username, next_page) );
        },
        loadProfileAchievementsStats: (username, next_page) => {
            dispatch( loadProfileAchievementsStats(username, next_page) );
        },
        loadProfileTournaments: (username, next_page) => {
            dispatch( loadProfileTournaments(username, next_page) );
        },
        clearProfileStatsAction: () => {
            dispatch( clearProfileStatsAction() );
        },
        loadProfileTeamStats: (username, next_page) => {
            dispatch( loadProfileTeamStats(username, next_page) );
        },
        setProfileSelectedPostAction: (post) => {
            dispatch( setProfileSelectedPostAction(post) );
        },
        changeProfilePostFormationModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostFormationModalStateAction(new_modal_state) );
        },
        changeProfilePollQuizPostModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePollQuizPostModalStateAction(new_modal_state) );
        },
        changeProfilePostCommentModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostCommentModalStateAction(new_modal_state) );
        },
        changeProfilePostMediaModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostMediaModalStateAction(new_modal_state) );
        },
        changeProfileTournamentsMatchesModalStateAction: (new_modal_state) => {
            dispatch( changeProfileTournamentsMatchesModalStateAction(new_modal_state) );
        },
        changeProfilePostVideoClipModalStateAction: (new_modal_state) => {
            dispatch( changeProfilePostVideoClipModalStateAction(new_modal_state) );
        },
        loadAllProfileVideo:( username )=>{
            dispatch(loadAllProfileVideo( username ));
        },
        loadMoreProfileVideo:( next_page )=>{
            dispatch(loadMoreProfileVideo( next_page ));
        },
        loadProfileMatches: ( username, next_page, tournaments_filter, old_matches ) => {
            return dispatch( loadProfileMatches( username, next_page, tournaments_filter, old_matches ) );
        },
        loadProfileAchievements:( username )=>{
            dispatch(loadProfileAchievements( username ));
        },
        loadProfileTrajectories:( username )=>{
            dispatch(loadProfileTrajectories( username ));
        },
        changeProfileFeaturedClipsViewMoreModalStateAction: (new_modal_state) => {
            dispatch( changeProfileFeaturedClipsViewMoreModalStateAction(new_modal_state) );
        },
    }
}


export default compose(
    withTranslation("profile"),
    connect(mapStateToProps, mapDispatchToProps)
)(ProfileUnauthenticatedView);