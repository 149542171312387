
// React
import React from 'react';

// Globals
// import { api_url } from "config/GlobalData";

// Cropper
import Cropper from 'react-easy-crop'

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
// import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './LeagueTeamUploadImageModalComponent.styles.css';

// Assets
import blankProfile from 'assets/icons/blankProfile.svg';


// Utilities
import getCroppedImg from 'shared/functions/CropImage';
import Loader from "react-loader-spinner";

// i18n
import { withTranslation } from 'react-i18next';


function LeagueTeamUploadImageModalComponent(props) {

   const [selectedImage, setSelectedImage] = React.useState(null);
   const [selectedImageUrl, setSelectedImageUrl] = React.useState(null);
   //const [imageInputRef, setImageInputRef] = React.useState();
   // const [uploadProgress, setUploadProgress] = React.useState( 0 );
   const [crop, setCrop] = React.useState( { x: 0, y: 0 } );
   const [zoom, setZoom] = React.useState(1);
   const [aspect, setAspect] = React.useState(props.aspect);  // eslint-disable-line
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState( {} );
   const [croppedImage, setCroppedImage] = React.useState(null);
   const [croppedImageUrl, setCroppedImageUrl] = React.useState(null);

   const [imageB64, setImageB64] = React.useState('');


   const [state, setState] = React.useState({image_input_ref: null,});

   /*const [state, setState] = React.useState(
         {
            selected_image: null,
            selected_image_url: null,
            image_input_ref: null,
            upload_progress: 0,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1,
            cropped_area_pixels: {},
            cropped_image: null,
            cropped_image_url: null,
         }
   );*/

   /*state = {
      selected_image: null,
      selected_image_url: null,
      image_input_ref: null,
      upload_progress: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      cropped_area_pixels: {},
      cropped_image: null,
      cropped_image_url: null,
   };*/

   const onCropChange = crop => {
      //setState({ ...state, crop: crop })
      setCrop(crop);
   }

   const onCropComplete = (cropped_area, cropped_area_pixels) => {
      //setState({...state, cropped_area_pixels: cropped_area_pixels })
      setCroppedAreaPixels(cropped_area_pixels);
   }

   const onZoomChange = zoom => {
      //setState({ ...state, zoom: zoom })
      setZoom(zoom);
   }

   const onImageChange = (event) => {
      setSelectedImage(event.target.files[0]);
      setSelectedImageUrl(URL.createObjectURL(event.target.files[0]));
   };

   // const onUploadProgress = (progressEvent) => {
   //    ---setState({ upload_progress: Math.round(progressEvent.loaded / progressEvent.total * 100) });
   //    setUploadProgress( Math.round(progressEvent.loaded / progressEvent.total * 100) );

   // }

   const executeImageAction = () =>  {
      if (selectedImage) {
         setState({loading: true});
         showCroppedImage()
            .then(()=> {
               onFileUpload(selectedImage.name)
            }
            )
            .catch(err => console.log(err));
      }
    }

   const onFileUpload = async (imageName) => {

      if (croppedImage) {
         const image = new File([croppedImage], imageName, {
            type: 'image/png'
         });
         
         props.setTeamImage(image, croppedImageUrl, imageB64 );

         onRemoveImage();
         props.onClose();
      }

      if (!props.cropImage && selectedImage) {
         const image = new File([selectedImage], imageName, {
            type: 'image/png'
         });
         
         props.setTeamImage(image, selectedImageUrl );

         onRemoveImage();
         props.onClose();
      }
      
   };

   const handleReaderLoaded = ( readerEvent ) => {
      let binaString = readerEvent.target.result;
      setImageB64( btoa(binaString) )
   }

   const showCroppedImage = async () => {
      try {
         const cropped_image = await getCroppedImg(
            selectedImageUrl,
            croppedAreaPixels,
            0
         )

         //this.setState({ cropped_image: cropped_image });
         setCroppedImage(cropped_image);

         if(cropped_image) {
               const reader = new FileReader();
               reader.onload = handleReaderLoaded.bind(this);
               reader.readAsBinaryString(cropped_image); 
         }

         //this.setState({ cropped_image_url: URL.createObjectURL(cropped_image) });
         setCroppedImageUrl(URL.createObjectURL(cropped_image));
      } catch (e) {
         console.error(e)
      }
   }

   const onRemoveImage = () => {
      //this.setState({ cropped_image: null, cropped_image_url: null, selected_image_url:null, selected_image:null });
      setCroppedImage(null);
      setCroppedImageUrl(null);
      setSelectedImage(null);
      setSelectedImageUrl(null);
   }



      return (
         <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('uploadPhotoProfile.title')}
            className={`medium ${props.className? props.className: ''}`}
         >
            <div className="league-team-upload-image">
               {/* Content */}
               <div className="">
                  {/* Crop Image */}
                  {props.cropImage && selectedImageUrl && !croppedImage && <div className="league-team-upload-image__content__media">
                     <Cropper
                        image={selectedImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={ onCropChange }
                        onCropComplete={ onCropComplete }
                        onZoomChange={ onZoomChange }
                     />                   
                  </div>}
                  {/* Display Image */}
                  {props.cropImage && croppedImage && <div className="league-team-upload-image__content__result">
                     <div className="league-team-upload-image__content__result__container">
                        <div className="league-team-upload-image__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="league-team-upload-image__content__result"/>                           
                        </div>                        
                     <img src={ croppedImageUrl} alt=""/>                        
                     </div>
                  </div>}

                  {/* Display Image */}
                  {!props.cropImage && selectedImageUrl && <div className="league-team-upload-image__content__result">
                     <div className="league-team-upload-image__content__result__container">
                        <div className="league-team-upload-image__content__result__container__loader">
                           <Loader
                              type="TailSpin"
                              color="#00C256"
                              height={60}
                              width={60}
                              timeout={3000} //3 secs
                              className="league-team-upload-image__content__result"/>                           
                        </div>                        
                     <img src={ selectedImageUrl} alt=""/>                        
                     </div>
                  </div>}
                  
                  

                  {/* Blank Image */}
                  {!croppedImage && !selectedImageUrl && <div className="league-team-upload-image__content__result">
                     <div className="league-team-upload-image__content__result__container">
                        <img src={blankProfile} alt="" />
                     </div>
                  </div>}

                  {/* Load image button */}
                  {!croppedImage && !selectedImageUrl && <div className="league-team-upload-image__action">
                     <SecondaryButtonComponent
                        onClick={() => { state.image_input_ref.click() }}
                        className="league-team-upload-image__action__button"
                        disabled={croppedImage ? true : false}
                     >
                        <span>{props.t('uploadPhotoProfile.buttonImg')}</span>
                     </SecondaryButtonComponent>
                     <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e)=>{onImageChange(e)}}
                        ref={image_input => state.image_input_ref = image_input}
                        accept="image/*"
                        name={"photo_in"}
                     />
                  </div>}
               </div>


            </div>



            <div className="league-team-upload-image__form">
               {/*<FormComponent
                  formInitialValues={formInitialValues}
                  onSubmit={(values, actions) => {

                  }}
                  className="league-team-upload-image__form__form"
                  functionDisabled={() => { }}
               >*/}
                  <div className="league-team-upload-image__form__group">
                     <SecondaryButtonComponent
                        type="button"
                        onClick={ onRemoveImage }
                        disabled={(!selectedImage) ? true : false}
                        className="league-team-upload-image__form__submit">
                        <span>{props.t('common:actions.delete')}</span>
                     </SecondaryButtonComponent>
                     <PrimaryButtonComponent
                        type="button"
                        disabled={(!selectedImage) ? true : false}
                        onClick={()=>{executeImageAction()}}
                        className="league-team-upload-image__form__submit">
                        <span>{props.t('uploadPhotoProfile.buttonOk')}</span>
                     </PrimaryButtonComponent>
                  </div>
               {/*</FormComponent>*/}
            </div>
         </Modal2Component>
      )

}



export default withTranslation(["profile","common"])(LeagueTeamUploadImageModalComponent);
