// React
import React, { useState } from 'react';

// Material UI
import Popover from '@mui/material/Popover';

// Assets
import options_icon from 'assets/icons/options-icon.svg';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import ShowPostQuizComponent from 'shared/components/show-post-quiz/ShowPostQuizComponent';
import ShowPostPollComponent from 'shared/components/show-post-poll/ShowPostPollComponent';

// Formaters
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';

// Styles
import './ProfilePostQuizPollModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function ProfilePostQuizPollModalComponent( props ) {
    const [originalLanguage, setOriginalLanguage ] = React.useState(true);
    // Edit post
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickEditPostButton = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseEditPostOption = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    
    // Report post menu
    const [reportMenuOpen, setReportMenuOpen] = useState(false);
    function reportMenuClick(){
        if(props.menu_is_open){
            if(reportMenuOpen){
                setReportMenuOpen(false);
            }else{
                setReportMenuOpen(true);
            }
        }
    }

    const changeLanguage = () => {
        if(originalLanguage){
            setOriginalLanguage(false);
        }else{
            setOriginalLanguage(true);
        }
    }

    return(
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.t('common:post')}
            className="profile-post-quiz-poll-modal"   
        >
            <div className='profile-post-quiz-poll-modal__container'>
                <div className='profile-post-quiz-poll-modal__comments'>
                    {/* Profile Post */}
                    { props.post?.author.type_object == "profile" &&
                        <div className="post__header">

                            {/* Profile photo */}
                            <div
                                onClick={ () => props.handleOnAuthorClick( props.post?.author ) }
                                className={"post__header__photo"}>
                                    {props.post?.author.photo &&
                                        <img src={props.post?.author.photo} alt="" />
                                    }
                                    {!props.post?.author.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }                    
                            </div>

                            {/* Profile data */}
                            <div className="post__header__info">
                                <div
                                    
                                    className="post__header__info__username baloa-normal-medium"
                                >
                                    <span onClick={ () => props.handleOnAuthorClick( props.post?.author ) } >{ `${props.post?.author.caption}`} </span>
                                    {props.post?.author.is_verified &&
                                        <span class="iconb2-Verificated">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </span>
                                    }
                                    {props.post?.author.baloer &&
                                        <span class="iconbal-Balores">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                            <span class="path3"></span>
                                        </span>
                                    }  
                                    { !isMobile &&
                                        <React.Fragment>
                                            &nbsp;&bull;&nbsp;
                                            <span 
                                                className={"post__header__info__username__follow-link baloa-hyperlink"}
                                                onClick={() => {
                                                    props.post?.is_following ?
                                                        props.unfollowPostProfile(props.post?.author.identifier, props.post?.id)
                                                        : props.followPostProfile(props.post?.author.identifier, props.post?.id);
                                                }}
                                            >                                  
                                                {props.post?.is_following ?
                                                    props.t('post:post.unfollow') /*TODO:implementar follow/unfollow*/
                                                    :
                                                    props.t('post:post.follow') /*TODO:implementar follow/unfollow*/
                                                } 
                                            </span>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="post__header__info__date baloa-table-column">                           
                                    { `${props.post?.author.profile_type}`}  &bull; {`${convertDateToMoment(props.post?.created)}`}
                                    {/* {console.log(props.post)/**en post hace [5] horas */} 
                                    { isMobile &&
                                        <React.Fragment>
                                            &nbsp;&bull;&nbsp;
                                            <span 
                                                className={"post__header__info__username__follow-link baloa-hyperlink mobile"}
                                                onClick={() => {
                                                    props.post?.is_following ?
                                                        props.unfollowPostProfile(props.post?.author.identifier, props.post?.id)
                                                        : props.followPostProfile(props.post?.author.identifier, props.post?.id);
                                                }}
                                            >                          
                                                {props.post?.is_following ?
                                                    props.t('post:post.unfollow') /*TODO:implementar follow/unfollow*/
                                                    :
                                                    props.t('post:post.follow') /*TODO:implementar follow/unfollow*/
                                                } 
                                            </span>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>

                            {/* Not Owners options */}
                            { props.post?.is_owner === false &&
                                <div className="post__header__options">
                                    <div 
                                        className="post__header__options__option"
                                        onClick={()=>{reportMenuClick()}}
                                    >
                                        <img src={ options_icon } alt=""/>
                                        { reportMenuOpen && (
                                            <ul className="post__header__options__option__list">
                                                <li>
                                                    <TextButtonComponent  
                                                        onClick={() => {
                                                            props.openReportPostModal(true);
                                                            props.handleReportPost(props.post?.id); 
                                                            reportMenuClick();
                                                        }}
                                                        className="post__header__options__option__list__button"
                                                    >

                                                        <span className="post__header__options__option__list__color baloa-table-column">
                                                            <span class="iconb2-report"></span>
                                                            {props.t('post:postReport.btnReport')}
                                                        </span> 
                                                    </TextButtonComponent>
                                                </li>
                                                <li>
                                                    <TextButtonComponent 
                                                        onClick={() => {
                                                            props.openBlockPostModal(true);
                                                            props.handleBlockProfile(props.post?.author.identifier);
                                                            reportMenuClick();
                                                        }}
                                                        className="post__header__options__option__list__button"
                                                    >
                                                        <span className="post__header__options__option__list__color baloa-table-column">
                                                            <span class="iconb2-block"></span>
                                                            {props.t('post:postReport.btnBlock')} @{props.post?.author.identifier}
                                                        </span> 
                                                    </TextButtonComponent>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            }

                            {/* Owner options */}
                            { props.post?.is_owner &&
                                <div className="post__header__options">
                                    <div className="post__header__options__option">
                                        <span 
                                            aria-describedby='simple-popover'
                                            // className="profile-experience__actions"
                                            onClick={ handleClickEditPostButton }
                                        >
                                            <img src={ options_icon } alt=""/>
                                        </span>    
                                    </div>
                                </div>
                            }

                            {/* User options edit popover */}
                            {/* Was necesary use open variable to close the popover div: is a special case don't use if is not necesary */}
                            { open && 
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={ anchorEl }
                                    onClose={handleCloseEditPostOption}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',    
                                    }}
                                    container= {anchorEl ? anchorEl : null}
                                    >
                                    <div
                                    className="post__header__options__option__list__popover"
                                    >
                                        <TextButtonComponent
                                            onClick={ ()=>{    
                                                handleCloseEditPostOption();
                                                props.changeHomeEditPostModal( true, props.post );
                                            } }
                                            className="post__header__options__option__list__button"
                                        >
                                            <span className="post__header__options__option__list__color baloa-table-column">
                                                <span class="icon-edit-82"></span>
                                                <span>{props.t('post:postEdit.btnEdit')}</span>
                                            </span>
                                        </TextButtonComponent>
                                        <TextButtonComponent
                                            onClick={ ()=>{ 
                                                handleCloseEditPostOption();
                                                props.handleOnDeletePostClick( props.post );
                                            } }
                                            className="post__header__options__option__list__button"
                                        >
                                            <span className="post__header__options__option__list__color baloa-table-column">
                                                <span class="icon-trash"></span>
                                                <span>{props.t('post:postEdit.btnDel')}</span>
                                            </span>
                                        </TextButtonComponent>  
                                    </div>
                                </Popover>
                            }
                        </div>                
                    }

                    
                    {/* Post caption */}
                    <div className="post__caption-me">
                        {props.post?.post_type === "Poll" &&
                            <div className={"post__caption-me__type-poll"}>
                                <span className={"iconpq-poll"}></span>
                                {props.t("post:post.type.poll")}
                            </div>
                        }
                        {props.post?.post_type === "Quiz" &&
                            <div className={"post__caption-me__type-quiz"}>
                                <span className={"iconpq-quiz"}></span>
                                {props.t("post:post.type.quiz")}
                            </div>
                        }
                        <div className="post__caption-me__text baloa-normal" dangerouslySetInnerHTML={{                            
                                __html: HASHTAG_FORMATTER( originalLanguage? props.post?.original_caption : props.post?.caption)
                            }}>
                        </div>
                        {props.post?.caption_language && props.post?.caption_language !== props.post?.original_caption_language &&
                            <div className={"post__caption-me baloa-names"} onClick={changeLanguage}>{ originalLanguage? props.t("post:post.showTranslation") : props.t("post:post.showOriginalLanguage")}</div>
                        }                
                    </div>
                    
                    {/* Post Quiz */}
                    {props.post?.post_type === "Quiz" &&
                        <div className="post__content">
                            <ShowPostQuizComponent
                                quiz_answers_count={props.post?.quiz_answers_count}
                                quiz_answers={props.post?.quiz_answers}
                                is_quiz_answer={props.post?.is_quiz_answer}
                                handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                                post_id={props.post?.id}
                            />                        
                        </div>
                    }

                    {/* Post Poll */}
                    {props.post?.post_type === "Poll" &&
                        <div className="post__content">
                            <ShowPostPollComponent
                                poll_answers_count={props.post?.poll_answers_count}
                                poll_answers={props.post?.poll_answers}
                                is_poll_answer={props.post?.is_poll_answer}
                                handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                                post_id={props.post?.id}
                            />                        
                        </div>
                    }

                </div>
            </div>
        </Modal2Component>
        )
    }
    
    export default withTranslation('profile')(ProfilePostQuizPollModalComponent);