// Post state
const post_state = {
    post: {
        profile: undefined,
        page: undefined,
        caption: undefined,
        post_image: undefined,
        post_video: undefined,
        clap_count: undefined,
        comment_count: undefined,
        is_clapped: undefined,
        clapped_by: [],
    },
    comments: [],
    comments_next_page:'',
    claps: [],
    claps_next_page: '',
    reply_comments: [],
    modals: {
        claps_is_open: false,
        report_is_open: false,
        block_is_open: false,
        accept_report_is_open: false,
        reactions_is_open: false,
        comment_reactions_is_open: false,
        match_detail_is_open: false,
        quote_modal_is_open: false,
    },
    reactions: [],
    reactions_next_page: '',
    reactions_by_type:{
        clap: [],
        clap_next: null,
        support: [],
        support_next: null,
        itsfunny: [],
        itsfunny_next: null,
        redcard: [],
        redcard_next: null,
        sad: [],
        sad_next: null,
        celebrate: [],
        celebrate_next: null
    },
    comment_reactions: [],
    comment_reactions_next_page: '',
    comment_reactions_by_type:{
        clap: [],
        clap_next: null,
        support: [],
        support_next: null,
        itsfunny: [],
        itsfunny_next: null,
        redcard: [],
        redcard_next: null,
        sad: [],
        sad_next: null,
        celebrate: [],
        celebrate_next: null
    },
    post_to_quote: undefined,
}

export default function PostReducer( state = post_state, action ) {

    switch ( action.type ) {

        case "POST:LOAD_POST": {

            return { ...state, post: action.post_data }

        }

        case "POST:LOAD_COMMENTS": {
            
            return { ...state, comments: action.post_comments.results, comments_next_page: action.post_comments.next }

        }

        case "POST:CLAP_COMMENTS": {
           
            let comment = state.comments.filter((element) =>{
                if(element.id === action.comments_id){
                    element.is_clapped = !element.is_clapped
                }
                return element;
            });
            
            return { ...state, comments: comment }
        }
        
        case "POST:LOAD_REPLY_COMMENTS": {
            let reply_comment = { id_comment:action.id_comment, reply_comments:action.post_reply_comments };

            const index = state.reply_comments.findIndex(rcomments => rcomments.id_comment === action.id_comment);

            let new_reply_comments = [];

            if(index >= 0){

                let new_reply_comments = [...state.reply_comments];
                new_reply_comments[index] = reply_comment;

                return {...state, reply_comments: new_reply_comments };

            }else{
                new_reply_comments = [...state.reply_comments, reply_comment];

                return {...state, reply_comments: new_reply_comments };
            }
                         
        }
        
        case "POST:LOAD_CLAPS": {
           
            return { ...state, claps: action.post_claps.results, claps_next_page: action.post_claps.next  } 

        }

        case "POST:CHANGE_CLAPS_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], claps_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:CHANGE_IS_FOLLOWING_STATE": {
            
            /* eslint-disable */
            let new_claps = state.reactions.results.filter((clap) => {
                if(clap.user.username === action.username){
                    clap.is_following = !clap.is_following
                }
                return clap;
            });
            /* eslint-enable */
        }
        /* eslint-disable */
        case "POST:CHANGE_REPORT_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], report_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
            
        }
        /* eslint-enable */

        case "POST:CHANGE_BLOCK_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], block_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:CHANGE_ACCEPT_REPORT_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], accept_report_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:LOAD_CLAPS_NEXT": {
            
            let new_claps = state.claps.concat( action.post_claps.results );
             return { ...state, claps: new_claps, claps_next_page: action.post_claps.next} 
 
        }

        case "POST:LOAD_COMMENTS_NEXT": {
           
            let new_comments = state.comments.concat( action.post_comments.results );
            return { ...state, comments: new_comments , comments_next_page: action.post_comments.next } 
 
        }

        case "POST:LOAD_POST_REACTIONS": {
           
            return { ...state, reactions: action.post_reactions, reactions_next_page: action.post_reactions.next  } 

        }

        case "POST:LOAD_POST_REACTIONS_NEXT": {

            if (action.reaction_type === 'All'){
                let new_reactions = state.reactions.concat( action.post_reactions.results );
                return { ...state, reactions: new_reactions, reactions_next_page: action.post_reactions.next} 
            }

            let new_reaction_type = {...state['reactions_by_type']}

            if (action.reaction_type === 'Clap'){
                new_reaction_type = { ...state['reactions_by_type'], clap: state.reactions_by_type.clap.concat(action.post_reactions.results), clap_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Support'){
                new_reaction_type = { ...state['reactions_by_type'], support: state.reactions_by_type.support.concat(action.post_reactions.results), support_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Its_Funny'){
                new_reaction_type = { ...state['reactions_by_type'], itsfunny: state.reactions_by_type.itsfunny.concat(action.post_reactions.results), itsfunny_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Red_Card'){
                new_reaction_type = { ...state['reactions_by_type'], redcard: state.reactions_by_type.redcard.concat(action.post_reactions.results), redcard_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Sad'){
                new_reaction_type = { ...state['reactions_by_type'], sad: state.reactions_by_type.sad.concat(action.post_reactions.results), sad_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Celebrate'){
                new_reaction_type = { ...state['reactions_by_type'], celebrate: state.reactions_by_type.celebrate.concat(action.post_reactions.results), celebrate_next: action.post_reactions.next}
            }
           
            return { ...state, reactions_by_type: new_reaction_type } 
            
        }
        
        case "POST:CHANGE_REACTIONS_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], reactions_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:LOAD_POST_REACTIONS_BY_TYPE": {

            let new_reaction_type = {...state['reactions_by_type']}

            if (action.reaction_type === 'Clap'){
                new_reaction_type = { ...state['reactions_by_type'], clap: action.post_reactions.results, clap_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Support'){
                new_reaction_type = { ...state['reactions_by_type'], support: action.post_reactions.results, support_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Its_Funny'){
                new_reaction_type = { ...state['reactions_by_type'], itsfunny: action.post_reactions.results, itsfunny_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Red_Card'){
                new_reaction_type = { ...state['reactions_by_type'], redcard: action.post_reactions.results, redcard_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Sad'){
                new_reaction_type = { ...state['reactions_by_type'], sad: action.post_reactions.results, sad_next: action.post_reactions.next}
            }
            if (action.reaction_type === 'Celebrate'){
                new_reaction_type = { ...state['reactions_by_type'], celebrate: action.post_reactions.results, celebrate_next: action.post_reactions.next}
            }
           
            return { ...state, reactions_by_type: new_reaction_type } 

        }

        case "POST:CHANGE_COMMENT_REACTIONS_MODAL_STATE": {
            
            let new_modals_state = { ...state['modals'], comment_reactions_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:LOAD_POST_COMMENT_REACTIONS": {
           
            return { ...state, comment_reactions: action.comment_reactions, comment_reactions_next_page: action.comment_reactions.next  } 

        }

        case "POST:LOAD_POST_COMMENT_REACTIONS_NEXT": {

            if (action.reaction_type === 'All'){
                let new_reactions = state.comment_reactions.results.concat( action.comment_reactions.results );
                return { ...state, comment_reactions: new_reactions, comment_reactions_next_page: action.comment_reactions.next} 
            }

            let new_reaction_type = {...state['comment_reactions_by_type']}

            if (action.reaction_type === 'Clap'){
                new_reaction_type = { ...state['comment_reactions_by_type'], clap: state.comment_reactions_by_type.clap.concat(action.comment_reactions.results), clap_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Support'){
                new_reaction_type = { ...state['comment_reactions_by_type'], support: state.comment_reactions_by_type.support.concat(action.comment_reactions.results), support_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Its_Funny'){
                new_reaction_type = { ...state['comment_reactions_by_type'], itsfunny: state.comment_reactions_by_type.itsfunny.concat(action.comment_reactions.results), itsfunny_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Red_Card'){
                new_reaction_type = { ...state['comment_reactions_by_type'], redcard: state.comment_reactions_by_type.redcard.concat(action.comment_reactions.results), redcard_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Sad'){
                new_reaction_type = { ...state['comment_reactions_by_type'], sad: state.comment_reactions_by_type.sad.concat(action.comment_reactions.results), sad_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Celebrate'){
                new_reaction_type = { ...state['comment_reactions_by_type'], celebrate: state.comment_reactions_by_type.celebrate.concat(action.comment_reactions.results), celebrate_next: action.comment_reactions.next}
            }
           
            return { ...state, comment_reactions_by_type: new_reaction_type } 
            
        }

        case "POST:LOAD_POST_COMMENT_REACTIONS_BY_TYPE": {

            let new_reaction_type = {...state['comment_reactions_by_type']}

            if (action.reaction_type === 'Clap'){
                new_reaction_type = { ...state['comment_reactions_by_type'], clap: action.comment_reactions.results, clap_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Support'){
                new_reaction_type = { ...state['comment_reactions_by_type'], support: action.comment_reactions.results, support_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Its_Funny'){
                new_reaction_type = { ...state['comment_reactions_by_type'], itsfunny: action.comment_reactions.results, itsfunny_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Red_Card'){
                new_reaction_type = { ...state['comment_reactions_by_type'], redcard: action.comment_reactions.results, redcard_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Sad'){
                new_reaction_type = { ...state['comment_reactions_by_type'], sad: action.comment_reactions.results, sad_next: action.comment_reactions.next}
            }
            if (action.reaction_type === 'Celebrate'){
                new_reaction_type = { ...state['comment_reactions_by_type'], celebrate: action.comment_reactions.results, celebrate_next: action.comment_reactions.next}
            }
           
            return { ...state, comment_reactions_by_type: new_reaction_type } 

        }

        case "POST:CHANGE_MATCH_DETAIL_MODAL_STATE": {            
            let new_modals_state = { ...state['modals'], match_detail_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:CHANGE_QUOTE_POST_MODAL_STATE": {            
            let new_modals_state = { ...state['modals'], quote_modal_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "POST:POST_TO_QUOTE":{
            return { ...state, post_to_quote: action.post }
        }

        case "POST:CHANGE_IS_FOLLOWING_STATE_FROM_REACTION_TYPE": {

            let new_reaction_type = {...state['reactions_by_type']}

            if (action.reaction_type === 'Clap'){
                let new_claps = state.reactions_by_type.clap.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], clap: new_claps }
            }
            if (action.reaction_type === 'Support'){
                let new_claps = state.reactions_by_type.support.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], support: new_claps }
            }
            if (action.reaction_type === 'Its_Funny'){
                let new_claps = state.reactions_by_type.itsfunny.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], itsfunny: new_claps }
            }
            if (action.reaction_type === 'Red_Card'){
                let new_claps = state.reactions_by_type.redcard.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], redcard: new_claps }
            }
            if (action.reaction_type === 'Sad'){
                let new_claps = state.reactions_by_type.sad.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], sad: new_claps }
            }
            if (action.reaction_type === 'Celebrate'){
                let new_claps = state.reactions_by_type.celebrate.filter((clap) => {
                    if(clap.user.username === action.username){
                        clap.is_following = !clap.is_following
                    }
                    return clap;
                });
                new_reaction_type = { ...state['reactions_by_type'], celebrate: new_claps }
            }
           
            return { ...state, reactions_by_type: new_reaction_type } 
        }
        
        // no default

    }

    return state;
}