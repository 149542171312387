// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-bulk-upload-confirmation__title {
    width: 100%;
    text-align: center;
}

.team-bulk-upload-confirmation__text{
    width: 100%;
    margin-top: calc(var(--space-x3) - var(--space-quarter));
    justify-content: center;
}

.team-bulk-upload-confirmation__actions {
    display: flex;
    margin-top: 27px;
    justify-content: space-around;
}

.team-bulk-upload-confirmation__actions.bulk-add-members__buttons__button{
    margin-left: unset;
}

.team-bulk-upload-confirmation__button {
    display: block;
    width: 49%;
    margin-top: var(--space-half-quarter);
    height: calc(var(--space-x2) + var(--space-half));
}

.team-bulk-upload-confirmation .modal__container{
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 1px 24px 5px rgba(77, 84, 81, 0.7);
    padding: 30px;
    width: 450px;
}

.team-bulk-upload-confirmation__title.baloa-subtitle-1{
    color: var(--primary-very-dark-color);
}

.team-bulk-upload-confirmation__text.baloa-table{
    color: var(--primary-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-team-bulk-upload-member-confirmation-modal/LeagueTeamBulkUploadMemberConfirmationModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,wDAAwD;IACxD,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,qCAAqC;IACrC,iDAAiD;AACrD;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kDAAkD;IAClD,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".team-bulk-upload-confirmation__title {\n    width: 100%;\n    text-align: center;\n}\n\n.team-bulk-upload-confirmation__text{\n    width: 100%;\n    margin-top: calc(var(--space-x3) - var(--space-quarter));\n    justify-content: center;\n}\n\n.team-bulk-upload-confirmation__actions {\n    display: flex;\n    margin-top: 27px;\n    justify-content: space-around;\n}\n\n.team-bulk-upload-confirmation__actions.bulk-add-members__buttons__button{\n    margin-left: unset;\n}\n\n.team-bulk-upload-confirmation__button {\n    display: block;\n    width: 49%;\n    margin-top: var(--space-half-quarter);\n    height: calc(var(--space-x2) + var(--space-half));\n}\n\n.team-bulk-upload-confirmation .modal__container{\n    border: none;\n    background: #FFFFFF;\n    box-shadow: 0px 1px 24px 5px rgba(77, 84, 81, 0.7);\n    padding: 30px;\n    width: 450px;\n}\n\n.team-bulk-upload-confirmation__title.baloa-subtitle-1{\n    color: var(--primary-very-dark-color);\n}\n\n.team-bulk-upload-confirmation__text.baloa-table{\n    color: var(--primary-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
