// React
import React from 'react';
// import PropTypes from 'prop-types';

// Formik
import { Field, useField } from 'formik';

// Material
import { Checkbox } from '@mui/material';

// Styles
import './CheckFieldComponent.styles.css';


class CheckFieldComponent extends React.Component {

    
    render() {
        
        const field_name = this.props.field_name;
        
        return (
            
            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    
                    const [field] = useField(field_name);

                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }

                    return (
                        <div className={ "checkbox-field " + ( this.props.className ? this.props.className : '' ) + ' ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' ) }>
                            <Checkbox
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                onClick={ this.props.onClick } 
                                checked={ this.props.checked }
                                onChange={ field.onChange }
                                { ...field }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                color='secondary'
                            />
                            <div className="checkbox-field__content">
                                { this.props.children }
                            </div>
                        </div>
                    )

                } }
            </Field>

        );
    }
}

// Types
CheckFieldComponent.propTypes = {
    // validationFunction: PropTypes.func.isRequired,
    // resetError: PropTypes.func.isRequired,
}
export default CheckFieldComponent;
