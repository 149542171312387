/*eslint eqeqeq: "off"*/
// React
import React from 'react';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './StaffActionComponent.styles.css';

function StaffActionComponent(props) {

    const { row, handleOnAcceptClick, handleOnRejectClick, handleOnEditActionClick, handleOnDeleteActionClick, acceptLabel, ignoreLabel, edit_row_id, handleOnCheckEditActionClick, handleOnCancelEditActionClick } = props;

    return (
        <React.Fragment>

            <div class="staff-actions">
                {row.role_state === 'Pending' &&
                    <React.Fragment>
                        <div class="staff-actions__action baloa-mini" onClick={ () => { handleOnAcceptClick( row.id );} }>
                            <span class="hubicon-check_circle staff-actions-icon"></span>
                            {acceptLabel}
                        </div>
                        <div class="staff-actions__action baloa-mini" onClick={ () => { handleOnRejectClick(row.id ) } }>
                            <span class="hubicon-cancel staff-actions-icon"></span>
                            {ignoreLabel}
                        </div>
                    </React.Fragment>                    
                }
                {row.role_state === 'Active' && row.role !== 'Owner' && (
                    !edit_row_id?
                    <React.Fragment>
                        <div class="staff-actions__action baloa-mini tooltip__edit" onClick={ () => { handleOnEditActionClick( row.id ); } }>
                            <span class="hubicon-edit staff-actions-icon"></span>
                            <span class="staff-actions__tooltip">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffTooltips.editTooltip')}</span>
                        </div>
                        <div class="staff-actions__action baloa-mini tooltip__delete" onClick={ () => { handleOnDeleteActionClick( row.id ) } }>
                            <span class="hubicon-delete staff-actions-icon"></span>
                            <span class="staff-actions__tooltip">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffTooltips.deleteTooltip')}</span>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div class="staff-actions__action baloa-mini tooltip__check-edit" onClick={ () => { handleOnCheckEditActionClick( row.id ); } }>
                            <span class="icon-check-single-72 staff-actions-icon"></span>
                            <span class="staff-actions__tooltip">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffTooltips.saveTooltip')}</span>
                        </div>
                        <div class="staff-actions__action baloa-mini tooltip__cancel-edit" onClick={ () => { handleOnCancelEditActionClick( row.id ) } }>
                            <span class="icon-x staff-actions-icon"></span>
                            <span class="staff-actions__tooltip">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.staffTooltips.cancelTooltip')}</span>
                        </div>
                    </React.Fragment>
                )}               
                     
                     
            </div>

        </React.Fragment>
    )

}

export default withTranslation(['league'])(StaffActionComponent)