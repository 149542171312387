import React, { useCallback }  from 'react';

//Components
import TextField from '@mui/material/TextField';

//Matyerial
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Styles
import './InputSearchWithButtonComponent.styles.css';
import { withStyles } from '@mui/styles';

function InputSearchWithButtonComponent( props ) {

    /* const SearchTextField = withStyles({
        root: {
          '& .MuiOutlinedInput-notchedOutline':{
            borderStyle: 'none',
          },
          '& .MuiOutlinedInput-root':{
            color: 'var(--basic-gray-color)',
            padding: '12px 10px;',
            '& span':{
              fontSize: 'var(--space-and-half)',
              marginRight: 'var(--space-half)',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px',
            },
          },
        },
      })(TextField); */
      const [searchteam, setSearchteam] = React.useState();
      const [focus, setFocus] = React.useState(false);
    
    React.useEffect(() => {       
      if(props.cleanValue){
        setSearchteam(null);
      }  
    }, [props.cleanValue]);

    const handleClear = useCallback(() => {
      props.handleOnSearchClick("");
      setSearchteam("");
    }, []);

    return (
      <React.Fragment>
          <TextField
              id={props.id}
              name={props.name}
              autoFocus={(searchteam && searchteam.length > 0) || focus}
              onChange={(event) => {
                setSearchteam(event.target.value);
              }}
              onKeyDown={(event) => {      
                  if (event.key === 'Enter' ) {
                      if(searchteam === undefined || searchteam === ""){
                          event.preventDefault();    
                      } else {
                          props.handleOnSearchClick(searchteam)
                      }
                  }
              }}
              onFocus={()=>{ setFocus(true)}}
              InputProps={{
                  startAdornment: (
                  <React.Fragment>
                      {
                        !props.removeIconSearch && <span class="icon-search"></span>
                      }
                  </React.Fragment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                    {
                      (!props.removeClearButton) && (searchteam && searchteam.length > 0) && <span className='hubicon-clear_option' onClick={()=>{handleClear()}}></span>
                    }
                    </React.Fragment>
                  ),
              }}
              className="input-search-with-button__input"
              placeholder={props.placeholder}
              variant="outlined"
              InputLabelProps={{
                  shrink: true,                                    
              }}
              value={searchteam}
              disabled={props.disabled}
          />
          <IconButton className='input-search-with-button__icon' onClick={ ()=>{ props.handleOnSearchClick(searchteam) }}>
              <SearchIcon/>
          </IconButton>
      </React.Fragment>
    )
}
                
export default InputSearchWithButtonComponent;

