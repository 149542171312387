// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-menu {
    box-shadow: 2px 2px 5px 2px #4444;
    padding: 0;
    /* width: 15%; */
    margin-top: 0;
    /* list-style: none; */
    position: absolute;
}
.button-menu__item {
    display: flex;
    background-color: var(--white-color);
    padding: 10px 15px;    
    /* width: 100%; */
    /* outline: none; */
    align-items: center;
}

.post-detail__comments__container__comment__info__button .icon-options{
    font-size: var(--space-and-half);
    color: var(--primary-very-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/app/posts/components/button-menu/ButtonMenuComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,qCAAqC;AACzC","sourcesContent":[".button-menu {\n    box-shadow: 2px 2px 5px 2px #4444;\n    padding: 0;\n    /* width: 15%; */\n    margin-top: 0;\n    /* list-style: none; */\n    position: absolute;\n}\n.button-menu__item {\n    display: flex;\n    background-color: var(--white-color);\n    padding: 10px 15px;    \n    /* width: 100%; */\n    /* outline: none; */\n    align-items: center;\n}\n\n.post-detail__comments__container__comment__info__button .icon-options{\n    font-size: var(--space-and-half);\n    color: var(--primary-very-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
