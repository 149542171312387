import React from 'react';
// import {Link} from 'react-router-dom';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Material
import Switch from '@mui/material/Switch';

// Styles
import './LeagueUserPermissionsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function LeagueUserPermissionsComponent( props ) {

    

    const [state, setState] = React.useState(
        {
            adminContent: false,
            adminTeams: false,
            adminStaff: false,
            adminTournaments: false,
            adminMatches: false,
            comGroup: false,
            tournamentGroup: false,
            pageGroup: false,
        }
    );
    React.useEffect(() => {    
        setState({ ...state, adminContent: props.active_member?.roles?.content_admin, 
            adminTeams: props.active_member?.roles?.team_admin,
            adminStaff: props.active_member?.roles?.staff_admin,
            adminTournaments: props.active_member?.roles?.tournament_admin,
            adminMatches: props.active_member?.roles?.match_admin});
    }, [props.active_member]); // eslint-disable-line

    React.useEffect(() => {    
        if( state.adminContent === true ){
            setState({ ...state, comGroup: true });
            if( state.adminTeams === true && state.adminTournaments === true && state.adminStaff === true && state.adminMatches === true ){
                setState({ ...state, comGroup: true, pageGroup: true });
            }
        }
        if( state.adminContent === false){
            setState({ ...state, comGroup: false, pageGroup: false });
        }
    }, [state.adminContent]);  // eslint-disable-line

    React.useEffect(() => {    
        if( state.adminTeams === true && state.adminTournaments === true ){
            setState({ ...state, tournamentGroup: true });
            if( state.adminContent === true && state.adminStaff === true && state.adminMatches === true ){
                setState({ ...state, tournamentGroup: true, pageGroup: true });
            }
        }
        if( state.adminTeams === false || state.adminTournaments === false ){
            setState({ ...state, tournamentGroup: false, pageGroup: false });
        }        
    }, [state.adminTeams, state.adminTournaments]);  // eslint-disable-line

    React.useEffect(() => {    
        if( state.adminContent === true && state.adminTeams === true && state.adminTournaments === true && state.adminStaff === true && state.adminMatches === true ){
            setState({ ...state, pageGroup: true });
        }
        if( state.adminContent === false || state.adminTeams === false || state.adminTournaments === false || state.adminStaff === false || state.adminMatches === false ){
            setState({ ...state, pageGroup: false });
        }
    }, [state.adminMatches, state.adminStaff]); // eslint-disable-line


    const changeAdminContent=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    const changeAdminTeams=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    const changeAdminStaff=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    const changeAdminTournaments=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    const changeAdminMatches=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }

    const handleComunicationsGroup=()=>{
        if(state.adminContent === true){
            setState({ ...state, adminContent:false, comGroup: false });
        }else{
            setState({ ...state, adminContent:true, comGroup: true });
        }
        
    }

    const handleAdminTournamentsGroup=()=>{
        if(state.adminTeams === true && state.adminTournaments === true){
            setState({ ...state, adminTeams: false, adminTournaments: false, tournamentGroup: false });
        }else{
            setState({ ...state, adminTeams: true, adminTournaments: true, tournamentGroup: true });
        }
        
    }

    const handleAdminPageGroup=()=>{
        if(state.adminContent === true && state.adminTeams === true && state.adminTournaments === true && state.adminStaff === true && state.adminMatches === true ){
            setState({ ...state, adminContent:false, adminTeams: false, adminTournaments: false, adminStaff: false, adminMatches: false, pageGroup: false, tournamentGroup: false, comGroup: false });
        }else{
            setState({ ...state, adminContent:true, adminTeams: true, adminTournaments: true, adminStaff: true, adminMatches: true, pageGroup: true, tournamentGroup: true, comGroup: true });
        }
        
    }
    const submitNewRoles=()=>{
        props.handleSavePermissions( props.active_member.username, {
            content_admin: state.adminContent,
            team_admin: state.adminTeams,
            staff_admin: state.adminStaff,
            tournament_admin: state.adminTournaments,
            match_admin: state.adminMatches
        })
    }
    

    return (
        <div className={'league-user-permissions__sections'}>
            <div className={"league-user-permissions__container"}>
            <div className={"league-user-permissions__title baloa-subtitle-2"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.permissionsTitle') }</div>
            <div className={"league-user-permissions__permissions-groups"}>
                <div className={`league-user-permissions__permission-group ${(state.comGroup === true? 'selected' : '')}`} onClick={ ()=>{ handleComunicationsGroup() } }>
                    <div className={"league-user-permissions__permission-group__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.comunicationsTitle') }</div>
                    <div className={"league-user-permissions__permission-group__description baloa-caption"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.comunicationsText') }</div>
                </div>
                <div className={`league-user-permissions__permission-group ${(state.tournamentGroup === true? 'selected':'')}`} onClick={ ()=>{ handleAdminTournamentsGroup() } }>
                    <div className={"league-user-permissions__permission-group__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.adminTournamentsTitle') }</div>
                    <div className={"league-user-permissions__permission-group__description baloa-caption"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.adminTournamentsText') }</div>
                </div>
                <div className={`league-user-permissions__permission-group ${(state.pageGroup === true? 'selected':'')}`} onClick={ ()=>{ handleAdminPageGroup() } }>
                    <div className={"league-user-permissions__permission-group__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.adminPagesTitle') }</div>
                    <div className={"league-user-permissions__permission-group__description baloa-caption"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberGroupPermissions.adminPagesText') }</div>
                </div>
            </div>
            <div className={"league-user-permissions__permissions-options"}>
                <div className={"league-user-permissions__permission-option"}>
                    <div className={"league-user-permissions__permission-option__text"} >
                        <div className={"league-user-permissions__permission-option__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminContentTitle') }</div>
                        <div className="league-user-permissions__permission-option__description baloa-username" dangerouslySetInnerHTML={{
                            __html: props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminContentText')
                            }}>
                        </div>
                    </div>
                    <div className={"league-user-permissions__permission-option__control"}>
                        <Switch
                            checked={ state.adminContent }
                            onChange={ changeAdminContent }
                            name="adminContent"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    </div>
                </div>
                <div className={"league-user-permissions__permission-option"}>
                    <div className={"league-user-permissions__permission-option__text"} >
                        <div className={"league-user-permissions__permission-option__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminTeamsTitle') }</div>
                        <div className="league-user-permissions__permission-option__description baloa-username" dangerouslySetInnerHTML={{
                            __html: props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminTeamsText')
                            }}>
                        </div>
                    </div>
                    <div className={"league-user-permissions__permission-option__control"}>
                        <Switch
                            checked={ state.adminTeams }
                            onChange={ changeAdminTeams }
                            name="adminTeams"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    </div>
                </div>
                <div className={"league-user-permissions__permission-option"}>
                    <div className={"league-user-permissions__permission-option__text"} >
                        <div className={"league-user-permissions__permission-option__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminStaffTitle') }</div>
                        <div className="league-user-permissions__permission-option__description baloa-username" dangerouslySetInnerHTML={{
                            __html: props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminStaffText')
                            }}>
                        </div>
                    </div>
                    <div className={"league-user-permissions__permission-option__control"}>
                        <Switch
                            checked={ state.adminStaff }
                            onChange={ changeAdminStaff }
                            name="adminStaff"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    </div>
                </div>
                <div className={"league-user-permissions__permission-option"}>
                    <div className={"league-user-permissions__permission-option__text"} >
                        <div className={"league-user-permissions__permission-option__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminTournamentsTitle') }</div>
                        <div className="league-user-permissions__permission-option__description baloa-username" dangerouslySetInnerHTML={{
                            __html: props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminTournamentsText')
                            }}>
                        </div>
                    </div>
                    <div className={"league-user-permissions__permission-option__control"}>
                        <Switch
                            checked={state.adminTournaments}
                            onChange={changeAdminTournaments}
                            name="adminTournaments"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    </div>
                </div>
                <div className={"league-user-permissions__permission-option"}>
                    <div className={"league-user-permissions__permission-option__text"} >
                        <div className={"league-user-permissions__permission-option__title baloa-names"}>{ props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminMatchesTitle') }</div>
                        <div className="league-user-permissions__permission-option__description baloa-username" dangerouslySetInnerHTML={{
                            __html: props.t('options.teamsAndMembers.tabs.tabStaff.memberPermissions.adminMatchesText')
                            }}>
                        </div>
                    </div>
                    <div className={"league-user-permissions__permission-option__control"}>
                        <Switch
                            checked={state.adminMatches}
                            onChange={changeAdminMatches}
                            name="adminMatches"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                        />
                    </div>
                </div>
            </div>
            <div className="league-user-permissions__save-button">
                <PrimaryButtonComponent 
                    onClick={()=>{ submitNewRoles() }} 
                    className="league-user-permissions__save-button__button small"
                >
                    <span>{ props.t('options.teamsAndMembers.tabs.tabStaff.savePermissionsBtnLabel') }</span>
                </PrimaryButtonComponent>
            </div>
        </div>
            <div className={"league-user-permissions__container__championships"}>
                {props.active_member.tournaments 
                ? <div className={'staff-member__registered-championships__container__championships'}>
                <h6 className='baloa-subtitle-2'>{ props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailRegisteredTournaments') }</h6>
                <ul>
                    {props.active_member.tournaments?.map((tournament, index) => (
                        <li className='registered-championships__card__championships' key={index}>
                            {/* <Link to={"/league/tournament/SARLChampi"}> */}
                                <div className='registered-championships__card__info__championships'>
                                    <div className='registered-championships__card__info__championships_content_text'>
                                        <p className='baloa-subtitle-1'>{tournament.tournament}</p>
                                        <p className='baloa-body-2'>{`${tournament.main_country}, ${tournament.main_city}`}</p>
                                        <p className='baloa-caption'>{tournament.tournament_tag?.extra?.value}</p>
                                        <p className='baloa-caption'>{`${tournament.start_date} - ${tournament.end_date}`}</p>
                                    </div>
                                    <span className='icon-chevron-roght' style={{ margin:'20px'}} />
                                </div>
                            {/* </Link> */}
                        </li>
                        ))
                    }                    
                </ul>
            </div>
                : <div className={'staff-member__registered-championships__container__championships_no_data'}>
                    <h6 className='baloa-headline-6'>{ props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberDetailRegisteredTournaments') }</h6>
                    <p className='baloa-caption'>{props.t('options.teamsAndMembers.tabs.tabStaff.labelMemberNoDataRegisteredChampionship')}</p>
                </div>
                }
            
            </div>
        </div>
    )
}
                
export default withTranslation("league")(LeagueUserPermissionsComponent);