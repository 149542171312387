// React
import React from 'react'

// Styles
import './ProfileRoleComponent.styles.css'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileRoleComponent( props ) {

    function handleIconRole () {
        if(props.profile.profile_type === 'fanatic'){
            return 'icon-fan';
        } else if(props.profile.profile_type === 'player') {
            return 'icon-player';
        } else {
            return 'icon-coach';
        }
    }

    return (
        <Card1Component 
            className={ "profile-role" + ( props.className ? props.className : '' ) } 
            title={props.t('exp.label2')}
            button={ props.is_owner && ( <span className='hubicon-edit'  onClick={() => {props.handleOnEditProfileRole()}}/>) }
            onButtonClick={ () => {} }
        >
            <div className='profile-role__container'>
                <span className={handleIconRole()} />
                <div className='baloa-subtitle-2'>{props.profile.profile_type === 'professional' ?props.t(`professional_types.${props.profile.profile_profession_type}`) : props.t(`role.${props.profile.profile_type}`)}</div>
            </div>
        </Card1Component>        
    )
}

export default withTranslation('profile')(ProfileRoleComponent);

