// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-detail-modal__container .post__content__tournament-result__container__tournament {
     box-shadow: none;
     margin: 0;
    }`, "",{"version":3,"sources":["webpack://./src/app/posts/components/match-detail-modal/MatchDetailModalComponent.styles.css"],"names":[],"mappings":"AAAA;KACK,gBAAgB;KAChB,SAAS;IACV","sourcesContent":[".match-detail-modal__container .post__content__tournament-result__container__tournament {\n     box-shadow: none;\n     margin: 0;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
