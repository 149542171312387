/*eslint eqeqeq: "off"*/
// React
import React from 'react'

// Styles
import './PageMembersComponent.styles.css'

// Assets
import plus_icon from 'assets/icons/plus-icon.svg'
import baloa_user_icon from 'assets/icons/baloa-user.svg'
import options_icon from 'assets/icons/options-icon.svg'

// Components
import Card1Component from 'shared/components/card-1/Card1Component';
import Popover from '@mui/material/Popover';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// React device detect
import { isMobile } from 'react-device-detect';


// i18n
import { withTranslation } from 'react-i18next';

function PageMembersComponent( props ) {
    const [anchorMember, setAnchorMember] = React.useState(null);
    const handleClickOptionsMemberButton = (event) => {
        setAnchorMember(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorMember(null);        
    };

    return (

        <Card1Component 
            className={ "page-members " + ( props.className ? props.className : '' ) } 
            title={props.t('tabs.members.title')}
            button={ ( 
                (props.is_admin && !isMobile) ? 
                <img className="page-members__new-button" 
                    src={ plus_icon }
                    alt=""
                />
                : ""
            ) }            
            onButtonClick={ props.onNewButton }
        >

            <div className="page-members__container">
                {
                    props.page_members?.map( member => 

                        <div className="page-members__members">
                            <div
                                onClick={() => { props.handleOnProfileClick(member)} }
                                className={"page-members__photo " + (member.photo ? '' : 'no-photo')}>
                                <img src={member.photo ? member.photo : baloa_user_icon} alt="" />
                            </div>
                            <div className="page-members__info"
                                onClick={() => { props.handleOnProfileClick(member) }}
                            >
                                <div                                    
                                    className="page-members__info__username baloa-names">
                                    {member.first_name + " " + member.last_name}
                                </div>
                                <div
                                    className="page-members__info__username baloa-username">
                                    {"@" + member.username} {member.is_following}
                                </div>
                               
                            </div>

                            {/* Profile Options */}
                            {props.is_admin && !isMobile &&
                                <div className="page-members__options">
                                    <div className="page-members__options__option">
                                        <span 
                                            id={ "member_opt_" + member.username }
                                            aria-control={ "member_opt_" + member.username } // eslint-disable-line
                                            className="profile-experience__actions"
                                            onClick={ handleClickOptionsMemberButton }
                                        >
                                            <img src={ options_icon } alt=""/>
                                        </span>
                                        {/* User options edit popover */}
                                        { anchorMember?.id == "member_opt_" + member.username &&
                                            <Popover
                                                id={ "member_opt_" + member.username }
                                                open={ Boolean( anchorMember )}
                                                anchorEl={ anchorMember }
                                                onClose={ handleCloseOptions }                   
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                container= { anchorMember ? anchorMember : null}
                                                >
                                                    {props.page_type == "group"?
                                                        <div
                                                            className="header-auth__actions__me__popover"
                                                        > 
                                                            <TextButtonComponent 
                                                                className="header-auth__actions__me__popover__action"
                                                                onClick={ () => {
                                                                    handleCloseOptions();
                                                                    props.kickoutGroupMember( member.username ); 
                                                                }}
                                                            >
                                                                <span>{ props.t('groups.adminAdministratorsCard.optLabKickOutAdmin') }</span>
                                                            </TextButtonComponent>
                                                        </div>
                                                    :
                                                        <div
                                                            className="header-auth__actions__me__popover"
                                                        >                        
                                                            <SecondaryButtonComponent
                                                                onClick={ ( )=>{ 
                                                                    handleCloseOptions();        
                                                                    props.deleteMemberFromPage( member );                          
                                                                } }
                                                                className="header-auth__actions__me__popover__action" 
                                                            >
                                                                <span>{props.t('tabs.members.button')}</span>
                                                            </SecondaryButtonComponent>  
                                                        </div>
                                                    }
                                            </Popover>   
                       
                                        }           
                                    </div>
                                </div>
                            }
                        
                        </div>
                    )
                }
            </div>

        </Card1Component>

    )

}

export default withTranslation('page')(PageMembersComponent);

//export default PageMembersComponent;
