// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-id-viewer__content{
    display: flex;
    justify-content: center;    
}

.card-id-viewer__horizontal-card {
    position: relative;
    width: 318pt;
    height: 176.25pt;
    background: var(--white-color);
}

.card-id-viewer__horizontal-card img {
    position: relative;
    width: 318pt;
    height: 176.25pt;
}

.card-id-viewer__vertical-card {
    position: relative;
    width: 205.50pt;
    height: 290.55pt;
    background: var(--white-color);
}

.card-id-viewer__vertical-card img{
    position: relative;
    width: 205.50pt;
    height: 290.55pt;
}`, "",{"version":3,"sources":["webpack://./src/app/cards/components/card-id-viewer/CardIdViewerComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".card-id-viewer__content{\n    display: flex;\n    justify-content: center;    \n}\n\n.card-id-viewer__horizontal-card {\n    position: relative;\n    width: 318pt;\n    height: 176.25pt;\n    background: var(--white-color);\n}\n\n.card-id-viewer__horizontal-card img {\n    position: relative;\n    width: 318pt;\n    height: 176.25pt;\n}\n\n.card-id-viewer__vertical-card {\n    position: relative;\n    width: 205.50pt;\n    height: 290.55pt;\n    background: var(--white-color);\n}\n\n.card-id-viewer__vertical-card img{\n    position: relative;\n    width: 205.50pt;\n    height: 290.55pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
