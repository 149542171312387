import React from 'react';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
// Styles
import './TournamentPhasesSummaryComponent.styles.css';
//i18
import { withTranslation } from 'react-i18next';

function TournamentPhasesSummaryComponent(props) {
    return (
        <React.Fragment>
            <div className='tournament-phases__content_reviewPhase'>
                <p className="baloa-subtitle-1 color-text">
                    {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelFirstPhase')}&nbsp;&nbsp;
                    {props.tournament_phases?.length ?? props.number_phase?.split(" ")[1]}
                    {props.phaseName?.length > 0 && <React.Fragment>&nbsp;-&nbsp;{props.phaseName}</React.Fragment>}
                </p>
                {props.general_summary
                    ? <div className='tournament-phases__content_reviewPhase-general'>
                        {props.groupsCount[0]?.groups_number > 0 &&
                            <React.Fragment>
                                {props.groupsCount[0]?.groups_number &&
                                    <div className='tournament-phases__container-text'>
                                        <div className="color-text baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelPhaseOfGroupsFinal')}</div>
                                        <div className="color-text-blob baloa-table">{props.groupsCount[0]?.groups_number}</div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        {props.classifiedGroup > 0 &&
                            <div className='tournament-phases__container-text'>
                                <div className="color-text baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelClasifiedByGroupSummary')}</div>
                                <div className="color-text-blob baloa-table">{props.classifiedGroup}</div>
                            </div>
                        }
                        <div className='tournament-phases__container-text'>
                            <div className="color-text baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelBestThird')}</div>
                            <div className="color-text-blob baloa-table">{props.best_third
                                ? props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelChkTrue')
                                : props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelChkFalse')
                            }</div>
                        </div>
                    </div>
                    : <div className='tournament-phases__content_reviewPhase-text'>
                        {props.groupsCount[0]?.groups_number > 0 &&
                            <React.Fragment>
                                {props.groupsCount[0]?.groups_number &&
                                    <p>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroupsSummary', { _cantGroups: props.groupsCount[0]?.groups_number, _cantTeams: props.groupsCount[0]?.teams_number })}</p>}
                                &nbsp;&nbsp;
                                {props.groupsCount[2]?.mixedGroups &&
                                    <p>{' '}{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelNumberOfGroupsSummary2', { _cantGroupsAlt: props.groupsCount[1]?.groups_number, _cantTeamsAlt: props.groupsCount[1]?.teams_number })} </p>}
                            </React.Fragment>
                        }
                        {props.classifiedGroup > 0 &&
                            <p>{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelClasifiedByGroup', { _clasifiedByGroup: `${props.classifiedGroup}` })}</p>
                        }
                    </div>
                }
            </div>
            {props.general_summary &&
                <div className="tournament-phases__content_reviewPhase_button">
                    <PrimaryButtonComponent onClick={() => props.handleOnCreatePhaseBtn()}>
                        {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelCreatePhase')}
                    </PrimaryButtonComponent>
                </div>
            }
        </React.Fragment>
    )
}

export default withTranslation('league')(TournamentPhasesSummaryComponent);