// React
import React from 'react';
import { Link } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';

// Styles
import './LeagueTeamListComponent.styles.css'

// Assets
import team_shield_icon from 'assets/icons/team-shield.svg';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// React device detect
import { isMobile } from 'react-device-detect';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function LeagueTeamListComponent( props ) {

    const [listToFilter, setListToFilter] = React.useState(props.page_teams);


    React.useMemo(() => setListToFilter(props.page_teams), [props.page_teams]); /* eslint-disable */

    const handleSearchTable = word => {
        const currentFilter = word.toLowerCase();
        setListToFilter(
            props.page_teams?.filter(function (team) {
                return team['name'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }));
    };

    return(
        <div className='teams-list__container'>
            {/* !isMobile && 
                <div className="teams-list__title baloa-normal-medium">{props.t("options.teamsAndMembers.labelTitleTeams")}</div>
             */}
             {isMobile && props.is_page_admin && props.permissions_controller &&
                <SecondaryButtonComponent
                    type={'button'}
                    className={"teams-list__option-button small"}
                    onClick={ ()=>{ props.handleOnShareTeamPageInscription() }}
                >
                    <span className={`icon hubicon-person_add`}>
                        <span className="path2"></span>
                        <span className="path3"></span> 
                    </span>
            
                    <span>{ props.t('team:newTeam.forms.players.multiButton.invitePlayer') }</span>
                </SecondaryButtonComponent>
             }
             <div class="teams-list__search-form" >
                <span className="icon-search" />
                <input className="teams-list__search_input baloa-names"
                    type="text" placeholder={props.t('team:teams.teamsSearchLabel')}
                    onChange={(event) => { handleSearchTable(event.target.value) }}>                        
                </input>                
                {props.is_page_admin && props.permissions_controller && !isMobile &&
                <React.Fragment>
                    <PrimaryButtonComponent
                        type={'button'}
                        className={"teams-list__option-button small"}
                        onClick={ props.addTeam }
                    >
                        <span className={`icon  hubicon-create_team`}>
                            <span className="path2"></span>
                            <span className="path3"></span> 
                        </span>
                
                        <span>{ props.t('team:newTeam.title') }</span>
                    </PrimaryButtonComponent>
                    <SecondaryButtonComponent
                        type={'button'}
                        className={"teams-list__option-button"}
                        onClick={ ()=>{ props.handleOnShareTeamPageInscription() }}
                    >
                        <span className={`icon hubicon-person_add`}>
                            <span className="path2"></span>
                            <span className="path3"></span> 
                        </span>
                
                        <span>{ props.t('team:newTeam.forms.players.multiButton.invitePlayer') }</span>
                    </SecondaryButtonComponent>
                </React.Fragment>
                }
            </div>
            <div className='teams-list__teams'>
                {!isMobile && 
                <div className='teams-list__teams-headers teams-list__grid-display'>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsNameLabel')}</div>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsCategoryLabel')}</div>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsGenderLabel')}</div>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsMembersLabel')}</div>
                </div>
                }
                {isMobile && 
                <div className='teams-list__teams-headers teams-list__grid-display'>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsNameLabel')}</div>
                    <div className='baloa-table-column'>{props.t('team:teams.teamsMembersLabel')}</div>
                </div>
                }
                {listToFilter?.map ( (team ) => (
                    <div  className='teams-list__container__card teams-list__grid-display'>
                        { props.is_page_admin && props.permissions_controller && !isMobile
                            ?
                            <Link to={"/league/team/"+ props.pagename + "&setshowteam=" + team.pagename +"&showteam=true" } >
                                <div className='teams-list__container__card__info'>
                                    {!team.photo &&
                                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                                    }
                                    {team.photo &&
                                        <img src={team.photo ? team.photo : team_shield_icon } alt="" className="teams-list__container__card__info__img"/>
                                    }
                                    <div className="teams-list__container__card__info__detail">
                                        <p className='baloa-names'>{team.name}</p>
                                        <p className='baloa-username'>@{team.pagename}</p>
                                    </div>
                                </div>
                            </Link>
                            :
                            <div onClick={ () => { props.handleOnTeamInfoModal(team) } }>
                                <div className='teams-list__container__card__info'>
                                    {!team.photo &&
                                        <span className="icon-ball" color={getRandonIconBallColor()}/>
                                    }
                                    {team.photo &&
                                        <img src={team.photo ? team.photo : team_shield_icon } alt="" className="teams-list__container__card__info__img"/>
                                    }
                                    <div className="teams-list__container__card__info__detail">
                                    <p className='baloa-names'>{team.name}</p>
                                        {isMobile 
                                        ? <div className='teams-list__subText_movil'>
                                            <p className='baloa-username'>@{team.team_gender}</p> -
                                            <p className='baloa-username'>@{team.team_category}</p>
                                        </div>
                                        : <p className='baloa-username'>@{team.pagename}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {!isMobile &&
                            <React.Fragment>
                            <div className='baloa-table'>{props.t(`options.tournaments.pages.newTournament.tabs.tabTeams.${team.team_category}`)}</div>
                            <div className='baloa-table'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${team.team_gender}`)}</div>
                            <div className='baloa-table'>{team.players_count}</div>
                            </React.Fragment>
                        }
                        {isMobile && 
                            <React.Fragment>
                            <div className='baloa-table'>{team.players_count}</div>
                            </React.Fragment>
                        }
                    </div>                    
                ))
            }
            </div>
        </div>
    )
}

export default withTranslation('league') (LeagueTeamListComponent);