// React
import React from 'react';

// Moment
import * as moment from 'moment';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { useTranslation } from 'react-i18next';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentMatchEditMainInfoComponent from '../tournament-match-edit-main-info/TournamentMatchEditMainInfoComponent';

// Styles
import './TournamentMatchComponent.styles.css';

function   TournamentMatchComponent( props ) {    
    const { t } = useTranslation('league'); 
    const [infoType, setInfoType] = React.useState('');
    const [optionsOpen, setOptionsOpen] = React.useState(false);

    const handleEdit = (info_type) => {
        if( props.match.state === 'Finalized' && (info_type === 'date-time' || info_type === 'field') ) {
            setInfoType('');
        } else {
            setInfoType(info_type);
        }
    }

    const handleSubmitForm = (info_type, form_values) => {
        let match_city = props.cities?.filter( (city) => city.name === props.match.match_city )
        let match_info = {
            "local_team": props.match.local_team,
            "visitant_team": props.match.visitant_team,
            "match_date": props.match.match_date ? props.match.match_date : null,
            "match_time": props.match.match_time ? props.match.match_time : null,
            "field": props.match.match_field ? props.match.match_field.name : null,
            "match_city": match_city[0] ? match_city[0]?.id : null, 
            "official_referee": props.match.official_referee ? props.match.official_referee : null,
            "second_assistant_referee": props.match.second_assistant_referee ? props.match.second_assistant_referee : null,
            "third_assistant_referee": props.match.third_assistant_referee ? props.match.third_assistant_referee : null,
            "fourth_official_referee": props.match.fourth_official_referee ? props.match.fourth_official_referee : null,
            "referee_assigner": props.match.referee_assigner ? props.match.referee_assigner : null,
            "date_match": props.match.date_id,
            "match_field": props.match.match_field ? props.match.match_field.id : null,
        }

        if(info_type === 'date-time') { 
            const [date, time] = form_values.match_date.split('T');
            match_info.match_date = date;
            match_info.match_time = time;
         }
         if(info_type === 'field') { 
            match_info.match_field = form_values.field;
         }
         if(info_type === 'referee') { 
            match_info.official_referee = form_values.referee.profile
         }
         props.handleSubmitEditInfoMatch(props.match.id, match_info);
         setInfoType('');
    }


    const handleMatchOptions = (option) => {
        if(option === 'delete_match') {
            props.handleDeleteMatchTournament( props.match );
        }
        if(option === 'view_report') {
            props.handleMatchReport( props.match );
        }
        if(option === 'download_report') {
            props.handleDownloadMatchReport( props.match );
        }
        setOptionsOpen(false);
    }
   
    return (
        <div className='tournament-match__container' >
            <div className='tournament-match__info-container'>
                <div className='tournament-match__info'>
                    <div className={`tournament-match__info-edit ${ infoType === 'date-time' ? 'show' : '' }`} >
                        <TournamentMatchEditMainInfoComponent 
                            info_type='date-time'
                            title={t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddDateTime')}
                            handleClose={ () => setInfoType('') }
                            handleEditMatchInfo={ (values) => handleSubmitForm('date-time', values) }
                            
                        />
                    </div>                    
                    <span className='hubicon-finish_date' />
                    <div className='' onClick={ () => handleEdit('date-time') }  >
                        { !props.match.match_date && !props.match.match_time 
                            ? <div className='baloa-table tournament-match__info__not-scheduled'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddDateTime')}</div>
                            : <div className={`baloa-table ${props.match.state === 'Scheduled' ? 'tournament-match__info__scheduled' : ''}`}>{`${moment( props.match.match_date ).format( 'MMMM D YYYY' )} - ${moment( props.match.match_time, "HH:mm:ss" ).format("hh:mm A")}`} </div>
                        }
                    </div>
                </div>
                <div className='tournament-match__info'>
                    <div className={`tournament-match__info-edit ${ infoType === 'field' ? 'show' : '' }`} >
                        <TournamentMatchEditMainInfoComponent 
                            info_type='field'
                            title={t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddField')}
                            handleClose={ () => setInfoType('') }
                            handleEditMatchInfo={ (values) => handleSubmitForm('field', values) }
                            tournament_fields={ props.tournament_fields }
                            new_page_field={ props.new_page_field }
                            handleFieldsAdminActions={ props.handleFieldsAdminActions }
                            handleTournamentFieldActions={ props.handleTournamentFieldActions }
                        />
                    </div>                    
                    <span className='hubicon-stadium' />
                    <div className='baloa-table' onClick={ () => handleEdit('field') } >
                        { !props.match.match_field && props.match.state !== 'Finalized'
                            ? <div className='baloa-table tournament-match__info__not-scheduled'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddField')}</div>
                            : <div className={`baloa-table ${(props.match.state === 'Scheduled' || props.match.state === 'Not_scheduled') ? 'tournament-match__info__scheduled' : ''}`}>{ !props.match.match_field ? props.match.field : props.match.match_field?.name }</div>
                        }
                    </div>
                </div>
                <div className='tournament-match__info'>
                    <div className={`tournament-match__info-edit ${ infoType === 'referee' ? 'show' : '' }`} >
                        <TournamentMatchEditMainInfoComponent
                            info_type='referee'
                            title={t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddReferee')}
                            handleClose={ () => setInfoType('') }
                            staff={props.staff}
                            handleEditMatchInfo={ (values) => handleSubmitForm('referee', values) }
                            handleAddStaf= { props.handleAddStaf }
                        />
                    </div>
                    <span className='icon-wistle' />
                    <div className='baloa-table' onClick={ () => handleEdit('referee') } >
                        { !props.match.official_referee 
                            ? <div className='baloa-table tournament-match__info__not-scheduled'>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelAddReferee')}</div>
                            : <div className={`baloa-table tournament-match__info__official-referee`}>{props.match.official_referee_name}</div>
                        }
                    </div>
                    <div className='tournament-match__info__options'>
                        <span className='icon-options' onClick={ () => setOptionsOpen(optionsOpen ? false : true) }/>
                        <div className={`tournament-match__info__options-box ${optionsOpen ? 'open' : ''} `} >
                            <div className='baloa-names' onClick={ () => handleMatchOptions('download_report') } >{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelDownloadReport')}</div>
                            <div className='baloa-names' onClick={ () => handleMatchOptions('delete_match') } >{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.matchOptions.deleteMatch')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tournament-match__teams-container'>
                <div className='tournament-match__team'>
                    <div className='baloa-names'>{ props.match.local_team_name }</div>
                    <div className='tournament-match__team-img'>
                        { props.match.local_team_photo
                            ?
                            <img src={props.match.local_team_photo} alt={`${ props.match.local_team } img`} />
                            :
                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                        }
                    </div>
                </div>
                <div className='tournament-match__teams-status__container'>
                    {props.match.state === 'Finalized' &&
                        <div className='baloa-subtitle-2'>{`${props.match.local_team_score} - ${props.match.visitant_team_score}`}</div>
                    }
                    {props.match.state !== 'Finalized' &&
                        <div className='baloa-subtitle-2'>VS</div>
                    }
                    { props.match.state === 'Scheduled' && <div className={ `tournament-match__teams-status__label baloa-username ${ props.match.state }` } >{t('scheduled')}</div> }
                    { props.match.state === 'Not_scheduled' && <div className={ `tournament-match__teams-status__label baloa-username ${ props.match.state }` } >{t('not_scheduled')}</div> }
                    { props.match.state === 'Finalized' && <div className={ `tournament-match__teams-status__label baloa-username ${ props.match.state }` } >{t('ended')}</div> }
                </div>
                <div className='tournament-match__team'>
                    <div className='tournament-match__team-img'>
                        { props.match.visitant_team_photo
                            ?
                            <img src={props.match.visitant_team_photo} alt={`${ props.match.visitant_team } img`} />
                            :
                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                        }
                    </div>
                    <div className='baloa-names'>{ props.match.visitant_team_name }</div>
                </div>
            </div>
            <div className={`tournament-match__actions-container ${props.match.state === 'Finalized' ? 'finalized' : ''}`}>
                { props.match.state === 'Scheduled' && <SecondaryButtonComponent onClick={ () => props.handleEditMatchTournament(props.match) } ><span className='hubicon-date icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelEditMatchInfo')}</span></SecondaryButtonComponent> }
                { props.match.state === 'Scheduled' && <PrimaryButtonComponent onClick={ () => props.handleOnFinishMatchActionClick(props.match) } ><span className='icon-wistle icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelReportResult')}</span></PrimaryButtonComponent> }
                { props.match.state === 'Not_scheduled' && <PrimaryButtonComponent onClick={ () => props.handleEditMatchTournament(props.match) } ><span className='hubicon-date icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelProgramMatch')}</span></PrimaryButtonComponent>} 
                { props.match.state === 'Finalized' && 
                    <React.Fragment>
                        <SecondaryButtonComponent disabled={!props.match.can_edit_match} onClick={ () => props.handleOnFinishMatchActionClick(props.match) } ><span className='hubicon-edit icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelEditResult')}</span></SecondaryButtonComponent> 
                        <SecondaryButtonComponent onClick={ () => props.handleEditMatchTournament(props.match) } ><span className='hubicon-date icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelEditMatchInfo')}</span></SecondaryButtonComponent> 
                        <SecondaryButtonComponent onClick={ () => handleMatchOptions('view_report') } ><span className='hubicon-view_form icon' /><span>{t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.lebelMatchReport')}</span></SecondaryButtonComponent> 
                    </React.Fragment>
                }

            </div>
        </div>
    );
}

export default TournamentMatchComponent;