
// React
import React from 'react';
import PropTypes from 'prop-types';

// Components

import FeedComponentV2 from 'shared/components/feed/FeedComponentV2';
//import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Shapes
import { PostShape } from 'app/posts/shapes/PostShape';
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';

// Styles
import './HomeFeedComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Home Feed Component:
//   Load and render Posts and handles their actions.

function HomeFeedComponent( props ) {

    return (
        <div 
        className = { 
            "home-feed " + ( props.className ? props.className : '' ) 
        }
        >
            
            {/* No Posts. Invitation to follow profiles */}
            { props.posts?.length === 0 ? 
                // <React.Fragment>
                //     <p className="home-feed__suggestions-title baloa-headline-4">
                //         {props.t('homeFeed.headLine')}</p>
                //     <p className="home-feed__suggestions-title-sm baloa-subtitle-1">
                //         {props.t('homeFeed.subtitle')}</p>
                //     <p className="home-feed__suggestions-title-sm baloa-body-2">
                //         {props.t('homeFeed.headLine')}</p>
                //     <ProfileSuggestionsComponent
                //         profiles = { props.profile_suggestions.slice(0, props.number_of_showing_profiles_suggestions) }
                //         className = "home-feed__suggestions"
                //         handleFollow = { ( profile ) => { props.handleFollow( profile ) } }
                //     />
                // </React.Fragment>
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={300} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={300} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={300} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={300} style={{ marginTop: 30 }}/>
                </React.Fragment>
                : 
                <FeedComponentV2 
                    className="home-feed__feed"

                    posts = { props.posts }
                    me_photo = { props.me_photo }
                    show_group = { true }

                    handleOnLoadMorePosts = { () => props.handleOnLoadMorePosts() }

                    //handleOnProfileClick = { ( profile ) => props.handleOnProfileClick( profile ) }
                    //handleOnPageClick = { ( page ) => props.handleOnPageClick( page ) }
                    
                    handleClap = { ( post ) => props.handleClap( post ) }
                    onCloseClapsModal={() => { props.onCloseClapsModal( true ) }}
                    loadPostClapsById={(post_id) => props.loadPostClapsById(post_id)}
                    
                    handleOnMessageIconClick = { ( post ) => props.handleOnMessageIconClick( post ) }
                    handleOnCommentsClick = { ( post ) => props.handleOnCommentsClick( post ) }
                    handleOnComment = { ( post, comment, pagename, page_id ) => props.handleOnComment( post, comment, pagename, page_id) }
                    
                    openReportPostModal={() => {props.openReportPostModal( true )}}
                    handleReportPost={(post_id) => {props.handleReportPost(post_id)}}
                    
                    openBlockPostModal={() => {props.openBlockPostModal( true )}}
                    handleBlockProfile={(username) => {props.handleBlockProfile(username)}}
                    handleBlockPage={(pagename) => {props.handleBlockPage(pagename)}}
                    
                    handleOnDeletePostClick = { ( post ) => props.handleOnDeletePostClick( post ) }
                    
                    handleOnCopyLinkClick = {(post) => {props.handleOnCopyLinkClick(post)}}

                    handleOnAuthorClick = { props.handleOnAuthorClick }
                    
                    menu_is_open = {props.menu_is_open}
                    isOpen={props.isOpen}
                    profile = { props.profile }
                    user = { props.user }
                    page = { props.page }
                    sendReaction= { props.sendReaction }
                    overrideReaction= { props.overrideReaction }
                    handleReactionsDetails = { props.handleReactionsDetails }
                    loadPostReactionsById = { props.loadPostReactionsById }
                    deleteReaction = { props.deleteReaction }
                    followPostProfile = { props.followPostProfile }                            
                    unfollowPostProfile = { props.unfollowPostProfile }                            
                    followPostPage = { props.followPostPage }                            
                    unfollowPostPage = { props.unfollowPostPage }

                    handleOnClickQuizAnswer={ props.handleOnClickQuizAnswer }
                    handleOnClickPollAnswer ={ props.handleOnClickPollAnswer }
                    handleOnQuoteIconClick = { props.handleOnQuoteIconClick }
                />
            }

        </div>
    )
}

// Types
HomeFeedComponent.propTypes = {

    className: PropTypes.string,

    // Data
    posts: PropTypes.arrayOf( PropTypes.shape( PostShape ) ).isRequired,
    profile_suggestions: PropTypes.arrayOf( PropTypes.shape( ProfileShape ) ).isRequired,
    me_photo: PropTypes.string.isRequired,
    number_of_showing_profiles_suggestions: PropTypes.number.isRequired,

    // Scroll
    handleOnLoadMorePosts: PropTypes.func.isRequired,

    // Profile/Page clicks
    handleOnProfileClick: PropTypes.func.isRequired,
    handleOnPageClick: PropTypes.func.isRequired,
    
    // Claps
    handleClap: PropTypes.func.isRequired,
    onCloseClapsModal: PropTypes.func, // TODO: Bad name
    loadPostClapsById: PropTypes.func, // TODO: To be removed
    
    // Comments
    handleOnMessageIconClick: PropTypes.func.isRequired,
    handleOnCommentsClick: PropTypes.func.isRequired,
    handleOnComment: PropTypes.func.isRequired,
    
    // Abuse report
    openReportPostModal: PropTypes.func, // TODO: Bad name
    handleReportPost: PropTypes.func,

    // Block author
    openBlockPostModal: PropTypes.func, // TODO: Bad name
    handleBlockProfile: PropTypes.func,
    handleBlockPage: PropTypes.func,

    // Options
    handleOnEditPostClick: PropTypes.func, // TODO: To be implemented
    handleOnDeletePostClick: PropTypes.func,

    // Share
    handleOnCopyLinkClick: PropTypes.func.isRequired,

}

// Exports
export default withTranslation('home')(HomeFeedComponent);
