
export const USERS_LOADING_STATES = {
    NONE: 0,
}

// Users State
const users_state = {
    loading: USERS_LOADING_STATES['NONE'],
    me: {},
    languages: {},
}

export default function UsersReducer ( state = users_state, action ) { 
    switch ( action.type ) {
        
        case "USERS:LOAD_ME": {
            return { ...state, loading: USERS_LOADING_STATES['NONE'], me: action.data }
        }

        case "USERS:LOAD_LANGUAGES": {
            return { ...state, languages: action.data }
        }

        // no default

    }
    return state;
}

