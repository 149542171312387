//React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import CheckFieldComponent from 'shared/components/check-field/CheckFieldComponent';

//Styles
import './TournamentCloseMatchModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentCloseMatchModalComponent(props) {

    const formInitialValues = {
        check : false,
    }

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}
            onClick={()=>{}}
            className={"modal-match"}
        >
            <p className="modal-title">                
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningCloseMatch.labelTitle')}
            </p>
            <p className="modal-text">
                {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningCloseMatch.labelInfo')}                    
            </p>
            <FormComponent 
                formInitialValues={formInitialValues} 
                onSubmit={( values, actions ) => { 
                
                }}
                className="modal-check__form"
            >
                <CheckFieldComponent
                    input_id="check" 
                    field_name={"check"}
                    // validationFunction={ CheckValidator }
                    disabled={false}
                    className="modal-check__form__check"
                    input_type="checkbox"
                    resetError={()=>{}}
                    error=""
                    message=""
                    placeholder="Data"
                    checked={props.checked}
                    onClick ={() => { props.checked ? props.handleEndMatchReminder(false): props.handleEndMatchReminder(true) }}
                >
                    <p>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningCloseMatch.labelCheck')}</p>
                </CheckFieldComponent>

                <div className="login-modal-actions"> 

                    <TextButtonComponent 
                        onClick={ ()=>{
                            props.handleCleanData(false);
                            props.onClose();
                        }}
                    >
                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningCloseMatch.labelBackAction')}</span>
                    </TextButtonComponent>
                    
                    <SecondaryButtonComponent 
                        onClick={() => {
                            props.onNewDataComment(props.comments);
                            props.handleCleanData(true);
                            props.onClose();
                        }}
                    >
                        <span>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalWarningCloseMatch.labelAction')}</span>
                    </SecondaryButtonComponent>
                    
                </div>
            </FormComponent>
            
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentCloseMatchModalComponent);