// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Styles
import './TournamentPageFieldsAdminModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentPageFieldsAdminModalComponent ( props ) {

    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.pageFields.labelTitle')}
            className="tournament-page-fields-admin__container"
         >
            <div className='tournament-page-fields-admin__subtitle baloa-table'>{ props.t('options.pageFields.labelText') }</div>
            <div className='tournament-page-fields-admin__fields'>
                <div className='tournament-page-fields-admin__fields__create-action'>
                    <div className='baloa-hyperlink-2'>{ props.t('options.pageFields.labelActionTitle') }</div>
                    <SecondaryButtonComponent onClick={ () => props.handleFieldsAdminActions('create-page-field', undefined) } ><span className='hubicon-add_option' /> <span>{ props.t('options.pageFields.labelActionBtn') }</span></SecondaryButtonComponent>
                </div>
                <div className='tournament-page-fields-admin__fields__list-container'>
                    { props.page_fields.length === 0 &&
                        <div className='tournament-page-fields-admin__fields__list__empty'>
                            <div className='baloa-table-column'>{ props.t('options.pageFields.labelNoFields') }</div>
                            <div className='baloa-table-column' onClick={ () => props.handleFieldsAdminActions('create-page-field') } >{ props.t('options.pageFields.labelActionBtn') }</div>
                        </div>
                    }
                    { props.page_fields.length > 0 && 
                        <div className='tournament-page-fields-admin__fields__list'>
                            { props.page_fields?.map( field => (
                                <div key={field.id} className='tournament-page-fields-admin__fields__field' >
                                    <div className='tournament-page-fields-admin__fields__field-info'>
                                        <div className='baloa-table-column'>{field.name}</div>
                                        <div className='baloa-username'>{field.address}</div>
                                    </div>
                                    <div className='tournament-page-fields-admin__fields__field-actions'>
                                        <span className='hubicon-edit' onClick={ () => props.handleFieldsAdminActions('edit-page-field', field) } />
                                        <span className='hubicon-delete' onClick={ () => props.handleFieldsAdminActions('delete-page-field', field) } />
                                    </div>                                    
                                </div>
                            ))}
                        </div>            
                    }                    
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentPageFieldsAdminModalComponent);