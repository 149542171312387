/*eslint eqeqeq: "off"*/
// Clickstream state
const clickstream_state = {
    UserId: '',
    Channel: 'web',
    Events: [],
    screen_time_start: undefined,
    screen_time_end: undefined,
}

export default function ClickstreamReducer( state = clickstream_state, action ) {
    
    switch ( action.type ) {

        case "CLICKSTREAM:ADD_EVENT": {
            
            let new_events = {...state, Events: [...state.Events, action.data ]}
            return { ...state, Events: new_events.Events }

        }

        case "CLICKSTREAM:RESET_EVENTS": {
            return { ...state, Events: [] }
        }

        case "CLICKSTREAM:SET_USER_ID": {
            return { ...state, UserId: action.data }
        }

        case "CLICKSTREAM:SCREEN_TIME_START": {
            return { ...state, screen_time_start: action.data }
        }

        case "CLICKSTREAM:SCREEN_TIME_END": {
            return { ...state, screen_time_end: action.data }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt
        // no default
            
    }

    return state;
}