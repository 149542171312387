// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-match-referees-crew-confirmation-payment .modal__container {
    max-width: 360px;
    padding: unset;
}

.league-match-referees-crew-confirmation-payment__container{
    margin-top: 50px;
}

.league-match-referees-crew-confirmation-payment__container .baloa-normal-medium {
    color: var(--primary-very-dark-color);
    padding: var(--space-x3) var(--space-and-half);
    padding: 40px 24px;
}

.league-match-referees-crew-confirmation-payment__action-btns {
    padding: var(--space-half) var(--space);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--new-input-background-color);
}

.league-match-referees-crew-confirmation-payment__action-btns .secondary-button {
    margin-right: var(--space-half-quarter);
    border: 2px solid var(--basic-gray-color);
    color: var(--basic-gray-color);
}

.league-match-referees-crew-confirmation-payment__action-btns .secondary-button:hover {
    background-color: unset;
}

.league-match-referees-crew-confirmation-payment__action-btns .button {
    padding: var(--space-half) var(--space);
    grid-template-columns: none;
    grid-template-areas: none;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-match-referees-crew-confirmation-payment-modal/LeagueMatchRefereesCrewConfirmationPaymentModal.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,8CAA8C;IAC9C,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uDAAuD;AAC3D;;AAEA;IACI,uCAAuC;IACvC,yCAAyC;IACzC,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uCAAuC;IACvC,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".league-match-referees-crew-confirmation-payment .modal__container {\n    max-width: 360px;\n    padding: unset;\n}\n\n.league-match-referees-crew-confirmation-payment__container{\n    margin-top: 50px;\n}\n\n.league-match-referees-crew-confirmation-payment__container .baloa-normal-medium {\n    color: var(--primary-very-dark-color);\n    padding: var(--space-x3) var(--space-and-half);\n    padding: 40px 24px;\n}\n\n.league-match-referees-crew-confirmation-payment__action-btns {\n    padding: var(--space-half) var(--space);\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    border-top: 1px solid var(--new-input-background-color);\n}\n\n.league-match-referees-crew-confirmation-payment__action-btns .secondary-button {\n    margin-right: var(--space-half-quarter);\n    border: 2px solid var(--basic-gray-color);\n    color: var(--basic-gray-color);\n}\n\n.league-match-referees-crew-confirmation-payment__action-btns .secondary-button:hover {\n    background-color: unset;\n}\n\n.league-match-referees-crew-confirmation-payment__action-btns .button {\n    padding: var(--space-half) var(--space);\n    grid-template-columns: none;\n    grid-template-areas: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
