// React
import React from 'react';

// Formik
import { Field } from 'formik';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import { ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import Autocomplete  from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import { withStyles } from '@mui/styles';


// Component styles
import './SelectActionBtnComponent.styles.css'

export default function SelectActionBtnComponent(props) {

    const [selectedOption, setSelectedOption] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const inputRef = React.useRef(null);

    const AutocompleteTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--basic-gray-color)',
            },          
            '& .MuiOutlinedInput-root': {
                '& fieldset': {             
                    border: 'var(--new-input-background-color)',
                },      
            },
        },
    })(TextField);

    const handleOnChange = (value, form) => {
        /* if (value === 'add_option') {
            // Reset the field value and clear any error
            form.setFieldValue(props.field_name, '');
            form.setFieldError(props.field_name, undefined);
            props.addItem();
        } else {       
            // Update Formik state with the new value and trigger validation 
            form.setFieldValue(props.field_name, value);
            props.onChange(value);
        } */

        if(value) {       
            // Update Formik state with the new value and trigger validation 
            setSelectedOption(value);
            setInputValue(value[props.optionSelectedField]);
            if( props.select_type === "referees" ){
                form.setFieldValue(props.field_name, value);
            }else {
                form.setFieldValue(props.field_name, value[props.optionSelectedField]);
            }
            
            props.onChange(value[props.optionSelectedField]);
        }
    };

    const handleClear = (form) => {
        setSelectedOption(null);
        form.setFieldValue(props.field_name, '');
        props.onChange(null);
        setInputValue('');
    }

    React.useEffect(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, [inputValue]);

    return (
        <FormControl className={`select-action-btn__container ${props.className}`} >
            <Field name={ props.field_name } validate={ props.validationFunction }  >
                {({ form }) => (
                    <React.Fragment>
                        { props.label && <label htmlFor={ props.id }>{ props.label }</label> }
                        {/* <Select                        
                            id={props.id}
                            {...field}
                            value={field.value || ''}
                            onChange={ (event) => handleOnChange(event.target.value, form) }
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            defaultValue={ props.defaultValue ? () => handleOnChange(props.defaultValue, form) : '' }
                            variant='standard'
                        >
                            <MenuItem className='select-action-btn__placeholder' value="" disabled>
                                {props.placeholder}
                            </MenuItem>
                            <MenuItem value="add_option" className='select-action-btn__add-option'>
                                <span className='hubicon-add_option' />
                                <div className='baloa-username'>{props.btn_add_label}</div>
                            </MenuItem>
                            { props.options && props.select_type === 'img_text' && props.options.map( (option, index) => (
                                <MenuItem key={index} value={option} className='select-action-btn__item'>
                                    { option.photo &&
                                        <img src={ option.photo } alt=""/>
                                    }
                                    { !option.photo &&
                                        <span className="icon-ball" color={ getRandonIconBallColor() } />
                                    }
                                    <ListItemText primary={`${option.first_name} ${option.last_name}`} secondary={`@${option.profile}`}/>
                                </MenuItem>
                            ))}
                            { props.options && props.select_type === 'text' && props.options.map( (option, index) => (
                                <MenuItem key={index} value={option.value} className=''>
                                    {option.caption}
                                </MenuItem>
                            ))}
                        </Select> */}
                        <Autocomplete                    
                            disabled={ props.disabled }
                            //freeSolo={props.freeSolo ? props.freeSolo : false}
                            options={props.options}
                            noOptionsText= { props.noOptionsText }
                            getOptionLabel={(option) => {
                                if(option[props.optionLabelField]){
                                    return (`${option[props.optionLabelField]}`)
                                }
                                else{
                                    return '';
                                }
                            }}                            
                            getOptionKey={option => option[props.optionSelectedField] }
                            disablePortal={true}
                            groupBy={ (option)=>{} }
                            renderGroup={(params) => (
                                props.select_type === "fields"?
                                    <div key={params.key}>
                                        <MenuItem value="add_option" className='select-action-btn__add-option' onClick={()=>{ props.addItem() }}>
                                            <span className='hubicon-add_option' />
                                            <div className='baloa-username'>{props.btn_add_label}</div>
                                        </MenuItem>
                                        {params.children.map((child) => (
                                            <MenuItem {...child.props} key={child.key} className=''>
                                                {child.props.children}
                                            </MenuItem>
                                        ))}
                                        
                                    </div>
                                :
                                    <div key={params.key}>
                                        <MenuItem value="add_option" className='select-action-btn__add-option' onClick={()=>{ props.addItem() }}>
                                            <span className='hubicon-add_option' />
                                            <div className='baloa-username'>{props.btn_add_label}</div>
                                        </MenuItem>
                                        {params.children.map((child) => (
                                            <MenuItem {...child.props} key={child.key} className='select-action-btn__item'>
                                                { child.props.value.photo &&
                                                    <img src={ child.props.value.photo } alt=""/>
                                                }
                                                { !child.props.value.photo &&
                                                    <span className="icon-ball" color={ getRandonIconBallColor() } />
                                                }
                                                <ListItemText primary={`${child.props.value.first_name} ${child.props.value.last_name}`} secondary={`@${child.props.value.profile}`}/>
                                            </MenuItem>
                                        ))}
                                        
                                    </div>
                            )}

                            isOptionEqualToValue={( option, value ) => {
                                if(value){
                                    return option[props.optionSelectedField] === value[props.optionSelectedField] 
                                }else if(!option){
                                    return null
                                }else{
                                    return null
                                }
                            }}
                            value={ props.value }
                            defaultValue={props.defaultValue? props.defaultValue : null}
                            onChange={ (event, value) => {handleOnChange(value, form)} }
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                
                            }}
                            renderOption={(comprops, option) => {
                                if(props.select_type === "fields"){
                                    return <MenuItem {...comprops} key={option.id} value={option.value} className=''>
                                                {option.caption}
                                            </MenuItem>
                                }else if(props.select_type === "referees"){
                                    return <MenuItem {...comprops} key={option.id} value={option} className='select-action-btn__item'>
                                                { option.photo &&
                                                    <img src={ option.photo } alt=""/>
                                                }
                                                { !option.photo &&
                                                    <span className="icon-ball" color={ getRandonIconBallColor() } />
                                                }
                                                <ListItemText primary={`${option.first_name} ${option.last_name}`} secondary={`@${option.profile}`}/>
                                            </MenuItem>
                                }
                            }}
                            renderInput={(params) => (                        
                                <div className={`select-action-btn__select ${props.className} ${selectedOption? 'selected' : ''}`} >
                                    {(selectedOption && params.inputProps.value !== "" && props.select_type === "referees") 
                                    ?
                                    <MenuItem key={selectedOption.id}  className='select-action-btn__item' {...params.inputProps}>
                                        { selectedOption.photo &&
                                            <img src={ selectedOption.photo } alt=""/>
                                        }
                                        { !selectedOption.photo &&
                                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                                        }
                                        <ListItemText primary={`${selectedOption.first_name} ${selectedOption.last_name}`} secondary={`@${selectedOption.profile}`}/>
                                        {!props.disabled && 
                                            <ListItemSecondaryAction onClick={() => { handleClear( form ) }}>
                                                <span className='hubicon-clear_option' />
                                            </ListItemSecondaryAction>
                                        }
                                    </MenuItem>
                                    :
                                    <AutocompleteTextField
                                        {...params}
                                        className={ props.className }
                                        inputRef={inputRef}
                                        //label={ props.label }
                                        variant="outlined"
                                        margin="normal"
                                        placeholder={ props.placeholder }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    }
                                </div>
                            )}
                        />
                        {form.errors[props.field_name] && form.touched[props.field_name] && (
                            <FormHelperText error>{form.errors[props.field_name]}</FormHelperText>
                        )}              
                    </React.Fragment>
                )}
                
            </Field>
        </FormControl>
    );
}
