// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* .home-feed {
    margin: var(--space-x2) 0;
} */

.home-feed__suggestions-title {}
.home-feed__suggestions-title-sm {
    display: none !important;
}

.home-feed__suggestions {
    margin-top: var(--space-x2);
}

/* .home-feed__feed {
    margin-top: var(--space-x2);
} */

/* Small */

.home-feed.sm .home-feed__suggestions-title{
    font-size: var(--font-normal) !important;
    font-weight: var(--font-regular) !important;
    color: var(--basic-gray-color);
}

.home-feed.sm .home-feed__suggestions__sugestion{
    font-size: var(--font-normal) !important;
    font-weight: var(--font-regular) !important;
    color: var(--basic-gray-color);
}

.home-feed.sm .home-feed__suggestions-title-label {
    display: block;
}

@media screen and ( max-width: 1024px ) {
    
    .home-feed__suggestions-title {
        text-align: center;
    }
    
}

@media screen and ( max-width: 576px ) {
    
    .home-feed__suggestions-title {
        display: none;
    }
    .home-feed__suggestions-title-sm {
        display: block !important;
        margin-bottom: var(--space-half);
    }

}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    
    .home-feed {
        padding: var(--space);
    }
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-feed/HomeFeedComponent.styles.css"],"names":[],"mappings":";AACA;;GAEG;;AAEH,+BAA+B;AAC/B;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;;GAEG;;AAEH,UAAU;;AAEV;IACI,wCAAwC;IACxC,2CAA2C;IAC3C,8BAA8B;AAClC;;AAEA;IACI,wCAAwC;IACxC,2CAA2C;IAC3C,8BAA8B;AAClC;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;QACI,kBAAkB;IACtB;;AAEJ;;AAEA;;IAEI;QACI,aAAa;IACjB;IACA;QACI,yBAAyB;QACzB,gCAAgC;IACpC;;AAEJ;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["\n/* .home-feed {\n    margin: var(--space-x2) 0;\n} */\n\n.home-feed__suggestions-title {}\n.home-feed__suggestions-title-sm {\n    display: none !important;\n}\n\n.home-feed__suggestions {\n    margin-top: var(--space-x2);\n}\n\n/* .home-feed__feed {\n    margin-top: var(--space-x2);\n} */\n\n/* Small */\n\n.home-feed.sm .home-feed__suggestions-title{\n    font-size: var(--font-normal) !important;\n    font-weight: var(--font-regular) !important;\n    color: var(--basic-gray-color);\n}\n\n.home-feed.sm .home-feed__suggestions__sugestion{\n    font-size: var(--font-normal) !important;\n    font-weight: var(--font-regular) !important;\n    color: var(--basic-gray-color);\n}\n\n.home-feed.sm .home-feed__suggestions-title-label {\n    display: block;\n}\n\n@media screen and ( max-width: 1024px ) {\n    \n    .home-feed__suggestions-title {\n        text-align: center;\n    }\n    \n}\n\n@media screen and ( max-width: 576px ) {\n    \n    .home-feed__suggestions-title {\n        display: none;\n    }\n    .home-feed__suggestions-title-sm {\n        display: block !important;\n        margin-bottom: var(--space-half);\n    }\n\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    \n    .home-feed {\n        padding: var(--space);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
