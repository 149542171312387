
export const SIGNUP_STATUS = {
    START: 1,
    PHONE: 2,
    OTP: 3,
    FORM: 4,
    USERNAME_ASSIGNMENT: 5,
    GOAL: 6,
    LOGIN: 7,
    EMAIL: 8,
    EMAIL_VERIFICATION: 9,
    LOGIN_EMAIL: 10,
    PROFILE_TYPE: 11,
    RECOVER_PASSWORD: 12,
    RECOVER_PASSWORD_VERIFICATION: 13,
    NEW_PASSWORD_EMAIL: 14,
    TEAM_PANEL: 15,
    CLAIM_ACCOUNT: 16,
    CLAIM_ACCOUNT_OPTIONS: 17
}

export const SIGNUP_LOADING_STATES = {
    NONE: 0,
    PHONE: 1,
    OTP: 2,
    RESEND_OTP: 3,
    WHATSAPP_OTP: 4,
    FORM: 5,
    USERNAME_ASSIGNMENT: 6,
    SOCIAL_FACEBOOK: 7,
    EMAIL: 8,
    EMAIL_VERIFICATION: 9,
    LOGIN_EMAIL: 10,
    PROFILE_TYPE: 11,
    RECOVER_PASSWORD: 12,
    RECOVER_PASSWORD_VERIFICATION: 13,
    NEW_PASSWORD_EMAIL: 14
}

// SignUp State
const signup_state = {
    status: SIGNUP_STATUS['START'],
    phone: '',
    loading: SIGNUP_LOADING_STATES['NONE'],
    show_login_modal: false,
    show_welcome_modal: false,
    logged_in: localStorage.getItem('token') ? true : false,
    //logged_in: false, 
    error: '',
    terms_modal: false,
    terms_conditions: [],
    email: '',
    cities: [],
    countries: [],
    countryId:'',
    recovery_password_code: '',
    teams: [],
    click_type: '',
    redirect_url: undefined,
    complete_user_info_modal: false,
    professional_types: [],
    pre_registered_members: [],
    claim_account_profile: undefined,
    document_verification: false,
}

export default function SignUpReducer ( state = signup_state, action ) { 
    switch ( action.type ) {

        case "SIGNUP:CHANGE_STATUS": {
            return { ...state, status: action.data }
        }

        case "SIGNUP:CHANGE_PHONE": {
            return { ...state, phone: action.data }
        }

        case "SIGNUP:CHANGE_LOADING": {
            return { ...state, loading: action.data }
        }

        case "SIGNUP:CHANGE_LOGIN_MODAL": {
            return { ...state, show_login_modal: action.data }
        }

        case "SIGNUP:CHANGE_WELCOME_MODAL": {
            return { ...state, show_welcome_modal: action.data }
        }

        case "SIGNUP:CHANGE_LOGGED_IN": {
            return { ...state, logged_in: action.data }
        }

        case "SIGNUP:CHANGE_TERMS_MODAL": {
            return { ...state, terms_modal: action.data }
        }

        case "SIGNUP:LOAD_TERMS_CONDITIONS_TEXT": {
            return { ...state, terms_conditions: action.data }
        }

        case "SIGNUP:CHANGE_EMAIL": {
            return { ...state, email: action.data }
        }

        case "SIGNUP:GET_COUNTRIES": {
            return { ...state, countries: action.data.data.results, countryId: action.data.data?.results[0]?.id }
        }

        case "SIGNUP:GET_CITIES_BY_COUNTRY": {
            return { ...state, cities: action.data.data.cities  }
        }

        case "SIGNUP:CHANGE_RECOVERY__PASSWORD_CODE": {
            return { ...state, recovery_password_code: action.data  }
        }

        case "SIGNUP:GET_TEAMS": {
            return { ...state, teams: action.data }
        }

        case "SIGNUP:CHANGE_CLICK_TYPE": {
            return { ...state, click_type: action.data }
        }

        case "SIGNUP:SET_URL_REDIRECT":{
            return { ...state, redirect_url: action.data }
        }

        case "SIGNUP:CHANGE_COMPLETE_USER_INFO_MODAL_STATE": { 
            return { ...state, complete_user_info_modal: action.new_state }
        }

        case "SIGNUP:LOAD_PROFESSION_TYPES": {
            return { ...state, professional_types: action.data.data }
        }

        case "SIGNUP:LOAD_PRE_REGISTERED_MEMBERS": {
            return { ...state, pre_registered_members: action.data.data }
        }

        case "SIGNUP:SET_CLAIMED_ACCOUNT_PROFILE": {
            return { ...state, claim_account_profile: action.data }
        }

        case "SIGNUP:CLEAN_PRE_REGISTERED_MEMBERS": {
            return { ...state, pre_registered_members: [] }
        }

        case "SIGNUP:CLEAN_CLAIMED_ACCOUNT_PROFILE": {
            return { ...state, claim_account_profile: undefined }
        }

        case "SIGNUP:REGISTER_DOCUMENT_VERIFICATION": { 
            return { ...state, document_verification: action.new_state }
        }

        // no default

    }
    return state;
}
