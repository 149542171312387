/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import CheckFieldComponent from 'shared/components/check-field/CheckFieldComponent';

import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import LeagueTeamUploadImageModalComponent from 'app/leagues/components/league-team-upload-image-modal/LeagueTeamUploadImageModalComponent';
import InputFieldMultilineComponent from 'shared/components/input-field-multiline/InputFieldMultilineComponent';

// Styles
import './ProfileExperiencesModalComponent.styles.css';

// Validators
import { RequiredValidator, yearRequiredValidator, yearValidator } from 'shared/validators/RequiredValidator';


// i18n
import { withTranslation } from 'react-i18next';

function ProfileExperiencesModalComponent( props ) {
    
    const formInitialValues = {
        experience: {
            achievement_name: props.experience?.achievement_name ? props.experience.achievement_name : "",
            institution: props.experience?.institution ? props.experience.institution : "",
            year: props.experience?.year ? props.experience.year : "",
            description: props.experience?.description ? props.experience.description : "",            
        },
        trajectory: {
            team_name: props.experience?.team_name ? props.experience.team_name : "",
            currently_playing: props.experience?.currently_playing ? props.experience.currently_playing : false,
            start_year: props.experience?.start_year ? props.experience.start_year : "",
            end_year: props.experience?.end_year ? props.experience.end_year : "",
            description: props.experience?.description ? props.experience.description : "",
        }
    }

    const [modalTitle, setModalTitle ] = React.useState('');
    const [modalSubTitle, setModalSubTitle ] = React.useState('');
    const [uploadLogoLabel, setUploadLogoLabel] = React.useState('');
    const [achievementNamePlaceholder, setAchievementNamePlaceholder] = React.useState('');
    const [teamNameLabel, setTeamNameLabel] = React.useState('');
    const [currentLabel, setCurrentLabel] = React.useState('');
    const [imagesSubtitle, setImagesSubtitle] = React.useState('');
    const [modalUploadGallery, setModalUploadGallery] = React.useState(false);
    const [achievementsGallery, setAchievementsGallery] = React.useState( [] );
    const [teamLogo, setTeamLogo] = React.useState();
    const [modalUploadTeamLogo, setModalUploadTeamLogo] = React.useState(false);
    const [currently, setCurrently] = React.useState(false);
    const formAchievementRef = React.createRef();
    const formTrajectoryRef = React.createRef();

    React.useEffect(() => {
        if(props.experience_type === 'achievements') {
            if(props.edit_experience) {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.achievementEditTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.achievementEditSubtitle'));
            } else {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.achievementAddTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.achievementAddSubtitle'));
            }
            setImagesSubtitle(props.t('profileExperiences.profileExperiencesModal.imagesSubtitle'));
            
            props.profile_type === 'professional'?
                setAchievementNamePlaceholder(props.t('profileExperiences.profileExperiencesModal.professionalAchievementNamePlaceholder'))
                :
                setAchievementNamePlaceholder(props.t('profileExperiences.profileExperiencesModal.achievementNamePlaceholder'));
            
            
        } else if(props.experience_type === 'trajectory') {
            if(props.edit_experience) {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.trajectoryEditTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.trajectoryEditSubtitle'));
            } else {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.trajectoryAddTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.trajectoryAddSubtitle'));
            }
            setUploadLogoLabel(props.t('profileExperiences.profileExperiencesModal.uploadLogoTeam'));
            setTeamNameLabel(props.t('profileExperiences.profileExperiencesModal.teamNameLabel'));
            setCurrentLabel(props.t('profileExperiences.profileExperiencesModal.currentLabel'));
            setImagesSubtitle(props.t('profileExperiences.profileExperiencesModal.trajectoryImagesSubtitle'));
        } else if(props.experience_type === 'experiences') {
            if(props.edit_experience) {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.experienceEditTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.experienceEditSubtitle'));
            } else {
                setModalTitle(props.t('profileExperiences.profileExperiencesModal.experienceAddTitle'));
                setModalSubTitle(props.t('profileExperiences.profileExperiencesModal.experienceAddSubtitle'));
            }
            setUploadLogoLabel(props.t('profileExperiences.profileExperiencesModal.uploadLogoExperience'));
            setTeamNameLabel(props.t('profileExperiences.profileExperiencesModal.organizationNameLabel'));
            setCurrentLabel(props.t('profileExperiences.profileExperiencesModal.currentOrganizationLabel'));
            setImagesSubtitle(props.t('profileExperiences.profileExperiencesModal.experienceImagesSubtitle'));
        }
    },[props]);// eslint-disable-line

    React.useEffect(() => {
        if(props.experience?.photos?.length > 0){
            setAchievementsGallery(props.experience.photos);
        } 
    },[props.experience?.photos]);

    React.useEffect(() => {
        if(props.experience?.team_logo){
            setTeamLogo(props.experience.team_logo);
        } 
    },[props.experience?.team_logo]);

    React.useEffect(() => {
        if(props.experience?.currently_playing){
            setCurrently(props.experience.currently_playing);
        } 
    },[props.experience?.currently_playing]);

    const handleUploadImg = (image, imageurl, img_b64) => {
        
        setAchievementsGallery([...achievementsGallery, {image: image, url:imageurl, name:image.name}]);

    }

    const handleUploadTeamLogo = (image, imageurl, img_b64) => {
        setTeamLogo({image: image, url:imageurl, name:image.name});
    }

    const handleRemoveImg = (index) => {
        const new_array = [...achievementsGallery.slice(0, index), ...achievementsGallery.slice(index + 1)];
        setAchievementsGallery(new_array);
    }

    const createFormData = (values) => {

        let oldImages = [];
        let formData = new FormData();
        formData.append("achievement_name", values.achievement_name);
        formData.append("institution", values.institution);
        formData.append("year", values.year);
        formData.append("description", values.description);

        /* eslint-disable */
        achievementsGallery.map(img =>{
            if(img.image){
                formData.append("photos", img.image, img.name);
            }else{
                oldImages.push(img);
            }
            
        })
        /* eslint-enable */
        if(oldImages.length > 0){
            formData.append("urls", JSON.stringify(oldImages) );
        }

        if(props.edit_experience) {        
            props.onNewData(props.experience.id, formData );
        }else{
            props.onNewData( formData );
        }
        
        closeModal();
    }

    const createTrajectoryFormData = (values) => {

        let oldImages = [];
        let formData = new FormData();
        formData.append("team_name", values.team_name);
        formData.append("currently_playing", values.currently_playing);
        formData.append("start_year", values.start_year);
        formData.append("end_year", values.end_year);
        formData.append("description", values.description);

        /* eslint-disable */
        achievementsGallery.map(img =>{
            if(img.image){
                formData.append("photos", img.image, img.name);
            }else{
                oldImages.push(img);
            }
            
        })
        /* eslint-enable */

        if(teamLogo.image){
            formData.append("logo", teamLogo.image, teamLogo.name);
        }
        if(oldImages.length > 0){
            formData.append("urls", JSON.stringify(oldImages) );
        }

        if(props.edit_experience) {        
            props.onNewData(props.experience.id, formData );
        }else{
            props.onNewData( formData );
        }
        
        closeModal();
    }

    const closeModal = () =>{
        setModalTitle('');
        setAchievementsGallery([]);
        setTeamLogo('');
        props.onClose();
    }

    const handleCurrently = (event) =>{
        setCurrently(event.target.checked);
    }

    return (
       
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={() => { closeModal() }}
            title={modalTitle}
            // button_type="submit"
            className='profile-experiences__modal-container'
        >


            { props.experience_type === 'achievements' &&
                <div className="profile-experiences__container scroll-parent">
                    <div className='baloa-table-column'>{modalSubTitle}</div>
                    <FormComponent 
                        formInitialValues={ formInitialValues.experience } 
                        onSubmit={( values, actions ) => {                            
                            createFormData(values);
                        }}
                        className="experience-form__form scroll"
                        functionDisabled={()=>{}}
                        innerRef={formAchievementRef}
                    >
                        <div className="profile-experiences__achievements-form">
                            <InputFieldComponent 
                                label={props.t('profileExperiences.profileExperiencesModal.achievementName')} 
                                input_id="achievement_name" 
                                field_name="achievement_name"
                                validationFunction={ RequiredValidator }
                                disabled={false}
                                className="experience-form__form__input"
                                input_type="text"
                                resetError={ () => props.onResetError( { 'achievement_name': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={achievementNamePlaceholder}
                                defaultValue={formInitialValues.experience.achievement_name}
                            />
                            <InputFieldComponent 
                                label={props.t('exp.label4')} 
                                input_id="institution" 
                                field_name="institution"
                                validationFunction={ RequiredValidator }
                                disabled={false}
                                className={ "experience-form__form__input " + ( props.is_profile ? "" : "hidden" ) }
                                input_type={ props.is_profile ? "text" : "hidden" }
                                display="none"
                                resetError={ () => props.onResetError( { 'institution': "" } ) }
                                error={ props.errors }
                                message=""
                                placeholder={props.t('profileExperiences.profileExperiencesModal.achievementInstitutionPlaceholder')}
                                defaultValue={formInitialValues.experience.institution}
                            />

                            <div className="experience-form__form__fields-group experience-form__form__fields-date">
                                <span className='hubicon-date'/>

                                <InputFieldComponent 
                                    //label={props.t('exp.label8')}
                                    label={props.t('profileExperiences.profileExperiencesModal.inputYear')} 
                                    input_id="year" 
                                    field_name="year"
                                    validationFunction={ yearRequiredValidator }
                                    disabled={false}
                                    className="experience-form__form__input"
                                    input_type="text"
                                    resetError={ () => props.onResetError( { 'year': "" } ) }
                                    error={ props.errors }
                                    message=""
                                    placeholder={props.t('profileExperiences.profileExperiencesModal.inputYearPlaceholder')} 
                                    defaultValue={formInitialValues.experience.year}
                                    maxLength="4"
                                />
                            </div>
                            <InputFieldMultilineComponent 
                                label={props.t('data.modal.title2')}
                                input_id="description" 
                                field_name="description"
                                validationFunction={ RequiredValidator }
                                disabled={false}
                                className="experience-form__form__input"
                                input_type="text"
                                resetError={ () => props.onResetError( { 'description': "" } )}
                                error={ props.errors }
                                message=""                        
                                placeholder=""
                                rows={ 5 }
                            />
                            <div className='profile-experiences__upload-gallery'>
                                <div className='baloa-table-column'>{props.t('profileExperiences.profileExperiencesModal.imagesTitle')}</div>
                                <div className='baloa-username'>{imagesSubtitle}</div>
                                <SecondaryButtonComponent disabled={achievementsGallery.length === 3} type='button' onClick={() => {setModalUploadGallery(true); }}>
                                    <span className='hubicon-add_option' />
                                    <span>{props.t('profileExperiences.profileExperiencesModal.imagesAddBtn')}</span>
                                </SecondaryButtonComponent>                                
                                <div className='profile-experiences__upload-gallery__add'>
                                    { achievementsGallery.length > 0 && achievementsGallery.map((img, index) => (
                                        <div key={index} className='profile-experiences__upload-gallery__add-img'>
                                            <span className='hubicon-clear_option' onClick={ () => {handleRemoveImg(index)} } />
                                            <img src={img.url} alt={'img'} />
                                        </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>                        
                    </FormComponent>
                    <div className="experience-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            //disabled={ !state.achievements_form_is_valid } 
                            onClick={()=>{formAchievementRef.current.handleSubmit()}} 
                            className="experience-form__form__submit"
                        >
                            <span>{props.t('settings.btnSave')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </div>
            }
            {(props.experience_type === 'trajectory'  || props.experience_type === 'experiences') &&            
                <div className="profile-experiences__container scroll-parent">
                    <div className='baloa-table-column'>{modalSubTitle}</div>
                    <FormComponent 
                        formInitialValues={ formInitialValues.trajectory } 
                        onSubmit={( values, actions ) => { 
                            createTrajectoryFormData(values);
                        }}
                        className="experience-form__form scroll"
                        innerRef={formTrajectoryRef}
                    >
        
                        <React.Fragment>
                            <div className='profile-experiences__team-logo__container'>
                                <div className='profile-experiences__team-logo__logo' onClick={() => {setModalUploadTeamLogo(true); } }>
                                    { teamLogo
                                        ? <img src={teamLogo.image? teamLogo.url: teamLogo} alt='logo' />
                                        : <span className="hubicon-default_pp"><span className="path1"></span><span className="path2"></span></span>
                                    }
                                    <span className='hubicon-add_option' />
                                </div>
                                <div className='profile-experiences__team-logo__upload-img baloa-table-column' onClick={() => {setModalUploadTeamLogo(true); } }>{uploadLogoLabel}</div>
                            </div>
                            <div className="profile-experiences__trajectory-form">
                                <InputFieldComponent 
                                    label={ teamNameLabel } 
                                    input_id="team_name" 
                                    field_name="team_name"
                                    validationFunction={ RequiredValidator }
                                    disabled={false}
                                    className="experience-form__form__input"
                                    input_type="text"
                                    resetError={ () => props.onResetError( { 'team_name': "" } ) }
                                    error={ props.errors }
                                    message=""
                                    defaultValue={formInitialValues.trajectory.team_name}
                                />
                                <CheckFieldComponent
                                    input_id="currently_playing"
                                    disabled={false}
                                    className="experience-form__form__trajectory__check-input"
                                    field_name="currently_playing"
                                    error={ props.errors }
                                    onClick={handleCurrently}
                                    checked={currently}                                 
                                >
                                    <div className="baloa-table-column">{currentLabel}</div>
                                </CheckFieldComponent>
                                

                                <div className="experience-form__form__fields-group experience-form__form__fields-date__container">
                                    <div className="experience-form__form__fields-group experience-form__form__fields-date">
                                        <span className='hubicon-date'/>
                                        <InputFieldComponent 
                                            label={props.t('profileExperiences.profileExperiencesModal.startYearLabel')} 
                                            input_id="start_year" 
                                            field_name="start_year"
                                            validationFunction={ yearRequiredValidator }
                                            disabled={false}
                                            className="experience-form__form__input"
                                            input_type="text"
                                            resetError={ () => props.onResetError( { 'start_year': "" } ) }
                                            error={ props.errors }
                                            message=""
                                            maxLength="4"
                                            placeholder={props.t('profileExperiences.profileExperiencesModal.inputYearPlaceholder')}
                                            defaultValue={formInitialValues.trajectory.start_year}
                                        />
                                    </div>
                                    <div className={`experience-form__form__fields-group experience-form__form__fields-date${currently? 'hidden' : ''}`}>
                                        <span className={`hubicon-date ${currently? 'disabled hidden' : ''}`}/>
                                        <InputFieldComponent 
                                            label={props.t('profileExperiences.profileExperiencesModal.endYearLabel')} 
                                            input_id="end_year" 
                                            field_name="end_year"
                                            validationFunction={ currently?()=>{} : yearValidator }
                                            disabled={currently}
                                            className={`experience-form__form__input ${currently? 'disabled hidden' : ''}`}
                                            input_type="text"
                                            resetError={ () => props.onResetError( { 'end_year': "" } ) }
                                            error={ props.errors }
                                            message=""
                                            maxLength="4"
                                            placeholder={props.t('profileExperiences.profileExperiencesModal.inputYearPlaceholder')}
                                            defaultValue={formInitialValues.trajectory.end_year}
                                        />
                                    </div>

                                </div>

                                <InputFieldMultilineComponent 
                                    label={props.t('data.modal.title2')}
                                    input_id="description" 
                                    field_name="description"
                                    validationFunction={ RequiredValidator }
                                    disabled={false}
                                    className="experience-form__form__input"
                                    input_type="text"
                                    resetError={ () => props.onResetError( { 'description': "" } )}
                                    error={ props.errors }
                                    message=""                        
                                    placeholder=""
                                    rows={ 5 }
                            />

                                <div className='profile-experiences__upload-gallery'>
                                    <div className='baloa-table-column'>{props.t('profileExperiences.profileExperiencesModal.imagesTitle')}</div>
                                    <div className='baloa-username'>{imagesSubtitle}</div>
                                    <SecondaryButtonComponent disabled={achievementsGallery.length === 3} type='button' onClick={() => {setModalUploadGallery(true); }}>
                                        <span className='hubicon-add_option' />
                                        <span>{props.t('profileExperiences.profileExperiencesModal.imagesAddBtn')}</span>
                                    </SecondaryButtonComponent>                                
                                    <div className='profile-experiences__upload-gallery__add'>
                                        {achievementsGallery.length > 0 && achievementsGallery.map((img, index) => (
                                            <div key={index} className='profile-experiences__upload-gallery__add-img'>
                                                <span className='hubicon-clear_option' onClick={ () => {handleRemoveImg(index)} } />
                                                <img src={img.url} alt={'img'} />
                                            </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>

                            
                
                        </React.Fragment>
                    </FormComponent>
                    <div className="experience-form__form__actions form__actions">
                        <PrimaryButtonComponent                                 
                            type="submit"
                            //disabled={ !state.experiences_form_is_valid } 
                            onClick={()=>{formTrajectoryRef.current.handleSubmit()}} 
                            className="experience-form__form__submit"
                        >
                            <span>{props.t('settings.btnSave')}</span>
                        </PrimaryButtonComponent>
                    </div>
                </div> 
            
            }

            <LeagueTeamUploadImageModalComponent 
                isOpen={ modalUploadGallery }
                onClose={() => {setModalUploadGallery(false)}}
                setTeamImage={ (new_img, imageurl, img_b64, binary) => { handleUploadImg(new_img, imageurl, img_b64, binary) }}
                aspect={3/2}
                cropImage={false}
            />
            <LeagueTeamUploadImageModalComponent 
                isOpen={ modalUploadTeamLogo }
                onClose={() => {setModalUploadTeamLogo(false)}}
                setTeamImage={ (new_img, imageurl, img_b64) => { handleUploadTeamLogo(new_img, imageurl, img_b64)}}
                aspect={1}
                cropImage={true}
            />

        </Modal2Component>
    )
}

export default withTranslation('profile')(ProfileExperiencesModalComponent);