/*eslint eqeqeq: "off"*/
// React
import React from "react";

// Components
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";
import FormComponent from "shared/components/form/FormComponent";
import InputFieldComponent from "shared/components/input-field/InputFieldComponent";
import SelectFieldComponent from "shared/components/select-field/SelectFieldComponent";
import LeagueTeamUploadImageModalComponent from "../league-team-upload-image-modal/LeagueTeamUploadImageModalComponent";
// Styles
import "./LeagueNewTeamFormComponent.styles.css";
// i18n
import { withTranslation } from "react-i18next";

// Validators
import { RequiredValidator } from "shared/validators/RequiredValidator";
import { UserNameValidator } from "shared/validators/SignUpValidator";

function LeagueNewTeamFormComponent(props) {
	const [state, setState] = React.useState({
		new_team_image_modal: false,
	});

	const [teamImage, setStateTeamImage] = React.useState(null);
	const [teamImageUrl, setStateTeamImageUrl] = React.useState(null);
	const [teamImageB64, setStateTeamImageB64] = React.useState();

	const formInitialValues = {
		teamname: "",
		name: "",
		team_gender: "",
		category: "",
		//play_other_tournaments: true,
		logo: null,
	};

	const handleNewTeamImageModal = (new_state) => {
		setState({ new_team_image_modal: new_state });
	};

	const setTeamImage = (team_image, team_image_url, img_b64) => {
		setStateTeamImage(team_image);
		setStateTeamImageUrl(team_image_url);
		setStateTeamImageB64(img_b64);
	};

	return (
		<FormComponent
			//formInitialValues={ formikvalues.initialValues }
			formInitialValues={formInitialValues}
			enableReinitialize={true}
			onSubmit={(values, actions) => {
				props.stepCompleted(1);
				if (Object.keys(values).length !== 0) {
					props.onNewData(values, teamImage, teamImageB64);
				}
				props.setNewTeamStepState(true);
			}}
			className="new-team-form__form form scroll-parent"
			autocomplete="off"
		>
			<div className="new-team-form__container">
				<div className="new-team-form__shield">
					{teamImageUrl ? (
						<img src={teamImageUrl} alt="" />
					) : (
						<div>
							<div className="new-team-form__shield__team-photo-label">
                        {props.t("newTeam.forms.basicInfo.pictureTeamLabel")}
							</div>
							<div className="new-team-form__shield__no-photo-icon">
								<span className="icon-ball icon-team-2-64" />
							</div>
						</div>
					)}
					<div
						className="new-team-form__shield__new-button"
						onClick={() => {
							handleNewTeamImageModal(true);
						}}
					>
						{props.t("newTeam.forms.basicInfo.uploadPictureLabel")}
					</div>
				</div>
				<div className="new-team-form__form__content form__fields">
					<InputFieldComponent
						label={props.t("newTeam.forms.basicInfo.teamLabel")}
						input_id="new-page-name"
						field_name="name"
						validationFunction={RequiredValidator}
						disabled={false}
						className="new-team-form__form__input__name"
						input_type="text"
						resetError={() => props.onResetError({ name: "" })}
						error={props.errors}
						message=""
						placeholder=""
					/>

					<InputFieldComponent
						label={
							"@ " + props.t("newTeam.forms.basicInfo.teamnameLabel")
						}
						input_id="new-page-pagename"
						field_name="teamname"
						validationFunction={UserNameValidator}
						disabled={false}
						className="new-team-form__form__input__pagename"
						input_type="text"
						resetError={() => props.onResetError({ teamname: "" })}
						error={props.errors}
						message={props.t("newTeam.forms.basicInfo.teamnameMessage")}
						placeholder=""
					/>

               <SelectFieldComponent
						label={props.t("newTeam.forms.basicInfo.teamgenderLabel")}
						field_name="team_gender"
						validationFunction={RequiredValidator}
						disabled={false}
						className="new-team-form__form__input"
						resetError={() => props.onResetError({ team_gender: "" })}
						error={props.errors}
						message=""
						placeholder=""
						options={props.new_team_genders}
               />

					<SelectFieldComponent
						label={props.t("newTeam.forms.basicInfo.categoryLabel")}
						field_name="category"
						validationFunction={RequiredValidator}
						disabled={false}
						className="new-team-form__form__input"
						resetError={() => props.onResetError({ category: "" })}
						error={props.errors}
						message=""
						placeholder=""
						// options={ [ { value: 'kg', content: 'kg' } ] }
						options={props.new_team_categories}
					/>

				</div>

				<diV className="new-team-form__horizontal-line" />

				<PrimaryButtonComponent
					disabled={false}
					onClick={() => {}}
					className="new-team-form__submit"
					type="submit"
				>
					<span>{props.t("newTeam.forms.basicInfo.button")}</span>
				</PrimaryButtonComponent>
			</div>

			<LeagueTeamUploadImageModalComponent
				isOpen={state.new_team_image_modal}
				onClose={() => {
					handleNewTeamImageModal(false);
				}}
				//handleNewTeamImageModal={ (state)=>{ handleNewTeamImageModal(state)}}
				setTeamImage={(new_image, imageurl, img_b64) => {
					setTeamImage(new_image, imageurl, img_b64);
				}}
				aspect = {1}
				cropImage={true}
			/>
		</FormComponent>
	);
}

export default withTranslation("team")(LeagueNewTeamFormComponent);
