
// PropTypes
import PropTypes from 'prop-types';

// Shapes
import { UserShape } from 'app/authentication/shapes/UserShape';


export const ProfileShape = { 
    id: PropTypes.string, 
    profile_type: PropTypes.oneOf(['player', 'fanatic', 'professional']),
    user: PropTypes.shape( UserShape ),
}
