import React, { useState } from 'react';

// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';

import Switch from '@mui/material/Switch';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';

//Styles
import './ProfileAddVideoModalComponent.styles.css';

function ProfileAddVideoModalComponent(props) {
  const [videoRef, setVideoRef] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoOptions, setVideoOptions] = useState({
    play: false,
    audio: false,
  });
  const [addToMatch, setAddToMatch] = useState(false);
  const [addToFeatured, setAddToFeatured] = useState(false);

  React.useEffect(()=>{
    if(!props.shareClipOnMatch){
        setAddToMatch(false)
    }
  },[props.shareClipOnMatch]);

  React.useEffect(()=>{
    if(props.post?.context?.has_paid_stats){
        setAddToMatch(true)
    }
  },[props.post]);

  /**
   * Uses the selected video from component ProfileFeaturedClips.js and forms the structure to be able 
   * to send to the back and publish the video
   * @param {String} video_title -- Prop of object containing form 
   */
  const onFileUpload = async ({ video_title, associate_match, featured }) => {

    if (props.selected_video_profile) {
      let formData = new FormData();
      formData.append("post_type", "Video");
      formData.append("channel", "Web");
      formData.append("caption", video_title);
      formData.append("post_video", props.selected_video_profile.videoData);
      if(props.type_modal_video_clip === 'featuredVideoClip' || featured){
          formData.append("is_profile_clip", true );
      }else{
          formData.append("is_profile_clip", false);
      }
      formData.append("is_profile_presentation_clip", props.type_modal_video_clip === 'presentationVideoClip');
      if((associate_match || props.type_modal_video_clip === 'matchVideoClip' ) && props.clipMatch){
        formData.append("associate_match", "True");
        formData.append("tournament_match_context", props.clipMatch.id);
      }

      props.uploadVideoProfile(formData, onUploadProgress, true);
    }
  };

  /**
   * Function in charge of keeping track of the process/duration 
   * when a video is published from the api
   * @param {*} progressEvent 
   */
  const onUploadProgress = (progressEvent) => {
    setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100));
  }

  /**
   * Resets all props whrn close the modal
   */
  const handleModalClose = () => {
    setVideoRef(null);
    setUploadProgress(0);
    setVideoOptions({ play: false, audio: false });
    setAddToMatch(false)
    props.onClose();
  }

  /**
   * The type of action to which the form should be directed is validated, whether it is to edit or publish a new video.
   * @param {Object} values -- Object containing values form 
   */
  const handleOnPostVideo = (values) => {
    if (props.type_action_modal_video_clip?.length > 0 && props.type_action_modal_video_clip?.includes('edit')) {
        if(props.type_action_modal_video_clip === 'editfeaturedVideoClip' || props.type_action_modal_video_clip === 'editmatchVideoClip' ){
            const params = {
              post_type: "Video",
              channel: "Web",
              caption: values.video_title,
            }
            if(props.type_action_modal_video_clip === 'editfeaturedVideoClip' || values.featured){
                params.is_profile_clip = true;
            }else{
                params.is_profile_clip =false;
            }
            if((values.associate_match || props.type_action_modal_video_clip === 'editmatchVideoClip' ) && props.clipMatch){
              params.associate_match = true;
              params.tournament_match_context = props.clipMatch.id;
            }
            if(props.type_action_modal_video_clip === 'editfeaturedVideoClip' && !values.associate_match){
              params.associate_match = false;
            }
            props.handleEditFeatureMatchVideoClip(props.post.id, params);
        }else{
            props.handleEditTitleVideoClip(props.post.id, values.video_title);
        }
      
    } else {
      onFileUpload(values);
    }
  };

  /**
   * Returns the text type for each video type based on the action type for the modal.
   * @returns {Object} The text type for each video
   */
  const renderTextModalType = () => {
    const modalTypes = {
      presentationVideoClip: {
        title: props.type_action_modal_video_clip === 'editpresentationVideoClip'
          ? props.t('addVideoClip.modals.labelEditOptionMenu')
          : props.t('addVideoClip.modals.labelModalTitlePresentationClip'),
        labelInputText: props.t('addVideoClip.modals.labelInputTextPresentationClip'),
        placeholderInputText: props.t('addVideoClip.modals.labelPlaceholderTextPresentationClip'),
      },
      featuredVideoClip: {
        title: props.type_action_modal_video_clip === 'editfeaturedVideoClip'
          ? props.t('addVideoClip.modals.labelEditClipOptionMenu')
          : props.t('addVideoClip.modals.labelModalTitleFeaturedClip'),
        labelInputText: props.t('addVideoClip.modals.labelInputTextFeaturedClip'),
        placeholderInputText: props.t('addVideoClip.modals.labelPlaceholderTextFeaturedClip'),
      },
      matchVideoClip: {
        title: props.type_action_modal_video_clip === 'editmatchVideoClip'
          ? props.t('addVideoClip.modals.labelEditMatchVideoOptionMenu')
          : props.t('addVideoClip.modals.labelModalTitleMatchClip'),
        labelInputText: props.t('addVideoClip.modals.labelInputTextMatchClip'),
        placeholderInputText: props.t('addVideoClip.modals.labelPlaceholderTextMatchClip'),
      },
    };
    return modalTypes[props.type_modal_video_clip] || {
      title: '...',
      labelInputText: '...',
      placeholderInputText: '...',
    };
  };

  const handlePlayOrPauseVideoClip = () => {
    if (videoRef.paused) {
      videoRef.play();
      setVideoOptions({ ...videoOptions, play: true })
    } else {
      videoRef.pause();
      setVideoOptions({ ...videoOptions, play: false })
    }
  };

  const handleSoundOrMuteVideoClip = () => {
    if (videoRef.muted) {
      videoRef.muted = false;
      setVideoOptions({ ...videoOptions, audio: false })
    } else {
      videoRef.muted = true;
      setVideoOptions({ ...videoOptions, audio: true })
    }
  }

  /**
   * Return the text of the video for input field renderings
   * @returns {String} -- Caption video
   */
  const setDefaultTextInput = () => {
    return (props.type_action_modal_video_clip?.length > 0 && props.type_action_modal_video_clip?.includes('edit'))
      ? props.post?.caption
      : '';
  }

  const handleChangeAddToMatch=(event)=>{
      setAddToMatch(event.target.checked);
      if(event.target.checked){
          props.handleLoadMatchClipModal();
      }else{
          props.cleanClipMatch()
      }     
  }

  const handleChangeAddToFeatured=(event)=>{
      setAddToFeatured(event.target.checked);       
  }

  const handleDeleteMatch=()=>{
      props.cleanClipMatch();
      props.handleLoadMatchClipModal();
  }

  const setInitialValues = () => {
      const initialValues = {
        video_title: setDefaultTextInput(),        
      }
      if(props.type_modal_video_clip !== 'matchVideoClip'){
          initialValues.associate_match = addToMatch;
      }

      if(props.type_modal_video_clip === 'matchVideoClip'){
          initialValues.featured = addToFeatured;
      }

      return initialValues;
  }

  return (
    <Modal2Component
      isOpen={props.isOpen}
      onClose={() => handleModalClose()}
      className={'profile_add_video_modal'}
      title={renderTextModalType().title}
    >
      <FormComponent
        formInitialValues={setInitialValues()}
        onSubmit={(values) => handleOnPostVideo(values)}
        className="profile_add_video_form scroll"
      >
        <div className='profile_add_video_form_container'>
          <InputFieldComponent
            label={renderTextModalType().labelInputText}
            input_id="video_title"
            field_name="video_title"
            validationFunction={() => { }}
            className="profile_add_video_input-title-clip"
            input_type="text"
            resetError={() => { }}
            error={{ video_title: "" }}
            placeholder={renderTextModalType().placeholderInputText}
            disabled={false}
            defaultValue={setDefaultTextInput()}
          />

          {(props.type_action_modal_video_clip === 'editfeaturedVideoClip' || props.type_action_modal_video_clip === 'editmatchVideoClip') && props.post.post_video
            ? <div className="profile_add_video-new-content__media">
              <video className="profile_add_video-new-content__media_video" preload="metadata"
                ref={el_ref => setVideoRef(el_ref)}
                src={props.post.post_video + "#t=0.001"}>
              </video>
              <div className="profile_add_video-new-content__media-control"
                onClick={() => handlePlayOrPauseVideoClip()}>
                <span className={!videoOptions.play ? "profile_add_video-new-content__media-control__icon" : ""} ></span>
              </div>
              <div className="profile_add_video-new-content__media_control-mute"
                onClick={() => handleSoundOrMuteVideoClip()}
              >
                <span className={`profile_add_video-new-content__media_control-mute__icon ${videoOptions.audio ? "icon-sound-off" : "icon-sound-on"}`}></span>
              </div>
            </div>
            : ''
          }

          {(props.type_action_modal_video_clip === '' || props.type_action_modal_video_clip?.length === 0) &&
            <div className='profile_add_video_container'>
              {props.selected_video_profile?.videoURL ?
                <div className="profile_add_video-new-content__media">
                  <video className="profile_add_video-new-content__media_video" preload="metadata"
                    ref={el_ref => setVideoRef(el_ref)}
                    src={props.selected_video_profile?.videoURL + "#t=0.001"}>
                  </video>
                  <div className="profile_add_video-new-content__media-control"
                    onClick={() => handlePlayOrPauseVideoClip()}>
                    <span className={!videoOptions.play ? "profile_add_video-new-content__media-control__icon" : ""} ></span>
                  </div>
                  <div className="profile_add_video-new-content__media_control-mute"
                    onClick={() => handleSoundOrMuteVideoClip()}
                  >
                    <span className={`profile_add_video-new-content__media_control-mute__icon ${videoOptions.audio ? "icon-sound-off" : "icon-sound-on"}`}></span>
                  </div>
                </div>
                : ''
              }
              <div className='profile_add_video-new-content'>
                <progress max="100" value={uploadProgress}
                  className="profile_add_video-new-content__progress">
                  {uploadProgress}
                </progress>
                {uploadProgress === 100 ? handleModalClose() : null}
              </div>
            </div>
          }
        </div>        
        <div className='profile_add_video-new-content__add-to-match'>
            {props.type_modal_video_clip !== 'matchVideoClip' && props.type_modal_video_clip !== 'presentationVideoClip' && !props.disableAddToMatch &&
                <div className='baloa-names'>
                    {props.t('addVideoClip.modals.labelAddMatches')}
                        <Switch
                            checked={ addToMatch }
                            onChange={  (e) => {
                                handleChangeAddToMatch(e); 
                            }}
                            name="associate_match"
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={!props.profile_tournament_paid}
                            color="secondary"
                        />
                </div>
            }
            {props.type_modal_video_clip === 'matchVideoClip' &&
                <div className='baloa-names'>
                    {props.t('addVideoClip.modals.labelAddToFeatured')}
                        <Switch
                            checked={ addToFeatured }
                            onChange={  (e) => {
                                handleChangeAddToFeatured(e); 
                            }}
                            name="featured"
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={!props.profile_tournament_paid}
                            color="secondary"
                        />
                </div>
            }
            {props.clipMatch && props.type_modal_video_clip !== 'matchVideoClip' &&
                <React.Fragment>
                <div className='profile-add-video__table-body__container'>
                    <div className='profile-add-video__tournament-match-info'>
                        <div className='profile-add-video__tournament_info'>
                            <div className='profile-add-video__tournament_info__img'>
                                { props.clipMatch.tournament_logo
                                    ? <img src={props.clipMatch.tournament_logo} alt={`${props.clipMatch.tournament_name} logo`} />
                                    : <span className='icon-ball' color={getRandonIconBallColor()} />
                                }
                            </div>
                            <div className='baloa-table'>{props.clipMatch.tournament_name}</div>                                                                         
                        </div>
                        <div className='profile-add-video__match_date baloa-table'>
                            {`${moment(props.clipMatch.match_date + ' ' + props.clipMatch.match_time).format('MMM DD, YYYY - h:mm A')}`}
                        </div>                              
                    </div>
                    <div className={`profile-add-video__tournament-match-data`}>
                        
                            <div className="profile-add-video__tournament-match-data__label baloa-table">{ props.clipMatch.local_team_name }</div>
                            {props.clipMatch.local_team_photo && 
                                <img src={ props.clipMatch.local_team_photo } alt={`${props.clipMatch.local_team_name} img`}/>
                            }
                            {!props.clipMatch.local_team_photo && 
                                <span className="icon-ball" color={getRandonIconBallColor()}/>
                            }
                            
                        
                            <div className="profile-add-video__tournament-match-data__score__left">
                                <div className='baloa-subtitle-2' >{ props.clipMatch.local_team_score }</div>
                                { props.clipMatch.local_team_penalty_score && <div className='baloa-subtitle-2' >{ `(${props.clipMatch.local_team_penalty_score}` }</div>}                            
                            </div>
                            <div className="profile-add-video__tournament-match-data__score__center">
                                <div className='baloa-subtitle-2' >-</div>
                                {props.clipMatch.local_team_penalty_score && <div className='baloa-subtitle-2' >-</div>}                            
                            </div>
                            <div className="profile-add-video__tournament-match-data__score__right">
                                <div className='baloa-subtitle-2' >{ props.clipMatch.visitant_team_score }</div>
                                { props.clipMatch.visitant_team_penalty_score && <div className='baloa-subtitle-2' >{ `${props.clipMatch.visitant_team_penalty_score})` }</div>}
                            </div>

                            {props.clipMatch.visitant_team_photo && 
                                <img src={ props.clipMatch.visitant_team_photo } alt={`${props.clipMatch.visitant_team_name} img`}/>
                            }
                            {!props.clipMatch.visitant_team_photo && 
                                <span className="icon-ball" color={getRandonIconBallColor()}/>
                            }
                            <div className="profile-add-video__tournament-match-data__label baloa-table">{ props.clipMatch.visitant_team_name }</div>                                   
                        
                    </div>
                    
                </div>

                <div className='profile-add-video__delete-match baloa-username' onClick={ ()=>{ handleDeleteMatch() } }>{props.t('addVideoClip.modals.deleteMatch')}</div>
                
                </React.Fragment>
            }
            {!props.profile_tournament_paid &&
                <div className='profile_add_video-new-content__add-to-match__message baloa-names'>
                    <span className='hubicon-info'></span>
                    {props.t('addVideoClip.modals.unblockTournamentsMsg')}
                    <PrimaryButtonComponent
                        className={'small unblock-button'}
                        type="button"
                        onClick={() => {props.handleModalUnlockData()}}
                    >
                        <span>{props.t('tournamentSummary.unlockBtn')}</span>
                    </PrimaryButtonComponent>
                </div>
            }
        </div>        
        <div className='profile_add_video_button-submit'>
            <PrimaryButtonComponent
                type="submit"
                onClick={() => { }}>
                <span>
                    {props.type_action_modal_video_clip?.length > 0 && props.type_action_modal_video_clip?.includes('edit')
                    ? props.t('addVideoClip.modals.labelEditOptionAction')
                    : props.t('addVideoClip.modals.modalPostButton')}
                </span>
            </PrimaryButtonComponent>
        </div>
      </FormComponent>
    </Modal2Component>
  )
}

export default withTranslation('profile')(ProfileAddVideoModalComponent);