// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal2.profile-post-comment-modal .modal2__container{
    width: 40%;
}

.profile-post-comment-modal__container{
    display: flex;
    align-items: flex-start;
}

.profile-post-comment-modal__comments{
    width: 100%;
}

.profile-post-comment-modal__comments .post__actions__action{
    margin-right: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-post-comment-modal/ProfilePostCommentModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".modal2.profile-post-comment-modal .modal2__container{\n    width: 40%;\n}\n\n.profile-post-comment-modal__container{\n    display: flex;\n    align-items: flex-start;\n}\n\n.profile-post-comment-modal__comments{\n    width: 100%;\n}\n\n.profile-post-comment-modal__comments .post__actions__action{\n    margin-right: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
