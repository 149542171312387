// React
import React from 'react';
import {Helmet} from "react-helmet";
// import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import {compose} from 'redux';

// Components
//import HomeBaner from 'app/home/components/baner/HomeBaner';
import HomeShortCutsComponent from 'app/home/components/home-shortcuts/HomeShortCutsComponent';
import PageSuggestionsComponent from 'app/pages/components/page-suggestions/PageSuggestionsComponent';
import PagesSuggestionsComponent from 'app/pages/components/page-groups-suggestions/PagesSuggestionsComponent';
//import PageGroupsSuggestionsMenuComponent from 'app/pages/components/page-groups-suggestions/PageGroupsSuggestionsMenuComponent';
import HomePagesUnauthMenuComponent from 'app/pages/components/home-pages-menu/HomePagesUnauthMenuComponent'; 
import HomePagesComponent from 'app/pages/components/home-pages/HomePagesComponent';
import HomePlayersFilterComponent from 'app/home/components/home-players-filter/HomePlayersFilterComponent';
import HomePlayersComponent from 'app/home/components/home-players/HomePlayersComponent';

//Container
import HomeFeedComponent from 'app/home/components/home-feed/HomeFeedComponent';

// Actions
import { loadHomePosts, loadUnauthenticatedHomePageSuggestions, loadHomePlayers, loadPlayerAttributes } from 'app/home/actions/HomeActions';
import { changeSignUpLoginModal, changeClickType } from 'app/authentication/actions/LoginActionsCreators';
import { loadUnauthenticatedGroupsSuggestions } from 'app/pages/actions/PageGroupsActions';
import { changeMobileMenuStateAction, } from 'app/home/actions/HomeActionsCreators';

// Styles
import './HomeAnonView.styles.css';

// Variables
import { web_url } from "config/GlobalData";

// Assets
import baloa_green_logo from 'assets/logos/baloa-logo.png';

// isMobile
import { isMobile } from 'react-device-detect';

class HomeAnonView extends React.Component {

    state = {
        content_info_to_show: "post",
    }

    componentDidMount() {
        this.props.loadHomePosts();
        this.props.loadUnauthenticatedHomePageSuggestions();
        this.props.loadUnauthenticatedGroupsSuggestions();

        //Set page_type to page from external link to page section
        if(this.props.location.pathname === '/pages'){
            this.handleClickMenuSugestions('pages');
        }

        //Set page_type to players from external link to players section
        if(this.props.location.pathname === '/players' ){
            this.handleClickMenuSugestions('players');
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.key !== this.props.location.key){
            this.handleClickMenuSugestions("post");
        }
    }

    handleClickMenuSugestions = (page_type) => {
        if( page_type === 'players'){
            this.props.loadHomePlayers( '', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
            this.props.loadPlayerAttributes();
        }
        this.setState({content_info_to_show: page_type});
    }

    handleHomeAnonModal = (modal_state, click_type) => {
        this.props.changeSignUpLoginModal(modal_state);
        this.props.changeClickType(click_type);
    }

    handleOnPageClick = ( page ) => {        
        if(page !== null && page.pagename !== null && page.pagename != undefined && page.pagename !== ""){
            this.props.history.push(`/page/${page.pagename}`);
        }
    }

    handleGoLink=(home_page_type)=>{
        switch(home_page_type){
            case 'players':
                this.props.history.push('/players');
                break;
            case 'pages':
                this.props.history.push('/pages');
                break;
            // no default
        }
    }

    render() {

        return (
            <React.Fragment>
                {/* <HomeBaner /> */}
                <div className={"home " + (this.state.content_info_to_show !== "post"? 'gphome':'')}>
                    {/* Social share headers */}
                    <Helmet>
                        <title>{`Baloa :: The Global Football Community`}</title>
                        {/* Facebook/WhatsApp  */}
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`${web_url}`} />
                        <meta property="og:title" content="Baloa" />
                        <meta property="og:description" content="The Global Football Community" />
                        <meta property="og:image" content={`${web_url}/logo_baloa_social_share.png`} />
                        <meta property="og:image:secure_url" content={`${web_url}/logo_baloa_social_share.png`} />
                        {/* Twitter  */}
                        <meta name="twitter:card" content="summary_large_image" />                    
                        <meta name="twitter:title" content="Baloa" />
                        <meta name="twitter:description" content="The Global Football Community" />
                        <meta name="twitter:image" content={`${web_url}/logo_baloa_social_share.png`} />
                        <meta name="twitter:url" content={`${web_url}`} />
                    </Helmet>
                    <div className={"home__left-container " + 
                        (this.state.content_info_to_show !== "post"? 
                            (this.state.content_info_to_show !== "pages" && this.state.content_info_to_show !== "explore" && this.state.content_info_to_show !== "players")? 
                        'gphome womenu' : (isMobile && this.state.content_info_to_show === "explore")? 'gphome mob-explore' :'gphome':'')}>
                        { this.state.content_info_to_show === "post" &&
                            <HomeShortCutsComponent
                                handleGoLink={(value)=>{this.handleGoLink(value)}}
                                handleCreateTeam = {()=>{this.handleHomeAnonModal(true, 'homeAnonCreateTeam')}}
                                handleJoinToTeam={()=>{this.handleHomeAnonModal(true, 'homeAnonJoinTeam')}}
                                handleAnonShortCuts={ () => {this.handleHomeAnonModal(true, 'homeAnonTournament')} }
                                is_home_anon_shortcuts={true}
                            />
                        }
                        { this.state.content_info_to_show !== "post" && this.state.content_info_to_show !== "players" &&
                            /* <PageGroupsSuggestionsMenuComponent
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                handleContentInfoActive = { this.state.content_info_to_show }
                            /> */
                            <HomePagesUnauthMenuComponent
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                handleContentInfoActive = { this.state.content_info_to_show }
                                onClickAddButton={()=>{ this.handleHomeAnonModal(true, 'pages'); }}
                                page_categories={ this.props.state.page.page_categories }
                                handleOnExplorePagesFilter={ ()=>{ this.handleHomeAnonModal(true, 'homeAnonExplore'); }}
                                title = {this.props.t('home:pages.explorePages')} 
                            />
                        }
                        { this.state.content_info_to_show === "players" &&
                            <HomePlayersFilterComponent 
                                handleSearchNationality={ ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                searchProfileWorldCities = { ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                nationalities = { this.props.state.profile.nationalities_search_results }                    
                                cities = { this.props.state.profile.world_cities_search_result }
                                lateralityOptions = {this.props.state.home.home_player_attributes.laterality}
                                primaryPositionOptions = {this.props.state.home.home_player_attributes.primary_position}
                                secondaryPositionOptions = {this.props.state.home.home_player_attributes.secondary_position}
                                handleOnPlayersFilter = { ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                playersearch={this.state.playersearch}
                            />
                        }    
                    </div>
                    { this.props.state.home.is_mobile_menu_on && 
                        <div className='home-anon__mobile__left-container' >
                            <div className='home-anon__mobile__left-container__header'>
                                <img src={baloa_green_logo} alt='baloa logo' />
                                <span className='icon-x' onClick={() => {this.props.changeMobileMenuStateAction(false)}} />                            
                            </div>
                            {/* Shortcuts */}
                            <HomeShortCutsComponent
                                handleGoLink={(value)=>{this.props.changeMobileMenuStateAction(false); this.handleClickMenuSugestions(value);}}
                                handleCreateTeam = {()=>{this.props.changeMobileMenuStateAction(false); this.handleHomeAnonModal(true, 'homeAnonCreateTeam');}}
                                handleJoinToTeam={()=>{this.props.changeMobileMenuStateAction(false); this.handleHomeAnonModal(true, 'homeAnonJoinTeam');}}
                                handleAnonShortCuts={ () => {this.props.changeMobileMenuStateAction(false); this.handleHomeAnonModal(true, 'homeAnonTournament');} }
                                is_home_anon_shortcuts={true}
                            />                            
                        </div>
                    }
                    <div className={"home-anon__center-container " + (this.state.content_info_to_show !== "post"? 'gphome':'')}>

                        { this.state.content_info_to_show === "post" &&
                            <HomeFeedComponent 

                                posts = { this.props.state.home.posts }
                                profile_suggestions = { this.props.state.home.profile_suggestions }
                                me_photo = { this.props.state.user.me.photo }

                                handleOnLoadMorePosts = { () => this.props.loadHomePosts( this.props.state.home.loading_posts, this.props.state.home.posts_next_page ) }

                                handleOnProfileClick = { () => this.handleHomeAnonModal( true, '' ) }
                                handleOnPageClick = { () => this.handleHomeAnonModal( true, '' ) }

                                handleClap = { () => this.handleHomeAnonModal( true, '' ) }
                                onCloseClapsModal={ () => {} }
                                loadPostClapsById={ () => {} }

                                handleOnMessageIconClick = { () => this.handleHomeAnonModal( true, 'homeAnonComment' ) }
                                handleOnCommentsClick = { () => this.handleHomeAnonModal( true, '' ) }
                                handleOnComment = { () => this.handleHomeAnonModal( true, 'homeAnonComment' ) }

                                handleReportMenuClick = { () => this.handleHomeAnonModal( true, '' ) }
                                openReportPostModal = { () => {} }
                                handleReportPost = { () => {} }

                                openBlockPostModal = { () => this.handleHomeAnonModal( true, '' ) }
                                handleBlockProfile={ () => {} }
                                handleBlockPage={ () => {} }

                                handleOnDeletePostClick = { () => this.handleHomeAnonModal( true, '' ) }

                                handleOnCopyLinkClick = { () => this.handleHomeAnonModal( true, '' ) }

                                share_menu_is_open = {false} // TODO: To be removed
                                handleShareMenuClick = { () => {} } // TODO: To be removed
                                report_menu_is_open = {false} // TODO: To be removed

                                handleOnAuthorClick = { () => this.handleHomeAnonModal( true, 'homeAnonAuthor' ) }
                                
                                menu_is_open = {true}
                                sendReaction= { () => this.handleHomeAnonModal( true, 'homeAnonReaction' ) }
                                overrideReaction= { () => this.handleHomeAnonModal( true, '' ) }
                                handleReactionsDetails = { () => this.handleHomeAnonModal(true, '') }
                                loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                                deleteReaction = { () => this.handleHomeAnonModal(true, '') }
                                followPostProfile= { () => this.handleHomeAnonModal(true, 'homeAnonFollowProfile') }                            
                                unfollowPostProfile= { () => this.handleHomeAnonModal(true, '') }                            
                                followPostPage= { () => this.handleHomeAnonModal(true, '') }                            
                                unfollowPostPage= { () => this.handleHomeAnonModal(true, '') }

                                handleOnClickQuizAnswer={ () => this.handleHomeAnonModal(true, '') }
                                handleOnClickPollAnswer={ () => this.handleHomeAnonModal(true, '') }

                                handleOnQuoteIconClick={ () => this.handleHomeAnonModal(true, 'homeAnonQuote') }
                            />
                        }
                        { this.state.content_info_to_show === "groups" &&

                        <div>
                            <PagesSuggestionsComponent
                                pages = { this.props.state.pageGroups.group_suggestions }
                                handleOnPageClick = { () => {this.handleHomeAnonModal(true, 'groups')} }
                                handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('home:pagesGroupsSuggestions.groups')} 
                                subTitleCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.groupsAddButton')}                                 
                                addButtonCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.addGroupButtonCaption')} 
                                cardsCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.groupCardCaption')}
                                onClickAddButton={() => {this.handleHomeAnonModal(true, 'groups')}}   
                                pages_type = {this.props.t('home:pagesGroupsSuggestions.group')} 
                                page_type = { "groups" }  
                                memberGroupPages = { [] }  
                                handleFollow = { () => {this.handleHomeAnonModal(true, 'groups')} }
                                addClassName = {"eight-width"}
                            >
                            </PagesSuggestionsComponent>
                        </div>

                        }

                        { (this.state.content_info_to_show === "pages" || this.state.content_info_to_show === "explore") &&

                        <div>                       
                            {/* <PagesSuggestionsComponent
                                pages = { this.props.state.home.page_suggestions }
                                handleOnPageClick = { () => {this.handleHomeAnonModal(true, 'pages')} }
                                handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('home:pagesGroupsSuggestions.pages')} 
                                subTitleCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.pageAddButton')} 
                                cardsCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.pageCardCaption')} 
                                addButtonCaption = {this.props.t('home:pagesGroupsSuggestions.sugestions.addPageButtonCaption')} 
                                onClickAddButton={ () => {this.handleHomeAnonModal(true, 'pages')} }
                                pages_type = {this.props.t('home:pagesGroupsSuggestions.page')} 
                                page_type = { "pages" }
                                myPages = { [] }
                                handleFollow = { () => {this.handleHomeAnonModal(true, 'pages')} }
                                addClassName = {"eight-width"}
                            >
                            </PagesSuggestionsComponent> */}
                            <HomePagesComponent
                                handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                                //handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('home:pages.explorePages')} 
                                //subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageAddButton')} 
                                //cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageCardCaption')} 
                                emptyPagesCaption = {this.props.t('home:pages.emptyExploreCaption')}
                                //addButtonCaption = {this.props.t('pagesGroupsSuggestions.sugestions.addPageButtonCaption')} 
                                //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}
                                //onClickAddButton={()=>{ this.props.changePageNewPageInformationModalStateAction( true ) }}
                                page_type = { "explore" }
                                myPages = { this.props.state.home?.explore_pages? this.props.state.home?.explore_pages : this.props.state.home?.page_suggestions?.slice(0,9) }
                                handleFollow = { ()=>{ this.handleHomeAnonModal(true, 'pages'); } }
                                handleUnFollow = { ()=>{ this.handleHomeAnonModal(true, 'pages'); } }
                                followCaption = { this.props.t('page:follower.request.button.follow') }
                                followingCaption = { this.props.t('page:follower.request.button.following') }
                                addClassName = {"explore"}
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                fetchMoreData = { this.props.state.home?.explore_pages_next_page? ()=>{ this.props.explorePagesFilter(null, null, null, this.props.state.home?.explore_pages_next_page) } : ()=>{console.log('llamo vacio')} }
                                pages_next_page = { this.props.state.home?.explore_pages_next_page }
                            >
                            </HomePagesComponent>
                        </div>

                        }
                        {/* Players seccion */}
                        {this.state.content_info_to_show === "players" &&
                            <div>                            
                                <HomePlayersComponent
                                    home_players={this.props.state.home?.home_players}
                                    emptyPlayers={this.props.t('players.filter.emptyPlayers')}
                                    playerVideo={this.props.t('players.filter.playerVideo')}

                                    handleOnProfileClick={ () => this.handleHomeAnonModal( true, '' ) }
                                    handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                    fetchMoreData = { ()=>{ this.props.loadHomePlayers( null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.state.home?.home_players_next_page ) } }
                                    home_players_next_page = { this.props.state.home?.home_players_next_page }

                                    handleSearchNationality={ ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                    searchProfileWorldCities = { ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                    nationalities = { this.props.state.profile.nationalities_search_results }                    
                                    cities = { this.props.state.profile.world_cities_search_result }
                                    lateralityOptions = {this.props.state.home.home_player_attributes.laterality}
                                    primaryPositionOptions = {this.props.state.home.home_player_attributes.primary_position}
                                    secondaryPositionOptions = {this.props.state.home.home_player_attributes.secondary_position}
                                    handleOnPlayersFilter = { ()=>{ this.handleHomeAnonModal(true, 'homeAnonPlayer'); } }
                                />
                            </div>
                        }
                    </div>
                    {this.state.content_info_to_show === "post" &&
                        <div className="home__right-container">
                            <PageSuggestionsComponent
                                title={this.props.t('homeAnonSuggestionsTitle')}
                                pages={ this.props.state.home.page_suggestions?.slice(0, 4) }
                                className = "home-anon__pages-suggestions sm"
                                handleFollow = {() => { this.handleHomeAnonModal(true, 'homeAnonFollowProfile') } }
                                go_link={this.props.t('homeAnonSuggestionsLink')}
                                handleGoLink={()=>this.handleHomeAnonModal(true, '')}
                                is_home_anon_suggestions={true}
                                handleAnonSuggestions={() => this.handleHomeAnonModal( true, 'homeAnonAuthor' )}
                            />                    
                        </div>
                    }
                </div>
            </React.Fragment>
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadHomePosts: ( loading = false, next_page) => {
            return dispatch( loadHomePosts( loading, next_page ) )
        },
        loadUnauthenticatedHomePageSuggestions: () => {
            return dispatch( loadUnauthenticatedHomePageSuggestions() )
        },
        loadUnauthenticatedGroupsSuggestions: () => {
            return dispatch( loadUnauthenticatedGroupsSuggestions())
        },
        changeSignUpLoginModal: ( new_state ) => {
            return dispatch( changeSignUpLoginModal( new_state ) )
        },
        changeClickType: ( click_type ) => {
            return dispatch( changeClickType( click_type ) );
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        loadHomePlayers: ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange,  next_page ) => {
            dispatch ( loadHomePlayers( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) )
        },
        loadPlayerAttributes: () => {
            dispatch ( loadPlayerAttributes() )
        },
    }
}

// Exports
//export default connect(mapStateToProps, mapDispatchToProps)(HomeAnonView);

export default compose(
    withTranslation(['login','home','page']), 
    connect(mapStateToProps, mapDispatchToProps)
)(HomeAnonView);
