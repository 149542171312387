// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-new-team__header {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
   border-bottom: 1px solid  var(--new-input-background-color);
}

.league-new-team__header_icon-close {
	padding-top: var(--space-half);
}

.league-new-team__icon-close {
	color: var(--primary-dark-color);
	font-size: var(--space-x2);
	padding: var(--space-half);
	cursor: pointer;
	align-self: right;
	margin-left: auto;
   margin-right: var(--space-half);
}


`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-new-team-component/LeagueNewTeamComponent.styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,mBAAmB;GACjB,2DAA2D;AAC9D;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,gCAAgC;CAChC,0BAA0B;CAC1B,0BAA0B;CAC1B,eAAe;CACf,iBAAiB;CACjB,iBAAiB;GACf,+BAA+B;AAClC","sourcesContent":[".league-new-team__header {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-direction: row;\n   border-bottom: 1px solid  var(--new-input-background-color);\n}\n\n.league-new-team__header_icon-close {\n\tpadding-top: var(--space-half);\n}\n\n.league-new-team__icon-close {\n\tcolor: var(--primary-dark-color);\n\tfont-size: var(--space-x2);\n\tpadding: var(--space-half);\n\tcursor: pointer;\n\talign-self: right;\n\tmargin-left: auto;\n   margin-right: var(--space-half);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
