// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './TournamentFieldsWarningModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentFieldsWarningModalComponent( props ){

    return(
        <ModalComponent
            isOpen={props.isOpen} 
            onClose={props.onClose}            
            className="tournament-fields-warning-modal__container"
        >
            <div className="tournament-fields-warning-modal__description" >
                <div className='baloa-hyperlink' >{props.mainLabel}</div>
                <div className='baloa-table' >{props.secondaryLabel}</div>
            </div>

            <div className="tournament-fields-warning-modal__actions">
                <SecondaryButtonComponent onClick={ () => props.onClose() } >{ props.t('options.pageFields.labelWarningCancelBtn') }</SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    type='button'
                    onClick={ () => props.handleDeleteField() }
                >{ props.t('options.pageFields.labelWarningDeleteBtn') }</PrimaryButtonComponent>
            </div>            
        </ModalComponent>
    )
}

export default withTranslation('league')(TournamentFieldsWarningModalComponent);