// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-form-payment-team-player-modal__container .modal2__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-half-quarter) var(--space-and-half) var(--space-and-half) var(--space-and-half);
}

.verify-form-payment-team-player-modal__container .modal2__container {
    width: 30%;
}

.verify-form-payment-team-player-modal__form-btn {
    margin-bottom: var(--space-half-quarter);
}

.verify-form-payment-team-player-modal__form-btn,
.verify-form-payment-team-player-modal__payment_btn {
    width: 100%;
    font-size: var(--space);
    padding: var(--space-half);
    grid-template-columns: none;
    grid-template-areas: none;
}

.verify-form-payment-team-player-modal__message {
    margin: var(--space-x2) 0; 
    text-align: center;    
}

.verify-form-payment-team-player-modal__container .hubicon-info {
    font-size: calc( var(--space-x2) + var(--space-quarter) );
    color: var(--primary-dark-color);
}
.verify-form-payment-team-player-modal__container .modal2__header {
    border-bottom: none;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
    .verify-form-payment-team-player-modal__container .modal2__container {
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/verify-form-payment-team-player-modal/VerifyFormPaymentTeamPlayerModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oGAAoG;AACxG;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;;IAEI,WAAW;IACX,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yDAAyD;IACzD,gCAAgC;AACpC;AACA;IACI,mBAAmB;AACvB;;AAEA,yCAAyC,4BAA4B;IACjE;QACI,UAAU;IACd;AACJ","sourcesContent":[".verify-form-payment-team-player-modal__container .modal2__content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: var(--space-half-quarter) var(--space-and-half) var(--space-and-half) var(--space-and-half);\n}\n\n.verify-form-payment-team-player-modal__container .modal2__container {\n    width: 30%;\n}\n\n.verify-form-payment-team-player-modal__form-btn {\n    margin-bottom: var(--space-half-quarter);\n}\n\n.verify-form-payment-team-player-modal__form-btn,\n.verify-form-payment-team-player-modal__payment_btn {\n    width: 100%;\n    font-size: var(--space);\n    padding: var(--space-half);\n    grid-template-columns: none;\n    grid-template-areas: none;\n}\n\n.verify-form-payment-team-player-modal__message {\n    margin: var(--space-x2) 0; \n    text-align: center;    \n}\n\n.verify-form-payment-team-player-modal__container .hubicon-info {\n    font-size: calc( var(--space-x2) + var(--space-quarter) );\n    color: var(--primary-dark-color);\n}\n.verify-form-payment-team-player-modal__container .modal2__header {\n    border-bottom: none;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n    .verify-form-payment-team-player-modal__container .modal2__container {\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
