// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentFinalizeTournamentSuggestionModalComponent.styles.css';


function TournamentFinalizeTournamentSuggestionModalComponent(props) {
    return(
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            className='finalize_tournament_suggestion_container'
        >
            <h5 className='baloa-headline-5 finalize_tournament_suggestion_title'>{props.t("options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalFinalizeTournamentSuggestion.labelTitle")}</h5>
            <p className='baloa-body-1'>{props.t("options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalFinalizeTournamentSuggestion.labelTextPart1")} <span className='baloa-body-bold'>{props.t("options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalFinalizeTournamentSuggestion.labelTextPart2")}</span> {props.t("options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalFinalizeTournamentSuggestion.labelTextPart3")}</p>

            <SecondaryButtonComponent onClick={ props.onClose } className='finalize_tournament_suggestion_btn'>
                <span>{props.t("options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalFinalizeTournamentSuggestion.labelBtn")}</span>
            </SecondaryButtonComponent>
        </ModalComponent>

    )
}

export default withTranslation('league')(TournamentFinalizeTournamentSuggestionModalComponent);