// React
import React from 'react';

// Formik
import { Field } from 'formik';

// Styles
import './SumRestInputComponent.styles.css';


class SumRestInputComponent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            inputValue: 0,
        };
    };

    componentDidMount(){
        if(this.props.defaultValue){
            this.setState({inputValue: this.props.defaultValue });
        }
    }

    sumValue( form ){
        let newValue = this.state.inputValue + 1;
        this.setState({inputValue: newValue });
        form.setFieldValue( this.props.field_name, newValue )
    }

    restValue( form ){
        if( this.state.inputValue !== 0) {
            let newValue = this.state.inputValue - 1;
            this.setState({inputValue: newValue });
            form.setFieldValue( this.props.field_name, newValue );
        }
    }

    render() {

        const field_name = this.props.field_name;

        return (
            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    /* if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    } */
                    return (
                        <div className= { "sum-rest-input " + ( this.props.className ? this.props.className : '' )  }>
                            { this.props.label && <label className="sum-rest-input__label" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <div className='sum-rest-input__container'>
                                <div className='sum-rest-input__button' onClick={()=>{this.restValue(data.form)}}>
                                    <span>{'-'}</span>
                                </div>
                                <input
                                    readOnly
                                    id={ this.props.input_id }
                                    disabled={ this.props.disabled }
                                    onClick={ this.props.onClick }
                                    onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined }
                                    className={
                                        'sum-rest-input__input ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                    }
                                    type = { this.props.input_type }
                                    { ...data.field }
                                    //onKeyDown={ () => { this.props.resetError() } }
                                    placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                    maxLength={ this.props.maxLength ? this.props.maxLength : undefined }
                                    max={this.props.max ? this.props.max : undefined }
                                    min={ this.props.min ? this.props.min : undefined }
                                    //value= { this.props.value }
                                    defaultValue= { this.props.defaultValue }
                                    autocomplete='off'
                                    onFocus={ this.props.onFocus }
                                />
                                <div className='sum-rest-input__button' onClick={()=>{this.sumValue(data.form)}}>
                                    <span className='hubicon-add_option' />
                                </div>                                
                            </div>
                            <p className="input-field__tip-message">{ this.props.message }</p>
                            <p className="input-field__message error">{ data.form.errors[ field_name ] }</p>
                        </div>
                    )

                } }
            </Field>

        );
    }
}

export default SumRestInputComponent;
