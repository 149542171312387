// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './LeagueMatchRefereesCrewConfirmationPaymentModal.styles.css';

//i18
import { withTranslation } from 'react-i18next'; 



function LeagueMatchRefereesCrewConfirmationPaymentModal( props ) {

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            title=''
            className="league-match-referees-crew-confirmation-payment"
        >
            <div className='league-match-referees-crew-confirmation-payment__container'>
                <div className='baloa-normal-medium'>{props.t('options.tournaments.pages.matchRefereesPayment.confirmRefereeingCrewTransferText')}</div>   
                <div className='league-match-referees-crew-confirmation-payment__action-btns'>
                    <SecondaryButtonComponent className="small" onClick={() => {props.onClose()}}>{props.t('options.tournaments.pages.matchRefereesPayment.cancelBtn')}</SecondaryButtonComponent>
                    <PrimaryButtonComponent className="small" onClick={() => {props.handleOnAcceptCrewPayment()}}>{props.t('options.tournaments.pages.matchRefereesPayment.confirmRefereeingCrewTransferOkBtn')}</PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('league')(LeagueMatchRefereesCrewConfirmationPaymentModal);
