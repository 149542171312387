// React
import React from 'react';
import { Switch } from 'react-router-dom';

// Routes
import RouteWithSubRoutes from 'config/routes/RouteWithSubRoutes';

// Components
//import SignUpHeaderComponent from 'shared/components/signup-header/SignUpHeaderComponent';


export default function SignUpLayout({routes, location}) {
  
  return (
    <React.Fragment>
      {/* Se comenta còdigo, nueva ui registro y registro abreviado */}
      {/* <SignUpHeaderComponent 
        location={location}
      /> */}
      <div>
        <Switch>
          {routes.map(route => (
            <RouteWithSubRoutes {...route} key={route.key} />
          ))}
        </Switch>
      </div>
    </React.Fragment>
  );
}
