// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
//import 'shared/components/follower/FollowerComponent.styles.css';
import './ClapsModalComponent.styles.css';

// Assets
import baloa_user_icon from 'assets/icons/user-white-icon.svg';

//i18
import { withTranslation } from 'react-i18next';

function ClapsModalComponent ( props ) {

    function handleScroll(e) {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if(props.claps_next_page !== null){
                props.loadPostClapsNext( props.claps_next_page );
            }    
        }
    }

    const handleButton=(clap)=>{
        if(props.page !== undefined){
            if(props.page?.pagename === clap.user?.username ){
                return false;
            }
            else{
                return true;
            }
        }else if(props.user_me?.username === clap.user?.username){
            return false;
        }else{
            return true;
        }
    }

    return (

        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('clapsModal.title')}
        >
            <div className="scroll-parent" onScroll={handleScroll}>
                <div className="claps scroll">            
                    { props.claps.map( clap => {
                        return (
                        <div key={ clap.user.username } className="claps__content">
                            <div className=" claps__content__box">
                                <div
                                className={ "claps__content__box__photo" }
                                // onClick={ () => onClick_User()}
                                >
                                    <img src={ clap.user.photo? clap.user.photo : baloa_user_icon } alt=""/>
                                </div>
                                <div
                                className="claps__content__box__info-username"
                                // onClick={ () => onClick_User()}
                                >
                                    <p className="baloa-subtitle-1"> 
                                        @{clap.user.username}
                                    </p>
                                </div>
                                <div className="claps__content__box__button">
                                    
                                    {handleButton(clap) && 
                                        (clap.is_following ? 
                                            <SecondaryButtonComponent 
                                                type="button"
                                                disabled={ false } 
                                                onClick = {() => { 
                                                    props.unfollowProfile( {'user':{'username':clap.user.username}} );
                                                    props.changePostIsFollowing(clap.user.username);
                                                } }
                                            >
                                                <span>{props.t('clapsModal.buttonFollowing')}</span>
                                            </SecondaryButtonComponent>
                                            :   <PrimaryButtonComponent 
                                                    type="button"
                                                    disabled={ false } 
                                                    onClick = {() => { 
                                                        props.followProfile( {'user':{'username':clap.user.username}} );
                                                        props.changePostIsFollowing(clap.user.username);
                                                    } }
                                                >
                                                    <span>{props.t('clapsModal.buttonFollow')}</span>
                                                </PrimaryButtonComponent>
                                        )
                                    } 
                                </div>   
                            </div>
                        </div>
                        )
                    }) }
                </div> 
                <div className="claps__bottom"></div>
            </div>
                   
        </Modal2Component>
    )
}

export default withTranslation('post')(ClapsModalComponent);

//export default ClapsModalComponent;