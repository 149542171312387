//React
import React, { useState } from 'react';


//Styles
import './LeagueEventsComponent.styles.css';

//Components
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';

//i18
import { withTranslation } from 'react-i18next';

function LeagueEventsComponent(props) {

    const [activeAll, setActiveAll] = useState(true);
    const [activeScheduled, setActiveScheduled] = useState(false);
    const [activeLive, setActiveLive] = useState(false); // eslint-disable-line

    function handleOnClick(btn_event) {
        if (btn_event === 'all') {
            setActiveAll(true);
            setActiveScheduled(false);
            setActiveLive(false);

        } else if (btn_event === 'scheduled') {
            setActiveScheduled(true);
            setActiveAll(false);
            setActiveLive(false);
        } else {
            setActiveLive(true);
            setActiveScheduled(false);
            setActiveAll(false);
        }
    }

    return (
        /* eslint-disable */
        <React.Fragment>
            <div className="post__content__tournament">
                {props.page_events?.length !== 0 &&
                    <div className='post__content__tournament-result__events__buttons'>
                        <div className={'events-btns' + ' ' + (activeAll ? 'active' : '')} onClick={() => handleOnClick('all')}>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.labelAll')}</div>
                        <div className={'events-btns' + ' ' + (activeScheduled ? 'active' : '')} onClick={() => handleOnClick('scheduled')}>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.labelScheduled')}</div>
                        {/* <div className={'events-btns' +' '+ (activeLive ? 'active' : '')} onClick={handleOnClick('live')}>EN VIVO</div> */}
                    </div>
                }
                {props.page_events?.length === 0 &&

                    <div class="page-feed ">

                        <p className="page-feed__suggestions-title baloa-headline-4">
                            {props.t('tabs.post.text')}
                        </p>
                        <p className="page-feed__suggestions-title-sm baloa-subtitle-1">
                            {props.t('tabs.post.text')}
                        </p>

                    </div>

                }

                {activeAll &&
                    props.page_events?.map((event, index) => (

                        <div>
                            {event.author !== null && event.author?.page_type === 'league' && event.post_match !== null &&
                                <LeagueTournamentInfoComponent
                                    key={index}
                                    tournament_info={event.post_match}
                                    className={"post__content__tournament-result__container__tournament"}
                                    display={'post'}
                                />
                            }
                        </div>
                    ))
                }

                {activeScheduled &&
                    props.page_events?.map((event, index) => (

                        <div>
                            {event.author !== null && event.author?.page_type === 'league' && event.post_match !== null && event.post_match.state === 'Scheduled' &&
                                <LeagueTournamentInfoComponent
                                    key={index}
                                    tournament_info={event.post_match}
                                    className={"post__content__tournament-result__container__tournament"}
                                    display={'post'}
                                />
                            }
                        </div>
                    ))
                }
            </div>


        </React.Fragment>
        /* eslint-enable */

    )

}

export default withTranslation('league')(LeagueEventsComponent);




