// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './AcceptReportModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function AcceptReportModalComponent ( props ){

    return(
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <p className="modal-title">
                {props.t('postReport.title')}
            </p>
            <p className="modal-text">
                {props.t('postReport.text')}
            </p>
            <div className="login-modal-actions">
                 
                <PrimaryButtonComponent onClick={ props.onClose }>
                    <span>{props.t('postReport.button')}</span>
                </PrimaryButtonComponent>
                
            </div>
        </ModalComponent>
    )

}

export default withTranslation('post')(AcceptReportModalComponent);

//export default AcceptReportModalComponent;