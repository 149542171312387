// React
import React from 'react';

// Components
import InputFieldAutocompletePaymentCountries from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompletePaymentCountries';
import SelectFieldComponent from 'shared/components/select-field/SelectFieldComponent';

// Validators
import { RequiredValidator } from 'shared/validators/RequiredValidator';

// i18next
import { withTranslation } from 'react-i18next';

// Styles
import './InputFieldSearchLocationComponent.styles.css';

function InputFieldSearchLocationComponent(props) { 

    React.useEffect(() => {
        if(props.countries && props.countries?.length === 1) {
            props.handleSearchCountryStates(props.countries[0].name)
        }
    }, [props.countries]); // eslint-disable-line

    return (
        <div className='input-field-search-location__container'>
            
            <InputFieldAutocompletePaymentCountries
                options={ props.countries? props.countries: null }
                noOptionsText={props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.noOpt')}
                optionLabelField={ "name" }
                optionSelectedField={ "id" }
                lettercount={ "2" }
                disabled={props.disabled}
                handleSearchWord ={ ( newCountryValue ) => {props.handleSearchNationality( newCountryValue )} }
                className="input-field-search-location__input"
                label={props.label_country}
                placeholder=""
                id={`${props.id}_country`}
                field_name={`${props.id}_country`}                                
                validationFunction={props.is_required ? RequiredValidator : false}
                resetError={ () => {}}
                error={ props.errors }
                value={props.countries? props.countries[0]: null}
            />

            <SelectFieldComponent
                label={props.label_state}
                field_name={props.field_name}
                input_id={props.id}
                validationFunction={props.is_required ? RequiredValidator : false}
                disabled={false}
                className="input-field-search-location__select-field"
                type={props.type}
                resetError={() => {}}
                error={props.errors}
                message=""
                options={props.country_states}
                defaultValue={props.defaultValue}
            />
        </div>
    );
};

export default withTranslation('league')(InputFieldSearchLocationComponent);