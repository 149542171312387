// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import LeagueCountrySearchIconComponent from '../../../leagues/components/league-member-search-icon/LeagueCountrySearchIconComponent';

// Styles
import './SignUpPhonePanelComponent.styles.css';

// Actions
import { ChangeErrorLogin } from 'app/authentication/actions/ErrosLogin';
import { sendPhoneAction, updatePreRegisterEmailPhone } from 'app/authentication/actions/LoginActions';
import { changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Validators
import { RequiredValidator, PhoneNumberRequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';
import {recaptcha_public_key} from "config/GlobalData";

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';
import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import {isMobile} from 'react-device-detect';

//Functions
import { generateBaloaToken } from 'shared/functions/GenerateBaloaToken';
class SignUpPhonePanelComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            disabled: false,
            captchaToken: undefined,
        };
    };
    
    recaptchaRef = React.createRef();
    formRef = React.createRef();

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    formInitialValues = {
        phone: "",
        country_code: this.props.state.constantsSingup.countries?.filter((country) => country.value === '1')[0]
    }    
    
    async submitUnregisterMemberEmail( phone ){
        await this.props.updatePreRegisterEmailPhone(phone, this.props.state.signup.claim_account_profile.id, 'phone' );
    }

    handleSubmit(event){
        event.preventDefault();
        if(this.state.captchaToken){
            this.formRef.current.submitForm();
        }
    }

    onChange(token){
        if(token){
            this.setState({captchaToken: token })
        }        
    }

    render = () => {         
        return (
            <React.Fragment>
                <div className="signup-phone-panel__container">
                    <div className="signup-phone-panel__container__left-col">
                        <Link to="/">
                            { !isMobile 
                                ?
                                <img className="signup-header__image" src={baloa_logo} alt=""/>
                                :
                                <img className="signup-header__image" src={baloa_green_logo} alt=""/>
                            }
                        </Link>
                    </div>
                    <div className="signup-phone-panel__container__right-col">
                        <div className='signup-phone-panel__container__right-col__title'>
                            <span className='icon-arrow-left baloa-headline-1' onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] )}/>
                            <div className='baloa-headline-1 ' >{this.props.t('phone.phoneTitle')}</div>
                        </div>
                        <div className="signup-phone-panel__text baloa-subtitle-2">{this.props.t('phone.text')}</div>
                        <FormComponent
                            formInitialValues = { this.formInitialValues }
                            onSubmit = { async ( values, actions ) => {
                                let phone_values = { phone: values['country_code']?.value+values['phone'], channel: "Web", "captcha-token": this.state.captchaToken };
                                let token = await generateBaloaToken(phone_values.phone);
                                if(this.props.state.signup.document_verification){
                                    this.submitUnregisterMemberEmail(phone_values);
                                }else{
                                    this.props.sendPhoneAction(phone_values, token);
                                }
                                
                            } }
                            className = " signup-phone-panel-form"
                            functionDisabled = { this.disabled.bind( this ) }
                            innerRef={this.formRef}
                        >
                            <label className="signup-phone-panel-form__label">{this.props.t('phone.phoneNumber')}</label>
                            {/* <div className="signup-phone-panel-form__fields">
                                <SelectFieldComponent 
                                    field_name="country_code"
                                    validationFunction={ RequiredValidator }
                                    disabled={false}
                                    className=""
                                    resetError={ () => {} }
                                    error={ { phone: "", country_code: "" } }
                                    message=""
                                    placeholder=""
                                    options= {this.props.state.constantsSingup.countries}
                                />
                                <div className="signup-phone-panel__vertical-separator__container">
                                    <div className="signup-phone-panel__vertical-separator" />
                                </div>
                                <InputFieldComponent
                                    input_id="signup-phone"
                                    field_name="phone"
                                    validationFunction={PhoneValidator}
                                    disabled={false}
                                    className=""
                                    type="text"
                                    resetError={() => {}}
                                    error={this.props.state.signup_error.error}
                                    message=""
                                />
                            </div> */}
                            <div className='signup-phone-panel-form__fields'>
                                <LeagueCountrySearchIconComponent
                                    id = {'country_code'}
                                    fieldName={'country_code'}
                                    options= {this.props.state.constantsSingup.countries?.filter((country) => country.content !== 'Código país') || this.props.state.constantsSingup.countries.filter((country) => country.content !== 'Country code')}
                                    modalSearchPlaceholder={'Buscar paises'}
                                    placeholderIndicative={this.props.state.constantsSingup.countries?.filter((country) => country.content === 'Código país')[0] || this.props.state.constantsSingup.countries?.filter((country) => country.content === 'Country code')[0] }
                                    validationFunction={ RequiredValidator }
                                    error={ { phone: "", country_code: "" } }
                                    resetError={() => { }}
                                    defaultValue={this.props.state.constantsSingup.countries?.filter((country) => country.value === '1')[0]}
                                />
                                <div className="signup-phone-panel__vertical-separator__container">
                                    <div className="signup-phone-panel__vertical-separator" />
                                </div>
                                <InputFieldComponent
                                    input_id="signup-phone"
                                    field_name="phone"
                                    validationFunction={PhoneNumberRequiredValidator}
                                    disabled={false}
                                    className=""
                                    type="text"
                                    resetError={() => {}}
                                    error={this.props.state.signup_error.error}
                                    message=""
                                />
                                
                            </div>
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                className='test_class'
                                hl="en"
                                size="normal"
                                sitekey={recaptcha_public_key}
                                onChange={(value)=>{this.onChange(value)}}
                            />
                            <div className="signup-phone-panel__form__submit__container">
                                {/* <PrimaryButtonComponent disabled={!this.state.disabled} onClick={() => { }} className="signup-phone-panel__form__submit"> */}
                                <PrimaryButtonComponent 
                                    disabled={ !this.state.captchaToken } 
                                    onClick={(e) => { this.handleSubmit(e) }} 
                                    className="signup-phone-panel__form__submit"
                                    type="button"
                                >
                                    <span>{this.props.t('phone.sendPhoneCode')}</span>
                                </PrimaryButtonComponent>
                            </div>
                            {/* <label className="signup-phone-panel__change-signin-method" onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] )}>{this.props.t('loginEmail.changeLoginMethod')}</label> */}
                        </FormComponent>
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendPhoneAction: (values, token) => {
            return dispatch(sendPhoneAction(values, token))
        },
        ChangeErrorLogin: (values) => {
            return dispatch(ChangeErrorLogin(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        updatePreRegisterEmailPhone: async( new_data, user_id, method ) => {
            await dispatch( updatePreRegisterEmailPhone( new_data, user_id, method ) )
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpPhonePanelComponent);
