export const changeHomeGroupsSuggestionsLoadingAction = ( data ) => {
    return { type: "HOME:CHANGE_LOADING_GROUP_SUGGESTIONS", data }
}

export const loadHomeGroupsSuggestionsAction = ( data ) => {
    return { type: "HOME:LOAD_GROUP_SUGGESTIONS", data }
}

export const changeErrorMessageSnackBar = ( new_state ) => {
    return { type: "HOME:CHANGE_ERROR_MESSAGE_SNACKBAR", new_state }
}

export const changeNewGroupPage = ( new_state ) => {
    return { type: "HOME:CHANGE_NEW_GROUP_PAGE", new_state }
}

export const changeCancelMemberRequestDialogModalStateAction = ( new_state ) => {
    return { type: "PAGEGROUP:CHANGE_CANCEL_MEMBER_REQUEST_DIALOG_MODAL_STATE", new_state }
}

export const checkMemberRequestAction = ( data ) => {
    return { type: "PAGEGROUP:CHECK_MEMBER_REQUEST", data }
}

export const setMemberGroupPagesAction = ( data ) => {
    return { type: "PAGEGROUP:SET_MEMBER_GROUP_PAGES", data }
}

export const changeNewGroupPagePost = ( new_state ) => {
    return { type: "PAGEGROUP:CHANGE_NEW_GROUP_PAGE_POST", new_state }
}