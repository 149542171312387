// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './ButtonComponent.styles.css';


function ButtonComponent( props ) {
    return (
        <button 
        id={props.id}
        type={props.type}
        disabled={props.disabled}
        onClick={props.onClick} 
        className={ 'button ' + ( props.className ? props.className : '' ) }
        >
            {props.children}
        </button>
    )
}

// Types
ButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
}
export default ButtonComponent;
