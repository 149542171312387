// React
import React from 'react';

// Styles
import './ShowPostQuizComponent.styles.css';

function ShowPostQuizComponent(props) {

    return( 

        <div className='show-post-quiz__container'>
            {props.quiz_answers.map( item =>(
                    <div key={item.id} 
                        onClick={ !props.is_quiz_answer?  ()=>{props.handleOnClickQuizAnswer(props.post_id, item.id)} : undefined  } 
                        className={"show-post-quiz__answer baloa-names " + (props.is_quiz_answer && (item.user_answer || item.is_correct)? (item.is_correct? "correct": "wrong") :'' )}
                    >
                        {item.caption}
                        {props.is_quiz_answer && (item.user_answer|| item.is_correct)?
                            item.is_correct?
                                <span className={"show-post-quiz__answer-icon icon-check-single-72"}></span>
                            :
                                <span className={"show-post-quiz__answer-icon icon-x"}></span>
                        :
                        ''    
                        }                                                
                    </div>
            )
            )}
            
            <div className={"show-post-quiz__counter"}>{props.quiz_answers_count +" respuestas"}</div>
        </div>
    )


}

export default ShowPostQuizComponent;