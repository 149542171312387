// React
import React, { useState } from 'react';

// Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
//import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Material ui
//import Tooltip from '@mui/material/Tooltip';
//import { withStyles } from '@mui/styles';
// Assets
import analysis_1_image from 'assets/icons/tournament-groups-type-new-green.svg';
import analysis_3_image from 'assets/icons/tournament-knockout-type-new-green.svg';
import analysis_4_image from 'assets/icons/tournament-league-type-new-green.svg';

// i18
import { useTranslation } from 'react-i18next';


// Styles
import './TournamentPhaseCreatedComponent.styles.css';


function TournamentPhaseCreatedComponent(props) {

    const { t } = useTranslation('league');
    const [optionMenu, setOptionMenu] = useState(false);
    //const [openTooltip, setOpenTooltip] = React.useState(false);
    const [closeMsg, setCloseMsg] = React.useState(false);

    const [boxPosition, setBoxPosition] = useState(false);
    const [isBoxOpen, setBoxOpen] = useState(false);

    /* const BaloaToolTip = withStyles((theme) => ({

        tooltip: {
            backgroundColor: 'var(--primary-dark-color)',
            color: 'var(--white-color)',
            fontSize: 'var(--space-half-quarter)',
            borderRadius: 'var(--space-and-half)',
            fontFamily: 'var(--secondary-font)',
            fontWeight: 'var(--font-bold)',
            padding: 'var(--space)',
        },
    }))(Tooltip); */
    /* eslint-disable */
    React.useEffect(() => {
        if (props.showAddButton && props.unfinished_matches === false && props.phase.state === 'Finalized' && props.is_tournament_finalized === false) {
            if (props.phase.best_third_teams !== 0 && props.phase.groups_count > 1) {
                if (((props.phase.groups_count * props.phase.classified_count) + props.phase.best_third_teams) <= 2) {
                    props.handleTournamentFinalizedSuggestion(true);
                }
            } else if ((props.phase.groups_count * props.phase.classified_count) <= 2) {
                props.handleTournamentFinalizedSuggestion(true);
            }
        }

    }, [props.phase.state]);
    /* eslint-enable */

    /* function optionMenuClick() {
        if (optionMenu) {
            setOptionMenu(false);
        } else {
            setOptionMenu(true);
        }
    } */

    /* const handleCloseToolTip = () => {
        setOpenTooltip(false);
    }; */

    /* const handleOpenToolTip = () => {
        if (optionMenu) {
            setOpenTooltip(false);
        } else {
            setOpenTooltip(true);
        }
    }; */

    const handleOnCloseMsg = () => {
        setCloseMsg(true)
    }

    function handleLastPhaseValidation() {
        if (props.showAddButton) {
            if (props.phase.best_third_teams !== 0 && props.phase.groups_count > 1) {
                if (((props.phase.groups_count * props.phase.classified_count) + props.phase.best_third_teams) <= 1) {
                    return false;
                }
            } else if ((props.phase.groups_count * props.phase.classified_count) <= 1) {
                return false;
            }
            return props.showAddButton;
        }
    }

    const renderImgTypePhase = (phase_type) =>{
        if(phase_type === 'Groups'){
            return analysis_1_image;
        }else if(phase_type === 'Free'){
            return analysis_4_image;
        }else{
            return analysis_3_image
        }
    }

    const handleOnBoxOpen = (index) => {
        if (!isBoxOpen) {
            setBoxPosition(index)
            setBoxOpen(true)
        } else {
            setBoxPosition()
            setBoxOpen(false)
        }
    };

    return (

        <div className={`phase-created ${handleLastPhaseValidation() ? "selected-phase__validation" : ""}`}>
            {/* { handleLastPhaseValidation() && 
                <SecondaryButtonComponent
                    disabled={false} 
                    onClick={ () => {props.onClickNewPhase()} }  
                    className="add-phase baloa-table-column"
                >
                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelNewPhase')}</span>
                </SecondaryButtonComponent>
            } */}
            <div className={`phase-created__field ${props.selectedPhase?.id === props.phase.id ? "selected-phase" : ""}`}
                onClick={() => props.onClickPhaseTab(props.phase)}>

                <img src={renderImgTypePhase(props.phase?.phase_type)} className="phase-created__field__content_photo" alt=""/>
                <div className='phase-created__field_summary'>
                    <div className="phase-created__field__content">
                        <div className="phase-created__field__content__text baloa-table">
                            {props.phase.caption}
                            {props.phase.state === 'Finalized'
                                ? t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelPhaseStatusFinalized')
                                : ""
                            }
                        </div>
                    </div>
                    <div className="phase-created__field__content__text baloa-names">
                        {props.phase.phase_name === null
                            ? props.phase.phase_name
                            : t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.' + props.phase.phase_name)
                        }
                    </div>
                </div>
                {props.showOptionMenu &&
                    <div className='tournament-phases__phase-options__container'>
                        <span className='icon-options' onClick={() => { handleOnBoxOpen(true) }} />
                        <div className={`tournament-phases-information_options-box ${boxPosition ? "active" : ""}`} >
                            <div className='tournament-phases_create_phase_options-box__option'
                                onClick={() => {
                                    handleOnBoxOpen(false);
                                    props.editTournamentPhaseV2(props.tournament, props.phase);
                                }}>
                                <span className='hubicon-edit' />
                                <div className='baloa-names'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')}</div>
                            </div>
                            <div className='tournament-phases_create_phase_options-box__option'
                                onClick={() => {
                                    handleOnBoxOpen(false);
                                    props.deleteTournamentPhase(props.phase, props.index);
                                }}>
                                <span className='hubicon-delete_question' />
                                <div className='baloa-names'>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDeletePhase')}</div>
                            </div>
                        </div>
                    </div>
                }
                {/* {props.showOptionMenu &&
                    <BaloaToolTip
                        title={t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.tooltipPhaseMenu')}
                        PopperProps={{
                            popperOptions: {
                                modifiers: {
                                    offset: {
                                        enabled: true,
                                        offset: '0, -90px',
                                    },
                                },
                            },
                        }}
                        open={openTooltip}
                        onClose={handleCloseToolTip}
                        onOpen={handleOpenToolTip}
                    >
                        <span className="phase-created__field__content__options icon-options"
                            onClick={() => optionMenuClick(true)}
                        />
                    </BaloaToolTip>
                } */}

                {optionMenu && props.edit_tournament && (
                    <ul className={
                        // props.showEndPhaseMenu && props.showDeletePhaseMenu && props.showEditPhaseMenu && props.showAddTeams
                        props.showEndPhaseMenu && props.showDeletePhaseMenu && props.showAddTeams
                            ?
                            "phase-created__option_three_option"
                            :
                            (props.showEndPhaseMenu && props.showDeletePhaseMenu)
                                || (props.showEndPhaseMenu && props.showEditPhaseMenu)
                                || (props.showDeletePhaseMenu && props.showEditPhaseMenu)
                                || (props.showDeletePhaseMenu && props.showAddTeams)
                                ? "phase-created__option_two_option" : "phase-created__option_one_option"
                    }
                    >
                        {props.showEndPhaseMenu &&
                            <li className="phase-created__option__list">
                                <TextButtonComponent
                                    onClick={() => {
                                        setOptionMenu(false);
                                        props.handleOnClickFinishPhase(props.phase);
                                    }}
                                >
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndPhase')}</span>
                                </TextButtonComponent>

                            </li>
                        }
                        {props.showDeletePhaseMenu &&
                            <li className="phase-created__option__list">
                                <TextButtonComponent
                                    onClick={() => {
                                        setOptionMenu(false);
                                        props.deleteTournamentPhase(props.phase, props.index);
                                    }}
                                >
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDeletePhase')}</span>
                                </TextButtonComponent>

                            </li>
                        }

                        {props.showAddTeams &&
                            <li className="phase-created__option__list">

                                <TextButtonComponent
                                    onClick={() => {
                                        setOptionMenu(false);
                                        props.AddTeamToGroupModal();
                                    }}
                                >
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelTeams')}</span>
                                </TextButtonComponent>

                            </li>
                        }
                        {props.showEditPhaseMenu &&
                            <li className="phase-created__option__list">

                                <TextButtonComponent
                                    onClick={() => {
                                        setOptionMenu(false);
                                        props.editTournamentPhaseV2(props.tournament, props.phase);
                                    }}
                                >
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEditPhase')}</span>
                                </TextButtonComponent>

                            </li>
                        }
                    </ul>
                )}

                {optionMenu && !props.edit_tournament && (
                    <ul className="phase-created__option options">
                        {props.showDeletePhaseMenu &&
                            <li className="phase-created__option__list">

                                <TextButtonComponent
                                    onClick={() => {
                                        setOptionMenu(false);
                                        props.deleteTournamentPhase(props.phase, props.index);
                                    }}
                                >
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelDeletePhase')}</span>
                                </TextButtonComponent>

                            </li>
                        }
                    </ul>
                )}

            </div>



            {!props.unfinished_matches && props.selectedPhase?.id === props?.phase?.id && props.phase.state === 'Active' && props.tournament_dates !== undefined &&
                <div className={`phases-created__phases__tooltip-msg ${(closeMsg ? 'hidden' : '')}`} >
                    <span
                        className='icon-x phases-created__phases__tooltip-msg__close-btn'
                        onClick={() => handleOnCloseMsg()}
                    />
                    <p className='baloa-caption-dark'>{t('toolTips.finishPhaseTooltip')}</p>
                </div>
            }

        </div>
    )
}

export default TournamentPhaseCreatedComponent;