import React from 'react';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
//i18
import { withTranslation } from 'react-i18next';

import './TournamentGeneralEndPhasesComponent.styles.css';

function TournamentGeneralEndPhasesComponent(props) {

    const VALUE_PHASE = "options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1."
    const PHASES_NAME_VALUES = [
        {
            value: "labelPhaseOfGroups",
            content: props.t(VALUE_PHASE + "labelPhaseOfGroups"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseTriangular",
            content: props.t(VALUE_PHASE + "labelPhaseTriangular"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseHomeRouns",
            content: props.t(VALUE_PHASE + "labelPhaseHomeRouns"),
            phase_type: "Groups",
        },
        {
            value: "labelPhasePentagonal",
            content: props.t(VALUE_PHASE + "labelPhasePentagonal"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseHexagonal",
            content: props.t(VALUE_PHASE + "labelPhaseHexagonal"),
            phase_type: "Groups",
        },
        {
            value: "labelPhaseRoundOf32",
            content: props.t(VALUE_PHASE + "labelPhaseRoundOf32"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseRoundOf16",
            content: props.t(VALUE_PHASE + "labelPhaseRoundOf16"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseQuarterFinal",
            content: props.t(VALUE_PHASE + "labelPhaseQuarterFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseSemiFinal",
            content: props.t(VALUE_PHASE + "labelPhaseSemiFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseFinal",
            content: props.t(VALUE_PHASE + "labelPhaseFinal"),
            phase_type: "Knockout",
        },
        {
            value: "labelPhaseLeague",
            content: props.t(VALUE_PHASE + "labelPhaseLeague"),
            phase_type: "Free",
        },
    ];

    const NAME_PHASE_SELECTED = PHASES_NAME_VALUES.find((phase) => phase.value === props.selected_phase?.phase_name)?.content;

    function validateFinalizePhase() {
        if(props.teams_classified_by_phases?.length === 0) {
            return true;
        } else if(props.teams_classified_by_phases?.length > 0 && props.teams_classified_by_phases.some(group => group.calculate_positions_table === false)) {
            return true;       
        } else {
            return false;
        }
    }

    return (
        <React.Fragment>
            <div className="tournament-phases__phases__arrow">
                <span className="icon-arrow-left" onClick={() => props.handleReturnFinishComponent(true)} />
                <p className="baloa-subtitle-1 color-text">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelEndPhase')}</p>
            </div>
            <div className="tournament-end-phases_container">
                <div className="tournament-end-phases_text_information">
                    <p className="baloa-subtitle-1 color-text">{props.selected_phase.caption} - {NAME_PHASE_SELECTED}</p>
                    <p className="baloa-table end-phases-subtitle"></p>
                    <div className="tournament-edit-tournament__classified">
                        <div className="tournament-edit-tournament__classified-square" />
                        <div className="baloa-table">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelQualifiedTheNextPhase')}</div>
                    </div>
                </div>
                <div className="tournament-general-end-phases scroll">
                    {props.teams_classified_by_phases?.map((position, index) => (
                        <div key={index} className="tournament-general-end-phase-teams">
                            <div className="tournament-general-end-phases-teams-best_thirds_group">
                                <p className="baloa-names">{position.group_name}</p>
                                {/* <FormControlLabel
                                    control={
                                        <Switch
                                            className="phases-group_switch_best-third"
                                            checked={bestThird}
                                            onChange={(event) => handleSwitchChangeBestThird(event)}
                                            name="best_third"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelBestThird')} /> */}
                            </div>
                            <div className="tournament-general-end-phases-teams-best_thirds_group">
                                <p className="best_thirds_group baloa-names">{props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure2.labelMyTeams')}</p>
                                <p className="best_thirds_group baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelPoints')}</p>
                            </div>
                            {position.classified_teams?.map((team, index) => (
                                <div key={index} className="tournament-general-end-phase-team_information">
                                    <div key={index} className="tournament-general-end-phase-team_photo_inf">
                                        <div className={`tournament-general-end-phases-team-status-best-third ${team.classified ? 'classified' : team.best_third ? 'best-third' : ''}`}></div>
                                        <p>{index + 1}</p>
                                        {team.photo
                                            ? <img src={team.photo} alt="" className="phases-teams--list__photo" />
                                            : <span className="icon-ball" color={getRandonIconBallColor()} />
                                        }
                                        <p className="baloa-names" >{team.page_name}</p>
                                    </div>
                                    <p className="baloa-names">{team.points}</p>
                                </div>
                            ))}
                            { props.tournament_results_table?.best_thirds && props.tournament_results_table?.best_thirds.length > 0 &&
                                props.tournament_results_table?.best_thirds.map((teamBestThird, index) => (
                                    <div className="tournament-general-end-phase-team_information">
                                        <div className="tournament-general-end-phase-team_photo_inf">
                                            <div className="tournament-general-end-phases-team-status-best-third"></div>
                                            <p>{index + 1}</p>
                                            {teamBestThird.photo
                                                ? <img src={teamBestThird.photo} alt="" className="phases-teams--list__photo" />
                                                : <span className="icon-ball" color={getRandonIconBallColor()} />
                                            }
                                            <p>{teamBestThird.page_name}</p>
                                        </div>
                                        <p>{teamBestThird.points}</p>
                                    </div>
                                ))
                            }
                        </div>
                    ))}
                </div>
                <div className="finish_groups_phase_footer">
                    <PrimaryButtonComponent
                        disabled={validateFinalizePhase()}
                        className=''
                        onClick={() => {
                            props.finishTournamentPhase(props.phase_id);
                            props.handleReturnFinishComponent();
                        }}
                    >
                        {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndGroupPhase.labelAction')}
                    </PrimaryButtonComponent>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation('league')(TournamentGeneralEndPhasesComponent);