// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ProfileTeamsModalComponent from 'app/profiles/components/profile-teams-modal/ProfileTeamsModalComponent';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Actions
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { addSignupTeamByCode, deleteSignupTeam, endAddTeamOnSignupAction } from 'app/authentication/actions/LoginActions';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './SignUpTeamPanelComponent.styles.css';

class SignUpTeamPanelComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            teams_is_open: false,
            profile: {
                user: {
                    "username": this.props.state.user.me.username
                }
            }
        };
    };

    handleLeftArrow() {
        this.props.changeSignUpStatus( SIGNUP_STATUS['PROFILE_TYPE'] );        
    }
    
    handleOpenTeamsModal() {
        this.setState({teams_is_open: true});
    }
    
    handleEndAddTeamOnSignupAction() {
        this.props.endAddTeamOnSignupAction( this.state.profile );
    }

    handleAddTeamByCode(teamcode) {
        console.log("Agregando equipo");
        console.log(this.props);
        this.props.addSignupTeamByCode(this.state.profile?.user?.username, teamcode);
    }

    handleDeleteSignupTeam(teamname) {
        this.props.deleteSignupTeam(this.state.profile?.user?.username, teamname);
    }

    handleOnCloseTeamsModal() {
        this.setState({teams_is_open: false});
        this.handleEndAddTeamOnSignupAction();
    }
    
    render = () => {
        return (
            <React.Fragment>
                {/* Profile Teams Modal */}
                <ProfileTeamsModalComponent
                    onClick={() => { }}
                    isOpen={this.state?.teams_is_open}
                    onClose={() => { 
                        this.handleOnCloseTeamsModal(); 
                    }}
                    model={this.props.state.signup}
                    onNewData={(teamcode)=>{
                        this.handleAddTeamByCode(teamcode)
                    }}
                    onDelete={(teamname)=>{
                        this.handleDeleteSignupTeam(teamname)
                    }}
                />

                <div className="signup-team-panel__container">
                    <div className="signup-team-panel__container__left-col"></div>
                    
                    <div className="signup-team-panel__container__right-col">
                        
                        <div className="signup-team-panel__container-title">
                            <span className="icon-arrow-left" onClick={  () => this.handleLeftArrow() } />
                            <h3 className="signup-team-panel__title">{this.props.t('playerTeams.teamsTitle')}</h3>
                            <div></div>
                        </div>
                        <h2 className="signup-team-panel__container-subtitle">{this.props.t('playerTeams.addTeamMessage')}</h2>
                        <div className="signup-team-panel__action-buttons">
                            <SecondaryButtonComponent 
                                type="button"
                                disabled={false} 
                                onClick={()=>{this.handleOpenTeamsModal();}} 
                                className="signup-team-panel__action-add-tem-button"
                            >
                                <span>{this.props.t('playerTeams.btnAddTeam')}</span>
                            </SecondaryButtonComponent>
                            <PrimaryButtonComponent 
                                type="button"
                                disabled={false} 
                                onClick={()=>{this.handleEndAddTeamOnSignupAction();}} 
                            >
                                <span>{this.props.t('playerTeams.btnContinue')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }    
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addSignupTeamByCode: (username, teamcode) => {
            dispatch ( addSignupTeamByCode(username,teamcode))
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        deleteSignupTeam: (username, teamname) => {
            dispatch( deleteSignupTeam(username, teamname))
        },
        endAddTeamOnSignupAction: ( profile ) => {
            dispatch( endAddTeamOnSignupAction( profile ) )
        }
    }
}

export default compose(
    withTranslation("profile"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpTeamPanelComponent);
