// React
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import { Field } from 'formik';

// Styles
import './SelectTeamFieldComponent.styles.css';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {/* ListItem, */ ListItemIcon, ListItemText } from '@mui/material';

// i18n
import { withTranslation } from 'react-i18next';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// React device detect
import { isMobile } from 'react-device-detect';


class SelectTeamFieldComponent extends React.Component {

    render() {
        
        const field_name = this.props.field_name;

        return (

            <Field name={ field_name } validate={ this.props.validationFunction } >
                { ( data ) => {
                    if ( this.props.error[ field_name ] !== "" ) {
                        data.form.errors[ field_name ] = this.props.error[ field_name ];
                    }
                    
                    return (
                        <div className= { "select-team-field " + ( this.props.className ? this.props.className : '' )  }>
                            { this.props.label && <label className="select-team-field-label" htmlFor={ this.props.input_id }>{ this.props.label }</label> }
                            <Select 
                                id={ this.props.input_id }
                                disabled={ this.props.disabled }
                                onChange={ this.props.onChange ? this.props.onChange(data.field.value) : undefined } 
                                className={ 
                                    'select-team-field-input ' + ( ( data.form.errors[ field_name ] && data.form.touched[ field_name ] ) ? 'error' : '' )
                                }
                                { ...data.field }
                                placeholder={ ( this.props.placeholder ? this.props.placeholder : '' ) }
                                defaultValue={this.props.defaultValue}
                                variant='standard'
                            >
                            
                                { this.props.options.map( (option) => {
                                    return (
                                        <MenuItem
                                            key={ option.value } 
                                            value={ option.value }
                                        >
                                            <ListItemIcon className="select-team-field-input__list__icon" >
                                                {option.team_logo?
                                                    <img className="select-team-field-input__list__icon__img" src={ option.team_logo} alt=""/>
                                                    :
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                }
                                            </ListItemIcon>
                                            {isMobile
                                            ? <ListItemText primary={ option.team } 
                                                secondary={
                                                <React.Fragment>
                                                    <span className={'baloa-username'}>@{option.teamname}</span>
                                                    <span className={'baloa-username'}>{option.team_gender}-{option.team_category}</span>
                                                </React.Fragment>
                                                } 
                                                className="select-team-field-input__listitem" 
                                            />
                                            : 
                                            <ListItemText primary={<p className={'baloa-names'}>{option.team}</p> } secondary={<span className={'baloa-username'}>{`@${option.teamname}`}</span>} className="select-team-field-input__listitem" />}
                                        </MenuItem> 
                                        /* <ListItem
                                            key={option.id}
                                            button
                                            className="select-team-field-input__list"
                                            selected={this.props.defaultValue.id === option.id}
                                            //onClick={(event) => handleListItemClick(event, item)}
                                        > 
                                            <ListItemIcon className="select-team-field-input__list__icon" >
                                                {option.team_logo?
                                                    <img className="select-team-field-input__list__icon__img" src={ option.team_logo} alt=""/>
                                                    :
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                }
                                            </ListItemIcon>
                                            {isMobile
                                            ? <ListItemText primary={ option.team } secondary={
                                                <React.Fragment>
                                                    <p className={'baloa-username'}>@{option.teamname}</p>
                                                    <p className={'baloa-username'}>{option.team_gender}-{option.team_category}</p>
                                                </React.Fragment>
                                            } className="select-team-field-input__listitem" />
                                            : <ListItemText primary={ option.team } secondary={`@${option.teamname}`} className="select-team-field-input__listitem" />}
                                        </ListItem>
                                        */
                                    )
                                } )} 
                            </Select>
                            <p className="select-field__message">{ this.props.message }</p>
                            <p className="input-field__message error">{ data.form.errors[ field_name ] }</p>
                        </div>
                    )

                } }
            </Field>
            
        );
    }
}

// Types
SelectTeamFieldComponent.propTypes = {
    validationFunction: PropTypes.func.isRequired,
}
export default withTranslation('validators')(SelectTeamFieldComponent);
