// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-team-detail-modal .modal2__header {
    padding: calc( var( --space ) + var( --space-quarter ) ) calc( var( --space ) + var( --space-quarter ) ) 0 0;
    border: none;
}

.page-team-detail-modal .modal2__content{
    overflow-y: auto;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .page-team-detail-modal .modal2__container{
        height: 100%;
        margin: 0 !important;
        max-height: 100%;
        border-radius: 0px;
    }

    .page-team-detail-modal .modal2__content{
        height: 100%;
    }

    .page-team-detail-modal .modal2__container{
        max-height: 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/app/pages/components/page-team-detail-modal/PageTeamDetailModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,4GAA4G;IAC5G,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,YAAY;QACZ,oBAAoB;QACpB,gBAAgB;QAChB,kBAAkB;IACtB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".page-team-detail-modal .modal2__header {\n    padding: calc( var( --space ) + var( --space-quarter ) ) calc( var( --space ) + var( --space-quarter ) ) 0 0;\n    border: none;\n}\n\n.page-team-detail-modal .modal2__content{\n    overflow-y: auto;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .page-team-detail-modal .modal2__container{\n        height: 100%;\n        margin: 0 !important;\n        max-height: 100%;\n        border-radius: 0px;\n    }\n\n    .page-team-detail-modal .modal2__content{\n        height: 100%;\n    }\n\n    .page-team-detail-modal .modal2__container{\n        max-height: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
