// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quoted-post__post-content{
    margin: var(--space);
    border-radius: 13px;
    border: 1px solid var(--new-gray-background-color);
}

.quoted-post__post-content.scroll{
    max-height: 500px;
}

.quoted-post__post-content.scroll.feed{
    max-height: unset;
    overflow-y: unset;
}

.quoted-post__image img{
    width: 100%;
    display: block;
    border-radius: 0 0 12px 12px;
}

@media screen and ( max-width: 1440px ) {
    .quoted-post__post-content.scroll{
        max-height: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/posts/components/quoted-post/QuotedPostComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,kDAAkD;AACtD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,4BAA4B;AAChC;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".quoted-post__post-content{\n    margin: var(--space);\n    border-radius: 13px;\n    border: 1px solid var(--new-gray-background-color);\n}\n\n.quoted-post__post-content.scroll{\n    max-height: 500px;\n}\n\n.quoted-post__post-content.scroll.feed{\n    max-height: unset;\n    overflow-y: unset;\n}\n\n.quoted-post__image img{\n    width: 100%;\n    display: block;\n    border-radius: 0 0 12px 12px;\n}\n\n@media screen and ( max-width: 1440px ) {\n    .quoted-post__post-content.scroll{\n        max-height: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
