// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './LeagueMatchResultComponent.styles.css';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//i18
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function LeagueMatchResultComponent ( props ) {

    return(
        <div className='league-match-result'>
            {!isMobile && 
            <React.Fragment>
                <div className="league-match-result__contianer__info-teams">
                    <div className="league-match-result__container__teams">
                        <div className={`league-match-result__cointainer__team-resume ${props.data_result.state === 'Scheduled' ? 'local' : ''}`}>                    
                            <div className="league-match-result__cointainer__team-container">
                                { props.data_result.local_team_photo && <img src={ props.data_result.local_team_photo } alt={`${props.data_result.local_team_name} img`}/>}
                                { !props.data_result.local_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-headline-2">{ props.data_result.local_team_name ? props.data_result.local_team_name : '' }</div>
                                <div className="baloa-normal-text">{ props.data_result.local_team ? '@' + props.data_result.local_team : '' }</div>
                            </div>
                            { props.data_result.state === 'Finalized' &&
                                <div className="league-match-result__score left">
                                    <div className='baloa-headline-2' >{ props.data_result.local_team_score }</div>
                                    { props.data_result.local_team_penalty_score && <div className='baloa-headline-1' >{ `(${props.data_result.local_team_penalty_score}` }</div>}                            
                                </div>
                            }
                        </div>
                    </div>
                    { props.data_result.state === 'Finalized' && 
                        <div className="baloa-headline-2 league-match-result__score">
                            <div className='baloa-headline-2' >-</div>
                            {props.data_result.local_team_penalty_score && <div className='baloa-headline-1' >-</div>}                            
                        </div>
                    }
                    {props.data_result.state === 'Scheduled' && 
                        <div className="baloa-headline-2 league-match-result__score">
                            <div className='baloa-headline-2' >vs</div>
                            {/* {props.data_result.local_team_penalty_score && <div className='baloa-headline-1' >-</div>} */}
                            { ( props.data_result.role_match === 'Assigner' || props.data_result.role_tournament === 'Assigner' || props.data_result.role_tournament === 'Administrator' || props.data_result.role_tournament === 'Owner') && 
                                <PrimaryButtonComponent onClick={() => {props.handleOnRefereesAssignmentBtn()}}
                                    className='league-match-result__score__referees-assignment'>
                                    {props.t('options.tournaments.pages.matchRefereesPayment.assignRefereeBtn')}
                                </PrimaryButtonComponent>
                            }
                        </div>
                    }                
                    <div className="league-match-result__container__teams">
                        <div className={`league-match-result__cointainer__team-resume ${props.data_result.state === 'Scheduled' ? 'visitant' : ''}`}>
                            { props.data_result.state === 'Finalized' &&
                                <div className="league-match-result__score right">
                                    <div className='baloa-headline-2' >{ props.data_result.visitant_team_score }</div>
                                    { props.data_result.visitant_team_penalty_score && <div className='baloa-headline-1' >{ `${props.data_result.visitant_team_penalty_score})` }</div>}
                                </div>
                            }
                            <div className="league-match-result__cointainer__team-container">
                                { props.data_result.visitant_team_photo && <img src={ props.data_result.visitant_team_photo } alt={`${props.data_result.visitant_team_name} img`}/>}
                                { !props.data_result.visitant_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-headline-2">{ props.data_result.visitant_team_name }</div>
                                <div className="baloa-normal-text">{ '@' + props.data_result.visitant_team }</div>
                            </div>
                        </div>                            
                    </div>                    
                </div>
                {props.data_result.state === 'Finalized' && 
                    (props.data_result.role_match === 'Assigner' || props.data_result.role_tournament === 'Assigner' || props.data_result.role_tournament === 'Administrator' || props.data_result.role_tournament === 'Owner') && 
                        <PrimaryButtonComponent onClick={() => {props.handleOnRefereesAssignmentBtn()}}
                            className='league-match-result__score__referees-assignment center-button'>
                            {props.t('options.tournaments.pages.matchRefereesPayment.assignRefereeBtn')}
                        </PrimaryButtonComponent>
                }
                </React.Fragment>
            }
            {isMobile && 
                <div className="league-match-result__contianer__info-teams">
                    <div className="league-match-result__container__teams">
                        <div className={`league-match-result__cointainer__team-resume ${props.data_result.state === 'Scheduled' ? 'local' : ''}`}>                    
                            <div className="league-match-result__cointainer__team-container">
                                { props.data_result.local_team_photo && <img src={ props.data_result.local_team_photo } alt={`${props.data_result.local_team_name} img`}/>}
                                { !props.data_result.local_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-table">{ props.data_result.local_team_name ? props.data_result.local_team_name : '' }</div>
                            </div>                            
                        </div>
                    </div>
                    {props.data_result.state === 'Finalized' && 
                        <div className="baloa-headline-2 league-match-result__score">
                            <div className="league-match-result__score-score">
                                <div className='baloa-headline-2' >{ props.data_result.local_team_score }</div>
                                <div className='baloa-headline-2' >-</div>
                                <div className='baloa-headline-2' >{ props.data_result.visitant_team_score }</div>
                            </div>
                            <div className="league-match-result__score-state baloa-username">{props.data_result.state}</div>
                            {/* <div>{'??'}</div> */}
                        </div>
                    }
                    {props.data_result.state === 'Scheduled' && 
                        <div className="baloa-headline-2 league-match-result__score">
                            <div className='baloa-headline-2' >vs</div>                        
                        </div>
                    }
                    <div className="league-match-result__container__teams">
                        <div className={`league-match-result__cointainer__team-resume ${props.data_result.state === 'Scheduled' ? 'visitant' : ''}`}>
                            <div className="league-match-result__cointainer__team-container">
                                { props.data_result.visitant_team_photo && <img src={ props.data_result.visitant_team_photo } alt={`${props.data_result.visitant_team_name} img`}/>}
                                { !props.data_result.visitant_team_photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-table">{ props.data_result.visitant_team_name }</div>
                            </div>
                        </div>                            
                    </div>
                </div>
            }
            <div className='league-match-result__separator-line'/>
            <div className='league-match-result__match-info' >
                <div className='league-match-result__match-info__item' >
                    <span className='baloa-table-column hubicon-date'/>
                    <div className='baloa-table-column'>{moment(props.data_result.match_date).format("DD MMM YYYY")}, {moment(props.data_result.match_time, ["HH.mm"]).format("hh:mm a")}</div>
                </div>
                <div className='league-match-result__match-info__item' >
                    <span className='baloa-table-column hubicon-stadium'/>
                    <div className='baloa-table-column'>{props.data_result.field}</div>
                </div>
                { props.data_result.official_referee &&
                    <div className='league-match-result__match-info__item' >
                        <span className='baloa-table-column icon-wistle'/>
                        <div className='baloa-table-column'>{props.data_result.official_referee_name}</div>
                    </div>
                }
            </div>
        </div>        
    )
}

export default withTranslation('league')(LeagueMatchResultComponent);