// React
import React from 'react';

// Styles
import './FollowerComponent.styles.css';

// Components
import RoundedButtonComponent from '../rounded-button/RoundedButtonComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';



function ProfileFollowerComponent ( props ){
    
    function onClick_User(){
        if(props.follower.is_type === 'profile'){
            props.handleOnProfileClick( {'user':{'username':props.follower.username}} )
        }else{
            props.handleOnPageClick({'pagename': props.follower.username});
        }
    }

    function onClick_onFollow (){
        if( props.follower.is_type === 'profile' ){
            props.onFollowProfile( props.follower.username, props.current_username );
        }else{
            props.onFollowPage( props.follower.username, props.current_username )
        }
    }

    function onClick_onUnfollow (){
        if( props.follower.is_type === 'profile' ){
            props.onUnfollowProfile( props.follower.username, props.current_username )
        }else{
            props.onUnfollowPage( props.follower.username, props.current_username )
        }
    }

    return (
        
        <div className="follower">
            <div className=" follower__box">
                <div
                className={ "follower__box__photo" }
                onClick={ () => onClick_User()}>
                    {props.follower.photo &&
                        <img src={ props.follower.photo} alt=""/>
                    }
                    {!props.follower.photo &&
                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                    } 
                </div>
                <div
                className="follower__box__info-username"
                onClick={ () => onClick_User()}>                    
                    <span className="baloa-names">
                        {`${props.follower.first_name} ${props.follower.last_name}`}
                    </span>
                    <span className="baloa-username"> 
                        @{props.follower.username}
                    </span>
                </div>
                <div className="follower__box__button">
                    { props.follower.is_following ? 
                    <RoundedButtonComponent
                        type="button"
                        disabled={ false } 
                        onClick = {() => onClick_onUnfollow() }
                        className={"inverse small"}
                    >
                        <span>{props.t('following')}</span>
                    </RoundedButtonComponent>
                    :   <RoundedButtonComponent 
                            type="button"
                            disabled={ false } 
                            onClick = {() => onClick_onFollow() }
                            className={"small"}
                        >
                            <span>{props.t('follow')}</span>
                        </RoundedButtonComponent>
                    }
                </div>   
            </div>
        </div>
    )
}


export default withTranslation('common')(ProfileFollowerComponent);