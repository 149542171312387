// React
import React from 'react';

// Styles
import './ProfileRecentMatchesFilter.styles.css';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

//Material
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { withStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


// i18n
import { withTranslation } from 'react-i18next';

const StyledMenu = withStyles({
    paper: {
        boxShadow:' 0px 5.30739px 5.30739px rgba(0, 0, 0, 0.25)',
        borderRadius: '14px'
    },
  })((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
  ));

function ProfileRecentMatchesFilter (props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [matchesStatus, setMatchesStatus] =React.useState('recent');

    const handleOnAccept = () =>{
        props.handleOnMatchesFilter(matchesStatus);
        handleClose();
    }

    const handleOnChoose = (e, value) =>{    
        setMatchesStatus(value);        
    }

    const handleOnCancel = () =>{
        //TODO: definir exactamente el comportamiento del filtro en boton cacelar
        //setMatchesStatus('recent');
        handleClose();
    }


    return (
        <div className= { "profile-recent-matches-filter " + ( props.className ? props.className : '' )  }>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}  id="set-simple" className='profile-recent-matches-filter__list-button'>
                <div className='baloa-table'>{matchesStatus === 'recent'? props.t('tournamentSummary.modalTournamentMatches.recentStateLbl') : props.t('tournamentSummary.modalTournamentMatches.olderStateLbl')}<span className='hubicon-expand_more'></span></div>
            </Button>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={'profile-recent-matches-filter__list'}

            >
                <div className='profile-recent-matches-filter__list__title baloa-table'>{props.listTitle}<span className='hubicon-clear_option' onClick={()=>{handleClose()}}></span></div>
                    <FormControl 
                        component="fieldset"
                        className={"profile-recent-matches-filter__radio-group"}
                    >
                        <RadioGroup 
                            className=""
                            onChange={handleOnChoose}
                        >
                            <FormControlLabel
                                value="recent"
                                control={<Radio color="secondary" checked={matchesStatus === 'recent'} />}
                                label={<span className="baloa-table-column">
                                        {props.t('tournamentSummary.modalTournamentMatches.recentStateLbl')}
                                    </span>}
                                labelPlacement="start"
                                className={matchesStatus === 'recent' ? 'selected' : ''}
                            />
                            <FormControlLabel
                                value="older"
                                control={<Radio color="secondary" checked={matchesStatus === 'older'} />}
                                label={<span className="baloa-table-column">
                                        {props.t('tournamentSummary.modalTournamentMatches.olderStateLbl')}
                                    </span>}
                                labelPlacement="start"
                                className={matchesStatus === 'older' ? 'selected' : ''}
                            />
                        </RadioGroup>
                    </FormControl>
                        
                <div className='profile-recent-matches-filter__buttons'>
                    <TextButtonComponent
                        className={'profile-recent-matches-filter__button-cancel xs'}
                        onClick={()=>{handleOnCancel()}}
                    >
                        <span>{props.t('tournamentSummary.modalTournamentMatches.cancelBtnLbl')}</span>
                    </TextButtonComponent>
                    <PrimaryButtonComponent
                            className={'xs'}
                            onClick={()=>{handleOnAccept()}}
                    >
                        <span>{props.t('tournamentSummary.modalTournamentMatches.acceptBtnLbl')}</span>
                    </PrimaryButtonComponent>
                </div> 
            </StyledMenu>
            
        </div>
    )

}

export default withTranslation('profile')(ProfileRecentMatchesFilter);
