// React
import React, { useState } from 'react';

// Components
import FormComponent from 'shared/components/form/FormComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentDynamicFormQuestionComponent from '../tournament-dynamic-form-question/TournamentDynamicFormQuestionComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';

//i18
import { withTranslation } from 'react-i18next';

// Styles
import './TournamentDynamicFormMakerComponent.styles.css';

//functions
import { RequiredValidator } from 'shared/validators/RequiredValidator';

const STRUCTURE_QUESTION = {
    submit_df_button_ref: null,
    formInitialValues: {
        form_name: '',
        question_0: '',
        input_type_0: '',
        isrequired_0: false,
    },
    errors: {
        form_name: '',
        question_0: '',
        input_type_0: '',
        isrequired_0: '',
    },
    submitDFObj: {},
    questions: [],
};

const STRUCTURE_FIELD = {
    id: 0,
    input_type: " ",
    caption: '',
    answers: [],
    is_required: false
};

function TournamentDynamicFormMakerComponent(props) {
    const [state, setState] = useState(STRUCTURE_QUESTION);
    const [fieldsObject, setFieldsObject] = useState(props.formData !== undefined? props.formData: [STRUCTURE_FIELD]);
    const [submitButtonRef, SetSubmitButtonRef] = useState ( // eslint-disable-line
        {
            submit_ref: null,
        }
    );
    const formRef = React.useRef();

    React.useEffect(() => {
        if(props.submitTeamPlayerForm){
            submitButtonRef.submit_ref.click();
        }
    }, [props.submitTeamPlayerForm]); // eslint-disable-line

    const addField = () => {
        const current_id = fieldsObject[(fieldsObject.length - 1)].id;
        const newObj = { id: (current_id + 1), input_type: " ", caption: '', answers: [], is_required: false };
        setFieldsObject((oldArray) => [...oldArray, newObj]);
        buildValuesToField(current_id);
    };

    React.useEffect(() => {
        const initValues = {}; 
        const errValues = {}; 
        if(props.formData){
            props.formData.forEach((question, index) => {
                const newQInput = `question_${question.id}`;
                const newITInput = `input_type_${question.id}`;
                const newIRInput = `isrequired_${question.id}`;
                initValues[newQInput] = question.caption;
                initValues[newITInput] = question.input_type;
                initValues[newIRInput] = question.is_required;
                errValues[newQInput] = '';
                errValues[newITInput] = '';
                errValues[newIRInput] = '';                
                if(question.answers?.length > 0) {                    
                    question.answers.forEach((answer, id_answer) => {
                        const newAInput = "answer_" + id_answer + "question_" + question.id;
                        const newChechk = "answerCheck_" + id_answer + "question_" + question.id;
                        initValues[newAInput] = answer.caption;
                        initValues[newChechk] = answer.defaultValue;
                        errValues[newAInput] = '';
                        errValues[newChechk] = '';                        
                    });
                } 
                               
            });

            initValues['form_name'] = props.formName? props.formName : '';
            errValues['form_name'] = '';
            
            setState({ ...state, formInitialValues: initValues, errors: errValues });
        }
    }, []); // eslint-disable-line

    const buildValuesToField = (current_id) => { 
        const initValues = state.formInitialValues; 
        const errValues = state.errors;
        const newQInput = `question_${current_id + 1}`;
        const newITInput = `input_type_${current_id + 1}`;
        const newIRInput = `isrequired_${current_id + 1}`;
        initValues[newQInput] = '';
        initValues[newITInput] = '';
        initValues[newIRInput] = false;
        errValues[newQInput] = '';
        errValues[newITInput] = '';
        errValues[newIRInput] = '';
        setState({ ...state, formInitialValues: initValues, errors: errValues });
    };

    const removeElement = (id) => {
        const indexQuestion = fieldsObject.findIndex((question) => question.id === id);
        indexQuestion !== -1 && fieldsObject.splice(indexQuestion, 1);
        let initValues = Object.assign({}, state.formInitialValues);
        delete initValues[`question_${id}`];
        delete initValues[`input_type_${id}`];
        delete initValues[`isrequired_${id}`];
        let errValues = Object.assign({}, state.errors);
        delete errValues[`question_${id}`];
        delete errValues[`input_type_${id}`];
        delete errValues[`isrequired_${id}`];
        // const cleanState = Object.assign(state, {formInitialValues: initValues, errors: errValues});
        // console.log(initValues,errValues);// llevar control sobre state al nivel de borrar
        formRef.current?.setFieldValue(`question_${id}`,'');
        formRef.current?.setFieldValue(`input_type_${id}`,'');
        formRef.current?.setFieldValue(`isrequired_${id}`,'');
        setState({ ...state, formInitialValues: initValues, errors: errValues });

    };

    const buildValuesToAnswer = (id_answer, id_question) => {
        const initValues = state.formInitialValues;
        const errValues = state.errors;
        const newAInput = "answer_" + id_answer + "question_" + id_question;
        const newChechk = "answerCheck_" + id_answer + "question_" + id_question;
        initValues[newAInput] = '';
        initValues[newChechk] = false;
        errValues[newAInput] = '';
        errValues[newChechk] = '';
        setState({ ...state, formInitialValues: initValues, errors: errValues });
    }

    const setQuestionObject = (question_object) => {
        const { id, caption, input_type, is_required, answers} = question_object;
        let obj = Object.assign([], fieldsObject);
        let obj2 = {}
        fieldsObject.forEach((question, index) => {
            if (question.id === id) {                
                obj2 = {
                    id: id,
                    caption: caption,
                    input_type: input_type,
                    is_required: is_required,
                    answers: answers,
                }
                obj[index] = obj2;         
            } 
        });
        setFieldsObject(obj);        
    };

    return (
        <div className='dynamic-form-maker__container scroll-parent'>
            {/* <div className='baloa-subtitle-2 dynamic-form-maker__title'>{props.form_title}</div> */}
            
            <div className='dynamic-form-maker__elements scroll'>
                <FormComponent
                    formInitialValues={state.formInitialValues}
                    onSubmit={(values, actions) => {                      
                        props.onNewFormValues(fieldsObject, values.form_name? values.form_name:null);
                        //props.onNewFormValues(fieldsObject);
                    }}                
                    className=""
                    functionDisabled={() => { }}
                    enableReinitialize={props.formData? true: false}
                    innerRef={formRef}
                >                 
                    <InputFieldComponent    
                        type={'text'}
                        input_id={'form_name'}
                        field_name={'form_name'}
                        label={props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.formName')}
                        validationFunction={RequiredValidator}
                        disabled={false}
                        className="dynamic-form-maker__form-title"
                        resetError={() => {}}
                        error={state.errors}
                        message=""
                        defaultValue={props.formName}
                    />

                    <div className='baloa-table-column dynamic-form-maker_description'>{props.form_description}</div>

                    <div className='dynamic-form-maker__buttons'>
                        <SecondaryButtonComponent
                            type="button"
                            id=""
                            className="xs"
                            onClick={() => { addField() }}
                            disabled={false}
                        >
                            <span>{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.addQuestionBtn')}</span>
                        </SecondaryButtonComponent>
                        {!props.handleSaveTournamentForm &&
                            <div onClick={() => { props.handlePreviewForm(fieldsObject) }}  className='tournament-dynamic-form-maker__preview-btn' >
                                <span className='hubicon-preview' />
                                <div className='baloa-table'>{props.t('options.tournaments.pages.newTournament.teamPlayerDynamicForm.previewBtn')}</div>
                            </div>
                        }
                    </div>

                    {fieldsObject?.map((e, i) =>                    
                        <TournamentDynamicFormQuestionComponent
                            key={e.id}
                            question={e}
                            removeElement={(id) => removeElement(id)}
                            setQuestionObject={(obj) => { setQuestionObject(obj) }}
                            errors={state.errors}
                            arrayQuestions={fieldsObject}
                            structure_field={STRUCTURE_FIELD}
                            buildValuesToAnswer={(indexA, indexQ)=> buildValuesToAnswer(indexA, indexQ)}
                            
                        />
                    )}

                    <input 
                        style={{ display: 'none' }}
                        type="submit"
                        ref={button_submit => submitButtonRef.submit_ref = button_submit}
                        value={"enviar"}
                        onClick={()=>{props.resetButton()}}
                    />
                </FormComponent>
            </div>
            {props.handleSaveTournamentForm &&
                <div className='dynamic-form-maker__buttons'>                
                    <SecondaryButtonComponent
                        type="button"
                        id=""
                        className="xs"
                        onClick={() => {
                            if(fieldsObject[0].caption.length > 0){
                                props.onNewFormValues(fieldsObject);
                            }
                        }}  
                        disabled={false}
                    >
                        <span>{props.t('options.tournaments.pages.newTournament.saveButton')}</span>
                    </SecondaryButtonComponent>                
                </div>
            }
        </div>
    )
};

export default withTranslation(['league', 'tournament'])(TournamentDynamicFormMakerComponent)