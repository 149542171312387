/*eslint eqeqeq: "off"*/
import React from 'react';
import { Link } from 'react-router-dom';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import SearchIcon from '@mui/icons-material/Search';

// Styles
import './SearchResultBoxComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

function SearchResultBoxComponent(props) {
    return(
        <div className = {'search-result-box ' + ( props.show_sbox? '' : 'hide' )} >
            {props.keyword?.length > 0 ?
                props.results?.length > 0 ?
                    <Link to={`/search/search=${props.keyword}`} className="search-result-box__more baloa-names"><SearchIcon />{`${props.t('search.title')} ${props.keyword}`}</Link>
                    :
                    <Link to={`/search/search=${props.keyword}`} className="search-result-box__more baloa-names">{`${props.t('search.title')} ${props.keyword}`}</Link>
                : ''
            }
            { props.results?.length > 0 && props.results?.slice(0, 5).map( result =>
                    <div key = { result.id } className = {"search-result-box__result"} onClick={()=>{props.handleMobileSearch()}}>
                        <Link to={`/${result.is_type}/${result.username}`} className="search-result-box__result__profile-info">
                            <div className={ "search-result-box__result__profile-info__photo" }>
                                {result.image &&
                                    <img src={result.image} alt="" />
                                }
                                {!result.image &&
                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                } 
                            </div>
                            <div className="search-result-box__result__profile-info__data">
                                <div className="search-result-box__result__profile-info__data__fullname">
                                    <div className="search-result-box__result__profile-info__data__fullname-name baloa-names">
                                        { result.full_name }
                                    </div>
                                    {/* <div className='search-result-box__result__profile-info__data-type baloa-mini'>{props.t('is_type.'+result.is_type)}</div> */}
                                </div>
                                <div className="search-result-box__result__profile-info__data__username baloa-table-column">
                                    {result.is_type === 'profile' && <span>@{result.username}&nbsp;&bull;&nbsp;{result.profile_type === 'professional'? props.t('profile:professional_types.'+result.profile_profession_type) : props.t('profile:role.'+result.profile_type) }</span>/* ('@' + {result.ref} + <span>&bull;</span> + {result.ref}) */ }
                                </div>
                            </div>
                        </Link>
                    </div>
            ) }            
            {(props.keyword?.length ===0 && props.results?.length === 0) &&
                <div className='search-result-box__link-buttons__container'>
                    <div className='search-result-box__link-buttons__subtitle baloa-names'>{props.t('resultBoxText')}</div>
                    <div className='search-result-box__link-buttons__buttons'>
                        <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('players')}}>
                            <span className="icon-player"/>
                            <div className="baloa-names" >
                                {props.t('home:quickAccess.players')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div>
                        <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('tournaments')}}>
                            <span className="hubicon-tournaments">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </span>
                            <div className="baloa-names">
                                {props.t('home:quickAccess.tournamentAccess')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div>
                        {/* <div className='search-result-box__link-buttons__button'>
                            {'prf expertos'}
                            <span className="icon-coach"/>
                            <span className='icon-chevron-roght'/>
                        </div> */}
                        {/* <div className='search-result-box__link-buttons__button'>
                            {'equipos'}
                            <span className='icon-chevron-roght'/>
                        </div> */}
                        <div className='search-result-box__link-buttons__button' onClick={() => {props.handleSearchLinkTo('pages')}}>
                            <span className="hubicon-pages">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                            </span>
                            <div className="baloa-names">
                                {props.t('home:quickAccess.pageAccess')}
                            </div>
                            <span className='icon-chevron-roght'/>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

export default withTranslation(['search', 'home', 'profile'])(SearchResultBoxComponent);
