// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms {}
.terms__content {
  padding: var(--space-x2-and-half) calc(var(--space-x3)*2.4);
}
.terms__content__text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: var(--font-regular);
  font-size: var(--space-half-quarter);
  line-height: 16px;
  letter-spacing: 0.004em;
  color: var(--primary-very-dark-color);
}
.terms__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.terms__actions__return {
    margin-right: var(--space-half-quarter);
}

.terms__actions__accept {
  margin-left: var(--space-half-quarter);
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */
  .terms__content {
    padding: var(--space);
  }
  .terms__content__text {    
    font-size: var(--space-half);    
  }
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/components/terms-and-conditions-modal/TermsAndConditionsModalComponent.styles.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;EACE,2DAA2D;AAC7D;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,gCAAgC;EAChC,oCAAoC;EACpC,iBAAiB;EACjB,uBAAuB;EACvB,qCAAqC;AACvC;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;EACE,sCAAsC;AACxC;;AAEA,yCAAyC,4BAA4B;EACnE;IACE,qBAAqB;EACvB;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".terms {}\n.terms__content {\n  padding: var(--space-x2-and-half) calc(var(--space-x3)*2.4);\n}\n.terms__content__text {\n  font-family: var(--secondary-font);\n  font-style: normal;\n  font-weight: var(--font-regular);\n  font-size: var(--space-half-quarter);\n  line-height: 16px;\n  letter-spacing: 0.004em;\n  color: var(--primary-very-dark-color);\n}\n.terms__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.terms__actions__return {\n    margin-right: var(--space-half-quarter);\n}\n\n.terms__actions__accept {\n  margin-left: var(--space-half-quarter);\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n  .terms__content {\n    padding: var(--space);\n  }\n  .terms__content__text {    \n    font-size: var(--space-half);    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
