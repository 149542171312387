// React
import React, { Component } from 'react'
import { connect } from 'react-redux';

// Actions
import {
    loadSearch,
    loadSearchFilter,
    loadSearchBox,
    followSearchProfile, 
    followSearchPage, 
    followSearchTournament, 
    unfollowSearchProfile, 
    unfollowSearchPage, 
    unfollowSearchTournament 
} from 'app/search/actions/SearchActions';
import { loadHomeProfileSuggestions } from 'app/home/actions/HomeActions';

import {
    loadProfile,
    loadProfilePosts,
} from 'app/profiles/actions/ProfileActions';

import { changeProfileSuggestionsModalStateAction } from 'app/home/actions/HomeActionsCreators';

// Components
import SearchResultComponent from 'app/search/components/search-result/SearchResultComponent';
import SearchMenuComponent from 'app/search/components/search-menu/SearchMenuComponent';
//import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
//import ProfileSuggestionsModalComponent from 'app/profiles/components/profile-suggestions-modal/ProfileSuggestionsModalComponent';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './SearchView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { compose } from 'redux';


class SearchView extends Component {
  constructor(props) {
    super(props);
    this.filter = this.props.match.params.filter;
    this.keyword = this.props.match.params.keyword;
  }

  componentDidMount() {
    this.props.loadHomeProfileSuggestions();
    //this.props.loadSearchBox(this.keyword);
    this.filter ? this.props.loadSearchFilter(this.keyword, this.filter) : this.props.loadSearch(this.keyword);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.filter !== prevProps.match.params.filter) {
      this.props.loadSearchFilter(this.props.match.params.keyword, this.props.match.params.filter);
    }
    if (this.props.match.params.keyword !== prevProps.match.params.keyword && !this.props.match.params.filter ) {
      this.props.loadSearch(this.props.match.params.keyword);
    }
  }

  handleSearchFilter(searchUrl) {
    this.props.history.push(searchUrl);
  }

  handleSearchLinkTo=(page_type, keyword)=>{
      switch(page_type){
          case 'profile':
              this.props.history.push(`/players?search=${keyword}`);
              break;        
          case 'page':
              this.props.history.push(`/pages?search=${keyword}`);
              break;  
          case 'tournament':
              this.props.history.push(`/tournament/hub/search?search=${keyword}`);
              break;
          //no default
      }
  }

  render() {
    return (
      <div className={`search ${(isMobile && !this.props.match.params.filter)? 'search-mobile' : ''}`}>
        <div className="search__left-container">
          <SearchMenuComponent 
            keyword={this.props.match.params.keyword}
            handleSearchFilter={(filterUrl)=>{ this.handleSearchFilter(filterUrl) }}
            filter={this.props.match.params.filter}
          />
        </div>
        <div className="search__center-container">
          <SearchResultComponent
            keyword={this.props.match.params.keyword}
            filter={this.props.match.params.filter}
            search_results={this.props.state.search.search_results}
            search_results_next={this.props.state.search.search_results_next}
            search_path={this.props.location?.pathname}
            handleFollowProfile={(profile) => { this.props.followSearchProfile(profile) }}
            handleUnFollowProfile={(profile) => { this.props.unfollowSearchProfile(profile) }}
            handleFollowPage={(page) => { this.props.followSearchPage(page) }}
            handleUnFollowPage={(page) => { this.props.unfollowSearchPage(page) }}
            handleFollowTournament={(page) => { this.props.followSearchTournament(page) }}
            handleUnFollowTournament={(page) => { this.props.unfollowSearchTournament(page) }}
            handleSearchLinkTo={(page_type)=>{ this.handleSearchLinkTo(page_type, this.props.match.params.keyword ) }}
            fetchMoreData={()=>{ this.props.loadSearchFilter(this.keyword, this.filter, this.props.state.search.search_results_next) }}
          />
        </div>
        {/* <div className="search__right-container">
          <ProfileSuggestionsComponent
            title={this.props.t('whoFollow')}
            profiles={this.props.state.home.profile_suggestions.slice(0, 4)}
            className="home__right-container__profile-suggestions sm"
            handleFollow={(profile) => { this.props.followProfile(profile) }}
            loading_profile_suggestions={this.props.state.home.loading_profile_suggestions}
            go_link={this.props.t('seeAll')}
            handleGoLink={() => { this.props.changeProfileSuggestionsModalStateAction(true) }}
          />
          <ProfileSuggestionsModalComponent
            onClick={() => { }}
            isOpen={this.props.state.home.modals.profile_suggestions_is_open}
            onClose={() => { this.props.changeProfileSuggestionsModalStateAction(false) }}
            title={this.props.t('whoFollow')}
            profiles={this.props.state.home.profile_suggestions}
            //className = "home__right-container__profile-suggestions sm"
            handleFollow={(profile) => { this.props.followProfile(profile) }}
          />
        </div> */}
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSearch: (keyword) => {
        dispatch(loadSearch(keyword))
    },
    loadHomeProfileSuggestions: () => {
        return dispatch(loadHomeProfileSuggestions())
    },
    loadSearchFilter: (keyword, filter, next_page) => {
        dispatch(loadSearchFilter(keyword, filter, next_page))
    },
    loadSearchBox: (keyword) => {
        dispatch(loadSearchBox(keyword))
    },
    loadProfile: (username) => {
        dispatch(loadProfile(username))
    },
    loadProfilePosts: (username) => {
        dispatch(loadProfilePosts(username))
    },
    followSearchProfile: ( profile ) => {
        return dispatch( followSearchProfile( profile ) )
    },
    followSearchPage: ( page ) => {
      return dispatch( followSearchPage( page ) )
    },
    followSearchTournament: ( page ) => {
        return dispatch( followSearchTournament( page ) )
    },
    changeProfileSuggestionsModalStateAction: (new_modal_state) => {
        dispatch( changeProfileSuggestionsModalStateAction(new_modal_state))
    },
    unfollowSearchProfile: (profile) => {
        return dispatch( unfollowSearchProfile(profile) );
    },
    unfollowSearchPage: ( page ) => {
        dispatch( unfollowSearchPage( page ) )
    },
    unfollowSearchTournament: ( page ) => {
        dispatch( unfollowSearchTournament( page ) )
    },
  }
}

export default compose(
  withTranslation("search"),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchView);
