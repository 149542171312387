
// State
const signup_error_state = {
    error: {
        code: '',
        phone: '',
        first_name: '',
        last_name: '',
        birthday: '',
        check: '',
        email: '',
        password: '',
        confirm_password: '',
        country: '',
        city: '',
        username:'',
        profile_type:'',
        profession_type:'',
    }
}

export default function SignUpErrorReducer ( state = signup_error_state, action ) { 
    
    switch( action.type ) {

        case "SIGNUP:CHANGE_ERROR":{
            const errorData = state.error;
            errorData[action.data.field] = action.data.error;
            return { ...state, error: errorData }
        }

        // no default
    }

    return state;
}
