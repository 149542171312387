
// i18next 
import i18n from 'i18next';

export const PhoneValidator = ( value ) => {
    let error;
    if (value && !/^\+?1?\d{8,15}$/i.test( value ) ) {
        error = i18n.t('validators:phoneError')//"No es un número válido";
    }
    if(value && /\s/g.test( value )){
        error = "No usar espacios";
    }
    return error;
}