// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './BlockModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function BlockModalComponent ( props ){

    function handleBlock(){
        if(props.profile_username !== null){
            props.blockProfile(props.profile_username);
        }else if(props.page_pagename !== null){
            props.blockPage(props.page_pagename);
        }
    }

    return(
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <div className='block-modal__container'>
                <p className="modal-title">
                    {props.t('blockUser.title')}
                </p>
                <p className="modal-text">
                
                    {props.t('blockUser.text', props.profile_username? {username: '@'+props.profile_username} : {username: '@'+props.page_pagename})}
                </p>
                <div className="login-modal-actions">
                    <SecondaryButtonComponent 
                        className={"small"}
                        onClick={() => {                        
                            props.onClose();
                        } }
                    >
                        <span>{props.t('blockUser.buttonBack')}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent 
                        className={"block-modal__accept small"}
                        type="submit"
                        disabled={false} 
                        onClick={() => {
                            handleBlock();
                            props.onClose();
                        } }
                    >
                        <span>{props.t('blockUser.buttonBlock')}</span>
                    </PrimaryButtonComponent>
                </div>
            </div>
        </ModalComponent>
    )

}

export default withTranslation('post')(BlockModalComponent)

//export default BlockModalComponent;