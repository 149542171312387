// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.finalize_tournament_suggestion_container .baloa-body-1,
.finalize_tournament_suggestion_container p span.baloa-body-bold {
    display: contents;
}

.finalize_tournament_suggestion_container {
    z-index: 1;
}

.finalize_tournament_suggestion_title {
    margin-bottom: var(--space-half);
} 

.finalize_tournament_suggestion_btn {
    margin-top: var(--space-and-half);
    float: right;
}

.finalize_tournament_suggestion_container .modal__container {
    max-width: 328px;
}

`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-finalize-tournament-suggestion-modal/TournamentFinalizeTournamentSuggestionModalComponent.styles.css"],"names":[],"mappings":";AACA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.finalize_tournament_suggestion_container .baloa-body-1,\n.finalize_tournament_suggestion_container p span.baloa-body-bold {\n    display: contents;\n}\n\n.finalize_tournament_suggestion_container {\n    z-index: 1;\n}\n\n.finalize_tournament_suggestion_title {\n    margin-bottom: var(--space-half);\n} \n\n.finalize_tournament_suggestion_btn {\n    margin-top: var(--space-and-half);\n    float: right;\n}\n\n.finalize_tournament_suggestion_container .modal__container {\n    max-width: 328px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
