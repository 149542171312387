/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';

import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Styles
import './InputFieldTeamAutocompleteComponent.styles.css';
import { withStyles } from '@mui/styles';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// React device detect
import { isMobile } from 'react-device-detect';

function InputFieldTeamAutocompleteComponent( props ) {

    const AutocompleteTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'var(--basic-gray-color)',
            },          
            '& .MuiOutlinedInput-root': {
                '& fieldset': {             
                    border: 'var(--new-input-background-color)',
                },      
            },
        },
    })(TextField);
    
    return(
            <Field name={ props.name }>
            {
                ({
                    form: { setFieldValue }, 
                }) => (
                <Autocomplete
                    //key={ props.key }
                    //name={ props.name }
                    disabled={false}
                    //component={Autocomplete}
                    freeSolo={props.freeSolo ? props.freeSolo : false}
                    options={props.options}
                    noOptionsText= { props.noOptionsText }
                    getOptionLabel={(option) => {
                        return (option.team ? `${option.team}` : '')                           
                    }}
                    getOptionKey={option => option[props.optionSelectedField] }
                    isOptionEqualToValue={( option, value ) => {
                        if(value){
                            return option[props.optionSelectedField] === value[props.optionSelectedField] 
                        }else{
                            return null
                        }
                        
                    }}
                    value={ props.value }
                    onChange={(e, value) => {
                        if(props.onChange ){
                            props.onChange( value )
                        }                    
                        setFieldValue(props.name, value )
                    }}
                    defaultValue={props.defaultValue}
                    renderOption={(comprops,option) => {
                        //display value in Popper elements                        
                        return <ListItem
                                    {...comprops}
                                    key={option.id}
                                    button
                                    className="input-field-team-autocomplete__list"
                                    //selected={selectedIndex === option.id}
                                    //onClick={(event) => handleListItemClick(event, item)}
                                >
                                    <ListItemIcon className="input-field-team-autocomplete__list__icon" >
                                        {option.team_logo?
                                            <img className="input-field-team-autocomplete__list__icon__img" src={ option.team_logo} alt=""/>
                                            :
                                            <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                        }
                                    </ListItemIcon>
                                    {isMobile
                                    ? <ListItemText primary={ option.team } secondary={
                                        <React.Fragment>
                                            <p className={'baloa-username'}>@{option.teamname}</p>
                                            <p className={'baloa-username'}>{option.team_gender}-{option.team_category}</p>
                                        </React.Fragment>
                                    } className="input-field-team-autocomplete__listitem" />
                                    : <ListItemText primary={ option.team } secondary={`@${option.teamname}`} className="input-field-team-autocomplete__listitem" />}
                                </ListItem>
                    }}               
                    renderInput={(params) => (
                        <div className='input-field-team-autocomplete__img-selected-option'>
                            { props.is_transfer_player &&
                                <div className='input-field-team-autocomplete__img-selected-option__img'>
                                    { props.options?.filter(el => el.team === params.inputProps.value)[0]? props.options?.filter(el => el.team === params.inputProps.value)[0].team_logo? 
                                        <img className="input-field-team-autocomplete__list__icon__img" src={ props.options?.filter(el => el.team === params.inputProps.value)[0].team_logo} alt=""/>
                                        :
                                        <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                        :
                                        ''
                                    }
                                </div>
                            }
                            <AutocompleteTextField
                                {...params}
                                className={ props.className + " input-field-team-autocomplete"}
                                label={ props.label }
                                variant="outlined"
                                margin="normal"
                                placeholder={ props.placeholder }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                /* InputProps={{
                                    startAdornment: <InputAdornment position="start">{"gg"}</InputAdornment>,
                                }}  */
                            />
                        </div>
                           
                    )}
                />
                )
            }
            </Field>
    )
}

export default InputFieldTeamAutocompleteComponent;
