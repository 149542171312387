// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-cards-selector__container{
    width: 100%;
    /* height: calc(calc(var(--space) * 3) + var(--space-half)); */
    height: 70px;
    padding-top: var(--space);
    padding-bottom: var(--space);
    display: flex;
    justify-content: space-between;
    position: relative;
}

.league-cards-selector__container img{
    margin: auto;
    opacity: 0.5;
    width: calc( var( --space-x2 ) + var( --space-eighth ) );
    height: calc( var( --space-x3 ) + var( --space-quarter ) + var( --space-eighth ) );
}

.league-cards-selector__container .Mui-checked img{
   
    opacity: 1;
}

.league-cards-selector__container .MuiFormControlLabel-root{
    margin: 0px calc( var( --space-x3 ) + var( --space ) ) 0 0;
}
.league-cards-selector__container .MuiCheckbox-root{
    padding: 0px;
}

.league-cards-selector__message {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: var(--font-regular);
    font-size: var(--space-half-quarter);
    line-height: var(--space);
    color: var(--primary-dark-color);
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 40px;
    position: absolute;
    text-align: left;
}
.league-cards-selector__message.error {
    color: var(--error-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-cards-selector/LeagueCardsSelectorComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8DAA8D;IAC9D,YAAY;IACZ,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,wDAAwD;IACxD,kFAAkF;AACtF;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,0DAA0D;AAC9D;AACA;IACI,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,gCAAgC;IAChC,oCAAoC;IACpC,yBAAyB;IACzB,gCAAgC;IAChC,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".league-cards-selector__container{\n    width: 100%;\n    /* height: calc(calc(var(--space) * 3) + var(--space-half)); */\n    height: 70px;\n    padding-top: var(--space);\n    padding-bottom: var(--space);\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n}\n\n.league-cards-selector__container img{\n    margin: auto;\n    opacity: 0.5;\n    width: calc( var( --space-x2 ) + var( --space-eighth ) );\n    height: calc( var( --space-x3 ) + var( --space-quarter ) + var( --space-eighth ) );\n}\n\n.league-cards-selector__container .Mui-checked img{\n   \n    opacity: 1;\n}\n\n.league-cards-selector__container .MuiFormControlLabel-root{\n    margin: 0px calc( var( --space-x3 ) + var( --space ) ) 0 0;\n}\n.league-cards-selector__container .MuiCheckbox-root{\n    padding: 0px;\n}\n\n.league-cards-selector__message {\n    font-family: var(--secondary-font);\n    font-style: normal;\n    font-weight: var(--font-regular);\n    font-size: var(--space-half-quarter);\n    line-height: var(--space);\n    color: var(--primary-dark-color);\n    width: fit-content;\n    margin-top: 40px;\n    position: absolute;\n    text-align: left;\n}\n.league-cards-selector__message.error {\n    color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
