// React
import React, { Component } from 'react'
import { connect } from 'react-redux';

// Actions
import { 
    deleteLeagueTeam,
    loadLeagueMembers, 
    loadLeagueTeams,} from 'app/leagues/actions/LeagueActions';
import { changeTeamInfoModalStateAction } from 'app/leagues/actions/LeagueActionsCreators';
import { loadPage, setStaffMemeberPermissions } from 'app/pages/actions/PageActions';
import { searchMembersLeague, addMemberToLeague, searchMembersLeagueWithValidation } from 'app/leagues/actions/LeagueActions';
import { changeLeaguesAddMemberToLeagueModalStateAction } from 'app/leagues/actions/LeagueActionsCreators';
import { searchTeamsLeague,addTeamToLeague, searchTeamsLeagueWithValidation } from 'app/leagues/actions/LeagueActions';
import { changeLeaguesAddTeamToLeagueModalStateAction } from 'app/leagues/actions/LeagueActionsCreators';

// Modals
import LeagueAddMemberModalComponent from 'app/leagues/components/league-add-member-modal/LeagueAddMemberModalComponent';
import LeagueAddTeamModalComponent from 'app/leagues/components/league-add-team-modal/LeagueAddTeamModalComponent';


// Components
import LeagueTeamInfoModalComponent from 'app/leagues/components/league-team-info-modal/LeagueTeamInfoModalComponent';
import TeamsMembersComponent from 'app/leagues/components/team-members/TeamsMembersComponent';
import { loadMatchReport } from '../actions/LeagueMatchActions';

//i18
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './LeagueView.styles.css';


class MemberView extends Component {

    constructor( props ) {
        super( props );
        this.pagename = this.props.match.params.pagename;
        this.state = {
            tab_selected: 0
        }
    }

    componentDidMount() {
        this.props.loadPage( this.pagename );        
    }

    handleSearchTeam = ( searchedteam ) => {
        this.props.searchTeam(searchedteam);
    }

    handleOnActiveTournament(selected_row) {
        
    }

    handleOnCompletedTournament(selected_row) {
        
    }

    handleOnTeamPageClick = ( team_pagename ) => {
        this.props.history.push(`/page/${team_pagename}`);
    }

    handleOnEndTournamentModalCloseClick = ( ) => {                
        this.props.changeEndTournamentModalStateAction(false);
    }

    handleOnAddTeamButtonClick = ( ) => {     
        this.props.changeLeaguesAddTeamToLeagueModalStateAction( true );  
    }

    handleOnAddMemberButtonClick = ( ) => {  
        this.props.changeLeaguesAddMemberToLeagueModalStateAction( true )      
    }

    render(){
       
        return(
   
            <React.Fragment>
                
                <div className="league">

                    <div className="league__left-container"></div>

                    <div className="league__center-container">

                        {/* team info modal */}
                        <LeagueTeamInfoModalComponent 
                            onClick={() => {}} 
                            isOpen={ this.props.state.leagues.modals?.team_info_is_open } 
                            onClose={() => { this.props.changeTeamInfoModalStateAction( false ) }}
                            league_pagename = {this.pagename}
                            deleteLeagueTeam = { (league_pagename, team_pagename) => {this.props.deleteLeagueTeam(league_pagename, team_pagename)}}
                            handleOnTeamPageClick = { (team_pagename) => {this.handleOnTeamPageClick(team_pagename)} }

                        />

                        <TeamsMembersComponent
                            leage_name = { this.pagename }
                            handleOnAddTeamButtonClick = { this.handleOnAddTeamButtonClick }
                            handleOnAddMemberButtonClick = { this.handleOnAddMemberButtonClick }
                            history_member_team = {this.props.history}
                            league_teams_is_loading = { this.props.state.leagues.league_teams_is_loading }
                            league_members_is_loading = { this.props.state.leagues.league_members_is_loading }
                            page = { this.props.state.page.page }
                            handleSavePermissions = { ( member_username, roles )=>{ this.props.setStaffMemeberPermissions( member_username, this.pagename, roles ) } }
                        />

                     </div>

                </div>

                <LeagueAddTeamModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.leagues.modals?.add_team_to_league_is_open } 
                    onClose={() => { this.props.changeLeaguesAddTeamToLeagueModalStateAction( false ) }}
                    title={ this.props.t('options.teamsAndMembers.tabs.tabTeams.modals.modalAddTeam.labelTitle') }
                    teams = {this.props.state.leagues?.searched_teams}
                    handleSearchTeam = {(searchedteam) => this.props.searchTeamWithValidation(searchedteam, this.props.state.leagues.league_teams.results)}
                    onNewData = { ( new_team ) => { 
                        this.props.changeLeaguesAddTeamToLeagueModalStateAction( false )
                        this.props.addTeamToLeague( this.pagename, new_team )                                                
                    } }
                />

                <LeagueAddMemberModalComponent 
                    onClick={() => {}} 
                    isOpen={ this.props.state.leagues.modals?.add_member_to_league_is_open } 
                    onClose={() => { this.props.changeLeaguesAddMemberToLeagueModalStateAction( false ) }}
                    title= { this.props.t('options.teamsAndMembers.tabs.tabStaff.modals.modalAddMember.labelTitle') }
                    profiles = {this.props.state.leagues?.searched_members}
                    handleSearchMember = {( member_username ) => this.props.searchMemberWithValidation( member_username, this.props.state.leagues.league_members.results )}
                    onNewData = { ( new_member ) => { 
                        this.props.changeLeaguesAddMemberToLeagueModalStateAction( false )
                        this.props.addMemberToLeague( this.pagename, new_member )                                                 
                    } }
                />
                

            </React.Fragment>
  
        )
    }

}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPage: ( username ) => {
            dispatch( loadPage( username ) )
        },
        changeTeamInfoModalStateAction: ( new_modal_state ) => {
            dispatch( changeTeamInfoModalStateAction( new_modal_state ))
        },
        deleteLeagueTeam: (league_pagename, team_pagename) => {
            dispatch( deleteLeagueTeam(league_pagename, team_pagename))
        },
        searchMember: ( member_username ) =>{
            dispatch( searchMembersLeague( member_username ) );
        },
        searchMemberWithValidation: ( member_username, actual_members ) =>{
            dispatch( searchMembersLeagueWithValidation( member_username, actual_members ) );
        },
        searchTeam: ( searchteam ) => {
            dispatch( searchTeamsLeague( searchteam ) );
        },
        searchTeamWithValidation: ( searchteam, actual_teams ) => {
            dispatch( searchTeamsLeagueWithValidation( searchteam, actual_teams ) );
        },
        changeLeaguesAddMemberToLeagueModalStateAction: ( new_modal_state ) => {            
            dispatch( changeLeaguesAddMemberToLeagueModalStateAction( new_modal_state ) )
        },
        changeLeaguesAddTeamToLeagueModalStateAction: ( new_modal_state ) => {
            dispatch( changeLeaguesAddTeamToLeagueModalStateAction( new_modal_state ) )
        },
        addMemberToLeague: (league_page_name, new_member) =>{
            dispatch ( addMemberToLeague( league_page_name, new_member.username) )
        },
        addTeamToLeague: ( league_page_name, new_team ) => {
            dispatch ( addTeamToLeague( league_page_name, new_team.pagename ) )
        },
        // changeEndTournamentModalStateAction: ( new_modal_state ) => {
        //     dispatch( changeEndTournamentModalStateAction( new_modal_state ) )
        // }, 
        loadMatchReport: ( match_id ) => {
            dispatch( loadMatchReport( match_id ) )
        },       
        // changeEndTournamentModalStateAction: ( new_modal_state ) => {
        //     dispatch( changeEndTournamentModalStateAction( new_modal_state ) )
        // }, 
               
        loadLeagueTeams: (leage_name) => {            
            dispatch( loadLeagueTeams(leage_name) )
        },    
        loadLeagueMembers: (leage_name) => {            
            dispatch( loadLeagueMembers(leage_name) )
        },
        setStaffMemeberPermissions: ( member_username, pagename, roles ) => {            
            dispatch( setStaffMemeberPermissions(member_username, pagename, roles) )
        },    
    }
}

export default compose(withTranslation("league"), connect(mapStateToProps, mapDispatchToProps))(MemberView);