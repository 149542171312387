// React
import React from 'react';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './CardIdViewerComponent.styles.css';

//Assests
import cardIdH from 'assets/images/cardIdH.png';
import cardIdV from 'assets/images/cardIdV.png';

function CardIdViewerComponent ( props ) {

    const getImageData = (imagePath, imageB64Data)=> {
        if(imagePath && imageB64Data){
            let imageUrlArray = imagePath.split(".");
            let imageExtension = imageUrlArray[imageUrlArray.length -1];
            return 'data:image/'+ imageExtension + ';base64,'+ imageB64Data;
        }else{
            return '';
        }
    }
    
    const changePixelsToPts=( pixels )=>{
        let pts =(pixels / 8) * 6;
        return `${pts}pt`;
    }
    
    return(

        <div className="card-id-viewer__content">  

                    <div className={props.orientation === 'horizontal'? 'card-id-viewer__horizontal-card' : 'card-id-viewer__vertical-card'}>
                        <img src={props.template_image? getImageData(props.template_image.file_document, props.template_image.parent_data) : props.orientation === 'horizontal'? cardIdH : cardIdV } alt=""/>
                        {props.downloadCardIdData?.map(ply => 
                            ply.code && ply.code === 'file'?
                                <img style={ {position: 'absolute', left: changePixelsToPts(ply.x), top: changePixelsToPts(ply.y), width: ply.width, height: ply.height, 
                                    borderTopLeftRadius: ply.shape === 'round'? '50%': '0', borderTopRightRadius: ply.shape === 'round'? '50%': '0', borderBottomRightRadius: ply.shape === 'round'? '50%': '0', borderBottomLeftRadius: ply.shape === 'round'? '50%': '0',
                                        objectFit: 'cover'}} 
                                    //src={`https://api.allorigins.win/raw?url=${encodeURIComponent(ply.value)}`}
                                    //src={getImageData(ply.value, ply.data)}
                                    src={ply.data}
                                    alt=""
                                />   
                                :                                        
                                <div style={ {position: 'absolute', 
                                    textAlign: 'initial',
                                    width: 'auto',
                                    textTransform: 'capitalize',
                                    left: changePixelsToPts(ply.x), 
                                    top: changePixelsToPts(ply.y), color: ply.color, fontSize: ply.fontSize, fontFamily: ply.fontFamily}}
                                >                                        
                                    {ply.code === 'date'? moment(ply.value).format("DD MMM YYYY") : ply.value.toLowerCase()}
                                </div>                                    
                        )}
                        
                    </div>

        </div> 
    )
}

export default CardIdViewerComponent;
