/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';

// Styles
// import help_icon from 'assets/icons/help-resume-icon.svg';
import './TournamentBasicDataInfoComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Moment
import * as moment from 'moment';
import i18n from 'i18next';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';


function TournamentBasicDataInfoComponent(props) {

    const renderDate = (dateText) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateText);
        return date.toLocaleDateString((moment.locale(i18n.language)), options);
    };

    return (
        <div class="basic-data-info__data__info__container">
            <div className='basic-data-info__data__title__container'>
                <p className='baloa-subtitle-2'>{props.t("options.tournaments.pages.newTournament.tabs.tabTournamentPhases.forms.formPhaseConfigure1.labelPhaseSummary")}</p>
            </div>
            <div className="basic-data-info__data__info__container_center">
                <div className={'basic-data-info-report-resume-left-col'}>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTournamentName')}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.name}</p>
                    </div> */}
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelCategory")}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.category}</p>
                    </div>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelGameDays")}</p>
                        <p className={'baloa-names'}>-</p>
                    </div> */}
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTypeRegistration")}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.tournament_registration_type}</p>
                    </div> */}
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <div className={'basic-data-info-report-resume-item-text-icon'}>
                            <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTotalTeam")}</p>
                            <img src={help_icon} alt="" />
                        </div>
                        <p className={'baloa-names'}>{props.selected_tournament.registration_price_per_team}</p>
                    </div> */}
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTournamentStart")}</p>
                        <p className={'baloa-names'}>{renderDate(props.selected_tournament.start_date)}</p>
                    </div>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelNumberTeams")}</p>
                        <p className={'baloa-names'}></p>
                    </div> */}
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelCountry')}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.main_country}</p>
                    </div>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelPrize")}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.tournament_prize_type}</p>
                    </div> */}
                </div>
                <div className={'basic-data-info-report-resume-right-col'}>
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelGender")}</p>
                        <p className={'baloa-names'}>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.selected_tournament.tournament_gender}`)}</p>
                    </div>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelPossibleGame")}</p>
                        <p className={'baloa-names'}>-</p>
                    </div> */}
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelRegistrationDead")}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.registration_deadline}</p>
                    </div> */}
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <div className={'basic-data-info-report-resume-item-text-icon'}>
                            <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelTotalPlayers")}</p>
                            <img src={help_icon} alt="" />
                        </div>
                        <p className={'baloa-names'}>{props.selected_tournament.registration_price_per_player}</p>
                    </div> */}
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelEndTournament")}</p>
                        <p className={'baloa-names'}>{renderDate(props.selected_tournament.end_date)}</p>
                    </div>
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelCity')}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.main_city}</p>
                    </div>
                    {/* <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t("options.tournaments.pages.newTournament.tabs.labelsSummary.labelNumberPlayerFTeam")}</p>
                        <p className={'baloa-names'}>-</p>
                    </div> */}
                    <div className={'basic-data-info-report-resume-item-text'}>
                        <p className={'baloa-table'}>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelDescription')}</p>
                        <p className={'baloa-names'}>{props.selected_tournament.description}</p>
                    </div>
                </div>

            </div>
            {props.selected_tournament.state !== 'Finalized' &&
                <div className="basic-data-info__data__info__container_right">
                    <SecondaryButtonComponent
                        onClick={() => { props.handleOnEditTournamentInfo() }}
                        type="button"
                        disabled={props.selected_tournament.state == "Finalized" || !permissionsController(props.pageRoles, ['TOURNAMENTAD'])}
                    >
                        <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelEditBasicDataBtn')}</span>
                    </SecondaryButtonComponent>
                </div>
            }
        </div>
    )
}

export default withTranslation('league')(TournamentBasicDataInfoComponent);