// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-player-info__container .baloa-hyperlink {
    color: var(--primary-very-dark-color);
    margin-bottom: var(--space-quarter);
}

.tournament-player-info__container .baloa-table {
    color: var(--basic-gray-color);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mCAAmC;AACvC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".tournament-player-info__container .baloa-hyperlink {\n    color: var(--primary-very-dark-color);\n    margin-bottom: var(--space-quarter);\n}\n\n.tournament-player-info__container .baloa-table {\n    color: var(--basic-gray-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
