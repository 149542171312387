import React, { useState, useEffect } from 'react';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';

import './ProfilePostVideoClipsOptionsModalComponent.styles.css';
//i18
import { withTranslation } from 'react-i18next';

function ProfilePostVideoClipsOptionsModalComponent(props) {
    const [switchOptions, setSwitchOptions] = useState(false);

    const [editOptionLabel, setEditOptionLabel] = useState('...');
    const [deleteOptionLabel, setDeleteOptionLabel] = useState('...');
    const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState('...');


    const setOptionsLabels = (type_modal_video_clip) => {
        switch (type_modal_video_clip) {
            case "presentationVideoClip":{
                setEditOptionLabel(props.t('addVideoClip.modals.labelEditOptionMenu'));
                setDeleteOptionLabel(props.t('addVideoClip.modals.labelDeleteOptionVideoPresentation'));
                setDeleteConfirmationMessage(props.t('addVideoClip.modals.labelSubtitleDeleteVideoPresentation'));
                return;
            }
            case "featuredVideoClip":{
                setEditOptionLabel(props.t('addVideoClip.modals.labelEditClipOptionMenu'));
                setDeleteOptionLabel(props.t('addVideoClip.modals.labelDeleteOptionVideoFeatured'));
                setDeleteConfirmationMessage(props.t('addVideoClip.modals.labelSubtitleDeleteVideoFeatured'));
                return;
            }

            case "matchVideoClip":{//revieew option name
                setEditOptionLabel(props.t('addVideoClip.modals.labelEditMatchVideoOptionMenu'));
                setDeleteOptionLabel(props.t('addVideoClip.modals.labelDeleteMatchVideoOptionMenu'));
                setDeleteConfirmationMessage(props.t('addVideoClip.modals.labelSubtitleDeleteMatchVideo'));
                return;
            }
            
            // no default
        }
    }

    useEffect(() => {
        setOptionsLabels(props.type_modal_video_clip);
    }, [props.type_modal_video_clip]);

    const handleDownload = async () => {
        try {
            const response = await fetch(props.post?.post_video);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'baloa_featured_video.mp4'; // Nombre del archivo que se descargará
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the video:', error);
        }
    };

    return (
        <ModalComponent
            isOpen={props.isOpen}
            className={"profile-post-video-options-modal"}
        >
            <div className="profile-post-video-options_list">
                {switchOptions
                    ? <React.Fragment>
                        <div className='profile-post-video-option_delete_subtitle baloa-names'>
                            {deleteConfirmationMessage}
                        </div>
                        <div className="profile-post-video-options_list_delete_button baloa-names"
                            onClick={() => props.handleDeleteVideoClip(props.post.id)}>
                            {props.t('addVideoClip.modals.labelDeleteOptionMenu')}
                        </div>
                    </React.Fragment>
                    : <React.Fragment>
                        <div className="profile-post-video-options_list_button baloa-names" onClick={handleDownload}>
                            {props.t('addVideoClip.modals.labelDownloadOptionMenu')}
                        </div>
                        <div className="profile-post-video-options_list_button baloa-names"
                            onClick={() => props.handleEditActionVideoClip(`edit${props.type_modal_video_clip}`)}>
                            {editOptionLabel}
                        </div>
                        <div className="profile-post-video-options_list_delete_button baloa-names"
                            onClick={() => setSwitchOptions(true)}>
                            {deleteOptionLabel}
                        </div>
                    </React.Fragment>
                }
                <div className="baloa-names profile-post-video-options_list_cancel_button"
                    onClick={() => {
                        props.onClose();
                        setSwitchOptions(false);
                    }} >
                    {props.t('addVideoClip.modals.labelCancelOptionMenu')}
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('profile')(ProfilePostVideoClipsOptionsModalComponent);