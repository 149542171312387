// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.unlock-stats__container .modal__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: var(--space-and-half);
    max-width: 490px;
}

.modal.unlock-stats__container .modal__container .hubicon-info {
    font-size: 45px;
    color: var(--primary-dark-color);
}

.modal.unlock-stats__container .modal__container .baloa-headline-2 {
    margin-top: var(--space-x2);
}

.modal.unlock-stats__container .modal__container .baloa-names {
    color: var(--primary-very-dark-color);
    margin-top: 27px;
    padding: 0 20px;
}

.unlock-stats__container .button {
    margin: var(--space-and-half) 20px 0 20px;
    width: -webkit-fill-available;
}

.unlock-stats__header {
    display: flex;
    align-items: center;
    width: 100%;
}

.unlock-stats__header__close_icon {
    color: #1C1B1F;
    font-size: 24px;
    font-weight: var(--font-medium);
    cursor: pointer;
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/unlock-stats-modal/UnlockStatsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yCAAyC;IACzC,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".modal.unlock-stats__container .modal__container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    padding: var(--space-and-half);\n    max-width: 490px;\n}\n\n.modal.unlock-stats__container .modal__container .hubicon-info {\n    font-size: 45px;\n    color: var(--primary-dark-color);\n}\n\n.modal.unlock-stats__container .modal__container .baloa-headline-2 {\n    margin-top: var(--space-x2);\n}\n\n.modal.unlock-stats__container .modal__container .baloa-names {\n    color: var(--primary-very-dark-color);\n    margin-top: 27px;\n    padding: 0 20px;\n}\n\n.unlock-stats__container .button {\n    margin: var(--space-and-half) 20px 0 20px;\n    width: -webkit-fill-available;\n}\n\n.unlock-stats__header {\n    display: flex;\n    align-items: center;\n    width: 100%;\n}\n\n.unlock-stats__header__close_icon {\n    color: #1C1B1F;\n    font-size: 24px;\n    font-weight: var(--font-medium);\n    cursor: pointer;\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
