// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-start-tournament-modal__container .modal2__container {
    max-width: 400px;
}

.tournament-start-tournament-modal__container .modal2__container .modal2__content{
    padding: var( --space );
}
.tournament-start-tournament-modal__actions .secondary-button {
    margin-right: var( --space-half-quarter );
}

.tournament-start-tournament-modal__container .baloa-table {
    color: var( --primary-dark-color );
    margin: var( --space-and-half ) 0 var( --space-x2 ) 0;
}

.tournament-start-tournament-modal__container .baloa-normal-medium {
    margin: var( --space-and-half ) 0 var( --space-x2 ) 0;
}

.tournament-start-tournament-modal__actions .primary-button,
.tournament-start-tournament-modal__actions .secondary-button {
    font-size: var( --space );
    padding: var( --space-half ) var( --space-and-half );
}

.tournament-start-tournament-modal__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-start-tournament-modal/TournamentStartTournamentModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,yCAAyC;AAC7C;;AAEA;IACI,kCAAkC;IAClC,qDAAqD;AACzD;;AAEA;IACI,qDAAqD;AACzD;;AAEA;;IAEI,yBAAyB;IACzB,oDAAoD;AACxD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;;AAE7B","sourcesContent":[".tournament-start-tournament-modal__container .modal2__container {\n    max-width: 400px;\n}\n\n.tournament-start-tournament-modal__container .modal2__container .modal2__content{\n    padding: var( --space );\n}\n.tournament-start-tournament-modal__actions .secondary-button {\n    margin-right: var( --space-half-quarter );\n}\n\n.tournament-start-tournament-modal__container .baloa-table {\n    color: var( --primary-dark-color );\n    margin: var( --space-and-half ) 0 var( --space-x2 ) 0;\n}\n\n.tournament-start-tournament-modal__container .baloa-normal-medium {\n    margin: var( --space-and-half ) 0 var( --space-x2 ) 0;\n}\n\n.tournament-start-tournament-modal__actions .primary-button,\n.tournament-start-tournament-modal__actions .secondary-button {\n    font-size: var( --space );\n    padding: var( --space-half ) var( --space-and-half );\n}\n\n.tournament-start-tournament-modal__actions {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
