// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-actions {
    padding: 16px;
    display: flex;    
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.player-actions__action {
    margin-bottom: 20px;
    align-self: flex-start;
    display: flex;
    align-items: center;
}
.player-actions__action.baloa-names{
    color: var(--primary-very-dark-color);
}

.player-actions__action:last-child {
    margin-bottom: 0;
}

.player-actions__action:hover {
    cursor: pointer;
}

.player-actions-icon{
    font-size: 22px;    
    color: var(--primary-very-dark-color);
    margin-right: 8px;
}

.player-actions__action__icon-edit {
    cursor: pointer;
    width: 22px;
    height: 22px;
    margin-right: 8px;
    padding: 2px;
}

.player-actions__action__icon-edit path {
    fill: var( --primary-very-dark-color );
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-team-players-actions/PlayersActionComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,sBAAsB;AAC1B;AACA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,qCAAqC;AACzC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".player-actions {\n    padding: 16px;\n    display: flex;    \n    align-items: center;\n    justify-content: flex-start;\n    flex-direction: column;\n}\n.player-actions__action {\n    margin-bottom: 20px;\n    align-self: flex-start;\n    display: flex;\n    align-items: center;\n}\n.player-actions__action.baloa-names{\n    color: var(--primary-very-dark-color);\n}\n\n.player-actions__action:last-child {\n    margin-bottom: 0;\n}\n\n.player-actions__action:hover {\n    cursor: pointer;\n}\n\n.player-actions-icon{\n    font-size: 22px;    \n    color: var(--primary-very-dark-color);\n    margin-right: 8px;\n}\n\n.player-actions__action__icon-edit {\n    cursor: pointer;\n    width: 22px;\n    height: 22px;\n    margin-right: 8px;\n    padding: 2px;\n}\n\n.player-actions__action__icon-edit path {\n    fill: var( --primary-very-dark-color );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
