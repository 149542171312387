
// Attributes state
const lineups_state = {
    constants: [],
    alignments: []
}

export default function LineUpsReducer( state = lineups_state, action ) {
    
    switch ( action.type ) {

        case "LINEUPS:LOAD_CONSTANTS": {

            return { ...state, constants: action.constants.formation }

        }

        case "LINEUPS:LOAD_ALIGNMENTS": {

            return { ...state, alignments: action.alignments }

        }

        // no default

    }

    return state;
}