// React
import React, { Component } from 'react'

// Assets
import delete_account_info from 'assets/images/delete-account-info.png';

// Styles
import './AccountDeleteInfoView.styles.css';

class AccountDeleteInfoView extends Component {        
    render() {        
        return(
            <div className="account-delete-info__container">
                <img src={delete_account_info} alt="delete account info img" />
            </div>
        )
    }
};

export default AccountDeleteInfoView;
