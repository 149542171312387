// Axios
import Axios from "config/axiosConfig";

// Variables
import { api_url } from "config/GlobalData";



export const GetUserMeApi = () => {
    return Axios.instance.get(`${api_url}/users/me/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}
export const PatchUserApi = ( user_data ) => {
    //return Axios.instance.put(`${api_url}/users/me/`,
    return Axios.instance.patch(`${api_url}/users/me/?version=2`,
    user_data,    
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        },        
    });
}

export const GetTermsApi = () => {
    return Axios.instance.get(`${api_url}/private-terms/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export const PatchUserDataWithPhotoApi = ( user_data ) => {
    return Axios.instance.patch( `${ api_url }/users/me/`,
    user_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const updateNoRegisterUserDataActionApi = ( user_data, configUpload ) => {

    return Axios.instance.patch( `${ api_url }/users-get-preregister/me/`,
        user_data,
        {
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
            onUploadProgress: configUpload,
        }    
    );    
}