
// i18next 
import i18n from 'i18next';

export default function convertLateralityCode( laterality_code ) {
    let laterality_code_readable;

    switch( laterality_code ) {

        case 'right':
            return i18n.t('profile:apt.lateralCode.right'); //'Derecho';

        case 'left':
            return i18n.t('profile:apt.lateralCode.left'); //'Izquierdo';
        
            case 'both-legs':
                return i18n.t('profile:apt.lateralCode.bothLegs'); //'Ambas piernas';    

        default:
            break

    }

    return laterality_code_readable;
}