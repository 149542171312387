// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.finish_free_phase_subtitle {
    margin-top: var(--space-and-half);
    margin-left: calc( var(--space) + var(--space-quarter) );
}

.teams_list li {
    list-style-type: none;
    padding: var(--space) 0;
    border-bottom: solid 1px var(--mid-gray-color);
    width: 60%;
}

.teams_list li span {
    width: var(--space);
    height: var(--space);
    color: #00C256;
    margin-right: var(--space-half);
}

.finish_free_phase_footer {
    display: flex;
    height: calc( var(--space-x3) * 2 );
    justify-content: flex-end;
    background-color: var(--white-color);
    align-items: center;
    
}

.finish_free_phase_btn {
    height: calc( var(--space-x2) + var(--space-half) );
    min-width: calc( var(--space-x3) * 3 );
    grid-template-columns: none; 
    grid-template-areas: none;
    margin-right: var(--space-and-half);    
}

.finish_free_phase_footer a{
    height: calc( var(--space-x2) + var(--space-half) );
    min-width: calc( var(--space-x3) * 3 );
    grid-template-columns: none; 
    grid-template-areas: none;

}

`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-warning-no-teams-in-league-modal/TournamentWarningNoTeamsLeagueModalComponent.styles.css"],"names":[],"mappings":";;AAEA;IACI,iCAAiC;IACjC,wDAAwD;AAC5D;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,8CAA8C;IAC9C,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,cAAc;IACd,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,mCAAmC;IACnC,yBAAyB;IACzB,oCAAoC;IACpC,mBAAmB;;AAEvB;;AAEA;IACI,mDAAmD;IACnD,sCAAsC;IACtC,2BAA2B;IAC3B,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;IACI,mDAAmD;IACnD,sCAAsC;IACtC,2BAA2B;IAC3B,yBAAyB;;AAE7B","sourcesContent":["\n\n.finish_free_phase_subtitle {\n    margin-top: var(--space-and-half);\n    margin-left: calc( var(--space) + var(--space-quarter) );\n}\n\n.teams_list li {\n    list-style-type: none;\n    padding: var(--space) 0;\n    border-bottom: solid 1px var(--mid-gray-color);\n    width: 60%;\n}\n\n.teams_list li span {\n    width: var(--space);\n    height: var(--space);\n    color: #00C256;\n    margin-right: var(--space-half);\n}\n\n.finish_free_phase_footer {\n    display: flex;\n    height: calc( var(--space-x3) * 2 );\n    justify-content: flex-end;\n    background-color: var(--white-color);\n    align-items: center;\n    \n}\n\n.finish_free_phase_btn {\n    height: calc( var(--space-x2) + var(--space-half) );\n    min-width: calc( var(--space-x3) * 3 );\n    grid-template-columns: none; \n    grid-template-areas: none;\n    margin-right: var(--space-and-half);    \n}\n\n.finish_free_phase_footer a{\n    height: calc( var(--space-x2) + var(--space-half) );\n    min-width: calc( var(--space-x3) * 3 );\n    grid-template-columns: none; \n    grid-template-areas: none;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
