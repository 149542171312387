// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.modal2__container {
    width: 70%;
}*/

.modal-team-info-button-actions {
    padding: 15px;
    background-color: white;
}

.modal-team-info-button-actions Button {
    margin: 0 10px;
    border-radius: 2px;
}

.del-member-btn {
    border-color: #FF515B !important;
}

.send-msg-btn {
    border-color: #00E063 !important;
}

.view-profile-btn {
    background-color: #00E063 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-team-info-modal/LeagueTeamInfoModalComponent.styles.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["/*.modal2__container {\n    width: 70%;\n}*/\n\n.modal-team-info-button-actions {\n    padding: 15px;\n    background-color: white;\n}\n\n.modal-team-info-button-actions Button {\n    margin: 0 10px;\n    border-radius: 2px;\n}\n\n.del-member-btn {\n    border-color: #FF515B !important;\n}\n\n.send-msg-btn {\n    border-color: #00E063 !important;\n}\n\n.view-profile-btn {\n    background-color: #00E063 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
