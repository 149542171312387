// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-match-scorer-form__form__actions {
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: var(--space-and-half);
}
.league-match-scorer-form__form__instruction {
    margin-left: var( --space-x3 );
    margin-right: var( --space-x3 );
    color: black !important;
}

.league-match-scorer-form__actions__back-btn {
    border: none;
    color: var( --primary-dark-color );
    margin-right: var( --space-and-half );
    text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/league-match-scorer-form/LeagueMatchScorerFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,8BAA8B;AAClC;AACA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,kCAAkC;IAClC,qCAAqC;IACrC,0BAA0B;AAC9B","sourcesContent":[".league-match-scorer-form__form__actions {\n    background: var(--white-color);\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    padding: var(--space-and-half);\n}\n.league-match-scorer-form__form__instruction {\n    margin-left: var( --space-x3 );\n    margin-right: var( --space-x3 );\n    color: black !important;\n}\n\n.league-match-scorer-form__actions__back-btn {\n    border: none;\n    color: var( --primary-dark-color );\n    margin-right: var( --space-and-half );\n    text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
