// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_id_delete_validation_modal .modal__container {
    padding: 50px 0 8px 0;
    max-width: 400px;
}

.card_id_delete_validation_modal__title {
    margin: 0 18px;
}

.card_id_delete_validation_modal__subtitle {
    margin: 21px 18px;
}

.card_id_delete_validation_modal_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--new-input-background-color);
    padding: 9px 14px 0 14px;
}

.card_id_delete_validation_modal_btn.red{
    background-color: var(--error-color);
    margin-left: var(--space-and-half);
    font-size: 15px;
    padding: 8px 20px;
    line-height: initial;
}

.card_id_delete_validation_modal_btn.gray{
    border: 2px solid var(--basic-gray-color);;
    color: var(--basic-gray-color);
    font-size: 15px;
    padding: 8px 20px;
    line-height: initial;
}

.card_id_delete_validation_modal_btn.gray:hover {
    background-color: var(--basic-gray-color);
    color: var(--white-color);
}

.card_id_delete_validation_modal .baloa-normal{
    color: var(--primary-very-dark-color);
}

.card_id_delete_validation_modal .baloa-table{
    color: var(--primary-dark-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-card-id-delete-validation-modal/TournamentCardIdDeleteValidationModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uDAAuD;IACvD,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;IACpC,kCAAkC;IAClC,eAAe;IACf,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,yCAAyC;IACzC,8BAA8B;IAC9B,eAAe;IACf,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;AAC7B;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".card_id_delete_validation_modal .modal__container {\n    padding: 50px 0 8px 0;\n    max-width: 400px;\n}\n\n.card_id_delete_validation_modal__title {\n    margin: 0 18px;\n}\n\n.card_id_delete_validation_modal__subtitle {\n    margin: 21px 18px;\n}\n\n.card_id_delete_validation_modal_footer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    border-top: 1px solid var(--new-input-background-color);\n    padding: 9px 14px 0 14px;\n}\n\n.card_id_delete_validation_modal_btn.red{\n    background-color: var(--error-color);\n    margin-left: var(--space-and-half);\n    font-size: 15px;\n    padding: 8px 20px;\n    line-height: initial;\n}\n\n.card_id_delete_validation_modal_btn.gray{\n    border: 2px solid var(--basic-gray-color);;\n    color: var(--basic-gray-color);\n    font-size: 15px;\n    padding: 8px 20px;\n    line-height: initial;\n}\n\n.card_id_delete_validation_modal_btn.gray:hover {\n    background-color: var(--basic-gray-color);\n    color: var(--white-color);\n}\n\n.card_id_delete_validation_modal .baloa-normal{\n    color: var(--primary-very-dark-color);\n}\n\n.card_id_delete_validation_modal .baloa-table{\n    color: var(--primary-dark-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
