import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font, PDFDownloadLink  } from '@react-pdf/renderer';

//Components
import Modal2Component from 'shared/components/modal2/Modal2Component';

// Moment
import * as moment from 'moment';
// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

//IsMobile
import { isMobile } from 'react-device-detect';

//assets
import cardIdH from 'assets/images/cardIdH.png';
import cardIdV from 'assets/images/cardIdV.png';

//Fonts
//import robotoRegular from "assets/fonts/Roboto-Regular.ttf";
//import robotoRegular from "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf";
import libreBaskervilleRegular from "assets/fonts/LibreBaskerville-Regular.ttf";
import openSansRegular from "assets/fonts/OpenSans-Regular.ttf";
import poppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import quicksandRegular from "assets/fonts/Quicksand-Regular.ttf";

//Styles
import './TournamentCardIdPdfGeneratorModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function TournamentCardIdPdfGeneratorModalComponent(props) {
    //Register available fonts
    //Font.register({ family: 'Roboto', fonts: [ {src: robotoRegular} ] } );
    Font.register({ family: 'Roboto', fonts: [ {src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf"} ] } );
    Font.register({ family: 'LibreBaskerville', fonts: [ {src: libreBaskervilleRegular} ] } );
    Font.register({ family: 'OpenSans', fonts: [ {src: openSansRegular} ] } );
    Font.register({ family: 'Poppins', fonts: [ {src: poppinsRegular} ] } );
    Font.register({ family: 'Quicksand', fonts: [ {src: quicksandRegular} ] } );

    const [cardsData, setCardsData] = React.useState([]);

    React.useEffect(() => { 
        setCardsData(props.downloadCardIdData);
     }, [props.downloadCardIdData]);// eslint-disable-line

    const changePixelsToPts=( pixels )=>{
        let pts =(pixels / 8) * 6;
        return `${pts}pt`;
    }
    /* const changePixelsToCms=( pixels )=>{
        //76dpi
        let cms =(pixels / 76) * 2.54;
        return `${cms}cm`;
    } */

    const getTextWidth=(maxWidth,startPos)=> {
        let newStart = changePixelsToPts(startPos);//Se convierte coordenada X en pts
        let newStartPos = newStart.substring(0,newStart?.length - 2); //Se elimina el 'pt' del string 
        let newWidth = maxWidth - newStartPos; //se obtiene el maximo width segun la posiscion inicial (coordenada x)
        return newWidth;
    }

    const stylesHorizontal = StyleSheet.create({
        page: { backgroundColor: 'transparent', paddingTop: 20, paddingLeft: 120 },
        section: { width: '318pt', height: '176.25pt', position: 'relative', 
        marginBottom: 20}
    });

    const stylesVertical = StyleSheet.create({
        page: { backgroundColor: 'transparent', paddingTop: 40, paddingLeft: 80, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', textTransform: 'lowercase' },
        section: { width: '205.50pt', height: '290.55pt', position: 'relative', 
        marginBottom: 20, marginRight: 20}
    });

    /* const getImageData = (imagePath, imageB64Data)=> {
        let imageUrlArray = imagePath?.split(".");
        let imageExtension = imageUrlArray[imageUrlArray?.length -1];
        return 'data:image/'+ imageExtension + ';base64,'+ imageB64Data;
    } */
    
    const FilesCarnet = () => {
        return (            
            <PDFViewer style={{width: '100%', height: 600}}>
                <Document style={{display: 'flex', flexFlow: 'column'}}>
                    <Page size="LETTER" style={props.orientation === 'horizontal'? stylesHorizontal.page : stylesVertical.page} >
                        {cardsData?.map((player, i) =>
                            <View style={props.orientation === 'horizontal'? stylesHorizontal.section : stylesVertical.section} wrap={false} key={i}>
                                <Image src={props.template_image? /* getImageData(props.template_image.file_document, props.template_image.parent_data) */props.template_image.file_document : props.orientation === 'horizontal'? cardIdH : cardIdV } 
                                    style={ {position: 'relative', width: props.orientation === 'horizontal'? '318pt' : '205.50pt', height: props.orientation === 'horizontal'? '176.25pt' : '290.55pt'}} 
                                />
                                {player?.map(ply => 
                                    ply.code && ply.code === 'file'?
                                        ply.data?
                                            <Image
                                                //src={ply.data}
                                                src={ply.value}
                                                style={ {position: 'absolute', left: changePixelsToPts(ply.x), top: changePixelsToPts(ply.y), width: ply.width, height: ply.height, 
                                                borderTopLeftRadius: ply.shape === 'round'? '50%': '0', borderTopRightRadius: ply.shape === 'round'? '50%': '0', borderBottomRightRadius: ply.shape === 'round'? '50%': '0', borderBottomLeftRadius: ply.shape === 'round'? '50%': '0',
                                                    objectFit: 'cover', transform: 'rotate(0)' }} 
                                                //src={`https://api.allorigins.win/raw?url=${encodeURIComponent(ply.value)}`}
                                            />
                                            :
                                            ''   
                                        :                                        
                                        <Text style={ {position: 'absolute',
                                            maxLines: 2, 
                                            textAlign: 'unset',
                                            textTransform: 'capitalize',
                                            width: getTextWidth(props.orientation === 'horizontal'? '318' : '205.50', ply.x),
                                            left: changePixelsToPts(ply.x), 
                                            top: changePixelsToPts(ply.y), color: ply.color, fontSize: ply.fontSize, fontFamily: ply.fontFamily}}
                                        >                                        
                                            {ply.code === 'date'? moment(ply.value).format("DD MMM YYYY") : ply.value.toLowerCase()}
                                        </Text>                                    
                                )}
                                
                            </View>
                        )
                        }
                    </Page>
                </Document>
            </PDFViewer>            
        );
    };

    const FilesCarnetMobile = () => {
        return (
            <Document style={{display: 'flex', flexFlow: 'column'}}>
                <Page size="LETTER" style={props.orientation === 'horizontal'? stylesHorizontal.page : stylesVertical.page} >
                    {cardsData?.map((player, i) =>
                        <View style={props.orientation === 'horizontal'? stylesHorizontal.section : stylesVertical.section} wrap={false} key={i}>
                            <Image src={props.template_image? /* getImageData(props.template_image.file_document, props.template_image.parent_data) */props.template_image.file_document : props.orientation === 'horizontal'? cardIdH : cardIdV } 
                                style={ {position: 'relative', width: props.orientation === 'horizontal'? '318pt' : '205.50pt', height: props.orientation === 'horizontal'? '176.25pt' : '290.55pt'}} 
                            />
                            {player?.map(ply => 
                                ply.code && ply.code === 'file'?
                                    <Image style={ {position: 'absolute', left: changePixelsToPts(ply.x), top: changePixelsToPts(ply.y), width: ply.width, height: ply.height, 
                                        borderTopLeftRadius: ply.shape === 'round'? '50%': '0', borderTopRightRadius: ply.shape === 'round'? '50%': '0', borderBottomRightRadius: ply.shape === 'round'? '50%': '0', borderBottomLeftRadius: ply.shape === 'round'? '50%': '0', 
                                        objectFit: 'cover', transform: 'rotate(0)'}} 
                                        //src={`https://api.allorigins.win/raw?url=${encodeURIComponent(ply.value)}`}
                                        //src={getImageData(ply.value, ply.data)}
                                        src={ply.value}
                                    />   
                                    :                                        
                                    <Text style={ {position: 'absolute',
                                        maxLines: 2, 
                                        textAlign: 'unset',
                                        textTransform: 'capitalize',
                                        width: getTextWidth(props.orientation === 'horizontal'? '318' : '205.50', ply.x),
                                        left: changePixelsToPts(ply.x),
                                        top: changePixelsToPts(ply.y), color: ply.color, fontSize: ply.fontSize, fontFamily: ply.fontFamily}}
                                    >                                        
                                        {ply.code === 'date'? moment(ply.value).format("DD MMM YYYY") : ply.value.toLowerCase()}
                                    </Text>                                    
                            )}
                            
                        </View>
                    )
                    }
                </Page>
            </Document>        
        );
    };

    return (
            <Modal2Component
                isOpen={props.isOpen}
                onClose={()=>{ props.onClose()}}
                title={props.title}
                className='card-id-pdf-generator-modal'
            >
                {isMobile &&
                    <div className='card-id-pdf-generator-modal__mobile-options'>
                        <PDFDownloadLink document={<FilesCarnetMobile />} fileName="card_ids.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 
                                    'Loading document...' 
                                : 
                                <div className='tournament-team-members-modal_download_template' >
                                    <span className='hubicon-id_card'></span>
                                    <div className='tournament-team-members-modal_download_template_text baloa-table-column'>
                                        {props.t('options.tournaments.cardIds.buttonOptions.downloadBtnLbl')}
                                    </div>
                                </div>
                            }
                        </PDFDownloadLink>
                    </div>
                }
                {!isMobile &&
                    <FilesCarnet />
                }
                
            </Modal2Component>

        )
}

export default withTranslation('league')(TournamentCardIdPdfGeneratorModalComponent);