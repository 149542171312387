// React
import React from 'react';
import PropTypes from 'prop-types';

// Shapes
import { NotificationShape } from 'app/notifications/shapes/NotificationShape';

// Styles
import './NotificationsPopoverComponent.styles.css';

// Components
import NotificationPopoverItemComponent from 'app/notifications/components/notification-popover-item/NotificationPopoverItemComponent';

//i18
import { withTranslation } from 'react-i18next';


// Notifications Popover Component:
//   Renders Notifications and handles basic actions.

function NotificationsPopoverComponent( props ) {

    return (
        <div className={ "notifications-popover " + ( props.className ? props.className : '' ) }>
            <p className="notifications-popover__title baloa-subtitle-2">{props.t('notifications')}</p>
            { props.notifications && props.notifications.map( notification =>
                <NotificationPopoverItemComponent
                    key = { notification.id }
                    notification = { notification } 
                    className = "notifications-popover__notification"
                    handleOnProfileClick = {props.handleOnProfileClick}
                    setReadNotification = {props.setReadNotification}
                    me={props.me}
                />
            ) }
            { !props.notifications && <p>{props.t('notificationsEmptyState')}</p> }
        </div>
    )
}

// Types
NotificationsPopoverComponent.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape( NotificationShape )
    ),
}

// Exports

export default withTranslation('dashboard')(NotificationsPopoverComponent)
