import React from 'react';
// Components
import LeagueTournamentGeneralListComponent from '../league-tournament-general-list/LeagueTournamentGeneralListComponent';
// Material UI
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';


//i18
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect'
// Styles
import './LeagueTournamentComponent.styles.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {/*Active Tournaments */}
            {value === 0 && (
                <React.Fragment>
                    {props.league_tournaments_is_loading &&
                        <Skeleton
                            animation="wave"
                            variant="rect"
                            component="div"
                            height={300}
                            style={{ marginTop: 30 }}
                        />
                    }
                    {props.active_tournaments &&
                        <LeagueTournamentGeneralListComponent
                            active_tournaments={props.active_tournaments}
                            number_iteration={props.number_iteration}
                            searchbyword={props.searchbyword}
                            onResetError={props.onResetError}
                            errors={props.errors}
                            handleBackButton={props.handleBackButton}
                            tournament_tag={props.tournament_tag}
                            league_page={props.league_page}
                            handleOnTournamentActionClick={props.handleOnTournamentActionClick}
                            handleFilterTournament={props.handleFilterTournament}
                            tournamentHub={props.tournamentHub}
                            filterType={props.filterType}
                            league_teams_is_loading = {props.league_teams_is_loading}
                            showFilter={props.showFilter}
                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            tournament_admin_view={props.tournament_admin_view}
                        />
                    }
                </React.Fragment>
            )}

            {/* Finish Tournament */}
            {value === 1 && (
                <React.Fragment>
                    {props.league_tournaments_is_loading &&
                        <Skeleton
                            animation="wave"
                            variant="rect"
                            component="div"
                            height={300}
                            style={{ marginTop: 30 }}
                        />
                    }
                    {!props.league_tournaments_is_loading && props.completed_tournaments &&
                        <LeagueTournamentGeneralListComponent
                            active_tournaments={props.completed_tournaments}
                            number_iteration={props.number_iteration}
                            searchbyword={props.searchbyword}
                            onResetError={props.onResetError}
                            errors={props.errors}
                            handleBackButton={props.handleBackButton}
                            tournament_tag={props.tournament_tag}
                            league_page={props.league_page}
                            handleOnTournamentActionClick={props.handleOnTournamentActionClick}
                            handleFilterTournament={props.handleFilterTournament}
                            tournamentHub={props.tournamentHub}
                            filterType={props.filterType}
                            league_teams_is_loading = {props.league_teams_is_loading}
                            showFilter={props.showFilter}
                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                            tournament_admin_view={props.tournament_admin_view}
                        />
                    }
                </React.Fragment>
            )}
        </div>
    );

}

function LeagueTournamentComponent(props) {
    const [tabSelected, setTabSelected] = React.useState(0);

    return (
        <div className="league-tournament__container">
           
            {!isMobile 
                ? (props.tournamentHub || !props.tournamentTile)
                    ? ''
                    //  <p className="league-tournament_title baloa-subtitle-2">{props.t('options.tournaments.labelTournamentManage')}</p>
                    : <p className="league-tournament_title baloa-subtitle-2">{props.tournamentTile}</p>
                : ''
            }
            {!props.tournamentHub &&
            /* <Tabs
                variant="fullWidth"
                className="league-tournament__container__tabs"
                value={tabSelected}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, new_value) => { setTabSelected(new_value) }}
                aria-label="Selection between "
            >
                
                <Tab label={props.t('options.tournaments.tabs.tabActives.labelTitle')} />
                {!props.tournamentHub &&
                    <Tab label={props.t('options.tournaments.tabs.tabEnded.labelTitle')} />
                }
            </Tabs> */
            <div className='league-tournament__tags'>
                <Chip
                    className={`league-tournament__tag ${tabSelected === 0? 'selected':''} baloa-table`}
                    label={props.t('options.tournaments.tabs.tabActives.labelTitle')}
                    onClick={() => { setTabSelected(0) }}
                />
                <Chip
                    className={`league-tournament__tag ${tabSelected === 1? 'selected':''} baloa-table`}
                    label={props.t('options.tournaments.tabs.tabEnded.labelTitle')}
                    onClick={() => { setTabSelected(1) }}
                />
            </div>
            }

            {/* Active Tournaments  */}
            <TabPanel
                index={0}
                value={tabSelected}
                active_tournaments={props.active_tournaments}
                handleOnTournamentActionClick={props.handleOnActiveTournamentActionClick}
                onResetError={props.onResetError}
                errors={props.errors}
                t={props.t}
                number_iteration={6}
                handleBackButton={props.handleBackButton}
                tournament_tag={props.tournament_tag}
                searchbyword={'name'}
                league_page={props.league_page}
                league_tournaments_is_loading={props.league_tournaments_is_loading}
                handleFilterTournament={props.handleFilterTournament}
                tournamentHub={props.tournamentHub}
                filterType={props.filterType}
                league_teams_is_loading = {props.league_teams_is_loading}
                showFilter={props.showFilter}
                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                tournament_admin_view={props.tournament_admin_view}
            />
            {/* Completed Tournaments */}
            <TabPanel
                index={1}
                value={tabSelected}
                completed_tournaments={props.completed_tournaments}
                handleOnTournamentActionClick={props.handleOnCompletedTournamentActionClick}
                onResetError={props.onResetError}
                errors={props.errors}
                t={props.t}
                number_iteration={6}
                handleBackButton={props.handleBackButton}
                tournament_tag={props.tournament_tag}
                searchbyword={'name'}
                league_page={props.league_page}
                league_tournaments_is_loading={props.league_tournaments_is_loading}
                handleFilterTournament={props.handleFilterTournament}
                tournamentHub={props.tournamentHub}
                filterType={props.filterType}
                league_teams_is_loading = {props.league_teams_is_loading}
                showFilter={props.showFilter}
                handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                tournament_admin_view={props.tournament_admin_view}
            />
        </div>
    );
}

export default withTranslation('league')(LeagueTournamentComponent)
