// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-external-stat-info-modal .modal__container {
    width: 420px;
    padding: 0;
}

.delete-external-stat-info-modal__info.baloa-names {
    text-align: center;
    padding: 10px 16px;
    margin: 16px 0;
    color: var(--primary-very-dark-color);
}

.delete-external-stat-info-modal__action-btn {
    border-top: 1px solid var(--new-input-background-color);
    padding: 4px 0;
}

.delete-external-stat-info-modal__action-btn.red .text-button {
    color: var(--error-color);
}

.delete-external-stat-info-modal__action-btn .text-button {
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-delete-external-stats-info-modal/ProfileDeleteExternalStatsInfoModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,qCAAqC;AACzC;;AAEA;IACI,uDAAuD;IACvD,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".delete-external-stat-info-modal .modal__container {\n    width: 420px;\n    padding: 0;\n}\n\n.delete-external-stat-info-modal__info.baloa-names {\n    text-align: center;\n    padding: 10px 16px;\n    margin: 16px 0;\n    color: var(--primary-very-dark-color);\n}\n\n.delete-external-stat-info-modal__action-btn {\n    border-top: 1px solid var(--new-input-background-color);\n    padding: 4px 0;\n}\n\n.delete-external-stat-info-modal__action-btn.red .text-button {\n    color: var(--error-color);\n}\n\n.delete-external-stat-info-modal__action-btn .text-button {\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
