import React, { useState, useEffect } from 'react';

//Components
import FormComponent from 'shared/components/form/FormComponent';
import TournamentFilterItemComponent from '../tournament-filter-state/TournamentFilterItemComponent';
import TournamentHubGeneralFilter from '../tournament-general-filter/TournamentGeneralFliterComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//i18
import { withTranslation } from 'react-i18next';

//Styles
import './TournamentHubFilterComponent.styles.css';
import filterIcon from '../../../../assets/icons/filter-tournament-icon.svg';

function TournamentHubFilterComponent(props) {

    const FORM_INITIAL_VALUES = {
        tournament_state: [],
        tournament_gender: [],
        tournament_category: [],
        tournament_type_soccer: [],
        tournament_main_city: []
    }
    const FILTER_TYPES = [
        { value: 'generalFilter', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelGeneralFilter'), filterCounter: 0 },
        { value: 'state', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelState'), filterCounter: 0 },
        { value: 'gender', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelGenre'), filterCounter: 0 },
        { value: 'category', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelCatergory'), filterCounter: 0 },
        { value: 'footballType', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelFootballType'), filterCounter: 0 },
        { value: 'ubication', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelUbication'), filterCounter: 0 },
    ];

    const TOURNAMENT_GENDER = [
        { value: 'Female', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Female') },
        { value: 'Male', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Male') },
        { value: 'Free', content: props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.Free') },
    ];

    const STATES_TOURNAMENT = [
        { value: 'Scheduled', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelStateActive'), checked: false },
        { value: 'Registration_Open', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelStateRegistrationOpen'), checked: false },
        { value: 'Finalized', content: props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelStateFinalized'), checked: false },
    ]

    const [listOfFilter, setListOfFilter] = useState(FILTER_TYPES); // eslint-disable-line
    const [boxPosition, setBoxPosition] = useState(false);
    const [isBoxOpen, setBoxOpen] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [listState, setListState] = useState(STATES_TOURNAMENT);
    const [listGenders, setListGenres] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listTypeSoccer, setListTypeSoccer] = useState([]);
    const [listUbication, setListUbication] = useState([]);

    useEffect(() => {
        if (props.word_to_filter?.length <= 0) {
            setBoxPosition(false);
            setBoxOpen(false)
            setFilterType('');
        }
    }, [props.word_to_filter]);

    useEffect(() => {
        if (props.tournament_subsection === 'registration_open') {
            listState.find((state) => state.value === 'Registration_Open').checked = true;
            handleChangesFilterCounters();
        }

        if (props.tournament_subsection === 'in_progress') {
            listState.find((state) => state.value === 'Scheduled').checked = true;
            handleChangesFilterCounters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tournament_subsection]);


    const handleBoxOpen = (filterType) => {
        setFilterType(filterType);
        if (!isBoxOpen) {
            setBoxPosition(filterType.value)
            setBoxOpen(true)
        } else {
            setBoxPosition(false);
            setBoxOpen(false)
            setFilterType('');
        }
    };

    const handleFilterButtonsFormat = ({ newFormats, typeFilter }) => {
        switch (typeFilter) {
            case 'tournament_gender':
                setListGenres(newFormats);
                break;
            case 'tournament_category':
                setListCategory(newFormats);
                break;
            case 'tournament_type_soccer':
                setListTypeSoccer(newFormats);
                break;
            case 'tournament_ubication':
                setListUbication(newFormats);
                break;
            case 'tournament_state':
                if (listState.findIndex((state) => state.value == newFormats.value) >= 0) {
                    listState.find((state) => state.value === newFormats.value).checked = newFormats.checked
                }
                break;
            default: setListGenres([]);
        }
    }

    const handleChangesFilterCounters = () => {
        const totalNumberFilter = listGenders.length + listCategory.length + listTypeSoccer.length + listUbication.length;
        listOfFilter.find((filter) => filter.value === 'generalFilter').filterCounter = totalNumberFilter;
        listOfFilter.find((filter) => filter.value === 'state').filterCounter = listState.filter(state => state.checked === true)?.length;
        listOfFilter.find((filter) => filter.value === 'gender').filterCounter = listGenders.length;
        listOfFilter.find((filter) => filter.value === 'category').filterCounter = listCategory.length;
        listOfFilter.find((filter) => filter.value === 'footballType').filterCounter = listTypeSoccer.length;
        listOfFilter.find((filter) => filter.value === 'ubication').filterCounter = listUbication.length;
    }

    const handleSubmitForm = (filterData) => {
        filterData.tournament_category = listCategory;
        filterData.tournament_gender = listGenders;
        filterData.tournament_type_soccer = listTypeSoccer;

        handleChangesFilterCounters();

        const filterParams = {
            gender: reformatDataArray(listGenders),
            category: reformatDataArray(listCategory),
            football_type: reformatDataArray(listTypeSoccer),
            state: reformatDataArray(listState?.map(state => {
                if (state.checked === true) return state.value
                else return undefined
            }).filter(value => value !== undefined)),
            main_city: reformatDataArray(listUbication?.map(city => {
                return city.name;
            })),
        }
        props.handleFilterSearchTournament(filterParams);
    };

    const reformatDataArray = (array) => {
        let queryParams = '';
        if (array.length > 0) {
            queryParams += array.map((filter) => {
                return `${filter}|`;
            });
        }
        return queryParams.replace(/(,)/gm, "").slice(0, -1);
    }

    const clearFilterStatus = (filterType) => {
        let totalNumberFilter = 0
        let filterParams = {
            gender: reformatDataArray(listGenders),
            category: reformatDataArray(listCategory),
            football_type: reformatDataArray(listTypeSoccer),
            state: '',
            main_city: reformatDataArray(listUbication?.map(city => {
                return city.name;
            })),
        };
        switch (filterType) {
            case 'gender':
                setListGenres([]);
                listOfFilter.find((filter) => filter.value === 'gender').filterCounter = 0;
                filterParams.gender = "";
                totalNumberFilter = listCategory.length + listTypeSoccer.length + listUbication.length;
                listOfFilter.find((filter) => filter.value === 'generalFilter').filterCounter = totalNumberFilter;
                break;
            case 'category':
                setListCategory([]);
                listOfFilter.find((filter) => filter.value === 'category').filterCounter = 0;
                filterParams.category = "";
                totalNumberFilter = listGenders.length + listTypeSoccer.length + listUbication.length;
                listOfFilter.find((filter) => filter.value === 'generalFilter').filterCounter = totalNumberFilter;
                break;
            case 'footballType':
                setListTypeSoccer([]);
                listOfFilter.find((filter) => filter.value === 'footballType').filterCounter = 0;
                filterParams.football_type = "";
                totalNumberFilter = listGenders.length + listCategory.length + listUbication.length;
                listOfFilter.find((filter) => filter.value === 'generalFilter').filterCounter = totalNumberFilter;
                break;
            case 'state':
                setListState(STATES_TOURNAMENT);
                listOfFilter.find((filter) => filter.value === 'state').filterCounter = 0;
                filterParams.state = "";
                break;
            case 'ubication':
                setListUbication([])
                listOfFilter.find((filter) => filter.value === 'ubication').filterCounter = 0;
                filterParams.main_city = "";
                totalNumberFilter = listGenders.length + listCategory.length + listTypeSoccer.length;
                listOfFilter.find((filter) => filter.value === 'generalFilter').filterCounter = totalNumberFilter;
                break;
            default: setListGenres("");
        }
        props.handleFilterSearchTournament(filterParams);
    }

    const loadTypeFilterComponent = (filterType, index) => {
        switch (filterType.value) {
            case 'state':
                return (
                    <TournamentFilterItemComponent
                        tournament_options={STATES_TOURNAMENT}
                        field_id={'tournament_state'}
                        filterType={'tournament_state'}
                        selectedFilter={listState}
                        tournament_subsection={props.tournament_subsection}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                    />);
            case 'gender':
                return (
                    <TournamentFilterItemComponent
                        tournament_options={TOURNAMENT_GENDER}
                        field_id={'tournament_gender'}
                        filterType={'tournament_gender'}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        selectedFilter={listGenders}
                    />
                );
            case 'category':
                return (
                    <TournamentFilterItemComponent
                        tournament_options={props.tournament_tag}
                        field_id={'tournament_category'}
                        filterType={'tournament_category'}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        selectedFilter={listCategory}
                    />
                );
            case 'footballType':
                return (
                    <TournamentFilterItemComponent
                        tournament_options={props.handleTranslateFootballTypes}
                        field_id={'tournament_type_soccer'}
                        filterType={'tournament_type_soccer'}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        selectedFilter={listTypeSoccer}
                    />
                );
            case 'ubication':
                return (
                    <TournamentFilterItemComponent
                        tournament_options={[]}
                        field_id={`tournament_filter_ubication_${index}`}
                        filterType={'tournament_ubication'}
                        handleFilterButtonsFormat={handleFilterButtonsFormat}
                        selectedCities={listUbication}
                        handleSearchWorldCities={props.handleSearchWorldCities}
                        world_cities={props.world_cities}
                    />
                );
            case 'generalFilter':
                return (<TournamentHubGeneralFilter
                    tournament_options_state={STATES_TOURNAMENT}
                    tournament_options_gender={TOURNAMENT_GENDER}
                    tournament_options_tag={props.tournament_tag}
                    tournament_options_FootballType={props.handleTranslateFootballTypes}
                    selected_filter_state={listState}
                    selected_filter_gender={listGenders}
                    selected_filter_category={listCategory}
                    selected_filter_footballType={listTypeSoccer}
                    selected_filter_ubication={listUbication}
                    tournament_subsection={props.tournament_subsection}
                    handleFilterButtonsFormat={handleFilterButtonsFormat}
                    world_cities={props.world_cities}
                    handleSearchWorldCities={props.handleSearchWorldCities}
                />);
            default: return (
                <div>Default General</div>
            )
        }
    }

    return (
        <div>
            {props.word_to_filter.length > 0 &&
                <div className='tournamentHub_filter_text baloa-normal-medium'>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelResults')} “{props.word_to_filter}”</div>
            }
            <div className={'tournamentHub_filter_buttons'}>
                {listOfFilter.map((filterItem, index) =>
                    <div className={`tournamentHub_all_filters_button_container ${filterItem.value}`} key={index}>
                        <div className={`tournamentHub_all_filters_buttons ${filterItem.filterCounter > 0 ? '_active' : ''}`}
                            key={index}>
                            <div key={index}
                                className={'tournamentHub_filter_button small'}
                                onClick={() => { handleBoxOpen(filterItem) }}>
                                <div className={'tournamentHub_filter_button_callsign'}>
                                    {filterItem.value === 'generalFilter' && <img src={filterIcon} alt={"filter_icon"} />}
                                    <span className='baloa-table'>{filterItem.content} </span>
                                    <span className='baloa-table number_circle'>{filterItem.filterCounter > 0 && filterItem.filterCounter}</span>
                                </div>
                            </div>
                            {filterItem.filterCounter === 0
                                ? filterItem.value !== 'generalFilter' && <span className="hubicon-expand_more" onClick={() => { handleBoxOpen(filterItem) }} />
                                : filterItem.value !== 'generalFilter' && <span className='icon-x baloa-names' onClick={() => clearFilterStatus(filterItem.value)} />
                            }
                        </div>
                        <div className={`dropdown-content ${boxPosition === filterItem.value ? '_active' : ''}`}>
                            <div className='dropdown-content_title'>
                                <p className='baloa-table'>{filterType?.content}</p>
                                <span className='icon-x baloa-names' onClick={() => {
                                    handleBoxOpen('');
                                }} />
                            </div>
                            <FormComponent
                                formInitialValues={FORM_INITIAL_VALUES}
                                onSubmit={(values) => { handleSubmitForm(values) }}
                                className=""
                                functionDisabled={() => { }}
                                enableReinitialize={false}
                            >
                                {/*  Render diferent filter section by component*/}
                                {boxPosition && loadTypeFilterComponent(filterType, index)}

                                <div className='tournament_filter_actions'>
                                    <SecondaryButtonComponent
                                        type={'button'}
                                        className="small"
                                        onClick={() => { handleBoxOpen('') }}>
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelCacelFilter')}</span>
                                    </SecondaryButtonComponent>&nbsp;
                                    <PrimaryButtonComponent
                                        className="small"
                                        type='submit'
                                        onClick={() => { handleBoxOpen(filterType) }}
                                    >
                                        <span>{props.t('options.tournaments.pages.newTournament.tabs.labelsFilter.labelApplyFilter')}</span>
                                    </PrimaryButtonComponent>
                                </div>
                            </FormComponent>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation('league')(TournamentHubFilterComponent);