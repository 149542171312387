
// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './LoginModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function LoginModalComponent( props ) {

    const[icon, setIcon] = React.useState('');
    const[modalTitle, setModalTitle] = React.useState('');
    const[modalText, setModalText] = React.useState('');
    const[urlRedirect, setUrlRedirect] = React.useState('');

    React.useEffect(() => {
        if(props.click_type === 'homeAnonComment') {
            setIcon('icon-message');
            setModalTitle('homeAnonCommentTitle');
            setModalText('homeAnonCommentText');
        } else if(props.click_type === 'homeAnonReaction') {
            setIcon('icon-claps-48');
            setModalTitle('homeAnonReactionTitle');
            setModalText('homeAnonReactionText');
        } else if(props.click_type === 'homeAnonFollowProfile') {
            setIcon('hubicon-person_add');
            setModalTitle('homeAnonFollowProfileTitle');
            setModalText('homeAnonFollowProfileText');
        } else if(props.click_type === 'homeAnonQuote') {
            setIcon('iconpq-quote');
            setModalTitle('homeAnonQuoteTitle');
            setModalText('homeAnonQuoteText');
        } else if(props.click_type === 'homeAnonProfile') {
            setIcon('icon-user');
            setModalTitle('homeAnonProfileTitle');
            setModalText('homeAnonProfileText');
        } else if(props.click_type === 'pages') {
            setIcon('');
            setModalTitle('homeAnonPagesTitle');
            setModalText('homeAnonPagesText');
        } else if(props.click_type === 'groups') {
            setIcon('');
            setModalTitle('homeAnonGroupsTitle');
            setModalText('homeAnonGroupsText');
        } else if(props.click_type === 'homeAnonTournament') {
            setIcon('icon-trophy');
            setModalTitle('homeAnonTournamentTitle');
            setModalText('homeAnonTournamentText');
        } else if(props.click_type === 'homeAnonCreateTeam') {
            setIcon('');
            setModalTitle('homeAnonCreateTeamTitle');
            setModalText('homeAnonCreateTeamText');
        } else if(props.click_type === 'homeAnonJoinTeam') {
            setIcon('');
            setModalTitle('homeAnonJoinTeamTitle');
            setModalText('homeAnonJoinTeamText');
            if(props.redirect_url){
                setUrlRedirect(props.redirect_url);
            }
        } else if(props.click_type === 'homeAnonSearchBar') {
            setIcon('icon-search');
            setModalTitle('homeAnonSearchBarTitle');
            setModalText('homeAnonSearchBarText');
        } else if(props.click_type === 'homeAnonPost') {
            setIcon('hubicon-add_option');
            setModalTitle('homeAnonPostTitle');
            setModalText('homeAnonPostText');
        } else if(props.click_type === 'homeAnonAuthor') {
            setIcon('author icon-user');
            setModalTitle('homeAnonAuthorTitle');
            setModalText('homeAnonAuthorText');
        }else if(props.click_type.split(':')[0] === 'homeAnonTournamentInscription') {
            setIcon('icon-trophy');
            setModalTitle( props.t('homeAnonTournamentInscriptionTitle', { _tournamentName: props.click_type.split(':')[1]}) );
            setModalText('homeAnonTournamentInscriptionText');
            if(props.redirect_url){
                setUrlRedirect(props.redirect_url);
            }            
        }else if(props.click_type === 'homeAnonTournamentfollow') {
            setIcon('icon-trophy');
            setModalTitle('homeAnonTournamentfollowTilte');
            setModalText('homeAnonTournamentfollowText');
        }else if(props.click_type === 'homeAnonStaffInvitation') {
            setIcon('icon-trophy staff-invitation');
            setModalTitle('homeAnonhomeAnonStaffInvitationTilte');
            setModalText('homeAnonhomeAnonStaffInvitationText');
            if(props.redirect_url){
                setUrlRedirect(props.redirect_url);
            }
        }else if(props.click_type === 'homeAnonExplore') {
            setIcon('hubicon-explore_2');
            setModalTitle('homeAnonExploreTitle');
            setModalText('homeAnonExploreText');
        }else if(props.click_type === 'homeAnonPlayer') {
            setIcon('hubicon-explore_2');
            setModalTitle('homeAnonPlayerTitle');
            setModalText('homeAnonPlayerText');
        }else {
            setModalTitle('');
            setModalText('');
            setIcon('');
        }
    },[props.click_type]); // eslint-disable-line
    
    return (
        <ModalComponent 
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <div className='login-modal__container' >
                { props.click_type === 'pages' &&
                    <span className="hubicon-pages">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                    </span>
                }
                { props.click_type === 'groups' &&
                    <span className="hubicon-groups">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    </span>
                }
                { props.click_type === 'homeAnonCreateTeam' &&
                    <span className="hubicon-create_team">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </span>
                }
                { props.click_type === 'homeAnonJoinTeam' &&
                    <span className="hubicon-join_team">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </span>
                }
                <span className={`login-modal__icon ${icon}`} />
                <div className="login-modal-title baloa-headline-2">{ modalTitle !== ''? props.t(`${modalTitle}`):  props.t('modalTitle')}</div>
                <div className="login-modal-text baloa-names">{modalText !== ''? props.t(`${modalText}`): props.t('modalText')}</div>
                <div className="login-modal-actions">
                    <Link to={"/cuenta/ingresar"+urlRedirect}>
                        <SecondaryButtonComponent onClick={ props.onClose }>
                            <span>{props.t('modalTextButton')}</span>
                        </SecondaryButtonComponent> 
                    </Link>
                </div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation('login')(LoginModalComponent);