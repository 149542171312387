// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const GetAttributesConstantsApi = () => {

    return Axios.instance.get( `${ api_url }/attributes/constants/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}

export const DeleteAttributeApi = ( attribute_data ) => {

    return Axios.instance.delete( `${ api_url }/attributes/${ attribute_data.id }/`, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
    
}

export const ValidateAttributeApi = ( attribute ) => {

    return Axios.instance.post( 
        `${ api_url }/attributes/${ attribute.id }/validate/`,
        {},
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}
