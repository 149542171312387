/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
// import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './TournamentTeamMembersModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Globals
import { api_url } from "config/GlobalData";

function TournamentTeamMembersModalComponent(props) {

    // Comment button to follow a player according to figma design Roster
    /*
    const handleFollowMember = (player) => {             
        if(player.is_following){
            let player_info = {
                username: player.username,
                is_following: false,
                player_id: player.tournament_player_id
            }
            props.unFollowPlayerTeam(player_info);
        } else {
            let player_info = {
                username: player.username,
                is_following: true,
                player_id: player.tournament_player_id
            }
            props.followPlayerTeam(player_info);
        }
    }
    */

    return (
        <Modal2Component
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="tournament-team-members-modal__team__cotainer scroll-parent"
            title={props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.columns.labelTeam')}
        >
            <div className='tournament-team-members-modal__team'>
                {props.selected_team?.team_logo
                    ?
                    <img src={props.selected_team?.team_logo} alt="" className="" />
                    :
                    <span className="icon-ball" color={getRandonIconBallColor()} />
                }
                <div className='baloa-names'>{props.selected_team?.team}</div>
                <div className='baloa-username'>@{props.selected_team?.teamname}</div>
                <div className='baloa-username'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.selected_team?.team_gender}`)} • {props.t(`options.tournaments.pages.newTournament.tabs.tabTeams.${props.selected_team?.category}`)}</div>
                {/* <SecondaryButtonComponent>{props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.btnFollow')}</SecondaryButtonComponent> */}

                {(props.selected_team?.is_tournament_admin || props.selected_team?.request_is_admin) &&
                    <div className='tournament-team-members-modal__download-buttons'>
                        <a href={`${api_url}/team/roster-pdf/${props.selected_team?.tournament_team_id}/`} target='_blank'
                            rel="noopener noreferrer" download
                            className='tournament-team-members-modal_download_template' >
                            <span className='hubicon-roster'></span>
                            <div className='tournament-team-members-modal_download_template_text baloa-table-column'>
                                {props.t('options.tournaments.pages.tournamentInfo.labelDownloadRoster')}
                            </div>
                        </a>
                        {(props.selected_team?.is_tournament_admin || props.selected_team?.request_is_admin) && props.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                        <div className='tournament-team-members-modal_download_template' onClick={()=>{ props.handleOnDownloadCardIds([props.selected_team.tournament_team_id]) }} >
                            <span className='hubicon-id_card'></span>
                            <div className='tournament-team-members-modal_download_template_text baloa-table-column'>
                                {props.t('options.tournaments.cardIds.buttonOptions.viewBtnLbl')}
                            </div>
                        </div>
                        } 
                    </div>
                }

            </div>
            <div className='baloa-table tournament-team-members-modal__members-title'></div>
            <div className='tournament-team-members-modal__members scroll'>
                {/** Staff */}
                {props.team_members.staff?.length > 0 &&
                    <div className='tournament-team-members-modal__members_staff_coach baloa-table'>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.coachStaffLabel')}
                    </div>
                }                
                {props.team_members.staff?.map((player, index) => {
                    return (
                        <div key={index} className='tournament-team-members-modal__container'>
                            <div className='tournament-team-members-modal'>
                                {player.photo
                                    ?
                                    <img src={player.photo} alt="" className="" />
                                    :
                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                }
                                <div className='tournament-team-members-modal__info'>
                                    <div className='baloa-table'>{player.full_name}</div>
                                    <div className='baloa-username'>@{player.username} • {player.team_role}</div>
                                </div>
                                {(props.team_members.is_team_admin || props.team_members.is_tournament_admin || (props.team_members?.request_is_staff && player.id === props.team_members?.team_profile_staff_id)) && player.form_state === 'Done' && props.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                                    <span className="hubicon-id_card" onClick={ ()=>{ props.handleOnDownloadStaffCardId(player.id, props.selected_team?.tournament_team_id ) }}/>
                                }
                            </div>
                            {/* <SecondaryButtonComponent onClick={() => handleFollowMember(player)} >
                                {!player.is_following
                                    ? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.btnFollow')
                                    : props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.btnUnfollow')}
                            </SecondaryButtonComponent> */}                            
                        </div>
                    )
                })}
                {/** Players */}
                {props.team_members.players?.length > 0 &&
                    <div className='tournament-team-members-modal__members_staff_coach baloa-table'>
                        {props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.playersLabel')}
                    </div>
                }
                {props.team_members.players?.map((player, index) => {
                    return (
                        <div key={index} className='tournament-team-members-modal__container'>
                            <div className='tournament-team-members-modal'>
                                {player.photo
                                    ?
                                    <img src={player.photo} alt="" className="" />
                                    :
                                    <span className="icon-ball" color={getRandonIconBallColor()} />
                                }
                                <div className='tournament-team-members-modal__info'>
                                    <div className='baloa-table'>{player.full_name}</div>
                                    <div className='baloa-username'>@{player.username} • {player.team_role}</div>
                                </div>
                                {(props.team_members.is_team_admin || props.team_members.is_tournament_admin || (props.team_members.request_is_tournament_player && player.tournament_team_player_id === props.selected_team?.request_tournament_player_id)) && player.form_state === 'Done' && player.tournament_team_player_id && props.carnet_id_template?.caption === 'CARD_ID_FORM' &&
                                    <span className="hubicon-id_card" onClick={ ()=>{ props.handleOnDownloadPlayerCardId(player.tournament_team_player_id) }}/>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </Modal2Component>
    )
}

export default withTranslation('league')(TournamentTeamMembersModalComponent);
