// React
import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import LeagueTournamentFilterComponent from '../league-tournament-filter/LeagueTournamentFilterComponent';
import HubTournamentCardComponent from 'app/tournaments/components/hub-tournament-card/HubTournamentCardComponent';

//import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import { withTranslation } from 'react-i18next';
//import calendarIcon from 'assets/icons/calendar-tournament-icon.svg';
//import ubicationIcon from 'assets/icons/ubication-tournament-icon.svg';
import arrowLeft from 'assets/icons/arrow-left-pag-icon.svg';
import arrowRigth from 'assets/icons/arrow-right-pag-icon.svg';

import { isMobile } from 'react-device-detect';

// Styles
import './LeagueTournamentGeneralListComponent.styles.css';

// Material UI
import Skeleton from '@mui/material/Skeleton';

function LeagueTournamentGeneralListComponent(props) {
    const { active_tournaments, searchbyword, number_iteration, showFilter } = props;
    const [wordToFind, setWordToFind] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [listToFilter, setListToFilter] = useState(active_tournaments);
    const [changeLink, setChangeLink] = useState(false);
    const [tournamentIdSelected, setTournamentIdSelected] = useState('');
    const [pageName, setPageName] = useState('');

    useMemo(() => setListToFilter(active_tournaments), [active_tournaments]); /* eslint-disable */

    React.useEffect(()=>{
        const containerList = document.getElementsByClassName('hub-tournament-card__tournament-info__name');
        //console.log('containerList',containerList);
        for (let i = 0; i < containerList.length; i++) {
            const container = containerList[i];
            const containerWidth = container.offsetWidth;
            //console.log('containerWidth',containerWidth);
            const text = container.textContent.trim();
            //console.log('text',text);
            const charWidth = parseInt(getComputedStyle(container).fontSize); // Ancho estimado de cada carácter
            //console.log('charWidth',charWidth);

            //const maxChars = Math.floor(containerWidth / charWidth); // Establece el número máximo de caracteres por línea
            const maxChars = 20;
            //console.log('maxChars',maxChars);
            const lineCount = 3; // Establece el número de líneas después del cual se agregarán los puntos suspensivos

            if (text.length > maxChars * lineCount) {
                let truncatedText = text.slice(0, maxChars * lineCount - 3) + '...'; // Trunca el texto en función del número de caracteres por línea y agrega los puntos suspensivos
                
                // Encuentra el último espacio en el texto truncado
                const lastSpaceIndex = truncatedText.lastIndexOf(' ');

                // Vuelve a truncar el texto en el último espacio
                truncatedText = truncatedText.slice(0, lastSpaceIndex);

                // Agrega los puntos suspensivos al final del texto truncado
                truncatedText += '...';

                container.textContent = truncatedText;
            }
        }
    },[listToFilter]);

    const handleSearchTable = word => {
        setCurrentPage(0);
        const currentFilter = word.toLowerCase();
        setWordToFind(word.toLowerCase());
        setListToFilter(
            active_tournaments.filter(function (tournament) {
                return tournament[searchbyword].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .includes(currentFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }).slice(0 * number_iteration, 0 * number_iteration + number_iteration));
    };

    const handleSwitchPage = page_number => {
        setCurrentPage(page_number - 1);
        wordToFind
            ?
            setListToFilter(
                active_tournaments?.filter(function (tournament) {
                    return tournament[searchbyword].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        .includes(wordToFind.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                }).slice((page_number - 1) * number_iteration, (page_number - 1) * number_iteration + number_iteration))
            :
            setListToFilter(
                active_tournaments.slice((page_number - 1) * number_iteration, (page_number - 1) * number_iteration + number_iteration))
    };

    const handleViewTournament = tournament => {
        if (props.tournamentHub) {
            setTournamentIdSelected(tournament.id);
            setPageName(tournament.league_page);
            setChangeLink(true);            
        } else props.handleOnTournamentActionClick(tournament);
    };

    if (changeLink) {
        return <Redirect to={{
            pathname: `/league/tournament/${pageName}`,
            state: { idTournament: tournamentIdSelected }
        }} />;
    }
    return (
        <React.Fragment>
            {active_tournaments &&
                <div>
                    <div className="league-tournament__search">
                        <div class="league-tournament__search-form__form" >
                            <span className="icon-search" />
                            <input className="league-tournament__search_input baloa-names"
                                type="text" placeholder={props.t('options.tournaments.tabs.tabActives.searchTournament')}
                                onChange={(event) => { handleSearchTable(event.target.value) }}></input>
                        </div>
                        {showFilter &&
                            <LeagueTournamentFilterComponent
                                t={props.t}
                                tournament_tag={props.tournament_tag}
                                onResetError={props.onResetError}
                                errors={props.errors}
                                filterType={props.filterType} // tournamentAll - tournamentSport - tournamentVideoGame
                                league_page={props.league_page}
                                handleBackButton={props.handleBackButton}
                                handleFilterTournament={props.handleFilterTournament}
                            />
                        }
                    </div>
                    {listToFilter.length > 0 && !props.league_teams_is_loading                    
                        ?
                        <React.Fragment>
                            {//!isMobile && 
                                <div className={`league-tournament-list__grid ${isMobile? 'scroll':'scroll'}`}>
                                    {listToFilter.slice(0, number_iteration).map((tournament, index) => (

                                        /* <div key={index} className="tab-panel_tournamentList_card" onClick={() => { handleViewTournament(tournament) }}>
                                            {tournament.tournament_logo
                                                ? <img src={tournament.tournament_logo} alt={`${''} img`} className={"tab-panel_tournamentList_img"} />
                                                : <span className={"icon-ball tab-panel_tournamentList_img"} color={getRandonIconBallColor()} />
                                            }
                                            <div className="tab-panel_tournamentList_card_inf">
                                                <div className="tab-panel_tournamentList_card_text">
                                                    <p className="baloa-names">{tournament.name}</p>
                                                    <div className="tab-panel_tournamentList_card_subText baloa-username">
                                                        <img src={calendarIcon} alt="" />&nbsp;
                                                        <p>{tournament.start_date}&nbsp;-&nbsp;{tournament.end_date}</p>
                                                        <img src={ubicationIcon} alt="" />&nbsp;
                                                        <p>{tournament.main_city}&nbsp;-&nbsp;{tournament.main_country}</p>
                                                    </div>
                                                </div>
                                                <div className="tab-panel_tournamentList_card_tags baloa-username">
                                                    <div className="tag-active">{props.t("tournament:viewTournament.tournamentType.Sport")}</div>
                                                    <div>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${tournament.tournament_gender}`)}</div>
                                                    <div>{tournament.category}</div>
                                                </div>
                                            </div>
                                        </div> */
                                        <HubTournamentCardComponent
                                            key={index}
                                            tournament={tournament}
                                            subsection={undefined}
                                            handleOnShareTournamentLinkClick={props.handleOnShareTournamentLinkClick}
                                            onEnterViewport={() => { }}
                                            is_tournamentList={true}
                                            handleOnViewTournament={ (tournament)=>{handleViewTournament(tournament)}}
                                            tournament_admin_view={props.tournament_admin_view}
                                        />
                                    ))}
                                </div>
                            }
                            {/* isMobile &&
                                listToFilter.slice(0, number_iteration).map((tournament, index) => (
                                    <div key={index} className="tab-panel_tournamentList_card" onClick={() => { handleViewTournament(tournament)} }>
                                        {tournament.tournament_logo === null
                                            ? <span className={"icon-ball tab-panel_tournamentList_img"} color={getRandonIconBallColor()} />
                                            : <img src={tournament.tournament_logo} alt={`${''} img`} className={"tab-panel_tournamentList_img"} />
                                        }
                                        <div className="tab-panel_tournamentList_card_inf">
                                            <div className="tab-panel_tournamentList_card_text">
                                                <p className="baloa-names">{tournament.name}</p>
                                                <div className="tab-panel_tournamentList_card_subText baloa-username">
                                                    <img src={calendarIcon} alt="" />&nbsp;
                                                    <p>{tournament.start_date}&nbsp;-&nbsp;{tournament.end_date}</p>
                                                    <img src={ubicationIcon} alt="" />&nbsp;
                                                    <p>{tournament.main_city}&nbsp;-&nbsp;{tournament.main_country}</p>
                                                </div>
                                            </div>
                                            <div className="tab-panel_tournamentList_card_tags baloa-username">
                                                <div className="tag-active">{props.t("tournament:viewTournament.tournamentType.Sport")}</div>
                                                <div>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${tournament.tournament_gender}`)}</div>
                                                <div>{tournament.category}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))   */                                  
                            }
                        </React.Fragment>
                        
                        :<React.Fragment>
                            <Skeleton animation="wave" variant="rect" component="div" height={500} style={{ marginTop: 5 }}/>
                        </React.Fragment> 
                    }
                    <PaginationList
                        onChangePage={handleSwitchPage}
                        count={active_tournaments.length}
                        listToFilter={listToFilter}
                        rowsPerPage={5}
                        page={currentPage}
                        newSearch={true}
                        // paginationByItem={true}
                    />
                </div>
            }
        </React.Fragment>
    );
};

const PaginationList = props => {
    const { count, rowsPerPage, onChangePage, listToFilter, paginationByItem } = props;
    const [countPage, setCountPage] = React.useState(0);

    React.useEffect(() => onChangePage(countPage + 1), [countPage]); /* eslint-disable */

    return (
        <React.Fragment>
            {!paginationByItem ?
                <div className={"pagination_list_options"}>
                    <div className={'pagination_list_options_action'}
                        onClick={() => (countPage !== 0) && setCountPage(countPage - 1)}>
                        <img src={arrowLeft} alt={"arrow-left"} />
                    </div>
                    <div className={'pagination_list_options_action active'}>{countPage + 1}</div>
                    {listToFilter.length > 0 &&
                        <div className={'pagination_list_options_action'}
                            onClick={() => ((countPage + 1) < (count / rowsPerPage)) && setCountPage(countPage + 1)}>
                            <img src={arrowRigth} alt={"arrow-right"} />
                        </div>
                    }
                </div>
                : <div className={"pagination_list_options"}>
                    <div className={'pagination_list_options_action active'}>{countPage + 1}</div>
                </div>}
        </React.Fragment>
    );
};

export default withTranslation(['league','tournament'])(LeagueTournamentGeneralListComponent)