// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.baloa-separator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.baloa-separator__line, .baloa-separator__center, .baloa-separator__elipse {
    position: absolute;
}

.baloa-separator__line {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/separator/SeparatorComponent.styles.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":["\n.baloa-separator {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n.baloa-separator__line, .baloa-separator__center, .baloa-separator__elipse {\n    position: absolute;\n}\n\n.baloa-separator__line {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
