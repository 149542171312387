// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament_phase_summary-container{
    border: 1px solid var(--new-gray-background-color);
    border-radius: 8px;
    margin: var(--space);
}

.tournament_phase_summary_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--new-gray-background-color);
    padding: var(--space);
}

.tournament_phase_summary_container_show_phases{
    padding: var(--space);
    display: flex;
    margin: var(--space) 0;
}

.tournament_phase_summary{
    margin-bottom: var(--space);
}

.tournament_phase_summary-container_sub_title{
    margin: var(--space) 0;
    padding: 0 var(--space);
}

.tournament_phase_summary__content__add{
    display: flex;
}

.tournament_phase_summary__content__add.phase-types,
.tournament-phases__phases__arrow.title .tournament-phases__phases__arrow_content_text {
    grid-column-start: 5;
    grid-column-end: 9;
    margin-left: -2%
}

.tournament_phase_summary_btn_create_phase{
    display: flex;
    align-items: center;
    gap: var(--space-half);
}`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-phase-summary/TournamentPhaseSummaryComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yDAAyD;IACzD,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,oBAAoB;IACpB,kBAAkB;IAClB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".tournament_phase_summary-container{\n    border: 1px solid var(--new-gray-background-color);\n    border-radius: 8px;\n    margin: var(--space);\n}\n\n.tournament_phase_summary_header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid var(--new-gray-background-color);\n    padding: var(--space);\n}\n\n.tournament_phase_summary_container_show_phases{\n    padding: var(--space);\n    display: flex;\n    margin: var(--space) 0;\n}\n\n.tournament_phase_summary{\n    margin-bottom: var(--space);\n}\n\n.tournament_phase_summary-container_sub_title{\n    margin: var(--space) 0;\n    padding: 0 var(--space);\n}\n\n.tournament_phase_summary__content__add{\n    display: flex;\n}\n\n.tournament_phase_summary__content__add.phase-types,\n.tournament-phases__phases__arrow.title .tournament-phases__phases__arrow_content_text {\n    grid-column-start: 5;\n    grid-column-end: 9;\n    margin-left: -2%\n}\n\n.tournament_phase_summary_btn_create_phase{\n    display: flex;\n    align-items: center;\n    gap: var(--space-half);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
