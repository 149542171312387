import React from 'react'

// Styles
import './ProfileTeamsModalComponent.style.css';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// import { RequiredValidator } from 'shared/validators/RequiredValidator';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

export const RenderProfileModalTeams = ({
    isOpen,
    onClosed,
    t,
    teamCode,
    teamsList,
    handleTeamCode,
    handleSave,
    onNewData,
    onDelete
}) => {
    return (
        <Modal2Component
            isOpen={isOpen}
            onClose={onClosed}
            title={t('playerTeams.addTeamsTitle')}
            className='render-profile-modal'
        >
            <div className="profile-add-team scroll-parent">
                <p className="profile-add-team__search_title baloa-names">
                    {t('playerTeams.searchByCodeLabel')}
                </p>
                <div className="profile-add-team__search">
                    <div className="profile-add-team__container ">
                        <input type="text" class="profile-add-team__search-input"
                            label={t('playerTeams.searchByCodeLabel')}
                            input_id="add-team-code"
                            field_name="input1"
                            value={teamCode}
                            onChange={handleTeamCode}
                            input_type="text"
                            placeholder=""
                        ></input>
                        <button className="profile-add-team__search-button baloa-table"
                            disabled={teamCode === ''}
                            onClick={() => onNewData(teamCode)}>
                            {t('playerTeams.addButton')}
                        </button>
                    </div>
                </div>
                <p className="profile-add-team__search_title baloa-names">{t('playerTeams.equipment_added')}({teamsList.length})</p>
                <div className="profile-add-team__search_items scroll">
                    {teamsList.map(({ id, name, teamname, logo }) =>
                        <div key={id} className="profile-add-team__team-space-and-half ">
                            <div className='profile-add-team__team-card'>
                                <div className="profile-add-team__card-photo">
                                    {logo
                                        ? <img src={logo} alt="" />
                                        : <span className={"icon-ball " + getRandonIconBallColor()} color={getRandonIconBallColor()}></span>
                                    }
                                </div>
                                <div className="profile-add-team__card-data">
                                    <div className="profile-add-team__content__title__text baloa-names profile-add-team-truncate">{name}</div>
                                    <div className="profile-add-team__content__title__text baloa-username profile-add-team-truncate">@{teamname}</div>
                                </div>
                            </div>
                            <div className="profile-add-team__card-delete" onClick={() => onDelete(teamname)}>
                                <span class="profile-span-remove-team icon-x " />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="add-team-form__form__actions form__actions">
                <PrimaryButtonComponent
                    type="submit"
                    // disabled={teamCode === ''}
                    onClick={handleSave}
                    className="add-team-form__form__submit"
                >
                    <span>{t('playerTeams.saveButton')}</span>
                </PrimaryButtonComponent>
            </div>
        </Modal2Component >
    )
}
