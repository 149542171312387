// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Shapes
import { ProfileShape } from 'app/profiles/shapes/ProfileShape';

// Components
import RoundedButtonComponent from 'shared/components/rounded-button/RoundedButtonComponent';

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Styles
import './PageSuggestionsComponent.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';


// ProfileSuggestions Component:
//   Renders Profile suggestions and handles their actions.

function PageSuggestionsComponent( props ) {

    return (
        <div 
        className = { 
            "page-suggestions " + ( props.className ? props.className : '' ) 
        }
        >
            { props.loading_page_suggestions &&
                <React.Fragment>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                    <Skeleton animation="wave" variant="rect" component="div" height={110} style={{ marginTop: 30 }}/>
                </React.Fragment>
            }
            {!props.loading_page_suggestions && 
            /* !props.loading_page_suggestions && props.pages.map( page => 
                <div 
                key = { page.id }
                className = "page-suggestions__suggestion"
                >
                    <Link to={`/page/${page.pagename}/`} className="page-suggestions__suggestion__page-info">
                        <div className={ "page-suggestions__suggestion__page-info__photo " + ( page.photo ? '' : 'no-photo' ) }>
                            <img src={ page.photo ? page.photo : baloa_user_icon } alt=""/>
                        </div>
                        <div className="page-suggestions__suggestion__page-info__data">
                            <p className="page-suggestions__suggestion__page-info__data__fullname baloa-truncate">
                                { page.name}
                            </p>
                            <p className="page-suggestions__suggestion__page-info__data__username baloa-truncate">
                                @{ page.pagename }
                            </p>
                        </div>
                    </Link>
                    <div className="page-suggestions__suggestion__follow-button">
                        <SecondaryButtonComponent
                            onClick = {() => { props.handleFollow( page ) }} 
                            className="small"
                        >
                            <span>{props.t('follower.request.button.follow')}</span>
                        </SecondaryButtonComponent>
                    </div>
                </div>
            ) */ 
                <div className="page-suggestions__container">
                    <div className="page-suggestions__title baloa-subtitle-2">{props.title}</div>   
                    <div className="page-suggestions__list">
                        {props.pages?.map( page => (
                                <div className="page-suggestions__list__page-item">
                                    { !props.is_home_anon_suggestions
                                        ?
                                            <Link to={`/page/${page.pagename}/`} className="page-suggestions__suggestion__page-info">
                                                {page.photo &&
                                                    <img src={ page.photo } alt=""/>
                                                }
                                                {!page.photo &&
                                                    <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                                }
                                                <div className="page-suggestions__list__page-item__data">
                                                    <div className="page-suggestions__list__page-item__data-name baloa-names baloa-truncate">{ page.name }</div>
                                                    <div className='page-suggestions__list__page-item__data-username baloa-username baloa-truncate'>@{ page.pagename }</div>
                                                </div>
                                            </Link>
                                        :
                                        <div className="page-suggestions__suggestion__page-info__anon-suggestions">
                                            {page.photo &&
                                                    <img src={ page.photo } alt=""/>
                                            }
                                            {!page.photo &&
                                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                            }                            
                                            <div className="page-suggestions__list__page-item__data" onClick={() => {props.handleAnonSuggestions()}}>
                                                <div className="page-suggestions__list__page-item__data-name baloa-names baloa-truncate">{ page.name }</div>
                                                <div className='page-suggestions__list__page-item__data-username baloa-username baloa-truncate'>@{ page.pagename }</div>
                                            </div>
                                        </div>
                                    }
                                    <div className="page-suggestions__suggestion__follow-button">
                                        <RoundedButtonComponent
                                            onClick = {() => { props.handleFollow( page ) }} 
                                            className="xs"
                                        >
                                            <span>{props.t('follower.request.button.follow')}</span>
                                        </RoundedButtonComponent>
                                    </div>
                                </div>
                            ))    
                        }                                        
                    </div>
                    <div className="page-suggestions__separator"></div>
                    <div className="page-suggestions__menu-link baloa-hyperlink-2" onClick={props.handleGoLink}>{props.go_link}</div> {/* TODO: falta modal */}
                </div>
            }
        </div>
    )
}

// Types
PageSuggestionsComponent.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape( ProfileShape )
    ),
    // onClick: PropTypes.func.isRequired,
}

// Exports

export default withTranslation('page')(PageSuggestionsComponent);

//export default PageSuggestionsComponent;
