// Action creators
import { 
    changeHomeGroupsSuggestionsLoadingAction,
    loadHomeGroupsSuggestionsAction,
    changeErrorMessageSnackBar,
    checkMemberRequestAction,
    setMemberGroupPagesAction,
} from './PageGroupsActionsCreators';

// Apis
import { MakeMemberRequestApi, 
    RejectMemberRequestApi, 
    CheckMemberRequestApi, 
    LeaveGroupApi, 
    AcceptMemberRequestApi, 
    GetMemberGroupPagesApi,
} from 'app/pages/apis/PageGroupsApi';

//Page Actions
import { loadPage, loadPageMembers } from './PageActions';

//Profile Apis
import { BlockProfileApi } from 'app/profiles/apis/ProfileApi';

//Home Apis
import { GetSuggestionsApi, GetUnauthenticatedSuggestionsApi } from 'app/home/apis/HomeApi';


// i18next 
import i18n from 'i18next';

export const loadGroupsSuggestions = () => ( dispatch ) => {

    dispatch( changeHomeGroupsSuggestionsLoadingAction( true ) );
    GetSuggestionsApi("group")
        .then( response => {
            dispatch( loadHomeGroupsSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomeGroupsSuggestionsLoadingAction( false ) );
        } );
}

export const makeMemberRequest = ( pagename ) => ( dispatch ) => {

    MakeMemberRequestApi( pagename )
        .then( response => {
            dispatch( loadPage( pagename ) );
            dispatch( loadPageMembers( pagename ) );
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:groups.snackBarMessages.memberRequest'),                    
                error_message_is_open: true
                } )
            );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}
export const checkMemberRequest = ( pagename ) => ( dispatch ) => {

    CheckMemberRequestApi( pagename )
        .then( response => {
            dispatch( checkMemberRequestAction( response ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}

export const  acceptMemberRequest  = ( member_request_id, username, pagename ) => ( dispatch ) => {

    AcceptMemberRequestApi( member_request_id )
        .then( response => {
            dispatch( loadPage( pagename ) );
            dispatch( loadPageMembers( pagename ) );
            dispatch( checkMemberRequest( pagename ) );
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:groups.snackBarMessages.accept', {_username: `${username}` }),                    
                error_message_is_open: true
                } )
            );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}

export const rejectMemberRequest = ( member_request_id, username, pagename, is_admin ) => ( dispatch ) => {

    RejectMemberRequestApi( member_request_id )
        .then( response => {
            dispatch( loadPage( pagename ) );
            if(is_admin){
                dispatch( checkMemberRequest( pagename ) );
            }            
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:groups.snackBarMessages.reject', {_username: `${username}` }),                    
                error_message_is_open: true
                } )
            );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}

export const leaveGroup= ( pagename, username ) => ( dispatch ) => {

    LeaveGroupApi( pagename, username )
        .then( response => {
            dispatch( loadPage( pagename ) );   
            dispatch( loadPageMembers( pagename ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}

export const kickoutGroupMember = ( username, pagename ) => ( dispatch ) => {

    BlockProfileApi( username )
        .then( response => {
            dispatch( loadPage( pagename ) );
            dispatch( loadPageMembers( pagename ) );
            dispatch(changeErrorMessageSnackBar( {
                message: i18n.t('page:groups.snackBarMessages.kickout', {_username: `${username}` }),                    
                error_message_is_open: true
                } )
            );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        });
}

export const loadMemberGroupPages = ( username ) => ( dispatch ) => {
    
    GetMemberGroupPagesApi( username )
        .then( response => {
            dispatch( setMemberGroupPagesAction( response ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        })        
}

export const loadUnauthenticatedGroupsSuggestions = () => ( dispatch ) => {

    dispatch( changeHomeGroupsSuggestionsLoadingAction( true ) );
    GetUnauthenticatedSuggestionsApi("group")
        .then( response => {
            dispatch( loadHomeGroupsSuggestionsAction( response.data ) );
        })
        .catch( error => {
            if(localStorage.getItem('token') != null){
                dispatch( changeErrorMessageSnackBar( {
                    message: i18n.t('page:groups.snackBarMessages.error'),
                    error_message_is_open: true
                } ) )
            }
        })
        .then( () => {
            dispatch( changeHomeGroupsSuggestionsLoadingAction( false ) );
        } );
}