import React, { useState, useEffect } from 'react';
//Components
import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';
import InputSearchComponent from 'shared/components/input-search/InputSearchComponent';
import LeagueSearchTeamListComponent from 'app/leagues/components/league-search-team-list/LeagueSearchTeamListComponent';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import { useTranslation } from 'react-i18next';
import './TournamentInviteTeamsComponent.styles.css';

const TournamentInviteTeamsComponent = (props) => {

    const { t } = useTranslation('league');
    const [league_teams, setLeagueTeams] = useState(); // eslint-disable-line
    const [validationTeams, setValidationTeams] = useState([]);
    const [add_item, setAddItem] = useState('');
    const [delete_item, setDeleteItem] = useState('');
    const [isInputFocus, setIsInputFocus] = useState('');
    const [isEmptySearch, setIsEmptySearch] = useState(true); // eslint-disable-line
    const [cleanValue, setCleanValue] = useState(false);

    const handleSearchListItemClick = (item) => {
        if (add_item !== item?.id) {
            if(props.modal_type_invited === 'inscription'){
                props.handleOnAddTeamToTournament({ ...item, state: 'Enrolled' });
            }else {
                props.handleOnAddTeamToTournament({ ...item, state: 'Invited' });
            }
            setAddItem(item.id);
        }
    };

    const handleListDeleteItemClick = (item) => {
        if (delete_item !== item?.id) {
            props.handleOnDeleteTeamFromTournament(item);
            setDeleteItem(item.id);
        }
    };

    const sendInvitationToTeam = (idTeamToInvite) => {
        if (!validationTeams.includes(idTeamToInvite)) {
            setValidationTeams((oldArray) => [...oldArray, idTeamToInvite]);
            // props.sendInvitationToTeamTournament({ id_tournament_team: idTeamToInvite, state_invite_team: 'Invited' })
        }
    };

    //Check functionality lol DisabledItem
    const handleListDisableItemClick = (event, item) => {
        props.handleDisableFunction(item)
    };

    useEffect(() => {
        let league_teams_filter = [];
        let remove_team = false;

        if (props.modalInvitation) return;
        if (props.tournament_teams) {
            props.tournament_teams.forEach((element) => {
                if (element.team_state !== 'Invited') {
                    sendInvitationToTeam(element.id);
                }
            });
        }
        if (props.baloaTeams) {
            props.baloaTeams.forEach((team, index) => {
                if (props.tournament_teams) {
                    props.tournament_teams.forEach((element, indexElement) => {
                        if (props.tournament_teams[indexElement].teamname === props.baloaTeams[index].teamname) {
                            remove_team = true;
                        }
                    });
                    if (!remove_team) {
                        league_teams_filter.push(props.baloaTeams[index]);
                    }
                    remove_team = false;
                }
            });
        }
        setLeagueTeams(league_teams_filter);
    }, [props]);// eslint-disable-line


    return (
        <div className={"invite_teams_container"}>
            {props.modal_type_invited === 'inscription'
                ? <div className={"invite_teams_container_text"}>
                    <p className={"baloa-table"}>{t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelSubTitleInscriptionTeam')}</p>
                </div>
                : <div className={"invite_teams_container_text"}>
                    <p className={"baloa-table"}>{t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelSubTitleInvitedTeam')}</p>
                </div>}
            <div className={"invite_teams_league_add_container"}>
                {props.is_team &&
                    <div className={"invite_teams_league_add_container_search"}>
                        <InputSearchComponent
                            placeholder={t('options.tournaments.pages.newTournament.tabs.tabTeams.searchPlaceholder')}
                            handleSearch={(v) => {
                                props.handleSearchBaloaTeam({ id: props.tournament?.id, teamName: v });
                                if (v !== '') {
                                    setIsEmptySearch(false);
                                    setCleanValue(false);
                                } else {
                                    setCleanValue(true);
                                    setIsEmptySearch(true);
                                    props.handleCleanMemberSearch();
                                    props.handleCleanBaloaTeamsSearch();
                                }
                            }}
                            autoFocus={isInputFocus}
                            cleanValue={cleanValue}
                            disabled={!props.tournament.id || !permissionsController(props.pageRoles, ['TOURNAMENTAD'])}
                        />
                        {props.baloaTeams.length !== 0 &&
                            <LeagueSearchTeamListComponent
                                items={league_teams}
                                onSelect={(selected_team) => {
                                    setIsInputFocus('true');
                                    handleSearchListItemClick(selected_team);
                                    props.handleCleanBaloaTeamsSearch();
                                    setCleanValue(true);
                                    setIsEmptySearch(true);
                                }}
                            />
                        }
                    </div>
                }
            </div>
            <div className={"invite_teams_league-addto__container scroll-parent"}>
                <div className="invite_teams_league-addto__container__title baloa-subtitle-2">
                    {props.modal_type_invited === 'inscription'
                    ? t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelRegisteredTeams')
                    
                    : t('options.tournaments.pages.newTournament.tabs.tabBasicData.labelTeamsInvited')}
                </div>
                <div className="invite_teams_league-addto__container-separator" />
                <ul className={"invite_teams_league-addto_list scroll"}>
                    {props.tournament_teams?.filter(team => team.team_state === `${props.modal_type_invited === 'inscription' ? 'Enrolled' : 'Invited'}`).map((item) => (
                        <li key={item.id} className={"invite_teams_league-addto_list-item " + (item.disabled ? "disabled-team" : '')}>
                            <div className={"invite_teams_league-addto__list-item_container"}>
                                {item.team_logo
                                    ?
                                    <img src={item.team_logo} alt={`${item.team} img`} className={"invite_teams_league-addto_list-item_img"} />
                                    :
                                    <span className={"icon-ball invite_teams_league-addto_list-item_img"} color={getRandonIconBallColor()} />
                                }
                                <div className="invite_teams_league-addto_list-item__info">
                                    <div className={`invite_teams_league-addto_list-item_text baloa-names ${item.disabled ? "disabled-team" : ''}`}> {props.itemType === "member" ? item.first_name + " " + item.last_name : item.team}</div>
                                    <div className={`invite_teams_league-addto_list-item_text baloa-username ${item.disabled ? "disabled-team" : ''}`} >
                                        {props.itemType === "member"
                                            ?
                                            <div>{`@${item.profile}`}</div>
                                            :
                                            <div>{t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${item.tournament_gender}`)}&nbsp;&bull;&nbsp;{`${item.category}`}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <TextButtonComponent
                                disabled={props.disabled_edit_tournament_data || item.disabled || !permissionsController(props.pageRoles, ['TOURNAMENTAD'])}
                                className="invite_teams_league-addto__delete-button baloa-hyperlink-2"
                                onClick={() => {
                                    if (props.eraseTeams) {
                                        handleListDeleteItemClick(item)
                                    } else {
                                        handleListDisableItemClick(item)
                                    }
                                }}
                                type={'button'}
                            >
                                {props.eraseTeams
                                    ?
                                    <span className="icon-x invite_teams_league-addto__delete-button__icon" />
                                    :
                                    <span>{t('options.tournaments.pages.newTournament.tabs.tabTeams.labelDisableButton')}</span>
                                }
                            </TextButtonComponent>
                        </li>
                    ))
                    }
                </ul>
            </div>

            <div className="tournament-invite-teams__actions">
                {/* <SecondaryButtonComponent
                    type="button"
                    id="tournament-invite-teams__back-btn"
                    onClick={() => { }}
                    disabled={true}
                >
                    <span>{t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelBackBtn')}</span>
                </SecondaryButtonComponent>
                <PrimaryButtonComponent
                    type="submit"
                    disabled={false}
                    onClick={() => props.handleOnFinishCreateNewTournament()}
                    className="tournament-create-new-tournament__submit-btn"
                >
                    <span>Finalizar</span>
                </PrimaryButtonComponent> */}
            </div>


        </div>
    );
};

export default TournamentInviteTeamsComponent;