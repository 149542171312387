// React
import React, { useState } from "react";

// Material UI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Components
import LeagueAddPlayersToTeamComponent from "app/leagues/components/league-add-players-to-team/LeagueAddPlayersToTeamComponent";
import LeagueEditTeamFormComponent from "app/leagues/components/league-edit-team-form-component/LeagueEditTeamFormComponent";
import LeagueBulkAddMembersComponentV2 from "app/leagues/components/league-bulk-add-members/LeagueBulkAddMembersComponentV2";

// Styles
import "./LeagueEditTeamComponent.styles.css";

//i18
import { withTranslation } from "react-i18next";
import PrimaryButtonComponent from "shared/components/primary-button/PrimaryButtonComponent";

function TabPanel(props) {
	const { children, value, index,  ...other } = props;


	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className="edit-team__tabpanel"
			{...other}
		>
			<div className="edit-team__tabpanel-line">
				{/* tab team data */}
				{value === index && index === 0 && (
					<LeagueEditTeamFormComponent
						errors={props.errors}
						team={props.team}
						team_categories={props.team_categories}
                  		team_genders={props.team_genders}
						onEditData={props.onEditData}
						onResetError={props.onResetError}
						handleOnUpdateActionClick={props.handleOnUpdateActionClick}
                  		submittedFromOutside={props.submittedFromOutside}
                  
						handleNameOnChange={props.handleNameOnChange}
						handleTeamGenderOnChange={props.handleTeamGenderOnChange}
						handleCategoryOnChange={props.handleCategoryOnChange}
						handleTeamImageOnChange={props.handleTeamImageOnChange}
						handleTeamImageB64OnChange={props.handleTeamImageB64OnChange}
					/>
				)}

				{/* tab members */}
				{value === index && index === 1 && (
					<React.Fragment>
						{!props.showBulk && (
							<LeagueAddPlayersToTeamComponent
								team={props.team}
								profiles={props.profiles}
								handleSearchMember={props.handleSearchMember}
								onDeletePlayerButton={props.onDeletePlayerButton}
								onSelectTeamPlayer={props.onSelectTeamPlayer}
								handleCleanMemberSearch={props.handleCleanMemberSearch}
								handleOnSaveButton={props.handleOnSaveButton}
								handleOnAddBulk={props.handleOnAddBulk}
								roles={props.roles}
								positions={props.positions}
								errors_member_rol={props.errors_member_rol}
								onSelectRolMember={props.onSelectRolMember}
							/>
						)}
						{props.showBulk && (
							<LeagueBulkAddMembersComponentV2
								handleOnSaveBulkButton={props.handleOnSaveBulkButton}
								handleOnUploadFile={props.handleOnUploadFile}
								bulkMembers={props.bulkMembers}
								handleOnBackBulk={props.handleOnBackBulk}
								cleanBulkMembers={props.cleanBulkMembers}
								uploadProgress={props.uploadProgress}
								cleanStatusBar={props.cleanStatusBar}
								updateBulkData={props.updateBulkData}
								handleOnDeleteMember={props.handleOnDeleteMember}
								handleOnMoreInfo={props.handleOnMoreInfo}
							/>
						)}
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

function LeagueEditTeamComponent(props) {

	const category = props.team?.category;
	//const category = props.team?.attributes[0]?.extra;

	const [tabSelected, setTabSelected] = useState(props.add_bulk_members? 1: 0);

   /*
   const [editTeamForm, setEditTeamForm] = useState({
		teamname: props.team.teamname,
		name: props.team.name,
		team_gender: props.team.team_gender,
		category: category?.value,      
      teamImage: props.team.photo,
      teamImageB64: null,
   })
   */

   let editTeamForm = {
		teamname: props.team.teamname,
		name: props.team.name,
		team_gender: props.team.team_gender,
		category: category?.value,      
   }

   let teamImage = null;
   let teamImageB64=  null;


   const handleNameOnChange = (value) => {            
      editTeamForm = {
         ...editTeamForm,
         name: value,
      }
   }      

   const handleTeamGenderOnChange = (value) => {
      editTeamForm = {
         ...editTeamForm,
         team_gender: value,
      };      
   }    

   const handleCategoryOnChange = (value) => {
      editTeamForm = {
         ...editTeamForm,
         category: value,
      };      
   }     

   const handleTeamImageOnChange = (value) => {
      teamImage= value;
   }    

   const handleTeamImageB64OnChange = (value) => {
         teamImageB64= value;
   }   


   const handleSubmit = () => {
      props.onEditData(editTeamForm, teamImage, teamImageB64);
      props.onCloseButton();
   }

	const onSaveBulkButton = () => {
		props.handleOnSaveBulkButton();
	};
	
	return (
		<div>
			<div className="edit-team__header baloa-headline-3">
				<div className="edit-team__header_header">
					<span
						className="icon-arrow-left show-team__icon-back"
						onClick={() => {
							props.onCloseButton();
						}}
					></span>
					{props.t("team.edit.editTitle")}
				</div>

				<PrimaryButtonComponent
					disabled={false}
					onClick={handleSubmit}
					className="edit-team-form__submit"
				>
					<span>{props.t("team.edit.forms.basicInfo.button")}</span>
				</PrimaryButtonComponent>            
			</div>


			<div className="edit-team__container">
				<Tabs
					variant="fullWidth"
					className="edit-team__center__content__tabs baloa-normal-medium"
					value={tabSelected}
					indicatorColor="primary"
					textColor="primary"
					onChange={(ev, new_value) => {
						setTabSelected(new_value);
					}}
					aria-label="Selection between "
				>
					<Tab label={props.t("team.edit.tabs.teamDataTabLabel")} />

					<Tab label={props.t("team.edit.tabs.memeberTabLabel")} />
				</Tabs>

				{/* tab team data */}
				<TabPanel
					index={0}
					value={tabSelected}
					team={props.team}
					errors={props.errors}
					onEditData={props.onEditData}
					onResetError={props.onResetError}
					team_categories={props.edit_team_categories}
               		team_genders={props.edit_team_genders}
					handleOnUpdateActionClick={props.handleOnUpdateActionClick}
					handleNameOnChange={handleNameOnChange}
					handleTeamGenderOnChange={handleTeamGenderOnChange}
					handleCategoryOnChange={handleCategoryOnChange}
					handleTeamImageOnChange={handleTeamImageOnChange}
					handleTeamImageB64OnChange={handleTeamImageB64OnChange}
				/>

				{/* tab members */}
				<TabPanel
					index={1}
					value={tabSelected}
					team={props.team}
					profiles={props.profiles}
					handleSearchMember={props.handleSearchMember}
					onDeletePlayerButton={props.onDeletePlayerButton}
					onSelectTeamPlayer={props.onSelectTeamPlayer}
					handleCleanMemberSearch={props.handleCleanMemberSearch}
					handleOnSaveButton={props.handleOnSaveButton}
					handleOnUploadFile={props.handleOnUploadFile}
					bulkMembers={props.bulkMembers}
					handleOnAddBulk={props.handleOnAddBulk}
					handleOnBackBulk={ props.handleOnBackBulk }
					handleOnSaveBulkButton={() => {
						onSaveBulkButton();
					}}
					cleanBulkMembers={props.cleanBulkMembers}
					uploadProgress={props.uploadProgress}
					cleanStatusBar={props.cleanStatusBar}
					roles={props.roles}
					positions={props.positions}
					errors_member_rol={props.errors_member_rol}
					onSelectRolMember={props.onSelectRolMember}
					handleNameOnChange={handleNameOnChange}
					handleTeamGenderOnChange={handleTeamGenderOnChange}
					handleCategoryOnChange={handleCategoryOnChange}
					handleTeamImageOnChange={handleTeamImageOnChange}
					handleTeamImageB64OnChange={handleTeamImageB64OnChange}
					showBulk={props.showBulk}
					updateBulkData={props.updateBulkData}
					handleOnDeleteMember={props.handleOnDeleteMember}
					handleOnMoreInfo={props.handleOnMoreInfo}
				/>
			</div>
		</div>
	);
}

export default withTranslation("team")(LeagueEditTeamComponent);
