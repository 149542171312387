// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
//import TextButtonComponent from 'shared/components/text-button/TextButtonComponent';

// Styles
import './LoginEmailPanelComponent.styles.css';

// Actions
import { logInEmailAction, updatePreRegisterEmailPhone } from 'app/authentication/actions/LoginActions';
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';

// Validators
import { EmailRequiredValidator } from 'shared/validators/RequiredValidator';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Assets
import baloa_logo from 'assets/images/logo-baloa-white.png';

import baloa_green_logo from 'assets/logos/baloa-logo.png';

// React device detect
import { isMobile } from 'react-device-detect';

class LoginEmailPanelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            disabled: false,
        };
    };

    disabled(data) {
        if (data.isValid && !this.state.disabled) {
            this.setState({disabled:true})
        }
        if(!data.isValid && this.state.disabled){
            this.setState({disabled:false}) 
        }
    }

    // resetErrorVerifyPassword(){
    //     const dataVerifyPassword = {
    //         'field': 'confirm_password',
    //         'error': ''
    //     }
    //     this.props.changeSignUpError( dataVerifyPassword);
    // }
    
    async submitUnregisterMemberEmail( email ){
        await this.props.updatePreRegisterEmailPhone({email: email}, this.props.state.signup.claim_account_profile.id, 'email' );
    }
    
    render = () => {
        
        const formInitialValues = {
            email: "",
            //password: "",
        }
        
        return (
            <div className="login-email-panel__container">
                <div className="login-email-panel__container__left-col">
                    <Link to="/">
                    {!isMobile
                        ?
                        <img className="login-header__image" src={baloa_logo} alt="" />
                        :
                        <img className="login-header__image" src={baloa_green_logo} alt="" />
                    }
                    </Link>
                </div>
                <div className="login-email-panel__container__right-col">
                    <div className='login-email-panel__container__right-col__title'>
                        <span className='icon-arrow-left baloa-headline-1' onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] )}/>
                        <div className='login-email-panel__title baloa-headline-1 ' >{this.props.t('email.emailTitle')}</div>
                    </div>
                    <div className="login-email-panel__text baloa-subtitle-2">{this.props.t('email.text')}</div>
                    <FormComponent
                        formInitialValues = { formInitialValues }
                        onSubmit = { ( values, actions ) => { 
                                //let email_values = { email: values.email, password: values.password }
                                if(this.props.state.signup.document_verification){
                                    this.submitUnregisterMemberEmail(values.email);
                                }else{
                                    this.props.logInEmailAction(values.email);
                                }
                                
                            }
                        }
                        className = "login-email-panel__form"
                        functionDisabled = { this.disabled.bind( this ) }
                    >
                        <div className="login-email-form__fields">
                            
                            <label for="email">{this.props.t('email.inputLabel')}</label>
                            <InputFieldComponent
                                input_id="login-email-panel__email"
                                field_name="email"
                                validationFunction={EmailRequiredValidator}
                                disabled={false}
                                className=""
                                type="text"
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                            />
                            {/* <label for="password">{this.props.t('password.title')}</label>
                            <InputFieldComponent
                                label=''
                                input_id="login-email-panel__password"
                                field_name="password"
                                validationFunction={RequiredValidator}
                                disabled={false}
                                className=""
                                resetError={() => {}}
                                error={this.props.state.signup_error.error}
                                message=""
                                input_type="password"
                            /> */}
                            {/* <TextButtonComponent 
                                className = "login-email-panel__recover-password__label"
                                onClick   = { () => this.props.changeSignUpStatus( SIGNUP_STATUS['RECOVER_PASSWORD'] ) } >
                                <span>{this.props.t('loginEmail.forgotPassword')}</span>
                            </TextButtonComponent>                            */}
                        </div>
                        <div className="login-email-panel__form__submit">
                            <PrimaryButtonComponent type="submit" disabled={!this.state.disabled} onClick={() => { }} className="login-email-panel__form__submit">
                                <span>{this.props.t('email.sendEmailCode')}</span>
                            </PrimaryButtonComponent>
                        </div>
                        
                    </FormComponent>
                    {/* <TextButtonComponent className="login-email-panel__form__change-method" onClick={() => this.props.changeSignUpStatus( SIGNUP_STATUS['LOGIN'] ) }>
                        <span>{this.props.t('loginEmail.changeLoginMethod')}</span>
                    </TextButtonComponent> */}
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logInEmailAction: (email_values) => {
            return dispatch(logInEmailAction(email_values))
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
        updatePreRegisterEmailPhone: async( new_data, user_id, method ) => {
            await dispatch( updatePreRegisterEmailPhone( new_data, user_id, method ) )
        },
    }
}

export default compose(
    withTranslation("signUp"),
    connect(mapStateToProps, mapDispatchToProps)
)(LoginEmailPanelComponent);
