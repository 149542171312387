/*eslint eqeqeq: "off"*/
// React
import React, { Component }from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import SignUpProffesionalSpecialtyPanelComponent from 'app/authentication/components/signup-proffesional-specialty-panel/SignUpProffesionalSpecialtyPanelComponent';

// Formik
import { Field } from 'formik';

// Styles
import './SignUpEmailProfileTypePanelComponent.styles.css';

// Constants
import { SIGNUP_STATUS } from 'app/authentication/reducers/SignUpReducer';

// Actions
import { changeSignUpError, changeSignUpStatus } from 'app/authentication/actions/LoginActionsCreators';
import { sendUserDataAction, createNewProfileTypeAction, createAttributeOnProfileAction } from 'app/authentication/actions/LoginActions';
import { loadAttributesConstants } from 'app/generalattributes/actions/AttributeActions';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import {compose} from 'redux';


class SignUpEmailProfileTypePanelComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            is_proffesional: false,
            profile: {
                user: {
                    "username": this.props.state.user.me.username
                }
            }
        };
    };
    
    componentDidMount(){
        this.props.loadAttributesConstants();
    }

    handleCreateAttributeOnProfileAction(new_attribute) {
        this.props.createAttributeOnProfileAction( this.state.profile, new_attribute );
    }
    

    render = () => {

        const formInitialValues = {
            profile_type: '',
        }
        return (
            <React.Fragment>
                <div className="signup-email-specialty-panel__container">
                    <div className="signup-email-specialty-panel__container__left-col"></div>
                    <div className="signup-email-specialty-panel__container__right-col">
                        
                            <FormComponent 
                                formInitialValues={formInitialValues} 
                                onSubmit={( values, actions ) => { 
                                    this.props.createNewProfileTypeAction( this.state.profile.user.username, values );
                                    if(values.profile_type === 'professional'){
                                        this.setState({is_proffesional: true});
                                    }
                                }}
                                className="signup-email-specialty-panel__form form"
                                functionDisabled={()=>{}}
                            >
                                
                                { this.state.is_proffesional !== true &&
                                    <React.Fragment>
                                        <div className="signup-email-specialty-panel__container__title">
                                            <span className="icon-arrow-left" onClick={  () => this.props.changeSignUpStatus( SIGNUP_STATUS['USERNAME_ASSIGNMENT'] )} />
                                            
                                            <h3 className="signup-email-specialty-panel__title">{this.props.t('role.title')}</h3>
                                            <div></div>
                                        </div>
                                        <h2 className="signup-email-specialty-panel__subtitle">{this.props.t('role.text')}</h2>
                                    
                                        <div className="signup-email-specialty-panel form__fields">
                                            <Field name="profile_type">
                                                {
                                                    ({
                                                        field,
                                                        form: { setFieldValue }, 
                                                        meta,
                                                    }) => (

                                                        <React.Fragment>
                                                            <div className="signup-email-specialty-panel__form__fields__field">
                                                                <div 
                                                                    onClick = { () => { setFieldValue('profile_type', 'fanatic') } }
                                                                    className={`signup-email-specialty-panel__form__fields__field__icon ${field.value == 'fanatic' ? 'active' : ''}`}
                                                                >
                                                                    <span className={`icon-fan signup-email-specialty-panel__form__fields__field__icon ${field.value == 'fanatic' ? 'active' : ''}`} />
                                                                </div>
                                                                <div className="signup-email-specialty-panel__form__fields__field__info">
                                                                    <p className="signup-email-specialty-panel__form__fields__field__caption baloa-body-1">{this.props.t('data.modal.text4')}</p>
                                                                    <p className="signup-email-specialty-panel__form__fields__field__description baloa-body-2">
                                                                        {this.props.t('data.modal.text5')}
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="signup-email-specialty-panel__form__fields__field">
                                                                <div 
                                                                    onClick = { () => { setFieldValue('profile_type', 'player') } }
                                                                    className={`signup-email-specialty-panel__form__fields__field__icon ${field.value == 'player' ? 'active' : ''}`}
                                                                >
                                                                    <span className= {`icon-player signup-email-specialty-panel__form__fields__field__icon ${field.value == 'player' ? 'active' : ''}`}></span>
                                                                </div>
                                                                <div className="signup-email-specialty-panel__form__fields__field__info">
                                                                    <p className="signup-email-specialty-panel__form__fields__field__caption baloa-body-1">{this.props.t('data.modal.text2')}</p>
                                                                    <p className="signup-email-specialty-panel__form__fields__field__description baloa-body-2">
                                                                    {this.props.t('data.modal.text3')}
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className= "signup-email-specialty-panel__form__fields__field " >
                                                                <div 
                                                                    onClick = { () => { setFieldValue('profile_type', 'professional'); } }
                                                                    className={`signup-email-specialty-panel__form__fields__field__icon ${field.value == 'professional' ? 'active' : ''}`}
                                                                >
                                                                    <span className={`icon-coach signup-email-specialty-panel__form__fields__field__icon ${field.value == 'professional' ? 'active' : ''}`} ></span>
                                                                </div>
                                                                <div className="signup-email-specialty-panel__form__fields__field__info">
                                                                    <p className="signup-email-specialty-panel__form__fields__field__caption baloa-body-1">{this.props.t('data.modal.text6')}</p>
                                                                    <p className="signup-email-specialty-panel__form__fields__field__description baloa-body-2">
                                                                    {this.props.t('data.modal.text7')}
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                }
                                { !this.state.is_proffesional && 
                                    <div className="signup-email-specialty-panel__form__actions">
                                        <PrimaryButtonComponent 
                                            type="submit"
                                            disabled={false} 
                                            onClick={() => {}} 
                                            className="signup-email-specialty-panel__form__submit"
                                        >
                                            <span>{this.props.t('data.modal.btnContinue')}</span>
                                        </PrimaryButtonComponent>
                                    </div>
                                }

                                { this.state.is_proffesional &&
                                    <SignUpProffesionalSpecialtyPanelComponent 
                                        specialties = { this.props.state.attributes.constants?.specialties }
                                        handleCreateAttributeOnProfileAction = { (new_attribute_data) => this.handleCreateAttributeOnProfileAction(new_attribute_data) }
                                        handleOnCloseSpecialtyPanel = {() => this.setState({is_proffesional: false}) }
                                    />
                                }
                            </FormComponent>
                        

                        
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUserDataAction: ( values, actions, username ) => {
            return dispatch( sendUserDataAction( values, actions, username ))
        },
        changeSignUpError : (values)=>{
            return dispatch(changeSignUpError(values))
        },
        loadAttributesConstants: () => {
            dispatch( loadAttributesConstants() )
        },
         createNewProfileTypeAction: ( profile, new_profile_type ) => {
            dispatch( createNewProfileTypeAction( profile, new_profile_type ) )
        },
        createAttributeOnProfileAction: ( profile, new_attribute_data ) => {
            dispatch( createAttributeOnProfileAction( profile, new_attribute_data ) )
        },
        changeSignUpStatus: ( new_status ) => {
            return dispatch( changeSignUpStatus( new_status ) )
        },
    }
}

export default compose(
    withTranslation("profile"),
    connect(mapStateToProps, mapDispatchToProps)
)(SignUpEmailProfileTypePanelComponent);