// React
import React from 'react';

// Components
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import TournamentEndMatchStepsComponent from '../tournament-end-match-steps/TournamentEndMatchStepsComponent';

//Material
import Switch from '@mui/material/Switch';

// Styles
import './TournamentEndMatchScoreboardComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Validators
import { OnlyNumbersRequiredValidator } from 'shared/validators/RequiredValidator';

function TournamentEndMatchScoreboardComponent ( props ) {

    const [local_scoreboard, setLocalScoreboard] = React.useState( undefined );
    const [visitant_scoreboard, setVisitantScoreboard] = React.useState( undefined );
    const [local_penalty_score, setLocalPenaltyScore] = React.useState( null );
    const [visitant_penalty_score, setVisitantPenaltyScore] = React.useState( null );
    const [state, setState] = React.useState({ penalty_definition: props.endGameState.score? props.endGameState.score.penalty_definition : false });


    React.useEffect(() =>{
        if(  props.endGameState.score?.local_scoreboard >= 0){
            setLocalScoreboard(props.endGameState.score?.local_scoreboard)
        }
        if(props.endGameState.score?.visitant_scoreboard >= 0){
            setVisitantScoreboard(props.endGameState.score.visitant_scoreboard);
        }
        if(props.endGameState.score?.local_team_penalty_score >= 0){
            setLocalPenaltyScore(props.endGameState.score.local_team_penalty_score);
        }
        if(props.endGameState.score?.visitant_team_penalty_score >= 0){
            setVisitantPenaltyScore(props.endGameState.score.visitant_team_penalty_score);
        }
        
    },[ props.endGameState.score]);

    const formInitialValues = {
        local_scoreboard: props.endGameState.score? props.endGameState.score.local_scoreboard : '',
        visitant_scoreboard: props.endGameState.score? props.endGameState.score.visitant_scoreboard : '',
        penalty_definition: props.endGameState.score? props.endGameState.score.penalty_definition : false,
        local_team_penalty_score: props.endGameState.score? props.endGameState.score.local_team_penalty_score : null,
        visitant_team_penalty_score: props.endGameState.score? props.endGameState.score.visitant_team_penalty_score : null,
    } 

    const handleOnChangeLocal=(event)=>{
        setLocalScoreboard (event?.target?.value);
    }

    const handleOnChangeVisitor=(event)=>{
        setVisitantScoreboard (event?.target?.value);
    }

    const handleLocalPenaltyScore = (event) => {
        setLocalPenaltyScore(event?.target?.value)
    }

    const handleVisitantPenaltyScore = (event) => {
        setVisitantPenaltyScore(event?.target?.value)
    }

    const changePenaltyShootout=(event)=>{
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    
    return(
        <div className="end-match-scoreboard scroll-parent">
            <FormComponent
                formInitialValues={formInitialValues} 
                onSubmit={( values, actions ) => {    
                    props.onNewData( values );
                    props.stepCompleted(2);
                    props.setScoreGameState( values );
                    props.setEndGameStepState(true);
                }}
                className="end-match-scoreboard__form form scroll-parent"
                functionDisabled={()=>{}}
                enableReinitialize={ true }
            >
                <div className="end-match-scoreboard__form__element scroll">
                    <TournamentEndMatchStepsComponent
                        step = { props.step }
                        stepCompleted = {(i)=>props.stepCompleted(i)}
                        endGameStepState = { props.endGameStepState }
                    />

                    <div className="tournament-end-match-scorerboard__teams__container" >
                        <div>
                        {/* <div className="tournament-end-match-scorerboard__teams__container__title">{'TITULO'}</div> */}
                        <div className="tournament-end-match-scorerboard__teams__container__scoreboard" >
                        <div className="tournament-end-match-scorerboard__teams__container__title baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelSubtitle')}</div>
                            {/* <div className="tournament-end-match-scoreboard__teams">
                                <div className="tournament-end-match-scoreboard__teams__data">                            
                                    <div className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelHome')}</div>
                                    { props.local_team?.photo && <img src={ props.local_team?.photo } alt={`${props.local_team?.teamname} img`}/>}
                                    { !props.local_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    <div className="baloa-headline-2">{ props.local_team?.name }</div>
                                    <div className="baloa-normal-text">{ `@${props.local_team?.pagename}`}</div>
                                </div>
                                <InputFieldComponent
                                    label="" 
                                    input_id="end-match-local_scoreboard" 
                                    field_name="local_scoreboard"
                                    validationFunction={ OnlyNumbersRequiredValidator }
                                    disabled={false}
                                    className="tournament-end-match-scorerboard__score-input"
                                    input_type="text"
                                    resetError={ () => props.onResetError( { 'local_scoreboard': "" } )}
                                    error={ props.errors }
                                    message=""
                                    placeholder="0"
                                    onChange={ ( e ) => { handleOnChangeLocal( e )} }
                                    value={ local_scoreboard }
                                />
                            </div>
                            <div className="tournament-end-match-scoreboard__teams">
                                <div className="tournament-end-match-scoreboard__teams__data"> 
                                    <div className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelAway')}</div>
                                    { props.visitor_team?.photo && <img src={ props.visitor_team?.photo } alt={`${props.visitor_team?.photo?.teamname} img`}/>}
                                    { !props.visitor_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                    <div className="baloa-headline-2">{ props.visitor_team?.name }</div>
                                    <div className="baloa-normal-text">{ `@${props.visitor_team?.pagename}` }</div>
                                </div>
                                <InputFieldComponent
                                    label="" 
                                    input_id="end-match_visitant_scoreboard" 
                                    field_name="visitant_scoreboard"
                                    validationFunction={ OnlyNumbersRequiredValidator }
                                    disabled={false}
                                    className="tournament-end-match-scorerboard__score-input"
                                    input_type="text"
                                    resetError={ () => props.onResetError( { 'visitant_scoreboard': "" } )}
                                    error={ props.errors }
                                    message=""
                                    placeholder="0"
                                    onChange={ ( e ) => { handleOnChangeVisitor( e )} }
                                    value={ visitant_scoreboard }
                                />
                                
                            </div> */}
                            <div className="tournament-end-match-scoreboard__teams__data">                            
                                <div className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelHome')}</div>
                                { props.local_team?.photo && <img src={ props.local_team?.photo } alt={`${props.local_team?.teamname} img`}/>}
                                { !props.local_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-headline-2">{ props.local_team?.name }</div>
                                <div className="baloa-normal-text">{ `@${props.local_team?.pagename}`}</div>
                            </div>
                           <InputFieldComponent
                                label="" 
                                input_id="end-match-local_scoreboard" 
                                field_name="local_scoreboard"
                                validationFunction={ OnlyNumbersRequiredValidator }
                                disabled={false}
                                className="tournament-end-match-scorerboard__score-input"
                                input_type="text"
                                resetError={ () => props.onResetError( { 'local_scoreboard': "" } )}
                                error={ props.errors }
                                message=""
                                placeholder="0"
                                onChange={ ( e ) => { handleOnChangeLocal( e )} }
                                value={ local_scoreboard }
                            />
                            <div className='tournament-end-match-scoreboard__teams__divisor'>{'-'}</div>
                            <InputFieldComponent
                                label="" 
                                input_id="end-match_visitant_scoreboard" 
                                field_name="visitant_scoreboard"
                                validationFunction={ OnlyNumbersRequiredValidator }
                                disabled={false}
                                className="tournament-end-match-scorerboard__score-input"
                                input_type="text"
                                resetError={ () => props.onResetError( { 'visitant_scoreboard': "" } )}
                                error={ props.errors }
                                message=""
                                placeholder="0"
                                onChange={ ( e ) => { handleOnChangeVisitor( e )} }
                                value={ visitant_scoreboard }
                            />
                            <div className="tournament-end-match-scoreboard__teams__data"> 
                                <div className="baloa-names">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelAway')}</div>
                                { props.visitor_team?.photo && <img src={ props.visitor_team?.photo } alt={`${props.visitor_team?.photo?.teamname} img`}/>}
                                { !props.visitor_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                <div className="baloa-headline-2">{ props.visitor_team?.name }</div>
                                <div className="baloa-normal-text">{ `@${props.visitor_team?.pagename}` }</div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className='tournament-end-match-scorerboard__penalty-shootouts__container' >
                        <div className='tournament-end-match-scorerboard__penalty-shootouts__switch-container'>
                            <div className='baloa-hyperlink-2' >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelShootouts')}</div>
                            <Switch
                                checked={ state.penalty_definition }
                                onChange={ changePenaltyShootout }
                                name="penalty_definition"
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="secondary"
                            />
                        </div>
                        { state.penalty_definition && 
                            <div className='tournament-end-match-scorerboard__shootouts__container' >
                                <div className='tournament-end-match-scorerboard__penalty-shootouts__teams' >
                                    <div className='tournament-end-match-scorerboard__penalty-shootouts__team' >
                                        <div className='tournament-end-match-scorerboard__penalty-shootouts__team-img' >
                                            { props.local_team?.photo && <img src={ props.local_team?.photo } alt={`${props.local_team?.teamname} img`}/>}
                                            { !props.local_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                        </div>
                                        <div className='tournament-end-match-scorerboard__penalty-shootouts__team-name' >
                                            <div className='baloa-hyperlink-2' >{ props.local_team?.name }</div>
                                            <div className='baloa-username' >{ `@${props.local_team?.pagename}`}</div>
                                        </div>
                                    </div>
                                    <div className='tournament-end-match-scorerboard__penalty-shootouts__team-score'>
                                        <div className='baloa-hyperlink-2' >{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabScore.labelShootoutsScore')}</div>

                                        <InputFieldComponent
                                            label="" 
                                            input_id="end-match-local_team_penalty_score" 
                                            field_name="local_team_penalty_score"
                                            validationFunction={ OnlyNumbersRequiredValidator }
                                            disabled={false}
                                            className="tournament-end-match-scorerboard__shootout-score-input"
                                            input_type="text"
                                            resetError={ () => props.onResetError( { 'local_team_penalty_score': "" } )}
                                            error={ props.errors }
                                            message=""
                                            placeholder="0"
                                            onChange={ ( e ) => { handleLocalPenaltyScore( e )} }
                                            value={ local_penalty_score }
                                        />
                                        <InputFieldComponent
                                            label="" 
                                            input_id="end-match-visitant_team_penalty_score" 
                                            field_name="visitant_team_penalty_score"
                                            validationFunction={ OnlyNumbersRequiredValidator }
                                            disabled={false}
                                            className="tournament-end-match-scorerboard__shootout-score-input"
                                            input_type="text"
                                            resetError={ () => props.onResetError( { 'visitant_team_penalty_score': "" } )}
                                            error={ props.errors }
                                            message=""
                                            placeholder="0"
                                            onChange={ ( e ) => { handleVisitantPenaltyScore( e )} }
                                            value={ visitant_penalty_score }
                                        />
                                    </div>
                                    <div className='tournament-end-match-scorerboard__penalty-shootouts__team' >
                                        
                                        <div className='tournament-end-match-scorerboard__penalty-shootouts__team-img' >
                                            { props.visitor_team?.photo && <img src={ props.visitor_team?.photo } alt={`${props.visitor_team?.photo?.teamname} img`}/>}
                                            { !props.visitor_team?.photo && <span className="icon-ball" color={getRandonIconBallColor()}/>}
                                        </div>
                                        <div className='tournament-end-match-scorerboard__penalty-shootouts__team-name' >
                                            <div className='baloa-hyperlink-2' >{ props.visitor_team?.name }</div>
                                            <div className='baloa-username' >{ `@${props.visitor_team?.pagename}` }</div>
                                        </div>
                                    </div>

                                </div>
                            </div>                        
                        }
                        
                    </div>
                          
                </div>
                <div className="end-match-scoreboard__form__actions form__actions">
                    <SecondaryButtonComponent 
                        disabled={false} 
                        onClick={()=>{props.stepCompleted(0);}} 
                        className="league-match-scorer-form__actions__back-btn"
                    >
                        <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonBack')}</span>
                    </SecondaryButtonComponent>
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="end-match-scoreboard__form__submit"
                    >
                        <span>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.labelButtonContinue')}</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>
             
        </div>
    )

}

export default withTranslation('league')(TournamentEndMatchScoreboardComponent);