/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Actions
import { checkPageAccountStatus } from 'app/payments/actions/PaymentsActions';

//Functions
import { permissionsController } from 'shared/functions/PemissionsController';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Material UI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './LeagueMenuComponent.styles.css';

class LeagueMenuComponent extends Component {

    constructor( props ) {
        super(props);
        this.state = {
            tab_selected: 0,
            options: [],
            selectedIndex: 0,
            anchorEl: null,
            poster_id: null,
        }
    }

    componentDidMount () {
        this.setOptionsPages();
    }

    componentDidUpdate (prevProps) {
        if(this.props.state.profile.loggedin.pages && prevProps.state.profile.loggedin !== this.props.state.profile.loggedin) {
            this.setOptionsPages();
        }
    }

    nextPath = (path) => {
        this.props.history.push(path)
    }

    selectedTab (tab) {
        
        var patt;

        if(tab === 0){
            patt = new RegExp("^\/page\/.+$"); // eslint-disable-line
        }

        if(tab === 1){
            patt = new RegExp("^\/league\/team\/.+$"); // eslint-disable-line
        }

        if(tab === 2){
            patt = new RegExp("^\/league\/member\/.+$"); // eslint-disable-line
        }

        if(tab === 3){
            patt = new RegExp("^\/league\/.+\/$"); // eslint-disable-line
        }

        if(tab === 4){
            patt = new RegExp("^\/league\/tournament\/.+$"); // eslint-disable-line
        }

        if(tab === 5){
            patt = new RegExp("^\/league\/payments$"); // eslint-disable-line
        }

        if(tab === 6){
            patt = new RegExp("^\/league\/help$"); // eslint-disable-line
        }

        return patt.test(this.props.location.pathname);

    }

    setAnchorEl = (e) => {
        this.setState({ anchorEl: e });
    }

    handleClickListItem = (event) => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    handleMenuItemClick = (event, index, page_name) => {
        this.props.history.push(`/page/${page_name}`);
        this.setSelectedIndex(index);
        this.setAnchorEl(null);
        this.setPosterId(page_name);
    };

    setPosterId = (id) => {
        this.setState({ poster_id: id });
    }

    setSelectedIndex = (e) => {
        this.setState({ selectedIndex: e });
    }

    setOptionsPages = () => {
        let optArray = [];        
        this.props.state.profile.loggedin?.pages?.map((p) =>        
            optArray.push([p.pagename, p.id, p.photo, p.name])
        );    
        this.setState({options: optArray});
        if (this.props.state.page.page) {
            let location = this.props.location.pathname?.split('/');
            let index = 0;
            if(location[1] === 'page' ) {
                index = optArray.findIndex(element => element[0] === location[2]);
            }
            if(location[1] === 'league' ) {
                index = optArray.findIndex(element => element[0] === location[3]);
            }
            this.setSelectedIndex(index);                
        }      
        
    }

    render() {
        return(
            <React.Fragment>
                {/* menu list */}
                {/*<div className={"league-menu " + ((this.props.state.page.page.page_type === "league" && this.props.state.page.page.is_admin) ? "" :"hide-menu")}>*/}
                {!isMobile &&
                    <div className={"league-menu " + ((this.props.state.page.page.page_type != "group" && ((permissionsController(this.props.state.page.page.roles, ['HASPAGEROLES']) && this.props.state.page.page.is_member ) || this.props.state.page.page.is_admin ) && !this.props.state.page.modals.select_team_inscription_is_open && !this.props.state.leagueTournaments.start_cards_id_component) ? "" :"hide-menu")}>
                    <div className="league-menu__title">
                        <p className="league-menu__title__text">{this.props.t('options.labelTitleMenu')}</p>
                    </div>
                    {/* <div className="league{-menu__field"+(this.selectedTab(1) ? "selected-item" :"")}>
                            {this.props.state.page.page.photo &&
                                <img src={ this.props.state.page.page.photo } alt=""/>
                            }
                            {!this.props.state.page.page.photo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            }                            
                            <div className="league-menu__page-item__data">
                                <div className="league-menu__page-item__data-name baloa-names">{ this.props.state.page.page.name }</div>
                                <div className='league-menu__page-item__data-username baloa-username'>@{ this.props.state.page.page.pagename }</div>
                            </div>
                    </div> */}
                    <div className="league-menu-component__page-selector">   
                        <List component="nav" aria-label="Device settings">
                            { this.state.options[this.state.selectedIndex] && (
                                <ListItem
                                    button
                                    aria-haspopup="true"
                                    aria-controls="lock-menu"
                                    aria-label="Poster"
                                    onClick={this.handleClickListItem}
                                >
                                    <ListItemAvatar>
                                        {this.state.options[this.state.selectedIndex][2] &&
                                            <Avatar className="league-menu__list-item__avatar" alt={this.state.options[this.state.selectedIndex][0]} src={this.state.options[this.state.selectedIndex][2]} />
                                        }
                                        {!this.state.options[this.state.selectedIndex][2] &&
                                            <Avatar className="league-menu__list-item__avatar" alt={this.state.options[this.state.selectedIndex][0]} >
                                                <span className={"league-menu__list-item icon-ball"} color={getRandonIconBallColor()} />
                                            </Avatar>
                                        }                                
                                    </ListItemAvatar>
                                    <div className='league-menu__page-info'>
                                        <ListItemText className="league-menu__list-item__text-name"  primary={ this.state.options[this.state.selectedIndex][3]} />
                                        <ListItemText className="league-menu__list-item__text-username"  primary={'@' + this.state.options[this.state.selectedIndex][0]} />
                                    </div>
                                    <div className='league-menu__icon-chevron'>
                                        <span className='icon-chevron-down' />
                                    </div>
                                    
                                </ListItem>
                                )
                            }
                        </List>
                        
                        <Menu
                            id="lock-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose} 
                        >
                            {this.state.options.map((option, index) => (
                            <MenuItem
                                key={option[1]}
                                selected={index === this.state.selectedIndex}
                                onClick={(event) => this.handleMenuItemClick(event, index, option[0])}
                            >
                                {option[2] &&
                                    <img className="league-menu__photo" src={option[2]} alt="" />
                                }
                                {!option[2] &&
                                    <span className={"league-menu__photo icon-ball"} color={getRandonIconBallColor()}></span> 
                                }
                                <div className='league-menu__page-info'>
                                    <div className='baloa-hyperlink'>{option[3]}</div>
                                    <div className='baloa-table'>@{option[0]}</div>
                                </div>
                                                        
                            </MenuItem>
                            ))}
                        </Menu>            
                    </div>

                    <div className={`league-menu__field ${this.selectedTab(0) ? "selected-item" :""}`}
                        onClick={() => {
                            this.setState({tab_selected:0});
                            this.nextPath(`/page/${this.props.state.page.page.pagename}/`);
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(0) ? "selected-item" :"")}>
                            { this.selectedTab(0) 
                                ? <span className="hubicon-home-1" />
                                : <span className="hubicon-home" />
                            }
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(0) ? "selected-item":"")}>
                                {this.props.t('options.home.labelTitle')}
                            </p>
                        </div>
                    </div>
                    
                    <div className={`league-menu__field ${this.selectedTab(1) ? "selected-item" :""}`}
                        onClick={() => {
                            if(permissionsController(this.props.state.page.page.roles, ['TEAMAD']) || this.props.state.page?.page?.is_admin){ 
                                this.setState({tab_selected:1});
                                this.nextPath(`/league/team/${this.props.state.page.page.pagename}/`);
                            }
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(1) ? "selected-item" :"")}>
                            { this.selectedTab(1) 
                                ? <span className="hubicon-team_fill" />
                                : <span className="icon-team" />
                            }                                
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(1) ? "selected-item":"")}>
                            {this.props.t('options.teamsAndMembers.labelTitleTeams')}
                            </p>
                        </div>
                    </div>

                    <div className={`league-menu__field ${this.selectedTab(4) ? "selected-item" :""}`}
                        onClick={() => {
                            if(permissionsController(this.props.state.page.page.roles, ['TOURNAMENTAD', 'MATCHAD']) || this.props.state.page?.page?.is_admin ){
                                this.setState({tab_selected:4});
                                this.nextPath(`/league/tournament/${this.props.state.page.page.pagename}`);
                            }
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(4) ? "selected-item" :"")}>
                            { this.selectedTab(4) 
                                ? <span className="hubicon-tournament_fill" />
                                : <span className="icon-trophy" />
                            }
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(4) ? "selected-item":"")}>
                                {this.props.t('options.home.tournamentsOption')}
                            </p>
                        </div>
                    </div>
                                        
                    <div className={`league-menu__field ${this.selectedTab(2) ? "selected-item" :""}`}
                        onClick={() => {
                            if(permissionsController(this.props.state.page.page.roles, ['STAFFAD']) || this.props.state.page?.page?.is_admin){
                                this.setState({tab_selected:2});
                                this.nextPath(`/league/member/${this.props.state.page.page.pagename}/`);
                            }
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(2) ? "selected-item" :"")}>
                            { this.selectedTab(2) 
                                ? <span className="hubicon-roles_fill" />
                                : <span className="hubicon-roles" />
                            }
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(2) ? "selected-item":"")}>
                            {this.props.t('options.home.rolPermissionsOption')}
                            </p>
                        </div>
                    </div>
                    
                    {/* <div className={"league-menu__field"+(this.selectedTab(1) ? "selected-item" :"")}
                        onClick={() => {
                            this.setState({tab_selected:3});
                            this.nextPath(`/league/${this.props.state.page.page.pagename}/`);
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(3) ? "selected-item" :"")}>
                                <span className="icon-team"></span>
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(3) ? "selected-item":"")}>
                                equipos antes
                            </p>
                        </div>
                    </div> */}

                    
                    
                    {/* <div className={"league-menu__field"+(this.selectedTab(1) ? "selected-item" :"")}
                        onClick={() => {this.setState({tab_selected:3})}}
                    >
                        <div className={"league-menu__field__icon "+(this.state.tab_selected === 3 ? "selected-item" :"")}>
                                <span className="icon-wistle1"></span>
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.state.tab_selected === 3 ? "selected-item":"")}>
                                Circulares
                            </p>
                        </div>
                    </div> */}
                    <div className={`league-menu__field  ${this.selectedTab(5) ? "selected-item" :""}`}
                        onClick={() => {
                            this.setState({tab_selected:5});
                            this.nextPath(`/league/payments`);
                            if(this.props.state.page.page.create_stripe_account && this.props.state.page.page.stripe_account_id){
                                this.props.checkPageAccountStatus(this.props.state.page.page.stripe_account_id);
                            }                           
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(5) ? "selected-item" :"")}>
                            {this.selectedTab(5) 
                                ? <span className="hubicon-payments_fill" />
                                : <span className="hubicon-payments" />
                            }
                            
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(5) ? "selected-item":"")}>
                                {this.props.t('options.home.paymentsOption')}
                            </p>
                        </div>
                    </div>
                    <div className={`league-menu__field  ${this.selectedTab(6) ? "selected-item" :""}`}
                        onClick={() => {
                            //this.setState({tab_selected:4});
                            this.nextPath(`/league/help`);
                        }}
                    >
                        <div className={"league-menu__field__icon "+(this.selectedTab(6) ? "selected-item" :"")}>
                            {this.selectedTab(6) 
                                ? <span className="hubicon-help_fill" />
                                : <span className="hubicon-help" />
                            }
                        </div>
                        <div className="league-menu__field__data">
                            <p className={"league-menu__field__data__text baloa-normal-medium "+(this.selectedTab(6) ? "selected-item":"")}>
                                {this.props.t('options.help.labelTitle')}
                            </p>
                        </div>
                    </div>
                      
                </div>
                }
            </React.Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkPageAccountStatus:(account_id) => {
            dispatch(checkPageAccountStatus(account_id))
        },
    }
}    

export default compose(
    withRouter,
    withTranslation("league"),
    connect(mapStateToProps, mapDispatchToProps)
)(LeagueMenuComponent);

