/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Assets
import field_colored_icon from 'assets/icons/field-icon.svg';
import user_add_icon from 'assets/icons/addplayer.svg';

// Actions
import { addUserFormation } from 'app/home/actions/HomeActionsCreators';

// Functions
import getPlayerNameByPosition from 'shared/functions/GetPlayerNameByPosition';
import getPositionNameByPosition from 'shared/functions/GetPositionNameByPosition';

// Styles
import './ShowPostFormationFieldComponent.styles.css';

// i18next
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Positions Field Component:
// Renders positions and handles their actions.
function ShowPostFormationFieldComponent( props ) {

    /* eslint-disable */
    let [ state, setState ] = React.useState( 
        { 
            lineUp: props.formation && props.formation.lineup ? props.formation.lineup: '',
            primary_position_selected: props.primary_position ? props.primary_position : '',
            secondary_positions_selected: props.secondary_positions ? props.secondary_positions : [],
            formation: props.formation,
        } 
    )
    /* eslint-enable */

    let positionsMap = new Map ([
        ["4-4-2", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "med4", "del1", "del2" ]],
        ["5-3-2", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "del1", "del2" ]],
        ["4-3-3", ["por", "def1", "def2", "def3", "def4", "med1", "med2", "med3", "del1", "del2", "del3" ]],
        ["3-4-3", ["por", "def1", "def2", "def3", "med1", "med2", "med3", "med4", "del1", "del2", "del3" ]],
        ["5-4-1", ["por", "def1", "def2", "def3", "def4", "def5", "med1", "med2", "med3", "med4", "del1" ]]        
    ]);
    
    return (
        
        <div className="show-position-fields__data">            
            <div className="show-post-field__container">
                <div className="show-post-field__positions">
                    <div className="show-post-formation-field">
                        <div className="show-post-formation-field__row show-post-formation-field__row__striker"> {/**delantero */}
                            { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                <React.Fragment key={position + index}>
                                    { getPositionNameByPosition(position) === "del" &&
                                        <div className="show-post-formation-field__row__assigned" >
                                            <div
                                                key = { position }
                                                className="show-post-formation-field__row__assigned__container"
                                            >
                                                <span className='icon-ball' color={getRandonIconBallColor()} />
                                                
                                            </div>
                                            <div className='show-post-formation-field__username__container'>
                                                <span className='show-post-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div> 
                        <div className="show-post-formation-field__row show-post-formation-field__row__midfielder"> {/**medio campo */}
                            { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                <React.Fragment key={position + index}>
                                    { getPositionNameByPosition(position) === "med" &&
                                        <div className="show-post-formation-field__row__assigned" >
                                            <div
                                                key = { position }
                                                className="show-post-formation-field__row__assigned__container"
                                            >
                                                <span className='icon-ball' color={getRandonIconBallColor()} />                                            
                                            </div>
                                            <div className='show-post-formation-field__username__container'>
                                                <span className='show-post-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                                ))}
                        </div>
                        <div className="show-post-formation-field__row show-post-formation-field__row__defender"> {/**defensa */}
                            { positionsMap.get(state.lineUp)?.map( (position, index) => ( 
                                <React.Fragment key={position + index}>
                                    { getPositionNameByPosition(position) === "def" &&
                                        <div className="show-post-formation-field__row__assigned" >
                                            <div
                                                key = { position }
                                                className="show-post-formation-field__row__assigned__container"                                                     
                                            >
                                                <span className='icon-ball' color={getRandonIconBallColor()} />
                                            </div>
                                            <div className='show-post-formation-field__username__container'>
                                                <span className='show-post-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( position, state.formation )}</span>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div> 
                        <div className="show-post-formation-field__row show-post-formation-field__row__goalkeeper"> {/**portero */}
                            {                                 
                                <div className="show-post-formation-field__row__assigned" >
                                    <div
                                        className="show-post-formation-field__row__assigned__container"                                                     
                                    >
                                        <span className='icon-ball' color={getRandonIconBallColor()} />
                                    </div>
                                    <div className='show-post-formation-field__username__container'>
                                        <span className='show-post-formation-field__username baloa-username baloa-truncate'>{getPlayerNameByPosition( 'por', state.formation )}</span>
                                    </div>
                                </div>
                            }
                        </div> 
                    </div>
                </div>
                { props.display === "form" && 
                    <div className="show-post-formation-field__container__img">
                        <img className="show-post-formation-field__field-img" src={ field_colored_icon } alt=""/>
                    </div>
                }
            </div>

            { props.sup == "true" && 
                <div className="show-position-fields-suplents">
                    <div className="grid-test-container">                    
                        <div><p className="baloa-subtitle-1">{props.t('alt')}</p></div>
                        <div>
                            { props.sup == "true" &&    
                                <React.Fragment> {                         
                                        <div className="show-formation-post-field-sup__positions__position display-form">
                                            <img src={ user_add_icon } alt=""/>                                        
                                            <p className="show-formation-post-text-field__field">{ state.formation.players.supl[0] != null ? state.formation.players.supl[0].name : "" }</p>
                                            <p className="show-formation-post-text-field-sup-pos__field">POR</p>
                                        </div>
                                    } 
                                </React.Fragment>
                            }
                        </div>   
                        <div>
                            { props.sup == "true" &&    
                                <React.Fragment> {                         
                                        <div className="show-formation-post-field-sup__positions__position display-form">
                                            <img src={ user_add_icon } alt=""/>                                        
                                            <p className="show-formation-post-text-field__field">{ state.formation.players.supl[1] != null ? state.formation.players.supl[1].name : "" }</p>
                                            <p className="show-formation-post-text-field-sup-pos__field">DEF</p>
                                        </div>
                                    } 
                                </React.Fragment>
                            }
                        </div>   
                        <div>
                            { props.sup == "true" &&    
                                <React.Fragment> {                         
                                        <div className="show-formation-post-field-sup__positions__position display-form">
                                            <img src={ user_add_icon } alt=""/>                                        
                                            <p className="show-formation-post-text-field__field">{ state.formation.players.supl[2] != null ? state.formation.players.supl[2].name : "" }</p>
                                            <p className="show-formation-post-text-field-sup-pos__field">MED</p>
                                        </div>
                                    } 
                                </React.Fragment>
                            }
                        </div>   
                        <div>
                            { props.sup == "true" &&    
                                <React.Fragment> {                         
                                        <div className="show-formation-post-field-sup__positions__position display-form">
                                            <img src={ user_add_icon } alt=""/>                                        
                                            <p className="show-formation-post-text-field__field">{ state.formation.players.supl[3] != null ? state.formation.players.supl[3].name : "" }</p>
                                            <p className="show-formation-post-text-field-sup-pos__field">DEL</p>
                                        </div>
                                    } 
                                </React.Fragment>
                            }
                        </div>   
                    </div>
                </div>              
            }
        </div>
    )
}

// Types
ShowPostFormationFieldComponent.propTypes = {
    primary_position: PropTypes.string,
    secondary_positions: PropTypes.array,
}

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {       
        addUserFormation: ( formation ) => {
            return dispatch( addUserFormation( formation ) );
        },
    }
}

// Exports

export default compose(
    withTranslation("newField"),
    connect(mapStateToProps, mapDispatchToProps)
)(ShowPostFormationFieldComponent);


