/*eslint eqeqeq: "off"*/
// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//Functions
//import { permissionsController } from 'shared/functions/PemissionsController';
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// i18n
import { withTranslation } from 'react-i18next';

// Redux
import {compose} from 'redux';

// Styles
import './TournamentMenuComponent.styles.css';

// React device detect
import { isMobile } from 'react-device-detect';

class TournamentMenuComponent extends Component {

    constructor( props ) {
        super(props);
        this.state = {
            tab_selected: 0,
        }
    }

    componentDidMount () {
        
    }

    nextPath = (path) => {
        this.props.history.push(path)
    }

    selectedTab (tab) {
        
        var patt;

        if(tab === 0){
            patt = new RegExp("^\/tournament\/hub"); // eslint-disable-line
        }

        if(tab === 1){
            patt = new RegExp("^\/tournament\/inscriptions"); // eslint-disable-line
        }

        if(tab === 2){
            patt = new RegExp("^\/tournament\/my-tournaments$"); // eslint-disable-line
        }

        if(tab === 3){
            patt = new RegExp("^\/tournament\/invitations$"); // eslint-disable-line
        }
        
        if(tab === 4){
            patt = new RegExp("^\/tournament\/matches$"); // eslint-disable-line
        }

        return patt.test(this.props.location.pathname);

    }

    render() {

        return(
            <React.Fragment>
                {/* menu list */}
                {/*<div className={"tournament-menu " + ((this.props.state.page.page.page_type === "league" && this.props.state.page.page.is_admin) ? "" :"hide-menu")}>*/}
                { isMobile 
                    ?
                    <div className='tournament-menu__container' >
                        {/* <div className='tournament-menu__mobile-title' >{this.props.t('menu.labelTitleMenu')}</div> */}
                        {/* <div className='tournament-menu__actions-buttons-container'>
                            <div className={'tournament-menu__actions-buttons '+(this.selectedTab(0) ? "active":"")}
                                onClick={() => {
                                    this.setState({tab_selected:0});
                                    this.nextPath(`/tournament/hub`);                            
                                }}
                            >
                                <div className="tournament-menu__actions-buttons__data">
                                <p className={"tournament-menu__actions-buttons__data__text baloa-names "}>
                                    {this.props.t('menu.hubLabelTitle')} 
                                </p>
                                </div>
                            </div>
                            <div className={"tournament-menu__actions-buttons "+(this.selectedTab(1) ? "active":"")}
                                onClick={() => {
                                    this.setState({tab_selected:1});
                                    this.nextPath(`/tournament/inscriptions`);                            
                                }}
                            >
                                <div className={"tournament-menu__actions-buttons__data"}>
                                    <p className={"tournament-menu__actions-buttons__data__text baloa-names "}>
                                        {this.props.t('menu.inscriptionsLabelTitle')}
                                    </p>
                                </div>
                            </div>
                            <div className={"tournament-menu__actions-buttons "+(this.selectedTab(3) ? "active":"")}
                                onClick={() => {
                                    this.setState({tab_selected:3});
                                    this.nextPath(`/tournament/invitations`);
                                }}
                            >
                                <div className="tournament-menu__actions-buttons__data">
                                    <p className={"tournament-menu__actions-buttons__data__text baloa-names "}>
                                        {this.props.t('menu.invitationsLabelTitle')}
                                    </p>
                                </div>
                            </div>        
                            <div className={"tournament-menu__actions-buttons "+(this.selectedTab(2) ? "active":"")}
                                onClick={() => {
                                    this.setState({tab_selected:2});
                                    this.nextPath(`/tournament/my-tournaments`);
                                    
                                }}
                            >
                                <div className="tournament-menu__actions-buttons__data">
                                    <p className={"tournament-menu__actions-buttons__data__text baloa-names "}>
                                        {this.props.t('menu.myTournamentsLabelTitle')}
                                    </p>
                                </div>
                            </div>  
                        </div> */}
                    </div>
                    :
                    <React.Fragment>
                        <div className={"tournament-menu "}>
                            <div className='tournament-menu__menu-links' >
                                <div className="tournament-menu__title">
                                    <p className="tournament-menu__title__text baloa-headline-2">{this.props.t('menu.labelTitleMenu')}</p>
                                </div>
                                <div className={"tournament-menu__field " +(this.selectedTab(0) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:0});
                                        this.nextPath(`/tournament/hub`);                            
                                    }}
                                >                        
                                    <div className="tournament-menu__field__data">
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(0) ? <span className='hubicon-home-1' /> : <span className='hubicon-home' /> }
                                            <div>{this.props.t('menu.hubLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tournament-menu__field "+(this.selectedTab(4) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:1});
                                        this.nextPath(`/tournament/matches`);                            
                                    }}
                                >
                                    <div className={"tournament-menu__field__data"}>
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(4) ? <span className='icon-field' /> : <span className='icon-field' /> }
                                            <div>{this.props.t('menu.matchesLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tournament-menu__field "+(this.selectedTab(1) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:1});
                                        this.nextPath(`/tournament/inscriptions`);                            
                                    }}
                                >
                                    <div className={"tournament-menu__field__data"}>
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(1) ? <span className='hubicon-confirmation_number-1' /> : <span className='hubicon-confirmation_number' /> }
                                            <div>{this.props.t('menu.inscriptionsLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tournament-menu__field "+(this.selectedTab(3) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:3});
                                        this.nextPath(`/tournament/invitations`);
                                    }}
                                >
                                    <div className="tournament-menu__field__data">
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(3) ? <span className='hubicon-forward_to_inbox' /> : <span className='hubicon-forward_to_inbox-1' /> }
                                            <div>{this.props.t('menu.invitationsLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div>        
                                <div className={"tournament-menu__field "+(this.selectedTab(2) ? "active":"")}
                                    onClick={() => {
                                        this.setState({tab_selected:2});
                                        this.nextPath(`/tournament/my-tournaments`);
                                        
                                    }}
                                >
                                    <div className="tournament-menu__field__data">
                                        <div className={"tournament-menu__field__data__text baloa-normal-medium "}>
                                            { this.selectedTab(2) ? <span className='hubicon-manage_accounts-1' /> : <span className='hubicon-manage_accounts1' /> }
                                            <div>{this.props.t('menu.myTournamentsLabelTitle')}</div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            { this.props.state.tournaments?.following_hub_tournaments_subsection.length > 0 &&
                                <div className='tournament-menu__following-tournaments'>
                                    <div className='baloa-names tournament-menu__following-tournaments__title'>{this.props.t('tournamentHubSections.tournament_follow')}</div>
                                    
                                    { this.props.state.tournaments?.following_hub_tournaments_subsection?.slice(0,5).map( (tournament, index) => (
                                        <Link to={`/tournament/hub/${tournament.tournament_id}`}>
                                            <div className='tournament-menu__following-tournament__container' key={index}  >
                                                <div className='tournament-menu__following-tournament__img'>
                                                    { tournament.tournament_logo
                                                        ?
                                                        <img src={tournament.tournament_logo} alt={tournament.name}/>
                                                        :
                                                        <span className="icon-ball" color={getRandonIconBallColor()} />

                                                    }
                                                </div>
                                                <div className='tournament-menu__following-tournament__tournament-info'> 
                                                    <div className='tournament-menu__following-tournament__tournament-name baloa-names'>{tournament.name}</div>
                                                    <div className='tournament-menu__following-tournament__tournament-info__labels'>
                                                        <div className='tournament-menu__following-tournament__tournament-info__label baloa-username'>{this.props.t('viewTournament.footballType.'+tournament.football_type)}</div>
                                                        <div className='tournament-menu__following-tournament__tournament-info__label baloa-username'>{this.props.t('league:options.tournaments.pages.newTournament.tabs.tabBasicData.'+tournament.tournament_gender)}</div>
                                                        <div className='tournament-menu__following-tournament__tournament-info__label baloa-username'>{this.props.t('league:options.tournaments.pages.newTournament.tabs.tabTeams.'+ tournament.category)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        
                                    ))

                                    }
                                    <Link to={"/tournament/hub/?following=true"}>
                                        <div className='baloa-table-column tournament-menu__following-tournament__view-more' >
                                            {this.props.t(`tournamentHubSections.viewMore`)}
                                        </div> 
                                    </Link>                                    
                                </div>
                            }                                   
                        </div>
                        
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}    

export default compose(
    withRouter,
    withTranslation("tournament"),
    connect(mapStateToProps, mapDispatchToProps)
)(TournamentMenuComponent);

