// Action creators
import {
    changeSearchBoxStateAction,
    changeSearchFilteredStateAction,
    loadSearchBoxAction,
    loadSearchAction,
    loadSearchFilterAction,
    updateSearchResultFollowAction,
} from './SearchActionsCreators';
import { changeErrorMessageSnackBar } from 'app/home/actions/HomeActionsCreators';

// Apis
import {
    GetSearchApi,
    GetSearchFilterApi,
} from '../apis/SearchApi';
import { FollowProfileApi, UnfollowProfileApi } from 'app/profiles/apis/ProfileApi';
import { FollowPageApi, UnfollowPageApi } from 'app/pages/apis/PageApi';
import { PostFollowTournamentHubApi, DeleteUnfollowTournamentHubApi } from 'app/tournaments/apis/TournamentApi';

// i18next 
import i18n from 'i18next';

export const loadSearchBox = ( keyword ) => {
    return ( dispatch ) => {
        //GetSearchApi( keyword )
        GetSearchFilterApi( keyword, 'profile' )
            .then( response => {
                dispatch( loadSearchBoxAction( response ) );
            })
            .catch(err => {
                //console.log(err);
            })
    }
}

export const loadSearch = ( keyword ) => {

    return ( dispatch ) => {
        dispatch( changeSearchBoxStateAction( false ) );
        GetSearchApi( keyword)
            .then( response => {
                dispatch( loadSearchAction( response ) );
            })
            .catch(err => {
                //console.log(err);
            })
    }
}

export const loadSearchFilter = ( keyword, filter, next_page) => {
    return ( dispatch ) => {
        dispatch( changeSearchBoxStateAction( false ) );
        dispatch( changeSearchFilteredStateAction( true ) );
        GetSearchFilterApi( keyword, filter, next_page )
            .then( response => {
                dispatch( loadSearchFilterAction( response ) );
            })
            .catch(err => {
                //console.log(err);
            })
    }
}

export const followSearchProfile = ( profile_username ) => {
    return ( dispatch) => {
        FollowProfileApi( profile_username )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'profile',followkey: profile_username}) )                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const followSearchPage = ( pagename ) => {
    return ( dispatch ) => {        
        FollowPageApi( pagename )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'page',followkey: pagename}) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const followSearchTournament = ( tournament_id ) => {
    return ( dispatch) => {
        PostFollowTournamentHubApi( tournament_id )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'tournament',followkey: tournament_id}) )                
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const unfollowSearchProfile = ( username ) => {
    return ( dispatch ) => {
        UnfollowProfileApi( username )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'profile',followkey: username}) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const unfollowSearchPage = ( pagename ) => {
    return ( dispatch ) => {
        UnfollowPageApi( pagename )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'page',followkey: pagename}) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('page:snackBarMessages.problemExist'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}

export const unfollowSearchTournament = ( tournament_id ) => {
    return ( dispatch ) => {
        DeleteUnfollowTournamentHubApi( tournament_id )
            .then( response => {
                dispatch( updateSearchResultFollowAction({search_type:'tournament',followkey: tournament_id}) )
            })
            .catch( error => {
                if(localStorage.getItem('token') != null){
                    dispatch( changeErrorMessageSnackBar( {
                        message: i18n.t('league:snackBarMessages.snackBarError'),
                        error_message_is_open: true
                    } ) )
                }
                //console.log( error );
            } )
    }
}