// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments {
    /* margin: 0 var(--space-x3); */
    display: grid;
    grid-template-areas: "left left center center center center center center center center center center";
    grid-template-columns: repeat(12, 1fr);
    gap: var(--space-and-half);    
}

.payments__center-container {
    grid-area: center;
    
}

.payments__center-container-title{
    background-color: white;
    padding: 20px 100px;
    display:flex;
}

.payments__center-container-text {
    background-color: white;
    margin: 25px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payments__center-container-text .hubicon-info {
    color: var(--new-green-color);
    margin-right: 14px;
}

.payments__center-container-img{
    text-align: center;
    padding-top: 70px;
}

.payments__center-container-img img{
    height: 350px;
}

.payments__center-container-title .icon-arrow-left {
    margin-right: var(--space-half-quarter);
}`, "",{"version":3,"sources":["webpack://./src/app/payments/views/PaymentsView.styles.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,sGAAsG;IACtG,sCAAsC;IACtC,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;;AAErB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".payments {\n    /* margin: 0 var(--space-x3); */\n    display: grid;\n    grid-template-areas: \"left left center center center center center center center center center center\";\n    grid-template-columns: repeat(12, 1fr);\n    gap: var(--space-and-half);    \n}\n\n.payments__center-container {\n    grid-area: center;\n    \n}\n\n.payments__center-container-title{\n    background-color: white;\n    padding: 20px 100px;\n    display:flex;\n}\n\n.payments__center-container-text {\n    background-color: white;\n    margin: 25px;\n    padding: 32px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.payments__center-container-text .hubicon-info {\n    color: var(--new-green-color);\n    margin-right: 14px;\n}\n\n.payments__center-container-img{\n    text-align: center;\n    padding-top: 70px;\n}\n\n.payments__center-container-img img{\n    height: 350px;\n}\n\n.payments__center-container-title .icon-arrow-left {\n    margin-right: var(--space-half-quarter);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
