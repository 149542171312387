/*eslint eqeqeq: "off"*/
// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//import InputFieldTeamAutocompleteComponent from 'shared/components/input-field-team-autocomplete/InputFieldTeamAutocompleteComponent';
import FormComponent from 'shared/components/form/FormComponent';
import InscriptionsFeeComponent from 'app/tournaments/components/inscriptions-fee/InscriptionsFeeComponent';
import InscriptionsFeePlayerComponent from '../inscriptions-fee-player/InscriptionsFeePlayerComponent';
import StepperComponent from '../stepper-inscription-modal/StepperInscriptionModalComponent';
import ComposeInscriptionFormComponent from 'app/tournaments/components/compose-inscription-form/ComposeInscriptionFormComponent'
import InputFieldAutocompleteWithSearchComponent from 'shared/components/input-field-autocomplete-with-search/InputFieldAutocompleteWithSearchComponent';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';


// Styles
import './InscriptionsModalComponent.styles.css';


//i18
import { withTranslation } from 'react-i18next';

function InscriptionsModalComponent ( props ) {

    const [step, setStep] = useState(null);
    const [buttonText, setButtonText] = useState('');
    const [submitButton, setSubmitButton] = useState(false);
    //const [submitWompiForm, setSubmitWompiForm] = useState(false);
    const [currentlyPlayersQuantity, setCurrentlyPlayersQuantity] = useState(0);
    const [defaulTeamValue, setDefaulTeamValue] = useState(null);
    const formRef = React.useRef();
    
    
    React.useEffect(() => {
        if(props.start_flow != null){
            setStep(props.start_flow);
        }
	}, [props.start_flow]); // eslint-disable-line

    React.useEffect(() =>{
        if(props.profile_teams?.length >= 1 && props.team_name_created.id){
            // props.setSelectedTeam(props.profile_teams[0]?.teamname)
            setDefaulTeamValue(props.profile_teams.find( team => team.teamname === props.team_name_created.teamname));
            formRef.current?.setFieldValue("team", props.profile_teams.find( team => team.teamname === props.team_name_created.teamname));
            props.setSelectedTeam(props.profile_teams.find( team => team.teamname === props.team_name_created.teamname))
            props.handle_load_created_teams();
        }
    },[props.profile_teams]); // eslint-disable-line

    React.useEffect(() => {
        if(step != null){
            if(props.inscrption_steps === 1){ //invitado torneo gratios
                setButtonText(props.t('inscriptionModal.buttons.register'));
            }
            if(props.inscrption_steps === 2){ // gratis abierto (seleccion de team)
                if(step==2){
                    setButtonText(props.t('inscriptionModal.buttons.register'));
                }else{
                    setButtonText(props.t('inscriptionModal.buttons.next'));
                }
                
            }
            if(props.inscrption_steps === 3){ // abierto con pago
                if(step ==3){
                    setButtonText(props.t('inscriptionModal.buttons.next'));//Envia al pago
                }else{
                    setButtonText(props.t('inscriptionModal.buttons.next'));
                }
                
            }
            if(props.inscrption_steps === 4){ // con formulario
                if(step == 3){
                    setButtonText(props.t('inscriptionModal.buttons.next'));//Envia al pago
                }else{
                    setButtonText(props.t('inscriptionModal.buttons.next'));
                }
                
            }
        }
	}, [step]); // eslint-disable-line

    function stepCompleted ( index ){

        if(index === 1){
            setStep(1);//select team
        }else if(index === 2){
            setStep(2);//team inscription form
        }else if (index === 3) {
            setStep(3);//payment form
        }else if (index === 4) {
            setStep(4);//
        }else if(index === 0){
            setStep(0);//pre load team (from invitations)
        }
        
    }
    const closeModal=()=>{
        stepCompleted(props.start_flow);     
        props.setSelectedTeam(null);   
        setSubmitButton(false);
        setDefaulTeamValue(null);
        props.onClose();
    }

    const submitCode=( data )=>{
        props.handleOnCodeButton(data.inscode);
    }
    const formInitialValues = {
        team: props.team_name_created ? props.team_name_created : ''
    }
    
    const sendForm =(data)=>{
        props.onNewData(data);

        if(step == 2 && props.inscrption_steps === 4){
            stepCompleted(3);
        }
        if(step == 2 && props.inscrption_steps === 2){
            props.handleOnInscription()
        }
    }

    /* const division_type = [
        { value: 'Ellite', content: props.t('registerPlayer.form.Ellite') },
        { value: 'Premiere', content: props.t('registerPlayer.form.Premiere') },
        { value: 'First_Division', content: props.t('registerPlayer.form.FirstDivision') },
    ]; */

    const handleSetPlayersQuantity = (value) => {
        props.setPlayersQuantity(value);
        setCurrentlyPlayersQuantity(value);
    }

    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={
                props.profile_type === 'player'
                    ? props.t('inscriptionModal.subtitlePlayer')
                    : props.t('inscriptionModal.title')
                }
            className="inscriptions_modal_component"
        >
            <div className="inscriptions scroll-parent">
                {/*Stepper*/}
                <div className="inscriptions_stepper">
                {props.inscrption_steps !== 1 && 
                    <StepperComponent 
                        inscription_steps = {props.inscrption_steps}
                        currentStep = {step}
                    />
                    }
                </div>

                {/* To select team */}
                { step === 0 && (
                    <React.Fragment>
                        <div className='inscriptions_selected-team'>
                        <div className='inscriptions_subtitle baloa-subtitle-2'>
                            {props.profile_type === 'player'
                                ? props.t('inscriptionModal.yourTeam')
                                : props.t('inscriptionModal.subtitle')
                            }
                        </div>
                        <div className='inscriptions_text'>{props.t('inscriptionModal.text0')}</div>
                        <FormComponent
                            formInitialValues={formInitialValues} 
                            onSubmit={( values, actions ) => {}}
                            className="inscriptions_search-team"
                            functionDisabled={()=>{}}
                            innerRef={formRef}
                        >

                        {!props.load_created_teams ?
                            /* <InputFieldTeamAutocompleteComponent
                                name= { 'team' }
                                options={ props.profile_teams? props.profile_teams : null }
                                noOptionsText={ props.t('inscriptionModal.emptyTeamsSearchMsg') }                            
                                optionSelectedField={ "id" }
                                className="tournament-team-player__input"
                                //label={props.t('')}
                                placeholder={props.t('inscriptionModal.teamsSearchPlaceholder')}
                                onChange={ (selectedTeam) => props.setSelectedTeam(selectedTeam) }
                                // value={ props.team_name_created.name ?? ''}
                                defaultValue={ defaulTeamValue ? defaulTeamValue : ''}
                            /> */
                            <InputFieldAutocompleteWithSearchComponent
                                options={props.profile_teams? props.profile_teams : null}
                                noOptionsText={ props.t('inscriptionModal.emptyTeamsSearchMsg') }
                                optionLabelField={"team"}
                                optionSelectedField={"id"}
                                lettercount={"3"}
                                handleSearchWord={(teamName) => props.handleSearchHubAdminTeam(teamName)}
                                className="tournament-team-player__input"
                                //label={}
                                placeholder={props.t('inscriptionModal.teamsSearchPlaceholder')}
                                id="team"
                                field_name="team"
                                validationFunction={() => { }}
                                resetError={() => { }}
                                error={{team:''}}
                                team_search={true}
                                setTeam={ (selectedTeam) => props.setSelectedTeam(selectedTeam) }
                                //disabled={}
                                value={ defaulTeamValue ? defaulTeamValue : null}
                            />
                        :
                        <span className="inscriptions_selected-team_loading-container">                                           
                            <div className='inscriptions_selected-team_loading__spinner-container'>                                                
                                <div className="span-inscriptions_selected-team_loading" />
                            </div>
                        </span>
                        }

                        </FormComponent>
                        <div className='inscription_team__button-create-team'>
                            <div className='baloa-table'>{props.t('inscriptionModal.labelCreateTeamSubtitle') }</div>
                            <SecondaryButtonComponent
                                type='button'
                                onClick={()=>props.handlePageNewTeamModal()}
                            >
                                <span>{props.t('inscriptionModal.labelCreateTeam')}</span>
                            </SecondaryButtonComponent>
                        </div>
                    </div>
                    </React.Fragment>
                )}
                
                {/* Pre selected team */}
                { step === 1 && (
                    <div className='inscriptions_selected-team'>
                        <div className='inscriptions_subtitle baloa-subtitle-2'>
                        {props.profile_type === 'teams'
                            ? props.t('inscriptionModal.subtitle')
                            : props.t('inscriptionModal.yourTeam')
                        }
                        </div>
                        <div className='inscriptions_text'>
                            {
                                props.profile_type === 'player'
                                ? props.t('inscriptionModal.text2')
                                : props.t('inscriptionModal.text1')
                            }</div>
                        <div className='inscriptions_team-data'>
                            <div className='inscriptions_team-data__image'>
                                {props.team_logo?
                                    <img src={props.team_logo} alt={props.team}/>
                                :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                            </div>
                            <div className='inscriptions_team-data__info'>
                                <div className='inscriptions_team-data__team baloa-table-column'>{props.team}</div>
                                <div className='inscriptions_team-data__teamname baloa-username'>@{props.teamname}</div>
                            </div>
                        </div>
                    </div>
                )}
 
                {/* Team form */}
                { (step === 2 && ( props.tournament_team_id || props.tournament_player_id )) &&(                    
                    /* Dynamic reading Component Form*/
                    <React.Fragment>
                        {props.profile_type === 'player'
                        ? <div className={'inscriptions_payment-title'}>{props.t('inscriptionModal.labelPlayerInformation')}</div> 
                        : <div className={'inscriptions_payment-title'}>{props.t('inscriptionModal.labelTeamInformation')}</div> }
                        <ComposeInscriptionFormComponent
                            inscription_form_template={props.inscription_form_template}
                            tournament_team_id={props.tournament_team_id}
                            tournament_player_id={props.tournament_player_id}
                            onNewData = { (data)=>{sendForm(data)}}
                            submitForm={submitButton}
                            resetButton={()=>{setSubmitButton(false)}}
                        />
                    </React.Fragment>
                )}
                
                { step === 3 && (
                    <React.Fragment>
                        <div className={'inscriptions_payment-title'}>{props.t('inscriptionModal.lableInscription')}</div>
                        <div className='inscriptions_payment'>
                            {props.profile_type === 'player'?
                                <React.Fragment>
                                    <InscriptionsFeePlayerComponent                                 
                                        registration_price_per_player={props.tournament_registration_price_per_player}
                                        currency={props.currency}
                                        submitCode={(data)=>{submitCode(data)}}
                                        payment_player_code={props.payment_player_code}

                                        profile_id={props.profile_id}
                                        tournament_player_id={props.tournament_player_id}
                                        resetPaymentButton={props.resetPaymentButton}
                                        submitWompiForm={props.submitWompiForm}
                                        type_redirect={props.profile_type} //Llega 'player'
                                        profile_phone={props.profile_phone}
                                        tournament_id={props.tournament_id}
                                    />                                
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <InscriptionsFeeComponent 
                                        registration_price_per_team={props.tournament_registration_price_per_team}
                                        registration_price_per_player={props.tournament_registration_price_per_player}
                                        currency={props.currency}
                                        setPlayersQuantity={ (value) => handleSetPlayersQuantity(value) }

                                        profile_id={props.profile_id}
                                        tournament_team_id={props.tournament_team_id}
                                        resetPaymentButton={props.resetPaymentButton}
                                        submitWompiForm={props.submitWompiForm}
                                        type_redirect={'teams'} //Llega 'teams'
                                        tournament_registration_type={props.tournament_registration_type}
                                        profile_phone={props.profile_phone}
                                        tournament_id={props.tournament_id}
                                    />
                                </React.Fragment>
                            }
                            
                        </div>
                    </React.Fragment>
                )}
                {/*  */}
                { step === 4 && (
                    <div>{"empty"}</div>
                )}
                <div className='inscriptions_buttons'>
                    <PrimaryButtonComponent
                        disabled={props.load_created_teams}
                        type={'button'}
                        onClick={ ()=>{                           
                            if(props.inscrption_steps === 1 ){
                                props.handleOnInscription(); //Equipo a inscribir  
                            }
                            if(props.inscrption_steps === 2 || props.inscrption_steps === 4 ){
                                if(step == 0 || step == 1){
                                    stepCompleted(2); // Team form
                                }
                                if(step == 2 && props.inscrption_steps === 4){
                                    setSubmitButton(true);
                                    let form_interval = setInterval(() => {
                                        stepCompleted(3);
                                    }, 2000);
                                    clearInterval(form_interval);
                                    
                                }
                                if(step == 2 && props.inscrption_steps === 2){
                                    setSubmitButton(true);
                                    let form_interval = setInterval(() => {
                                        props.handleOnInscription();
                                    }, 2000);
                                    clearInterval(form_interval);
                                }
                                if(step == 3 && props.inscrption_steps === 4){
                                    if(props.payment_player_code !== 'player_code'){
                                        props.handleOnPayment();
                                    }else{
                                        props.handleOnInscription();
                                    }
                                }
                            }
                            if(props.inscrption_steps === 3 ){
                                
                                if(step == 0 || step == 1){
                                    stepCompleted(3); // Payment form
                                }
                                if(step == 3){
                                    // Payment for Onlt Team
                                    if(currentlyPlayersQuantity > 0 && props.tournament_registration_type === 'Player_paid'){
                                        props.handleOnPayment();
                                    }else if(props.payment_player_code !== 'player_code' && props.tournament_registration_type === 'Team_paid' ){
                                        props.handleOnPayment();
                                    }else if(props.tournament_registration_type === 'Paid'){
                                        props.handleOnPayment();
                                    }else{
                                        props.handleOnInscription();
                                    }
                                    
                                }
                            }
                            
                        }}
                        className='small'
                    >
                        {!props.load_created_teams 
                            ? <span>{buttonText}</span>
                            : <span className="inscriptions_selected-team_loading-container">                                           
                                <div className='inscriptions_selected-team_loading__spinner-container'>                                                
                                    <div className="span-inscriptions_selected-team_loading button-inscription" />
                                </div>
                            </span>
                        }
                    </PrimaryButtonComponent> 
                </div>
            </div>
        </Modal2Component>
    )

}

export default withTranslation('tournament')(InscriptionsModalComponent);