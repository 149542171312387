/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ScrollTrigger from 'react-scroll-trigger';

// Components
import LeagueTournamentInfoComponent from 'app/leagues/components/league-tournament-info-component/LeagueTournamentInfoComponent';
import ShowPostFormationFieldComponent from 'shared/components/show-post-formation/ShowPostFormationFieldComponent';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import convertDateToMoment from 'shared/functions/ConvertDateToMoment';

// Formaters
import { HASHTAG_FORMATTER } from 'shared/components/hashtag-formatter/HashtagFromatter';

// Styles
import './QuotedPostComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';



function QuotedPostComponent ( props ){

    // Video controls
    const videoRef = React.useRef();

    React.useEffect( () => {
        if ( videoRef.current != undefined ) {
            // Play video when father component tells to
            if ( props.play_video ) { 
                videoRef.current.play(); 
            }else { 
                videoRef.current.pause(); 
            }
            // Mute video when father component tells to
            videoRef.current.muted = !props.play_audio;
        }
    }, [ props.play_video, props.play_audio ] )  // eslint-disable-line

    return(
        <div className={"quoted-post__post-content scroll " + (props.className? props.className : '')}>
            {props.post?.author?.type_object == "page" &&
                <div className="post__header">
                    <div
                        className={"post__header__photo"}
                    >
                        {props.post?.author.photo &&
                            <img src={props.post?.author.photo} alt="" />
                        }
                        {!props.post?.author.photo &&
                            <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                        }
                    </div>
                    <div className="post__header__info">
                        <div className="post__header__info__username baloa-normal-medium">
                            <span /* onClick={ () => props.handleOnAuthorClick( props.post?.author ) } */ >{ `${props.post?.author?.caption}`} </span>
                            {props.post.author.is_verified &&
                                <span class="iconb2-Verificated">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            }
                            {props.post.author.baloer &&
                                <span class="iconbal-Balores">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </span>
                            } 
                            &nbsp;&bull;&nbsp;                                             
                            <span 
                                className={"post__header__info__username__follow-link baloa-hyperlink"}
                                onClick={() => {
                                    props.post.is_following ?
                                        props.unfollowPostPage(props.post.author.identifier, props.post.id)
                                        : props.followPostPage(props.post.author.identifier, props.post.id);
                                }}
                            >                                
                                {props.post.is_following ?
                                    props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                    :
                                    props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                } 
                                
                            </span>
                        </div>
                        <p className="post__header__info__date baloa-table-column">
                            { convertDateToMoment( props.post?.created ) }
                        </p>

                    </div>
                </div>
            }
            {/* Profile Post */}
            {props.post?.author?.type_object == "profile" && 
                <div className="post__header">

                    {/* Profile photo */}
                    <div
                        /* onClick={ () => props.handleOnAuthorClick( props.post.author ) } */
                        className={"post__header__photo"}>
                            {props.post.author.photo &&
                                <img src={props.post.author.photo} alt="" />
                            }
                            {!props.post.author.photo &&
                                <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                            }                    
                    </div>

                    {/* Profile data */}
                    <div className="post__header__info">
                        <div
                            
                            className="post__header__info__username baloa-normal-medium"
                        >
                            <span /* onClick={ () => props.handleOnAuthorClick( props.post.author ) } */ >{ `${props.post.author.caption}`} </span>
                            {props.post.author.is_verified &&
                                <span class="iconb2-Verificated">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            }
                            {props.post.author.baloer &&
                                <span class="iconbal-Balores">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </span>
                            }  
                            &nbsp;&bull;&nbsp;
                            <span 
                                className={"post__header__info__username__follow-link baloa-hyperlink"}
                                onClick={() => {
                                    props.post.is_following ?
                                        props.unfollowPostProfile(props.post.author.identifier, props.post.id)
                                        : props.followPostProfile(props.post.author.identifier, props.post.id);
                                }}
                            >                                  
                                {props.post.is_following ?
                                    props.t('post.unfollow') /*TODO:implementar follow/unfollow*/
                                    :
                                    props.t('post.follow') /*TODO:implementar follow/unfollow*/
                                } 
                            </span>
                        </div>
                        <div className="post__header__info__date baloa-table-column">                           
                            { `${props.post.author.profile_type}`}  &bull; {`${convertDateToMoment(props.post.created)}`}
                            {/* {console.log(props.post)/**en post hace [5] horas */} 
                        </div>
                    </div>
                </div>
            }

            {/** Post Caption */}
            <div class="post__caption-me">
                <div className="post__caption-me__text baloa-normal" dangerouslySetInnerHTML={{
                    __html: HASHTAG_FORMATTER( props.post?.original_caption? props.post?.original_caption: '' )
                }}></div>
            </div>

            {/* Post Content */}

            <ScrollTrigger 
                onEnter={ props.onEnterViewport } 
                onExit={ props.onExitViewport }
            >
            
                {/* Post Formation content */}
                { props.post?.formation &&
                    <div className="post-formation__content">
                        <div className="post-formation__data">
                            <div className="post-formation__data__positions-graph">
                                <ShowPostFormationFieldComponent
                                    show={props.post?.formation ? "block" : "none"}
                                    display="form"
                                    position="secondary"
                                    formation={props.post.formation}
                                    sup="false"
                                />
                            </div>
                        </div>
                    </div>
                }

                {/* Post video content */}
                {props.post?.post_video &&
                    <div className="post__content">
                        
                        {/* Video Player */}
                        {props.post?.post_stream.hls?
                            <ReactHlsPlayer
                                playerRef={videoRef}
                                src={props.post?.post_stream.hls}
                                preload = "auto"
                                autoPlay={true}
                                width={'100%'}
                                height={'100%'}                                            
                                loop={true}
                                poster = { props.post?.post_stream?.thumbnail ? props.post?.post_stream?.thumbnail : "" }
                            />
                            :
                            <video
                                ref = { videoRef }
                                src = { props.post?.post_video }
                                loop
                                preload = "auto"
                                poster = { props.post?.post_stream?.thumbnail ? props.post.post_stream?.thumbnail : "" }
                                muted 
                                playsinline
                            ></video>
                        }
                        <div className="post__content__video-control"
                            onClick={ () => props.handleOnPlayVideo() }
                        >
                            <div className={ !props.play_video ? "post__content__video-control__background" : "" }></div>
                            <span className={ !props.play_video ? "post__content__video-control__icon" : "" } ></span>
                        </div>
                        <div className="post__content__control-mute"
                            onClick={ () => props.handleOnMuteVideo() }
                        >
                            <span className={ !props.play_audio ? "post__content__control-mute__icon icon-sound-off" : "post__content__control-mute__icon icon-sound-on"}></span>
                        </div>

                    </div>
                }

                {/* Post image content */}
                {props.post?.post_image &&
                    <div className="quoted-post__image">
                        <img src={props.post.post_image} alt="" />
                    </div>
                }

                {/* Post match content */}
                {props.post?.post_match &&
                    <div className="post__content">
                        <LeagueTournamentInfoComponent
                            tournament_info={props.post.post_match}
                            className = { "post__content__tournament-result__container" }
                        />                        
                    </div>
                }
            </ScrollTrigger>     
        </div>
    )

}
export default withTranslation('post')(QuotedPostComponent);