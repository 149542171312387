// React
import React from 'react';

//Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './SelectTeamInscriptionComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';


// React device detect
import { isMobile } from 'react-device-detect';

function SelectTeamInscriptionComponent ( props ){

    return(   
        <div className="select-team-inscription__container">
            <div className="select-team-inscription__close-header" onClick={props.onClose}>
                <span className="select-team-inscription__close-header__icon icon-x "></span>  
            </div>
            <div className="select-team-inscription__page-data">
                <div className='select-team-inscription__page-data__img'>
                    {props.page?.photo &&
                        <img src={props.page?.photo} alt="" />
                    }
                    {!props.page?.photo &&
                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                    }
                </div>
                <div className='baloa-normal-medium'>{props.page?.name}</div>
                <div className='baloa-names'>@{props.page?.pagename}</div>
            </div>
            <div className='select-team-inscription__subtitle baloa-names'>{props.t('page:pageTeamInscrptionModal.subtitle')}</div>
            
            {!isMobile &&
                <div className='select-team-inscription__teams-data__container'>
                    <div className='select-team-inscription__teams-data__header grid-columns-set'>
                        <div className='baloa-table-column'>{props.t('team:teams.teamsNameLabel')}</div>
                        <div className='baloa-table-column'>{props.t('team:teams.teamsCategoryLabel')}</div>
                        <div className='baloa-table-column'>{props.t('team:teams.teamsGenderLabel')}</div>
                        <div className='baloa-table-column'></div>
                    </div>
                    
                    {props.page?.teams?.map(team =>
                        <div className='select-team-inscription__teams-data__team grid-columns-set'>
                            <div className='select-team-inscription__teams-data__team__name'>
                                <div className='select-team-inscription__teams-data__team__name-image'>
                                    {team?.photo &&
                                        <img src={team?.photo} alt="" />
                                    }
                                    {!team?.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }
                                </div>
                                <div>
                                    <div className='baloa-names'>{team.name}</div>
                                    <div className='baloa-username'>{team.teamname}</div>
                                </div>
                            </div>
                            <div className='baloa-names'>{props.t(`options.tournaments.pages.newTournament.tabs.tabTeams.${team.category}`)}</div>
                            <div className='baloa-names'>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${team.gender}`)}</div>
                            <div>
                                <PrimaryButtonComponent
                                    type='button'
                                    className='select-team-inscription__teams-data__team__button small'
                                    onClick={ ()=>{ props.handleOnTeamButtonClick(props.page?.pagename, team.teamname, team.is_player) } }
                                >   
                                    {team.is_player &&
                                        <span>{props.t('page:pageTeamInscrptionModal.buttons.goToTeam')}</span>
                                    }
                                    {!team.is_player &&
                                        <span>{props.t('page:pageTeamInscrptionModal.buttons.joinMe')}</span>
                                    }
                                    
                                </PrimaryButtonComponent>
                            </div>
                        </div>
                    )}
                    
                </div>
            }
            {isMobile &&
                <div className='select-team-inscription__teams-data__container'>
                    {props.page?.teams?.map(team =>
                        <div className='select-team-inscription__teams-data__team'>
                            <div className='select-team-inscription__teams-data__team__name'>
                                <div className='select-team-inscription__teams-data__team__name-image'>
                                    {team?.photo &&
                                        <img src={team?.photo} alt="" />
                                    }
                                    {!team?.photo &&
                                        <span className={"icon-ball"} color={getRandonIconBallColor()}></span>
                                    }
                                </div>
                                <div>
                                    <div className='baloa-mini'>{team.name}</div>
                                    <div className='select-team-inscription__data-tags'>
                                        <div className='select-team-inscription__tag '>{props.t(`options.tournaments.pages.newTournament.tabs.tabTeams.${team.category}`)}</div>
                                        <div className='select-team-inscription__tag '>{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${team.gender}`)}</div>
                                    </div>
                                </div>
                                <PrimaryButtonComponent
                                    type='button'
                                    className='select-team-inscription__teams-data__team__button small'
                                    onClick={ ()=>{ props.handleOnTeamButtonClick(props.page?.pagename, team.teamname, team.is_player) } }
                                >   
                                    {team.is_player &&
                                        <span>{props.t('page:pageTeamInscrptionModal.buttons.goToTeam')}</span>
                                    }
                                    {!team.is_player &&
                                        <span>{props.t('page:pageTeamInscrptionModal.buttons.joinMe')}</span>
                                    }
                                    
                                </PrimaryButtonComponent>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )

}

export default withTranslation('league')(SelectTeamInscriptionComponent);
