
// React
import React from 'react';

// Components
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import InputFieldComponent from 'shared/components/input-field/InputFieldComponent';
import FormComponent from 'shared/components/form/FormComponent';

// Styles
import './ProfileTechnicsPrimaryTeamModalComponent.styles.css';

// Validators
import { AnyValidator } from 'shared/validators/SignUpValidator';

// i18n
import { withTranslation } from 'react-i18next';
import ProfileStepsDatasheetComponent from '../profile-steps-datasheet/ProfileStepsDatasheetComponent';



function ProfileTechnicsPrimaryTeamModalComponent( props ) {
    
    const formInitialValues = {
        primary_team: props.model.attributes?.primary_team?.value?.name,
    }

    return (
        
            <div className="technics-form">

                <FormComponent 
                    formInitialValues={formInitialValues} 
                    onSubmit={( values, actions ) => { 
                        let new_attribute_data = { ...props.model.attributes?.primary_team, value: { name: values['primary_team'] } };
                        props.onNewData( new_attribute_data );
                        props.stepCompleted(1);
                    }}
                    className="technics-form__form form"
                    functionDisabled={()=>{}}
                >
                    <div className="technics-form__form__fields form__fields">
                        <ProfileStepsDatasheetComponent
                            step={props.step}
                            stepCompleted = {(i)=>props.stepCompleted(i)}
                        />
                        <div className="technics-form__form__fields__content baloa-subtitle-2">
                        {props.t('playerCard.question')}
                        </div>
                        <InputFieldComponent 
                            label={props.t('playerCard.title3')} 
                            input_id="signup-last-name" 
                            field_name="primary_team"
                            validationFunction={ AnyValidator }
                            disabled={false}
                            className="technics-form__form__input margin-input"
                            input_type="text"
                            resetError={ () => props.onResetError( { 'primary_team': "" } )}
                            error={ props.errors }
                            message=""
                            placeholder={props.t('playerCard.placeholder')}
                        />
                    </div>

                    <div className="technics-form__form__actions form__actions">
                        <PrimaryButtonComponent 
                            type="submit"
                            disabled={false} 
                            onClick={()=>{}} 
                            className="technics-form__form__submit"
                        >
                            <span>{props.t('playerCard.button')}</span>
                        </PrimaryButtonComponent>
                    </div>

                </FormComponent>

            </div>
    )
}

export default withTranslation('profile')(ProfileTechnicsPrimaryTeamModalComponent);