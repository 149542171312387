// Axios
import Axios from "config/axiosConfig";

// Globals
import { api_url } from "config/GlobalData";


export const SetMatchScoreApi = ( match_id , score ) => {

    return Axios.instance.patch( `${ api_url }/tournament-matches/${ match_id }/score/?version=2`,
        {
            local_team_score: score.local_scoreboard,
            visitant_team_score: score.visitant_scoreboard,
            penalty_definition: score.penalty_definition,
            local_team_penalty_score: score.local_team_penalty_score,
            visitant_team_penalty_score: score.visitant_team_penalty_score,
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

/* export const SetMatchPlayersGoalsApi = ( match, goals ) => {

    return Axios.instance.get( `${ api_url }/`, //TODO: cambiar a url del API
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
} */

export const SetMatchPlayersCardsApi = ( match_id, cards ) => { //TODO: Nueva API

    return Axios.instance.post( `${ api_url }/tournament-matches/${match_id}/players_cards/?version=2`,
        {
            local: cards.local,
            visitant: cards.visitant
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const SetMatchCommentsApi = ( match_id, comments ) => { //TODO: Nueva API

    return Axios.instance.patch( `${ api_url }/tournament-matches/${ match_id }/comments/?version=2`,
        {
            comments: comments.comment,
            profile: comments.profile
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
    
}

export const SetMatchScorersApi = ( match_id, scorers ) => { //TODO: Nueva API

    return Axios.instance.post( `${ api_url }/tournament-matches/${match_id}/scorers/?version=2`, 
        {
            local: scorers.local,
            visitant: scorers.visitant
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );
    
}

export const SetMatchChangesApi = ( match_id, changes ) => { //TODO: Nueva API

    return Axios.instance.post( `${ api_url }/tournament-matches/${match_id}/players_changes/?version=2`, 
        {
            local: changes.local,
            visitant: changes.visitant
        },
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        } 
    );
    
}

export const GetMatchReportApi = ( match_id ) => { //TODO: Nueva API
    return Axios.instance.get( `${ api_url }/tournament-matches/${ match_id }/?version=2`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

// TODO: Se cambia por Api GetTeamMembersApi para traer equipos en la finalización de un partido en un torneo.
export const GetTeamPageMembersApi = ( team_page_name ) => { //TODO: Nueva API
    return Axios.instance.get( `${ api_url }/teams-page/${ team_page_name }/members/?pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTeamPageDataApi = ( team_page_name ) => { //TODO: Nueva API

    return Axios.instance.get( `${ api_url }/teams-page/${ team_page_name }/`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetTeamMembersApi = ( team_page_name ) => { 
    return Axios.instance.get( `${ api_url }/search-tournament-team/${ team_page_name }/members/?pagination=False`, 
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const GetAdminTeaminMatchReportApi = ( match_id ) => { //TODO: Nueva API
    return Axios.instance.get( `${ api_url }/tournament-matches/${ match_id }/team_players_in_match_role/`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }
    );
}

export const PatchAdminTeamsAssistanceAligmentApi = ( matchId, playerArray) =>{
    return Axios.instance.patch(`${api_url}/tournament-matches/${matchId}/team_players_in_match/`,
    { players: playerArray },
    {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
    );
}
