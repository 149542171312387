// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-search-location__input input[type=text], .input-field-search-location__input .select-field-input, .imput-field-search-location__input .input-field-input {
    background-color: var(--white-color);
    box-sizing: border-box;
    width: 100%;
}

.input-field-search-location__input .input-field-autocomplete-search input {
    margin: 0px;
}

.input-field-search-location__input .input-field-autocomplete-search div {
    background-color: transparent;
}

.input-field-search-location__input .input-field-autocomplete-search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child  {
    padding-left: 6px;
}

.input-field-search-location__input .input-field-autocomplete-search label {
    font-size: 14px;
}

.input-field-search-location__container .input-field-auto-search {
    margin: 0 0 62px 0;
}

.input-field-search-location__container .input-field-autocomplete-search input{
    height: var(--space-x3);
}

.input-field-search-location__select-field .MuiInput-underline::before{
    border-bottom:none;
    content: none;
}

.input-field-search-location__container {
    margin-top: var(--space-and-half);
}`, "",{"version":3,"sources":["webpack://./src/app/tournaments/components/dynamic-fields-tournament-form/input-field-search-location/InputFieldSearchLocationComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".input-field-search-location__input input[type=text], .input-field-search-location__input .select-field-input, .imput-field-search-location__input .input-field-input {\n    background-color: var(--white-color);\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.input-field-search-location__input .input-field-autocomplete-search input {\n    margin: 0px;\n}\n\n.input-field-search-location__input .input-field-autocomplete-search div {\n    background-color: transparent;\n}\n\n.input-field-search-location__input .input-field-autocomplete-search .MuiAutocomplete-inputRoot[class*=\"MuiOutlinedInput-root\"] .MuiAutocomplete-input:first-child  {\n    padding-left: 6px;\n}\n\n.input-field-search-location__input .input-field-autocomplete-search label {\n    font-size: 14px;\n}\n\n.input-field-search-location__container .input-field-auto-search {\n    margin: 0 0 62px 0;\n}\n\n.input-field-search-location__container .input-field-autocomplete-search input{\n    height: var(--space-x3);\n}\n\n.input-field-search-location__select-field .MuiInput-underline::before{\n    border-bottom:none;\n    content: none;\n}\n\n.input-field-search-location__container {\n    margin-top: var(--space-and-half);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
