import React, { useEffect, useState } from 'react';
import '../check-tournament-form/CheckTournamentFormComponent.styles.css';


function CheckboxTournamentFormComponent({
    id,
    caption,
    question_type,
    is_required,
    answers,
    onhandleCheked
}) {

    const [typeCheckbox, setTypeCheckbox] = useState('');

    useEffect(() => {
        setTypeCheckbox(question_type.caption);
    }, [question_type]);

    const handleChecked = (event) => {
        onhandleCheked({ id: event.target.value, status: event.target.checked })
    }

    return (
        <div className={"checkbox_tournament"}>
            {typeCheckbox === 'boolean'
                ? <React.Fragment>
                    <div className={'baloa-username checkbox_tournament_Form_label_question'}>{caption}</div>
                    <div className={'checkbox_tournament_Form'}>
                        <input
                            className={'checkbox_tournament_Form_input'}
                            type="checkbox"
                            name={id}
                            id={id}
                            value={id}
                            onChange={(event) => handleChecked(event)}
                        />
                        <label className={'checkbox_tournament_Form_label'} for={id} >{caption}</label>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className={'baloa-username checkbox_tournament_Form_label_question'}>{caption}</div>
                    {
                        answers.length > 0 &&
                        answers.map(element =>

                            <div key={element.id} className={'checkbox_tournament_Form'}>
                                <input
                                    className={'checkbox_tournament_Form_input'}
                                    type="checkbox"
                                    name={id}
                                    id={element.id}
                                    value={element.id}
                                    onChange={(event) => handleChecked(event)}
                                    defaultChecked={element.is_default}
                                />
                                <label className={'checkbox_tournament_Form_label'} for={element.id}>{element.caption}</label>
                            </div>
                        )
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default CheckboxTournamentFormComponent;