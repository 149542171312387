// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.finish_free_phase_subtitle {
    margin-top: var(--space-and-half);
    margin-left: calc( var(--space) + var(--space-quarter) );
}

.teams_list li {
    list-style-type: none;
    padding: var(--space) 0;
    border-bottom: solid 1px var(--mid-gray-color);
    width: 60%;
}

.teams_list__photo img {
    width: var(--space);
    height: var(--space);
    margin-right: var(--space-half);
    position: relative;
    overflow: hidden;
    background: var(--white-color);
    padding: var(--space-quarter);
    min-width: var(--space-x2);
    min-height: var(--space-x2);
}

.finish_free_phase_footer {
    display: flex;
    height: calc( var(--space-x3) * 2 );
    justify-content: flex-end;
    background-color: var(--white-color);
    align-items: center;
    
}

.finish_free_phase_btn {
    height: calc( var(--space-x2) + var(--space-half) );
    width: calc( var(--space-x3) * 4 );
    grid-template-columns: none; 
    grid-template-areas: none;
    margin-right: var(--space-and-half);    
}

`, "",{"version":3,"sources":["webpack://./src/app/leagues/components/tournament-finish-free-phase-modal/TournamentFinishFreePhaseModalComponent.styles.css"],"names":[],"mappings":";;AAEA;IACI,iCAAiC;IACjC,wDAAwD;AAC5D;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,8CAA8C;IAC9C,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;IAC9B,6BAA6B;IAC7B,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mCAAmC;IACnC,yBAAyB;IACzB,oCAAoC;IACpC,mBAAmB;;AAEvB;;AAEA;IACI,mDAAmD;IACnD,kCAAkC;IAClC,2BAA2B;IAC3B,yBAAyB;IACzB,mCAAmC;AACvC","sourcesContent":["\n\n.finish_free_phase_subtitle {\n    margin-top: var(--space-and-half);\n    margin-left: calc( var(--space) + var(--space-quarter) );\n}\n\n.teams_list li {\n    list-style-type: none;\n    padding: var(--space) 0;\n    border-bottom: solid 1px var(--mid-gray-color);\n    width: 60%;\n}\n\n.teams_list__photo img {\n    width: var(--space);\n    height: var(--space);\n    margin-right: var(--space-half);\n    position: relative;\n    overflow: hidden;\n    background: var(--white-color);\n    padding: var(--space-quarter);\n    min-width: var(--space-x2);\n    min-height: var(--space-x2);\n}\n\n.finish_free_phase_footer {\n    display: flex;\n    height: calc( var(--space-x3) * 2 );\n    justify-content: flex-end;\n    background-color: var(--white-color);\n    align-items: center;\n    \n}\n\n.finish_free_phase_btn {\n    height: calc( var(--space-x2) + var(--space-half) );\n    width: calc( var(--space-x3) * 4 );\n    grid-template-columns: none; \n    grid-template-areas: none;\n    margin-right: var(--space-and-half);    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
