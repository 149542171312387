// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.notifications-popover {
    background: var(--white-color);
    padding: var(--space-and-half) var(--space);
    overflow: hidden;
    box-shadow: var(--shadow-card-3);
    border-radius: 2px;
}
.notifications-popover__title.baloa-subtitle-2{
    color: var(--primary-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/app/notifications/components/notifications-popover/NotificationsPopoverComponent.styles.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,2CAA2C;IAC3C,gBAAgB;IAChB,gCAAgC;IAChC,kBAAkB;AACtB;AACA;IACI,gCAAgC;AACpC","sourcesContent":["\n.notifications-popover {\n    background: var(--white-color);\n    padding: var(--space-and-half) var(--space);\n    overflow: hidden;\n    box-shadow: var(--shadow-card-3);\n    border-radius: 2px;\n}\n.notifications-popover__title.baloa-subtitle-2{\n    color: var(--primary-dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
