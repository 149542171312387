// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-external-stats__container {
    border-radius: 14px;
    background: var(--bg-card-color);
    padding: var(--space-and-half);
    position: relative;
    margin-top: 16px;
}

.profile-external-stats__title .baloa-subtitle-2 {
    color: var(--primary-dark-color);
    padding: 10px 0;
    margin-bottom: 14px;
}

.profile-external-stats__title .hubicon-add_option {
    font-size: 28px;
    color: var(--basic-gray-color);
}

.profile-external-stats__empty-message.baloa-normal-text {
    color: var(--basic-gray-color);
    text-align: center;
    padding: 20px 0;
}

.profile-external-stats__view-more.baloa-normal-medium {
    color: var(--new-green-color);
    width: 100%;
    text-align: center;
    margin-top: 14px;
    cursor: pointer;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ 

    .profile-external-stats__container {
        border-radius: 8px;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    }
}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-external-stats/ProfileExternalStatsComponent.style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,8BAA8B;IAC9B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,kBAAkB;QAClB,aAAa;QACb,iBAAiB;QACjB,kBAAkB;QAClB,+CAA+C;IACnD;AACJ","sourcesContent":[".profile-external-stats__container {\n    border-radius: 14px;\n    background: var(--bg-card-color);\n    padding: var(--space-and-half);\n    position: relative;\n    margin-top: 16px;\n}\n\n.profile-external-stats__title .baloa-subtitle-2 {\n    color: var(--primary-dark-color);\n    padding: 10px 0;\n    margin-bottom: 14px;\n}\n\n.profile-external-stats__title .hubicon-add_option {\n    font-size: 28px;\n    color: var(--basic-gray-color);\n}\n\n.profile-external-stats__empty-message.baloa-normal-text {\n    color: var(--basic-gray-color);\n    text-align: center;\n    padding: 20px 0;\n}\n\n.profile-external-stats__view-more.baloa-normal-medium {\n    color: var(--new-green-color);\n    width: 100%;\n    text-align: center;\n    margin-top: 14px;\n    cursor: pointer;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */ \n\n    .profile-external-stats__container {\n        border-radius: 8px;\n        padding: 10px;\n        margin-left: 10px;\n        margin-right: 10px;\n        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
