import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from "@mui/styles";
import { FormLabel } from '@mui/material';
import './TournamentMatchAdminFilterItem.styles.css';

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

//i18
import { withTranslation } from 'react-i18next';


function TournamentMatchAdminFilterItemComponent(props) {
    const [formats, setFormats] = useState(props.selectedFilter ?? []);
    const [dayOrder, setDayOrder] = useState(props.orderDay ?? null);
    const [matchOrder, setMatchOrder] = useState(props.orderMatch ?? null);
    const [filteredData, setFilteredData ] = useState(props.selectedFilter);

    useEffect( () => {
        setFilteredData(props.selectedFilter);
    }, [ props.selectedFilter ] );// eslint-disable-line

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(1),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
        props.handleFilterButtonsFormat({ newFormats, typeFilter: props.field_id });
    };


    const handleChecked = (event) => {
        const newFormats = { value: event.target.value, checked: event.target.checked };
        props.handleFilterButtonsFormat({ newFormats, typeFilter: props.field_id });
    }

    const handleChangeRadioButton = ({ target }) => {
        const { value } = target;
        if (value === 'match_recent_older' || value === 'match_older_recent') {
            setMatchOrder(value);
        } else {
            setDayOrder(value);
        }
    };

    const handleFilter=(ev)=> {
        setFilteredData(props.selectedFilter?.filter(match => ( match.caption?.toLowerCase().includes(ev.target.value?.toLowerCase())  ) ));
    }

    return (
        <div>
            {(props.filterType === 'match_team' || props.filterType === 'match_stadium') &&
                <div className='tournament_match_filter_item__input-search' >
                    <span className="icon-search" />
                    <input className="tournament_match_filter_item__search_input baloa-username"
                        type="text" placeholder={props.filterType === 'match_team'? props.t("matchAdminFilter.filterLabels.searchTeam"): props.t("matchAdminFilter.filterLabels.searchField")}
                        onChange={(event) => { handleFilter(event) }}>
                    </input>
                </div>
            }
            {(props.filterType === 'match_team' || props.filterType === 'match_stadium' || props.filterType === 'match_status') &&
                <div className='tournament_match_filter_item__checkbox-list scroll'>
                    {!filteredData && 
                        <div className='tournament_match_filter_item__spinner-container'>                                                
                            <div className="tournament_match_filter_item__loader" />
                        </div>
                    }
                    {filteredData?.map((state, index) =>
                        <div className={'tournament_match_filter_item_checkbox'} key={index}>
                            { (props.filterType === 'match_team') &&
                                <div className='tournament-match-admin-filter-item__info-team'>
                                    { state.img
                                        ? <img src={state.img} alt={`${state.team}`} />
                                        : <span className="icon-ball" color={ getRandonIconBallColor() } />
                                    }
                                    <label className={'tournament_match_filter_item_checkbox_label baloa-table'}id={state.value}>
                                        {state.caption}
                                    </label>
                                </div>
                            }
                            { (props.filterType === 'match_status' || props.filterType === 'match_stadium') &&
                                <div className='tournament-match-admin-filter-item__info-team'>
                                    <label className={'tournament_match_filter_item_checkbox_label baloa-table'}id={state.value}>
                                        {state.caption}
                                    </label>                                    
                                </div>
                            }
                            <input
                                className={'checkbox_tournament_Form_input'}
                                type="checkbox"
                                name={props.field_id}
                                id={props.field_id}
                                value={state.value}
                                checked={state.checked}
                                onChange={(event) => {handleChecked(event)}}
                            />
                        </div>
                    )}
                </div>
            }
            {props.filterType === 'match_day' &&
                <div className={'tournament_match_filter_item scroll toggle_button_group'}>
                    <StyledToggleButtonGroup
                        color="primary"
                        value={formats}
                        onChange={handleFormat}
                        name={props.field_id}
                        id={props.field_id}
                    >
                        {props.generalOptions?.map((item, index) => (
                            <ToggleButton key={index} value={index + 1}>
                                {item.caption}
                            </ToggleButton>
                        ))}
                    </StyledToggleButtonGroup>
                </div>
            }
            {props.filterType === 'match_order_by' &&
                <div className={'tournament_match_filter_item'}>
                    <FormControl component="fieldset"
                        className={"tournament_match_filter_item_radio_buttons"}>
                        <FormLabel className='tournament_match_filter_item_radio_title baloa-table'>{props.t("matchAdminFilter.filterLabels.orderByDay")}</FormLabel>
                        <RadioGroup
                            aria-labelledby="orderStateInMatch"
                            name={'order_day'}
                            value={dayOrder}
                            className="tournament_match_filter_item_radio__option-button inside"
                            onChange={handleChangeRadioButton}>
                            <FormControlLabel
                                value="older_recent"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">
                                    {props.t("matchAdminFilter.filterLabels.orderDayOlder_Recent")}
                                </span>}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="recent_older"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">
                                    {props.t("matchAdminFilter.filterLabels.orderDayRecent_Older")}
                                </span>}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset"
                        className={"tournament_match_filter_item_radio_buttons"}>
                        <FormLabel className='tournament_match_filter_item_radio_title baloa-table'>{props.t("matchAdminFilter.filterLabels.orderMatch")}</FormLabel>
                        <RadioGroup
                            aria-labelledby="playerStateInMatch"
                            name={'order_match'}
                            value={matchOrder}
                            className="tournament_match_filter_item_radio__option-button inside"
                            onChange={handleChangeRadioButton}>
                            <FormControlLabel
                                value="match_recent_older"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">
                                    {props.t("matchAdminFilter.filterLabels.orderMatchRecent_Older")}
                                </span>}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="match_older_recent"
                                control={<Radio color="secondary" />}
                                label={<span className="baloa-username">
                                    {props.t("matchAdminFilter.filterLabels.orderMatchOlder_Recent")}
                                </span>}
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            }
        </div>
    )
}

export default withTranslation('league')(TournamentMatchAdminFilterItemComponent);