// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';

// Styles
import './ProfileInviteToValidateAptitudeModalComponent.styles.css';

// Assets
import baloa_user_icon from 'assets/icons/user-white-icon.svg';

// i18n
import { withTranslation } from 'react-i18next';

function ProfileInviteToValidateAptitudeModalComponent( props ) {

    return (
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('apt.modal.title')}
        >
            <div className="scroll-parent">
                <div className="invite scroll">            
                    { props.followers.map( follower => {
                        return (
                        <div key={ follower.username } className="invite__content">
                            <div className=" invite__content__box">
                                <div
                                className={ "invite__content__box__photo" }
                                // onClick={ () => onClick_User()}
                                >
                                    <img src={ follower.photo ? follower.photo : baloa_user_icon } alt=""/>
                                </div>
                                <div
                                className="invite__content__box__info-username"
                                // onClick={ () => onClick_User()}
                                >
                                    <p className="baloa-subtitle-1"> 
                                        @{follower.username}
                                    </p>
                                </div>
                                <div className="invite__content__box__button">
                                    <SecondaryButtonComponent 
                                        type="button"
                                        disabled={ follower['invited-' + props.aptitude?.id] ? follower['invited-' + props.aptitude?.id]: false } 
                                        onClick = { () => { 
                                            props.onInviteToValidate( props.aptitude, follower.username ); 
                                            follower['invited-' + props.aptitude?.id] = true;
                                        } }
                                    >
                                        <span>{ follower['invited-' + props.aptitude?.id] ? props.t('apt.modal.label1') : props.t('apt.modal.label2') }</span>
                                    </SecondaryButtonComponent>
                                    
                                </div>   
                            </div>
                        </div>
                        )
                    }) }
                </div> 
                <div className="invite__bottom"></div>
            </div>
        </Modal2Component>
    )
}


export default withTranslation('profile')(ProfileInviteToValidateAptitudeModalComponent); 