// React
import React from 'react';

// Components

import Modal2Component from 'shared/components/modal2/Modal2Component';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

import team_icon from 'assets/icons/team-icon.svg';

//Styles
import './TournamentFinishFreePhaseModalComponent.styles.css'

//i18
import { withTranslation } from 'react-i18next';

function TournamentFinishFreePhaseModalComponent ( props ) {
    
    return (
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndFreePhase.labelTitle')}
            className="finish_free_phase_modal medium"
         >
            <h1 className='baloa-subtitle-1 finish_free_phase_subtitle'>{props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndFreePhase.labelQualifiedTeams')}</h1>             
            <ul className='teams_list scroll'>
                 {
                     props.teams_classified_by_phases.map( (teams) => {
                        return(
                            teams.classified_teams.map( team =>{
                                return(
                                    <li className='teams_list__photo baloa-body-2'>
                                        {/* <div className='teams_list__photo'> */}
                                            <img src={  team.photo ? team.photo : team_icon } alt=""/>
                                        {/* </div>  */}
                                        {team.name}
                                    </li>
                                )
                            }) 
                        )
                     })
                 }
             </ul>
             <div className='finish_free_phase_footer'>
                <SecondaryButtonComponent 
                    className='finish_free_phase_btn'
                    onClick={ props.onClose }
                >
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndFreePhase.labelBackAction')}                    
                </SecondaryButtonComponent>
                <PrimaryButtonComponent 
                    className='finish_free_phase_btn'
                    onClick={()=> {
                        props.finishTournamentPhase(props.phase_id);
                        props.onClose();
                    }}
                >                    
                    {props.t('options.tournaments.pages.newTournament.tabs.tabFixture.modals.modalEndFreePhase.labelAction')}
                </PrimaryButtonComponent>
             </div>
        </Modal2Component>
    )


}

export default withTranslation('league')(TournamentFinishFreePhaseModalComponent);