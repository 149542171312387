// React
import React, { useState } from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import ComposeInscriptionFormComponent from 'app/tournaments/components/compose-inscription-form/ComposeInscriptionFormComponent'

//Material
import Skeleton from '@mui/material/Skeleton'

// Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';

// Styles
import './AnswerMultipleFormsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

function AnswerMultipleFormsModalComponent ( props ) {

    const [submitButton, setSubmitButton] = useState(false);
    const [sendResponseForm, setSendResponseForm] = useState(true); // eslint-disable-line
    const [listStaffAndPlayer, setListStaffAndPlayer] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [playerId, setPlayerId] = useState(null);
    const [staffId, setStaffId] = useState(null);

    React.useEffect(()=>{
        if(props.team_players && props.team_staff){
            setListStaffAndPlayer(props.team_players.concat(props.team_staff));
            // handleSelectMember(props.selectedPlayer);
        }
    },[props.team_players,props.team_staff])

    React.useEffect(()=>{
        if(!selectedUser){
            handleSelectMember(props.selectedPlayer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectedPlayer])

    React.useEffect(()=>{
        if((playerId || staffId) && selectedUser){
            if(selectedUser?.player_role === 'player'){
                setPlayerId(selectedUser?.id); 
            }else{    
                setStaffId(selectedUser?.id);
            }
        }
    },[playerId, staffId,selectedUser])

    const closeModal=()=>{
        setSubmitButton(false);
        setSendResponseForm(false);
        setSelectedUser(null);
        setStaffId(null);
        setPlayerId(null);
        props.onClose();
    }
    
    const sendForm = async (data)=>{
        await props.onNewData(data);
        setSendResponseForm(false);
        setSelectedUser(null);
        setStaffId(null);
        setPlayerId(null);
    }

    const handleSelectMember = (item) => {
        if(item?.form_id === null){
            if(item.role?.team_role === "staff" || item.role?.team_role === "coach"){
                props.realizeMultiplePlayerForms(item, 'staff');
                setSelectedUser(item);
                setStaffId(item.id);
                setPlayerId(null);
            }else{
                props.realizeMultiplePlayerForms(item, 'player');
                setSelectedUser(item);
                setPlayerId(item.id);
                setStaffId(null);
            }
            setSendResponseForm(true);  
        }
    }

    const handleNextTeamPlayersStaff = () => {
        if( props.next_team_players ){
            props.handleOnNextTeamPlayers();
        }else if( props.next_team_staff ){
            props.handleOnNextTeamStaff();
        }        
    }
    
    return(
        <Modal2Component
            isOpen={props.isOpen} 
            onClose={closeModal}
            title={
                props.t('inscriptionModal.labelPlayerForm')
                }
            className="medium"
        >
            <div className="team-members scroll-parent">

                <div className='team-members__title baloa-body-1'>
                    {props.t('inscriptionModal.subtitleModalForm') }
                </div>

               <div className='scroll-parent'>
                    <div className='team-members__container'>
                        <div className='team-members__list'>
                            <div className='team-members__list__title baloa-body-1'>{props.t('inscriptionModal.subtitleListUser')}</div>
                                <div className='team-members__list__container scroll'>
                                    { listStaffAndPlayer.map(
                                            (player, index) => (
                                                <div className={selectedUser?.id === player.id ? 'team-members__list__container__user selected__user' : 'team-members__list__container__user'} onClick={() => {handleSelectMember(player)}} key={player.id}>
                                                    <div key={index} className='team-members__list__container__user__info'> 
                                                        { player.profile_photo
                                                            ? <img src={player.profile_photo} alt={`${player.full_name} img`} />
                                                            : player.photo 
                                                                ? <img src={player.photo} alt={`${player.full_name} img`} />
                                                                : <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                                        }
                                                        <div className='name-username'>
                                                            { player.full_name 
                                                                ? <div className='baloa-names'>{player.full_name}</div> 
                                                                : <div className='baloa-names'>{player.first_name} {player.last_name}</div>
                                                            }  
                                                            <div className='baloa-username'>@{player.username}</div>
                                                        </div>
                                                    </div>
                                                    { player.form_id != null  && 
                                                        <div className='icon-ompleted-form'>
                                                            <span className='hubicon-check_circle_fill'></span>
                                                        </div>
                                                        }
                                                </div>
                                            )
                                        )
                                    }
                                    {((listStaffAndPlayer.length > 0) && (props.loading_team_staff || props.loading_team_players)) &&
                                        <React.Fragment>
                                            <div className='tournament-detail__team-players__players-skeleton'>
                                                <Skeleton  animation="wave" variant='circular' width={40} height={40}/>
                                                <div className='tournament-detail__team-players__players-skeleton__text'>
                                                    <Skeleton  animation="wave"  width={135}/>
                                                    <Skeleton  animation="wave" width={80}/>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {((props.next_team_players && !props.loading_team_players ) || (props.next_team_staff && !props.loading_team_staff) )&&
                                        <div className='team-members__view-more-link baloa-names' onClick={()=>{ handleNextTeamPlayersStaff() }}>{props.t('viewTournament.inscription.teamPlayerWidget.viewMore')}</div>
                                    }
                                </div>
                        </div>
                        {/** Player data*/}
                        {props.profile_type === 'player' &&
                            <div className='hub-forms-modal__team-info'> 
                                { (props.selected_admin_player?.profile_photo || props.selected_admin_player?.photo)
                                    ? props.selected_admin_player?.profile_photo ? 
                                        <img src={props.selected_admin_player?.profile_photo} alt={`${props.selected_admin_player?.username} img`} />
                                        : <img src={props.selected_admin_player?.photo} alt={`${props.selected_admin_player?.username} img`} />
                                    :
                                    <span className={"icon-ball"} color={getRandonIconBallColor()} ></span>
                                }
                                <div className='hub-forms-modal__team-info__name-username'>
                                    {
                                        props.selected_admin_player?.full_name  ? <div className='baloa-names'>{props.selected_admin_player?.full_name}</div> : <div className='baloa-names'>{props.selected_admin_player?.first_name} {props.selected_admin_player?.last_name}</div>
                                    }
                                    <div className='baloa-username'>@{props.selected_admin_player?.username}</div>
                                </div>
                            </div>
                        }

                        <div className='team-members__form scroll'>
                            {/* Team/Player form */}
                            { ( selectedUser && (playerId  || staffId)) && 
                                <ComposeInscriptionFormComponent
                                    inscription_form_template={props.inscription_form_template}
                                    tournament_team_id={props.tournament_team_id}
                                    tournament_player_id={playerId}//Null si es el formulario de un equipo
                                    onNewData = { (data)=>{sendForm(data)}}
                                    submitForm={submitButton}
                                    resetButton={()=>{setSubmitButton(false)}}
                                    team_player_form={true}
                                    tournament_staff_id = {staffId}
                                    isMultipleForms = {true}
                                /> 
                            }
                        </div>
                    </div>
               </div>

                <div className='hub-forms-modal__buttons'>
                    <PrimaryButtonComponent
                        type={'button'}
                        onClick={ ()=>{ setSubmitButton(true) }} 
                        disabled={(selectedUser === null && sendResponseForm)} 
                        className='small'
                    >
                        { props.send_response_form 
                        ?   <div className='new-tournament__center__header__button__spinner-container'>                                                
                                <div className="span-newtournament__disabled" />
                            </div>
                        : <span>{props.t('inscriptionModal.buttons.fillOutForm')}</span>
                        }
                    </PrimaryButtonComponent>    
                </div>
            </div>
        </Modal2Component>
    )
}

export default withTranslation('tournament')(AnswerMultipleFormsModalComponent);