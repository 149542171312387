// React
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import TableComponent from 'shared/components/dynamic-table/TableComponent';
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';

// Styles
import './TournamentStatisticsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';


function TournamentStatisticsComponent(props){ 

    function handleTournamentResults(value, array) {
        const listArray = []
        if(value === "scorers") {
            array.forEach((element, index) => {
                listArray.push({ id: element.id, index: index + 1, full_name: <Link to={`/profile/${element.name}/`}><TournamentPlayerInfoComponent full_name={`${element.first_name} ${element.last_name}`} team_name={element.teamname} /></Link>, goals: element.goals})                
            });
        }
        if(value === "yellow_cards") {
            array.forEach((element, index) => {
                listArray.push({ id: element.id, index: index + 1, full_name: <Link to={`/profile/${element.name}/`}><TournamentPlayerInfoComponent full_name={`${element.first_name} ${element.last_name}`} team_name={element.teamname} /></Link>, yellow_card: <div className='tournament-statistics__amonished__cards-container'>{element.cards}<div className='yellow-card'/></div>})                
            });
        }
        if(value === "red_cards") {
            array.forEach((element, index) => {
                listArray.push({ id: element.id, index: index + 1, full_name: <Link to={`/profile/${element.name}/`}><TournamentPlayerInfoComponent full_name={`${element.first_name} ${element.last_name}`} team_name={element.teamname} /></Link>, red_card: <div className='tournament-statistics__amonished__cards-container'>{element.cards}<div className='red-card'/></div>})                
            });
        }
        return listArray;
    }

    return(        
        <div className='tournament-statistics__container'>
            <div className='tournament-statistics__scorers'>
                <div className='tournament-statistics__scorers-title'>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.scorersLabel')}</div>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals')}</div>
                </div>
                { props.tournament_results_table?.scorers_table?.length > 0
                    ?
                        <TableComponent                   
                            rows = { handleTournamentResults('scorers', props.tournament_results_table?.scorers_table) }
                            columns = { [
                                "index",
                                "photo",
                                "full_name",                        
                                "goals",                                                
                            ] }
                            columns_headers={ [] }
                            has_image_column={ true }
                            image_column={ 1 }
                            rows_per_page={ 5 }
                            pagination={ true }
                            action_column={ false }
                            order_by="index"
                            has_searchbar={ false }
                            has_add_button={ false }  
                            team={ false }
                        />
                    :
                            <div className='tournament-statistics__no-data'>
                                <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noScorersText')}</div>
                            </div>
                }
            </div>
            <div className='baloa-subtitle-2 tournament-statistics__amonished-title'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.modals.modalEndMatch.tabs.tabCards.labelTitle')}</div>
            <div className='tournament-statistics__amonished'>
                <div className='tournament-statistics__amonished__cards'>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardYellow')}</div>
                    { props.tournament_results_table?.cards?.yellow_cards?.length > 0
                        ?
                            <TableComponent                   
                                rows = { handleTournamentResults('yellow_cards', props.tournament_results_table?.cards.yellow_cards) }
                                columns = { [
                                    "index",
                                    "photo",
                                    "full_name",                        
                                    "yellow_card",
                                ] }
                                columns_headers={ [] }
                                has_image_column={ true }
                                image_column={ 1 }
                                rows_per_page={ 5 }
                                pagination={ true }
                                order_by="index"
                                action_column={ false }
                                has_searchbar={ false }
                                has_add_button={ false }
                                team={ false }
                            />
                        :
                            <div className='tournament-statistics__no-data'>
                                <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noAmonishedText')}</div>
                            </div>
                    }
                </div>
                <div className='tournament-statistics__amonished__cards'>
                    <div className='baloa-subtitle-2'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardRed')}</div>
                    { props.tournament_results_table?.cards?.red_cards?.length > 0 
                        ?
                            <TableComponent                   
                                rows = { handleTournamentResults('red_cards', props.tournament_results_table?.cards.red_cards) }
                                columns = { [
                                    "index",
                                    "photo",
                                    "full_name",                        
                                    "red_card",
                                ] }
                                columns_headers={ [] }
                                has_image_column={ true }
                                image_column={ 1 }
                                rows_per_page={ 5 }
                                pagination={ true }
                                action_column={ false }
                                order_by="index"
                                has_searchbar={ false }
                                has_add_button={ false }
                                team={ false }
                            />
                        :
                            <div className='tournament-statistics__no-data'>
                                <div className='baloa-normal'>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noAmonishedText')}</div>
                            </div>
                    }
                </div>
            </div>                      
        </div>
    )
}

export default withTranslation('league')(TournamentStatisticsComponent);