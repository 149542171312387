// React
import React from 'react';

// Components
import Modal2Component from 'shared/components/modal2/Modal2Component';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import FormComponent from 'shared/components/form/FormComponent';
import { useFormik } from 'formik';
import { Autocomplete } from '@mui/material';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

// Styles
import './ProfileNationalityModalComponent.styles.css';
import { withStyles } from '@mui/styles';

// i18n
import { withTranslation } from 'react-i18next';


function ProfileNationalityModalComponent( props ) {

    const { children, /*value,*/ index } = props; // eslint-disable-line
    const [input_change, setInputChange] = React.useState("");
    const formRef = React.createRef();

    const formikvalues = useFormik({
        initialValues: {
            nationality: props.model?.nationality? props.model?.nationality : null,
            location: props.model?.location? props.model?.location :null,
            birthday: props.model?.user?.birthday? props.model?.user?.birthday: null,
        },
    });

    const AutocompleteTextField = withStyles({
        root: {
          '& label.Mui-focused': {
            color: '#2a302a',
          },          
          '& .MuiOutlinedInput-root': {
            '& fieldset': {             
              border: 'none',
            },           
          },
        },
      })(TextField);

    return (
        
        <Modal2Component 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            title={props.t('data.title')}
        >
            
            <FormComponent 
                formInitialValues={ formikvalues.initialValues } 
                onSubmit={( values, actions ) => {   
                    let new_nationality_data = {
                        /* eslint-disable */ 
                        [0]: values['nationality'],
                        [1]: values['location'],
                        [2]: values['birthday']
                        /* eslint-enable */
                    }
                    props.onNewData( new_nationality_data );
                }}
                className="nationality-form__form form"
                autocomplete="off"
                innerRef={formRef}
            >
                <div className="nationality-form__form__content">
                    <Box margin={1}>
                        { props.user_birthday === null &&
                            <div className='profile-nationality-modal__input-container'>
                                <div className='profile-nationality-modal__input-label ' >{props.t('data.labelBirthday')}</div>
                                <input name='birthday' type='date' className='profile-nationality-modal__input baloa-names' />
                            </div>
                        }
                        <Autocomplete
                            //id="profile_nationality"
                            name="nationality"
                            //component={Autocomplete}

                            options={props.nationalities? props.nationalities : null}
                            noOptionsText={props.t('data.modal.noOpt')}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={option => option[props.optionSelectedField] }
                            isOptionEqualToValue={(option, value) => (option.id === value.id/* , console.log('vlue', value, option) */) }
                            disablePortal={true}
                            onInputChange={(event, newNationalityValue) => {
                                if(newNationalityValue.length > 2 ){
                                    props.handleSearchNationality( newNationalityValue );
                                    setInputChange('profile_nationality');
                                }
                            }}
                            onChange={(e,data)=> {
                                formRef.current.setFieldValue('nationality',data)
                            }}
                            renderInput={(params) => (
                                <AutocompleteTextField
                                    {...params}
                                    className="nationality-form__form__content__input"
                                    label={props.t('data.textNationality')}
                                    variant="outlined"
                                    margin="normal"
                                    debug="true"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    autoFocus={input_change ===  'profile_nationality'}
                                />
                            )}
                        />
    
                        <Autocomplete
                            //id="location"
                            name="location"
                            //component={Autocomplete}
                            options={props.locations? props.locations : null}
                            noOptionsText={props.t('data.modal.noOpt')}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={option => option.id }
                            isOptionEqualToValue={(option, value) => option.id === value.id }
                            disablePortal={true}
                            onInputChange={(event, newLocationValue) => {
                                if(newLocationValue.length > 2 ){
                                //props.handleSearchLocation( formikvalues.values.nationality,newLocationValue );
                                    props.handleSearchLocation( props.nationalities[0]?.id,newLocationValue );
                                    setInputChange('profile_location');
                                }
                            }}

                            onChange={(e,data)=> {
                                formRef.current.setFieldValue('location',data)
                            }}

                            renderInput={(params) => (
                                <AutocompleteTextField
                                    {...params}
                                    className="nationality-form__form__content__input"
                                    label={props.t('data.textLocation')}
                                    variant="outlined"
                                    margin="normal"
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    autoFocus={input_change ===  'profile_location'}
                                />
                            )}
                        />
                    </Box>
                </div>
                <div className="nationality-form__form__actions form__actions">
                    <PrimaryButtonComponent 
                        type="submit"
                        disabled={false} 
                        onClick={()=>{}} 
                        className="specialties__actions__submit"
                    >
                        <span>{props.specialty? props.t('data.modal.label1') : props.t('data.modal.label2') }</span>
                    </PrimaryButtonComponent>
                </div>
            </FormComponent>

        </Modal2Component>

        )
    }
    
    export default withTranslation('profile')(ProfileNationalityModalComponent);